import { useCallback, useRef, useState } from 'react';

import { Grid } from '@mui/material';
import dayjs from 'dayjs';

import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { GridItem } from '@ecp/components';
import { INITIAL_OFFERS_REQUESTED } from '@ecp/features/sales/shared/constants';
import {
  calculateMaxPolicyStartDaysLimit,
  getAnswer,
  getOfferProductsSelectedByType,
  getPolicyStartDates,
  getRecalcProcessingValue,
  setProcessingRecalc,
  updateAnswers,
  updateOffers,
  useForm,
  usePolicyStartDateFields,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue } from '@ecp/features/sales/shared/types';
import {
  getProductDisplayNameFromProduct,
  getProductNameFromProduct,
  isConnectAutoProduct,
  isLineOfBusinessBundle,
  isLineOfBusinessProperty,
  LineOfBusiness,
} from '@ecp/features/shared/product';

import { DatePicker } from '../DatePicker';
import { Dialog } from '../Dialog';
import { Form } from '../Form';
import type { QuotesPolicyStartDateProps } from './QuotesPolicyStartDate';
import { useStyles } from './QuotesPolicyStartDateDialog.styles';

interface Props extends QuotesPolicyStartDateProps {
  open: boolean;
  toggleDialog: () => void;
}

export const QuotesPolicyStartDateDialog: React.FC<Props> = (props) => {
  const { open, selectedLob, toggleDialog } = props;
  const { classes } = useStyles();

  // selectedLob here is expected to be unbundled at the moment, if not - check with analytics team
  const productName = selectedLob.split('.')[1].toLowerCase();
  const dispatch = useDispatch();
  const initialOffersRequested =
    useSelector((state: RootStore) => getAnswer(state, INITIAL_OFFERS_REQUESTED)) === 'true';
  const isRecalcProcessing = useSelector(getRecalcProcessingValue);
  const [autoStartDateChanged, setAutoStartDateChanged] = useState(false);
  const [propertyStartDateChanged, setPropertyStartDateChanged] = useState(false);
  const productsSelectedByType = useSelector(getOfferProductsSelectedByType);
  const policyStartDateFields = usePolicyStartDateFields();
  const propertyProductName =
    productsSelectedByType.property && getProductNameFromProduct(productsSelectedByType.property);
  const propertyProductDisplayName =
    productsSelectedByType.property &&
    getProductDisplayNameFromProduct(productsSelectedByType.property);

  const initValues = useRef(
    Object.keys(productsSelectedByType).reduce((acc, product) => {
      const policyStartDateField = policyStartDateFields[product];
      acc[policyStartDateField.key] = policyStartDateField.value;

      return acc;
    }, {} as Record<string, AnswerValue>),
  );
  const { validateForm, patchFormValues } = useForm({
    fields: policyStartDateFields,
    initValues,
  });

  const minDate = dayjs().startOf('d').add(1, 'd');

  const maxDays = calculateMaxPolicyStartDaysLimit({
    isAutoProduct: selectedLob === LineOfBusiness.AUTO,
    isConnectAutoProduct: isConnectAutoProduct(productsSelectedByType.auto),
  });
  const maxDate = dayjs().startOf('d').add(maxDays, 'd');

  const isSelectedLobBundle = isLineOfBusinessBundle(selectedLob);
  const isSelectedLobProperty = isLineOfBusinessProperty(selectedLob);

  const policyStartDateAnswers = useSelector(getPolicyStartDates);

  const handleAutoPolicyStartDateOnComplete = useCallback(
    (value: AnswerValue) => {
      if (value) setAutoStartDateChanged(policyStartDateAnswers.auto !== value.toString());
    },
    [policyStartDateAnswers.auto, setAutoStartDateChanged],
  );
  // disable manual entry when the featue flag is enabled
  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    if (flagValues[FeatureFlags.CONNECT_AUTO_STATES_LIMIT_EFFECTIVE_DATE]) event.preventDefault();
  }, []);

  const handlePropertyPolicyStartDateOnComplete = useCallback(
    (value: AnswerValue) => {
      if (value) setPropertyStartDateChanged(policyStartDateAnswers.property !== value.toString());
    },
    [policyStartDateAnswers.property, setPropertyStartDateChanged],
  );

  // Make policy start date modal open automatically only on first visit
  const viewedInitialQuotes = useCallback(async () => {
    if (initialOffersRequested) {
      await dispatch(
        updateAnswers({
          answers: {
            [INITIAL_OFFERS_REQUESTED]: false,
          },
        }),
      );
    }
  }, [dispatch, initialOffersRequested]);

  const handleSaveAndClose = useCallback(async () => {
    if (validateForm().isValid) {
      if (autoStartDateChanged || propertyStartDateChanged) {
        dispatch(setProcessingRecalc(true));
        const actionsOnComplete = Object.keys(productsSelectedByType).map((product) => {
          const policyStartDateField = policyStartDateFields[product];

          return policyStartDateField.props.actionOnComplete(policyStartDateField.value);
        });
        await Promise.all(actionsOnComplete);
        await patchFormValues();
        await dispatch(updateOffers());
        dispatch(setProcessingRecalc(false));
        setAutoStartDateChanged(false);
        setPropertyStartDateChanged(false);
      }
      toggleDialog();
      viewedInitialQuotes();
    }
  }, [
    autoStartDateChanged,
    dispatch,
    patchFormValues,
    policyStartDateFields,
    productsSelectedByType,
    propertyStartDateChanged,
    toggleDialog,
    validateForm,
    viewedInitialQuotes,
  ]);
  // Reset values if user hits close without saving
  const handleClose = useCallback(() => {
    viewedInitialQuotes();
    Object.keys(productsSelectedByType).forEach((product) =>
      policyStartDateFields[product].reset(),
    );
    toggleDialog();
  }, [productsSelectedByType, policyStartDateFields, viewedInitialQuotes, toggleDialog]);

  return (
    <Dialog
      open={open}
      actionButtonLabel='Save &amp; Close'
      textButtonLabel='Cancel'
      textButtonVariant='iconTextMedium'
      titleText='Confirm your policy start date'
      actionButtonOnClick={handleSaveAndClose}
      textButtonOnClick={handleClose}
      onClose={handleClose}
      isProcessing={isRecalcProcessing}
      buttonPlacement='right'
      trackingNameButton={`${productName}_policy_start_date_save`}
      trackingLabelButton='save'
      trackingNameText={`${productName}_policy_start_date_cancel`}
      trackingLabelText='cancel'
      trackingNameCloseIcon={`${productName}_policy_start_date_x`}
      trackingLabelCloseIcon='x'
      data-testid='changeStartDateDialog'
      analyticsElementRender='choice.policyStartDateModal.modal'
      disableClose={isRecalcProcessing}
      disclosureText='Adjustments to start date could result in adjustments to price.'
    >
      <Form showBackdrop={isRecalcProcessing} component='div'>
        <Grid container item xs={12} alignContent='center'>
          <Grid item xs={12}>
            <p>
              {selectedLob === LineOfBusiness.HOME
                ? 'This should be your closing day on a new house purchase. For an existing house, this should be the day your policy expires or before.'
                : 'This should be the day your current policy expires or before.'}
              &nbsp;Set your policy start date between tomorrow and {maxDays} days in advance.
            </p>
          </Grid>
          {(selectedLob === LineOfBusiness.AUTO || isSelectedLobBundle) && (
            <GridItem
              topSpacing='sm'
              md={6}
              sm={12}
              xs={12}
              className={isSelectedLobBundle ? classes.columnLeft : undefined}
            >
              {productsSelectedByType.auto && (
                <DatePicker
                  {...policyStartDateFields.auto.props}
                  actionOnComplete={handleAutoPolicyStartDateOnComplete}
                  data-testid='autoPSD'
                  fullWidth
                  id='AutoPolicyStart'
                  label='Auto policy start date'
                  minDate={minDate}
                  maxDate={maxDate}
                  onKeyDown={handleKeyDown}
                  trackingName='auto_policy_start_date'
                  trackingLabel={policyStartDateFields.auto.props.value}
                  analyticsElement='choice.policyStartDateModal.autoPolicyDatePicker'
                />
              )}
            </GridItem>
          )}
          {(isSelectedLobProperty || isSelectedLobBundle) && (
            <GridItem
              topSpacing='sm'
              md={6}
              sm={12}
              xs={12}
              className={isSelectedLobBundle ? classes.columnRight : undefined}
            >
              {productsSelectedByType.property && (
                <DatePicker
                  {...policyStartDateFields.property.props}
                  actionOnComplete={handlePropertyPolicyStartDateOnComplete}
                  data-testid={`${propertyProductName}PSD`}
                  fullWidth
                  id={`${propertyProductDisplayName}PolicyStart`}
                  label={`${propertyProductDisplayName} policy start date`}
                  minDate={minDate}
                  maxDate={maxDate}
                  onKeyDown={handleKeyDown}
                  trackingName={`${propertyProductName}_policy_start_date`}
                  trackingLabel={policyStartDateFields.property.props.value}
                  analyticsElement={`choice.policyStartDateModal.${propertyProductName}PolicyDatePicker`}
                />
              )}
            </GridItem>
          )}
        </Grid>
      </Form>
    </Dialog>
  );
};
