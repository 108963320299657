import { useCallback, useMemo } from 'react';

import { FormHelperText, Grid } from '@mui/material';

import { castToBoolean } from '@ecp/utils/common';

import { useAddFields } from '@ecp/features/sales/form';
import type { CheckboxProps } from '@ecp/features/sales/shared/components';
import { Checkbox } from '@ecp/features/sales/shared/components';
import {
  getPrimaryInsuredStateCode,
  getStateSpecificSignatureAcknowledgementMetadata,
  useField,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product, ProductName } from '@ecp/features/shared/product';

import { signatureAcknowledgementMetadata } from '../../../metadata/Acknowledgement.metadata';
import { setAgreementResponse } from '../../../state';
import type { Acknowledgement, DocumentForm } from '../../../types';
import { useStyles } from './CheckboxAcknowledgements.styles';
import { DocumentLink } from './DocumentLink';

interface Props {
  acknowledgements: Acknowledgement[];
  coverageType: ProductName;
  selectedProduct: Product;
}

export const CheckboxAcknowledgements: React.FC<Props> = (props) => {
  const { acknowledgements, coverageType, selectedProduct } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const checkboxAcknowledgements = useField(
    `static.checkout.${coverageType}.paymentAcknowledgement`,
  );
  useAddFields({
    [`static.checkout.${coverageType}.paymentAcknowledgement`]: checkboxAcknowledgements,
  });
  const {
    props: { name, value, error, actionOnComplete },
  } = checkboxAcknowledgements;

  const onPaymentAcknowledgementChangeCheck = useCallback<NonNullable<CheckboxProps['onChange']>>(
    (_, checked) => {
      actionOnComplete(String(checked));
      dispatch(setAgreementResponse(checked));
    },
    [dispatch, actionOnComplete],
  );

  const documentForms = useMemo(
    () =>
      acknowledgements
        .reduce((acc, acknowledgement) => {
          if (acknowledgement.documentForms) acc.push(...acknowledgement.documentForms);

          return acc;
        }, [] as DocumentForm[])
        .sort((x, y) => x.documentDisplayName.length - y.documentDisplayName.length),
    [acknowledgements],
  );

  const documentLinks: React.ReactElement[] | undefined = useMemo(
    () =>
      documentForms.map((documentForm, index) => {
        const isLast = documentForms.length === index + 1;

        return (
          <DocumentLink
            key={index}
            documentForm={documentForm}
            isLast={isLast}
            selectedProduct={selectedProduct}
            trackingName={`${documentForm.documentFormName.toLowerCase()}_link`}
            trackingLabel={documentForm.documentFormName.toLowerCase()}
          />
        );
      }),
    [documentForms, selectedProduct],
  );

  const stateCode = useSelector(getPrimaryInsuredStateCode);

  // Acknowledgements that require a checkbox confirmation will share the same text to display.
  // To avoid showing this text twice, we can look for the first key in the metadata that is
  // also contained in the array of acknowledgements passed to this component.
  const checkboxText = useMemo(() => {
    const stateData = getStateSpecificSignatureAcknowledgementMetadata(
      signatureAcknowledgementMetadata[selectedProduct],
      stateCode,
    );
    const key = Object.keys(stateData).find((ackMetaDataKey) =>
      acknowledgements.map((ack) => ack.key).includes(ackMetaDataKey),
    );

    return key && stateData[key].title;
  }, [acknowledgements, selectedProduct, stateCode]);

  return (
    <Grid container className={classes.root} role='group' alignItems='flex-start' wrap='nowrap'>
      <Checkbox
        name={name}
        value={value}
        checked={castToBoolean(value)}
        onChange={onPaymentAcknowledgementChangeCheck}
        trackingName='customer_agreement_checkbox'
        trackingLabel={value as string}
        data-testid={name}
        className={classes.checkbox}
        inputProps={{
          'aria-label': 'Customer Agreement Checkbox',
        }}
      />
      <ul className={classes.verticalAlignMiddle}>
        <li>
          {checkboxText}
          {` `}
          {documentLinks}
        </li>
        {error && (
          <li>
            <FormHelperText error role='alert'>
              {error}
            </FormHelperText>
          </li>
        )}
      </ul>
    </Grid>
  );
};
