import type { Product } from '@ecp/features/shared/product';

import { getNumOfInstallments } from './paymentPlanUtil';

export const getMonthlyPaymentsText = (
  paymentPlan: string | undefined,
  downPayment: number | undefined,
  product?: Product,
): string => {
  const numberOfInstallments = getNumOfInstallments(paymentPlan, Number(downPayment), product);

  return `${numberOfInstallments} monthly payment${numberOfInstallments !== 1 ? 's' : ''} of`;
};
