import { memo } from 'react';

import { Grid } from '@mui/material';

import {
  getProducts,
  isSniPageEnabled,
  useField,
  usePostBindField,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import {
  MortgageAddressQuestion,
  SecondaryInsuredQuestion,
} from '../../../components/Question/Mortgage';
import { useStyles } from './MortgageFormQuestions.styles';

export const MortgageFormQuestions: React.FC = memo(() => {
  const { classes } = useStyles();

  const isPaymentThroughMortgage =
    useField('static.checkout.home.paymentMethod').value === 'Mortgage';

  const mortgageLoanCompanyNameOrigin = usePostBindField('mortgage.loanCompanyNameOrigin');
  const isSniPageRendered = useSelector((state: RootStore) => isSniPageEnabled(state));
  // Hide SNI property questions for midvale bundle
  const products = useSelector((state: RootStore) => getProducts(state));
  const isMidvaleBundle = products.length > 1 && products.includes('amfam.auto');

  if (!isPaymentThroughMortgage || !mortgageLoanCompanyNameOrigin.exists) return null;

  return (
    <div className={classes.informationCard}>
      <div className={classes.headerContainer}>
        <Grid item container>
          <Grid item xs={12}>
            <p className={classes.header}>Home information</p>
          </Grid>
          <Grid item xs={12} className={classes.description}>
            <p className={classes.content}>
              Do you have a mortgage on your home? We recommend you add this information to your
              policy now. Otherwise, we will send you reminders.
            </p>
          </Grid>
          <Grid item xs={12}>
            <MortgageAddressQuestion />
            {!isSniPageRendered && !isMidvaleBundle && <SecondaryInsuredQuestion />}
          </Grid>
        </Grid>
      </div>
    </div>
  );
});
