import { castAnswerType } from '@ecp/utils/common';

import type { Answers } from '@ecp/features/sales/shared/types';

import type { QuestionsState } from '../types';
import { getQuestionWithDefault } from './getQuestion';

export const makeAnswersRich = (answers: Answers, questionsState: QuestionsState): Answers => {
  return Object.assign(
    {},
    ...Object.entries(answers).map(([key, value]) => {
      const question = getQuestionWithDefault(key, questionsState);
      const newValue = castAnswerType(value, question.answerType);

      return { [key]: newValue };
    }),
  );
};
