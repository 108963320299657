import { useCallback, useMemo } from 'react';

import { ensureStringArray } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';

import {
  createRef,
  getAllValues,
  getInquiryLoaded,
  getValueForPrefix,
  updateAnswers,
  updateRemovedRef,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Answers, AnswerValue, Percentages } from '@ecp/features/sales/shared/types';

import {
  FOUNDATION_PERCENTAGE_SUFFIX,
  FOUNDATION_REF,
  FOUNDATION_TYPE_SUFFIX,
} from '../../constants';

interface BaseFoundationType {
  percentage: string;
  foundationType: string;
}

interface FoundationType extends BaseFoundationType {
  ref: string;
}

const getFoundation = (ref: string, allValues: Answers): FoundationType => {
  const getString = getValueForPrefix<string>(ref, allValues);

  return {
    percentage: getString(FOUNDATION_PERCENTAGE_SUFFIX),
    foundationType: getString(FOUNDATION_TYPE_SUFFIX),
    ref,
  };
};

const useFoundations = (): FoundationType[] => {
  const allValues = useSelector(getAllValues);
  const refs: string[] = ensureStringArray(allValues[FOUNDATION_REF]);

  return refs.map((ref) => getFoundation(ref, allValues));
};

export const useFoundationPercentages = (): { [key: string]: string } => {
  const foundationTypes = useFoundations();

  return foundationTypes.reduce<{ [key: string]: string }>((percentages, foundation) => {
    return { ...percentages, [foundation.foundationType]: foundation.percentage };
  }, {});
};

interface AddFoundationTypeResult {
  // the api has received the update
  refs: string[];
  // the ref used
  foundationRef: string;
}

const useAddFoundation = (): (() => AddFoundationTypeResult) => {
  const dispatch = useDispatch();
  const allValues = useSelector(getAllValues);
  const inquiryLoaded = useSelector(getInquiryLoaded);

  return useCallback(() => {
    const foundationRef = dispatch(createRef('foundation'));
    // FIXME: assume inquiry is loaded?
    if (!inquiryLoaded) {
      datadogLog({
        logType: 'error',
        message: 'inquiry not loaded',
        context: {
          logOrigin:
            'libs/features/sales/quotes/property/home/src/components/Question/HomeBasic/FoundationTypeQuestion/util.ts',
          functionOrigin: 'useAddFoundation/useCallback',
        },
      });
      throw new Error('inquiry not loaded');
    }

    const existingRefs: string[] = ensureStringArray(allValues[FOUNDATION_REF]);
    const refs = [
      ...existingRefs,
      ...(!existingRefs.includes(foundationRef) ? [foundationRef] : []),
    ];

    return {
      refs,
      foundationRef,
    };
  }, [allValues, dispatch, inquiryLoaded]);
};

const makeFoundationAnswers = (
  foundations: FoundationType[],
  percentages: Percentages,
): Answers => {
  const newFoundations: BaseFoundationType[] = Object.entries(percentages).map(([key, value]) => ({
    foundationType: key,
    percentage: value,
  }));

  return newFoundations.reduce<Answers>((preAnswers, bew) => {
    const foundation = foundations.find((ew) => ew.foundationType === bew.foundationType);
    if (!foundation) {
      datadogLog({
        logType: 'error',
        message: `Not found foundation for value "${bew.foundationType}"`,
        context: {
          logOrigin:
            'libs/features/sales/quotes/property/home/src/components/Question/HomeBasic/FoundationTypeQuestion/util.ts',
          functionOrigin: 'makeFoundationAnswers',
        },
      });
      throw new Error(`Not found foundation for value "${bew.foundationType}"`);
    }

    return {
      ...preAnswers,
      [`${foundation.ref}.${FOUNDATION_PERCENTAGE_SUFFIX}`]: bew.percentage,
    };
  }, {});
};

export const useAddAndUpdateFoundation = (): ((key: string, value: string) => Promise<void>) => {
  const dispatch = useDispatch();
  const foundations = useFoundations();
  const addFoundation = useAddFoundation();

  return useCallback(
    async (key: string, value: string, percentages: Percentages = {}) => {
      const foundation = { foundationType: key, percentage: value };
      const { refs, foundationRef } = addFoundation();
      const { foundationType, percentage } = foundation;
      const answers = makeFoundationAnswers(foundations, percentages);
      await dispatch(
        updateAnswers({
          answers: {
            ...answers,
            [FOUNDATION_REF]: refs,
            [`${foundationRef}.${FOUNDATION_TYPE_SUFFIX}`]: foundationType,
            [`${foundationRef}.${FOUNDATION_PERCENTAGE_SUFFIX}`]: percentage,
          },
        }),
      );
    },
    [addFoundation, foundations, dispatch],
  );
};

export const useUpdateFoundation = (): ((percentages: Percentages) => Promise<void>) => {
  const dispatch = useDispatch();
  const foundations = useFoundations();

  return useCallback(
    async (percentages: Percentages) => {
      const answers = makeFoundationAnswers(foundations, percentages);
      await dispatch(updateAnswers({ answers }));
    },
    [foundations, dispatch],
  );
};

export const useRemoveFoundation = (): ((foundationType: string) => Promise<void>) => {
  const dispatch = useDispatch();
  const allValues = useSelector(getAllValues);
  const foundations = useFoundations();

  return useCallback(
    async (foundationType: string, percentages: Percentages = {}) => {
      const foundation = foundations.find((ew) => ew.foundationType === foundationType);
      if (!foundation) {
        datadogLog({
          logType: 'error',
          message: `Not found foundation for value "${foundationType}"`,
          context: {
            logOrigin:
              'libs/features/sales/quotes/property/home/src/components/Question/HomeBasic/FoundationTypeQuestion/util.ts',
            functionOrigin: 'useRemoveFoundationType/useCallback',
          },
        });
        throw new Error(`Not found exterior wall for value "${foundationType}"`);
      }
      const answers = makeFoundationAnswers(foundations, percentages);
      await dispatch(
        updateRemovedRef({ refType: FOUNDATION_REF, allValues, removedRef: foundation.ref }),
      );
      await dispatch(
        updateAnswers({
          answers: {
            ...answers,
            [`${foundation.ref}.${FOUNDATION_TYPE_SUFFIX}`]: null,
            [`${foundation.ref}.${FOUNDATION_PERCENTAGE_SUFFIX}`]: null,
          },
        }),
      );
    },
    [foundations, allValues, dispatch],
  );
};

// Use this hook when the user is allowed to select only one foundation type with a radio
export const useSingleFoundation = (): {
  singleFoundationRef: string;
  addUpdateFoundationDetails: (foundationType: AnswerValue) => Promise<void>;
} => {
  const dispatch = useDispatch();
  const allValues = useSelector(getAllValues);
  const refs: string[] = ensureStringArray(allValues[FOUNDATION_REF]);
  const firstFoundationRef = refs[0];

  // This memoization is important so that the hook doesn't rerender
  // everytime anything change in the store and falls to an infinite loop.
  const singleFoundationRef = useMemo(() => {
    return firstFoundationRef ?? dispatch(createRef('foundation'));
  }, [dispatch, firstFoundationRef]);

  const addUpdateFoundationDetails = useCallback(
    async (foundationType: AnswerValue): Promise<void> => {
      await dispatch(
        updateAnswers({
          answers: {
            [FOUNDATION_REF]: singleFoundationRef,
            [`${singleFoundationRef}.${FOUNDATION_TYPE_SUFFIX}`]: foundationType,
          },
        }),
      );
    },
    [dispatch, singleFoundationRef],
  );

  return {
    singleFoundationRef,
    addUpdateFoundationDetails,
  };
};

export const doesFoundationTypeIncludeBasement = (foundationTypeValue: string): boolean =>
  foundationTypeValue?.includes('FOUNDATION.FOUNDATION_TYPE.BASEMENT_UNDERGROUND_BELOW_GRADE') ||
  foundationTypeValue?.includes('FOUNDATION.FOUNDATION_TYPE.BASEMENT_DAYLIGHT_WALKOUT');
