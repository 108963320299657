import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { datadogLog } from '@ecp/utils/logger';

import { env } from '@ecp/env';
import { ErrorReason, PrefillFlow } from '@ecp/features/sales/shared/constants';
import { SalesRequestError } from '@ecp/features/sales/shared/utils/network';

import { getGlobalError } from '../../global/selectors';
import { wrapThunkActionWithErrHandler } from '../../util/wrapThunkActionWithErrHandler';
import { setInquiryApiGetSuccess } from '../actions';
import { postPrefill } from '../api';
import {
  getDalSessionId,
  getIsPrefillCompleted,
  getPartnerExperienceId,
  getPrimaryInsuredPersonLock,
} from '../selectors';
import { updateAnswers } from './updateAnswers';

export const makeSurePrefillFlowInStore = wrapThunkActionWithErrHandler<void>(
  () => async (dispatch, getState) => {
    const state = getState();
    const expId = getPartnerExperienceId(state);
    const dalSessionId = getDalSessionId(state);

    const allowPrefillShortFlowAuto = flagValues[FeatureFlags.ALLOW_PREFILL_SHORT_FLOW_AUTO];
    const forceLongFlowHome = flagValues[FeatureFlags.FORCE_LONG_FLOW_HOME];
    const isPrefillCompleted = getIsPrefillCompleted(state);
    const primaryInsuredPersonLock = getPrimaryInsuredPersonLock(state);
    const callPrefillOnAddingProduct =
      flagValues[FeatureFlags.SHOULD_CALL_PREFILL_ON_ADDING_PRODUCT];
    if (
      !dalSessionId ||
      env.static.prefillDisabled ||
      (env.static.isAgent && env.prefillDisabled?.[expId]) ||
      (primaryInsuredPersonLock && isPrefillCompleted)
    )
      return;

    const errorReason = getGlobalError(state)?.errorReason;
    if (errorReason === ErrorReason.RULES_EXCEPTION) return;

    try {
      // TODO this override calls can be moved to DAL prefill endpoint as well
      // EDSP-11441 Adding additional check and this if condition can be removed when above TODO is addressed
      if (!callPrefillOnAddingProduct) {
        await dispatch(
          updateAnswers({
            answers: {
              // ECP-11497 Conditionally add the answer when flag is OFF
              ...(!allowPrefillShortFlowAuto && { 'auto.prefill.flow.override': PrefillFlow.LONG }),
              // ECP-700: Conditionally add these to answers for prefill override
              ...(forceLongFlowHome && { 'home.prefill.flow.override': PrefillFlow.LONG }),
            },
          }),
        );
      }
      const response = await postPrefill({ dalSessionId });
      dispatch(setInquiryApiGetSuccess(response.payload));
    } catch (e) {
      const error = e as Error | SalesRequestError;

      datadogLog({
        logType: 'error',
        message: `Error posting prefill - ${error?.message}`,
        context: {
          logOrigin:
            'libs/features/sales/shared/store/lib/src/inquiry/thunks/makeSurePrefillFlowInStore.ts',
          functionOrigin: 'makeSurePrefillFlowInStore',
          ...(error instanceof SalesRequestError && { errorData: error.errorData }),
        },
        error,
      });

      throw e;
    }
  },
  'makeSurePrefillFlowInStore',
);
