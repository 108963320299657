import { Evaluators, Operators, QuestionTypes } from '../constants';
import type { Answer, Question } from '../types';

/** Returns the native js type of input. */
export const typeOf = (input: unknown): string =>
  Object.prototype.toString.call(input).slice(8, -1);
/** Returns whether input is of native js type. */
export const isType = (type: string, input: unknown): boolean => typeOf(input) === type;

export const isUndefined = (input: unknown): input is undefined => isType('Undefined', input);
export const isNull = (input: unknown): input is null => isType('Null', input);
export const isString = (input: unknown): input is string => isType('String', input);
export const isNumber = (input: unknown): input is number => isType('Number', input);
export const isBoolean = (input: unknown): input is boolean => isType('Boolean', input);
export const isObject = (input: unknown): input is Record<string, unknown> =>
  isType('Object', input);
export const isArray = (input: unknown): input is unknown[] => isType('Array', input);
export const isFunction = (input: unknown): input is (...args: unknown[]) => unknown =>
  isType('Function', input);
/** Returns whether input is undefined, null, or an empty string. */
export const isEmpty = (input: unknown): boolean =>
  isUndefined(input) || isNull(input) || (isString(input) && input.length === 0);

const answerTypes = Object.values(QuestionTypes);
export const isAnswer = (input: unknown): input is Answer => answerTypes.includes(typeOf(input));

const questionTypes = answerTypes;
export const isQuestion = (input: unknown): input is Question =>
  isObject(input) && questionTypes.includes(input.type);

/** Returns whether input is an appropriately formatted string. */
export const isValueReference = (input: unknown): input is string =>
  isString(input) && input.startsWith('@');

const operators = Object.values(Operators);
export const isOperator = (input: unknown): input is Operators => operators.includes(input);

const evaluators = Object.values(Evaluators);
export const isEvaluator = (input: unknown): input is Evaluators => evaluators.includes(input);
