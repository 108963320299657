import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const DaycareOnPremisesQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const daycareOnPremises = useHomePropertyField('dayCareOnPremisesInd');
  const { label = daycareOnPremises.question?.title, trackingName = 'DaycareOnPremisesButton' } =
    props;

  useInitValues({ [daycareOnPremises.key]: false });

  useAddFields({ daycareOnPremises });

  if (!daycareOnPremises.exists) return null;

  return (
    <RadioGroupWithOptions
      {...daycareOnPremises.props}
      id='DaycareOnPremises'
      label={label}
      variant='yesNoButton'
      trackingName={trackingName}
    />
  );
};
