export interface Metadata {
  step1: React.ReactElement;
  step2: React.ReactElement;
  step3: React.ReactElement;
}

const metadata: Metadata = {
  step1: (
    <div className='step step1'>
      <p className='stepHeader'>Analyzing your responses</p>
      <p>Reviewing the information</p>
      <p>you provided</p>
    </div>
  ),
  step2: (
    <div className='step step2'>
      <p className='stepHeader'>Optimizing your policy</p>
      <p>Customizing coverages to your</p>
      <p>needs</p>
    </div>
  ),
  step3: (
    <div className='step step3'>
      <p className='stepHeader'>Preparing your quote</p>
      <p>Applying discounts and building</p>
      <p>your quote</p>
    </div>
  ),
};

export default metadata;
