import { Button } from '@ecp/components';
import type { ButtonVariant } from '@ecp/components';
import { getChatEnabled, openMessenger } from '@ecp/features/shared/chat';

interface Props {
  className?: string;
  displayText: string;
  trackingLabel?: string;
  trackingName?: string;
  variant: ButtonVariant;
}

/** This link triggers the Genesys Chat Messenger to open programmatically on our pages. */
export const ChatHelp: React.FC<Props> = (props) => {
  const { className, displayText, trackingLabel, trackingName, variant } = props;

  if (!getChatEnabled()) return null;

  return (
    <Button
      className={className}
      trackingLabel={trackingLabel}
      trackingName={trackingName}
      onClick={openMessenger}
      variant={variant}
    >
      {displayText}
    </Button>
  );
};
