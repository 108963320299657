import { PhoneLink } from '@ecp/components';
import { Dialog } from '@ecp/features/sales/shared/components';
import { partner } from '@ecp/partners';

import { useStyles } from './ProtectedPersonsDisclosureDialog.styles';

interface Props {
  open: boolean;
  onClose(): void;
  trackingName?: string;
  trackingLabel?: string;
}

export const ProtectedPersonsDisclosureDialog: React.FC<Props> = (props) => {
  const { open, onClose, trackingName, trackingLabel } = props;
  const { classes } = useStyles();

  return (
    <Dialog
      actionButtonLabel='Close'
      titleText='Protected Persons'
      open={open}
      onClose={onClose}
      trackingNameButton={trackingName}
      trackingLabelButton={trackingLabel}
      trackingLabelCloseIcon='X'
      trackingNameCloseIcon='ProtectedPersonsDisclosure_X'
      buttonPlacement='right'
    >
      <p className={classes.paddingBetween}>
        We may collect information from sources other than you or someone else named in your
        application. This information may include confidential abuse information; that is,
        confidential information regarding acts of domestic abuse; the work, home, school, or other
        address or telephone number of a victim of domestic abuse; an applicant’s or insured’s
        status as a victim of domestic abuse; or their status as a family member, employer or
        associate of a victim of domestic abuse or a person with whom an applicant is known to have
        a direct, close personal, family, or abuse-related counseling relationship.
      </p>
      <p className={classes.paddingBetween}>
        All insurance companies are prohibited by law from using anyone’s confidential abuse status
        as a basis for denying coverage; refusing to issue, renew, or reissue a policy; canceling or
        otherwise terminating a policy; restricting or excluding policy coverage or benefits; or
        charging a higher premium for a policy.
      </p>
      <p className={classes.paddingBetween}>
        A protected person has a right to request access to any confidential abuse information we
        have about them in our records or files. A protected person also has a right to request that
        we correct, amend, or delete any such information we have about them in our records or
        files. A notice detailing our confidential abuse information practices is available on
        request.
      </p>
      <p className={classes.paddingBetween}>
        To request more information please call us at{' '}
        <PhoneLink key='b' withUnderlinedLinkStyle number={partner.shared.servicePhoneNumber} />.
      </p>
    </Dialog>
  );
};
