import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CheckoutSidebar' })((theme) => ({
  root: {
    ...theme.mixins.withRightSidebar.sidebarContainer,
    '@supports (position: sticky)': {
      ...theme.mixins.withRightSidebar.sidebarContainer['@supports (position: sticky)'],
      top: 90,
    },
  },
  checkoutCard: {
    paddingTop: 0,
  },
}));
