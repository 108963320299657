import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

export const PartnerAutoPolicyQuestion: React.FC<QuestionProps> = (props) => {
  const {
    label = 'Do you have auto insurance with us? By bundling your policies, your savings could be up to 10%!',
    trackingName = 'AutoInsuranceWithConnectButton',
  } = props;

  const partnerAutoPolicy = useField('discount.partnerAutoPolicy');

  // TODO This needs to be dafulted on SAPI side
  useInitValues({ [partnerAutoPolicy.key]: true });

  useAddFields({ partnerAutoPolicy });

  if (!partnerAutoPolicy.exists) return null;

  return (
    <RadioGroupWithOptions
      {...partnerAutoPolicy.props}
      label={label}
      id={partnerAutoPolicy.props.name}
      variant='yesNoButton'
      trackingName={trackingName}
    />
  );
};
