import { useCallback, useState } from 'react';

import { STATE_CODE_PREFIX } from './constants';
import { fetchAptSugestions, fetchSuggestions } from './geoApi';
import type { GeoAddress } from './types';

interface GeoAddressOptions {
  addressSuggestions: GeoAddress[];
  handleSuggestionsFetchRequested(
    input: string,
    zipcode: string,
    state: string,
  ): Promise<GeoAddress[]>;
  handleSuggestionsClearRequested(): void;
  handleAptSuggestionFetchRequested(
    searchValue: string,
    selectedValue: string,
  ): Promise<GeoAddress[]>;
  selectedApt: boolean;
}

/** This custom hook supports autocomplete component for address selections */
export const useGeoAddressOptions = (): GeoAddressOptions => {
  const [addressSuggestions, setAddressSuggestions] = useState<GeoAddress[]>([]);
  const [selectedApt, setSelectedApt] = useState(false);
  const POBoxIndicator = 'PO Box';

  const handleAptSuggestionFetchRequested = useCallback(
    async (searchValue: string, selectedValue: string): Promise<GeoAddress[]> => {
      const aptSuggestion = (await fetchAptSugestions({ searchValue, selectedValue })) || [];
      setAddressSuggestions(aptSuggestion);
      setSelectedApt(true);
      // Adding in default ("Use as typed") suggestion to end of array aptSuggestion
      aptSuggestion.push({
        street_line: searchValue,
        secondary: '',
        city: '',
        state: '',
        zipcode: '',
        entries: 0,
      });

      return aptSuggestion;
    },
    [setAddressSuggestions, setSelectedApt],
  );

  const handleSuggestionsFetchRequested = useCallback(
    async (value: string, zipcode: string, state: string): Promise<GeoAddress[]> => {
      const suggestions =
        (await fetchSuggestions({
          value,
          zipcode,
          state: state.replace(STATE_CODE_PREFIX, ''),
        })) || [];

      // Because we do not support PO Box, we are removing if from suggestions.
      const output = suggestions.filter((e) => !e.street_line.includes(POBoxIndicator));

      // Adding in default ("Use as typed") suggestion to end of array output
      output.push({
        street_line: value,
        secondary: '',
        city: '',
        state: '',
        zipcode: '',
        entries: 0,
      });
      setAddressSuggestions(output);
      setSelectedApt(false);

      return output;
    },
    [setAddressSuggestions, setSelectedApt],
  );

  const handleSuggestionsClearRequested = useCallback((): void => {
    setAddressSuggestions([]);
  }, [setAddressSuggestions]);

  return {
    addressSuggestions,
    handleSuggestionsFetchRequested,
    handleSuggestionsClearRequested,
    handleAptSuggestionFetchRequested,
    selectedApt,
  };
};
