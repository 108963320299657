import { FormHelperText, Grid } from '@mui/material';

import { castToBoolean } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import type { CheckboxProps } from '@ecp/components';
import { Checkbox } from '@ecp/features/sales/shared/components';
import type { Field } from '@ecp/types';

import { useStyles } from './IAgreeCheckbox.styles';

export interface Props {
  acknowledgementText: string;
  field: Field;
  checkboxSubText?: string;
}

export const IAgreeCheckbox: React.FC<Props> = (props) => {
  const { acknowledgementText, field } = props;
  const {
    props: { name, value, error, actionOnChange },
  } = field;
  const { classes } = useStyles();
  const handleCheckboxAcknowledgementChangeCheck = useEvent<NonNullable<CheckboxProps['onChange']>>(
    (_, checked) => {
      actionOnChange(String(checked));
    },
  );

  return (
    <Grid
      container
      className={classes.checkboxRoot}
      role='group'
      alignItems='flex-start'
      wrap='nowrap'
    >
      <Checkbox
        name={name}
        value={value}
        checked={castToBoolean(value)}
        onChange={handleCheckboxAcknowledgementChangeCheck}
        trackingName='CustomerAgreementCheckbox'
        trackingLabel='IAgreeAndRead'
        inputProps={{
          'aria-label': 'Customer Agreement Checkbox',
        }}
        title=''
      />
      <ul className={classes.verticalAlign}>
        <li>{acknowledgementText}</li>
        {error && (
          <li>
            <FormHelperText error role='alert'>
              {error}
            </FormHelperText>
          </li>
        )}
      </ul>
    </Grid>
  );
};
