import { useCallback, useEffect, useRef, useState } from 'react';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { upperFirst } from '@ecp/utils/common';

import {
  OFFER_PRODUCTS_SELECTED,
  PRODUCT_LOB_USER_SELECTION,
} from '@ecp/features/sales/shared/constants';
import {
  describeOfferSummary,
  getLineOfBusiness,
  getLineOfBusinessUserSelection,
  getOffersForSelectedLob,
  handlePremiumPlanSelection,
  updateAnswers,
} from '@ecp/features/sales/shared/store';
import type { ThunkAction } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { LineOfBusinessUnbundled } from '@ecp/features/shared/product';
import {
  getLineOfBusinessUnbundledFromLineOfBusiness,
  getProductNameFromUnbundledLob,
  getProductNamesFromLob,
  isLineOfBusinessBundle,
  LineOfBusiness,
} from '@ecp/features/shared/product';

const preferredLineOfBusinessUserSelectionOrder: LineOfBusinessUnbundled[] = [
  LineOfBusiness.AUTO,
  LineOfBusiness.HOME,
  LineOfBusiness.RENTERS,
];

const isBottomOfTheFunnelWithinTopOfTheFunnel = (
  bottom: LineOfBusiness,
  top: LineOfBusiness,
): boolean => {
  if (bottom === top) return true;

  const lineOfBusinessUnbundled = getLineOfBusinessUnbundledFromLineOfBusiness(top);

  return lineOfBusinessUnbundled.includes(bottom);
};

const useDetermineLineOfBusinessUserSelection = (): LineOfBusiness => {
  const defaultSelection = useSelector(getLineOfBusiness);
  const offersForSelectedLob = useSelector(getOffersForSelectedLob);
  const currentSelection = useSelector(getLineOfBusinessUserSelection);

  // Start with current user selection on the Quote Summary page
  let pitchedSelection = currentSelection;

  // Fall back to initial selection on the Landing page or via Add/Remove product
  if (
    !pitchedSelection ||
    !isBottomOfTheFunnelWithinTopOfTheFunnel(pitchedSelection, defaultSelection)
  )
    pitchedSelection = defaultSelection;

  // Check if respective offer is available and return pitched value if it is
  if (isLineOfBusinessBundle(pitchedSelection)) {
    if (offersForSelectedLob?.bundle) return pitchedSelection;
  } else {
    const productName = getProductNameFromUnbundledLob(pitchedSelection);
    if (offersForSelectedLob?.[productName]) return pitchedSelection;
  }

  // Otherwise check in the order of preference if respective offer is available and return respective line of business
  pitchedSelection = preferredLineOfBusinessUserSelectionOrder.find((lineOfBusiness) => {
    const productName = getProductNameFromUnbundledLob(lineOfBusiness);

    return offersForSelectedLob?.[productName];
  });

  return pitchedSelection || defaultSelection;
};

interface ProductOption {
  label: 'Auto only' | 'Home only' | 'Renters only' | 'Auto + Home' | 'Auto + Renters';
  value: LineOfBusiness;
}

const updateUserSelection =
  (
    newSelection: LineOfBusiness,
    previousSelection: React.MutableRefObject<LineOfBusiness | undefined>,
    offers: ReturnType<typeof getOffersForSelectedLob>,
  ): ThunkAction<Promise<void>> =>
  async (dispatch) => {
    const { offerProductsSelected } = describeOfferSummary(offers, newSelection);
    const answers = {
      [PRODUCT_LOB_USER_SELECTION]: newSelection,
      [OFFER_PRODUCTS_SELECTED]: offerProductsSelected,
    };
    await dispatch(updateAnswers({ answers }));
    previousSelection.current = newSelection;
  };

export const useQuotesSelection = (): {
  handleChangeLineOfBusinessUserSelection: (newSelection: LineOfBusiness) => Promise<void>;
  lineOfBusinessUserSelection: LineOfBusiness;
  tabOptions: ProductOption[];
  isUserSelectionUpdating: boolean;
} => {
  const [isUserSelectionUpdating, setUserSelectionUpdating] = useState<boolean>(true);
  const dispatch = useDispatch();
  const offersForSelectedLob = useSelector(getOffersForSelectedLob);
  const lineOfBusinessUserSelection = useDetermineLineOfBusinessUserSelection();
  const lineOfBusinessUserSelectionPrevious = useRef();

  // Update user selection each time new value is determined
  useEffect(() => {
    if (lineOfBusinessUserSelection !== lineOfBusinessUserSelectionPrevious.current) {
      dispatch(
        updateUserSelection(
          lineOfBusinessUserSelection,
          lineOfBusinessUserSelectionPrevious,
          offersForSelectedLob,
        ),
      ).then(() => {
        setUserSelectionUpdating(false);
      });
    } else {
      setUserSelectionUpdating(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineOfBusinessUserSelection]);

  const handleChangeLineOfBusinessUserSelection = useCallback(
    async (newSelection: LineOfBusiness) => {
      dispatch(
        updateUserSelection(
          newSelection,
          lineOfBusinessUserSelectionPrevious,
          offersForSelectedLob,
        ),
      );
      dispatch(
        handlePremiumPlanSelection({
          offers: offersForSelectedLob,
          selectedLob: newSelection,
        }),
      );

      const label = isLineOfBusinessBundle(newSelection)
        ? 'Bundle'
        : upperFirst(getProductNamesFromLob(newSelection)[0]);

      trackClick({ action: 'product_selection_tab', label });
    },
    [dispatch, offersForSelectedLob],
  );

  const tabOptions: ProductOption[] = [];

  // Tabs always need to be shown as we might be quoting bundle but get only monoline offers back
  if (offersForSelectedLob) {
    if (offersForSelectedLob.auto)
      tabOptions.push({ label: 'Auto only', value: LineOfBusiness.AUTO });
    if (offersForSelectedLob.home)
      tabOptions.push({ label: 'Home only', value: LineOfBusiness.HOME });
    if (offersForSelectedLob.renters)
      tabOptions.push({ label: 'Renters only', value: LineOfBusiness.RENTERS });
    if (offersForSelectedLob.bundle) {
      if (offersForSelectedLob.home)
        tabOptions.push({ label: 'Auto + Home', value: LineOfBusiness.BUNDLE });
      if (offersForSelectedLob.renters)
        tabOptions.push({ label: 'Auto + Renters', value: LineOfBusiness.BUNDLE_AUTO_RENTERS });
    }
  }

  return {
    handleChangeLineOfBusinessUserSelection,
    lineOfBusinessUserSelection,
    tabOptions,
    isUserSelectionUpdating,
  };
};
