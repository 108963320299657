import { forwardRef, memo } from 'react';

import { IconUIDriver as IconUIDriverSvg } from '@ecp/themes/base';

import { useStyles } from './DriverIcon.styles';

export interface DriverIconProps {
  className?: string;
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  index?: number;
}

/**
 * A wrapper around driver icon svg applying color, specific to the index prop.
 * So, instead of having a separate icon file per each color, we have one file and this wrapper.
 */
export const DriverIcon = memo(
  forwardRef<HTMLDivElement, DriverIconProps>((props, ref) => {
    const { className } = props;
    const { classes, cx } = useStyles(props, { props });

    return (
      <div ref={ref} className={cx(classes.root, className)}>
        <IconUIDriverSvg className={classes.icon} title='driverIcon' />
      </div>
    );
  }),
);
