import { useCallback, useMemo, useState } from 'react';

import { FormControl, FormLabel, Grid } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { noop } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';

import { GridItem, useShowMoreOrLess } from '@ecp/components';
import type { AnswerValue, Percentages } from '@ecp/features/sales/shared/types';
import type { CardOption, Field, Option } from '@ecp/types';

import { CheckboxGroup } from '../CheckboxGroup';
import { Select } from '../Select';
import { useStyles } from './PercentageGroupSelection.styles';

interface Props {
  percentages: Percentages;
  uiField: Field;
  title: string;
  subtitle: string;
  options: CardOption[];
  percentageOptions: Option[];
  percentageGroupLabel?: React.ReactNode;
  hidePercentageSelected?: boolean;
  onAdd: (key: string, value: string, percentages?: Percentages) => Promise<void>;
  onUpdate: (percentages: Percentages) => Promise<void>;
  onRemove: (key: string, percentages?: Percentages) => Promise<void>;
  optionTrackingName?: string;
  percentTrackingName?: string;
  moreLessTrackingName?: string;
  moreLessTrackingLabel?: (value: string) => string;
  moreLessDataTestId?: string;
  checkBoxGroupTestId?: string;
  maxOptionToSelect?: number;
  sortByKey?: boolean;
}

export const PercentageGroupSelection: React.FC<Props> = (props) => {
  const {
    percentages,
    uiField,
    title,
    subtitle,
    options,
    percentageOptions,
    percentageGroupLabel,
    hidePercentageSelected,
    onAdd,
    onUpdate,
    onRemove,
    optionTrackingName,
    percentTrackingName,
    moreLessTrackingName,
    moreLessTrackingLabel,
    moreLessDataTestId: groupTestId,
    checkBoxGroupTestId = '',
    maxOptionToSelect = 2,
    sortByKey,
  } = props;
  const { classes, cx } = useStyles();

  // cache values locally to improve checkbox response
  const [selectedValues, setSelectedValues] = useState(() => Object.keys(percentages));
  const [localPercentages, setLocalPercentages] = useState(percentages);

  const allOptions: CardOption[] = useMemo(() => options, [options]);
  const selectOptions = useMemo(
    () => percentageOptions.filter((option) => option.label !== '100%'),
    [percentageOptions],
  );

  const { displayOptions, showMore, handleClick } = useShowMoreOrLess(
    allOptions,
    4,
    moreLessTrackingName,
    moreLessTrackingLabel,
    sortByKey,
  );

  const isDisabled = useCallback(
    (value: string): boolean => {
      // const addingOption = displayOptions.find(
      //   ({ value: val }) => selectedValues.includes(val) && !percentages[val],
      // );
      // if (addingOption) return true;

      // const removingOption = displayOptions.find(
      //   ({ value: val }) => !selectedValues.includes(val) && percentages[val],
      // );
      // if (removingOption) return true;

      if (selectedValues.length === maxOptionToSelect && !selectedValues.includes(value)) {
        return true;
      }

      return false;
    },
    [selectedValues, maxOptionToSelect],
  );

  const checkboxGroupOptions = useMemo(() => {
    return displayOptions.map((option) => ({ ...option, disabled: isDisabled(option.value) }));
  }, [displayOptions, isDisabled]);

  const getPercentageValueByLabel = useCallback(
    (value: number): string => {
      const label = `${value}%`;
      const option = percentageOptions.find((o) => o.label === label);
      if (option) return option.value;

      const message = `Not found percentage value for label "${label}"`;
      datadogLog({
        logType: 'error',
        message,
        context: {
          logOrigin:
            'libs/features/sales/shared/components/src/PercentageGroup/PercentageGroupSelection.tsx',
          functionOrigin: 'getPercentageValueByLabel',
        },
      });
      throw new Error(message);
    },
    [percentageOptions],
  );

  const convertLabelToNumber = useCallback((label: string | React.ReactElement): number => {
    if (typeof label === 'string') {
      const labelNumber = Number(label.substring(0, label.length - 1));
      if (!Number.isNaN(labelNumber)) return labelNumber;
    }

    const message = `"${label}" is invalid number`;
    datadogLog({
      logType: 'error',
      message,
      context: {
        logOrigin:
          'libs/features/sales/shared/components/src/PercentageGroup/PercentageGroupSelection.tsx',
        functionOrigin: 'convertLabelToNumber',
      },
    });
    throw new Error(message);
  }, []);

  const getPercentageLabelValue = useCallback(
    (value: string): number => {
      const option = percentageOptions.find((o) => o.value === value);
      if (option) return convertLabelToNumber(option.label);

      const message = `Not found percentage label for value "${value}"`;
      datadogLog({
        logType: 'error',
        message,
        context: {
          logOrigin:
            'libs/features/sales/shared/components/src/PercentageGroup/PercentageGroupSelection.tsx',
          functionOrigin: 'getPercentageLabelValue',
        },
      });
      throw new Error(message);
    },
    [percentageOptions, convertLabelToNumber],
  );

  type SelectedOptions = Array<{ option: string; percentage: number }>;
  const trackChange = useCallback(
    (selectedOptions: SelectedOptions): void => {
      const gaOptions: Record<string, string> = {};
      const gaPercentages: Record<string, string> = {};
      allOptions.forEach((option) => {
        const selectedOption = selectedOptions.find((o) => o.option === option.value);
        if (selectedOption) {
          gaOptions[option.value] = '1';
          gaPercentages[option.value] = selectedOption.percentage.toString();
        } else {
          gaOptions[option.value] = '0';
          gaPercentages[option.value] = '0';
        }
      });
      if (optionTrackingName) {
        trackClick({ action: optionTrackingName, label: JSON.stringify(gaOptions) });
      }
      if (percentTrackingName) {
        trackClick({ action: percentTrackingName, label: JSON.stringify(gaPercentages) });
      }
    },
    [optionTrackingName, percentTrackingName, allOptions],
  );

  const addOption = useCallback(
    async (checkedValues: string[], value: string) => {
      // this logic only support select one or two option(s)
      // TODO support multiple options can be selected
      if (checkedValues.length === 1) {
        const percentageValue = getPercentageValueByLabel(100);
        trackChange([{ option: value, percentage: 100 }]);
        setLocalPercentages({ [value]: percentageValue });
        await onAdd(value, percentageValue);
      } else if (checkedValues.length === 2) {
        const percentageValue = getPercentageValueByLabel(50);
        const firstKey = Object.keys(localPercentages)[0];
        const newState = { [firstKey]: percentageValue, [value]: percentageValue };
        trackChange([
          { option: firstKey, percentage: 50 },
          { option: value, percentage: 50 },
        ]);
        setLocalPercentages(newState);
        await onAdd(value, percentageValue, { [firstKey]: percentageValue }); // 3
      }
    },
    [trackChange, getPercentageValueByLabel, onAdd, localPercentages],
  );

  const removeOption = useCallback(
    async (checkedValues: string[], value: string) => {
      // this logic only support select one or two option(s)
      // TODO support multiple options can be selected
      if (checkedValues.length === 0) {
        setLocalPercentages({});
        trackChange([]);
        await onRemove(value);
      } else if (checkedValues.length === 1) {
        const percentageValue = getPercentageValueByLabel(100);
        const otherKey = Object.keys(localPercentages).find((key) => key !== value);
        if (!otherKey) {
          const message = 'No two options';
          datadogLog({
            logType: 'error',
            message,
            context: {
              logOrigin:
                'libs/features/sales/shared/components/src/PercentageGroup/PercentageGroupSelection.tsx',
              functionOrigin: 'removeOption',
            },
          });
          throw new Error(message);
        }
        trackChange([{ option: otherKey, percentage: 100 }]);
        setLocalPercentages({ [otherKey]: percentageValue });
        await onRemove(value, { [otherKey]: percentageValue });
      }
    },
    [trackChange, onRemove, getPercentageValueByLabel, localPercentages],
  );

  const handleCheckboxGroupChange = useCallback(
    async (checkedValues: string[], valueClicked = '', newChecked?: boolean) => {
      setSelectedValues(checkedValues);
      if (newChecked) await addOption(checkedValues, valueClicked);
      else await removeOption(checkedValues, valueClicked);
      uiField.props.actionOnComplete(checkedValues.join(','));
      // Restore the Keyboard focus to the clicked Checkbox
      if (valueClicked) document.querySelector<HTMLElement>(`[value="${valueClicked}"]`)?.focus();
    },
    [uiField.props, addOption, removeOption],
  );

  const getSelectLabel = useCallback(
    (selectedValue: string) => {
      const option = allOptions.find((o) => o.value === selectedValue);
      if (option) return option.label;

      datadogLog({
        logType: 'error',
        message: `Not found select label for value "${selectedValue}"`,
        context: {
          logOrigin:
            'libs/features/sales/shared/components/src/PercentageGroup/PercentageGroupSelection.tsx',
          functionOrigin: 'getSelectLabel',
        },
      });

      throw new Error(`Not found select label for value "${selectedValue}"`);
    },
    [allOptions],
  );

  const getInferredValue = useCallback(
    (labelValue: number) => {
      const inferredValue = percentageOptions.find((option) => {
        const labelNumber = convertLabelToNumber(option.label);

        return labelValue + labelNumber === 100;
      });
      if (inferredValue) return inferredValue.value;

      const message = `Can not match another value for "${labelValue}"`;
      datadogLog({
        logType: 'error',
        message,
        context: {
          logOrigin:
            'libs/features/sales/shared/components/src/PercentageGroup/PercentageGroupSelection.tsx',
          functionOrigin: 'getInferredValue',
        },
      });
      throw new Error(message);
    },
    [percentageOptions, convertLabelToNumber],
  );

  const handlePercentageOnChange = useCallback(
    async (key: string, valueStr: string) => {
      const otherKey = Object.keys(localPercentages).find((k) => k !== key);
      if (Object.keys(localPercentages).length !== 2 || !otherKey) {
        const message = 'No two options';
        datadogLog({
          logType: 'error',
          message,
          context: {
            logOrigin:
              'libs/features/sales/shared/components/src/PercentageGroup/PercentageGroupSelection.tsx',
            functionOrigin: 'handlePercentageOnChange',
          },
        });
        throw new Error(message);
      }

      const value = getPercentageLabelValue(valueStr);
      const otherValueStr = getInferredValue(value);
      const otherValue = getPercentageLabelValue(otherValueStr);
      const newState = { [key]: valueStr, [otherKey]: otherValueStr };
      trackChange([
        { option: key, percentage: value },
        { option: otherKey, percentage: otherValue },
      ]);
      setLocalPercentages(newState);
      await onUpdate(newState);
    },
    [localPercentages, getPercentageLabelValue, getInferredValue, trackChange, onUpdate],
  );

  const handleActionOnChange = useCallback(
    (selectedValue: string) => (value: AnswerValue) =>
      handlePercentageOnChange(selectedValue, value as string),
    [handlePercentageOnChange],
  );

  const percentageSelections = useMemo(() => {
    if (selectedValues.length <= 1 || !!hidePercentageSelected) return null;

    return (
      <Grid container>
        {selectedValues.map((selectedValue, index) => {
          return (
            <GridItem
              topSpacing='xs'
              xs={12}
              md={6}
              className={cx(index % 2 ? classes.columnRight : classes.columnLeft)}
              key={selectedValue}
            >
              <Select
                options={selectOptions}
                value={localPercentages[selectedValue]}
                id={`static.${selectedValue}`}
                label={getSelectLabel(selectedValue)}
                actionOnChange={handleActionOnChange(selectedValue)}
                actionOnComplete={noop}
                disabled={isDisabled(selectedValue)}
              />
            </GridItem>
          );
        })}
      </Grid>
    );
  }, [
    classes.columnLeft,
    classes.columnRight,
    cx,
    getSelectLabel,
    handleActionOnChange,
    hidePercentageSelected,
    isDisabled,
    localPercentages,
    selectOptions,
    selectedValues,
  ]);

  return (
    <>
      <CheckboxGroup
        {...uiField.props}
        label={title}
        helperText={subtitle}
        values={selectedValues}
        options={checkboxGroupOptions}
        actionOnComplete={handleCheckboxGroupChange}
        showMoreOrLessProps={{ showMore, onClick: handleClick, groupTestId }}
        dataTestIdPrefix={checkBoxGroupTestId}
      />
      {selectedValues.length > 1 && !hidePercentageSelected ? (
        <FormControl component='fieldset'>
          <FormLabel
            className={classes.percentageGroupLabel}
            component='legend'
            error={false}
            focused={false}
          >
            {percentageGroupLabel}
          </FormLabel>
          {percentageSelections}
        </FormControl>
      ) : (
        percentageSelections
      )}
    </>
  );
};
