import { Divider, Grid } from '@mui/material';

import { upperFirst } from '@ecp/utils/common';

import { getOfferProductsSelected } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';
import { getProductDisplayNameFromProduct } from '@ecp/features/shared/product';

import { getAnyProductHasCheckboxAcknowledgements } from '../../../state/acknowledgements';
import { useStyles } from './ContactUsDialog.styles';

const getPolicyText = (products: Product[]): string => {
  const productNames = products.map(getProductDisplayNameFromProduct);

  if (productNames.length === 0) return 'insurance policy';
  if (productNames.length === 1) return `${productNames[0]} insurance policy`;

  return `${upperFirst(productNames.join(' and '))} insurance policies`;
};

export const ContactUsDialog: React.FC = () => {
  const { classes } = useStyles();

  const anyProductHasCheckboxAcknowledgements = useSelector(
    getAnyProductHasCheckboxAcknowledgements,
  );
  const offerProductsSelected = useSelector(getOfferProductsSelected);
  const policyText = getPolicyText(offerProductsSelected);

  const contentBody = (
    <>
      <p className={classes.body}>
        We can't issue your {policyText} online unless you agree to the statements on the previous
        page.
      </p>
      <p className={classes.body}>
        Go back to agree to the statements listed, or call to speak to an insurance expert who can
        answer your questions
      </p>
    </>
  );

  const fullScreenContent = (
    <Grid container justifyContent='center'>
      <Grid item xs={12} className={classes.dialogContainer}>
        <h1>Our agents are here if you need us.</h1>
        <Divider aria-hidden='true' className={classes.dialogDivider} />
        {contentBody}
      </Grid>
    </Grid>
  );

  if (anyProductHasCheckboxAcknowledgements) return fullScreenContent;
  else return contentBody;
};
