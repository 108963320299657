/**
 * Avoid naming it "waitFor" to avoid the confusion with waitFor from testing-library.
 * @param args.condition - a callback function to wait until it returns a truthy value
 * @param args.name - used in the console output if the time limit elapsed
 * @param args.limit - time limit to wait
 * @param args.interval - interval to re-check condition
 * @returns condition callback return result
 */
export function waitForCondition<T>(args: {
  condition: () => T;
  name?: string;
  limit?: number;
  interval?: number;
}): Promise<T | undefined> {
  return new Promise((resolve) => {
    const { condition, name = 'unnamed', limit = 3000, interval = 50 } = args;

    let timeout: ReturnType<typeof setTimeout>;
    const startTime = +new Date();
    const check = (): void => {
      const result = condition();
      if (result) {
        clearTimeout(timeout);
        resolve(result);

        return;
      }

      const elapsed = +new Date() - startTime;
      if (elapsed > limit) {
        console.warn(`wait for condition ${name} timed out after ${elapsed}ms`);
        clearTimeout(timeout);
        resolve(undefined);

        return;
      }

      timeout = setTimeout(check, interval);
    };
    check();
  });
}
