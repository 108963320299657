import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { OfferStatusCode } from '@ecp/features/sales/shared/constants';
import {
  describeOfferSummary,
  getErroredProductsFromOffers,
  getOffers,
  getOffersForSelectedLob,
  isOfferMVRActive,
} from '@ecp/features/sales/shared/store';
import type { Offers, RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';

import { useQuotesSelection } from '../../views/Quotes/QuotesTabs/useQuotesSelection';
import type { DeclinationData } from './types';

export const createDeclinationData = (product: Product, offers: Offers): DeclinationData => {
  const declinationData: DeclinationData = {
    extendedMessage: undefined,
    quoteNumber: undefined,
  };

  if (product && offers[product]) {
    const eligibilityReason = offers[product]?.eligibility?.reasons?.find(
      (reason) => reason.extendedMessage,
    );

    if (eligibilityReason) {
      declinationData.extendedMessage = eligibilityReason.extendedMessage;
    }
    declinationData.quoteNumber = offers[product]?.details?.quoteNumber;
  }

  return declinationData;
};

export const getDeclinationData =
  (product: Product) =>
  (state: RootStore): DeclinationData => {
    const offers = getOffers(state);

    return createDeclinationData(product, offers);
  };

export const useDeclinationNotices = (): {
  hasDeclinationNotices: boolean;
  isMVRActive: boolean;
  erroredProducts: Product[];
} => {
  const erroredProducts = useSelector(getErroredProductsFromOffers);
  const { lineOfBusinessUserSelection: selectedLob } = useQuotesSelection();
  const offersForSelectedLob = useSelector(getOffersForSelectedLob);
  const { autoBundledOffer, autoOnlyOffer } = describeOfferSummary(
    offersForSelectedLob,
    selectedLob,
  );
  const isMVRActive = isOfferMVRActive({ autoOnlyOffer, autoBundledOffer, selectedLob });
  const hasDeclinationNotices = Boolean(!isMVRActive || erroredProducts.length);

  return { hasDeclinationNotices, isMVRActive, erroredProducts };
};

/**
 * Util function to determine if the midvale auto offer has a retrieve error caused by the product switch.
 *
 * This is targeted to different states based of the feature flag "AUTO_PRODUCT_SWAP_STATE_ROLLOUT"
 *
 * @returns boolean
 */
export const useDetermineAutoProductSwitchError = (): boolean => {
  const offers = useSelector(getOffers);
  const isAutoProductSwapStateRollout = flagValues[FeatureFlags.AUTO_PRODUCT_SWAP_STATE_ROLLOUT];
  const hasAutoProductSwitchError = Object.values(offers || {}).some((offer) => {
    return (
      offer.eligibility.statusCode === OfferStatusCode.ERROR &&
      offer.eligibility.reasons &&
      !!offer.eligibility.reasons?.find((reason) => reason.code === 'AUTO_SWITCH_RE_QUOTE')
    );
  });

  return !!hasAutoProductSwitchError && isAutoProductSwapStateRollout;
};

export const getOfferErrors = (
  state: RootStore,
): Record<'shouldShowHO2Guidance' | 'isDNQError' | 'isCNQError', boolean> => {
  const offers = Object.values(getOffers(state) || {});
  const shouldShowHO2Guidance = offers.some(
    (offer) =>
      (offer.eligibility.statusCode === OfferStatusCode.DNQ ||
        offer.eligibility.statusCode === OfferStatusCode.CNQ) &&
      offer.eligibility.reasons?.map(({ message }) => message).toString() === 'HO2 Quote',
  );
  const isDNQError = offers.some((offer) => offer.eligibility.statusCode === OfferStatusCode.DNQ);
  const isCNQError = offers.some((offer) => offer.eligibility.statusCode === OfferStatusCode.CNQ);

  return { shouldShowHO2Guidance, isDNQError, isCNQError };
};
