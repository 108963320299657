import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'Popover' })((theme) => ({
  pointer: {
    overflowX: 'unset',
    overflowY: 'unset',
    marginTop: 15,

    '&:before': {
      content: '""',
      position: 'absolute',
      marginRight: '-0.71em',
      top: -20,
      left: 95,
      width: 20,
      height: 20,
      backgroundColor: 'white',
      transform: 'translate(-50%, 50%) rotate(135deg)',
      clipPath: 'inset(0px 0px -10px -10px)',
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      borderLeft: `1px solid ${theme.palette.grey[200]}`,
    },
    [theme.breakpoints.down('md')]: {
      overflowX: 'auto',
      overflowY: 'auto',
      '&:before': {
        top: -20,
        left: 195,
        width: 20,
        height: 20,
      },
    },
    '@media only screen and (min-device-width: 376px) and (max-device-width: 767px)': {
      '&:before': {
        top: -20,
        left: 146,
        width: 20,
        height: 20,
      },
    },
    [theme.breakpoints.up('md')]: {
      '&:before': {
        top: -20,
        left: 100,
        width: 20,
        height: 20,
      },
    },
  },
  popoverContainer: {
    padding: 20,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    marginRight: 15,
  },
  titleDivider: {
    backgroundColor: theme.palette.other.divider,
    margin: '15px 0 10px',
    width: '100%',
  },
  closeButton: {
    ...theme.mixins.setColorSvg(theme.palette.primary.main),
    height: 'auto',
    marginLeft: 'auto',
    minWidth: 'auto',
  },
  warningImage: {
    width: 25,
    height: 25,
    marginRight: 10,
  },
  warningContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.warning.light,
    padding: 15,
  },
  warningCloseButton: {
    alignSelf: 'flex-start',
    minWidth: 'auto',
    paddingLeft: 15,
    marginLeft: 'auto',
    height: 22,
  },
  warningCloseIcon: {
    height: 14,
    width: 14,
    ...theme.mixins.setColorSvg(theme.palette.text.primary),
  },
}));
