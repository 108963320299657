import { FormHelperText } from '@mui/material';

import { GridItem } from '@ecp/components';
import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { Checkbox } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { PropertyProduct } from '@ecp/features/shared/product';
import { getProductNameFromProduct } from '@ecp/features/shared/product';

import {
  getPaymentPlanForProduct,
  getPaymentTypeForProduct,
} from '../../../../state/paymentoptions';
import { useStyles } from './AutoWithdrawAgree.styles';
import metadata from './metadata';
import type { AcknowledgementFunction } from './metadata/metadata';

interface Props extends QuestionProps {
  product: PropertyProduct;
}

const PAYMENT_TYPES_REQUIRING_AGREEMENT = new Set(['EFT', 'CreditCard', 'CostcoVisa']);

const isAcknowledgementFunction = (
  value: string | AcknowledgementFunction | undefined,
): value is AcknowledgementFunction => typeof value === 'function';

export const AutoWithdrawAgree: React.FC<Props> = (props) => {
  const { product, ...rest } = props;
  const { classes } = useStyles();
  const productName = getProductNameFromProduct(product);
  const paymentPlan = useSelector((state: RootStore) => getPaymentPlanForProduct(state, product));
  const productPaymentType = useSelector((state: RootStore) =>
    getPaymentTypeForProduct(state, product),
  );

  const isAgreementRequired = PAYMENT_TYPES_REQUIRING_AGREEMENT.has(productPaymentType);
  const isMakePaymentsSelected = paymentPlan !== 'Full';

  const autoWithdrawAgreeKey = `static.checkout.${productName}.autoWithdrawAgree`;
  const autoWithdrawAgree = useField(autoWithdrawAgreeKey);
  const customerAcknowledgementKey = `static.checkout.${productName}.customerAcknowledgement`;
  const customerAcknowledgement = useField(customerAcknowledgementKey);
  useAddFields({
    [autoWithdrawAgreeKey]: autoWithdrawAgree,
    [customerAcknowledgementKey]: customerAcknowledgement,
  });

  useAddConditionValues({
    conditionalFields: [customerAcknowledgement],
    isExcluded: () => isMakePaymentsSelected || !agreementIsRequiredAndExists,
  });
  useAddConditionValues({
    conditionalFields: [autoWithdrawAgree],
    isExcluded: () => !isMakePaymentsSelected || !agreementIsRequiredAndExists,
  });

  const agreement =
    productPaymentType &&
    paymentPlan &&
    metadata[product]?.[productPaymentType]?.[paymentPlan !== 'Full' ? 'default' : paymentPlan];

  const getAgreement: AcknowledgementFunction | undefined = isAcknowledgementFunction(agreement)
    ? agreement
    : undefined;

  const agreementIsRequiredAndExists = getAgreement && isAgreementRequired;

  const field = isMakePaymentsSelected ? autoWithdrawAgree : customerAcknowledgement;

  if (!field.exists || !agreementIsRequiredAndExists) return null;

  return (
    <GridItem>
      <GridItem className={classes.root}>
        <Checkbox
          actionOnComplete={field.props.actionOnComplete}
          checked={field.props.value || false}
          data-testid='autoWithdrawAgree'
          id={field.props.name}
          value
          // TODO Analytics TBD
          // trackingName='ADROptIn'
          {...rest}
        />
        <p className={classes.agreement}>{getAgreement()}</p>
      </GridItem>
      {field.props.error && (
        <FormHelperText role='alert' error>
          {field.props.error}
        </FormHelperText>
      )}
    </GridItem>
  );
};
