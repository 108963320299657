import { memo } from 'react';

import { Grid } from '@mui/material';

import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { GridItem } from '@ecp/components';
import { usePostBindField } from '@ecp/features/sales/shared/store';
import type { Product } from '@ecp/features/shared/product';

import { PostBindContactInformationQuestion } from '../../../components/Question/PostBindContactInformation';
import { useStyles } from './ContactInformationFormQuestions.styles';
import { getAutoProductContactInfoConfig } from './PostBindContactInformation.metadata';

// Define the props type
interface ContactInformationFormQuestionsProps {
  products: Product[];
}

export const ContactInformationFormQuestions: React.FC<ContactInformationFormQuestionsProps> = memo(
  (props) => {
    const { classes } = useStyles();

    const mailingAddressLine1 = usePostBindField('mailing.address.line1');
    const checkoutPageContactInfoRedesign = flagValues[FeatureFlags.CHECKOUT_CONTACT_INFORMATION];
    if (
      getAutoProductContactInfoConfig(props.products) ||
      checkoutPageContactInfoRedesign ||
      !mailingAddressLine1.exists
    ) {
      return null;
    }

    return (
      <Grid container className={classes.informationCard}>
        <GridItem className={classes.headerContainer}>
          <Grid item container>
            <Grid item xs={12}>
              <h3 className={classes.headerContainerTitle}>Contact information</h3>
            </Grid>
            <Grid item xs={12}>
              <p>
                Please confirm your mailing address. We&apos;ll send any policy related documents
                here.
              </p>
            </Grid>
            <Grid item xs={12}>
              <PostBindContactInformationQuestion />
            </Grid>
          </Grid>
        </GridItem>
      </Grid>
    );
  },
);
