import { useMemo } from 'react';

import { Divider, Grid, Link } from '@mui/material';

import { GoogleAnalyticsLabels, trackClick } from '@ecp/utils/analytics/tracking';
import { isTruthy } from '@ecp/utils/common';
import { useLocation } from '@ecp/utils/routing';

import { PagePath } from '@ecp/features/sales/shared/routing';

import { useStyles } from './AppFooter.styles';
import metadata from './metadata';

const handleFooterClick: React.MouseEventHandler = (event) => {
  const action = event.currentTarget.getAttribute('data-action');
  const label = (event.target as Element).getAttribute('data-label');
  if (action && label) trackClick({ action, label });
};

export const AppFooter: React.FC = () => {
  const { classes, cx } = useStyles();
  const location = useLocation();
  const isCheckoutPage = PagePath.CHECKOUT.includes(location.pathname);
  const isCovOrRatingCriteriaPage = [PagePath.RATING_CRITERIA, PagePath.COVERAGES].includes(
    location.pathname,
  );

  const links = useMemo(
    () =>
      [
        {
          href: PagePath.PRIVACY_POLICY,
          label: 'PrivacyPolicy',
          text: 'Privacy Policy',
          trackingName: 'privacy_policy_link',
          trackingLabel: 'privacy_policy',
        },
        {
          href: PagePath.TERMS_OF_USE,
          label: 'TermsOfUse',
          text: 'Terms of Use',
          trackingName: 'terms_of_use_link',
          trackingLabel: 'terms_of_use',
        },
        {
          href: PagePath.PARTICIPATING_INSURERS,
          label: 'ParticipatingInsurers',
          text: 'Participating Insurers',
          trackingName: 'participating_insurers_link',
          trackingLabel: 'participating_insurers',
        },
      ].filter(isTruthy),
    [],
  );

  const linksSection = (
    <div className={classes.linksWrapper}>
      {links.map((link, index) => (
        <div className={classes.sectionItem} key={link.href}>
          <Link
            className={classes.links}
            data-label={link.label}
            href={link.href.replace(/^\//, '')}
            target='_blank'
            title={link.label}
          >
            {link.text}
          </Link>
          {index < links.length - 1 ? (
            <Divider orientation='vertical' className={classes.linksDivider} />
          ) : null}
        </div>
      ))}
    </div>
  );

  return (
    <footer
      className={cx(
        classes.root,
        isCheckoutPage && classes.checkoutFooter,
        isCovOrRatingCriteriaPage && classes.coverageFooter,
      )}
      data-action={GoogleAnalyticsLabels.FOOTER}
      onClick={handleFooterClick}
      role='contentinfo'
    >
      <Grid container spacing={0} direction='column' justifyContent='center' alignItems='center'>
        <p className={classes.copyrightText}>{metadata.copyrightTextElement}</p>
        {linksSection}
        <div className={classes.disclaimerText}>{metadata.disclaimerTextElement}</div>
      </Grid>
    </footer>
  );
};
