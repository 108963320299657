import { useEffect } from 'react';

import * as interactionId from '@ecp/utils/analytics/interaction-id';
import { isTruthy } from '@ecp/utils/common';
import { useLocation } from '@ecp/utils/routing';

import { env } from '@ecp/env';
import {
  getDalSessionId,
  getFullPniPersonInfo,
  getPrimaryInsuredAddressInfo,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { CustomAttributes } from '@ecp/features/shared/chat';
import { addCustomAttributes } from '@ecp/features/shared/chat';

const useGetCustomerReference = (): string => useSelector(getDalSessionId) || '';

const useGetCustomerInfo = (): string => {
  const location = useLocation();
  const { state } = useSelector(getPrimaryInsuredAddressInfo);
  const page = location.pathname;
  const product = env.product;
  const visitInfo = [state, page, product].filter(isTruthy).join('~');

  return visitInfo;
};

const useGetCustomerAttributes = (): {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
} => {
  const pni = useSelector(getFullPniPersonInfo);

  return { firstName: pni.firstName, lastName: pni.lastName, email: pni.email, phone: pni.phone };
};

/**
 * update Genesys custom attributes when values of which are available or changed.
 */
export const useAttributes = (): void => {
  const customerInfo = useGetCustomerInfo();
  const customerReference = useGetCustomerReference();
  const { firstName, lastName } = useGetCustomerAttributes();
  const afeInteractionId = interactionId.get();

  useEffect(() => {
    const newAttributes: CustomAttributes = {
      customerInfo,
      customerReference,
      firstName,
      lastName,
      afeInteractionId,
    };

    addCustomAttributes(newAttributes);
  }, [firstName, lastName, customerInfo, customerReference, afeInteractionId]);
};
