import { scrollToElement } from '@ecp/utils/web';

import type { Field } from '@ecp/types';

/** @deprecated To be removed in favor of generic error scrolling inside getForm -> validateForm. */
export function goToFirstError(): boolean {
  const firstInvalid = document.querySelector('[class*="Mui-error"]');
  if (firstInvalid) {
    setTimeout(() => {
      scrollToElement({ element: firstInvalid });
    }, 0);

    return true;
  }

  return false;
}

/** @deprecated To be removed in favor of generic error scrolling inside getForm -> validateForm. */
export function goToErrorByQuestionKey(key: string): void {
  setTimeout(() => {
    const firstInvalidLabel = document.querySelector(`[id*="${key}"][id$="-label"]`);
    if (firstInvalidLabel) scrollToElement({ element: firstInvalidLabel });
  }, 0);
}

export const clearFields = (...fields: Field[]): void => {
  fields.forEach((field) => field.reset());
};

// FIXME: we should use a class for Field and Fields?
// TODO This must live in forms util
export function isField(obj: unknown): obj is Field {
  return !!(
    obj &&
    typeof obj === 'object' &&
    'validate' in obj &&
    'update' in obj &&
    'props' in obj
  );
}
