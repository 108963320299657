import { useCallback } from 'react';

import { isMasked } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';

import {
  deleteAnswers,
  getFieldErrors,
  setFormErrorsChangedByField,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useStore } from '@ecp/features/sales/shared/store/utils';
import type { Vehicle } from '@ecp/features/sales/shared/types';
import type { Field } from '@ecp/types';

import { fetchVehicleByVinNumber } from '../../api';
import type { VehicleInfoResults } from '../../api/vehicle/vehicleApi';
import { VIN_REGEX_FULL } from '../../constants';

export const validateVinFormat = (value: string, isRequired?: boolean): string | undefined => {
  if (!isMasked(value)) {
    if (value?.length > 17) {
      return 'Please enter 17 digits';
    }
    if (value) {
      return VIN_REGEX_FULL.test(value) ? undefined : 'Please enter a valid VIN';
    }
    if (isRequired) return 'Required field';
  }

  return undefined;
};

export const validateVinFormatRegex = (value: string): string | undefined => {
  if (value && !isMasked(value)) {
    return VIN_REGEX_FULL.test(value) ? undefined : 'Please enter a valid VIN';
  }
  if (isMasked(value) && value.length !== 17) {
    return 'Please enter a valid VIN';
  }

  return undefined;
};

export interface ValidateVinResult {
  vehicleInfo: VehicleInfoResults | null;
  vinErr: string;
}

export const useValidateVin: () => (
  value: string,
  vehicle: Vehicle,
) => Promise<ValidateVinResult> = () => {
  const dispatch = useDispatch();

  return useCallback(
    async (value: string, vehicle: Vehicle) => {
      let vinErr = '';
      let vehicleInfo: VehicleInfoResults | null = {
        make: '',
        model: '',
        vehicleCharacteristics: '',
        vinPattern: '',
        year: '',
        msrp: '',
        vehicleDetailId: '',
      };

      // If the vin number is empty we want to delete the key
      if (value === '' || value === null) {
        await dispatch(deleteAnswers({ ref: 'vin' }));

        return {
          vehicleInfo,
          vinErr,
        };
      }

      try {
        if (!vehicle) {
          datadogLog({
            logType: 'warn',
            message: 'No vehicle supplied for vin on complete action',
            context: {
              logOrigin:
                'libs/features/sales/quotes/auto/src/state/modelUtil/vinNumberValidation.ts',
              functionOrigin: 'useValidateVin',
            },
          });

          throw new Error('No vehicle supplied for vin on complete action');
        }
        vehicleInfo = await fetchVehicleByVinNumber({
          year: vehicle.year,
          vinNumber: (value as string).trim(),
        });

        /**
         * If we do get a successful response back then we need to check the make and model
         * to ensure that everything matches
         */
        if (vehicleInfo?.make !== vehicle.make || vehicleInfo.model !== vehicle.model) {
          vinErr =
            'The vehicle identification number does not match the vehicle make/model/trim. Please update in order to move forward.';
        }
      } catch (e) {
        /**
         * We get to the catch block in 2 scenarios
         *
         * 1. the vin is invalid
         * 2. the vin year does not match
         *
         * In scenario 2 we want to display a custom error message. If the message contians 'valid'
         * anywhere in the sentence we know that it is not a year mismatch and the requirement is to
         * display whatever error the fuel API sends.
         */
        if (e instanceof Error) {
          vinErr = e.message.includes('valid')
            ? e.message
            : 'The vehicle year does not match the VIN. Make sure you have the correct vehicle year selected to continue.';
          datadogLog({
            logType: 'warn',
            message: vinErr,
            context: {
              logOrigin:
                'libs/features/sales/quotes/auto/src/state/modelUtil/vinNumberValidation.ts',
              functionOrigin: 'useValidateVin',
              // TODO: we cannot spread anything into DD logs
              // ...e,
            },
            error: e,
          });
        }
      }

      return {
        vehicleInfo,
        vinErr,
      };
    },
    [dispatch],
  );
};

export const useValidateVinNumber = (vin: Field): (() => boolean) => {
  const dispatch = useDispatch();
  const store = useStore();

  return useCallback(() => {
    const isRequired = true;
    const validateFormatResult = validateVinFormat(vin.props.value as string, isRequired);
    if (validateFormatResult) {
      dispatch(setFormErrorsChangedByField({ key: vin.key, errors: [validateFormatResult] }));

      return false;
    }
    const errors = getFieldErrors(store.getState(), vin.key);

    return errors.length === 0;
  }, [dispatch, store, vin.props.value, vin.key]);
};
