import { Alert } from '@ecp/components';
import { Link, PhoneLink } from '@ecp/features/sales/shared/components';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { RentersProduct } from '@ecp/features/shared/product';
import { isProductRenters } from '@ecp/features/shared/product';
import { partner } from '@ecp/partners';

import { getEnrollments } from '../../../../state';
import { useStyles } from './AdditionalInterestError.styles';

export const AdditionalInterestError: React.FC = () => {
  const { classes } = useStyles();
  const enrollments = useSelector(getEnrollments);
  const product = Object.keys(enrollments).find(isProductRenters) as RentersProduct;
  const { enrollUrl, policyNumber } = enrollments[product] || {};

  return (
    <Alert className={classes.root} type='error'>
      We had a problem saving your landlord's information. Please call us at{' '}
      <PhoneLink withUnderlinedLinkStyle number={partner.shared.servicePhoneNumber} /> or{' '}
      <Link
        href={enrollUrl}
        target='_blank'
        trackingName='access_renters_policy_online'
        trackingLabel={`Policynumber: ${policyNumber}, Product: ${product}`}
      >
        access your policy online
      </Link>{' '}
      to complete this step.
    </Alert>
  );
};
