import { createContext, useCallback, useContext, useEffect, useRef } from 'react';

import { noop } from '@ecp/utils/common';

import type { Answers, Condition, FieldRefEntry } from '@ecp/features/sales/shared/types';
import type { AnswerValue, Fields } from '@ecp/types';

export interface PageState {
  addRef: (refEntry: FieldRefEntry) => void;
  getRefs: () => FieldRefEntry[];
  removeRefs: () => void;
  addField: <Value extends AnswerValue = AnswerValue>(refField: Fields<Value>) => void;
  getFields: () => Fields;
  removeFields: () => void;
  removeByFields: <Value extends AnswerValue = AnswerValue>(refField: Fields<Value>) => void;
  addInitValues: (answers: Answers) => void;
  getInitValues: () => Answers;
  initValues?: Answers;
  removeInitValues: () => void;
  addConditionValues: (condition: Condition) => void;
  removeConditionValues: () => void;
  getConditionValues: () => Condition[];
}

const initialState: PageState = {
  addRef: noop,
  getRefs: (): FieldRefEntry[] => {
    return [];
  },
  removeRefs: noop,
  addField: noop,
  getFields: (): Fields => {
    return {};
  },
  removeFields: noop,
  removeByFields: noop,
  addInitValues: noop,
  getInitValues: (): Answers => {
    return {};
  },
  removeInitValues: noop,
  addConditionValues: noop,
  removeConditionValues: noop,
  getConditionValues: (): Condition[] => {
    return [];
  },
};

/** @deprecated This is really form context. To be rewritten with our forms handling solution altogether. */
export const PageContext = createContext(initialState);

export const useGetFieldRefs = (): (() => FieldRefEntry[]) => useContext(PageContext).getRefs;
export const useRegisterField = (): ((refEntry: FieldRefEntry) => void) =>
  useContext(PageContext).addRef;
export const useRemoveFieldRefs = (): (() => void) => useContext(PageContext).removeRefs;
export const useGetFields = (): (() => Fields) => useContext(PageContext).getFields;
export const useAddField = <Value extends AnswerValue = AnswerValue>(): ((
  field: Fields<Value>,
) => void) => useContext(PageContext).addField;
export const useRemoveFields = (): (() => void) => useContext(PageContext).removeFields;
export const useRemoveByFields = <Value extends AnswerValue = AnswerValue>(): ((
  field: Fields<Value>,
) => void) => useContext(PageContext).removeByFields;
export const useGetInitValues = (): (() => Answers) => useContext(PageContext).getInitValues;
export const useAddInitValues = (): ((answers: Answers) => void) =>
  useContext(PageContext).addInitValues;
export const useRemoveInitValues = (): (() => void) => useContext(PageContext).removeInitValues;
export const useAddConditionValue = (): ((condition: Condition) => void) =>
  useContext(PageContext).addConditionValues;
export const useRemoveConditionValues = (): (() => void) =>
  useContext(PageContext).removeConditionValues;
export const useGetConditionValues = (): (() => Condition[]) =>
  useContext(PageContext).getConditionValues;

export const useFieldRef = <T extends HTMLElement>(name?: string): React.RefObject<T> => {
  const ref = useRef(null);
  const registerField = useRegisterField();
  useEffect(() => registerField({ name, ref }), [name, registerField]);

  return ref;
};

export const useAddConditionValues = (condition: Condition): Condition => {
  const addCondition = useAddConditionValue();
  useEffect(() => addCondition(condition), [addCondition, condition]);

  return condition;
};

export const useAddFields = <Value extends AnswerValue = AnswerValue>(
  field: Fields<Value>,
): Fields<Value> => {
  const addField = useAddField<Value>();
  useEffect(() => addField(field));

  return field;
};

export const useAddFieldsCallback = <Value extends AnswerValue = AnswerValue>(): ((
  field: Fields<Value>,
) => void) => {
  const addField = useAddField<Value>();

  return useCallback(
    (field: Fields<Value>) => {
      addField(field);
    },
    [addField],
  );
};

export const useRemoveByFieldsCallback = <Value extends AnswerValue = AnswerValue>(): ((
  field: Fields<Value>,
) => void) => {
  const removeByFields = useRemoveByFields<Value>();

  return useCallback(
    (field: Fields<Value>) => {
      removeByFields(field);
    },
    [removeByFields],
  );
};

export const useInitValues = (answers: Answers | null | undefined): void => {
  const addInitValues = useAddInitValues();
  useEffect(() => {
    if (answers) {
      addInitValues(answers);
    }
    // If we don't have this to force it to run only once, given
    // that we're actually updating state now in the provider,
    // we get infinite re-renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useInitValuesState = (): Answers | undefined => {
  return useContext(PageContext).initValues;
};
