import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'StateFeesDialog' })((theme) => ({
  heading: {
    marginTop: 10,
    marginBottom: 10,
  },
  body: {
    ...theme.typography.body1,
    marginTop: 10,
  },
  subText: {
    ...theme.typography.body1Bold,
  },
  dialogBody: {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
  },
}));
