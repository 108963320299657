import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'QuotesPage' })((theme) => ({
  root: {
    '& #content': {
      maxWidth: 'initial',
    },
    '& [role=main]': {
      [theme.breakpoints.only('md')]: {
        maxWidth: 'initial',
      },
    },
  },
  errorContainer: {
    ...theme.mixins.form,
    maxWidth: 1144,
    margin: '60px auto 0 auto',
    [theme.breakpoints.down('xl')]: {
      maxWidth: theme.breakpoints.values.lg,
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: theme.breakpoints.values.md,
    },
    [theme.breakpoints.down('md')]: {
      margin: '10px 0px',
      padding: '0px 15px',
    },
  },
}));
