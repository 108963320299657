import { emptyArray } from '@ecp/utils/common';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { useEvent } from '@ecp/utils/react';
import { location } from '@ecp/utils/routing';

import { env } from '@ecp/env';
import { useCheckHasGen10Vehicle } from '@ecp/features/sales/quotes/auto';
import { ConfigType, NavStatus, PrefillFlow } from '@ecp/features/sales/shared/constants';
import {
  createOffers,
  fetchPageFlowResponse,
  fetchProductOrderResponse,
  getAllPageFlows,
  getAreSomeSelectedProductsIndicative,
  getAutoPrefillFlow,
  getCurrentFlows,
  getHomePrefillFlow,
  getLineOfBusiness,
  getNavTracking,
  getOfferProductsSelected,
  getOfferSetId,
  getOperatorsInDrivers,
  getPageFlowIntro,
  getPageFlowProducts,
  getPartnerExperienceId,
  getPrimaryInsuredAddressInfo,
  getProducts,
  getProductsForLOB,
  getProductTrackingStatus,
  getSummaryDeltaAndPurchaseFlows,
  getVehicles,
  jumpToPage,
  patchUserSelectionAndUpdateOffer,
  questionExists,
  wrapThunkActionWithErrHandler,
} from '@ecp/features/sales/shared/store';
import type {
  ConfigPage,
  NavTracking,
  PageFlow,
  PageFlowRequest,
  RequestConfigParams,
  RequestedProductOrder,
  RootStore,
  ThunkAction,
} from '@ecp/features/sales/shared/store/types';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { Product, ProductName } from '@ecp/features/shared/product';
import { hasPropertyProduct, LineOfBusiness } from '@ecp/features/shared/product';
import { getProductNameFromProduct, productMatchesLOB } from '@ecp/features/shared/product';
import type { ExperienceId } from '@ecp/partners';

import { PagePath } from './constants';

type ProductPrefillFlow = Partial<Record<ProductName, PrefillFlow>>;

const getPrefillFlow = (product: Product, prefillFlows: ProductPrefillFlow): PrefillFlow => {
  const productName = getProductNameFromProduct(product);
  const flow = productName && prefillFlows[productName];

  return flow || PrefillFlow.LONG;
};

const generateConfigParams = (
  product: Product,
  prefillFlows: ProductPrefillFlow,
  stateCode: string,
  expId?: ExperienceId,
): RequestConfigParams => {
  return {
    baseExp: env.static.baseExp,
    ...(expId && { expId }),
    ...(product && { product }),
    flow: getPrefillFlow(product, prefillFlows),
    stateCode,
  };
};

const checkIntroPageContinue = (introPageFlow: PageFlow, pathname: string): boolean => {
  const introPageName = introPageFlow.value.navigationOrder.find((pageName) => {
    if (introPageFlow.value.byPageId[pageName]?.path === pathname) return true;

    return false;
  });
  if (introPageName) return true;

  return false;
};

const getCurrentFlowAndPageIndex = (
  pathname: string,
  allPageFlows: PageFlow[],
): { currentFlowIndex: number; currentPageIndex: number } => {
  const [currentFlowIndex, currentPageIndex] = allPageFlows
    .map((f, i): [number, ConfigPage[]] => [i, f.value.pageFlows])
    .map(([i, pf]) => [i, pf.findIndex((p) => p.path === pathname)])
    .find(([, pfIndex]) => pfIndex >= 0) || [-1, -1];

  return {
    currentFlowIndex,
    currentPageIndex,
  };
};

export const getProductOrderFromConfig = wrapThunkActionWithErrHandler(
  () => async (dispatch) => {
    const productOrderRequest: RequestedProductOrder = {
      id: 'productOrder',
    };
    await dispatch(fetchProductOrderResponse(productOrderRequest));
  },
  'getProductOrderFromConfig',
);

// These pages are defined for all product specific.
const addProductFlowRequest = (
  products: Product[],
  modifiedProduct: Product | undefined,
  pageFlowRequest: PageFlowRequest,
  prefillFlows: ProductPrefillFlow,
  stateCode: string,
  selectedOffersProducts: Product[],
  expId?: ExperienceId,
): void => {
  const sniPageFeatureEnabled = flagValues[FeatureFlags.SECONDARY_NAMED_INSURED_PAGE];
  const sniQuestionsEnabled = flagValues[FeatureFlags.SNI_DRIVER_QUESTIONS];

  const sniPageEnabled =
    // SNIPage enabled, Auto SNI is on Additional Driver page, but there is a property product
    (sniPageFeatureEnabled && sniQuestionsEnabled && hasPropertyProduct(products)) ||
    // SNIPage enabled, Auto SNI isn't rendered on Additional Driver page
    (sniPageFeatureEnabled && !sniQuestionsEnabled);

  products.forEach((product) => {
    if (product !== modifiedProduct) {
      const params: RequestConfigParams = generateConfigParams(
        product,
        prefillFlows,
        stateCode,
        expId,
      );
      pageFlowRequest.splice(
        pageFlowRequest.findIndex((ele) => {
          if (sniPageEnabled) {
            return ele.id === ConfigType.SECONDARY_NAMED_INSURED_PAGE_FLOW;
          } else {
            return ele.id === ConfigType.INITIAL_SUMMARY_PAGE_FLOW;
          }
        }),
        0,
        {
          id: 'product-' + product,
          configType: ConfigType.PRODUCT_PAGE_FLOW,
          params,
        },
      );
      // Add Delta Page flow request based on the offer being selected on Quote Summary page.
      if (
        // Add Delta page flows for the products being selected initially by the user when selected offers producrs are empty
        selectedOffersProducts.length === 0 ||
        // Add Delta page flows only for the selected offer product. Skip for the other product in bundle scenario.
        (selectedOffersProducts.length > 0 && selectedOffersProducts.includes(product))
      ) {
        pageFlowRequest.splice(
          pageFlowRequest.findIndex((ele) => ele.id === ConfigType.FINAL_SUMMARY_PAGE_FLOW),
          0,
          {
            id: 'delta-' + product,
            configType: ConfigType.DELTA_PAGE_FLOW,
            params,
          },
        );
      }

      // TODO: We need to make sure it works for Bundle scenario as well
      // To make sure, created a task to handle bundle scenario ADVECP-3754
      const thirdPartyInterestPageEnabled = flagValues[FeatureFlags.THIRD_PARTY_INTEREST_FLOW];
      if (thirdPartyInterestPageEnabled) {
        pageFlowRequest.splice(
          pageFlowRequest.findIndex((ele) => ele.id === ConfigType.FINAL_SUMMARY_PAGE_FLOW),
          0,
          {
            id: 'thirdPartyInterest-' + product,
            configType: ConfigType.THIRD_PARTY_INTEREST_FLOW,
            params,
          },
        );
      }
    }
  });
};

const addModifiedProductFlowRequest = (
  modifiedProduct: Product,
  pageFlowRequest: PageFlowRequest,
  prefillFlows: ProductPrefillFlow,
  stateCode: string,
  expId?: ExperienceId,
): void => {
  const params: RequestConfigParams = generateConfigParams(
    modifiedProduct,
    prefillFlows,
    stateCode,
    expId,
  );

  pageFlowRequest.splice(
    pageFlowRequest.findIndex((ele) => ele.id === ConfigType.INITIAL_SUMMARY_PAGE_FLOW),
    0,
    {
      id: 'product-' + modifiedProduct,
      configType: ConfigType.PRODUCT_PAGE_FLOW,
      params,
    },
  );

  pageFlowRequest.splice(
    pageFlowRequest.findIndex((ele) => ele.id === ConfigType.FINAL_SUMMARY_PAGE_FLOW),
    0,
    {
      id: 'delta-' + modifiedProduct,
      configType: ConfigType.DELTA_PAGE_FLOW,
      params,
    },
  );
};

const insertPrefillHomeSummaryRequestBeforeInitialSummaryPageFlow = (
  pageFlowRequest: PageFlowRequest,
  prefillFlows: ProductPrefillFlow,
  stateCode: string,
  expId?: ExperienceId,
): PageFlowRequest => {
  const params: RequestConfigParams = generateConfigParams(
    'homesite.home',
    prefillFlows,
    stateCode,
    expId,
  );
  pageFlowRequest.splice(
    pageFlowRequest.findIndex((ele) => ele.id === ConfigType.INITIAL_SUMMARY_PAGE_FLOW),
    0,
    {
      id: ConfigType.HOME_PREFILL_SUMMARY_PAGE_FLOW,
      configType: ConfigType.HOME_PREFILL_SUMMARY_PAGE_FLOW,
      params,
    },
  );

  return pageFlowRequest;
};

const shouldRequestHomePrefillSummaryPageFlow = (): boolean => {
  return flagValues[FeatureFlags.HOME_PREFILL_SUMMARY_PAGE_ABC_TEST] === 'VARIANT_A';
};

const addHomePrefillSummaryPageFlowToRequestBaseOnFeatureFlag = (
  pageFlowRequest: PageFlowRequest,
  prefillFlows: ProductPrefillFlow,
  stateCode: string,
  expId?: ExperienceId,
): PageFlowRequest => {
  if (shouldRequestHomePrefillSummaryPageFlow()) {
    return insertPrefillHomeSummaryRequestBeforeInitialSummaryPageFlow(
      pageFlowRequest,
      prefillFlows,
      stateCode,
      expId,
    );
  }

  return pageFlowRequest;
};

// These pages are defined for all products. i.e, these pages gets displayed for all product flows.
// opco related configuration, please check 'productPageFlow.json'
const generatePageFlowRequest = (
  products: Product[],
  prefillFlows: ProductPrefillFlow,
  stateCode: string,
  selectedOffersProducts: Product[],
  expId?: ExperienceId,
  modifiedProduct?: Product,
): PageFlowRequest => {
  const params = {
    baseExp: env.static.baseExp,
    ...(expId && { expId }),
  };
  const pageFlowRequest: PageFlowRequest = [
    {
      id: ConfigType.INTRO_PAGE_FLOW,
      configType: ConfigType.INTRO_PAGE_FLOW,
      params,
    },
    {
      id: ConfigType.INITIAL_SUMMARY_PAGE_FLOW,
      configType: ConfigType.INITIAL_SUMMARY_PAGE_FLOW,
      params,
    },
    {
      id: ConfigType.FINAL_SUMMARY_PAGE_FLOW,
      configType: ConfigType.FINAL_SUMMARY_PAGE_FLOW,
      params,
    },
    {
      id: ConfigType.PURCHASE_PAGE_FLOW,
      configType: ConfigType.PURCHASE_PAGE_FLOW,
      params,
    },
  ];
  const sniPageFeatureEnabled = flagValues[FeatureFlags.SECONDARY_NAMED_INSURED_PAGE];
  const sniQuestionsEnabled = flagValues[FeatureFlags.SNI_DRIVER_QUESTIONS];

  const sniPageEnabled =
    // SNIPage enabled, Auto SNI is on Additional Driver page, but there is a property product
    (sniPageFeatureEnabled && sniQuestionsEnabled && hasPropertyProduct(products)) ||
    // SNIPage enabled, Auto SNI isn't rendered on Additional Driver page
    (sniPageFeatureEnabled && !sniQuestionsEnabled);

  if (sniPageEnabled) {
    pageFlowRequest.splice(1, 0, {
      id: ConfigType.SECONDARY_NAMED_INSURED_PAGE_FLOW,
      configType: ConfigType.SECONDARY_NAMED_INSURED_PAGE_FLOW,
      params,
    });
  }

  const thirdPartyReportsPageEnabled = flagValues[FeatureFlags.THIRD_PARTY_REPORTS_FLOW];
  if (thirdPartyReportsPageEnabled) {
    pageFlowRequest.splice(
      pageFlowRequest.findIndex((ele) => ele.id === ConfigType.FINAL_SUMMARY_PAGE_FLOW),
      0,
      {
        id: ConfigType.THIRD_PARTY_REPORTS_FLOW,
        configType: ConfigType.THIRD_PARTY_REPORTS_FLOW,
        params,
      },
    );
  }

  addProductFlowRequest(
    products,
    modifiedProduct,
    pageFlowRequest,
    prefillFlows,
    stateCode,
    selectedOffersProducts,
    expId,
  );
  if (modifiedProduct) {
    addModifiedProductFlowRequest(modifiedProduct, pageFlowRequest, prefillFlows, stateCode, expId);
  }

  const requestAfterAddingHomePrefillSummaryPageFlow =
    addHomePrefillSummaryPageFlowToRequestBaseOnFeatureFlag(
      pageFlowRequest,
      prefillFlows,
      stateCode,
      expId,
    );

  return requestAfterAddingHomePrefillSummaryPageFlow;
};

export const getAllPageFlowsFromConfig = wrapThunkActionWithErrHandler<PageFlowNavigation, void>(
  ({ modifiedProduct }) =>
    async (dispatch, getState) => {
      const state = getState();
      const { pathname } = location;
      const lineOfBusiness = getLineOfBusiness(state);
      const selectedProducts = lineOfBusiness ? getProductsForLOB(state) : [];
      const selectedOffersProducts =
        pathname === PagePath.QUOTES || pathname === PagePath.COVERAGES
          ? getOfferProductsSelected(state)
          : [];
      const flows = getCurrentFlows(state);
      const { state: stateCode } = getPrimaryInsuredAddressInfo(state);
      const expId = env.static.isAgent
        ? getPartnerExperienceId(state) ?? undefined
        : env.static.expId;
      const pageFlowRequest = generatePageFlowRequest(
        selectedProducts,
        flows,
        stateCode,
        selectedOffersProducts,
        expId,
        modifiedProduct,
      );
      await dispatch(fetchPageFlowResponse(pageFlowRequest));
    },
  'getAllPageFlowsFromConfig',
);

const hasHomePrefillSummaryPageFlow = (pageFlows: PageFlow[]): boolean =>
  pageFlows.some((pageFlow) => pageFlow.id === ConfigType.HOME_PREFILL_SUMMARY_PAGE_FLOW);

const getNextPage = (
  pathname: string,
  allPageFlows: PageFlow[],
  homePrefillFlow: PrefillFlow | undefined,
  navTracking: Record<string, NavTracking>,
  autoPrefillFlow: PrefillFlow | undefined,
  needDriverAssignment: boolean,
  needVehicleOwnershipDate: boolean | undefined,
  lineOfBusiness: LineOfBusiness,
  getState: () => RootStore,
  shouldSkipQuotePage: boolean,
): string => {
  // This code will be removed in EDSP-8506
  // line 387 can be changed to 'pathname === PagePath.PERSON &&'
  const finalIntroPage = PagePath.PERSON;
  // FIXME: ideally there are no empty page flows
  // and we could skip this step.
  const nonEmptyFlows = allPageFlows.filter((p) => p.value.pageFlows.length);
  // find the flow with the current page
  const { currentFlowIndex, currentPageIndex } = getCurrentFlowAndPageIndex(
    pathname === PagePath.PERSON_ADDRESS ? PagePath.PERSON : pathname,
    nonEmptyFlows,
  );
  const state: RootStore = getState();
  const rentersPropertyDetailsPageEnabled = flagValues[FeatureFlags.RENTERS_PROPERTY_DETAILS_PAGE];
  if (pathname === PagePath.SELECT_PRODUCT) return PagePath.LANDING;
  if (pathname === PagePath.EDIT_AUTO_PROFILE)
    if (needVehicleOwnershipDate && autoPrefillFlow === PrefillFlow.SHORT)
      // Redirect user to Vehicle Ownership page from Auto Profile page for auto short flow
      return PagePath.VEHICLE_OWNERSHIP_DATE;
    // For adv auto, vehicles comes before driver assignments
    // Redirect user to Driver Assignment Page from Auto Profile page in case driver assignments needed.
    else if (needDriverAssignment) return PagePath.DRIVER_ASSIGNMENT;
    else return PagePath.AUTO_DISCOUNTS;
  if (pathname === PagePath.VEHICLE_OWNERSHIP_DATE)
    if (needDriverAssignment)
      // Redirect user to Driver Assignment Page from Vehicle Ownership page.
      return PagePath.DRIVER_ASSIGNMENT;
    // Redirect user to Auto Short Discounts Page from Vehicle Ownership page invase of driver assignment not needed.
    else return PagePath.AUTO_DISCOUNTS_SHORT;
  if (pathname === PagePath.DRIVER_ASSIGNMENT)
    if (autoPrefillFlow === PrefillFlow.SHORT)
      // Redirect user From Driver Assignment Page to Auto Short Discounts page for Auto Short Flow.
      return PagePath.AUTO_DISCOUNTS_SHORT;
    // Redirect user From Driver Assignment Page to Auto Long Discounts page.
    else return PagePath.AUTO_DISCOUNTS;
  // Condition to redirect user from person address page to next home flow based on prefill flag.
  // This logic needed only for Zillow
  if (
    ((env.static.personAddressPageIsLastIntroPage && pathname === PagePath.PERSON_ADDRESS) ||
      pathname === PagePath.TRANSITION) &&
    lineOfBusiness === LineOfBusiness.HOME
  )
    if (homePrefillFlow === PrefillFlow.SHORT) return PagePath.HOME_DISCOUNTS;
    else return PagePath.HOME_BASIC;
  if (pathname === PagePath.SECONDARY_NAMED_INSURED) {
    if (hasHomePrefillSummaryPageFlow(nonEmptyFlows)) {
      return PagePath.HOME_PREFILL_SUMMARY;
    }

    return PagePath.QUOTES;
  }
  if (pathname === PagePath.HOME_PREFILL_SUMMARY) {
    return PagePath.QUOTES;
  }
  // Condition to send the user to the Quotes page instead of propertydetails page for Zillow only
  // rentersPropertyDetailsPageEnabled feature flag will be removed once we launch in Prod
  const partnerExperienceId: ExperienceId = getPartnerExperienceId(state);
  if (
    pathname === finalIntroPage &&
    env.static.isAgent &&
    env.pageFlowFlag?.[partnerExperienceId]?.skipRentersPropertyDetailsPage &&
    rentersPropertyDetailsPageEnabled
  )
    return PagePath.QUOTES;

  // no page matches any flow
  if (currentFlowIndex === -1) return '';
  const currentFlow = nonEmptyFlows[currentFlowIndex];
  // choose the next page in the matching flow
  let nextPage = currentFlow.value.pageFlows[currentPageIndex + 1]?.path;

  // EDSP-11441 Skip quote page on back nav and when offer is in bindable state and SHOULD_SKIP_QUOTE_PAGE_ON_BACK_NAV is turned on
  if (shouldSkipQuotePage)
    nextPage = nonEmptyFlows[currentFlowIndex + 2]?.value.pageFlows[0]?.path || '';

  // so there isn't an next page, go to the next page flow
  // and get the first page.
  if (!nextPage) nextPage = nonEmptyFlows[currentFlowIndex + 1]?.value.pageFlows[0]?.path || '';

  // redirect agent to home discounts page instead of home basic page when FLOW is SHORT
  if (
    env.static.isAgent &&
    homePrefillFlow === PrefillFlow.SHORT &&
    nextPage === PagePath.HOME_BASIC &&
    navTracking[PagePath.HOME_DISCOUNTS]?.status === undefined
  )
    nextPage = PagePath.HOME_DISCOUNTS;

  return nextPage;
};

type NextPage = {
  skipNextProductFlow?: boolean;
  needVehicleOwnershipDate?: boolean;
  replace?: boolean;
};

const jumpToNextPageFromConfig = wrapThunkActionWithErrHandler<NextPage, void>(
  ({ skipNextProductFlow, needVehicleOwnershipDate, replace = false }) =>
    async (dispatch, getState): Promise<void> => {
      const { pathname } = location;
      let nextPage = '';
      let state = getState();
      let allPageFlows: PageFlow[] = getAllPageFlows(state);
      const introPageFlow = getPageFlowIntro(state);
      const productPageFlows = getPageFlowProducts(state);
      const driverAssignmentQuestionExists = questionExists('vehicle.<id>.primary.driver.ref')(
        state,
      );
      const operators = getOperatorsInDrivers(state);
      const vehicles = getVehicles(state);

      if (shouldFetchConfigs(introPageFlow, pathname)) {
        await dispatch(getAllPageFlowsFromConfig({}));
        // Get the updated state after primary insured locked and prefill flows values returned by SAPI and after getting last config flows.
        state = getState();
        allPageFlows = getAllPageFlows(state);
      }
      const { currentFlowIndex } = getCurrentFlowAndPageIndex(pathname, allPageFlows);

      // START - EDSP-11441 Skip quote page on back nav and when offer is in bindable state and SHOULD_SKIP_QUOTE_PAGE_ON_BACK_NAV is turned on
      const skipQuotePage = flagValues[FeatureFlags.SHOULD_SKIP_QUOTE_PAGE_ON_BACK_NAV];
      const isCurrentPageDiscounts =
        pathname === PagePath.AUTO_DISCOUNTS ||
        pathname === PagePath.HOME_DISCOUNTS ||
        pathname === PagePath.RENTERS_DISCOUNTS;
      const shouldSkipQuotePage =
        skipQuotePage && isCurrentPageDiscounts && !getAreSomeSelectedProductsIndicative(state);
      // END - EDSP-11441 Skip quote page on back nav and when offer is in bindable state and SHOULD_SKIP_QUOTE_PAGE_ON_BACK_NAV is turned on

      const shouldFilterNextProductFlow =
        skipNextProductFlow &&
        productPageFlows.findIndex((x) => x.id === allPageFlows[currentFlowIndex].id) === 0;
      nextPage = getNextPage(
        pathname,
        allPageFlows.filter(
          (pageFlow, index) => !(shouldFilterNextProductFlow && index === currentFlowIndex + 1),
        ),
        getHomePrefillFlow(state),
        getNavTracking(state),
        getAutoPrefillFlow(state),
        (operators.length > 1 || vehicles.length > 1) && driverAssignmentQuestionExists,
        needVehicleOwnershipDate,
        getLineOfBusiness(state),
        getState,
        shouldSkipQuotePage,
      );

      // To avoid back nav issues when auto dnqs post auto delta and user backnavs from coveragesPage, we are removing autodelta from history. ECP-10491
      await dispatch(
        jumpToPage({
          page: nextPage,
          replace: pathname === PagePath.AUTO_DELTA || replace,
          addErrorHash: nextPage === PagePath.ERROR,
          removeQuery: pathname === PagePath.AUTO_DELTA,
        }),
      );
    },
  'jumpToNextPageFromConfig',
);

function shouldFetchConfigs(introPageFlow: PageFlow, pathname: string): boolean | undefined {
  return (
    (introPageFlow.value.pageFlows.length > 0 && checkIntroPageContinue(introPageFlow, pathname)) ||
    // Condition to call config api to get the updated Delta page flows based on the offer being selected on Quote Summary page.
    pathname === PagePath.QUOTES ||
    pathname === PagePath.TRANSITION ||
    (env.static.personAddressPageIsLastIntroPage && PagePath.PERSON_ADDRESS === pathname)
  );
}

type UseNavigateToNextPageParams = {
  skipNextProductFlow?: boolean;
};

type NavigateToNextPage = (replace?: boolean) => Promise<void>;

export const useNavigateToNextPage = ({
  skipNextProductFlow,
}: UseNavigateToNextPageParams = {}): NavigateToNextPage => {
  const dispatch = useDispatch();
  const needVehicleOwnershipDate = useCheckHasGen10Vehicle();
  const navigateToNextPage = useEvent(async (replace?: boolean): Promise<void> => {
    // Avoid type coercion
    const shouldReplace = typeof replace === 'boolean' ? replace : undefined;
    await dispatch(
      jumpToNextPageFromConfig({
        skipNextProductFlow,
        needVehicleOwnershipDate,
        replace: shouldReplace,
      }),
    );
  });

  return navigateToNextPage;
};

type PageFlowNavigation = {
  modifiedProduct?: Product;
};

type ConfigParams = {
  modifiedLOB?: LineOfBusiness | null;
};

export const getUpdatedPageflowsFromConfig = wrapThunkActionWithErrHandler<ConfigParams, void>(
  ({ modifiedLOB }) =>
    async (dispatch, getState) => {
      const modifiedProducts = modifiedLOB
        ? getProducts(getState()).filter((product) => productMatchesLOB(modifiedLOB, product))
        : [];
      // TODO This needs to be rewritten as it doesn't support multiple products
      const modifiedProduct = modifiedProducts?.length > 0 ? modifiedProducts[0] : undefined;

      await dispatch(
        getAllPageFlowsFromConfig({
          modifiedProduct,
        }),
      );
    },
  'getUpdatedPageflowsFromConfig',
);

export const getNextPageOnProductAddFromConfig =
  ({ path, product }: { path: string; product: Product }): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const productPageFlows = getPageFlowProducts(state);
    const otherPageFlows = getSummaryDeltaAndPurchaseFlows(state);
    const nonEmptyFlows = [
      ...(env.static.isAgent ? productPageFlows : emptyArray),
      ...otherPageFlows,
    ].filter((p) => p.value.pageFlows.length);
    const { currentFlowIndex } = getCurrentFlowAndPageIndex(path, nonEmptyFlows);

    if (currentFlowIndex !== -1) {
      const addedProductFlow = productPageFlows.filter((p) => p.id === `product-${product}`)[0];

      await dispatch(
        jumpToPage({
          page: addedProductFlow.value.pageFlows[0].path,
          replace: false,
          addErrorHash: path === PagePath.ERROR,
        }),
      );
    }
  };

export const getNextPageOnProductDeleteFromConfig =
  ({ path, productFlow }: { path: string; productFlow: PageFlow }): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const pageFlows = getAllPageFlows(state);
    const otherPageFlows = getSummaryDeltaAndPurchaseFlows(state);
    let nextPagePath = path;
    const nextPage = productFlow.value.pageFlows.find((page) => page.path === path);
    const nonEmptyFlows = otherPageFlows.filter((p) => p.value.pageFlows.length);
    const { currentFlowIndex } = getCurrentFlowAndPageIndex(path, nonEmptyFlows);
    const productTrackingStatus = getProductTrackingStatus(getState());
    const offerSetId = getOfferSetId(getState());

    if (productTrackingStatus.status === NavStatus.VALID) {
      if (offerSetId) {
        await dispatch(patchUserSelectionAndUpdateOffer());
      } else {
        await dispatch(createOffers());
        nextPagePath = PagePath.QUOTES;
      }
    } else if (nextPage || currentFlowIndex !== -1) {
      const productPageFlows = pageFlows.filter(
        (pageFlow) =>
          pageFlow.id.includes('product') &&
          pageFlow.value.byPageId &&
          pageFlow.value.menuItems?.length > 0,
      );
      productPageFlows.forEach((pageFlow) => {
        nextPagePath = pageFlow.value.pageFlows[0].path;
      });
    }

    if (path !== nextPagePath) {
      await dispatch(
        jumpToPage({
          page: nextPagePath,
          replace: false,
          addErrorHash: path === PagePath.ERROR,
        }),
      );
    }
  };
