import { RadioGroupWithOptions } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const MineSubsidenceDamageQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const mineSubsidenceDamage = useHomePropertyField('unrepairedMineSubDamageInd');
  const {
    trackingName = 'unrepired_mine_subsidence_damage',
    label = mineSubsidenceDamage?.question?.title,
  } = props;

  useInitValues({ [mineSubsidenceDamage.key]: mineSubsidenceDamage.question.defaultValue });

  useAddFields({ mineSubsidenceDamage });

  if (!mineSubsidenceDamage.exists) return null;

  return (
    <RadioGroupWithOptions
      {...mineSubsidenceDamage.props}
      variant='yesNoButton'
      trackingName={trackingName}
      label={label}
    />
  );
};
