import { Divider } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';

import type { FeeItem } from '@ecp/features/sales/checkout';

import { Dialog } from '../../Dialog';
import { SingleLineDetail } from '../../SingleLineDetail';
import { useStyles } from './StateFeesDialog.styles';

interface Props {
  stateFeeItems?: FeeItem[];
  surcharges?: FeeItem[];
  open: boolean;
  onClose(): void;
}

export const StateFeesDialog: React.FC<Props> = (props) => {
  const { stateFeeItems, surcharges, open, onClose } = props;
  const { classes } = useStyles();

  const getFeeItems = (feeItems: FeeItem[] | undefined | null): React.ReactElement[] | null => {
    if (!feeItems) return null;

    return feeItems
      .filter((feeItem) => parseFloat(feeItem.value) !== 0)
      .map((feeItem) => (
        <SingleLineDetail
          key={feeItem.name}
          text={feeItem.name}
          value={parseDollar(feeItem.value)}
        />
      ));
  };

  // We will only display non-zero state specific items sent across from the carriers.
  return (
    <Dialog
      actionButtonLabel='Close'
      buttonPlacement='right'
      titleText='Fees'
      trackingNameCloseIcon='StateSpecificFees_X'
      trackingLabelCloseIcon='X'
      trackingNameButton='StateSpecificFeesClose'
      trackingLabelButton='Close'
      open={open}
      onClose={onClose}
    >
      <div className={classes.dialogBody}>
        <p className={classes.heading}>The fees listed below may have affected your premium.</p>
        <Divider aria-hidden='true' />
        <div className={classes.body}>
          {getFeeItems(stateFeeItems)}
          {getFeeItems(surcharges)}
        </div>
      </div>
    </Dialog>
  );
};
