import type { ProductName } from '@ecp/features/shared/product';

import type { PaymentCardOption } from '../../../../metadata';
import { paymentTypeOrder, paymentTypeToPaymentMethod } from '../../../../metadata';

export const getPaymentMethodOptions = (
  availablePaymentTypes: string[] | undefined,
  coverageType: ProductName,
): PaymentCardOption[] | undefined => {
  if (paymentTypeOrder[coverageType] && availablePaymentTypes) {
    return paymentTypeOrder[coverageType]
      ?.filter((paymentType) => availablePaymentTypes.includes(paymentType))
      .map((paymentType) => paymentTypeToPaymentMethod[paymentType]);
  }

  return undefined;
};
