import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<{
  leftAlignDialog?: boolean;
  leftAlignSessionContent?: boolean;
}>({ name: 'SessionExpiryContent' })((theme, { leftAlignDialog, leftAlignSessionContent }) => ({
  root: {
    '& h1': {
      display: 'inline-block',
      marginLeft: 10,
      width: 100,
      marginBottom: 25,
    },
    '& p': {
      ...theme.typography.body3,
      [theme.breakpoints.down('md')]: {
        ...theme.typography.body1,
        textAlign: leftAlignDialog && leftAlignSessionContent ? 'left' : 'center',
      },
      marginTop: 10,
      textAlign: leftAlignDialog && leftAlignSessionContent ? 'left' : 'center',
    },
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  sessionTimerContent: {
    display: 'grid',
    textAlign: leftAlignDialog && leftAlignSessionContent ? 'left' : 'center',
    '& svg': {
      verticalAlign: 'bottom',
      width: 82,
      height: 82,
    },
    '& span': {
      padding: leftAlignSessionContent ? 0 : 20,
    },
  },
  sessionTimerButton: {
    padding: 20,
  },
}));
