import { useEffect, useState } from 'react';

import { useMediaQuery } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { emptyArray } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';
import { preventScroll } from '@ecp/utils/web';

import { useTheme } from '@ecp/themes/base';
import type { CardOption } from '@ecp/types';

import { getSortedOptions } from '../utils';
import type { ShowMoreOrLessProps } from './ShowMoreOrLess';

export const useShowMoreOrLess = (
  options: CardOption[] = emptyArray as unknown as CardOption[],
  initSizeProp: number,
  trackingName?: string,
  trackingLabel?: (value: string) => string,
  sortByKey?: boolean,
): {
  notShow: boolean;
  showMore: boolean;
  displayOptions: CardOption[];
  handleClick: NonNullable<ShowMoreOrLessProps['onClick']>;
} => {
  const [updatedOptions, setUpdatedOptions] = useState([] as CardOption[]);
  const [displayOptions, setDisplayOptions] = useState([] as CardOption[]);
  const [showMore, setShowMore] = useState(false);
  const [notShow, setNotShow] = useState(false);

  const { breakpoints } = useTheme();

  const initSize = useMediaQuery(breakpoints.between('md', 'xl')) ? 3 : initSizeProp;

  const stringifiedOptions = JSON.stringify(options);

  // This effect needs to be called only when the options array changes.
  // For the first render, it will initialize the rest of the state variables.
  useEffect(() => {
    // First sort the options if needed
    const sortedOptions = sortByKey && options?.length ? getSortedOptions(options) : options;
    setUpdatedOptions(sortedOptions);

    // Determine how many cards to show based on the length of options
    const evaluatedSize = sortedOptions.length > initSize ? initSize : sortedOptions.length;
    setDisplayOptions(sortedOptions.slice(0, evaluatedSize));

    // Initialize showMore and show only few options
    setShowMore(false);

    if (sortedOptions.length <= initSize) {
      setNotShow(true);
    } else {
      setNotShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initSize, sortByKey, stringifiedOptions]);

  if (!showMore && displayOptions.length > initSize) {
    setDisplayOptions(updatedOptions.slice(0, initSize));
  }

  const handleClick = useEvent<NonNullable<ShowMoreOrLessProps['onClick']>>((event) => {
    if (showMore) {
      setDisplayOptions(updatedOptions.slice(0, initSize));
      if (trackingName) {
        trackClick({
          action: trackingName,
          label: trackingLabel ? trackingLabel('Less') : 'Less',
        });
      }
      // BUGFIX for Safari.
      // Removing elements, which add up to scrollHeight, will not make the window to scroll up. Force scroll by focusing on the button.
      event.currentTarget.focus();
    } else {
      // BUGFIX for all browsers, except Safari.
      // Appending elements, which add up to scrollHeight, will unwillingly make the window to scroll down. Prevent scroll.
      preventScroll();
      setDisplayOptions(updatedOptions);
      if (trackingName) {
        trackClick({
          action: trackingName,
          label: trackingLabel ? trackingLabel('More') : 'More',
        });
      }
    }
    setShowMore(!showMore);
  });

  return { notShow, showMore, displayOptions, handleClick };
};
