import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'GetQuote' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    ...theme.mixins.form,
    marginTop: 50,
    [theme.breakpoints.up('lg')]: {
      padding: '0 60px',
      marginTop: 60,
    },
  },
  desktopHeading: {
    marginBottom: 40,
  },
  agreementPadding: {
    paddingTop: 20,
  },
  dividerContainer: {},
  divider: {},
  productUpsellsContainer: {},
  quoteContainer: {},
  productUpsellsDesktopListOverride: {},
}));
