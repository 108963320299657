import type { AudienceAttribute } from '@ecp/utils/flags';
import { getAllFlagDecision, getOptimizelyUserId, updateUserAttributes } from '@ecp/utils/flags';
import { datadogRum } from '@ecp/utils/logger';

import { trackSapiAnalyticsEvent } from './trackSapiAnalyticsEvent';

const trackOptimizelyFeatureFlagsForDatadogAnalytics = (): void => {
  const allDecisions = getAllFlagDecision();

  Object.values(allDecisions).forEach(({ flagKey, variationKey }) =>
    datadogRum.addFeatureFlagEvaluation(flagKey, variationKey),
  );
};

const trackOptimizelyVisitorIdVariantIdAndName = (): void => {
  const eventDetail = createEventDetail();
  trackSapiAnalyticsEvent({
    element: 'optimizelyVisitorIdVariantIdAndName',
    event: 'captured',
    eventDetail: eventDetail,
  });
};

const haveDecisions = (): boolean => {
  const allDecisions = getAllFlagDecision();

  return allDecisions && Object.keys(allDecisions).length > 0;
};

const createVariantIdAndNames = (): string => {
  const allDecisions = getAllFlagDecision();

  return Object.values(allDecisions)
    .map(({ flagKey, ruleKey, variationKey }) => `${flagKey}, ${ruleKey}, ${variationKey}`)
    .join('; ');
};

const createEventDetail = (): string => {
  const userId = getOptimizelyUserId();

  return `VisitorId:${userId}, VariantIdAndNames:${createVariantIdAndNames()}`;
};

export const updateUserAttributesAndDecisionAnalytics = (attributes: AudienceAttribute): void => {
  updateUserAttributes(attributes);

  if (haveDecisions()) {
    trackOptimizelyVisitorIdVariantIdAndName();
    trackOptimizelyFeatureFlagsForDatadogAnalytics();
  }
};
