import { createAction } from '@reduxjs/toolkit';

import type { Answers } from '@ecp/features/sales/shared/types';
import type { Questions } from '@ecp/types';

import type { AnswersResponse, InquiryResponse } from './types';
import type { PrepareAction } from './util/prepareAction';
import { prepareAction } from './util/prepareAction';

export type InquiryOnOffersPayload = {
  dalSessionId: string;
  inquiryId: string;
  questions: Questions;
  answers: Answers;
};

export const setAnswersApiGetSuccess = createAction(
  '@answers/apiGetSuccess',
  prepareAction as unknown as PrepareAction<AnswersResponse>,
);
export const setAnswersApiPatchSuccess = createAction(
  '@answers/apiPatchSuccess',
  prepareAction as unknown as PrepareAction<{
    answers: Answers;
    errors?: AnswersResponse['data']['errors'];
  }>,
);
export const setInquiryApiGetSuccess = createAction(
  '@inquiry/apiGetSuccess',
  prepareAction as unknown as PrepareAction<InquiryResponse>,
);
export const setInquiryCreationFlag = createAction<boolean>('@inquiry/creatingInquiry');
export const setInquiryApiPostSuccess = createAction(
  '@inquiry/apiPostSuccess',
  prepareAction as unknown as PrepareAction<InquiryResponse>,
);
export const setDalSessionId = createAction<string>('@inquiry/setDalSessionId');
export const setInquiryId = createAction<string>('@inquiry/setId');
export const setInquiryOnOffersApiSuccess = createAction(
  '@inquiry/setInquiryOnOffersApiSuccess',
  prepareAction as unknown as PrepareAction<InquiryOnOffersPayload>,
);

/** @deprecated Remove after properly replacing all Redux slices with RTK createSlice. */
export type InquiryActions = ReturnType<
  | typeof setAnswersApiGetSuccess
  | typeof setAnswersApiPatchSuccess
  | typeof setInquiryApiGetSuccess
  | typeof setInquiryCreationFlag
  | typeof setInquiryApiPostSuccess
  | typeof setDalSessionId
  | typeof setInquiryId
  | typeof setInquiryOnOffersApiSuccess
>;
