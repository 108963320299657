import type { Product } from '@ecp/features/shared/product';
import { isProductRenters } from '@ecp/features/shared/product';

import type { PaymentOption } from '../types';

export const determineInstallmentFeeTotal = (
  product: Product,
  paymentOption: PaymentOption,
): string | undefined => {
  const installmentFeeTotal = isProductRenters(product)
    ? paymentOption.autoPayFeeTotal
    : paymentOption.convenienceFeeTotal;

  return installmentFeeTotal;
};
