import { useCallback, useState } from 'react';

import { FormControl, FormLabel, Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import {
  isMasked,
  maskMortgageLoanNumber,
  parseDollar,
  resetMaskedPaymentAccountNumber,
} from '@ecp/utils/common';
import { formatDate } from '@ecp/utils/date';
import { useEffectOnce } from '@ecp/utils/react';

import { GridItem, NumberFormat } from '@ecp/components';
import { env } from '@ecp/env';
import { useAddFields } from '@ecp/features/sales/form';
import {
  PhoneLink,
  RadioGroupWithOptions,
  Select,
  SingleLineDetail,
  TextField,
} from '@ecp/features/sales/shared/components';
import { PhoneNumberQuestion } from '@ecp/features/sales/shared/questions';
import { useField, usePniRef } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, OptionProps } from '@ecp/features/sales/shared/types';
import type { Product, ProductName } from '@ecp/features/shared/product';
import { partner } from '@ecp/partners';
import { useIsMobile } from '@ecp/themes/base';

import { MortgageRequest } from '../../../metadata/Mortgage.metadata';
import { setMortgage } from '../../../state/purchase';
import { useStyles } from './MortgagePayment.styles';
import type { PaymentFieldOption } from './PaymentTerm';

interface Props {
  paymentOptions: PaymentFieldOption[];
  policyStartDate: string;
  product: Product;
  // TODO I think this can be only home and maybe second home
  coverageType: ProductName;
}

export const MortgagePayment: React.FC<Props> = (props) => {
  const { paymentOptions, product, policyStartDate, coverageType } = props;
  const { classes } = useStyles();

  const dispatch = useDispatch();
  const pniRef = usePniRef();
  const isMobile = useIsMobile();

  const fields = {
    knowMortgageCompany: useField(`static.checkout.${coverageType}.mortgageKnowMortgageCompany`),
    mortgageCompanyName: useField(`static.checkout.${coverageType}.mortgageCompany`),
    mortgageCompanyAddress: useField(`static.checkout.${coverageType}.mortgageAddressLine1`),
    mortgageCompanySuite: useField(`static.checkout.${coverageType}.mortgageAddressLine2`),
    mortgageCompanyCity: useField(`static.checkout.${coverageType}.mortgageCity`),
    mortgageCompanyState: useField(`static.checkout.${coverageType}.mortgageState`),
    mortgageCompanyZip: useField(`static.checkout.${coverageType}.mortgageZip`),
    mortgageLoanNumber: useField(`static.checkout.${coverageType}.mortgageLoanNumber`),
  };

  useEffectOnce(() => {
    const resetValue = '';
    onMortgageCompanyChange(resetValue);
    onMortgageCompanyAddressChange(resetValue);
    onMortgageCompanySuiteChange(resetValue);
    onMortgageCompanyCityChange(resetValue);
    onMortgageCompanyStateChange(resetValue);
    onMortgageCompanyZipChange(resetValue);
    onMortgageLoanNumberChange(resetValue);
  }, []);

  useAddFields({
    knowMortgageCompany: fields.knowMortgageCompany,
    mortgageCompanyAddress: fields.mortgageCompanyAddress,
    mortgageCompanyCity: fields.mortgageCompanyCity,
    mortgageCompanyZip: fields.mortgageCompanyZip,
    mortgageCompanyName: fields.mortgageCompanyName,
    mortgageCompanyState: fields.mortgageCompanyState,
    mortgageCompanySuite: fields.mortgageCompanySuite,
    mortgageLoanNumber: fields.mortgageLoanNumber,
  });

  const loanNumberValue = fields.mortgageLoanNumber.props.value;
  const [loanNumberDisplayValue, setLoanNumberDisplayValue] = useState<string | undefined>(
    loanNumberValue ? maskMortgageLoanNumber(loanNumberValue) : undefined,
  );

  const paymentOption = paymentOptions[0];
  const policyDurationDisplayText =
    paymentOption?.content.policyDuration === '6' ? '6 month policy total' : 'Yearly policy total';

  const onMortgageCompanyChange = useCallback(
    (value: string) => {
      if (value !== '') dispatch(setMortgage({ product, company: value, filled: true }));
      fields.mortgageCompanyName.validateUpdateAndPatch(value);
    },
    [dispatch, fields.mortgageCompanyName, product],
  );

  const onMortgageCompanyAddressChange = useCallback(
    (value: string) => {
      dispatch(setMortgage({ product, addressLine1: value }));
      fields.mortgageCompanyAddress.validateUpdateAndPatch(value);
    },
    [dispatch, fields.mortgageCompanyAddress, product],
  );

  const onMortgageCompanySuiteChange = useCallback(
    (value: string) => {
      dispatch(setMortgage({ product, addressLine2: value }));
      fields.mortgageCompanySuite.validateUpdateAndPatch(value);
    },
    [dispatch, fields.mortgageCompanySuite, product],
  );

  const onMortgageCompanyCityChange = useCallback(
    (value: string) => {
      dispatch(setMortgage({ product, city: value }));
      fields.mortgageCompanyCity.validateUpdateAndPatch(value);
    },
    [dispatch, fields.mortgageCompanyCity, product],
  );

  const onMortgageCompanyZipChange = useCallback(
    (value: string) => {
      dispatch(setMortgage({ product, zip: value }));
      fields.mortgageCompanyZip.validateUpdateAndPatch(value);
    },
    [dispatch, fields.mortgageCompanyZip, product],
  );

  const onMortgageCompanyStateChange = useCallback(
    (value: AnswerValue) => {
      dispatch(setMortgage({ product, state: value as string }));
      fields.mortgageCompanyState.validateUpdateAndPatch(value);
    },
    [dispatch, fields.mortgageCompanyState, product],
  );

  const onMortgageLoanNumberChange = useCallback(
    (value: string) => {
      let valueTrimmed = value.trim();

      if (isMasked(valueTrimmed)) {
        if (loanNumberDisplayValue && valueTrimmed.length === loanNumberDisplayValue?.length)
          return; // user clicks into field and leaves
        valueTrimmed = resetMaskedPaymentAccountNumber(valueTrimmed, loanNumberDisplayValue);
      }
      setLoanNumberDisplayValue(undefined);
      dispatch(setMortgage({ product, loanNumber: value }));
      fields.mortgageLoanNumber.validateUpdateAndPatch(value);
    },
    [dispatch, fields.mortgageLoanNumber, product, loanNumberDisplayValue],
  );

  const onLoanNumberBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const { value } = event.target;
      onMortgageLoanNumberChange(value);
      if (fields.mortgageLoanNumber.errors?.length) return;

      const maskedValue = maskMortgageLoanNumber(value.trim());
      setLoanNumberDisplayValue(maskedValue);
    },

    [onMortgageLoanNumberChange, fields.mortgageLoanNumber.errors?.length],
  );

  const getMortgageDetails = (): JSX.Element | null => {
    return (
      <>
        {MortgageRequest[product]?.required && (
          <GridItem topSpacing='lg' xs={12}>
            <RadioGroupWithOptions
              {...fields.knowMortgageCompany.props}
              className={classes.mortgageOption}
              id='KnowMortgageCompany'
              trackingName='know_mortgage_company'
              trackingLabel='know_mortgage_company'
              variant='yesNoButton'
              label={<p className={classes.label}>Do you know who your mortgage company is?</p>}
              cardSize='small'
            />
          </GridItem>
        )}
        {fields.knowMortgageCompany.value === false && (
          <GridItem xs={12} topSpacing='lg'>
            <p className={classes.content}>
              It&apos;s ok if you don&apos;t have the mortgage company name right now, but be sure
              to update this information by {formatDate(policyStartDate)}. You can do this after
              purchase in your online account or by calling us at{' '}
              <PhoneLink withUnderlinedLinkStyle number={partner.shared.salesPhoneNumber} />
            </p>
          </GridItem>
        )}
        {MortgageRequest[product].required && fields.knowMortgageCompany.value === false && (
          <GridItem xs={12} topSpacing='xs'>
            <p className={classes.subTitle}>
              In case we have any questions, what is your phone number?
            </p>
            <PhoneNumberQuestion
              id='phonenumber'
              personRef={pniRef}
              trackingName='dont_know_mortgage_company_phone_number'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
              required
              fullWidth={false}
            />
          </GridItem>
        )}

        {fields.knowMortgageCompany.value && (
          <FormControl component='fieldset' className={classes.fieldset}>
            <Grid container>
              <GridItem topSpacing='lg'>
                <FormLabel component='legend' focused={false} className={classes.label}>
                  Tell us about your mortgage company
                </FormLabel>
              </GridItem>

              <GridItem topSpacing='xs' xs={12}>
                <TextField
                  id='mortgageCompanyName'
                  label='Mortgage company name'
                  ariaLabel='Mortgage company name'
                  {...fields.mortgageCompanyName.props}
                  actionOnChange={onMortgageCompanyChange}
                  trackingName='mortgage_company_name'
                  trackingLabel={GoogleAnalyticsLabels.REDACTED}
                />
              </GridItem>
              <GridItem topSpacing='sm' xs={12}>
                <TextField
                  id='mortgageLoanNumber'
                  label='Loan number (optional)'
                  ariaLabel='Mortgage loan number'
                  {...fields.mortgageLoanNumber.props}
                  actionOnChange={onMortgageLoanNumberChange}
                  onBlur={onLoanNumberBlur}
                  value={loanNumberDisplayValue ?? fields.mortgageLoanNumber.value}
                  trackingName='mortgage_loan_number'
                  trackingLabel={GoogleAnalyticsLabels.REDACTED}
                />
              </GridItem>
              <GridItem topSpacing='sm' xs={12}>
                <TextField
                  id='mortgageCompanyAddress'
                  label='Address (optional)'
                  ariaLabel='Address'
                  {...fields.mortgageCompanyAddress.props}
                  actionOnChange={onMortgageCompanyAddressChange}
                  trackingName='mortgage_company_address_line1'
                  trackingLabel={GoogleAnalyticsLabels.REDACTED}
                />
              </GridItem>
              <GridItem topSpacing='sm' xs={12}>
                <TextField
                  id='mortgageCompanySuite'
                  label='Suite/unit # (optional)'
                  ariaLabel='Suite/Unit #'
                  {...fields.mortgageCompanySuite.props}
                  actionOnChange={onMortgageCompanySuiteChange}
                  trackingName='mortage_company_apt'
                  trackingLabel={GoogleAnalyticsLabels.REDACTED}
                />
              </GridItem>
              <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
                <TextField
                  id='mortgageCompanyCity'
                  label='City (optional)'
                  ariaLabel='City'
                  {...fields.mortgageCompanyCity.props}
                  actionOnChange={onMortgageCompanyCityChange}
                  trackingName='mortgage_company_city'
                  trackingLabel={GoogleAnalyticsLabels.REDACTED}
                />
              </GridItem>
              <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnRight}>
                <Select
                  id='mortgageCompanyState'
                  label='State (optional)'
                  inputAriaLabel='State'
                  inputButtonAriaLabel='State'
                  {...(fields.mortgageCompanyState.props as OptionProps)}
                  actionOnChange={onMortgageCompanyStateChange}
                  trackingName='mortgage_company_state'
                  trackingLabel={GoogleAnalyticsLabels.REDACTED}
                />
              </GridItem>
              <GridItem topSpacing='sm' xs={12}>
                <NumberFormat
                  id='mortgageCompanyZip'
                  label='Zip code (optional)'
                  {...fields.mortgageCompanyZip.props}
                  actionOnChange={onMortgageCompanyZipChange}
                  trackingName='mortgage_company_zip'
                  trackingLabel={GoogleAnalyticsLabels.REDACTED}
                  formatType='zipcode'
                />
              </GridItem>
            </Grid>
          </FormControl>
        )}
      </>
    );
  };

  return (
    <div className={isMobile ? classes.mortgageMobilePayment : classes.mortgagePayment}>
      <GridItem bottomSpacing={15}>
        <p className={classes.title}>Mortgage - premium details</p>
        {!env.static.isAgent && (
          <p>
            Pay through your home's mortgage escrow account. We'll connect with your mortgage lender
            to help ensure that your payments are processed on time.
          </p>
        )}
      </GridItem>
      <Grid item xs={12} md={5}>
        <SingleLineDetail
          text={policyDurationDisplayText}
          value={parseDollar(paymentOption?.content.totalAmt)}
        />
        <SingleLineDetail text='Due today' value={parseDollar('0')} />
        <SingleLineDetail text='Policy start date' value={formatDate(policyStartDate)} />
      </Grid>
      {getMortgageDetails()}
    </div>
  );
};
