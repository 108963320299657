import { forwardRef } from 'react';

import { useEvent } from '@ecp/utils/react';

import { Button as ButtonBase } from '@ecp/components';
import type { ButtonProps } from '@ecp/components';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

type Props = ButtonProps & {
  analyticsElement?: string;
  analyticsEventDetail?: string;
};

export const Button: React.FC<Props> = forwardRef((props, ref) => {
  const { onClick, analyticsElement, analyticsEventDetail, ...rest } = props;

  const handleButtonClick: ButtonProps['onClick'] = useEvent(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (analyticsElement) {
        trackSapiAnalyticsEvent({
          element: analyticsElement,
          event: 'click',
          eventDetail: analyticsEventDetail || 'true',
        });
      }
      onClick?.(event);
    },
  );

  return <ButtonBase {...rest} ref={ref} onClick={handleButtonClick} />;
});
