import { useCallback, useMemo, useState } from 'react';

import { GoogleAnalyticsLabels, trackClick } from '@ecp/utils/analytics/tracking';
import { emptyArray } from '@ecp/utils/common';

import { useAddFields } from '@ecp/features/sales/form';
import {
  ADVISOR_FIRSTNAME,
  ADVISOR_LASTNAME,
  ADVISOR_MIDDLENAME,
  ADVISOR_NAME,
  ADVISOR_NUMBER,
  ADVISOR_STATE,
} from '@ecp/features/sales/shared/ameriprise-advisor/constants';
import type { AmeripriseAdvisor } from '@ecp/features/sales/shared/ameriprise-advisor/types';
import { STATE_CODE_PREFIX } from '@ecp/features/sales/shared/constants';
import { deleteAnswers, updateAnswers, useField } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { Field } from '@ecp/types';

import { fetchAdvisorsByLastName } from './api';
import { composeAdvisorValue } from './composeAdvisorValue';

interface AdvisorOptions {
  advisorName: Field;
  advisorNumber: Field;
  autoCompleteSuggestions: string[];
  handleSuggestionSelectedRequested(
    value: string,
    suggestions?: AmeripriseAdvisor[],
  ): Promise<void>;
  handleSuggestionsFetchRequested(lastName: string): Promise<AmeripriseAdvisor[]>;
  handleSuggestionsClearRequested(): void;
}

const OTHER_SUGGESTION: AmeripriseAdvisor = {
  advisorNumber: '',
  firstName: 'Other',
  lastName: '',
  middleName: undefined,
  stateCode: '',
};

/** This custom hook supports autocomplete component for advisor search */
export const useAdvisorNameSearch = (): AdvisorOptions => {
  const dispatch = useDispatch();

  const advisorNumber = useField(ADVISOR_NUMBER);
  const advisorFirstName = useField(ADVISOR_FIRSTNAME);
  const advisorLastName = useField(ADVISOR_LASTNAME);
  const advisorMiddleName = useField(ADVISOR_MIDDLENAME);
  const advisorState = useField(ADVISOR_STATE);
  const advisorName = useField(ADVISOR_NAME);

  const [selectedAdvisor, setSelectedAdvisor] = useState<string | null>(advisorNumber.props.value);

  useAddFields({
    advisorNumber,
    advisorFirstName,
    advisorLastName,
    advisorMiddleName,
    advisorState,
    advisorName,
  });

  const [advisorSuggestions, setAdvisorSuggestions] = useState<AmeripriseAdvisor[]>([]);

  const handleSuggestionSelectedRequested: AdvisorOptions['handleSuggestionSelectedRequested'] =
    useCallback(
      async (value, suggestions = advisorSuggestions) => {
        const selectedSuggestion = suggestions.find(
          (suggestion) => value === composeAdvisorValue(suggestion),
        );
        if (selectedSuggestion) {
          trackClick({ action: 'AdvisorLastName', label: GoogleAnalyticsLabels.REDACTED });
          setSelectedAdvisor(selectedSuggestion.advisorNumber);
          await dispatch(
            updateAnswers({
              answers: {
                [ADVISOR_NUMBER]: selectedSuggestion.advisorNumber,
                [ADVISOR_FIRSTNAME]: selectedSuggestion.firstName,
                [ADVISOR_LASTNAME]: selectedSuggestion.lastName,
                [ADVISOR_MIDDLENAME]: selectedSuggestion.middleName,
                [ADVISOR_STATE]:
                  selectedSuggestion.stateCode &&
                  `${STATE_CODE_PREFIX}${selectedSuggestion.stateCode}`,
              },
            }),
          );

          return;
        }

        // nullify static key if a user manually types in a name and doesn't pick "Other"
        if (advisorName.value && advisorName.value !== 'Other' && !selectedAdvisor) {
          advisorName.props.actionOnComplete(null);

          return;
        }

        // nullify all advisor answers when the value in input filed is empty
        // nullify if values were not picked from the advisorSuggestions list
        if (!value || (value && suggestions.length > 0 && !selectedSuggestion)) {
          setSelectedAdvisor(null);
          advisorName.props.actionOnComplete(null);
          await dispatch(
            deleteAnswers({
              keys: [
                ADVISOR_NUMBER,
                ADVISOR_FIRSTNAME,
                ADVISOR_LASTNAME,
                ADVISOR_MIDDLENAME,
                ADVISOR_STATE,
              ],
            }),
          );

          return;
        }
      },
      [advisorName, advisorSuggestions, dispatch, selectedAdvisor],
    );

  const handleSuggestionsFetchRequested: AdvisorOptions['handleSuggestionsFetchRequested'] =
    useCallback(async (value) => {
      const trimmedValue = value.trim();
      let suggestions: AmeripriseAdvisor[] = [];
      if (trimmedValue.length >= 2) {
        suggestions = await fetchAdvisorsByLastName(value);
        suggestions.unshift(OTHER_SUGGESTION);
      }
      setAdvisorSuggestions(suggestions);

      return suggestions;
    }, []);

  const handleSuggestionsClearRequested: AdvisorOptions['handleSuggestionsClearRequested'] =
    useCallback(() => {
      setAdvisorSuggestions(emptyArray as unknown as AmeripriseAdvisor[]);
    }, []);

  const autoCompleteSuggestions = useMemo(
    () => advisorSuggestions.map(composeAdvisorValue),
    [advisorSuggestions],
  );

  return {
    advisorName,
    advisorNumber,
    autoCompleteSuggestions,
    handleSuggestionSelectedRequested,
    handleSuggestionsFetchRequested,
    handleSuggestionsClearRequested,
  };
};
