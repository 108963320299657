import { makeStyles } from '@ecp/themes/base';

import type { Props } from './Accordion';

export const useStyles = makeStyles<Props>({ name: 'Accordion' })((...[, { spacing }]) => ({
  root: {
    '& > *': {
      marginBottom: spacing ?? 16,
    },
    '& > *:last-child': {
      marginBottom: 0,
    },
  },
}));
