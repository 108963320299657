import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'HomeInteriorForm' })((theme) => ({
  ...theme.mixins.formAll,
  root: theme.mixins.form,
  labelNoMargin: {
    marginTop: 0,
  },
  showMoreOrLessPanel: {
    display: 'flex',
  },
  addHeatSourceBtn: {
    marginBottom: 20,
  },
  modalDiv: {
    margin: '15px 0',
  },
  tableContainer: {
    ...theme.mixins.tableContainer,
    marginTop: 30,
    marginBottom: 30,
  },
  firstRow: {
    ...theme.mixins.tableFirstRow,
    '& th': {
      padding: 10,
      overflowWrap: 'anywhere',
      ...theme.typography.h4,
    },
  },
  tableBody: {
    ...theme.mixins.simpleTableBody,
    '& tr td': {
      padding: 10,
      lineHeight: '22px',
    },
  },
  action: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  actionItem: {
    paddingRight: 10,
  },
  error: {
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: -30,
    fontStyle: 'italic',
    fontSize: 12,
    fontWeight: 400,
  },
  checkIcon: {
    color: theme.palette.success.complete,
    display: 'block',
  },
  clearIcon: {
    color: theme.palette.error.main,
    display: 'block',
  },
  nextButton: {
    ...theme.mixins.nextButton,
    marginTop: 20,
  },
}));
