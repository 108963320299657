export interface Metadata {
  columnContentHeader: string;
  columnContentText: string;
  columnContentFooter: string;
}

const metadata: Metadata = {
  columnContentHeader: 'Do you want to let your landlord know?',
  columnContentText: `We’ll notify your landlord, property manager, or other third party when your place is covered so you don’t have to.`,
  columnContentFooter: 'We may notify your landlord or property manager if you cancel your policy.',
};

export default metadata;
