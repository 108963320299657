import { partner } from '@ecp/partners';

import { PhoneLink } from '../PhoneLink';

export const RVPErrorCustomReason: React.FC<unknown> = () => {
  return (
    <h2>
      Please give us a call at
      <PhoneLink number={partner.shared.salesPhoneNumber} withUnderlinedLinkStyle /> and be prepared
      to provide some information from the list below.
    </h2>
  );
};
