import { useCallback, useRef } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem } from '@ecp/components';
import { useGetConditionValues, useGetFields } from '@ecp/features/sales/form';
import { Button, Form, NextPageInstructions } from '@ecp/features/sales/shared/components';
import { useForm } from '@ecp/features/sales/shared/store';

import { MarketValueQuestion } from '../../components';
import { useStyles } from './HomeMarketValueForm.styles';

export interface HomeMarketValueFormProps {
  onNext: () => Promise<void>;
}

export const HomeMarketValueForm: React.FC<HomeMarketValueFormProps> = (props) => {
  const { onNext } = props;
  const { classes } = useStyles();
  const initValues = useRef({});
  const getFields = useGetFields();
  const getConditions = useGetConditionValues();

  const { isPatchFormInProgress, patchFormValues, validateForm } = useForm({
    initValues,
    fields: getFields(),
    conditions: getConditions(),
  });

  const handleSubmit = useCallback(async () => {
    if (validateForm().isValid) {
      await patchFormValues();
      await onNext();
    }
  }, [onNext, patchFormValues, validateForm]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container>
          <GridItem xs={12}>
            <MarketValueQuestion />
          </GridItem>
        </Grid>
        <Grid item xs={12}>
          <NextPageInstructions divider>
            Thanks. Next, you&apos;ll see your discounts.
          </NextPageInstructions>
        </Grid>
        <Grid item xs={12}>
          <Button
            data-testid='HomeMarketValueSubmit'
            className={classes.next}
            variant='primary'
            onClick={handleSubmit}
            isProcessing={isPatchFormInProgress}
            trackingName='HomeMarketValueSaveAndContinue'
            trackingLabel={GoogleAnalyticsLabels.CONTINUE}
          >
            Save & continue
          </Button>
        </Grid>
      </Form>
    </div>
  );
};
