import { useCallback } from 'react';

import { Button, Tooltip } from '@ecp/components';

import { useStyles } from './EstimatedTooltip.styles';

type EstimatedTooltipProps = {
  inline?: boolean;
};

export const EstimatedTooltip = ({ inline = false }: EstimatedTooltipProps): React.ReactElement => {
  const { cx, classes } = useStyles();
  const handleTooltipClick = useCallback((event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  return (
    <Tooltip placement='top' title='We need a few more pieces of information to finalize your rate'>
      <Button
        className={cx(classes.estimated, { [classes.inline]: inline })}
        variant='iconTextMedium'
        onClick={handleTooltipClick}
        onMouseDown={handleTooltipClick}
        onMouseUp={handleTooltipClick}
      >
        (estimated)
      </Button>
    </Tooltip>
  );
};
