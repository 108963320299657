import { useAddFields } from '@ecp/features/sales/form';
import { TextField } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const RoofMaterialDescriptionQuestion: React.FC<QuestionProps> = (props) => {
  const useRoofPropertyField = useFieldWithPrefix('property.roof', 'property.roof');
  const roofMaterialDescription = useRoofPropertyField('materialDescription');
  const roofMaterial = useRoofPropertyField('material');
  const {
    trackingName = 'roof_material_description',
    groupLabel = roofMaterialDescription.question?.title,
  } = props;

  const displayRoofMaterialDescription =
    roofMaterial.exists &&
    roofMaterial.props.value &&
    roofMaterial.props.value === 'OTHER_EXT' &&
    roofMaterialDescription.exists;

  useAddFields({ roofMaterialDescription });

  if (!displayRoofMaterialDescription) return null;

  return (
    <TextField
      {...roofMaterialDescription.props}
      ariaLabel='Roof Material Description'
      helperText={roofMaterialDescription.question.helpText}
      label={groupLabel}
      trackingName={trackingName}
    />
  );
};
