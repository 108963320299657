import { Stack } from '@mui/material';

import { isDateGreaterThanLimitDate } from '@ecp/utils/date';

import { Alert } from '@ecp/components';
import { getAutoPolicyStartDate } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

interface Props {
  className?: string;
}

// TODO: Remove this notice post Jan 1, 2025
export const CoverageLimitIncreaseNotice: React.FC<Props> = ({ className }) => {
  const autoPolicyStartDate = useSelector(getAutoPolicyStartDate);
  // EDSP-12641 Show the warning when the selected date is greater than Dec 31, 2024
  // Auto Policy Start Date format is YYYY-MM-DD
  const isGreaterThanLimitDate = isDateGreaterThanLimitDate(autoPolicyStartDate, '2024-12-31');

  return (
    <>
      {isGreaterThanLimitDate && (
        <Stack spacing='10px' direction='column' className={className}>
          <Alert type='info'>
            <p>
              On January 1, 2025, a new state law requires an increase in the minimum limits for
              motor vehicle liability coverage, so your limits may be adjusted. Please review the
              ‘Coverages for everyone on your policy’ section.
            </p>
          </Alert>
        </Stack>
      )}
    </>
  );
};
