import { useMemo } from 'react';

import { Card, CardContent, Grid, Step, StepLabel } from '@mui/material';

import { useLocation } from '@ecp/utils/routing';

import { PagePath } from '@ecp/features/sales/shared/routing';
import {
  getAggregatePathTrackingStatus,
  getNavTracking,
  getPageFlowPaths,
  getPniPersonInfo,
  getPrimaryInsuredAddressInfo,
} from '@ecp/features/sales/shared/store';
import type { PageFlow } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { useStyles } from '../NavbarDrawer';
import metadata from '../NavbarDrawer/metadata';
import { getNavbarStatus, getPersonalInformation } from '../navigationUtil';
import { StepIcon } from '../StepIcon';

export interface PersonalInfoNavbarProps {
  activeRootStepNumber: number;
  introPageFlow: PageFlow;
  jumpInNavbar: (pagePth: string) => void;
}

export const PersonalInfoNavbar: React.FC<PersonalInfoNavbarProps> = (props) => {
  const { introPageFlow, activeRootStepNumber, jumpInNavbar } = props;
  const { classes, cx } = useStyles();
  const { removeOrDisableStep } = metadata;
  const personInformation = useSelector(getPniPersonInfo);
  const addressInformation = useSelector(getPrimaryInsuredAddressInfo);
  const navTracking = useSelector(getNavTracking);

  const navTrackingStatus = useMemo(
    () => getAggregatePathTrackingStatus(navTracking, getPageFlowPaths(introPageFlow)),
    [introPageFlow, navTracking],
  );
  const location = useLocation();

  return (
    <Card className={classes.cardMargin}>
      <CardContent className={classes.cardPadding}>
        <div>
          {introPageFlow.value?.menuItems?.map((menuItem) => {
            const { active, completed } = getNavbarStatus(navTrackingStatus);

            const step = (
              <Step key={menuItem.text} completed={completed} expanded>
                <StepLabel
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={
                    activeRootStepNumber <= 0 ? undefined : () => jumpInNavbar(menuItem.path)
                  }
                >
                  <Grid container justifyContent='flex-start'>
                    <Grid item className={classes.iconPadding}>
                      <StepIcon
                        active={active || location.pathname === PagePath.PERSON_ADDRESS}
                        completed={completed}
                      />
                    </Grid>
                    <Grid
                      item
                      aria-disabled={!completed}
                      className={cx(
                        classes.columnLeft,
                        classes.labelText,
                        active && classes.activeStep,
                        completed && classes.labelLink,
                        removeOrDisableStep && classes.progressiveLabel,
                      )}
                    >
                      {menuItem.text}
                    </Grid>
                  </Grid>
                </StepLabel>
                {personInformation.firstName && (
                  <Grid container>
                    <Grid item className={classes.content}>
                      {getPersonalInformation(personInformation, addressInformation)}
                    </Grid>
                  </Grid>
                )}
              </Step>
            );

            return step;
          })}
        </div>
      </CardContent>
    </Card>
  );
};
