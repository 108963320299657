import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({
  name: 'IndicativeQuoteWarning',
})((theme) => ({
  indicativeQuoteWarning: {
    margin: 0,
    [theme.breakpoints.down('lg')]: {
      margin: '5px auto 0px',
    },
  },
  indicativeQuoteWarningIcon: {
    height: 24,
    width: 24,
    minWidth: 24,
    ...theme.mixins.setColorSvg(theme.palette.warning.dark),
  },
  carret: {
    width: 0,
    height: 0,
    borderLeft: '20px solid transparent',
    borderRight: '20px solid transparent',
    borderTop: `20px solid ${theme.palette.warning.main}`,
    margin: '-2px auto 0px',
    position: 'relative',
    '&:after': {
      content: '""',
      width: 0,
      height: 0,
      borderLeft: '21px solid transparent',
      borderRight: '21px solid transparent',
      borderTop: `21px solid ${theme.palette.warning.light}`,
      position: 'absolute',
      top: -24,
      left: -21,
    },
  },
}));
