import { useCallback } from 'react';

import {
  getAnswers,
  getAutoPrefillFlow,
  getHomePrefillFlow,
  getLineOfBusiness,
  jumpToPage,
  questionExists,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

import { prefillFlowWait } from './pageFlowRouting';
import type { PageFlowParams } from './types';

export const useNavigateToPage = (
  page: string,
  {
    replace = false,
    removeQuery = false,
    replaceProfileAdd = false,
    skipLogPageView = false,
    addErrorHash = false,
    skipError = false,
  }: {
    replace?: boolean;
    removeQuery?: boolean;
    replaceProfileAdd?: boolean;
    skipLogPageView?: boolean;
    addErrorHash?: boolean;
    skipError?: boolean;
  } = {
    replace: false,
    removeQuery: false,
    replaceProfileAdd: false,
    skipLogPageView: false,
    addErrorHash: false,
    skipError: false,
  },
): (() => Promise<void>) => {
  const dispatch = useDispatch();

  return useCallback(
    () =>
      // save the current page to the store.
      // This helps if agent wants to go back to last visited page after authentication
      dispatch(
        jumpToPage({
          page,
          replace,
          title: document.title,
          removeQuery,
          replaceProfileAdd,
          skipLogPageView,
          addErrorHash,
          skipError,
        }),
      ),
    [
      dispatch,
      page,
      replace,
      removeQuery,
      replaceProfileAdd,
      skipLogPageView,
      addErrorHash,
      skipError,
    ],
  );
};

const usePageFlowParams = (): PageFlowParams => {
  const lineOfBusiness = useSelector(getLineOfBusiness);
  const hasMarketValueQuestion = useSelector((state: RootStore) =>
    questionExists('property.marketValue')(state),
  );
  const autoPrefillFlow = useSelector(getAutoPrefillFlow);
  const homePrefillFlow = useSelector(getHomePrefillFlow);
  const answers = useSelector(getAnswers);

  const { recentLifeEvent, residencyType } = answers;

  return {
    lineOfBusiness,
    hasRecentLifeEvent: recentLifeEvent as boolean,
    hasResidencyOwnership: residencyType === 'RESIDENCY_TYPE.OWN',
    autoPrefillFlow,
    homePrefillFlow,
    hasMarketValueQuestion,
  };
};

export const usePrefillFlowDetermined = (): boolean => {
  const params = usePageFlowParams();
  const result = prefillFlowWait(params);

  return result.noWait;
};
