import type { LineOfBusiness } from '@ecp/features/shared/product';
import { partner } from '@ecp/partners';

export interface MetadataItem {
  columnContentHeader: string;
  columnContentText: string;
}

export type Metadata = {
  [lob in LineOfBusiness]?: MetadataItem;
} & {
  default: MetadataItem;
};

const defaultMetadata: MetadataItem = {
  columnContentHeader: 'Access your policies online',
  columnContentText: `Sign up to access your ${partner.shared.partnerDisplayName} policies 24/7. Once you create
your account, you can make any policy changes or file a claim online.`,
};

const metadata: Metadata = {
  default: defaultMetadata,
};

export default metadata;
