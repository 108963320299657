import { useCallback, useEffect, useRef, useState } from 'react';

import { Divider, Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { pick } from '@ecp/utils/common';

import { Button } from '@ecp/features/sales/shared/components';
import type { Field, SubCoverages } from '@ecp/types';

import { useStyles } from './HealthInsurerQuestion.styles';

export interface HealthInsurerQuestionProps {
  subCoverages: SubCoverages;
  pips: string[];
  displaySubCoverage: (
    key: string,
    subCoverageField: Field | undefined,
    subCoverageTitle: string,
    subCoverageFieldId: string,
    primaryText: string | null | undefined,
    trackingName: string,
    trackingLabel: string,
  ) => React.ReactElement;
}

function canShowSecondHealthInsuer(subCoverages: SubCoverages, pips: string[]): boolean {
  if (subCoverages && pips[3] && pips[4]) {
    const name2 = subCoverages[pips[3]]?.field?.value;
    const policy2 = subCoverages[pips[4]]?.field?.value;
    if (name2 || policy2) {
      return true;
    }
  }

  return false;
}

export const HealthInsurerQuestion: React.FC<HealthInsurerQuestionProps> = (props) => {
  const { subCoverages, pips, displaySubCoverage } = props;
  const { classes } = useStyles();

  const firstRender = useRef(true);

  const [showSecondHealthInsurer, setShowSecondHealthInsurer] = useState(
    canShowSecondHealthInsuer(subCoverages, pips),
  );

  const handleAddRemoveButtonClick = useCallback(() => {
    setShowSecondHealthInsurer((prev) => !prev);
  }, []);

  const addRemoveButtonText = showSecondHealthInsurer
    ? '- Remove the second health insurer'
    : '+ Add another health insurer';

  // reset the second health Insurer when they are removed
  const resetHealthInsurerAnswer = useCallback(async () => {
    if (showSecondHealthInsurer === false && !firstRender.current) {
      let field = subCoverages[pips[3]]?.field;
      if (field) {
        field.props.actionOnChange('');
        // change the Field.value key directly so that getPatchFormValues() will always get the updated field value
        field.value = '';
        await field.props.actionOnComplete('');
      }

      field = subCoverages[pips[4]]?.field;
      if (field) {
        field.props.actionOnChange('');
        // change the Field.value key directly so that getPatchFormValues() will always get the updated field value
        field.value = '';
        await field.props.actionOnComplete('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSecondHealthInsurer]);

  useEffect(() => {
    if (!firstRender.current) resetHealthInsurerAnswer();
  }, [resetHealthInsurerAnswer]);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  if (!subCoverages[pips[0]]) return null;

  const pipSubcoverages = pick(subCoverages, pips); // pick pip related subcoverages
  if (!pipSubcoverages[pips[0]]) {
    return null;
  }

  const {
    field: pipSubCoverageField,
    title: pipSubCoverageTitle,
    primaryText: pipPrimaryText,
  } = pipSubcoverages[pips[0]] || {};

  const trackingName = '';
  const trackingLabel = '';

  // dispaly health insurer related fields
  // TODO: Maybe could use a more generic way
  const { field: insurerName1Field, title: insurerName1Title } = pipSubcoverages[pips[1]] || {};
  const { field: insurerPolicyNumber1Field, title: insurerPolicyNumber1Title } =
    pipSubcoverages[pips[2]] || {};
  const { field: insurerName2Field, title: insurerName2Title } = pipSubcoverages[pips[3]] || {};
  const { field: insurerPolicyNumber2Field, title: insurerPolicyNumber2Title } =
    pipSubcoverages[pips[4]] || {};

  const valueOfPipPrimary = pipSubCoverageField?.props.value;
  // For ADA compliance test id 244, aria-labelledby, aria-describedby cannnot have space(' '), period('.') delimiters
  const pipSubCoverageFieldId = pipSubCoverageTitle.replace(/ /g, '');

  return (
    <>
      <div key={pips[0]} className={classes.subCoveragesRow}>
        {displaySubCoverage(
          pips[0],
          pipSubCoverageField,
          pipSubCoverageTitle,
          pipSubCoverageFieldId,
          pipPrimaryText,
          trackingName,
          trackingLabel,
        )}
      </div>
      {valueOfPipPrimary !== 'PIPAsPrimary' && (
        <div>
          <Divider aria-hidden='true' className={classes.divider} />
          <div>
            <div className={classes.healthInsurerHelpText}>
              Enter your health insurer name & policy number
            </div>
            <div className={classes.healthInsurerRequirement}>You can enter a maximum of 2</div>
          </div>
          <div className={classes.healthInsurerSet}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                {pipSubcoverages[pips[1]] &&
                  displaySubCoverage(
                    pips[1],
                    insurerName1Field,
                    insurerName1Title,
                    insurerName1Title.replaceAll(' ', ''),
                    null,
                    'PIP_Health_Insurer1',
                    GoogleAnalyticsLabels.REDACTED,
                  )}
              </Grid>
              <Grid item xs={12} md={6}>
                {pipSubcoverages[pips[2]] &&
                  displaySubCoverage(
                    pips[2],
                    insurerPolicyNumber1Field,
                    insurerPolicyNumber1Title,
                    insurerPolicyNumber1Title.replaceAll(' ', ''),
                    null,
                    'PIP_Health_Insurer_Policy_Number1',
                    GoogleAnalyticsLabels.REDACTED,
                  )}
              </Grid>
            </Grid>
          </div>
          <Button
            variant='iconText'
            onClick={handleAddRemoveButtonClick}
            className={classes.addRemoveButton}
          >
            {addRemoveButtonText}
          </Button>
          {showSecondHealthInsurer && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                {pipSubcoverages[pips[3]] &&
                  displaySubCoverage(
                    pips[3],
                    insurerName2Field,
                    insurerName2Title,
                    insurerName2Title.replaceAll(' ', ''),
                    null,
                    'PIP_Health_Insurer2',
                    GoogleAnalyticsLabels.REDACTED,
                  )}
              </Grid>
              <Grid item xs={12} md={6}>
                {pipSubcoverages[pips[4]] &&
                  displaySubCoverage(
                    pips[4],
                    insurerPolicyNumber2Field,
                    insurerPolicyNumber2Title,
                    insurerPolicyNumber2Title.replaceAll(' ', ''),
                    null,
                    'PIP_Health_Insurer_Policy_Number2',
                    GoogleAnalyticsLabels.REDACTED,
                  )}
              </Grid>
            </Grid>
          )}
        </div>
      )}
    </>
  );
};
