import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AgreementContent' })((theme) => ({
  agreementContent: {
    ...theme.typography.body1Italics,
    whiteSpace: 'pre-line',
    '& .MuiLink-root': {
      fontStyle: 'inherit',
    },
  },
}));
