import { Link } from '@ecp/features/sales/shared/components';
import {
  CAAutoDiscountsInformation,
  insuranceCreditScore,
  MNSurchargeDisclosure,
  NJAutoRights,
  NJBuyersGuide,
} from '@ecp/features/sales/shared/metadata';
import type { CarrierMessagingType } from '@ecp/features/sales/shared/types';
import type { Product } from '@ecp/features/shared/product';

// we pass premiums here to use when needed
// simply pass as an argument to use (consentToRate: (statePremium: string, ourPremium: string))
export const carrierMessagingMetadata: Partial<
  Record<Product, Record<string, CarrierMessagingType>>
> = {
  'homesite.home': {
    fortifiedRoofUpgradeCoverageFlag: {
      name: 'Fortified Roof Coverage Availability',
      message: () => {
        return <>We also offer FORTIFIED roof upgrade coverage. For details, please contact us.</>;
      },
    },
    coverageCancellationDisclaimer: {
      name: 'Coverage Cancellation Disclaimer',
      message: () => {
        return (
          <strong>
            THE INSURER MAY ELECT TO CANCEL COVERAGE AT ANY TIME DURING THE FIRST 59 DAYS FOLLOWING
            ISSUANCE OF THE COVERAGE FOR ANY REASON WHICH IS NOT SPECIFICALLY PROHIBITED BY STATUTE.
          </strong>
        );
      },
      stateOptions: {
        SC: {
          message: () => {
            return (
              <strong>
                THE INSURER CAN CANCEL THIS POLICY FOR WHICH YOU ARE APPLYING WITHOUT CAUSE DURING
                THE FIRST 120 DAYS. THAT IS THE INSURER'S CHOICE. AFTER THE FIRST 120 DAYS, THE
                INSURER CAN ONLY CANCEL THIS POLICY FOR REASONS STATED IN THE POLICY.
              </strong>
            );
          },
        },
      },
    },
    consentToRate: {
      // TODO: consentToRate is only in NC for now. This should really have a state option which would require some logic.
      name: 'North Carolina Consent to Rate Information',
      message: (_state, statePremium, ourPremium) => {
        return (
          <>
            Notice: In accordance with G.S. 58-36-30(B1), your policy is currently being rated under
            North Carolina Consent to Rate regulations. The premium using North Carolina
            Bureau&#39;s rates for Homeowners insurance coverage applied for is {statePremium}. Our
            premium for this coverage is {ourPremium}.
          </>
        );
      },
    },
    creditDisclaimer: {
      name: 'Extraordinary life circumstance',
      message: () => {
        return (
          <>
            If your credit information has been adversely impacted by an extraordinary life
            circumstance that has occurred in the last 3 years, you may request in writing that we
            consider this when using your credit information. More information will be included with
            your policy documents.{' '}
            <Link href={insuranceCreditScore} target='_blank'>
              Learn more now
            </Link>
          </>
        );
      },
      stateOptions: {
        DE: {
          name: 'Credit Information',
          message: () => {
            return (
              <>
                If we do use a credit based score, you will have the right on an annual basis to
                request that we obtain your updated insurance score, based on a current credit
                report, to determine whether use of the new insurance score would result in a
                decrease in your insurance premium. If the new score would result in a decrease in
                your premium, we will make that reduction. If the new credit information would not
                reduce your premium, your credit report will not be used to impact your premium in
                any way.
              </>
            );
          },
        },
        VA: {
          message: () => {
            return (
              <>
                In connection with this application for insurance, we shall review your credit
                report or obtain or use an insurance credit score based on the information contained
                in that credit report. We may use a third party in connection with the development
                of your insurance credit score. You may request that your credit information be
                updated and if you question the accuracy of the credit information, we will, upon
                your request, reevaluate you based on corrected credit information from a consumer
                reporting agency.
              </>
            );
          },
        },
      },
    },
    personalInformation: {
      name: 'Personal Information Disclosure',
      message: () => null,
      stateOptions: {
        VT: {
          message: () => {
            return (
              <>
                We do not disclose, and do not wish to reserve the right to disclose, nonpublic
                personal information about customers or former customers to affiliates except as
                authorized under Sections 15, 16, and 17 of VT ADC 4-3-42.
              </>
            );
          },
        },
      },
    },
  },
  'amfam.auto': {
    noPriorInsurance: {
      name: 'No prior insurance',
      message: () => {
        return (
          <>
            There are some situations where a lack or lapse of insurance is legally permissible. If
            you think you were not required by law to carry insurance, please contact us as this
            information may impact your premium.
          </>
        );
      },
    },
    newHampshireResident: {
      name: 'Residency exception',
      message: () => {
        return (
          <>
            You’ll be asked to sign a NH Residency acknowledgement form prior to purchasing the
            policy. If you feel that you qualify for a NH residency exception, please contact us.
          </>
        );
      },
    },
    insuranceResources: {
      name: 'Insurance resources',
      message: () => {
        return (
          <>
            To learn more about insurance review the following documents:{' '}
            <Link href={NJAutoRights} target='_blank'>
              Consumer Bill of Rights
            </Link>{' '}
            |{' '}
            <Link href={NJBuyersGuide} target='_blank'>
              Buyers Guide
            </Link>
            .
          </>
        );
      },
    },
    basicPolicy: {
      name: 'Basic policy',
      message: () => {
        return (
          <>
            We currently don’t offer a Basic policy online. If you’re interested in a policy with
            this coverage, please contact us.
          </>
        );
      },
    },
    sevenDayPolicy: {
      name: '7 Day Policy',
      message: () => {
        return <>If you wish to purchase a 7-day policy, please contact us.</>;
      },
    },
    limitedLiabilityPolicy: {
      name: 'Limited liability',
      message: () => {
        return (
          <>
            We currently don’t offer a Limited Liability policy online. If you’re interested in a
            policy with this coverage, please contact us.
          </>
        );
      },
    },
    limitationsOnRightToSue: {
      name: 'Right to sue',
      message: () => {
        return (
          <>
            Kentucky law gives you the right to accept or reject limitations on your right to sue.
            Each member of the household has this choice.{' '}
            <p>
              If you accept limitations on your right to sue, your right to sue the at-fault party
              is limited unless your injury involves a broken bone, permanent disfigurement, medical
              expenses over $1,000, permanent injury, or death. With these injuries that exceed the
              No-Fault thresholds, you retain your right to sue for pain and suffering and expenses
              not included in No-Fault coverage.{' '}
            </p>{' '}
            If you reject limitations on your right to sue, you will have to prove the other party
            was at fault before you can recover. Your recovery will be reduced by any degree of
            fault on your part. You will not be entitled to receive No-Fault Benefits, unless you
            choose to buy this coverage. If you choose to reject limitations on your right to sue,
            you may receive an increase in your premium.
          </>
        );
      },
    },
    comprehensiveOnly: {
      name: 'Vehicle coverages',
      message: () => {
        return (
          <>
            If you’d like one of your insured vehicles to carry Comprehensive only coverage, please
            contact us. At least one vehicle on the policy must have Liability coverage. Exceptions
            may apply for military personnel.
          </>
        );
      },
    },
    limitedCollision: {
      name: 'Limited collision or excess attendant care coverage',
      message: () => {
        return (
          <>
            We currently don’t offer Limited Collision or Excess Attendant Care coverage online. If
            you’re interested in a policy with these coverages, please contact us.
          </>
        );
      },
    },
    combinedSingleLimitCoverage: {
      name: 'Combined single limit coverage',
      message: () => {
        return (
          <>
            If you elect to reject stacking of Uninsured Motorist Bodily Injury &#x28;UMBI&#x29; and
            Uninsured Motorist Property Damage &#x28;UMPD&#x29; coverage you may purchase a Combined
            Single Limit coverage. If you’re interested in a policy with this coverage, please
            contact us.
          </>
        );
      },
    },
    limitedCollisionAndAgreedAmount: {
      name: 'Limited collision',
      message: () => {
        return (
          <>
            <p>
              We currently don’t offer Agreed Amount or Limited Collision or Optional Bodily Injury
              coverages online. If you are interested in purchasing a policy with either of these
              coverages, please contact us.
            </p>
            <p>
              To learn more, review the following document:{' '}
              <Link href='https://www.mass.gov/doc/waystosavepdf/download' target='_blank'>
                Way to Save Brochure
              </Link>
            </p>
          </>
        );
      },
    },
    rentalVehiclePhysicalDamage: {
      name: 'Rental vehicle physical damage',
      message: () => {
        return (
          <>
            We currently don’t offer Rental Vehicle Physical Damage coverage online. If you’re
            interested in a policy with this coverage, please contact us.
          </>
        );
      },
    },
    consentToRate: {
      name: 'Rating Consent',
      message: () => {
        return (
          <>
            The Premium that we are charging for Automobile Physical Damage and related expenses
            that covers the damage to your covered vehicle(s) exceeds the Premium based upon the
            approved rates in North Carolina, in accordance with G.S. 58-36-30(b).
          </>
        );
      },
    },
  },
  'connect.auto': {
    SurchargeDisclosureMN: {
      name: 'Minnesota Surcharge Disclosure',
      message: () => {
        return (
          <p>
            You may review and print the Minnesota Surcharge Disclosure Statement at:{' '}
            <Link href={MNSurchargeDisclosure} target='_blank'>
              Minnesota Surcharge Disclosure
            </Link>
          </p>
        );
      },
    },
    AUTO_DISCOUNTS_QUALIFY_CA: {
      name: 'Auto Premium Discount Information.',
      message: () => {
        return (
          <>
            You may review and print{' '}
            <Link href={CAAutoDiscountsInformation} target='_blank'>
              Auto Premium Discount Information.
            </Link>
          </>
        );
      },
    },
    ADDITIONAL_COVERAGES_CA: {
      name: 'Coverage availability',
      message: () => {
        return (
          <>
            Coverages and their availability may vary. If you would like a quote with comprehensive
            and collision coverages, or lower financial responsibility limits, and they are not
            available online, please contact us. The information here only provides a general
            description of available coverages and is not a statement of contract. All coverages are
            subject to all policy provisions and applicable endorsements.
          </>
        );
      },
    },
  },
  'homesite.renters': {
    consentToRate: {
      // TODO: consentToRate is only in NC for now. This should really have a state option which would require some logic.
      name: 'North Carolina Consent to Rate Information',
      message: (_state, statePremium, ourPremium) => {
        return (
          <>
            Notice: In accordance with G.S. 58-36-30(b1), the premium based upon the approved rates
            in North Carolina for residential property insurance coverage applied for would be{' '}
            {statePremium}. Our premium for this coverage is {ourPremium}.
          </>
        );
      },
    },
    coverageCancellationDisclaimer: {
      name: 'Coverage Cancellation Disclaimer',
      message: () => null,
      stateOptions: {
        MN: {
          message: () => {
            return (
              <strong>
                THE INSURER MAY ELECT TO CANCEL COVERAGE AT ANY TIME DURING THE FIRST 59 DAYS
                FOLLOWING ISSUANCE OF THE COVERAGE FOR ANY REASON WHICH IS NOT SPECIFICALLY
                PROHIBITED BY STATUTE.
              </strong>
            );
          },
        },
        SC: {
          message: () => {
            return (
              <strong>
                THE INSURER CAN CANCEL THIS POLICY FOR WHICH YOU ARE APPLYING WITHOUT CAUSE DURING
                THE FIRST 120 DAYS. THAT IS THE INSURER'S CHOICE. AFTER THE FIRST 120 DAYS, THE
                INSURER CAN ONLY CANCEL THIS POLICY FOR REASONS STATED IN THE POLICY.
              </strong>
            );
          },
        },
      },
    },
    creditDisclaimer: {
      name: 'Extraordinary life circumstance',
      message: () => null,
      stateOptions: {
        WV: {
          message: () => {
            return (
              <>
                If your credit information has been adversely impacted by an extraordinary life
                circumstance that has occurred in the last 3 years, you may request in writing that
                we consider this when using your credit information. More information will be
                included with your policy documents.
              </>
            );
          },
        },
      },
    },
    personalInformation: {
      name: 'Personal Information Disclosure',
      message: () => null,
      stateOptions: {
        VT: {
          message: () => {
            return (
              <>
                We do not disclose, and do not wish to reserve the right to disclose, nonpublic
                personal information about customers or former customers to affiliates except as
                authorized under Sections 15, 16, and 17 of VT ADC 4-3-42.
              </>
            );
          },
        },
      },
    },
  },
};
