import { useAddFields } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

export const UnitsInBuildingQuestion: React.FC<QuestionProps> = (props) => {
  const {
    label = 'How many units are in the rental property?',
    trackingName = 'rental_property_units_selection',
  } = props;

  const unitsInBuilding = useField('property.numberOfUnits');

  const unitsInBuildingOptions = unitsInBuilding.props.options;

  useAddFields({ unitsInBuilding });

  if (!unitsInBuilding.exists) return null;

  return (
    <Select
      {...unitsInBuilding.props}
      label={label}
      options={unitsInBuildingOptions}
      id='units_in_building'
      trackingName={trackingName}
    />
  );
};
