import { useCallback } from 'react';

import { trackRender } from '@ecp/utils/analytics/tracking';

import { getSapiAnalyticsSelectedOfferEventDetail } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

import type { CheckoutCardProps } from '../CheckoutCard';
import { CheckoutCard } from '../CheckoutCard';

export const CheckoutCardWithAnalytics: React.FC<CheckoutCardProps> = (props) => {
  const sapiAnalyticsEventDetails = useSelector(getSapiAnalyticsSelectedOfferEventDetail);

  const trackRecalc = useCallback(() => {
    trackRender({ action: 'RecalculatedCoverages', label: sapiAnalyticsEventDetails });
    trackSapiAnalyticsEvent({
      element: 'choice.policyCoveragePage.recalcButton',
      event: 'click',
      eventDetail: sapiAnalyticsEventDetails,
    });
  }, [sapiAnalyticsEventDetails]);

  return (
    <CheckoutCard
      {...props}
      onRecalc={trackRecalc}
      recalcButtonTrackingLabel={sapiAnalyticsEventDetails}
    />
  );
};
