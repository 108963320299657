import type { Option } from '@ecp/types';

/**
 * @param fromYear first element in the result would be based on this value
 * @param endYear last element in the result would be based on this value
 * @returns an array of items of `Option` shape starting from `fromYear` to `endYear` inclusive.
 */
export const getYearsOptions = (fromYear: number, endYear: number): Option[] => {
  const isOrderAscending = fromYear < endYear;
  const yearsCount = isOrderAscending ? endYear - fromYear : fromYear - endYear;
  const options: Option[] = new Array(yearsCount);

  for (let i = 0; i <= yearsCount; ++i) {
    const yearString = String(isOrderAscending ? fromYear + i : fromYear - i);
    options[i] = { label: yearString, value: yearString };
  }

  return options;
};
