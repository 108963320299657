import { keyframes, makeStyles } from '@ecp/themes/base';
import {
  GraphicHomeCoverageAdditionalLivingExpenseImageUrl,
  GraphicHomeCoverageDeductibleImageUrl,
  GraphicHomeCoverageDwellingImageUrl,
  GraphicHomeCoverageIncreasedDwellingImageUrl,
  GraphicHomeCoveragePersonalInjuryImageUrl,
  GraphicHomeCoveragePersonalLiabilityImageUrl,
  GraphicHomeCoveragePersonalPropertyImageUrl,
  GraphicHomeDetailFlatImageUrl,
  GraphicHomeDetailGableImageUrl,
  GraphicHomeDetailGambrelImageUrl,
  GraphicHomeDetailHipImageUrl,
  GraphicHomeDetailMansardImageUrl,
  GraphicHomeDetailShedImageUrl,
  IconCardProtectionImageUrl,
} from '@ecp/themes/base';

export const imageUrls = [
  GraphicHomeDetailShedImageUrl,
  GraphicHomeDetailGableImageUrl,
  IconCardProtectionImageUrl,
  GraphicHomeDetailHipImageUrl,
  GraphicHomeDetailMansardImageUrl,
  IconCardProtectionImageUrl,
  GraphicHomeDetailGambrelImageUrl,
  GraphicHomeDetailShedImageUrl,
  IconCardProtectionImageUrl,
  GraphicHomeDetailFlatImageUrl,
  GraphicHomeDetailHipImageUrl,
  IconCardProtectionImageUrl,
  GraphicHomeCoverageDwellingImageUrl,
  GraphicHomeCoveragePersonalPropertyImageUrl,
  IconCardProtectionImageUrl,
  GraphicHomeCoveragePersonalLiabilityImageUrl,
  GraphicHomeCoverageDeductibleImageUrl,
  IconCardProtectionImageUrl,
  GraphicHomeCoveragePersonalInjuryImageUrl,
  GraphicHomeCoverageAdditionalLivingExpenseImageUrl,
  IconCardProtectionImageUrl,
  GraphicHomeCoverageIncreasedDwellingImageUrl,
  GraphicHomeCoveragePersonalLiabilityImageUrl,
  IconCardProtectionImageUrl,
];

const leftToRightMarquee = keyframes({
  '0%': {
    left: '0',
  },
  '100%': {
    left: '-100%',
  },
});

const rightToLeftMarquee = keyframes({
  '0%': {
    left: '-100%',
  },
  '100%': {
    left: '0',
  },
});

export const useStyles = makeStyles({ name: 'TransitionPage' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    ...theme.mixins.pageRoot,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  title: {
    marginTop: '200px',
    marginBottom: '10px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '20px',
    },
  },
  subTitle: {
    fontWeight: '400',
    marginBottom: '130px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '130px',
    },
  },
  marqueeWrapper: {
    display: 'block',
  },
  marqueeContainer: {
    overflow: 'hidden',
  },
  marqueeSpan: {
    float: 'left',
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  marqueeBlock: {
    height: '150px',
    overflow: 'hidden',
    boxSizing: 'border-box',
    position: 'relative',
    [theme.breakpoints.only('sm')]: {
      width: 'calc(130px * 10.5)',
      height: '120px',
    },
    [theme.breakpoints.up('md')]: {
      width: 'calc(250px * 8.8)',
      margin: '40px auto',
    },
  },
  topRow: {
    display: 'block',
    width: '200%',
    position: 'absolute',
    animation: [`${leftToRightMarquee} 15s linear infinite`],
  },
  bottomRow: {
    display: 'block',
    width: '200%',
    position: 'absolute',
    animation: [`${rightToLeftMarquee} 15s linear infinite`],
  },
  image: {
    width: '215px',
    height: '133.4px',
    float: 'left',
    transition: 'all .2s ease-out',
    margin: '0 20px',
    borderRadius: '8px',
    border: '1px',
    borderColor: theme.palette.grey[200],
    [theme.breakpoints.only('sm')]: {
      width: 120,
      height: 75,
    },
  },
  cardProtectionImage: {
    width: '40px',
    height: '40px',
    [theme.breakpoints.only('sm')]: {
      width: '20px',
      height: '20px',
    },
    float: 'left',
    transition: 'all .2s ease-out',
    alignItems: 'center',
    ...theme.mixins.setColorSvg(theme.palette.success.main),
  },
}));
