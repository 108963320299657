import { memo } from 'react';

import { Grid } from '@mui/material';

import { GridItem, NumberFormat } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { getField, getOfferProductsSelected } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { ProductName } from '@ecp/features/shared/product';
import { getProductNameFromProduct } from '@ecp/features/shared/product';
import type { Field } from '@ecp/types';

import { useStyles } from './PreviousPolicyInformationQuestions.styles';

export const usePreviousPremiumFields = (): Partial<
  Record<`previous.premium.${'six' | 'twelve'}Month.${ProductName}`, Field>
> => {
  const dispatch = useDispatch();
  const offerProductsSelected = useSelector(getOfferProductsSelected);

  return useSelector((state: RootStore) =>
    offerProductsSelected.reduce((acc, product) => {
      const productName = getProductNameFromProduct(product);
      const policyTerm = productName === 'auto' ? 'six' : 'twelve';
      const key = `previous.premium.${policyTerm}Month.${productName}` as const;
      const field = getField(state, { key, dispatch, isPostBindQuestion: true });
      if (field.exists) acc[key] = field;

      return acc;
    }, {} as ReturnType<typeof usePreviousPremiumFields>),
  );
};

export const PreviousPolicyInformationQuestions: React.FC = memo(() => {
  const { classes } = useStyles();
  const fields = usePreviousPremiumFields();
  useAddFields({ fields });

  const fieldsEntries = Object.entries(fields);

  if (!fieldsEntries.length) return null;

  return (
    <Grid container xs={12} className={classes.informationCard}>
      <GridItem topSpacing='sm' xs={12}>
        <h3>Previous policy information</h3>
      </GridItem>
      <GridItem topSpacing='xs' xs={12}>
        <Grid container columnSpacing='30px' rowSpacing='20px'>
          {Object.entries(fields).map(([key, field], index) => {
            const productName = key.substring(key.lastIndexOf('.') + 1);
            const policyTerm = productName === 'auto' ? 6 : 12;
            const label = `Previous ${policyTerm}-month ${productName} premium (optional)`;

            return (
              <Grid key={index} item xs={12} lg={6}>
                <NumberFormat
                  {...field?.props}
                  label={label}
                  helperText=''
                  ariaLabel={label}
                  prefix='$'
                  thousandSeparator
                  trackingName={`previous_${
                    policyTerm === 6 ? 'six' : 'twelve'
                  }_month_${productName}_premium_question`}
                />
              </Grid>
            );
          })}
        </Grid>
      </GridItem>
    </Grid>
  );
});
