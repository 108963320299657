import type { GridSize } from '@mui/material';
import { Divider, Grid } from '@mui/material';

import { isTruthy } from '@ecp/utils/common';

import { GridItem, HelpBox } from '@ecp/components';
import { SingleLineDetail, SummaryHeader } from '@ecp/features/sales/shared/components';
import type { PolicySummaryProps } from '@ecp/features/sales/shared/types';
import { GraphicUIAgentAvatarImageUrl } from '@ecp/themes/base';

import metadata from './metadata';
import { useStyles } from './PolicySummary.styles';

export const PolicySummary: React.FC<{
  policySummaries: PolicySummaryProps[];
  policyTitle?: string;
}> = (props) => {
  const { classes } = useStyles();
  const { policySummaries: policySummariesProp, policyTitle: policyTitleProp } = props;
  const policySummaries = policySummariesProp.filter(isTruthy);

  if (!policySummaries.length) return null;

  const showHelpBox = policySummaries.some((policySummary) => policySummary.showHelpBox);
  const coverageTypes = policySummaries.map((policySummary) => policySummary.coverageType);
  const coverageType = coverageTypes.length > 1 ? 'policies' : `${coverageTypes[0]} policy`;
  const policyTitle = policyTitleProp ?? `Your ${coverageType}`;

  const summaryTitleText =
    metadata.summaryTitleOpco !== ''
      ? `${metadata.summaryTitleOpco} ${coverageType}`
      : coverageType;

  const summaryTitle = `Here's a quick review of the ${summaryTitleText} you're purchasing today.`;

  const getPolicySummaryGridProps = (
    index: number,
    length: number,
  ): { className: string | undefined; md: GridSize } => {
    if (index + 1 === length && index % 2 === 0) return { className: undefined, md: 12 };

    return {
      className: index % 2 === 0 ? classes.columnLeft : classes.columnRight,
      md: 6,
    };
  };

  return (
    <>
      <h2 className={classes.policyTitle}>{policyTitle}</h2>
      <Divider aria-hidden='true' className={classes.divider} />
      <Grid container className={classes.mainGrid}>
        <GridItem topSpacing='sm' xs={12}>
          {showHelpBox && (
            <HelpBox
              image={GraphicUIAgentAvatarImageUrl}
              data-testid='helpBoxIcon'
              content={summaryTitle}
            />
          )}
          {!showHelpBox && <p>{summaryTitle}</p>}
        </GridItem>

        <Grid container>
          {policySummaries
            .filter((x) => x)
            .map((policySummary, index) => (
              <GridItem
                {...getPolicySummaryGridProps(index, policySummaries.length)}
                xs={12}
                topSpacing='sm'
                key={index}
              >
                <SummaryHeader
                  coverageType={policySummary.coverageType}
                  premiumAmount={policySummary.premiumAmount}
                  policyDuration={policySummary.policyDuration}
                  dueAmount={policySummary.dueAmount}
                  paymentType={policySummary.paymentType}
                />
                <Grid container>
                  {policySummary.items.map((singleItem, index) => (
                    <GridItem
                      topSpacing={index === 0 ? 'sm' : 'none'}
                      xs={12}
                      key={index}
                      className={classes.detail}
                    >
                      <SingleLineDetail
                        variant={singleItem.variant}
                        text={singleItem.text}
                        value={singleItem.value}
                        classes={{
                          leftWrapper: classes.leftWrapper,
                        }}
                      />
                    </GridItem>
                  ))}
                </Grid>
              </GridItem>
            ))}
        </Grid>
      </Grid>
    </>
  );
};
