import type { Answers } from '@ecp/features/sales/shared/types';
import type { SalesResponse } from '@ecp/features/sales/shared/utils/network';
import { salesRequest } from '@ecp/features/sales/shared/utils/network';

import type { PostBindResponse } from './types';

export const getPostBindQuestions = ({
  dalSessionId,
}: {
  dalSessionId: string;
}): Promise<SalesResponse<PostBindResponse>> => {
  return salesRequest({
    endpoint: `session/${dalSessionId}/offers/fulfillments`,
    options: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
};

export const postPostBindAnswers = ({
  dalSessionId,
  answers = {},
}: {
  dalSessionId: string;
  answers?: Answers;
}): Promise<SalesResponse<PostBindResponse>> => {
  const body = JSON.stringify(answers);

  return salesRequest({
    endpoint: `session/${dalSessionId}/offers/fulfillments`,
    options: {
      body,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
};
