import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AdditionalWildfireMitigationForm' })((theme) => ({
  root: {
    marginTop: 0,
  },
  title: {
    marginTop: 40,
    marginBottom: 20,
  },
}));
