import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'HomeMarketValueForm' })((theme) => ({
  ...theme.mixins.formAll,
  toolTip: {
    width: 8,
    height: 8,
    margin: '3px 0px 0px 4px',
    [theme.breakpoints.down('md')]: {
      margin: '-1px 0px 0px 4px',
    },
  },
  next: {
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: 20,
    },
  },
}));
