import { useCallback, useMemo } from 'react';

import { Toggle } from '@ecp/features/sales/shared/components';
import { SELECTED_PAYMENT_PLAN } from '@ecp/features/sales/shared/constants';
import {
  getQuestion,
  getSelectedPaymentPlan,
  updateAnswers,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

interface Props {
  size?: 'small' | 'medium' | 'large';
}

export const PremiumToggle: React.FC<Props> = (props) => {
  const { size } = props;
  const premiumPlan = useSelector(getSelectedPaymentPlan);
  const getSelectedPaymentPlanQuestion = useMemo(() => getQuestion(SELECTED_PAYMENT_PLAN), []);
  const paymentPlanQuestion = useSelector(getSelectedPaymentPlanQuestion);
  const paymentPlanOptions = paymentPlanQuestion.options;
  const options = paymentPlanOptions?.map((option) => option.label as string) ?? [];
  const value =
    (paymentPlanOptions?.find((option) => premiumPlan === option.value)?.label as string) ?? '';
  const dispatch = useDispatch();

  const handleToggleChange = useCallback(
    (...[, value]: [React.ChangeEvent<unknown>, string]) => {
      const planOption = paymentPlanOptions?.find((option) => value === option.label);
      if (planOption) {
        dispatch(updateAnswers({ answers: { [SELECTED_PAYMENT_PLAN]: planOption.value } }));
      }
    },
    [dispatch, paymentPlanOptions],
  );

  return (
    <Toggle
      size={size}
      options={options}
      onChange={handleToggleChange}
      value={value}
      trackingName='monthly_full_payment_selection'
    />
  );
};
