export interface Metadata {
  headerTitle: React.ReactElement | string;
  headerBody?: string;
  showAgentImage: boolean;
  showChatIcon: boolean;
  showPhoneIcon: boolean;
  hideHelpCard?: boolean;
  withUnderlineLinkStyle?: boolean;
}

const metadata: Metadata = {
  headerTitle: 'Need help?',
  headerBody: 'Licensed advisors can assist.',
  showAgentImage: false,
  showChatIcon: false,
  showPhoneIcon: false,
};

export default metadata;
