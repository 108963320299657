import type { PostBindRemindersMetadata } from '@ecp/features/sales/shared/types';

import { ExternalInspection } from '../components/PostBindReminders/ExternalInspection';
import { InternalInspection } from '../components/PostBindReminders/InternalInspection';
export const postBindRemindersMetadata: {
  [productKey: string]: PostBindRemindersMetadata;
} = {
  'homesite.home': {
    KeepCostcoMembershipUpdated: {
      title:
        'Make sure to keep your Costco membership information updated and let us know if you make any updates.',
    },
    EXTERNAL_INSPECTION: {
      title: 'We may conduct an inspection of your property.',
      DialogComponent: ExternalInspection,
      linkText: 'Learn how this could affect your policy.',
      trackingName: 'inspection_learn_more_link',
      trackingLabel: 'InspectionLearnMore',
    },
    INTERNAL_EXTERNAL_INSPECTION: {
      title: 'We may conduct an inspection of your property.',
      DialogComponent: InternalInspection,
      linkText: 'Learn how this could affect your policy.',
      trackingName: 'inspection_learn_more_link',
      trackingLabel: 'InspectionLearnMore',
    },
    ContactForAdditionalInfo: {
      title:
        'You’d like to update your mailing address, add an additional interest or named insured, or you’d like to add an additional designee (dependent on state availability).',
      stateOptions: {
        VA: {
          title:
            'You’d like to add a lienholder, a third party interest, a loss payable clause or you need to update your mailing address.',
        },
      },
    },
    insuranceCertification: {
      title:
        'You’d like an Insurance Certification (FR-19) sent to the Department of Motor Vehicles (DMV).',
    },
    businessUse: {
      title: 'Your vehicle is titled to a self-owned business, a corporate estate, or a trust.',
    },
    travelinkDiscount: {
      title:
        'Additional Discount: If you participate in the Delaware Travelink Car Pool, an employer-sponsored ride-sharing program, you may be eligible for a discount .',
    },
    militaryDiscount: {
      title:
        'Additional Discount:  If one of the following applies: 1) You are on active duty in the military and based in Louisiana 2) You are on active duty in the military, deployed out of state or overseas, and your spouse and/or dependents are living in Louisiana 3) You are a spouse or individual/dependent in the same household who meets the above criteria then you may be eligible for a discount.',
    },
    montanaNationalGuard: {
      title:
        'Additional Discount:  If you are a member of the Montana National Guard and have taken a defensive driving course, you may be eligible for a discount.',
    },
    financialResponsibility: {
      title: 'You need a Financial Responsibility filing (SR-22 or FR-44).',
    },
    excludeDriver: {
      title: 'You’d like to exclude a driver from this policy.',
    },
    excludeDriverFromPip: {
      title: 'You’d like to exclude driver(s) from Personal Injury Protection coverage.',
    },
    addressConfidentiality: {
      title:
        'If you are a participant in an address confidentiality program, please contact us for acknowledgements.',
    },
    occupancyChanges: {
      title:
        'It is your responsibility to immediately inform us of any changes to the occupancy or ownership of this property.',
    },
  },
  'amfam-adv.home': {
    KeepCostcoMembershipUpdated: {
      title:
        'Make sure to keep your Costco membership information updated and let us know if you make any updates.',
    },
    EXTERNAL_INSPECTION: {
      title: 'We may conduct an inspection of your property.',
      DialogComponent: ExternalInspection,
      linkText: 'Learn how this could affect your policy.',
      trackingName: 'inspection_learn_more_link',
      trackingLabel: 'InspectionLearnMore',
    },
    INTERNAL_EXTERNAL_INSPECTION: {
      title: 'We may conduct an inspection of your property.',
      DialogComponent: InternalInspection,
      linkText: 'Learn how this could affect your policy.',
      trackingName: 'inspection_learn_more_link',
      trackingLabel: 'InspectionLearnMore',
    },
    ContactForAdditionalInfo: {
      title:
        'You’d like to update your mailing address, add an additional interest or named insured, or you’d like to add an additional designee (dependent on state availability).',
      stateOptions: {
        VA: {
          title:
            'You’d like to add a lienholder, a third party interest, a loss payable clause or you need to update your mailing address.',
        },
      },
    },
    insuranceCertification: {
      title:
        'You’d like an Insurance Certification (FR-19) sent to the Department of Motor Vehicles (DMV).',
    },
    businessUse: {
      title: 'Your vehicle is titled to a self-owned business, a corporate estate, or a trust.',
    },
    travelinkDiscount: {
      title:
        'Additional Discount: If you participate in the Delaware Travelink Car Pool, an employer-sponsored ride-sharing program, you may be eligible for a discount .',
    },
    militaryDiscount: {
      title:
        'Additional Discount:  If one of the following applies: 1) You are on active duty in the military and based in Louisiana 2) You are on active duty in the military, deployed out of state or overseas, and your spouse and/or dependents are living in Louisiana 3) You are a spouse or individual/dependent in the same household who meets the above criteria then you may be eligible for a discount.',
    },
    montanaNationalGuard: {
      title:
        'Additional Discount:  If you are a member of the Montana National Guard and have taken a defensive driving course, you may be eligible for a discount.',
    },
    financialResponsibility: {
      title: 'You need a Financial Responsibility filing (SR-22 or FR-44).',
    },
    excludeDriver: {
      title: 'You’d like to exclude a driver from this policy.',
    },
    excludeDriverFromPip: {
      title: 'You’d like to exclude driver(s) from Personal Injury Protection coverage.',
    },
    addressConfidentiality: {
      title:
        'If you are a participant in an address confidentiality program, please contact us for acknowledgements.',
    },
    occupancyChanges: {
      title:
        'It is your responsibility to immediately inform us of any changes to the occupancy or ownership of this property.',
    },
  },
  'connect.auto': {
    DeathAndDisability: {
      title: 'If you would like to purchase Auto Death or Disability benefits, contact us.',
    },
    CARCO_NY: {
      title:
        'We require, at no cost, a vehicle inspection on any vehicle 6 years old or newer with physical damage coverage. This must be completed within 14 days of the policy start date. Contact CARCO at 888.242.1200 or search Carcogroup online to find a location near you.',
    },
    CARCO_NJ: {
      title:
        'We require, at no cost, a vehicle inspection on any vehicle 7 years old or newer with physical damage. This must be completed within 7 days of the policy start date. Contact CARCO at 888.242.1200 or search Carcogroup online to find a location near you.',
    },
    KeepCostcoMembershipUpdated: {
      title:
        'Make sure to keep your Costco membership information updated and let us know if you make any updates.',
    },
    MissingMvrPremiumCanChange: {
      title:
        'Your auto policy was purchased before we could obtain driving records. If the records indicate additional driving or claims activity, your premium may change or your policy may be canceled. We will notify you of any changes.',
    },
    PaymentDate: {
      title: 'The effective date of your auto policy is also your payment due date.',
    },
    CancelPreviousPolicy: {
      title:
        'You are responsible for cancellation of the policy with your previous insurance provider.',
    },
    IDCard: {
      title:
        'Your state does not allow identification cards to be available electronically and downloaded for use. Official identification cards will be mailed to you in the next few business days.  ',
    },
    GoodStudent: {
      title:
        'Proof of Good Student eligibility must be received within 14 days to maintain the discount, or the policy premium will increase.',
    },
    DefensiveDriver: {
      title:
        'Proof of Defensive Driver eligibility (certificate of completion that identifies the driver and a course completion date) must be received within 14 days to maintain the discount, or the policy premium will increase.',
    },
  },
  'amfam.auto': {
    autopayAndPaperlessNotice: {
      title:
        'In order to retain your paperless and/or auto pay discounts you must register for online billing and go paperless. If you do not register within ten business days, the discount(s) will be removed and the policy premium adjusted accordingly.',
    },
    contactForTpiNotice: {
      title:
        'Please contact us if you want to add a lienholder, third party interest, or if you need to update your mailing address.',
    },
    proofOfInsurance: {
      title:
        'I understand that the information contained within proof of insurance is provided to me as a courtesy and may not be accepted as valid proof of insurance in my state. Official proof of insurance cards will be mailed with your policy documents, and can also be accessed via MyAccount.',
    },
    proofOfInsuranceByMailOnly: {
      title:
        'Your state does not allow identification cards to be available electronically and downloaded for use. Official identification cards will be mailed to you with your paperwork in the next few business days.',
    },
    ContactForAdditionalInfo: {
      title:
        'You’d like to update your mailing address, add an additional interest or named insured, or you’d like to add an additional designee (dependent on state availability).',
      stateOptions: {
        VA: {
          title:
            'You’d like to add a lienholder, a third party interest, a loss payable clause or you need to update your mailing address.',
        },
      },
    },
    lossPayableClauseEndorsement: {
      title:
        'The loss payable clause endorsement provides loss or damage payment to the loss payee indicated on the declarations. This clause indicates that a third-party is to be provided any funds paid out for a loss. There is no premium charge for this endorsement. If you would like this coverage, please contact us.',
    },
    policyUpdateAfterMvrReview: {
      title:
        'Your auto policy was purchased prior to obtaining the driving records or a credit report. After the reports are obtained, your premium may change. Any adjustments will be communicated by mail.',
    },
    policyUpdateAfterCbrReview: {
      title:
        'Your auto policy was purchased prior to obtaining the driving records or a credit report. After the reports are obtained, your premium may change. Any adjustments will be communicated by mail.',
    },
    autopayNotice: {
      title:
        'In order to retain your Auto Pay discount you’ll need to create an online account and complete the Auto Pay enrollment.  If you do not register within ten business days, the discount will be removed and the policy premium adjusted accordingly.',
    },
    paperlessNotice: {
      title:
        'You’ll receive your initial policy documents via mail.  Once you’ve created an online account and completed the Paperless discount enrollment, future correspondence will be delivered electronically.',
    },
    autopayNoticeCC: {
      title:
        'In order to retain your Auto Pay discount you’ll need to create an online account, add a bank account, and complete the Auto Pay enrollment. If you do not register within ten business days, the discount will be removed and the policy premium adjusted accordingly.',
    },
    paperlessNoticeAutoEnroll: {
      title: 'You’ll receive your initial policy documents via mail.',
    },
    osiEmailConfirmation: {
      title:
        'You’ll receive a confirmation email from Midvale Insurance, on behalf of General Motors Insurance, within 24 hours. If you do not see this email, please check your spam folder.',
    },
    proofOfHomeOwnership: {
      title:
        'Home Ownership: Email proof of home ownership such as a copy of  the deed to the home, a recent tax record or a copy of the mortgage statement.',
    },
    proofOfPriorInsurance: {
      title:
        'Prior Carrier Information: Email proof of prior insurance, including your name, coverage limits, and coverage term.',
    },
    proofOfGoodStudent: {
      title:
        'Good Student: Email a copy of the most recent grade report, or proof that the student has scored in the 80th percentile.',
    },
    proofOfAwayAtSchool: {
      title:
        'Away at School: Email a letter of certification of enrollment or acceptance from the college, which also includes their mailing address.',
    },
    proofOfCollegeGraduate: {
      title:
        'Arkansas College Graduate: Email a transcript that includes the school name, cumulative grade average and student name.',
    },
    defensiveDriverCertificate: {
      title:
        'Defensive Driver: Email the certificate of completion that identifies the driver and a course completion date.',
    },
    insuranceCertification: {
      title:
        'You’d like an Insurance Certification (FR-19) sent to the Department of Motor Vehicles (DMV).',
    },
    businessUse: {
      title: 'Your vehicle is titled to a self-owned business, a corporate estate, or a trust.',
    },
    travelinkDiscount: {
      title:
        'Additional Discount: If you participate in the Delaware Travelink Car Pool, an employer-sponsored ride-sharing program, you may be eligible for a discount .',
    },
    militaryDiscount: {
      title:
        'Additional Discount:  If one of the following applies: 1) You are on active duty or a reservist in the military and based in Louisiana 2) You are on active duty or a reservist in the military, deployed out of state or overseas, and your spouse and/or dependents are living in Louisiana 3) You are a spouse or individual/dependent in the same household who meets the above criteria then you may be eligible for a discount.',
    },
    montanaNationalGuard: {
      title:
        'Additional Discount:  If you are a member of the Montana National Guard and have taken a defensive driving course, you may be eligible for a discount.',
    },
    financialResponsibility: {
      title: 'You need a Financial Responsibility filing (SR-22 or FR-44).',
    },
    excludeDriver: {
      title: 'You’d like to exclude a driver from this policy.',
    },
    priorCarrierCancel: {
      title: 'You’ll need to cancel the policy with your previous insurance provider.',
    },
    costcoMembershipUpdates: {
      title:
        'Make sure to keep your Costco membership information updated and let us know if you make any updates.',
    },
  },
  'amfam-adv.auto': {
    autopayAndPaperlessNotice: {
      title:
        'In order to retain your paperless and/or auto pay discounts you must register for online billing and go paperless. If you do not register within ten business days, the discount(s) will be removed and the policy premium adjusted accordingly.',
    },
    contactForTpiNotice: {
      title:
        'Please contact us if you want to add a lienholder, third party interest, or if you need to update your mailing address.',
    },
    proofOfInsurance: {
      title:
        'I understand that the information contained within proof of insurance is provided to me as a courtesy and may not be accepted as valid proof of insurance in my state. Official proof of insurance cards will be mailed with your policy documents, and can also be accessed via MyAccount.',
    },
    proofOfInsuranceByMailOnly: {
      title:
        'Your state does not allow identification cards to be available electronically and downloaded for use. Official identification cards will be mailed to you with your paperwork in the next few business days.',
    },
    ContactForAdditionalInfo: {
      title:
        'You’d like to update your mailing address, add an additional interest or named insured, or you’d like to add an additional designee (dependent on state availability).',
      stateOptions: {
        VA: {
          title:
            'You’d like to add a lienholder, a third party interest, a loss payable clause or you need to update your mailing address.',
        },
      },
    },
    lossPayableClauseEndorsement: {
      title:
        'The loss payable clause endorsement provides loss or damage payment to the loss payee indicated on the declarations. This clause indicates that a third-party is to be provided any funds paid out for a loss. There is no premium charge for this endorsement. If you would like this coverage, please contact us.',
    },
    policyUpdateAfterMvrReview: {
      title:
        'Your auto policy was purchased prior to obtaining the driving records or a credit report. After the reports are obtained, your premium may change. Any adjustments will be communicated by mail.',
    },
    policyUpdateAfterCbrReview: {
      title:
        'Your auto policy was purchased prior to obtaining the driving records or a credit report. After the reports are obtained, your premium may change. Any adjustments will be communicated by mail.',
    },
    autopayNotice: {
      title:
        'In order to retain your Auto Pay discount you’ll need to create an online account and complete the Auto Pay enrollment.  If you do not register within ten business days, the discount will be removed and the policy premium adjusted accordingly.',
    },
    paperlessNotice: {
      title:
        'You’ll receive your initial policy documents via mail.  Once you’ve created an online account and completed the Paperless discount enrollment, future correspondence will be delivered electronically.',
    },
    autopayNoticeCC: {
      title:
        'In order to retain your Auto Pay discount you’ll need to create an online account, add a bank account, and complete the Auto Pay enrollment. If you do not register within ten business days, the discount will be removed and the policy premium adjusted accordingly.',
    },
    paperlessNoticeAutoEnroll: {
      title: 'You’ll receive your initial policy documents via mail.',
    },
    osiEmailConfirmation: {
      title:
        'You’ll receive a confirmation email from Midvale Insurance, on behalf of General Motors Insurance, within 24 hours. If you do not see this email, please check your spam folder.',
    },
    proofOfHomeOwnership: {
      title:
        'Home Ownership: Email proof of home ownership such as a copy of  the deed to the home, a recent tax record or a copy of the mortgage statement.',
    },
    proofOfPriorInsurance: {
      title:
        'Prior Carrier Information: Email proof of prior insurance, including your name, coverage limits, and coverage term.',
    },
    proofOfGoodStudent: {
      title:
        'Good Student: Email a copy of the most recent grade report, or proof that the student has scored in the 80th percentile.',
    },
    proofOfAwayAtSchool: {
      title:
        'Away at School: Email a letter of certification of enrollment or acceptance from the college, which also includes their mailing address.',
    },
    proofOfCollegeGraduate: {
      title:
        'Arkansas College Graduate: Email a transcript that includes the school name, cumulative grade average and student name.',
    },
    defensiveDriverCertificate: {
      title:
        'Defensive Driver: Email the certificate of completion that identifies the driver and a course completion date.',
    },
    insuranceCertification: {
      title:
        'You’d like an Insurance Certification (FR-19) sent to the Department of Motor Vehicles (DMV).',
    },
    businessUse: {
      title: 'Your vehicle is titled to a self-owned business, a corporate estate, or a trust.',
    },
    travelinkDiscount: {
      title:
        'Additional Discount: If you participate in the Delaware Travelink Car Pool, an employer-sponsored ride-sharing program, you may be eligible for a discount .',
    },
    militaryDiscount: {
      title:
        'Additional Discount:  If one of the following applies: 1) You are on active duty or a reservist in the military and based in Louisiana 2) You are on active duty or a reservist in the military, deployed out of state or overseas, and your spouse and/or dependents are living in Louisiana 3) You are a spouse or individual/dependent in the same household who meets the above criteria then you may be eligible for a discount.',
    },
    montanaNationalGuard: {
      title:
        'Additional Discount:  If you are a member of the Montana National Guard and have taken a defensive driving course, you may be eligible for a discount.',
    },
    financialResponsibility: {
      title: 'You need a Financial Responsibility filing (SR-22 or FR-44).',
    },
    excludeDriver: {
      title: 'You’d like to exclude a driver from this policy.',
    },
    priorCarrierCancel: {
      title: 'You’ll need to cancel the policy with your previous insurance provider.',
    },
  },
  'homesite.renters': {
    autopayAndPaperlessNotice: {
      title:
        'In order to retain your paperless and/or auto pay discounts you must register for online billing and go paperless. If you do not register within ten business days, the discount(s) will be removed and the policy premium adjusted accordingly.',
    },
    contactForAdditionalInfo: {
      title:
        'Please contact us if you would like to update your mailing address, add an additional interest or named insured.',
    },
    KeepCostcoMembershipUpdated: {
      title:
        'Make sure to keep your Costco membership information updated and let us know if you make any updates.',
    },
  },
  'amfam-adv.renters': {
    autopayAndPaperlessNotice: {
      title:
        'In order to retain your paperless and/or auto pay discounts you must register for online billing and go paperless. If you do not register within ten business days, the discount(s) will be removed and the policy premium adjusted accordingly.',
    },
    contactForAdditionalInfo: {
      title:
        'Please contact us if you would like to update your mailing address, add an additional interest or named insured.',
    },
    KeepCostcoMembershipUpdated: {
      title:
        'Make sure to keep your Costco membership information updated and let us know if you make any updates.',
    },
  },
};
