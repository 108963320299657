import { useCallback } from 'react';

import dayjs from 'dayjs';

import { useAddFields } from '@ecp/features/sales/form';
import { DatePicker } from '@ecp/features/sales/shared/components';
import {
  isDuplicatePerson,
  setFormErrorsResetByField,
  useField,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue } from '@ecp/types';

import type { QuestionProps } from '../../questionProps.types';

interface Props extends QuestionProps {
  personRef: string;
  disabled: boolean;
  hidePicker?: boolean;
}

export const DateOfBirthQuestion: React.FC<Props> = (props) => {
  const {
    personRef,
    id = 'DOB',
    hidePicker = true,
    label = 'Date of birth',
    trackingName = 'dob',
    disabled,
    staticValueWithRef = false,
  } = props;
  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const dispatch = useDispatch();
  const dateOfBirth = usePersonField('dob');
  const firstName = usePersonField('firstName');
  const lastName = usePersonField('lastName');
  const dateOfBirthRecall = useField('static.person.dob');
  const additionalDriverDateOfBirth = useField(`static.${personRef}.dob`);

  useAddFields({ dateOfBirth });

  const handleDateOfBirthComplete = useCallback(
    (value: AnswerValue): void => {
      dateOfBirth.props.actionOnComplete(value);
      // use staticValueWithRef with additional drivers
      if (staticValueWithRef) {
        additionalDriverDateOfBirth.props.actionOnComplete(value);
        // use dateOfBirthRecall for PNI
      } else {
        dateOfBirthRecall.props.actionOnComplete(value);
      }
      if (isDuplicatePerson(firstName.props.error))
        dispatch(setFormErrorsResetByField({ key: `${personRef}.firstName` }));
      if (isDuplicatePerson(lastName.props.error)) {
        dispatch(setFormErrorsResetByField({ key: `${personRef}.lastName` }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [firstName.props.error, lastName.props.error, dispatch, personRef],
  );

  if (!dateOfBirth.exists && !disabled) return null;

  return (
    <DatePicker
      {...dateOfBirth.props}
      actionOnComplete={handleDateOfBirthComplete}
      autoComplete='bday'
      data-testid='dob'
      disabled={disabled}
      hidePicker={hidePicker}
      id={id}
      label={label}
      trackingName={trackingName}
      trackingLabel={dateOfBirth.props.value ? dayjs(dateOfBirth.props.value).format('YYYY') : ' '}
    />
  );
};
