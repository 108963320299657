import { configureStore } from '@reduxjs/toolkit';

import * as interactionId from '@ecp/utils/analytics/interaction-id';
import { handleEnvOverrides } from '@ecp/utils/env';
import * as flags from '@ecp/utils/flags';
import { datadogLog } from '@ecp/utils/logger';
import { location } from '@ecp/utils/routing';
import { localStorage, sessionStorage } from '@ecp/utils/storage';

import { env } from '@ecp/env';
import { CHAT_DISABLED_PAGES } from '@ecp/features/sales/shared/chat';
import { throwOnExceedingInitializationLimit } from '@ecp/features/sales/shared/misc';
import { initializeLogger } from '@ecp/features/sales/shared/utils/logger';
import { storeConfiguration } from '@ecp/features/sales/shell';
import * as chat from '@ecp/features/shared/chat';

const initializeOptimizely = async (): Promise<void> => {
  const handleError = (reason?: string, error?: Error): void => {
    datadogLog({
      logType: 'error',
      message: reason
        ? `Optimizely SDK failed with ${reason} - ${error?.message}`
        : `Optimizely SDK failed with non ok result - ${error?.message}`,
      context: {
        logOrigin: 'apps/sales/monoline/renters/src/initialize.ts',
        functionOrigin: 'initializeOptimizely',
      },
      error,
    });
  };

  await flags
    .initialize({ sdkKey: env.optimizely.sdkKey, storage: sessionStorage })
    .then((result) => {
      if (!result?.success) handleError(result?.reason);
    })
    .catch((error) => {
      handleError('Optimizely SDK failed with unknown error', error);
    });

  flags.updateUserAttributes({
    baseExp: env.static.baseExp,
    expId: env.static.expId,
    partnerName: env.static.partnerName,
    opcoName: env.static.opcoName,
    testAudience: env.optimizely.testAudience,
  });
};

// Add feature flags and AB tests initialization as well
export const initializeGlobal = async (): Promise<void> => {
  // eslint-disable-next-line no-console
  console.log(
    `Platform Build: ${env.static.releaseVersion} - ${new Date(
      env.static.releaseTimestamp,
    ).toLocaleString()}`,
  );

  sessionStorage.setNamespace(env.static.expId);
  localStorage.setNamespace(env.static.expId);

  handleEnvOverrides();

  initializeLogger();

  interactionId.initialize({ storage: sessionStorage });

  if (env.mockapi) {
    const { registerMockServer } = await import('@ecp/utils/mock/browser');
    await registerMockServer();
  }

  if (env.optimizely.enabled) await initializeOptimizely();

  // We need to maintain the query params, which get reset in the ProductFormQuestions.tsx or on page refresh.
  // This makes sure that the first landingURL that we get when the page is loaded is persisted and never overwritten for the duration of
  // the user's session. When a new session comes along the values will be removed.
  // TODO: remove this hack when underlying teams make provisions for CA and CONNECT
  if (!sessionStorage.getItem('landingURL')) {
    sessionStorage.setItem('landingURL', window.location.href);
  }

  const enabledChatCondition = (): boolean => {
    return !CHAT_DISABLED_PAGES.has(location.pathname) && env.chatEnabled;
  };

  chat.initialize({
    deploymentId: env.genesysChatDeploymentId || '',
    queueName: env.genesysChatQueueName || '',
    environment: env.genesysChatEnvironment || '',
    enabledCondition: enabledChatCondition,
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const initializeAndGetStore = () => {
  const defaultMiddlewareOptions = {
    immutableCheck: true,
    serializableCheck: true,
  };

  const store = configureStore(storeConfiguration(defaultMiddlewareOptions));

  throwOnExceedingInitializationLimit(store);

  return store;
};
