import type { MutableRefObject } from 'react';
import { useCallback, useMemo } from 'react';

import type { TypographyVariant } from '@mui/material';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Typography,
} from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { emptyArray, isKeyOf, isType } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';
import { isHTMLElementWithValue } from '@ecp/utils/web';

import {
  AutoAdjustableGrid,
  CardFormControlLabel,
  ShowMoreOrLess,
  TooltipWithIcon,
} from '@ecp/components';
import type { AutoAdjustableGridProps, ShowMoreOrLessProps } from '@ecp/components';
import { useFieldRef } from '@ecp/features/sales/form';
import { IconUIDelete, IconUIEdit } from '@ecp/themes/base';
import type { CardOption } from '@ecp/types';

import type { AddMoreProps } from '../AddMore';
import { AddMore } from '../AddMore';
import { Button } from '../Button';
import { Checkbox } from './Checkbox';
import { useStyles } from './CheckboxGroup.styles';

export type Props = Pick<
  React.ComponentProps<typeof CardFormControlLabel>,
  'classes' | 'className'
> & {
  name: string;
  cardSize?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  error?: string;
  helperText?: string;
  label?: React.ReactNode;
  message?: React.ReactNode;
  /** @deprecated Remove after migrating driver/vehicle cards to the new implementation which is independent from CheckboxGroup. */
  messageClassName?: string;
  actionOnComplete: (value: string[], valueClicked?: string, newChecked?: boolean) => void;
  actionOnEdit?: (ref: string) => void;
  actionOnDelete?: (ref: string) => void;
  options?: CardOption[];
  mutuallyExclusiveOptions?: Props['values'][];
  mutuallyDisabledOptions?: Props['values'][];
  mutuallyDisabledOptionInfoTip?: string;
  showMoreOrLessProps?: ShowMoreOrLessProps;
  addMoreProps?: AddMoreProps;
  adjustableGrid?: boolean;
  adjustableGridProps?: AutoAdjustableGridProps;
  values: string[];
  variant?:
    | 'classic'
    | 'card'
    | 'editCard'
    | 'classicCompact'
    | 'horizontalCard'
    | 'iconCard'
    | 'horizontalCardCuztomizedLabel'
    | 'paddedHorizontalCard';
  trackingName?: string;
  dataTestIdPrefix?: string;
  allOptions?: CardOption[];
  customVariant?: TypographyVariant | 'inherit';
  labelClassName?: string;
};

export type NoPatchProps = Pick<
  React.ComponentProps<typeof CardFormControlLabel>,
  'classes' | 'className'
> & {
  name: string;
  cardSize?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  error?: string;
  helperText?: string;
  label?: React.ReactNode;
  message?: React.ReactNode;
  /** @deprecated Remove after migrating driver/vehicle cards to the new implementation which is independent from CheckboxGroup. */
  messageClassName?: string;
  actionOnComplete: (value: string[], valueClicked?: string, newChecked?: boolean) => void;
  actionOnEdit?: (ref: string) => void;
  actionOnDelete?: (ref: string) => void;
  options?: CardOption[];
  mutuallyExclusiveOptions?: Props['values'][];
  mutuallyDisabledOptions?: Props['values'][];
  mutuallyDisabledOptionInfoTip?: string;
  showMoreOrLessProps?: ShowMoreOrLessProps;
  addMoreProps?: AddMoreProps;
  adjustableGrid?: boolean;
  adjustableGridProps?: AutoAdjustableGridProps;
  values: string[];
  variant?: 'classic' | 'card' | 'editCard' | 'classicCompact' | 'horizontalCard' | 'iconCard';
  trackingName?: string;
  dataTestIdPrefix?: string;
  allOptions?: CardOption[];
  customVariant?: TypographyVariant | 'inherit';
  labelClassName?: string;
  prevStatusRef: MutableRefObject<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [k: string]: any;
  }>;
  forceUpdateParentComponent: () => void;
};

export const CheckboxGroup: React.FC<Props> = (props) => {
  const {
    name,
    cardSize = 'small',
    className,
    disabled,
    error,
    helperText,
    label,
    message,
    messageClassName,
    actionOnComplete,
    options = emptyArray,
    mutuallyExclusiveOptions = emptyArray,
    mutuallyDisabledOptions = emptyArray,
    mutuallyDisabledOptionInfoTip,
    showMoreOrLessProps,
    addMoreProps,
    adjustableGrid = false,
    adjustableGridProps,
    values = emptyArray,
    variant: variantProp = 'classic',
    trackingName,
    actionOnEdit,
    actionOnDelete,
    dataTestIdPrefix = '',
    allOptions,
    customVariant = 'body4',
    labelClassName,
    classes: labelClasses,
  } = props;
  const cardCount = options.length + Number(!!showMoreOrLessProps) + Number(!!addMoreProps);
  const { classes, cx } = useStyles({ ...props, cardCount });
  const convertedMutuallyExclusiveOptions = mutuallyExclusiveOptions.map((el) => el.map(String));
  const convertedMutuallyDisabledOptions = mutuallyDisabledOptions.map((el) => el.map(String));

  const ref = useFieldRef(name);

  // auto-detect 'card' variant
  const variant =
    options[0] &&
    (options[0].icon || options[0].image || options[0].iconProduct) &&
    variantProp !== 'editCard' &&
    variantProp !== 'horizontalCard' &&
    variantProp !== 'horizontalCardCuztomizedLabel' &&
    variantProp !== 'iconCard'
      ? 'card'
      : variantProp;

  const onValueChanged = useCallback(
    (selectedValue: string, newChecked: boolean) => {
      let updatedValues = values.map(String);
      const gaLabel: Record<string, string> = {};
      if (updatedValues.includes(selectedValue)) {
        updatedValues.splice(updatedValues.indexOf(selectedValue), 1);
      }
      if (newChecked) {
        updatedValues = convertedMutuallyExclusiveOptions.reduce(
          (acc, option) => {
            let newValues = [...acc];
            if (option.includes(selectedValue)) {
              newValues = newValues.filter((value) => !option.includes(value));
            }

            return newValues;
          },
          [...updatedValues],
        );
        updatedValues.push(selectedValue);
      }

      actionOnComplete(updatedValues, selectedValue, newChecked);
      if (trackingName) {
        // If the checkboxgroup uses more or less props pass in all the parents cardoptions to ensure all option data is sent to GA
        const trackingOptions = allOptions || options;
        trackingOptions.forEach((item) => {
          if (updatedValues.includes(item.value)) {
            gaLabel[item.value] = '1';
          } else {
            gaLabel[item.value] = '0';
          }
        });
        trackClick({ action: trackingName, label: JSON.stringify(gaLabel) });
      }
    },
    [
      actionOnComplete,
      allOptions,
      convertedMutuallyExclusiveOptions,
      options,
      trackingName,
      values,
    ],
  );
  const handleChange = useCallback(
    (event: React.ChangeEvent<unknown>, newChecked: boolean) => {
      if (
        !isType(event.currentTarget, HTMLElement) ||
        !isHTMLElementWithValue(event.currentTarget)
      ) {
        datadogLog({
          logType: 'error',
          message: 'Not a valid event target for Checkbox',
          context: {
            logOrigin: 'libs/features/sales/shared/components/src/CheckboxGroup/CheckboxGroup.tsx',
            functionOrigin: 'handleChange',
          },
        });
        throw new Error('Not a valid event target for Checkbox');
      }

      onValueChanged(event.currentTarget.value, newChecked);
    },
    [onValueChanged],
  );

  const isCard = variant === 'card' || variant === 'editCard' || variant === 'iconCard';
  const isClassic = variant === 'classic' || variant === 'classicCompact';
  const handleControlLabelsClick = useMemo(
    () =>
      options.map((option) => ({
        handleEdit: actionOnEdit ? () => actionOnEdit(option.value) : undefined,
        handleDelete: actionOnDelete ? () => actionOnDelete(option.value) : undefined,
      })),
    [actionOnDelete, actionOnEdit, options],
  );
  const formControlLabels = options.map((option, index) => {
    const idForLabel = name ? `${name}-${option.value}` : option.value;
    const testLabel = name === 'static.driverRefs' ? `d${index}` : `v${index}`;
    let dataTestIDLabel = `${option.value}`;
    if (dataTestIdPrefix) {
      dataTestIDLabel = `${dataTestIdPrefix}-${index}`;
    }
    const testLabelCard = `card.${testLabel}`;
    const testLabelDelete = `delete.${testLabel}`;
    const testLabelEdit = `edit.${testLabel}`;

    // Attach ref only to the first control component
    // To avoid confusion later when setting focus on it or scrolling to it in getValidateForm
    const inputRef = index === 0 ? ref : undefined;
    const checked = values.map(String).includes(String(option.value));
    const isMutuallyDisabled = convertedMutuallyDisabledOptions.some((pairedOptions) => {
      if (pairedOptions.includes(option.value)) {
        const mututalOption = pairedOptions.find((value) => value !== option.value);

        return values.map(String).includes(mututalOption);
      }

      return false;
    });
    const isDisabled = disabled || option.disabled || isMutuallyDisabled;

    if (variant === 'editCard') {
      return (
        <CardFormControlLabel
          data-testid={testLabelCard}
          control={
            <Grid container className={classes.editCardContainer}>
              <Grid item xs={6}>
                <Button
                  className={classes.editButton}
                  data-testid={testLabelEdit}
                  variant='iconText'
                  onClick={handleControlLabelsClick[index].handleEdit}
                  aria-label={`Edit ${option.label}`}
                  icon={<IconUIEdit className={classes.icon} />}
                  // analyticsElement="choice.reviewVehicleAndDriverPage.saveAndContinueButton"
                >
                  Edit
                </Button>
              </Grid>
              {!isDisabled && (
                <Grid item xs={6}>
                  <Button
                    className={classes.editButton}
                    data-testid={testLabelDelete}
                    variant='iconText'
                    onClick={handleControlLabelsClick[index].handleDelete}
                    aria-label={`Delete ${option.label}`}
                    // analyticsElement="choice.reviewVehicleAndDriverPage.saveAndContinueButton"
                    icon={<IconUIDelete className={classes.icon} />}
                  >
                    Delete
                  </Button>
                </Grid>
              )}
            </Grid>
          }
          size={cardSize}
          profileCard
          htmlFor={idForLabel}
          key={idForLabel}
          option={option}
          name={name}
        />
      );
    }

    if (variant === 'card' || variant === 'horizontalCard') {
      return (
        <CardFormControlLabel
          classes={
            labelClasses || {
              root: labelClassName,
            }
          }
          data-testid={dataTestIDLabel}
          control={<Checkbox data-testid={option.testId} id={idForLabel} variant={variant} />}
          size={variant === 'horizontalCard' ? 'sizeHorizontal' : cardSize}
          checked={checked}
          disabled={isDisabled}
          htmlFor={idForLabel}
          inputRef={inputRef}
          key={idForLabel}
          name={name}
          option={option}
          onChange={handleChange}
          variant={variant}
        />
      );
    }

    if (variant === 'horizontalCardCuztomizedLabel') {
      return (
        <CardFormControlLabel
          classes={
            labelClasses || {
              root: labelClassName,
            }
          }
          data-testid={dataTestIDLabel}
          control={<Checkbox data-testid={option.testId} id={idForLabel} variant={variant} />}
          size='sizeHorizontal'
          checked={checked}
          disabled={isDisabled}
          htmlFor={idForLabel}
          inputRef={inputRef}
          key={idForLabel}
          name={name}
          option={option}
          onChange={handleChange}
          variant={variant}
        />
      );
    }
    if (variant === 'iconCard') {
      const isExclusiveOption = convertedMutuallyExclusiveOptions.some((pairedOptions) =>
        pairedOptions.includes(option.value),
      );
      const doesSelectedExclusiveOptionExist = convertedMutuallyExclusiveOptions.some(
        (pairedOptions) => {
          return (
            pairedOptions.some((value) => values.map(String).includes(value)) &&
            pairedOptions.includes(option.value)
          );
        },
      );
      const showAddIcon =
        !checked &&
        (!isExclusiveOption || (isExclusiveOption && !doesSelectedExclusiveOptionExist));

      return (
        <CardFormControlLabel
          classes={
            labelClasses || {
              root: labelClassName,
            }
          }
          data-testid={dataTestIDLabel}
          control={
            <Checkbox
              data-testid={option.testId}
              id={idForLabel}
              className={classes.iconCardInput}
            />
          }
          size='sizeIconCard'
          checked={checked}
          showAddIcon={showAddIcon}
          disabled={isDisabled}
          disabledOptionTip={mutuallyDisabledOptionInfoTip}
          htmlFor={idForLabel}
          inputRef={inputRef}
          key={idForLabel}
          name={name}
          option={option}
          onChange={handleChange}
          onButtonClick={onValueChanged}
          variant={variant}
          tabIndex={isDisabled ? -1 : 0}
        />
      );
    }
    if (variant === 'paddedHorizontalCard') {
      return (
        <CardFormControlLabel
          classes={
            labelClasses || {
              root: cx(labelClassName, classes.horizontalCheckboxLabels),
            }
          }
          data-testid={dataTestIDLabel}
          control={<Checkbox data-testid={option.testId} id={idForLabel} variant={variant} />}
          size='sizePaddedHorizontal'
          checked={checked}
          disabled={isDisabled}
          htmlFor={idForLabel}
          inputRef={inputRef}
          key={idForLabel}
          name={name}
          option={option}
          onChange={handleChange}
          variant={variant}
        />
      );
    }

    // use classic
    return (
      <FormControlLabel
        control={
          <Checkbox
            data-testid={option.testId}
            id={idForLabel}
            className={classes.classicCheckbox}
          />
        }
        classes={{
          root: classes.classicRoot,
          label: variant === 'classicCompact' ? classes.classicCompactLabel : classes.classicLabel,
        }}
        checked={checked}
        disabled={isDisabled}
        htmlFor={idForLabel}
        inputRef={inputRef}
        key={idForLabel}
        name={name}
        label={
          <>
            <span className={classes.classicCheckbox}>{option.label}</span>
            {option.helpText && <TooltipWithIcon title={option.helpText} />}
          </>
        }
        value={option.value}
        className={classes.formControl}
        onChange={handleChange}
      />
    );
  });

  if (showMoreOrLessProps) {
    formControlLabels.push(
      <ShowMoreOrLess
        cardSize={cardSize !== 'large' ? cardSize : undefined}
        {...showMoreOrLessProps}
        key='showMoreLess'
      />,
    );
  }
  if (addMoreProps) {
    formControlLabels.push(<AddMore {...addMoreProps} key='addMore' />);
  }

  return (
    <FormControl
      component='fieldset'
      className={cx(
        classes.root,
        isCard && classes.card,
        variant === 'classic' && classes.classic,
        isCard && cardSize === 'small' && classes.cardSmall,
        isCard && cardSize === 'medium' && classes.cardMedium,
        className,
      )}
      error={!!error}
      data-testid={dataTestIdPrefix}
    >
      <FormLabel
        component='legend'
        focused={false}
        className={classes.label}
        id={name && `${name}-label`}
      >
        <Typography variant={customVariant}>{label || name}</Typography>
        {helperText && (
          <FormHelperText className={classes.subLabel} error={false}>
            {helperText}
          </FormHelperText>
        )}
      </FormLabel>
      <p className={messageClassName}>{message}</p>
      <FormGroup
        row={!isClassic}
        className={cx(
          classes.group,
          isKeyOf(variant, classes) && classes[variant],
          labelClasses && isKeyOf(variant, labelClasses) && labelClasses[variant],
        )}
      >
        {adjustableGrid ? (
          <AutoAdjustableGrid spacing={10} {...adjustableGridProps}>
            {formControlLabels}
          </AutoAdjustableGrid>
        ) : (
          formControlLabels
        )}
      </FormGroup>
      {error && (
        <FormHelperText
          className={variant === 'classicCompact' ? classes.listError : classes.errorText}
          role='alert'
        >
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export const CheckboxNoPatchGroup: React.FC<NoPatchProps> = (props) => {
  const {
    name,
    cardSize = 'small',
    className,
    disabled,
    error,
    helperText,
    label,
    message,
    messageClassName,
    actionOnComplete,
    options = emptyArray,
    mutuallyExclusiveOptions = emptyArray,
    mutuallyDisabledOptions = emptyArray,
    showMoreOrLessProps,
    addMoreProps,
    adjustableGrid = false,
    adjustableGridProps,
    values = emptyArray,
    variant: variantProp = 'classic',
    trackingName,
    dataTestIdPrefix = '',
    allOptions,
    customVariant = 'body4',
    prevStatusRef,
    forceUpdateParentComponent,
  } = props;
  const cardCount = options.length + Number(!!showMoreOrLessProps) + Number(!!addMoreProps);
  const { classes, cx } = useStyles({ ...props, cardCount });
  const convertedMutuallyExclusiveOptions = mutuallyExclusiveOptions.map((el) => el.map(String));
  const convertedMutuallyDisabledOptions = mutuallyDisabledOptions.map((el) => el.map(String));

  const ref = useFieldRef(name);

  // auto-detect 'card' variant
  const variant =
    options[0] &&
    (options[0].icon || options[0].image || options[0].iconProduct) &&
    variantProp !== 'editCard' &&
    variantProp !== 'horizontalCard' &&
    variantProp !== 'iconCard'
      ? 'card'
      : variantProp;

  const onValueChanged = useCallback(
    (selectedValue: string, newChecked: boolean) => {
      let updatedValues = values.map(String);
      const gaLabel: Record<string, string> = {};
      if (updatedValues.includes(selectedValue)) {
        updatedValues.splice(updatedValues.indexOf(selectedValue), 1);
      }
      if (newChecked) {
        updatedValues = convertedMutuallyExclusiveOptions.reduce(
          (acc, option) => {
            let newValues = [...acc];
            if (option.includes(selectedValue)) {
              newValues = newValues.filter((value) => !option.includes(value));
            }

            return newValues;
          },
          [...updatedValues],
        );
        updatedValues.push(selectedValue);
      }

      actionOnComplete(updatedValues, selectedValue, newChecked);
      const prevCheckValue = prevStatusRef.current.filter(
        (item: { key: string | string[] }) => item.key.indexOf(selectedValue) !== -1,
      );
      if (prevCheckValue[0].value !== newChecked) {
        forceUpdateParentComponent();
      }
      if (trackingName) {
        const trackingOptions = allOptions || options;
        trackingOptions.forEach((item) => {
          if (updatedValues.includes(item.value)) {
            gaLabel[item.value] = '1';
          } else {
            gaLabel[item.value] = '0';
          }
        });
        trackClick({ action: trackingName, label: JSON.stringify(gaLabel) });
      }
    },
    [
      actionOnComplete,
      allOptions,
      convertedMutuallyExclusiveOptions,
      forceUpdateParentComponent,
      options,
      prevStatusRef,
      trackingName,
      values,
    ],
  );
  const handleChange = useCallback(
    (event: React.ChangeEvent<unknown>, newChecked: boolean) => {
      if (
        !isType(event.currentTarget, HTMLElement) ||
        !isHTMLElementWithValue(event.currentTarget)
      ) {
        datadogLog({
          logType: 'error',
          message: 'Not a valid event target for Checkbox',
          context: {
            logOrigin: 'libs/features/sales/shared/components/src/CheckboxGroup/CheckboxGroup.tsx',
            functionOrigin: 'handleChange',
          },
        });
        throw new Error('Not a valid event target for Checkbox');
      }
      onValueChanged(event.currentTarget.value, newChecked);
    },
    [onValueChanged],
  );

  const isCard = variant === 'card' || variant === 'editCard' || variant === 'iconCard';
  const isClassic = variant === 'classic' || variant === 'classicCompact';
  const formControlLabels = options.map((option, index) => {
    const idForLabel = name ? `${name}-${option.value}` : option.value;

    // Attach ref only to the first control component
    // To avoid confusion later when setting focus on it or scrolling to it in getValidateForm
    const inputRef = index === 0 ? ref : undefined;
    const checked = values.map(String).includes(String(option.value));
    const isMutuallyDisabled = convertedMutuallyDisabledOptions.some((pairedOptions) => {
      if (pairedOptions.includes(option.value)) {
        const mututalOption = pairedOptions.find((value) => value !== option.value);

        return values.map(String).includes(mututalOption);
      }

      return false;
    });
    const isDisabled = disabled || option.disabled || isMutuallyDisabled;

    // use classic
    return (
      <FormControlLabel
        control={
          <Checkbox
            data-testid={option.testId}
            id={idForLabel}
            className={classes.classicCheckbox}
          />
        }
        classes={{
          root: classes.classicRoot,
          label: variant === 'classicCompact' ? classes.classicCompactLabel : classes.classicLabel,
        }}
        checked={checked}
        disabled={isDisabled}
        htmlFor={idForLabel}
        inputRef={inputRef}
        key={idForLabel}
        name={name}
        label={
          <>
            <span className={classes.classicCheckbox}>{option.label}</span>
            {option.helpText && <TooltipWithIcon title={option.helpText} />}
          </>
        }
        value={option.value}
        className={classes.formControl}
        onChange={handleChange}
      />
    );
  });

  if (showMoreOrLessProps) {
    formControlLabels.push(
      <ShowMoreOrLess
        cardSize={cardSize !== 'large' ? cardSize : undefined}
        {...showMoreOrLessProps}
        key='showMoreLess'
      />,
    );
  }
  if (addMoreProps) {
    formControlLabels.push(<AddMore {...addMoreProps} key='addMore' />);
  }

  return (
    <FormControl
      component='fieldset'
      className={cx(
        classes.root,
        isCard && classes.card,
        variant === 'classic' && classes.classic,
        className,
      )}
      error={!!error}
      data-testid={dataTestIdPrefix}
    >
      <FormLabel
        component='legend'
        focused={false}
        className={classes.label}
        id={name && `${name}-label`}
      >
        <Typography variant={customVariant}>{label || name}</Typography>
        {helperText && (
          <FormHelperText className={classes.subLabel} error={false}>
            {helperText}
          </FormHelperText>
        )}
      </FormLabel>
      <p className={messageClassName}>{message}</p>
      <FormGroup
        row={!isClassic}
        className={cx(classes.group, isKeyOf(variant, classes) && classes[variant])}
      >
        {adjustableGrid ? (
          <AutoAdjustableGrid spacing={10} {...adjustableGridProps}>
            {formControlLabels}
          </AutoAdjustableGrid>
        ) : (
          formControlLabels
        )}
      </FormGroup>
      {error && (
        <FormHelperText
          className={variant === 'classicCompact' ? classes.listError : classes.errorText}
          role='alert'
        >
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};
