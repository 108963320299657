import { parseDollar } from '@ecp/utils/common';

import type { Product } from '@ecp/features/shared/product';

import metadata from './metadata/metadata';

const numOfInstallments: Record<string, number> = {
  'One Month': 1,
  'Two Months': 2,
  'Four Months': 4,
  'Five Months': 5,
  'Six Months': 6,
  'Ten Months': 10,
  'Eleven Months': 11,
  'Twelve Months': 12,
};

export const getNumOfInstallments = (
  paymentPlan: string | undefined,
  downPayment: number | undefined,
  product?: Product,
): number => {
  if (!paymentPlan) return 0;
  const pitchedNumOfInstallments = numOfInstallments[paymentPlan];

  // connect should return numOfInstallments indicated from SAPI
  if (product && metadata[product].isConnectAutoInstallmentPlan && downPayment) {
    return pitchedNumOfInstallments;
  }

  return subtractOneDayIfHasDownPayment(pitchedNumOfInstallments, downPayment);
};

const subtractOneDayIfHasDownPayment = (
  pitchedNumOfInstallments: number,
  downPayment: number | undefined,
): number => {
  if (!pitchedNumOfInstallments) return 0;
  const hasNoDownPayment = parseDollar(downPayment) === '$0.00';

  return hasNoDownPayment ? pitchedNumOfInstallments : pitchedNumOfInstallments - 1;
};
