import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({
  name: 'MortgageAddressQuestion',
})((theme) => ({
  ...theme.mixins.formAll,
  mortageCompany: {
    marginBottom: 40,
  },
}));
