import { env } from '@ecp/env';
import type { PropertyProduct } from '@ecp/features/shared/product';

type MetadataItem = {
  coverages: string;
  deductibles: string;
  otherCoverages: string;
} & (
  | {
      optionalCoverages: string;
    }
  | { includedCoverages: string }
);

export type Metadata = Record<
  PropertyProduct,
  { titles: MetadataItem; trackingNames: MetadataItem }
>;

const homeMetadata: Metadata[PropertyProduct] = {
  titles: {
    deductibles: env.static.isAgent ? 'Deductibles' : 'Your deductibles',
    coverages: env.static.isAgent ? 'Coverages for the home' : 'Coverages for your home',
    otherCoverages: 'Extended protection',
    optionalCoverages: 'Optional coverages',
  },
  trackingNames: {
    deductibles: 'Deductibles',
    coverages: 'PolicyLevelCoverages',
    otherCoverages: 'ExtendedPolicyLevelCoverages',
    optionalCoverages: 'OptionalPolicyLevelCoverages',
  },
};

const rentersMetadata: Metadata[PropertyProduct] = {
  titles: {
    deductibles: env.static.isAgent ? 'Deductibles' : 'Your deductibles',
    coverages: 'Main coverages and deductible',
    otherCoverages: 'Additional protection',
    includedCoverages: 'Included',
  },
  trackingNames: {
    deductibles: 'Deductibles',
    coverages: 'PolicyLevelCoverages',
    otherCoverages: 'AdditionalPolicyLevelCoverages',
    includedCoverages: 'IncludedPolicyLevelCoverages',
  },
};

const metadata: Metadata = {
  'homesite.home': homeMetadata,
  'homesite.renters': rentersMetadata,
  'amfam-adv.home': homeMetadata,
  'amfam-adv.renters': rentersMetadata,
};

export default metadata;
