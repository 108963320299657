import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PercentageGroup' })((theme) => ({
  imageGroup: {
    display: 'flex',
    position: 'relative',
  },
  percentageTitle: {
    ...theme.typography.body4,
    marginBottom: 15,
    paddingTop: 40,
  },
  dotSeparator: {
    flex: '1 1 auto',
    margin: '22px 5px 0 5px',
    borderTop: '1px dotted',
  },
  error: {
    marginBottom: 10,
    paddingLeft: 10,
    color: theme.palette.error.main,
  },
}));
