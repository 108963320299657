import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import type { OfferType } from '@ecp/features/sales/shared/constants';
import {
  AUTO_POLICY_OFFERTYPE,
  HOME_POLICY_OFFERTYPE,
  RENTERS_POLICY_OFFERTYPE,
} from '@ecp/features/sales/shared/constants';
import type { Answers } from '@ecp/features/sales/shared/types';
import { LineOfBusiness } from '@ecp/features/shared/product';

import { wrapThunkActionWithErrHandler } from '../../util';
import { getLineOfBusiness } from '../selectors';
import { updateAnswers } from './updateAnswers';

type UpdateOfferType = { offerType: OfferType };

// Advance specific ADVECP-4010
// Set offer type on answers so that SAPI knows when to rate vs when not to rate
// When OFFER_WITHOUT_PREMIUM SAPI would just return coverages but not premiums
// Premiums are returned only when OFFER_WITH_PREMIUM
export const updateOfferType = wrapThunkActionWithErrHandler<UpdateOfferType>(
  ({ offerType }) =>
    async (dispatch, getState) => {
      const state = getState();
      const lob: LineOfBusiness = getLineOfBusiness(state);
      const offerTypeFeatureAvailable =
        flagValues[FeatureFlags.OFFER_WITH_AND_WITHOUT_RATE_FEATURE];

      if (!offerTypeFeatureAvailable || !lob || !offerType) {
        return;
      }

      let answers: Answers = {};
      switch (lob) {
        case LineOfBusiness.AUTO: {
          answers = { [AUTO_POLICY_OFFERTYPE]: offerType };
          break;
        }
        case LineOfBusiness.HOME: {
          answers = { [HOME_POLICY_OFFERTYPE]: offerType };
          break;
        }
        case LineOfBusiness.RENTERS: {
          answers = { [RENTERS_POLICY_OFFERTYPE]: offerType };
          break;
        }
        case LineOfBusiness.BUNDLE: {
          answers = {
            [AUTO_POLICY_OFFERTYPE]: offerType,
            [HOME_POLICY_OFFERTYPE]: offerType,
          };
          break;
        }
        case LineOfBusiness.BUNDLE_AUTO_RENTERS: {
          answers = {
            [AUTO_POLICY_OFFERTYPE]: offerType,
            [RENTERS_POLICY_OFFERTYPE]: offerType,
          };
          break;
        }
        default:
      }

      if (answers)
        await dispatch(
          updateAnswers({
            answers,
          }),
        );
    },
  'updateOfferType',
);
