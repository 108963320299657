import type { RootStore } from '@ecp/features/sales/shared/store/types';
import type { Product } from '@ecp/features/shared/product';

import type { Enrollment, EnrollmentsResponse } from './types';

export const getEnrollments = (state: RootStore): EnrollmentsResponse['data'] =>
  state.enrollments.enrollments;

// !TODO to be evaluated if we want to keep it as it's not imported anywhere
export const getEnrollmentsForProduct = (state: RootStore, product: Product): Enrollment => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return getEnrollments(state)[product]!;
};
