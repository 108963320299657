import type { ReactNode } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';

import { Grid } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { sessionStorage } from '@ecp/utils/storage';
import { scrollToElement } from '@ecp/utils/web';

import { GridItem } from '@ecp/components';
import { env } from '@ecp/env';
import {
  CheckoutSidebar,
  MobileMonthlyPaymentsCheckoutCard,
  MonthlyPaymentsCheckoutCard,
} from '@ecp/features/sales/checkout';
import { useGetConditionValues, useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import { Button, Form, SaveAndExit } from '@ecp/features/sales/shared/components';
import { PurchaseErrorReason } from '@ecp/features/sales/shared/constants';
import { PurchaseStatusCode } from '@ecp/features/sales/shared/constants';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import {
  getDalSessionId,
  getIsMVRActive,
  getOfferProductsSelected,
  getOfferSetId,
  getPurchaseError,
  getSelectedPaymentPlan,
  useForm,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { CarrierName, Product } from '@ecp/features/shared/product';
import {
  getProductNameFromProduct,
  getReducedProductNameFromProduct,
} from '@ecp/features/shared/product';
import { IconUIArrowLeft, useIsDesktop, useIsTablet } from '@ecp/themes/base';

import { MobilePurchaseCard } from '../../components';
import { CheckoutFormBody } from '../../formBody/CheckoutFormBody/CheckoutFormBody';
import { IAgreeDialog } from '../../formBody/CheckoutFormBody/IAgreeDialog';
import { PostBindRetryMessaging } from '../../metadata/PostBindRetryMessaging.metadata';
import { getPurchaseRequest, postAcknowledgementsResponse } from '../../state';
import { getPostBindProductsSummary } from '../../state/postbind';
import type {
  Acknowledgements,
  CheckoutData,
  CheckoutOffers,
  PaymentFields,
  PaymentMethods,
  PostBindMessage,
  PostBindSummary,
  ProductPurchaseStatus,
  Signatures,
} from '../../types';
import { CheckoutPageTitle } from '../../views/CheckoutPage/CheckoutPageTitle';
import { useStyles } from './CheckoutForm.styles';
import metadata from './metadata';
import {
  createTrackingLabel,
  postAllPaymentOptions,
  purchase,
  useCleanUpPaymentFieldsOnUnmount,
} from './util';

const CustomizeCoverageButton = ({ className }: { className: string }): React.ReactNode => {
  const navigateToCoveragesPage = useNavigateToPage(PagePath.COVERAGES);

  if (!flagValues[FeatureFlags.EXPRESS_PATH]) {
    return null;
  }

  return (
    <Button
      onClick={navigateToCoveragesPage}
      variant='iconTextMedium'
      icon={<IconUIArrowLeft />}
      className={className}
    >
      Customize Coverage
    </Button>
  );
};

interface Props {
  acknowledgements: Acknowledgements;
  carrierName?: CarrierName;
  fields: PaymentFields;
  handleReuseAutoPaymentCheck: (
    event: React.ChangeEvent<HTMLInputElement>,
    newChecked: boolean,
  ) => void;
  iAgreeOpen: boolean;
  isConnectAutoCC: boolean;
  offers: CheckoutOffers;
  onNext: () => Promise<void>;
  onPurchaseErrorNext: () => Promise<void>;
  onPurchaseRetryNext: () => Promise<void>;
  payments: PaymentMethods;
  products: Product[];
  reuseAutoPayment: boolean;
  setIAgreeOpen(val: boolean): void;
  setShowDisplayLoadingPage: (show: boolean) => void;
  signatures: Signatures;
  syncPropertyToAuto: (newType: string) => void;
  disclosureScripts?: ReactNode;
}

export const CheckoutForm: React.FC<Props> = (props) => {
  const {
    acknowledgements,
    carrierName,
    fields,
    handleReuseAutoPaymentCheck,
    iAgreeOpen,
    isConnectAutoCC,
    offers,
    onNext,
    onPurchaseErrorNext,
    onPurchaseRetryNext,
    payments,
    products,
    reuseAutoPayment,
    setIAgreeOpen,
    setShowDisplayLoadingPage,
    signatures,
    syncPropertyToAuto,
    disclosureScripts,
  } = props;
  const { classes, cx } = useStyles();
  const isTablet = useIsTablet();

  const [contactUsOpen, setContactUsOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showIAgreeSpinner, setShowIAgreeSpinner] = useState(false);
  const [isPurchaseDisabled, setIsPurchaseDisabled] = useState(false);
  // TODO This always must be derived state
  const [hasTokens, setHasTokens] = useState(true);
  const [hasBankPayToken, setHasBankPayToken] = useState(true);
  const [hasRecurringPayToken, setHasRecurringPayToken] = useState(true);
  const currentPostBindRetryCount = sessionStorage.getItem('PostBindRetryCount') as number;
  const dispatch = useDispatch();
  const dalSessionId = useSelector(getDalSessionId);
  const offerSetId = useSelector(getOfferSetId);
  const purchaseRequest = useSelector((state: RootStore) => getPurchaseRequest(state, products));
  const checkoutRedesignFeatureFlag = flagValues[FeatureFlags.CHECKOUT_PAGE_REDESIGN];
  const stateRef = useRef<PurchaseErrorReason | null>(null);
  const shouldGenerateTokenForEFTPay = flagValues[FeatureFlags.EFT_PAY_THROUGH_PAYMENT_GATEWAY];
  stateRef.current = useSelector(getPurchaseError);

  const initValues = useRef(useGetInitValues()());
  const getFields = useGetFields();
  const getConditions = useGetConditionValues();
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues,
    fields: getFields(),
    conditions: getConditions(),
  });

  const handleIAgreeClose = useCallback((): void => {
    setIAgreeOpen(false);
  }, [setIAgreeOpen]);

  const handleContactUsClose = useCallback((): void => {
    setContactUsOpen(false);
  }, []);

  const handleBackToAgreeClick = useCallback((): void => {
    setContactUsOpen(false);
    setIAgreeOpen(true);
  }, [setIAgreeOpen]);

  const handleIAgreeClick = useCallback(async () => {
    setShowIAgreeSpinner(true);
    // TODO: Remove this dispatch and move it to IAgree modal
    await dispatch(postAcknowledgementsResponse({ categories: ['IAgree'] }));
    setIAgreeOpen(false);
    setShowIAgreeSpinner(false);
  }, [dispatch, setIAgreeOpen, setShowIAgreeSpinner]);

  const handleHelpButtonClick = useCallback(async () => {
    setIAgreeOpen(false);
    setContactUsOpen(true);
  }, [setIAgreeOpen]);

  const handleCheckoutSaveAndExit = useCallback(() => {
    if (fields.auto) {
      fields.auto.paymentAcknowledgement?.props.actionOnComplete(null);
      fields.auto.acknowledgementName?.props.actionOnComplete(null);
      fields.auto.pniAcknowledgementName?.props.actionOnComplete(null);
      fields.auto.sniAcknowledgementName?.props.actionOnComplete(null);
    }

    if (fields.property) {
      fields.property.paymentAcknowledgement?.props.actionOnComplete(null);
      fields.property.acknowledgementName?.props.actionOnComplete(null);
      fields.property.pniAcknowledgementName?.props.actionOnComplete(null);
      fields.property.sniAcknowledgementName?.props.actionOnComplete(null);
    }
  }, [fields]);

  const offerProductsSelected = useSelector(getOfferProductsSelected);

  const productsSummary = useSelector((state: RootStore) =>
    getPostBindProductsSummary(state, offerProductsSelected),
  );

  const canProductBeRetried = useCallback(
    (product: Product, purchasePolicyStatus: Record<string, ProductPurchaseStatus>) =>
      purchasePolicyStatus[product].errorReason
        ? PostBindRetryMessaging[product].find((key) => {
            // if we have a secondarymessagekey in our metadata we need to do a substring check
            // instead of a straight equality check for matching errorReason for supported retry scenarios.
            if (key.secondaryMessageKey) {
              return (
                purchasePolicyStatus[product].errorReason?.includes(key.secondaryMessageKey) &&
                purchasePolicyStatus[product].errorReason?.includes(key.messageKey || '')
              );
            }

            return key.messageKey === purchasePolicyStatus[product].errorReason;
          })
        : undefined,

    [],
  );

  function getPurchaseResultsSummary(
    productsSummary: PostBindSummary[],
    canProductBeRetried: (
      product: Product,
      purchasePolicyStatus: Record<string, ProductPurchaseStatus>,
    ) => PostBindMessage | undefined,
  ): { failedLength: number; retryLength: number; purchasedLength: number } {
    const purchasePolicyStatus = sessionStorage.getItem('purchasePolicyStatus') as Record<
      string,
      ProductPurchaseStatus
    >;
    const failed = [];
    const retry = [];
    const purchased = [];
    for (const { product, getProductIsPurchased } of productsSummary) {
      if (!purchasePolicyStatus) {
        failed.push(product);
      } else {
        const purchaseStatus = purchasePolicyStatus[product]?.purchaseStatus;
        if (getProductIsPurchased(purchaseStatus as unknown as ProductPurchaseStatus)) {
          failed.push(product);
        } else if (purchaseStatus === PurchaseStatusCode.ERROR) {
          const errorMsg = canProductBeRetried(product, purchasePolicyStatus);
          if (errorMsg) {
            failed.push(product);
            retry.push(product);
          }
        } else {
          purchased.push(product);
        }
      }
    }

    return {
      failedLength: failed.length,
      retryLength: retry.length,
      purchasedLength: purchased.length,
    };
  }

  const handleSubmit = useCallback(async () => {
    // Ensure tokens are in place for carriers that require them
    setHasTokens(true);
    setHasBankPayToken(true);
    setHasRecurringPayToken(true);
    let purchaseTokens = true;
    Object.values(purchaseRequest).forEach((purchase) => {
      // If token is not available, set purchaseTokens = false and purchase won't be called
      if (purchase.paymentOption.creditCard && !purchase.paymentOption.creditCard.token) {
        setHasTokens(false);
        purchaseTokens = false;
        const firstPaymentMethod = document.querySelector('[class*="CheckoutForm-box"]');
        if (firstPaymentMethod) {
          scrollToElement({ element: firstPaymentMethod });
        }
      }
      // If bank pay token is not available, set purchaseTokens = false and purchase won't be called
      if (
        shouldGenerateTokenForEFTPay &&
        purchase.paymentOption.bankAccount &&
        !purchase.paymentOption.bankAccount.financialAccountToken
      ) {
        setHasBankPayToken(false);
        purchaseTokens = false;
      }
      // If recurring pay bank pay token is not available, set purchaseTokens = false and purchase won't be called
      if (
        shouldGenerateTokenForEFTPay &&
        purchase.paymentOption.banckAccountForRecurringPay &&
        !purchase.paymentOption.banckAccountForRecurringPay.financialAccountToken
      ) {
        setHasRecurringPayToken(false);
        purchaseTokens = false;
      }
    });
    if (
      !isPurchaseDisabled &&
      validateForm().isValid &&
      dalSessionId &&
      offerSetId &&
      purchaseTokens
    ) {
      setIsProcessing(true);
      // TODO We want SAPI to return all remote validation errors and assign them to the respective fields in patchFormValues, so we can return early and automatically show errors
      await patchFormValues();

      const results = await Promise.all([
        dispatch(postAllPaymentOptions({ dalSessionId, offers, payments })),
        dispatch(postAcknowledgementsResponse({ categories: ['Signature', 'Disclosure'] })),
      ]);
      const didPostAllPaymentOptionsSucceed = results[0];
      if (!didPostAllPaymentOptionsSucceed) {
        setIsProcessing(false);

        return;
      }
      const skipFetchPostBindQuestions = metadata.skipFetchPostBindQuestions;
      await dispatch(
        purchase({ dalSessionId, offerSetId, offers, products, skipFetchPostBindQuestions }),
      );
      if (stateRef.current === PurchaseErrorReason.CREDIT_CARD_DECLINED_ERROR) {
        setIsProcessing(false);

        return;
      }
      setShowDisplayLoadingPage(true);
      const { failedLength, retryLength, purchasedLength } = getPurchaseResultsSummary(
        productsSummary,
        canProductBeRetried,
      );

      const displayTechnicalFailure = sessionStorage.getItem('displayTechnicalFailure');
      if (displayTechnicalFailure || currentPostBindRetryCount > 3) {
        await onPurchaseErrorNext();
        setIsProcessing(false);
      } else if (purchasedLength) {
        await onNext();
      } else if (failedLength) {
        if (retryLength) {
          await onPurchaseRetryNext();
        } else {
          // Showing error page temporarily.
          // Remove this block when we replace membership number validation logic
          await onPurchaseErrorNext();
        }
        setIsProcessing(false);
      } else {
        await onNext();
        setIsProcessing(false);
      }
    }
  }, [
    purchaseRequest,
    isPurchaseDisabled,
    validateForm,
    offerSetId,
    patchFormValues,
    dispatch,
    dalSessionId,
    offers,
    payments,
    products,
    setShowDisplayLoadingPage,
    productsSummary,
    canProductBeRetried,
    currentPostBindRetryCount,
    onPurchaseErrorNext,
    onNext,
    onPurchaseRetryNext,
    shouldGenerateTokenForEFTPay,
  ]);

  useCleanUpPaymentFieldsOnUnmount({ fields, payments, products });

  // TODO const checkoutData = useSelector(getCheckoutDataFromOffers);
  // TODO premiumPlan is included in getCheckoutDataFromOffers
  // TODO however if the user has a bundle, they can select monthly-full on the checkout page for each product (in the bundle) individually
  // TODO whereas on all prior pages - only for the entire bundle
  const premiumPlan = useSelector(getSelectedPaymentPlan);
  // TODO Replace checkoutData custom stuff below with getCheckoutDataFromOffers
  const checkoutData = useMemo(
    () =>
      products.reduce((acc, product) => {
        const productName = getProductNameFromProduct(product);
        const reducedProductName = getReducedProductNameFromProduct(product);
        const offer = offers[reducedProductName];
        const payment = payments[reducedProductName];
        if (offer && payment) {
          acc[reducedProductName] = {
            policyDuration: offer.offer.policyDuration,
            premiumAmount: parseDollar(
              payment.paymentPremium || offer.offer?.[premiumPlan]?.totalPremium,
            ),
            monthlyAmount: parseDollar(payment.paymentMonthly),
            productName,
            paymentPlan: payment.paymentPlan,
          };
        }

        return acc;
      }, {} as CheckoutData),
    [products, offers, payments, premiumPlan],
  );

  const iAgreeDialogOpen = useMemo(() => {
    const hasAcknowledgements = Object.values(acknowledgements).some(
      (acknowledgement) => acknowledgement?.length,
    );

    return iAgreeOpen && hasAcknowledgements;
  }, [iAgreeOpen, acknowledgements]);

  const buttonTrackingLabel = createTrackingLabel({ offers, premiumPlan });

  const checkoutCardProps = {
    buttonDisabled: isPurchaseDisabled,
    buttonText: 'Purchase',
    buttonTrackingLabel,
    checkoutData,
    checkoutElement: 'choice.checkoutPage.purchaseThesePoliciesButton',
    isMVRActive: useSelector(getIsMVRActive),
    isProcessing,
    onCheckout: handleSubmit,
  };

  const isDesktop = useIsDesktop();

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container className={classes.formContainer}>
          {metadata.shouldRenderTitle && <CheckoutPageTitle carrierName={carrierName} />}
          <CustomizeCoverageButton className={classes.customizeCoverageButton} />
          <CheckoutFormBody
            products={products}
            offers={offers}
            signatures={signatures}
            payments={payments}
            handleReuseAutoPaymentCheck={handleReuseAutoPaymentCheck}
            reuseAutoPayment={reuseAutoPayment}
            syncPropertyToAuto={syncPropertyToAuto}
            isConnectAutoCC={isConnectAutoCC}
            setIsPurchaseDisabled={setIsPurchaseDisabled}
            isPatchFormInProgress={isPatchFormInProgress}
            hasTokens={hasTokens}
            iAgreeOpen={iAgreeOpen}
            hasBankPayToken={hasBankPayToken}
            hasRecurringPayToken={hasRecurringPayToken}
          />
          {env.static.isAgent && disclosureScripts ? disclosureScripts : null}
          <GridItem topSpacing='lg' xs={12} className={classes.carrierButtons}>
            <Button
              data-testid='bottomPurchase'
              variant='success'
              onClick={handleSubmit}
              isProcessing={isProcessing}
              disabled={isPurchaseDisabled}
              className={classes.next}
              trackingLabel={buttonTrackingLabel}
              trackingName='bottom_purchase_policies_button'
              analyticsElement='choice.checkoutPage.purchaseThesePoliciesButton'
            >
              Purchase
            </Button>
            {!env.static.isAgent && <SaveAndExit handleSave={handleCheckoutSaveAndExit} />}
          </GridItem>
        </Grid>
        {(iAgreeDialogOpen || contactUsOpen) && (
          <IAgreeDialog
            contactUsOpen={contactUsOpen}
            iAgreeOpen={iAgreeDialogOpen}
            isProcessing={showIAgreeSpinner}
            handleIAgreeClick={handleIAgreeClick}
            handleHelpButtonClick={handleHelpButtonClick}
            handleBackToAgreeClick={handleBackToAgreeClick}
            onIAgreeClose={handleIAgreeClose}
            onContactUsClose={handleContactUsClose}
            buttonPlacement='right'
          />
        )}
      </Form>
      {isTablet ? null : checkoutRedesignFeatureFlag ? (
        <Grid
          container
          className={cx(
            classes.sidebarContainerRoot,
            env.static.isAgent && classes.agentSidebarContainerRoot,
            products.length > 1 && classes.bundleSidebarContainerRoot,
          )}
          spacing={2}
          direction='column'
        >
          <Grid item xs={12}>
            <MonthlyPaymentsCheckoutCard
              {...checkoutCardProps}
              showCTAButton
              buttonTrackingName='side_purchase_policies'
              showStateFees
              isCheckout
            />
          </Grid>
        </Grid>
      ) : (
        <CheckoutSidebar {...checkoutCardProps} buttonTrackingName='side_purchase_policies' />
      )}
      {isDesktop ? null : checkoutRedesignFeatureFlag ? (
        <MobileMonthlyPaymentsCheckoutCard
          {...checkoutCardProps}
          buttonTrackingName='mobile_purchase_policies'
          isCheckout
        />
      ) : (
        <MobilePurchaseCard {...checkoutCardProps} buttonTrackingName='mobile_purchase_policies' />
      )}
    </div>
  );
};
