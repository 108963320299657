import type { FormEvent } from 'react';
import { memo, useCallback, useMemo } from 'react';

import { MenuItem, Paper } from '@mui/material';
import Autosuggest from 'react-autosuggest';

import { noop } from '@ecp/utils/common';

import type { TextFieldProps } from '@ecp/components';
import type { Props } from '@ecp/types';

import { TextField } from '../TextField';
import { useStyles } from './AutoComplete.styles';
import { useAutoCompleteInputAriaAutoCompletePatch } from './util';

interface AutoCompleteProps extends Props<string> {
  disabled?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  id?: string;
  label?: string;
  ariaLabel?: string;
  trackingName?: string;
  trackingLabel?: string;
  placeholder?: string;
  required?: boolean;
  suggestions: string[];
  onSuggestionsFetchRequested(value: string): void;
  onSuggestionsClearRequested(): void;
  onSuggestionSelected(value: string): void;
  autoComplete?: string;
}

const RenderInputComponent: Autosuggest.RenderInputComponent &
  React.FC<Autosuggest.RenderInputComponentProps> = (inputProps) => {
  const {
    inputRef = noop,
    ref,
    size: _,
    ...rest
  } = inputProps as Autosuggest.RenderInputComponentProps & TextFieldProps;

  const referenceFunction = (node: HTMLInputElement): void => {
    if (typeof ref === 'function') ref(node);
    if (typeof inputRef === 'function') inputRef(node);
  };

  // eslint-disable-next-line react/jsx-no-bind
  return <TextField inputRef={referenceFunction} {...rest} />;
};

function getSuggestionValue(suggestion: string): string {
  return suggestion;
}

export const AutoComplete: React.FC<AutoCompleteProps> = memo((props) => {
  const {
    value: valueProp,
    ariaLabel,
    actionOnChange,
    suggestions,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    onSuggestionSelected,
    ...rest
  } = props;
  // value will sometimes be assigned null or undefined
  // such as after clicking on element and leaving without filling it out, and if so it will throw error
  const value = valueProp || '';
  const { classes } = useStyles();

  const autoCompleteContainerRef = useAutoCompleteInputAriaAutoCompletePatch();

  const handleChange = useCallback(
    (event: FormEvent, { newValue }: Autosuggest.ChangeEvent): void => {
      actionOnChange(newValue);
    },
    [actionOnChange],
  );

  const handleSuggestionsFetchRequested = useCallback(
    (input: { value: string; reason: string }) => {
      // Avoid fetching advisor details `on focus`, it must be only `on type`
      input.reason !== 'input-focused' && onSuggestionsFetchRequested(input.value);
    },
    [onSuggestionsFetchRequested],
  );

  const handleSuggestionSelected = useCallback(
    (
      event: React.ChangeEvent<EventTarget>,
      { suggestionValue }: { suggestionValue: string },
    ): void => {
      onSuggestionSelected(suggestionValue);
    },
    [onSuggestionSelected],
  );

  const handleComplete: NonNullable<
    Autosuggest.AutosuggestProps<string, string>['inputProps']['onBlur']
  > = useCallback(
    (event, params) => {
      if (params) {
        if (params.highlightedSuggestion) {
          handleSuggestionSelected(event, { suggestionValue: params.highlightedSuggestion });

          return;
        }
        const inputText = event.currentTarget as HTMLInputElement;
        // Clear the text field in case if user doesn't select any suggestion
        // Or if there aren't any suggestions
        if (
          (suggestions.length > 0 && !suggestions.includes(inputText.value)) ||
          suggestions.length === 0
        ) {
          handleSuggestionSelected(event, { suggestionValue: inputText.value });
        }
      }
    },
    [handleSuggestionSelected, suggestions],
  );

  const renderSuggestion = useCallback(
    (
      suggestion: string,
      { isHighlighted }: Autosuggest.RenderSuggestionParams,
    ): React.ReactElement => {
      return (
        <MenuItem selected={isHighlighted} component='div' className={classes.listItem}>
          <div>{suggestion}</div>
        </MenuItem>
      );
    },
    [classes.listItem],
  );

  const renderSuggestionsContainer: Autosuggest.RenderSuggestionsContainer = useCallback(
    (options) => (
      <Paper {...options.containerProps} square aria-label='Suggested advisorNumber'>
        {options.children}
      </Paper>
    ),
    [],
  );

  const theme = useMemo(
    () => ({
      container: classes.container,
      suggestionsContainerOpen: classes.suggestionsContainerOpen,
      suggestionsList: classes.suggestionsList,
      suggestion: classes.suggestion,
    }),
    [
      classes.container,
      classes.suggestion,
      classes.suggestionsContainerOpen,
      classes.suggestionsList,
    ],
  );

  return (
    <div className={classes.root}>
      <Autosuggest
        getSuggestionValue={getSuggestionValue}
        inputProps={{
          ...rest,
          'aria-label': ariaLabel,
          value: value,
          onChange: handleChange,
          onBlur: handleComplete,
          ref: autoCompleteContainerRef,
        }}
        multiSection={undefined}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionSelected={handleSuggestionSelected}
        renderInputComponent={RenderInputComponent}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        suggestions={suggestions}
        theme={theme}
      />
    </div>
  );
});
