import { emptyArray, isEqual, merge, parseDollar } from '@ecp/utils/common';
import { formatDate } from '@ecp/utils/date';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { getVehicleDescription } from '@ecp/features/sales/quotes/auto';
import type { PaymentPlan } from '@ecp/features/sales/shared/constants';
import {
  CarrierAutoPolicyMetadata,
  CarrierVehicleDeductibleMetadata,
  featureFlaggedPropertyCoveragesMetadata,
  propertyCoveragesMetadata,
  VehicleCoverageMetadata,
} from '@ecp/features/sales/shared/metadata';
import type {
  AnswerValue,
  CoverageItem,
  CoveragesFields,
  Driver,
  OptionData,
  PolicySummary,
  PropertyCoverages,
  PropertyCoveragesFields,
  Vehicle,
  VehicleCoverages,
} from '@ecp/features/sales/shared/types';
import type { AutoProduct, ProductName } from '@ecp/features/shared/product';
import type { Field, Option, OptionMetadata, OptionsMetadata, SubCoverages } from '@ecp/types';

import type { OfferInfo } from '../offers/types';
import { getOptionLabelByType } from './getOptionLabelByType';

const getValue = (key: string, options?: Option[]): React.ReactElement | string | undefined =>
  options ? options?.find((e) => e.value === key)?.label : getOptionLabelByType(key, 'CURRENCY');

export const getDisplayValue = (label: React.ReactElement | string | undefined): string => {
  const labelStr = label?.toString() || 'Unavailable';
  const labelStrLowerCase = label?.toString().toLowerCase();
  switch (labelStrLowerCase) {
    case 'true':
      return 'Included';
    case 'no coverage':
    case 'not included':
    case 'none':
      return '+ Add Coverage';
    default:
      return labelStr;
  }
};
const buildCardData = (
  metaData: OptionMetadata,
  type: string,
  field: Field,
  options?: Option[],
  stateCode = '',
): OptionData => {
  const label = getValue(type, options);
  const stateOption = metaData.stateOptions?.[stateCode];

  if (stateOption) {
    const { subCoverages, ...metaDataWithoutSubCoverages } = metaData;
    /* eslint-disable no-param-reassign */
    metaData = { ...metaDataWithoutSubCoverages, ...stateOption };
    const stateSubCoverages = stateOption.subCoverages;
    if (stateSubCoverages) {
      metaData.subCoverages = { ...subCoverages, ...stateSubCoverages }; // deep merge
    } else if (subCoverages) {
      metaData.subCoverages = subCoverages; // copy it back
    }
    // Clean up the state options since we no longer need those
    delete metaData.stateOptions;
  }

  return {
    ...metaData,
    type,
    displayValue: getDisplayValue(label),
    isCovered:
      !!label &&
      ![
        'not included',
        'no coverage',
        'not available',
        'rejected',
        'none',
        '+ Add Coverage',
      ].includes(label.toString().toLowerCase()),
    editable: (options && options.length > 1) || !options,
    field,
  };
};

const buildSubCoverage = (
  coverageFields: CoveragesFields,
  metadataSubCoverages?: SubCoverages,
  handleCoverageItemChange?: (field: Field) => (value: AnswerValue) => Promise<void>,
): SubCoverages | undefined => {
  const subCoverages: SubCoverages = {};

  if (metadataSubCoverages) {
    Object.keys(metadataSubCoverages).forEach((subCoverageKey) => {
      const field = coverageFields[subCoverageKey];
      if (field?.exists) {
        if (handleCoverageItemChange)
          field.props.actionOnComplete = handleCoverageItemChange(field);
        subCoverages[subCoverageKey] = {
          title: metadataSubCoverages[subCoverageKey].title,
          primaryText: metadataSubCoverages[subCoverageKey].primaryText,
          isPipSubCoverage: metadataSubCoverages[subCoverageKey].isPipSubCoverage,
          isExcludeDriver: metadataSubCoverages[subCoverageKey].isExcludeDriver,
          field,
        };
        field.noScrollError = !!metadataSubCoverages[subCoverageKey].isPipSubCoverage;
      }
    });
  }

  return isEqual(subCoverages, {}) ? undefined : subCoverages;
};

const buildCoverageItems = (
  optionsMetadata: OptionsMetadata | undefined,
  coveragesFields: CoveragesFields,
  stateCode?: string,
  handleCoverageItemChange?: (field: Field) => (value: AnswerValue) => Promise<void>,
): CoverageItem[] => {
  if (!optionsMetadata) return emptyArray as unknown as CoverageItem[];

  const items = Object.keys(optionsMetadata).reduce((acc, coverageKey) => {
    const field = coveragesFields[coverageKey];
    if (field?.exists) {
      if (handleCoverageItemChange) field.props.actionOnComplete = handleCoverageItemChange(field);

      const data = buildCardData(
        optionsMetadata[coverageKey],
        field.props.value,
        field,
        field.props.options,
        stateCode,
      );
      // !TODO Consider removing refKey logic everywhere, since we have refFieldKey logic to
      // link mutually dependent fields for validation purposes, whereas it should reside in SAPI questions validation criteria
      const refKey = optionsMetadata[coverageKey].refFieldKey;
      if (refKey) {
        data.refField = coveragesFields[refKey];
      }
      const metadataSubCoverages = data?.subCoverages;
      data.subCoverages = buildSubCoverage(
        coveragesFields,
        metadataSubCoverages,
        handleCoverageItemChange,
      );

      acc.push(data);
    }

    return acc;
  }, [] as CoverageItem[]);

  return items;
};

export const buildAutoPolicyCoverage = (
  productKey: AutoProduct,
  policyCoverageFields: CoveragesFields,
  stateCode = '',
  handleCoverageItemChange?: (field: Field) => (value: AnswerValue) => Promise<void>,
  drivers?: Driver[],
): CoverageItem[] => {
  const metaData = CarrierAutoPolicyMetadata[productKey];

  // goes through metaData subCoverages if matches <> adds new subCoverages with driverId key and existing title and primaryText
  // example key: pipExcludeDriver-<driver.id>  => pipExcludeDriver-0ntkc2ya
  Object.keys(metaData).forEach((covKey) => {
    const subCoverages = metaData[covKey].subCoverages;
    if (subCoverages) {
      Object.keys(subCoverages).forEach((subCovKey) => {
        if (/<.+>/.test(subCovKey)) {
          if (drivers) {
            drivers.forEach((driver) => {
              const driverId = driver.ref.split('.')[1];
              const driverIdKey = subCovKey.replace(/<.+>/, driverId);
              subCoverages[driverIdKey] =
                metaData[covKey].stateOptions?.[stateCode]?.subCoverages?.[subCovKey] ||
                subCoverages[subCovKey];
            });
          }
        }
      });
    }
  });

  return buildCoverageItems(
    CarrierAutoPolicyMetadata[productKey],
    policyCoverageFields,
    stateCode,
    handleCoverageItemChange,
  );
};

export const buildVehicleCoverages = (
  productKey: AutoProduct,
  vehicles: Vehicle[],
  vehicleCoverageFields: { [key: string]: CoveragesFields },
  stateCode?: string,
  handleCoverageItemChange?: (field: Field) => (value: AnswerValue) => Promise<void>,
): VehicleCoverages[] => {
  return vehicles.map((vehicle) => {
    const { year, make, model, ref } = vehicle;
    const coveragesFields = vehicleCoverageFields[ref];

    return {
      year,
      make,
      model,
      type: model,
      ref,
      deductibles: buildCoverageItems(
        CarrierVehicleDeductibleMetadata[productKey],
        coveragesFields,
        stateCode,
        handleCoverageItemChange,
      ),
      coverages: buildCoverageItems(
        VehicleCoverageMetadata[productKey],
        coveragesFields,
        stateCode,
        handleCoverageItemChange,
      ),
      description: getVehicleDescription(vehicle),
    };
  });
};

export const buildPropertyCoverages = (
  productKey: string,
  propertyCoverageFields: PropertyCoveragesFields,
  stateCode?: string,
  handleCoverageItemChange?: (field: Field) => (value: AnswerValue) => Promise<void>,
): PropertyCoverages => {
  const enableIquoteHo4Coverages = flagValues[FeatureFlags.IQUOTE_HO4_COVERAGES];

  const propertyCoverages = Object.keys(propertyCoverageFields).reduce((acc, key) => {
    let coveragesMetadata: OptionsMetadata;
    if (enableIquoteHo4Coverages) {
      coveragesMetadata = merge(
        {},
        propertyCoveragesMetadata[key][productKey],
        featureFlaggedPropertyCoveragesMetadata[key][productKey],
      );
    } else {
      coveragesMetadata = propertyCoveragesMetadata[key][productKey];
    }
    acc[key] = buildCoverageItems(
      coveragesMetadata,
      propertyCoverageFields[key],
      stateCode,
      handleCoverageItemChange,
    );

    return acc;
  }, {} as PropertyCoverages);

  return propertyCoverages;
};

export const buildPolicySummaryData = (
  coverageType: ProductName,
  scrollToEdit: 'vehicle-coverages' | 'home-coverages' | 'renters-coverages',
  startDate: string,
  offerInfo: OfferInfo,
  premiumPlan: PaymentPlan,
): PolicySummary => {
  const policy: PolicySummary = {
    coverageType,
    premiumAmount: parseDollar(offerInfo.fullPremium?.totalPremium),
    dueAmount: parseDollar(offerInfo[premiumPlan]?.totalPremium),
    downPayment: parseDollar(offerInfo.monthlyPremium?.downPayment),
    monthlyAmount: parseDollar(offerInfo.monthlyPremium?.installmentPayment),
    paymentPlan: offerInfo.monthlyPremium?.paymentPlan,
    totalMonthlyPremium: parseDollar(offerInfo.monthlyPremium?.totalPremium),
    policyDuration: offerInfo.policyDuration || '',
    policyStartDate: formatDate(startDate),
    scrollToEdit,
    items: [],
  };
  if (offerInfo.coveragePolicyKeys) {
    Object.keys(offerInfo.coveragePolicyKeys).forEach((item) => {
      policy.items.push({
        text: item,
        value: offerInfo.coveragePolicyKeys ? offerInfo.coveragePolicyKeys[item] : '',
        variant: 'regular',
      });
    });
  }

  return policy;
};
