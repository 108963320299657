import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'RetrieveForm' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    ...theme.mixins.form,
    marginTop: 50,
    [theme.breakpoints.up('lg')]: {
      marginTop: 60,
      padding: '0 60px',
    },
  },
  chaseLinkError: {
    margin: '15px 0',
  },
  landingPageButton: {
    marginTop: 40,
  },
  alert: {
    marginTop: 10,
    marginBottom: 10,
  },
  subtitle: {
    ...theme.typography.subtitle,
    marginTop: 5,
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      ...theme.typography.h2,
      fontWeight: 400,
    },
  },
  backQuotesButton: {
    display: 'block',
    marginTop: 20,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      padding: '0 15px',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
    },
  },
}));
