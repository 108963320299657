import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'StateFeesSection' })((theme) => ({
  linkTag: {
    ...theme.typography.body2,
    color: theme.palette.text.link,
    padding: 0,
    verticalAlign: 'baseline',
    height: 'auto',
  },
  stateFeeText: theme.typography.body2,
  stateFeesContainer: {
    marginTop: 10,
    textAlign: 'center',
    '& p': {
      textAlign: 'center',
    },
  },
}));
