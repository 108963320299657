import { PhoneLink } from '@ecp/features/sales/shared/components';
import type { Product } from '@ecp/features/shared/product';
import { partner } from '@ecp/partners';

import { NachaAuthorizationAgreement } from '../../../../../metadata/RecurringPayment/GeneralCheckoutDisclaimers';

export type AcknowledgementFunction = (partnerName?: string) => React.ReactElement | undefined;

export interface PaymentPlanAcknowledgement {
  default: AcknowledgementFunction;
  Full: AcknowledgementFunction;
  paymentPlan?: 'default' | 'Full';
  [key: string]: AcknowledgementFunction | string | undefined;
}

// TODO paymentPlan has to be enum vs string, getPaymentPlanForProduct typing is too wide
// TODO This overlaps with libs/features/sales/checkout/src/metadata/RecurringPayment/GeneralCheckoutDisclaimers.tsx
// TODO Short term decision is to maintain this in a separate location and look at the payment disclosure holistically
export type Metadata = Partial<Record<Product, Record<string, PaymentPlanAcknowledgement>>>;

const defaultAcknowledgement: PaymentPlanAcknowledgement = {
  default: () => (
    <>
      You authorize Homesite Group, Incorporated (Homesite) to initiate the payment listed on this
      page. Your initial payment will be submitted once you select "Purchase Policy". You further
      authorize Homesite to initiate debit and/or credit entries electronically from your bank
      account or credit card indicated above for the purpose of insurance premiums for this policy.
      Homesite will make payment deductions in the amounts and on the due dates indicated on your
      billing statement. Refer to your bill for this schedule of deductions and any applicable fees.
      This Authorization shall apply to any renewal, reinstated, or amended policy with Homesite
      unless revoked by you. The charge will appear on your bank or credit card statement as
      “Homeowners Insurance.” Note that Homesite has the right to discontinue your enrollment at any
      time. Refunds or credits, if any, may be made directly to your account or card. Changes to
      your policy or premium may change the amount debited. It is your responsibility to notify us
      if you change financial institutions. You can cancel your payment deductions by calling us at{' '}
      <PhoneLink withUnderlinedLinkStyle number={partner.shared.salesPhoneNumber} />.
    </>
  ),
  Full: (partnerName = partner.shared.partnerDisplayName) => (
    <>
      I authorize Homesite Group, Incorporated to initiate the payment listed on this page. I
      understand that payment will be submitted once I select "Purchase Policy". If you have
      questions regarding this payment, please contact {partnerName} at{' '}
      <PhoneLink withUnderlinedLinkStyle number={partner.shared.salesPhoneNumber} />. Agents are
      available {partner.shared.salesPhoneHours}.
    </>
  ),
};

const metadata: Metadata = {
  'homesite.renters': {
    EFT: {
      default: () => (
        <NachaAuthorizationAgreement
          product='renters'
          defaultComponent={defaultAcknowledgement.default()}
        />
      ),
      Full: () => (
        <NachaAuthorizationAgreement
          product='renters'
          defaultComponent={defaultAcknowledgement.Full()}
        />
      ),
    },
    CreditCard: defaultAcknowledgement,
    Mortgage: defaultAcknowledgement,
    CostcoVisa: defaultAcknowledgement,
  },
};

export default metadata;
