export const enum PurchaseErrorReason {
  MISSING_CREDITCARD_TOKEN_ERROR = 'MISSING_CREDITCARD_TOKEN_ERROR',
  CREDIT_CARD_DECLINED_ERROR = 'CREDIT_CARD_DECLINED_ERROR',
  INVALID_ROUTING_NUMBER_ERROR = 'INVALID_ROUTING_NUMBER_ERROR',
  INVALID_BANK_ACCOUNT_NUMBER_ERROR = 'INVALID_BANK_ACCOUNT_NUMBER_ERROR',
  INVALID_MEMBERSHIP_FAILURE = 'INVALID_MEMBERSHIP_FAILURE',
  UNKNOWN_PURCHASE_FAILURE = 'UNKNOWN_PURCHASE_FAILURE',
}

export const enum ErrorReason {
  // DNQ - 'Do not quote' is hard stop decline
  DNQ = 'DNQ',
  // CNQ - 'Could not quote' is soft stop where they are missing info and need to call call center to complete the quote
  CNQ = 'CNQ',
  // QNB - 'Quote not bound' is quoted but not Bound and need to call call center to complete the quote
  QNB = 'QNB',
  // Something happened preventing a proper response or ability to provide a quote Eg. timeout, service not avilable
  ERROR = 'ERROR',
  // This error means we have no products to support for the zip provided
  PARTNER_NOT_ELIGIBLE = 'PARTNER_NOT_ELIGIBLE',
  // This error means home product is not supported for the zip provided
  LOB_HOME_NOT_ELIGIBLE = 'LOB_HOME_NOT_ELIGIBLE',
  // This error means auto product is not supported for the zip provided
  LOB_AUTO_NOT_ELIGIBLE = 'LOB_AUTO_NOT_ELIGIBLE',
  // This error means renters product is not supported for the zip provided
  LOB_RENTERS_NOT_ELIGIBLE = 'LOB_RENTERS_NOT_ELIGIBLE',
  // This error means bundle product is not supported for the zip provided
  LOB_BUNDLE_NOT_ELIGIBLE = 'LOB_BUNDLE_NOT_ELIGIBLE',
  GLOBAL = 'GLOBAL',
  RULES_EXCEPTION = 'RULES_EXCEPTION',
  // This error means agent is not licensed to support for the zip provided
  AGENT_NOT_LICENSED_FOR_STATE = 'AGENT_NOT_LICENSED_FOR_STATE',
  // RVP - 'Risk Verification Platform' is a hard stop because of failed verification on drivers information
  RVP = 'RVP',
  PREFILL_FAILED = 'PREFILL_FAILED',
  POLICY_TYPE_INVALID = 'POLICY_TYPE_INVALID',
  CROSS_ACCOUNT_RETRIEVE = 'CROSS_ACCOUNT_RETRIEVE',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
}

export const enum RetreivalErrorReason {
  DOB_OR_EMAIL_MISMATCH = 'DOB_OR_EMAIL_MISMATCH',
  PRODUCT_MISMATCH = 'PRODUCT_MISMATCH',
  LAST_NAME_MISMATCH = 'LAST_NAME_MISMATCH',
  ZIPCODE_MISMATCH = 'ZIPCODE_MISMATCH',
  QUOTE_NOT_FOUND = 'QUOTE_NOT_FOUND',
}
