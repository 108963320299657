import { memo } from 'react';

import { Grid } from '@mui/material';

import metadata from '../metadata';
import { useStyles } from './Header.styles';

export const Header: React.FC = memo(() => {
  const { classes } = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <p className={classes.topHeader}>{metadata.headerTitle}</p>
        {metadata.headerBody && <p className={classes.topContent}>{metadata.headerBody}</p>}
      </Grid>
    </Grid>
  );
});
