import { TooltipWithIcon } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import type { OptionProps } from '@ecp/features/sales/shared/types';
import { LineOfBusiness } from '@ecp/features/shared/product';

import type { QuestionProps } from '../../questionProps.types';

interface Props extends QuestionProps {
  groupLabelAuto?: string;
  groupLabelHome?: string;
  helpTextAuto?: string;
  helpTextHome?: string;
  forLineOfBusiness: LineOfBusiness.HOME | LineOfBusiness.AUTO;
}

export const LengthOfResidenceQuestion: React.FC<Props> = (props) => {
  const {
    forLineOfBusiness,
    groupLabelHome = 'How many years have you lived at this address?',
    groupLabelAuto = 'How many years have you been living in your current residence?',
    trackingName = 'ResidencyLength',
    helpTextAuto = 'Tell us how many years you’ve been living where you live right now.',
    helpTextHome = 'Tell us how many years you’ve been living where you live right now. Or, if you’re getting this quote for a new property, tell us how long you’ve been living at the address you’ll be moving from.',
    label = 'Select',
  } = props;

  const usePropertyField = useFieldWithPrefix('property', 'property');
  const lengthOfResidenceHome = usePropertyField('lengthOfResidence');
  const lengthOfResidenceAuto = usePropertyField('auto.lengthOfResidence');

  const shouldUseHomeQuestion = forLineOfBusiness === LineOfBusiness.HOME;

  const lengthOfResidence = shouldUseHomeQuestion ? lengthOfResidenceHome : lengthOfResidenceAuto;
  const groupLabel = shouldUseHomeQuestion ? groupLabelHome : groupLabelAuto;
  const helpText = shouldUseHomeQuestion ? helpTextHome : helpTextAuto;

  useAddFields({ lengthOfResidence });

  if (!lengthOfResidence.exists) return null;

  return (
    <Select
      {...(lengthOfResidence.props as OptionProps)}
      id='lengthOfResidence'
      groupLabel={
        <>
          {`${groupLabel}`}
          <TooltipWithIcon title={helpText} />
        </>
      }
      label={label}
      data-testid='lengthOfResidence'
      trackingName={trackingName}
      inputButtonAriaLabel='lengthOfResidence'
      fullWidth={false}
    />
  );
};
