import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<void, 'error'>({ name: 'DiscountCard' })(
  (...[theme, , classes]) => ({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    container: {
      padding: 15,
    },
    divider: {
      margin: '15px 0',
    },
    vehicleDriverList: {
      display: 'flex',
      flexDirection: 'column',
    },
    modalTitleContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    dialogPaper: {
      maxWidth: 400,
    },
    modalTitle: {
      ...theme.typography.body1Bold,
      flex: 1,
      marginLeft: 12,
      alignSelf: 'center',
    },
    helpIcon: {
      fontSize: 20,
      ...theme.mixins.setColorSvg(theme.palette.primary.main),
      pointerEvents: 'all',
      verticalAlign: 'middle',
    },
    discountTitleAndNameDiv: {
      overflow: 'auto',
      marginRight: 10,
    },
    discountTitle: theme.typography.body1Bold,
    discountContainer: {
      display: 'flex',
      flexDirection: 'row',
      textOverflow: 'ellipsis',
    },
    discount: {
      ...theme.typography.body2,
      [theme.breakpoints.down('md')]: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'block',
        overflow: 'hidden',
      },
    },
    reviewButton: {
      width: 'max-content',
      [theme.breakpoints.down('md')]: {
        height: 20,
      },
    },
    iconButton: {
      height: 14,
      width: 14,
      minWidth: 14,
    },
    closeIcon: {
      height: 14,
      width: 14,
      cursor: 'pointer',
    },
    buttonGrid: {
      margin: 'inherit',
      width: '100%',
      display: 'flex',
      padding: '15px 5px 0 5px',
    },
    formLabel: {
      marginLeft: 13,
      lineHeight: '36px',
      [`&.${classes.error}`]: {
        color: theme.palette.error.main,
      },
    },
    error: {
      color: theme.palette.error.main,
    },
    fieldError: {
      marginLeft: 34,
      marginTop: 15,
    },
    svg: {
      ...theme.mixins.svg(),
      '& svg, &': {
        height: 30,
        width: 30,
        marginRight: 10,
      },
    },
    subDiscountDivider: {
      padding: '0 10px',
    },
    subDiscountTitle: {
      padding: '0 10px 0 34px',
      marginBottom: 15,
    },
    subDiscountItem: {
      padding: '4px 10px 4px 24px',

      '&:first-of-type': {
        paddingTop: '8px',
      },

      '&:last-of-type': {
        paddingBottom: 0,
      },
    },
    radioButtonMargin: {
      marginBottom: '0',
    },
    discountRadioButton: {
      padding: '0px 10px 0px 34px',
      color: theme.palette.primary.main,

      '&:first-of-type': {
        paddingTop: '8px',
      },

      '&:last-of-type': {
        paddingBottom: '0px',
      },
    },
  }),
);
