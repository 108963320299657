import { Grid } from '@mui/material';

import { TooltipWithIcon } from '@ecp/components';
import type { Percentages } from '@ecp/features/sales/shared/types';
import type { CardOption, Field } from '@ecp/types';

import { PercentageGroup } from '../PercentageGroup';
import { useStyles } from './PercentageGroupConnector.styles';

interface Props {
  title: string;
  subtitle?: string;
  helpText?: string | React.ReactElement;
  options: CardOption[];
  label?: string;
  percentages: Percentages;
  uiField: Field;
  onAdd: (key: string, value: string) => Promise<void>;
  onUpdate: (percentages: Percentages) => Promise<void>;
  onRemove: (key: string) => Promise<void>;
  optionTrackingName?: string;
  percentTrackingName?: string;
  moreLessTrackingName?: string;
  hidePercentageSelected?: boolean;
  moreLessTrackingLabel?: (value: string) => string;
  onAddAndUpdateAll?: (key: string, value: string, percentages: Percentages) => Promise<void>;
  sortByKey?: boolean;
}

export const PercentageGroupConnector: React.FC<Props> = (props) => {
  const { title = '', subtitle, label, options, helpText, ...rest } = props;
  const { classes } = useStyles();

  const typeLabel = label || '';
  const percentageTitle = `What’s the percentage of each ${typeLabel} type?`;
  const questionTitleWords = title.split(' ');
  const questionTitleLastWord = questionTitleWords.pop();
  const groupTitle = (
    <>
      {questionTitleWords.join(' ')}
      <span className={classes.title}>
        &nbsp;{questionTitleLastWord}
        {helpText && <TooltipWithIcon title={helpText} />}
      </span>
    </>
  );

  return (
    <Grid item xs={12}>
      <PercentageGroup
        {...rest}
        groupTitle={groupTitle}
        percentageTitle={percentageTitle}
        helperText={subtitle}
        options={options}
      />
    </Grid>
  );
};
