import { Grid } from '@mui/material';

import { useEffectOnce } from '@ecp/utils/react';

import { getLineOfBusiness } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import { RetrieveSlider } from '@ecp/features/sales/shell';
import {
  GraphicLandingProductImageRentersDesktop,
  GraphicLandingProductImageRentersMobile,
  useIsTablet,
} from '@ecp/themes/base';

import { useStyles } from './LandingPage.styles';
import { useLandingPage } from './util';

export const LandingPage: React.FC = () => {
  const { classes, cx } = useStyles();
  const isTablet = useIsTablet();
  const lineOfBusiness = useSelector(getLineOfBusiness);
  const lineOfBusinessClassName = lineOfBusiness?.substring(
    lineOfBusiness.indexOf('.') + 1,
  ) as keyof typeof classes;

  const { formType, isLoading, isRetrieve, quoteFormComponent, retrieveFormComponent } =
    useLandingPage();

  useEffectOnce(() => {
    trackSapiAnalyticsEvent({
      element: 'choice.landingPage.page',
      event: 'render',
      eventDetail: 'true',
    });
  }, []);

  if (isLoading) return null;

  return (
    <div className={classes.root} role='main'>
      <div className={classes.maskContainer}>
        {isTablet ? (
          <GraphicLandingProductImageRentersMobile className={classes.hiddenImage} />
        ) : (
          <GraphicLandingProductImageRentersDesktop className={classes.hiddenImage} />
        )}
        <div className={classes.maskBase} />
        <div className={cx(classes.mask, classes[lineOfBusinessClassName])} />
      </div>
      <Grid
        container
        className={cx(classes.mainContainer, isRetrieve && classes.mainContainerIsRetrieve)}
      >
        <RetrieveSlider
          positionB={isRetrieve}
          quoteForm={quoteFormComponent}
          retrieveForm={retrieveFormComponent}
          formType={formType}
          classes={{ mainContentSlider: classes.mainContentSlider }}
        />
      </Grid>
    </div>
  );
};
