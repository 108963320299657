import type { Product } from '@ecp/features/shared/product';

import { getDalSessionId, getDriverRefs } from '../inquiry/selectors';
import { getOfferSetId } from '../offers';
import { wrapThunkActionWithErrHandler } from '../util/wrapThunkActionWithErrHandler';
import { setRatingCriteriaApiGetSuccess } from './actions';
import { getRatingCriteria } from './api';

type FetchRatingCriteria = { dalSessionId?: string; product: Product };
export const fetchRatingCriteria = wrapThunkActionWithErrHandler<FetchRatingCriteria, void>(
  ({ dalSessionId, product }) =>
    async (dispatch, getState) => {
      const dalSessionIdValue = dalSessionId ?? getDalSessionId(getState());
      const offerSetId = getOfferSetId(getState());

      if (!dalSessionIdValue || !offerSetId) {
        return;
      }

      const response = await getRatingCriteria({ dalSessionId: dalSessionIdValue, product });

      const ratingCriteriaResponse = response.payload.data;
      if (ratingCriteriaResponse.platformRatingCriteria.drivers) {
        const driverRefs = getDriverRefs(getState()).map((driverRef) => driverRef.split('.')[1]);
        ratingCriteriaResponse.platformRatingCriteria.drivers.sort((a, b) => {
          return driverRefs.indexOf(a.id as string) - driverRefs.indexOf(b.id as string);
        });
      }

      const productRatingCriteria = {
        productKey: product,
        ratingCriteriaResponse,
      };

      dispatch(setRatingCriteriaApiGetSuccess(productRatingCriteria));
    },
  'fetchRatingCriteria',
);
