import { Alert } from '@ecp/components';
import { CarrierLogo } from '@ecp/features/sales/shared/components';
import { ProductQuoteNumber } from '@ecp/features/sales/shared/components';
import { getIsMVRActive } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { CarrierName } from '@ecp/features/shared/product';
import { IconUISecure } from '@ecp/themes/base';

import { useStyles } from './CheckoutPageTitle.styles';

export interface CheckoutPageTitleProps {
  title?: string;
  carrierName?: CarrierName;
  hasCarrierLogo?: boolean;
}

export const CheckoutPageTitle: React.FC<CheckoutPageTitleProps> = (props) => {
  const { carrierName, hasCarrierLogo = false, title = 'Secure checkout' } = props;
  const { classes } = useStyles();
  const isMVRActive = useSelector(getIsMVRActive);

  return (
    <>
      {!isMVRActive && (
        <div className={classes.alertDiv}>
          <Alert type='warning'>
            <p>
              We are unable to order driving records at this time. You may purchase this policy, but
              undisclosed or inaccurate driving or claim activity may result in a premium adjustment
              or the cancelation of your policy once the reports are obtained.
            </p>
          </Alert>
        </div>
      )}
      <div className={classes.titleContainer}>
        <div className={classes.checkoutTitleSection}>
          <div className={classes.checkoutTitleImages}>
            <IconUISecure className={classes.headerImage} />
            {hasCarrierLogo && carrierName && (
              <CarrierLogo carrierName={carrierName} className={classes.carrierLogo} />
            )}
          </div>
          <p className={classes.title}>{title}</p>
        </div>
        <div className={classes.quoteNumber}>
          <ProductQuoteNumber />
        </div>
      </div>
    </>
  );
};
