import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PostBindPolicyCard' })((theme) => ({
  cardHeader: { marginTop: 0, marginBottom: 0 },
  informationCard: {
    backgroundColor: theme.palette.grey[100],
    borderTop: `4px solid ${theme.palette.primary.main}`,
    padding: '0px 20px 20px 20px',
  },
  errorInformationCard: {
    borderTopColor: theme.palette.error.main,
  },
  headerContainer: {
    display: 'flex',
    marginTop: 20,
    marginBottom: 5,
  },
  leftWrapper: {
    backgroundColor: theme.palette.grey[100],
  },
  successIcon: {
    ...theme.mixins.setColorSvg(theme.palette.success.main),
    width: 20,
    height: 20,
  },
  icon: {
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',

    marginRight: 9,
  },
  successIconContainer: {
    width: 24,
    height: 24,
    backgroundColor: `${theme.palette.success.main}40`, // Add opacity
  },
  errorIconContainer: {
    width: 23,
    height: 23,
    backgroundColor: theme.palette.error.light,
  },
  errorIcon: {
    width: 15,
    height: 15,
    borderRadius: 100,
    backgroundColor: theme.palette.error.light,
    ...theme.mixins.setColorSvg(theme.palette.error.main),
  },
  policyCenterButton: {},
  divider: {},
}));
