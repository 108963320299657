import dayjs from 'dayjs';

import { partner } from '@ecp/partners';

type DayOfWeek = 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun';
interface DailyHours {
  date: string;
  time: string;
}

const daysOfTheWeekOrder: DayOfWeek[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const dayjsMapping: Record<number, DayOfWeek> = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
};

const getSalesPhoneHours = (): DailyHours[] => {
  const response: DailyHours[] = [];
  const dailyHours = partner.shared.salesPhoneHours.split(', ').map((dateTime) => {
    const [date, time] = dateTime.split(': ');

    return {
      date: date,
      time: time,
    } as DailyHours;
  });

  dailyHours.forEach((dailyHour) => {
    const [firstDate, secondDate] = dailyHour.date.split('-');
    const firstIndex = daysOfTheWeekOrder.indexOf(firstDate?.trim() as DayOfWeek);
    const lastIndex = daysOfTheWeekOrder.indexOf(secondDate?.trim() as DayOfWeek);

    if (firstIndex === -1) return;

    response.push({
      date: firstDate,
      time: dailyHour.time,
    });

    if (lastIndex !== -1) {
      for (let i = firstIndex + 1; i <= lastIndex; i++) {
        response.push({
          date: daysOfTheWeekOrder[i],
          time: dailyHour.time,
        });
      }
    }
  });

  return response.sort(
    (a, b) =>
      daysOfTheWeekOrder.indexOf(a.date as DayOfWeek) -
      daysOfTheWeekOrder.indexOf(b.date as DayOfWeek),
  );
};

const salesPhoneHours = getSalesPhoneHours();

export const getTodaysSalesHours = (): string | undefined => {
  const dayOfWeek = dayjs().get('day');

  return salesPhoneHours.find((x) => x.date === dayjsMapping[dayOfWeek])?.time;
};
