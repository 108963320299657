import { useMemo } from 'react';

import { createSelector } from '@reduxjs/toolkit';

import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { LineOfBusinessUnbundled } from '@ecp/features/shared/product';
import { getUnbundledLobFromProductName, LineOfBusiness } from '@ecp/features/shared/product';
import type { CardOption, Fields } from '@ecp/types';

import { getField } from '../form/selectors';
import { getAvailablePolicyTypes } from '../inquiry/selectors';
import { useGetProductFields } from './quoteUtil';

export const useGetLOBFields = (options: CardOption[]): Fields => {
  const dispatch = useDispatch();

  const selectors = options.map((option) => (state: RootStore) => {
    const field = getField(state, { key: option.value, questionKey: option.value, dispatch });

    return field;
  });

  const fieldsSelector = createSelector(selectors, (...fields) => {
    return fields.reduce((acc, field) => {
      if (!field.exists) return acc;
      acc[field.key] = field;

      return acc;
    }, {} as Fields);
  });

  return useSelector(fieldsSelector);
};

/** Filters LineOfBusiness Card options based on SAPI inquiry product field value. */
export const useGetLinesOfBusinessUnbundledSupportedOptions = <
  T extends string = string,
  U extends string = string,
  V extends CardOption<T, U>[] = CardOption<T, U>[],
>(
  options: V,
): V => {
  const { product } = useGetProductFields();

  const linesOfBusinessUnbundledSupported = useSelector(getAvailablePolicyTypes).map(
    getUnbundledLobFromProductName,
  );

  const result = useMemo(() => {
    let eligibleOptions: LineOfBusinessUnbundled[];
    // When inquiry has not been created yet
    if (!product.props.options || linesOfBusinessUnbundledSupported.length === 0)
      eligibleOptions = [LineOfBusiness.AUTO, LineOfBusiness.HOME];
    // When inquiry has been created
    else
      eligibleOptions = product.props.options
        // product.lob question options represent all theoretically available lines of business, so need to narrow down based on product question
        .filter((option) => linesOfBusinessUnbundledSupported.includes(option.value))
        .map((option) => option.value as LineOfBusinessUnbundled);

    return options.filter(
      (option) => eligibleOptions.includes(option.key) || eligibleOptions.includes(option.value),
    ) as typeof options;
  }, [product.props.options, linesOfBusinessUnbundledSupported, options]);

  return result;
};
