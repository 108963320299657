import type { StateOption } from '@ecp/types';

export interface BaseMetadata {
  agreementContent?: string;
}

export interface StateMetadata extends StateOption<BaseMetadata>, BaseMetadata {}
export const metadata: StateMetadata = {
  stateOptions: {},
};

export default metadata;
