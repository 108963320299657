import { LogoPrimaryImageUrl } from '@ecp/themes/base';

export interface Metadata {
  bannerText?: React.ReactNode;
  bannerPages?: Array<string>;
  opcoLogo?: string;
  title?: string;
  showEmailText?: boolean;
  fixedHeader?: boolean;
  styledLogoImage?: boolean;
  primaryLogo: string;
  showVerticalDivider?: boolean;
  showLogoOnlyOnMobile?: boolean;
}

const metadata: Metadata = {
  primaryLogo: LogoPrimaryImageUrl,
  showVerticalDivider: true,
};

export default metadata;
