import { BINDABLE_BRIDGEOVER } from '@ecp/features/sales/shared/constants';
import { getAnswer } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { Navbar as BaseNavbar } from './Navbar.js';
import type { NavbarProps } from './Navbar.js';

export const Navbar: React.FC<NavbarProps> = (props) => {
  const isBindableTransfer = useSelector((state: RootStore) =>
    getAnswer(state, BINDABLE_BRIDGEOVER),
  );

  return !isBindableTransfer ? <BaseNavbar {...props} /> : null;
};
