import { Divider, Grid } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';

import { StateFeesSection } from '@ecp/features/sales/shared/components';
import { PaymentPlan } from '@ecp/features/sales/shared/constants';
import { getOfferDetailsForProduct } from '@ecp/features/sales/shared/store';
import type { OfferInfo, RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AutoProduct, PropertyProduct } from '@ecp/features/shared/product';
import { getProductDisplayNameFromProduct } from '@ecp/features/shared/product';
import { useIsMobile } from '@ecp/themes/base';

import { getMonthlyPaymentsText } from '../../util';
import { EstimatedTooltip } from '../EstimatedTooltip';
import { Recalc } from '../Recalc';
import metadata from './metadata';
import { useStyles } from './MobileMonthlyPaymentsCheckoutCard.styles';

interface Props {
  autoOfferProduct: AutoProduct;
  propertyOfferProduct: PropertyProduct;
  recalculate?: boolean;
  isMVRActive?: boolean;
  showProcess: boolean;
  premiumPlan: PaymentPlan;
  showError?: boolean;
  showStateFees?: boolean;
  isPricingDetailsHidden?: boolean;
}

export const MobileMonthlyPaymentsBundleCoverageView: React.FC<Props> = (props) => {
  const {
    autoOfferProduct,
    propertyOfferProduct,
    isMVRActive,
    isPricingDetailsHidden,
    recalculate,
    showProcess,
    premiumPlan,
    showError,
    showStateFees,
  } = props;
  const { classes, cx } = useStyles();
  const isMobile = useIsMobile();

  const autoOfferDetails = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, autoOfferProduct),
  );
  const propertyOfferDetails = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, propertyOfferProduct),
  );

  if (showError || !autoOfferDetails || !propertyOfferDetails) return null;

  const propertyProductName = getProductDisplayNameFromProduct(propertyOfferProduct);

  const getPremiumFromOffer = (offerDetails: OfferInfo): string => {
    if (premiumPlan === PaymentPlan.MONTHLY_PREMIUM) {
      return parseDollar(offerDetails?.monthlyPremium?.installmentPayment);
    }

    return parseDollar(offerDetails?.fullPremium?.totalPremium);
  };

  return (
    <>
      {/* Tablet View */}
      {isMobile ? (
        <Grid
          xs={12}
          container
          className={classes.bottomDetailsWrapper}
          justifyContent='center'
          data-testid='mobileView'
        >
          <Grid item xs={6}>
            <Grid item xs={12} className={classes.policyDurationHeader}>
              <p className={cx(classes.mobileHeading, classes.textCenter)}>
                <span className={classes.policyDurationHeaderProduct}>Auto</span>{' '}
                {`${autoOfferDetails.policyDuration}-mo policy`}
              </p>
            </Grid>
            <Grid item xs={10} className={classes.premiumsDividerContainer}>
              <Divider aria-hidden='true' />
            </Grid>
            <Grid item xs={12}>
              <p className={cx(classes.policyCheckoutHeaders, classes.textCenter)}>
                {' '}
                {premiumPlan === PaymentPlan.MONTHLY_PREMIUM
                  ? getMonthlyPaymentsText(
                      autoOfferDetails.monthlyPremium?.paymentPlan,
                      autoOfferDetails.monthlyPremium?.downPayment,
                      autoOfferProduct,
                    )
                  : 'One-time payment of'}
              </p>
            </Grid>

            <Grid item xs={12} className={cx(classes.premiumBold, classes.textCenter)}>
              <Recalc
                amount={getPremiumFromOffer(autoOfferDetails)}
                recalculate={recalculate}
                showProcess={showProcess}
              />
              {!isMVRActive && <EstimatedTooltip />}
            </Grid>
            {premiumPlan === PaymentPlan.MONTHLY_PREMIUM && (
              <Grid container>
                {(metadata.showDueTodayAmt || !!autoOfferDetails.monthlyPremium?.downPayment) && (
                  <Grid
                    item
                    xs={12}
                    className={cx(classes.policyCheckoutHeaders, classes.textCenter)}
                  >
                    Due today{' '}
                    <Recalc
                      amount={parseDollar(autoOfferDetails.monthlyPremium?.downPayment)}
                      amountClassname={classes.premiums}
                      recalculate={recalculate}
                      showProcess={showProcess}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <p className={cx(classes.policyCheckoutHeaders, classes.textCenter)}>
                    Policy total{' '}
                    <Recalc
                      amount={parseDollar(autoOfferDetails?.monthlyPremium?.totalPremium)}
                      amountClassname={classes.premiums}
                      recalculate={recalculate}
                      showProcess={showProcess}
                    />
                  </p>
                </Grid>
              </Grid>
            )}
            {!isPricingDetailsHidden && (
              <StateFeesSection
                premiumPlan={premiumPlan}
                offerDetails={autoOfferDetails}
                showStateFees={showStateFees}
              />
            )}
          </Grid>

          <Grid item xs={6}>
            <Grid item xs={12} className={classes.policyDurationHeader}>
              <p className={cx(classes.mobileHeading, classes.textCenter)}>
                <span className={classes.policyDurationHeaderProduct}>{propertyProductName}</span>{' '}
                {`${propertyOfferDetails.policyDuration}-mo policy`}
              </p>
            </Grid>
            <Grid item xs={10} className={classes.premiumsDividerContainer}>
              <Divider aria-hidden='true' />
            </Grid>
            <Grid item xs={12}>
              <p className={cx(classes.policyCheckoutHeaders, classes.textCenter)}>
                {premiumPlan === PaymentPlan.MONTHLY_PREMIUM
                  ? getMonthlyPaymentsText(
                      propertyOfferDetails.monthlyPremium?.paymentPlan,
                      propertyOfferDetails.monthlyPremium?.downPayment,
                      propertyOfferProduct,
                    )
                  : 'One-time payment of'}
              </p>
            </Grid>

            <Grid item xs={12} className={cx(classes.premiumBold, classes.textCenter)}>
              <Recalc
                amount={getPremiumFromOffer(propertyOfferDetails)}
                recalculate={recalculate}
                showProcess={showProcess}
              />
            </Grid>
            {premiumPlan === PaymentPlan.MONTHLY_PREMIUM && (
              <Grid container>
                {(metadata.showDueTodayAmt ||
                  !!propertyOfferDetails.monthlyPremium?.downPayment) && (
                  <Grid
                    item
                    xs={12}
                    className={cx(classes.policyCheckoutHeaders, classes.textCenter)}
                  >
                    Due today{' '}
                    <Recalc
                      amount={parseDollar(propertyOfferDetails.monthlyPremium?.downPayment)}
                      amountClassname={classes.premiums}
                      recalculate={recalculate}
                      showProcess={showProcess}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <p className={cx(classes.policyCheckoutHeaders, classes.textCenter)}>
                    Policy total{' '}
                    <Recalc
                      amount={parseDollar(propertyOfferDetails?.monthlyPremium?.totalPremium)}
                      amountClassname={classes.premiums}
                      recalculate={recalculate}
                      showProcess={showProcess}
                    />
                  </p>
                </Grid>
              </Grid>
            )}
            {!isPricingDetailsHidden && (
              <StateFeesSection
                premiumPlan={premiumPlan}
                offerDetails={propertyOfferDetails}
                showStateFees={showStateFees}
              />
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid
          md={9}
          sm={4}
          xs={4}
          container
          className={classes.bottomDetailsWrapper}
          data-testid='tabletView'
        >
          <Grid item xs={5} className={classes.leftColumnContainer}>
            <Grid item xs={12} className={classes.policyDurationHeader}>
              <p className={classes.mobileHeading}>
                <span className={classes.policyDurationHeaderProduct}>Auto</span>{' '}
                {`${autoOfferDetails.policyDuration}-mo policy`}
                {!isMVRActive && <EstimatedTooltip inline />}
              </p>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={7} className={classes.policyCheckoutHeaders}>
                {premiumPlan === PaymentPlan.MONTHLY_PREMIUM
                  ? getMonthlyPaymentsText(
                      autoOfferDetails.monthlyPremium?.paymentPlan,
                      autoOfferDetails.monthlyPremium?.downPayment,
                      autoOfferProduct,
                    )
                  : 'One-time payment of'}
              </Grid>
              <Grid item xs={5} className={cx(classes.premiumBold)}>
                <Recalc
                  amount={getPremiumFromOffer(autoOfferDetails)}
                  recalculate={recalculate}
                  showProcess={showProcess}
                />
              </Grid>
            </Grid>
            {premiumPlan === PaymentPlan.MONTHLY_PREMIUM && (
              <>
                {(metadata.showDueTodayAmt || !!autoOfferDetails.monthlyPremium?.downPayment) && (
                  <Grid item container>
                    <Grid item xs={7} className={classes.policyCheckoutHeaders}>
                      Due today
                    </Grid>
                    <Grid item xs={5} className={classes.premiums}>
                      <Recalc
                        amount={parseDollar(autoOfferDetails.monthlyPremium?.downPayment)}
                        recalculate={recalculate}
                        showProcess={showProcess}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid item container>
                  <Grid item xs={7} className={classes.policyCheckoutHeaders}>
                    Policy total
                  </Grid>
                  <Grid item xs={5} className={classes.premiums}>
                    <Recalc
                      amount={parseDollar(autoOfferDetails?.monthlyPremium?.totalPremium)}
                      recalculate={recalculate}
                      showProcess={showProcess}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {!isPricingDetailsHidden && (
              <StateFeesSection
                premiumPlan={premiumPlan}
                offerDetails={autoOfferDetails}
                showStateFees={showStateFees}
              />
            )}
          </Grid>

          <Grid item xs={5} className={classes.rightColumnContainer}>
            <Grid item xs={12} className={classes.policyDurationHeader}>
              <p className={classes.mobileHeading}>
                <span className={classes.policyDurationHeaderProduct}>{propertyProductName}</span>{' '}
                {`${propertyOfferDetails.policyDuration}-mo policy`}
              </p>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={7} className={classes.policyCheckoutHeaders}>
                {premiumPlan === PaymentPlan.MONTHLY_PREMIUM
                  ? getMonthlyPaymentsText(
                      propertyOfferDetails.monthlyPremium?.paymentPlan,
                      propertyOfferDetails.monthlyPremium?.downPayment,
                      propertyOfferProduct,
                    )
                  : 'One-time payment of'}
              </Grid>
              <Grid item xs={5} className={cx(classes.premiumBold)}>
                <Recalc
                  amount={getPremiumFromOffer(propertyOfferDetails)}
                  recalculate={recalculate}
                  showProcess={showProcess}
                />
              </Grid>
            </Grid>

            {premiumPlan === PaymentPlan.MONTHLY_PREMIUM && (
              <>
                {(metadata.showDueTodayAmt ||
                  !!propertyOfferDetails.monthlyPremium?.downPayment) && (
                  <Grid item container>
                    <Grid item xs={7} className={classes.policyCheckoutHeaders}>
                      Due today
                    </Grid>
                    <Grid item xs={5} className={classes.premiums}>
                      <Recalc
                        amount={parseDollar(propertyOfferDetails.monthlyPremium?.downPayment)}
                        recalculate={recalculate}
                        showProcess={showProcess}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid item container>
                  <Grid item xs={7} className={classes.policyCheckoutHeaders}>
                    Policy total
                  </Grid>
                  <Grid item xs={5} className={classes.premiums}>
                    <Recalc
                      amount={parseDollar(propertyOfferDetails?.monthlyPremium?.totalPremium)}
                      removeWrapper
                      recalculate={recalculate}
                      showProcess={showProcess}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {!isPricingDetailsHidden && (
              <StateFeesSection
                premiumPlan={premiumPlan}
                offerDetails={propertyOfferDetails}
                showStateFees={showStateFees}
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
