import type { TypographyProps } from '@mui/material';
import { Divider, Typography } from '@mui/material';

import { useStyles } from './NextPageInstructions.styles';

interface Props extends TypographyProps {
  compact?: boolean;
  divider?: boolean;
}

export const NextPageInstructions: React.FC<Props> = (props) => {
  const { children, className, compact, divider, ...rest } = props;
  const { classes, cx } = useStyles();

  return (
    <>
      {divider && <Divider aria-hidden='true' className={classes.divider} />}
      <Typography className={cx(classes.root, className, compact && classes.compact)} {...rest}>
        {children}
      </Typography>
    </>
  );
};
