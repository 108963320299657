import { Fragment, memo } from 'react';

import { Divider, Grid } from '@mui/material';

import { getIsBundleForOfferProductsSelected } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { getProductDisplayName } from '@ecp/features/shared/product';
import { useIsMobile } from '@ecp/themes/base';

import type { CheckoutData, CheckoutPremium } from '../../../types';
import { EstimatedTooltip } from '../../EstimatedTooltip';
import { useStyles } from './CheckoutCard.styles';
import metadata from './metadata';

interface Props {
  checkoutData: CheckoutData;
  isMVRActive?: boolean;
  compactDivider?: boolean;
}

export const CheckoutCardBody: React.FC<Props> = memo((props) => {
  const { checkoutData, isMVRActive, compactDivider } = props;
  const { classes, cx } = useStyles();
  const isMobile = useIsMobile();
  const isBundle = useSelector(getIsBundleForOfferProductsSelected);

  const getPremium = (premium: CheckoutPremium, isBundle: boolean): React.ReactElement => {
    const productDisplayName = getProductDisplayName(premium.productName);
    const isMonthly = premium.paymentPlan !== 'Full';

    return (
      <Grid container justifyContent='center' alignItems='baseline'>
        {isBundle ? (
          <>
            <Grid item xs={3}>
              <p className={classes.sectionHeader}>{productDisplayName}</p>
            </Grid>
            <Grid item xs={3}>
              <p className={classes.subText}>{`${premium.policyDuration}mo`}</p>
            </Grid>
            <Grid item xs={6}>
              <p className={classes.sectionText}>{premium.premiumAmount}</p>
            </Grid>
          </>
        ) : (
          <>
            <Grid className={classes.product}>
              {!isMobile && (
                <p className={classes.protection}>Get your {premium.productName} protection for</p>
              )}
            </Grid>
            {metadata.shouldReflectPaymentTermSelection ? (
              <Grid container className={classes.details}>
                <p className={classes.singlePremiumAmount}>
                  {isMonthly ? premium.monthlyAmount : premium.premiumAmount}
                  &nbsp;
                  <span className={classes.subText}>{isMonthly ? '/mo' : '/yr'}</span>
                </p>
                {isMobile && (
                  <p className={classes.subText}>&nbsp;{productDisplayName} 1 year policy</p>
                )}
              </Grid>
            ) : (
              <Grid container className={classes.details}>
                <p className={classes.singlePremiumAmount}>{premium.premiumAmount}</p>
                &nbsp;
                <p className={classes.subText}>
                  {isMobile
                    ? `${productDisplayName} ${premium.policyDuration}mo policy`
                    : `/${premium.policyDuration}mo`}
                </p>
              </Grid>
            )}
          </>
        )}
        {premium.productName === 'auto' && !isMVRActive && <EstimatedTooltip />}
      </Grid>
    );
  };

  return (
    <Grid
      container
      className={cx(classes.section, isBundle && classes.bundledPremium)}
      justifyContent='center'
      direction='column'
    >
      {Object.values(checkoutData).map((premium, index) => (
        <Fragment key={premium.productName}>
          {index > 0 && (
            <Divider
              aria-hidden='true'
              className={
                compactDivider ? classes.compactHorizontalDivider : classes.horizontalDivider
              }
            />
          )}
          {getPremium(premium, isBundle)}
        </Fragment>
      ))}
    </Grid>
  );
});
