import { Typography } from '@mui/material';

import { GridItem, NumberFormat } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const RenovationInLast15YearsQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyRenovationField = useFieldWithPrefix(
    'property.renovation',
    'property.renovation',
  );
  const renovationInLast15Years = useHomePropertyRenovationField('renovationLast15Years');
  const renovationYear = useHomePropertyRenovationField('renovationYear');
  const renovationDocumentProvided = useHomePropertyRenovationField(
    'renovationDocumentProvidedInd',
  );
  const {
    label = renovationInLast15Years.question?.title,
    trackingName = 'renovation_in_last_15years_selection',
    groupLabel = renovationYear.question?.title,
    trackingLabel = 'renovation_year_question',
  } = props;

  const documentProvidedLabel = renovationDocumentProvided.question?.title;
  const documentProvidedTrackingName = 'renovation_document_provided_selection';

  useAddFields({ renovationInLast15Years, renovationYear, renovationDocumentProvided });

  const displayRenovationSubQuestion =
    renovationInLast15Years.exists &&
    renovationInLast15Years.props.value &&
    renovationYear.exists &&
    renovationDocumentProvided.exists;

  if (!renovationInLast15Years.exists) return null;

  return (
    <>
      <RadioGroupWithOptions
        {...renovationInLast15Years.props}
        label={label}
        variant='yesNoButton'
        trackingName={trackingName}
      />
      {displayRenovationSubQuestion && (
        <GridItem topSpacing='lg' xs={12}>
          <NumberFormat
            {...renovationYear.props}
            groupLabel={<Typography variant='body4'>{groupLabel}</Typography>}
            format='####'
            placeholder='YYYY'
            id='RenovationInLast15Years'
            trackingName={trackingLabel}
          />
        </GridItem>
      )}
      {displayRenovationSubQuestion && (
        <GridItem topSpacing='lg' xs={12}>
          <RadioGroupWithOptions
            {...renovationDocumentProvided.props}
            label={documentProvidedLabel}
            variant='yesNoButton'
            trackingName={documentProvidedTrackingName}
          />
        </GridItem>
      )}
    </>
  );
};
