import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'Tabs' })((theme) => ({
  root: {
    width: '100%',
  },
  tabTitles: {
    textTransform: 'none',
  },
  appBarTabsHeader: {
    boxShadow: 'none',
  },
  scrollButtons: {},
}));
