import {
  IconCardAwayAtSchool,
  IconCardDefensiveDriver,
  IconCardEducationLevel,
  IconCardGoodStudent,
} from '@ecp/themes/base';
import type { CardOption } from '@ecp/types';

export type DriverDiscountCardOption = CardOption<string, string> & {
  // TODO: Remove these types once SAPI is able to send the discounts questions based on display rules
  ageLimit?: number;
  additionalAgeLimit?: number;
  ageGreater?: number;
};

export const collegeDegreeOptions: CardOption[] = [
  {
    label: '2-year degree',
    value: 'twoYearDegree',
  },
  {
    label: '4-year degree',
    value: 'fourYearDegree',
  },
  {
    label: 'Graduate degree',
    value: 'graduateDegree',
  },
];

export const defensiveDriverOptions: CardOption[] = [
  {
    value: 'DEFENSIVE_DRIVER_COURSE_TYPE.BASIC',
    label: 'Basic',
  },
  {
    value: 'DEFENSIVE_DRIVER_COURSE_TYPE.ADVANCED',
    label: 'Advanced',
  },
];

// TODO we need to put discount default value to metadata
export const discountsDefaultValue = {
  defensiveDriverCourse: 'DEFENSIVE_DRIVER_COURSE_TYPE.BASIC',
};

// !TODO Replace with a static value ensuring it gets imported after feature flags got initialized
export const driverDiscountsOptions: () => Array<DriverDiscountCardOption> = () => {
  const options: Array<DriverDiscountCardOption> = [
    {
      value: 'goodStudent',
      label: 'Good student',
      icon: <IconCardGoodStudent />,
      helpText:
        'Full time college or high school student under age of 25. May need to provide a recent grade report maintaining a "B" average.',
    },
    {
      value: 'awayAtSchool',
      label: 'Away at school',
      icon: <IconCardAwayAtSchool />,
      helpText: 'A driver who attends school more than 100 miles from home without a vehicle.',
    },
    {
      value: 'defensiveDriver',
      label: 'Defensive driver',
      icon: <IconCardDefensiveDriver />,
      helpText:
        'Driver must have completed a defensive driver course in the last 3 years. State criteria may apply.',
      stateOptions: {
        KY: {
          helpText:
            'To qualify for this discount the driver must be 55 years of age or older and completed the driving course within the last 5 years. Military personnel, regardless of age, may be eligible if they have completed a U.S. Armed Forces Defensive Driver course, please contact us for more information.',
        },
        DE: {
          value: 'defensiveDriverCourse',
        },
        DC: {
          helpText:
            'Applies to licensed drivers who have taken a defensive driver course in the last 2 years. State mandated criteria may apply.',
        },
        IL: {
          helpText:
            'To qualify for this discount the driver must be 55 years of age or older and hasn`t had any at-fault accidents or minor violations within the last year.',
        },
        WA: {
          helpText:
            'To qualify for this discount the driver must be 55 years of age or older and has completed a defensive driving course within the last 2 years.',
        },
        OK: {
          helpText:
            'To qualify for this discount the driver must have taken the Motor Vehicle Accident Prevention course within the last 3 years without being required to do so by the court resulting from a motor vehicle violation or alcohol/drug offense.',
        },
      },
    },
    {
      value: 'collegeDegree',
      label: 'College degree',
      icon: <IconCardEducationLevel />,
      helpText:
        "You may qualify for a discount if you have a bachelor's degree or higher from a college or university.",
      stateOptions: {
        AR: {
          label: 'College Graduate',
        },
      },
    },
  ];

  return options;
};
