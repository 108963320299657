/**
 * This regex shouldn't have `g` flag on it as we're going to be using test method on it:
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/test#using_test_on_a_regex_with_the_global_flag
 */
export const VIN_REGEX_FULL = /[A-HJ-NPR-Z0-9]{17}/;
export const VIN_REGEX_NEGATED_SYMBOL = /[^A-HJ-NPR-Z0-9]/g;

export const enum DriverType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  OCCASIONAL = 'occasional',
}
