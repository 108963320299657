import {
  LINE_OF_BUSINESS_AUTO,
  LINE_OF_BUSINESS_HOME,
  LINE_OF_BUSINESS_RENTERS,
} from '@ecp/features/sales/shared/constants';
import { LineOfBusiness } from '@ecp/features/shared/product';
import { IconCardAuto, IconCardHome, IconCardRent } from '@ecp/themes/base';

import type { Metadata } from './types';
import { convertArrayOfUnbundledLobTuplesToArrayOfProductNamesWithStaticPrefixTuples } from './util';

const options: Metadata['options'] = [
  {
    key: LineOfBusiness.AUTO,
    value: LINE_OF_BUSINESS_AUTO,
    label: 'Auto',
    icon: <IconCardAuto />,
  },
  {
    key: LineOfBusiness.HOME,
    value: LINE_OF_BUSINESS_HOME,
    label: 'Home',
    icon: <IconCardHome />,
  },
  {
    key: LineOfBusiness.RENTERS,
    value: LINE_OF_BUSINESS_RENTERS,
    label: 'Renters',
    icon: <IconCardRent />,
  },
];

const mutuallyExclusiveOptions: Metadata['mutuallyExclusiveOptions'] = [
  [LineOfBusiness.HOME, LineOfBusiness.RENTERS],
];

const mutuallyDisabledOptions: Metadata['mutuallyDisabledOptions'] = [];

const mutuallyExclusiveOptionsWithStaticPrefix: Metadata['mutuallyExclusiveOptionsWithStaticPrefix'] =
  convertArrayOfUnbundledLobTuplesToArrayOfProductNamesWithStaticPrefixTuples(
    mutuallyExclusiveOptions,
  );

const mutuallyDisabledOptionsWithStaticPrefix: Metadata['mutuallyDisabledOptionsWithStaticPrefix'] =
  convertArrayOfUnbundledLobTuplesToArrayOfProductNamesWithStaticPrefixTuples(
    mutuallyDisabledOptions,
  );

const metadata: Metadata = {
  options,
  mutuallyExclusiveOptions,
  mutuallyDisabledOptions,
  mutuallyExclusiveOptionsWithStaticPrefix,
  mutuallyDisabledOptionsWithStaticPrefix,
};

export default metadata;
