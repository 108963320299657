import { createReducer } from '@reduxjs/toolkit';

import { setApiComplete, setApiStart, setSapiTarget } from './actions';
import type { ApiState } from './types';

const apiInitial: ApiState = { inProgressCalls: [], sapiTarget: 'v3' };

export const apiReducer = createReducer(apiInitial, (builder) => {
  builder
    .addCase(setApiStart, (state, { payload: id }) => {
      state.inProgressCalls.push(id);
    })
    .addCase(setApiComplete, (state, { payload: id }) => {
      state.inProgressCalls = state.inProgressCalls.filter((p) => id !== p);
    })
    .addCase(setSapiTarget, (state, { payload: sapiTarget }) => {
      state.sapiTarget = sapiTarget;
    })
    .addMatcher(
      (action): action is { type: '@global/teardownStore' } =>
        action.type === '@global/teardownStore',
      () => apiInitial,
    );
});
