import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { PagePath } from '@ecp/features/sales/shared/routing';

import { PersonForm } from './PersonForm';

export const PersonPage: React.FC = () => {
  return (
    <Page
      title="Let's get to know you better"
      analyticsElement='choice.personPage.page'
      sidebarProps={{
        drawer: <NavbarDrawer pagePath={PagePath.PERSON} />,
      }}
    >
      <PersonForm />
    </Page>
  );
};
