import { Grid } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';
import { formatDate } from '@ecp/utils/date';

import { StateFeesSection } from '@ecp/features/sales/shared/components';
import type { PaymentPlan } from '@ecp/features/sales/shared/constants';
import {
  getOfferDetailsForProduct,
  getUpdateOffersInProgress,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';
import { getProductDisplayNameFromProduct } from '@ecp/features/shared/product';
import { useIsMobile, useIsTablet } from '@ecp/themes/base';

import type { PaymentOption } from '../../types';
import { getCombinedTotal, getMonthlyPaymentsText } from '../../util';
import { determineInstallmentFeeTotal } from '../../util/installmentFeeUtil';
import { EstimatedTooltip } from '../EstimatedTooltip';
import { Recalc } from '../Recalc';
import { useStyles } from './MonthlyPaymentsCheckoutCard.styles';

interface Props {
  offerProduct: Product;
  recalculate?: boolean;
  showEstimated?: boolean;
  showProcess: boolean;
  psd: string;
  premiumPlan: PaymentPlan;
  showStateFees?: boolean;
  paymentOption: PaymentOption;
}

export const MonthlyPaymentsMonolineCheckoutView: React.FC<Props> = (props) => {
  const {
    offerProduct,
    recalculate,
    showEstimated,
    showProcess,
    psd,
    premiumPlan,
    showStateFees,
    paymentOption,
  } = props;
  const { classes, cx } = useStyles();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const updatesInProgress = useSelector((state: RootStore) => getUpdateOffersInProgress(state));
  const recalculatingOrUpdating = recalculate || updatesInProgress;
  const showLoading = updatesInProgress || showProcess;

  const offerDetails = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, offerProduct),
  );
  const productName = getProductDisplayNameFromProduct(offerProduct);

  if (!offerDetails) return null;

  const renderMonthlyView = (
    <>
      <Grid item xs={8} display='flex' alignItems='center'>
        <p className={classes.policyCheckoutHeaders}>
          {getMonthlyPaymentsText(
            paymentOption.paymentPlan,
            Number(paymentOption.downPayment),
            offerProduct,
          )}
        </p>
      </Grid>
      <Grid item xs={4} className={cx(classes.premiumFeesMonolineColumn, classes.monthlyPayments)}>
        <Recalc
          amount={parseDollar(paymentOption.monthlyAmount)}
          recalculate={recalculatingOrUpdating}
          showProcess={showProcess}
        />
      </Grid>
      {Number(paymentOption.downPayment) > 0 && (
        <>
          <Grid item xs={6}>
            <p className={classes.policyCheckoutHeaders}>Due today</p>
          </Grid>
          <Grid item xs={6} className={cx(classes.premiumFeesColumn, classes.premiumFeesInfo)}>
            <Recalc
              amount={parseDollar(paymentOption.downPayment)}
              recalculate={recalculatingOrUpdating}
              showProcess={showProcess}
            />
          </Grid>
        </>
      )}
      <Grid item xs={6}>
        <p className={classes.policyCheckoutHeaders}>Installment Fees</p>
      </Grid>
      <Grid item xs={6} className={cx(classes.premiumFeesColumn, classes.premiumFeesInfo)}>
        <Recalc
          amount={parseDollar(determineInstallmentFeeTotal(offerProduct, paymentOption))}
          recalculate={recalculatingOrUpdating}
          showProcess={showProcess}
        />
      </Grid>
      {isMobile ? null : (
        <>
          <Grid item xs={6}>
            <p className={classes.policyCheckoutHeaders}>Policy total</p>
          </Grid>
          <Grid item xs={6} className={cx(classes.premiumFeesColumn, classes.premiumFeesInfo)}>
            <Recalc
              amount={parseDollar(getCombinedTotal(offerProduct, paymentOption))}
              recalculate={recalculatingOrUpdating}
              showProcess={showProcess}
            />
          </Grid>
          <Grid item xs={6}>
            <p className={classes.policyCheckoutHeaders}>Start date</p>
          </Grid>
          <Grid item xs={6} className={cx(classes.premiumFeesColumn, classes.premiumFeesInfo)}>
            <span className={cx(classes.customizeButton, classes.premiumFeesInfo)}>
              {formatDate(psd)}
            </span>
          </Grid>
          {isTablet ? null : (
            <StateFeesSection
              premiumPlan={premiumPlan}
              offerDetails={offerDetails}
              showStateFees={showStateFees}
            />
          )}
        </>
      )}
    </>
  );

  const renderFullPaymentView = (
    <>
      <Grid item xs={8} display='flex' alignItems='center'>
        <p className={classes.policyCheckoutHeaders}>One-time payment of</p>
      </Grid>
      <Grid item xs={4} className={cx(classes.premiumFeesMonolineColumn, classes.monthlyPayments)}>
        <Recalc
          amount={parseDollar(paymentOption.totalPremium)}
          recalculate={recalculate}
          showProcess={showLoading}
        />
      </Grid>
      {isMobile ? null : (
        <>
          <Grid item xs={6}>
            <p className={classes.policyCheckoutHeaders}>Start date</p>
          </Grid>
          <Grid item xs={6} className={cx(classes.premiumFeesColumn, classes.premiumFeesInfo)}>
            <span className={cx(classes.customizeButton, classes.premiumFeesInfo)}>
              {formatDate(psd)}
            </span>
          </Grid>
          {isTablet ? null : (
            <StateFeesSection
              premiumPlan={premiumPlan}
              offerDetails={offerDetails}
              showStateFees={showStateFees}
            />
          )}
        </>
      )}
    </>
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <p className={classes.policyDurationHeaderColumn}>
          <span className={classes.policyDurationHeader}>{productName}</span>{' '}
          {`${offerDetails.policyDuration}-mo policy`}
          {showEstimated && <EstimatedTooltip inline />}
        </p>
      </Grid>
      {paymentOption.paymentPlan === 'Full' ? renderFullPaymentView : renderMonthlyView}
    </Grid>
  );
};
