import type { RadioGroupWithOptionsProps as RadioGroupWithOptionsPropsBase } from '@ecp/components';
import { RadioGroupWithOptions as RadioGroupWithOptionsBase } from '@ecp/components';
import { useFieldRef } from '@ecp/features/sales/form';

export const RadioGroupWithOptions: React.FC<RadioGroupWithOptionsPropsBase> = (props) => {
  const { name, ...radioGroupProps } = props;

  const fieldRef = useFieldRef<HTMLButtonElement>(name);

  return <RadioGroupWithOptionsBase {...radioGroupProps} name={name} ref={fieldRef} />;
};
