import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutoComplete' })((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    ...theme.mixins.shadow2,
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 11,
    marginTop: -20,
    left: 0,
    right: 0,
    maxHeight: 320,
    overflowY: 'auto',
    [theme.breakpoints.up('md')]: {
      width: 'fit-content',
    },
    borderRadius: 4,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: '10 px 0 0 0',
    padding: 0,
    listStyleType: 'none',
  },
  useAddressAsTypedContainer: {
    padding: '10px 30px 0 11px',
    borderTop: `1px solid ${theme.palette.other.divider}`,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '& *': {
        color: theme.palette.primary.contrastText,
      },
    },
  },

  useAddressAsTyped: {
    ...theme.typography.body1Italics,
    color: theme.palette.text.tertiary,
    marginLeft: 14,
  },
  aptAddressContainer: {
    ...theme.typography.body1,
    marginLeft: 14,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '& *': {
        color: theme.palette.primary.contrastText,
      },
    },
  },
  defaultSuggestion: {
    padding: '0 0 10px 14px',
    marginBottom: 10,
  },
  divider: {
    height: theme.spacing(2),
  },
  listItem: {
    padding: '8px 25px',
    '&.Mui-selected, &.Mui-focusVisible': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
    },
    [theme.breakpoints.down('md')]: {
      whiteSpace: 'normal',
    },
  },
  addressSuggestion: {
    marginRight: 10,
  },
  arrowKey: {
    fontSize: 12,
    textAlign: 'right',
    justifySelf: 'flex-end',
  },
}));
