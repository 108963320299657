import { GridItem } from '@ecp/components';
import { PartnerAutoPolicyQuestion } from '@ecp/features/sales/quotes/property/renters';

export const DiscountsFormBodyRenters: React.FC = () => {
  return (
    <GridItem xs={12}>
      <PartnerAutoPolicyQuestion />
    </GridItem>
  );
};
