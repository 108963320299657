import { sessionStorage } from '@ecp/utils/storage';

import type { NavState } from '@ecp/features/sales/shared/store/types';
import type { ExperienceId } from '@ecp/partners';

/**
 * @deprecated This will go away and
 * will be replaced with a global application state management utility
 * splitting something like expId, dalSessionId, inquiryId, offers across smaller utilities
 * all backed by sessionStorage.
 */
export type Session = {
  expId?: ExperienceId;
  homeInspectionStatus?: string | null;
  dalSessionId: string | null;
  inquiryId: string | null;
  nav?: NavState | null;
  offerSetId: string | null;
};

const sessionKeys = [
  'homeInspectionStatus',
  'dalSessionId',
  'inquiryId',
  'nav',
  'offerSetId',
] as const;

/**
 * @deprecated This will go away and
 * will be replaced with a global application state management utility
 * splitting something like expId, dalSessionId, inquiryId, offers across smaller utilities
 * all backed by sessionStorage.
 */
export const getSession = ({ expId }: { expId: ExperienceId }): Session =>
  sessionKeys.reduce(
    (acc, key) => {
      const value = sessionStorage.getItem(key) as Session[typeof key];
      // @ts-ignore Not sure why TS complains here
      acc[key] = value;

      return acc;
    },
    { expId } as Session,
  );

/**
 * @deprecated This will go away and
 * will be replaced with a global application state management utility
 * splitting something like expId, dalSessionId, inquiryId, offers across smaller utilities
 * all backed by sessionStorage.
 */
export const setSession = (session: Session): void => {
  Object.keys(session).forEach((key) => {
    session[key] && key !== 'expId'
      ? sessionStorage.setItem(key, session[key])
      : sessionStorage.removeItem(key);
  });
};
