import { memo, useCallback } from 'react';

import { Divider, Grid } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { emptyArray } from '@ecp/utils/common';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { GridItem, HelpBox, Link } from '@ecp/components';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import {
  getAreSomeSelectedProductsIndicative,
  getIsBundleForOfferProductsSelected,
  getOfferProductsSelectedByType,
  getOffers,
  getPrimaryInsuredStateCode,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { GraphicUIAgentAvatarImageUrl, IconCardAuto, useIsMobile } from '@ecp/themes/base';

import { useShowCostcoCoveragesPageRedesignPolicyStartCalendarOnAutoAndPropertyForms } from '../../../../util/coveragesPageRedesignABTestsLayout';
import { useIsCustomizeCoveragesEnabled } from '../../util';
import { CoveragesAccordion } from '../CoveragesAccordion';
import { useStyles } from '../CoveragesForm.styles';
import type { CoveragesSummaryProps } from '../CoveragesSummary';
import { CoveragesSummary } from '../CoveragesSummary';
import coveragesMetadata from '../metadata';
import { PolicyStartDateCalendar } from '../PolicyStartDateCalendarForm';
import metadata from './metadata';
import type { AutoCoveragesFormProps } from './types';
import {
  getApplicableDriverDiscounts,
  getApplicableVehicleDiscounts,
  useAutoCoveragesForm,
} from './util';
import { VehicleAndDriverDiscounts } from './VehicleAndDriverDiscounts';
import { VehicleCoveragesForm } from './VehicleCoveragesForm';
import * as vehicleCoveragesMetadata from './VehicleCoveragesForm/metadata';

type DisclaimerProps = Pick<AutoCoveragesFormProps, 'messages' | 'setCarrierMessagingOpen'> & {
  stateCode: string;
};

const AutoRatingCriteriaDisclaimer: React.FC<DisclaimerProps> = memo(
  ({ setCarrierMessagingOpen, stateCode, messages = emptyArray }) => {
    const { classes } = useStyles();

    const navigateToRatingCriteriaPage = useNavigateToPage(PagePath.RATING_CRITERIA);

    const navigateToRatingCriteria = useCallback(async () => {
      await navigateToRatingCriteriaPage();
      trackClick({ action: 'rating_criteria_link', label: 'rating_criteria' });
    }, [navigateToRatingCriteriaPage]);

    const messageNames = messages.map((element) => element.name || '');

    const openCarrierMessaging = useCallback(
      () => setCarrierMessagingOpen(true),
      [setCarrierMessagingOpen],
    );

    return (
      <div>
        <p>
          We use information from a combination of data sources to calculate your premium.{' '}
          <Link component='button' onClick={navigateToRatingCriteria}>
            Here's some of the information we used.
          </Link>
        </p>
        {/* If we don't have any carrier messages we don't want to display anything including the divider because it looks weird */}
        {messageNames.length > 0 && (
          <>
            <Divider className={classes.disclaimerDivider} />
            <p>
              Please review the following important information for {stateCode}.&nbsp;
              <Link component='button' onClick={openCarrierMessaging} display='inline'>
                {messageNames.join(', ')}
              </Link>
            </p>
          </>
        )}
      </div>
    );
  },
);

export const AutoCoveragesForm: React.FC<AutoCoveragesFormProps> = memo((props) => {
  const { autoDiscountItems } = props;
  const { classes, cx } = useStyles();
  const { auto: autoOfferProduct } = useSelector(getOfferProductsSelectedByType);

  const offers = useSelector(getOffers);

  const { autoPolicyCoverages, autoVehicleCoverages } = useAutoCoveragesForm(props);

  const isMobile = useIsMobile();
  const isBundle = useSelector(getIsBundleForOfferProductsSelected);
  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const areSomeSelectedProductsIndicative = useSelector(getAreSomeSelectedProductsIndicative);

  const applicableDriverDiscounts = useSelector(getApplicableDriverDiscounts);
  const applicableVehicleDiscounts = useSelector(getApplicableVehicleDiscounts);

  const isCustomizeCoveragesEnabled = useIsCustomizeCoveragesEnabled?.();

  const showPolicyStartCalendar =
    useShowCostcoCoveragesPageRedesignPolicyStartCalendarOnAutoAndPropertyForms();
  const coveragePageRedesignVariant = flagValues[FeatureFlags.COVERAGE_PAGE_REDESIGN_VARIANTS];

  if (!(autoOfferProduct && offers && autoPolicyCoverages.length && autoVehicleCoverages.length))
    return null;

  const renderCoveragesSummary = (): React.ReactElement => {
    const coverageGroups = autoVehicleCoverages.reduce(
      (acc, vehicle) => {
        const { coverages, deductibles, description } = vehicle;
        acc.push({
          title: `${vehicleCoveragesMetadata.vehicleCoveragesFormMetadata.title} ${description}`,
          items: [...deductibles, ...coverages],
        });

        return acc;
      },
      [
        {
          title: metadata.title,
          items: autoPolicyCoverages,
        },
      ] as NonNullable<CoveragesSummaryProps['coverageGroups']>,
    );

    return (
      <>
        <CoveragesSummary
          path={PagePath.EDIT_AUTO_PROFILE}
          summaryType='vehicleDriver'
          title='Vehicles and drivers'
          trackingName='EditVehiclesAndDrivers'
        />
        <CoveragesSummary
          discountItems={autoDiscountItems?.[autoOfferProduct]}
          path={PagePath.AUTO_DISCOUNTS}
          summaryType='discounts'
          title='Auto discounts applied'
          trackingName='EditAutoDiscounts'
          product='auto'
        />
        <CoveragesSummary
          coverageGroups={coverageGroups}
          path={PagePath.QUOTES}
          summaryType='coverages'
          title='Auto coverages'
          trackingName='EditAutoCoverages'
        />
      </>
    );
  };

  const renderCoverages = (): React.ReactElement => {
    const footer =
      applicableDriverDiscounts.length || applicableVehicleDiscounts.length ? (
        <VehicleAndDriverDiscounts
          autoProductKey={autoOfferProduct}
          isMobile={isMobile}
          applicableDriverDiscounts={applicableDriverDiscounts}
          applicableVehicleDiscounts={applicableVehicleDiscounts}
        />
      ) : undefined;

    const showPolicyCoverageBanner = Boolean(
      offers[autoOfferProduct]?.messages?.filter(({ id }) => id === 'CEDED_POLICY').length,
    );

    const discountText = coveragePageRedesignVariant
      ? 'Policy discounts applied'
      : 'Discounts applied';

    return (
      <>
        {isBundle && (
          <Grid
            container
            className={cx(
              classes.addSpacing,
              !showPolicyStartCalendar && classes.productHeading,
              showPolicyStartCalendar && classes.productHeadingTest,
            )}
          >
            {!coveragePageRedesignVariant && <IconCardAuto className={classes.productIcon} />}
            <h2>Auto policy summary</h2>
          </Grid>
        )}
        {showPolicyStartCalendar && (
          <div className={classes.costcoTestAutoAndPropertyCoveragesFormPolicyStartDateCalendarRow}>
            <div
              className={
                classes.costcoTestAutoAndPropertyCoveragesFormPolicyStartDateCalendarContainer
              }
            >
              <PolicyStartDateCalendar product={autoOfferProduct} />
            </div>
          </div>
        )}
        <CoveragesAccordion
          title={discountText}
          discountItems={autoDiscountItems?.[autoOfferProduct]}
          footer={footer}
          trackingName='auto_discounts_button'
          product='auto'
        />
        <CoveragesAccordion
          title={metadata.title}
          coverageItems={autoPolicyCoverages}
          showPolicyCoverageBanner={showPolicyCoverageBanner}
          trackingName='policy_level_coverages'
        />
        <VehicleCoveragesForm vehicles={autoVehicleCoverages} />
      </>
    );
  };

  return (
    <>
      {isCustomizeCoveragesEnabled ? renderCoveragesSummary() : renderCoverages()}
      {!coveragesMetadata.hideDisclaimers && !areSomeSelectedProductsIndicative && (
        <GridItem topSpacing='sm' xs={12} className={classes.helpboxSection}>
          <HelpBox
            image={GraphicUIAgentAvatarImageUrl}
            data-testid='helpBoxIcon'
            content={<AutoRatingCriteriaDisclaimer {...props} stateCode={stateCode} />}
          />
        </GridItem>
      )}
    </>
  );
});
