import { forwardRef, memo } from 'react';

import type { ToggleButtonGroupProps } from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import { useStyles } from './Toggle.styles';

export interface ToggleProps extends Pick<ToggleButtonGroupProps, 'onChange' | 'size' | 'value'> {
  options: string[];
  fullWidth?: boolean;
}

export const Toggle: React.FC<ToggleProps> = memo(
  forwardRef((props, ref) => {
    const { onChange, options, value: toggleValue, size, fullWidth = false } = props;
    const { classes, cx } = useStyles();

    return (
      <ToggleButtonGroup
        size={size}
        className={classes.root}
        exclusive
        onChange={onChange}
        value={toggleValue}
        ref={ref}
      >
        {options.map((item) => {
          return (
            <ToggleButton
              key={item}
              className={cx(classes.toggleButton, fullWidth && classes.fullWidthButton)}
              value={item}
            >
              {item}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    );
  }),
);
