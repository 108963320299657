import { useEvent } from '@ecp/utils/react';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { PagePath, useNavigateToNextPage } from '@ecp/features/sales/shared/routing';

import { PropertyDetailsForm } from '../../forms';

export const PropertyDetailsPage: React.FC = () => {
  const navigateToNextPage = useNavigateToNextPage();

  const handleNext = useEvent(async () => {
    await navigateToNextPage();

    return;
  });

  // TODO: Add a analyticsElement title for this page.
  return (
    <Page
      title='Rental property details'
      subTitle=''
      analyticsElement=''
      sidebarProps={{
        drawer: <NavbarDrawer pagePath={PagePath.RENTERS_PROPERTY_DETAILS} />,
      }}
    >
      <PropertyDetailsForm onNext={handleNext} />
    </Page>
  );
};
