import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CheckoutPage' })((theme) => ({
  root: {
    '& #content': {
      maxWidth: 'initial',
    },
    '& [role=main]': {
      [theme.breakpoints.only('md')]: {
        maxWidth: 'initial',
      },
    },
  },
  checkoutTitleImages: {
    display: 'flex',
  },
  headerImage: {
    marginRight: 10,
    height: 45,
  },
  carrierLogo: {
    width: 150,
    height: 45,
    paddingRight: 15,
  },
  loading: {
    display: 'flex',
    height: '50vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
