import { Grid } from '@mui/material';

import { env } from '@ecp/env';
import { Button, SaveAndExit } from '@ecp/features/sales/shared/components';
import { useIsMobile } from '@ecp/themes/base';

import type { CheckoutCardProps } from '../CheckoutCard';
import { CheckoutCardBody } from '../CheckoutCard';
import { useStyles } from './MobilePurchaseCard.styles';

export const MobilePurchaseCard: React.FC<CheckoutCardProps> = (props) => {
  const {
    checkoutData,
    onCheckout,
    buttonText,
    buttonDisabled,
    isProcessing,
    buttonTrackingName,
    buttonTrackingLabel,
    checkoutElement,
    isMVRActive,
  } = props;
  const { classes } = useStyles();
  const isMobile = useIsMobile();

  return (
    <Grid container className={classes.bottomCheckoutBarContainer}>
      <Grid item xs={isMobile ? 6 : 4}>
        <CheckoutCardBody checkoutData={checkoutData} isMVRActive={isMVRActive} compactDivider />
      </Grid>
      <Grid item xs={6} className={classes.bottomCheckoutBarButton}>
        <div className={classes.carrierButtons}>
          <Button
            variant='success'
            data-testid='mobilePurchase'
            onClick={onCheckout}
            isProcessing={isProcessing}
            disabled={buttonDisabled}
            trackingName={buttonTrackingName}
            trackingLabel={buttonTrackingLabel}
            analyticsElement={checkoutElement}
          >
            {buttonText}
          </Button>
          {!env.static.isAgent && <SaveAndExit />}
        </div>
      </Grid>
    </Grid>
  );
};
