import { useAddFields } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const SolidFuelQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const solidFuel = useHomePropertyField('hasSolidFuelInd');
  const { label = solidFuel.question?.title, trackingName = 'SolidFuelButton' } = props;

  useAddFields({ solidFuel });

  if (!solidFuel.exists) return null;

  return (
    <RadioGroupWithOptions
      {...solidFuel.props}
      id='SolidFuel'
      label={label}
      variant='yesNoButton'
      trackingName={trackingName}
    />
  );
};
