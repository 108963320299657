import { useCallback, useState } from 'react';

import { FormHelperText, Grid } from '@mui/material';

import { Button, RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import { MAX_NUM_HEATING_SOURCES } from '@ecp/features/sales/shared/constants';
import {
  deleteAnswers,
  getAllValues,
  getHeatingSourceRefs,
  useField,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, HeatingSource } from '@ecp/features/sales/shared/types';
import {
  IconUICheckCircleSolid,
  IconUIDelete,
  IconUIEdit,
  IconUIExclaimTriangle,
  IconUIPlus,
} from '@ecp/themes/base';

import { HEATING_SOURCE_REF } from '../../../../constants';
import { heatingSourceMetaData } from '../../../../metadata';
import {
  useCreateHeatingSource,
  useHeatingSources,
  useRemoveHeatingSource,
} from '../../../../state';
import { HeatingSourceDialog } from './HeatingSourceDialog';
import { useStyles } from './HeatingSourceQuestion.styles';

interface Props {
  hasHeatingSourceError: boolean;
  setHasHeatingSourceError: (hasHeatSourceExist: boolean) => void;
}

export const HeatingSourceQuestion: React.FC<Props> = (props) => {
  const { hasHeatingSourceError, setHasHeatingSourceError } = props;
  const { cx, classes } = useStyles();
  const dispatch = useDispatch();

  const ADD_NEW_HEAT_SOURCE = 'ADDNEW';
  const hasHeatingSource = useField('static.hasHeatingSource');
  const heatingSourceRefs = useSelector(getHeatingSourceRefs);
  const addHeatingSource = useCreateHeatingSource();
  const removeHeatingSource = useRemoveHeatingSource();
  const numHeatingSources = !heatingSourceRefs ? 0 : heatingSourceRefs.length;
  const heatingSourcesValues = useHeatingSources(heatingSourceRefs);
  const allValues = useSelector(getAllValues);
  const [editingHeatingSourceRef, setEditingHeatingSourceRef] = useState('');
  const [heatingSourceDialogOpen, setHeatingSourceDialogOpen] = useState(false);
  const [expanded, setExpanded] = useState('');
  // Checks if heat source items exists in grid or fire place question is set as yes
  const hasHeatingSourceValue = !!numHeatingSources || !!hasHeatingSource.value;

  const handleAddHeatingSource = useCallback(() => {
    setExpanded(ADD_NEW_HEAT_SOURCE);
    const heatingSourceRef = addHeatingSource();
    setEditingHeatingSourceRef(heatingSourceRef);
  }, [addHeatingSource]);

  const handleHasHeatingSource = useCallback(
    async (value: AnswerValue) => {
      hasHeatingSource.validateUpdateAndPatch(value);
      if (!value) {
        setHasHeatingSourceError(false);
        setHeatingSourceDialogOpen(true);
        setExpanded('');
        const heatingSourceRef = addHeatingSource();
        setEditingHeatingSourceRef(heatingSourceRef);
        if (numHeatingSources > 0) {
          // TODO - This can be a thunk (removeAllHeatingSources)
          const keys = Object.keys(allValues).filter(
            (key) => key.startsWith('heatingSource.') || key === HEATING_SOURCE_REF,
          );
          await dispatch(deleteAnswers({ keys }));
        }
      } else {
        handleAddHeatingSource();
      }
    },
    [
      hasHeatingSource,
      setHasHeatingSourceError,
      addHeatingSource,
      numHeatingSources,
      allValues,
      dispatch,
      handleAddHeatingSource,
    ],
  );

  const checkHeatingSourceStatus = (heatingSource: HeatingSource): boolean => {
    const fuelTypeValue = heatingSource.fuelType;
    const materialValue = heatingSource.material;
    if (!fuelTypeValue && !materialValue) {
      return false;
    }

    return true;
  };

  const getValues = (key: string, type: string): React.ReactElement | string => {
    let dataObject;
    switch (type) {
      case 'heatingSource':
        dataObject = heatingSourceMetaData.heatingSource.find((item) => item.value === key);
        break;
      case 'fireplaceType':
        dataObject = heatingSourceMetaData.fireplaceType.find((item) => item.value === key);
        break;
      case 'fuelType':
        dataObject = heatingSourceMetaData.fuelType.find((item) => item.value === key);
        break;
      default:
        dataObject = undefined;
    }

    return dataObject?.label || '';
  };

  const handleRemoveHeatingSource = useCallback(
    (ref: string) => () => {
      removeHeatingSource(ref);
    },
    [removeHeatingSource],
  );

  const handleExpand = useCallback(
    (ref: string) => () => {
      setExpanded(ref);
      if (ref === ADD_NEW_HEAT_SOURCE) {
        const heatingSourceRef = addHeatingSource();
        setEditingHeatingSourceRef(heatingSourceRef);
      }
    },
    [setExpanded, setEditingHeatingSourceRef, addHeatingSource],
  );

  const heatingSourceDialogOnClose = useCallback(() => {
    setHeatingSourceDialogOpen(false);
    setEditingHeatingSourceRef('');
    setHasHeatingSourceError(numHeatingSources <= 0);
    setExpanded('');
  }, [numHeatingSources, setHasHeatingSourceError]);

  const heatingSourcesList = (
    <Grid item xs={12}>
      {Object.values(heatingSourcesValues).map((heatingSource) => (
        <div key={heatingSource.ref}>
          {expanded !== heatingSource.ref && (
            <Grid
              item
              container
              xs={12}
              className={cx(classes.heatSourceCard, classes.heatSourceItem)}
            >
              <Grid item className={cx(classes.heatSourceText, classes.heatSourceItemText)}>
                {getValues(heatingSource.type, 'heatingSource') + ', '}
                {heatingSource.material
                  ? getValues(heatingSource.material, 'fireplaceType')
                  : getValues(heatingSource.fuelType, 'fuelType')}
              </Grid>
              <Grid className={classes.checkGrid}>
                {checkHeatingSourceStatus(heatingSource) ? (
                  <IconUICheckCircleSolid className={classes.checkIcon} />
                ) : (
                  <IconUIExclaimTriangle className={cx(classes.checkIcon, classes.warningIcon)} />
                )}
              </Grid>
              <Grid item className={classes.editDeleteButton}>
                <Button
                  variant='iconTextMedium'
                  className={classes.heatSourceButton}
                  trackingName='edit_heat_source_button'
                  trackingLabel='edit_heat_source'
                  onClick={handleExpand(heatingSource.ref)}
                >
                  <IconUIEdit />
                  Edit
                </Button>
                <Button
                  variant='iconTextMedium'
                  className={classes.heatSourceButton}
                  trackingName='delete_heat_source_button'
                  trackingLabel='delete_heat_source'
                  onClick={handleRemoveHeatingSource(heatingSource.ref)}
                >
                  <IconUIDelete />
                  Delete
                </Button>
              </Grid>
            </Grid>
          )}
          {expanded === heatingSource.ref && (
            <HeatingSourceDialog
              open={heatingSourceDialogOpen}
              onClose={heatingSourceDialogOnClose}
              editingHeatingSourceRef={heatingSource.ref}
            />
          )}
          {!checkHeatingSourceStatus(heatingSource) && (
            <Grid>
              <FormHelperText error focused={false} data-offset='220'>
                Required field missing. Update or delete this heat source to continue.
              </FormHelperText>
            </Grid>
          )}
        </div>
      ))}
      <div>
        {expanded !== ADD_NEW_HEAT_SOURCE && numHeatingSources < MAX_NUM_HEATING_SOURCES && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              className={cx(classes.heatSourceCard, classes.addHeatSourceItem)}
              fullWidth
              trackingName='add_new_heat_source_button'
              trackingLabel='add_new_heat_source'
              onClick={handleExpand(ADD_NEW_HEAT_SOURCE)}
            >
              <div className={cx(classes.heatSourceText, classes.addHeatSourceItemText)}>
                Add another heat source
              </div>
              <IconUIPlus className={classes.plusIcon} />
            </Button>
          </div>
        )}
        {expanded === ADD_NEW_HEAT_SOURCE && (
          <HeatingSourceDialog
            open={heatingSourceDialogOpen}
            onClose={heatingSourceDialogOnClose}
            editingHeatingSourceRef={editingHeatingSourceRef}
          />
        )}
      </div>
    </Grid>
  );

  if (!hasHeatingSource.exists) return null;

  return (
    <>
      <Grid item xs={12}>
        <RadioGroupWithOptions
          {...hasHeatingSource.props}
          value={hasHeatingSourceValue}
          actionOnComplete={handleHasHeatingSource}
          label='Does this home have any of these heat sources: fireplaces, fireplace inserts or freestanding stoves?'
          id='HasHeatingSource'
          variant='yesNoButton'
          data-testid='hasHeatingSource'
          trackingName='heat_source_question'
        />
      </Grid>
      {hasHeatingSourceValue && (
        <>
          {hasHeatingSourceError && numHeatingSources <= 0 && (
            <Grid item xs={12}>
              <FormHelperText error focused={false} data-offset='220'>
                Required field
              </FormHelperText>
            </Grid>
          )}
          <Grid item xs={12}>
            {heatingSourcesList}
          </Grid>
        </>
      )}
    </>
  );
};
