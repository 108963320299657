import type { LineOfBusiness } from '@ecp/features/shared/product';

export type PolicyEffectiveDateAdjustor = (effectivePolicyStartDate: string) => string;

export interface Metadata {
  skipFetchPostBindQuestions?: boolean;
  redirectPath?: string;
  defaultLineOfBusiness?: LineOfBusiness;
  policyEffectiveStartDateAdjustors?: {
    renters?: PolicyEffectiveDateAdjustor;
  };
  shouldGetPolicyEffectiveDateQueryParam?: boolean;
}

const metadata: Metadata = {};

export default metadata;
