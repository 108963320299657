import { useCallback } from 'react';

import { Grid } from '@mui/material';

import { noop } from '@ecp/utils/common';

import { Button, GridItem } from '@ecp/components';
import type { ButtonProps } from '@ecp/components';
import { IconMaterialDesignActionOpenInNew } from '@ecp/themes/base';

export const VoiceSignature: React.FC = () => {
  const handleVoiceScript = useCallback<NonNullable<ButtonProps['onClick']>>(() => {
    return noop();
    /* TODO - EDSP-10888 Put voice script from CMS as link destination to open in new page, 
     Upon receipt, allow user to click checkbox and pass voice as signature method** */
  }, []);

  return (
    <Grid container item xs={12} spacing={1}>
      <GridItem topSpacing='md' xl={6} xs={12}>
        <Button
          onClick={handleVoiceScript}
          variant='outlinePrimary'
          color='primary'
          icon={<IconMaterialDesignActionOpenInNew />}
          iconLast
        >
          VIEW VOICE SIGNATURE SCRIPT
        </Button>
      </GridItem>
    </Grid>
  );
};
