import type { PageProps } from '@ecp/features/sales/shared/components';

import { CoveragesPageUpperSection } from '../CoveragesPageUpperSection';
import type { CoveragesSharedProps } from '../types';

export type Metadata = Pick<CoveragesSharedProps, 'checkoutButtonText'> &
  Pick<PageProps, 'title' | 'variant'>;

const metadata: Metadata = {
  checkoutButtonText: 'View payment options',
  title: <CoveragesPageUpperSection />,
  variant: 'bannerHeading',
};

export default metadata;
