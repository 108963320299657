import * as interactionId from '@ecp/utils/analytics/interaction-id';
import { agentAuth } from '@ecp/utils/auth';
import { getToken } from '@ecp/utils/botcontrol';
import { uuid } from '@ecp/utils/common';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { sessionStorage } from '@ecp/utils/storage';

import { env } from '@ecp/env';
import { PARTNER_EXPERIENCE_ID } from '@ecp/features/sales/shared/constants';
import type { ExperienceId } from '@ecp/partners';

import { getSapiAuthToken } from './sapiAuthToken';

async function extendRequestInit({
  init,
  requestId,
  withAgentToken = true,
  allowAfeHeaders,
}: {
  init: RequestInit;
  requestId?: string;
  withAgentToken?: boolean;
  allowAfeHeaders?: boolean;
}): Promise<RequestInit | Record<string, unknown>> {
  const expId = (sessionStorage.getItem(PARTNER_EXPERIENCE_ID) || env.static.expId) as ExperienceId;
  const shouldIncludeAuthToken = !flagValues[FeatureFlags.DISABLE_AUTH0];

  // In agent experiences requests to our internal services should include agent auth header
  const shouldIncludeAgentToken = env.static.isAgent && withAgentToken;
  const [sapiToken, agentToken] = await Promise.all([
    getSapiAuthToken(),
    shouldIncludeAgentToken ? agentAuth.token : undefined,
  ]);

  const wafToken = await getToken();

  const authorization = shouldIncludeAuthToken ? `Bearer ${sapiToken}` : 'NOAUTH';

  return {
    ...init,
    headers: {
      ...init.headers,
      [allowAfeHeaders ? 'afe-trace-id' : 'X-Request-Id']: requestId || uuid(),
      [allowAfeHeaders ? 'afe-session-id' : 'X-Interaction-Id']: interactionId.get(),
      [allowAfeHeaders ? 'afe-experience-id' : 'X-Experience-Id']: expId,
      ...(sapiToken && {
        Authorization: authorization,
      }),
      ...(shouldIncludeAgentToken &&
        // TODO Added to bypass sending agent token to SAPI v4 because they aren't ready to consume it yet and return 401
        // TODO Better to feature flag this instead
        !env.bypassAgentToken && {
          [allowAfeHeaders ? 'afe-user-token' : 'X-Agent-Auth-Token']: `Bearer ${agentToken}`,
        }),
      ...(allowAfeHeaders && {
        'afe-source-id': env.static.applicationName,
        'afe-auth-key': env.static.authKey,
      }),
      ...(!!wafToken && { 'x-aws-waf-token': wafToken }),
    },
  };
}

export function requestWithToken({
  url,
  init,
  requestId,
  withAgentToken,
  allowAfeHeaders,
}: {
  url: string;
  init: RequestInit;
  requestId?: string;
  withAgentToken?: boolean;
  allowAfeHeaders?: boolean;
}) {
  return async (): Promise<Response> => {
    const extendedRequestInit = await extendRequestInit({
      init,
      requestId,
      withAgentToken,
      allowAfeHeaders,
    });

    const result = await fetch(url, extendedRequestInit);

    return result;
  };
}
