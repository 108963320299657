import { useFieldWithPrefix, useMailingAddressRef } from '@ecp/features/sales/shared/store';
import type { Address, FieldsDef } from '@ecp/features/sales/shared/types';
import type { Fields } from '@ecp/types';

interface MailingAddressFields extends Fields {
  address: FieldsDef<Pick<Address, 'line1' | 'line2' | 'city' | 'state' | 'zipcode'>>;
}

export const useMailingAddressFields = (): MailingAddressFields => {
  const primaryInsuredMailingAddressRef = useMailingAddressRef();
  const useAddressField = useFieldWithPrefix(primaryInsuredMailingAddressRef, 'address.<id>');

  return {
    address: {
      line1: useAddressField('line1'),
      line2: useAddressField('line2'),
      city: useAddressField(`city`),
      state: useAddressField(`state`),
      zipcode: useAddressField('zipcode'),
    },
  };
};
