import type { RouteProps } from '@ecp/utils/routing';
import { Redirect, Route } from '@ecp/utils/routing';

import {
  CheckoutErrorPage,
  CheckoutPage,
  CheckoutRetryPage,
  PostBindPage,
} from '@ecp/features/sales/checkout';
import {
  HomeBasicPage,
  HomeExteriorPage,
  HomeInteriorPage,
  HomeMarketValuePage,
} from '@ecp/features/sales/quotes/property/home';
import { PropertyDetailsPage } from '@ecp/features/sales/quotes/property/renters';
import { QuotesPage } from '@ecp/features/sales/quotes/property/shared';
import { PagePath } from '@ecp/features/sales/shared/routing';
import {
  DiscountsPage,
  ErrorNotFoundPage,
  RatingCriteriaPage,
  SecondaryNamedInsuredPage,
} from '@ecp/features/sales/shell';

import {
  CoveragesPage,
  LandingPage,
  PersonAddressPage,
  PersonPage,
  TransitionPage,
} from '../../views';

export const dynamicRoutes = [
  // Quick quote flow pages
  <Route path={PagePath.LANDING} component={LandingPage} />,
  <Route path={PagePath.PERSON_ADDRESS} component={PersonAddressPage} />,
  <Route path={PagePath.PERSON} component={PersonPage} />,
  <Route path={PagePath.QUOTES} component={QuotesPage} />,
  // App pages
  <Route path={PagePath.COVERAGES} component={CoveragesPage} />,
  <Route path={PagePath.CHECKOUT_RETRY} component={CheckoutRetryPage} />,
  <Route path={PagePath.CHECKOUT_ERROR} component={CheckoutErrorPage} />,
  <Route path={PagePath.CHECKOUT} component={CheckoutPage as RouteProps['component']} />,
  <Route path={PagePath.RENTERS_PROPERTY_DETAILS} component={PropertyDetailsPage} />,
  <Route path={PagePath.POST_BIND} component={PostBindPage as RouteProps['component']} />,
  <Route path={PagePath.RATING_CRITERIA} component={RatingCriteriaPage} />,
  <Route path={PagePath.RENTERS_DISCOUNTS} component={DiscountsPage} />,
  <Route path={PagePath.HOME_BASIC} component={HomeBasicPage} />,
  <Route path={PagePath.HOME_EXTERIOR} component={HomeExteriorPage} />,
  <Route path={PagePath.HOME_INTERIOR} component={HomeInteriorPage} />,
  <Route path={PagePath.HOME_MARKET_VALUE} component={HomeMarketValuePage} />,
  <Route path={PagePath.HOME_DISCOUNTS} component={DiscountsPage} />,
  <Route path={PagePath.TRANSITION} component={TransitionPage} />,
  <Route path={PagePath.SECONDARY_NAMED_INSURED} component={SecondaryNamedInsuredPage} />,
  <Route path={PagePath.DEFAULT}>
    <Redirect to={PagePath.LANDING} replace />
  </Route>,
  // Fallback page
  <Route component={ErrorNotFoundPage} />,
];
