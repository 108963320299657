import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { AutoComplete, GridItem } from '@ecp/components';

import { useAdvisorNameSearch } from '../../util/useAdvisorNameSearch';

export interface AdvisorNameSearchQuestionProps {
  disabled?: boolean;
}

export const AdvisorNameSearch: React.FC<AdvisorNameSearchQuestionProps> = (props) => {
  const {
    advisorName,
    advisorNumber,
    autoCompleteSuggestions,
    handleSuggestionSelectedRequested,
    handleSuggestionsFetchRequested,
    handleSuggestionsClearRequested,
  } = useAdvisorNameSearch();

  const disabled = props.disabled ?? false;

  if (!advisorNumber.exists) return null;

  return (
    <GridItem topSpacing='sm' xs={12} md={12}>
      <AutoComplete
        {...advisorName.props}
        disabled={disabled}
        label='What is your Ameriprise Financial Advisor’s last name? (optional)'
        suggestions={autoCompleteSuggestions}
        onSuggestionsClearRequested={handleSuggestionsClearRequested}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionSelected={handleSuggestionSelectedRequested}
        data-testid='AmeripriseAdvisorNumber'
        trackingName='advisor_last_name'
        trackingLabel={GoogleAnalyticsLabels.REDACTED}
        autoComplete='ameriprise-advisor-number'
        helperText='If you aren’t sure of your advisor’s name, then please choose Other.'
      />
    </GridItem>
  );
};
