import { LOB_ORDER } from '@ecp/features/sales/shared/constants';
import type { ThunkAction } from '@ecp/features/sales/shared/store/types';
import type { LineOfBusiness } from '@ecp/features/shared/product';

import { getLobOrder } from '../selectors';
import { updateAnswers } from './updateAnswers';

export const updateLobOrder =
  (
    lob: LineOfBusiness | null | undefined,
    mergeWithExistingLobOrder?: boolean,
  ): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    if (!lob) return;

    const state = getState();
    const lobOrder = getLobOrder(state, lob, mergeWithExistingLobOrder);

    await dispatch(updateAnswers({ answers: { [LOB_ORDER]: lobOrder } }));
  };
