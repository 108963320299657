import type { SalesResponse } from '@ecp/features/sales/shared/utils/network';
import { salesRequest } from '@ecp/features/sales/shared/utils/network';

import type { EnrollmentsResponse } from './types';

export const getEnrollments = ({
  dalSessionId,
}: {
  dalSessionId: string;
}): Promise<SalesResponse<EnrollmentsResponse>> => {
  return salesRequest({
    endpoint: `session/${dalSessionId}/offers/enrollments`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
};
