import {
  GraphicAddPersonalPossessionsReplacementCost1024X512BImageUrl,
  GraphicHomeCoverageAdditionalCoverageOtherDetachedStructureImageUrl,
  GraphicRentersCoverageBuildingAlterationsImageUrl,
  GraphicRentersCoverageFraudCoverageImageUrl,
  GraphicRentersCoverageSelfStorageImageUrl,
  GraphicRentersCoverageSpecialPossessionsImageUrl,
} from '@ecp/themes/base';
import type { OptionsMetadata } from '@ecp/types';

/**
 * The exports here mimic the base exports/keys of deductibles, coverages, other coverages, optional coverages
 * and included coverages on propertyCoverages metadata and type PropertyCoveragesFields
 *
 * The keys that will be feature flagged with IQUOTE_HO4_COVERAGES are implemented here
 *
 * TODO: Make this metadata file feature flag specific to allow the use of multiple feature flags.
 * */

export const deductibles: { [productKey: string]: OptionsMetadata } = {};

const rentersAdditionalCoverages: OptionsMetadata = {
  'coverages.personalPropertyPerilsNew': {
    primaryImage: GraphicRentersCoverageSpecialPossessionsImageUrl,
    title: 'Special Personal Possessions',
    primaryText: `Your policy covers your personal property from a finite list of “perils” or causes of loss.`,
    secondaryText: `While policy exclusions still apply, adding this endorsement expands what types of losses are covered by eliminating the list of standard perils.`,
  },
  'coverages.replacementCostOnPersonalProperty': {
    primaryImage: GraphicAddPersonalPossessionsReplacementCost1024X512BImageUrl,
    title: 'Personal Possessions Replacement Cost',
    primaryText: 'Your policy can be endorsed to include replacement costs for personal property.',
    secondaryText:
      'This means that if your personal property is stolen or damaged, you’ll be paid enough to replace your items with versions of like, kind and quality. This coverage applies whether or not your possessions are located in your home at the time of loss.',
  },
  'coverages.buildingAdditionsAlterationsCoverage': {
    primaryImage: GraphicRentersCoverageBuildingAlterationsImageUrl,
    title: 'Increased limit for Building Additions and Alterations',
    primaryText:
      "Your policy provides a limited amount of coverage for any improvements, installations, or alterations made to your rental unit at your expense. This increase is calculated as a percentage of your 'Personal possessions' coverage limit.",
    secondaryText:
      'This endorsement increases the amount of coverage available if these additions or alterations are damaged due to a covered loss.',
  },
};

export const coverages: { [productKey: string]: OptionsMetadata } = {
  'homesite.renters': rentersAdditionalCoverages,
  'amfam-adv.renters': rentersAdditionalCoverages,
};

const rentersAdditionalOtherCoverages: OptionsMetadata = {
  'coverages.personalPropertySelfStorageCoverage': {
    primaryImage: GraphicRentersCoverageSelfStorageImageUrl,
    title: 'Increased Personal Possessions at Self-Storage Facility',
    primaryText: `It is important you have your possessions covered when it is in storage. This increase is calculated as a percentage of your 'Personal possessions' coverage limit.`,
    secondaryText:
      'This endorsement can help make sure you have the right amount of coverage for all of your possessions.',
  },
  'coverages.electronicPaymentsForgeryCoverage': {
    primaryImage: GraphicRentersCoverageFraudCoverageImageUrl,
    title: 'Increased Credit EFT Access Card Counterfeit Money',
    primaryText:
      'Your policy provides some coverage due to theft or unauthorized use of a credit card, bank card or the acceptance of counterfeit money.',
    secondaryText:
      'Fraud like this can be costly, by adding this endorsement you can increase the amount of coverage for these kinds of losses.',
  },
  'coverages.personalPropertyOtherResidence': {
    primaryImage: GraphicHomeCoverageAdditionalCoverageOtherDetachedStructureImageUrl,
    title: 'Increased Amount of Insurance for Personal Property at Other Residences',
    primaryText: `Your policy covers your personal property but limits coverage for property located away from your rental. This increase is calculated as a percentage of your 'Personal possessions' coverage limit.`,
    secondaryText: `If you have property stored at a friend’s house or in your parents' garage, adding this endorsement will help ensure you have the coverage you need for your possessions.`,
  },
};

export const otherCoverages: { [productKey: string]: OptionsMetadata } = {
  'homesite.renters': rentersAdditionalOtherCoverages,
  'amfam-adv.renters': rentersAdditionalOtherCoverages,
};

export const includedCoverages: { [productKey: string]: OptionsMetadata } = {};

export const optionalCoverages: { [productKey: string]: OptionsMetadata } = {};
