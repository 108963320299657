import { memo } from 'react';

import { Grid } from '@mui/material';

import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { MonthlyPaymentsCheckoutCard } from '@ecp/features/sales/checkout';
import { useIsDesktop, useIsMobile } from '@ecp/themes/base';

import { useCheckoutCardProps } from '../../../util/useCheckoutCardProps';
import { CoveragesPageTitle } from './CoveragesPageTitle';
import { useStyles } from './CoveragesPageUpperSection.styles';

export const CoveragesPageUpperSection: React.FC = memo(() => {
  const { classes } = useStyles();

  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();

  const coveragePageRedesignVariant = flagValues[FeatureFlags.COVERAGE_PAGE_REDESIGN_VARIANTS];

  const shouldHideMonthlyPayments =
    (isMobile && flagValues[FeatureFlags.COVERAGEPAGE_REDESIGN]) || coveragePageRedesignVariant;

  return (
    <Grid
      container
      justifyContent={coveragePageRedesignVariant && isMobile ? 'left' : 'center'}
      className={classes.root}
    >
      <CoveragesPageTitle />
      {!shouldHideMonthlyPayments && (isDesktop ? null : <RenderMonthlyPaymentsCheckoutCard />)}
    </Grid>
  );
});

const RenderMonthlyPaymentsCheckoutCard: React.FC = memo(() => {
  const checkoutCardProps = useCheckoutCardProps();

  return <MonthlyPaymentsCheckoutCard {...checkoutCardProps} />;
});
