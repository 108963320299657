export interface Metadata {
  displayPaymentImage?: boolean;
  showHelpboxTip?: boolean;
}

const metadata: Metadata = {
  displayPaymentImage: true,
  showHelpboxTip: true,
};

export default metadata;
