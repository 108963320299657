export interface Metadata {
  phoneMessage: string;
}

const metadata: Metadata = {
  phoneMessage:
    'If you are interested in adding additional products, please call a licensed advisor at',
};

export default metadata;
