import { Grid } from '@mui/material';

import { Alert, GridItem } from '@ecp/components';

import { DeclinationNotice } from '../DeclinationNotice';
import { useDeclinationNotices } from '../util';
import metadata from './metadata';

interface Props {
  className?: string;
}

export const DeclinationNotices: React.FC<Props> = ({ className }) => {
  // Get DNQ offer details only if product option is available
  // Eg. Auto quote requested on landing page
  // 1. Auto only quotes on Quote Summary -> Show DNQ Reasons (if any) for auto quotes only
  // 2. FDS HIT -> Bundle option available -> Show DNQ Reasons (if any) for auto and home quotes
  const { erroredProducts, hasDeclinationNotices, isMVRActive } = useDeclinationNotices();

  if (!hasDeclinationNotices) {
    return null;
  }

  return (
    <Grid container rowSpacing='10px' className={className}>
      {/* When auto flow is short, check for mvr not being active and show the alert */}
      {!isMVRActive && (
        <GridItem>
          <Alert type='warning'>
            <p>{metadata.mvrText}</p>
          </Alert>
        </GridItem>
      )}
      {erroredProducts.map((product) => (
        <GridItem key={product}>
          <DeclinationNotice product={product} />
        </GridItem>
      ))}
    </Grid>
  );
};
