import { createReducer } from '@reduxjs/toolkit';

import {
  setAntiTheftDevicesDialogToggled,
  setNavAll,
  setNavCurrentPageChanged,
  setNavIncidentRefChanged,
  setNavMilitaryDeploymentRefChanged,
  setNavPreviousPageChanged,
  setPageStatusChanged,
  setPageStatusRemoved,
  setZipLookupBypassed,
} from './actions';
import type { NavState } from './types';

const navStateInitial: NavState = {};

export const navReducer = createReducer(navStateInitial, (builder) => {
  builder
    .addCase(
      setAntiTheftDevicesDialogToggled,
      (state, { payload: antiTheftDevicesDialogToggled }) => {
        state.antiTheftDevicesDialogToggled = antiTheftDevicesDialogToggled;
      },
    )
    .addCase(setNavAll, (state, { payload: nav }) => {
      return nav ? { ...state, ...(nav as NavState) } : state;
    })
    .addCase(setNavCurrentPageChanged, (state, { payload: currentPage }) => {
      state.currentPage = currentPage;
    })
    .addCase(setNavIncidentRefChanged, (state, { payload: incidentId }) => {
      state.incidentRef = incidentId;
    })
    .addCase(setNavMilitaryDeploymentRefChanged, (state, { payload: militaryDeploymentId }) => {
      state.militaryDeploymentsRef = militaryDeploymentId;
    })
    .addCase(setNavPreviousPageChanged, (state, { payload: previousPage }) => {
      state.previousPage = previousPage;
    })
    .addCase(setPageStatusChanged, (state, { payload }) => {
      if (!state.navTracking) state.navTracking = {};

      // Can only have one page in progress at a time, so revert other entries to false
      if (payload.tracking.inProgress) {
        const inProgressKeys = Object.keys(state.navTracking).filter(
          (key) => state.navTracking && state.navTracking[key]?.inProgress,
        );
        inProgressKeys.forEach((key) => {
          if (state.navTracking) state.navTracking[key].inProgress = false;
        });
      }

      state.navTracking[payload.path] = payload.tracking;
    })
    .addCase(setPageStatusRemoved, (state, { payload: pathToRemove }) => {
      if (state.navTracking) delete state.navTracking[pathToRemove];
    })
    .addCase(setZipLookupBypassed, (state, { payload: zipLookupBypassed }) => {
      state.zipLookupBypassed = zipLookupBypassed;
    })
    .addMatcher(
      (action): action is { type: '@global/teardownStore' } =>
        action.type === '@global/teardownStore',
      () => navStateInitial,
    );
});
