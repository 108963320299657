import { unique } from '@ecp/utils/common';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { LINE_OF_BUSINESS, OfferStatusCode } from '@ecp/features/sales/shared/constants';
import type { Answers, DriverBasicInfo } from '@ecp/features/sales/shared/types';
import type { CarrierName, Product } from '@ecp/features/shared/product';
import {
  getCarrierNameFromProduct,
  getLineOfBusinessUnbundledFromLineOfBusiness,
  getProductNameFromUnbundledLob,
  isLineOfBusinessBundle,
  isLineOfBusinessSupported,
  isProductAuto,
  isProductHome,
  isProductRenters,
  LineOfBusiness,
} from '@ecp/features/shared/product';

import metadata from './metadata';
import type {
  Offer,
  OfferDetails,
  OfferInfo,
  OffersGetResponse,
  OffersRecallResponse,
  QuoteSummaryOffers,
} from './types';

// TODO This module needs discriminated unions

const DEFAULT_QUOTE_SUMMARY_OFFERS: QuoteSummaryOffers = {
  auto: null,
  home: null,
  bundle: null,
  renters: null,
};

export const createQuoteSummaryOffers = (
  inputOffers: Partial<QuoteSummaryOffers>,
): QuoteSummaryOffers => {
  return Object.assign({}, { ...DEFAULT_QUOTE_SUMMARY_OFFERS, ...inputOffers });
};

const describeOffer = (
  offer: OfferDetails,
): {
  isBundle: boolean;
  autoOffer?: OfferInfo;
  homeOffer?: OfferInfo;
  rentersOffer?: OfferInfo;
  carrierName?: CarrierName;
} => {
  const offerKeys = Object.keys(offer);
  const isBundle = offerKeys.length > 1;

  const autoOfferKey = offerKeys.find(isProductAuto);
  const homeOfferKey = offerKeys.find(isProductHome);
  const rentersOfferKey = offerKeys.find(isProductRenters);
  const autoOffer = autoOfferKey && offer[autoOfferKey];
  const homeOffer = homeOfferKey && offer[homeOfferKey];
  const rentersOffer = rentersOfferKey && offer[rentersOfferKey];

  const product = autoOfferKey || homeOfferKey || rentersOfferKey;
  const carrierName = product ? getCarrierNameFromProduct(product) : undefined;

  const result: ReturnType<typeof describeOffer> = {
    isBundle,
    autoOffer,
    homeOffer,
    rentersOffer,
    carrierName,
  };

  return result;
};

// Home/Renters offer is always bindable. So, based on the selectedLob
// and corresponding auto offer, it can be determined whether
// the offer is estimated or not.
export const isOfferEstimated = ({
  autoOnlyOffer,
  autoBundledOffer,
  selectedLob,
}: {
  autoOnlyOffer?: OfferInfo | null;
  autoBundledOffer?: OfferInfo | null;
  selectedLob: LineOfBusiness;
}): { isEstimate: boolean; estimateActionText: string; estimateTrackingName: string } => {
  switch (selectedLob) {
    case LineOfBusiness.AUTO: {
      return {
        isEstimate: !!autoOnlyOffer?.isEstimated,
        estimateActionText: 'Finish And Buy',
        estimateTrackingName: 'FinishAndBuy',
      };
    }
    case LineOfBusiness.BUNDLE: {
      return {
        isEstimate: !!autoBundledOffer?.isEstimated,
        estimateActionText: 'Review And Buy Now',
        estimateTrackingName: 'ReviewAndBuyNow',
      };
    }
    // Home/Renters will never have indicative quote
    default:
      return { isEstimate: false, estimateActionText: '', estimateTrackingName: '' };
  }
};

// Quote No Bind offer is determined based on offer eligibility
// It considers both auto and (home or renters) offer in case of bundling
export const isOfferQuoteNoBind = ({
  autoOnlyOffer,
  homeOnlyOffer,
  rentersOnlyOffer,
  autoBundledOffer,
  homeBundledOffer,
  rentersBundledOffer,
  selectedLob,
}: {
  autoOnlyOffer?: OfferInfo | null;
  homeOnlyOffer?: OfferInfo | null;
  rentersOnlyOffer?: OfferInfo | null;
  autoBundledOffer?: OfferInfo | null;
  homeBundledOffer?: OfferInfo | null;
  rentersBundledOffer?: OfferInfo | null;
  selectedLob: LineOfBusiness;
}): boolean => {
  if (selectedLob === LineOfBusiness.AUTO) return !!autoOnlyOffer?.isQuoteNoBind;
  if (selectedLob === LineOfBusiness.HOME) return !!homeOnlyOffer?.isQuoteNoBind;
  if (selectedLob === LineOfBusiness.RENTERS) return !!rentersOnlyOffer?.isQuoteNoBind;
  if (isLineOfBusinessBundle(selectedLob))
    return (
      !!autoBundledOffer?.isQuoteNoBind ||
      !!homeBundledOffer?.isQuoteNoBind ||
      !!rentersBundledOffer?.isQuoteNoBind
    );

  return false;
};

export const isConfirmationRequired = ({
  autoOnlyOffer,
  autoBundledOffer,
  selectedLob,
}: {
  autoOnlyOffer?: OfferInfo | null;
  autoBundledOffer?: OfferInfo | null;
  selectedLob: LineOfBusiness;
}): boolean => {
  if (selectedLob === LineOfBusiness.AUTO) return !!autoOnlyOffer && autoOnlyOffer.isRVP;
  if (isLineOfBusinessBundle(selectedLob)) return !!autoBundledOffer && autoBundledOffer.isRVP;

  return false;
};

export const isOfferMVRActive = ({
  autoOnlyOffer,
  autoBundledOffer,
  selectedLob,
}: {
  autoOnlyOffer?: OfferInfo | null;
  autoBundledOffer?: OfferInfo | null;
  selectedLob: LineOfBusiness;
}): boolean => {
  if (selectedLob === LineOfBusiness.AUTO) return !!autoOnlyOffer?.isMVRActive;
  if (isLineOfBusinessBundle(selectedLob)) return !!autoBundledOffer?.isMVRActive;

  return true;
};

/**
 * !TODO Convert this nonsense into discriminated union
 */
interface OfferSummary {
  autoOnlyOffer: OfferInfo | null;
  autoOnlyPremium: number;
  autoBundledPremium: number;
  carrierName?: CarrierName;
  homeOnlyOffer: OfferInfo | null;
  homeOnlyPremium: number;
  homeBundledPremium: number;
  rentersOnlyOffer: OfferInfo | null;
  rentersOnlyPremium: number;
  rentersBundledPremium: number;
  autoBundledOffer: OfferInfo | null;
  homeBundledOffer: OfferInfo | null;
  rentersBundledOffer: OfferInfo | null;
  isBundleSelected: boolean;
  trackingLabel: string;
  offerProductsSelected: Product[];
  savedPremium: number | undefined;
}

export const describeOfferSummary = (
  offerSummary: QuoteSummaryOffers | null,
  selectedLob: LineOfBusiness,
): OfferSummary => {
  // Offer Summary will have individual auto, home and bundled products
  // The following section get the description of those offers individually
  const bundledOfferDescription = offerSummary?.bundle ? describeOffer(offerSummary.bundle) : null;
  const autoOfferDescription = offerSummary?.auto ? describeOffer(offerSummary.auto) : null;
  const homeOfferDescription = offerSummary?.home ? describeOffer(offerSummary.home) : null;
  const rentersOfferDescription = offerSummary?.renters
    ? describeOffer(offerSummary.renters)
    : null;

  const autoOnlyOffer = autoOfferDescription?.autoOffer || null;
  const homeOnlyOffer = homeOfferDescription?.homeOffer || null;
  const rentersOnlyOffer = rentersOfferDescription?.rentersOffer || null;

  const autoBundledOffer = bundledOfferDescription?.autoOffer || null;
  const homeBundledOffer = bundledOfferDescription?.homeOffer || null;
  const rentersBundledOffer = bundledOfferDescription?.rentersOffer || null;

  // Get the carrier key based on selected Line of business
  // assumption - carrier same in case of bundling.
  const carrierName = {
    [LineOfBusiness.AUTO]: autoOfferDescription?.carrierName,
    [LineOfBusiness.HOME]: homeOfferDescription?.carrierName,
    [LineOfBusiness.RENTERS]: rentersOfferDescription?.carrierName,
    [LineOfBusiness.BUNDLE]: bundledOfferDescription?.carrierName,
    [LineOfBusiness.BUNDLE_AUTO_RENTERS]: bundledOfferDescription?.carrierName,
  }[selectedLob];

  // Get the individual auto and home premiums as well as bundled premium
  const autoOnlyPremium = autoOnlyOffer?.fullPremium?.totalPremium || 0;
  const homeOnlyPremium = homeOnlyOffer?.fullPremium?.totalPremium || 0;
  const rentersOnlyPremium = rentersOnlyOffer?.fullPremium?.totalPremium || 0;
  const autoBundledPremium = autoBundledOffer?.fullPremium?.totalPremium || 0;
  const homeBundledPremium = homeBundledOffer?.fullPremium?.totalPremium || 0;
  const rentersBundledPremium = rentersBundledOffer?.fullPremium?.totalPremium || 0;
  // This value is based on user selection either to go monoline or bundle
  const isBundleSelected = isLineOfBusinessBundle(selectedLob);

  // tracking Label is used for Google Analytics tracking
  let trackingLabel = '';

  const offerProductsSelected: Product[] = [];
  switch (selectedLob) {
    // Go Monoline Auto
    case LineOfBusiness.AUTO: {
      if (autoOnlyOffer) {
        offerProductsSelected.push(autoOnlyOffer.product);
      }
      trackingLabel =
        (autoOnlyOffer &&
          `premiumType:${autoOnlyOffer.fullPremium?.premiumType}, offerId:${autoOnlyOffer.offerId}, product:${autoOnlyOffer.product}, premiumAmount:${autoOnlyPremium}, quoteNumber:${autoOnlyOffer.details.quoteNumber}`) ||
        '';
      break;
    }
    // Go Monoline Home
    case LineOfBusiness.HOME: {
      if (homeOnlyOffer) {
        offerProductsSelected.push(homeOnlyOffer.product);
      }
      trackingLabel =
        (homeOnlyOffer &&
          `premiumType:${homeOnlyOffer.fullPremium?.premiumType}, offerId:${homeOnlyOffer.offerId}, product:${homeOnlyOffer.product}, premiumAmount:${homeOnlyPremium}, quoteNumber:${homeOnlyOffer.details.quoteNumber}`) ||
        '';
      break;
    }
    // Go Monoline Renters
    case LineOfBusiness.RENTERS: {
      if (rentersOnlyOffer) {
        offerProductsSelected.push(rentersOnlyOffer.product);
      }
      trackingLabel =
        (rentersOnlyOffer &&
          `premiumType:${rentersOnlyOffer.fullPremium?.premiumType}, offerId:${rentersOnlyOffer.offerId}, product:${rentersOnlyOffer.product}, premiumAmount:${rentersOnlyPremium}, quoteNumber:${rentersOnlyOffer.details.quoteNumber}`) ||
        '';
      break;
    }
    // Go bundle
    case LineOfBusiness.BUNDLE: {
      if (autoBundledOffer) {
        offerProductsSelected.push(autoBundledOffer.product);
      }
      if (homeBundledOffer) {
        offerProductsSelected.push(homeBundledOffer.product);
      }
      trackingLabel =
        (autoBundledOffer &&
          homeBundledOffer &&
          `premiumType:${autoBundledOffer.fullPremium?.premiumType}, offerId:${autoBundledOffer.offerId}, product:${autoBundledOffer.product}, premiumAmount:${autoBundledPremium}, quoteNumber:${autoBundledOffer.details.quoteNumber}, premiumType:${homeBundledOffer.fullPremium?.premiumType}, offerId:${homeBundledOffer.offerId}, product:${homeBundledOffer.product}, premiumAmount:${homeBundledPremium}, quoteNumber:${homeBundledOffer.details.quoteNumber}`) ||
        '';
      break;
    }
    case LineOfBusiness.BUNDLE_AUTO_RENTERS: {
      if (autoBundledOffer) {
        offerProductsSelected.push(autoBundledOffer.product);
      }
      if (rentersBundledOffer) {
        offerProductsSelected.push(rentersBundledOffer.product);
      }
      trackingLabel =
        (autoBundledOffer &&
          rentersBundledOffer &&
          `premiumType:${autoBundledOffer.fullPremium?.premiumType}, offerId:${autoBundledOffer.offerId}, product:${autoBundledOffer.product}, premiumAmount:${autoBundledPremium}, quoteNumber:${autoBundledOffer.details.quoteNumber}, premiumType:${rentersBundledOffer.fullPremium?.premiumType}, offerId:${rentersBundledOffer.offerId}, product:${rentersBundledOffer.product}, premiumAmount:${rentersBundledPremium}, quoteNumber:${rentersBundledOffer.details.quoteNumber}`) ||
        '';
      break;
    }
    default:
      break;
  }

  const savedPremium =
    autoOnlyOffer && homeOnlyOffer && autoBundledOffer && homeBundledOffer
      ? autoOnlyPremium + homeOnlyPremium - autoBundledPremium - homeBundledPremium
      : autoOnlyOffer && rentersOnlyOffer && autoBundledOffer && rentersBundledOffer
      ? autoOnlyPremium + rentersOnlyPremium - autoBundledPremium - rentersBundledPremium
      : undefined;

  return {
    autoOnlyOffer,
    autoOnlyPremium,
    autoBundledPremium,
    carrierName,
    homeOnlyOffer,
    homeOnlyPremium,
    homeBundledPremium,
    rentersOnlyOffer,
    rentersOnlyPremium,
    rentersBundledPremium,
    autoBundledOffer,
    homeBundledOffer,
    rentersBundledOffer,
    isBundleSelected,
    trackingLabel,
    offerProductsSelected,
    savedPremium,
  };
};

export const getAnalyticsLabelsAndValidProducts = (
  offers: QuoteSummaryOffers | null,
  lineOfBusiness: LineOfBusiness,
): { analyticsLabel: string[]; validProducts: Product[] } => {
  const products: Product[] = [];
  const analyticsLabel: string[] = [];

  if (!isLineOfBusinessSupported(lineOfBusiness) || !offers)
    return { analyticsLabel, validProducts: [] };

  // Bundle offer details
  // UseCase: 1. LOB -> Bundle
  // 2. LOB -> AUTO/HOME -> Bundle offer available
  let summary;
  if (offers.bundle) {
    summary = describeOfferSummary(offers, lineOfBusiness);
    analyticsLabel.push(summary.trackingLabel);
    products.push(...summary.offerProductsSelected);
  }

  const lineOfBusinessUnbundled = getLineOfBusinessUnbundledFromLineOfBusiness(lineOfBusiness);
  lineOfBusinessUnbundled.forEach((lob) => {
    const productName = getProductNameFromUnbundledLob(lob);
    if (offers[productName]) {
      summary = describeOfferSummary(offers, lob);
      analyticsLabel.push(summary.trackingLabel);
      products.push(...summary.offerProductsSelected);
    }
  });

  const validProducts = unique(products);

  return { analyticsLabel, validProducts };
};

export const getValidOffersForAnalytics = (
  offers: QuoteSummaryOffers | null,
  lineOfBusiness: LineOfBusiness,
): string => {
  // No valid product available
  if (!offers) {
    return JSON.stringify({
      element: 'choice.validOfferCheck',
      event: 'update',
      [LINE_OF_BUSINESS]: lineOfBusiness,
      'valid.products': [],
    });
  }
  const { analyticsLabel, validProducts } = getAnalyticsLabelsAndValidProducts(
    offers,
    lineOfBusiness,
  );

  // Added element and event to eventDetails for easier lookup as per request of CDMP team
  return JSON.stringify({
    element: 'choice.validOfferCheck',
    event: 'update',
    [LINE_OF_BUSINESS]: lineOfBusiness,
    'valid.products': validProducts,
    offers: analyticsLabel,
  });
};

export const getAllAvailableOfferDetailsForAnalytics = (
  offers: QuoteSummaryOffers,
  lineOfBusiness: LineOfBusiness,
): string => {
  const { analyticsLabel } = getAnalyticsLabelsAndValidProducts(offers, lineOfBusiness);

  return analyticsLabel.toString();
};

export type AutoQuoteDetails = {
  drivers: DriverBasicInfo[];
};

/**
 *
 * @param offer - Offer from offers call
 * @param hasUserSelection - ECP-13490 QNB is not valid after user selection
 * @returns true if offer is valid; false if status is not valid
 */
export const isOfferValid = (offer: Offer | undefined | null, hasUserSelection = true): boolean =>
  !!offer &&
  (offer.eligibility.statusCode === OfferStatusCode.OK ||
    (!hasUserSelection && offer.eligibility.statusCode === OfferStatusCode.QNB) ||
    offer.eligibility.statusCode === OfferStatusCode.LOCKED ||
    offer.eligibility.statusCode === OfferStatusCode.PURCHASED);

export const isOfferOk = (offer: Offer | undefined | null): boolean =>
  !!offer && offer.eligibility.statusCode === OfferStatusCode.OK;

export const isOfferPurchased = (offer: Offer | undefined | null): boolean =>
  !!offer && offer.eligibility.statusCode === OfferStatusCode.PURCHASED;

export const isOfferLocked = (offer: Offer | undefined | null): boolean =>
  !!offer && offer.eligibility.statusCode === OfferStatusCode.LOCKED;

export const isOfferBindable = (offer: Offer | undefined | null): boolean =>
  !!offer && offer.eligibility.statusCode === OfferStatusCode.OK && offer.eligibility.bindable;

export const checkOfferAutoSwitchRequote = (offer: Offer | undefined | null): boolean => {
  return (
    !!offer &&
    !!offer.eligibility?.reasons?.some((reason) => reason.code === 'AUTO_SWITCH_RE_QUOTE')
  );
};
// RVP is only for Midvale Auto but here the logic is generalized
export const isOfferRVP = (offer: Offer | undefined | null): boolean => {
  // In v4, DAL doesn't give the "reason" node so we have to check in the "reasons" object
  const includesRVPReasons = offer?.eligibility.reasons?.some(
    (e) => e.code === 'PSPRVPH' || e.code === 'PSPRVPM',
  );

  return (
    !!offer &&
    offer.eligibility.statusCode === OfferStatusCode.QNB &&
    (includesRVPReasons ||
      offer.eligibility.reason === 'PSPRVPH' ||
      offer.eligibility.reason === 'PSPRVPM')
  );
};

export const isFullPayOnly = (
  offerSummary: QuoteSummaryOffers | null,
  selectedLob: LineOfBusiness,
): boolean => {
  const {
    autoOnlyOffer,
    homeOnlyOffer,
    autoBundledOffer,
    homeBundledOffer,
    rentersOnlyOffer,
    rentersBundledOffer,
  } = describeOfferSummary(offerSummary, selectedLob);
  const isHomeMonthlyPremiumEnabled = flagValues[FeatureFlags.HOME_MONTHLY_PREMIUM];
  const isRentersMonthlyPremiumEnabled = flagValues[FeatureFlags.RENTERS_MONTHLY_PREMIUM];

  switch (selectedLob) {
    case LineOfBusiness.AUTO:
      return !autoOnlyOffer?.monthlyPremium;
    case LineOfBusiness.HOME:
      return !homeOnlyOffer?.monthlyPremium || !isHomeMonthlyPremiumEnabled;
    case LineOfBusiness.BUNDLE:
      return (
        !autoBundledOffer?.monthlyPremium ||
        !homeBundledOffer?.monthlyPremium ||
        !isHomeMonthlyPremiumEnabled
      );
    case LineOfBusiness.RENTERS:
      return !rentersOnlyOffer?.monthlyPremium || !isRentersMonthlyPremiumEnabled;
    case LineOfBusiness.BUNDLE_AUTO_RENTERS:
      return (
        !autoBundledOffer?.monthlyPremium ||
        !rentersBundledOffer?.monthlyPremium ||
        !isRentersMonthlyPremiumEnabled
      );
  }

  return false;
};

export const filterAnswersDependenciesForNewOffers = <T extends Answers>(answers: T): T =>
  Object.keys(answers).reduce((acc, key) => {
    if (
      (!key.startsWith('analytics.') &&
        !key.startsWith('sapi.') &&
        !key.startsWith('static.') &&
        !key.startsWith('platform.')) ||
      // Answer keys below need to be included in the dependencies as an exception to the rule above
      key.startsWith('static.derived.discount.')
    )
      acc[key] = answers[key];

    return acc;
  }, {} as T);

export const isOfferAutoSwitchRequote = (response: OffersGetResponse): boolean => {
  const { offers } = response.data;

  const errorOffers = Object.values(offers).filter((offer) => checkOfferAutoSwitchRequote(offer));
  // if there is bundle offers, this error auto_switch_recall is only for auto, we will not show error for bundle

  return Object.values(offers).length === 1 && errorOffers.length === 1;
};

export const isIndicativeQuoteRetrievalDenied = (offerResponse: OffersRecallResponse): boolean => {
  const { denyIndicativeQuoteRetrieve } = metadata;
  const indicativeOfferProducts = Object.keys(offerResponse.data.offers)?.reduce((acc, product) => {
    if (
      offerResponse.data.offers[product] &&
      offerResponse.data.offers[product]?.quoteType === 'INDICATIVE'
    )
      acc = [...acc, product];

    return acc;
  }, [] as Product[] | []);

  return indicativeOfferProducts.length > 0 && !!denyIndicativeQuoteRetrieve;
};
