import { lazy } from 'react';

import { Route } from '@ecp/utils/routing';

import { PagePath } from '@ecp/features/sales/shared/routing';

export const staticPaths = [
  PagePath.ACCESSIBILITY,
  PagePath.FAQ,
  PagePath.PARTICIPATING_INSURERS,
  PagePath.PRIVACY_POLICY,
  PagePath.TERMS_OF_USE,
  PagePath.USE_OF_CREDIT_DISCLOSURE,
];

const AccessibilityPage = lazy(() =>
  import('@ecp/features/sales/shell').then((module) => ({ default: module.AccessibilityPage })),
);

const FAQPage = lazy(() =>
  import('@ecp/features/sales/shell').then((module) => ({
    default: module.FAQPage,
  })),
);

const ParticipatingCarriersPage = lazy(() =>
  import('@ecp/features/sales/shell').then((module) => ({
    default: module.ParticipatingCarriersPage,
  })),
);

const PrivacyPolicyPage = lazy(() =>
  import('@ecp/features/sales/shell').then((module) => ({
    default: module.PrivacyPolicyPage,
  })),
);

const TermsOfUsePage = lazy(() =>
  import('@ecp/features/sales/shell').then((module) => ({
    default: module.TermsOfUsePage,
  })),
);

const UseOfCreditDisclosurePage = lazy(() =>
  import('@ecp/features/sales/shell').then((module) => ({
    default: module.UseOfCreditDisclosurePage,
  })),
);

export const staticRoutes = [
  <Route path={PagePath.ACCESSIBILITY} component={AccessibilityPage} />,
  <Route path={PagePath.FAQ} component={FAQPage} />,
  <Route path={PagePath.PARTICIPATING_INSURERS} component={ParticipatingCarriersPage} />,
  <Route path={PagePath.PRIVACY_POLICY} component={PrivacyPolicyPage} />,
  <Route path={PagePath.TERMS_OF_USE} component={TermsOfUsePage} />,
  <Route path={PagePath.USE_OF_CREDIT_DISCLOSURE} component={UseOfCreditDisclosurePage} />,
];
