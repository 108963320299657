import { useCallback, useMemo } from 'react';

import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { PercentageGroupConnector } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField, useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import type { CardOption } from '@ecp/types';

import {
  useFloorTypeGroup,
  useFloorTypePercentages,
  useGetInteriorPercentageFields,
} from '../../../../state';

export const FloorTypeQuestion: React.FC<QuestionProps> = (props: QuestionProps) => {
  const floorType = useField('static.floor');
  const floorTypeField = useFieldWithPrefix('floor.<id>', 'floor.<id>')('type');
  const { addAndUpdateFloorType, updateFloorTypes, removeFloorType } = useFloorTypeGroup();
  const floorTypePercentages = useFloorTypePercentages();
  const types = Object.keys(floorTypePercentages).join(',');
  useInitValues({ [floorType.key]: types });

  const floorTypeFieldQuestionOptions = floorTypeField?.question?.options;
  const getFloorTypeAllLabels = useMemo(() => {
    return floorTypeFieldQuestionOptions?.map((option) => option.value) || [];
  }, [floorTypeFieldQuestionOptions]);
  const percentageFields = useGetInteriorPercentageFields(getFloorTypeAllLabels);

  useAddFields({
    floorType,
    ...percentageFields,
  });

  const handleFloorTypeAdd = useCallback(
    async (key: string, value: string) => {
      await addAndUpdateFloorType({ type: key, percentage: value });
    },
    [addAndUpdateFloorType],
  );

  const handleFloorTypesUpdate = useCallback(
    async (keyValues: { [key: string]: string }) => {
      if (Object.keys(keyValues).length > 0) {
        const floorTypes = Object.entries(keyValues).map(([key, value]) => {
          return { type: key, percentage: value };
        });
        await updateFloorTypes(floorTypes);
      }
    },
    [updateFloorTypes],
  );

  const handleFloorTypeRemove = useCallback(
    async (key: string) => {
      await removeFloorType(key);
    },
    [removeFloorType],
  );

  if (!floorTypeField.exists) return null;

  const {
    label = floorTypeField.question.title || '',
    helperText = floorTypeField.question.helpText,
    trackingName = 'floor_types_more_choices',
  } = props;

  return (
    <PercentageGroupConnector
      hidePercentageSelected
      title={label}
      subtitle={floorTypeField.question.subtitle || ''}
      helpText={helperText}
      label='floor'
      options={floorTypeField.question.options as CardOption[]}
      percentages={floorTypePercentages}
      uiField={floorType}
      onAdd={handleFloorTypeAdd}
      onUpdate={handleFloorTypesUpdate}
      onRemove={handleFloorTypeRemove}
      moreLessTrackingName={trackingName}
      optionTrackingName='floor_types_multiselect'
      percentTrackingName='floor_type_percent'
      sortByKey
    />
  );
};
