import { useAddFields } from '@ecp/features/sales/form';
import { Slider } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const NumberOfStoriesQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const numberOfStories = useHomePropertyField('numberOfStories');
  const { trackingName = 'home_stories_slider', groupLabel = numberOfStories?.question?.title } =
    props;

  useAddFields({ numberOfStories });

  return (
    <>
      {' '}
      {numberOfStories.exists && (
        <Slider
          {...numberOfStories.props}
          id='NumberOfStories'
          groupLabel={groupLabel}
          trackingName={trackingName}
          dataTestId='NumberOfStories'
        />
      )}
    </>
  );
};
