import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<void, 'large' | 'medium' | 'small' | 'image'>({
  name: 'AddMore',
})((...[theme, , classes]) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      width: '100% !important',
    },
  },
  button: theme.mixins.setColorSvg(theme.palette.text.link),
  card: {
    minHeight: 170,
    minWidth: 140,
    background: theme.palette.secondary.background,
    marginLeft: 12,
    [`&.${classes.medium}, &.${classes.small}`]: {
      [theme.breakpoints.only('lg')]: {
        width: 149,
      },
      [theme.breakpoints.only('md')]: {
        width: 168,
      },
    },
    '&:hover': {
      ...theme.mixins.shadow1,
    },
    [`& .${classes.image}`]: theme.mixins.setColorSvg(theme.palette.grey[500]),
  },
  text: {
    ...theme.typography.body1Bold,
  },
  image: {
    marginRight: 10,
    height: 70,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: 7,
      marginBottom: 7,
      width: 72,
    },
  },
  large: {
    minHeight: 70,
    flexDirection: 'row',
    backgroundColor: theme.palette.grey[50],
    border: `2px dashed ${theme.palette.text.link}`,
    borderRadius: 8,
    justifyContent: 'normal',
    marginRight: '0px !important',
  },
  medium: {
    width: 176,
  },
  small: {
    width: 140,
  },
  addMoreContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': theme.mixins.shadow1,
    [theme.breakpoints.down('md')]: {
      height: 230,
      margin: 'auto',
    },
  },
  disabled: {
    '& > *': {
      color: theme.palette.text.disabled,
    },
    cursor: 'auto',
    '&:hover': {
      boxShadow: 'none',
    },
  },
}));
