import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { isDlNumberMasked, isMasked } from '@ecp/utils/common';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { datadogLog } from '@ecp/utils/logger';

import { INVALID_LICENSE_NUMBER_MSG } from '@ecp/features/sales/shared/constants';
import {
  deleteAnswers,
  setFormErrorsChangedByField,
  setFormErrorsResetByField,
  updateAnswers,
} from '@ecp/features/sales/shared/store';
import type {
  ActionMetadataArgs,
  Answers,
  Driver,
  ProductQuestionsMetadata,
  QuestionsMetadata,
  Vehicle,
} from '@ecp/features/sales/shared/types';
import type { Field, Fields, Props } from '@ecp/types';

import { fetchVehicleByVinNumber } from '../api';
import { validateDriverLicense, validateVinFormat } from '../state';

export const validateVin = async (args: ActionMetadataArgs): Promise<string> => {
  const { value, customData, dispatch, field } = args;

  // If the vin number is empty we want to delete the key
  if (value === '') {
    await dispatch(deleteAnswers({ ref: field.key }));

    return '';
  }

  let vinErr = '';

  try {
    if (!customData) {
      datadogLog({
        logType: 'warn',
        message: 'No vehicle supplied for vin on complete action',
        context: {
          logOrigin: 'libs/features/sales/quotes/auto/src/metadata/AutoDeltaQuestions.metadata.ts',
          functionOrigin: 'validateVin',
        },
      });

      throw new Error('No vehicle supplied for vin on complete action');
    }

    const { vehicle } = customData as {
      vehicle: Vehicle;
      vehicles: {
        metadata: QuestionsMetadata;
        fields: Fields;
        vehicle: Vehicle;
      }[];
    };

    const vehicleInfo = await fetchVehicleByVinNumber({
      year: vehicle.year,
      vinNumber: (value as string).trim(),
    });

    if (vehicleInfo?.year === vehicle.year) {
      // Reaching here means there is no vin/year exception,
      // so we need to update all vehicle values
      const { make, model, vehicleCharacteristics, msrp, vinPattern, vehicleDetailId } =
        vehicleInfo;

      dispatch(
        updateAnswers({
          answers: {
            [`${vehicle.ref}.make`]: make,
            [`${vehicle.ref}.model`]: model,
            [`${vehicle.ref}.vehicleDetailId`]: vehicleDetailId,
            [`${vehicle.ref}.msrpPrice`]: msrp,
            [`${vehicle.ref}.stubVin`]: vinPattern,
            [`${vehicle.ref}.series`]: vehicleCharacteristics,
            [`${vehicle.ref}.vin`]: (value as string).trim(),
          },
        }),
      );
    }
  } catch (e) {
    /**
     * We get to the catch block in 2 scenarios
     *
     * 1. the vin is invalid
     * 2. the vin year does not match
     *
     * In scenario 2 we want to display a custom error message. If the message contians 'valid'
     * anywhere in the sentence we know that it is not a year mismatch and the requirement is to
     * display whatever error the fuel API sends.
     */
    if (e instanceof Error) {
      vinErr = e.message.includes('valid')
        ? e.message
        : 'The vehicle year does not match the VIN. Make sure you have the correct vehicle year selected to continue.';
      datadogLog({
        logType: 'warn',
        message: vinErr,
        context: {
          logOrigin: 'libs/features/sales/quotes/auto/src/metadata/AutoDeltaQuestions.metadata.ts',
          functionOrigin: 'validateVin',
          // TODO: we cannot spread anything into DD logs
          // ...e,
        },
        error: e,
      });
    }
  }

  return vinErr;
};

const vinFieldOnComplete = async (args: ActionMetadataArgs): Promise<string> => {
  const { dispatch, field, value } = args;
  let err = '';
  if (!isMasked(value)) {
    const isRequired = true;
    const disableVehicleServiceCallFlag = flagValues[FeatureFlags.DISABLE_VEHICLE_SERVICE_CALL];
    err = !disableVehicleServiceCallFlag
      ? await validateVin(args)
      : validateVinFormat(value as string, isRequired) || '';
    if (err) {
      dispatch(
        setFormErrorsChangedByField({
          key: field.key,
          errors: [err],
        }),
      );
    } else {
      dispatch(setFormErrorsResetByField({ key: field.key }));
    }
    if (err !== '') return err;
    // we will not be patching the vin here in any flow
    // vin will be patched on submit on auto delta page (if it is changed)
  }

  return '';
};

const vinOnComplete = async (args: ActionMetadataArgs): Promise<'DND'> => {
  await vinFieldOnComplete(args);

  return 'DND';
};

const vinOnChange = async (args: ActionMetadataArgs): Promise<'DND'> => {
  const { field, value } = args;
  if (isMasked(value)) {
    if (field.value !== null) field.validateUpdateAndPatch(null);
  } else {
    // after accounting for whitespace, if the user has entered 17 valid characters, we want to run the fuelAPI validation immediately.
    // This helps avoid situations where the API request has not resolved by the time the user submits the form.
    // Validation already exists within the onChange handler that accounts for situations where the value !== 17 characters.
    if ((value as string).trim().length === 17) {
      await vinFieldOnComplete(args);
    }
    field.props.actionOnChange(value);
  }

  return 'DND';
};

const plateNumberOnComplete = async (args: ActionMetadataArgs): Promise<string> => {
  const { dispatch, field, value } = args;
  const err = field.validate(value);
  if (err) {
    dispatch(
      setFormErrorsChangedByField({
        key: field.key,
        errors: [err[0]],
      }),
    );
  } else {
    dispatch(setFormErrorsResetByField({ key: field.key }));
  }
  if (err[0] !== '') return err[0];

  return '';
};

type Drivers = Array<{
  metadata: QuestionsMetadata;
  fields: Fields;
  driver: Driver;
}>;

const findDriverByRef = (drivers: Drivers, ref: string): Drivers[number] =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  drivers.find((d) => d.driver.ref === ref)!;

const licenseNumberOnChange = (args: ActionMetadataArgs): string => {
  const { value, customData, field } = args;
  if (isDlNumberMasked(value)) {
    const { drivers, driver } = customData as {
      drivers: Drivers;
      driver: Driver;
    };

    field.props.actionOnChange(null);

    // clear out the state
    const stateField = findDriverByRef(drivers, driver.ref).fields['license.state'] as Field;
    if (stateField.value !== null) stateField.validateUpdateAndPatch(null);

    // do not update the field with the new value
    return 'DND';
  }

  // update the field with the new value
  return '';
};

const licenseNumberOnComplete = async (args: ActionMetadataArgs): Promise<string> => {
  const { customData, dispatch, value } = args;

  const { drivers, driver } = customData as {
    drivers: Drivers;
    driver: Driver;
  };

  if (isDlNumberMasked(value)) return 'DND';
  // CSUI-1200 To address driver license validation, here we are
  // clearing out the errors action on complete. then we will repatch
  // all drivers for errors. If a duplicate driver error comes back we will
  // skip the other validations until they address the error.
  const driverObj: Answers = {};
  let duplicateDriverCheck = false;
  // Validate driver license number

  // get the state
  const state = findDriverByRef(drivers, driver.ref).fields['license.state']?.value;
  // validate the driver license with the number and the state (if the state has not been selected, it will trigger this error too since validation would fail)
  const validationResult = await validateDriverLicense(value as string, state as string);

  drivers.forEach((currentDriver) => {
    const { key, props, value } = currentDriver.fields['license.number'] as {
      key: string;
      props: Props;
      value: string;
    };
    if (props.error === 'Duplicate driver’s license number') {
      dispatch(setFormErrorsResetByField({ key }));
    }
    if (validationResult && !isDlNumberMasked(value)) {
      driverObj[key] = value;
      if (props.error === 'Duplicate driver’s license number') {
        duplicateDriverCheck = true;
      }
    }
  });

  await dispatch(updateAnswers({ answers: driverObj }));

  if (duplicateDriverCheck) {
    return 'Duplicate Driver';
  }

  const validationError = validationResult ? '' : INVALID_LICENSE_NUMBER_MSG;

  return validationError;
};

const licenseStateOnChange = async (args: ActionMetadataArgs): Promise<string> => {
  const { customData, dispatch, field, value } = args;

  const { drivers, driver } = customData as {
    drivers: Drivers;
    driver: Driver;
  };

  // skip update if value is same
  if (value === field.value) return 'DND';

  // find DL number
  const dlNumberField = findDriverByRef(drivers, driver.ref).fields['license.number'] as Field;

  if (!dlNumberField) return '';

  // if DL number is masked, clear out DL number and patch it
  if (dlNumberField.value && isDlNumberMasked(dlNumberField.value)) {
    dlNumberField.props.actionOnComplete(null);

    // else validate DL number and state
  } else if (!dlNumberField.errors.includes('Duplicate drivers license number')) {
    // validate
    const validationResult = await validateDriverLicense(
      dlNumberField.value as string,
      value as string,
    );

    // if error, set driver license field error
    if (!validationResult) {
      dispatch(
        setFormErrorsChangedByField({
          key: dlNumberField.key as string,
          errors: [INVALID_LICENSE_NUMBER_MSG],
        }),
      );
    } else if (dlNumberField.errors[0] === INVALID_LICENSE_NUMBER_MSG) {
      // clear the driver license validation error if there is any, but not other errors (let the other error persist)
      dispatch(setFormErrorsResetByField({ key: dlNumberField.key as string }));
    }
  }

  // update the field with the new value
  return '';
};

const licenseStateOnComplete = (): string => '';

const relationshipToApplicantOnComplete = (args: ActionMetadataArgs): string => {
  const { customData, dispatch } = args;

  const { drivers } = customData as {
    drivers: Drivers;
    driver: Driver;
  };

  // If there are 2 or fewer drivers we don't want to send back anything
  // since it would be impossible to have multiple spouses if there are only
  // 2 or fewer drivers.
  if (drivers.length <= 2) {
    return '';
  }

  const duplicates = drivers.filter((d) => {
    if (d.fields?.relationshipToApplicant) {
      return d.fields.relationshipToApplicant.value === 'SP';
    }

    return false;
  });

  // There should only be 1 spouse max
  const err = duplicates.length > 1 ? 'Multiple spouses' : '';

  // validate all other relationship fields in case previous errors are now valid
  drivers.forEach((d) => {
    if (d.fields?.relationshipToApplicant?.key && d.fields.relationshipToApplicant.value === 'SP') {
      const relationshipKey = d.fields.relationshipToApplicant.key as string;
      if (err) {
        dispatch(
          setFormErrorsChangedByField({
            key: relationshipKey,
            errors: [err],
          }),
        );
      } else {
        dispatch(setFormErrorsResetByField({ key: relationshipKey }));
      }
    }
  });

  return err;
};

export const AutoDeltaVehicleQuestionMetadata = (vehicleName: string): ProductQuestionsMetadata => {
  return {
    'amfam-adv.auto': {
      vin: {
        key: 'vin',
        title: 'VIN',
        trackingName: 'vin',
        displayType: 'TextInput',
        testId: 'vin',
        trackingLabel: GoogleAnalyticsLabels.REDACTED,
        actions: {
          asyncActionOnChange: vinOnChange,
          asyncActionOnComplete: vinOnComplete,
        },
      },
      isLeased: {
        key: 'isLeased',
        title: 'Is this vehicle leased?',
        trackingName: 'IsVehicleLeased',
        displayType: 'RadioGroup',
        testId: 'isLeased',
      },
      leasedDate: {
        key: 'leasedDate',
        title: 'Lease start date',
        helpText: 'The date the contract was signed which can be found on your leasing contract',
        trackingName: 'LeaseDate',
        displayType: 'DatePicker',
        fullWidth: true,
        hidePicker: true,
        testId: 'leasedDate',
      },
    },
    'amfam.auto': {
      odometerReading: {
        key: 'odometerReading',
        title: 'Current odometer',
        placeholder: '(miles)',
        thousandSeparator: true,
        fullWidth: true,
        trackingName: 'current_odometer',
        displayType: 'NumberFormat',
        testId: 'odometerReading',
      },
      annualMiles: {
        key: 'annualMiles',
        title: 'Yearly mileage',
        placeholder: '(miles)',
        thousandSeparator: true,
        fullWidth: true,
        trackingName: 'yearly_mileage',
        displayType: 'NumberFormat',
        testId: 'annualMiles',
      },
      // mileage is for v4 midvale and the miles is for v3 midvale
      annualMileage: {
        key: 'annualMileage',
        title: 'Yearly mileage',
        placeholder: '(miles)',
        thousandSeparator: true,
        fullWidth: true,
        trackingName: 'yearly_mileage',
        displayType: 'NumberFormat',
        testId: 'annualMileage',
      },
      vin: {
        key: 'vin',
        title: 'VIN',
        trackingName: 'vin',
        displayType: 'TextInput',
        testId: 'vin',
        trackingLabel: GoogleAnalyticsLabels.REDACTED,
        actions: {
          asyncActionOnChange: vinOnChange,
          asyncActionOnComplete: vinOnComplete,
        },
      },
      isLeased: {
        key: 'isLeased',
        title: 'Is this vehicle leased?',
        trackingName: 'IsVehicleLeased',
        displayType: 'RadioGroup',
        testId: 'isLeased',
      },
      leasedDate: {
        key: 'leasedDate',
        title: 'Lease start date',
        helpText: 'The date the contract was signed which can be found on your leasing contract',
        trackingName: 'LeaseDate',
        displayType: 'DatePicker',
        fullWidth: true,
        hidePicker: true,
        testId: 'leasedDate',
      },
    },
    'connect.auto': {
      annualMileage: {
        key: 'annualMileage',
        title: 'Yearly mileage',
        placeholder: '(miles)',
        trackingName: 'yearly_mileage',
        displayType: 'NumberFormat',
        testId: 'annualMileage',
        trackingLabel: GoogleAnalyticsLabels.REDACTED,
      },
      vin: {
        key: 'vin',
        title: 'VIN',
        trackingName: 'vin',
        displayType: 'TextInput',
        testId: 'vin',
        trackingLabel: GoogleAnalyticsLabels.REDACTED,
        actions: {
          asyncActionOnChange: vinOnChange,
          asyncActionOnComplete: vinOnComplete,
        },
      },
      plateType: {
        key: 'plateType',
        title: 'License plate type',
        trackingName: 'license_plate_type',
        displayType: 'Select',
        testId: 'plateType',
        trackingLabel: GoogleAnalyticsLabels.REDACTED,
      },
      plateNumber: {
        key: 'plateNumber',
        title: 'License plate number',
        trackingName: 'license_plate_number',
        displayType: 'TextInput',
        testId: 'plateNumber',
        trackingLabel: GoogleAnalyticsLabels.REDACTED,
        actions: {
          asyncActionOnComplete: plateNumberOnComplete,
        },
      },
    },
  };
};

export const AutoDeltaDriverQuestionMetadata = (
  firstName = '',
  primaryFirstName = '',
  international = false,
): ProductQuestionsMetadata => {
  return {
    'amfam-adv.auto': {
      'license.number': {
        key: 'license.number',
        id: 'LicenseNumber',
        title: 'Driver’s license #',
        trackingLabel: GoogleAnalyticsLabels.REDACTED,
        displayColumns: 8,
        displayType: 'TextInput',
        trackingName: 'drivers_license_number',
        testId: 'driversLicense',
        actions: {
          actionOnChange: licenseNumberOnChange,
          asyncActionOnComplete: licenseNumberOnComplete,
        },
      },
      'license.state': {
        key: 'license.state',
        id: 'LicenseState',
        title: 'State',
        displayColumns: 4,
        displayType: 'Select',
        trackingName: 'drivers_license_state',
        testId: 'driversLicenseState',
        actions: {
          asyncActionOnChange: licenseStateOnChange,
          actionOnComplete: licenseStateOnComplete,
        },
      },
      'license.ageFirstLicensed': {
        key: 'license.license.ageFirstLicensed',
        id: 'license.ageFirstLicensed',
        title: 'Age First Licensed',
        displayColumns: 4,
        displayType: 'TextInput',
        trackingName: 'drivers_age_first_licensed',
        testId: 'ageFirstLicensed',
      },
    },
    'amfam.auto': {
      'license.number': {
        key: 'license.number',
        id: 'LicenseNumber',
        title: 'Driver’s license #',
        trackingLabel: GoogleAnalyticsLabels.REDACTED,
        displayColumns: 8,
        displayType: 'TextInput',
        trackingName: 'drivers_license_number',
        testId: 'driversLicense',
        actions: {
          actionOnChange: licenseNumberOnChange,
          asyncActionOnComplete: licenseNumberOnComplete,
        },
      },
      'license.state': {
        key: 'license.state',
        id: 'LicenseState',
        title: 'State',
        displayColumns: 4,
        displayType: 'Select',
        trackingName: 'drivers_license_state',
        testId: 'driversLicenseState',
        actions: {
          asyncActionOnChange: licenseStateOnChange,
          actionOnComplete: licenseStateOnComplete,
        },
      },
      militaryDeploymentDate: {
        key: 'militaryDeploymentDate',
        title: 'Deployment date',
        helpText: `Please provide ${firstName}’s military deployment date`,
        displayType: 'DatePicker',
        fullWidth: true,
        trackingName: 'driver_deployment_date',
        testId: 'militaryDeploymentDate',
      },
      relationshipToApplicant: {
        key: 'relationshipToApplicant',
        title: 'Relationship',
        helpText: `What is ${firstName}’s relationship with ${primaryFirstName}?`,
        displayType: 'Select',
        trackingName: 'driver_relationship',
        testId: 'relationshipToApplicant',
        actions: {
          actionOnComplete: relationshipToApplicantOnComplete,
        },
      },
    },
    'connect.auto': {
      'license.number': {
        key: 'license.number',
        id: 'LicenseNumber',
        title: 'Driver’s license #',
        trackingLabel: GoogleAnalyticsLabels.REDACTED,
        displayColumns: 8,
        displayType: 'TextInput',
        trackingName: 'drivers_license_number',
        testId: 'driversLicense',
        actions: {
          actionOnChange: licenseNumberOnChange,
          asyncActionOnComplete: licenseNumberOnComplete,
        },
      },
      'license.state': {
        key: 'license.state',
        id: 'LicenseState',
        title: 'State',
        displayColumns: 4,
        trackingName: 'drivers_license_state',
        testId: 'driversLicenseState',
        displayType: 'Select',
        actions: {
          asyncActionOnChange: licenseStateOnChange,
          actionOnComplete: licenseStateOnComplete,
        },
      },
    },
  };
};
