import { useCallback } from 'react';

import { createRef, getSapiTarget, usePostBindField } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { FieldsDef } from '@ecp/features/sales/shared/types';
import type { Fields } from '@ecp/types';

import { getPostBindAnswers, updatePostBindAnswers } from '../state';

interface AdditionalInterestFields {
  name: string;
  email: string;
  exists: boolean;
  city: string;
  zipcode: string;
  state: string;
  line1: string;
  line2: string;
}

interface AllFields extends Fields {
  allFields: FieldsDef<AdditionalInterestFields>;
}

export const useAdditionalInterestAddressRef = (): string => {
  const additionalInterestAddressRefKey = 'additionalInterest.address.ref';

  return useSelector(getPostBindAnswers)[additionalInterestAddressRefKey] as string;
};

export const useAdditionalInterestFields = (): AllFields => {
  const prefix = 'additionalInterest';
  const sapiTarget = useSelector(getSapiTarget);
  let additionalInterestAddressRef = useAdditionalInterestAddressRef();

  const addressKeyPrefix = sapiTarget === 'v4' ? 'additionalInterest.address' : 'address.<id>.';
  additionalInterestAddressRef =
    sapiTarget === 'v4' ? addressKeyPrefix : additionalInterestAddressRef;

  return {
    allFields: {
      line1: usePostBindField(`${additionalInterestAddressRef}.line1`, `${addressKeyPrefix}line1`),
      line2: usePostBindField(`${additionalInterestAddressRef}.line2`, `${addressKeyPrefix}line2`),
      city: usePostBindField(`${additionalInterestAddressRef}.city`, `${addressKeyPrefix}city`),
      state: usePostBindField(`${additionalInterestAddressRef}.state`, `${addressKeyPrefix}state`),
      zipcode: usePostBindField(
        `${additionalInterestAddressRef}.zipcode`,
        `${addressKeyPrefix}zipcode`,
      ),
      name: usePostBindField(`${prefix}.name`),
      email: usePostBindField(`${prefix}.email`),
      exists: usePostBindField(`${prefix}.exists`),
    },
  };
};

// adds additional interest address ref to the store,
export const useAdditionalInterest = (): (() => void) => {
  const dispatch = useDispatch();

  return useCallback(() => {
    const addressRef: string = dispatch(createRef('address'));

    dispatch(
      updatePostBindAnswers({
        'additionalInterest.address.ref': addressRef,
      }),
    );
  }, [dispatch]);
};
