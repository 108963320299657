import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AdditionalInterestForm' })((theme) => ({
  root: {
    marginTop: 15,
    display: 'flex',
    flexDirection: 'column',
  },
  informationCard: {
    backgroundColor: theme.palette.grey[100],
    borderTopColor: theme.palette.primary.main,
    borderTopStyle: 'solid',
    borderTopWidth: 4,
    marginTop: 20,
    padding: '0px 20px 20px 20px',
  },
  columnContentHeader: {
    marginTop: 20,
    marginBottom: 6,
  },
  radioBtnGroupContainer: {
    color: theme.palette.primary.darkText,
    marginTop: 20,
    paddingLeft: 0,
  },
  columnContentFooter: {
    marginTop: 15,
    marginBottom: 0,
    [theme.breakpoints.up('md')]: {
      marginTop: 20,
    },
  },
  formRoot: {
    marginTop: 20,
  },
  continueBtn: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  textTeriary: {
    color: theme.palette.text.tertiary,
  },
  textItalic: theme.typography.body1Italics,
}));
