import { useAddFields } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

export const FireHydrantQuestion: React.FC<QuestionProps> = (props) => {
  const {
    label = 'Is there a fire hydrant within 1000 feet of the rental property?',
    trackingName = 'fire_hydrant_selection',
  } = props;

  const fireHydrant = useField('fireHydrant');

  useAddFields({ fireHydrant });

  if (!fireHydrant.exists) return null;

  return (
    <RadioGroupWithOptions
      {...fireHydrant.props}
      label={label}
      id={fireHydrant.props.name}
      variant='yesNoButton'
      trackingName={trackingName}
    />
  );
};
