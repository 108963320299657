import { memo } from 'react';

import type { TextFieldProps } from '@ecp/components';
import { useFieldRef } from '@ecp/features/sales/form';
import { IconUIClose, IconUIPercentage } from '@ecp/themes/base';

import { Button } from '../Button';
import { TextField } from '../TextField';
import { useStyles } from './PercentageInput.styles';

export interface PercentageInputProps
  extends Pick<TextFieldProps, 'disabled' | 'error' | 'id' | 'name'> {
  label: React.ReactElement | string;
  onBlur: NonNullable<TextFieldProps['onBlur']>;
  onChange: NonNullable<TextFieldProps['onChange']>;
  onRemove: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  value: string;
}

export const PercentageInput: React.FC<PercentageInputProps> = memo((props) => {
  const { disabled, error, id, label, name, onBlur, onChange, onRemove, value = '' } = props;
  const { classes } = useStyles();
  const ref = useFieldRef(name);

  return (
    <div className={classes.percentageInputPanel} key={name}>
      <label htmlFor={name} className={classes.percentageLabel} id={id && `${id}-label`}>
        {label}
      </label>
      <div className={classes.inputPanel}>
        <TextField
          icon={<IconUIPercentage className={classes.percentageIcon} />}
          disabled={disabled}
          id={id}
          name={name}
          value={value}
          error={error}
          inputRef={ref}
          onChange={onChange}
          onBlur={onBlur}
          ariaLabel={typeof label === 'string' ? label : name}
        />
      </div>
      <div className={classes.removeButtonPanel}>
        <Button
          disabled={disabled}
          variant='iconText'
          data-key={name}
          onClick={onRemove}
          aria-label={`Remove ${label}`}
        >
          <IconUIClose className={classes.removeIcon} />
        </Button>
      </div>
    </div>
  );
});
