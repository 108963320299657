import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutoComplete' })((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    ...theme.mixins.shadow2,
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 11,
    marginTop: -20,
    left: 0,
    right: 0,
    maxHeight: 320,
    overflowY: 'auto',
    [theme.breakpoints.up('md')]: {
      width: '125%',
    },
    borderRadius: 4,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: '10 px 0 0 0',
    padding: 0,
    listStyleType: 'none',
  },
  listItem: {
    padding: '8px 25px',
    '&.Mui-selected, &.Mui-focusVisible': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
    },
    [theme.breakpoints.down('md')]: {
      whiteSpace: 'normal',
    },
  },
}));
