import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'HomeExteriorForm' })((theme) => ({
  ...theme.mixins.formAll,
  showMoreOrLess: {
    minWidth: 200,
    height: 200,
    marginTop: 40,
  },
  showMoreOrLessPanel: {
    display: 'flex',
  },
  link: {
    marginLeft: 3,
    color: 'rgb(0, 0, 255)',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
