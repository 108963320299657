import {
  emptyQuestion,
  OfferStatusCode,
  PurchaseStatusCode,
  REF_REGEX,
} from '@ecp/features/sales/shared/constants';
import {
  getOfferDetailsForProduct,
  getOffers,
  getProductPolicyStartDate,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import type { Answers } from '@ecp/features/sales/shared/types';
import type { Product } from '@ecp/features/shared/product';
import { getProductNameFromProduct } from '@ecp/features/shared/product';
import type { Question, Questions } from '@ecp/types';

import { PostBindRetryMessaging } from '../../metadata';
import type {
  CheckoutOfferDetails,
  PaymentMethod,
  PostBindSummary,
  ProductPurchaseStatus,
} from '../../types';
import {
  getPaymentPlanForProduct,
  getPaymentTypeForProduct,
  getSelectedPaymentOptionPremium,
} from '../paymentoptions';

export const getPostBindQuestions = (state: RootStore): Questions => state.postbind.questions;

export const getQuestionPostBindNoDefault = (
  key: string,
  questions: Questions,
  questionKeyProp?: string,
): Question => {
  const questionKey = questionKeyProp || key.replace(REF_REGEX, '.<id>.');
  const question = questions[key] || questions[questionKey];

  return question;
};

export const getQuestionPostBind = (
  key: string,
  questions: Questions,
  questionKeyProp?: string,
): Question => {
  return (
    getQuestionPostBindNoDefault(key, questions, questionKeyProp) || (emptyQuestion as Question)
  );
};

export const getPostBindQuestion =
  (key: string, questionKey?: string): ((state: RootStore) => Question) =>
  (state: RootStore): Question =>
    getQuestionPostBind(key, getPostBindQuestions(state), questionKey);

export const getPostBindAnswers = (state: RootStore): Answers => state.postbind.answers;

export const getPostBindQuestionsKeysWithId = (state: RootStore): Questions => {
  const allQuestions = getPostBindQuestions(state);
  const idQuestions = Object.keys(allQuestions)
    .filter((key) => key.includes('<id>'))
    .reduce((obj, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = allQuestions[key];

      return obj;
    }, {} as Questions);

  return idQuestions;
};

export const getProductOfferCheckoutDetails = (
  state: RootStore,
  product: Product,
): CheckoutOfferDetails | undefined => {
  const offer = getOfferDetailsForProduct(state, product);
  const productName = getProductNameFromProduct(product);
  const policyStartDate = getProductPolicyStartDate(state, productName);

  return offer
    ? {
        selectedProduct: product,
        policyStartDate,
        offer,
      }
    : undefined;
};

export const getPaymentDetailsForProduct = (state: RootStore, product: Product): PaymentMethod => {
  const paymentType = getPaymentTypeForProduct(state, product);
  const paymentPlan = getPaymentPlanForProduct(state, product);
  const paymentPremium = getSelectedPaymentOptionPremium(state, product).totalPremium;

  return {
    paymentType,
    paymentPlan,
    paymentPremium,
  };
};

const canProductBeRetried = (product: Product, purchaseStatus: ProductPurchaseStatus): boolean =>
  PostBindRetryMessaging[product].some(
    (key) =>
      key.secondaryMessageKey &&
      purchaseStatus.errorReason?.includes(key.secondaryMessageKey) &&
      purchaseStatus.errorReason?.includes(key.messageKey || ''),
  );

export const getPostBindProductsSummary = (
  state: RootStore,
  selectedProducts: Product[],
): PostBindSummary[] => {
  const offers = getOffers(state);

  return selectedProducts.map((product) => {
    const offerCheckoutDetails = getProductOfferCheckoutDetails(state, product);
    const payment = getPaymentDetailsForProduct(state, product);

    const offerEligibility = offers[product]?.eligibility.statusCode;

    return {
      offerCheckoutDetails,
      offerEligibility,
      payment,
      product,
      productName: getProductNameFromProduct(product),
      getProductIsPurchased: (status: ProductPurchaseStatus) =>
        status.purchaseStatus === PurchaseStatusCode.COMPLETE ||
        offerEligibility === OfferStatusCode.PURCHASED,
      retryPurchase: (status: ProductPurchaseStatus) =>
        status.purchaseStatus === PurchaseStatusCode.ERROR && canProductBeRetried(product, status),
    };
  });
};

export const getHasPostBindSubmitError = (state: RootStore): boolean =>
  state.postbind.errors.length > 0;
