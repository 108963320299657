import type { Answers } from '@ecp/features/sales/shared/types';
import type { Questions } from '@ecp/types';

export const getUpdatedQuestionsFromAnswers = (
  answers: Answers,
  questions?: Questions,
): Questions | undefined => {
  if (!questions) return undefined;

  return Object.assign(
    {},
    ...Object.keys(questions).map((key) => {
      if (answers[key]) {
        return {
          [key]: {
            ...questions[key],
            value: answers[key],
          },
        };
      }

      return { [key]: questions[key] };
    }),
  );
};
