import { useEffect } from 'react';

import { useInteractionId } from '@ecp/utils/analytics/interaction-id';
import { initialize, setDimension, TrackingDimensions } from '@ecp/utils/analytics/tracking';
import { uppercase } from '@ecp/utils/common';
import { getOptimizelyUserId } from '@ecp/utils/flags';
import { location } from '@ecp/utils/routing';

import { env } from '@ecp/env';
import {
  getAgentId,
  getAgentPartnerProducerId,
  getAutoPrefillFlow,
  getCustomerId,
  getDalSessionId,
  getHomePrefillFlow,
  getInquiryId,
  getLineOfBusiness,
  getMembershipTier,
  getOfferDetailsForProduct,
  getOfferProductsSelected,
  getOfferSetId,
  getPartnerExperienceId,
  getPrimaryInsuredAddressInfo,
  getPrimaryInsuredPersonRef,
  getZipFromQueryString,
  useGetAddressFields,
  // getPrimaryInsuredStateCode,
} from '@ecp/features/sales/shared/store';
import { useSelector, useStore } from '@ecp/features/sales/shared/store/utils';
import { getReducedProductNameFromProduct } from '@ecp/features/shared/product';

/**
 * Keeps track of various variables in the app which it associates with the current Google Analytics session.
 */
export const useGoogleAnalytics = (): void => {
  const store = useStore();

  const expId = useSelector(getPartnerExperienceId) || env.static.expId;
  useEffect(() => {
    initialize(env.gtmId, env.runtimeEnv, expId);
    setDimensionsByQueryParams();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const interactionId = useInteractionId().get();
  useEffect(() => {
    if (interactionId) setDimension(TrackingDimensions.INTERACTION_ID, interactionId);
  }, [interactionId]);

  const userId = getOptimizelyUserId();
  useEffect(() => {
    if (userId) setDimension(TrackingDimensions.OPTIMIZELY_VISITOR_ID, userId);
  }, [userId]);

  const agencyNumber = useSelector(getAgentPartnerProducerId);
  useEffect(() => {
    if (agencyNumber) setDimension(TrackingDimensions.AGENCY_NUMBER, agencyNumber);
  }, [agencyNumber]);

  const agentId = useSelector(getAgentId);
  useEffect(() => {
    if (agentId) setDimension(TrackingDimensions.AGENT_ID, agentId);
  }, [agentId]);

  const partnerExpId = useSelector(getPartnerExperienceId);
  useEffect(() => {
    if (partnerExpId) {
      const envAndExperienceId = `${env.runtimeEnv}-${partnerExpId}`;
      setDimension(TrackingDimensions.ENV_AND_EXPERIENCE_ID, envAndExperienceId);
      setDimension(TrackingDimensions.EXPERIENCE_ID_CURRENT, partnerExpId);
    }
  }, [partnerExpId]);

  const dalSessionId = useSelector(getDalSessionId);
  useEffect(() => {
    if (dalSessionId) setDimension(TrackingDimensions.DALSESSION_ID, dalSessionId);
  }, [dalSessionId]);

  const inquiryId = useSelector(getInquiryId);
  useEffect(() => {
    if (inquiryId) setDimension(TrackingDimensions.INQUIRY_ID, inquiryId);
  }, [inquiryId]);

  // IMP: Notes from Analytics team:
  // The Initial Lob and Current Lob should be same at all times from UI perspective.
  const lineOfBusiness = useSelector(getLineOfBusiness);
  useEffect(() => {
    if (lineOfBusiness) {
      setDimension(TrackingDimensions.LOB_INITIAL, lineOfBusiness);
      setDimension(TrackingDimensions.LOB_CURRENT, lineOfBusiness);
    }
  }, [lineOfBusiness]);

  const customerId = useSelector(getCustomerId);
  useEffect(() => {
    if (customerId) setDimension(TrackingDimensions.CUSTOMER_ID, customerId);
  }, [customerId]);

  const primaryInsuredPersonRef = useSelector(getPrimaryInsuredPersonRef).split('.')[1];
  useEffect(() => {
    if (primaryInsuredPersonRef) {
      setDimension(TrackingDimensions.DRIVER_REF_PRIMARY, primaryInsuredPersonRef);
    }
  }, [primaryInsuredPersonRef]);

  const addressFields = useGetAddressFields();
  const state = addressFields.address.state.props.value;
  const address = useSelector(getPrimaryInsuredAddressInfo);
  const zipFromQueryString = useSelector(getZipFromQueryString);
  const zipcode = address.zipcode || zipFromQueryString;
  // const {
  //   address: { line1, line2, city, state, zipcode },
  //   primaryInsuredAddressLock,
  // } = fields;
  useEffect(() => {
    if (state) setDimension(TrackingDimensions.STATE, state);
    if (zipcode) setDimension(TrackingDimensions.ZIP, zipcode);
  }, [zipcode, state]);

  const autoPrefillFlow = useSelector(getAutoPrefillFlow);
  useEffect(() => {
    if (autoPrefillFlow) setDimension(TrackingDimensions.PREFILL_FLOW.AUTO, autoPrefillFlow);
  }, [autoPrefillFlow]);

  const homePrefillFlow = useSelector(getHomePrefillFlow);
  useEffect(() => {
    if (homePrefillFlow) setDimension(TrackingDimensions.PREFILL_FLOW.PROPERTY, homePrefillFlow);
  }, [homePrefillFlow]);

  const membershipTier = useSelector(getMembershipTier);
  useEffect(() => {
    if (membershipTier) setDimension(TrackingDimensions.MEMBERSHIP_TIER, membershipTier);
  }, [membershipTier]);

  const offerSetId = useSelector(getOfferSetId);
  useEffect(() => {
    if (offerSetId) setDimension(TrackingDimensions.OFFER_SET_ID, offerSetId);
  }, [offerSetId]);

  const offerProductsSelected = useSelector(getOfferProductsSelected);
  useEffect(() => {
    if (offerProductsSelected) {
      offerProductsSelected.forEach((product) => {
        const reducedProductName = uppercase(getReducedProductNameFromProduct(product));

        setDimension(TrackingDimensions.PRODUCT[reducedProductName], product);

        const offerDetails = getOfferDetailsForProduct(store.getState(), product);
        setDimension(
          TrackingDimensions.BINDABLE_QUOTE[reducedProductName],
          offerDetails?.isEstimated,
        );

        if (offerDetails?.details.quoteNumber) {
          setDimension(
            TrackingDimensions.QUOTE_NUMBER[reducedProductName],
            offerDetails?.details.quoteNumber,
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerProductsSelected]);
};

const setDimensionsByQueryParams = (): void => {
  const query = location.search;

  if (query.redirectid) setDimension(TrackingDimensions.REDIRECT_ID, query.redirectid);
  if (query.refid) setDimension(TrackingDimensions.REF_ID, query.refid);

  // TODO emailUUID and all other query string params need to be lowercased across the app
  if (query.emailUUID) setDimension(TrackingDimensions.EMAIL_UUID, query.emailUUID);
};
