import { useCallback, useEffect, useState } from 'react';

import { FormLabel, Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import {
  isMasked,
  maskPaymentAccountNumber,
  resetMaskedPaymentAccountNumber,
} from '@ecp/utils/common';
import { restrictSpaceChars } from '@ecp/utils/web';

import { GridItem } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { RadioGroupWithOptions, Select, TextField } from '@ecp/features/sales/shared/components';
import { getPniPersonInfo, useDeltaField, useField } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product, ProductName } from '@ecp/features/shared/product';

import { getPaymentTypeForProduct } from '../../../state/paymentoptions';
import {
  ensureEFTPayToken,
  setReccuringPayAccountNumber,
  setReccuringPayAccountType,
  setReccuringPayAccountUse,
  setReccuringPayRoutingNumber,
} from '../../../state/purchase';
import { useStyles } from './RecurringPaymentSetUp.styles';

export interface RecurringPaymentSetUpProps {
  selectedProduct: Product;
  coverageType: ProductName;
  hasToken?: boolean;
}

export const RecurringPaymentSetUp: React.FC<RecurringPaymentSetUpProps> = (props) => {
  const { classes, cx } = useStyles();
  const { selectedProduct, coverageType, hasToken } = props;

  const dispatch = useDispatch();

  const routingNumber = useField(`static.checkout.${coverageType}.recurringPayment.routingNumber`);
  const accountNumber = useField(`static.checkout.${coverageType}.recurringPayment.accountNumber`);
  const accountUse = useField(`static.checkout.${coverageType}.recurringPayment.accountUse`);
  const accountType = useField(`static.checkout.${coverageType}.recurringPayment.accountType`);

  const pni = useSelector(getPniPersonInfo);
  const productPaymentType = useSelector((state: RootStore) =>
    getPaymentTypeForProduct(state, selectedProduct),
  );

  const autoPay = useField(`discount.${coverageType}.autoPay`);
  const autoPayType = useDeltaField(`coverageOption.${selectedProduct}`, 'autoPayType');
  const useInitialPayPaymentInd = useField(
    `static.checkout.${coverageType}.useInitialPayPaymentInd`,
  );
  const recurringPaymentAmount = useField(`static.checkout.${coverageType}.recurringPaymentAmount`);

  const displayBankDetails =
    productPaymentType !== 'EFT' || (useInitialPayPaymentInd && !useInitialPayPaymentInd.value);
  const useInitialPayPaymentIndLabel = `${pni?.firstName} is signed up for automatic recurring payments. Does ${pni?.firstName} want to use the same bank account as the initial payment for the Auto premium?`;
  const bankDetailsLabel =
    productPaymentType === 'EFT'
      ? `What bank account does ${pni?.firstName} want to use for automatic recurring payments?`
      : `${pni?.firstName} is signed up for automatic recurring payments, which requires a bank account. What bank account does ${pni?.firstName} want to use for automatic recurring payments?`;

  const accountNumberValue = accountNumber.props.value;
  const [accNumDisplayValue, setAccNumDisplayValue] = useState<string | undefined>(
    accountNumberValue ? maskPaymentAccountNumber(accountNumberValue) : undefined,
  );

  useEffect(() => {
    if (
      routingNumber.props.error ||
      accountNumber.props.error ||
      accountUse.props.error ||
      accountType.props.error
    )
      return;
    if (
      !(
        routingNumber.props.value &&
        accountNumber.props.value &&
        accountUse.props.value &&
        accountType.props.value
      )
    )
      return;
    dispatch(
      ensureEFTPayToken({
        product: selectedProduct,
        accountType: accountType.props.value,
        accountUse: accountUse.props.value,
        accountNumber: accountNumber.props.value,
        routingNumber: routingNumber.props.value,
        isReccuringPayment: true,
      }),
    );
  }, [
    accountNumber.props.error,
    routingNumber.props.error,
    accountNumber.props.value,
    routingNumber.props.value,
    dispatch,
    selectedProduct,
    accountUse.props.error,
    accountUse.props.value,
    accountType.props.error,
    accountType.props.value,
  ]);

  useInitValues({
    [useInitialPayPaymentInd.key]: false,
  });
  useAddFields({
    [useInitialPayPaymentInd.key]: useInitialPayPaymentInd,
    [recurringPaymentAmount.key]: recurringPaymentAmount,
    [routingNumber.key]: routingNumber,
    [accountNumber.key]: accountNumber,
    [accountUse.key]: accountUse,
    [accountType.key]: accountType,
  });

  const onAccountUseChange = useCallback(
    (value: string) => {
      dispatch(setReccuringPayAccountUse({ product: selectedProduct, accountUse: value }));
      accountUse.validateUpdateAndPatch(value);
    },
    [dispatch, selectedProduct, accountUse],
  );

  const onAccountTypeChange = useCallback(
    (value: string) => {
      dispatch(setReccuringPayAccountType({ product: selectedProduct, accountType: value }));
      accountType.validateUpdateAndPatch(value);
    },
    [dispatch, selectedProduct, accountType],
  );

  const onRoutingNumberChange = useCallback(
    (value: string) => {
      const valueTrimmed = value.trim();
      dispatch(
        setReccuringPayRoutingNumber({ product: selectedProduct, routingNumber: valueTrimmed }),
      );
      routingNumber.validateUpdateAndPatch(valueTrimmed);
    },
    [dispatch, selectedProduct, routingNumber],
  );

  const onAccountNumberChange = useCallback(
    (value: string) => {
      let valueTrimmed = value.trim();

      if (isMasked(valueTrimmed)) {
        if (accNumDisplayValue && valueTrimmed.length === accNumDisplayValue?.length) return; // user clicks into field and leaves
        valueTrimmed = resetMaskedPaymentAccountNumber(valueTrimmed, accNumDisplayValue);
      }
      setAccNumDisplayValue(undefined);
      dispatch(
        setReccuringPayAccountNumber({ product: selectedProduct, accountNumber: valueTrimmed }),
      );
      accountNumber.validateUpdateAndPatch(valueTrimmed);
    },
    [accountNumber, dispatch, selectedProduct, accNumDisplayValue],
  );

  const onAccountNumberBlur = useCallback((event: React.FocusEvent<HTMLInputElement>): void => {
    setAccNumDisplayValue(maskPaymentAccountNumber(event.target.value.trim()));
  }, []);

  const onAccountNumberFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>): void => {
      if (isMasked(event.target.value.trim())) setAccNumDisplayValue(accountNumberValue);
    },
    [accountNumberValue],
  );

  const onRecurringPaymentAccountSelectionComplete = useCallback(
    (value: string) => {
      useInitialPayPaymentInd.validateUpdateAndPatch(value);
      recurringPaymentAmount.validateUpdateAndPatch('');
      setAccNumDisplayValue(undefined);
      dispatch(setReccuringPayAccountNumber({ product: selectedProduct, accountNumber: '' }));
      accountNumber.validateUpdateAndPatch('');
      dispatch(setReccuringPayRoutingNumber({ product: selectedProduct, routingNumber: '' }));
      routingNumber.validateUpdateAndPatch('');
      dispatch(setReccuringPayAccountUse({ product: selectedProduct, accountUse: '' }));
      accountUse.validateUpdateAndPatch('');
      dispatch(setReccuringPayAccountType({ product: selectedProduct, accountType: '' }));
      accountType.validateUpdateAndPatch('');
    },
    [
      accountNumber,
      accountType,
      accountUse,
      dispatch,
      recurringPaymentAmount,
      routingNumber,
      selectedProduct,
      useInitialPayPaymentInd,
    ],
  );

  const onRecurringPaymentAmountOptionChange = useCallback(
    (value: string) => {
      recurringPaymentAmount.validateUpdateAndPatch(value);
    },
    [recurringPaymentAmount],
  );

  if (!autoPay || !autoPay.value || !autoPayType || autoPayType.value !== 'MY_ACCOUNT_BILLING')
    return null;

  return (
    <Grid container className={classes.sameAccountLabel}>
      {productPaymentType === 'EFT' && (
        <GridItem topSpacing='md'>
          <RadioGroupWithOptions
            {...useInitialPayPaymentInd.props}
            label={useInitialPayPaymentIndLabel}
            name='RecurringPaymentAccountSelection'
            trackingName='RecurringPaymentAccountSelection'
            variant='yesNoButton'
            actionOnComplete={onRecurringPaymentAccountSelectionComplete}
          />
        </GridItem>
      )}

      {/* Display when same recurring payment method as the initial payment or when the intial payment is not EFT(Credit card) */}
      {displayBankDetails && (
        <>
          <GridItem topSpacing='md' className={classes.bankPaymentSection}>
            {bankDetailsLabel}
          </GridItem>
          <Grid item xs={12} ignore='true' className={classes.bankPaymentFieldsContainer}>
            {!hasToken && (
              <FormLabel component='legend' focused={false} error>
                Please re-enter account information
              </FormLabel>
            )}
            <Grid container>
              <Grid item xs={12} md={6} className={classes.columnLeft}>
                <Select
                  {...accountUse.props}
                  label='Account Use'
                  data-testid='accountUse'
                  id={`accountUse-${selectedProduct}`}
                  value={accountUse.value}
                  trackingName='eft_account_Use'
                  trackingLabel={GoogleAnalyticsLabels.REDACTED}
                  actionOnChange={onAccountUseChange}
                />
              </Grid>
              <Grid item xs={12} md={6} className={cx(classes.columnRight, classes.accountNumber)}>
                <Select
                  {...accountType.props}
                  label='Account Type'
                  data-testid='accountType'
                  id={`accountType-${selectedProduct}`}
                  value={accountType.value}
                  trackingName='eft_account_Type'
                  trackingLabel={GoogleAnalyticsLabels.REDACTED}
                  actionOnChange={onAccountTypeChange}
                />
              </Grid>

              <Grid item xs={12} md={6} className={classes.columnLeft}>
                <TextField
                  {...routingNumber.props}
                  inputMode='numeric'
                  pattern='[0-9]*'
                  label='Routing number'
                  ariaLabel='Routing number'
                  data-testid='routingNumber'
                  id={`RoutingNumber-${selectedProduct}`}
                  value={routingNumber.value}
                  trackingName='eft_routing_number'
                  trackingLabel={GoogleAnalyticsLabels.REDACTED}
                  actionOnChange={onRoutingNumberChange}
                  maxLength={9}
                  onKeyDown={restrictSpaceChars}
                />
              </Grid>
              <Grid item xs={12} md={6} className={cx(classes.columnRight, classes.accountNumber)}>
                <TextField
                  {...accountNumber.props}
                  inputMode='numeric'
                  pattern='[0-9]*'
                  label='Account number'
                  ariaLabel='Account number'
                  id={`AccountNumber-${selectedProduct}`}
                  data-testid='accNumber'
                  value={accNumDisplayValue ?? accountNumber.value}
                  trackingName='eft_account_number'
                  trackingLabel={GoogleAnalyticsLabels.REDACTED}
                  onBlur={onAccountNumberBlur}
                  actionOnChange={onAccountNumberChange}
                  onFocus={onAccountNumberFocus}
                  maxLength={17}
                  onKeyDown={restrictSpaceChars}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {/* Display irrespective of what the initial payment method is */}
      <Grid container className={classes.recurringPaymentAmount} spacing={1}>
        <GridItem xl={6} xs={12}>
          <Select
            {...recurringPaymentAmount.props}
            label='Recurring Payment Amount'
            data-testid='recurringPaymentAmount'
            id={`recurringPaymentAmount-${selectedProduct}`}
            value={recurringPaymentAmount.value}
            trackingName='recurring_PaymentAmount_Option'
            trackingLabel={GoogleAnalyticsLabels.REDACTED}
            actionOnChange={onRecurringPaymentAmountOptionChange}
          />
        </GridItem>
      </Grid>
    </Grid>
  );
};
