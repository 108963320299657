import { createAction } from '@reduxjs/toolkit';

import type {
  Dependencies,
  OffersGetResponse,
  OffersPostResponse,
  OffersPutResponse,
  OffersRecallResponse,
  OffersResponseOnPostInquiry,
} from './types';

export const deleteOffers = createAction('@offers/delete');
export const setDependenciesSnapshot = createAction<Dependencies>('@offers/dependenciesSnapshot');
export const setOffersApiGetSuccess = createAction<OffersGetResponse>('@offers/apiGetSuccess');
export const setOffersApiPostSuccess = createAction<OffersPostResponse>('@offers/apiPostSuccess');
export const setOffersApiPutSuccess = createAction<OffersPutResponse>('@offers/apiPutSuccess');
export const setOffersResponseFromPostInquirySuccess = createAction<OffersResponseOnPostInquiry>(
  '@offers/setOffersResponseFromPostInquirySuccess',
);
export const setOffersApiRecallFail = createAction<string>('@offers/apiRecallFail');
export const setOffersApiRecallSuccess = createAction<OffersRecallResponse>(
  '@offers/apiRecallSuccess',
);
export const setProcessingRecalc = createAction<boolean>('@offers/setProcessingRecalc');
export const setRecalled = createAction<boolean>('@offers/setRecalled');
export const setShouldRecalc = createAction<boolean>('@offers/recalculate');
export const setBlobRetrieveFailed = createAction<boolean>('@offers/setBlobRetrieveFailed');

export type OffersActions = ReturnType<
  | typeof deleteOffers
  | typeof setDependenciesSnapshot
  | typeof setOffersApiGetSuccess
  | typeof setOffersApiPostSuccess
  | typeof setOffersApiPutSuccess
  | typeof setOffersResponseFromPostInquirySuccess
  | typeof setOffersApiRecallFail
  | typeof setOffersApiRecallSuccess
  | typeof setProcessingRecalc
  | typeof setRecalled
  | typeof setShouldRecalc
  | typeof setBlobRetrieveFailed
>;
