import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PageHeader' })((theme) => ({
  root: {
    ...theme.mixins.form,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 40,
    width: '100%',
    padding: '0 30px',
    [theme.breakpoints.down('lg')]: {
      marginTop: 24,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 15px',
    },
  },
  banner: {
    // Increase specificity with `&&` otherwise media queries in the root class take precedence
    '&&': {
      backgroundColor: theme.palette.primary.main,
      justifyContent: 'center',
      margin: 0,
      padding: 30,
      [theme.breakpoints.down('md')]: {
        padding: 15,
      },
    },
  },
  subTitle: {
    marginTop: 5,
  },
  compact: {
    padding: 0,
  },
}));
