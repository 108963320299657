import { memo, useCallback } from 'react';

import { Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { RadioGroupWithOptions, Select } from '@ecp/features/sales/shared/components';
import { usePostBindField } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, OptionProps } from '@ecp/features/sales/shared/types';
import { clearFields } from '@ecp/features/sales/shared/utils/web';

import {
  deleteKeyFromPostBindAnswers,
  deleteKeysFromPostBindAnswers,
  updatePostBindAnswers,
} from '../../../state';
import { VehicleLienHolderOtherCompanyQuestion } from './VehicleLienHolderOtherCompanyQuestion';
import { useStyles } from './VehicleLienHolderQuestion.styles';

interface Props {
  year: string;
  make: string;
  model: string;
  vehicleRef: string;
  leaseHolderRef: string;
  lienHolderRef: string;
}

export const VehicleLienHolderQuestion: React.FC<Props> = memo((props) => {
  const { classes } = useStyles();

  const { year, make, model, vehicleRef, leaseHolderRef, lienHolderRef } = props;

  const vehicleStatusField = usePostBindField(`${vehicleRef}.status`);

  // FINANCE
  const vehicleFinanceOptions = usePostBindField(`${lienHolderRef}.lienCompanyNameOrigin`);
  const financeCompanyName = String(vehicleFinanceOptions.value || '');

  // For other Option -> FINANCE
  const otherFinanceCompanyName = usePostBindField(`${lienHolderRef}.address.companyName`);
  const otherFinanceCompanyZip = usePostBindField(`${lienHolderRef}.address.zipcode`);
  const otherFinanceCompanyState = usePostBindField(`${lienHolderRef}.address.state`);
  const otherFinanceCompanyCity = usePostBindField(`${lienHolderRef}.address.city`);
  const otherFinanceCompanyLine1 = usePostBindField(`${lienHolderRef}.address.line1`);
  const otherFinanceCompanyLine2 = usePostBindField(`${lienHolderRef}.address.line2`);

  // LEASE
  const vehicleLeaseOptions = usePostBindField(`${leaseHolderRef}.leaseCompanyNameOrigin`);
  const leaseCompanyName = String(vehicleLeaseOptions.value || '');

  // For other Option -> LEASE
  const otherLeaseCompanyName = usePostBindField(`${leaseHolderRef}.address.companyName`);
  const otherLeaseCompanyZip = usePostBindField(`${leaseHolderRef}.address.zipcode`);
  const otherLeaseCompanyState = usePostBindField(`${leaseHolderRef}.address.state`);
  const otherLeaseCompanyCity = usePostBindField(`${leaseHolderRef}.address.city`);
  const otherLeaseCompanyLine1 = usePostBindField(`${leaseHolderRef}.address.line1`);
  const otherLeaseCompanyLine2 = usePostBindField(`${leaseHolderRef}.address.line2`);

  useAddFields({
    [`${lienHolderRef}.lienCompanyNameOrigin`]: vehicleFinanceOptions,
    [`${leaseHolderRef}.leaseCompanyNameOrigin`]: vehicleLeaseOptions,
  });

  useAddFields({
    [`${lienHolderRef}.address.companyName`]: otherFinanceCompanyName,
    [`${lienHolderRef}.address.zipcode`]: otherFinanceCompanyZip,
    [`${lienHolderRef}.address.state`]: otherFinanceCompanyState,
    [`${lienHolderRef}.address.city`]: otherFinanceCompanyCity,
    [`${lienHolderRef}.address.line1`]: otherFinanceCompanyLine1,
    [`${lienHolderRef}.address.line2`]: otherFinanceCompanyLine2,
  });

  useAddFields({
    [`${leaseHolderRef}.address.companyName`]: otherLeaseCompanyName,
    [`${leaseHolderRef}.address.zipcode`]: otherLeaseCompanyZip,
    [`${leaseHolderRef}.address.state`]: otherLeaseCompanyState,
    [`${leaseHolderRef}.address.city`]: otherLeaseCompanyCity,
    [`${leaseHolderRef}.address.line1`]: otherLeaseCompanyLine1,
    [`${leaseHolderRef}.address.line2`]: otherLeaseCompanyLine2,
  });

  const dispatch = useDispatch();

  // Reset fields when vehicle status changes
  // Need only certin hook refs to run this
  const handleVehicleStatusChange = useCallback(
    (value: AnswerValue): void => {
      vehicleStatusField.props.actionOnComplete(value);
      if (value === 'OWN') {
        clearFields(
          vehicleFinanceOptions,
          otherFinanceCompanyName,
          otherFinanceCompanyZip,
          otherFinanceCompanyState,
          otherFinanceCompanyCity,
          otherFinanceCompanyLine1,
          otherFinanceCompanyLine2,
          vehicleLeaseOptions,
          otherLeaseCompanyName,
          otherLeaseCompanyZip,
          otherLeaseCompanyState,
          otherLeaseCompanyCity,
          otherLeaseCompanyLine1,
          otherLeaseCompanyLine2,
        );
        dispatch(deleteKeyFromPostBindAnswers(`${vehicleRef}.leaseHolder.ref`));
        dispatch(deleteKeyFromPostBindAnswers(`${vehicleRef}.lienHolder.ref`));
        dispatch(deleteKeysFromPostBindAnswers(lienHolderRef));
        dispatch(deleteKeysFromPostBindAnswers(leaseHolderRef));
      } else if (value === 'FINANCE') {
        // Create ref to lienHolder
        dispatch(updatePostBindAnswers({ [`${vehicleRef}.lienHolder.ref`]: lienHolderRef }));
        clearFields(
          vehicleLeaseOptions,
          otherLeaseCompanyName,
          otherLeaseCompanyZip,
          otherLeaseCompanyState,
          otherLeaseCompanyCity,
          otherLeaseCompanyLine1,
          otherLeaseCompanyLine2,
        );
        dispatch(deleteKeyFromPostBindAnswers(`${vehicleRef}.leaseHolder.ref`));
        dispatch(deleteKeysFromPostBindAnswers(leaseHolderRef));
      } else if (value === 'LEASE') {
        // Create ref to leaseHolder
        dispatch(updatePostBindAnswers({ [`${vehicleRef}.leaseHolder.ref`]: leaseHolderRef }));
        clearFields(
          vehicleFinanceOptions,
          otherFinanceCompanyName,
          otherFinanceCompanyZip,
          otherFinanceCompanyState,
          otherFinanceCompanyCity,
          otherFinanceCompanyLine1,
          otherFinanceCompanyLine2,
        );
        dispatch(deleteKeyFromPostBindAnswers(`${vehicleRef}.lienHolder.ref`));
        dispatch(deleteKeysFromPostBindAnswers(lienHolderRef));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, vehicleStatusField, lienHolderRef, leaseHolderRef, vehicleRef],
  );

  useAddConditionValues({
    conditionalFields: [
      otherFinanceCompanyName,
      otherFinanceCompanyZip,
      otherFinanceCompanyState,
      otherFinanceCompanyCity,
      otherFinanceCompanyLine1,
    ],
    isExcluded: () => financeCompanyName !== 'OTHER',
    isRequiredOverride: () => true,
  });

  useAddConditionValues({
    conditionalFields: [
      otherLeaseCompanyName,
      otherLeaseCompanyZip,
      otherLeaseCompanyState,
      otherLeaseCompanyCity,
      otherLeaseCompanyLine1,
    ],
    isExcluded: () => leaseCompanyName !== 'OTHER',
    isRequiredOverride: () => true,
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <p className={classes.header}>
            {year} {make} {model}
          </p>
        </Grid>
        <GridItem xs={12} className={classes.radioContainer}>
          <RadioGroupWithOptions
            {...(vehicleStatusField.props as OptionProps)}
            id={`VehicleStatus-${vehicleRef}`}
            actionOnComplete={handleVehicleStatusChange}
            trackingName='vehicle_lienholder_button'
          />
        </GridItem>
        <GridItem xs={12} topSpacing='sm'>
          {vehicleStatusField.value === 'FINANCE' && (
            <>
              <Select
                {...(vehicleFinanceOptions.props as OptionProps)}
                id={`FinanceOptions-${vehicleRef}`}
                label='Loan/lease company name'
                name='FinanceOptions'
                inputButtonAriaLabel='Finance company name'
                trackingName='loan_lease_company_selection'
              />
              {financeCompanyName === 'OTHER' && (
                <VehicleLienHolderOtherCompanyQuestion
                  id={`otherFinanceCompany-${vehicleRef}`}
                  otherCompanyName={otherFinanceCompanyName}
                  otherCompanyAddressLine1={otherFinanceCompanyLine1}
                  otherCompanyAddressLine2={otherFinanceCompanyLine2}
                  otherCompanyCity={otherFinanceCompanyCity}
                  otherCompanyState={otherFinanceCompanyState}
                  otherCompanyZip={otherFinanceCompanyZip}
                />
              )}
            </>
          )}
          {vehicleStatusField.value === 'LEASE' && (
            <>
              <Select
                {...(vehicleLeaseOptions.props as OptionProps)}
                id={`LeaseOptions-${vehicleRef}`}
                label='Loan/lease company name'
                name='LeaseOptions'
                inputButtonAriaLabel='Lease company name'
                trackingName='loan_lease_company_selection'
              />
              {leaseCompanyName === 'OTHER' && (
                <VehicleLienHolderOtherCompanyQuestion
                  id={`otherLeaseCompany-${vehicleRef}`}
                  otherCompanyName={otherLeaseCompanyName}
                  otherCompanyAddressLine1={otherLeaseCompanyLine1}
                  otherCompanyAddressLine2={otherLeaseCompanyLine2}
                  otherCompanyCity={otherLeaseCompanyCity}
                  otherCompanyState={otherLeaseCompanyState}
                  otherCompanyZip={otherLeaseCompanyZip}
                />
              )}
            </>
          )}
        </GridItem>
      </Grid>
    </Grid>
  );
});
