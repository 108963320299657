import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PropertyDetailsFormQuestions' })((theme) => ({
  root: {},
  sectionHeader: {
    paddingTop: 60,
  },
  smSelectionField: {
    '& .MuiInputBase-root': {
      maxWidth: 140,
    },
  },
  mdSelectionField: {
    '& .MuiInputBase-root': {
      maxWidth: 425,
    },
  },
  topSectionContainer: {
    marginTop: 15,
  },
  sectionContainer: {
    marginBottom: 40,
    minWidth: 800,
  },
  smallSectionContainer: {
    marginBottom: 15,
  },
  selectContainer: {
    '& .MuiFormLabel-root': {
      ...theme.typography.body4,
      marginBottom: 15,
    },
  },
}));
