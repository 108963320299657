import { env } from '@ecp/env';
import { type PaymentPlan, RetreivalErrorReason } from '@ecp/features/sales/shared/constants';
import type { LineOfBusiness, Product } from '@ecp/features/shared/product';
import { partner } from '@ecp/partners';
import type { BaseMetadataCollection } from '@ecp/types';

export interface Metadata {
  PolicySummaryMetadata: Record<Product, BaseMetadataCollection>;
  QuoteSummaryMetadata: Record<Product, BaseMetadataCollection>;
  TopCoveragesMetadata: Record<Product, BaseMetadataCollection>;
  RetrieveQuoteErrorMetadata: { [key: string]: string };
  DefaultPaymentPlanMetadata: Partial<Record<LineOfBusiness, PaymentPlan>>;
  RetrievalErrors: { [key: string]: Record<string, string> };
  denyIndicativeQuoteRetrieve?: boolean;
  DisplayErrorRecallMessage: { recallErrorMessage: string };
}

export const metadata: Metadata = {
  PolicySummaryMetadata: {
    'amfam.auto': {
      // V4 Midvale matching the keys to be the same as connect.auto
      'coverages.policy.BodilyInjury': {
        title: 'Bodily injury limit',
      },
      'coverages.policy.PropertyDamage': {
        title: 'Property damage limit',
      },
      // V3 Midvale
      'coverages.policy.bodilyInjury': {
        title: 'Bodily injury limit',
      },
      'coverages.policy.propertyDamage': {
        title: 'Property damage limit',
      },
    },
    'connect.auto': {
      'coverages.policy.BodilyInjuryPropertyDamage': {
        title: 'Bodily injury/property damage',
      },
      'coverages.policy.BodilyInjury': {
        title: 'Bodily injury',
      },
      'coverages.policy.PropertyDamage': {
        title: 'Property damage',
      },
    },
    'amfam-adv.auto': {
      'coverages.policy.BodilyInjuryPropertyDamage': {
        title: 'Bodily injury/property damage',
      },
      'coverages.policy.BodilyInjury': {
        title: 'Bodily injury',
      },
      'coverages.policy.PropertyDamage': {
        title: 'Property damage',
      },
    },
    'homesite.home': {
      'coverages.dwelling': {
        title: 'Dwelling coverage',
      },
      'deductibles.deductible': {
        title: 'Standard deductible',
      },
    },
    'amfam-adv.home': {
      'coverages.dwelling': {
        title: 'Dwelling coverage',
      },
      'deductibles.deductible': {
        title: 'Standard deductible',
      },
    },
    'homesite.renters': {
      'coverages.lostOfUse': {
        title: 'Temporary housing expense',
      },
      'coverages.personalProperty': {
        title: 'Personal possessions',
      },
      'coverages.personalLiability': {
        title: 'Personal liability',
      },
    },
    'amfam-adv.renters': {
      'coverages.lostOfUse': {
        title: 'Temporary housing expense',
      },
      'coverages.personalProperty': {
        title: 'Personal possessions',
      },
      'coverages.personalLiability': {
        title: 'Personal liability',
      },
    },
    'connect-boost.auto': {},
  },
  QuoteSummaryMetadata: {
    'amfam.auto': {
      // V4 Midvale matching the keys to be the same as connect.auto
      'coverages.policy.BodilyInjury': {
        title: 'Bodily injury limit',
      },
      'coverages.policy.MedicalExpense': {
        title: 'Medical expense',
      },
      'coverages.policy.PropertyDamage': {
        title: 'Property damage limit',
      },
      'coverages.policy.UninsuredMotorist': {
        title: 'Uninsured motorist',
      },
      // V3 Midvale
      'coverages.policy.bodilyInjury': {
        title: 'Bodily injury limit',
      },
      'coverages.policy.medical': {
        title: 'Medical expense',
      },
      'coverages.policy.propertyDamage': {
        title: 'Property damage limit',
      },
      'coverages.policy.uninsuredMotorist': {
        title: 'Uninsured motorist',
      },
    },
    'amfam-adv.auto': {
      'coverages.policy.bodilyInjury': {
        title: 'Bodily injury limit',
      },
      'coverages.policy.propertyDamage': {
        title: 'Property damage limit',
      },
      'coverages.policy.uninsuredMotorist': {
        title: 'Uninsured motorist',
      },
      'coverages.policy.uninsuredUnderinsuredMotorist': {
        title: 'Uninsured or Underinsured motorist',
      },
    },
    'connect.auto': {
      'coverages.policy.BodilyInjury': {
        title: 'Bodily injury limit',
      },
      'coverages.policy.PropertyDamage': {
        title: 'Property damage limit',
      },
      // TODO - Remove these Text fields after v3 retires
      'coverages.policy.BodilyInjuryText': {
        title: 'Bodily injury limit',
      },
      'coverages.policy.PropertyDamageText': {
        title: 'Property damage limit',
      },
      'coverages.policy.UninsuredMotorist': {
        title: 'Uninsured motorist',
      },
      'coverages.policy.UninsuredMotoristUnderInsuredMotorist': {
        title: 'Uninsured or underinsured motorist',
      },
      'coverages.policy.UninsuredMotoristUnderInsuredMotoristUMPD': {
        title: 'Uninsured/underinsured',
      },
      'coverages.policy.MedicalExpense': {
        title: 'Medical expense',
      },
    },
    'homesite.home': {
      'coverages.dwelling': {
        title: 'Dwelling coverage',
      },
      'coverages.increasedCoverageC': {
        title: 'Personal possessions',
      },
      'coverages.personalLiability': {
        title: 'Personal liability',
      },
      'deductibles.deductible': {
        title: 'Standard deductible',
      },
    },
    'amfam-adv.home': {
      'coverages.dwelling': {
        title: 'Dwelling coverage',
      },
      'coverages.increasedCoverageC': {
        title: 'Personal possessions',
      },
      'coverages.personalLiability': {
        title: 'Personal liability',
      },
      'deductibles.deductible': {
        title: 'Standard deductible',
      },
    },
    'homesite.renters': {
      'coverages.lostOfUse': {
        title: 'Temporary housing expense',
      },
      'coverages.personalProperty': {
        title: 'Personal possessions',
      },
      'coverages.personalLiability': {
        title: 'Personal liability',
      },
    },
    'amfam-adv.renters': {},
    'connect-boost.auto': {},
  },
  TopCoveragesMetadata: {
    'homesite.renters': {
      'coverages.personalProperty': {
        title: 'Personal possessions',
      },
      'coverages.lostOfUse': {
        title: 'Temporary housing expense',
      },
      'coverages.personalLiability': {
        title: 'Personal liability',
      },
      'coverages.medicalPaymentsToOthers': {
        title: 'Medical payments',
      },
      'deductibles.deductible': {
        title: 'Deductible',
      },
    },
    'amfam-adv.renters': {},
    'connect-boost.auto': {},
    'amfam.auto': {
      // V4 Midvale matching the keys to be the same as connect.auto
      'coverages.policy.BodilyInjury': {
        title: 'Bodily injury limit',
      },
      'coverages.policy.MedicalExpense': {
        title: 'Medical expense',
      },
      'coverages.policy.PropertyDamage': {
        title: 'Property damage limit',
      },
      'coverages.policy.UninsuredMotorist': {
        title: 'Uninsured motorist',
      },
      // v3 Midvale
      'coverages.policy.bodilyInjury': {
        title: 'Bodily injury limit',
      },
      'coverages.policy.medical': {
        title: 'Medical expense',
      },
      'coverages.policy.propertyDamage': {
        title: 'Property damage limit',
      },
      'coverages.policy.uninsuredMotorist': {
        title: 'Uninsured motorist',
      },
    },
    'amfam-adv.auto': {
      'coverages.policy.bodilyInjury': {
        title: 'Bodily injury limit',
      },
      'coverages.policy.propertyDamage': {
        title: 'Property damage limit',
      },
      'coverages.policy.uninsuredMotorist': {
        title: 'Uninsured motorist',
      },
      'coverages.policy.uninsuredUnderinsuredMotorist': {
        title: 'Uninsured or Underinsured motorist',
      },
    },
    'connect.auto': {
      'coverages.policy.BodilyInjury': {
        title: 'Bodily injury limit',
      },
      'coverages.policy.PropertyDamage': {
        title: 'Property damage limit',
      },
      // TODO - Remove these Text fields after v3 retires
      'coverages.policy.BodilyInjuryText': {
        title: 'Bodily injury limit',
      },
      'coverages.policy.PropertyDamageText': {
        title: 'Property damage limit',
      },
      'coverages.policy.UninsuredMotorist': {
        title: 'Uninsured motorist',
      },
      'coverages.policy.UninsuredMotoristUnderInsuredMotorist': {
        title: 'Uninsured or underinsured motorist',
      },
      'coverages.policy.UninsuredMotoristUnderInsuredMotoristUMPD': {
        title: 'Uninsured/underinsured',
      },
      'coverages.policy.MedicalExpense': {
        title: 'Medical expense',
      },
    },
    'homesite.home': {
      'coverages.dwelling': {
        title: 'Dwelling coverage',
      },
      'coverages.increasedCoverageC': {
        title: 'Personal possessions',
      },
      'coverages.personalLiability': {
        title: 'Personal liability',
      },
      'deductibles.deductible': {
        title: 'Standard deductible',
      },
    },
    'amfam-adv.home': {
      'coverages.dwelling': {
        title: 'Dwelling coverage',
      },
      'coverages.increasedCoverageC': {
        title: 'Personal possessions',
      },
      'coverages.personalLiability': {
        title: 'Personal liability',
      },
      'deductibles.deductible': {
        title: 'Standard deductible',
      },
    },
  },
  RetrieveQuoteErrorMetadata: {
    CNQA101:
      'The vehicle information was not available in vehicle reports. ISO information is not available.',
    CNQA102: 'VIN entered contained invalid characters.',
    CNQA103: 'California driving history report failed.',
    CNQA104:
      'DREX needed - take off platform. Remind client there is a 14-day turnaround time with no guaranteed acceptance. Offer Agency, if needed.',
    PRE001: 'Used an invalid policy effective date.',
    PRE002: 'This quote has already been purchased.',
    PRE003: 'There is a moratorium in place for this location.',
    PREA101: 'A driver is below the required age limit.',
    PREA102: 'A driver has an SR-22.',
    PREA108: 'Currently insured by Ameriprise Insurance Company.',
    PREA109: 'Currently insured by IDS Property Casualty Insurance.',
    PREA110: 'Cannot have an odd number of married drivers listed on policy.',
    PREA111: 'No drivers have a valid license.',
    PREA112: 'Too many vehicles to quote on platform.',
    PREA113: 'Vehicle information not found',
    PREA114: 'Too many drivers listed on policy to quote on platform.',
    PREA115: 'Prior BI is 25/50 or lower',
    PREA116: 'No prior insurance',
    POST001: 'Start date is greater than the number of days allowed in the future',
    POST002: 'NY Auto: Requires Additional Review',
  },
  DefaultPaymentPlanMetadata: {},
  RetrievalErrors: {
    [RetreivalErrorReason.DOB_OR_EMAIL_MISMATCH]: {
      displayErrorMessage: 'Unable to recall - DOB/E-mail combination mismatch or no quote exists',
    },
    [RetreivalErrorReason.PRODUCT_MISMATCH]: {
      displayErrorMessage: 'Unable to recall - Incorrect product type',
    },
    [RetreivalErrorReason.LAST_NAME_MISMATCH]: {
      displayErrorMessage: 'Unable to recall - Last name not found',
    },
    [RetreivalErrorReason.ZIPCODE_MISMATCH]: {
      displayErrorMessage: 'Unable to recall - Incorrect zip code',
    },
  },
  DisplayErrorRecallMessage: {
    recallErrorMessage: env.static.isAgent
      ? 'Unable to find any quotes for this product. Please confirm or adjust your selection.'
      : `Your quote was not found. Adjust your information or click "Start a new quote" below for a new one. Still need help? Call us at ${partner.shared.salesPhoneNumber}.`,
  },
};

export default metadata;
