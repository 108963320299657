import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CheckoutErrorPage' })((theme) => ({
  root: theme.mixins.pageRoot,
  header: {
    color: theme.palette.error.main,
    textAlign: 'center',
  },
  headerContainer: {
    margin: '40px 0px 65px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    '& h3': {
      marginTop: 5,
    },
  },
  mariaBoxContent: theme.typography.body1,
  helpBoxContainer: {
    border: `1px solid ${theme.palette.grey[700]}`,
    borderRadius: 4,
    maxWidth: 534,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    position: 'relative',
    marginBottom: '200px',
  },
  helpBoxIcon: {
    position: 'absolute',
    top: '-13%',
    backgroundColor: 'transparent',
  },
  mariaBox: {
    padding: '30px 20px 20px 20px',
  },
  callUsButton: {
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  phone: {
    '& span': theme.typography.button2Medium,
  },
  contactContainer: {
    marginTop: 30,
    paddingBottom: 35,
  },
}));
