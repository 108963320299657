import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

export const DeckClearanceStatusQuestion: React.FC<QuestionProps> = (props) => {
  const {
    label = 'Have you cleared vegetation and debris from under your deck?',
    trackingName = 'cleared_vegetation_debris_under_deck',
  } = props;
  const clearedVegetationUnderDeck = useField('property.deckClearanceStatus');

  if (!clearedVegetationUnderDeck.exists) return null;

  return (
    <RadioGroupWithOptions
      {...clearedVegetationUnderDeck.props}
      id='clearedVegetationUnderDeck'
      label={label}
      variant='button'
      trackingName={trackingName}
    />
  );
};
