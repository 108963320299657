import { CoveragePersonalInjuryProtectionPreviewImageUrl, makeStyles } from '@ecp/themes/base';

import type { Props } from './CoverageCard';

export const useStyles = makeStyles<Props>({ name: 'CoverageCard' })((theme, { subCoverages }) => ({
  root: {
    overflow: 'hidden',
    paddingBottom: subCoverages ? 24 : 0,
    border: `1px solid ${theme.palette.other.border}`,
    '& .MuiAccordionSummary-root > .MuiAccordionSummary-expandIconWrapper > svg > path': {
      fill: theme.palette.primary.main,
    },
    '& > .MuiAccordionSummary-root': {
      padding: '0px 10px',
    },
  },
  summaryContent: {
    margin: '0 !important',
    [theme.breakpoints.up('lg')]: {
      minHeight: 82,
    },
  },
  accordionError: {
    '&.MuiAccordion-root': {
      borderColor: theme.palette.error.main,
    },
  },
  summaryError: {
    ...theme.typography.body2,
    backgroundColor: theme.palette.error.light,
    padding: '2px 16px',
    textAlign: 'center',
  },
  summaryImage: {
    width: 112,
    height: 'calc(100% - 16px)',
    flexShrink: 0,
    margin: '8px 0',
    borderRadius: 4,
    [theme.breakpoints.down('sm')]: {
      width: 0,
    },
  },
  summaryTitlePanel: {
    padding: 10,
    paddingRight: 34,
    flexGrow: 1,
    textAlign: 'left',
  },
  summaryTitle: {
    ...theme.typography.body3Bold,
    paddingBottom: 5,
  },
  summarySubTitle: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
  },
  summaryValue: {
    ...theme.typography.body1Bold,
    color: theme.palette.success.dark,
  },
  summaryValueRejected: {
    color: theme.palette.other.caution,
  },
  divider: {
    color: theme.palette.other.divider,
  },
  detailsRoot: {
    padding: 15,
    paddingBottom: 10,
  },
  detailsContainer: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    width: '100%',
    overflow: 'hidden',
    lineHeight: '18px',
  },
  detailsHelpLink: {
    display: 'inherit',
  },
  breakdownContainer: {
    background: `no-repeat center/cover url(${CoveragePersonalInjuryProtectionPreviewImageUrl})`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 94,
    margin: '10px 0',
    borderRadius: 4,
    '&:hover': {
      cursor: 'zoom-in',
    },
  },
  breakdownButton: {
    margin: '30px 0',
  },
  expandSubCoveragesButton: {
    ...theme.typography.body2,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 24,
    borderRadius: 4,
    visibility: 'visible',
    backgroundColor: theme.palette.grey['50'],
    '&:hover': {
      backgroundColor: theme.palette.grey['50'],
    },
  },
  secondaryText: {
    paddingTop: 5,
    '& > p': {
      ...theme.typography.body2,
      color: theme.palette.text.secondary,
    },
  },
  errorIcon: {
    verticalAlign: 'bottom',
    padding: '2px 0px',
    ...theme.mixins.setColorSvg(theme.palette.error.dark),
  },
  dialogRoot: {
    ...theme.mixins.formAll,
    '& .MuiPaper-root.MuiDialog-paper': {
      top: '0%',
      alignItems: 'center',
    },
    '& .MuiButton-text': {
      '& > svg': {
        ...theme.mixins.setColorSvg(theme.palette.secondary.contrastText),
      },
    },
  },
  personalInjuryTableContainer: {
    border: 0,
    justifyContent: 'center',
    alignItems: 'center',
    margin: '40px 0px 20px 0px',
    [theme.breakpoints.down('md')]: {
      margin: '40px 15px 20px 15px',
    },
  },
  dialogContent: {
    margin: 'auto',
    maxWidth: 748,
    overflow: 'hidden',
  },
  listItems: {
    padding: 0,
    paddingLeft: 16,
  },
  listItem: theme.typography.body2,
  socialProofingContainer: {
    display: 'flow',
    alignItems: 'center',
  },
  // A/B/C test styles
  socialProofingHeader: {
    padding: 2,
    width: 170,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    marginBottom: 10,
  },
  socialProofingHeaderBlue: {
    backgroundColor: theme.palette.primary.light,
    ...theme.mixins.setColorSvg(theme.palette.primary.dark),
  },
  // Setting a hexcode directly since it is not available in the theme file and
  // this is just an A/B test.
  socialProofingHeaderGreen: {
    backgroundColor: '#DFF0D8',
    ...theme.mixins.setColorSvg('#008000'),
  },
  socialProofingIcon: {
    marginRight: 5,
  },
  socialProofingIconGreen: {},
  socialProofingText: {
    [theme.breakpoints.down('md')]: {
      height: 80,
      display: 'block',
    },
    paddingTop: 6,
    paddingLeft: 22,
    display: 'flex',
    borderLeft: `2px solid ${theme.palette.other.border}`,
    marginLeft: 12,
    marginTop: -28,
    height: 50,
    position: 'relative',
    top: 18,
  },
  addCoverageButton: {
    marginLeft: 5,
    height: 20,
  },
}));
