import { useEffect, useRef } from 'react';

export const useAutoCompleteInputAriaAutoCompletePatch = (): React.RefObject<HTMLInputElement> => {
  const autoCompleteContainerRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoCompleteContainerRef.current) {
      const autoSuggestInput = autoCompleteContainerRef.current as HTMLInputElement;
      const parentDivElement = autoSuggestInput.parentElement as HTMLDivElement;
      parentDivElement?.removeAttribute('aria-autoComplete');
    }
  }, []);

  return autoCompleteContainerRef;
};
