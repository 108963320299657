import { makeStyles } from '@ecp/themes/base';

import type { DriverIconProps } from './DriverIcon';

export const useStyles = makeStyles<DriverIconProps>({ name: 'DriverIcon' })(
  (...[theme, { index = 0 }]) => ({
    root: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      verticalAlign: 'middle',
      width: 25,
      height: 25,
      // default fill color for the driver icon main content
      ...theme.mixins.setColorSvg(theme.palette.grey[500]),
      borderRadius: '100%',
      // background colors for the driver icon
      backgroundColor: theme.palette.primary.main,
    },
    icon: {
      width: '100%',
      height: '100%',
    },
  }),
);
