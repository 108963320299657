import { datadogLog } from '@ecp/utils/logger';

import type { Product } from '@ecp/features/shared/product';
import { isProductRenters } from '@ecp/features/shared/product';

import type { PaymentOption } from '../types';
import { determineInstallmentFeeTotal } from './installmentFeeUtil';

/**
 * Calculates the combined total of the premium and installment fee for a given product and payment option.
 *
 * If an installment fee does not exist for the given product and payment option, the total premium is returned.
 * If an installment fee exists, it calculates the total amount by adding the installment fee to the premium.
 * The function returns the combined total as a string.
 *
 * @param {Product} product - The product for which the total is being calculated.
 * @param {PaymentOption} option - The payment option associated with the product.
 * @returns {string} The combined total of the premium and installment fee as a string.
 */
export const getCombinedTotal = (product: Product, option: PaymentOption): string => {
  if (!installmentFeeExists(product, option)) {
    return option.totalPremium;
  }

  const installmentFeeTotal = determineInstallmentFeeTotal(product, option);
  if (!installmentFeeTotal) {
    return option.totalPremium;
  }

  const premiumAndInstallmentFeeTotal = calculatePremiumAndInstallmentFeeTotal(
    installmentFeeTotal,
    option,
  );

  return String(premiumAndInstallmentFeeTotal);
};

const installmentFeeExists = (product: Product, option: PaymentOption): boolean => {
  // for renters product, if autoPayFeeTotal is not defined, then installment fee does not exist
  // see PaymentOption type for more explainations
  if (isProductRenters(product) && !option.autoPayFeeTotal) {
    return false;
  }
  // for auto and home products, if convenienceFeeTotal is not defined, then installment fee does not exist
  // see PaymentOption type for more explainations
  if (!isProductRenters(product) && !option.convenienceFeeTotal) {
    return false;
  }

  return true;
};

const calculatePremiumAndInstallmentFeeTotal = (
  installmentFeeTotal: string,
  option: PaymentOption,
): number => {
  const premiumAndInstallmentFeeTotal =
    parseFloat(installmentFeeTotal) + parseFloat(option.totalPremium);

  if (Number.isNaN(premiumAndInstallmentFeeTotal)) {
    logAndThrowNaNError(option);
  }

  return premiumAndInstallmentFeeTotal;
};

const logAndThrowNaNError = (option: PaymentOption): void => {
  logNaNError(option);
  throw new Error(`total was NaN using, ${JSON.stringify(option)}`);
};

const logNaNError = (option: PaymentOption): void => {
  datadogLog({
    logType: 'error',
    message: `Checkout Payment Method total was NaN`,
    context: {
      logOrigin: 'libs/features/sales/checkout/src/util/totalPremiumUtil.ts',
      functionOrigin: 'logNaNError',
      paymentPlan: option.paymentPlan,
      paymentType: option.paymentType,
    },
  });
};
