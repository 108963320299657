import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AppFooter' })((theme) => ({
  root: {
    ...theme.mixins.footerRoot,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingBottom: 40,
    paddingTop: 40,
    [theme.breakpoints.down('xl')]: {
      height: 'auto',
    },
  },
  coverageFooter: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: 182,
    },
  },
  checkoutFooter: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: 102,
    },
  },
  copyrightText: {
    ...theme.typography.body1,
    color: theme.palette.partner.footerTextPrimary,
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 15,
      paddingRight: 15,
      textAlign: 'center',
    },
  },
  sectionItem: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px 7.5px',
  },
  linksWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '10px auto',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  linksDivider: {
    width: 1,
    height: 20,
    color: theme.palette.grey[800],
    marginLeft: 15,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  disclaimerText: {
    ...theme.typography.body1,
    color: theme.palette.partner.footerTextSecondary,
    display: 'flex',
    justifyContent: 'center',
    width: '700px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
    [theme.breakpoints.only('md')]: {
      width: '688px',
    },
    [theme.breakpoints.only('sm')]: {
      width: '375px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '375px',
    },
  },
  noMargin: {
    margin: 0,
  },
  links: {
    ...theme.typography.button2,
    color: theme.palette.text.link,
  },
}));
