import { useMediaQuery } from '@mui/material';

import type { Theme } from '../theme';

/** Detect whether the screen width is mobile portrait mode. */
export const useIsMobile = (): boolean =>
  useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));

/** Detect whether the screen width is tablet portrait mode. */
export const useIsTablet = (): boolean =>
  useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg'));

/** Detect whether the screen width is desktop mode. */
export const useIsDesktop = (): boolean =>
  useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));

export const useAboveMobile = (): boolean =>
  useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));
