import { useCallback } from 'react';

import { Grid } from '@mui/material';

import { useEffectOnce } from '@ecp/utils/react';

import { GridItem, TextField } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { getFullPersonInfo, useField } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { ProductName } from '@ecp/features/shared/product';
import type { AnswerValue } from '@ecp/types';

import { setSignatureMethodPersonEmail } from '../../../state/acknowledgements';

export interface Props extends QuestionProps {
  coverageType: ProductName;
  personRef: string;
}

export interface SignatureEmailOptions {
  label: string;
  value: string;
}

export const RemoteClick2SignPerson: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { coverageType, personRef } = props;
  const { firstName, lastName, email } = useSelector((state) =>
    getFullPersonInfo(state, personRef),
  );
  const signatureEmail = useField(
    `static.${personRef}.checkout.${coverageType}.remoteClick2Sign.email`,
  );
  const signatureNewEmail = useField(
    `static.${personRef}.checkout.${coverageType}.remoteClick2Sign.newEmail`,
  );

  useInitValues(
    email
      ? { [signatureEmail.key]: email, [signatureNewEmail.key]: email }
      : { [signatureEmail.key]: 'Add New Email', [signatureNewEmail.key]: null },
  );
  useAddFields({
    [signatureEmail.key]: signatureEmail,
    [signatureNewEmail.key]: signatureNewEmail,
  });

  const getEmailOptions = (options: string[]): SignatureEmailOptions[] =>
    options.map((value) => ({
      label: value,
      value,
    }));

  useEffectOnce(() => {
    const emailValue = signatureNewEmail.value ?? signatureEmail.value ?? email;
    dispatch(
      setSignatureMethodPersonEmail({
        personRef,
        email: `${emailValue}`,
      }),
    );
  });

  const onEmailOptionChange = useCallback(
    (value: AnswerValue): void => {
      signatureEmail.props.actionOnChange(value);
      if (value === email) {
        signatureNewEmail.props.actionOnComplete(email);
        dispatch(setSignatureMethodPersonEmail({ personRef, email: `${value}` }));
      } else {
        signatureNewEmail.validateUpdateAndPatch(null);
      }
    },
    [dispatch, personRef, signatureEmail, signatureNewEmail, email],
  );

  const onEmailValueChange = useCallback(
    (value: AnswerValue): void => {
      signatureNewEmail.props.actionOnChange(value);
      dispatch(setSignatureMethodPersonEmail({ personRef, email: `${value}` }));
    },
    [dispatch, personRef, signatureNewEmail],
  );

  if (!signatureEmail.exists) return null;

  return (
    <Grid container item xs={12} spacing={1}>
      {email && (
        <GridItem topSpacing='md' xl={6} xs={12}>
          <Select
            {...signatureEmail.props}
            label={
              firstName && lastName
                ? `Remote Click2Sign Email for ${firstName} ${lastName}`
                : 'Remote Click2Sign Email'
            }
            id='remoteClick2SignEmail'
            options={getEmailOptions(email ? [email, 'Add New Email'] : ['Add New Email'])}
            trackingName='remote_click2sign_email'
            actionOnChange={onEmailOptionChange}
          />
        </GridItem>
      )}
      {signatureEmail.value === 'Add New Email' && (
        <GridItem topSpacing='md' xl={6} xs={12}>
          <TextField
            {...signatureNewEmail.props}
            label={email ? 'New Email' : `Remote Click2Sign Email for ${firstName} ${lastName}`}
            trackingName='remote_click2sign_new_email'
            actionOnChange={onEmailValueChange}
            autoComplete='email'
            inputMode='email'
          />
        </GridItem>
      )}
    </Grid>
  );
};
