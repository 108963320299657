import { useCallback, useMemo } from 'react';

import type { SliderProps } from '@mui/material';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Slider as MuiSlider,
  useMediaQuery,
} from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { emptyArray } from '@ecp/utils/common';

import { useFieldRef } from '@ecp/features/sales/form';
import { useTheme } from '@ecp/themes/base';
import type { Option } from '@ecp/types';

import { useMuiSliderStyles, useStyles } from './Slider.styles';

type Mark = Exclude<NonNullable<SliderProps['marks']>, boolean>[number];

interface Props {
  className?: string;
  error?: string;
  groupLabel?: string;
  helperText?: string;
  id?: string;
  name?: string;
  options?: Option[];
  value: string;
  label?: string;
  trackingName?: string;
  trackingLabel?: string;
  actionOnChange: (value: string) => void;
  actionOnComplete: (value: string) => void;
  dataTestId?: string;
}

// getting and keeping the "ball" from not bleeding out into the margin or not allowing the "track" to poke out
// this is not an exact science, but it keeps the "ball" in the proper place in most any screen width / num marks
const useMinMax = (marks: Mark[]): [number, number] => {
  const { breakpoints } = useTheme();
  let minMult = 0.008;
  let maxMult = 0.013;

  if (useMediaQuery(breakpoints.down('xs'))) {
    minMult = 0.024;
    maxMult = 0.04;
  }
  if (useMediaQuery(breakpoints.only('sm'))) {
    minMult = 0.012;
    maxMult = 0.02;
  }

  const numSections = marks.length - 1;
  const min = -(minMult * numSections);
  const max = numSections + maxMult * numSections;

  return [min, max];
};

export const Slider: React.FC<Props> = (props) => {
  const {
    className,
    error,
    groupLabel,
    helperText,
    id,
    name,
    options: optionsProp = emptyArray,
    value,
    label,
    trackingName,
    trackingLabel,
    actionOnChange,
    actionOnComplete,
    dataTestId,
  } = props;
  const { classes, cx } = useStyles();
  const { classes: muiSliderClasses } = useMuiSliderStyles();

  // always add empty option - otherwise, something always selected with no way to unselect
  const options = useMemo(() => [{ value: '', label: 'Select' }, ...optionsProp], [optionsProp]);
  // convert selected value to index since Slider needs values to be numbers and we may not get numbers in options
  let selectedIndex = options.findIndex((option) => option.value === value);
  selectedIndex = selectedIndex === -1 ? 0 : selectedIndex;
  const marks = useMemo<Mark[]>(
    () => options.map((item, index) => ({ value: index, label: item.label })),
    [options],
  );

  const [min, max] = useMinMax(marks);

  const styledMarks = marks.map((mark) => ({
    value: mark.value,
    label: (
      <div
        data-testid={mark.label}
        className={cx(
          classes.markValue,
          mark.value === selectedIndex && classes.markValueSelected,
          mark.label === 'Select' && classes.firstMark,
        )}
      >
        {mark.label}
      </div>
    ),
  }));

  const valueLabelFormat = useCallback(() => marks[selectedIndex].label, [marks, selectedIndex]);

  // Callback function that is fired when the slider's value changed.
  const handleChange = useCallback<NonNullable<SliderProps['onChange']>>(
    (event, newValue) => {
      // handle change is getting triggered couple of times,
      // one with new modified value and one with old value on left or right arrow selections.
      // Below condiftion will skip the event that triggers with previous value
      if (event.type === 'change') return;
      // internal value is index, so map back to actual option.value that was selected
      actionOnChange(options[Number(newValue)].value);
    },
    [actionOnChange, options],
  );

  // Callback function that is fired when the mouseup is triggered.
  const handleChangeCommited = useCallback<NonNullable<SliderProps['onChangeCommitted']>>(
    (event, newValue) => {
      // handle change is getting triggered couple of times,
      // one with new modified value and one with old value on left or right arrow selections.
      // Below condition will skip the event that triggers with previous value
      if (event.type === 'change') return;
      // internal value is index, so map back to actual option.value that was selected
      actionOnComplete(options[Number(newValue)].value);
      // tracks slider clicks for google analytics
      // if we want to specify a value we send ga tracking label and if not we send the value
      if (trackingName) {
        if (trackingLabel) {
          trackClick({ action: trackingName, label: trackingLabel });
        }
        trackClick({ action: trackingName, label: options[Number(newValue)].value });
      }
    },
    [actionOnComplete, options, trackingLabel, trackingName],
  );

  return (
    <FormControl className={cx(classes.root, classes.formControl, className)} error={!!error}>
      {groupLabel && (
        <FormLabel
          component='legend'
          focused={false}
          className={classes.label}
          id={name && `${name}-label`}
        >
          {groupLabel}
          {helperText && <FormHelperText error={false}>{helperText}</FormHelperText>}
        </FormLabel>
      )}
      <MuiSlider
        className={classes.control}
        classes={muiSliderClasses}
        ref={useFieldRef(name)}
        aria-label={label || groupLabel}
        aria-valuetext={`${options[selectedIndex].label}`}
        id={id}
        name={name}
        value={selectedIndex}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommited}
        valueLabelFormat={valueLabelFormat}
        step={null}
        valueLabelDisplay='auto'
        marks={styledMarks}
        min={min}
        max={max}
        data-testid={dataTestId}
      />
      {error && (
        <FormHelperText
          className={classes.errorText}
          role='alert'
          // aria-label will announce the label and error message, follow by role alert which also announce the error message
          aria-label={error && `${options[selectedIndex].label} ${error}`}
        >
          {error || ' '}
        </FormHelperText>
      )}
    </FormControl>
  );
};
