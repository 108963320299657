import { useCallback } from 'react';

import {
  createRef,
  getAnswers,
  getMappedKeyDynamic,
  getPropertiesOfTemplateWithRef,
  removeAndDeleteRef,
  setProperty,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type {
  AnswerValue,
  HeatingSource,
  Nested,
  PropertyList,
  Template,
} from '@ecp/features/sales/shared/types';

import { HEATING_SOURCE_REF } from '../../constants';

export const useCreateHeatingSource = (): (() => string) => {
  const dispatch = useDispatch();

  return useCallback(() => dispatch(createRef('heatingSource')), [dispatch]);
};

export const useRemoveHeatingSource = (): ((ref: string) => Promise<void>) => {
  const dispatch = useDispatch();

  return useCallback(
    async (ref: string) => {
      await dispatch(removeAndDeleteRef({ refType: HEATING_SOURCE_REF, ref }));
    },
    [dispatch],
  );
};

const heatingSourceTemplate: Template<HeatingSource> = {
  ref: '',
  type: '',
  material: '',
  fuelType: '',
};
const heatingSourceMapping: Record<string, string> = {};

const getHeatingSourceProperties = (
  ref: string,
  ...prefix: string[]
): PropertyList<HeatingSource> =>
  getPropertiesOfTemplateWithRef(heatingSourceTemplate, [ref, ...prefix]);

const getHeatingSourceValues = (state: RootStore, ref: string): HeatingSource => {
  const answers = getAnswers(state);
  const properties = getHeatingSourceProperties(ref);

  return properties.reduce(
    (result, property) => {
      const key = getMappedKeyDynamic(property, { [ref]: heatingSourceMapping });
      const value = answers[key];
      setProperty(result as unknown as Nested<AnswerValue>, property, value);

      return result;
    },
    { ref } as HeatingSource,
  );
};

const getHeatingSourcesValues = (
  state: RootStore,
  refs: string[],
): Record<string, HeatingSource> => {
  return refs.reduce((result, ref) => {
    result[ref] = getHeatingSourceValues(state, ref);

    return result;
  }, {} as Record<string, HeatingSource>);
};

const useHeatingSourcesValues = (refs: string[]): Record<string, HeatingSource> => {
  return useSelector((state: RootStore) => getHeatingSourcesValues(state, refs));
};

export const useHeatingSources = (refs: string[]): HeatingSource[] => {
  return Object.values(useHeatingSourcesValues(refs));
};
