import { makeDriverItemList, SingleLineDetail } from '@ecp/features/sales/shared/components';
import { NavStatus } from '@ecp/features/sales/shared/constants';
import { PageFlow, PagePath } from '@ecp/features/sales/shared/routing';
import type { NavTracking } from '@ecp/features/sales/shared/store/types';
import type {
  Address,
  CarrierFields,
  Driver,
  DriverOnly,
  HomeBasicsInfo,
  HomeExteriorInfo,
  Person,
  Vehicle,
} from '@ecp/features/sales/shared/types';
import { LineOfBusiness } from '@ecp/features/shared/product';

interface ItemList {
  description: string | React.ReactNode;
  imageUrl?: string;
  icon?: React.ReactNode;
}

const parseType = (t: string | undefined): string | undefined => {
  switch (t) {
    case 'SINGLE_OR_MULTI_FAMILY.SINGLE_FAMILY':
      return 'Single family';
    case 'SINGLE_OR_MULTI_FAMILY.TWO_FAMILY':
      return 'Two families';
    case 'SINGLE_OR_MULTI_FAMILY.THREE_FAMILY':
      return 'Three families';
    case 'SINGLE_OR_MULTI_FAMILY.FOUR_FAMILY':
      return 'Four families';
    case 'SINGLE_OR_MULTI_FAMILY.FIVE_PLUS_FAMILY':
      return 'Five+ families';
    default:
      return undefined;
  }
};

// Product Section
export const getProduct = (lineOfBusiness: string): React.ReactElement => {
  let displayLineOfBusiness;
  if (lineOfBusiness === LineOfBusiness.AUTO) {
    displayLineOfBusiness = 'Auto';
  } else if (lineOfBusiness === LineOfBusiness.HOME) {
    displayLineOfBusiness = 'Home';
  } else {
    displayLineOfBusiness = 'Auto + Home';
  }

  return <div>{displayLineOfBusiness}</div>;
};

// Personal Information Section
export const getPersonalInformation = (
  personInformation: Pick<
    Person,
    'firstName' | 'middleName' | 'lastName' | 'suffix' | 'dateOfBirth'
  >,
  addressInformation: Pick<Address, 'city' | 'line1' | 'line2' | 'state' | 'zipcode'>,
): React.ReactElement => {
  const firstName = personInformation.firstName || '';
  const lastName = personInformation.lastName || '';
  const middleName = personInformation.middleName?.slice(0, 1)?.toUpperCase() || '';
  const suffix = personInformation.suffix || '';
  const name = `${firstName} ${middleName} ${lastName} ${suffix}`;

  return (
    <>
      <div>{name}</div>
      {addressInformation.line1 && (
        <div>
          {addressInformation.line1}
          {addressInformation.line2 ? ` ${addressInformation.line2},` : ','}{' '}
          {addressInformation.city} {addressInformation.state}
        </div>
      )}
    </>
  );
};

// Home Information Section
export const getHomeInformation = (
  basicInfo: HomeBasicsInfo,
  exteriorInfo: HomeExteriorInfo,
): React.ReactElement => {
  let parsedType;
  let parsedSqft;
  let parsedYearBuilt;
  let parsedExterior;
  let parsedRoofShape;
  let parsedRoofYear;
  if (basicInfo) {
    const type = basicInfo.type ? basicInfo.type : undefined;
    parsedType = parseType(type);
    parsedSqft = basicInfo.sqft ? `${basicInfo.sqft}` : undefined;
    parsedYearBuilt =
      basicInfo.yearBuilt !== 'undefined' && basicInfo.yearBuilt !== undefined
        ? basicInfo.yearBuilt
        : undefined;
  }
  if (exteriorInfo) {
    parsedExterior = exteriorInfo.exterior ? exteriorInfo.exterior : undefined;
    parsedRoofShape = exteriorInfo.roofShape ? exteriorInfo.roofShape : undefined;
    parsedRoofYear = exteriorInfo.roofYear ? `${exteriorInfo.roofYear}` : undefined;
  }

  return (
    <>
      {parsedType ? <SingleLineDetail text='Type' value={parsedType} /> : null}
      {parsedSqft ? <SingleLineDetail text='Sqft' value={parsedSqft} /> : null}
      {parsedYearBuilt ? <SingleLineDetail text='Year built' value={parsedYearBuilt} /> : null}
      {parsedExterior ? <SingleLineDetail text='Exterior' value={parsedExterior} /> : null}
      {parsedRoofShape ? <SingleLineDetail text='Roof shape' value={parsedRoofShape} /> : null}
      {parsedRoofYear ? <SingleLineDetail text='Roof year' value={parsedRoofYear} /> : null}
    </>
  );
};

// Auto Profile Section
const getItemList = (items: ItemList[], className: string): React.ReactElement | null => {
  if (items.length > 0) {
    return (
      <ul className={className}>
        {items.map(({ description }, index) => (
          <li key={index}>{description}</li>
        ))}
      </ul>
    );
  }

  return null;
};

export const getDrivers = (
  drivers: (Pick<
    Driver,
    | 'firstName'
    | 'middleName'
    | 'lastName'
    | 'suffix'
    | 'dateOfBirth'
    | 'personRef'
    | 'relationshipToApplicant'
    | 'gender'
    | 'ref'
    | 'driverStatus'
  > & { driversLicense: Pick<DriverOnly['driversLicense'], 'number'> })[],
  className: string,
): React.ReactElement => {
  const driverItemList = makeDriverItemList(drivers);

  return <div>{getItemList(driverItemList, className)}</div>;
};

export const getVehicles = (
  vehicleItems: Pick<Vehicle, 'year' | 'make' | 'model' | 'ref' | 'description'>[],
  className: string,
): React.ReactElement => {
  return <div>{getItemList(vehicleItems, className)}</div>;
};

// Quotes Section
export const getQuoteInformation = (
  carrier: CarrierFields,
  lineOfBusiness: LineOfBusiness,
  partnerName?: string,
  showLogo?: boolean,
): React.ReactElement | null => {
  let lineOfBusinessTitle = '';

  switch (lineOfBusiness) {
    case LineOfBusiness.BUNDLE:
      lineOfBusinessTitle = 'Auto & Home';
      break;
    case LineOfBusiness.BUNDLE_AUTO_RENTERS:
      lineOfBusinessTitle = 'Auto & Renters';
      break;
    case LineOfBusiness.AUTO:
      lineOfBusinessTitle = 'Auto';
      break;
    case LineOfBusiness.HOME:
      lineOfBusinessTitle = 'Home';
      break;
    case LineOfBusiness.RENTERS:
      lineOfBusinessTitle = 'Renters';
      break;
  }

  return carrier ? (
    <>
      {showLogo && (
        <img src={carrier.logo} style={{ width: 40, paddingRight: 10 }} alt='Carrier Logo' />
      )}
      <p>{`${partnerName || carrier.marketingName} ${lineOfBusinessTitle}`}</p>
    </>
  ) : null;
};

export const getDiscountRoute = (pageFlow: PageFlow): string => {
  switch (pageFlow) {
    case PageFlow.A:
    case PageFlow.D:
    case PageFlow.G:
    case PageFlow.H:
    case PageFlow.I:
    case PageFlow.K:
    case PageFlow.U:
    case PageFlow.V:
    case PageFlow.Y:
    case PageFlow.Z:
    case PageFlow.MIB:
    case PageFlow.MIC:
      return PagePath.AUTO_DISCOUNTS; // Auto Long form discounts
    case PageFlow.B:
    case PageFlow.C:
    case PageFlow.E:
    case PageFlow.F:
    case PageFlow.J:
    case PageFlow.L:
    case PageFlow.W:
    case PageFlow.X:
    case PageFlow.AA:
    case PageFlow.AB:
    case PageFlow.MIE:
    case PageFlow.MID:
      return PagePath.AUTO_DISCOUNTS_SHORT; // Auto Short form discounts
    case PageFlow.M:
    case PageFlow.O:
    case PageFlow.Q:
    case PageFlow.R:
    case PageFlow.MI:
      return PagePath.HOME_DISCOUNTS;
    default:
      return PagePath.AUTO_DISCOUNTS;
  }
};

export const getNavbarStatus = (
  navTracking: NavTracking,
): { active: boolean; completed: boolean } => {
  return {
    active: navTracking.inProgress,
    completed: !navTracking.inProgress && navTracking.status === NavStatus.VALID,
  };
};
