import { datadogLog } from '@ecp/utils/logger';

import { ErrorReason } from '@ecp/features/sales/shared/constants';
import { SalesRequestError } from '@ecp/features/sales/shared/utils/network';

import { getSapiTarget } from '../../api';
import { setFormErrorsChangedByField } from '../../form';
import { wrapThunkActionWithErrHandler } from '../../util/wrapThunkActionWithErrHandler';
import { setInquiryApiGetSuccess } from '../actions';
import { postPolicyType } from '../api';
import {
  getDalSessionId,
  getLineOfBusiness,
  getPrimaryInsuredAddressInfo,
  getPrimaryInsuredAddressRef,
} from '../selectors';
import { getErrorMessageFromErrorReason } from '../util/getErrorMessageFromErrorReason';
import { getErrorReasonFromPolicyTypes } from '../util/getErrorReasonFromPolicyTypes';

export const submitPolicyType = wrapThunkActionWithErrHandler<void>(
  () => async (dispatch, getState) => {
    const state = getState();
    const sapiTarget = getSapiTarget(state);

    // This is a v4 only functionality and not to be called on v3 flow
    if (sapiTarget !== 'v4') return;

    const dalSessionId = getDalSessionId(state);
    const lineOfBusiness = getLineOfBusiness(state);
    const { state: addressState } = getPrimaryInsuredAddressInfo(state);
    const primaryInsuredAddressRef = getPrimaryInsuredAddressRef(state);
    const zipKey = `${primaryInsuredAddressRef}.zipcode`;

    // Every time product.lob is modified, submitPolicyType should be
    // called. You can add/ remove product even after prefill
    if (!dalSessionId || !lineOfBusiness || !addressState) {
      return;
    }

    try {
      const response = await postPolicyType({ dalSessionId });

      dispatch(setInquiryApiGetSuccess(response.payload));
    } catch (error) {
      const e = error as Error | SalesRequestError;

      datadogLog({
        logType: 'error',
        message: `Error posting policy type - ${e?.message}`,
        context: {
          logOrigin: 'libs/features/sales/shared/store/lib/src/inquiry/thunks/submitPolicyType.ts',
          functionOrigin: 'submitPolicyType',
          ...(e instanceof SalesRequestError && { errorData: e.errorData }),
        },
        error: e,
      });

      if (error instanceof SalesRequestError) {
        const { errorCode, errorData } = error;
        if (
          errorCode === ErrorReason.POLICY_TYPE_INVALID &&
          errorData?.availablePolicyTypes &&
          errorData?.unavailableRequestedPolicyTypes
        ) {
          const errorReason = getErrorReasonFromPolicyTypes(
            errorData.availablePolicyTypes,
            errorData.unavailableRequestedPolicyTypes,
          );

          if (errorReason) {
            const errorMessage = getErrorMessageFromErrorReason(errorReason);

            // Set form error for zip code to show inline error for this scenario
            dispatch(
              setFormErrorsChangedByField({
                key: zipKey,
                errors: errorMessage,
              }),
            );

            return;
          }
        }
      }

      throw error;
    }
  },
  'submitPolicyType',
);
