import { Dialog } from '@ecp/features/sales/shared/components';
import type { LineOfBusiness } from '@ecp/features/shared/product';

import metadata from './metadata';
import { useStyles } from './MonolineProductNotEligibleDialog.styles';

interface Props {
  open: boolean;
  onClose(): void;
  stateCode: string;
  unavailableLob: LineOfBusiness;
}

export const MonolineProductNotEligibleDialog: React.FC<Props> = (props) => {
  const { open, onClose, stateCode, unavailableLob } = props;
  const { classes } = useStyles();

  return (
    <Dialog
      actionButtonLabel='CLOSE'
      titleText='Insurance not yet available in your state'
      trackingNameButton='StateUnavailableClose'
      trackingLabelButton='Close'
      trackingNameCloseIcon='StateUnavailable_X'
      trackingLabelCloseIcon='X'
      open={open}
      onClose={onClose}
      buttonPlacement='right'
      actionButtonOnClick={onClose}
    >
      <div className={classes.dialogBody}>
        {metadata[unavailableLob]?.stateOptions?.[stateCode]?.displayText ||
          metadata[unavailableLob]?.displayText}
      </div>
    </Dialog>
  );
};
