import { cloneDeep } from '@ecp/utils/common';

import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';
import { getProductNameFromProduct } from '@ecp/features/shared/product';
import type { Field } from '@ecp/types';

import { defaultPaymentByProductType } from '../metadata';
import { setPaymentType } from '../state';
import type { PurchaseResponse } from '../state/purchase';
import type {
  CheckoutOfferDetails,
  PostBindFields,
  PostBindSummary,
  ProductErrorMessages,
  ProductMsgTuple,
  PurchaseResults,
} from '../types';
import type { PaymentFields } from './checkoutUtil';
import metadata from './metadata';

// TODO: This function is being used in the retry form's re-submit button
export const getPaymentFields = (
  products: Array<Product>,
  fields: Record<Product, PaymentFields>,
  paymentPlan?: string[],
): PaymentFields => {
  const fieldsCopy = cloneDeep(fields);

  products.forEach((product, index) => {
    const productFields = fieldsCopy[product];

    delete productFields.pniAcknowledgementName;
    delete productFields.sniAcknowledgementName;
    delete productFields.paymentAcknowledgement;
    delete productFields.acknowledgementName;
    if (metadata[product].removeCheckboxValidation) {
      delete productFields.autoWithdrawAgree;
      delete productFields.customerAcknowledgement;
    }

    if (paymentPlan && paymentPlan[index] === 'Full' && productFields.autoWithdrawAgree) {
      delete productFields.autoWithdrawAgree;
    }
    if (paymentPlan && paymentPlan[index] !== 'Full' && productFields.customerAcknowledgement) {
      delete productFields.customerAcknowledgement;
    }
    // CONNECT card related fields are not needed when validating
    // form because of the iframe so we must delete them
    if (product.includes('connect')) {
      Object.keys(productFields).forEach((field) => {
        if (field.includes('Card')) {
          delete productFields[field];
        }
      });
    }
  });

  if (products.length === 1) {
    return fieldsCopy[products[0]];
  }

  const bundleFields: Record<Field['key'], Field> = {};

  Object.values(fieldsCopy).forEach((fields) =>
    Object.values(fields).forEach((field) => (bundleFields[field.key] = field)),
  );

  return bundleFields;
};

export const getProductErrors = (productErrors: ProductErrorMessages): ProductMsgTuple[] | null => {
  const errorMessagesObj = Object.values(productErrors).length ? productErrors : null;

  return (
    errorMessagesObj &&
    Object.keys(errorMessagesObj).reduce((acc, product) => {
      const { subtext, messageKey } = productErrors[product];
      const message = subtext || messageKey;
      if (!message) return acc;

      const hasErrorMsgIdx = acc.findIndex(([, msg]) => msg === message);

      if (hasErrorMsgIdx >= 0) {
        const updatedItem: ProductMsgTuple = [null, message];

        return acc.map((item, id) => (hasErrorMsgIdx === id ? updatedItem : item));
      } else {
        acc.push([product, message]);

        return acc;
      }
    }, [] as ProductMsgTuple[])
  );
};

export const useGetMarketingFields = (): PostBindFields => {
  const useMarketingField = useFieldWithPrefix('', 'marketing');

  return {
    bindFields: {
      referenceNumber: useMarketingField('referenceNumber'),
      siteUseRate: useMarketingField('siteUseRate'),
      recommendationRate: useMarketingField('recommendationRate'),
      lastQuestionRate: useMarketingField('lastQuestionRate'),
    },
  };
};

export const createSapiAnalyticsEvent = (
  products: Product[],
  purchaseResults: PurchaseResults,
  showRetryProducts: boolean,
  element: string,
  event: 'render' | 'select' | 'click' | 'captured' | 'update',
): {
  element: string;
  event: 'render' | 'select' | 'click' | 'captured' | 'update';
  eventDetail: string;
} => {
  let failureType = '';
  const failedProducts = `[${purchaseResults.failed.join(', ')}]`;
  const successfulProducts = `[${purchaseResults.purchased.join(', ')}]`;
  const retryProducts = `[${purchaseResults.retry.join(', ')}]`;

  if (products.length === 1) {
    failureType = getProductNameFromProduct(products[0]);
  } else {
    failureType = `bundle - ${
      purchaseResults.failed.length > 1
        ? 'both'
        : getProductNameFromProduct(purchaseResults.failed[0])
    }`;
  }

  const analyticsDetails = {
    failureType,
    failedProducts,
    successfulProducts,
    retryProducts: showRetryProducts ? retryProducts : '',
  };

  return { element, event, eventDetail: JSON.stringify(analyticsDetails) };
};

export const getPolicyNumber = (
  purchaseResponse: PurchaseResponse,
  offer: CheckoutOfferDetails,
  productStr: string,
): string =>
  purchaseResponse.policies?.[productStr].policyNumber || offer?.offer.details.policyNumber || '';

export const getProductsAndPolicyNumbers = (
  purchaseResponse: PurchaseResponse,
  productsSummary: PostBindSummary[],
): {
  policyNumberPostBind: string[];
  productsSelected: Product[];
} =>
  productsSummary.reduce(
    (acc, { offerCheckoutDetails, product }) => {
      if (offerCheckoutDetails) {
        acc.policyNumberPostBind.push(
          getPolicyNumber(purchaseResponse, offerCheckoutDetails, product),
        );
        acc.productsSelected.push(product);
      }

      return acc;
    },
    { policyNumberPostBind: [], productsSelected: [] } as {
      policyNumberPostBind: string[];
      productsSelected: Product[];
    },
  );

export const getProductsToUpdate = (
  productsSummary: PostBindSummary[],
  purchased: Product[],
  fieldsByProduct: Record<Product, PaymentFields>,
): Product[] =>
  productsSummary.reduce((acc, { product }) => {
    if (product && !purchased.includes(product)) {
      const fields = fieldsByProduct[product];

      acc.push(product);
      if (fields) {
        if (fields.creditCardCardNumber) {
          fields.creditCardCardNumber.props.actionOnChange('');
          fields.creditCardExpirationDate.props.actionOnChange('');
          fields.creditCardFullName.props.actionOnChange('');
        }
        if (fields.costcoCardNumber) {
          fields.costcoCardNumber.props.actionOnChange('');
          fields.costcoCardExpirationDate.props.actionOnChange('');
          fields.costcoCardFullName.props.actionOnChange('');
        }
        fields.routingNumber.props.actionOnChange('');
        fields.accountNumber.props.actionOnChange('');
      }
    }

    return acc;
  }, [] as Product[]);

export const useResetPaymentTypes = (): {
  resetPaymentTypes: (productsSummary: PostBindSummary[], purchased: Product[]) => void;
} => {
  const dispatch = useDispatch();

  return {
    resetPaymentTypes: (productsSummary: PostBindSummary[], purchased: Product[]) =>
      productsSummary.forEach(({ offerCheckoutDetails, product, productName, payment }) => {
        if (
          !payment.paymentType &&
          offerCheckoutDetails?.offer &&
          product &&
          !purchased.includes(product)
        ) {
          dispatch(
            setPaymentType({
              product,
              paymentType: defaultPaymentByProductType[productName],
            }),
          );
        }
      }),
  };
};
