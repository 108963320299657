// policy
export const AUTO_POLICY_EFFECTIVE_DATE_VARIANT_DAY = 'policy.auto.effectiveDate.variant.days';

// vehicle
export const GARAGE_ADDRESS_REF_SUFFIX = 'garage.address.ref';
export const VEHICLE_ADDRESS_REF = 'vehicle.address.ref';

// driver
export const DRIVER_LICENSE_CONFIRM = 'driver.primary.license.number.confirm';
export const INCIDENT_REF_SUFFIX = 'incident.ref';
export const PRIOR_INSURANCE_REF_SUFFIX = 'priorInsurance.ref';
export const MILITARY_DEPLOYMENTS_REF_SUFFIX = 'militaryDeployment.ref';
export const DRIVER_UNLICENSED = 'LICENSE.STATUS.UNLICENSED';
export const DRIVER_REVOKED = 'LICENSE.STATUS.REVOKED';
export const DRIVER_EXPIRED = 'LICENSE.STATUS.EXPIRED';
export const DRIVER_LEARNERS_PERMIT = 'LICENSE.STATUS.LEARNERS_PERMIT';
export const DRIVER_VALID = 'LICENSE.STATUS.VALID_US';
export const DRIVER_INTL = 'LICENSE.STATUS.INTERNATIONAL';

// to store the driver refs with invalid DL number
export const INVALID_DL_DRIVER_REFS = 'static.invalidDLDriverRefs';
