import { Grid } from '@mui/material';

import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { GridItem } from '@ecp/components';
import { PRIMARY_INSURED_PERSON_LOCK } from '@ecp/features/sales/shared/constants';
import {
  DateOfBirthRecallQuestion,
  EmailQuestion,
  FirstNameQuestion,
  LastNameQuestion,
  ProductSelectQuestion,
  RetrieveZipCodeQuestion,
} from '@ecp/features/sales/shared/questions';
import { useField, usePniRef } from '@ecp/features/sales/shared/store';

import { useStyles } from './RetrieveFormQuestions.styles';

export const RetrieveFormQuestions: React.FC<Record<string, unknown>> = (props) => {
  const { classes } = useStyles();
  const pniRef = usePniRef();
  const primaryInsuredPersonLock = useField(PRIMARY_INSURED_PERSON_LOCK);
  const disabled = Boolean(primaryInsuredPersonLock.props.value);
  const hideRetrieveProductQuestionFeature =
    flagValues[FeatureFlags.HIDE_RETRIEVE_PRODUCT_QUESTION];

  return (
    <div>
      <Grid container>
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
          <FirstNameQuestion
            id='getBackFirstName'
            trackingName='GetBackFirstName'
            personRef={pniRef}
            disabled={disabled}
          />
        </GridItem>
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnRight}>
          <LastNameQuestion
            id='getBackLastName'
            trackingName='GetBackLastName'
            personRef={pniRef}
            disabled={disabled}
          />
        </GridItem>
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
          <DateOfBirthRecallQuestion
            id='getBackDateOfBirth'
            trackingName='GetBackDOB'
            personRef={pniRef}
            disabled={disabled}
          />
        </GridItem>
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnRight}>
          <RetrieveZipCodeQuestion disabled={disabled} />
        </GridItem>
        <GridItem topSpacing='sm' xs={12}>
          <EmailQuestion
            id='getBackEmailAddress'
            trackingName='GetBackEmailAddress'
            personRef={pniRef}
            disabled={disabled}
          />
        </GridItem>
        {!hideRetrieveProductQuestionFeature && (
          <GridItem topSpacing='sm' xs={12}>
            <ProductSelectQuestion
              id='productSelectQuote'
              disabled={disabled}
              trackingName='GetProductSelectQuestion'
            />
          </GridItem>
        )}
      </Grid>
    </div>
  );
};
