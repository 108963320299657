import { LogoProgressiveHomeByHomesiteImageUrl } from '@ecp/themes/base';

import type { Metadata } from './metadata.js';
import baseMetadata from './metadata.js';

const metadata: Metadata = {
  ...baseMetadata,
  styledLogoImage: true,
  primaryLogo: LogoProgressiveHomeByHomesiteImageUrl,
  showVerticalDivider: true,
};

export default metadata;
