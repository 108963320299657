import { useEffect } from 'react';

import { DECLINATION_NOTICE_DISPLAYED_PAGE_PATH } from '@ecp/features/sales/shared/constants';
import {
  getCurrentPage,
  getDeclinationNoticeDisplayedPagePath,
  getErroredProductsFromOffers,
  updateAnswers,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

import { DeclinationNotices } from '../DeclinationNotices/DeclinationNotices';
import metadata from './metadata';

interface Props {
  className?: string;
}
export const DeclinationNoticesWrapper: React.FC<Props> = ({ className }) => {
  const currentPage = useSelector(getCurrentPage);
  const erroredProducts = useSelector(getErroredProductsFromOffers);

  const declinationNoticeDisplayed = useSelector(getDeclinationNoticeDisplayedPagePath);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentPage !== declinationNoticeDisplayed && erroredProducts.length > 0) {
      dispatch(
        updateAnswers({ answers: { [DECLINATION_NOTICE_DISPLAYED_PAGE_PATH]: currentPage } }),
      );
    }
  }, [currentPage, declinationNoticeDisplayed, dispatch, erroredProducts.length]);

  return !metadata.shouldHideDeclinationNotice ? (
    <DeclinationNotices className={className} />
  ) : null;
};
