import { useAddFields } from '@ecp/features/sales/form';
import { Slider } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const HalfBathQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const halfBaths = useHomePropertyField('halfBaths');

  const { groupLabel = halfBaths.question?.title, trackingName = 'half_baths_slider' } = props;

  useAddFields({ halfBaths });

  if (!halfBaths.exists) return null;

  return <Slider {...halfBaths.props} groupLabel={groupLabel} trackingName={trackingName} />;
};
