import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({
  name: 'EstimatedTooltip',
})((theme) => ({
  estimated: {
    ...theme.components.MuiButton,
    ...theme.typography.body1Underline,
    color: theme.palette.text.link,
    textAlign: 'center',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    borderStyle: 'none',
    padding: 0,
    '&:hover': {
      color: theme.palette.text.hover,
    },
  },
  inline: {
    display: 'inline',
    textAlign: 'left',
    marginLeft: 5,
  },
}));
