import { TooltipWithIcon } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const HasPoolQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const hasPool = useHomePropertyField('hasPool');
  const helpText = hasPool.question?.helpText;
  const { trackingName = 'has_pool_drop_down', groupLabel = hasPool?.question?.title } = props;

  useInitValues({ [hasPool.key]: hasPool.question.defaultValue });

  useAddFields({ hasPool });

  if (!hasPool.exists) return null;

  return (
    <Select
      {...hasPool.props}
      fullWidth={false}
      groupLabel={
        <>
          {groupLabel}
          <TooltipWithIcon title={helpText} />
        </>
      }
      id='hasPool'
      trackingName={trackingName}
      inputButtonAriaLabel='hasPool'
    />
  );
};
