import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CheckboxAcknowledgements' })((theme) => ({
  root: theme.typography.body1,
  verticalAlignMiddle: {
    ...theme.typography.body1,
    margin: 0,
    paddingLeft: 12,
    listStyle: 'none',
  },
  verticalAlignLinks: {
    paddingLeft: 33,
  },
  label: {
    padding: 0,
    font: 'inherit',
    color: 'inherit',
    '&.Mui-error': {
      color: 'inherit',
    },
    display: 'flex',
    alignItems: 'flex-start',
  },
  checkbox: {},
  documentFormLink: {
    textAlign: 'left',
  },
}));
