import { wrapThunkActionWithErrHandler } from '../../util/wrapThunkActionWithErrHandler';
import { postSessionRenew } from '../api';
import { getDalSessionId } from '../selectors';

export const renewSession = wrapThunkActionWithErrHandler<void>(
  () => async (_dispatch, getState) => {
    const state = getState();
    const dalSessionId = getDalSessionId(state);

    if (!dalSessionId) {
      return;
    }

    await postSessionRenew({ dalSessionId });
  },
  'renewSession',
);
