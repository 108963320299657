import { createAction } from '@reduxjs/toolkit';

import type { SapiTarget } from './types';

export const setApiStart = createAction<string>('@api/start');
export const setApiComplete = createAction<string>('@api/complete');
export const setSapiTarget = createAction<SapiTarget>('@api/sapiTarget');

export type ApiActions = ReturnType<
  typeof setApiStart | typeof setApiComplete | typeof setSapiTarget
>;
