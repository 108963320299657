import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CoveragesSummary' })((theme) => ({
  root: {
    ...theme.mixins.form,
    ...theme.mixins.withRightSidebar.contentContainer,
    justifyContent: 'center',
  },
  addSpacing: theme.mixins.addSpacing,
  addSpacingVert: {
    marginTop: 40,
  },
  coveragesSummaryHeader: {
    boxShadow: 'none',
    paddingBottom: 10,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    minHeight: 30,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  coveragesSummaryBlock: {
    background: theme.palette.grey[100],
    padding: 20,
  },
  discountsSummaryBlock: {
    padding: '20px 20px 15px',
  },
}));
