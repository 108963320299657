import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<{
  drawerAnchor: 'bottom' | 'right';
}>({
  name: 'PolicyStartDateCalendar',
})((theme, { drawerAnchor }) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    maxHeight: 75,
    width: '100%',
    gap: 12,
    cursor: 'pointer',
  },
  iconButton: {
    padding: 0,
    height: 63, // just high enough to contain the icon
  },
  iconAndDateContainer: {
    alignItems: 'center',
    gap: 13,
    flexDirection: 'row',
    display: 'flex',
    maxHeight: 75,
  },
  calendarIconContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    img: { maxWidth: 68 },
  },
  monthTypography: {
    position: 'absolute',
    top: 12,
    left: '50%',
    transform: 'translateX(-50%)',
    ...theme.typography.body2Bold,
    color: theme.palette.primary.dark,
    zIndex: 1,
  },
  dateTypography: {
    position: 'absolute',
    top: 45,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontWeight: 700,
    fontSize: 28,
    color: theme.palette.common.black,
  },
  editContainer: {
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingBottom: 12,
  },
  costcoStyleEditButton: {
    ...theme.typography.button2,
    color: theme.palette.text.link,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.text.link,
    },
  },
  zillowStyleEditButton: {
    ...theme.typography.body3Bold,
    color: theme.palette.primary.dark,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.dark,
    },
  },
  costcoStyleEditIcon: {
    color: theme.palette.text.link,
    ...theme.mixins.setColorSvg(theme.palette.text.link),
  },
  zillowStyleEditIcon: {
    color: theme.palette.primary.dark,
    ...theme.mixins.setColorSvg(theme.palette.primary.dark),
  },
  policyStartDateTxt: {
    ...theme.typography.body1Bold,
    color: theme.palette.error.darkText,
  },
  longDate: {
    ...theme.typography.body3,
    color: theme.palette.text.primary,
  },
  drawerTitle: theme.typography.h2,
  drawerPaper: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: drawerAnchor === 'right' ? 450 : undefined,
    },
  },
  drawerBody: {
    padding: '0 20px 46px',
    [theme.breakpoints.up('lg')]: {
      padding: '0 50px 46px',
    },
  },
  // without this fix, the drawer would pop up, disappear, and then pop back up again
  drawerModalAnchorRightJitterFix: {
    position: 'fixed',
    overflow: 'unset',
  },
  drawerFooter: {
    ...(drawerAnchor === 'right' && {
      position: 'absolute',
      bottom: 0,
      width: '100%',
    }),
  },
  continueButton: {
    ...theme.mixins.formAll.next,
    marginTop: 0,
  },
  pageDrawerModalOnEmbeddedModule: {
    // "absolute" and "overflow: hidden" are needed to prevent adding white space to the bottom of the page
    position: 'absolute', // to remove the "fixed" to make the drawer show up inside the embedded module modal
    overflow: 'hidden',
    '@media only screen and (max-width: 481px)': {
      position: 'fixed',
    },
  },
  pageDrawerModalZIndexFixOnMonolineAndBundleApps: {
    zIndex: theme.zIndex.drawer + 11, // to cover the header
  },
  fullWidth: {
    width: '100%',
  },
  policyHelpText: {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
  },
}));
