import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({
  name: 'SignatureAcknowledgements',
})((theme) => ({
  sniGrid: {
    [theme.breakpoints.down('md')]: {
      paddingTop: '0px !important',
    },
  },
}));
