import { Fragment, useCallback, useState } from 'react';

import { Grid } from '@mui/material';

import { lowerFirst, upperFirst } from '@ecp/utils/common';

import { env } from '@ecp/env';
import { Button, PhoneLink } from '@ecp/features/sales/shared/components';
import { getOfferProductsSelected } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import {
  getReducedProductNameFromProduct,
  isProductAuto,
  isProductHome,
} from '@ecp/features/shared/product';
import { partner } from '@ecp/partners';

import { Acknowledgement } from '../../formBody/CheckoutFormBody/Acknowledgement';
import { CarrierMessaging } from '../../formBody/CheckoutFormBody/CheckoutFormBody/CarrierMessaging';
import { ImportantInformation } from '../../formBody/CheckoutFormBody/CheckoutFormBody/ImportantInformation';
import { CheckoutPaymentMethod } from '../../formBody/CheckoutFormBody/CheckoutPaymentMethod/CheckoutPaymentMethod';
import { RecurringPaymentSetUp } from '../../formBody/CheckoutFormBody/RecurringPaymentSetUp';
import { getSignatures } from '../../state/acknowledgements';
import { getPaymentOptions, setPaymentPlan, setPaymentType } from '../../state/paymentoptions';
import type { CreditCardType } from '../../state/purchase';
import {
  setAccountNumber,
  setCardNumber,
  setCreditCardType,
  setExpirationDate,
  setName,
  setRoutingNumber,
} from '../../state/purchase';
import type { PostBindSummary, PurchaseResults } from '../../types';
import { useGetCreditCardType, useGetSelectedProductsCheckoutFields } from '../../util';
import metadata from '../CheckoutForm/metadata';
import { useStyles } from './PostBindRetryPaymentForm.styles';

interface Props {
  onResubmitPayment: (() => Promise<void>) | undefined;
  productsSummary: PostBindSummary[];
  purchaseResults: PurchaseResults;
  hasTokens: boolean;
  submittingPayment: boolean;
  hasBankPayToken: boolean;
  hasRecurringPayToken: boolean;
}

export const PostBindRetryPaymentForm: React.FC<Props> = (props) => {
  const {
    onResubmitPayment,
    productsSummary,
    purchaseResults,
    hasTokens,
    submittingPayment,
    hasBankPayToken,
    hasRecurringPayToken,
  } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const paymentOptions = useSelector(getPaymentOptions);
  const [reuseAutoPayment, setReuseAutoPayment] = useState(false);
  const offerProductsSelected = useSelector(getOfferProductsSelected);
  const fields = useGetSelectedProductsCheckoutFields(offerProductsSelected);
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const handleCallUs = useCallback(() => setShowPhoneNumber(true), [setShowPhoneNumber]);
  const home = productsSummary.find(({ product }) => isProductHome(product));
  const auto = productsSummary.find(({ product }) => isProductAuto(product));
  const autoCreditCardType = useGetCreditCardType(auto?.product, 'creditCard');
  const autoCostoCardType = useGetCreditCardType(auto?.product, 'costcoVisa');
  const signatures = useSelector(getSignatures);

  const isConnectAutoCC = productsSummary.some(
    ({ product, payment }) =>
      isProductAuto(product) &&
      product.includes('connect') &&
      (payment.paymentType === 'CreditCard' || payment.paymentType === 'CostcoVisa'),
  );
  const syncPaymentType = useCallback(
    (target: PostBindSummary, source: PostBindSummary) => {
      const sourceFields = fields[source.product];
      const targetFields = fields[target.product];

      return (newType: string) => {
        if (isConnectAutoCC) {
          setReuseAutoPayment(false);
        } else {
          // payment types are stored with first letter capitalized, but type PaymentMethod are strings with first letter lowercase
          const selectedPaymentType = upperFirst(newType);

          if (selectedPaymentType) {
            dispatch(setPaymentType({ product: target.product, paymentType: selectedPaymentType }));
            targetFields.paymentMethod.props.actionOnChange(selectedPaymentType);

            // home should not re-use auto payment plan, but we need to default a payment plan according to home payment type
            const genericAutoPaymentType =
              selectedPaymentType === 'CostcoVisa' ? 'CreditCard' : selectedPaymentType;

            const availablePaymentOptions = paymentOptions[
              target.product
            ].carrierPaymentOptions.filter(
              (option) => option?.paymentType === genericAutoPaymentType,
            );
            const filteredOptions = availablePaymentOptions.filter(
              (option) => option.paymentPlan === target.payment.paymentPlan,
            );

            // only update home payment plan if the current home payment plan/type pair is invalid
            if (filteredOptions.length === 0 && availablePaymentOptions[0]?.paymentPlan) {
              dispatch(
                setPaymentPlan({
                  product: target.product,
                  paymentPlan: availablePaymentOptions[0].paymentPlan,
                }),
              );
            }

            // take payment values from auto payment and apply to home
            if (selectedPaymentType === 'EFT') {
              const autoRoutingNumber = sourceFields.routingNumber.value as string;
              dispatch(
                setRoutingNumber({
                  product: target.product,
                  routingNumber: autoRoutingNumber,
                }),
              );
              targetFields.routingNumber.props.actionOnChange(autoRoutingNumber);

              const autoAccountNumber = sourceFields.accountNumber.value as string;
              dispatch(
                setAccountNumber({
                  product: target.product,
                  accountNumber: autoAccountNumber,
                }),
              );
              targetFields.accountNumber.props.actionOnChange(autoAccountNumber);
            }

            if (selectedPaymentType === 'CreditCard' || selectedPaymentType === 'CostcoVisa') {
              const autoCreditCardNumber =
                selectedPaymentType === 'CreditCard'
                  ? sourceFields.creditCardCardNumber.props.value
                  : sourceFields.costcoCardNumber.props.value;
              const autoCreditCardExpirationDate =
                selectedPaymentType === 'CreditCard'
                  ? sourceFields.creditCardExpirationDate.props.value
                  : sourceFields.costcoCardExpirationDate.props.value;
              const autoCreditCardFullName =
                selectedPaymentType === 'CreditCard'
                  ? sourceFields.creditCardFullName.props.value
                  : sourceFields.costcoCardFullName.props.value;

              const downcaseType = lowerFirst(selectedPaymentType) as CreditCardType;

              const autoCardType =
                selectedPaymentType === 'CreditCard' ? autoCreditCardType : autoCostoCardType;
              if (autoCardType) {
                dispatch(
                  setCreditCardType({
                    creditCardType: autoCardType,
                    product: target.product,
                    type: downcaseType,
                  }),
                );
              }

              dispatch(
                setCardNumber({
                  cardNumber: autoCreditCardNumber || '',
                  product: target.product,
                  type: downcaseType,
                }),
              );
              dispatch(
                setName({
                  fullName: autoCreditCardFullName || '',
                  product: target.product,
                  type: downcaseType,
                }),
              );

              dispatch(
                setExpirationDate({
                  expirationDate:
                    autoCreditCardExpirationDate !== 'Invalid date'
                      ? autoCreditCardExpirationDate
                      : '',
                  product: target.product,
                  type: downcaseType,
                }),
              );

              if (selectedPaymentType === 'CreditCard') {
                targetFields.creditCardCardNumber.props.actionOnChange(autoCreditCardNumber);
                targetFields.creditCardExpirationDate.props.actionOnChange(
                  autoCreditCardExpirationDate,
                );
                targetFields.creditCardFullName.props.actionOnChange(autoCreditCardFullName);
              } else {
                targetFields.costcoCardNumber.props.actionOnChange(autoCreditCardNumber);
                targetFields.costcoCardExpirationDate.props.actionOnChange(
                  autoCreditCardExpirationDate,
                );
                targetFields.costcoCardFullName.props.actionOnChange(autoCreditCardFullName);
              }
            }
          }
        }
      };
    },
    [dispatch, autoCostoCardType, autoCreditCardType, isConnectAutoCC, fields, paymentOptions],
  );

  const handleReuseAutoPaymentCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      setReuseAutoPayment(newChecked);

      if (newChecked && home && auto) {
        syncPaymentType(home, auto)(auto.payment.paymentType || '');
      }
    },
    [syncPaymentType, home, auto],
  );

  return (
    <Grid container justifyContent='center'>
      <Grid item xs={12} className={classes.paymentContainer}>
        <div>
          {productsSummary.map(
            ({ product, productName, offerCheckoutDetails }) =>
              product &&
              offerCheckoutDetails?.offer &&
              purchaseResults.retry.includes(product) &&
              !purchaseResults.purchased.includes(product) && (
                <Fragment key={`postbind-retry-pmt-${product}`}>
                  <h2 className={classes.dialogTitle}>
                    Re-submit your {productName} payment information
                  </h2>
                  <p className={classes.paymentHeader}>
                    Please re-enter your payment information or select an alternate method. You will
                    not be charged multiple times.
                  </p>
                  <CheckoutPaymentMethod
                    label={`Enter your ${productName} policy payment information`}
                    selectedProduct={offerCheckoutDetails.selectedProduct}
                    offerDetails={offerCheckoutDetails.offer}
                    policyStartDate={offerCheckoutDetails.policyStartDate}
                    paymentOptions={paymentOptions[product]}
                    coverageType={productName}
                    handleReuseAutoPaymentCheck={handleReuseAutoPaymentCheck}
                    reuseAutoPayment={reuseAutoPayment}
                    // TODO Add renters or convert home to property
                    syncPropertyToAuto={home && auto ? syncPaymentType(home, auto) : undefined} // This can now be made dynamic
                    isConnectAutoCC={isConnectAutoCC}
                    hasTokens={hasTokens}
                    hasBankPayToken={hasBankPayToken}
                  />
                  {metadata.collectRecurringPay && (
                    <RecurringPaymentSetUp
                      selectedProduct={offerCheckoutDetails.selectedProduct}
                      coverageType={productName}
                      hasToken={hasRecurringPayToken}
                    />
                  )}
                  {!env.static.isAgent && <ImportantInformation />}

                  {metadata.showCarrierMessaging && <CarrierMessaging coverageType={productName} />}
                  {metadata.showAcknowledgement && (
                    <Acknowledgement
                      signatureAcknowledgements={
                        signatures[getReducedProductNameFromProduct(product)]
                      }
                      selectedProduct={offerCheckoutDetails.selectedProduct}
                      coverageType={productName}
                    />
                  )}
                </Fragment>
              ),
          )}
        </div>
        <div className={classes.contactContainer}>
          {metadata.hideContactInfo && (
            <div data-testid='TopPhoneNumberLink' className={classes.phone}>
              {!showPhoneNumber ? (
                <PhoneLink
                  key='a'
                  withIcon
                  withLinkStyle
                  number='Call us'
                  onClick={handleCallUs}
                  trackingName='CallUsLink'
                  trackingLabel='PhoneNumber'
                />
              ) : (
                <PhoneLink
                  key='b'
                  withIcon
                  withLinkStyle
                  number={partner.shared.servicePhoneNumber}
                />
              )}
            </div>
          )}
          <Button
            variant='primary'
            onClick={onResubmitPayment}
            className={classes.submitButton}
            isProcessing={submittingPayment}
          >
            Re-submit payment
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};
