import { useMemo } from 'react';

import { Grid } from '@mui/material';

import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { GridItem } from '@ecp/components';
import { env } from '@ecp/env';
import { DriverLicenseConfirmQuestion } from '@ecp/features/sales/quotes/auto';
import { AdvisorNameSearch } from '@ecp/features/sales/shared/ameriprise-advisor';
import { CallRecordingOnVisibilityWrapper } from '@ecp/features/sales/shared/components';
import { EmailQuestion } from '@ecp/features/sales/shared/questions';
import {
  getLexisNexisOptInAnswer,
  getPersonInfo,
  getPniPersonInfo,
  getPrimaryInsuredStateCode,
  getSelectedPaymentPlan,
  useField,
  usePniRef,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { PolicySummary } from '@ecp/features/sales/shared/types';
import type { Product } from '@ecp/features/shared/product';
import {
  getProductNameFromProduct,
  isProductAuto,
  isProductProperty,
} from '@ecp/features/shared/product';

import { MembershipDetailsQuestion } from '../../../components';
import { sniEmailRequiredStates } from '../../../metadata';
import { getPaymentNumber } from '../../../state';
import type { CheckoutOffers, PaymentMethods, Signatures } from '../../../types';
import { CheckoutContactInformation } from './CheckoutContactInformationSection';
import { useStyles } from './CheckoutFormBody.styles';
import { CheckoutReview } from './CheckoutReview';
import { CheckoutSection } from './CheckoutSection';
import { ImportantInformation } from './ImportantInformation';
import metadata from './metadata';
import { NamedInsuredContactInfoQuestions } from './NamedInsuredContactInfoQuestions/NamedInsuredContactInfoQuestions';
import { buildCheckoutReviewData, getSniPersonRef } from './util';

interface Props {
  products: Product[];
  offers: CheckoutOffers;
  signatures: Signatures;
  payments: PaymentMethods;
  handleReuseAutoPaymentCheck: (
    event: React.ChangeEvent<HTMLInputElement>,
    newChecked: boolean,
  ) => void;
  reuseAutoPayment: boolean;
  isConnectAutoCC: boolean;
  syncPropertyToAuto: (newType: string) => void;
  setIsPurchaseDisabled: (value: boolean) => void;
  isPatchFormInProgress: boolean;
  hasTokens: boolean;
  iAgreeOpen: boolean;
  hasBankPayToken: boolean;
  hasRecurringPayToken: boolean;
}

export const CheckoutFormBody: React.FC<Props> = (props) => {
  const {
    products,
    offers,
    signatures,
    payments,
    handleReuseAutoPaymentCheck,
    reuseAutoPayment,
    syncPropertyToAuto,
    isConnectAutoCC,
    hasTokens,
    setIsPurchaseDisabled,
    iAgreeOpen,
    hasBankPayToken,
    hasRecurringPayToken,
  } = props;
  const { classes, cx } = useStyles();

  const knowMortgageCompany = useField('static.checkout.home.mortgageKnowMortgageCompany');
  const phoneNumberRequired =
    !!metadata.phoneRequired ||
    (!!offers.property &&
      payments.property?.paymentType === 'Mortgage' &&
      knowMortgageCompany.value === false);

  const pniRef = usePniRef();
  const driver = useSelector(getPniPersonInfo);
  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const sniRef = useSelector(getSniPersonRef);
  const sniDriver = useSelector((state: RootStore) =>
    sniRef ? getPersonInfo(state, sniRef) : undefined,
  );
  const lexisNexisOptIn = useSelector(getLexisNexisOptInAnswer);
  const pniFeatureFlag = flagValues[FeatureFlags.PNIDL_NUMBER_CONFIRMATION];
  const checkoutPageContactInfoRedesign = flagValues[FeatureFlags.CHECKOUT_CONTACT_INFORMATION];
  const checkoutPageRedesign = flagValues[FeatureFlags.CHECKOUT_PAGE_REDESIGN];

  const paymentNumber = useSelector((state: RootStore) => getPaymentNumber(state, products));
  const premiumPlan = useSelector(getSelectedPaymentPlan);

  const policiesForCheckoutReview = useMemo(
    () =>
      Object.entries(offers).reduce((acc, [reducedProductName, offer]) => {
        const payment = payments[reducedProductName];
        if (offer && payment) {
          const productName = getProductNameFromProduct(offer.selectedProduct);
          const checkoutReviewData = buildCheckoutReviewData(
            productName,
            offer,
            payment,
            paymentNumber[offer.selectedProduct].paymentNumber,
            premiumPlan,
          );
          // TODO Make sure this is actually needed, I had to add paymentType to PolicySummary type because of that
          // TODO See if we can merge PolicySummary and PolicySummaryProps types together
          // TODO and if we actually need CheckoutReview component, seems like it's just a passthrough
          if (isProductProperty(offer.selectedProduct))
            checkoutReviewData.paymentType = payment.paymentType;
          acc.push(checkoutReviewData);
        }

        return acc;
      }, [] as PolicySummary[]),
    [offers, paymentNumber, payments, premiumPlan],
  );

  const checkoutSection = Object.entries(offers).map(([reducedProductName, offer]) => {
    if (offer) {
      const productName = getProductNameFromProduct(offer.selectedProduct);
      const isAuto = isProductAuto(offer.selectedProduct);
      const isProperty = isProductProperty(offer.selectedProduct);
      const payment = payments[reducedProductName];

      return (
        <GridItem
          key={reducedProductName}
          topSpacing={checkoutPageRedesign ? 'sm' : 'lg'}
          className={cx(
            env.static.isAgent
              ? classes.agentCheckoutSectionContainer
              : classes.checkoutSectionContainer,
          )}
        >
          <CheckoutSection
            coverageType={productName}
            offerDetails={offer}
            handleReuseAutoPaymentCheck={handleReuseAutoPaymentCheck}
            reuseAutoPayment={reuseAutoPayment}
            signatureAcknowledgements={signatures[reducedProductName]}
            hasTokens={hasTokens}
            syncPropertyToAuto={isAuto ? syncPropertyToAuto : undefined}
            isConnectAutoCC={isProperty ? isConnectAutoCC : undefined}
            paymentType={isProperty ? payment?.paymentType : undefined}
            hasBankPayToken={hasBankPayToken}
            hasRecurringPayToken={hasRecurringPayToken}
          />
        </GridItem>
      );
    }

    return null;
  });

  return (
    <>
      <Grid container>
        <GridItem topSpacing='lg'>
          <MembershipDetailsQuestion />
        </GridItem>

        {env.static.isAgent ? (
          <CallRecordingOnVisibilityWrapper
            trackingName='checkoutPage'
            trackVisibility={!iAgreeOpen}
          >
            {checkoutSection}
          </CallRecordingOnVisibilityWrapper>
        ) : (
          checkoutSection
        )}

        {/* PLPC don't need to show email and phone on checkout page */}
        {checkoutPageContactInfoRedesign && metadata.showEmailAndPhoneQuestions && (
          <GridItem topSpacing='lg' xs={12}>
            <CheckoutContactInformation phoneNumberRequired={phoneNumberRequired} />
          </GridItem>
        )}

        {metadata.showReviewSection && (
          <GridItem topSpacing='lg' xs={12}>
            <CheckoutReview policies={policiesForCheckoutReview} />
          </GridItem>
        )}
      </Grid>
      {/* PLPC don't need to show email and phone on checkout page */}
      {!checkoutPageContactInfoRedesign && metadata.showEmailAndPhoneQuestions && (
        <>
          <GridItem topSpacing='lg' xs={12}>
            <p className={classes.confirmLabel}>
              {metadata.getContactHeaderText(driver.firstName)}
            </p>
          </GridItem>
          <NamedInsuredContactInfoQuestions
            phoneNumberRequired={phoneNumberRequired}
            namedInsuredRef={pniRef}
          />
        </>
      )}
      {metadata.showAdvisorNameSearchQuestions && <AdvisorNameSearch />}
      {!env.static.isAgent && lexisNexisOptIn && pniFeatureFlag && offers.auto && (
        <GridItem topSpacing='sm' xs={12} md={6} lg={6} className={classes.columnLeft}>
          <DriverLicenseConfirmQuestion setIsPurchaseDisabled={setIsPurchaseDisabled} />
        </GridItem>
      )}
      {metadata.showEmailAndPhoneQuestions &&
        env.static.isAgent &&
        sniRef &&
        sniDriver &&
        sniEmailRequiredStates.includes(stateCode) && (
          <>
            <p className={classes.provideLabel}>{`Provide ${sniDriver.firstName}'s email`}</p>
            <Grid container justifyContent='space-between'>
              <GridItem xs={12} md={6} lg={6} className={classes.columnLeft}>
                <EmailQuestion
                  id='SniEmailAddress'
                  personRef={sniRef}
                  label='Email'
                  trackingName='ConfirmSniEmailAddress'
                  helperText={metadata.emailHelpText}
                  fieldKey='email2-required'
                />
              </GridItem>
            </Grid>
          </>
        )}
      {!env.static.isAgent && (
        <GridItem topSpacing='md' xs={12}>
          <ImportantInformation />
        </GridItem>
      )}
    </>
  );
};
