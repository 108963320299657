import { useEffect } from 'react';

import {
  getDalSessionId,
  makeSurePrefillFlowInStore,
  updateAnswers,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

export const useTransitionPage = (): void => {
  const dispatch = useDispatch();
  const dealSessionId = useSelector(getDalSessionId);
  const handleIndicativeRetrieve = async (): Promise<void> => {
    if (dealSessionId) {
      await dispatch(updateAnswers({ answers: { isBindable: true } }));
      await dispatch(makeSurePrefillFlowInStore());
    }
  };
  useEffect(() => {
    handleIndicativeRetrieve();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
