import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({
  name: 'MonthlyPaymentsCheckoutCard',
})((theme) => ({
  // custom styles
  button: {
    margin: 0,
    width: '100%',
  },
  hideButtonText: {
    display: 'none',
  },
  spinnerOnly: {
    paddingRight: 15,
  },
  cardContainer: {
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.other.border}`,
    borderRadius: 4,
    marginTop: 10,
  },
  items: {
    marginTop: 10,
    paddingLeft: 5,
    paddingRight: 5,
  },

  product: {
    padding: '10px 0 15px',
  },
  footer: {
    padding: '0 10px',
  },
  sectionHeader: { ...theme.typography.h3, textAlign: 'right' },
  sectionText: { ...theme.typography.h3, textAlign: 'right' },

  recalculateCardContainer: {
    backgroundColor: theme.palette.error.light,
    borderRadius: 4,
  },
  monthlyPaymentsCardContainer: {
    borderRadius: 4,
    '& .MuiCardActions-root': {
      padding: '5px 10px 20px',
      [theme.breakpoints.down('md')]: {
        padding: '5px 10px 0',
      },
    },
  },
  recalculateContainer: {
    ...theme.typography.body2,
    margin: 'auto -15px',
    width: 'calc(100%  30px)',
    textAlign: 'center',
  },
  recalculateBody: {
    textAlign: 'left',
    marginTop: 10,
    marginLeft: 15,
    marginRight: 15,
  },
  recalculateButton: {
    margin: 'auto',
  },
  recalcButtonBg: {
    backgroundColor: theme.palette.other.caution,
    '&:hover': {
      backgroundColor: theme.palette.other.caution,
    },
  },
  infoIcon: {
    color: theme.palette.warning.main,
    display: 'block',
    margin: 'auto',
  },
  policyDurationHeaderColumn: {
    ...theme.typography.body1,
  },
  policyDurationHeader: theme.typography.body1Bold,
  policyDurationBundleHeaderColumn: {
    marginBottom: 5,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      marginBottom: 0,
    },
  },
  editIcon: {
    ...theme.mixins.setColorSvg(theme.palette.primary.main),
    cursor: 'pointer',
    marginLeft: 2,
    color: theme.palette.secondary.main,
  },
  policyCheckoutHeaders: {
    ...theme.typography.body2,
    color: theme.palette.grey[800],
  },
  policyBundleCheckoutHeaders: {
    ...theme.typography.body2,
    color: theme.palette.grey[800],
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  customizeButton: {
    color: theme.palette.text.primary,
  },
  installmentStatement: {
    '& > *': {
      ...theme.typography.body2,
      textAlign: 'center',
      margin: '15px auto 0',
    },
  },
  premiumFeesColumn: {
    textAlign: 'right',
  },
  toggleContainer: {
    marginBottom: 15,
    [theme.breakpoints.down('lg')]: {
      maxWidth: 180,
      marginBottom: 20,
    },
    '& .MuiToggleButton-root': {
      whiteSpace: 'nowrap',
    },
  },
  premiumFeesMonolineColumn: {
    textAlign: 'right',
  },
  premiumFeesBundleColumn: {
    ...theme.typography.h3,
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  premiumsDividerContainer: {
    margin: '0 auto',
    padding: '3px 0',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  rightColumn: {
    [theme.breakpoints.only('md')]: {
      borderLeft: `1px solid ${theme.palette.other.border}`,
      paddingLeft: 60,
    },
  },
  leftColumn: {
    [theme.breakpoints.only('md')]: {
      paddingRight: 60,
    },
  },
  bundleMonthlyPayments: {
    ...theme.typography.h4,
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  monthlyPayments: {
    ...theme.typography.h4,
    textAlign: 'right',
  },
  premiums: theme.typography.body2Bold,
  premiumFeesInfo: {
    ...theme.typography.body2Bold,
    color: theme.palette.text.primary,
    textAlign: 'right',
  },
  premiumDate: {
    ...theme.typography.button2Small,
    color: theme.palette.secondary.main,
    textAlign: 'right',
  },
  dueTodayText: {
    ...theme.typography.body2Bold,
    textAlign: 'right',
  },
  premiumsDivider: {
    backgroundColor: theme.palette.other.divider,
    margin: '15px 0',
    [theme.breakpoints.down('md')]: {
      margin: '5px 0 3px 0',
    },
  },
  bundlePremiumContainer: {
    marginTop: 10,
  },
  policyStartDate: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
    },
  },
  carrierButtons: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
    gap: 15,
  },
}));
