import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CheckoutCard' })((theme) => {
  const largeTextCommonStyles = {
    lineHeight: 1,
  };

  return {
    button: {
      width: '100%',
    },
    hideButtonText: {
      display: 'none',
    },
    spinnerOnly: {
      paddingRight: 15,
    },
    body: {
      marginTop: 10,
    },
    card: {
      border: `1px solid ${theme.palette.other.border}`,
      borderRadius: 4,
      boxShadow: 'none',
    },
    cardContainer: {},
    headerContainer: {},
    product: {
      padding: '0 0 15px',
      [theme.breakpoints.down('md')]: {
        padding: 0,
      },
    },
    header: {
      ...largeTextCommonStyles,
      ...theme.typography.h5,
      [theme.breakpoints.down('xl')]: theme.typography.h6,
    },
    footer: {
      padding: '5px 5px 15px 12px',
      gap: 15,
    },
    footerWithWideButton: {
      [theme.breakpoints.down('xl')]: {
        padding: '0 0 15px 2px',
      },
    },
    footerText: {
      ...largeTextCommonStyles,
      ...theme.typography.h5,
      [theme.breakpoints.down('xl')]: theme.typography.h6,
    },
    largeText: {
      ...largeTextCommonStyles,
      ...theme.typography.h2,
    },
    section: {
      padding: '0 5px',
      [theme.breakpoints.down('xl')]: {
        padding: 0,
      },
    },
    bundledPremium: {
      paddingBottom: 20,
      [theme.breakpoints.down('md')]: {
        paddingBottom: 0,
      },
    },
    sectionHeader: {
      ...theme.typography.h3,
      [theme.breakpoints.down('md')]: {
        ...theme.typography.h6,
      },
    },
    sectionText: {
      ...theme.typography.h2,
      textAlign: 'right',
      [theme.breakpoints.down('md')]: {
        ...theme.typography.h4,
        textAlign: 'right',
      },
    },
    subText: {
      ...theme.typography.body3,
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        ...theme.typography.body2,
        textAlign: 'center',
      },
    },
    details: {
      justifyContent: 'center',
      alignItems: 'flex-end',
      minWidth: 115,
      [theme.breakpoints.down('md')]: {
        justifyContent: 'left',
      },
    },
    singlePremiumAmount: {
      ...theme.typography.h1,
      textAlign: 'right',
      [theme.breakpoints.down('md')]: {
        ...theme.typography.h4,
        textAlign: 'right',
      },
    },
    horizontalDivider: {
      margin: '15px 0',
    },
    compactHorizontalDivider: {
      margin: '7px 0',
    },
    recalculateCardContainer: {
      backgroundColor: theme.palette.warning.light,
    },
    recalculateContainer: {
      ...theme.typography.body2,
      margin: 'auto -15px',
      width: 'calc(100% + 30px)',
      textAlign: 'center',
    },
    recalculateBody: {
      textAlign: 'left',
      marginTop: 10,
      marginLeft: 15,
      marginRight: 15,
    },
    recalculateButton: {
      margin: 'auto',
      [theme.breakpoints.down('xl')]: {
        ...theme.typography.body2,
      },
    },
    infoIcon: {
      display: 'block',
      margin: 'auto',
      ...theme.mixins.setColorSvg(theme.palette.warning.main),
    },
    protection: {
      ...theme.typography.body3,
      [theme.breakpoints.down('md')]: {
        ...theme.typography.body2,
      },
    },
    estimatedWrapper: {
      ...theme.typography.body3,
      marginTop: 5,
      [theme.breakpoints.down('md')]: {
        marginTop: 0,
      },
    },
    estimatedContainer: {
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'left',
      },
    },
    estimatedContainerBundle: {
      justifyContent: 'right',
    },
  };
});
