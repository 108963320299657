import { useCallback, useState } from 'react';

import { Button } from '@ecp/features/sales/shared/components';

import { PaymentInfoDisclosureDialog } from '../PaymentInfoDisclosureDialog';
import { useStyles } from './PolicyDifference.style';

export const PolicyDifference: React.FC = () => {
  const { classes } = useStyles();

  const [open, setOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setOpen((prevOpenState) => !prevOpenState);
  }, [setOpen]);

  return (
    <div className={classes.policyDifferenceContainer}>
      <Button
        variant='iconText'
        onClick={toggleOpen}
        trackingName='policy_total_diffence_link'
        trackingLabel='policy_total_difference'
        data-testid='policyTotalsDifferentLink'
      >
        Why are these policy totals different?
      </Button>
      <PaymentInfoDisclosureDialog
        open={open}
        onClose={toggleOpen}
        trackingName='policy_total_difference_close'
        trackingLabel='close'
      />
    </div>
  );
};
