import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'RecurringPaymentInfoDialog' })((theme) => ({
  root: {},

  dialogBody: {
    '& p': {
      marginBottom: 10,
    },
  },
}));
