import { useEffect } from 'react';

import { useEvent } from '@ecp/utils/react';

import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import {
  getOfferDetailsForProduct,
  getOfferProductsSelectedByType,
  startOverFlow,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

import { Dialog } from '../Dialog';
import { SessionExpiryContent } from './SessionExpiryContent';
import { useSessionTimer } from './useSessionTimer';
import {
  addContinueQuoteAnalytics,
  addSessionExpiryTimerAnalytics,
  getSessionExpiryLabels,
} from './util';

export const SessionExpiryTimerDialog: React.FC = () => {
  const dispatch = useDispatch();
  const onSessionExpiryClick = useEvent(async () => {
    await dispatch(startOverFlow());
  });
  const {
    showTimer,
    isSessionIdle,
    remaining,
    handleSessionExpiry,
    handleContinueSession,
    handleGetQuote,
  } = useSessionTimer({ enableTimer: true, onSessionExpiryClick });

  const { property: propertyOfferProduct } = useSelector(getOfferProductsSelectedByType);

  const propertyOfferDetails = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, propertyOfferProduct),
  );
  const offerExist = !!propertyOfferDetails;
  const { buttonText, titleText, trackingLabel, trackingName, trackingNameCloseIcon, eventDetail } =
    getSessionExpiryLabels(isSessionIdle, offerExist);
  const navigateToRetrievePage = useNavigateToPage(PagePath.LANDING_RETRIEVE);

  useEffect(() => {
    if (showTimer) addSessionExpiryTimerAnalytics(eventDetail, isSessionIdle);
  }, [eventDetail, isSessionIdle, showTimer]);

  const handleContinueQuote = useEvent(async (closeButton?: boolean) => {
    addContinueQuoteAnalytics(eventDetail, closeButton);
    handleSessionExpiry();
    await navigateToRetrievePage();
  });

  const handleSessionPromptButtonClickDialog = useEvent(async () => {
    if (!isSessionIdle) {
      handleContinueSession();
    } else if (offerExist) {
      handleContinueQuote();
    } else {
      handleGetQuote(eventDetail);
    }
  });

  const handleSessionPromptCloseIconClick = useEvent(async () => {
    if (!isSessionIdle) {
      handleContinueSession(true);
    } else if (offerExist) {
      handleContinueQuote(true);
    } else {
      handleGetQuote(eventDetail, true);
    }
  });

  return (
    <Dialog
      open={showTimer}
      titleText={titleText}
      onClose={handleSessionPromptCloseIconClick}
      actionButtonOnClick={handleSessionPromptButtonClickDialog}
      actionButtonLabel={buttonText}
      trackingNameButton={trackingName}
      trackingLabelButton={trackingLabel}
      trackingNameCloseIcon={trackingNameCloseIcon}
      trackingLabelCloseIcon={trackingLabel}
    >
      <SessionExpiryContent
        isSessionIdle={isSessionIdle}
        offerExist={offerExist}
        remaining={remaining}
      />
    </Dialog>
  );
};
