import { createReducer } from '@reduxjs/toolkit';

import { noop, unique } from '@ecp/utils/common';

import {
  setAvailablePaymentTypes,
  setPaymentOptionsApiGetSuccess,
  setPaymentOptionsApiPostSuccess,
  setPaymentPlan,
  setPaymentType,
} from './actions';
import type { PaymentsState } from './types';

const paymentOptionsInitial: PaymentsState = {
  paymentOptions: {},
};

export const paymentOptionsReducer = createReducer(paymentOptionsInitial, (builder) => {
  builder
    .addCase(setPaymentOptionsApiGetSuccess, (state, { payload }) => {
      const { offerSetId, paymentOptions } = payload.data;
      state.offerSetId = offerSetId;
      Object.keys(paymentOptions).forEach((product) => {
        if (state.paymentOptions[product])
          state.paymentOptions[product].carrierPaymentOptions =
            paymentOptions[product].carrierPaymentOptions;
        else state.paymentOptions[product] = paymentOptions[product];
        if (!state.paymentOptions[product].paymentPlan) {
          state.paymentOptions[product].paymentPlan = 'Full';
        }
      });
    })
    .addCase(setPaymentOptionsApiPostSuccess, noop)
    .addCase(setPaymentType, (state, { payload }) => {
      const { product, paymentType } = payload;
      state.paymentOptions[product].paymentType = paymentType;
    })
    .addCase(setPaymentPlan, (state, { payload }) => {
      const { product, paymentPlan } = payload;
      state.paymentOptions[product].paymentPlan = paymentPlan;
    })
    .addCase(setAvailablePaymentTypes, (state, { payload }) => {
      const { paymentOptions } = payload.data;
      Object.keys(paymentOptions).forEach((product) => {
        // Creates a set of available payment types that are unique for the product
        state.paymentOptions[product].availablePaymentTypes = unique(
          paymentOptions[product].carrierPaymentOptions.map(
            (paymentOption) => paymentOption.paymentType,
          ),
        );
      });
    })
    .addMatcher(
      (action): action is { type: '@global/teardownStore' } =>
        action.type === '@global/teardownStore',
      () => paymentOptionsInitial,
    );
});
