import { useState } from 'react';

import { Grid } from '@mui/material';
import dayjs from 'dayjs';

import { getShortMonth } from '@ecp/utils/date';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { useEvent } from '@ecp/utils/react';

import { Button, GridItem } from '@ecp/components';
import { PageDrawer } from '@ecp/features/sales/shared/components';
import {
  getIsBundleForOfferProductsSelected,
  getPolicyEfectiveDateParam,
  getProductPolicyStartDate,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import type { Product } from '@ecp/features/shared/product';
import {
  getProductDisplayNameFromProduct,
  getProductNameFromProduct,
} from '@ecp/features/shared/product';
import { GraphicCoverageCalendarImageUrl, IconUIEdit, useIsMobile } from '@ecp/themes/base';

import { PolicyStartDateForm } from '../PolicyStartDateForm';
import { getStartDateHelpText } from '../PolicyStartDateForm/util';
import { useStyles } from './PolicyStartDateCalendar.styles';

interface Props {
  product: Product;
  isEmbeddedModule?: boolean;
}

type OPTIONS = {
  usePageDrawerBuildInContinueButton: boolean;
  drawerAnchor: 'bottom' | 'right';
  isEmbeddedModule: boolean;
  fullWidthButton: boolean;
  editButtonStyle: 'costco' | 'zillow';
};

type GetOptions = ({ isEmbeddedModule }: { isEmbeddedModule?: boolean }) => OPTIONS;

const getCostcoCoveragePageRedesignOptions: GetOptions = () => {
  return {
    usePageDrawerBuildInContinueButton: false,
    drawerAnchor: 'right',
    isEmbeddedModule: false,
    fullWidthButton: false,
    editButtonStyle: 'costco',
  };
};

const getZillowExpressPathOptions: GetOptions = ({ isEmbeddedModule }) => {
  return {
    usePageDrawerBuildInContinueButton: true,
    drawerAnchor: isEmbeddedModule ? 'bottom' : 'right',
    isEmbeddedModule: !!isEmbeddedModule,
    fullWidthButton: isEmbeddedModule ? false : true,
    editButtonStyle: 'zillow',
  };
};

// zillow monoline coverage page redesign
const DEFAULT_OPTIONS: OPTIONS = {
  usePageDrawerBuildInContinueButton: true,
  drawerAnchor: 'right',
  isEmbeddedModule: false,
  fullWidthButton: true,
  editButtonStyle: 'zillow',
};

const getOptionsBasedOnFeatureFlag: GetOptions = (params): OPTIONS => {
  return flagValues[FeatureFlags.EXPRESS_PATH]
    ? getZillowExpressPathOptions(params)
    : flagValues[FeatureFlags.COVERAGE_PAGE_REDESIGN_VARIANTS]
    ? getCostcoCoveragePageRedesignOptions(params)
    : DEFAULT_OPTIONS;
};

export const PolicyStartDateCalendar: React.FC<Props> = (props) => {
  const { product, isEmbeddedModule = false } = props;

  const options = getOptionsBasedOnFeatureFlag({ isEmbeddedModule });

  const { classes, cx } = useStyles({
    drawerAnchor: options.drawerAnchor,
  });

  const productName = getProductNameFromProduct(product);
  const productPolicyStartDate = useSelector((state: RootStore) =>
    getProductPolicyStartDate(state, productName),
  );
  const policyStartDate = dayjs(productPolicyStartDate);
  const fullMonth = policyStartDate.format('MMMM');
  const year = policyStartDate.format('YYYY');
  const date = policyStartDate.format('D');
  const shortMonth = getShortMonth(productPolicyStartDate);
  const productDisplayName = getProductDisplayNameFromProduct(product);
  const isBundle = useSelector(getIsBundleForOfferProductsSelected);
  const isMobile = useIsMobile();
  const [isPageDrawerOpen, setIsPageDrawerOpen] = useState<boolean>(false);
  const policyEffectiveDateParam = useSelector(getPolicyEfectiveDateParam);

  const togglePageDrawerHandler = useEvent((isEditing: boolean) => {
    if (isEditing) {
      trackSapiAnalyticsEvent({
        element: 'policyCoveragePage.policyStartDate.modal',
        event: 'render',
        eventDetail: 'true',
      });
    }
    setIsPageDrawerOpen((prevVal): boolean => !prevVal);
  });

  const onSaveHandler = useEvent(() => {
    trackSapiAnalyticsEvent({
      element: 'policyCoveragePage.policyStartDate.modal.continue',
      event: 'click',
      eventDetail: 'Continue',
    });
    togglePageDrawerHandler(false);
  });

  return (
    <>
      <GridItem
        className={cx(classes.root)}
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => togglePageDrawerHandler(true)}
      >
        <div className={classes.iconAndDateContainer}>
          <div className={classes.iconButton}>
            <div className={classes.calendarIconContainer}>
              <span className={classes.monthTypography}>{shortMonth}</span>
              <img src={GraphicCoverageCalendarImageUrl} alt='calendar icon' />
              <span className={classes.dateTypography}> {date}</span>
            </div>
          </div>
          <div className={classes.policyStartDateTxt}>
            {isBundle && isMobile ? 'Policy start date' : `${productDisplayName} policy start date`}{' '}
            <p className={classes.longDate}>
              {fullMonth} {date}, {year}
            </p>
          </div>
        </div>
        <div className={classes.editContainer}>
          <Button
            variant='iconText'
            trackingName='policy_start_date'
            trackingLabel='policy_start'
            className={cx(
              options.editButtonStyle === 'costco' && classes.costcoStyleEditButton,
              options.editButtonStyle === 'zillow' && classes.zillowStyleEditButton,
            )}
            icon={
              <IconUIEdit
                className={cx(
                  options.editButtonStyle === 'costco' && classes.costcoStyleEditIcon,
                  options.editButtonStyle === 'zillow' && classes.zillowStyleEditIcon,
                )}
              />
            }
          >
            Edit
          </Button>
        </div>
      </GridItem>
      {policyEffectiveDateParam && (
        <GridItem topSpacing='xs' xs={12} md={12}>
          <p className={classes.policyHelpText}>
            {getStartDateHelpText(product, policyEffectiveDateParam)}
          </p>
        </GridItem>
      )}
      <PageDrawer
        open={isPageDrawerOpen}
        // eslint-disable-next-line react/jsx-no-bind
        onClose={() => togglePageDrawerHandler(false)}
        // eslint-disable-next-line react/jsx-no-bind
        saveHandler={() => onSaveHandler()}
        isProcessing={false}
        title='When do you want your coverage to start?'
        classes={{
          drawerTitle: classes.drawerTitle,
          drawerPaper: classes.drawerPaper,
          drawerBody: classes.drawerBody,
          drawerFooter: classes.drawerFooter,
        }}
        modalClassName={cx(
          !options.isEmbeddedModule && classes.pageDrawerModalZIndexFixOnMonolineAndBundleApps,
          !options.isEmbeddedModule && classes.drawerModalAnchorRightJitterFix,
          options.isEmbeddedModule && classes.pageDrawerModalOnEmbeddedModule,
        )}
        saveButtonTrackClickParams={{
          action: 'continue',
          label: 'continue',
        }}
        closeButtonTrackClickParams={{
          action: 'policy_start_X ',
          label: 'X',
        }}
        anchor={options.drawerAnchor}
        saveButtonLabel='Continue'
        showCancelButton={false}
        showFooter={options.usePageDrawerBuildInContinueButton}
        saveButtonClassName={options.fullWidthButton ? classes.fullWidth : undefined}
      >
        <Grid container spacing={2}>
          {isPageDrawerOpen && (
            <>
              <PolicyStartDateForm product={product} variant='inDrawer' />
              {!options.usePageDrawerBuildInContinueButton && (
                <Grid item xs={12}>
                  <Button
                    onClick={onSaveHandler}
                    variant='primary'
                    trackingName='continue'
                    trackingLabel='continue'
                    id='module-drawer-save-button'
                    className={classes.continueButton}
                  >
                    Continue
                  </Button>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </PageDrawer>
    </>
  );
};
