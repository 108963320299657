import type { RootStore } from '@ecp/features/sales/shared/store/types';
import type { Product } from '@ecp/features/shared/product';

import type { RatingCriteriaResponse } from './types';

export const getRatingCategoryResponseForProduct = (
  state: RootStore,
  productKey: Product,
): RatingCriteriaResponse['data'] | null => {
  if (!state.ratingCriteria.ratingCriteriaList[productKey]) {
    return null;
  }

  return state.ratingCriteria.ratingCriteriaList[productKey].ratingCriteriaResponse;
};
