import type { RequestInitExtended, TypedResponse } from './typedFetch';
import { typedFetch } from './typedFetch';

export const DEFAULT_API_TIMEOUT = 5000;

/** Fetch with auto-canecellation if timeout exceedes. */
export async function fetchWithTimeout<T>({
  url,
  init,
  timeout = DEFAULT_API_TIMEOUT,
  ensureResponseOk = true,
}: {
  url: string;
  init: RequestInitExtended;
  timeout?: number;
  ensureResponseOk?: boolean;
}): Promise<TypedResponse<T>> {
  const controller = new AbortController();
  const { signal } = controller;

  const timeoutResult = setTimeout(() => {
    controller.abort();
  }, timeout);

  const initWithAbortSignal: RequestInitExtended = {
    ...init,
    signal,
  };

  return typedFetch<T>(url, initWithAbortSignal)
    .then((resp) => {
      if (resp.ok || !ensureResponseOk) return resp;

      return Promise.reject(resp);
    })
    .finally(() => {
      clearTimeout(timeoutResult);
    });
}
