import { datadogLog } from '@ecp/utils/logger';

import type { AppDispatch, RootStore } from '@ecp/features/sales/shared/store/types';
import type {
  Address,
  Answers,
  AnswerValue,
  FieldsDef,
  Nested,
  Person,
  Property,
  PropertyList,
  Template,
} from '@ecp/features/sales/shared/types';
import type { Question } from '@ecp/types';

import { getField } from '../form/selectors';
import { getAnswers } from './selectors';
import { getLabelForAnswersValue } from './util/getLabelForAnswersValue';
import { getValueForPrefix } from './util/getValueForPrefix';

// This is used by getPropertiesOfTemplateWithRef and emptyMapping
//  to help Typescript collapse the nested structure of Template.
// i.e. is template[key] a nested template, or a leaf value?
// (Typescript has difficulty with the conditional extends used in the Template definition)
export const isTemplate = <T>(a: unknown): a is Template<T> => a != null && typeof a === 'object';

// These properties will always be skipped
// (could be passed if we need a different list between types)
const propertiesToSkip = ['ref'];

// These templates are used by getPropertiesOfTemplateWithRef in order to iterate
// over the keys and build the property list.
// By defining a template object, we can still tie into the type system.
// That is, if the Person definition changes, the Template will be out of type,
// and the code will not compile. (and vice versa).

export const addressTemplate: Template<Address> = {
  line1: '',
  line2: '',
  zipcode: '',
  city: '',
  state: '',
  ref: '',
  latitude: '',
  longitude: '',
  matchType: '',
  isLocked: false,
};
export const addressMapping: Record<string, string> = {};

export const personTemplate: Template<Person> = {
  dateOfBirth: '',
  email: '',
  firstName: '',
  middleName: '',
  suffix: '',
  fromPrefill: false,
  gender: '',
  ref: '',
  lastName: '',
  maritalStatus: '',
  relationshipToApplicant: '',
  phone: '',
  insuredType: '',
};
export const personMapping: Record<string, string> = {
  dateOfBirth: 'dob',
  maritalStatus: 'married',
};

// getPropertiesOfTemplateWithRef<T>(template, ref):
// This function's purpose is to return a list of paths that could be fetched from
// Questions in order to construct the requested type T.
// The template object is used as a pattern for understanding which paths to make.
// The ref is prepended to each entry.
// The output looks something like this:
// [
//   ['driver.1', 'isSni'],
//   ['driver.1', 'driverslicense', 'agefirstlicensed'],
//   ['driver.1', 'driverslicense', 'status'],
//   ['driver.1', 'currentInsurance', 'carrier'],
// ]
export const getPropertiesOfTemplateWithRef = <T extends Record<string, unknown>>(
  template: Template<T>,
  ref: string[],
): PropertyList<T> =>
  Object.keys(template)
    .filter((key) => !propertiesToSkip.includes(key))
    .reduce<PropertyList<T>>((result, key) => {
      const value = template[key];
      if (isTemplate<T[string]>(value)) {
        result.push(...getPropertiesOfTemplateWithRef(value, [...ref, key]));
      } else {
        result.push([...ref, key]);
      }

      return result;
    }, []);

export const getAddressProperties = (ref: string, ...prefix: string[]): PropertyList<Address> =>
  getPropertiesOfTemplateWithRef(addressTemplate, [ref, ...prefix]);
export const getPersonProperties = (ref: string): PropertyList<Person> =>
  getPropertiesOfTemplateWithRef(personTemplate, [ref]);

// This is basically underscore set(),
// But it ignores the first entry since that's assumed to be the passed obj already.
// NOTE: mutates object
// e.g.
//   const foo = { a: { b: 2 }};
//   setProperty(foo, ['foo', 'a', 'c'], 4);
// mutates obj "foo" to be:
//   { a: { b: 2, c: 4 }}
export const setProperty = <T>(obj: Nested<T>, path: string[], value: T): void => {
  let current = obj;

  if (path.length < 2) {
    datadogLog({
      logType: 'error',
      message: 'path should contain at least 2 elements',
      context: {
        logOrigin: 'libs/features/sales/shared/store/lib/src/inquiry/noun-selectors.ts',
        functionOrigin: 'setProperty',
      },
    });
    throw new Error('path should contain at least 2 elements');
  }

  // skip the first and last entries
  // (first is the reference name, last will be used after the loop)
  for (let i = 1; i < path.length - 1; i += 1) {
    const key = path[i];

    if (!current[key]) {
      current[key] = {};
    }

    // current[key] is either another object or AnswerValue,
    // don't bother checking just assume that the path given is valid
    current = current[key] as Nested<T>;
  }
  current[path[path.length - 1]] = value;
};

export const getMappedKey = (property: Property, mapping: Record<string, string>): string => {
  if (property.length < 2) {
    datadogLog({
      logType: 'error',
      message: 'property should contain at least 2 elements',
      context: {
        logOrigin: 'libs/features/sales/shared/store/lib/src/inquiry/noun-selectors.ts',
        functionOrigin: 'getMappedKey',
      },
    });
    throw new Error('property should contain at least 2 elements');
  }

  const path = property.slice(1).join('.');
  const mappedPath = mapping[path];

  return `${property[0]}.${mappedPath || path}`;
};

export const getMappedKeyDynamic = (
  property: Property,
  mappings: Record<string, Record<string, string>>,
): string => {
  // determine which mapping to use based on the first property entry.
  const ref = property[0];

  return getMappedKey(property, mappings[ref]);
};

// FIXME: These selectors are not yet optimized to be reference stable in any way.
const getAddressValues = (state: RootStore, ref: string): Address => {
  const properties = getAddressProperties(ref);
  const answers = getAnswers(state);

  return properties.reduce(
    (result, property) => {
      // the properties given here can be trusted to be part of address,
      // since they were generated with getAddressProperties
      const key = getMappedKey(property, addressMapping);
      const value = answers[key];
      setProperty(result as unknown as Nested<AnswerValue>, property, value);

      return result;
    },
    { ref } as Address,
  );
};

export const getAddressesValues = (state: RootStore, refs: string[]): Record<string, Address> => {
  return refs.reduce((result, ref) => {
    result[ref] = getAddressValues(state, ref);

    return result;
  }, {} as Record<string, Address>);
};

const getAddressFields = (
  state: RootStore,
  ref: string,
  dispatch: AppDispatch,
): FieldsDef<Address> => {
  const properties = getAddressProperties(ref);

  return properties.reduce((result, property) => {
    // the properties given here can be trusted to be part of address,
    // since they were generated with getAddressProperties
    const key = getMappedKey(property, addressMapping);
    const value = getField(state, { key, dispatch });
    setProperty(result, property, value);

    return result;
  }, {} as FieldsDef<Address>);
};

export const getAddressesFields = (
  state: RootStore,
  refs: string[],
  dispatch: AppDispatch,
): Record<string, FieldsDef<Address>> => {
  return refs.reduce((result, ref) => {
    result[ref] = getAddressFields(state, ref, dispatch);

    return result;
  }, {} as Record<string, FieldsDef<Address>>);
};

// FIXME: These selectors are not yet optimized to be reference stable in any way.
export const getPersonValues = (state: RootStore, ref: string): Person => {
  const properties = getPersonProperties(ref);
  const answers = getAnswers(state);

  return properties.reduce(
    (result, property) => {
      // the properties given here can be trusted to be part of person,
      // since they were generated with getPersonProperties
      const key = getMappedKey(property, personMapping);
      const value = answers[key];
      setProperty(result as unknown as Nested<AnswerValue>, property, value);

      return result;
    },
    { ref } as Person,
  );
};

export const getPersonsValues = (state: RootStore, refs: string[]): Record<string, Person> => {
  return refs.reduce((result, ref) => {
    result[ref] = getPersonValues(state, ref);

    return result;
  }, {} as Record<string, Person>);
};

export const getPersonFields = (
  state: RootStore,
  ref: string,
  dispatch: AppDispatch,
): FieldsDef<Person> => {
  const properties = getPersonProperties(ref);

  return properties.reduce((result, property) => {
    // the properties given here can be trusted to be part of person,
    // since they were generated with getPersonProperties
    const key = getMappedKey(property, personMapping);
    const value = getField(state, { key, dispatch });
    setProperty(result, property, value);

    return result;
  }, {} as FieldsDef<Person>);
};

export const getPersonsFields = (
  state: RootStore,
  refs: string[],
  dispatch: AppDispatch,
): Record<string, FieldsDef<Person>> => {
  return refs.reduce((result, ref) => {
    result[ref] = getPersonFields(state, ref, dispatch);

    return result;
  }, {} as Record<string, FieldsDef<Person>>);
};

export const getPerson = (
  ref: string,
  allValues: Answers,
  getQuestion: (key: string, questionKey: string) => Question,
): Person => {
  const getString = getValueForPrefix<string>(ref, allValues);
  const getBoolean = getValueForPrefix<boolean>(ref, allValues);
  const suffixQuestion = getQuestion(`person.${ref}.suffix`, 'person.<id>.suffix');

  return {
    dateOfBirth: getString('dob'),
    email: getString('email'),
    firstName: getString('firstName'),
    fromPrefill: getBoolean('fromPrefill'),
    gender: getString('gender'),
    ref,
    insuredType: getString('insuredType'),
    lastName: getString('lastName'),
    maritalStatus: getString('married'),
    relationshipToApplicant: getString('relationshipToApplicant'),
    phone: getString('phone'),
    middleName: getString('middleName'),
    suffix: getLabelForAnswersValue(suffixQuestion, getString('suffix')).toString(),
  };
};

export const isDuplicatePerson = (error: unknown): boolean =>
  error ===
  'Duplicate person based on name and date of birth. Verify the entered information or remove the driver.';
