import { makeStyles } from '@ecp/themes/base';

import type { PaymentOptionProps } from './PaymentOption';

export const useStyles = makeStyles<PaymentOptionProps>({ name: 'PaymentOption' })(
  (...[theme, { checked }]) => {
    // Background color and transition of card and SingleLineDetail need to match perfectly
    const paymentOptionStyles = {
      backgroundColor: theme.palette.grey['50'],
      transition: '0.3s',
    };

    return {
      paymentOption: {
        padding: 15,
        borderRadius: 8,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: checked ? theme.palette.primary.main : theme.palette.grey['600'],
        outline: checked ? `1px solid ${theme.palette.primary.main}` : '',
        // Add margin of 2px to account for border/outline
        margin: 2,
      },
      paymentOptionBackground: paymentOptionStyles,
      detailsHeading: {
        ...theme.typography.h4,
        position: 'relative',
        margin: '0 0 5px 0',
      },
      detailsHeadingCustomPay: {
        ...theme.typography.h4,
        position: 'relative',
        margin: '0 0 15px 0',
      },
      paymentOptionsMargin: {
        marginLeft: 39,
      },
      singlePaymentOptionMargin: {
        marginLeft: 15,
      },
      dottedLine: {
        flex: '1',
        borderBottom: '1px dotted',
        height: '1.0em',
        marginLeft: 8,
      },
      value: {
        textAlign: 'right',
      },
      horizontalDivider: {
        width: '100%',
        height: 1,
        backgroundColor: theme.palette.other.divider,
        margin: '20px 0',
      },
      horizontalDividerCustomPay: {
        width: '100%',
        height: 1,
        backgroundColor: theme.palette.other.divider,
        margin: '5px 0',
      },
      leftWrapper: paymentOptionStyles,
      classicLabel: {
        '& .MuiFormControlLabel-label': {
          ...theme.typography.h4,
          marginLeft: 15,
        },
      },
      disableButton: {
        pointerEvents: 'none',
        '&:hover': {
          cursor: 'pointer',
        },
      },
      paymentInfoLink: {
        textAlign: 'right',
      },
      paymentDisclosureText: {
        ...theme.typography.body2,
        display: 'inline',
        color: theme.palette.text.tertiary,
      },
      customAmountText: {
        ...theme.typography.body2,
        display: 'inline',
        color: theme.palette.text.tertiary,
        marginBottom: 20,
      },
      convienceFeeText: {
        ...theme.typography.body2,
        display: 'inline',
      },
      singleLineDetailRoot: {
        marginBottom: 5,
      },
    };
  },
);
