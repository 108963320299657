import { cloneElement, useCallback, useEffect, useRef, useState } from 'react';

import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { scrollToTop } from '@ecp/utils/web';

import { Card } from '@ecp/components';
import { env } from '@ecp/env';
import { Button } from '@ecp/features/sales/shared/components';
import { useIsMobile } from '@ecp/themes/base';

import { useToggleLandingAndRetrievePages } from '../../util/useToggleLandingAndRetrievePages';
import metadata from './metadata';
import { useStyles, visibilityDelay } from './RetrieveSlider.styles';

interface Props {
  positionB?: boolean;
  quoteForm: React.ReactElement;
  retrieveForm: React.ReactNode;
  formType?: string;
  subHeader?: boolean;
  eventLabel?: string;
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  buttonTracking?: ButtonTrackingProps;
}

interface ButtonTrackingProps {
  trackingName?: string;
  trackingLabel?: string;
  posBTrackingName?: string;
  posBTrackingLabel?: string;
}

export const RetrieveSlider: React.FC<Props> = (props) => {
  const { positionB, quoteForm, retrieveForm, formType, subHeader, eventLabel, buttonTracking } =
    props;
  const { cx } = useStyles();
  const { classes } = useStyles(undefined, { props });
  const isMobile = useIsMobile();
  const productSelectRedesignFeatureFlag = flagValues[FeatureFlags.PRODUCT_SELECT_REDESIGN];

  const buttonTrackingName = positionB
    ? buttonTracking?.posBTrackingName || 'GetNewQuotesButton'
    : buttonTracking?.trackingName || 'GetBackToQuoteButton';
  const buttonTrackingLabel = positionB
    ? buttonTracking?.posBTrackingLabel || 'GetNewQuotes'
    : buttonTracking?.trackingLabel || 'GetBackToYourQuote';
  const buttonEventLabel = positionB ? 'getNewQuotesButton' : 'getBackToYourQuoteButton';
  const analyticsElement = eventLabel || `choice.${formType}GetBackToYourQuote.${buttonEventLabel}`;

  const toggleLandingAndRetrievePages = useToggleLandingAndRetrievePages();

  const [isAnimating, setIsAnimating] = useState(false);
  const animatingId = useRef<number>();

  const togglePosition = useCallback(async () => {
    if (positionB && env.newQuoteRedirectUrl) {
      window.location.href = new URL(env.newQuoteRedirectUrl).href;

      return;
    }

    setIsAnimating(true);
    animatingId.current = window.setTimeout(() => setIsAnimating(false), visibilityDelay);
    await toggleLandingAndRetrievePages();
    document.getElementById('RetrieveSlider')?.focus();
    scrollToTop();
  }, [toggleLandingAndRetrievePages, positionB]);

  useEffect(() => {
    return () => {
      if (animatingId.current) {
        clearTimeout(animatingId.current);
      }
    };
  });

  const retrieveButton: React.ReactNode = !metadata.hideNewQuoteButton && (
    <Button
      variant='iconTextMedium'
      disableRipple
      className={positionB ? classes.retrieveButton : classes.backQuotesButton}
      onClick={togglePosition}
      trackingName={buttonTrackingName}
      trackingLabel={buttonTrackingLabel}
      analyticsElement={analyticsElement}
      data-testid={positionB ? 'GetNewQuotesButton' : 'GetBackToQuoteButton'}
    >
      {positionB ? metadata.getNewQuoteLinkText : 'Continue a saved quote'}
    </Button>
  );

  const landing = formType === 'landingPage';

  const quoteFormBody =
    (!positionB || isAnimating) && cloneElement(quoteForm, quoteForm.props, retrieveButton);

  const retrieveFormBody = (
    <>
      {(positionB || isAnimating) && retrieveForm}
      {retrieveButton}
    </>
  );

  return (
    <div
      className={cx(
        classes.root,
        positionB ? classes.positionB : classes.positionA,
        !isMobile && positionB && classes.cardPositionB,
        !isMobile && !positionB && classes.cardPositionA,
        !positionB && landing && subHeader && classes.hasSubHeader,
      )}
    >
      {metadata.protectionIcon && productSelectRedesignFeatureFlag !== 'PRODUCT_SELECT_VARIANT' && (
        <div className={classes.protectionIcon}>{metadata.protectionIcon}</div>
      )}
      {/* <div className={cx(classes.sideContent, landing && classes.sideContentLanding)} /> */}
      <div className={classes.mainContent} id='RetrieveSlider' tabIndex={-1}>
        {/* this holds the two forms */}
        <div className={classes.mainContentSlider}>
          {/* B comes first for animation reasons */}
          <div className={cx(classes.mainContentSection, classes.mainContentSectionB)}>
            {isMobile ? (
              retrieveFormBody
            ) : (
              <Card
                className={classes.mainCard}
                classes={{ content: classes.cardContent }}
                body={
                  <>
                    {retrieveForm} {retrieveButton}
                  </>
                }
                divider={false}
              />
            )}
          </div>
          <div className={cx(classes.mainContentSection, classes.mainContentSectionA)}>
            {isMobile ? (
              quoteFormBody
            ) : (
              <Card
                className={classes.mainCard}
                classes={{ content: classes.cardContent }}
                body={quoteFormBody}
                divider={false}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
