import { PagePath } from '@ecp/features/sales/shared/routing';

export interface Metadata {
  nextPage: PagePath;
  shouldCallPrefill: boolean;
}

const metadata: Metadata = {
  nextPage: PagePath.PERSON,
  shouldCallPrefill: false,
};

export default metadata;
