import { Grid } from '@mui/material';

import { getSelectedPropertyProduct } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { isProductRenters } from '@ecp/features/shared/product';

import { DiscountsFormBodyHome } from './DiscountsFormBodyHome';
import { DiscountsFormBodyRenters } from './DiscountsFormBodyRenters';

type Props = React.ComponentProps<typeof DiscountsFormBodyHome>;

export const DiscountsFormBody: React.FC<Props> = (props) => {
  const selectedPropertyProduct = useSelector(getSelectedPropertyProduct);

  return (
    <Grid item xs={12}>
      {isProductRenters(selectedPropertyProduct) ? (
        <DiscountsFormBodyRenters />
      ) : (
        <DiscountsFormBodyHome {...props} />
      )}
    </Grid>
  );
};
