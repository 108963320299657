export interface Metadata {
  shouldRenderTitle?: boolean;
  shouldRenderTopSpacer?: boolean;
  variant?: 'compact' | 'normal' | 'bannerHeading' | 'noheading';
}

const metadata: Metadata = {
  shouldRenderTitle: false,
  shouldRenderTopSpacer: true,
};

export default metadata;
