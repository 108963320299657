import type { DriverBasicInfo, DriverVehicleListItem } from '@ecp/features/sales/shared/types';

import { DriverIcon } from './DriverIcon';

export const makeDriverItemList = (drivers: DriverBasicInfo[]): DriverVehicleListItem[] =>
  drivers
    .filter(({ firstName, lastName }) => firstName && lastName)
    .map(({ firstName, middleName, lastName, suffix }, index) => {
      return {
        description: `${firstName || ''} ${middleName?.slice(0, 1)?.toUpperCase() || ''} ${
          lastName || ''
        } ${suffix || ''}`,
        icon: <DriverIcon data-testid='DriverIcon' index={index} />,
      };
    });
