import { memo, useCallback } from 'react';

import { trackClick } from '@ecp/utils/analytics/tracking';

import type { LinkProps } from '@ecp/components';
import { Link as LinkBase } from '@ecp/components';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

export type Props = LinkProps & {
  trackingName?: string;
  trackingLabel?: string;
  analyticsElement?: string;
  analyticsEventDetail?: string;
};

export const Link: React.FC<Props> = memo((props) => {
  const { trackingName, trackingLabel, analyticsElement, analyticsEventDetail, onClick, ...rest } =
    props;
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>) => {
      trackClick({ action: trackingName, label: trackingLabel });
      if (analyticsElement) {
        trackSapiAnalyticsEvent({
          element: analyticsElement,
          event: 'click',
          eventDetail: analyticsEventDetail || 'true',
        });
      }
      onClick?.(event);
    },
    [analyticsElement, analyticsEventDetail, onClick, trackingLabel, trackingName],
  );

  return <LinkBase onClick={handleClick} {...rest} />;
});
