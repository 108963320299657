import { useCallback, useEffect, useState } from 'react';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { datadogLog } from '@ecp/utils/logger';
import { sessionStorage } from '@ecp/utils/storage';

import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { TextField } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { getPartnerExperienceId, getPniPersonInfo } from '@ecp/features/sales/shared/store';
import { useField } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { DRIVER_LICENSE_CONFIRM } from '../../../../constants';
import metadata from './metadata';

interface Props extends QuestionProps {
  setIsPurchaseDisabled: (value: boolean) => void;
}

export const DriverLicenseConfirmQuestion: React.FC<Props> = (props) => {
  const {
    id = 'DriverLicense',
    trackingName = 'drivers_license_number',
    trackingLabel = GoogleAnalyticsLabels.REDACTED,
    setIsPurchaseDisabled,
  } = props;
  const driver = useSelector(getPniPersonInfo);
  const driverLicense = useField(DRIVER_LICENSE_CONFIRM);
  useAddFields({ driverLicense });
  useAddConditionValues({
    isRequiredOverride: () => !metadata.hideDriverLicenseConfirm,
    conditionalFields: [driverLicense],
  });

  const [failedAttempt, setFailedAttempt] = useState('');
  const [mustValidate, setMustValidate] = useState(false);
  const expId = useSelector(getPartnerExperienceId);
  let currentCheckoutRetryCount = sessionStorage.getItem('CheckoutRetryCount') as number;
  if (!currentCheckoutRetryCount) {
    sessionStorage.setItem('CheckoutRetryCount', 0);
    currentCheckoutRetryCount = 0;
  }

  useEffect(() => {
    if (mustValidate) {
      if (driverLicense.props.error && currentCheckoutRetryCount < 3) {
        const newCount = currentCheckoutRetryCount + 1;
        if (newCount === 3) {
          setFailedAttempt('Please call an agent to purchase');
          setIsPurchaseDisabled(true);
          datadogLog({
            logType: 'warn',
            message: 'Reached 3 invalid tries on confirming PNI driver license number',
            context: {
              logOrigin:
                'DriverLicenseConfirmQlibs/features/sales/quotes/auto/src/components/Question/Driver/DriverLicenseConfirmQuestion/DriverLicenseConfirmQuestion.tsxuestion',
              functionOrigin: 'DriverLicenseConfirmQuestion/useEffect',
            },
          });
        }
        sessionStorage.setItem('CheckoutRetryCount', newCount);
        setMustValidate(false);
      }
    }

    if (currentCheckoutRetryCount === 3) {
      setFailedAttempt('Please call an agent to purchase');
      setIsPurchaseDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mustValidate, currentCheckoutRetryCount, expId, driverLicense.props.error]);

  const onCompleteLicenseField = useCallback(async (value: string) => {
    // setMustValidate must come before setIsPurchasedDisabled
    // to keep the count incrementing correctly
    setMustValidate(true);
    setIsPurchaseDisabled(true);
    await driverLicense.props.actionOnComplete(value);
    setIsPurchaseDisabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!driverLicense.exists || metadata.hideDriverLicenseConfirm) return null;

  return (
    <TextField
      {...driverLicense.props}
      error={failedAttempt || driverLicense.props.error}
      actionOnComplete={onCompleteLicenseField}
      id={id}
      ariaLabel='Driver License'
      autoComplete='driverLicense'
      data-testid='driverLicense'
      disabled={!!failedAttempt}
      helperText={`Confirm ${driver.firstName}'s driver's license number`}
      label={`${driver.firstName}'s driver's license #`}
      trackingLabel={trackingLabel}
      trackingName={trackingName}
    />
  );
};
