import { createReducer } from '@reduxjs/toolkit';

import {
  deleteOffers,
  setBlobRetrieveFailed,
  setDependenciesSnapshot,
  setOffersApiGetSuccess,
  setOffersApiPostSuccess,
  setOffersApiPutSuccess,
  setOffersApiRecallFail,
  setOffersApiRecallSuccess,
  setOffersResponseFromPostInquirySuccess,
  setProcessingRecalc,
  setRecalled,
  setShouldRecalc,
} from './actions';
import type { OffersState } from './types';
import { filterAnswersDependenciesForNewOffers } from './util';

const offersInitial: OffersState = {
  offers: {},
  recalculate: false,
  recalcProcessing: false,
  recalled: false,
  recallError: '',
  dependenciesSnapshot: { answers: {}, products: [] },
  blobRetrieveFailed: false,
};

export const offersReducer = createReducer(offersInitial, (builder) => {
  builder
    .addCase(deleteOffers, (state) => {
      // Deleting offers should also delete offerSetId and Inquiry Id as part of it
      state.dalSessionId = '';
      state.inquiryId = '';
      state.offerSetId = '';
      state.offers = offersInitial.offers;
    })
    .addCase(setDependenciesSnapshot, (state, { payload }) => {
      const { answers, products } = payload;
      const filteredAnswers = filterAnswersDependenciesForNewOffers(answers);
      state.dependenciesSnapshot = { answers: filteredAnswers, products };
    })
    .addCase(setOffersApiGetSuccess, (state, { payload }) => {
      const { inquiryId, offerSetId, offers } = payload.data;
      state.dalSessionId = payload.dalSessionId;
      state.inquiryId = inquiryId;
      state.offerSetId = offerSetId;
      state.offers = offers;
    })
    .addCase(setOffersApiPostSuccess, (state, { payload }) => {
      const { inquiryId, offerSetId, offers } = payload.data;
      state.dalSessionId = payload.dalSessionId;
      state.inquiryId = inquiryId;
      state.offerSetId = offerSetId;
      state.offers = offers;
    })

    .addCase(setOffersApiPutSuccess, (state, { payload }) => {
      // FIXME: PUT should merge the returned offers into our existing set.
      // i.e. if we do a POST for product A and B
      // then later we do a PUT for product A,
      // product B won't be returned, but the current reducer will remove it.
      // We are not doing this right now because we don't even certainly know
      // what the result looks like. (endpoint is not yet ready).

      // FIXME this is untested, it's not clear what the result structure will be,
      // it's assumed to be the same as a POST.

      const { inquiryId, offerSetId, offers } = payload.data;
      state.dalSessionId = payload.dalSessionId;
      state.inquiryId = inquiryId;
      state.offerSetId = offerSetId;
      state.offers = offers;
    })
    // EDSP-11130 - Resume flow for experiences coming with url and quoteid
    .addCase(setOffersResponseFromPostInquirySuccess, (state, { payload }) => {
      const { inquiryId, offerSetId, offers, dalSessionId } = payload;
      state.dalSessionId = dalSessionId;
      state.inquiryId = inquiryId;
      state.offerSetId = offerSetId;
      state.offers = offers;
    })

    .addCase(setOffersApiRecallFail, (state, { payload }) => {
      state.recallError = payload;
    })
    .addCase(setOffersApiRecallSuccess, (state, { payload }) => {
      const { inquiryId, offerSetId, offers } = payload.data;
      state.dalSessionId = payload.dalSessionId;
      state.inquiryId = inquiryId;
      state.offerSetId = offerSetId;
      state.offers = offers;
    })
    .addCase(setProcessingRecalc, (state, { payload }) => {
      state.recalcProcessing = payload;
    })
    .addCase(setRecalled, (state, { payload }) => {
      state.recalled = payload;
    })
    .addCase(setBlobRetrieveFailed, (state, { payload }) => {
      state.blobRetrieveFailed = payload;
    })
    .addCase(setShouldRecalc, (state, { payload }) => {
      state.recalculate = payload;
    })
    .addMatcher(
      (action): action is { type: '@global/teardownStore' } =>
        action.type === '@global/teardownStore',
      () => offersInitial,
    );
});
