import { useAddFields } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

export const RetiredStatusQuestion: React.FC<QuestionProps> = (props) => {
  const {
    label = 'Is the policyholder (or their spouse/domestic partner) retired?',
    trackingName = 'policyholder_retired_selection',
  } = props;

  const retiredStatus = useField('retiredStatus');

  useAddFields({ retiredStatus });

  if (!retiredStatus.exists) return null;

  return (
    <RadioGroupWithOptions
      {...retiredStatus.props}
      label={label}
      id={retiredStatus.props.name}
      variant='yesNoButton'
      trackingName={trackingName}
    />
  );
};
