import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutoWithdrawAgree' })((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  agreement: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.other.border,
    marginLeft: 15,
    padding: 10,
    maxHeight: 80,
    overflowY: 'scroll',
  },
}));
