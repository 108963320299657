import type { SalesResponse } from '@ecp/features/sales/shared/utils/network';
import { salesRequest } from '@ecp/features/sales/shared/utils/network';
import type { Product } from '@ecp/features/shared/product';

import type { PaymentOptionResponse, PaymentOptionsRequest, PaymentOptionsResponse } from './types';

export const postPaymentOption = ({
  dalSessionId,
  product,
  paymentType,
  paymentPlan,
}: {
  dalSessionId: string;
  product: Product;
  paymentType: string;
  paymentPlan: string;
}): Promise<SalesResponse<PaymentOptionResponse>> => {
  const body = JSON.stringify({
    product,
    paymentType,
    paymentPlan,
  });

  return salesRequest({
    endpoint: `session/${dalSessionId}/offers/payment-options`,
    options: {
      body,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
};

export const getPaymentOptions = ({
  dalSessionId,
  products,
}: PaymentOptionsRequest): Promise<SalesResponse<PaymentOptionsResponse>> => {
  const params: string = products.map((x) => `product=${x}`).join('&');

  return salesRequest({
    endpoint: `session/${dalSessionId}/offers/payment-options?${params}`,
    options: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
};
