import { useRef } from 'react';

import { useGetConditionValues, useGetFields } from '@ecp/features/sales/form';
import { Form } from '@ecp/features/sales/shared/components';
import { useForm } from '@ecp/features/sales/shared/store';

import { AdditionalWildfireMitigationFormQuestions } from '../../formBody';
import { useStyles } from './AdditionalWildfireMitigationForm.styles';

export const AdditionalWildfireMitigationForm: React.FC = () => {
  const { classes } = useStyles();
  const initValues = useRef({});
  const getFields = useGetFields();
  const getConditions = useGetConditionValues();

  const { isPatchFormInProgress } = useForm({
    initValues,
    fields: getFields(),
    conditions: getConditions(),
  });

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <h2 className={classes.title}>Additional wildfire mitigation questions</h2>
        <AdditionalWildfireMitigationFormQuestions />
      </Form>
    </div>
  );
};
