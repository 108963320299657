import { useCallback } from 'react';

import { TooltipWithIcon } from '@ecp/components';
import { useInitValues } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import type { AnswerValue } from '@ecp/types';

export const RentedToOthersQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const rentedToOthers = useHomePropertyField('rentedToOthersInd');
  const numberOfDaysRented = useHomePropertyField('noOfDaysRented');
  const { label = rentedToOthers.question?.title, trackingName = 'rentedToOthersButton' } = props;
  const helpText = rentedToOthers.question?.helpText;

  useInitValues({ [rentedToOthers.key]: false });

  const handleRentedToOthersChange = useCallback(
    async (value: AnswerValue) => {
      rentedToOthers.validateUpdateAndPatch(value);
      if (!value) {
        numberOfDaysRented.validateUpdateAndPatch(null);
      }
    },
    [rentedToOthers, numberOfDaysRented],
  );

  if (!rentedToOthers.exists) return null;

  return (
    <RadioGroupWithOptions
      {...rentedToOthers.props}
      variant='yesNoButton'
      id='RentedToOthers'
      trackingName={trackingName}
      trackingLabel={rentedToOthers.props.value}
      label={
        <>
          {label}
          <TooltipWithIcon title={helpText} />
        </>
      }
      actionOnComplete={handleRentedToOthersChange}
    />
  );
};
