import { logPageView } from '@ecp/utils/analytics/tracking';
import { datadogLog, trackDataDogCustomView, trackDataDogUserAction } from '@ecp/utils/logger';

import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

export const getRemainingSecondsDisplay = (remainingSeconds: number): string => {
  remainingSeconds = Number(remainingSeconds);
  const minutes = Math.floor((remainingSeconds % 3600) / 60);
  const seconds = Math.floor((remainingSeconds % 3600) % 60);

  const minutesDisplay = String(minutes).padStart(2, '0');
  const secondsDisplay = String(seconds).padStart(2, '0');

  return minutesDisplay + ':' + secondsDisplay;
};

export const getSessionExpiryLabels = (
  isSessionIdle: boolean,
  offerExist: boolean,
): {
  buttonText: string;
  bodyText: string;
  titleText: string;
  trackingLabel: string;
  trackingName: string;
  trackingNameCloseIcon: string;
  eventDetail: string;
} => {
  let buttonText = 'Continue session';
  let bodyText = 'For your security, this session will end soon.';
  let titleText = 'Session time out in';
  let trackingLabel = 'time_out_modal_version: Warning';
  let trackingName = 'continue_session_button';
  let trackingNameCloseIcon = 'continue_session_x';
  let eventDetail = 'version: Warning';
  if (isSessionIdle) {
    buttonText = offerExist ? 'Continue quote' : 'Get quote';
    bodyText = offerExist
      ? 'To safeguard your data, your session has closed. You may continue your saved quote to pick up where you left off.'
      : 'To safeguard your data, your session has closed. You may start over to continue getting a quote.';
    titleText = 'Session expired';
    trackingLabel = offerExist
      ? 'time_out_modal_version:expired_with_offer'
      : 'time_out_modal_version:expired_no_offer';
    trackingName = offerExist ? 'continue_quote_button' : 'get_quote_button';
    trackingNameCloseIcon = offerExist ? 'continue_quote_x' : 'get_quote_x';
    eventDetail = offerExist ? 'version: expired_with_offer' : 'version: expired_no_offer';
  }

  return {
    buttonText,
    bodyText,
    titleText,
    trackingLabel,
    trackingName,
    trackingNameCloseIcon,
    eventDetail,
  };
};

export const addSessionExpiryTimerAnalytics = (
  eventDetail: string,
  isSessionIdle: boolean,
): void => {
  isSessionIdle
    ? logPageView('session-timeout-expired ', 'Session Timeout Expired')
    : logPageView('session-timeout-warning', 'Session Timeout Warning');
  trackSapiAnalyticsEvent({
    element: isSessionIdle ? 'sessionTimeoutModal.expiration' : 'sessionTimeoutModal.modal',
    event: 'render',
    eventDetail: eventDetail,
  });
  trackDataDogCustomView('SessionExpiryTimerView');
  datadogLog({
    logType: 'info',
    message: 'SessionExpiry Loaded',
    context: {
      logOrigin: 'libs/features/sales/shared/components/src/SessionExpiryTimer/util.ts',
      functionOrigin: 'addSessionExpiryTimerAnalytics',
      view: 'SessionExpiryView',
    },
  });
};

export const addGetQuoteAnalytics = (eventDetail: string, closeButton?: boolean): void => {
  trackSapiAnalyticsEvent({
    element: closeButton
      ? 'sessionTimeoutModal.xToGetQuoteButton'
      : 'sessionTimeoutModal.getQuoteButton',
    event: 'click',
    eventDetail: eventDetail,
  });
  datadogLog({
    logType: 'info',
    message: 'Get Quote Clicked',
    context: {
      logOrigin: 'libs/features/sales/shared/components/src/SessionExpiryTimer/util.ts',
      functionOrigin: 'addGetQuoteAnalytics',
    },
  });
  trackDataDogUserAction({
    name: 'Get Quote Clicked',
    context: { action: 'handleGetQuote' },
  });
};

export const addContinueQuoteAnalytics = (eventDetail: string, closeButton?: boolean): void => {
  trackSapiAnalyticsEvent({
    element: closeButton
      ? 'sessionTimeoutModal.xToContinueQuoteButton'
      : 'sessionTimeoutModal.continueQuoteButton',
    event: 'click',
    eventDetail: eventDetail,
  });
  datadogLog({
    logType: 'info',
    message: 'Continue Quote Clicked',
    context: {
      logOrigin: 'libs/features/sales/shared/components/src/SessionExpiryTimer/util.ts',
      functionOrigin: 'addContinueQuoteAnalytics',
    },
  });
  trackDataDogUserAction({
    name: 'Continue Quote Clicked',
    context: { action: 'handleContinueQuote' },
  });
};

export const addContinueSessionAnalytics = (closeButton?: boolean): void => {
  trackSapiAnalyticsEvent({
    element: closeButton
      ? 'sessionTimeoutModal.xToContinueSessionButton'
      : 'sessionTimeoutModal.continueSessionButton',
    event: 'click',
    eventDetail: 'version: Warning',
  });
  datadogLog({
    logType: 'info',
    message: 'Continue Session Clicked',
    context: {
      logOrigin: 'libs/features/sales/shared/components/src/SessionExpiryTimer/util.ts',
      functionOrigin: 'addContinueSessionAnalytics',
    },
  });
  trackDataDogUserAction({
    name: 'Continue Session Clicked',
    context: { action: 'handleContinueSession' },
  });
};
