export const enum LobStatus {
  /** For a given lob, all required questions are answered, and all answers are valid. */
  COMPLETE = 'COMPLETE',
  /** For a given lob, not all required questions are answered, or some answers are invalid. */
  INCOMPLETE = 'INCOMPLETE',
  ELIGIBLE = 'ELIGIBLE',
  INELIGIBLE = 'INELIGIBLE',
}

export const enum OfferStatusCode {
  /** OK can be purchased */
  OK = 'OK',
  /** DNQ is hard stop decline */
  DNQ = 'DNQ',
  /** CNQ is soft stop where they are missing info and need to call call center to complete the quote */
  CNQ = 'CNQ',
  /** QNB is Quoted but not Bound and need to call call center to complete the quote */
  QNB = 'QNB',
  /** Incomplete means there is insufficent information and need to call call center to complete the quote */
  INCOMPLETE = 'INCOMPLETE',
  /** Quote is already purchased */
  PURCHASED = 'PURCHASED',
  /** Quote is expired - happens on recall event if the offer has an effective date that has gone past */
  EXPIRED = 'EXPIRED',
  /** Something happened preventing a proper response or ability to provide a quote Eg. timeout, service not avilable */
  ERROR = 'ERROR',
  LOCKED = 'LOCKED',
}

export const enum PurchaseStatusCode {
  /** Purchase completed */
  COMPLETE = 'COMPLETE',
  /** Incomplete means there is insufficent information and need to call call center to complete the purchase */
  INCOMPLETE = 'INCOMPLETE',
  /** Quote is already purchased */
  PURCHASED = 'PURCHASED',
  /** Something happened preventing a proper response or ability to provide a quote Eg. timeout, service not avilable */
  ERROR = 'ERROR',
  /** Purchase completed but policy bind failed and need to call call center to inquire */
  BINDFAILED = 'BINDFAILED',
}

export const enum TaskStatus {
  WORKING = 'WORKING',
  COMPLETE = 'COMPLETE',
}
