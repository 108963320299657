import { datadogLog } from '@ecp/utils/logger';

import { wrapThunkActionWithErrHandler } from '@ecp/features/sales/shared/store';
import type { Answers } from '@ecp/features/sales/shared/types';
import { SalesRequestError } from '@ecp/features/sales/shared/utils/network';

import {
  setPostBindApiGetSuccess,
  setPostBindApiPostError,
  setPostBindApiPostSuccess,
} from './actions';
import { getPostBindQuestions, postPostBindAnswers } from './api';

type FetchPostBindQuestions = { dalSessionId: string };
// !TODO this is probably not needed in initializeUtil. PostBind page should fetch on the first mount and not re-fetch on the succeeding mounts
export const fetchPostBindQuestions = wrapThunkActionWithErrHandler<FetchPostBindQuestions, void>(
  ({ dalSessionId }) =>
    async (dispatch) => {
      const response = await getPostBindQuestions({ dalSessionId });
      dispatch(setPostBindApiGetSuccess(response.payload));
    },
  'fetchPostBindQuestions',
);

type PushPostBindAnswers = { dalSessionId: string; answers: Answers };
export const pushPostBindAnswers = wrapThunkActionWithErrHandler<PushPostBindAnswers, boolean>(
  ({ dalSessionId, answers }) =>
    async (dispatch) => {
      return (
        postPostBindAnswers({ dalSessionId, answers })
          .then((response) => {
            if (response.payload.data.errors?.length > 0) {
              dispatch(setPostBindApiPostError(response.payload.data));

              return false;
            }
            dispatch(setPostBindApiPostSuccess(response.payload));

            return true;
          })
          // Post bind question submission errors (i.e. network errors and non-successful responses from SAPI)
          // should not lead to Global error page or any error feedback on the screen,
          // unless explicitly handled in the components by pulling the error from the post bind reducer
          .catch((error) => {
            datadogLog({
              logType: 'error',
              message: `Error pushing post bind answers - ${error?.message}`,
              context: {
                logOrigin: 'libs/features/sales/checkout/src/state/postbind/thunks.ts',
                functionOrigin: 'pushPostBindAnswers',
                message: error.message,
                ...(error instanceof SalesRequestError && { errorData: error.errorData }),
              },
              error,
            });

            dispatch(
              setPostBindApiPostError({
                errors: [
                  {
                    domain: '',
                    reason: '',
                    message: `Network or CORS error; ${error?.message}`,
                    locationType: '',
                    location: '',
                  },
                ],
              }),
            );

            return false;
          })
      );
    },
  'pushPostBindAnswers',
);
