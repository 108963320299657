import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<void, 'label'>({ name: 'NavbarDrawer' })(
  (...[theme, , classes]) => ({
    ...theme.mixins.formAll,
    root: {
      backgroundColor: 'transparent',
      '& .MuiStepLabel-label': {
        ...theme.typography.body1,
      },
      '& .MuiStepLabel-completed': {
        color: theme.palette.text.primary,
      },
      '& .MuiStepper-root': {
        padding: 0,
        '& > :last-child': {
          marginBottom: 0,
        },
      },
      '& .MuiStepLabel-active': {
        color: theme.palette.primary.main,
      },
      '& .MuiStepConnector-line': {
        borderColor: theme.palette.primary.main,
      },
      '& .MuiStepLabel-root': {
        padding: 0,
      },
      [`& .${classes.label}`]: {
        ...theme.typography.body1,
        color: theme.palette.text.secondary,
        marginTop: 5,
        [theme.breakpoints.down('md')]: {
          ...theme.typography.body3,
        },
      },
      [theme.breakpoints.down('md')]: {
        padding: 0,
      },
    },
    navbarDrawerImage: {
      [theme.breakpoints.down('lg')]: {
        width: '100%',
        height: 'auto',
      },
    },
    label: {
      ...theme.typography.body1Bold,
      color: theme.palette.text.primary,
      whiteSpace: 'nowrap',
    },
    stepHeadLabel: {
      ...theme.typography.body1Bold,
      color: theme.palette.text.primary,
      whiteSpace: 'nowrap',
    },
    stepLabel: {
      padding: 0,
    },
    labelText: {
      ...theme.typography.body1Bold,
      whiteSpace: 'nowrap',
      alignSelf: 'center',
      color: theme.palette.text.disabled,
    },
    actionIcon: {
      ...theme.mixins.setColorSvg(theme.palette.primary.main),
      fontSize: 20,
      height: 'auto',
      minWidth: 40,
      padding: 0,
    },
    content: {
      ...theme.typography.body1,
      color: theme.palette.text.secondary,
      marginLeft: 35,
      marginTop: 10,
      borderLeftColor: theme.palette.primary.main,
    },
    activeStep: {
      color: theme.palette.text.primary,
    },
    incompleteCircle: {
      ...theme.typography.body2,
      background: theme.palette.primary.main,
      borderRadius: '50%',
      color: theme.palette.grey[50],
      fontSize: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    notVisitedCircle: {
      ...theme.typography.body2,
      background: theme.palette.grey[800],
      borderRadius: '50%',
      color: theme.palette.grey[50],
      fontSize: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    link: {
      ...theme.typography.h6,
    },
    cardPadding: {
      padding: '15px 13px 15px 20px',
    },
    noPadding: {
      padding: 0,
    },
    rootStepper: {
      backgroundColor: theme.palette.primary.background,
    },
    cardMargin: {
      '& .MuiCardContent-root:last-child': {
        padding: '15px 20px',
      },
      marginBottom: 15,
      boxShadow: 'none',
      borderRadius: 4,
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    iconPadding: {
      paddingRight: 15,
      lineHeight: 0,
    },
    textPadding: {
      paddingLeft: 15,
    },
    noConnector: {
      display: 'none',
    },
    productStepPadding: {
      margin: '15px 0px 15px 58px',
    },
    divider: {
      marginLeft: 20,
    },
    addProductSubHeader: {
      paddingBottom: 15,
    },
    labelLink: {
      color: theme.palette.text.primary,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    progressiveLabel: {
      color: theme.palette.text.primary,
      cursor: 'text',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  }),
);
