import type { CheckoutCardProps } from '@ecp/features/sales/checkout';
import {
  getCheckoutDataFromOffers,
  getIsMVRActive,
  getRecalculateValue,
  getSapiAnalyticsSelectedOfferEventDetail,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { useIsOfferLocked } from './useIsOfferLocked';
import { usePreToCheckoutSubmit } from './usePreToCheckoutSubmit';
import { useRecalculateOffer } from './useRecalculateOffer';

export const useCheckoutCardProps = (
  args: {
    checkoutButtonText?: string;
    onNext?: () => Promise<void>;
  } = {},
): CheckoutCardProps => {
  const { onNext, checkoutButtonText = 'Checkout' } = args;

  const checkoutData = useSelector(getCheckoutDataFromOffers);

  const isMVRActive = useSelector(getIsMVRActive);

  const recalculateOffer = useRecalculateOffer();

  const { isProcessing, handleSubmit } = usePreToCheckoutSubmit(onNext);

  const sapiAnalyticsSelectedOfferEventDetail = useSelector(
    getSapiAnalyticsSelectedOfferEventDetail,
  );

  const isOfferLocked = useIsOfferLocked();
  const recalculate = useSelector(getRecalculateValue);

  const checkoutCardProps: CheckoutCardProps = {
    ...recalculateOffer,
    buttonText: checkoutButtonText,
    checkoutData,
    isMVRActive,
    isProcessing,
    onCheckout: handleSubmit,
    sapiAnalyticsSelectedOfferEventDetail,
    showStateFees: true,
    buttonDisabled: isOfferLocked || recalculate,
  };

  return checkoutCardProps;
};
