export const STATE_CODE_PREFIX = 'STATE_CODE.';

export const SmartyStreetsEnums = {
  MATCH_TYPE: {
    EXACT: 'EXACT',
    PARTIAL: 'PARTIAL',
    NONE: 'NONE',
  },
  METADATA: {
    RDI: {
      RESIDENTIAL: 'Residential',
      COMMERCIAL: 'Commercial',
    },
    RECORD_TYPE: {
      FIRM: 'F',
      GENERAL_DELIVERY: 'G',
      HIGH_RISE: 'H',
      POST_OFFICE_BOX: 'P',
      RURAL_ROUTE: 'R',
      STREET: 'S',
    },
    ZIP_TYPE: {
      UNIQUE: 'Unique',
      MILITARY: 'Military',
      PO_BOX: 'POBox',
      STANDARD: 'Standard',
    },
    CARRIER_ROUTE: {
      CITY: 'C',
      RURAL: 'R',
      HIGHWAY: 'H',
      BOX: 'B',
      GENERAL: 'G',
    },
    BUILDING_DEFAULT_INDICATOR: {
      YES: 'Y',
      NO: 'N',
    },
    RESIDENTIAL_DELIVERY_INDICATOR: {
      RESIDENTIAL: 'Residential',
      COMMERCIAL: 'Commercial',
    },
    PRECISION: {
      UNKNOWN: 'Unknown',
      ZIP5: 'Zip5',
      ZIP6: 'Zip6',
      ZIP7: 'Zip7',
      ZIP8: 'Zip8',
      ZIP9: 'Zip9',
    },
  },
  ANALYSIS: {
    DPV_MATCH_CODE: {
      CONFIRMED: 'Y',
      NOT_CONFIRMED: 'N',
      CONFIRMED_BY_DROPPING_SECONDARY: 'S',
      CONFIRMED_MISSING_SECONDARY: 'D',
    },
    DPV_FOOTNOTES: {
      AA: 'AA',
      A1: 'A1',
      BB: 'BB',
      CC: 'CC',
      F1: 'F1',
      G1: 'G1',
      M1: 'M1',
      M3: 'M3',
      N1: 'N1',
      PB: 'PB',
      P1: 'P1',
      P3: 'P3',
    },
    DPV_CMRA: {
      YES: 'Y',
      NO: 'N',
    },
    LACSLINK_CODE: {
      A: 'A',
      ZERO_ZERO: '00',
      ZERO_NINE: '09',
      ONE_FOUR: '14',
      NINE_TWO: '92',
    },
    LACSLINK_INDICATOR: {
      Y: 'Y',
      S: 'S',
      N: 'N',
      F: 'F',
    },
  },
};
