import { Payeezy } from '@homesite/payment-utils';
import dayjs from 'dayjs';

import { trackError } from '@ecp/utils/analytics/tracking';
import { datadogLog } from '@ecp/utils/logger';

import { env } from '@ecp/env';
import type { AnswerValue } from '@ecp/features/sales/shared/types';
import type { SalesResponse } from '@ecp/features/sales/shared/utils/network';
import { salesRequest } from '@ecp/features/sales/shared/utils/network';

import type { Policy } from '../../types';
import type { PurchaseRequestOnSubmit } from './types';

interface PayeezyCreditCard {
  cardNumber: string;
  fullName: string;
  expirationDate: string;
  cardType: string;
}

export const payeezyTokenizeCreditCard = async (card: PayeezyCreditCard): Promise<string> => {
  const date = dayjs(card.expirationDate);

  const [status, { serviceGuid, tokenValue, responseError }] = await Payeezy.fetchPaymentToken({
    creditCardNumber: card.cardNumber,
    cardHolderName: card.fullName,
    cardType: card.cardType,
    expirationMonth: date.format('MM') as Payeezy.MM,
    expirationYear: date.format('YY') as Payeezy.YyFromNow,
    tokenServiceUri: env.creditCardTokenServiceUri,
  });

  if (status !== 200 && responseError) {
    let message = '';
    if ('errorDescription' in responseError) message = responseError.errorDescription;
    else if ('messages' in responseError)
      message = responseError.messages.map((msg) => msg.errorDescription).join(' ');

    trackError({ action: message, label: status });
    datadogLog({
      logType: 'error',
      message: `${message} - serviceGuid: [${serviceGuid}]`,
      context: {
        logOrigin: 'libs/features/sales/checkout/src/state/purchase/api.ts',
        functionOrigin: 'payeezyTokenizeCreditCard',
      },
    });

    throw new Error(`${message} - serviceGuid: [${serviceGuid}]`);
  }

  return tokenValue;
};

export interface PurchaseResponse {
  dalSessionId: string;
  data: {
    offerSetId: string;
    policies: {
      [productKey: string]: Policy;
    };
  };
}

class RequestError extends Error {
  public requestUrl?: string;

  public requestOptions?: RequestInit;

  public response?: Response;

  public constructor(
    message?: string,
    requestUrl?: string,
    requestOptions?: RequestInit,
    response?: Response,
  ) {
    super(message);

    // @ts-ignore FIXME ASAP
    if (Error.captureStackTrace) {
      // @ts-ignore FIXME ASAP
      Error.captureStackTrace(this, RequestError);
    }

    this.name = 'RequestError';
    this.requestUrl = requestUrl;
    this.requestOptions = requestOptions;
    this.response = response;
  }
}

interface TokenResponse {
  pciToken: string;
  status: {
    code: string;
    reason: string;
    metrics: {
      acceptedOn: string;
      returnedOn: string;
    };
    transactionId: string;
  };
}

interface FinTokenResponse {
  finAcctServiceResponse: {
    tokenId: string;
    consumerKey: string;
    apiStatus: {
      code: string;
      reason: string;
      metrics: {
        acceptedOn: string;
        returnedOn: string;
      };
      transactionId: string;
    };
  };
}

type PciAuthorizationServiceDestinations = 'PaymentGateWayService' | 'FinancialAccountService';

export const createPciToken = async ({
  consumerKey,
  destinationLists,
  authorizationKey,
  transactionId,
}: {
  consumerKey: string;
  destinationLists: PciAuthorizationServiceDestinations[];
  authorizationKey: string;
  transactionId?: string;
}): Promise<string> => {
  const body = JSON.stringify({
    consumerKey,
    destinationNames: destinationLists,
    transactionId,
  });
  const options: RequestInit = {
    method: 'POST',
    body,
    headers: {
      authorization: authorizationKey,
      'AFI-AppName': env.amfamBillingAfiAppname,
      'Content-Type': 'application/json',
    },
  };
  const endpoint = `${env.amfamBillingApiRoot}/pciAuthorizationService/services/external/v1/create`;
  const pciTokenResponse = await fetch(endpoint, options);
  if (!pciTokenResponse.ok) {
    trackError({ action: pciTokenResponse.statusText, label: pciTokenResponse.status });
    datadogLog({
      logType: 'error',
      message: `API (response) had bad error status [${pciTokenResponse.status}] ${endpoint}`,
      context: {
        logOrigin: 'libs/features/sales/checkout/src/state/purchase/api.ts',
        functionOrigin: 'createPciToken',
      },
    });

    throw new RequestError(
      `API (response) had bad error status [${pciTokenResponse.status}] ${endpoint}`,
      endpoint,
      options,
    );
  }
  const responseBody: TokenResponse = await pciTokenResponse.json();

  return responseBody.pciToken;
};

export const financialAccountsToken = async ({
  consumerKey,
  financialType,
  authorizationKey,
  cardNumber,
  expirationDate,
  routingNumber,
  accountNumber,
  accountType,
  accountUse,
}: {
  consumerKey: string;
  financialType: string;
  authorizationKey: string;
  cardNumber?: string;
  expirationDate?: string;
  routingNumber?: string;
  accountNumber?: string;
  accountType?: string;
  accountUse?: string;
}): Promise<string> => {
  const body = {
    consumerKey,
    financialType,
    ...(financialType === 'CARD' && {
      creditCard: {
        cardNumber,
        expirationDate,
      },
    }),
    ...(financialType === 'BANK' && {
      bankAccount: {
        routingNumber,
        accountNumber,
        accountType,
        accountUse,
      },
    }),
  };

  const options: RequestInit = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      authorization: authorizationKey,
      'AFI-AppName': env.amfamBillingAfiAppname,
      'Content-Type': 'application/json',
    },
  };
  const endpoint = `${env.amfamBillingApiRoot}/finacctservice/services/external/v2/finacct/financialaccounts`;
  const response = await fetch(endpoint, options);

  if (response.status !== 200) {
    datadogLog({
      logType: 'error',
      message: 'Could not get financial account token',
      context: {
        logOrigin: 'libs/features/sales/checkout/src/state/purchase/api.ts',
        functionOrigin: 'financialAccountsToken',
        responseStatus: response.status,
        statusText: response.statusText,
      },
    });
  }

  if (!response.ok) {
    trackError({ action: response.statusText, label: response.status });
    datadogLog({
      logType: 'error',
      message: `API (response) had bad error status [${response.status}] ${endpoint}`,
      context: {
        logOrigin: 'libs/features/sales/checkout/src/state/purchase/api.ts',
        functionOrigin: 'financalAccountsToken',
      },
    });

    throw new RequestError(
      `API (response) had bad error status [${response.status}] ${endpoint}`,
      endpoint,
      options,
    );
  }
  const responseBody: FinTokenResponse = await response.json();

  return responseBody.finAcctServiceResponse.tokenId;
};

export const postOnPurchase = ({
  dalSessionId,
  purchaseRequest,
  membershipCardNumber,
  signal,
}: {
  dalSessionId: string;
  purchaseRequest: PurchaseRequestOnSubmit;
  membershipCardNumber?: AnswerValue;
  signal?: AbortSignal;
}): Promise<SalesResponse<PurchaseResponse>> => {
  const body = JSON.stringify({
    purchaseRequest,
    ...(membershipCardNumber && { membershipCardNumber }),
  });

  return salesRequest({
    endpoint: `session/${dalSessionId}/offers/purchase`,
    options: {
      body,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    },
  });
};
