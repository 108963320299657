import { Grid } from '@mui/material';

import { getLineOfBusiness } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { LineOfBusiness } from '@ecp/features/shared/product';

import { Main } from '../Main';
import { useStyles } from './GetQuote.styles';
import metadata from './metadata';

export const GetQuote: React.FC = () => {
  const { classes } = useStyles();
  const lineOfBusiness = useSelector(getLineOfBusiness);

  return (
    <div className={classes.root}>
      <Grid item xs={12} className={classes.desktopHeading}>
        <h1>
          {lineOfBusiness
            ? metadata[lineOfBusiness]?.pageTitle
            : metadata[LineOfBusiness.RENTERS]?.pageTitle}
        </h1>
      </Grid>
      <Main />
    </div>
  );
};
