import type { Option, Question } from '@ecp/types';

/** Given a Question with options, returns the label from options whose value matches the answer value. */
export const getLabelForAnswersValue = (
  question: Question,
  answersValue: string | number | true | string[],
): Option['label'] => {
  const options = question.options?.find((q) => q.value === answersValue);

  return options?.label || '';
};
