import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'HeatingSourceQuestion' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    ...theme.mixins.form,
    marginTop: 60,
  },
  label: {
    ...theme.typography.body4,
    marginBottom: 15,
    marginTop: 15,
    paddingLeft: 15,
  },
  labelNoMargin: {
    marginTop: 0,
  },
  showMoreOrLessPanel: {
    display: 'flex',
  },
  addHeatSourceBtn: {
    margin: '40px 0px 20px',
  },
  tableContainer: {
    ...theme.mixins.tableContainer,
  },
  firstRow: theme.mixins.tableFirstRow,
  tableBody: theme.mixins.simpleTableBody,
  actionItem: {
    minWidth: 'fit-content',
    '&:not(:first-child)': {
      paddingLeft: 10,
    },
  },
  clearIcon: {
    display: 'block',
    ...theme.mixins.setColorSvg(theme.palette.error.main),
  },
  nextButton: theme.mixins.nextButton,
  heatSourceCard: {
    marginTop: 15,
    borderRadius: 4,
    height: 80,
    alignItems: 'center',
  },
  heatSourceItem: {
    border: `2px solid ${theme.palette.other.border}`,
    display: 'grid',
    gridTemplateColumns: 'max-content',
    gridAutoFlow: 'column',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      minHeight: 70,
      gridTemplateRows: '1fr',
      gridAutoFlow: 'unset',
      borderRadius: 8,
      gridTemplateColumns: '1fr 40px',
    },
  },
  addHeatSourceItem: {
    border: `2px solid ${theme.palette.text.link}`,
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
    [theme.breakpoints.down('md')]: {
      height: 70,
      borderRadius: 8,
    },
  },
  heatSourceText: {
    ...theme.typography.body1Bold,
    fontSize: 14,
    marginLeft: 20,
    [theme.breakpoints.down('md')]: {
      marginLeft: 10,
    },
  },
  heatSourceItemText: {
    color: theme.palette.text.primary,
    [theme.breakpoints.down('md')]: {
      marginTop: 10,
    },
  },
  addHeatSourceItemText: {
    color: theme.palette.text.link,
    alignItems: 'left',
    textTransform: 'none',
  },
  newHeatSource: {
    border: `2px solid ${theme.palette.text.link}`,
    marginTop: 15,
    padding: 0,
    borderRadius: 8,
    height: 'auto',
    display: 'flex',
  },
  editDeleteButton: {
    ...theme.mixins.setColorSvg(theme.palette.text.link),
    textAlign: 'right',
    marginRight: 17,
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
      marginBottom: 10,
    },
  },
  checkGrid: {
    [theme.breakpoints.down('md')]: {
      marginRight: 6.5,
      justifySelf: 'right',
    },
  },
  checkIcon: {
    ...theme.mixins.setColorSvg(theme.palette.success.main),
    height: 36,
    width: 36,
    marginLeft: 8,
    marginTop: 7,
    [theme.breakpoints.down('md')]: {
      marginBottom: -29,
      marginTop: 0,
    },
  },
  warningIcon: {
    ...theme.mixins.setColorSvg(theme.palette.other.caution),
  },
  heatSourceButton: {
    marginRight: 5,
    marginLeft: 10,
  },
  plusIcon: {
    ...theme.mixins.setColorSvg(theme.palette.text.link),
    height: 24,
    width: 24,
    marginRight: 20,
    [theme.breakpoints.down('md')]: {
      height: 40,
      width: 40,
      marginRight: 8,
    },
  },
  saveButton: {
    paddingTop: 15,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  cancelButton: {
    paddingLeft: 15,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      paddingTop: 15,
      paddingLeft: 0,
    },
  },
  buttonGrid: {
    padding: 15,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  dropdown: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 15,
    maxWidth: '50%',
    flexBasis: '100%',
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
      maxWidth: '100%',
    },
  },
}));
