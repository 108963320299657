import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'MortgageFormQuestions' })((theme) => ({
  text: theme.typography.body1,
  header: {
    ...theme.typography.h3,
    paddingBottom: 6,
  },
  content: theme.typography.body1,
  divider: {
    marginTop: 2,
    marginBottom: 15,
  },
  padding: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  informationCard: {
    backgroundColor: theme.palette.grey[100],
    borderTopColor: theme.palette.primary.main,
    borderTopStyle: 'solid',
    borderTopWidth: 4,
    marginBottom: 20,
    marginTop: 20,
    padding: '20px 20px 15px 20px',
  },
  headerContainer: {
    ...theme.typography.h3,
    display: 'flex',
  },
  description: {
    marginBottom: 20,
  },
}));
