import { PagePath } from '@ecp/features/sales/shared/routing';

export const CHAT_DISABLED_PAGES = new Set([
  PagePath.ACCESSIBILITY,
  PagePath.FAQ,
  PagePath.PARTICIPATING_INSURERS,
  PagePath.PRIVACY_POLICY,
  PagePath.TERMS_OF_USE,
  PagePath.USE_OF_CREDIT_DISCLOSURE,
]);
