import { useCallback, useMemo, useState } from 'react';

import { Grid } from '@mui/material';

import { sessionStorage } from '@ecp/utils/storage';

import { env } from '@ecp/env';
import { ProductQuoteNumber } from '@ecp/features/sales/shared/components';
import { Button, PhoneLink } from '@ecp/features/sales/shared/components';
import { PurchaseErrorReason } from '@ecp/features/sales/shared/constants';
import { getPurchaseError } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { partner } from '@ecp/partners';
import { GraphicUIAgentAvatarImageUrl } from '@ecp/themes/base';

import { useStyles } from './CheckoutErrorPage.styles';

export const CheckoutErrorPage: React.FC<unknown> = () => {
  const { classes } = useStyles();
  const [displayPhoneNumber, setDisplayPhoneNumber] = useState(false);

  const handleCallUsClick = useCallback(() => {
    setDisplayPhoneNumber(true);
  }, [setDisplayPhoneNumber]);

  const purchaseError = useSelector(getPurchaseError);
  const displayTechnicalFailure = sessionStorage.getItem('displayTechnicalFailure');

  const failureMessage = useMemo(() => {
    if (purchaseError === PurchaseErrorReason.INVALID_MEMBERSHIP_FAILURE) {
      return 'We were unable to verify your Costco membership number. Please call us to complete your purchase.';
    } else if (displayTechnicalFailure) {
      return 'We’re encountering a network issue and cannot process your payment online. Please speak with an agent to continue.';
    } else {
      return 'To protect your identity, we aren’t able to process your request after three failed attempts. Please speak with an agent to continue.';
    }
  }, [displayTechnicalFailure, purchaseError]);

  return (
    <Grid container justifyContent='center' className={classes.root}>
      <div>
        <div className={classes.headerContainer}>
          <h1 className={classes.header}>We&apos;re sorry, something went wrong.</h1>
          {env.static.isAgent && <ProductQuoteNumber />}
        </div>
        <div className={classes.helpBoxContainer}>
          <img
            src={GraphicUIAgentAvatarImageUrl}
            className={classes.helpBoxIcon}
            alt='helpBoxIcon'
          />
          <div className={classes.mariaBox} data-testid='mariaBoxHelpText'>
            <p className={classes.mariaBoxContent}>{failureMessage}</p>
            <div>
              {' '}
              {!displayPhoneNumber ? (
                <Button
                  variant='primary'
                  onClick={handleCallUsClick}
                  className={classes.callUsButton}
                  analyticsElement='choice.postBindPage.technicalFailurePage.callUsButton'
                  analyticsEventDetail='true'
                >
                  Call us
                </Button>
              ) : (
                <Button variant='primary' className={classes.callUsButton}>
                  <div data-testid='TopPhoneNumberLink'>
                    <PhoneLink
                      number={partner.shared.servicePhoneNumber}
                      trackingName='TopPhoneNumberLink'
                      trackingLabel='PhoneNumber'
                    />
                  </div>
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
};
