import { LogoSpinner } from './LogoSpinner';
import { useStyles } from './LogoSpinnerWrapper.styles';

/**
 * LogoSpinnerWrapper Component
 *
 * This component is designed to display a LogoSpinner centered on the page.
 * It takes up the entire height of the parent container with white space (via flexGrow: 1) and the spinner centered vertically.
 * Intended to be used to show a spinner before page content is loaded.
 *
 * Key Features:
 * - Centrally positions a LogoSpinner within the parent container.
 * - Employs a white background to cover the default spinner in 'index.html'.
 *
 * Note: The parent container must be a flex container.
 */

export const LogoSpinnerWrapper: React.FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <LogoSpinner loaderPositionStyle='absolute' />
    </div>
  );
};
