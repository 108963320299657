import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'QuotesError' })((theme) => ({
  root: {},
  quoteContainer: {
    margin: 0,
    padding: 0,
  },
  divider: {
    backgroundColor: theme.palette.other.divider,
    margin: '20px 0px',
  },
  guidance: {
    ...theme.typography.body1,
    marginBottom: 20,
  },
  imageGrid: {
    position: 'relative',
    minHeight: 400, // must be bigger than image
    [theme.breakpoints.down('md')]: {
      minHeight: 0,
    },
  },
  imageContainer: {
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  subtitle: {
    marginBottom: 10,
  },
  cnqQnbError: {
    marginBottom: 20,
  },
}));
