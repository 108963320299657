import { memo, useEffect } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem, NumberFormat } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { Select, TextField } from '@ecp/features/sales/shared/components';
import {
  getPrimaryInsuredAddressInfo,
  useField,
  usePostBindField,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { OptionProps } from '@ecp/features/sales/shared/types';

import { useStyles } from './PostBindContactInformationQuestion.styles';

export const PostBindContactInformationQuestion: React.FC = memo(() => {
  const { classes } = useStyles();

  const mailingAddressLine1 = usePostBindField('mailing.address.line1');
  const mailingAddressLine2 = usePostBindField('mailing.address.line2');
  const mailingAddressCity = usePostBindField('mailing.address.city');
  const mailingAddressState = usePostBindField('mailing.address.state');
  const mailingAddressZipcode = usePostBindField('mailing.address.zipcode');
  const contactPhone = usePostBindField('person.phone');

  const { line1, line2, city, state, zipcode } = useSelector(getPrimaryInsuredAddressInfo);
  const phone = useField(`${String(useField('primaryInsured.person.ref').value)}.phone`).value;

  useAddFields({ mailingAddressZipcode });

  useEffect(() => {
    if (mailingAddressLine1.exists) {
      if (mailingAddressLine1.value === undefined && line1)
        mailingAddressLine1.props.actionOnComplete(line1);
      if (mailingAddressLine2.value === undefined && line2)
        mailingAddressLine2.props.actionOnComplete(line2);
      if (mailingAddressCity.value === undefined && city)
        mailingAddressCity.props.actionOnComplete(city);
      if (mailingAddressState.value === undefined && state)
        mailingAddressState.props.actionOnComplete(`STATE_CODE.${state}`);
      if (mailingAddressZipcode.value === undefined && zipcode)
        mailingAddressZipcode.props.actionOnComplete(zipcode);
    }
    if (contactPhone.exists && contactPhone.value === undefined && phone)
      contactPhone.props.actionOnComplete(phone);
  }, [
    line1,
    line2,
    city,
    state,
    zipcode,
    phone,
    mailingAddressLine1,
    mailingAddressLine2,
    mailingAddressCity,
    mailingAddressState,
    mailingAddressZipcode,
    contactPhone,
  ]);

  if (!mailingAddressLine1.exists) return null;

  return (
    <Grid container>
      <GridItem topSpacing='sm' xs={12} md={6} lg={6} className={classes.columnLeft}>
        <TextField
          {...mailingAddressLine1.props}
          id='MailingAddressLine1'
          label='Address'
          ariaLabel='Mailing address line 1'
          trackingName='contact_address'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
        />
      </GridItem>
      <GridItem topSpacing='sm' xs={12} md={6} lg={6} className={classes.columnRight}>
        <TextField
          {...mailingAddressLine2.props}
          id='MailingAddressLine2'
          label='Apt./Unit # (optional)'
          ariaLabel='Mailing address unit# (optional)'
          trackingName='contact_address_line_2'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
        />
      </GridItem>
      <GridItem topSpacing='sm' xs={12} md={4} lg={4} className={classes.columnLeft}>
        <TextField
          {...mailingAddressCity.props}
          id='MailingAddressCity'
          label='City'
          ariaLabel='Mailing address city'
          trackingName='contact_address_city'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
        />
      </GridItem>
      <GridItem topSpacing='sm' xs={12} md={4} lg={4} className={classes.columnMiddle}>
        <Select
          {...(mailingAddressState.props as OptionProps)}
          id='MailingAddressState'
          label='State'
          inputButtonAriaLabel='Additional Information Mailing State'
          trackingName='contact_address_state'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
        />
      </GridItem>
      <GridItem topSpacing='sm' xs={12} md={4} lg={4} className={classes.columnRight}>
        <NumberFormat
          {...mailingAddressZipcode.props}
          id='MailingAddressZipcode'
          formatType='zipcode'
          label='ZIP code'
          ariaLabel='Zip code'
          data-testid='AdditionalInformationQuestionZipcode'
          trackingName='contact_address_zip_code'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
        />
      </GridItem>
      {contactPhone.exists && (
        <GridItem topSpacing='sm' xs={12} md={6} lg={6} className={classes.columnLeft}>
          <NumberFormat
            {...contactPhone.props}
            id='ContactPhone'
            label='Phone number (optional)'
            ariaLabel='Phone number'
            helperText='What is the best number to reach you at if we have any questions about your policies?'
            mask='_'
            trackingName='postbind_phone_number'
            trackingLabel={GoogleAnalyticsLabels.REDACTED}
            formatType='phone'
          />
        </GridItem>
      )}
    </Grid>
  );
});
