import { lowerFirst } from '@ecp/utils/common';
import { sessionStorage } from '@ecp/utils/storage';

import { PurchaseErrorReason, PurchaseStatusCode } from '@ecp/features/sales/shared/constants';
import type { AppDispatch } from '@ecp/features/sales/shared/store/types';
import type { Product } from '@ecp/features/shared/product';
import type { Field, Fields } from '@ecp/types';

import { PostBindRetryMessaging } from '../../metadata/PostBindRetryMessaging.metadata';
import type { CreditCardType, PurchaseRequestOnSubmit } from '../../state/purchase';
import {
  setAccountNumber,
  setCardNumber,
  setExpirationDate,
  setName,
  setRoutingNumber,
} from '../../state/purchase';
import type {
  Policy,
  PostBindMessage,
  PostBindSummary,
  ProductPurchaseStatus,
  PurchaseResults,
} from '../../types';

export interface PaymentFields extends Fields {
  [fieldKey: string]: Field;
}
export const canProductBeRetried = (product: Product): PostBindMessage | undefined => {
  const purchasePolicyStatus = sessionStorage.getItem('purchasePolicyStatus') as Record<
    string,
    ProductPurchaseStatus
  >;

  return purchasePolicyStatus[product].errorReason
    ? PostBindRetryMessaging[product].find((key) => {
        // if we have a secondarymessagekey in our metadata we need to do a substring check
        // instead of a straight equality check for matching errorReason for supported retry scenarios.
        if (key.secondaryMessageKey) {
          return (
            purchasePolicyStatus[product].errorReason?.includes(key.secondaryMessageKey) &&
            purchasePolicyStatus[product].errorReason?.includes(key.messageKey || '')
          );
        }

        return key.messageKey === purchasePolicyStatus[product].errorReason;
      })
    : undefined;
};

// we need to check to see if the returned message for purchase is allowed to be retried
// if not we do not let the user proceed and throw an error.
export const shouldDisplayErrorPage = (
  failed: Product[],
  retry: Product[],
  purchaseError: PurchaseErrorReason | null,
  offerProductsSelected: Product[],
): boolean => {
  return (
    failed.length === offerProductsSelected.length &&
    !retry.length &&
    // TODO: Revisit this w/ phase 2 for membership error/retry
    purchaseError !== PurchaseErrorReason.INVALID_MEMBERSHIP_FAILURE
  );
};

export const getPurchaseResults = (productsSummary: PostBindSummary[]): PurchaseResults => {
  const purchasePolicyStatus = sessionStorage.getItem('purchasePolicyStatus') as Record<
    string,
    ProductPurchaseStatus
  >;

  return productsSummary.reduce(
    (acc, { product, getProductIsPurchased }) => {
      if (!purchasePolicyStatus) {
        acc.failed.push(product);
      } else if (getProductIsPurchased(purchasePolicyStatus[product])) {
        acc.purchased.push(product);
      } else if (purchasePolicyStatus[product].purchaseStatus === PurchaseStatusCode.ERROR) {
        acc.failed.push(product);
        const errorMsg = canProductBeRetried(product);
        if (errorMsg) {
          acc.retry.push(product);
          acc.errorMessages[product] = errorMsg as PostBindMessage;
        }
      }

      return acc;
    },
    {
      purchased: [],
      failed: [],
      retry: [],
      errorMessages: {},
    } as PurchaseResults,
  );
};

export const cleanUpFields = (
  productsSummary: PostBindSummary[],
  paymentFields: Record<Product, PaymentFields>,
  dispatch: AppDispatch,
): void => {
  productsSummary.forEach(({ product, payment }) => {
    // Resets credit card fields
    if (payment.paymentType) {
      dispatch(
        setCardNumber({
          cardNumber: '',
          product,
          type: lowerFirst(payment.paymentType) as CreditCardType,
        }),
      );
      // Resets name
      dispatch(
        setName({
          fullName: '',
          product,
          type: lowerFirst(payment.paymentType) as CreditCardType,
        }),
      );
      // Resets expiration date
      dispatch(
        setExpirationDate({
          expirationDate: '',
          product,
          type: lowerFirst(payment.paymentType) as CreditCardType,
        }),
      );
      // Resets bank account fields
      dispatch(setAccountNumber({ product, accountNumber: '' }));
      dispatch(setRoutingNumber({ product, routingNumber: '' }));
    }
    const paymentFieldsForProduct = paymentFields[product];
    if (paymentFieldsForProduct.creditCardCardNumber) {
      paymentFieldsForProduct.creditCardCardNumber.props.actionOnComplete('');
      paymentFieldsForProduct.creditCardExpirationDate.props.actionOnComplete('');
      paymentFieldsForProduct.creditCardFullName.props.actionOnComplete('');
    }
    if (paymentFieldsForProduct.costcoCardNumber) {
      paymentFieldsForProduct.costcoCardNumber.props.actionOnComplete('');
      paymentFieldsForProduct.costcoCardExpirationDate.props.actionOnComplete('');
      paymentFieldsForProduct.costcoCardFullName.props.actionOnComplete('');
    }
    paymentFieldsForProduct.routingNumber.props.actionOnComplete('');
    paymentFieldsForProduct.accountNumber.props.actionOnComplete('');
  });
};

export const checkEligiblePurchaseRequestProducts = async (
  productsSummary: PostBindSummary[],
  purchaseRequest: PurchaseRequestOnSubmit,
): Promise<void> => {
  const purchasePolicyStatus = sessionStorage.getItem('purchasePolicyStatus') as Record<
    string,
    ProductPurchaseStatus
  >;
  productsSummary.forEach(({ product, getProductIsPurchased }) => {
    if (getProductIsPurchased(purchasePolicyStatus[product])) {
      delete purchaseRequest[product];
    }
  });
};

export const allowPurchaseRetry = (
  policies: { [productKey: string]: Policy } | undefined,
): boolean => {
  for (const product in policies) {
    const purchaseStatus = policies[product].purchaseStatus;
    if (purchaseStatus === 'ERROR' && canProductBeRetried(product as Product)) {
      return true;
    }
  }

  return false;
};

export const isRetrySuccessful = (
  policies: { [productKey: string]: Policy } | undefined,
): boolean => {
  for (const product in policies) {
    const purchaseStatus = policies[product].purchaseStatus;
    if (purchaseStatus !== 'ERROR') {
      return true;
    }
  }

  return false;
};
