import { Grid } from '@mui/material';

import { GridItem } from '@ecp/components';

import type { CheckoutCardProps } from '../CheckoutCard';
import { CheckoutCard } from '../CheckoutCard';
import { useStyles } from './CheckoutSidebar.styles';
import { HelpCardConditional } from './HelpCard';

export const CheckoutSidebar: React.FC<CheckoutCardProps> = (props) => {
  const { classes } = useStyles();

  return (
    <Grid container className={classes.root} direction='row'>
      <GridItem xs={12} className={classes.checkoutCard}>
        <CheckoutCard {...props} />
      </GridItem>
      <GridItem topSpacing='sm' xs={12}>
        <HelpCardConditional
          phoneNumberTrackingValue='side_phone_number'
          chatTrackingValue='side_chat'
        />
      </GridItem>
    </Grid>
  );
};
