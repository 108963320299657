import { useEffect } from 'react';

import { PaymentPlan } from '@ecp/features/sales/shared/constants';
import {
  CheckoutFieldsMetaByType,
  getFieldsByKeys,
  getSelectedPaymentPlan,
  useFieldWithPrefix,
  usePniRef,
} from '@ecp/features/sales/shared/store';
import { AutoCheckoutFieldsMeta, PhoneNumberMeta } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product, ProductName } from '@ecp/features/shared/product';
import { getProductNameFromProduct } from '@ecp/features/shared/product';
import type { Field, Fields, Option } from '@ecp/types';

import {
  getCreditCardType,
  getPaymentPlanForProduct,
  getPaymentTypeForProduct,
  getSelectedPaymentOptionPremium,
  setPaymentPlan,
} from '../state';
import type { CreditCardType } from '../state/purchase';
import type { PaymentOption } from '../types';

export interface PaymentFields extends Fields {
  [fieldKey: string]: Field;
}

export const useGetPaymentType = (product?: Product): PaymentOption['paymentType'] | undefined => {
  return useSelector((state: RootStore) => {
    if (product) return getPaymentTypeForProduct(state, product);

    return undefined;
  });
};

export const useGetPaymentPlan = (product: Product): PaymentOption['paymentPlan'] | undefined => {
  return useSelector((state: RootStore) => getPaymentPlanForProduct(state, product));
};

export const useGetPaymentPremiumAmount = (
  product: Product,
): PaymentOption['totalPremium'] | undefined => {
  return useSelector(
    (state: RootStore) => getSelectedPaymentOptionPremium(state, product).totalPremium,
  );
};

export const useGetAutoCheckoutFields = (): PaymentFields => {
  const dispatch = useDispatch();

  return useSelector(
    (state: RootStore) =>
      getFieldsByKeys(
        state,
        'static.checkout.auto',
        Object.keys(AutoCheckoutFieldsMeta),
        dispatch,
      ) as PaymentFields,
  );
};

export const useGetPropertyCheckoutFields = (
  productName: ProductName | undefined,
): PaymentFields => {
  const dispatch = useDispatch();
  const pniRef = usePniRef();
  const usePersonField = useFieldWithPrefix(pniRef, 'person.<id>');
  const {
    question: { required, ...questionOthers },
    ...others
  } = usePersonField('phone');
  const keys = productName ? Object.keys(CheckoutFieldsMetaByType[productName]) : [];

  return {
    phoneNumber: {
      question: {
        required: PhoneNumberMeta.required,
        ...questionOthers,
      },
      ...others,
    },
    ...useSelector(
      (state: RootStore) =>
        getFieldsByKeys(state, `static.checkout.${productName}`, keys, dispatch) as PaymentFields,
    ),
  };
};

export const useGetSelectedProductsCheckoutFields = (
  products: Product[],
): Record<Product, PaymentFields> => {
  const dispatch = useDispatch();
  const pniRef = usePniRef();
  const usePersonField = useFieldWithPrefix(pniRef, 'person.<id>');
  const {
    question: { required, ...questionOthers },
    ...others
  } = usePersonField('phone');

  const getFields = useSelector((state: RootStore) => (productName: ProductName) => ({
    ...(productName === 'home' && {
      phoneNumber: {
        question: {
          required: PhoneNumberMeta.required,
          ...questionOthers,
        },
        ...others,
      },
    }),
    ...(getFieldsByKeys(
      state,
      `static.checkout.${productName}`,
      Object.keys(CheckoutFieldsMetaByType[productName]),
      dispatch,
    ) as PaymentFields),
  }));

  return products.reduce((acc, product) => {
    const productName = getProductNameFromProduct(product);
    acc[product] = getFields(productName);

    return acc;
  }, {} as Record<Product, PaymentFields>);
};

export const useGetCreditCardType = (
  product: Product | undefined,
  type: CreditCardType,
): string | undefined => {
  return useSelector((state: RootStore) => {
    if (product && type) return getCreditCardType(state, product, type);

    return undefined;
  });
};

export const useSetDefaultPaymentPlanForProduct = <
  T extends Array<{ option: Option }> = Array<{ option: Option }>,
>({
  paymentOptions,
  product,
}: {
  paymentOptions: T;
  product: Product;
}): void => {
  const dispatch = useDispatch();
  const paymentPlan = useSelector(getSelectedPaymentPlan);

  useEffect(() => {
    const monthlyOption = paymentOptions.find((item) => item.option.value !== 'Full')?.option.value;
    const fullOption = paymentOptions.find((item) => item.option.value === 'Full')?.option.value;
    let defaultSelection: string | undefined;
    if (
      (paymentPlan === PaymentPlan.FULL_PREMIUM && fullOption) ||
      (paymentPlan !== PaymentPlan.FULL_PREMIUM && !monthlyOption)
    )
      defaultSelection = fullOption;
    else if (
      (paymentPlan !== PaymentPlan.FULL_PREMIUM && monthlyOption) ||
      (paymentPlan === PaymentPlan.FULL_PREMIUM && !fullOption)
    )
      defaultSelection = monthlyOption;

    if (defaultSelection) {
      dispatch(setPaymentPlan({ product, paymentPlan: defaultSelection }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
