import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CarrierMessagingDialog' })((theme) => ({
  subHeader: {
    marginTop: 15,
  },
  body: {
    ...theme.typography.body1,

    '& ul': {
      paddingLeft: 15,
    },

    '& li': {
      marginBottom: 15,
    },

    '& .MuiLink-button': {
      ...theme.mixins.linkButton,
      color: theme.palette.primary.main,
      display: 'inherit',
      textDecoration: 'underline',
    },
    '& p': {
      marginTop: 20,
    },
  },
}));
