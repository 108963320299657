import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<void, 'markValueSelected'>({ name: 'Slider' })(
  (...[theme, , classes]) => ({
    root: {
      '& .MuiFormHelperText-root.Mui-error': {
        marginTop: 10,
      },
    },
    control: {},
    formControl: {},
    label: {
      marginBottom: 15,
    },
    markValue: {
      ...theme.typography.body1,
      color: theme.palette.text.primary,
      display: 'flex',
      justifyContent: 'center',
      padding: '9px 0',
      margin: -10,
      width: 44,
      [`&:not(.${classes.markValueSelected}):hover`]: {
        color: theme.palette.text.primary,
        fontWeight: 'bold',
      },
      '@media only screen and (max-width:500px)': {
        fontSize: 14,
      },
    },
    markValueSelected: {
      ...theme.typography.body1Bold,
      color: theme.palette.primary.main,
    },
    firstMark: {
      marginLeft: 12,
      '@media only screen and (max-width:500px)': {
        marginLeft: 2,
      },
    },
    errorText: {},
  }),
);

export const useMuiSliderStyles = makeStyles<void, 'active' | 'valueLabel'>({
  name: 'MuiSlider',
})((...[theme, , classes]) => ({
  root: {
    height: 2,
    marginBottom: 30,
    maxWidth: theme.breakpoints.values.lg,
    padding: '8px 0',
  },
  active: {},
  thumb: {
    backgroundColor: theme.palette.primary.main,
    height: 21,
    marginTop: 0,
    marginLeft: 0,
    width: 21,
    '&:hover': {
      boxShadow: 'none',
    },
    [`&[aria-valuenow="0"] .${classes.valueLabel}`]: {
      display: 'none',
    },
    '@media (hover: none)': {
      boxShadow: 'none',
    },
  },
  valueLabel: {
    ...theme.typography.body2,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    lineHeight: '20px',
    textAlign: 'center',
    top: -17,
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
  mark: {
    opacity: 0,
  },
  markLabel: {
    color: theme.palette.primary.contrastText,
    transform: 'translateX(-47%)',
    top: 30,

    // HACK for the rightmost label when it's several symbols long
    '@media only screen and (max-width: 805px)': {
      textAlign: 'center',
      whiteSpace: 'normal',
    },
  },
  track: {
    borderRadius: 2.5,
    color: theme.palette.primary.main,
    height: 5,
    opacity: 1,
  },
  rail: {
    borderRadius: 2.5,
    color: theme.palette.primary.light,
    height: 5,
    opacity: 1,
  },
}));
