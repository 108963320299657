import { useEvent } from '@ecp/utils/react';

import { useShowMoreOrLess } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import type { AnswerValue } from '@ecp/types';

export const RoofMaterialQuestion: React.FC<QuestionProps> = (props) => {
  const useRoofPropertyField = useFieldWithPrefix('property.roof', 'property.roof');
  const roofMaterial = useRoofPropertyField('material');
  const roofMaterialDescription = useRoofPropertyField('materialDescription');

  const actionOnComplete = useEvent(async (value: AnswerValue) => {
    roofMaterial.props.actionOnComplete(value);
    if (value !== 'OTHER_EXT') {
      roofMaterialDescription.props.actionOnComplete(null);
    }
  });

  const { label = roofMaterial.question?.title, trackingName = 'roof_material_button' } = props;

  useAddFields({ roofMaterial });

  const {
    showMore: showMoreRoofMaterial,
    displayOptions: displayRoofMaterial,
    handleClick: handleRoofMaterialShowMoreOrLess,
  } = useShowMoreOrLess(roofMaterial.props.options, 4, 'RoofMaterialMoreChoices');

  if (!roofMaterial.exists) return null;

  return (
    <RadioGroupWithOptions
      {...roofMaterial.props}
      options={displayRoofMaterial}
      label={label}
      showMoreOrLessProps={{
        showMore: showMoreRoofMaterial,
        onClick: handleRoofMaterialShowMoreOrLess,
        groupTestId: 'RoofMaterialMoreChoices',
      }}
      trackingName={trackingName}
      cardSize='small'
      actionOnComplete={actionOnComplete}
    />
  );
};
