import { Grid } from '@mui/material';

import { useStyles } from './RVPErrorCustomPage.styles';

export const RVPErrorCustomGuidance: React.FC<unknown> = () => {
  const { classes } = useStyles();

  return (
    <Grid>
      <div className={classes.guidanceHead}>Customer identification documentation:</div>
      <ul className={classes.guidanceList}>
        <li>Photo ID with date of birth.</li>
        <li>Current bank statement.</li>
        <li>Current paycheck stub.</li>
        <li>Current utility bill.</li>
        <li>Mortgage or lease agreements.</li>
        <li>
          Non-photo ID issued by the United States or any of it&rsquo;s agencies which shows the
          name and address of customer.
        </li>
      </ul>
      <div className={classes.guidanceHead}>Vehicle identification documentation:</div>
      <ul className={classes.guidanceList}>
        <li>Vehicle registration.</li>
        <li>DMV vehicle registration renewal notice.</li>
        <li>
          If the vehicle is a new purchase, please obtain one of the following along with a current
          address:
          <ul className={classes.guidanceInnerList}>
            <li className={classes.guidanceInnerListItem}>
              Bill of sale for a licensed dealer or private seller.
            </li>
            <li className={classes.guidanceInnerListItem}>Temporary DMV registration.</li>
            <li className={classes.guidanceInnerListItem}>
              Finance or lease agreement identifying the vehicle and providing owner information.
            </li>
          </ul>
        </li>
      </ul>
    </Grid>
  );
};
