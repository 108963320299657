import { datadogLogs, datadogRum, setCustomActionName, setGlobalContext } from '@ecp/utils/logger';

import { env } from '@ecp/env';

export const initializeLogger = (): void => {
  // Datadog team requested their environment be called prod. So we update that here.
  const dataDogEnvironment = env.runtimeEnv === 'production' ? 'prod' : env.runtimeEnv;

  const datadogCommonConfig: Parameters<typeof datadogLogs.init>[0] &
    Parameters<typeof datadogRum.init>[0] = {
    applicationId: env.datadogApplicationId,
    clientToken: env.datadogClientToken,
    site: 'datadoghq.com',
    service: env.datadogService,
    env: dataDogEnvironment,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    version: env.static.releaseVersion,
    /** Mask all text on the screen */
    defaultPrivacyLevel: 'mask',
  } as const;

  datadogLogs.init({
    ...datadogCommonConfig,
    // forwardErrorsToLogs defaults to true. This will automatically capture
    // all uncaught exceptions. If an uncaught exception contains PII/PCI data
    // it will not go through the scrubbing methods so we can't enable it
    forwardErrorsToLogs: false,
  });

  datadogRum.init({
    ...datadogCommonConfig,
    trackUserInteractions: true,
    allowedTracingUrls: env.datadogAllowedTracingOrigins,
    beforeSend: setCustomActionName,
    enableExperimentalFeatures: ['feature_flags'],
    trackResources: true,
    trackLongTasks: true,
  });

  if (env.datadogSessionReplayEnabled) datadogRum.startSessionReplayRecording();
  setGlobalContext('expId', env.static.expId);
  setGlobalContext('partnerName', env.static.partnerName);
  setGlobalContext('baseExp', env.static.baseExp);
};
