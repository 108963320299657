import { getProductNameFromUnbundledLob } from '@ecp/features/shared/product';

import type { Metadata } from './types';

export const convertArrayOfUnbundledLobTuplesToArrayOfProductNamesWithStaticPrefixTuples = <
  T extends Metadata['mutuallyExclusiveOptions'] | Metadata['mutuallyDisabledOptions'],
>(
  arrayOfUnbundledLobTuples: T,
): T extends Metadata['mutuallyExclusiveOptions']
  ? Metadata['mutuallyExclusiveOptionsWithStaticPrefix']
  : Metadata['mutuallyDisabledOptionsWithStaticPrefix'] =>
  arrayOfUnbundledLobTuples.map((options) =>
    options.map(
      (option) => `static.product.lob.${getProductNameFromUnbundledLob(option)}` as const,
    ),
  );
