import { env } from '@ecp/env';

export interface Metadata {
  hideVehicleElementsNotAvailable?: boolean;
  title: string;
}

export const vehicleCoveragesFormMetadata: Metadata = {
  title: env.static.isAgent ? 'Coverages for the ' : 'Coverages for your ',
};
