import * as interactionId from '@ecp/utils/analytics/interaction-id';
import { location } from '@ecp/utils/routing';

import { ErrorReason } from '@ecp/features/sales/shared/constants';
import type { ThunkAction } from '@ecp/features/sales/shared/store/types';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import { SalesRequestError } from '@ecp/features/sales/shared/utils/network';

import { updateGlobalError } from '../global/actions';
import { setBlobRetrieveFailed, setOffersApiRecallFail } from './actions';

const trackRecallError = ({
  error,
  emailUUID,
  inquiryId,
  dalSessionId,
}: {
  error: SalesRequestError;
  emailUUID?: string;
  inquiryId: string;
  dalSessionId: string;
}): void => {
  const { response: { status = undefined, statusText = undefined } = {}, requestId = '' } = error;
  const searchParams = location.search;
  if (searchParams.source !== 'bridge') {
    trackSapiAnalyticsEvent({
      element: 'choice.emailRetrieve.recall',
      event: 'captured',
      eventDetail: `emailUUID: ${emailUUID}, offersReponseCode: ${status},
      offersResponseText: ${statusText}, XTraceID: ${requestId}, inquiryID: ${inquiryId}, dalSessionId: ${dalSessionId}`,
    });
  }
};

const handleNonRulesException = (): ThunkAction<void> => (dispatch) => {
  dispatch(
    setOffersApiRecallFail(
      'Unable to find any quotes for this product. Please confirm or adjust your selection.',
    ),
  );
  // reset interaction Id when recall doesn't return any offers
  interactionId.reset();
};

const handleRulesException =
  ({ error }: { error: SalesRequestError }): ThunkAction<void> =>
  (dispatch) => {
    const { requestId = '', name, errorStack, message } = error;

    dispatch(
      updateGlobalError({
        hasError: true,
        requestId: requestId || '',
        errorReason: ErrorReason.RULES_EXCEPTION,
        name,
        text: errorStack,
        message,
      }),
    );
  };

const handleSalesRequestError =
  ({
    error,
    emailUUID,
    inquiryId,
    dalSessionId,
  }: {
    error: SalesRequestError;
    emailUUID?: string;
    inquiryId: string;
    dalSessionId: string;
  }): ThunkAction<Promise<void>> =>
  async (dispatch) => {
    const { response: { status } = {}, errorReason } = error;
    const searchParams = location.search;
    if (searchParams.source !== 'bridge') {
      trackRecallError({ error, emailUUID, inquiryId, dalSessionId });
    }

    if (status === 400 || status === 404) {
      if (errorReason === ErrorReason.RULES_EXCEPTION) {
        dispatch(handleRulesException({ error }));
      } else {
        dispatch(handleNonRulesException());
      }
    } else {
      throw new Error('Error Recalling Quote');
    }
  };

export const handleBlobRetrieveError =
  ({
    error,
    emailUUID,
    inquiryId,
    dalSessionId,
  }: {
    error: Error | SalesRequestError;
    emailUUID?: string;
    inquiryId: string;
    dalSessionId: string;
  }): ThunkAction<Promise<void>> =>
  async (dispatch) => {
    dispatch(setBlobRetrieveFailed(true));

    if (error instanceof SalesRequestError) {
      await dispatch(handleSalesRequestError({ error, emailUUID, inquiryId, dalSessionId }));
    }
  };
