import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'FullNameAcknowledgements' })((theme) => ({
  acknowledgementListContainer: {
    margin: '10px 0 0',
    listStyle: 'none',
    paddingLeft: 33,
  },
  inputHeader: {
    ...theme.typography.body2,
    color: theme.palette.text.tertiary,
  },
  input: {
    width: '40%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  acknowledgementItem: theme.typography.body1,
  verticalAlignMiddle: theme.typography.body1,
}));
