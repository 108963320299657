import { merge } from '@ecp/utils/common';

import { LineOfBusiness } from '@ecp/features/shared/product';
import type { StateOption } from '@ecp/types';

export interface BaseMetadata {
  fraudWarning?: string;
}

interface StateMetadata extends StateOption<BaseMetadata>, BaseMetadata {}

const homeData = {
  fraudWarning: `Any person who, with intent to defraud or knowing that he is facilitating a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement is guilty of insurance fraud.`,
  stateOptions: {
    AK: {
      fraudWarning: `In connection with this application for insurance or notice of renewal, we will review your credit report or obtain or use a credit-based insurance score based on the information contained in your credit report. We may use a third party in connection with the development of your insurance score. We may use this information to decide whether to insure you or how much to charge.`,
    },
    CA: {
      fraudWarning: `For your protection California law requires the following to appear on this form. Any person who knowingly presents false or fraudulent information to obtain or amend insurance coverage or to make a claim for the payment of a loss is guilty of a crime and may be subject to fines and confinement in state prison.`,
    },
    DC: {
      fraudWarning: `Your premium and eligibility are based on information provided. WARNING: it is a crime to provide false or misleading information to an insurer for the purpose of defrauding the insurer or any other person. Penalties include imprisonment and/or fines. In addition, an insurer may deny insurance benefits if false information materially related to a claim was provided by the applicant.`,
    },
    DE: {
      fraudWarning: `In connection with this application for insurance, we may obtain or use a credit based insurance score based on the information contained in your credit report. We may use a third party in connection with the development of your insurance score. If we do use a credit based score, you will have the right on an annual basis to request that we obtain your updated insurance score, based on a current credit report, to determine whether use of the new insurance score would result in a decrease in your insurance premium. If the new score would result in a decrease in your premium, we will make that reduction. If the new credit information would not reduce your premium, your credit report will not be used to impact your premium in any way.`,
    },
    FL: {
      fraudWarning: `Any person who knowingly and with intent to injure, defraud, or deceive any insurer files a statement of claim or an application containing any false, incomplete, or misleading information is guilty of a felony of the third degree.`,
    },
    LA: {
      fraudWarning: `Your premium and eligibility are based on information provided. Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.`,
    },
    NJ: {
      fraudWarning: `Your premium and eligibility are based on information provided. Any person who includes any false or misleading information on an application for an insurance policy is subject to criminal and civil penalties.`,
    },
    NM: {
      fraudWarning: `Any person who knowingly presents a false or fraudulent claim for payment of loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to civil fines and criminal penalties.`,
    },
    OR: {
      fraudWarning: `Any person who, with intent to defraud or knowing that he is facilitating a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement may be guilty of insurance fraud.`,
    },
    VA: {
      fraudWarning: `Your premium and eligibility are based on information provided. It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.`,
    },
    WA: {
      fraudWarning: `Your premium and eligibility are based on information provided. It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.`,
    },
    WV: {
      fraudWarning: `Your premium and eligibility are based on information provided. Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.`,
    },
  },
};

const rentersData = {
  stateOptions: {
    AL: {
      fraudWarning: `Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or who knowingly presents false information in an application for insurance is guilty of a crime and may be subject to restitution fines or confinement in prison, or any combination thereof.`,
    },
    CA: {
      fraudWarning: `For your protection California law requires the following to appear on this form. Any person who knowingly presents false or fraudulent information to obtain or amend insurance coverage or to make a claim for the payment of a loss is guilty of a crime and may be subject to fines and confinement in state prison.`,
    },
    DC: {
      fraudWarning: `Your premium and eligibility are based on information provided. WARNING: it is a crime to provide false or misleading information to an insurer for the purpose of defrauding the insurer or any other person. Penalties include imprisonment and/or fines. In addition, an insurer may deny insurance benefits if false information materially related to a claim was provided by the applicant.`,
    },
    FL: {
      fraudWarning: `Any person who knowingly and with intent to injure, defraud, or deceive any insurer files a statement of claim or an application containing any false, incomplete, or misleading information is guilty of a felony of the third degree.`,
    },
    KY: {
      fraudWarning: `Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or who knowingly presents false information in an application for insurance is guilty of a crime and may be subject to restitution fines or confinement in prison, or any combination thereof.`,
    },
    LA: {
      fraudWarning: `Your premium and eligibility are based on information provided. Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.`,
    },
    NM: {
      fraudWarning:
        'Any person who knowingly presents a false or fraudulent claim for payment of loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to civil fines and criminal penalties.',
    },
    NV: {
      fraudWarning: `Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.`,
    },
    NJ: {
      fraudWarning: `Your premium and eligibility are based on information provided. Any person who includes any false or misleading information on an application for an insurance policy is subject to criminal and civil penalties.`,
    },
    OH: {
      fraudWarning: `Any person who, with intent to defraud or knowing that he is facilitating a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement is guilty of insurance fraud.`,
    },
    OR: {
      fraudWarning: `Any person who, with intent to defraud or knowing that he is facilitating a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement is guilty of insurance fraud.`,
    },
    TN: {
      fraudWarning: `Your premium and eligibility are based on information provided. It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.`,
    },
    VA: {
      fraudWarning: `Your premium and eligibility are based on information provided. It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.`,
    },
    WA: {
      fraudWarning:
        'Your premium and eligibility are based on information provided. It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.',
    },
    WV: {
      fraudWarning: `Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.`,
    },
  },
};

const autoData = {
  stateOptions: {
    CA: {
      fraudWarning: `For your protection California law requires the following to appear on this form. Any person who knowingly presents false or fraudulent information to obtain or amend insurance coverage or to make a claim for the payment of a loss is guilty of a crime and may be subject to fines and confinement in state prison.`,
    },
  },
};

const bundleAutoRenters = merge(rentersData, autoData);

const bundle = merge(homeData, autoData);

export const metadata: Partial<Record<LineOfBusiness, StateMetadata>> = {
  [LineOfBusiness.HOME]: homeData,
  [LineOfBusiness.RENTERS]: rentersData,
  [LineOfBusiness.AUTO]: autoData,
  [LineOfBusiness.BUNDLE]: bundle,
  [LineOfBusiness.BUNDLE_AUTO_RENTERS]: bundleAutoRenters,
};

export default metadata;
