import * as interactionId from '@ecp/utils/analytics/interaction-id';
import { camelizeKeys, uuid } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';
import { stringifySearchParams } from '@ecp/utils/routing';
import { sessionStorage } from '@ecp/utils/storage';

import { env } from '@ecp/env';
import type { AmeripriseAdvisor } from '@ecp/features/sales/shared/ameriprise-advisor/types';
import { PARTNER_EXPERIENCE_ID } from '@ecp/features/sales/shared/constants';
import type { ExperienceId } from '@ecp/partners';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getRequestParams = async () => ({
  requestId: uuid(),
  expId: (sessionStorage.getItem(PARTNER_EXPERIENCE_ID) || env.static.expId) as ExperienceId,
  interactionId: interactionId.get(),
});

export const fetchAdvisorsByLastName = async (
  lastName: string,
  environment: Pick<
    typeof env,
    'ameripriseAdvisorSearchApiKey' | 'ameripriseAdvisorSearchApiRoot'
  > = env,
): Promise<AmeripriseAdvisor[]> => {
  const { requestId, expId, interactionId } = await getRequestParams();
  const searchParams = stringifySearchParams({ lastname: lastName });
  const requestUrl = `${environment.ameripriseAdvisorSearchApiRoot}/AdvisorSearchByLastName${searchParams}`;

  return await fetch(requestUrl, {
    headers: {
      'Content-Type': 'application/json',
      'afe-Request-Id': requestId,
      'afe-interaction-id': interactionId,
      'afe-Experience-Id': expId,
      'afe-api-key': environment.ameripriseAdvisorSearchApiKey || '',
    },
  })
    .then((response) => {
      const { status, statusText } = response;
      if (status !== 200) {
        datadogLog({
          logType: 'error',
          message: 'Error calling Ameriprise Advisor API',
          context: {
            logOrigin: 'libs/features/sales/shared/ameriprise-advisor/lib/src/util/api.ts',
            functionOrigin: 'fetchAdvisorsByLastName',
            requestUrl,
            requestId,
            responseStatus: status,
            statusText: statusText,
          },
        });

        return [];
      }

      return response.json();
    })
    .then((data: AmeripriseAdvisor[]) => camelizeKeys(data))
    .catch((error) => {
      datadogLog({
        logType: 'error',
        message: `Error calling Ameriprise Advisor API - ${error?.message}`,
        context: {
          logOrigin: 'libs/features/sales/shared/ameriprise-advisor/lib/src/util/api.ts',
          functionOrigin: 'fetchAdvisorsByLastName',
          requestUrl,
          requestId,
          responseError: JSON.stringify(error),
        },
        error,
      });

      return [];
    });
};
