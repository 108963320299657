import { Keywords } from './constants';
import { resolveOperation } from './operations';
import {
  isAnswer,
  isEmpty,
  isObject,
  isQuestion,
  isString,
  isType,
  isUndefined,
} from './predicates';
import type { Answer, Answers, AnswerSet, Errors, Question, Questions, QuestionSet } from './types';
import { flatten } from './utils';

export interface Options {
  strict: boolean;
}

export function validateAnswer(
  question: Question,
  answer: Answer,
  answers: AnswerSet = {},
): string[] {
  if (!isQuestion(question)) throw new Error('Unrecognized Question');
  if (!isAnswer(answer) && !isEmpty(answer))
    throw new Error('Urecognized Answer - Expected a String, Number, or Boolean Answer');

  const _answers: Answers = flatten<Answers>(answers);

  const {
    type,
    required,
    options,
    min,
    max,
    minLength,
    maxLength,
    criteria,
    _errors = {},
  } = question;

  const errors: string[] = [];

  if (isEmpty(answer)) {
    if (
      required === true ||
      (isObject(required) && resolveOperation(required, { value: answer, answers: _answers }))
    )
      errors.push(_errors[Keywords.REQUIRED] || 'Required field');

    return errors;
  }

  if (!isType(type, answer)) return [_errors[Keywords.TYPE] || `Expected type "${type}"`];

  if (options && !options.map(({ value }) => value).includes(answer))
    errors.push(_errors[Keywords.OPTIONS] || `Invalid option "${answer}"`);
  if (min && answer < min)
    errors.push(_errors[Keywords.MIN] || `Expected a value of at least ${min}`);
  if (max && answer > max)
    errors.push(_errors[Keywords.MAX] || `Expected a value of at most ${max}`);
  if (minLength && (answer as string).length < minLength)
    errors.push(_errors[Keywords.MINLENGTH] || `Expected at least ${minLength} characters`);
  if (maxLength && (answer as string).length > maxLength)
    errors.push(_errors[Keywords.MAXLENGTH] || `Expected at most ${maxLength} characters`);

  if (criteria) {
    criteria.forEach(({ error = 'error', ...operation }) => {
      if (!resolveOperation(operation, { value: answer, answers: _answers })) errors.push(error);
    });
  }

  return errors;
}

export function validateAnswers(
  questions: QuestionSet,
  answers: AnswerSet,
  options: Options = { strict: true },
): Errors {
  if (!isObject(answers)) throw new Error('Expected answers to be an AnswerSet');
  if (!isObject(questions)) throw new Error('Expected questions to be a QuestionSet');

  const results: Errors = [];

  const _answers: Answers = flatten<Answers>(answers);
  const _questions: Questions = flatten<Questions>(questions, true);

  Object.keys(_answers).forEach((key) => {
    const question: Question = _questions[key];
    if (isUndefined(question)) {
      if (options.strict) throw new Error(`Question Path "${key}" does not exist`);

      return;
    }
    if (!isQuestion(question)) throw new Error(`Unrecognized Question @ "${key}"`);
    const answer: Answer = _answers[key];
    if (!isAnswer(answer) && !isEmpty(answer))
      throw new Error(
        `Urecognized Answer - Expected a String, Number, or Boolean Answer @ "${key}"`,
      );
    const errors = validateAnswer(question, answer, answers);
    results.push(...errors.map((error) => ({ path: [key], error })));
  });

  return results;
}

export function evaluateQuestionSet(questions: QuestionSet, answers: AnswerSet): QuestionSet {
  if (!isObject(questions)) throw new Error('Unrecognized Questions');
  if (!isObject(answers)) throw new Error('Unrecognized Answers');

  const _answers: Answers = flatten<Answers>(answers);

  function traverseQuestions(q: QuestionSet): QuestionSet {
    const output: QuestionSet = {};
    Object.entries(q).forEach(([key, value]) => {
      if (isQuestion(value)) {
        output[key] = { ...value };
        if (isString(value.required))
          output[key].required = value.required === 'true' ? true : false;
        if (isObject(value.required))
          output[key].required = resolveOperation(value.required, { value: '', answers: _answers });

        if (isString(value.hide)) output[key].hide = value.hide === 'true' ? true : false;
        if (isObject(value.hide))
          output[key].hide = resolveOperation(value.hide, { value: '', answers: _answers });
      } else if (isObject(value)) {
        output[key] = traverseQuestions(value);
      } else throw new Error('Unhandled Question traversal error');
    });

    return output;
  }

  return traverseQuestions(questions);
}
