import { ErrorReason } from '@ecp/features/sales/shared/constants';
import type { ProductName } from '@ecp/features/shared/product';

export const getErrorReasonFromPolicyTypes = (
  availablePolicyTypes: ProductName[],
  unavailableRequestedPolicyTypes: ProductName[],
): ErrorReason | null => {
  let errorReason = null;
  if (!availablePolicyTypes.length) {
    errorReason = ErrorReason.PARTNER_NOT_ELIGIBLE;
  } else if (
    unavailableRequestedPolicyTypes.length === 1 &&
    unavailableRequestedPolicyTypes.includes('auto')
  ) {
    errorReason = ErrorReason.LOB_AUTO_NOT_ELIGIBLE;
  } else if (
    unavailableRequestedPolicyTypes.length === 1 &&
    unavailableRequestedPolicyTypes.includes('home')
  ) {
    errorReason = ErrorReason.LOB_HOME_NOT_ELIGIBLE;
  } else if (
    unavailableRequestedPolicyTypes.length === 1 &&
    unavailableRequestedPolicyTypes.includes('renters')
  ) {
    errorReason = ErrorReason.LOB_RENTERS_NOT_ELIGIBLE;
  } else if (unavailableRequestedPolicyTypes.length > 1) {
    errorReason = ErrorReason.LOB_BUNDLE_NOT_ELIGIBLE;
  }

  return errorReason;
};
