import {
  getAnswersFormattedDateAfterDays,
  isMoreThan60DaysFromToday,
  isPastDate,
  isToday,
} from '@ecp/utils/date';

import type { PolicyEffectiveDateAdjustor } from '../metadata';

export const adjustPolicyEffectiveStartDate: PolicyEffectiveDateAdjustor = (
  policyEffectiveDateParam: string,
): string => {
  if (isToday(policyEffectiveDateParam) || isPastDate(policyEffectiveDateParam)) {
    return getAnswersFormattedDateAfterDays(1);
  }

  // If the policy start date is more than 60 days from now, default to 60 days from now
  if (isMoreThan60DaysFromToday(policyEffectiveDateParam)) {
    return getAnswersFormattedDateAfterDays(60);
  }

  return policyEffectiveDateParam;
};
