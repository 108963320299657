import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './HeaderMainContent.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css, theme } = useStylesTss();

  return useStylesBase(params, {
    ...styleOverrides,
    props: {
      ...styleOverrides?.props,
      classes: {
        headerDivider: css({
          height: 51,
          backgroundColor: theme.palette.text.primary,
          margin: 'auto 0',
          [theme.breakpoints.down('md')]: {
            margin: 'auto 15px auto 10px',
          },
        }),
        partnerLogoImage: css({
          width: 197,
          height: 37,
          margin: 15,
          [theme.breakpoints.down('md')]: {
            width: 132,
            height: 25,
            margin: '15px 5px',
          },
        }),
        ...styleOverrides?.props.classes,
      },
    },
  });
};
