import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DiscountsForm' })((theme) => ({
  ...theme.mixins.formAll,
  secondaryButtonContainer: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 30,
    },
  },
  wildfireMitigationSection: {
    marginTop: 40,
    padding: 12,
    borderRadius: 3,
    border: `2px solid ${theme.palette.error.main}`,
    background: theme.palette.error.light,
  },
  discountsCheckBoxGroup: {},
}));
