import { GraphicGlobalLoader, GraphicGlobalLoaderMobile, useIsMobile } from '@ecp/themes/base';

import { Page } from '../Page';
import { useStyles } from './GlobalLoading.styles';
import metadata from './metadata';

export type GlobalLoadingProps = {
  loadingPageTitle: string;
};

export const GlobalLoading: React.FC<GlobalLoadingProps> = (props) => {
  const { loadingPageTitle } = props;
  const { classes } = useStyles();
  const isMobile = useIsMobile();

  return (
    <Page analyticsElement='choice.quoteLoadingPage.page'>
      <h1 className={classes.title}>{loadingPageTitle}</h1>
      <div className={classes.loadingContainer}>
        <div className={classes.loadingIndicatorWrap}>
          {isMobile ? <GraphicGlobalLoaderMobile /> : <GraphicGlobalLoader />}
          <div className='steps'>
            {metadata.step1}
            {metadata.step2}
            {metadata.step3}
          </div>
        </div>
      </div>
    </Page>
  );
};
