import { makeStyles } from '@ecp/themes/base';
import type { CSSObject } from '@ecp/themes/base';

const radioButtonContainerStyles = (): CSSObject => {
  return {
    '& label': {
      margin: '0 8px 0 0',
    },
  };
};

export const useStyles = makeStyles({ name: 'ProductSelectQuestion' })(() => ({
  radioButtonContainer: radioButtonContainerStyles(),
}));
