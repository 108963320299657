import { makeStyles } from '@ecp/themes/base';

import type { Props } from './CheckboxGroup';

export const useStyles = makeStyles<
  Props & { cardCount: number },
  'root' | 'card' | 'cardSmall' | 'cardMedium' | 'classic'
>({
  name: 'CheckboxGroup',
})((...[theme, { label, helperText, message, cardCount, variant }, classes]) => ({
  root: {
    '& h6, & h2': {
      marginTop: 0,
      marginBottom: variant === 'editCard' ? 0 : helperText || message ? 5 : 15,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      marginTop: 10,
    },
  },
  card: {
    marginBottom: 0,
  },
  cardMedium: {},
  cardSmall: {},
  classic: {},
  classicRoot: {
    marginLeft: -10,
    pointerEvents: 'none',
  },
  classicLabel: theme.typography.body1,
  classicCompactLabel: {
    ...theme.typography.body1,
    marginLeft: 13,
    '& button': {
      position: 'unset',
      pointerEvents: 'auto',
    },
  },
  classicCheckbox: {
    pointerEvents: 'auto',
  },
  control: {},
  listError: {
    marginTop: 10,
  },
  errorText: {
    [`.${classes.classic} &`]: {
      marginTop: 0,
    },
  },
  formControl: {},
  group: theme.mixins.cardSelectionGroup(
    classes.root,
    classes.card,
    classes.cardSmall,
    classes.cardMedium,
    cardCount,
  ),
  label: {
    // When no label or helper text provided, add dummy legend tag for accessibility reasons and hide it
    ...(!(label || helperText) && theme.mixins.visuallyHidden),
    zIndex: 1,
    padding: 0,
    ...(!helperText && variant !== 'editCard' && { marginBottom: 15 }),
  },
  subLabel: {
    color: theme.palette.text.tertiary,
    padding: 0,
    marginTop: 5,
    marginBottom: 15,
    display: 'block',
  },
  editCardContainer: {
    marginLeft: 90,
    marginTop: -40,
    width: 122,
  },
  classicCompact: {
    marginLeft: 10,
    '& .MuiFormControlLabel-root:not(:first-child)': {
      marginTop: 15,
    },
  },
  horizontalCheckboxLabels: {
    width: '100%',
    flexDirection: 'column',
    borderRadius: '5px',
    border: '1px solid rgb(0 0 0 / 23%)',
    margin: '5px 10px 5px 0px',
  },
  editCard: {
    '& > *': {
      [`.${classes.root}.${classes.card} &`]: {
        // Override conflicting selector from cardSelectionGroup mixin
        marginTop: '15px !important',
        [theme.breakpoints.up('md')]: {
          marginRight: 15,
          maxWidth: 'calc(50% - 7.5px)',
          width: '100%',
          '&:nth-child(2n)': {
            marginRight: 0,
          },
        },
      },
    },
  },
  editButton: {
    height: 'auto',
    padding: '8px 0',
    justifyContent: 'normal',
  },
  icon: {
    width: 'auto',
    height: '24px !important',
    paddingRight: '0px !important',
  },
  iconCardInput: {
    // hide input field for iconCard
    display: 'none',
  },
}));
