import { emptyObject, mergeWith } from '@ecp/utils/common';

import type { QuestionsUIMetadata } from '@ecp/features/sales/shared/types';
import type { Question, Questions } from '@ecp/types';

import { getOptionLabelByType } from '../../modelUtil/getOptionLabelByType';

// convert question option if {type, value} to {label, value} based on the type in order to use existing components
const convertSapiQuestions = (
  questions: Questions,
  questionsUIMetadata: QuestionsUIMetadata,
): Questions => {
  return Object.assign(
    {},
    ...Object.entries(questions).map(([key, question]) => {
      const uiQuestionMeta = questionsUIMetadata[key];
      const options = question.options?.map((option) => {
        if (option.label) {
          const metaOption = uiQuestionMeta?.options?.find((o) => o.value === option.value);
          if (metaOption && !uiQuestionMeta.hasStaticOptions) {
            return {
              ...metaOption,
              value: option.value,
            };
          } else {
            return option;
          }
        } else
          return {
            label: getOptionLabelByType(option.value, option.type),
            value: option.value,
          };
      });

      // Exclude options uiQuestionMetadata as we defined ehanced options array above
      const { options: _, ...rest } = { options: [], ...uiQuestionMeta };

      return {
        [key]: {
          ...question,
          ...rest,
          ...(options && { options }),
        },
      };
    }),
  );
};

const concatCriteria = (
  objValue: Question[keyof Question],
  srcValue: Question[keyof Question],
  key: keyof Question,
): Question[keyof Question] =>
  key === 'criteria'
    ? [
        ...((objValue as Record<string, unknown>[]) || []),
        ...((srcValue as Record<string, unknown>[]) || []),
      ]
    : undefined;

export const mergeWithOverrides = (
  questions: Questions = emptyObject as unknown as Questions,
  questionsOverrides: Record<string, Partial<Question>>,
  questionsUIMetadata: QuestionsUIMetadata,
): Questions =>
  mergeWith(
    {},
    convertSapiQuestions(questions, questionsUIMetadata),
    questionsOverrides,
    concatCriteria,
  );
