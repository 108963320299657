import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { Slider } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const CeilingHeightQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const ceilingHeight = useHomePropertyField('ceiling.height');
  const {
    groupLabel = ceilingHeight.question?.title,
    dataTestId = 'ceilingHeight',
    trackingName = 'main_floor_ceiling_height_slider',
  } = props;
  useInitValues({ [ceilingHeight.key]: 'CEILING.HEIGHT.NINE_FEET' });
  useAddFields({ ceilingHeight });

  if (!ceilingHeight.exists) return null;

  return (
    <Slider
      {...ceilingHeight.props}
      data-testid={dataTestId}
      groupLabel={groupLabel}
      trackingName={trackingName}
    />
  );
};
