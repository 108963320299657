import { useCallback, useEffect, useRef, useState } from 'react';

import { Grid } from '@mui/material';

import { ensureZipCode } from '@ecp/utils/common';
import { SmartyStreetsEnums, validateAndCombineAddress } from '@ecp/utils/geo';
import { datadogLog } from '@ecp/utils/logger';
import { useEvent } from '@ecp/utils/react';
import { useSearchParams } from '@ecp/utils/routing';

import { GridItem } from '@ecp/components';
import { useGetConditionValues, useGetInitValues } from '@ecp/features/sales/form';
import { Button } from '@ecp/features/sales/shared/components';
import { NavStatus, STATE_CODE_PREFIX } from '@ecp/features/sales/shared/constants';
import type { ZipCodeQuestionProps } from '@ecp/features/sales/shared/questions';
import {
  getUpdatedPageflowsFromConfig,
  PagePath,
  useNavigateToNextPage,
  useNavigateToPage,
  usePrefillFlowDetermined,
} from '@ecp/features/sales/shared/routing';
import {
  createAddressForUpdate,
  fetchCityAndStateSuggestionsThunkAction,
  getFieldErrors,
  getLineOfBusiness,
  getPrimaryInsuredStateCode,
  makeSurePrefillFlowInStore,
  setFormErrorsChangedByField,
  setFormErrorsResetByField,
  setZipLookupBypassed,
  submitPolicyType,
  updateAnswers,
  updatePageStatus,
  useField,
  useFieldWithPrefix,
  useForm,
  useGetAddressFields,
  useGetPersonFields,
  usePrimaryAddress,
  usePrimaryAddressRef,
} from '@ecp/features/sales/shared/store';
import type { ThunkAction, ValidateFormResult } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Answers, AnswerValue } from '@ecp/features/sales/shared/types';
import { goToFirstError } from '@ecp/features/sales/shared/utils/web';
import { useToggleLandingAndRetrievePages } from '@ecp/features/sales/shell';
import type { Field, Fields } from '@ecp/types';

import metadata from '../metadata';
import { AddressQuestions } from './AddressQuestions';
import { useStyles } from './Main.styles';
import { MonolineProductNotEligibleDialog } from './MonolineProductNotEligibleDialog';
import { PersonQuestions } from './PersonQuestions';

interface AddressFields extends Fields {
  primaryAddress: {
    primaryLine1: Field;
    primaryLine2: Field;
    primaryCity: Field;
    primaryState: Field;
    primaryZipcode: Field;
  };
}

const usePrimaryAddressFields = (): AddressFields => {
  const primaryAddressRef = usePrimaryAddressRef();
  const usePrimaryAddressField = useFieldWithPrefix(primaryAddressRef, 'address.<id>');

  return {
    primaryAddress: {
      primaryCity: usePrimaryAddressField('city'),
      primaryState: usePrimaryAddressField('state'),
      primaryLine1: usePrimaryAddressField('line1'),
      primaryLine2: usePrimaryAddressField('line2'),
      primaryZipcode: usePrimaryAddressField('zipcode'),
    },
  };
};

interface SubmitParams extends Pick<ZipCodeQuestionProps, 'validateZipCodeAndGetStateCity'> {
  onNext: () => Promise<void>;
  patchFormValues: () => Promise<string>;
  prefillFlowDetermined: boolean;
  primaryAddressRef: string;
  setIsSubmitting: (f: boolean) => void;
  validateForm: () => ValidateFormResult;
  isShowModal?: boolean;
  isUpdateAnswers?: boolean;
  zipCode: Field;
}

const getResetStateCity = (primaryAddressRef: string): Answers => ({
  [`${primaryAddressRef}.state`]: '',
  [`${primaryAddressRef}.city`]: '',
});

const doSubmit =
  ({
    onNext,
    patchFormValues,
    prefillFlowDetermined,
    primaryAddressRef,
    setIsSubmitting,
    validateForm,
    validateZipCodeAndGetStateCity,
    isShowModal,
    isUpdateAnswers,
    zipCode,
  }: SubmitParams): ThunkAction<Promise<void>> =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      setTimeout(async () => {
        setIsSubmitting(true);

        if (!validateForm().isValid) {
          setIsSubmitting(false);

          return resolve();
        }

        const stateCity = await validateZipCodeAndGetStateCity(zipCode.value);
        await dispatch(
          updateAnswers({
            answers: {
              ...stateCity,
              [zipCode.key]: zipCode.value,
            },
            // If we already submitted zip/state/city (via field blur),
            // these values will be automatically excluded via preprocessAnswers in updateAnswers
            // and SAPI won't respond with "product not eligible" error,
            // as they only pass this error each time we submit state answer.
            // We want to force-include these answers.
            force: true,
          }),
        );
        await dispatch(submitPolicyType());
        const zipCodeFieldErrors = getFieldErrors(getState(), zipCode.key);
        if (isShowModal && zipCodeFieldErrors.length) {
          return reject();
        }

        if (isUpdateAnswers) {
          await patchFormValues();
        }

        if (getFieldErrors(getState(), `${primaryAddressRef}.state`).length) {
          setIsSubmitting(false);
          goToFirstError();

          return;
        }

        await onNext();
        if (!prefillFlowDetermined) {
          // show spinner on continue button
          setIsSubmitting(true);
        } else {
          setIsSubmitting(false);
        }

        // return resolve();
      }, 0);
    });

export const Main: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const getInitValues = useGetInitValues();
  const personFields = useGetPersonFields();

  const getConditions = useGetConditionValues();
  const addressFields = useGetAddressFields();
  const landingFields = metadata.shouldCallPrefill
    ? { ...addressFields.address, ...personFields.person }
    : { ...addressFields.address };
  const [open, setOpen] = useState(false);
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: landingFields,
    conditions: getConditions(),
  });

  const prefillFlowDetermined = usePrefillFlowDetermined();
  const primaryAddressRef = usePrimaryAddressRef();
  const address = useField('static.address');
  const primaryAddress = usePrimaryAddress();
  const fields = usePrimaryAddressFields();
  const selectedLob = useSelector(getLineOfBusiness);
  const stateCode = useSelector(getPrimaryInsuredStateCode);

  const navigateToNextPage = useNavigateToNextPage();
  const navigateToNextAddressPage = useNavigateToPage(PagePath.PERSON_ADDRESS);

  const validateZipCodeAndGetStateCity = useEvent(
    async (value: AnswerValue): Promise<Answers | null> => {
      const zipcode = ensureZipCode(value);
      if (!zipcode) return getResetStateCity(primaryAddressRef);

      // Prefill city, state and zipcode for Person Page form
      const cityAndStateSuggestionResult = await dispatch(
        fetchCityAndStateSuggestionsThunkAction(zipcode),
      );
      const cityAndStateSuggestion = cityAndStateSuggestionResult?.response;
      dispatch(setZipLookupBypassed(!!cityAndStateSuggestion?.bypass));
      if (cityAndStateSuggestion?.bypass) return getResetStateCity(primaryAddressRef);

      if (!cityAndStateSuggestion?.state) {
        dispatch(
          setFormErrorsChangedByField({
            key: fields.primaryAddress.primaryZipcode.key,
            errors: ['Invalid Zip Code'],
          }),
        );
        datadogLog({
          logType: 'warn',
          message: `Invalid Zip Code - ${fields.primaryAddress.primaryZipcode.value}`,
          context: {
            logOrigin: 'apps/sales/monoline/renters/src/views/LandingPage/Main/Main.tsx',
            functionOrigin: 'validateZipCodeAndGetStateCity',
          },
        });

        return getResetStateCity(primaryAddressRef);
      }
      dispatch(setFormErrorsResetByField({ key: fields.primaryAddress.primaryZipcode.key }));

      return {
        [`${primaryAddressRef}.state`]: `${STATE_CODE_PREFIX}${cityAndStateSuggestion.state}`,
        [`${primaryAddressRef}.city`]: cityAndStateSuggestion.city,
      };
    },
  );

  // If a user doesn't select a suggested address and chooses to enter the address manually all fields need to be combined and validated when a user submits the form.
  const onNext = useCallback(async () => {
    const {
      primaryAddress: { primaryCity, primaryState, primaryLine1, primaryLine2 },
    } = fields;
    const primaryStateWithoutPrefix = primaryState.value as string;
    const primaryInputAddress = {
      street: `${primaryLine1.value} ${primaryLine2.value ? (primaryLine2.value as string) : ''}`,
      state: primaryStateWithoutPrefix
        ? primaryStateWithoutPrefix.replace(STATE_CODE_PREFIX, '')
        : '',
      city: primaryCity.value as string,
    };
    const parsedAddress = await validateAndCombineAddress(primaryInputAddress, primaryAddressRef);

    if (
      parsedAddress &&
      parsedAddress.matchType === SmartyStreetsEnums.MATCH_TYPE.EXACT &&
      parsedAddress.line1 === primaryLine1.value
    ) {
      const addressToBeSaved = createAddressForUpdate(parsedAddress);

      address.props.actionOnComplete(primaryAddress);

      await dispatch(updateAnswers({ answers: { ...addressToBeSaved } }));

      dispatch(updatePageStatus(NavStatus.VALID, PagePath.PERSON_ADDRESS));

      if (metadata.shouldCallPrefill) await dispatch(makeSurePrefillFlowInStore());

      await navigateToNextPage();

      return;
    }
    // If the address is not parsed then do not prefill.
    // Prefill will be handled by PersonAddressPage when there is a valid address available.
    await dispatch(
      getUpdatedPageflowsFromConfig({
        modifiedLOB: selectedLob,
      }),
    );
    await navigateToNextAddressPage();
  }, [
    fields,
    primaryAddressRef,
    dispatch,
    selectedLob,
    navigateToNextAddressPage,
    address.props,
    primaryAddress,
    navigateToNextPage,
  ]);

  const handleSubmit = useEvent(async () => {
    await dispatch(
      doSubmit({
        onNext,
        patchFormValues,
        prefillFlowDetermined,
        primaryAddressRef,
        validateZipCodeAndGetStateCity,
        setIsSubmitting,
        validateForm,
        isShowModal: true,
        isUpdateAnswers: true,
        zipCode: fields.primaryAddress.primaryZipcode as Field,
      }),
    ).catch((error) => {
      datadogLog({
        logType: 'warn',
        message: `Error submitting form - ${error.message}`,
        context: {
          logOrigin: 'apps/sales/monoline/renters/src/views/LandingPage/Main/Main.tsx',
          functionOrigin: 'handleSubmit',
        },
        error,
      });

      setOpen(true);
    });
  });

  const toggleLandingAndRetrievePages = useToggleLandingAndRetrievePages();

  const searchParams = useSearchParams();
  const handleSearchParamsImplemented = useNavigateToPage(PagePath.LANDING, {
    replace: true,
    removeQuery: true,
    replaceProfileAdd: false,
  });
  useEffect(() => {
    if (Object.keys(searchParams).length) {
      handleSearchParamsImplemented();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = useEvent(() => {
    setOpen(false);
    setIsSubmitting(false);
  });

  return (
    <>
      <Grid item xs={12}>
        <AddressQuestions />
      </Grid>
      <PersonQuestions />
      <Grid container>
        <GridItem xs={12} topSpacing='lg'>
          <Button
            variant='primary'
            onClick={handleSubmit}
            isProcessing={isPatchFormInProgress || isSubmitting}
            className={classes.next}
            trackingName='GetNewQuotesButton'
            trackingLabel='GetNewQuotes'
            analyticsElement='choice.landingPageGetBackToYourQuote.getNewQuotesButton'
            type='submit'
          >
            {metadata.getQuoteButtonText}
          </Button>
        </GridItem>

        <GridItem xs={12} md={1} textAlign='center' topSpacing='sm'>
          <Button
            variant='iconTextMedium'
            disableRipple
            onClick={toggleLandingAndRetrievePages}
            trackingName='GetBackToQuoteButton'
            trackingLabel='GetBackToYourQuote'
            analyticsElement='choice.landingPageGetBackToYourQuote.getBackToYourQuoteButton'
          >
            Continue a saved quote
          </Button>
        </GridItem>
      </Grid>
      {open && (
        <MonolineProductNotEligibleDialog
          open={open}
          onClose={handleClose}
          unavailableLob={selectedLob}
          stateCode={stateCode}
        />
      )}
    </>
  );
};
