import { memo } from 'react';

import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { env } from '@ecp/env';
import { useIsTablet } from '@ecp/themes/base';

import { useStyles } from './CoveragesPageTitle.styles';
import { useTitle } from './util';

export const CoveragesPageTitle: React.FC = memo(() => {
  const { classes } = useStyles();
  const isTablet = useIsTablet();
  const title = useTitle();

  const coveragePageRedesignVariant = flagValues[FeatureFlags.COVERAGE_PAGE_REDESIGN_VARIANTS];

  return isTablet && !coveragePageRedesignVariant ? null : (
    <h1 className={env.static.isAgent ? undefined : classes.title}>{title}</h1>
  );
});
