import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CheckoutRetryForm' })((theme) => ({
  root: {},
  dialogRoot: { maxWidth: '100%' },
  header: {
    ...theme.typography.h1,
    marginTop: 40,
    marginBottom: 65,
    color: theme.palette.error.main,
    textAlign: 'center',
  },
  headerWithQuoteNumber: {
    ...theme.typography.h1,
    marginTop: 40,
    color: theme.palette.error.main,
    textAlign: 'center',
  },
  subHeader: {
    ...theme.typography.h3,
    marginBottom: 40,
    div: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  mariaBoxContent: {
    ...theme.typography.body1,
    marginBottom: 4,
  },
  mariaBoxBold: theme.typography.body1Bold,
  helpBoxContainer: {
    border: `1px solid ${theme.palette.grey[700]}`,
    borderRadius: 4,
    maxWidth: 534,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    position: 'relative',
  },
  helpBoxIcon: {
    position: 'absolute',
    top: '-10%',
    backgroundColor: 'transparent',
  },
  mariaBox: {
    padding: '30px 20px 20px 20px',
  },
  tryAgainButton: {
    margin: 20,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '20px 0',
    },
  },
  callUsButton: {
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  phone: {
    '& span': theme.typography.button2Medium,
  },
  contactContainer: {
    marginTop: 30,
    paddingBottom: 35,
  },
}));
