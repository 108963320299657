/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useMemo, useRef } from 'react';

import { createSelector } from 'reselect';

import { trackDefault } from '@ecp/utils/analytics/tracking';
import { emptyArray, isEqual, noop } from '@ecp/utils/common';

import {
  buildAutoPolicyCoverage,
  buildVehicleCoverages,
  getAnswers,
  getAvailableObjectForDriverVehicleDiscount,
  getDrivers,
  getOfferProductsSelectedByType,
  getPrimaryInsuredStateCode,
  getVehicles,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type {
  AnswerValue,
  CoverageItem,
  Driver,
  OptionMetadataWithKey,
  Vehicle,
  VehicleCoverages,
} from '@ecp/features/sales/shared/types';
import type { Field, OptionsMetadata } from '@ecp/types';

import { useCoveragesFormValidationFactory } from '../util/useCoveragesFormValidationFactory';
import driverDiscountMetadata from './metadata/driverDiscount';
import vehicleDiscountMetadata from './metadata/vehicleDiscount';
import type { AutoCoveragesFormProps } from './types';
import type { DiscountMetadata } from './VehicleAndDriverDiscounts';

/** Logs policy-level auto coverages object initially and on every change to it. */
const useAutoPolicyLevelCoveragesAnalytics = (autoPolicyCoverages: CoverageItem[]): void => {
  const prevAutoPolicyCoverageFields = useRef({});

  useEffect(() => {
    if (!autoPolicyCoverages) return;
    const trackPolicyCoverages: { [label: string]: string } = {};

    autoPolicyCoverages.forEach(({ title, field }) => {
      if (title && field?.value) trackPolicyCoverages[title] = String(field.value);
    });

    if (!isEqual(prevAutoPolicyCoverageFields.current, trackPolicyCoverages)) {
      prevAutoPolicyCoverageFields.current = trackPolicyCoverages;
      trackDefault({
        action: 'DefaultAutoPolicyLevelCoverages',
        label: JSON.stringify(trackPolicyCoverages),
      });
    }
  }, [autoPolicyCoverages]);
};

/** Logs vehicle-level auto coverages object initially and on every change to it. */
const useAutoVehicleLevelCoveragesAnalytics = (autoVehicleCoverages: VehicleCoverages[]): void => {
  const prevVehicleCoverageFields = useRef({});

  useEffect(() => {
    if (!autoVehicleCoverages) return;
    const trackVehicleCoverages: { [title: string]: string }[] = [];

    autoVehicleCoverages.forEach(({ coverages, deductibles, ref }) => {
      const trackVehicleCoveragesAndDeductibles: { [title: string]: string } = {};
      trackVehicleCoveragesAndDeductibles.ref = ref;
      [...coverages, ...deductibles].forEach(({ title, displayValue }) => {
        if (title && displayValue) trackVehicleCoveragesAndDeductibles[title] = displayValue;
      });
      trackVehicleCoverages.push(trackVehicleCoveragesAndDeductibles);
    });

    if (!isEqual(prevVehicleCoverageFields.current, trackVehicleCoverages)) {
      prevVehicleCoverageFields.current = trackVehicleCoverages;
      trackVehicleCoverages.forEach((each) =>
        trackDefault({ action: 'DefaultVehicleLevelCoverages', label: JSON.stringify(each) }),
      );
    }
  }, [autoVehicleCoverages]);
};

/* eslint-disable no-param-reassign */
export const useAutoCoveragesForm = (
  props: Pick<AutoCoveragesFormProps, 'fields' | 'onFormValidChange'>,
): {
  autoPolicyCoverages: CoverageItem[];
  autoVehicleCoverages: VehicleCoverages[];
  handleCoverageItemChange: (field: Field) => (value: AnswerValue) => Promise<void>;
} => {
  const { fields, onFormValidChange = noop } = props;
  const initValues = useRef({});
  const drivers = useSelector(getDrivers);
  const vehicles = useSelector(getVehicles);
  const { auto: autoOfferProduct } = useSelector(getOfferProductsSelectedByType);

  const { handleCoverageItemChange } = useCoveragesFormValidationFactory(
    initValues,
    fields,
    onFormValidChange,
  );

  const stateCode = useSelector(getPrimaryInsuredStateCode);

  /** Policy-level auto coverages and deductibles */
  const autoPolicyCoverages = useMemo(
    () =>
      autoOfferProduct && fields?.policyCoverageFields
        ? buildAutoPolicyCoverage(
            autoOfferProduct,
            fields.policyCoverageFields,
            stateCode,
            handleCoverageItemChange,
            drivers,
          )
        : (emptyArray as unknown as CoverageItem[]),
    [autoOfferProduct, fields?.policyCoverageFields, stateCode, handleCoverageItemChange, drivers],
  );
  /** Vehicle-level auto coverages and deductibles */
  const autoVehicleCoverages = useMemo(
    () =>
      autoOfferProduct && fields?.vehicleCoverageFields
        ? buildVehicleCoverages(
            autoOfferProduct,
            vehicles,
            fields.vehicleCoverageFields,
            stateCode,
            handleCoverageItemChange,
          )
        : (emptyArray as unknown as VehicleCoverages[]),
    [
      autoOfferProduct,
      fields?.vehicleCoverageFields,
      vehicles,
      stateCode,
      handleCoverageItemChange,
    ],
  );

  // Analytics
  useAutoPolicyLevelCoveragesAnalytics(autoPolicyCoverages);
  useAutoVehicleLevelCoveragesAnalytics(autoVehicleCoverages);

  return {
    autoPolicyCoverages,
    autoVehicleCoverages,
    handleCoverageItemChange,
  };
};

const makeApplicableDiscounts = <T extends Driver[] | Vehicle[]>(
  state: RootStore,
  availableDiscounts: OptionMetadataWithKey[],
  driversOrVehicles: T,
): DiscountMetadata[] =>
  availableDiscounts
    .map<DiscountMetadata>((discount) => {
      const { subDiscount, entityKey } = discount;
      if (subDiscount) {
        Object.keys(subDiscount).forEach((key) => {
          subDiscount[key].availableObjects = getAvailableObjectForDriverVehicleDiscount<T[number]>(
            state,
            key,
            driversOrVehicles,
            subDiscount[key].entityKey,
          );
          subDiscount[key].parentKey = discount.key;
        });
      }

      return {
        ...discount,
        availableObjects: getAvailableObjectForDriverVehicleDiscount<T[number]>(
          state,
          discount.key!,
          driversOrVehicles,
          entityKey,
        ),
      };
    })
    .filter(({ availableObjects }) => !!availableObjects.length);

export const getStateSpecificAvailableDriverVehicleDiscountstMetadata = (
  options: OptionsMetadata,
  stateCode: string,
): OptionsMetadata => {
  const opt = options;
  Object.keys(options).forEach((key) => {
    if (options[key].stateOptions) {
      const { stateOptions } = options[key];
      opt[key] = { ...options[key], ...stateOptions?.[stateCode] };
      delete opt[key].stateOptions;
    }
  });

  return opt;
};

export const getAvailableDriverVehicleDiscounts = (
  state: RootStore,
  type: 'driver' | 'vehicle',
  stateCode: string,
): OptionMetadataWithKey[] => {
  const { auto: autoOfferProduct } = getOfferProductsSelectedByType(state);
  if (autoOfferProduct) {
    const answers = getAnswers(state);
    const discountsMetadata =
      type === 'driver'
        ? driverDiscountMetadata[autoOfferProduct]
        : vehicleDiscountMetadata[autoOfferProduct];

    const discountMetadata = getStateSpecificAvailableDriverVehicleDiscountstMetadata(
      discountsMetadata,
      stateCode,
    );

    // this will get all the available discounts that are applied regardless of driver/vehicle
    const answersKeys = Object.keys(answers).filter((key) =>
      new RegExp(`${autoOfferProduct}.discount.${type}`, 'ig').test(key),
    );

    // this will hold a list of unique discount keys
    const totalAvailableDiscountKeys: string[] = [];

    for (let i = 0; i < answersKeys.length; i += 1) {
      const splitKey = answersKeys[i].split('.');
      const key = splitKey[splitKey.length - 1];

      if (totalAvailableDiscountKeys.indexOf(key) === -1) {
        totalAvailableDiscountKeys.push(key);
      }
    }

    // Since we have unique discount keys we can use the metadata
    // to return the OptionMetadata object
    return (
      totalAvailableDiscountKeys
        // if we get any unexpected keys we need to make sure we aren't sending them
        .filter((key) => discountMetadata[key] !== undefined)
        .map((key) => {
          const { subDiscount } = discountMetadata[key];
          // Get all available sub dicount key from answer available keys
          if (subDiscount) {
            discountMetadata[key].subDiscount = totalAvailableDiscountKeys.reduce((acc, curr) => {
              const temp = acc;

              return subDiscount[curr] ? { ...temp, [curr]: subDiscount[curr] } : temp;
            }, {});
          }

          return { ...discountMetadata[key], key };
        })
    );
  }

  return emptyArray as unknown as OptionMetadataWithKey[];
};

export const getApplicableDriverDiscounts = createSelector(
  (state: RootStore) => state,
  (state: RootStore) => getPrimaryInsuredStateCode(state),
  (state: RootStore) => getDrivers(state),
  (state, stateCode, drivers) => {
    const availableDriverDiscounts = getAvailableDriverVehicleDiscounts(state, 'driver', stateCode);

    return makeApplicableDiscounts(state, availableDriverDiscounts, drivers);
  },
);

export const getApplicableVehicleDiscounts = createSelector(
  (state: RootStore) => state,
  (state: RootStore) => getPrimaryInsuredStateCode(state),
  (state: RootStore) => getVehicles(state),
  (state, stateCode, vehicles) => {
    const availableVehicleDiscounts = getAvailableDriverVehicleDiscounts(
      state,
      'vehicle',
      stateCode,
    );

    return makeApplicableDiscounts(state, availableVehicleDiscounts, vehicles);
  },
);
