import { useRef, useState } from 'react';

/**
 * Returns shared isLoading ref flag and a wrapper function, which accepts a callback to be executed only if isLoading flag is `true`.
 * @param shouldWait wrapped function will not be called while this agrument is truthy
 */
export function useLoading(shouldWait = false): {
  isLoading: boolean;
  isLoadingRef: React.MutableRefObject<boolean>;
  withLoading: <T = void>(
    callback: (args: T) => unknown,
  ) => (...args: Parameters<typeof callback>) => Promise<unknown>;
} {
  const isLoadingRef = useRef(false);
  const [isLoading, setIsLoading] = useState(isLoadingRef.current);

  const withLoading: ReturnType<typeof useLoading>['withLoading'] =
    (callback) =>
    async (...args) => {
      if (isLoadingRef.current || shouldWait) return undefined;
      isLoadingRef.current = true;
      setIsLoading(true);
      try {
        return await callback(...args);
      } finally {
        isLoadingRef.current = false;
        setIsLoading(false);
      }
    };

  return { isLoading, isLoadingRef, withLoading };
}
