import { isTruthy } from '@ecp/utils/common';

import { buildLocationFromTo } from './buildLocationFromTo';
import { globalBasename } from './history';
import { location, stringifyLocation } from './location';
import type { To } from './types';
import { navigate as navigateWouter } from './wouter';

const LEADING_SLASH_REGEX = /^\//;
const TRAILING_SLASH_REGEX = /\/$|\/(?=\?|#)/;

/**
 * @returns path with removed duplicate `/`,`?`,`#` symbols, trailing `/` symbol in the path
 * and adds leading `/`, so that navigation does not append to the existing path
 */
const normalizePath = (path: string): string =>
  '/' +
  path
    // Remove multiple sequential slashes
    .replace(/\/{2,}/g, '/')
    // Remove multiple sequential question marks
    .replace(/\?{2,}/g, '?')
    // Remove multiple sequential hashes
    .replace(/#{2,}/g, '#')
    .replace(LEADING_SLASH_REGEX, '')
    .replace(TRAILING_SLASH_REGEX, '');

const addBase = (to = '', base?: string): string =>
  [base || globalBasename, to].filter(isTruthy).join('/');

const withBase = (to = '', base?: string, exact?: boolean): string =>
  exact ? to : addBase(to, base);

/** @returns pathname+search+hash string which covers navigate function behavior */
const determineTo = (to: Partial<To> | string, base?: string, exact?: boolean): string => {
  // Covers 1
  if (typeof to === 'string') return withBase(to, base, exact);

  // Covers 2-6
  if (to.pathname || to.pathname === '')
    return withBase(stringifyLocation(buildLocationFromTo(to)), base, exact);

  // Covers 7
  const locationFromTo = buildLocationFromTo(to);
  if (to.pathname !== '') locationFromTo.pathname = location.pathname;
  if (to.search !== '' && !to.search) locationFromTo.search = location.search;
  if (to.hash !== '' && !to.hash) locationFromTo.hash = location.hash;

  return withBase(stringifyLocation(locationFromTo), base, exact);
};

/**
 * This is what you would normally use to **navigate inside and outside of components**.
 *
 * Base path behavior is different compared with `useNavigate`.
 * As it's not a hook, it can only know about global (document) base path,
 * whereas `useNavigate` uses the closest Router base path, which can be composed from multiple base paths if Routers are nested.
 * If you need to use other than global (document) base path, pass your own base as `option.base`.
 *
 * This function skips navigation if the new URL is identical to the existing URL.
 *
 * Behavior related to `base` parameter:
 *
 * - If `base` param is passed and `options.exact` is omitted or `false`, it will prepend `base` param.
 * - If `base` param is omitted and `options.exact` is omitted or `false`, it will prepend global (document) base path.
 * - If `options.exact` is `true`, it will not prepend any base path.
 *
 * Behavior related to `to` parameter:
 *
 * - (1) If `to` is a string, it replaces everything in the URL
 * - (2) If `to.pathname` is specified, it replaces the existing pathname in the URL
 * - (3) If `to.pathname` is specified and `to.search` is not, existing search params in the URL get removed
 * - (4) If `to.pathname` is specified and `to.hash` is not, existing hash in the URL gets removed
 * - (5) If `to.search` is specified, it replaces the existing search params in the URL and has no effect on pathname and hash
 * - (6) If `to.hash` is specified, it replaces the existing hash in the URL and has no effect on pathname and search params
 * - (7) If any of these params are specified as an empty string, it will delete the respective entry in the URL
 *
 * To open a new tab, pass `options.external: true`.
 *
 * @returns status whether navigation has been performed or skipped.
 */
export const navigate = (
  to: Partial<To> | string,
  { base = '', exact = false, external = false, replace = false } = {},
): boolean => {
  // TODO Look into replacing with areLocationsEqual which requires two objects, whereas determineTo returns string
  const pitchedTo = normalizePath(determineTo(to, base, exact));

  if (external) {
    window.open(pitchedTo);

    return true;
  }

  const currentLocation = normalizePath(determineTo(location));

  if (pitchedTo === currentLocation) return false;

  navigateWouter(pitchedTo, { replace });

  return true;
};
