import { createAction } from '@reduxjs/toolkit';

import type { PurchaseResponse } from './api';
import type {
  BankAccountNumberPayload,
  BankAccountTypePayload,
  BankAccountUsePayload,
  BankRoutingNumberPayload,
  CreditCardExpirationDatePayload,
  CreditCardFilledPayload,
  CreditCardFinancialAccountTokenPayload,
  CreditCardNamePayload,
  CreditCardNumberPayload,
  CreditCardTokenPayload,
  CreditCardTypePayload,
  EFTPayParams,
  EFTPayTokenPayload,
  EFTPayTokenPayloadForReccuringPayment,
  MortgagePayload,
  PaymentMethodPayload,
  TokenForParams,
} from './types';

export const setPciToken = createAction<string>('@purchase/pciToken');
export const setAccountNumber = createAction<BankAccountNumberPayload>(
  '@purchase/setAccountNumber',
);
export const setRoutingNumber = createAction<BankRoutingNumberPayload>(
  '@purchase/setRoutingNumber',
);
export const setAccountUse = createAction<BankAccountUsePayload>('@purchase/setAccountUse');
export const setAccountType = createAction<BankAccountTypePayload>('@purchase/setAccountType');
export const setReccuringPayAccountNumber = createAction<BankAccountNumberPayload>(
  '@purchase/setReccuringPayAccountNumber',
);
export const setReccuringPayRoutingNumber = createAction<BankRoutingNumberPayload>(
  '@purchase/setReccuringPayRoutingNumber',
);
export const setReccuringPayAccountUse = createAction<BankAccountUsePayload>(
  '@purchase/setReccuringPayAccountUse',
);
export const setReccuringPayAccountType = createAction<BankAccountTypePayload>(
  '@purchase/setReccuringPayAccountType',
);
export const setCardNumber = createAction<CreditCardNumberPayload>('@purchase/setCardNumber');
export const setCreditCardToken = createAction<CreditCardTokenPayload>(
  '@purchase/setCreditCardToken',
);
export const setCreditCardFinancialAccountToken =
  createAction<CreditCardFinancialAccountTokenPayload>(
    '@purchase/setCreditCardFinancialAccountToken',
  );
export const setEFTPayToken = createAction<EFTPayTokenPayload>('@purchase/setEFTPayToken');
export const setEFTPayTokenForReccuringPayment =
  createAction<EFTPayTokenPayloadForReccuringPayment>(
    '@purchase/setEFTPayTokenForReccuringPayment',
  );
export const setCreditCardType = createAction<CreditCardTypePayload>('@purchase/setCreditCardType');
export const setExpirationDate = createAction<CreditCardExpirationDatePayload>(
  '@purchase/setExpirationDate',
);
export const setFilled = createAction<CreditCardFilledPayload>('@purchase/setFilled');
export const setGettingTokenFor = createAction<TokenForParams>('@purchase/setGettingTokenFor');
export const setTokenForEFTPay = createAction<EFTPayParams>('@purchase/setTokenForEFTPay');
export const setTokenForReccuringPayment = createAction<EFTPayParams>(
  '@purchase/setTokenForReccuringPayment',
);
export const setHomeInspectionStatus = createAction<string>('@purchase/setHomeInspectionStatus');
export const setMortgage = createAction<MortgagePayload>('@purchase/setMortgage');
export const setName = createAction<CreditCardNamePayload>('@purchase/setName');
export const setPaymentMethod = createAction<PaymentMethodPayload>('@purchase/setPaymentMethod');
export const setPaymentOptionsAndAcksRefetchRequired = createAction<boolean>(
  '@purchase/setPaymentOptionsAndAcksRefetchRequired',
);
export const setPurchaseApiPostSuccess = createAction<PurchaseResponse>('@purchase/apiPostSuccess');
export const setPurchaseRequestProduct = createAction<string[]>(
  '@purchase/setPurchaseRequestProduct',
);

export type PurchaseActions = ReturnType<
  | typeof setPciToken
  | typeof setAccountUse
  | typeof setAccountNumber
  | typeof setAccountType
  | typeof setCardNumber
  | typeof setCreditCardToken
  | typeof setCreditCardType
  | typeof setCreditCardFinancialAccountToken
  | typeof setExpirationDate
  | typeof setFilled
  | typeof setGettingTokenFor
  | typeof setTokenForEFTPay
  | typeof setTokenForReccuringPayment
  | typeof setHomeInspectionStatus
  | typeof setMortgage
  | typeof setName
  | typeof setPaymentMethod
  | typeof setPaymentOptionsAndAcksRefetchRequired
  | typeof setPurchaseApiPostSuccess
  | typeof setPurchaseRequestProduct
  | typeof setRoutingNumber
>;
