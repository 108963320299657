import { useCallback, useMemo } from 'react';

import { ensureStringArray } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';

import {
  createRef,
  getAllValues,
  getInquiryLoaded,
  getValueForPrefix,
  updateAnswers,
  updateRemovedRef,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Answers, AnswerValue, Percentages } from '@ecp/features/sales/shared/types';

import {
  CONSTRUCTION_MATERIAL_PERCENTAGE_SUFFIX,
  CONSTRUCTION_MATERIAL_SUFFIX,
  EXTERIOR_WALL_REF,
} from '../../constants';

interface BaseExteriorWall {
  constructionMaterial: string;
  constructionMaterialPercentage: string;
}

interface ExteriorWall extends BaseExteriorWall {
  ref: string;
}

const getExteriorWall = (ref: string, allValues: Answers): ExteriorWall => {
  const getString = getValueForPrefix<string>(ref, allValues);

  return {
    constructionMaterial: getString(CONSTRUCTION_MATERIAL_SUFFIX),
    constructionMaterialPercentage: getString(CONSTRUCTION_MATERIAL_PERCENTAGE_SUFFIX),
    ref,
  };
};

export const useExteriorWalls = (): ExteriorWall[] => {
  const allValues = useSelector(getAllValues);
  const refs: string[] = ensureStringArray(allValues[EXTERIOR_WALL_REF]);

  return refs.map((ref) => getExteriorWall(ref, allValues));
};

export const useExteriorWallPercentages = (): { [key: string]: string } => {
  const exteriorWalls = useExteriorWalls();

  return exteriorWalls.reduce<{ [key: string]: string }>((percentages, exteriorWall) => {
    return {
      ...percentages,
      [exteriorWall.constructionMaterial]: exteriorWall.constructionMaterialPercentage,
    };
  }, {});
};

interface AddExteriorWallResult {
  // the api has received the update
  refs: string[];
  // the ref used
  exteriorWallRef: string;
}

const useAddExteriorWall = (): (() => AddExteriorWallResult) => {
  const dispatch = useDispatch();
  const allValues = useSelector(getAllValues);
  const inquiryLoaded = useSelector(getInquiryLoaded);

  return useCallback(() => {
    if (!inquiryLoaded) {
      datadogLog({
        logType: 'error',
        message: 'inquiry not loaded',
        context: {
          logOrigin: 'libs/features/sales/quotes/property/home/src/state/util/exteriorWallUtil.ts',
          functionOrigin: 'useAddExteriorWall/useCallback',
        },
      });
      throw new Error('inquiry not loaded');
    }

    const exteriorWallRef = dispatch(createRef('exteriorWall'));
    const existingRefs: string[] = ensureStringArray(allValues[EXTERIOR_WALL_REF]);
    const refs = [
      ...existingRefs,
      ...(!existingRefs.includes(exteriorWallRef) ? [exteriorWallRef] : []),
    ];

    return {
      refs,
      exteriorWallRef,
    };
  }, [allValues, dispatch, inquiryLoaded]);
};

const makeExteriorWallAnswers = (
  exteriorWalls: ExteriorWall[],
  keyValues: { [key: string]: string },
): Answers => {
  const newExteriorWalls: BaseExteriorWall[] = Object.entries(keyValues).map(([key, value]) => ({
    constructionMaterial: key,
    constructionMaterialPercentage: value,
  }));

  return newExteriorWalls.reduce<Answers>((preAnswers, bew) => {
    const exteriorWall = exteriorWalls.find(
      (ew) => ew.constructionMaterial === bew.constructionMaterial,
    );
    if (!exteriorWall) {
      datadogLog({
        logType: 'error',
        message: `Not found exterior wall for value "${bew.constructionMaterial}"`,
        context: {
          logOrigin: 'libs/features/sales/quotes/property/home/src/state/util/exteriorWallUtil.ts',
          functionOrigin: 'makeExteriorWallAnswers',
        },
      });
      throw new Error(`Not found exterior wall for value "${bew.constructionMaterial}"`);
    }

    return {
      ...preAnswers,
      [`${exteriorWall.ref}.${CONSTRUCTION_MATERIAL_PERCENTAGE_SUFFIX}`]:
        bew.constructionMaterialPercentage,
    };
  }, {});
};

export const useAddAndUpdateExteriorWall = (): ((key: string, value: string) => Promise<void>) => {
  const dispatch = useDispatch();
  const exteriorWalls = useExteriorWalls();
  const addExteriorWall = useAddExteriorWall();

  return useCallback(
    async (
      constructionMaterial: string,
      constructionMaterialPercentage: string,
      updates: Percentages = {},
    ) => {
      const { refs, exteriorWallRef } = addExteriorWall();
      const answers = makeExteriorWallAnswers(exteriorWalls, updates);

      await dispatch(
        updateAnswers({
          answers: {
            ...answers,
            [EXTERIOR_WALL_REF]: refs,
            [`${exteriorWallRef}.${CONSTRUCTION_MATERIAL_SUFFIX}`]: constructionMaterial,
            [`${exteriorWallRef}.${CONSTRUCTION_MATERIAL_PERCENTAGE_SUFFIX}`]:
              constructionMaterialPercentage,
          },
        }),
      );
    },
    [addExteriorWall, exteriorWalls, dispatch],
  );
};

export const useUpdateExteriorWall = (): ((keyValues: {
  [key: string]: string;
}) => Promise<void>) => {
  const dispatch = useDispatch();
  const exteriorWalls = useExteriorWalls();

  return useCallback(
    async (keyValues: { [key: string]: string }) => {
      const answers = makeExteriorWallAnswers(exteriorWalls, keyValues);
      await dispatch(updateAnswers({ answers }));
    },
    [exteriorWalls, dispatch],
  );
};

export const useRemoveExteriorWall = (): ((constructionMaterial: string) => Promise<void>) => {
  const dispatch = useDispatch();
  const allValues = useSelector(getAllValues);
  const exteriorWalls = useExteriorWalls();

  return useCallback(
    async (constructionMaterial: string, updates: Percentages = {}) => {
      const exteriorWall = exteriorWalls.find(
        (ew) => ew.constructionMaterial === constructionMaterial,
      );
      if (!exteriorWall) {
        datadogLog({
          logType: 'error',
          message: `Not found exterior wall for value "${constructionMaterial}"`,
          context: {
            logOrigin:
              'libs/features/sales/quotes/property/home/src/state/util/exteriorWallUtil.ts',
            functionOrigin: 'useRemoveExteriorWall/useCallback',
          },
        });
        throw new Error(`Not found exterior wall for value "${constructionMaterial}"`);
      }
      const answers = makeExteriorWallAnswers(exteriorWalls, updates);

      await dispatch(
        updateRemovedRef({ refType: EXTERIOR_WALL_REF, allValues, removedRef: exteriorWall.ref }),
      );
      await dispatch(
        updateAnswers({
          answers: {
            ...answers,
            [`${exteriorWall.ref}.${CONSTRUCTION_MATERIAL_SUFFIX}`]: null,
            [`${exteriorWall.ref}.${CONSTRUCTION_MATERIAL_PERCENTAGE_SUFFIX}`]: null,
          },
        }),
      );
    },
    [exteriorWalls, allValues, dispatch],
  );
};

export const useSingleExteriorWall = (): {
  singleExteriorWallRef: string;
  addUpdateExteriorWallDetails: (exteriorWall: AnswerValue) => Promise<void>;
} => {
  const dispatch = useDispatch();
  const allValues = useSelector(getAllValues);
  const refs: string[] = ensureStringArray(allValues[EXTERIOR_WALL_REF]);
  const firstExteriorWallRef = refs[0];

  // This memoization is important so that the hook doesn't rerender
  // everytime anything change in the store and falls to an infinite loop.
  const singleExteriorWallRef = useMemo(() => {
    return firstExteriorWallRef ?? dispatch(createRef('exteriorWall'));
  }, [dispatch, firstExteriorWallRef]);

  const addUpdateExteriorWallDetails = useCallback(
    async (exteriorWall: AnswerValue): Promise<void> => {
      await dispatch(
        updateAnswers({
          answers: {
            [EXTERIOR_WALL_REF]: singleExteriorWallRef,
            [`${singleExteriorWallRef}.${CONSTRUCTION_MATERIAL_SUFFIX}`]: exteriorWall,
          },
        }),
      );
    },
    [dispatch, singleExteriorWallRef],
  );

  return {
    singleExteriorWallRef,
    addUpdateExteriorWallDetails,
  };
};
