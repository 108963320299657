import { Grid } from '@mui/material';

import { TooltipWithIcon } from '@ecp/components';
import { env } from '@ecp/env';
import {
  getOfferProductsSelectedByType,
  getPolicyEfectiveDateParam,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { useIsMobile } from '@ecp/themes/base';

import * as metadata from './metadata';
import { PolicyDatePickerGrid } from './PolicyDatePickerGrid';
import { useStyles } from './PolicyStartDateForm.styles';
import { PolicyText } from './PolicyText';
import { getStartDateHelpText } from './util';

export const PolicyStartDateFormOnPageVariant = (): React.ReactNode => {
  const { classes, cx } = useStyles({
    inDrawer: false,
  });
  const isMobile = useIsMobile();

  return (
    <>
      <div className={cx(classes.accordionSummaryHeader, `MuiAccordionSummary-root`)}>
        <div className={cx(`MuiAccordionSummary-content`)}>
          <h3>
            {metadata.title}
            <TooltipWithIcon
              className={classes.policyToolTip}
              title='Adjustments to start date could result in adjustments to price.'
            />
          </h3>
        </div>
      </div>
      {isMobile ? <MobileLayout /> : <DesktopLayout />}
    </>
  );
};

const MobileLayout = (): React.ReactNode => {
  const { classes, cx } = useStyles({
    inDrawer: false,
  });
  const { auto: autoOfferProduct, property: propertyOfferProduct } = useSelector(
    getOfferProductsSelectedByType,
  );

  return (
    <Grid container rowSpacing={2} columnSpacing={2} className={cx(classes.accordionDetailsBkg)}>
      {autoOfferProduct && (
        <Grid container item spacing={2} xs={12} md={6} lg={6}>
          {!env.static.isAgent && <PolicyText product={autoOfferProduct} inDrawer={false} />}
          <PolicyDatePickerGrid product={autoOfferProduct} classes={classes} />
        </Grid>
      )}
      {propertyOfferProduct && (
        <Grid container item spacing={2} xs={12} md={6} lg={6}>
          {!env.static.isAgent && <PolicyText product={propertyOfferProduct} inDrawer={false} />}
          <PolicyDatePickerGrid product={propertyOfferProduct} classes={classes} />
        </Grid>
      )}
    </Grid>
  );
};

const DesktopLayout = (): React.ReactNode => {
  const { classes } = useStyles({
    inDrawer: false,
  });
  const { auto: autoOfferProduct, property: propertyOfferProduct } = useSelector(
    getOfferProductsSelectedByType,
  );
  const policyEffectiveDateParam = useSelector(getPolicyEfectiveDateParam);

  return (
    <Grid container spacing={2} className={classes.accordionDetailsBkg}>
      <Grid
        container
        item
        spacing={2}
        xs={12}
        className={env.static.isAgent ? classes.datePickerContainer : undefined}
      >
        {autoOfferProduct && (
          <Grid container item xs={12} md={6}>
            <PolicyDatePickerGrid product={autoOfferProduct} classes={classes} />
          </Grid>
        )}
        {propertyOfferProduct && (
          <Grid container item xs={12} md={6}>
            <PolicyDatePickerGrid product={propertyOfferProduct} classes={classes} />
            {policyEffectiveDateParam && (
              <p className={classes.policyHelpText}>
                {getStartDateHelpText(propertyOfferProduct, policyEffectiveDateParam)}
              </p>
            )}
          </Grid>
        )}
      </Grid>
      {!env.static.isAgent && (
        <Grid container item spacing={2} xs={12} marginBottom='15px'>
          {autoOfferProduct && propertyOfferProduct && (
            <>
              <Grid container item xs={12} md={6}>
                <PolicyText product={autoOfferProduct} inDrawer={false} />
              </Grid>
              <Grid container item xs={12} md={6}>
                <PolicyText product={propertyOfferProduct} inDrawer={false} />
              </Grid>
            </>
          )}
          {autoOfferProduct && !propertyOfferProduct && (
            <Grid container item xs={12} md={9}>
              <PolicyText product={autoOfferProduct} inDrawer={false} />
            </Grid>
          )}
          {!autoOfferProduct && propertyOfferProduct && (
            <Grid container item xs={12} md={9}>
              <PolicyText product={propertyOfferProduct} inDrawer={false} />
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};
