import { useCallback, useRef, useState } from 'react';

import { GoogleAnalyticsLabels, trackClick } from '@ecp/utils/analytics/tracking';

import { Alert } from '@ecp/components';
import { Button, Form } from '@ecp/features/sales/shared/components';
import { useForm, useGetRetrieveFields } from '@ecp/features/sales/shared/store';
import { useToggleLandingAndRetrievePages } from '@ecp/features/sales/shell';

import metadata from '../metadata';
import { useStyles } from './RetrieveForm.styles';
import { RetrieveFormQuestions } from './RetrieveFormQuestions';

export interface RetrieveFormProps {
  chaseLinkError: boolean;
  recallError: string;
  isRecallInProgress: boolean;
  onNext: () => void;
  formType: string;
}

export const RetrieveForm: React.FC<RetrieveFormProps> = (props) => {
  const { onNext, chaseLinkError, recallError, isRecallInProgress, formType } = props;
  const { classes, cx } = useStyles();
  const fields = useGetRetrieveFields();
  const [displayChaseLinkMessage, setDisplayChaseLinkMessage] = useState(true);
  const initValues = useRef({});
  const { validateForm } = useForm({ initValues, fields });
  const eventLabel = `choice.${formType}GetBackToYourQuote.getNewQuotesButton`;
  const handleSubmit = useCallback(async () => {
    trackClick({ action: 'GetBackContinueButton', label: GoogleAnalyticsLabels.CONTINUE });

    if (validateForm().isValid) {
      onNext();
    }
  }, [onNext, validateForm]);

  const handleChaseLinkErrorClose = useCallback(() => {
    setDisplayChaseLinkMessage(false);
  }, [setDisplayChaseLinkMessage]);

  const toggleLandingAndRetrievePages = useToggleLandingAndRetrievePages();

  return (
    <div className={classes.root}>
      <h1>Welcome back</h1>
      <Form showBackdrop={isRecallInProgress}>
        {chaseLinkError && displayChaseLinkMessage ? (
          <Alert
            className={classes.chaseLinkError}
            onClose={handleChaseLinkErrorClose}
            type='error'
            withAction
          >
            We couldn&apos;t find your previous offer. Please provide more information.
          </Alert>
        ) : (
          <p className={classes.subtitle}>
            Please fill out the following fields to locate your quote
          </p>
        )}
        <RetrieveFormQuestions {...props} />
        {recallError && (
          <Alert type='error' className={classes.alert}>
            {recallError}
          </Alert>
        )}
        <Button
          variant='primary'
          onClick={handleSubmit}
          isProcessing={isRecallInProgress}
          disabled={isRecallInProgress}
          className={cx(classes.next, classes.landingPageButton)}
          analyticsElement={`choice.${formType}GetBackToYourQuote.continueButton`}
          type='submit'
          data-testid='recallQuotesContinueButton'
        >
          {metadata.getQuoteButtonText}
        </Button>
        {metadata.displayNewQuoteButton && (
          <Button
            variant='iconTextMedium'
            disableRipple
            className={classes.backQuotesButton}
            onClick={toggleLandingAndRetrievePages}
            trackingName='GetNewQuotesButton'
            trackingLabel='GetNewQuotes'
            analyticsElement={eventLabel}
          >
            Start a new quote
          </Button>
        )}
      </Form>
    </div>
  );
};
