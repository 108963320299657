import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'SecondaryInsuredQuestion' })((theme) => ({
  ...theme.mixins.formAll,
  text: { ...theme.typography.body4, marginBottom: 20 },
  secondaryInsuredText: {
    ...theme.typography.body4,
    marginTop: 40,
    marginBottom: 20,
  },
  dob: { ...theme.typography.body4, marginTop: 20 },
}));
