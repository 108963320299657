import { uuid } from '@ecp/utils/common';

import type { ThunkAction } from '@ecp/features/sales/shared/store/types';

import { setApiComplete, setApiStart } from './actions';

interface FetchApiArgs {
  fn: () => Promise<unknown>;
  idPrefix?: string;
}

export const fetchApi =
  ({ fn, idPrefix = '' }: FetchApiArgs): ThunkAction<Promise<string>> =>
  async (dispatch) => {
    const id = `${idPrefix ? `${idPrefix}-` : ''}${uuid()}`;
    dispatch(setApiStart(id));

    try {
      await fn();
    } finally {
      dispatch(setApiComplete(id));
    }

    return id;
  };
