import type { ProductName } from '@ecp/features/shared/product';

export type ContentByProductType = Record<ProductName, Content>;

interface Content {
  insuranceType: string;
  underwritingGroupFull: string;
  underwritingGroupShort: string;
}

export type DataType = 'insuranceType' | 'underwritingGroupFull' | 'underwritingGroupShort';

const metadata: () => ContentByProductType = () => ({
  auto: {
    insuranceType: '"Auto insurance."',
    underwritingGroupFull: 'CONNECT, powered by American Family Insurance, and its subsidiaries',
    underwritingGroupShort: 'CONNECT',
  },
  home: {
    insuranceType: '"Homeowners insurance."',
    underwritingGroupFull: 'Homesite Group Incorporated, and its subsidiaries',
    underwritingGroupShort: 'Homesite Group',
  },
  renters: {
    insuranceType: '"Homeowners insurance."',
    underwritingGroupFull: 'Homesite Group Incorporated, and its subsidiaries',
    underwritingGroupShort: 'Homesite Group',
  },
});

export default metadata;
