import { Divider, Grid } from '@mui/material';

import type { Product } from '@ecp/features/shared/product';

import { PolicyDatePickerGrid } from './PolicyDatePickerGrid';
import { useStyles } from './PolicyStartDateForm.styles';
import { PolicyText } from './PolicyText';

export const AdjustmentsText = (): React.ReactNode => {
  const { classes } = useStyles({});

  return (
    <Grid item xs={12}>
      <p className={classes.adjustmentToStartDateTxt}>
        Adjustments to start date could result in adjustments to price.
      </p>
    </Grid>
  );
};

const DividerGrid = (): React.ReactNode => {
  const { classes } = useStyles({});

  return (
    <Grid item xs={12}>
      <Divider className={classes.divider} />
    </Grid>
  );
};

export const PolicyStartDateFormInDrawerVariant = ({
  product,
}: {
  product: Product;
}): React.ReactNode => {
  const { classes } = useStyles({
    inDrawer: true,
  });

  return (
    <>
      <DividerGrid />
      <PolicyText product={product} inDrawer />
      <PolicyDatePickerGrid product={product} classes={classes} />
      <AdjustmentsText />
    </>
  );
};
