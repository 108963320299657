import { trackError } from '@ecp/utils/analytics/tracking';
import { emptyArray } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';
import type { RequestInitExtended } from '@ecp/utils/network';
import { typedFetch } from '@ecp/utils/network';

import { env } from '@ecp/env';
import type { VehicleModel } from '@ecp/features/sales/shared/types';

interface ResponseStatus {
  code: string;
  metrics?: {
    timestamp: string;
    timeElapsedMillis: string;
  };
  reason: string;
}

interface ValidationError {
  bean: string;
  beanId: string;
  code: string;
  field: string;
  message: string;
}

interface ResponseError {
  code: string;
  type: string;
  message: string;
  validationErrors: ValidationError[];
}

export interface VehicleApiResponse {
  status: ResponseStatus;
  error?: ResponseError;
}

export interface VehicleOption {
  key: string;
  name: string;
}

interface Categories {
  typeList: VehicleOption[];
}

interface VehicleOptionsResponse extends VehicleApiResponse {
  TypeListCategories: Categories[];
}

interface VehicleOptionsCombinedResponse extends VehicleApiResponse {
  Models: VehicleModel[];
}

export interface VehicleInfoResults {
  make: string;
  model: string;
  vehicleCharacteristics: string;
  vinPattern: string;
  year: string;
  series?: string;
  vin?: string;
  msrp: string;
  vehicleTypeCode?: string;
  vehicleDetailId: string;
}

interface VehicleInfoResultsResponse extends VehicleApiResponse {
  VehicleInfoResults: VehicleInfoResults;
}

interface VehicleInfoResponse extends VehicleApiResponse {
  VehicleInfo: VehicleInfoResults;
}

const VEHICLE_TYPE_CODE = 'auto';
const vehicleApiDefaultHeaders = {
  'afi-appname': env.afiAppname,
  'afi-api-key': env.afiApiKey,
};
const vehicleApiDefaultOptions = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    ...vehicleApiDefaultHeaders,
  },
  params: {},
};

export const checkResponse = (response: VehicleApiResponse): void => {
  const { code, reason } = response.status;
  if (code !== '200') {
    if (response.error?.validationErrors.length) {
      const errorMessage = response.error.validationErrors[0].message;
      trackError({ action: errorMessage, label: code });
      datadogLog({
        logType: 'error',
        message: `Vehicle API responded with error - ${errorMessage}, ${reason}`,
        context: {
          logOrigin: 'libs/features/sales/quotes/auto/src/api/vehicle/vehicleApi.ts',
          contextType: 'Vehicle API Error',
          functionOrigin: 'checkResponse',
          responseStatus: code,
          responseReason: reason,
          responseErrorCode: response.error.code,
          responseErrorMessage: response.error.message,
          responseValidationErrors: JSON.stringify(response.error.validationErrors),
        },
        error: { ...response.error, name: 'Vehicle API Error' },
      });
      throw new Error(errorMessage);
    }

    const message = `Exception calling Vehicle Api - code: [${code}] - reason - [${reason}]`;
    trackError({ action: reason, label: code });
    datadogLog({
      logType: 'error',
      message,
      context: {
        logOrigin: 'libs/features/sales/quotes/auto/src/api/vehicle/vehicleApi.ts',
        contextType: 'Vehicle API Error',
        functionOrigin: 'checkResponse',
        responseStatus: code,
        responseReason: reason,
      },
    });
    throw new Error(message);
  }
};

const handleError = (error: Error, message: string, functionOrigin: string): VehicleApiResponse => {
  datadogLog({
    logType: 'error',
    message: message,
    context: {
      logOrigin: 'libs/features/sales/quotes/auto/src/api/vehicle/vehicleApi.ts',
      functionOrigin: functionOrigin,
    },
    error,
  });

  return {
    status: {
      code: '0',
      reason: `Network or CORS error; ${JSON.stringify(error)}`,
    },
  };
};

export const getOptionsAsStringArray = (typeListCategories: Categories[]): string[] => {
  if (typeListCategories.length > 0) {
    const category = typeListCategories.find(
      (categories) => categories.typeList && categories.typeList.length > 0,
    );
    if (category) return category.typeList.map((options) => options.name);
  }

  return emptyArray as unknown as string[];
};

export const getCombinedOptionsArray = (models: VehicleModel[]): VehicleModel[] => {
  if (models.length > 0) {
    return models.map((options) => ({
      key: options.key,
      make: options.make,
      name: options.model,
    }));
  }

  return emptyArray as unknown as VehicleModel[];
};

export const getOptions = (typeListCategories: Categories[]): VehicleOption[] => {
  if (typeListCategories && typeListCategories.length > 0) {
    const category = typeListCategories.find((categories) => categories.typeList);
    if (category) return category.typeList;
  }

  return emptyArray as unknown as VehicleOption[];
};

export const fetchMakes = async ({
  year,
  referenceId = '',
}: {
  year: string;
  referenceId?: string;
}): Promise<string[]> => {
  const init: RequestInitExtended = {
    ...vehicleApiDefaultOptions,
    headers: { ...vehicleApiDefaultOptions.headers, referenceId },
    body: JSON.stringify({
      Queries: [
        {
          year,
          vehicleTypeCode: VEHICLE_TYPE_CODE,
        },
      ],
    }),
  };
  const response = await typedFetch<VehicleOptionsResponse>(`${env.vehicleApiRoot}/makes`, init)
    .then((resp) => resp.json())
    .catch((error) =>
      handleError(error, `Error getting makes from ${env.vehicleApiRoot}/makes`, 'fetchMakes'),
    );

  checkResponse(response);

  if ('TypeListCategories' in response) {
    const makes = getOptionsAsStringArray(response.TypeListCategories);

    // remove duplicate makes (not sure why there are two, but in all cases I checked, it was the same models returned for both and fuel api only has it with '-', not space)
    return makes.filter((make) => !(make === 'Mercedes Benz' && makes.includes('Mercedes-Benz')));
  }

  return emptyArray as unknown as string[];
};

export const fetchModels = async ({
  year,
  make,
  referenceId = '',
}: {
  year: string;
  make: string;
  referenceId?: string;
}): Promise<VehicleModel[]> => {
  const init: RequestInitExtended = {
    ...vehicleApiDefaultOptions,
    headers: { ...vehicleApiDefaultOptions.headers, referenceId },
    body: JSON.stringify({
      Queries: [
        {
          year,
          make,
          vehicleTypeCode: VEHICLE_TYPE_CODE,
          referenceId,
        },
      ],
    }),
  };

  const response = await typedFetch<VehicleOptionsResponse | VehicleOptionsCombinedResponse>(
    `${env.vehicleApiRoot}/modelscombined`,
    init,
  )
    .then((resp) => resp.json())
    .catch((error) =>
      handleError(
        error,
        `Error getting models from ${env.vehicleApiRoot}/modelscombined`,
        'fetchModels',
      ),
    );

  checkResponse(response);

  if ('Models' in response) return getCombinedOptionsArray(response.Models);

  return emptyArray as unknown as VehicleModel[];
};

export const fetchSeries = async ({
  year,
  make,
  model,
  referenceId = '',
}: {
  year: string;
  make: string;
  model: string;
  referenceId?: string;
}): Promise<VehicleOption[]> => {
  const init: RequestInitExtended = {
    ...vehicleApiDefaultOptions,
    headers: { ...vehicleApiDefaultOptions.headers, referenceId },
    body: JSON.stringify({
      Queries: [
        {
          year,
          make,
          model,
          vehicleTypeCode: VEHICLE_TYPE_CODE,
          referenceId,
        },
      ],
    }),
  };
  const response = await typedFetch<VehicleOptionsResponse>(`${env.vehicleApiRoot}/series`, init)
    .then((resp) => resp.json())
    .catch((error) =>
      handleError(error, `Error getting series from ${env.vehicleApiRoot}/series`, 'fetchSeries'),
    );

  checkResponse(response);

  if ('TypeListCategories' in response) return getOptions(response.TypeListCategories);

  return emptyArray as unknown as VehicleOption[];
};

export const fetchVehicleInfo = async ({
  year,
  make,
  model,
  vehicleDetailId,
  referenceId = '',
}: {
  year: string;
  make: string;
  model: string;
  vehicleDetailId: string;
  referenceId?: string;
}): Promise<VehicleInfoResults | null> => {
  const init: RequestInitExtended = {
    ...vehicleApiDefaultOptions,
    headers: { ...vehicleApiDefaultOptions.headers, referenceId },
    body: JSON.stringify({
      Queries: [
        {
          year,
          make,
          model,
          vehicleDetailId,
          vehicleTypeCode: VEHICLE_TYPE_CODE,
        },
      ],
    }),
  };
  const response = await typedFetch<VehicleInfoResultsResponse>(`${env.vehicleApiRoot}/info`, init)
    .then((resp) => resp.json())
    .catch((error) =>
      handleError(error, `Error getting info from ${env.vehicleApiRoot}/info`, 'fetchVehicleInfo'),
    );

  checkResponse(response);

  if ('VehicleInfoResults' in response) return response.VehicleInfoResults;

  return null;
};

export const fetchVehicleByVinNumber = async ({
  year,
  vinNumber,
  referenceId = '',
}: {
  year: string;
  vinNumber: string;
  referenceId?: string;
}): Promise<VehicleInfoResults | null> => {
  const init: RequestInitExtended = {
    headers: { ...vehicleApiDefaultHeaders, referenceId },
    params: { year },
  };
  const response = await typedFetch<VehicleInfoResponse>(`${env.vehicleApiRoot}/${vinNumber}`, init)
    .then((resp) => resp.json())
    .catch((error) =>
      handleError(
        error,
        `Error getting vehicle from ${env.vehicleApiRoot}/${vinNumber}`,
        'fetchVehicleByVinNumber',
      ),
    );

  checkResponse(response);

  if ('VehicleInfo' in response) return response.VehicleInfo;

  return null;
};
