import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'VehicleAndDriverDiscounts' })((theme) => ({
  root: {
    width: '100%',
  },
  discountCardContainer: {
    width: '100%',
  },
  divider: {
    margin: '10px 0px',
  },
  discountContainer: {
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  discountGrid: {
    padding: 0,
  },
  vehicleIcon: {
    maxWidth: 45,
    maxHeight: 31,
    height: '100%',
  },
  title: {
    marginBottom: 10,
  },
  expandDiscounts: {
    justifyContent: 'space-between',
    marginBottom: 10,
  },
}));
