import { createSelectorCreator, defaultMemoize } from 'reselect';

import { isEqual } from '@ecp/utils/common';

/**
 * This selector will do value comparison rather than reference comparison.
 * Should only be used when necessary:
 * - a small array of values
 * - a small object of values
 */
export const createDeepSelector = createSelectorCreator(defaultMemoize, isEqual);
