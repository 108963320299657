import { trackClick } from '@ecp/utils/analytics/tracking';

export const trackCoverageChangeToggleClick = (coverageTitle: string, expanded: boolean): void =>
  trackClick({ action: `change_${coverageTitle}`, label: expanded ? 'Open' : 'Close' });

export const trackCoverageReadMoreLessClick = (coverageTitle: string, expanded: boolean): void =>
  trackClick({
    action: `Read${expanded ? 'Less' : 'More'}_${coverageTitle}`,
    label: expanded ? 'Less' : 'More',
  });

export const trackViewPIPClick = (coverageTitle: string): void =>
  trackClick({ action: `View_${coverageTitle}_PIP`, label: 'ViewPIP' });

export const showCoverageSelectOption = (coverageKey: string): boolean => {
  // list of Coverages which will appear as a select dropdown
  return [
    'homesite.home.coverages.lossAssessmentCoverage',
    'homesite.renters.coverages.electronicPaymentsForgeryCoverage',
  ].includes(coverageKey);
};

export const showOtherStructuresCoverageSection = (field: string): boolean => {
  return ['homesite.home.coverages.otherStructures'].includes(field);
};
