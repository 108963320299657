import type { SalesResponse } from '@ecp/features/sales/shared/utils/network';
import { salesRequest } from '@ecp/features/sales/shared/utils/network';
import type { Product } from '@ecp/features/shared/product';

import type { RatingCriteriaResponse } from './types';

export const getRatingCriteria = ({
  dalSessionId,
  product,
}: {
  dalSessionId: string;
  product: Product;
}): Promise<SalesResponse<RatingCriteriaResponse>> => {
  return salesRequest({
    endpoint: `session/${dalSessionId}/offers/rating-criteria?product=${product}`,
    options: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
};
