import { memo } from 'react';

import { Box, Divider } from '@mui/material';

import { PhoneLink } from '@ecp/features/sales/shared/components';
import { partner } from '@ecp/partners';

import { useStyles } from '../QuotesError.styles';

export const HO2Guidance: React.FC = memo(() => {
  const { classes } = useStyles();

  return (
    <Box>
      <h1>Whoops! No quotes available.</h1>
      <Divider aria-hidden='true' className={classes.divider} />
      <p className={classes.guidance}>
        Based on the information you’ve provided, we’re unable to insure your home with a standard
        Replacement Cost Policy.
      </p>
      <p className={classes.guidance}>
        The good news — we offer a Market Value Policy that should sufficiently insure your home.
      </p>
      <p className={classes.guidance}>
        Call one of our licensed advisors for your home insurance quote at
        <PhoneLink withUnderlinedLinkStyle withLinkStyle number={partner.shared.dnqPhoneNumber} />.
      </p>
    </Box>
  );
});
