import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'IAgreeDialog' })((theme) => ({
  title: {
    ...theme.typography.body3,
    color: theme.palette.text.primary,
  },
  checkboxDialogTitle: {
    margin: '10px 0px 20px 0px',
  },
  bulletDialogTitle: {
    marginTop: 10,
  },
  dialogRoot: {
    maxWidth: '100%',
    '& .MuiDialogActions-root': {
      marginTop: 40,
    },
  },
  dialogContainer: {
    maxWidth: '85%',
    marginTop: 40,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 15,
      marginRight: 15,
    },
  },
  dialogContentContiner: {
    maxWidth: '85%',
    marginTop: 24,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 15,
      marginRight: 15,
    },
  },
  productHeaderMargin: {
    margin: '15px 0px',
  },
  verticalAlign: {
    ...theme.typography.body1,
    margin: 0,
    paddingLeft: 12,
    listStyle: 'none',
  },
  checkboxRoot: {
    marginTop: 15,
  },
  dialogTitle: theme.typography.header1,
  fullscreenDialogTitle: theme.typography.h1,
  listItemsPadding: {
    paddingLeft: 15,
  },
  checkboxItemsPadding: {
    paddingLeft: 0,
  },
  dialogHeader: { ...theme.typography.header1, lineHeight: '32px' },
  dialogMargin: { marginLeft: 24 },
  listItemsContent: {
    listStyleType: 'none',
    paddingLeft: 0,
  },
  statementListStyle: {
    paddingLeft: 24,
    listStyleType: 'disc !important',
    'ul, li': {
      ...theme.typography.body1,
    },
  },
  itemHeader: { ...theme.typography.body1, marginTop: 24 },
  error: {
    color: theme.palette.error.main,
  },
  requiredError: {
    ...theme.typography.body3,
    color: theme.palette.error.main,
    padding: '10px 0 0 12px',
  },
}));
