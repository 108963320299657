import { cloneElement } from 'react';

import { IconUIPlus } from '@ecp/themes/base';

import { Button } from '../Button';
import { useStyles } from './AddMore.styles';

export interface AddMoreProps {
  buttonTitle: string;
  handleClick: () => void;
  cardSize: 'small' | 'medium' | 'large';
  disabled?: boolean;
  image?: React.ReactElement;
  dataTestId?: string;
  className?: string;
  showDefaultAddMore?: boolean;
}

export const AddMore: React.FC<AddMoreProps> = (props) => {
  const {
    handleClick,
    cardSize,
    buttonTitle,
    disabled,
    image,
    dataTestId = '',
    className,
    showDefaultAddMore = false,
  } = props;
  const { classes, cx } = useStyles();

  if (!showDefaultAddMore) return null;

  return (
    <Button
      variant='iconText'
      className={cx(
        classes.root,
        classes.card,
        classes[cardSize],
        disabled && classes.disabled,
        className,
      )}
      data-testid={dataTestId}
      onClick={disabled ? undefined : handleClick}
    >
      {image && cloneElement(image, { className: cx(classes.image, image.props.className) })}
      <IconUIPlus fontSize='large' className={classes.button} />
      <div className={classes.text}>{buttonTitle}</div>
    </Button>
  );
};
