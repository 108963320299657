import { GridItem } from '@ecp/components';

import {
  ExteriorWallConstructionQuestion,
  HomeBuiltOnSlopeQuestion,
  OtherStructuresBlanketCoverageQuestion,
  RoofClassQuestion,
  RoofInstallationDateQuestion,
  RoofMaterialDescriptionQuestion,
  RoofMaterialQuestion,
  RoofShapeQuestion,
} from '../components';

export interface HomeExteriorProps {
  onNext: () => Promise<void>;
}

export const HomeExteriorFormQuestions: React.FC<HomeExteriorProps> = () => {
  return (
    <>
      <GridItem xs={12}>
        <ExteriorWallConstructionQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <RoofShapeQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <RoofMaterialQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <RoofMaterialDescriptionQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <RoofClassQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <RoofInstallationDateQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <HomeBuiltOnSlopeQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <OtherStructuresBlanketCoverageQuestion />
      </GridItem>
    </>
  );
};
