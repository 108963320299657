import { useState } from 'react';

import { useEvent } from '@ecp/utils/react';

import { Alert } from '@ecp/components';
import { Button } from '@ecp/features/sales/shared/components';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';
import { getProductNameFromProduct } from '@ecp/features/shared/product';

import { downloadAndSaveDocuments } from '../../../../state';
import { useStyles } from './DownloadPostBindDocuments.styles';
import metadata from './metadata';
import type { DocumentType, Status } from './types';

interface Props {
  documentFormName: string;
  policyNumber: string;
  purchased?: boolean;
  selectedProduct: Product;
  /** Initial status.
   * @default 'default'
   */
  status?: Status;
  /**
   * Timeout in seconds until delayed message will be shown.
   * @default 9
   */
  delayedMessageTimeoutInSeconds?: number;
  trackingName: string;
  analyticsElement: string;
  documentType: DocumentType;
}

const DEFAULT_DELAY_IN_SECONDS = 9;
export const DownloadPostBindDocuments: React.FC<Props> = (props) => {
  const {
    documentFormName,
    policyNumber,
    purchased,
    selectedProduct,
    status: statusProp = 'default',
    delayedMessageTimeoutInSeconds = DEFAULT_DELAY_IN_SECONDS,
    trackingName,
    analyticsElement,
    documentType,
  } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [status, setStatus] = useState<Status>(statusProp);

  const handleDocumentDownloadClick = useEvent(async (): Promise<void> => {
    setStatus('processing');
    const timeout = setTimeout(() => setStatus('delayed'), delayedMessageTimeoutInSeconds * 1000);

    const result = await dispatch(
      downloadAndSaveDocuments({
        product: selectedProduct,
        documentTypeName: documentFormName,
        documentDisplayName: documentFormName,
      }),
    );

    clearTimeout(timeout);
    const newStatus = result ? 'default' : 'error';
    setStatus(newStatus);
  });

  if (status === 'error')
    return (
      <Alert className={classes.root} type='info'>
        {metadata[documentType]?.error(selectedProduct)}
      </Alert>
    );

  const isProcessing = status === 'processing' || status === 'delayed';
  const icon = isProcessing ? undefined : metadata.icon;
  const productName = getProductNameFromProduct(selectedProduct);
  const trackingLabel = `Policynumber: ${policyNumber}, Product: ${selectedProduct}`;

  return (
    <Button
      disabled={!purchased}
      classes={{
        root: classes.root,
      }}
      onClick={handleDocumentDownloadClick}
      isProcessing={isProcessing}
      trackingName={`download_${productName}_${trackingName}`}
      trackingLabel={trackingLabel}
      analyticsElement={analyticsElement}
      analyticsEventDetail={trackingLabel}
      data-testid='postBindDownload'
      variant='iconTextMedium'
      icon={icon}
    >
      {metadata[documentType]?.[status]}
    </Button>
  );
};
