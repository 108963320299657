import { TooltipWithIcon } from '@ecp/components';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const OccupyMoreThanOneResidenceQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const occupancyType = useHomePropertyField('occupancyType');
  const occupyMoreThanOneRes = useHomePropertyField('occupyByMoreThanOneResidentInd');
  const { label = occupyMoreThanOneRes.question?.title, trackingName = 'OccupyMoreThanResidence' } =
    props;
  const helpText = occupyMoreThanOneRes.question?.helpText;
  const displayOccupyMoreThanOneResQuestion =
    occupancyType.exists &&
    occupancyType.props.value &&
    occupancyType.props.value === 'prim' &&
    occupyMoreThanOneRes.exists;
  if (!displayOccupyMoreThanOneResQuestion) return null;

  return (
    <RadioGroupWithOptions
      {...occupyMoreThanOneRes.props}
      variant='yesNoButton'
      id='OccupyMoreThan'
      trackingName={trackingName}
      trackingLabel={occupyMoreThanOneRes.props.value}
      label={
        <>
          {label}
          <TooltipWithIcon title={helpText} />
        </>
      }
    />
  );
};
