import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'SiteUnavailable' })((theme) => ({
  container: {
    ...theme.mixins.pageRoot,
    marginTop: 200,
    height: '100vh',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: 50,
    },
  },
  content: {
    alignContent: 'flex-start',
    '& img': {
      textAlign: 'center',
      width: '100%',
      margin: '0px auto 40px',
    },
    '& p': {
      textAlign: 'center',
    },
  },
  imageGrid: {
    position: 'relative',
    minHeight: 400,
    [theme.breakpoints.down('md')]: {
      minHeight: 'auto',
    },
  },
  imageContainer: {},
}));
