import { datadogLog } from '@ecp/utils/logger';

import { PurchaseErrorReason } from '@ecp/features/sales/shared/constants';
import {
  updatePurchaseError,
  wrapThunkActionWithErrHandler,
} from '@ecp/features/sales/shared/store';
import { SalesRequestError } from '@ecp/features/sales/shared/utils/network';
import type { Product } from '@ecp/features/shared/product';

import {
  setAvailablePaymentTypes,
  setPaymentOptionsApiGetSuccess,
  setPaymentOptionsApiPostSuccess,
} from './actions';
import { getPaymentOptions, postPaymentOption } from './api';
import type { PaymentOptionsRequest } from './types';

export const fetchPaymentOptions = wrapThunkActionWithErrHandler<PaymentOptionsRequest, void>(
  ({ dalSessionId, products }) =>
    async (dispatch) => {
      const response = await getPaymentOptions({ dalSessionId, products }).catch((error) => {
        datadogLog({
          logType: 'error',
          message: `Error getting payment options - ${error?.message}`,
          context: {
            logOrigin: 'libs/features/sales/checkout/src/state/paymentoptions/thunks.ts',
            functionOrigin: 'fetchPaymentOptions',
            message: error?.message,
            ...(error instanceof SalesRequestError && { errorData: error.errorData }),
          },
          error,
        });
        throw new Error('Error getting payment options');
      });
      dispatch(setPaymentOptionsApiGetSuccess(response.payload));
      dispatch(setAvailablePaymentTypes(response.payload));
    },
  'fetchPaymentOptions',
);

type PushPaymentOption = {
  dalSessionId: string;
  product: Product;
  paymentType: string;
  paymentPlan: string;
};

export const pushPaymentOption = wrapThunkActionWithErrHandler<PushPaymentOption, void | string>(
  ({ dalSessionId, product, paymentType, paymentPlan }) =>
    async (dispatch) => {
      // Reset missingCreditCardTokenError info
      dispatch(updatePurchaseError(null));
      const response = await postPaymentOption({
        dalSessionId,
        product,
        paymentType,
        paymentPlan,
      }).catch((error) => {
        let errorReason = null;
        if (error instanceof SalesRequestError) {
          const errorBody = error.errorBody?.toLowerCase();
          // TODO - Revisit this logic after SAPI adds proper error code instead of these random error text
          if (errorBody) {
            if (
              errorBody.includes('missingcreditcardtoken') ||
              errorBody.includes('invalid paymentoptioncreditcard') ||
              errorBody.includes('missing_cc_data') ||
              errorBody.includes('invalid_financial_token')
            ) {
              errorReason = PurchaseErrorReason.MISSING_CREDITCARD_TOKEN_ERROR;
            }
          }
        }

        datadogLog({
          logType: errorReason ? 'warn' : 'error',
          message: `Error posting payment options - ${error.message}`,
          context: {
            logOrigin: 'libs/features/sales/checkout/src/state/paymentoptions/thunks.ts',
            functionOrigin: 'pushPaymentOption',
            message: error.message,
            reason: error.errorReason,
            ...(error instanceof SalesRequestError && { errorData: error.errorData }),
          },
          error,
        });

        // Don't go to error page if missing credit card token
        if (errorReason) {
          return errorReason;
        } else {
          throw Error(error);
        }
      });

      if (response === PurchaseErrorReason.MISSING_CREDITCARD_TOKEN_ERROR) {
        dispatch(updatePurchaseError(response));

        return response;
      } else if (response && typeof response !== 'string') {
        dispatch(setPaymentOptionsApiPostSuccess(response.payload));
      }

      return '';
    },
  'pushPaymentOption',
);
