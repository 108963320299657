import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'Signature' })((theme) => ({
  container: {
    maxWidth: theme.breakpoints.values.md,
    marginTop: '40px',
  },
  divider: {
    margin: '4px 0px 0px',
    height: 4,
    backgroundColor: theme.palette.primary.main,
    border: 'none',
  },
}));
