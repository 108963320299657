import { env } from '@ecp/env';
import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CoveragesPageUpperSection' })((theme) => ({
  root: env.static.isAgent
    ? {
        justifyContent: 'left',
      }
    : theme.mixins.withRightSidebar.contentContainer,
}));
