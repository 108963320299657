import { Grid } from '@mui/material';

import { Button, GridItem, HoursList, PhoneLink } from '@ecp/components';
import { partner } from '@ecp/partners';
import { IconUITimeout } from '@ecp/themes/base';

import { useStyles } from './SessionExpiryContent.styles';
import { getRemainingSecondsDisplay, getSessionExpiryLabels } from './util';

type Props = {
  offerExist: boolean;
  remaining: number;
  isSessionIdle: boolean;
  showActionButton?: boolean;
  handleSessionPromptButtonClick?: () => void;
} & ActionButtonProps;

type ActionButtonProps =
  | {
      showActionButton?: false | undefined;
      handleSessionPromptButtonClick?: () => void;
    }
  | {
      showActionButton: true;
      handleSessionPromptButtonClick: () => void;
    };

export const SessionExpiryContent: React.FC<Props> = (props) => {
  const { offerExist, remaining, isSessionIdle, showActionButton, handleSessionPromptButtonClick } =
    props;
  const { classes } = useStyles({
    leftAlignDialog: !showActionButton,
    leftAlignSessionContent: isSessionIdle,
  });
  const { bodyText, buttonText, trackingLabel, trackingName } = getSessionExpiryLabels(
    isSessionIdle,
    offerExist,
  );

  return (
    <Grid container className={classes.root}>
      <GridItem xs={12} lg={12} className={classes.sessionTimerContent}>
        {!isSessionIdle && (
          <span>
            <IconUITimeout />
            <h1>{getRemainingSecondsDisplay(remaining)}</h1>
          </span>
        )}
        <p>{bodyText}</p>
      </GridItem>
      {showActionButton && (
        <GridItem xs={12} lg={12} className={classes.sessionTimerButton}>
          <Button
            variant='primary'
            onClick={handleSessionPromptButtonClick}
            trackingLabel={trackingLabel}
            trackingName={trackingName}
          >
            {buttonText}
          </Button>
        </GridItem>
      )}
      <GridItem xs={12} lg={12} className={classes.sessionTimerContent}>
        {isSessionIdle && (
          <div>
            <p>Reach our licensed insurance experts for help at </p>
            <PhoneLink withUnderlinedLinkStyle number={partner.shared.salesPhoneNumber} />
            {partner.shared.salesPhoneHours && (
              <HoursList weeklyHours={partner.shared.salesPhoneHours} />
            )}
          </div>
        )}
      </GridItem>
    </Grid>
  );
};
