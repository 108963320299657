import { useCallback } from 'react';

import { Grid } from '@mui/material';

import { useEffectOnce } from '@ecp/utils/react';

import { GridItem } from '@ecp/components';
import { useInitValues } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import { useField } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product, ProductName } from '@ecp/features/shared/product';

import { getSignatureMethodOptions, setSignatureMethod } from '../../../state/acknowledgements';
import { RemoteClick2Sign } from './RemoteClick2Sign';
import { useStyles } from './Signature.styles';
import { VoiceSignature } from './VoiceSignature';

interface Props {
  coverageType: ProductName;
  selectedProduct: Product;
}

export const Signature: React.FC<Props> = (props) => {
  const { coverageType, selectedProduct } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const signatureMethod = useField(`static.checkout.${coverageType}.signatureMethod`);

  const signatureOptions = useSelector((state) =>
    getSignatureMethodOptions(state, selectedProduct),
  );
  useInitValues({ [signatureMethod.key]: 'REMOTE_CLICK_SIGN' });

  useEffectOnce(() => {
    const value = signatureMethod.value || 'REMOTE_CLICK_SIGN';
    dispatch(setSignatureMethod(value.toString()));
  });
  const handleSignatureOptionChange = useCallback(
    (value: string): void => {
      signatureMethod.props.actionOnComplete(value);
      dispatch(setSignatureMethod(value));
    },
    [dispatch, signatureMethod],
  );

  // TODO: ADVECP-4095: Map signature method to SAPI (POST Acknowledgements)

  if (!signatureMethod.exists || !signatureOptions.length) return null;

  return (
    <Grid container className={classes.container} spacing={1}>
      <GridItem xl={6} xs={12}>
        <Select
          {...signatureMethod.props}
          fullWidth
          label='Signature Method'
          id='signType'
          options={signatureOptions}
          trackingName='signType'
          inputButtonAriaLabel='Signature method'
          actionOnChange={handleSignatureOptionChange}
        />
      </GridItem>
      {signatureMethod.value === 'REMOTE_CLICK_SIGN' && (
        <RemoteClick2Sign coverageType={coverageType} selectedProduct={selectedProduct} />
      )}
      {signatureMethod.value === 'VOICE' && <VoiceSignature />}
    </Grid>
  );
};
