import { keyframes, makeStyles } from '@ecp/themes/base';

const animation = keyframes({
  '0%, 80%, 100%': {
    transform: 'scale(0)',
    letterSpacing: 5,
  },
  '50%': {
    transform: 'scale(1)',
    letterSpacing: 5,
  },
});

export const useStyles = makeStyles({
  name: 'Recalc',
})((theme) => ({
  inlineDots: {
    marginLeft: 5,
    display: 'inline',
  },
  movingLoadingContainer: {
    '& span': {
      animation,
      animationDuration: '1.4s',
      animationIterationCount: 'infinite',
      animationFillMode: 'both',
      display: 'inline-block',
      color: theme.palette.text.primary,
    },
    '& span:nth-child(2)': {
      animationDelay: '.2s',
    },
    '& span:nth-child(3)': {
      animationDelay: '.4s',
    },
  },
  loadingDotsContainer: {
    '& span': {
      color: theme.palette.text.primary,
    },
  },
}));
