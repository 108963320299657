import { LineOfBusiness } from '@ecp/features/shared/product';

type Metadata = Partial<
  Record<
    LineOfBusiness,
    {
      pageTitle: string;
    }
  >
>;

const metadata: Metadata = {
  [LineOfBusiness.HOME]: {
    pageTitle: 'Protect your belongings with home insurance',
  },
  [LineOfBusiness.RENTERS]: {
    pageTitle: 'Protect your belongings with renters insurance',
  },
};

export default metadata;
