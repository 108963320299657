import type { RemindersMetadata } from '@ecp/features/sales/shared/types';

import { PostBindReminder } from './PostBindReminder';
import { useStyles } from './PostBindReminders.styles';

interface Props {
  reminders?: RemindersMetadata[];
}

export const PostBindReminders: React.FC<Props> = (props) => {
  const { reminders } = props;
  const { classes } = useStyles();

  return (
    <ul className={classes.listItems}>
      {reminders?.map((reminder, index) => (
        <PostBindReminder key={index} reminder={reminder} />
      ))}
    </ul>
  );
};
