import { FeatureFlags, flagValues, flagVariables } from '@ecp/utils/flags';

/**
 * Calculates the maximum number of days in advance a policy start date can be set.
 * The limit varies based on the state and whether the product is auto.
 *
 * @param {Object} params - The parameters for the calculation.
 * @param {string} params.stateCode - The state code (e.g., 'CA', 'WA') for which the policy is being set.
 * @param {boolean} params.isAuto - Indicates if the product is auto.
 * @param {boolean} params.isConnectAutoProduct - Indicates if the product is a CONNECT auto product.
 * @returns {number} The maximum number of days in advance the policy start date can be set.
 *                   Returns a fixed default for non-auto products, and varies for auto product based on state.
 */
export const calculateMaxPolicyStartDaysLimit = ({
  isAutoProduct,
  isConnectAutoProduct,
}: {
  isAutoProduct: boolean;
  isConnectAutoProduct?: boolean;
}): number => {
  // Effective date manipulation is done for auto product
  if (isAutoProduct) {
    // If the auto product is connect, we use CONNECT_AUTO_STATES_LIMIT_EFFECTIVE_DATE and AUTO_QUOTE_WINDOW_TO_30DAYS
    // to determine last effective date for the state
    if (isConnectAutoProduct && flagValues[FeatureFlags.CONNECT_AUTO_STATES_LIMIT_EFFECTIVE_DATE]) {
      const limitDate = getLimitDate();

      if (limitDate) {
        const daysLeft = Math.floor(
          Math.max(0, (limitDate.getTime() - Date.now()) / (1000 * 60 * 60 * 24)),
        );

        return flagValues[FeatureFlags.AUTO_QUOTE_WINDOW_TO_30DAYS]
          ? Math.min(daysLeft, 29)
          : daysLeft;
      }
    }

    return flagValues[FeatureFlags.AUTO_QUOTE_WINDOW_TO_30DAYS]
      ? 29
      : DEFAULT_MAX_POLICY_START_DAYS_LIMIT;
  }

  // This will be defaulted to 60 days for Home product
  return DEFAULT_MAX_POLICY_START_DAYS_LIMIT;
};
// function to check if the varialbe is defined.
const getLimitDate = (): Date | null => {
  const connectAutoLimitEffectiveDateFeatureFlag =
    FeatureFlags.CONNECT_AUTO_STATES_LIMIT_EFFECTIVE_DATE;

  return flagVariables[connectAutoLimitEffectiveDateFeatureFlag] &&
    flagVariables[connectAutoLimitEffectiveDateFeatureFlag].LIMIT_EFFECTIVE_DATE
    ? new Date(flagVariables[connectAutoLimitEffectiveDateFeatureFlag].LIMIT_EFFECTIVE_DATE)
    : null;
};

export const DEFAULT_MAX_POLICY_START_DAYS_LIMIT = 60;
