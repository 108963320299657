import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CheckoutPaymentMethod' })((theme) => ({
  checkoutPaymentContainer: {
    ...theme.typography.body1,
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  box: {
    [theme.breakpoints.down('md')]: {
      border: 'none',
      padding: 0,
    },
    padding: '0 20px 20px',
  },
  singlePaymentMethodSpacing: {
    paddingTop: 20,
    marginTop: 0,
  },
  singlePaymentMethodMobileSpacing: {
    paddingTop: 0,
    marginTop: 0,
  },
  paymentMethodContainer: {
    padding: '20px 20px 0',
  },
  paymentMethodMobileContainer: {
    padding: '20px 20px 0',
  },
  paymentMethodDetailsContainer: {
    padding: 0,
  },
  divider: {
    height: 4,
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    ...theme.typography.h2,
    marginBottom: 10,
  },
  background: {
    backgroundColor: theme.palette.grey['100'],
  },
  policyDifferenceContainer: {
    marginTop: 10,
    textAlign: 'right',
  },
  paymentTips: theme.typography.body1Bold,
  paymentTipsList: {
    marginTop: 0,
    paddingLeft: 25,
  },
  paymentTipContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  popOverText: theme.typography.body1,
  tip: {
    backgroundColor: theme.palette.secondary.background,
    borderRadius: 8,
  },
  reuseLabel: {
    '& .MuiFormControlLabel-label': {
      ...theme.typography.body1,
      marginLeft: 12,
    },
  },
  reuseLabelContainer: {
    padding: '15px 40px 0',
  },
  reuseLabelMobileContainer: {
    padding: '15px 20px 0',
  },
}));
