import { Divider, Grid } from '@mui/material';

import { Button, GridItem } from '@ecp/components';
import { TextField } from '@ecp/features/sales/shared/components';
import { IconUIDelete, IconUIPlus, useIsMobile } from '@ecp/themes/base';
import type { AnswerValue, Field } from '@ecp/types';

import { useStyles } from './OtherStructuresForm.styles';

interface OtherStructureFormProps {
  field: Field<AnswerValue> | undefined;
}

export const OtherStructuresForm: React.FC<OtherStructureFormProps> = (props) => {
  const { field } = props;
  const { classes } = useStyles();
  const isMobile = useIsMobile();
  const lastStructure = true;

  const showOtherStucturesForm = field?.value === 'Coverage Selected';

  if (!field) return null;

  // TODO: ECP-13871 Build Logic once SAPI data contract is provided.
  return showOtherStucturesForm ? (
    <>
      <Divider className={classes.divider} />
      <h3 className={classes.subtitle}>Add other Structures</h3>
      <Grid container>
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
          <TextField
            // eslint-disable-next-line react/jsx-no-bind
            actionOnComplete={() => console.log('done!')}
            ariaLabel='First Name'
            id='descriptionOfStructure'
            label='Description of structure'
            trackingLabel='descriptionOfStructure'
            trackingName='descriptionOfStructure'
          />
        </GridItem>
        <GridItem topSpacing='sm' xs={12} md={5} className={classes.columnRight}>
          <TextField
            // eslint-disable-next-line react/jsx-no-bind
            actionOnComplete={() => console.log('done!')}
            ariaLabel='First Name'
            id='additionalLimitOfLiability'
            label='Additional limit of liability'
            trackingLabel='additionalLimitOfLiability'
            trackingName='additionalLimitOfLiability'
          />
        </GridItem>
        {!isMobile && (
          <GridItem topSpacing='sm' xs={12} md={1} className={classes.columnRight}>
            <Button
              className={classes.removeStructureButton}
              variant='iconTextLarge'
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => console.log('done!')}
              icon={<IconUIDelete />}
            />
          </GridItem>
        )}
        {lastStructure && (
          <GridItem topSpacing='sm' xs={6} md={6} className={classes.columnLeft}>
            <Button
              className={classes.addStructureButtons}
              variant='iconTextLarge'
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => console.log('done!')}
              icon={<IconUIPlus />}
            >
              Add structure
            </Button>
          </GridItem>
        )}
        {isMobile && (
          <GridItem topSpacing='sm' xs={6} md={6} className={classes.columnLeft}>
            <Button
              variant='iconTextLarge'
              className={classes.addStructureButtons}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => console.log('done!')}
              icon={<IconUIDelete />}
            >
              Remove structure
            </Button>
          </GridItem>
        )}
      </Grid>
    </>
  ) : null;
};
