export interface Metadata {
  displayNewQuoteButton?: boolean;
  getQuoteButtonText: string;
  shouldSetPersonLocks?: boolean;
  shouldCallPrefill?: boolean;
  disableLandingPage?: boolean;
}

const metadata: Metadata = {
  getQuoteButtonText: 'Continue',
  shouldSetPersonLocks: false,
  shouldCallPrefill: false,
};

export default metadata;
