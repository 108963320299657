import { useCallback, useEffect, useState } from 'react';

import { Card, CardContent, Divider } from '@mui/material';

import { env } from '@ecp/env';

import { useStyles } from '../NavbarDrawer';
import { ProductNavbarHeader } from './ProductNavbarHeader';
import { ProductNavbarStep } from './ProductNavbarStep';
import type { ProductNavbarProps } from './types';

export const ProductNavbar: React.FC<ProductNavbarProps> = (props) => {
  const { productPageFlows, activeRootStepNumber, jumpInNavbar, pagePath, ...rest } = props;
  const { classes } = useStyles();
  const [expandedIndex, setExpandedIndex] = useState<number | undefined>(activeRootStepNumber - 1);

  useEffect(() => {
    setExpandedIndex(activeRootStepNumber - 1);
  }, [setExpandedIndex, activeRootStepNumber]);

  const isLabelClickable = useCallback(
    (index: number) =>
      env.static.isAgent ? activeRootStepNumber >= 1 : activeRootStepNumber > index + 1,
    [activeRootStepNumber],
  );

  const handleLabelClick = useCallback(
    (index: number, path: string): void => {
      if (isLabelClickable(index)) {
        env.static.isAgent
          ? setExpandedIndex(index === expandedIndex ? undefined : index)
          : jumpInNavbar(path);
      }
    },
    [setExpandedIndex, expandedIndex, jumpInNavbar, isLabelClickable],
  );

  return (
    <Card className={classes.cardMargin}>
      <CardContent className={classes.cardPadding}>
        <ProductNavbarHeader pagePath={pagePath} />
      </CardContent>
      <Divider aria-hidden='true' />
      {productPageFlows.map((productPageFlow, index) => {
        return (
          <div key={index}>
            <CardContent className={classes.noPadding}>
              <ProductNavbarStep
                stepNumber={index + 1}
                productPageFlow={productPageFlow}
                activeRootStepNumber={activeRootStepNumber}
                jumpInNavbar={jumpInNavbar}
                pagePath={pagePath}
                onLabelClick={handleLabelClick}
                expanded={expandedIndex === index}
                isLabelClickable={isLabelClickable}
                {...rest}
              />
            </CardContent>
            <div>
              {productPageFlows.length > 1 && productPageFlows.length - 1 !== index && (
                <Divider aria-hidden='true' />
              )}
            </div>
          </div>
        );
      })}
    </Card>
  );
};
