export interface Metadata {
  emailHelpText?: JSX.Element | string;
  phoneHelpText?: JSX.Element | string;
  phoneRequired?: boolean;
  showAdvisorNameSearchQuestions?: boolean;
  showEmailAndPhoneQuestions?: boolean;
  getContactHeaderText: (firstName?: string) => string;
  policyTitle: string;
  showReviewSection?: boolean;
}

const metadata: Metadata = {
  emailHelpText: 'We’ll send communications about your policy to this email.',
  phoneHelpText: 'We may reach out if we have questions or if follow-up is needed.',
  phoneRequired: false,
  getContactHeaderText: (firstName) => `Confirm ${firstName}'s information`,
  policyTitle: 'Review and purchase your policies',
  showEmailAndPhoneQuestions: true,
  showReviewSection: true,
};

export default metadata;
