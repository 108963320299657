import type { Product } from '@ecp/features/shared/product';

import { PolicyStartDateFormInDrawerVariant } from './PolicyStartDateFormInDrawerVariant';
import { PolicyStartDateFormOnPageVariant } from './PolicyStartDateFormOnPageVariant';

type InDrawerVariantProps = {
  variant: 'inDrawer';
  product: Product;
};

type OnPageVariantProps = {
  variant: 'onPage';
};

type Props = InDrawerVariantProps | OnPageVariantProps;

export const PolicyStartDateForm: React.FC<Props> = (props) => {
  const { variant } = props;

  if (variant === 'inDrawer') {
    return <PolicyStartDateFormInDrawerVariant product={props.product} />;
  }

  return <PolicyStartDateFormOnPageVariant />;
};
