import { useMemo } from 'react';

const htmlDecode = (content: string | undefined | null): string => {
  if (content) {
    const div = document.createElement('div');
    div.innerHTML = content;

    return div?.childNodes?.[0]?.nodeValue || '';
  }

  return '';
};

/**
 *
 * @param content HTML string which can include escaped chars.
 * @example &lt;p>&lt;b>Quote Number: BX15061193&lt;/b>&lt;/p>
 * @returns decoded HTML string ready to be passed as a prop to a component:
 * `<div dangerouslySetInnerHTML={{ __html: htmlDecode(body) }} />`
 */
export const useHtmlDecode: typeof htmlDecode = (content) =>
  useMemo(() => htmlDecode(content), [content]);
