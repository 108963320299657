import { emptyArray } from '@ecp/utils/common';

export enum QuestionTypes {
  STRING = 'String',
  NUMBER = 'Number',
  BOOLEAN = 'Boolean',
}

export enum Keywords {
  TYPE = 'type',
  DESCRIPTION = 'description',
  DEFAULT = 'default',
  REQUIRED = 'required',
  OPTIONS = 'options',
  MIN = 'min',
  MAX = 'max',
  MINLENGTH = 'minLength',
  MAXLENGTH = 'maxLength',
  CRITERIA = 'criteria',
  HIDE = 'hide',
  META = 'meta',
  ERRORS = '_errors',
}

export enum Operators {
  REGEX = '$regex',
  EXISTS = '$exists',
  EQ = '$eq',
  EQDEL = '$eqdel',
  NE = '$ne',
  NEDEL = '$nedel',
  GT = '$gt',
  GTDEL = '$gtdel',
  GTE = '$gte',
  GTEDEL = '$gtedel',
  LT = '$lt',
  LTDEL = '$ltdel',
  LTE = '$lte',
  LTEDEL = '$ltedel',
  BETWEEN = '$between',
  IN = '$in',
  NIN = '$nin',
  IS_EVEN = '$isEven',
  IS_ODD = '$isOdd',
  AND = '$and',
  OR = '$or',
  NOR = '$nor',
  NOT = '$not',
  ANY = '$any',
  EVERY = '$every',
}

export enum Evaluators {
  ADD = '$add',
  SUBTRACT = '$subtract',
  MULTIPLY = '$multiply',
  DIVIDE = '$divide',
  SUM = '$sum',
  PRODUCT = '$product',
  MODULO = '$modulo',
  COUNT = '$count',
  DATE = '$date',
}

export const emptyRegExpMatchArray = emptyArray as unknown as RegExpMatchArray;
