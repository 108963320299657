import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PostBindRetryPaymentForm' })((theme) => ({
  root: {},
  paymentContainer: {
    maxWidth: 760,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 15,
      marginRight: 15,
    },
  },
  dialogTitle: {
    marginTop: 40,
    marginBottom: 10,
  },
  phone: {
    paddingRight: 30,
    '& span': theme.typography.button2Medium,
    [theme.breakpoints.down('md')]: {
      marginTop: 20,
    },
  },
  submitButton: {
    height: 40,
    width: 182,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  contactContainer: {
    marginTop: 30,
    paddingBottom: 35,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: 40,
      flexWrap: 'wrap',
      flexDirection: 'column-reverse',
    },
  },
  paymentHeader: {
    marginBottom: 40,
  },
}));
