import type { PaymentType } from '@ecp/features/sales/shared/store/types';
import type { ProductName } from '@ecp/features/shared/product';

export type DefaultPaymentByProductType = Record<ProductName, PaymentType>;

export const defaultPaymentByProductType: DefaultPaymentByProductType = {
  auto: 'CreditCard',
  home: 'Mortgage',
  renters: 'CreditCard',
};
