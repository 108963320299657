import type { CarrierName } from '@ecp/features/shared/product';
import { getCarrierExtendedDisplayName } from '@ecp/features/shared/product';

import { useStyles } from './CarrierLogo.styles';
import * as metadata from './metadata';

interface Props {
  carrierName: CarrierName;
  className?: string;
}

export const CarrierLogo: React.FC<Props> = (props) => {
  const { carrierName, className } = props;
  const { classes, cx } = useStyles();

  const imageUrl = metadata.getCarrierLogoImageUrlFromCarrierName(carrierName);

  const carrierExtendedDisplayName = getCarrierExtendedDisplayName(carrierName);

  return imageUrl ? (
    <div className={cx(classes.root, className)}>
      <img className={classes.image} src={imageUrl} alt={carrierExtendedDisplayName} />
    </div>
  ) : null;
};
