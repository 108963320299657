import { useCallback, useEffect } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem } from '@ecp/components';
import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { TextField } from '@ecp/features/sales/shared/components';
import { useField } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { ProductName } from '@ecp/features/shared/product';

import { setPniSignatureText, setSniSignatureText } from '../../../state';
import { useStyles } from './SignatureAcknowledgements.styles';
interface Props {
  coverageType: ProductName;
  pniSignature: string;
  sniSignature?: string;
}

export const SignatureAcknowledgements: React.FC<Props> = (props) => {
  const { coverageType, pniSignature, sniSignature } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const pniAcknowledgementField = useField(
    `static.checkout.${coverageType}.pniAcknowledgementName`,
  );
  const sniAcknowledgementField = useField(
    `static.checkout.${coverageType}.sniAcknowledgementName`,
  );

  useEffect(() => {
    if (pniAcknowledgementField?.props?.value) {
      dispatch(setPniSignatureText(pniAcknowledgementField.props.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useAddFields({
    [pniAcknowledgementField.key]: pniAcknowledgementField,
    [sniAcknowledgementField.key]: sniAcknowledgementField,
  });

  useAddConditionValues({
    conditionalFields: [sniAcknowledgementField],
    isExcluded: () => !sniSignature,
    isRequiredOverride: () => !!sniSignature,
  });

  const onBlurPNISignature = useCallback(() => {
    dispatch(setPniSignatureText(pniAcknowledgementField.props.value));
  }, [dispatch, pniAcknowledgementField.props]);

  const onChangePNISignature = useCallback(
    (value: string) => {
      pniAcknowledgementField.props.actionOnChange(value);
      dispatch(setPniSignatureText(pniAcknowledgementField.props.value));
    },
    [dispatch, pniAcknowledgementField.props],
  );

  const onBlurSNISignature = useCallback(() => {
    dispatch(setSniSignatureText(sniAcknowledgementField.props.value));
  }, [dispatch, sniAcknowledgementField.props]);

  const onChangeSNISignature = useCallback(
    (value: string) => {
      sniAcknowledgementField.props.actionOnChange(value);
      dispatch(setSniSignatureText(sniAcknowledgementField.props.value));
    },
    [dispatch, sniAcknowledgementField.props],
  );

  return (
    <Grid container>
      {pniSignature && (
        <GridItem topSpacing='lg' xs={12} md={6} lg={6}>
          <TextField
            id='FullName'
            {...pniAcknowledgementField.props}
            actionOnChange={onChangePNISignature}
            onBlur={onBlurPNISignature}
            ariaLabel='Signature'
            groupLabel={`Signature for ${pniSignature}`}
            helperText='Please type your full name. This serves as your legal signature & acknowledges the information
            provided on this page.'
            trackingName='customer_signature_field'
            trackingLabel={GoogleAnalyticsLabels.REDACTED}
          />
        </GridItem>
      )}

      {sniSignature && (
        <GridItem topSpacing='lg' xs={12} md={6} lg={6} className={classes.sniGrid}>
          <TextField
            id='FullName'
            {...sniAcknowledgementField.props}
            actionOnChange={onChangeSNISignature}
            onBlur={onBlurSNISignature}
            ariaLabel='Signature'
            groupLabel={`Signature for ${sniSignature}`}
            helperText='Please type your full name. This information serves as your legal signature & acknowledges the information
            provided on this page.'
            trackingName='customer_signature_field'
            trackingLabel={GoogleAnalyticsLabels.REDACTED}
          />
        </GridItem>
      )}
    </Grid>
  );
};
