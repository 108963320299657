import { env } from '@ecp/env';
import {
  PhoneLink,
  RVPErrorCustomGuidance,
  RVPErrorCustomReason,
} from '@ecp/features/sales/shared/components';
import { ErrorReason, PurchaseErrorReason } from '@ecp/features/sales/shared/constants';
import { partner } from '@ecp/partners';
import {
  GraphicErrorCallUsImageUrl,
  GraphicErrorGenericErrorPortraitImageUrl,
} from '@ecp/themes/base';

export const getErrorPageMetadata = (
  errorReason?: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  stateCode?: string,
): {
  title: string;
  subtitle?: string;
  reason?: string;
  customReason?: React.ReactElement;
  guidance?: React.ReactElement;
  navigationText?: string;
  nextPage?: string;
  returnButtonTrackingName?: string;
  errorImage: string;
  errorDetails?: React.ReactElement;
} => {
  switch (errorReason) {
    case ErrorReason.RVP:
      return {
        title: 'In order to complete this quote, we need more information',
        reason: `Please give us a call at  ${partner.shared.salesPhoneNumber} and be prepared to provide some information from the list below.`,
        customReason: <RVPErrorCustomReason />,
        guidance: <RVPErrorCustomGuidance />,
        navigationText: 'Return to quotes',
        nextPage: '/quote/quotes',
        returnButtonTrackingName: 'ReturnToQuotesLink',
        errorImage: GraphicErrorGenericErrorPortraitImageUrl,
      };
    case PurchaseErrorReason.UNKNOWN_PURCHASE_FAILURE:
      return {
        title: "We're having trouble processing your payment online",
        guidance: (
          <>
            Please call us at{' '}
            <PhoneLink withUnderlinedLinkStyle number={partner.shared.salesPhoneNumber} /> to
            purchase your policy
          </>
        ),
        errorImage: GraphicErrorCallUsImageUrl,
      };
    case ErrorReason.RULES_EXCEPTION:
      return {
        title: `We're having trouble processing your online quote.`,
        guidance: (
          <>
            Please contact us at{' '}
            <PhoneLink withUnderlinedLinkStyle number={partner.shared.dnqPhoneNumber} /> to speak
            with an agent who is ready to assist.
          </>
        ),
        errorDetails: (
          <>
            Mention error code: <strong>78465</strong> to the agent
          </>
        ),
        errorImage: GraphicErrorCallUsImageUrl,
      };
    case ErrorReason.ERROR:
    case ErrorReason.GLOBAL:
    default:
      return {
        title: 'Oops!',
        subtitle: 'Well, this is unexpected...',
        reason: 'We are working hard to fix the issue and apologize for the inconvenience.',
        guidance: !env.static.isAgent ? (
          <>
            Please return to the previous page. If that doesn&apos;t work and you need immediate
            assistance, please call us at{' '}
            <PhoneLink withUnderlinedLinkStyle number={partner.shared.salesPhoneNumber} />
          </>
        ) : undefined,
        navigationText: 'Return to the previous page',
        nextPage: errorReason === ErrorReason.ERROR ? '/quote/quotes' : '',
        returnButtonTrackingName: 'ReturnToPrevPageLink',
        errorImage: GraphicErrorGenericErrorPortraitImageUrl,
      };
  }
};
