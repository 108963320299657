import { useAddFields } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

export const FireStationQuestion: React.FC<QuestionProps> = (props) => {
  const {
    label = 'Is there a fire station within 5 miles of the rental property?',
    trackingName = 'fire_station_selection',
  } = props;

  const fireStation = useField('fireStation');

  useAddFields({ fireStation });

  if (!fireStation.exists) return null;

  return (
    <RadioGroupWithOptions
      {...fireStation.props}
      label={label}
      id={fireStation.props.name}
      variant='yesNoButton'
      trackingName={trackingName}
    />
  );
};
