import { TooltipWithIcon } from '@ecp/components';
import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

import propertyQuestionsUIMetadata from '../../../../propertyQuestionsUIMetadata';

export const HomeBuiltOnSlopeQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const homeBuiltOnSlope = useHomePropertyField('homeBuiltOnSlope');
  const uiQuestionMetaOptions = propertyQuestionsUIMetadata[homeBuiltOnSlope.key].options;
  const { label = homeBuiltOnSlope.question?.title, trackingName = 'home_slope_selection' } = props;

  useAddFields({ homeBuiltOnSlope });

  useAddConditionValues({
    conditionalFields: [homeBuiltOnSlope],
    isRequiredOverride: () => true,
  });

  if (!homeBuiltOnSlope.exists) return null;

  return (
    <RadioGroupWithOptions
      {...homeBuiltOnSlope.props}
      options={uiQuestionMetaOptions}
      id='HomeBuiltOnSlope'
      trackingName={trackingName}
      label={
        <>
          {label}
          <TooltipWithIcon title={homeBuiltOnSlope.question?.helpText} />
        </>
      }
      cardSize='small'
    />
  );
};
