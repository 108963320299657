import { useCallback, useRef } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { useGetConditionValues, useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import { Button, Form, NextPageInstructions } from '@ecp/features/sales/shared/components';
import { useForm } from '@ecp/features/sales/shared/store';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

import { HomeBasicFormQuestions } from '../../formBody';
import { useStyles } from './HomeBasicForm.styles';

export interface HomeBasicProps {
  onNext: () => Promise<void>;
}

export const HomeBasicForm: React.FC<HomeBasicProps> = (props) => {
  const { onNext } = props;
  const { classes } = useStyles();
  const getFields = useGetFields();
  const getInitValues = useGetInitValues();
  const conditions = useGetConditionValues();

  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    conditions: conditions(),
  });

  const handleSubmit = useCallback(async () => {
    if (validateForm().isValid) {
      await patchFormValues();
      trackSapiAnalyticsEvent({
        element: 'choice.homeBasicPage.saveAndContinueButton',
        event: 'click',
        eventDetail: 'true',
      });
      await onNext();
    }
  }, [validateForm, patchFormValues, onNext]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container>
          <HomeBasicFormQuestions />
          <Grid item xs={12}>
            <NextPageInstructions divider>
              Thanks! Next, we’ll get details on the outside of the home.
            </NextPageInstructions>
          </Grid>
          <Grid container item xs={12}>
            <Button
              data-testid='HomeBasicSubmit'
              variant='primary'
              onClick={handleSubmit}
              isProcessing={isPatchFormInProgress}
              className={classes.next}
              trackingName={GoogleAnalyticsLabels.CONTINUE}
              trackingLabel='home_basic_continue'
            >
              Save & continue
            </Button>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};
