import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ErrorNotFoundPage' })((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      maxHeight: 450,
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: 600,
    },
  },
  containerWrapper: {
    margin: '145px auto',
    padding: '0px 30px',
    maxWidth: 1144,
    [theme.breakpoints.down('lg')]: {
      marginTop: 50,
      minHeight: 450,
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      padding: '0px 15px',
    },
  },
  container: {
    maxWidth: 550,
    '& .MuiFab-label': {
      fontSize: 16,
    },
  },
  imageContainer: {
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      order: 1,
    },
  },
  headerContainer: {
    [theme.breakpoints.down('lg')]: {
      order: 2,
      marginTop: 0,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  divider: {
    marginTop: 20,
    marginBottom: 30,
    color: theme.palette.other.divider,
    [theme.breakpoints.down('md')]: {
      marginBottom: 20,
    },
  },
  errorImage: {
    height: 'auto',
    width: '100%',
    maxWidth: 530,
  },
  pageTitle: {
    ...theme.typography.h1,
    paddingTop: 35,
    paddingBottom: 10,
    textAlign: 'left',
    [theme.breakpoints.down('lg')]: {
      paddingTop: 0,
      textAlign: 'center',
    },
  },
  homepageButton: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));
