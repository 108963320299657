import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'Main' })((theme) => ({
  ...theme.mixins.formAll,
  groupLabel: {
    ...theme.typography.body3Bold,
    marginBottom: 20,
  },
  next: {
    ...theme.mixins.formAll.next,
    marginTop: 0,
  },
}));
