export const costcoWebsite = 'https://www.costco.com/';
export const webCookiePolicy = 'https://support.google.com/analytics/answer/6004245?hl=en';
export const NJAutoRights =
  'http://www.state.nj.us/dobi/division_consumers/insurance/autorights09.html';
export const NJBuyersGuide = 'http://www.state.nj.us/dobi/division_consumers/pdf/autoguide02.pdf';
export const MAWaysToSavePDF = 'https://www.mass.gov/doc/waystosavepdf/download';
export const privacyPolicy = 'https://go.midvaleinsurance.com/privacy-policy/';
export const telematicsConsent = 'https://www.gminsurance.com/connected-vehicle';
export const equifax = 'https://www.equifax.com/';
export const homesiteWebsite = 'https://www.homesite.com/';
export const homesiteCPA = 'https://go.homesite.com/cpa/';
export const amfamCostcoLegalPolicy = 'https://www.connectbyamfam.com/Costco/legal/privacy';
export const amfamConnectLegalPolicy =
  'https://www.connectbyamfam.com/legal/privacy/privacy-policy/';
export const insuranceCreditScore =
  'https://go.homesite.com/privacy-policy.html#insuranceCreditScore';
export const MNSurchargeDisclosure = 'https://connectbyamfam.com/documents/mn_rating.pdf';
export const CAAutoDiscountsInformation =
  'https://connectbyamfam.com/documents/auto/AICA07%20(009).pdf';
export const homesitePrivatePolicy = 'https://go.homesite.com/privacy_policy';
