import { useCallback, useMemo } from 'react';

import { Collapse, Divider, Grid, Step, StepLabel } from '@mui/material';

import {
  getAggregatePathTrackingStatus,
  getNavTracking,
  getPageFlowPaths,
  getPageFlowReferencePartialPaths,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { DeleteProductDialog } from '../DeleteProductDialog';
import { useStyles } from '../NavbarDrawer';
import { getNavbarStatus } from '../navigationUtil';
import { ProductStepper } from '../ProductStepper';
import { StepIcon } from '../StepIcon';
import metadata from './metadata';
import type { ProductNavbarProps } from './types';

interface Props extends Omit<ProductNavbarProps, 'productPageFlows'> {
  productPageFlow: ProductNavbarProps['productPageFlows'][number];
  stepNumber: number;
  onLabelClick?: (index: number, path: string) => void | undefined;
  expanded: boolean;
  isLabelClickable: (index: number) => boolean;
}

export const ProductNavbarStep: React.FC<Props> = (props) => {
  const {
    jumpInNavbar,
    pagePath,
    productPageFlow,
    stepNumber,
    onLabelClick,
    expanded,
    isLabelClickable,
  } = props;
  const { classes, cx } = useStyles();
  const navTracking = useSelector(getNavTracking);

  const handleClick = useCallback(
    () => onLabelClick && onLabelClick(stepNumber - 1, productPageFlow.value?.pageFlows[0].path),
    [onLabelClick, productPageFlow.value?.pageFlows, stepNumber],
  );

  const navTrackingStatus = useMemo(
    () =>
      getAggregatePathTrackingStatus(
        navTracking,
        getPageFlowPaths(productPageFlow),
        getPageFlowReferencePartialPaths(productPageFlow),
      ),
    [navTracking, productPageFlow],
  );

  return (
    <div>
      {productPageFlow.value?.menuItems?.map((menuItem) => {
        const { active, completed } = getNavbarStatus(navTrackingStatus);

        const step = (
          <div key={menuItem.text}>
            <Step completed={completed}>
              <StepLabel
                classes={{
                  label: cx(active ? classes.activeStep : undefined),
                }}
              >
                <Grid container justifyContent='space-between' className={classes.cardPadding}>
                  <Grid
                    item
                    aria-disabled={!completed || !isLabelClickable(stepNumber - 1)}
                    className={cx(
                      classes.columnLeft,
                      classes.labelText,
                      active && classes.activeStep,
                    )}
                    onClick={handleClick}
                  >
                    <div className={classes.iconContainer}>
                      <StepIcon active={active} completed={completed} index={stepNumber} />
                      <p
                        className={cx(
                          classes.textPadding,
                          classes.labelText,
                          isLabelClickable(stepNumber - 1) && classes.labelLink,
                        )}
                      >
                        {menuItem.text}
                      </p>
                    </div>
                  </Grid>
                  <Grid item>
                    {!metadata.hideAddOrRemoveProductButton && (
                      <DeleteProductDialog
                        product={menuItem.text}
                        pagePath={pagePath}
                        productFlow={productPageFlow}
                      />
                    )}
                  </Grid>
                </Grid>
              </StepLabel>

              <Collapse in={expanded && !!menuItem.subMenuItems}>
                <Divider aria-hidden='true' className={classes.divider} />
                <div className={classes.productStepPadding}>
                  <ProductStepper
                    pagePath={pagePath}
                    jumpInNavbar={jumpInNavbar}
                    productSteps={menuItem.subMenuItems}
                    productPageFlow={productPageFlow}
                  />
                </div>
              </Collapse>
            </Step>
          </div>
        );

        return step;
      })}
    </div>
  );
};
