import { PhoneLink } from '@ecp/components';
import type { StateCompanyName } from '@ecp/features/sales/shared/types';
import { partner } from '@ecp/partners';

import {
  GeneralPaymentConfirmationDisclaimer,
  GeneralPaymentDisclaimer,
  NachaAuthorizationAgreement,
} from './GeneralCheckoutDisclaimers';

export const RecurringPaymentCompanyName: { [productKey: string]: StateCompanyName } = {};

const ProgressiveAuthorizationAgreement = (): JSX.Element => (
  <>
    <p>
      I authorize Homesite Group, Incorporated to initiate the payment listed on this page. I
      understand that payment will be submitted once I select "Purchase Policy". If you have
      questions regarding this payment, please contact {partner.shared.partnerDisplayName} at{' '}
      <PhoneLink withUnderlinedLinkStyle number={partner.shared.servicePhoneNumber} />. Agents are
      available {partner.shared.servicePhoneHours}.
    </p>
    <br />
  </>
);

export const RecurringPaymentMetadata: Record<
  string,
  Record<
    string,
    | ((name: string, state: string) => JSX.Element)
    | ((name?: string, state?: string) => JSX.Element)
  >
> = {
  EFT: {
    'homesite.home': (...[, state]: [string, string]): JSX.Element => {
      let fraudText = '';
      if (state === 'VA') {
        fraudText =
          'Your premium and eligibility are based on information provided. It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.';
      }

      return (
        <>
          <NachaAuthorizationAgreement
            product='home'
            defaultComponent={<ProgressiveAuthorizationAgreement />}
          />
          <GeneralPaymentDisclaimer fraudText={fraudText} />
        </>
      );
    },
    'homesite.renters': (): JSX.Element => (
      <NachaAuthorizationAgreement
        product='renters'
        defaultComponent={<GeneralPaymentConfirmationDisclaimer />}
      />
    ),
  },
  CreditCard: {
    'homesite.home': (name: string, state: string): JSX.Element => {
      let fraudText = '';
      if (state === 'VA') {
        fraudText =
          'Your premium and eligibility are based on information provided. It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.';
      }

      return <GeneralPaymentDisclaimer fraudText={fraudText} />;
    },
    'homesite.renters': (): JSX.Element => <GeneralPaymentConfirmationDisclaimer />,
  },
};
