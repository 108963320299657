import { Fragment, useCallback, useMemo, useRef, useState } from 'react';

import { Divider, Grid } from '@mui/material';

import { useEvent } from '@ecp/utils/react';

import { useAddFields, useGetConditionValues, useGetInitValues } from '@ecp/features/sales/form';
import { Dialog, Form } from '@ecp/features/sales/shared/components';
import { getOfferProductsSelected, useForm } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { getProductDisplayNameFromProduct } from '@ecp/features/shared/product';
import type { Field } from '@ecp/types';

import { getAnyProductHasCheckboxAcknowledgements } from '../../../state/acknowledgements';
import { ContactUsDialog } from '../ContactUsDialog';
import { IAgreeCheckbox } from './IAgreeCheckbox';
import { useStyles } from './IAgreeDialog.styles';
import { getIAgreeDialogParams, useGetCheckboxIAgreeFields } from './utils';

export interface Props {
  buttonPlacement?: 'left' | 'right';
  contactUsOpen: boolean;
  iAgreeOpen: boolean;
  isProcessing: boolean;
  handleIAgreeClick?(): void;
  handleHelpButtonClick?(): void;
  onIAgreeClose(): void;
  handleBackToAgreeClick?(): void;
  onContactUsClose(): void;
}

export const IAgreeDialog: React.FC<Props> = (props) => {
  const {
    handleIAgreeClick,
    handleBackToAgreeClick,
    contactUsOpen,
    iAgreeOpen,
    isProcessing,
    handleHelpButtonClick,
    onIAgreeClose,
    onContactUsClose,
  } = props;
  const { classes, cx } = useStyles();
  const [showCarrierNumber, setShowCarrierNumber] = useState(false);
  const handleCallUsClick = useCallback((): void => {
    setShowCarrierNumber(true);
  }, []);
  const handleIAgreeButtonClick = useEvent(async () => {
    anyProductHasCheckboxAcknowledgements
      ? validateForm().isValid && handleIAgreeClick?.()
      : handleIAgreeClick?.();
  });
  const iAgreeDialogParams = getIAgreeDialogParams(
    contactUsOpen,
    showCarrierNumber,
    handleCallUsClick,
    onIAgreeClose,
    onContactUsClose,
    handleBackToAgreeClick,
    handleIAgreeButtonClick,
    handleHelpButtonClick,
  );
  const offerProductsSelected = useSelector(getOfferProductsSelected);
  const anyProductHasCheckboxAcknowledgements = useSelector(
    getAnyProductHasCheckboxAcknowledgements,
  );
  const initValues = useRef(useGetInitValues()());
  const checkBoxIAgreeAcknowledgementData = useGetCheckboxIAgreeFields();
  const allCheckboxFields = offerProductsSelected.reduce((acc, product) => {
    acc = { ...acc, ...checkBoxIAgreeAcknowledgementData[product].fields };

    return acc;
  }, {} as Record<string, Field>);
  useAddFields({ ...allCheckboxFields });
  const getConditions = useGetConditionValues();
  const { validateForm } = useForm({
    initValues,
    fields: allCheckboxFields,
    conditions: getConditions(),
  });

  const content = useMemo(
    () =>
      offerProductsSelected.map((product) => {
        return (
          <Fragment key={product}>
            {offerProductsSelected.length > 1 && (
              <h2 className={classes.productHeaderMargin}>
                {getProductDisplayNameFromProduct(product)}
              </h2>
            )}
            <ul
              className={
                anyProductHasCheckboxAcknowledgements
                  ? classes.checkboxItemsPadding
                  : classes.listItemsPadding
              }
            >
              {checkBoxIAgreeAcknowledgementData[product].acknowledgements &&
                Object.keys(checkBoxIAgreeAcknowledgementData[product].acknowledgements).map(
                  (key) =>
                    checkBoxIAgreeAcknowledgementData[product].fields[key] ? (
                      <IAgreeCheckbox
                        acknowledgementText={
                          checkBoxIAgreeAcknowledgementData[product].acknowledgements[key]
                            .acknowledgementText
                        }
                        field={checkBoxIAgreeAcknowledgementData[product].fields[key]}
                        key={key}
                      />
                    ) : (
                      <li key={key}>
                        {
                          checkBoxIAgreeAcknowledgementData[product].acknowledgements[key]
                            .acknowledgementText
                        }
                      </li>
                    ),
                )}
            </ul>
          </Fragment>
        );
      }),
    [
      checkBoxIAgreeAcknowledgementData,
      anyProductHasCheckboxAcknowledgements,
      classes.checkboxItemsPadding,
      classes.listItemsPadding,
      classes.productHeaderMargin,
      offerProductsSelected,
    ],
  );

  const iAgreeTitle = (
    <>
      <p
        className={
          anyProductHasCheckboxAcknowledgements
            ? classes.fullscreenDialogTitle
            : classes.dialogTitle
        }
      >
        Review and agree to the following statements to continue your purchase.
      </p>
      <p
        className={cx(
          classes.title,
          anyProductHasCheckboxAcknowledgements
            ? classes.checkboxDialogTitle
            : classes.bulletDialogTitle,
        )}
      >
        If you can't agree to all statements, please call and we'll help you with your quote.
      </p>
    </>
  );

  const fullScreenContent = (
    <Grid container justifyContent='center'>
      <Grid item xs={12} className={classes.dialogContainer}>
        {iAgreeTitle}
        <Divider aria-hidden='true' />
        {content}
      </Grid>
    </Grid>
  );

  return (
    <Form>
      <Dialog
        actionButtonLabel={iAgreeDialogParams.actionButtonLabel}
        textButtonLabel={iAgreeDialogParams.textButtonLabel}
        textButtonVariant='iconTextMedium'
        titleText={
          anyProductHasCheckboxAcknowledgements
            ? ''
            : iAgreeOpen
            ? iAgreeTitle
            : 'Our agents are here if you need us.'
        }
        actionButtonOnClick={iAgreeDialogParams.handleActionButtonClick}
        textButtonOnClick={iAgreeDialogParams.handleTextButtonClick}
        open={contactUsOpen || iAgreeOpen}
        showAppBar={false}
        fullScreen={anyProductHasCheckboxAcknowledgements}
        isProcessing={isProcessing}
        onClose={iAgreeDialogParams.onClose}
        className={anyProductHasCheckboxAcknowledgements ? classes.dialogRoot : ''}
        trackingDimension={iAgreeDialogParams.trackingDimension}
        trackingNameButton={iAgreeDialogParams.trackingNameButton}
        trackingLabelButton={iAgreeDialogParams.trackingLabelButton}
        trackingNameText={iAgreeDialogParams.trackingNameText}
        trackingLabelText={iAgreeDialogParams.trackingLabelText}
        analyticsElementRender={iAgreeDialogParams.analyticsElementRender}
        analyticsElementButton={iAgreeDialogParams.analyticsElementButton}
        analyticsElementText={iAgreeDialogParams.analyticsElementText}
        hideTitleCloseButton
        showFullScreenActionButtons={anyProductHasCheckboxAcknowledgements}
      >
        {iAgreeOpen ? (
          anyProductHasCheckboxAcknowledgements ? (
            fullScreenContent
          ) : (
            content
          )
        ) : (
          <ContactUsDialog />
        )}
      </Dialog>
    </Form>
  );
};
