import { keyframes, makeStyles } from '@ecp/themes/base';

const fadeIn = keyframes({
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
});

const fadeOut = keyframes({
  '0%': {
    opacity: 1,
  },
  '100%': {
    opacity: 0,
  },
});

const loading = keyframes({
  from: {
    right: '100%',
  },
  to: {
    right: 0,
  },
});

export const useStyles = makeStyles({ name: 'GlobalLoading' })((theme) => ({
  ...theme.mixins.formAll,
  title: {
    textAlign: 'center',
    marginTop: 60,
    marginBottom: 40,

    [theme.breakpoints.down('md')]: {
      marginTop: 24,
    },
  },
  nextText: {
    ...theme.typography.h3,
    textAlign: 'center',
  },
  loadingContainer: {
    width: 709,
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: 328,
    },
  },
  loadingIndicatorWrap: {
    height: 89.2,
    position: 'relative',
    margin: '0px auto 35px',
    '& > * ': {
      position: 'relative',
      zIndex: 2,
    },
    '&:after': {
      content: `" "`,
      position: 'absolute',
      top: 1,
      bottom: 0,
      left: 1,
      background: theme.palette.primary.main,
      animation: [`${loading} 12.5s linear forwards`],
      zIndex: 1,
    },
    '& .loadingIndicatorDesktop': {
      height: 89.2,
    },
    '& .zone01': {
      fill: theme.palette.text.primary,
    },
    '& .zone03': {
      fill: theme.palette.other.body,
    },
    '& .zone04': {
      fill: theme.palette.other.body,
    },
    '& .steps': {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        width: 660,
      },
      flexBasis: '33%',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        position: 'relative',
      },
    },
    '& .step': {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      opacity: 0,
      marginLeft: -10,
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        position: 'absolute',
        width: '100%',
        margin: 0,
      },
    },
    '& .stepHeader': {
      ...theme.typography.body3Bold,
      marginTop: 10,
      marginBottom: 5,
      textAlign: 'center',
    },
    '& .step1': {
      opacity: 0,
      animation: [`${fadeIn} 2s linear 1s forwards`],
    },
    '& .step2': {
      animation: [`${fadeIn} 2s linear 5s forwards`],
    },
    '& .step3': {
      animation: [`${fadeIn} 2s linear 9.5s forwards`],
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 35,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 575,
      height: 65,
      '& .loadingIndicatorMobile': {
        marginBottom: 15,
      },
      '& .step1': {
        opacity: 0,
        animation: [`${fadeIn} 1s linear .5s forwards, ${fadeOut} 1s linear 4s forwards`],
      },
      '& .step2': {
        animation: [`${fadeIn} 1s linear 5s forwards, ${fadeOut} 1s linear 9s forwards`],
      },
      '& .step3': {
        animation: [`${fadeIn} 1s linear 10s forwards`],
      },
    },
  },
}));
