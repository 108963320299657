import {
  describeOfferSummary,
  getIsProductOfferLocked,
  getOffersForSelectedLob,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { useQuotesSelection } from '@ecp/features/sales/shell';
import { isProductAuto } from '@ecp/features/shared/product';

export const useIsOfferLocked = (): boolean => {
  const offersForSelectedLob = useSelector(getOffersForSelectedLob);
  const { lineOfBusinessUserSelection } = useQuotesSelection();
  const { offerProductsSelected } = describeOfferSummary(
    offersForSelectedLob,
    lineOfBusinessUserSelection,
  );

  const lockedAutoProduct = useSelector((state: RootStore) =>
    offerProductsSelected.filter(
      (product) => isProductAuto(product) && getIsProductOfferLocked(state, product),
    ),
  );

  const isOfferLocked = lockedAutoProduct && lockedAutoProduct.length > 0;

  return isOfferLocked;
};
