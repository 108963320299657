import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

export const NonCombustibleUseWithinThirtyFeetQuestion: React.FC<QuestionProps> = (props) => {
  const {
    label = 'Do you only use non-combustible structures or materials within 30 feet of your home?',
    trackingName = 'cleared_non_combustible_objects_within_30feet',
  } = props;
  const nonCombustibleUseWithinThirtyFeet = useField('property.thirtyFootNonCombustibleInd');

  if (!nonCombustibleUseWithinThirtyFeet.exists) return null;

  return (
    <RadioGroupWithOptions
      {...nonCombustibleUseWithinThirtyFeet.props}
      id='nonCombustibleUseWithinThirtyFeet'
      label={label}
      variant='yesNoButton'
      trackingName={trackingName}
    />
  );
};
