import { LineOfBusiness } from '@ecp/features/shared/product';

interface OptionItem {
  label: string;
  value: string;
}

export interface Metadata {
  label: string;
  options: OptionItem[];
}

const options: OptionItem[] = [
  {
    label: 'Home',
    value: LineOfBusiness.HOME,
  },
  {
    label: 'Renters',
    value: LineOfBusiness.RENTERS,
  },
];

const metadata: Metadata = {
  label: 'What kind of quote?',
  options,
};

export default metadata;
