import { cloneElement, useCallback, useState } from 'react';

import type { FormControlLabelProps } from '@mui/material';
import { FormControlLabel, Grid } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';

import { GridItem } from '@ecp/components';
import { Button, SingleLineDetail } from '@ecp/features/sales/shared/components';
import type { Product, ProductName } from '@ecp/features/shared/product';
import type { Option } from '@ecp/types';

import { RecurringPaymentInfoDialog } from '../RecurringPaymentInfoDialog';
import { CustomDownPaymentAmount } from './CustomDownPaymentAmount';
import metadata from './metadata';
import { useStyles } from './PaymentOption.styles';
import type { GoogleAnalytics, PaymentFieldOption } from './PaymentTerm';

export interface PaymentOptionProps extends Omit<FormControlLabelProps, 'label'> {
  product: Product;
  paymentType: string;
  option: Option;
  policyDuration?: string;
  downPaymentAmt?: string;
  numOfInstallments?: string;
  installmentAmt?: string;
  totalOfInstallments?: string;
  totalAmt?: string;
  googleAnalytics?: GoogleAnalytics;
  firstInstallmentAmount?: string;
  firstInstallmentNumberOfMonths?: string;
  totalPremium?: string;
  installmentFeeTotal?: string;
  installmentFeePerPayment?: string;
  paymentOptions?: PaymentFieldOption[];
  coverageType?: ProductName;
  isCustomPay?: boolean;
}

export const PaymentOption: React.FC<PaymentOptionProps> = (props) => {
  const { classes, cx } = useStyles(props);
  const {
    product,
    paymentType,
    checked,
    control,
    disabled,
    option,
    policyDuration,
    downPaymentAmt,
    numOfInstallments,
    installmentAmt,
    totalOfInstallments,
    totalAmt,
    googleAnalytics,
    firstInstallmentAmount,
    firstInstallmentNumberOfMonths,
    totalPremium,
    installmentFeeTotal,
    installmentFeePerPayment,
    paymentOptions,
    coverageType,
    isCustomPay,
    ...passthru
  } = props;
  const [open, setOpen] = useState(false);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const makeHeading = (): string => {
    if (paymentOptions?.length === 1 && option.value === 'Full') {
      return 'Pay in full';
    }
    if (option.value === 'Full') {
      return 'Pay in full and avoid fees';
    } else if (option.value === 'Custom') {
      return 'Custom amount';
    } else {
      return 'Make payments';
    }
  };

  const isCustomPayChecked = option.value === 'Custom' && checked;
  const policyDurationDisplayText =
    policyDuration === '6' ? '6 month policy total' : 'Yearly policy total';
  const singleLineDetailVariant = isCustomPay ? 'payment' : '';

  const makeDescription = (): React.ReactNode => {
    const installmentFeeTotalExists = installmentFeeTotal && installmentFeeTotal !== '0.0';
    switch (option.value) {
      case 'Full':
        return (
          <>
            <SingleLineDetail
              text={policyDurationDisplayText}
              value={parseDollar(totalAmt)}
              variant={singleLineDetailVariant}
              classes={{
                leftWrapper: classes.leftWrapper,
              }}
            />
            {!metadata.hidePayinFullDisclosure && (
              <p className={classes.paymentDisclosureText}>
                By selecting “Pay in full” I agree to the&nbsp;
                <Button
                  variant='textSmall'
                  onClick={onOpen}
                  trackingName={googleAnalytics?.trackingNamePaymentInfo}
                  trackingLabel={googleAnalytics?.trackingLabelPaymentInfo}
                >
                  payment information disclosure.
                </Button>
              </p>
            )}
            <RecurringPaymentInfoDialog
              product={product}
              paymentType={paymentType}
              open={open}
              onClose={handleClose}
              trackingNameClose={googleAnalytics?.trackingNamePaymentInfoClose}
              trackingLabelClose={googleAnalytics?.trackingLabelPaymentInfoClose}
              trackingNameX={googleAnalytics?.trackingNamePaymentInfoX}
              trackingLabelX={googleAnalytics?.trackingLabelPaymentInfoX}
            />
          </>
        );
      case 'One Month':
      case 'Two Months':
      case 'Four Months':
      case 'Five Months':
      case 'Six Months':
      case 'Ten Months':
      case 'Eleven Months':
      case 'Twelve Months':
        return (
          <>
            <SingleLineDetail
              text='Due today'
              value={parseDollar(downPaymentAmt)}
              variant={singleLineDetailVariant}
              classes={{
                root: classes.singleLineDetailRoot,
                leftWrapper: classes.leftWrapper,
              }}
            />
            {firstInstallmentNumberOfMonths && firstInstallmentAmount && (
              <SingleLineDetail
                text={`${firstInstallmentNumberOfMonths} months down`}
                value={parseDollar(firstInstallmentAmount)}
                variant=''
                classes={{
                  root: classes.singleLineDetailRoot,

                  leftWrapper: classes.leftWrapper,
                }}
              />
            )}
            {!isCustomPay && (
              <SingleLineDetail
                text={`+${numOfInstallments} payments of ${parseDollar(installmentAmt)}`}
                value={parseDollar(totalOfInstallments)}
                variant=''
                classes={{
                  root: classes.singleLineDetailRoot,

                  leftWrapper: classes.leftWrapper,
                }}
              />
            )}
            {firstInstallmentNumberOfMonths && policyDuration && (
              <SingleLineDetail
                text={`${policyDuration} month policy premium`}
                value={`${parseDollar(totalPremium)}`}
                variant=''
                classes={{
                  root: classes.singleLineDetailRoot,

                  leftWrapper: classes.leftWrapper,
                }}
              />
            )}
            {installmentFeeTotalExists && (
              <SingleLineDetail
                text='Installment fees'
                value={parseDollar(installmentFeeTotal)}
                classes={{
                  leftWrapper: classes.leftWrapper,
                }}
              />
            )}
            {installmentFeeTotalExists && installmentFeePerPayment && (
              <p className={classes.convienceFeeText}>
                Each payment includes a {parseDollar(installmentFeePerPayment)} installment fee
              </p>
            )}
            {isCustomPay && (
              <>
                <div className={classes.horizontalDividerCustomPay} />
                <SingleLineDetail
                  text='Initial payment'
                  value={parseDollar(downPaymentAmt)}
                  variant='payment'
                  classes={{
                    root: classes.singleLineDetailRoot,
                    leftWrapper: classes.leftWrapper,
                  }}
                />
              </>
            )}
            <div
              className={
                !isCustomPay ? classes.horizontalDivider : classes.horizontalDividerCustomPay
              }
            />
            <SingleLineDetail
              text={`${policyDuration} month policy total`}
              value={parseDollar(totalAmt)}
              variant={singleLineDetailVariant}
              classes={{
                leftWrapper: classes.leftWrapper,
              }}
            />
            <p className={classes.paymentDisclosureText}>
              By selecting “Make payments” I agree to the&nbsp;
              <Button
                variant='textSmall'
                onClick={onOpen}
                trackingName={googleAnalytics?.trackingNamePaymentInfo}
                trackingLabel={googleAnalytics?.trackingLabelPaymentInfo}
              >
                payment information disclosure.
              </Button>
            </p>
            <RecurringPaymentInfoDialog
              product={product}
              paymentType={paymentType}
              open={open}
              onClose={handleClose}
              trackingNameClose={googleAnalytics?.trackingNamePaymentInfoClose}
              trackingLabelClose={googleAnalytics?.trackingLabelPaymentInfoClose}
              trackingNameX={googleAnalytics?.trackingNamePaymentInfoX}
              trackingLabelX={googleAnalytics?.trackingLabelPaymentInfoX}
            />
          </>
        );
      case 'Custom':
        return (
          <>
            <Grid container>
              <GridItem xs={12}>
                <p className={classes.customAmountText}>
                  Pay a custom amount to lower each month's payment.
                </p>
              </GridItem>
              {isCustomPayChecked && (
                <GridItem xs={12}>
                  <CustomDownPaymentAmount product={product} coverageType={coverageType} />
                </GridItem>
              )}
              <GridItem xs={12}>
                <p className={classes.paymentDisclosureText}>
                  The remaining balance will be billed over the remainder of the policy term.
                </p>
              </GridItem>
            </Grid>
            <SingleLineDetail
              text={`${policyDuration} Month policy total`}
              value={parseDollar(totalAmt)}
              variant='payment'
              classes={{
                leftWrapper: classes.leftWrapper,
              }}
            />
            <p className={classes.paymentDisclosureText}>
              By selecting "Custom Amount" I agree to the&nbsp;
              <Button
                variant='textSmall'
                onClick={onOpen}
                trackingName={googleAnalytics?.trackingNamePaymentInfo}
                trackingLabel={googleAnalytics?.trackingLabelPaymentInfo}
              >
                payment information disclosure.
              </Button>
            </p>
            <RecurringPaymentInfoDialog
              product={product}
              paymentType={paymentType}
              open={open}
              onClose={handleClose}
              trackingNameClose={googleAnalytics?.trackingNamePaymentInfoClose}
              trackingLabelClose={googleAnalytics?.trackingLabelPaymentInfoClose}
              trackingNameX={googleAnalytics?.trackingNamePaymentInfoX}
              trackingLabelX={googleAnalytics?.trackingLabelPaymentInfoX}
            />
          </>
        );
      default:
        return '';
    }
  };

  return (
    <div className={cx(!isCustomPay && classes.paymentOptionBackground, classes.paymentOption)}>
      <p className={!isCustomPay ? classes.detailsHeading : classes.detailsHeadingCustomPay}>
        <FormControlLabel
          {...passthru}
          className={
            paymentOptions?.length === 1
              ? cx(classes.disableButton, classes.classicLabel)
              : classes.classicLabel
          }
          checked={checked}
          label={makeHeading()}
          control={cloneElement(control)}
          disabled={disabled}
          key={option.value}
          value={option.value}
        />
      </p>
      <div
        className={
          paymentOptions?.length === 1
            ? classes.singlePaymentOptionMargin
            : classes.paymentOptionsMargin
        }
      >
        {makeDescription()}
      </div>
    </div>
  );
};
