import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({
  name: 'LogoSpinnerWrapperStyles',
})((theme) => ({
  root: {
    ...theme.mixins.pageRoot,
    flexGrow: 1,
    position: 'relative',
  },
}));
