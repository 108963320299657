import type { CarrierDisplayName, CarrierName } from './types';

type CarrierNameSupported = 'connect' | 'midvale' | 'amfam-adv' | 'connect-boost' | 'homesite';
type CarrierMetadata = Record<
  CarrierName,
  {
    name: CarrierNameSupported;
    displayName: CarrierDisplayName;
    extendedDisplayName: string;
  }
>;

const carrierMetadataNew: Pick<CarrierMetadata, CarrierNameSupported> = {
  connect: {
    name: 'connect',
    displayName: 'CONNECT',
    extendedDisplayName: 'Connect powered by American Family Insurance',
  },
  midvale: {
    name: 'midvale',
    displayName: 'Midvale',
    extendedDisplayName: 'Midvale Home & Auto',
  },
  'amfam-adv': {
    name: 'amfam-adv',
    displayName: 'American Family Insurance Company',
    extendedDisplayName: 'American Family Insurance Company',
  },
  'connect-boost': {
    name: 'connect-boost',
    displayName: 'CONNECT',
    extendedDisplayName: 'Connect powered by American Family Insurance',
  },
  homesite: {
    name: 'homesite',
    displayName: 'Homesite',
    extendedDisplayName: 'Homesite Insurance',
  },
} as const;

/**
 * Replace with carrierMetadataNew after completion of https://theexperimentationlab.atlassian.net/wiki/spaces/SAPI/pages/1791132917/Rectify+product+names+for+DIG+CHOICE
 */
const carrierMetadata: CarrierMetadata = {
  ...carrierMetadataNew,
  amfam: carrierMetadataNew.midvale,
  connect: carrierMetadataNew.connect,
  homesite: carrierMetadataNew.homesite,
  'connect-boost': carrierMetadataNew.connect,
} as const;

/**
 * @deprecated to be removed https://theexperimentationlab.atlassian.net/wiki/spaces/SAPI/pages/1791132917/Rectify+product+names+for+DIG+CHOICE
 * @param carrierName - deprecated carrier name
 * @returns actual carrier name
 */
export const getCarrierActualNameFromDeprecatedName = (
  carrierName?: CarrierName,
): CarrierNameSupported | undefined =>
  carrierName ? carrierMetadata[carrierName].name : undefined;

export const getCarrierDisplayName = (carrierName: CarrierName): CarrierDisplayName =>
  carrierMetadata[carrierName].displayName;

export const getCarrierExtendedDisplayName = (carrierName: CarrierName): string =>
  carrierMetadata[carrierName].extendedDisplayName;
