import { datadogLog } from '@ecp/utils/logger';
import { Headers } from '@ecp/utils/network';

import type { SapiTarget } from '../../api';
import { setSapiTarget } from '../../api';
import { wrapThunkActionWithErrHandler } from '../../util/wrapThunkActionWithErrHandler';
import { setInquiryApiGetSuccess } from '../actions';
import { getInquiry } from '../api';
import { getDalSessionId } from '../selectors';

type FetchInquiry = { dalSessionId?: string };

export const fetchInquiry = wrapThunkActionWithErrHandler<FetchInquiry, void>(
  ({ dalSessionId }) =>
    async (dispatch, getState) => {
      const dalSessionIdValue = dalSessionId ?? getDalSessionId(getState());

      if (!dalSessionIdValue) {
        return;
      }

      const response = await getInquiry({ dalSessionId: dalSessionIdValue });

      const hasSapiTarget = Headers.SAPI_TARGET in response.headers;
      if (!hasSapiTarget) {
        datadogLog({
          logType: 'error',
          message: 'sapi target not found in response headers for fetchInquiry',
          context: {
            logOrigin: 'libs/features/sales/shared/store/lib/src/inquiry/thunks/fetchInquiry.ts',
            functionOrigin: 'fetchInquiry',
          },
        });

        throw new Error('sapi target not found in response headers for fetchInquiry');
      }
      const sapiTarget = response.headers[Headers.SAPI_TARGET] as SapiTarget;
      dispatch(setSapiTarget(sapiTarget));

      dispatch(setInquiryApiGetSuccess(response.payload));
    },
  'fetchInquiry',
);
