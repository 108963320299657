import { useEffect } from 'react';

export const useRealignLogoSpinner = (ensureSessionCompleted: boolean): void => {
  useEffect(() => {
    const main = document.getElementsByTagName('main')?.[0];
    const loader = document.getElementById('loader');

    if (!main || !loader) return;

    const mainBoundingClientRect = main.getBoundingClientRect();
    const mainMidPoint = mainBoundingClientRect.top + mainBoundingClientRect.height / 2;

    loader.style.top = `${mainMidPoint}px`;
  }, [ensureSessionCompleted]);
};
