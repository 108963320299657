import { useCallback } from 'react';

import { Typography } from '@mui/material';

import { NumberFormat } from '@ecp/components';
import { GridItem } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { DatePicker, RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import type { AnswerValue } from '@ecp/types';

export const NewDwellingUnderConstructionQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const useHomePropertyConstructionField = useFieldWithPrefix(
    'property.construction',
    'property.construction',
  );
  const newDwellingUnderConstruction = useHomePropertyConstructionField('underConstructionInd');
  const aboveGroundConstructionDate = useHomePropertyConstructionField('constructionStartDate');
  const renovationInLast15Years = useHomePropertyField('renovation.renovationLast15Years');
  const estimatedCompletionDate = useHomePropertyConstructionField(
    'constructionEstimatedCompletionDate',
  );
  const actualCompletionDate = useHomePropertyConstructionField('constructionActualCompletionDate');

  const estimatedCost = useHomePropertyConstructionField('constructionCost');

  const {
    label = newDwellingUnderConstruction.question?.title,
    trackingName = 'new_dwelling_under_construction_selection',
  } = props;

  const aboveGroundLabel = aboveGroundConstructionDate.question?.title;
  const aboveGroundTrackingName = 'above_ground_construction_date_question';
  const estimatedCompletionDatelabel = estimatedCompletionDate.question?.title;
  const estimatedCompletionDateTrackingName = 'estimated_completion_date_question';
  const actualCompletionDatelabel = actualCompletionDate.question?.title;
  const actualCompletionDateTrackingName = 'actual_completion_date_question';
  const estimatedCostLabel = estimatedCost.question?.title;
  const estimatedCostTrackingName = 'estimated_cost_question';

  useAddFields({
    newDwellingUnderConstruction,
    aboveGroundConstructionDate,
    actualCompletionDate,
    estimatedCompletionDate,
    estimatedCost,
  });

  const displayUnderConstructionQuestion =
    renovationInLast15Years.exists && !renovationInLast15Years.props.value;

  const newDwellingYes =
    newDwellingUnderConstruction.exists && newDwellingUnderConstruction.props.value;

  const displayAboveGroundConstructionDate = newDwellingYes && aboveGroundConstructionDate.exists;

  const displayEstimatedCompletionDate = newDwellingYes && estimatedCompletionDate.exists;

  const displayActualComplationDate = newDwellingYes && actualCompletionDate.exists;

  const displayEstimatedCost = newDwellingYes && estimatedCost.exists;

  // Resets values of subquestions
  const resetNewDwellingSubValues: () => void = useCallback(async () => {
    aboveGroundConstructionDate.props.actionOnComplete(null);
    estimatedCompletionDate.props.actionOnComplete(null);
    actualCompletionDate.props.actionOnComplete(null);
    estimatedCost.props.actionOnComplete(null);
  }, [aboveGroundConstructionDate, estimatedCompletionDate, actualCompletionDate, estimatedCost]);

  // resetNewDwellingSubValues if newDwellingUnderConstruction is set to false
  const handleNewDwellingChange = useCallback(
    async (value: AnswerValue) => {
      newDwellingUnderConstruction.validateUpdateAndPatch(value);
      if (!value) {
        resetNewDwellingSubValues();
      }
    },
    [newDwellingUnderConstruction, resetNewDwellingSubValues],
  );

  // Don't display any of hese questions if renovation in last 15 years, and also reset their values
  if (!displayUnderConstructionQuestion) {
    if (newDwellingUnderConstruction.props.value) {
      newDwellingUnderConstruction.validateUpdateAndPatch(false);
    }
    if (
      aboveGroundConstructionDate.props.value ||
      estimatedCompletionDate.props.value ||
      actualCompletionDate.props.value ||
      estimatedCost.props.value
    ) {
      resetNewDwellingSubValues();
    }

    return null;
  }

  return (
    <>
      <RadioGroupWithOptions
        {...newDwellingUnderConstruction.props}
        label={label}
        variant='yesNoButton'
        trackingName={trackingName}
        actionOnComplete={handleNewDwellingChange}
      />
      {displayAboveGroundConstructionDate && (
        <GridItem topSpacing='lg' xs={12}>
          <DatePicker
            {...aboveGroundConstructionDate.props}
            hidePicker
            id='AboveGroundConstructionDate'
            label={<Typography variant='body4'>{aboveGroundLabel}</Typography>}
            trackingName={aboveGroundTrackingName}
            data-testid={aboveGroundTrackingName}
          />
        </GridItem>
      )}
      {displayEstimatedCompletionDate && (
        <GridItem topSpacing='lg' xs={12}>
          <DatePicker
            {...estimatedCompletionDate.props}
            hidePicker
            id='EstimatedCompletionDate'
            label={<Typography variant='body4'>{estimatedCompletionDatelabel}</Typography>}
            trackingName={estimatedCompletionDateTrackingName}
            data-testid={estimatedCompletionDateTrackingName}
          />
        </GridItem>
      )}
      {displayActualComplationDate && (
        <GridItem topSpacing='lg' xs={12}>
          <DatePicker
            {...actualCompletionDate.props}
            hidePicker
            id='ActualCompletionDate'
            label={<Typography variant='body4'>{actualCompletionDatelabel}</Typography>}
            trackingName={actualCompletionDateTrackingName}
            data-testid={actualCompletionDateTrackingName}
          />
        </GridItem>
      )}
      {displayEstimatedCost && (
        <GridItem topSpacing='lg' xs={12}>
          <NumberFormat
            {...estimatedCost.props}
            id='MarketValue'
            groupLabel={<Typography variant='body4'>{estimatedCostLabel}</Typography>}
            prefix='$'
            thousandSeparator
            decimalScale={0}
            trackingName={estimatedCostTrackingName}
            data-testid={estimatedCostTrackingName}
          />
        </GridItem>
      )}
    </>
  );
};
