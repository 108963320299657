import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'OtherStructures' })((theme) => ({
  ...theme.mixins.formAll,
  root: {},
  yesNoButtons: {
    width: '100%',
  },
  divider: {
    margin: '20px 0px',
    color: theme.palette.other.divider,
  },
  subtitle: {
    ...theme.typography.body4,
  },
  removeStructureButton: {
    marginTop: 34,
  },
  addStructureButtons: {
    margin: '10px 0px',
    [theme.breakpoints.down('md')]: {
      margin: '0px 0px 5px',
    },
  },
}));
