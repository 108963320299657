import { getLineOfBusiness, getPrimaryInsuredStateCode } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import metadata from './metadata';
import type { BaseMetadata } from './metadata';

// TODO Extract these across the codebase into a function or create a Proxy and wrap each metadata object
export const useMetadata = (): BaseMetadata => {
  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const lob = useSelector(getLineOfBusiness);

  const result = (lob && (metadata[lob]?.stateOptions?.[stateCode] || metadata[lob])) || {};

  return result;
};
