import { createAction } from '@reduxjs/toolkit';

import type { Answers } from '@ecp/features/sales/shared/types';
import type { Questions } from '@ecp/types';

import type { PostBindResponse, PostBindSubmitError } from './types';

export const setPostBindApiGetSuccess = createAction<PostBindResponse>('@postbind/apiGetSuccess');
export const setPostBindApiPostSuccess = createAction<PostBindResponse>('@postbind/apiPostSuccess');
export const setPostBindApiPostError = createAction<PostBindSubmitError>('@postbind/apiPostError');
export const deleteKeyFromPostBindAnswers = createAction<string>(
  '@postbind/deleteKeyFromPostBindAnswers',
);
export const updatePostBindAnswers = createAction<Answers>(
  '@postbind/updateKeyFromPostBindAnswers',
);
export const updatePostBindQuestions = createAction<Questions>(
  '@postbind/updateKeyFromPostBindQuestions',
);
export const deleteKeysFromPostBindAnswers = createAction<string>(
  '@postbind/deleteKeysFromPostBindAnswers',
);

export type PostBindActions = ReturnType<
  | typeof setPostBindApiGetSuccess
  | typeof setPostBindApiPostSuccess
  | typeof setPostBindApiPostError
  | typeof deleteKeyFromPostBindAnswers
  | typeof updatePostBindAnswers
  | typeof updatePostBindQuestions
  | typeof deleteKeysFromPostBindAnswers
>;
