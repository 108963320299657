import { useAddFields } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

export const DogBiteHistoryQuestion: React.FC<QuestionProps> = (props) => {
  const {
    label = 'Does the policyholder’s dog have a bite history?',
    trackingName = 'bite_history_selection',
  } = props;

  const dogBiteHistory = useField('dogBiteHistory');

  useAddFields({ dogBiteHistory });
  const dogBiteHistoryOptions = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
    { label: 'No dog', value: 'noDog' },
  ];

  if (!dogBiteHistory.exists) return null;

  return (
    <RadioGroupWithOptions
      {...dogBiteHistory.props}
      options={dogBiteHistoryOptions}
      label={label}
      id={dogBiteHistory.props.name}
      variant='button'
      trackingName={trackingName}
    />
  );
};
