import { useCallback, useEffect, useState } from 'react';

import { AppBar, Box, Tabs as MuiTabs, Tab } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';

import type { TabOptions } from '@ecp/features/sales/shared/types';

import { useStyles } from './Tabs.styles';

interface TabPanelProps {
  value: number;
  index: number;
}

const TabPanel: React.FC<React.PropsWithChildren<TabPanelProps>> = ({
  children,
  index,
  value,
  ...rest
}) => (
  <div
    role='tabpanel'
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...rest}
  >
    {value === index && (
      <Box component='div' overflow='hidden' display='block'>
        {children}
      </Box>
    )}
  </div>
);

interface A11yProps {
  id: string;
  'aria-controls': string;
}

const a11yProps = (index: number): A11yProps => ({
  id: `full-width-tab-${index}`,
  'aria-controls': `full-width-tabpanel-${index}`,
});

interface Props {
  className?: string;
  currentIndex: number;
  tabOptions: TabOptions[];
  onChange?: (index: number) => void;
}

export const Tabs: React.FC<Props> = (props) => {
  const { className, currentIndex, tabOptions } = props;
  const { classes, cx } = useStyles();
  const [value, setValue] = useState(currentIndex);

  const handleChange = useCallback(
    (_: React.ChangeEvent<unknown>, newValue: number): void => {
      setValue(newValue);
      if (props.onChange) props.onChange(newValue);
      // Get tracking details for the selected tab
      const { trackingLabel, trackingName } = tabOptions[newValue];
      if (trackingLabel && trackingName) {
        trackClick({ action: trackingName, label: trackingLabel });
      }
    },
    [props, tabOptions],
  );

  // allows consumer to change currentIndex (useful for selecting tab in error)
  useEffect(() => setValue(currentIndex), [currentIndex]);

  return (
    <div className={cx(classes.root, className)}>
      <AppBar className={classes.appBarTabsHeader} position='sticky' color='default'>
        <MuiTabs
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          variant='scrollable'
          scrollButtons='auto'
          className={classes.scrollButtons}
        >
          {tabOptions.map((tabOption, index) => {
            const { label, image } = tabOption;

            return (
              <Tab
                className={classes.tabTitles}
                key={index}
                label={label}
                {...a11yProps(index)}
                icon={image}
              />
            );
          })}
        </MuiTabs>
      </AppBar>
      {tabOptions.map((tabOption, index) => {
        const { body } = tabOption;

        return (
          <TabPanel value={value} key={index} index={index}>
            {body}
          </TabPanel>
        );
      })}
    </div>
  );
};
