import { get as getInteractionId } from '@ecp/utils/analytics/interaction-id';
import { useEffectOnce } from '@ecp/utils/react';
import { deviceInformation } from '@ecp/utils/web';

import { env } from '@ecp/env';

import { trackSapiAnalyticsEvent } from './trackSapiAnalyticsEvent';

export const useSapiAnalyticsLoadEvent = (): void => {
  useEffectOnce(() => {
    sendLoadEvent();
  });
};

const sendLoadEvent = (): void => {
  trackSapiAnalyticsEvent({
    element: 'applicationLoadDetails',
    event: 'load',
    eventDetail: JSON.stringify(getLoadEventDetail()),
  });
};

const getLoadEventDetail = (): LoadEventDetail => {
  const interactionId = getInteractionId();
  const experienceId = env.static.expId;
  const userAgent = window.navigator.userAgent;
  const deviceVendor = deviceInformation.device.vendor || '';
  const deviceModel = deviceInformation.device.model || '';
  const deviceType = deviceInformation.device.type || '';
  const osName = deviceInformation.os.name || '';
  const osVersion = deviceInformation.os.version || '';
  const osVersionMajor = getMajorVersion(osVersion);
  const urlHost = window.location.host;
  const urlPath = window.location.pathname;
  const queryString = window.location.search;
  const referralUrl = document.referrer;
  const baseExp = env.static.baseExp;
  const browserName = deviceInformation.browser.name || '';
  const browserVersion = deviceInformation.browser.version || '';
  const browserVersionMajor = getMajorVersion(browserVersion);
  const afiAppname = env.afiAppname || '';

  const eventDetail: LoadEventDetail = {
    userAgent,
    queryString,
    referralUrl,
    experienceId,
    interactionId,
    baseExp,
    browserName,
    browserVersion,
    browserVersionMajor,
    deviceVendor,
    deviceModel,
    deviceType,
    osName,
    osVersion,
    osVersionMajor,
    urlHost,
    urlPath,
    afiAppname,
  };

  return eventDetail;
};

type LoadEventDetail = {
  userAgent: string;
  queryString: string;
  referralUrl: string;
  experienceId: string;
  interactionId: string;
  baseExp: string;
  browserName: string;
  browserVersion: string;
  browserVersionMajor: string;
  deviceModel: string;
  deviceType: string;
  deviceVendor: string;
  osName: string;
  osVersion: string;
  osVersionMajor: string;
  urlHost: string;
  urlPath: string;
  afiAppname: string;
};

/**
 * Extracts and returns the major version from a version string.
 *
 * @param {string} version - A version string in the format 'major.minor.patch'.
 * @returns {string} The major version number as a string.
 *
 * @example
 * getMajorVersion('1.2.3'); // returns '1'
 */
export function getMajorVersion(version: string): string {
  return version.split('.')[0];
}
