import { useCallback } from 'react';

import { emptyArray } from '@ecp/utils/common';

import { useAddFields } from '@ecp/features/sales/form';
import { CheckboxGroup } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useGetLOBFields, useGetProductFields } from '@ecp/features/sales/shared/store';
import type { Field } from '@ecp/types';

type Props = QuestionProps &
  Pick<
    React.ComponentProps<typeof CheckboxGroup>,
    'options' | 'mutuallyExclusiveOptions' | 'mutuallyDisabledOptions' | 'error'
  >;

export const ProductQuestion: React.FC<Props> = (props) => {
  const {
    options = emptyArray as unknown as NonNullable<Props['options']>,
    mutuallyExclusiveOptions,
    mutuallyDisabledOptions,
    error,
  } = props;
  const { product } = useGetProductFields();
  const fields = useGetLOBFields(options);

  useAddFields({ ...fields, product });

  const values = options
    .filter((option) => {
      const field = fields[option.value];

      return !!(field && field.value);
    })
    .map((option) => option.value);

  const actionOnComplete = useCallback(
    (values: string[], _valueClicked?: string, _newChecked?: boolean): void => {
      Object.keys(fields).forEach((key) => {
        const val = values.includes(key);
        const keyField = fields[key] as Field;
        keyField.update(val);
      });
    },
    [fields],
  );

  // Modify options to disable only 'Renters'
  const modifiedOptions = options.map((option) => ({
    ...option,
    disabled: option.label === 'Renters', // only disable Renters for bundle quote
  }));

  return (
    <CheckboxGroup
      name='lineOfBusiness'
      options={modifiedOptions} // Pass the modified options
      mutuallyExclusiveOptions={mutuallyExclusiveOptions}
      mutuallyDisabledOptions={mutuallyDisabledOptions}
      values={values}
      variant='horizontalCard'
      error={error}
      actionOnComplete={actionOnComplete}
      trackingName='LineOfBusinessSelection'
    />
  );
};
