import { useCallback } from 'react';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { ensureZipCode } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';
import { useEvent } from '@ecp/utils/react';

import { NumberFormat } from '@ecp/components';
import { env } from '@ecp/env';
import { useAddFields } from '@ecp/features/sales/form';
import { STATE_CODE_PREFIX } from '@ecp/features/sales/shared/constants';
import {
  fetchCityAndStateSuggestionsThunkAction,
  setFormErrorsChangedByField,
  setZipLookupBypassed,
  updateAnswers,
  useGetProductFields,
  usePrimaryAddressRef,
} from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { Answers, AnswerValue } from '@ecp/features/sales/shared/types';

import type { QuestionProps } from '../../questionProps.types';

interface Props extends QuestionProps {
  disabled?: boolean;
}

const getResetStateCity = (primaryAddressRef: string): Answers => ({
  [`${primaryAddressRef}.state`]: '',
  [`${primaryAddressRef}.city`]: '',
});

export const RetrieveZipCodeQuestion: React.FC<Props> = (props) => {
  const {
    id = 'getBackZipCode',
    label = 'ZIP code',
    trackingName = 'GetBackZipCode',
    disabled,
  } = props;
  const dispatch = useDispatch();

  const { zipCode } = useGetProductFields();
  const primaryAddressRef = usePrimaryAddressRef();
  const allowCARedirect = env.caConnectRedirectHomeUrl || env.caConnectRedirectAutoUrl;
  useAddFields({ zipCode });

  const validateZipCodeAndGetStateCity = useEvent(
    async (value: AnswerValue): Promise<Answers | null> => {
      const zipcode = ensureZipCode(value);
      if (!zipcode) return getResetStateCity(primaryAddressRef);

      // Prefill city, state and zipcode for Person Page form
      const cityAndStateSuggestionResult = await dispatch(
        fetchCityAndStateSuggestionsThunkAction(zipcode),
      );
      const cityAndStateSuggestion = cityAndStateSuggestionResult?.response;
      dispatch(setZipLookupBypassed(!!cityAndStateSuggestion?.bypass));
      if (cityAndStateSuggestion?.bypass) return getResetStateCity(primaryAddressRef);

      if (!cityAndStateSuggestion?.state) {
        dispatch(setFormErrorsChangedByField({ key: zipCode.key, errors: ['Invalid Zip Code'] }));
        datadogLog({
          logType: 'warn',
          message: `Invalid Zip Code - ${zipCode.value}`,
          context: {
            logOrigin:
              'libs/features/sales/shared/questions/src/ZipCode/RetrieveZipCodeQuestion/RetrieveZipCodeQuestion.tsx',
            functionOrigin: 'validateZipCodeAndGetStateCity',
          },
        });

        return getResetStateCity(primaryAddressRef);
      }

      // For CA GMI, we dont patch state and city to avoid 400 from SAPI
      const isCARedirect = allowCARedirect && cityAndStateSuggestion.state === 'CA';

      if (!isCARedirect)
        return {
          [`${primaryAddressRef}.state`]: `${STATE_CODE_PREFIX}${cityAndStateSuggestion.state}`,
          [`${primaryAddressRef}.city`]: cityAndStateSuggestion.city,
        };

      return null;
    },
  );

  const handleZipCodeComplete = useCallback(
    async (value: string) => {
      const errors = zipCode.validateAndUpdate(value);
      if (!errors.length) {
        const stateCity = await validateZipCodeAndGetStateCity(value);
        await dispatch(
          updateAnswers({
            answers: {
              ...stateCity,
              [zipCode.key]: value,
            },
          }),
        );
      }
    },
    [dispatch, validateZipCodeAndGetStateCity, zipCode],
  );

  if (!zipCode.exists) return null;

  return (
    <NumberFormat
      {...zipCode.props}
      actionOnComplete={handleZipCodeComplete}
      value={zipCode.value || ''}
      formatType='zipcode'
      id={id}
      name='getBackZipCode'
      label={label}
      ariaLabel='Zip code'
      fullWidth={false}
      trackingName={trackingName}
      trackingLabel={GoogleAnalyticsLabels.REDACTED}
      disabled={disabled}
    />
  );
};
