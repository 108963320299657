import {
  AGENT_NOT_LICENSED_FOR_STATE_MSG,
  ErrorReason,
  LOB_AUTO_NOT_ELIGIBLE_MSG,
  LOB_BUNDLE_NOT_ELIGIBLE_MSG,
  LOB_HOME_NOT_ELIGIBLE_MSG,
  LOB_RENTERS_NOT_ELIGIBLE_MSG,
  PARTNER_NOT_ELIGIBLE_MSG,
} from '@ecp/features/sales/shared/constants';

/**
 *
 * @param errorReason - The api error reason
 * @returns string[] - The error message to be displayed to the user
 */
export const getErrorMessageFromErrorReason = (errorReason: string): string[] => {
  switch (errorReason) {
    case ErrorReason.LOB_AUTO_NOT_ELIGIBLE:
      return [LOB_AUTO_NOT_ELIGIBLE_MSG];
    case ErrorReason.LOB_HOME_NOT_ELIGIBLE:
      return [LOB_HOME_NOT_ELIGIBLE_MSG];
    case ErrorReason.LOB_RENTERS_NOT_ELIGIBLE:
      return [LOB_RENTERS_NOT_ELIGIBLE_MSG];
    case ErrorReason.LOB_BUNDLE_NOT_ELIGIBLE:
      return [LOB_BUNDLE_NOT_ELIGIBLE_MSG];
    case ErrorReason.AGENT_NOT_LICENSED_FOR_STATE:
      return [AGENT_NOT_LICENSED_FOR_STATE_MSG];
    case ErrorReason.PARTNER_NOT_ELIGIBLE:
      return [PARTNER_NOT_ELIGIBLE_MSG];
    default:
      return ['Unknown error'];
  }
};
