import { useCallback } from 'react';

import type { PhoneLinkProps } from '@ecp/components';
import { PhoneLink as PhoneLinkBase } from '@ecp/components';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

interface Props extends PhoneLinkProps {
  analyticsEventDetail?: string;
}

export const PhoneLink: React.FC<Props> = (props: Props) => {
  const { analyticsEventDetail, onClick, ...rest } = props;

  const handlePhoneLinkClick: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
    (event) => {
      onClick?.(event);
      trackSapiAnalyticsEvent({
        element: 'choice.anyPage.callButton',
        event: 'click',
        eventDetail: analyticsEventDetail || 'true',
      });
    },
    [analyticsEventDetail, onClick],
  );

  return <PhoneLinkBase onClick={handlePhoneLinkClick} {...rest} />;
};
