import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CheckoutContactInformation' })((theme) => ({
  ...theme.mixins.formAll,
  title: {
    margin: 0,
    marginBottom: 10,
  },
  divider: {
    margin: '4px 0px 0px',
    height: 4,
    backgroundColor: theme.palette.primary.main,
    border: 'none',
  },
  mainGrid: {
    backgroundColor: theme.palette.grey['100'],
    padding: '0 20px 20px',
  },
  subHeader: theme.typography.body4,
}));
