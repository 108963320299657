import { createAction } from '@reduxjs/toolkit';

import type {
  PaymentOptionResponse,
  PaymentOptionsResponse,
  PaymentPlanPayload,
  PaymentTypePayload,
} from './types';

export const setPaymentOptionsApiGetSuccess = createAction<PaymentOptionsResponse>(
  '@paymentoptions/apiGetSuccess',
);
export const setPaymentOptionsApiPostSuccess = createAction<PaymentOptionResponse>(
  '@paymentoption/apiPostSuccess',
);
export const setPaymentType = createAction<PaymentTypePayload>('@payment/setPaymentType');
export const setPaymentPlan = createAction<PaymentPlanPayload>('@payment/setPaymentPlan');
export const setAvailablePaymentTypes = createAction<PaymentOptionsResponse>(
  '@payment/availablePaymentTypes',
);

export type PaymentOptionsActions = ReturnType<
  | typeof setPaymentOptionsApiGetSuccess
  | typeof setPaymentOptionsApiPostSuccess
  | typeof setPaymentType
  | typeof setPaymentPlan
  | typeof setAvailablePaymentTypes
>;
