import { GridItem } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const OtherStructuresBlanketCoverageQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const otherStructuresBlanketCoverage = useHomePropertyField('otherStructuresBlanketCoverageInd');
  const addOtherStructures = useHomePropertyField('addOtherStructuresInd');

  const {
    label = otherStructuresBlanketCoverage.question?.title,
    trackingName = 'other_structures_blanket_coverage_question',
    groupLabel = addOtherStructures.question?.title,
    trackingLabel = 'add_other_structures_question',
  } = props;

  useAddFields({ otherStructuresBlanketCoverage });
  useInitValues({ [addOtherStructures.key]: addOtherStructures.question.defaultValue });

  if (!otherStructuresBlanketCoverage.exists) return null;

  return (
    <>
      <GridItem topSpacing='lg' xs={12}>
        <RadioGroupWithOptions
          {...otherStructuresBlanketCoverage.props}
          label={label}
          variant='yesNoButton'
          trackingName={trackingName}
          value={otherStructuresBlanketCoverage.value}
        />
      </GridItem>

      {addOtherStructures.exists &&
        otherStructuresBlanketCoverage.value !== undefined &&
        !otherStructuresBlanketCoverage.value && (
          <GridItem topSpacing='lg' xs={12}>
            <RadioGroupWithOptions
              {...addOtherStructures.props}
              label={groupLabel}
              variant='yesNoButton'
              trackingName={trackingLabel}
              value={addOtherStructures.value}
            />
          </GridItem>
        )}
    </>
  );
};
