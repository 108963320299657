import { Grid, IconButton } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';

import { GridItem } from '@ecp/components';
import type { ProductName } from '@ecp/features/shared/product';
import { getProductDisplayName } from '@ecp/features/shared/product';
import { IconUIExpandLess, IconUIExpandMore } from '@ecp/themes/base';

import metadata from './metadata';
import { useStyles } from './SummaryHeader.styles';

type Props = {
  coverageType: ProductName;
  premiumAmount: string;
  policyDuration: string;
  dueAmount: string;
  recalculating?: boolean;
  paymentType?: string;
  needExpand?: boolean;
  expand?: boolean;
  handleExpandOrCollapse?: () => void;
};

export const SummaryHeader: React.FC<Props> = (props) => {
  const {
    coverageType,
    premiumAmount,
    policyDuration,
    dueAmount,
    recalculating = false,
    paymentType,
    needExpand,
    expand,
    handleExpandOrCollapse,
  } = props;
  const { classes, cx } = useStyles();
  const dueToday = paymentType === 'Mortgage' ? parseDollar('0') : dueAmount;
  const productDisplayName = getProductDisplayName(coverageType);

  return (
    <Grid container className={classes.summaryHeader}>
      <Grid item className={classes.typeDiv}>
        <h3 className={classes.type}>{productDisplayName}</h3>
      </Grid>
      <Grid
        item
        className={cx(classes.verticalDivider, { [classes.recalculatingText]: recalculating })}
      />
      <GridItem>
        <Grid container className={classes.premiumColumn}>
          <h3 className={cx(recalculating && classes.recalculatingText)}>{premiumAmount} </h3>
          <p className={cx(classes.term, recalculating && classes.recalculatingText)}>
            &nbsp;{policyDuration}-mo policy
          </p>
        </Grid>
        <Grid />
        {metadata.displayAsLowAs && (
          <p className={cx(classes.premiumTextLine2, recalculating && classes.recalculatingText)}>
            As low as {dueToday} due today
          </p>
        )}
      </GridItem>
      {needExpand && (
        <Grid item xs={1} className={classes.expandIconPanel}>
          <IconButton
            className={classes.expandIconButton}
            title='Expand Icon'
            onClick={handleExpandOrCollapse}
            size='large'
          >
            {expand ? (
              <IconUIExpandLess className={classes.expandIcon} title='expandLess' />
            ) : (
              <IconUIExpandMore className={classes.expandIcon} title='expandMore' />
            )}
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};
