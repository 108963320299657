import { createReducer } from '@reduxjs/toolkit';

import { emptyArray, emptyObject } from '@ecp/utils/common';

import {
  setFormErrorsChanged,
  setFormErrorsChangedByField,
  setFormErrorsReset,
  setFormErrorsResetByField,
  setFormFieldReset,
  setFormInitialize,
  setFormReset,
  setFormValueChanged,
  setInitValues,
} from './actions';
import type { FormState } from './types';

const formStateInitial: FormState = { errors: {}, initValues: {}, userValues: {} };

export const formReducer = createReducer(formStateInitial, (builder) => {
  builder
    .addCase(setFormErrorsChanged, (state, { payload: errors }) => {
      state.errors = errors;
    })
    .addCase(setFormErrorsChangedByField, (state, { payload: { key, errors } }) => {
      state.errors[key] = errors;
    })
    .addCase(setFormErrorsReset, (state) => {
      state.errors = emptyObject as unknown as FormState['errors'];
    })
    .addCase(setFormErrorsResetByField, (state, { payload: { key } }) => {
      state.errors[key] = emptyArray as unknown as string[];
    })
    .addCase(setFormFieldReset, (state, { payload: key }) => {
      delete state.errors[key];
      delete state.userValues[key];
    })
    .addCase(setFormInitialize, (state, { payload: { initValues } }) => {
      state.errors = {};
      state.initValues = initValues;
      state.userValues = {};
    })
    .addCase(setInitValues, (state, { payload: { initValues } }) => {
      state.initValues = initValues;
    })
    .addCase(setFormReset, (state) => {
      state.errors = {};
      state.userValues = {};
    })
    .addCase(setFormValueChanged, (state, { payload: { key, value } }) => {
      state.userValues[key] = value;
    })
    .addMatcher(
      (action): action is { type: '@global/teardownStore' } =>
        action.type === '@global/teardownStore',
      () => formStateInitial,
    );
});
