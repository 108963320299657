import { Grid } from '@mui/material';

import { GridItem } from '@ecp/components';

import {
  ClassARatedRoofQuestion,
  DeckClearanceStatusQuestion,
  EnclosedEavesQuestion,
  FireResistantVentsQuestion,
  FiveFootCombustibleClearanceQuestion,
  HundredFeetDefensibleSpaceQuestion,
  MultiPaneWindowsQuestion,
  NonCombustibleUseWithinFiveFeetQuestion,
  NonCombustibleUseWithinThirtyFeetQuestion,
  VerticalGroundClearanceQuestion,
} from '../../components';

export const AdditionalWildfireMitigationFormQuestions: React.FC = () => {
  return (
    <Grid container>
      <GridItem>
        <DeckClearanceStatusQuestion />
      </GridItem>

      <GridItem topSpacing='lg' sm={12}>
        <FiveFootCombustibleClearanceQuestion />
      </GridItem>

      <GridItem topSpacing='lg' sm={12}>
        <NonCombustibleUseWithinFiveFeetQuestion />
      </GridItem>

      <GridItem topSpacing='lg' sm={12}>
        <NonCombustibleUseWithinThirtyFeetQuestion />
      </GridItem>

      <GridItem topSpacing='lg' sm={12}>
        <HundredFeetDefensibleSpaceQuestion />
      </GridItem>

      <GridItem topSpacing='lg' sm={12}>
        <ClassARatedRoofQuestion />
      </GridItem>

      <GridItem topSpacing='lg' sm={12}>
        <EnclosedEavesQuestion />
      </GridItem>

      <GridItem topSpacing='lg' sm={12}>
        <FireResistantVentsQuestion />
      </GridItem>

      <GridItem topSpacing='lg' sm={12}>
        <MultiPaneWindowsQuestion />
      </GridItem>

      <GridItem topSpacing='lg' sm={12}>
        <VerticalGroundClearanceQuestion />
      </GridItem>
    </Grid>
  );
};
