import { useEffect } from 'react';

import { getShouldTeardownApp, teardownStore } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

interface TeardownControllerProps {
  /**
   * Handler to be executed right after the teardown.
   */
  onTeardown: () => void;
}

/**
 * Tears down currently rendered React tree and Redux store.
 * It is analogous to page refresh, but persitst everything global to itself and up the React tree:
 * - all assets and scripts within index.html
 * - Polyfills
 * - Optimizely
 * - Feature flags
 * - Datadog
 * - Chat
 */
export const TeardownController: React.FC<React.PropsWithChildren<TeardownControllerProps>> = (
  props,
) => {
  const { children, onTeardown } = props;

  const dispatch = useDispatch();

  const shouldTeardownApp = useSelector(getShouldTeardownApp);
  useEffect(() => {
    if (shouldTeardownApp) {
      dispatch(teardownStore(undefined));
      onTeardown();
    }
  }, [dispatch, onTeardown, shouldTeardownApp]);

  // This makes sure to teardown children on the render tick, when shouldTeardownApp becomes `true`.
  // Then on the next render tick children will be rendered anew.
  if (shouldTeardownApp) return null;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
