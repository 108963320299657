import { PhoneLink } from '@ecp/features/sales/shared/components';
import { LineOfBusiness } from '@ecp/features/shared/product';
import { partner } from '@ecp/partners';
import type { StateOption } from '@ecp/types';

export interface BaseMetadata {
  displayText: JSX.Element;
}

interface StateMetadata extends StateOption<BaseMetadata>, BaseMetadata {}

export const metadata: Partial<Record<LineOfBusiness, StateMetadata>> = {
  [LineOfBusiness.RENTERS]: {
    displayText: (
      <>
        Unfortunately, Renters insurance is not yet available in your state. To continue with
        Renters, contact a licensed advisor at{' '}
        <PhoneLink withUnderlinedLinkStyle number={partner.shared.salesPhoneNumber} />.
      </>
    ),
    stateOptions: {},
  },
  [LineOfBusiness.HOME]: {
    displayText: (
      <>
        Unfortunately, Home insurance is not yet available in your state. To continue with Home,
        contact a licensed advisor at{' '}
        <PhoneLink withUnderlinedLinkStyle number={partner.shared.salesPhoneNumber} />.
      </>
    ),
    stateOptions: {
      AK: { displayText: <>Unfortunately, Home insurance is not yet available in your state.</> },
      AR: { displayText: <>Unfortunately, Home insurance is not yet available in your state.</> },
      DC: { displayText: <>Unfortunately, Home insurance is not yet available in your state.</> },
      FL: { displayText: <>Unfortunately, Home insurance is not yet available in your state.</> },
      HI: { displayText: <>Unfortunately, Home insurance is not yet available in your state.</> },
    },
  },
  [LineOfBusiness.AUTO]: {
    displayText: (
      <>
        Unfortunately, Auto insurance is not yet available in your state. To continue with Auto,
        contact a licensed representative at{' '}
        <PhoneLink withUnderlinedLinkStyle number={partner.shared.salesPhoneNumber} />.
      </>
    ),
    stateOptions: {},
  },
};

export default metadata;
