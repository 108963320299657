import type { TypographyProps } from '@mui/material';
import { Typography } from '@mui/material';

import { useStyles } from './TextItem.styles';

interface TextItemProps extends TypographyProps {
  title: string;
  description: React.ReactNode;
  paddingBetween?: boolean;
}

export const TextItem: React.FC<TextItemProps> = (props) => {
  const { title, description, variant = 'h2', paddingBetween = false } = props;
  const { classes, cx } = useStyles();

  return (
    <>
      <Typography
        variant={variant}
        aria-level={2}
        role='heading'
        classes={{ root: classes.heading }}
      >
        {title}
      </Typography>
      <p className={cx(classes.description, paddingBetween && classes.paddingBetween)}>
        {description}
      </p>
    </>
  );
};
