import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { lowerFirst, upperFirst } from '@ecp/utils/common';
import { Events, trackEvent } from '@ecp/utils/flags';
import { scrollToTop } from '@ecp/utils/web';

import { LogoSpinner } from '@ecp/components';
import { env } from '@ecp/env';
import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import {
  PagePath,
  useNavigateToNextPage,
  useNavigateToPage,
} from '@ecp/features/sales/shared/routing';
import {
  getDalSessionId,
  getIsBundleForOfferProductsSelected,
  getOfferDetailsForProduct,
  getOfferProductsSelected,
  getOfferProductsSelectedByType,
  getOfferSetId,
  getPolicyStartDates,
  getRecalculateValue,
  getUserSelection,
  patchUserSelectionAndUpdateOffer,
  setShouldRecalc,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';
import {
  getCarrierNameFromProduct,
  getProductNameFromProduct,
  UserSelection,
} from '@ecp/features/shared/product';

import { CheckoutForm } from '../../forms/CheckoutForm/CheckoutForm';
import { defaultPaymentByProductType } from '../../metadata';
import {
  getCreditCardType,
  getPaymentOptionsAndAcksForCheckout,
  getPaymentOptionsAndAcksRefetchRequired,
  setAccountNumber,
  setCardNumber,
  setCreditCardType,
  setExpirationDate,
  setName,
  setRoutingNumber,
} from '../../state';
import { getIAgreeAcknowledgements, getSignatures } from '../../state/acknowledgements';
import {
  getPaymentOptions,
  getPaymentPlanForProduct,
  getSelectedPaymentOptionPremium,
  setPaymentPlan,
  setPaymentType,
} from '../../state/paymentoptions';
import type { CreditCardType } from '../../state/purchase';
import {
  useGetAutoCheckoutFields,
  useGetPaymentType,
  useGetPropertyCheckoutFields,
} from '../../util';
import { PostBindLoadingPage } from '../PostBindPage/PostBindLoadingPage';
import { useStyles } from './CheckoutPage.styles';
import { CheckoutPageTitle } from './CheckoutPageTitle';
import metadata from './metadata';

const useGetPaymentPlan = (product?: Product): string | undefined => {
  return useSelector((state: RootStore) => {
    if (product) return getPaymentPlanForProduct(state, product);

    return undefined;
  });
};

const useGetPaymentPremiumAmount = (product?: Product): string | undefined => {
  return useSelector((state: RootStore) => {
    if (product) return getSelectedPaymentOptionPremium(state, product).totalPremium;

    return undefined;
  });
};

const useGetPaymentMonthlyAmount = (product?: Product): string | undefined => {
  return useSelector((state: RootStore) => {
    if (product) return getSelectedPaymentOptionPremium(state, product).monthlyAmount;

    return undefined;
  });
};

const useGetCreditCardType = (
  product: Product | undefined,
  type: CreditCardType,
): string | undefined => {
  return useSelector((state: RootStore) => {
    if (product && type) return getCreditCardType(state, product, type);

    return undefined;
  });
};

interface CheckoutPageProps {
  disclosureScripts?: ReactNode;
}

export const CheckoutPage: React.FC<CheckoutPageProps> = (props) => {
  const { disclosureScripts } = props;
  const { classes } = useStyles();
  const [iAgreeOpen, setIAgreeOpen] = useState(true);
  const [displayLoadingPage, setShowDisplayLoadingPage] = useState(false);
  const handleNext = useNavigateToNextPage();
  const navigateToCheckoutErrorPage = useNavigateToPage(PagePath.CHECKOUT_ERROR, {
    replace: true,
    removeQuery: true,
  });
  const navigateToCheckoutRetryPage = useNavigateToPage(PagePath.CHECKOUT_RETRY, {
    replace: true,
    removeQuery: true,
  });
  const { auto: autoOfferProduct, property: propertyOfferProduct } = useSelector(
    getOfferProductsSelectedByType,
  );
  const propertyProductName = propertyOfferProduct
    ? getProductNameFromProduct(propertyOfferProduct)
    : undefined;
  const isBundle = useSelector(getIsBundleForOfferProductsSelected);
  const product = autoOfferProduct || propertyOfferProduct;
  const carrierName = product ? getCarrierNameFromProduct(product) : undefined;
  const autoFields = useGetAutoCheckoutFields();
  const propertyFields = useGetPropertyCheckoutFields(propertyProductName);
  const autoOfferDetails = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, autoOfferProduct),
  );
  const propertyOfferDetails = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, propertyOfferProduct),
  );

  const autoPaymentType = useGetPaymentType(autoOfferProduct);
  const propertyPaymentType = useGetPaymentType(propertyOfferProduct);
  const autoPaymentPlan = useGetPaymentPlan(autoOfferProduct);
  const propertyPaymentPlan = useGetPaymentPlan(propertyOfferProduct);
  const autoPaymentPremiumAmount = useGetPaymentPremiumAmount(autoOfferProduct);
  const propertyPaymentPremiumAmount = useGetPaymentPremiumAmount(propertyOfferProduct);
  const autoPaymentMonthlyAmount = useGetPaymentMonthlyAmount(autoOfferProduct);
  const propertyPaymentMonthlyAmount = useGetPaymentMonthlyAmount(propertyOfferProduct);
  const autoCreditCardType = useGetCreditCardType(autoOfferProduct, 'creditCard');
  const autoCostoCardType = useGetCreditCardType(autoOfferProduct, 'costcoVisa');
  const paymentOptions = useSelector(getPaymentOptions);
  const propertyPaymentOptions = propertyOfferProduct && paymentOptions[propertyOfferProduct];
  const signatures = useSelector(getSignatures);
  const acknowledgements = useSelector(getIAgreeAcknowledgements);
  const offerProductsSelected = useSelector(getOfferProductsSelected);
  const { auto: autoPolicyStartDate, property: propertyPolicyStartDate } =
    useSelector(getPolicyStartDates);
  // TODO - Move the below useMemos to selectors
  const offers = useMemo(
    () => ({
      ...(autoOfferProduct &&
        autoOfferDetails && {
          auto: {
            selectedProduct: autoOfferProduct,
            offer: autoOfferDetails,
            policyStartDate: autoPolicyStartDate,
          },
        }),
      ...(propertyOfferProduct &&
        propertyOfferDetails && {
          property: {
            selectedProduct: propertyOfferProduct,
            offer: propertyOfferDetails,
            policyStartDate: propertyPolicyStartDate,
          },
        }),
    }),
    [
      autoOfferDetails,
      autoOfferProduct,
      autoPolicyStartDate,
      propertyOfferDetails,
      propertyOfferProduct,
      propertyPolicyStartDate,
    ],
  );

  const payments = useMemo(
    () => ({
      ...(autoOfferProduct && {
        auto: {
          paymentType: autoPaymentType,
          paymentPlan: autoPaymentPlan,
          paymentPremium: autoPaymentPremiumAmount,
          paymentMonthly: autoPaymentMonthlyAmount,
        },
      }),
      ...(propertyOfferProduct && {
        property: {
          paymentType: propertyPaymentType,
          paymentPlan: propertyPaymentPlan,
          paymentPremium: propertyPaymentPremiumAmount,
          paymentMonthly: propertyPaymentMonthlyAmount,
        },
      }),
    }),
    [
      autoOfferProduct,
      autoPaymentMonthlyAmount,
      autoPaymentPlan,
      autoPaymentPremiumAmount,
      autoPaymentType,
      propertyOfferProduct,
      propertyPaymentMonthlyAmount,
      propertyPaymentPlan,
      propertyPaymentPremiumAmount,
      propertyPaymentType,
    ],
  );

  // Run this effect after I Agree modal closes,
  // as after loading Connect iframe, its script moves focus into CC number input field, what scrolls window into an iframe
  useEffect(() => {
    if (!iAgreeOpen) scrollToTop();
  }, [iAgreeOpen]);

  // A/B test metric tracking function for checkout page view
  useEffect(() => {
    trackEvent(Events.CHECKOUT_PAGE);
  }, []);
  // To handle forward and back navigation for product.userSelection
  const dispatch = useDispatch();
  const bundleTypeFromInquires = useSelector(getUserSelection);
  const bundleTypeOfSelectedProduct = isBundle ? UserSelection.BUNDLED : UserSelection.UNBUNDLED;
  const dalSessionId = useSelector(getDalSessionId);
  const offerSetId = useSelector(getOfferSetId);

  // Loading indicator to handle the following edge case
  // User clicks on checkout button on Coverages page
  // While checkout spinner is shown and the checkout process is in progress
  // User back navigates from coverages page to Quotes page -> userSelection is removed
  // The application navigates to checkout page as that is the last statement to get executed
  // Update SAPI with the previous userSelection to get updated offer information
  const [loading, setLoading] = useState(false);

  // need to know if Connect auto payment is credit card based as they do validation via iframe
  // and we cannot use the "re-use auto payment" bundle feature for home payment in this scenario
  const isConnectAutoCC =
    !!autoOfferProduct?.includes('connect') &&
    (autoPaymentType === 'CreditCard' || autoPaymentType === 'CostcoVisa');

  const [reuseAutoPayment, setReuseAutoPayment] = useState(false);

  const syncPropertyToAuto = useCallback(
    (newType: string) => {
      if (isConnectAutoCC) {
        setReuseAutoPayment(false);
      } else {
        // payment types are stored with first letter capitalized, but type PaymentMethod are strings with first letter lowercase
        const selectedPaymentType = upperFirst(newType);

        if (selectedPaymentType && propertyOfferProduct) {
          dispatch(
            setPaymentType({ product: propertyOfferProduct, paymentType: selectedPaymentType }),
          );
          propertyFields.paymentMethod.props.actionOnChange(selectedPaymentType);

          // home should not re-use auto payment plan, but we need to default a payment plan according to home payment type
          const genericAutoPaymentType =
            selectedPaymentType === 'CostcoVisa' ? 'CreditCard' : selectedPaymentType;
          const propertyCarrierPaymentOptions = propertyPaymentOptions
            ? propertyPaymentOptions.carrierPaymentOptions
            : [];
          const availablePropertyPaymentOptions = propertyCarrierPaymentOptions.filter(
            (option) => option?.paymentType === genericAutoPaymentType,
          );
          const filteredOptions = availablePropertyPaymentOptions.filter(
            (option) => option.paymentPlan === propertyPaymentPlan,
          );

          // only update home payment plan if the current home payment plan/type pair is invalid
          if (filteredOptions.length === 0 && availablePropertyPaymentOptions[0]?.paymentPlan) {
            dispatch(
              setPaymentPlan({
                product: propertyOfferProduct,
                paymentPlan: availablePropertyPaymentOptions[0].paymentPlan,
              }),
            );
          }

          // take payment values from auto payment and apply to property
          if (selectedPaymentType === 'EFT') {
            const autoRoutingNumber = autoFields.routingNumber.value as string;
            dispatch(
              setRoutingNumber({ product: propertyOfferProduct, routingNumber: autoRoutingNumber }),
            );
            propertyFields.routingNumber.props.actionOnChange(autoRoutingNumber);

            const autoAccountNumber = autoFields.accountNumber.value as string;
            dispatch(
              setAccountNumber({ product: propertyOfferProduct, accountNumber: autoAccountNumber }),
            );
            propertyFields.accountNumber.props.actionOnChange(autoAccountNumber);
          }

          if (selectedPaymentType === 'CreditCard' || selectedPaymentType === 'CostcoVisa') {
            const autoCreditCardNumber =
              selectedPaymentType === 'CreditCard'
                ? autoFields.creditCardCardNumber.props.value
                : autoFields.costcoCardNumber.props.value;
            const autoCreditCardExpirationDate =
              selectedPaymentType === 'CreditCard'
                ? autoFields.creditCardExpirationDate.props.value
                : autoFields.costcoCardExpirationDate.props.value;
            const autoCreditCardFullName =
              selectedPaymentType === 'CreditCard'
                ? autoFields.creditCardFullName.props.value
                : autoFields.costcoCardFullName.props.value;

            const downcaseType = lowerFirst(selectedPaymentType) as CreditCardType;

            const autoCardType =
              selectedPaymentType === 'CreditCard' ? autoCreditCardType : autoCostoCardType;
            if (autoCardType) {
              dispatch(
                setCreditCardType({
                  creditCardType: autoCardType,
                  product: propertyOfferProduct,
                  type: downcaseType,
                }),
              );
            }

            dispatch(
              setCardNumber({
                cardNumber: autoCreditCardNumber || '',
                product: propertyOfferProduct,
                type: downcaseType,
              }),
            );
            dispatch(
              setName({
                fullName: autoCreditCardFullName || '',
                product: propertyOfferProduct,
                type: downcaseType,
              }),
            );

            dispatch(
              setExpirationDate({
                expirationDate:
                  autoCreditCardExpirationDate !== 'Invalid date'
                    ? autoCreditCardExpirationDate
                    : '',
                product: propertyOfferProduct,
                type: downcaseType,
              }),
            );

            if (selectedPaymentType === 'CreditCard') {
              propertyFields.creditCardCardNumber.props.actionOnChange(autoCreditCardNumber);
              propertyFields.creditCardExpirationDate.props.actionOnChange(
                autoCreditCardExpirationDate,
              );
              propertyFields.creditCardFullName.props.actionOnChange(autoCreditCardFullName);
            } else {
              propertyFields.costcoCardNumber.props.actionOnChange(autoCreditCardNumber);
              propertyFields.costcoCardExpirationDate.props.actionOnChange(
                autoCreditCardExpirationDate,
              );
              propertyFields.costcoCardFullName.props.actionOnChange(autoCreditCardFullName);
            }
          }
        }
      }
    },
    [
      autoCostoCardType,
      autoCreditCardType,
      autoFields.accountNumber.value,
      autoFields.costcoCardExpirationDate.props.value,
      autoFields.costcoCardFullName.props.value,
      autoFields.costcoCardNumber.props.value,
      autoFields.creditCardCardNumber.props.value,
      autoFields.creditCardExpirationDate.props.value,
      autoFields.creditCardFullName.props.value,
      autoFields.routingNumber.value,
      dispatch,
      propertyFields.accountNumber?.props,
      propertyFields.costcoCardExpirationDate?.props,
      propertyFields.costcoCardFullName?.props,
      propertyFields.costcoCardNumber?.props,
      propertyFields.creditCardCardNumber?.props,
      propertyFields.creditCardExpirationDate?.props,
      propertyFields.creditCardFullName?.props,
      propertyFields.paymentMethod?.props,
      propertyFields.routingNumber?.props,
      propertyOfferProduct,
      propertyPaymentOptions,
      propertyPaymentPlan,
      isConnectAutoCC,
    ],
  );

  const handleReuseAutoPaymentCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      setReuseAutoPayment(newChecked);

      if (newChecked) {
        syncPropertyToAuto(autoPaymentType as string);
      }
    },
    [autoPaymentType, syncPropertyToAuto],
  );

  const loadPaymentOptionsAndAcks = useSelector(getPaymentOptionsAndAcksRefetchRequired);
  const recalculate = useSelector(getRecalculateValue);
  // The dependencies would make sure user selection gets properly updated.
  // Also, this would avoid any mismatch because of edge case back nav scenarios
  // Do not call updateOffers after the offer is purchased
  // TODO - Revisit this useEffect after SAPI resolves confusion regarding product.userSelection in answers
  useEffect(() => {
    if (
      offerSetId &&
      (bundleTypeFromInquires === null || bundleTypeFromInquires !== bundleTypeOfSelectedProduct)
    ) {
      (async () => {
        setLoading(true);
        await dispatch(patchUserSelectionAndUpdateOffer());

        if (recalculate) dispatch(setShouldRecalc(false));
        setLoading(false);

        // To acknowledge the latest acknowledgements
        setIAgreeOpen(true);
      })();
    }
    if (!autoPaymentType && autoOfferProduct) {
      dispatch(
        setPaymentType({
          product: autoOfferProduct,
          paymentType: defaultPaymentByProductType.auto,
        }),
      );
    }
    if (!propertyPaymentType && propertyOfferProduct && propertyProductName) {
      dispatch(
        setPaymentType({
          product: propertyOfferProduct,
          paymentType: defaultPaymentByProductType[propertyProductName],
        }),
      );
    }
  }, [
    autoOfferProduct,
    bundleTypeFromInquires,
    bundleTypeOfSelectedProduct,
    dispatch,
    propertyOfferProduct,
    offerSetId,
    autoPaymentType,
    propertyPaymentType,
    propertyProductName,
    recalculate,
  ]);

  useEffect(() => {
    if (loadPaymentOptionsAndAcks) {
      const productsToUpdate = [autoOfferProduct, propertyOfferProduct].filter(
        (offerProduct) => offerProduct,
      ) as Product[];
      (async () => {
        await dispatch(
          getPaymentOptionsAndAcksForCheckout({ dalSessionId, products: productsToUpdate }),
        );
      })();
    }
  }, [autoOfferProduct, dispatch, propertyOfferProduct, loadPaymentOptionsAndAcks, dalSessionId]);

  if (autoOfferProduct) {
    if (!signatures.auto?.length) {
      delete autoFields.paymentAcknowledgement;
      delete autoFields.acknowledgementName;
      delete autoFields.pniAcknowledgementName;
      delete autoFields.sniAcknowledgementName;
    }

    if (signatures.auto?.length) {
      const requiredsNameSignature =
        signatures.auto.filter(({ acknowledgementType }) => acknowledgementType === 'Signature')
          .length > 0;

      if (!requiredsNameSignature) {
        delete autoFields.acknowledgementName;
        delete autoFields.pniAcknowledgementName;
        delete autoFields.sniAcknowledgementName;
      }
    }
  }

  if (propertyOfferProduct) {
    if (!signatures.property?.length) {
      delete propertyFields.paymentAcknowledgement;
      delete propertyFields.acknowledgementName;
      delete propertyFields.pniAcknowledgementName;
      delete propertyFields.sniAcknowledgementName;
    }

    if (signatures.property?.length) {
      const requiredsNameSignature =
        signatures.property.filter(({ acknowledgementType }) => acknowledgementType === 'Signature')
          .length > 0;

      if (!requiredsNameSignature) {
        delete propertyFields.acknowledgementName;
        delete propertyFields.pniAcknowledgementName;
        delete propertyFields.sniAcknowledgementName;
      }
    }
  }

  return (
    <div className={classes.root}>
      {displayLoadingPage && (
        <Page analyticsElement='choice.checkoutPage.page'>
          <PostBindLoadingPage />
        </Page>
      )}
      {!displayLoadingPage && (
        <Page
          analyticsElement='choice.checkoutPage.page'
          topSpacer={metadata.shouldRenderTopSpacer}
          title={metadata.shouldRenderTitle && <CheckoutPageTitle carrierName={carrierName} />}
          variant={metadata.variant}
          {...(env.static.isAgent && {
            sidebarProps: {
              drawer: <NavbarDrawer pagePath={PagePath.CHECKOUT} />,
              forceSwipeable: !env.static.isAgent,
            },
          })}
        >
          {loading && !displayLoadingPage ? (
            <div className={classes.loading}>
              <LogoSpinner />
            </div>
          ) : (
            <CheckoutForm
              products={offerProductsSelected}
              offers={offers}
              signatures={signatures}
              acknowledgements={acknowledgements}
              payments={payments}
              handleReuseAutoPaymentCheck={handleReuseAutoPaymentCheck}
              reuseAutoPayment={reuseAutoPayment}
              syncPropertyToAuto={syncPropertyToAuto}
              isConnectAutoCC={isConnectAutoCC}
              onNext={handleNext}
              onPurchaseErrorNext={navigateToCheckoutErrorPage}
              onPurchaseRetryNext={navigateToCheckoutRetryPage}
              iAgreeOpen={iAgreeOpen}
              setIAgreeOpen={setIAgreeOpen}
              fields={{
                auto: autoFields,
                property: propertyFields,
              }}
              setShowDisplayLoadingPage={setShowDisplayLoadingPage}
              carrierName={carrierName}
              disclosureScripts={disclosureScripts}
            />
          )}
        </Page>
      )}
    </div>
  );
};
