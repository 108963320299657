/**
 * `person.<id>.dob`, `driver.<id>.license.number`, `vehicle.<id>.vin`,
 * `<productName>.delta.driver.<id>.license.number (connect.auto.delta.driver.6ep9r1jv.license.number)`,
 * `<productName>.delta.vehicle.<id>.vin (connect.auto.delta.vehicle.g8s8vsja.vin)`,
 * `driver.primary.license.number.confirm`
 */
export const MASKABLE_ANSWER_KEYS_REGEX =
  /\.(?:dob|license\.number|vin)$|^driver\.primary\.license\.number\.confirm$/;

/**
 * `driver.<id>.license.number`,
 * `<productName>.delta.driver.<id>.license.number (connect.auto.delta.driver.6ep9r1jv.license.number)`,
 * `driver.primary.license.number.confirm`
 */
export const DL_NUMBER_REGEX = /\.(?:license\.number)$|^driver\.primary\.license\.number\.confirm$/;

export const HAS_NON_WHITESPACE_CHAR_REGEX = /\S/;

/** Add any system field keys if necessary to prevent writes into respective fields at all times. */
export const SYSTEM_FIELD_KEYS_REGEX =
  /^(?:auto|home)\.prefill\.flow$|^choiceId$|^customerId$|^discounts.partnerAutoPolicyNumber$|^platform\.|^sapi\./;

/**
 * PCI protected fields (credit card) have strict requirements to the storage mechanics.
 * To be on the safe side, we don't persist them on the client side and other than payment service back-end.
 * We apply the same safe guards to the bank account fields.
 */
export const PROTECTED_KEYS_REGEX =
  /\.(creditCardCardNumber|creditCardExpirationDate|creditCardFullName|routingNumber|accountNumber|costcoCardNumber|costcoCardExpirationDate|costcoCardFullName)$/;
