import { useAddFields } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

export const PeopleLivingQuestion: React.FC<QuestionProps> = (props) => {
  const {
    label = 'How many people are living in the policyholder’s unit?',
    trackingName = 'people_living_selection',
  } = props;

  const peopleLivinginUnit = useField('peopleLivinginUnit');
  const peopleLivinginUnitOptions = peopleLivinginUnit.props.options;

  useAddFields({ peopleLivinginUnit });

  if (!peopleLivinginUnit.exists) return null;

  return (
    <Select
      {...peopleLivinginUnit.props}
      label={label}
      options={peopleLivinginUnitOptions}
      id='people_living_in_unit'
      trackingName={trackingName}
    />
  );
};
