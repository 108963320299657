import type { StringKeyOf } from 'type-fest';

import { castToBoolean, isDlNumberMasked, isMasked } from '@ecp/utils/common';

import {
  PRIMARY_INSURED_ADDRESS_LOCK,
  PRIMARY_INSURED_ADDRESS_REF,
  PRIMARY_INSURED_PERSON_LOCK,
  PRIMARY_INSURED_PERSON_REF,
} from '@ecp/features/sales/shared/constants';
import type { Answers } from '@ecp/features/sales/shared/types';

import {
  DL_NUMBER_REGEX,
  HAS_NON_WHITESPACE_CHAR_REGEX,
  MASKABLE_ANSWER_KEYS_REGEX,
  PROTECTED_KEYS_REGEX,
  SYSTEM_FIELD_KEYS_REGEX,
} from './constants';

type AnswerKeyPredicate = (key: StringKeyOf<Answers>) => boolean;
type AnswerPredicate = (key: StringKeyOf<Answers>, obj: Answers) => boolean;

export const isValueMaskable: AnswerKeyPredicate = (key) => MASKABLE_ANSWER_KEYS_REGEX.test(key);

/**
 * TODO To make it more robust, Question entity needs to have a new metadata field `masked: boolean`
 * @see https://theexperimentationlab.atlassian.net/wiki/spaces/EECPML/pages/3680600522/DRAFT+Assumptions+and+Expectations
 */
export const isValueMasked: AnswerPredicate = (key, obj) => {
  if (!isValueMaskable(key)) return false;

  // First handle answers whose values can legitimately include `*`
  // where we need to use heuristics-based predicates
  if (DL_NUMBER_REGEX.test(key)) return isDlNumberMasked(obj[key]);

  return isMasked(obj[key]);
};

export const isEmptyOrHasOnlyWhitespaces = (val: unknown): boolean =>
  typeof val === 'string' && (val === '' || !HAS_NON_WHITESPACE_CHAR_REGEX.test(val));

export const isAnswerToBeRemoved: AnswerPredicate = (key, obj) =>
  obj[key] === null || obj[key] === undefined || isEmptyOrHasOnlyWhitespaces(obj[key]);

export const doesAnswerExist: AnswerPredicate = (key, allAnswers) => key in allAnswers;

export const isAnswerProtected: AnswerKeyPredicate = (key) => PROTECTED_KEYS_REGEX.test(key);

/**
 * Read-only answers are "system fields" and "fields locked from update
 * when a respective lock has been set (via respective lock answer)".
 *
 * @see https://theexperimentationlab.atlassian.net/wiki/spaces/CDMP/pages/3202351606
 *
 * TODO To make it more robust, Question entity needs to have a new metadata field `locked: boolean`
 * @see https://theexperimentationlab.atlassian.net/wiki/spaces/EECPML/pages/3680600522/DRAFT+Assumptions+and+Expectations
 */
export const isAnswerReadOnly: AnswerPredicate = (key, allAnswers) => {
  // System fields
  if (SYSTEM_FIELD_KEYS_REGEX.test(key)) return true;

  // Primary insured person locked fields
  const personRef = allAnswers[PRIMARY_INSURED_PERSON_REF];
  const personLock = castToBoolean(allAnswers[PRIMARY_INSURED_PERSON_LOCK]);
  if (personRef && personLock && RegExp(`^${personRef}.(firstName|lastName|dob)$`).test(key))
    return true;

  // Primary insured person address locked fields
  const addressRef = allAnswers[PRIMARY_INSURED_ADDRESS_REF];
  const addressLock = castToBoolean(allAnswers[PRIMARY_INSURED_ADDRESS_LOCK]);
  if (
    addressRef &&
    addressLock &&
    RegExp(`^${addressRef}.(line1|line2|state|city|zipcode|latitude|longitude)$`).test(key)
  )
    return true;

  // Other fields are writable
  return false;
};
