import { TextField, useShowMoreOrLess } from '@ecp/components';
import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

import metadata from './metadata';
import { useStyles } from './StyleOfHomeQuestion.styles';

export const StyleOfHomeQuestion: React.FC<QuestionProps> = (props) => {
  const { classes } = useStyles();
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const styleOfHome = useHomePropertyField('styleOfHome');
  const dwellingStyleDescription = useHomePropertyField('dwellingStyleDescription');
  const { label = styleOfHome.question?.title, trackingName = 'home_style_selection' } = props;
  const dwellingDescriptionLabel = dwellingStyleDescription.question?.title;

  const { showMore, displayOptions, handleClick } = useShowMoreOrLess(
    metadata.options,
    4,
    'home_style_more_choices',
  );

  useAddFields({ styleOfHome, dwellingStyleDescription });
  useAddConditionValues({
    conditionalFields: [dwellingStyleDescription],
    isExcluded: () => !(styleOfHome.value === 'STYLE_OF_HOME.OTHER_EXT'),
    isRequiredOverride: () => true,
  });

  if (!styleOfHome.exists) return null;

  return (
    <>
      <RadioGroupWithOptions
        {...styleOfHome.props}
        id='StyleOfHome'
        options={displayOptions}
        label={label}
        showMoreOrLessProps={{
          showMore,
          onClick: handleClick,
          groupTestId: 'StyleOfHome-SeeMoreOrLessChoices',
        }}
        trackingName={trackingName}
        cardSize='small'
      />
      {styleOfHome.value &&
        dwellingStyleDescription.exists &&
        ['STYLE_OF_HOME.OTHER_EXT'].includes(styleOfHome.value) && (
          <TextField
            className={classes.numberInput}
            {...dwellingStyleDescription.props}
            ariaLabel='Dwelling Style Description'
            label={dwellingDescriptionLabel}
            trackingLabel='dwellingStyle'
            trackingName='dwellingStyleDescription'
            placeholder='Other Description'
            required
          />
        )}
    </>
  );
};
