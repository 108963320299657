import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<void, 'icon'>({
  name: 'OutageBanner',
})((...[theme, , classes]) => ({
  root: {
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    display: 'flex',
    margin: 0,
    padding: 10,
    border: 0,
  },
  warning: {
    color: theme.palette.warning.darkText,
    backgroundColor: theme.palette.warning.light,
    borderColor: theme.palette.warning.main,
    [`& .${classes.icon}`]: theme.mixins.setColorSvg(theme.palette.warning.dark),
  },
  icon: {
    height: 24,
    minWidth: 14,
    width: 24,
    ...theme.mixins.setColorSvg(theme.palette.text.primary),
  },
  message: {
    margin: '0 8px',
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  wrapper: {
    maxWidth: 1084,
    width: '100%',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    margin: '0 auto',
    [theme.breakpoints.down('xl')]: {
      maxWidth: theme.breakpoints.values.lg,
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: theme.breakpoints.values.md,
    },
  },
}));
