import { idFor } from '@ecp/utils/common';

import type { ThunkAction } from '@ecp/features/sales/shared/store/types';

import { updateGlobalSeed } from '../../global/actions';
import { getGlobalSeed } from '../../global/selectors';

export const createRef =
  <T extends string>(type: T): ThunkAction<`${T}.${string}`> =>
  (dispatch, getState) => {
    const globalSeed = getGlobalSeed(getState());
    const [newSeed, newRef] = idFor(globalSeed, type);
    dispatch(updateGlobalSeed(newSeed));

    return newRef;
  };
