import { GridItem, useShowMoreOrLess } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { RadioGroupWithOptions, Slider } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

export const GarageTypeQuestion: React.FC<QuestionProps> = (props) => {
  const garageType = useField('property.garage.type');
  const garageSize = useField('property.garage.size');
  const { label = garageType.question?.title, trackingName = 'garage_type_button' } = props;

  useInitValues({
    [garageType.key]: garageType.question.defaultValue,
    [garageSize.key]: garageSize.question.defaultValue,
  });

  useAddFields({ garageType, garageSize });

  const {
    showMore: showMoreGarageType,
    displayOptions: displayGarageType,
    handleClick: handleGarageTypeShowMoreOrLess,
  } = useShowMoreOrLess(
    garageType.props.options,
    4,
    'GarageTypeMoreChoices',
    (value: string) => `GarageType${value}Choices`,
  );
  // TODO: 'NONE_EXT' to be removed when sapi cleans up option type values to be uniform

  return (
    <>
      {garageType.exists && (
        <RadioGroupWithOptions
          {...garageType.props}
          options={displayGarageType}
          label={label}
          showMoreOrLessProps={{
            showMore: showMoreGarageType,
            onClick: handleGarageTypeShowMoreOrLess,
            groupTestId: 'GarageTypeMoreChoices',
          }}
          trackingName={trackingName}
          id='GarageType'
          cardSize='small'
        />
      )}
      {garageSize.exists &&
        garageType.value &&
        !['GARAGE.TYPE.NONE', 'NONE_EXT'].includes(garageType.value) && (
          <GridItem topSpacing='lg' xs={12}>
            <Slider
              {...garageSize.props}
              groupLabel={garageSize.question.title}
              trackingName='garage_size_slider'
            />
          </GridItem>
        )}
    </>
  );
};
