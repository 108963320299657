import * as constants from '@ecp/features/sales/shared/constants';
import { LineOfBusiness } from '@ecp/features/shared/product';
import {
  IconCardAntiTheft,
  IconCardAutomaticPayments,
  IconCardAwayAtSchool,
  IconCardCentralDirectBurglarAlarm,
  IconCardCentralDirectFireAlarm,
  IconCardDefensiveDriver,
  IconCardEducationLevel,
  IconCardFireExtinguisher,
  IconCardGoodStudent,
  IconCardIndoorSprinklers,
  IconCardKeptInGarage,
  IconCardOwn,
  IconCardPaperlessBilling,
} from '@ecp/themes/base';
import type { CardOption } from '@ecp/types';

export const DISCOUNT_AUTO_GOOD_STUDENT: CardOption = {
  value: constants.DISCOUNT_AUTO_GOOD_STUDENT,
  label: 'Good student',
  icon: <IconCardGoodStudent />,
  helpText:
    'Full time college or high school student under age of 25. May need to provide a recent grade report maintaining a "B" average.',
  testId: 'goodStudent',
};

export const DISCOUNT_AUTO_PAPERLESS: CardOption = {
  value: constants.DISCOUNT_AUTO_PAPERLESS,
  label: 'Paperless',
  icon: <IconCardPaperlessBilling />,
  helpText: 'Enrollment in a paperless account set up.',
};

export const DISCOUNT_AUTO_AWAY_AT_SCHOOL: CardOption = {
  value: constants.DISCOUNT_AUTO_AWAY_AT_SCHOOL,
  label: 'Away at school',
  icon: <IconCardAwayAtSchool />,
  helpText: 'A driver who attends school more than 100 miles from home without a vehicle.',
};

export const DISCOUNT_AUTO_PAY: CardOption = {
  value: constants.DISCOUNT_AUTO_PAY,
  label: 'Auto pay',
  icon: <IconCardAutomaticPayments />,
  helpText:
    'Do you plan to sign up for recurring payments of your auto premium through automatic withdrawal from a checking or savings account?',
};

export const DISCOUNT_AUTO_DEFENSIVE_DRIVER: CardOption = {
  value: constants.DISCOUNT_AUTO_DEFENSIVE_DRIVER,
  label: 'Defensive driver',
  icon: <IconCardDefensiveDriver />,
  helpText:
    'Driver must have completed a defensive driver course in the last 3 years. State criteria may apply.',
};

export const DISCOUNT_PROPERTY_HOME_BUYER: CardOption = {
  value: constants.DISCOUNT_PROPERTY_NEW_HOME_PURCHASE,
  label: 'Homebuyer',
  icon: <IconCardOwn />,
  helpText:
    'If you’re buying a home or have purchased one in the last six months, you may qualify for a discount. The home does not need to be a new construction.',
};

export const DISCOUNT_PROPERTY_CENTRAL_OR_DIRECT_ALARM_BURGLAR: CardOption = {
  value: constants.DISCOUNT_PROPERTY_CENTRAL_OR_DIRECT_ALARM_BURGLAR,
  label: 'Central/direct burglar alarm',
  icon: <IconCardCentralDirectBurglarAlarm />,
  helpText: 'Directly alerts a third party monitoring company of a possible intrusion.',
};

export const DISCOUNT_PROPERTY_CENTRAL_OR_DIRECT_ALARM_FIRE: CardOption = {
  value: constants.DISCOUNT_PROPERTY_CENTRAL_OR_DIRECT_ALARM_FIRE,
  label: 'Central/direct fire alarm',
  icon: <IconCardCentralDirectFireAlarm />,
  helpText: 'Directly alerts a third party monitoring company of a possible fire.',
};

export const DISCOUNT_PROPERTY_INDOOR_SPRINKLER: CardOption = {
  value: constants.DISCOUNT_PROPERTY_INDOOR_SPRINKLER,
  label: 'Indoor sprinkler system',
  icon: <IconCardIndoorSprinklers />,
  helpText:
    'May apply if a properly maintained interior sprinkler system (fire suppression system) is present in the home.',
};

export const DISCOUNT_AUTO_ANTI_THEFT: CardOption = {
  value: constants.DISCOUNT_AUTO_ANTI_THEFT,
  label: 'Anti-theft devices',
  icon: <IconCardAntiTheft />,
  helpText: 'Examples of anti-theft devices are alarms or fuel cutoffs.',
};

export const DISCOUNT_AUTO_KEPT_IN_GARAGE: CardOption = {
  value: constants.DISCOUNT_AUTO_KEPT_IN_GARAGE,
  label: 'Kept in garage',
  icon: <IconCardKeptInGarage />,
  helpText: 'Is vehicle regularly parked in a garage when not at work?',
};

export const DISCOUNT_AUTO_4YEAR_DEGREE: CardOption = {
  value: constants.DISCOUNT_AUTO_4YEAR_DEGREE,
  label: '4 year degree',
  icon: <IconCardEducationLevel />,
  helpText: 'A bachelor’s degree or higher from a college or university.',
};

export const DISCOUNT_PROPERTY_WILDFIRE_MITIGATION: CardOption = {
  value: constants.DISCOUNT_PROPERTY_WILDFIRE_MITIGATION,
  label: 'Wildfire mitigation',
  icon: <IconCardFireExtinguisher />,
  helpText:
    'May apply if certain preventative measures have been taken to protect against wildfires.',
};

export const discountOptions: Record<LineOfBusiness, CardOption[]> = {
  [LineOfBusiness.AUTO]: [
    DISCOUNT_AUTO_GOOD_STUDENT,
    DISCOUNT_AUTO_PAPERLESS,
    DISCOUNT_AUTO_AWAY_AT_SCHOOL,
    DISCOUNT_AUTO_PAY,
    DISCOUNT_AUTO_DEFENSIVE_DRIVER,
    DISCOUNT_AUTO_ANTI_THEFT,
    DISCOUNT_AUTO_KEPT_IN_GARAGE,
    DISCOUNT_AUTO_4YEAR_DEGREE,
  ],
  [LineOfBusiness.HOME]: [
    DISCOUNT_PROPERTY_HOME_BUYER,
    DISCOUNT_PROPERTY_CENTRAL_OR_DIRECT_ALARM_BURGLAR,
    DISCOUNT_PROPERTY_CENTRAL_OR_DIRECT_ALARM_FIRE,
    DISCOUNT_PROPERTY_INDOOR_SPRINKLER,
    DISCOUNT_PROPERTY_WILDFIRE_MITIGATION,
  ],
  [LineOfBusiness.BUNDLE]: [
    DISCOUNT_PROPERTY_HOME_BUYER,
    DISCOUNT_PROPERTY_CENTRAL_OR_DIRECT_ALARM_BURGLAR,
    DISCOUNT_PROPERTY_CENTRAL_OR_DIRECT_ALARM_FIRE,
    DISCOUNT_PROPERTY_INDOOR_SPRINKLER,
    DISCOUNT_PROPERTY_WILDFIRE_MITIGATION,
  ],
  [LineOfBusiness.RENTERS]: [],
  [LineOfBusiness.BUNDLE_AUTO_RENTERS]: [],
};
