import { IconCardProtection } from '@ecp/themes/base';

export interface Metadata {
  protectionIcon?: React.ReactElement;
  getNewQuoteLinkText: string;
  hideNewQuoteButton?: boolean;
  // delay move of main content
  mainDuration: number;
  // delay move of main content
  mainPanelDelay: number;
  // for the internal content
  secondaryDuration: number;
  fadeDuration: number;
  showDelay: number;
}

const metadata: Metadata = {
  protectionIcon: <IconCardProtection />,
  getNewQuoteLinkText: 'Start a new quote',
  mainDuration: 1000,
  mainPanelDelay: 150,
  secondaryDuration: 300,
  fadeDuration: 700,
  showDelay: 400,
};

export default metadata;
