import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './PostBindForm.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css, theme } = useStylesTss();

  return useStylesBase(params, {
    ...styleOverrides,
    props: {
      ...styleOverrides?.props,
      classes: {
        titleHeader: css({
          color: theme.palette.text.primary,
          textAlign: 'center',
          alignSelf: 'center',
        }),
        ...styleOverrides?.props.classes,
      },
    },
  });
};
