import { makeStyles } from '@ecp/themes/base';

import type { PageDrawerProps } from './PageDrawer';

export const useStyles = makeStyles<PageDrawerProps>({ name: 'PageDrawer' })((theme) => {
  return {
    drawerHeader: {
      position: 'relative',
      height: 51,
    },
    drawerCloseIcon: {
      position: 'absolute',
      height: 44,
      width: 44,
      top: 8,
      right: 8,
      padding: 12,
      fontSize: 16,
      '& svg > path': {
        fill: theme.palette.text.hover,
      },
    },
    closeIcon: {
      cursor: 'pointer',
    },
    drawerBody: {
      padding: '0 20px 46px',
    },
    drawerFooter: {
      borderTop: `1px solid ${theme.palette.other.divider}`,
      height: 76,
      display: 'flex',
      padding: '0 20px',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    drawerCloseButton: {
      fontWeight: 700,
      textDecoration: 'none',
      marginRight: 24,
    },
    drawerTitle: {
      ...theme.typography.header1,
      fontFamily: theme.typography.fontFamily,
      paddingBottom: 10,
    },
    drawerPaper: {
      position: 'absolute',
    },
  };
});
