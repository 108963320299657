import { hasValue } from '@ecp/utils/common';

import type { SelectProps as SelectPropsBase } from '@ecp/components';
import { Select as SelectBase } from '@ecp/components';
import { useFieldRef } from '@ecp/features/sales/form';

export const Select = <T extends string = string>(
  props: SelectPropsBase<T>,
): React.ReactElement<SelectPropsBase<T>> => {
  const { name, value: valueProp = '', ...selectProps } = props;

  const value = (hasValue(valueProp) ? String(valueProp) : '') as T;

  return <SelectBase<T> inputRef={useFieldRef(name)} name={name} value={value} {...selectProps} />;
};
