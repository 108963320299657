import { Typography } from '@mui/material';

import { NumberFormat } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const NoOfMortgagesQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const noOfMortgages = useHomePropertyField('noOfMortgages');
  const { groupLabel = noOfMortgages.question?.title, trackingName = 'no_of_mortgages_question' } =
    props;

  useAddFields({ noOfMortgages });

  useInitValues({ [noOfMortgages.key]: noOfMortgages.question.defaultValue });

  if (!noOfMortgages.exists) return null;

  return (
    <NumberFormat
      {...noOfMortgages.props}
      groupLabel={<Typography variant='body4'>{groupLabel}</Typography>}
      format='##'
      trackingName={trackingName}
    />
  );
};
