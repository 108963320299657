import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DeclinationDialog' })((theme) => ({
  root: {
    '& header': {
      height: 70,
      boxShadow: 'none',
      '& .MuiGrid-container': {
        alignItems: 'baseline',
      },
    },
    '& .MuiButton-text': {
      margin: 0,
    },
  },
  container: {
    overflow: 'hidden',
    marginTop: 40,
    [theme.breakpoints.down('md')]: {
      marginTop: 24,
    },
  },
  imageContainer: {
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  imageGrid: {
    display: 'block',
    overflow: 'hidden',
    position: 'relative',
    minHeight: 400, // must be taller than image
    [theme.breakpoints.down('lg')]: {
      alignItems: 'center',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
      minHeight: 0,
    },
  },
  nextButton: {
    marginBottom: 40,
  },
}));
