import { datadogLog } from '@ecp/utils/logger';
import { location } from '@ecp/utils/routing';

import {
  getAllPageFlowsFromConfig,
  getProductOrderFromConfig,
} from '@ecp/features/sales/shared/routing';
import {
  blobRetrieve,
  getBlobRetrieveFailed,
  updateBridgeOver,
  updateInitializing,
} from '@ecp/features/sales/shared/store';
import { getDalSessionId, updateStartedInitializing } from '@ecp/features/sales/shared/store';
import type { ThunkAction } from '@ecp/features/sales/shared/store/types';

import { initializeUsingQuery } from './initializeUsingQuery';
import { initializeSession } from './initializeUtil';

export const initDone: () => ThunkAction<Promise<void>> = () => async (dispatch, getState) => {
  const dalSessionId = getDalSessionId(getState());
  if (dalSessionId) {
    dispatch(updateInitializing(false));
    dispatch(updateStartedInitializing(false));
  }
};

const logBootstrapMetrics = (
  startTime: number,
  isExpressPathToCheckout: boolean,
  blobRetrieveFailed: boolean,
): void => {
  const endTime = Date.now();
  const duration = endTime - startTime;
  datadogLog({
    logType: 'info',
    message: 'bootstrapSessionWithBlob completed',
    context: {
      isExpressPathToCheckout,
      duration,
      blobRetrieveSuceeded: !blobRetrieveFailed,
      logOrigin: 'libs/features/sales/shell/src/store/bootstrapSessionWithBlob.ts',
      functionOrigin: 'bootstrapSessionWithBlob',
    },
  });
};

export const bootstrapSessionWithBlob =
  ({ isMonoline }: { isMonoline?: boolean }): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const bootstrapStartTime = Date.now();
    const isExpressPathToCheckout = location.search.redirect === 'checkout';

    const searchParams = location.search;
    dispatch(updateStartedInitializing(true));
    if (searchParams?.source === 'bridge') dispatch(updateBridgeOver(true));

    if (searchParams?.retrieve)
      await dispatch(
        blobRetrieve({
          encryptedData: searchParams.retrieve,
          emailUUID: searchParams.emailUUID ?? '',
          source: searchParams.source ?? '',
          isExpressPathToCheckout,
        }),
      );

    const failed = getBlobRetrieveFailed(getState());
    if (failed) {
      // create session in order to show the landing page
      await dispatch(initializeSession);
      if (!isMonoline) await dispatch(getProductOrderFromConfig());
      await dispatch(getAllPageFlowsFromConfig({}));
    } else {
      await dispatch(initializeUsingQuery);
    }

    await dispatch(initDone());
    logBootstrapMetrics(bootstrapStartTime, isExpressPathToCheckout, failed);
  };
