import { memo, useCallback, useMemo, useState } from 'react';

import type { ListItemsProps } from '@ecp/components';
import { ListItems } from '@ecp/components';
import type { PolicyType } from '@ecp/features/sales/shared/store/types';

import { useStyles } from './DiscountsBlock.styles';

export interface DiscountsBlockProps {
  footer?: ListItemsProps['footer'];
  items?: ListItemsProps['items'];
  product?: PolicyType;
}

export const DiscountsBlock: React.FC<DiscountsBlockProps> = memo((props) => {
  const { items, footer } = props;
  const { classes } = useStyles();
  const cardClasses: ListItemsProps['classes'] = useMemo(
    () => ({
      root: classes.root,
      content: classes.content,
      horizontalDivider: classes.horizontalDivider,
    }),
    [classes.content, classes.horizontalDivider, classes.root],
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const handleToggle = useCallback(
    () => setIsExpanded((prevIsExpanded) => !prevIsExpanded),
    [setIsExpanded],
  );

  if (!items) return null;

  return (
    <ListItems
      cardClasses={cardClasses}
      items={items}
      expand={isExpanded}
      handleExpandOrCollapse={handleToggle}
      footer={footer}
      bannerItem='discounts'
    />
  );
});
