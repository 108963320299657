import { emptyObject } from '@ecp/utils/common';

import type { Answers } from '@ecp/features/sales/shared/types';

import { doesAnswerExist, isAnswerProtected, isValueMasked } from '../predicates';

/**
 * Post-processor creates the answers object gotten from SAPI ready to be saved to local state by following the rules:
 * - instead of masked values from the response payload, use unmasked ones from local state,
 * if they exist there and aren't masked, otherwise fall back to the response payload values;
 * - use protected answers from local state;
 *
 * @param answersToSave answers from the response payload
 * @param allAnswers answers from inquiry local state
 *
 * TODO Pull `makeAnswersRich` from `setAnswersApiPatchSuccess` reducer into this function
 */
export const postprocessAnswers = (
  answersToSave: Answers,
  allAnswers: Answers = emptyObject as unknown as Answers,
): Answers => {
  const answersDraft: Answers = {};

  Object.keys(answersToSave).forEach((key) => {
    if (
      isValueMasked(key, answersToSave) &&
      doesAnswerExist(key, allAnswers) &&
      !isValueMasked(key, allAnswers)
    ) {
      answersDraft[key] = allAnswers[key];

      return;
    }

    answersDraft[key] = answersToSave[key];
  });

  Object.keys(allAnswers).forEach((key) => {
    if (isAnswerProtected(key)) answersDraft[key] = allAnswers[key];
  });

  return answersDraft;
};
