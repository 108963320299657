import { useCallback, useState } from 'react';

import { trackClick } from '@ecp/utils/analytics/tracking';

import { Alert, Link } from '@ecp/components';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';
import { getProductNameFromProduct } from '@ecp/features/shared/product';

import { DeclinationDialog } from '../DeclinationDialog/DeclinationDialog';
import { getDeclinationData, useDetermineAutoProductSwitchError } from '../util';

interface Props {
  product: Product;
}

export const DeclinationNotice: React.FC<Props> = ({ product }) => {
  const declinationData = useSelector(getDeclinationData(product));
  const productName = getProductNameFromProduct(product);
  const otherPolicy = productName === 'auto' ? 'home' : 'auto';
  const hasProductSwitchError = useDetermineAutoProductSwitchError();

  const [isDeclinationDialogOpen, setDeclinationDialogOpen] = useState(false);
  const handleDeclinationDialogOpen = useCallback(() => {
    trackClick({ action: 'DeclinationDialogLinkClick', label: declinationData.quoteNumber });
    setDeclinationDialogOpen(true);
  }, [declinationData.quoteNumber]);
  const handleDeclinationDialogClose = useCallback(() => {
    setDeclinationDialogOpen(false);
  }, []);

  return (
    // TODO: Determine what content need to be rendered for the agent.
    <>
      <Alert type='warning'>
        {declinationData.extendedMessage && (
          <p>
            We were unable to build you a bundle online. Learn more about why your auto policy was
            declined&nbsp;
            <Link component='button' onClick={handleDeclinationDialogOpen}>
              here
            </Link>
            .
          </p>
        )}
        {!declinationData.extendedMessage && (
          <>
            {hasProductSwitchError ? (
              <>
                <p>As part of a recent application update, all auto quotes have been reset.</p>
                <br />
                <p>
                  Your home quote was not impacted and is available to view and purchase. We're
                  sorry for the inconvenience and appreciate your understanding.
                </p>
              </>
            ) : (
              <p>
                We were unable to build you a bundle online. Continue with your {otherPolicy} policy
                online, then give us a call for your {productName}. Learn more about why your policy
                was declined{' '}
                <Link component='button' onClick={handleDeclinationDialogOpen}>
                  here
                </Link>
                .
              </p>
            )}
          </>
        )}
      </Alert>
      {isDeclinationDialogOpen && (
        <DeclinationDialog onClose={handleDeclinationDialogClose} open product={product} />
      )}
    </>
  );
};
