import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CheckoutForm' })((theme) => ({
  root: {
    ...theme.mixins.form,
    ...theme.mixins.withRightSidebar.contentContainer,
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '0',
    },
    // Override marginTop from mixin since we don't know what the first component on the page
    // might be (in CheckoutFormBody), so margin will be managed by each component separately
    marginTop: 0,
  },
  formContainer: {
    ...theme.mixins.withRightSidebar.formContainer,
    padding: '0px 15px',
    [theme.breakpoints.up('lg')]: {
      paddingRight: '30px',
    },
  },
  carrierButtons: {
    display: 'flex',
    gap: 20,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  next: { ...theme.mixins.nextButton, margin: 0 },
  sidebarContainerRoot: {
    ...theme.mixins.withRightSidebar.sidebarContainer,
    marginTop: 70,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  agentSidebarContainerRoot: {
    marginTop: 8,
  },
  bundleSidebarContainerRoot: {
    marginTop: 60,
  },
  customizeCoverageButton: {},
}));
