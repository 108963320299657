import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PaymentTerm' })(() => ({
  root: {
    overflow: 'hidden',
    position: 'relative',
  },
  paymentOptionContainer: {
    marginTop: 20,
  },
  hideRadioButton: {
    display: 'none',
  },
}));
