import { useCallback, useRef, useState } from 'react';

import type { AccordionProps } from '@mui/material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardMedia,
  Divider,
  Grid,
} from '@mui/material';

import { Table } from '@ecp/components';
import { env } from '@ecp/env';
import { Button, Dialog } from '@ecp/features/sales/shared/components';
import type { CoverageItem } from '@ecp/features/sales/shared/types';
import {
  IconUIExclaimTriangle,
  IconUIExpandMore,
  IconUISmile,
  IconUITrophy,
  useIsMobile,
  useIsTablet,
} from '@ecp/themes/base';
import type { Option } from '@ecp/types';

import { useStyles } from './CoverageCard.styles';
import { CoverageCardItems } from './CoverageCardItems';
import metadata from './metadata';
import {
  trackCoverageChangeToggleClick,
  trackCoverageReadMoreLessClick,
  trackViewPIPClick,
} from './util';

export interface Props extends Omit<CoverageItem, 'key'> {
  key?: string | number;
  expanded?: boolean;
  unmountOnExit?: boolean;
  error?: string;
  /** Accordion items expand-collapse event */
  onChange?: AccordionProps['onChange'];
}

export const CoverageCard: React.FC<Props> = (props) => {
  const {
    displayValue,
    isCovered,
    isDeductible,
    listItems,
    field,
    subCoverages,
    expanded = false,
    unmountOnExit = false,
    primaryImage,
    socialProofText,
    isWaterBackup,
    title,
    primaryText,
    secondaryText,
    personalInjuryTable,
    error = field?.props.error,
    onChange,
  } = props;
  const { classes, cx } = useStyles(props);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const hideSubTitle = isTablet && metadata.hideSubTitleForTabletAndBelow;

  let subItemError: string | undefined;
  if (subCoverages) {
    Object.values(subCoverages).forEach((subCoverageItem) => {
      if (!subItemError) {
        // we only take the first error
        subItemError = subCoverageItem?.field?.props.error;
      }
    });
  }
  const displayError = error || subItemError;

  const handleChange = useCallback(
    async (event: React.SyntheticEvent<Element, Event>, isExpanded: boolean) => {
      trackCoverageChangeToggleClick(title, isExpanded);
      await onChange?.(event, isExpanded);
    },
    [onChange, title],
  );

  const getLabel = (key: string, list?: Option[]): Option['label'] | undefined =>
    list?.find((e) => e.value === key)?.label;
  const selectedLabel = field && getLabel(field.props.value, field.props.options);

  const ref = useRef<HTMLDivElement>(null);

  const [isHelpExpanded, setIsHelpExpanded] = useState(false);
  const handleHelpToggle = useCallback(() => {
    setIsHelpExpanded((prevIsHelpExpanded) => {
      trackCoverageReadMoreLessClick(title, prevIsHelpExpanded);

      return !prevIsHelpExpanded;
    });
  }, [title]);

  const renderListItems = (): React.ReactElement | null => {
    if (!listItems) return null;

    return (
      <ul className={classes.listItems}>
        {listItems.map((listItem, index) => (
          <li className={classes.listItem} key={index}>
            {listItem}
          </li>
        ))}
      </ul>
    );
  };

  const [showPersonalInjuryTable, setShowPersonalInjuryTable] = useState(false);
  const handleSecondarySectionClick = useCallback(() => {
    setShowPersonalInjuryTable(true);
    trackViewPIPClick(title);
  }, [title]);
  const handleSecondarySectionKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement> | undefined) => {
      if (e?.key === 'Enter' || e?.key === ' ') {
        setShowPersonalInjuryTable(true);
        trackViewPIPClick(title);
      }
    },
    [title],
  );
  const handleDialogClose = useCallback(() => setShowPersonalInjuryTable(false), []);

  const renderSecondarySection = (): React.ReactElement => (
    <>
      <div className={classes.secondaryText}>{secondaryText}</div>
      {renderListItems()}
      {personalInjuryTable && (
        <>
          <div
            className={classes.breakdownContainer}
            onClick={handleSecondarySectionClick}
            onKeyPress={handleSecondarySectionKeyPress}
            role='button'
            tabIndex={0}
          >
            <Button variant='outlineSecondary' className={classes.breakdownButton}>
              View breakdown
            </Button>
          </div>
          {showPersonalInjuryTable && (
            <Dialog
              titleText={`${title} Breakdown`}
              open={showPersonalInjuryTable}
              fullScreen
              className={classes.dialogRoot}
              onClose={handleDialogClose}
            >
              <div className={classes.dialogContent}>
                <div className={classes.personalInjuryTableContainer}>
                  <Table {...personalInjuryTable} />
                </div>
              </div>
            </Dialog>
          )}
        </>
      )}
    </>
  );

  const handleShowHideSubCoveragesClick = useCallback(() => ref.current?.click(), []);
  const shouldRenderImage = !env.static.isAgent && !isMobile && !metadata.hideImage;
  const coverageImage =
    shouldRenderImage && typeof primaryImage === 'string' ? { image: primaryImage } : null;

  return (
    <Grid item xs={12} data-name={field?.props.name}>
      {!env.static.isAgent && socialProofText && isWaterBackup && (
        <Grid container className={classes.socialProofingContainer}>
          <Grid
            item
            className={cx(
              classes.socialProofingHeader,
              !isCovered ? classes.socialProofingHeaderBlue : classes.socialProofingHeaderGreen,
            )}
          >
            {!isCovered ? (
              <>
                <IconUITrophy
                  className={cx(classes.socialProofingIcon, classes.socialProofingHeaderBlue)}
                />
                Customers like you
              </>
            ) : (
              <>
                <IconUISmile
                  className={cx(classes.socialProofingIcon, classes.socialProofingHeaderGreen)}
                />
                Smart Choice!
              </>
            )}
          </Grid>
          <p className={classes.socialProofingText}>
            {socialProofText}
            <Button
              variant='textMedium'
              onClick={handleShowHideSubCoveragesClick}
              className={classes.addCoverageButton}
            >
              Add coverage
            </Button>
          </p>
        </Grid>
      )}
      <Accordion
        className={cx(classes.root, displayError ? classes.accordionError : undefined)}
        variant='outlined'
        expanded={expanded}
        onChange={handleChange}
        TransitionProps={{ unmountOnExit }}
      >
        <>
          {displayError && (
            <Grid item xs={12} className={classes.summaryError} role='alert'>
              <IconUIExclaimTriangle className={classes.errorIcon} />
              &nbsp; {displayError}
            </Grid>
          )}
          <AccordionSummary
            classes={{
              content: classes.summaryContent,
            }}
            ref={ref}
            expandIcon={<IconUIExpandMore id={`${field?.key}_expandCoverageButton`} />}
          >
            <Grid container direction='column' wrap='nowrap'>
              <Grid container wrap='nowrap'>
                {shouldRenderImage && (
                  <Grid item>
                    <CardMedia className={classes.summaryImage} {...coverageImage} title={title}>
                      {!coverageImage && primaryImage}
                    </CardMedia>
                  </Grid>
                )}
                <Grid item className={classes.summaryTitlePanel}>
                  <p
                    className={classes.summaryTitle}
                    id={`${field?.key}-card-label`}
                    data-offset='28'
                  >
                    {title}
                  </p>

                  {!hideSubTitle && (
                    <p className={classes.summarySubTitle}>
                      {isDeductible ? 'Deductibles' : 'Coverage Limit'}
                    </p>
                  )}
                  <p
                    className={cx(classes.summaryValue, !isCovered && classes.summaryValueRejected)}
                  >
                    {displayValue || selectedLabel}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>
        </>
        <Divider className={classes.divider} />

        <AccordionDetails className={classes.detailsRoot}>
          <p className={classes.detailsContainer}>
            {primaryText}
            {isHelpExpanded && renderSecondarySection()}
            {(secondaryText || personalInjuryTable || listItems) && (
              <Button
                className={classes.detailsHelpLink}
                variant='textSmall'
                onClick={handleHelpToggle}
                aria-label={
                  isHelpExpanded ? `Read less ${primaryText}` : `Read more ${primaryText}`
                }
                id={`${field?.key}-readMore-button`}
              >
                {isHelpExpanded ? 'Read less' : 'Read more'}
              </Button>
            )}
            <CoverageCardItems field={field} subCoverages={subCoverages} title={title} />
          </p>
        </AccordionDetails>
        {subCoverages && (
          <Button
            variant='iconText'
            onClick={handleShowHideSubCoveragesClick}
            className={classes.expandSubCoveragesButton}
            id={`${field?.key}_expandSubCoveragesButton`}
          >
            {expanded ? '- Hide' : '+ Show'} Sub Coverages
          </Button>
        )}
      </Accordion>
    </Grid>
  );
};
