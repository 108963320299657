import { PagePath } from '@ecp/features/sales/shared/routing';

export interface Metadata {
  outageBannerPages: string[];
}

const metadata: Metadata = {
  outageBannerPages: [PagePath.LANDING, PagePath.PERSON, PagePath.LANDING_RETRIEVE],
};

export default metadata;
