import {
  GraphicHomeDetailPrimaryImageUrl,
  GraphicHomeDetailRentalImageUrl,
  GraphicHomeDetailSeasonalImageUrl,
  GraphicHomeDetailVacantImageUrl,
} from '@ecp/themes/base';
import type { CardOption } from '@ecp/types';

export interface Metadata {
  options: CardOption<string, string>[];
}

const metadata: Metadata = {
  options: [
    {
      label: 'Primary home (owner occupied)',
      value: 'PROPERTY.OCCUPANCY_TYPE.PRIMARY_HOME_OWNER_OCCUPIED',
      image: GraphicHomeDetailPrimaryImageUrl,
      helpText:
        'Residence principally occupied by the owner on a full-time basis for more than 6 months of the year.',
      alt: 'Primary home (owner occupied)',
    },
    {
      value: 'PROPERTY.OCCUPANCY_TYPE.SEASONAL_SECONDARY_RESIDENCE',
      label: 'Seasonal / secondary',
      image: GraphicHomeDetailSeasonalImageUrl,
      helpText: (
        <>
          {' '}
          <p>
            <strong>Secondary residence -</strong> occupied by the owner less than 6 months of the
            year.{' '}
          </p>
          <p>
            <strong>Seasonal residence -</strong> occupied only during certain times of the year
            (example: a summer home)
          </p>{' '}
        </>
      ),
      alt: 'Seasonal / secondary',
    },
    {
      value: 'PROPERTY.OCCUPANCY_TYPE.RENTED_OR_NON_OWNER_OCCUPIED',
      label: 'Rented or non-owner occupied',
      image: GraphicHomeDetailRentalImageUrl,
      helpText:
        'Principally occupied by someone other than the owner. Rent may or may not be paid by the occupant.',
      alt: 'Rented or non-owner occupied',
    },
    {
      value: 'PROPERTY.OCCUPANCY_TYPE.VACANT_NOT_OCCUPIED',
      label: 'Vacant not occupied',
      image: GraphicHomeDetailVacantImageUrl,
      helpText: 'Residence that is not furnished and not occupied by the insured.',
      alt: 'Vacant not occupied',
    },
  ],
};

export default metadata;
