import { memo } from 'react';

import { GridItem } from '@ecp/components';
import { SingleLineDetail } from '@ecp/features/sales/shared/components';
import type { CoverageItem } from '@ecp/features/sales/shared/types';

import { useStyles } from '../CoveragesForm.styles';

export interface CoverageGroup {
  title: string;
  items: CoverageItem[];
}

export const CoverageSummaryCardItem: React.FC<CoverageGroup> = memo(({ title, items }) => {
  const { classes } = useStyles();

  if (!items || !items.length) return null;

  return (
    <GridItem topSpacing='sm'>
      <h5 className={classes.CoverageSummaryItemTitle}> {title}</h5>
      {items.map(({ title, displayValue }, index) => (
        <GridItem xs={12} key={`${index} ${displayValue}`}>
          <SingleLineDetail
            text={title}
            value={displayValue}
            classes={{
              leftWrapper: classes.coverageSumLeftWrapper,
              right: classes.coverageSumRight,
            }}
          />
        </GridItem>
      ))}
    </GridItem>
  );
});
