import { useCallback, useRef, useState } from 'react';

import { Divider, Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem } from '@ecp/components';
import { useGetConditionValues, useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import { Button, Form } from '@ecp/features/sales/shared/components';
import { getHeatingSource, getHeatingSourceRefs, useForm } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import { goToFirstError } from '@ecp/features/sales/shared/utils/web';

import { HomeInteriorFormQuestions } from '../../formBody';
import { useHeatingSources } from '../../state';
import { useStyles } from './HomeInteriorForm.styles';

export interface HomeInteriorProps {
  onNext: () => Promise<void>;
}

export const HomeInteriorForm: React.FC<HomeInteriorProps> = (props) => {
  const { onNext } = props;
  const { classes } = useStyles();
  const getFields = useGetFields();
  const getInitValues = useGetInitValues();
  const conditions = useGetConditionValues();
  const heatingSourceRefs = useSelector(getHeatingSourceRefs);
  const heatingSourcesValues = useHeatingSources(heatingSourceRefs);
  const hasHeatingSource = useSelector(getHeatingSource);

  const [hasHeatingSourceError, setHasHeatingSourceError] = useState(false);

  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    conditions: conditions(),
  });

  const validateHeatingSourceValues = useCallback(() => {
    const invalidHeatSourceValues = heatingSourcesValues.filter(
      (heatingSourceValue) => !heatingSourceValue.fuelType && !heatingSourceValue.material,
    );
    const hasHeatSourceErrorExist =
      hasHeatingSource && (!heatingSourcesValues?.length || invalidHeatSourceValues?.length);
    setHasHeatingSourceError(!!hasHeatSourceErrorExist);
    if (hasHeatSourceErrorExist || goToFirstError()) {
      /** set time out is required to wait untill heating source error state to be set and scroll the window to HeatingSource error.
      Because here we are setting the error using react UseState hook. */
      setTimeout(() => {
        goToFirstError();
      }, 0);

      return false;
    }

    return true;
  }, [hasHeatingSource, heatingSourcesValues]);

  const handleSubmit = useCallback(async () => {
    const hasValidHeatingSourceValues = validateHeatingSourceValues();
    if (hasValidHeatingSourceValues && validateForm().isValid) {
      await patchFormValues();
      trackSapiAnalyticsEvent({
        element: 'choice.homeInteriorPage.saveAndContinueButton',
        event: 'click',
        eventDetail: 'true',
      });
      await onNext();
    }
  }, [validateHeatingSourceValues, validateForm, patchFormValues, onNext]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container>
          <HomeInteriorFormQuestions
            hasHeatingSourceError={hasHeatingSourceError}
            setHasHeatingSourceError={setHasHeatingSourceError}
          />
          <GridItem topSpacing='lg' xs={12}>
            <Divider />
          </GridItem>
          <GridItem topSpacing='lg' xs={12}>
            <h3>Thanks! This finishes the details on the inside of the home.</h3>
          </GridItem>
          <Grid container item xs={12}>
            <Button
              id='submit'
              variant='primary'
              onClick={handleSubmit}
              data-testid='showMyDiscounts'
              isProcessing={isPatchFormInProgress}
              classes={{ root: classes.nextButton }}
              className={classes.next}
              trackingName={GoogleAnalyticsLabels.CONTINUE}
              trackingLabel='home_interior_continue'
            >
              Show my discounts
            </Button>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};
