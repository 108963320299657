import { Typography } from '@mui/material';

import { NumberFormat } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

import { useStyles } from './LivingAreaQuestion.styles';

export const LivingAreaQuestion: React.FC<QuestionProps> = (props) => {
  const { classes } = useStyles();
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const livingArea = useHomePropertyField('livingArea');
  const { trackingName = 'square_footage', groupLabel = livingArea.question?.title } = props;

  useAddFields({ livingArea });

  if (!livingArea.exists) return null;

  return (
    <NumberFormat
      {...livingArea.props}
      groupLabel={<Typography variant='body4'>{groupLabel}</Typography>}
      id={livingArea.props.name}
      format='####'
      trackingName={trackingName}
      classes={{ input: classes.numberInput }}
    />
  );
};
