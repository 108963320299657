import { isProductRenters } from '@ecp/features/shared/product';
import { type Product } from '@ecp/features/shared/product';

import type { PaymentOption } from '../../../../types';
import { getCombinedTotal, getNumOfInstallments } from '../../../../util';
import { determineInstallmentFeeTotal } from '../../../../util/installmentFeeUtil';
import type { PaymentFieldOption } from '../../PaymentMethod/PaymentTerm';

export const createPaymentOptions = (
  paymentType: string,
  sortedPaymentOptions: PaymentOption[],
  selectedPaymentType: string | undefined,
  selectedProduct: Product,
  policyDuration: string | undefined,
): PaymentFieldOption[] => {
  const result: PaymentFieldOption[] = [];
  sortedPaymentOptions
    .filter((element) => element.paymentType === paymentType)
    .forEach((item) => {
      const numOfInstallments = getNumOfInstallments(
        item.paymentPlan,
        Number(item.downPayment),
        selectedProduct,
      );
      const totalOfInstallments = (numOfInstallments * parseFloat(item.monthlyAmount)).toFixed(2);
      const combinedTotal = getCombinedTotal(selectedProduct, item);
      const gaValues = getGaTrackingValues(selectedPaymentType || '', item.paymentPlan);
      const firstInstallmentNumberOfMonths =
        item.firstInstallmentNumberOfMonths &&
        parseFloat(item.firstInstallmentNumberOfMonths).toFixed();
      const firstInstallmentAmount =
        item.firstInstallmentAmount && `$${item.firstInstallmentAmount}`;
      const installmentFeePerPayment = determineInstallmentFeePerPayment(selectedProduct, item);
      const installmentFeeTotal = determineInstallmentFeeTotal(selectedProduct, item);

      result.push({
        option: {
          value: item.paymentPlan,
          label: item.paymentPlan,
        },
        content: {
          product: selectedProduct,
          paymentType: item.paymentType,
          policyDuration,
          downPaymentAmt: `$${item.downPayment}`,
          numOfInstallments: `${numOfInstallments}`,
          installmentAmt: `$${item.monthlyAmount}`,
          totalOfInstallments: `$${totalOfInstallments}`,
          totalAmt: `$${combinedTotal}`,
          installmentFeeTotal,
          installmentFeePerPayment: `$${installmentFeePerPayment}`,
          firstInstallmentAmount,
          firstInstallmentNumberOfMonths,
          totalPremium: `$${item.totalPremium}`,
        },
        googleAnalytics: {
          trackingNameButton: gaValues.trackingNameButton,
          trackingLabelButton: gaValues.trackingLabelButton,
          trackingLabelPaymentInfo: gaValues.trackingLabelPaymentInfo,
          trackingNamePaymentInfo: gaValues.trackingNamePaymentInfo,
          trackingNamePaymentInfoClose: gaValues.trackingNamePaymentInfoClose,
          trackingLabelPaymentInfoClose: gaValues.trackingLabelPaymentInfoClose,
          trackingNamePaymentInfoX: gaValues.trackingNamePaymentInfoX,
          trackingLabelPaymentInfoX: gaValues.trackingLabelPaymentInfoX,
        },
      });
    });

  return result;
};

const determineInstallmentFeePerPayment = (
  product: Product,
  paymentOption: PaymentOption,
): string | undefined => {
  const installmentFeePerPayment = isProductRenters(product)
    ? paymentOption.autoPayFeePerPayment
    : paymentOption.convenienceFeePerPayment;

  return installmentFeePerPayment;
};

const getGaTrackingValues = (
  type: string,
  typeOfPayment: string,
): {
  trackingNameButton: string;
  trackingLabelButton: string;
  trackingNamePaymentInfo: string;
  trackingLabelPaymentInfo: string;
  trackingNamePaymentInfoClose: string;
  trackingLabelPaymentInfoClose: string;
  trackingNamePaymentInfoX: string;
  trackingLabelPaymentInfoX: string;
} => {
  let trackingNameButton = '';
  let trackingLabelButton = '';
  let trackingNamePaymentInfo = '';
  let trackingLabelPaymentInfo = '';
  let trackingNamePaymentInfoClose = '';
  let trackingLabelPaymentInfoClose = '';
  let trackingNamePaymentInfoX = '';
  let trackingLabelPaymentInfoX = '';

  switch (type) {
    case 'EFT':
      if (typeOfPayment === 'Full') {
        trackingNameButton = 'BankAccountPayInFullButton';
        trackingLabelButton = 'BankAccountPayInFull';
      } else {
        trackingNameButton = 'eft_make_payments_button';
        trackingLabelButton = 'eft_make_payments';
      }
      trackingNamePaymentInfo = 'eft_payment_info_disclosure_link';
      trackingLabelPaymentInfo = 'eft_payment_info_disclosure';
      trackingNamePaymentInfoClose = 'eft_payment_info_disclosure_close';
      trackingLabelPaymentInfoClose = 'close';
      trackingNamePaymentInfoX = 'eft_payment_info_disclosure_x';
      trackingLabelPaymentInfoX = 'x';
      break;
    case 'CreditCard':
      if (typeOfPayment === 'Full') {
        trackingNameButton = 'cc_pay_in_full_button';
        trackingLabelButton = 'cc_pay_in_full';
      } else {
        trackingNameButton = 'cc_make_payments_button';
        trackingLabelButton = 'cc_make_payments';
      }
      trackingNamePaymentInfo = 'cc_payment_info_disclosure_link';
      trackingLabelPaymentInfo = 'cc_payment_info_disclosure';
      trackingNamePaymentInfoClose = 'cc_payment_info_disclosure_close';
      trackingLabelPaymentInfoClose = 'close';
      trackingNamePaymentInfoX = 'cc_payment_info_disclosure_x';
      trackingLabelPaymentInfoX = 'x';
      break;
    case 'CostcoVisa':
      if (typeOfPayment === 'Full') {
        trackingNameButton = 'CostcoCCPayInFullButton';
        trackingLabelButton = 'CostcoCCPayInFull';
      } else {
        trackingNameButton = 'CostcoCCMakePaymentsButton';
        trackingLabelButton = 'CostcoCCMakePayments';
      }
      trackingNamePaymentInfo = 'CostcoCCPaymentInfoDisclosureLink';
      trackingLabelPaymentInfo = 'CostcoCCPaymentInfoDisclosure';
      trackingNamePaymentInfoClose = 'CostcoCCPaymentInfoDisclosureCloseButton';
      trackingLabelPaymentInfoClose = 'CostcoCCPaymentInfoDisclosureClose';
      break;
    default:
      break;
  }

  return {
    trackingNameButton,
    trackingLabelButton,
    trackingNamePaymentInfo,
    trackingLabelPaymentInfo,
    trackingNamePaymentInfoClose,
    trackingLabelPaymentInfoClose,
    trackingNamePaymentInfoX,
    trackingLabelPaymentInfoX,
  };
};
