import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'HealthInsurerQuestion' })((theme) => ({
  root: {
    padding: '10px 3px 0 3px',
  },
  subCoveragesRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 30px',
    alignItems: 'flex-start',
    marginBottom: 7,
    position: 'relative',
  },
  divider: {
    marginBottom: 20,
  },
  healthInsurerHelpText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  healthInsurerRequirement: {
    marginTop: 8,
    fontSize: 14,
  },
  addRemoveButton: {
    color: theme.palette.primary.main,
    fontSize: 15,
    fontWeight: 'bold',
  },
  healthInsurerSet: {
    marginTop: 10,
  },
}));
