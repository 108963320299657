import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'VehicleLienHolderQuestion' })((theme) => ({
  header: {
    ...theme.typography.body4,
    marginTop: 20,
    marginBottom: 5,
  },
  // !TODO: Remove this once the global spacing changes done on RadioGroup component
  radioContainer: {
    '& .MuiFormControlLabel-root': {
      marginTop: 10,
    },
  },
}));
