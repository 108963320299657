import {
  PRIMARY_INSURED_ADDRESS_REF,
  PRIMARY_INSURED_MAILING_ADDRESS_REF,
  STATE_CODE_PREFIX,
  STATIC_INITIAL_PRIMARY_INSURED_ADDRESS_REF_FROM_RELATE,
  STATIC_INITIAL_PRIMARY_INSURED_MAILING_ADDRESS_REF_FROM_RELATE,
  STATIC_PREVIOUS_PRIMARY_INSURED_ADDRESS_REF,
  STATIC_PREVIOUS_PRIMARY_MAILING_INSURED_ADDRESS_REF,
} from '@ecp/features/sales/shared/constants';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Address, Answers } from '@ecp/features/sales/shared/types';

import { getAllValues } from '../form/selectors';
import { getAddressesValues } from '../inquiry/noun-selectors';
import { usePniRef } from './personModelUtil';

export const usePrimaryAddressRef = (): string =>
  useSelector(getAllValues)[PRIMARY_INSURED_ADDRESS_REF] as string;

export const useInitialPrimaryAddressRefFromRelate = (): string =>
  useSelector(getAllValues)[STATIC_INITIAL_PRIMARY_INSURED_ADDRESS_REF_FROM_RELATE] as string;

export const useInitialPrimaryMailingAddressRefFromRelate = (): string =>
  useSelector(getAllValues)[
    STATIC_INITIAL_PRIMARY_INSURED_MAILING_ADDRESS_REF_FROM_RELATE
  ] as string;

export const usePreviousPrimaryAddressRef = (): string =>
  useSelector(getAllValues)[STATIC_PREVIOUS_PRIMARY_INSURED_ADDRESS_REF] as string;

export const usePreviousPrimaryMailingAddressRef = (): string =>
  useSelector(getAllValues)[STATIC_PREVIOUS_PRIMARY_MAILING_INSURED_ADDRESS_REF] as string;

export const useMailingAddressRef = (): string =>
  useSelector(getAllValues)[PRIMARY_INSURED_MAILING_ADDRESS_REF] as string;

export const usePriorAddressRef = (): string => {
  const pniRef = usePniRef();

  return useSelector(getAllValues)[`${pniRef}.additionalInformation.priorAddressRefIds`] as string;
};

// FIXME: functions like this can leverage data in noun-selectors to build these objects automatically?
// i.e. export a utility function like this one, but that uses the mapping and template information
// that way the get selectors in noun-selectors will match these create functions (they would always
// be in sync/reverseable).
export const createAddressForUpdate = (address: Address): Answers => {
  const addressRef = address.ref;
  const addressToBeUpdated = {
    [`${addressRef}.city`]: address.city,
    [`${addressRef}.latitude`]: address.latitude,
    [`${addressRef}.line1`]: address.line1,
    [`${addressRef}.line2`]: address.line2,
    [`${addressRef}.longitude`]: address.longitude,
    [`${addressRef}.state`]: `${STATE_CODE_PREFIX}${address.state}`, // add prefix to match SAPI state code
    [`${addressRef}.zipcode`]: address.zipcode,
  };

  return addressToBeUpdated;
};

export const useAddressFieldValue = (addressRef: string, fieldName: string): string =>
  useSelector(getAllValues)[`${addressRef}.${fieldName}`] as string;

export const usePrimaryAddress = (): string => {
  const primaryAddressRef = usePrimaryAddressRef();
  const allValues = useSelector(getAllValues);

  const city = allValues[`${primaryAddressRef}.city`];
  const line1 = allValues[`${primaryAddressRef}.line1`];
  const line2 = allValues[`${primaryAddressRef}.line2`];
  const stateCode = allValues[`${primaryAddressRef}.state`] as string;

  if (line1 && city && stateCode) {
    const state = stateCode ? stateCode.substring(STATE_CODE_PREFIX.length, stateCode.length) : '';

    return `${line1}${line2 && line2 !== '' ? ` ${line2}` : ''}, ${city} ${state}`;
  }

  return '';
};

export const useAddressesValues = (refs: string[]): Record<string, Address> => {
  return useSelector((state: RootStore) => getAddressesValues(state, refs));
};
