export interface Metadata {
  showAcknowledgement: boolean;
  showSignature?: boolean;
  showCarrierMessaging?: boolean;
  showPolicySummarySection?: boolean;
  collectRecurringPay?: boolean;
}

const metadata: Metadata = {
  showAcknowledgement: true,
  showCarrierMessaging: true,
  showPolicySummarySection: true,
  collectRecurringPay: false,
};

export default metadata;
