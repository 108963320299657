import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const WildfireDefenseQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const wildfireDefense = useHomePropertyField('wildfireDefenseServiceInd');

  const { label = wildfireDefense.question?.title, trackingName = 'wild_fire_defense_button' } =
    props;

  if (!wildfireDefense.exists) return null;

  return (
    <RadioGroupWithOptions
      {...wildfireDefense.props}
      variant='yesNoButton'
      trackingName={trackingName}
      label={label}
    />
  );
};
