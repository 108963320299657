import { useCallback, useMemo, useState } from 'react';

import { Grid } from '@mui/material';

import { reduceSentenceList } from '@ecp/utils/common';

import { env } from '@ecp/env';
import { ProductQuoteNumber } from '@ecp/features/sales/shared/components';
import { Button, Dialog, PhoneLink } from '@ecp/features/sales/shared/components';
import {
  getDalSessionId,
  getIsBundleForOfferProductsSelected,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import type { Product } from '@ecp/features/shared/product';
import { getProductDisplayNameFromProduct } from '@ecp/features/shared/product';
import { partner } from '@ecp/partners';
import { GraphicUIAgentAvatarImageUrl } from '@ecp/themes/base';

import { getPaymentOptionsForRetry } from '../../state';
import { cleanUpFields } from '../../state/purchaseUtil/purchaseUtil';
import type { PostBindSummary, PurchaseResults } from '../../types';
import type { PaymentFields } from '../../util';
import {
  createSapiAnalyticsEvent,
  getProductErrors,
  getProductsToUpdate,
  useResetPaymentTypes,
} from '../../util';
import { CheckoutErrorPage } from '../CheckoutErrorPage';
import { useStyles } from './CheckoutRetryForm.styles';
import { CheckoutRetryPaymentForm } from './CheckoutRetryPaymentForm';

interface Props {
  currentPostBindRetryCount: number;
  fieldsByProduct: Record<Product, PaymentFields>;
  hasTokens: boolean;
  onResubmitPayment: () => Promise<void>;
  productsSummary: PostBindSummary[];
  purchaseResults: PurchaseResults;
  setShowRetryModal?: (show: boolean) => void;
  showRetryModal?: boolean;
  submittingPayment: boolean;
  hasBankPayToken: boolean;
  hasRecurringPayToken: boolean;
}

export const CheckoutRetryForm: React.FC<Props> = (props) => {
  const {
    currentPostBindRetryCount = 0,
    fieldsByProduct,
    hasTokens,
    onResubmitPayment,
    productsSummary,
    purchaseResults,
    setShowRetryModal,
    showRetryModal,
    submittingPayment,
    hasBankPayToken,
    hasRecurringPayToken,
  } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const dalSessionId = useSelector(getDalSessionId);
  const [loadingPaymentOptions, setLoadingPaymentOptions] = useState(false);
  const { resetPaymentTypes } = useResetPaymentTypes();
  const isBundle = useSelector(getIsBundleForOfferProductsSelected);

  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const handleCallUs = useCallback(() => {
    setShowPhoneNumber(true);
    trackSapiAnalyticsEvent({
      element: 'choice.postBindPage.retryPage.callUsButton',
      event: 'click',
      eventDetail: 'true',
    });
  }, [setShowPhoneNumber]);

  const openRetryModal = useCallback(async () => {
    const productsToUpdate = getProductsToUpdate(
      productsSummary,
      purchaseResults.purchased,
      fieldsByProduct,
    );
    setLoadingPaymentOptions(true);

    await dispatch(getPaymentOptionsForRetry({ dalSessionId, products: productsToUpdate }));
    // clean up fields before opening modal
    setLoadingPaymentOptions(false);
    resetPaymentTypes(productsSummary, purchaseResults.purchased);

    setShowRetryModal?.(true);
    const element = 'choice.postBindPage.retryPage.tryAgainButton';
    const showRetryProducts = true;
    const sapiAnalyticsEvent = createSapiAnalyticsEvent(
      productsSummary.map(({ product }) => product),
      purchaseResults,
      showRetryProducts,
      element,
      'click',
    );
    trackSapiAnalyticsEvent(sapiAnalyticsEvent);
  }, [
    dispatch,
    resetPaymentTypes,
    productsSummary,
    fieldsByProduct,
    purchaseResults,
    setShowRetryModal,
    dalSessionId,
  ]);

  const handleSaveAndClose = useCallback(() => {
    cleanUpFields(productsSummary, fieldsByProduct, dispatch);
    setShowRetryModal?.(false);
  }, [setShowRetryModal, dispatch, fieldsByProduct, productsSummary]);

  const handleClose = useCallback(() => {
    cleanUpFields(productsSummary, fieldsByProduct, dispatch);
    setShowRetryModal?.(false);
  }, [setShowRetryModal, dispatch, fieldsByProduct, productsSummary]);

  const productsList = productsSummary
    .map(({ productName }) => productName)
    .sort()
    .reduce(reduceSentenceList(productsSummary.length), '');

  const reducedErrorMessages = useMemo(
    () => getProductErrors(purchaseResults.errorMessages),
    [purchaseResults.errorMessages],
  );

  return (
    <Grid container justifyContent='center' className={classes.root}>
      {currentPostBindRetryCount < 3 ? (
        <div>
          <h1 className={env.static.isAgent ? classes.headerWithQuoteNumber : classes.header}>
            We’re sorry, something went wrong.
          </h1>
          <div className={classes.subHeader}>
            <ProductQuoteNumber />
          </div>
          <div className={classes.helpBoxContainer}>
            <img
              src={GraphicUIAgentAvatarImageUrl}
              className={classes.helpBoxIcon}
              alt='helpBoxIcon'
            />

            <div className={classes.mariaBox} data-testid='mariaBoxHelpText'>
              {reducedErrorMessages ? (
                reducedErrorMessages.map(([product, message], idx) => (
                  <p key={`postbind-error-msg-${idx}`} className={classes.mariaBoxContent}>
                    {!!product && isBundle && (
                      <span className={classes.mariaBoxBold}>
                        {getProductDisplayNameFromProduct(product)} policy:{' '}
                      </span>
                    )}
                    {message}
                  </p>
                ))
              ) : (
                <p className={classes.mariaBoxContent}>
                  You can re-submit your information or speak to one of our licensed agents to
                  assist you. Don’t worry, you’ll only be charged once for your policy.
                </p>
              )}
              <div>
                <Button
                  variant='primary'
                  onClick={openRetryModal}
                  className={classes.tryAgainButton}
                  isProcessing={loadingPaymentOptions}
                  data-testid='postBindTryAgainButton'
                >
                  Try again
                </Button>
              </div>
              <div data-testid='TopPhoneNumberLink' className={classes.phone}>
                {!showPhoneNumber ? (
                  <PhoneLink
                    key='a'
                    withIcon
                    withLinkStyle
                    number='Call us'
                    onClick={handleCallUs}
                    trackingName='CallUsLink'
                    trackingLabel='PhoneNumber'
                  />
                ) : (
                  <PhoneLink
                    key='b'
                    withIcon
                    withLinkStyle
                    number={partner.shared.servicePhoneNumber}
                  />
                )}
              </div>
            </div>
          </div>
          <Dialog
            fullScreen
            open={!!showRetryModal}
            titleText={`Re-submit your ${productsList} payment information`}
            actionButtonOnClick={handleSaveAndClose}
            onClose={handleClose}
            className={classes.dialogRoot}
            trackingNameButton='CustomizeQuotesCloseButton'
            trackingLabelButton='Close'
            trackingNameCloseIcon='CustomizeQuotes_X'
            trackingLabelCloseIcon='X'
          >
            <CheckoutRetryPaymentForm
              onResubmitPayment={onResubmitPayment}
              hasTokens={hasTokens}
              submittingPayment={submittingPayment}
              productsSummary={productsSummary}
              purchaseResults={purchaseResults}
              hasBankPayToken={hasBankPayToken}
              hasRecurringPayToken={hasRecurringPayToken}
            />
          </Dialog>
        </div>
      ) : (
        <CheckoutErrorPage />
      )}
    </Grid>
  );
};
