import { memo, useEffect } from 'react';

import { Grid } from '@mui/material';

import { isKeyOf } from '@ecp/utils/common';

import { GridItem } from '@ecp/components';
import { env } from '@ecp/env';
import { Link } from '@ecp/features/sales/shared/components';
import {
  getDalSessionId,
  getLobForOfferProductsSelected,
  getOfferSetId,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';
import { getProductNameFromProduct } from '@ecp/features/shared/product';

import { fetchEnrollmentsResponse, getEnrollments, getPurchaseResponse } from '../../../../state';
import { useStyles } from './AccessPoliciesOnline.styles';
import metadata from './metadata';

export const AccessPoliciesOnline: React.FC = memo(() => {
  const enrollments = useSelector(getEnrollments);
  const dispatch = useDispatch();
  const dalSessionId = useSelector(getDalSessionId);
  const offerSetId = useSelector(getOfferSetId);
  const purchaseResponse = useSelector(getPurchaseResponse);
  const lob = useSelector(getLobForOfferProductsSelected);
  const selectedMetadata = isKeyOf(lob, metadata) ? metadata[lob] : metadata.default;

  const products = Object.keys(enrollments);
  const { classes } = useStyles({ productLength: products.length });

  useEffect(() => {
    dispatch(fetchEnrollmentsResponse({ dalSessionId, offerSetId }));
  }, [dispatch, purchaseResponse, dalSessionId, offerSetId]);

  // Filter products to only those with a valid enrollUrl
  const validProducts = products.filter((product) => {
    const { enrollUrl } = enrollments[product] || {};

    return enrollUrl;
  });
  if (!validProducts.length) return null;

  const renderLink = (product: Product): JSX.Element => {
    const { enrollUrl, policyNumber } = enrollments[product] || {};

    const productName = getProductNameFromProduct(product);

    return (
      <Link
        analyticsElement='choice.postBindPage.viewPolicyOnline'
        analyticsEventDetail={`Policynumber: ${policyNumber}, Product: ${product}`}
        href={enrollUrl}
        target='_blank'
        trackingName={`access_${productName}_policy_online`}
        trackingLabel={`Policynumber: ${policyNumber}, Product: ${product}`}
      >
        Access your {productName} policy online
      </Link>
    );
  };
  const links = !env.static.isAgent && (
    <ul className={classes.linkContainer}>
      {validProducts.map((product) => (
        <li className={classes.listItem} key={product}>
          {renderLink(product)}
        </li>
      ))}
    </ul>
  );

  return (
    <Grid className={classes.root} container>
      <GridItem className={classes.informationCard}>
        <h3 className={classes.columnContentHeader}>{selectedMetadata?.columnContentHeader}</h3>
        <p>{selectedMetadata?.columnContentText}</p>
        {links}
      </GridItem>
    </Grid>
  );
});
