import { isMoreThan60DaysFromToday, isPastDate, isToday } from '@ecp/utils/date';

import { calculateMaxPolicyStartDaysLimit } from '@ecp/features/sales/shared/store';
import type { Product } from '@ecp/features/shared/product';
import {
  isConnectAutoProduct,
  isProductAuto,
  isProductHome,
  isProductRenters,
} from '@ecp/features/shared/product';

export const getStartDateFieldText = (
  product: Product,
  policyEffectiveDateParam?: string,
): string => {
  const maxDays = calculateMaxPolicyStartDaysLimit({
    isAutoProduct: isProductAuto(product),
    isConnectAutoProduct: isConnectAutoProduct(product),
  });
  if (isProductRenters(product)) {
    if (
      policyEffectiveDateParam &&
      (isToday(policyEffectiveDateParam) || isPastDate(policyEffectiveDateParam))
    ) {
      return `Cannot select today's date. To maximize protection, the date selected should be the day your lease starts or when your current policy expires. Start date must be within 60 days of today’s date.`;
    }

    return `Cannot select today's date. To maximize protection, the date selected should be the day your lease starts or when your current policy expires.`;
  }
  if (isProductHome(product))
    return `Cannot select today's date. Choose a date within the next ${maxDays} days. To maximize protection, the date selected should be on or before the day of closing or when your current policy expires.`;

  if (isProductAuto(product))
    return `Cannot select today's date. Choose a date within the next ${maxDays} days. To maximize protection, the date selected should be on or before your current policy expires.`;

  return '';
};

export const getStartDateHelpText = (
  product: Product,
  policyEffectiveDateParam: string,
): string => {
  if (
    isProductRenters(product) &&
    (isToday(policyEffectiveDateParam) || isPastDate(policyEffectiveDateParam))
  )
    return 'Tomorrow is the earliest we can start your policy';

  if (isProductRenters(product) && isMoreThan60DaysFromToday(policyEffectiveDateParam))
    return 'Your lease start date is over 60 days away. You can still get a quote but will need to return within 60 days of your start date to purchase.';

  return '';
};
