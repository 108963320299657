import { useEffect } from 'react';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { NumberFormat } from '@ecp/components';
import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

import type { QuestionProps } from '../../questionProps.types';

interface Props extends QuestionProps {
  personRef: string;
  disabled?: boolean;
  fullWidth?: boolean;
  required?: boolean;
  label?: string;
}

export const PhoneNumberQuestion: React.FC<Props> = (props) => {
  const {
    id = 'Phone',
    helperText,
    trackingName = 'phone',
    personRef,
    disabled,
    fullWidth = true,
    required = false,
    label,
  } = props;
  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const phone = usePersonField('phone');
  useAddFields({ phone });
  useAddConditionValues({
    isRequiredOverride: () => required,
    conditionalFields: [phone],
  });
  useEffect(() => {
    if (phone.value == null) {
      const elements = document.getElementsByName(`${personRef}.phone`);
      elements.forEach((element) => {
        if (element instanceof HTMLInputElement) {
          element.value = '';
          element.defaultValue = '';
        }
      });
    }
  }, [phone, personRef]);

  if (!phone.exists) return null;

  return (
    <NumberFormat
      {...phone.props}
      type='tel'
      pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}'
      autoComplete='tel-national'
      data-testid='phoneNumber'
      disabled={disabled}
      formatType='phone'
      fullWidth={fullWidth}
      helperText={helperText}
      id={id}
      label={label}
      mask='_'
      trackingLabel={GoogleAnalyticsLabels.REDACTED}
      trackingName={trackingName}
    />
  );
};
