import { useMemo, useRef } from 'react';

import { trackDefault } from '@ecp/utils/analytics/tracking';
import { isEqual } from '@ecp/utils/common';

import {
  buildPropertyCoverages,
  getOfferProductsSelectedByType,
  getPrimaryInsuredStateCode,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { CoverageItem, PropertyCoverages } from '@ecp/features/sales/shared/types';
import { getProductDisplayNameFromProduct } from '@ecp/features/shared/product';

import { useCoveragesFormValidationFactory } from '../util/useCoveragesFormValidationFactory';
import metadata from './metadata';
import type { PropertyCoveragesFormProps } from './types';

let prevTrackingPayload: Partial<Record<keyof PropertyCoverages, Record<string, string>>> = {};

/** Logs property coverages object initially and on every change to it. */
const usePropertyCoveragesAnalytics = (propertyCoverages: PropertyCoverages | null): void => {
  const { property: propertyOfferProduct } = useSelector(getOfferProductsSelectedByType);
  if (!propertyCoverages || !propertyOfferProduct) return;

  const productDisplayName = getProductDisplayNameFromProduct(propertyOfferProduct);
  const coverageGroupKeys = Object.keys(metadata[propertyOfferProduct].trackingNames);
  const trackingPayload = coverageGroupKeys.reduce((acc, key) => {
    acc[key] = propertyCoverages[key].reduce((accItem, { title, field }) => {
      if (title && field?.value) accItem[title] = String(field.value);

      return accItem;
    }, {} as Record<CoverageItem['title'], string>);

    return acc;
  }, {} as typeof prevTrackingPayload);

  coverageGroupKeys.forEach((key) => {
    if (!isEqual(prevTrackingPayload[key], trackingPayload[key])) {
      const trackingName = `${productDisplayName}${metadata[propertyOfferProduct].trackingNames[key]}`;
      trackDefault({
        action: `Default${trackingName}`,
        label: JSON.stringify(trackingPayload[key]),
      });
    }
  });

  prevTrackingPayload = trackingPayload;
};

export const usePropertyCoveragesForm = (
  props: Pick<PropertyCoveragesFormProps, 'fields' | 'onFormValidChange'>,
): {
  propertyCoverages: PropertyCoverages | null;
} => {
  const { fields, onFormValidChange } = props;
  const initValues = useRef({});
  const { property: propertyOfferProduct } = useSelector(getOfferProductsSelectedByType);
  const { handleCoverageItemChange } = useCoveragesFormValidationFactory(
    initValues,
    fields,
    onFormValidChange,
  );

  const stateCode = useSelector(getPrimaryInsuredStateCode);

  /** Property coverages and deductibles */
  const propertyCoverages = useMemo(
    () =>
      fields && propertyOfferProduct
        ? buildPropertyCoverages(propertyOfferProduct, fields, stateCode, handleCoverageItemChange)
        : null,
    [fields, propertyOfferProduct, stateCode, handleCoverageItemChange],
  );

  // Analytics
  usePropertyCoveragesAnalytics(propertyCoverages);

  return {
    propertyCoverages,
  };
};
