import { memo, useCallback } from 'react';

import { trackClick } from '@ecp/utils/analytics/tracking';

import type { Item } from '@ecp/components';
import { Button, GridItem } from '@ecp/components';
import { useNavigateToPage } from '@ecp/features/sales/shared/routing';
import type { PolicyType } from '@ecp/features/sales/shared/store/types';
import { IconUIEdit } from '@ecp/themes/base';

import { CoveragesSummaryCard } from '../CoveragesSummaryCard';
import type { CoverageGroup } from '../CoveragesSummaryCard/CoverageSummaryCardItem';
import { DiscountsBlock } from '../DiscountsBlock';
import { VehicleDriverSummary } from '../VehicleDriverSummary';
import { useStyles } from './CoveragesSummary.styles';

export interface CoveragesSummaryProps {
  summaryType: 'coverages' | 'discounts' | 'vehicleDriver';
  title: string;
  coverageGroups?: CoverageGroup[];
  discountItems?: Item[];
  trackingName?: string;
  product?: PolicyType;
  path?: string;
}

export const CoveragesSummary: React.FC<CoveragesSummaryProps> = memo(
  ({ summaryType, title, coverageGroups, discountItems, trackingName, product, path = '' }) => {
    const { classes, cx } = useStyles();

    const navigateToEditCoveragePage = useNavigateToPage(path);
    const navigateToEditCoverage = useCallback(async () => {
      await navigateToEditCoveragePage();
      trackClick({ action: trackingName, label: `Sent to ${path}` });
    }, [navigateToEditCoveragePage, trackingName, path]);

    if (summaryType === 'discounts' && !discountItems?.length) return null;

    return (
      <GridItem
        topSpacing={summaryType === 'discounts' ? 'xs' : 'lg'}
        xs={12}
        className={cx(classes.addSpacing, classes.addSpacingVert)}
      >
        <GridItem className={classes.coveragesSummaryHeader}>
          <h3 data-testid={title}>{title}</h3>
          {path && (
            <Button
              onClick={navigateToEditCoverage}
              icon={<IconUIEdit />}
              iconLast
              variant='iconTextMedium'
            >
              Edit
            </Button>
          )}
        </GridItem>
        {summaryType === 'vehicleDriver' && (
          <GridItem className={classes.coveragesSummaryBlock}>
            <VehicleDriverSummary />
          </GridItem>
        )}
        {summaryType === 'discounts' && (
          <GridItem className={cx(classes.coveragesSummaryBlock, classes.discountsSummaryBlock)}>
            <DiscountsBlock items={discountItems} product={product} />
          </GridItem>
        )}
        {summaryType === 'coverages' && coverageGroups?.length && (
          <GridItem className={classes.coveragesSummaryBlock}>
            <CoveragesSummaryCard coverageGroups={coverageGroups} />
          </GridItem>
        )}
      </GridItem>
    );
  },
);
