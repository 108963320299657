import { env } from '@ecp/env';
import { makeStyles } from '@ecp/themes/base';

const mobileWrapperHeight = 44;
const drawerPaperPadding = 15;
export const useStyles = makeStyles<void, 'drawerPaper'>({ name: 'Navbar' })(
  (...[theme, , classes]) => ({
    root: {
      display: 'flex',
      margin: 0,
      ...(env.static.isAgent && {
        [theme.breakpoints.up('lg')]: {
          padding: '40px 30px',
          position: 'sticky',
          left: 0,
          top: 64,
        },
        [`& .${classes.drawerPaper}`]: {
          padding: 0,
          width: 274,
        },
      }),
    },
    drawerButton: {
      height: 'auto',
      width: '100%',
      justifyContent: 'flex-start',
      textDecoration: 'none',
    },
    drawerButtonIcon: {
      ...theme.mixins.setColorSvg(theme.palette.text.link),
      fontSize: 16,
    },
    drawerButtonWrapper: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
    },
    drawerEditButton: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 15,
      minHeight: mobileWrapperHeight,
      width: '100%',
      // This is needed to support a requirement of other than paper container background color
      // taking the full width of the paper container
      '&:after': {
        backgroundColor: theme.palette.grey[200],
        content: '""',
        display: 'block',
        position: 'absolute',
        left: -100,
        right: -100,
        top: 0,
        bottom: 0,
        zIndex: -1,
      },
    },
    drawerPaper: {
      padding: `15px ${drawerPaperPadding}px`,
      [theme.breakpoints.only('md')]: {
        padding: `${drawerPaperPadding}px ${2 * drawerPaperPadding}px`,
      },
      backgroundColor: theme.palette.primary.background,
      backgroundPosition: 'bottom',
      backgroundSize: '100% auto',
      borderRadius: 8,
      border: 'none',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      [theme.breakpoints.up('lg')]: {
        width: 304,
      },
      [theme.breakpoints.down('lg')]: {
        borderRadius: 0,
        paddingTop: 0,
      },
    },
    drawerText: { ...theme.typography.body1Bold, color: theme.palette.text.link },
    headerSpacer: {
      minHeight: env.static.isAgent ? theme.appVars.appHeaderHeightAgent : 105,
      [theme.breakpoints.down('lg')]: {
        minHeight: env.static.isAgent ? theme.appVars.appHeaderHeightAgent : 70,
      },
    },
    mobileWrapper: {
      ...theme.mixins.form,
      backgroundColor: theme.palette.grey[200],
      height: mobileWrapperHeight,
      margin: 0,
      width: '100%',
    },
    nav: {
      width: '100%',
    },
    swipeableDrawerRoot: {
      zIndex: 1200,
      [`& .${classes.drawerPaper}`]: {
        paddingTop: 0,
        borderRadius: 0,
      },
    },
  }),
);
