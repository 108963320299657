import { useEffect, useState } from 'react';

import { useIdleTimer } from 'react-idle-timer';

import { useEvent } from '@ecp/utils/react';

import { renewSession } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';

import { addContinueSessionAnalytics, addGetQuoteAnalytics } from './util';

// Set a 1 hour idle timeout in milliseconds
const timeout = 1000 * 60 * 60 * 1;

/**
 * promptBeforeIdle is a time in milliseconds that tells the idle timer after how long to prompt the
 * user. we're going to use this in the opposte way. We want to prompt the user after 58 minutes of
 * inactivity so we'll substract the timout by this to get the real promptBeforeIdle.
 */
const promptBeforeIdleTime = 1000 * 60 * 58;

export const useSessionTimer = ({
  enableTimer = false,
  onSessionExpiryClick,
}: {
  enableTimer: boolean;
  onSessionExpiryClick: () => unknown;
}): {
  showTimer: boolean;
  isSessionIdle: boolean;
  remaining: number;
  handleStillHere: () => Promise<void>;
  handleSessionExpiry: () => Promise<void>;
  handleContinueSession: (closeButton?: boolean) => Promise<void>;
  handleGetQuote: (eventDetail: string, closeButton?: boolean) => Promise<void>;
} => {
  const [showTimer, setShowTimer] = useState(false);
  const [isSessionIdle, setIsSessionIdle] = useState(false);
  const stopOnIdle = true;
  const dispatch = useDispatch();
  /**
   * This will trigger after 58 minutes of inactivity
   */
  const onPrompt = (): void => {
    setIsSessionIdle(false);
    setShowTimer(true);
  };

  const onAction = (): void => {
    setIsSessionIdle(false);
  };

  const onIdle = (): void => {
    setIsSessionIdle(true);
  };

  const [remaining, setRemaining] = useState<number>(timeout);

  const { getRemainingTime, reset } = useIdleTimer({
    timeout,
    promptBeforeIdle: timeout - promptBeforeIdleTime,
    onPrompt,
    onAction,
    onIdle,
    stopOnIdle,
    disabled: !enableTimer,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = useEvent(async () => {
    await dispatch(renewSession());
    setShowTimer(false);
    reset();
    setRemaining(0);
  });

  const handleSessionExpiry = useEvent(async () => {
    setShowTimer(false);
    await onSessionExpiryClick();
    reset();
  });
  const handleContinueSession = useEvent(async (closeButton?: boolean) => {
    handleStillHere();
    addContinueSessionAnalytics(closeButton);
  });

  const handleGetQuote = useEvent(async (eventDetail: string, closeButton?: boolean) => {
    handleSessionExpiry();
    addGetQuoteAnalytics(eventDetail, closeButton);
  });

  return {
    showTimer,
    isSessionIdle,
    remaining,
    handleStillHere,
    handleSessionExpiry,
    handleContinueSession,
    handleGetQuote,
  };
};
