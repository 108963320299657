import { useCallback, useMemo, useState } from 'react';

import { cloneDeep, merge } from '@ecp/utils/common';

import type { Answers, Condition, FieldRefEntry } from '@ecp/features/sales/shared/types';
import type { Fields } from '@ecp/types';

import type { PageState } from './pageContext';
import { PageContext } from './pageContext';

/** @deprecated This is really form provider. To be rewritten with our forms handling solution altogether. */
export const PageProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [refs, setRefs] = useState<FieldRefEntry[]>([]);
  const [fields, setFields] = useState<Fields>({});
  const [initValues, setInitValues] = useState<Answers>({});
  const [conditionValues, setConditionValues] = useState<Condition[]>([]);

  const addRef: PageState['addRef'] = useCallback(
    (refEntry) => {
      if (!refEntry.name || refEntry.name.includes('undefined')) return;
      const existing = refs.findIndex((ref) => ref.name === refEntry.name);
      if (existing >= 0) {
        refs.splice(existing);
      }

      refs.push(refEntry);
    },
    [refs],
  );
  const getRefs = useCallback(() => refs, [refs]);
  const removeRefs = useCallback(() => {
    setRefs([]);
  }, []);

  /**
   * Used to add fields to the context that would be used to register the different questions on the form
   * In case of a duplicate addition of a field it will be automatically replaced with the new one
   */
  const addField: PageState['addField'] = useCallback(
    (field) => {
      // fields consists of all different questions and their corresponding field selectors registered with question key
      Object.keys(field).forEach((key) => {
        // @ts-ignore Either types need to be rewritten or this statement
        fields[key] = field[key];
      });
    },
    [fields],
  );
  const getFields = useCallback(() => fields, [fields]);
  const removeFields = useCallback(() => {
    setFields({});
  }, []);
  const removeByFields: PageState['removeByFields'] = useCallback(
    (field) => {
      Object.keys(field).forEach((key) => {
        if (fields[key]) {
          // @ts-ignore Either types need to be rewritten or this statement
          delete fields[key];
        }
      });
    },
    [fields],
  );

  /**
   * Add init values to the page provider context for initial values of a question.
   *
   * In case of multiple renders the initial state value is merged with the values we invoke the hook with.
   * initValues: type of 'Answers'
   *  */
  const addInitValues: PageState['addInitValues'] = useCallback(
    (answers) => {
      // For now, we want to keep the mutation happening from `merge()` below
      // so other uses of `addInitValues()` get the updated value. But we need to
      // use `cloneDeep()` so we actually update state instead of just mutating it.
      // It's not great, but works for now until we rewrite forms.
      setInitValues(cloneDeep(merge(initValues, answers)));
    },
    [initValues],
  );
  const getInitValues = useCallback(() => initValues, [initValues]);
  const removeInitValues = useCallback(() => {
    setInitValues({});
  }, []);
  const getConditionValues = useCallback(() => conditionValues, [conditionValues]);
  const removeConditionValues = useCallback(() => {
    setConditionValues([]);
  }, []);

  /**
   * Add condition values to the page provider context to handle any conditional overrides.
   *
   * In case of multiple renders where the hook is called with the same condition key more than once,
   * we replace the existing with the new key, else we push the state directly
   *  */
  const addConditionValues: PageState['addConditionValues'] = useCallback(
    (condition) => {
      const existing = conditionValues.findIndex((conditions) => {
        return conditions.conditionalFields[0].key === condition.conditionalFields[0].key;
      });
      if (existing >= 0) {
        // Replace the existing condition with the new one for the same key
        conditionValues.splice(existing, 1, condition);
      } else {
        // push to the array if it doesn't exist
        conditionValues.push(condition);
      }
    },
    [conditionValues],
  );

  const providerState: PageState = useMemo(
    () => ({
      initValues,
      addRef,
      getRefs,
      removeRefs,
      addField,
      getFields,
      removeFields,
      removeByFields,
      addInitValues,
      getInitValues,
      removeInitValues,
      addConditionValues,
      removeConditionValues,
      getConditionValues,
    }),
    [
      addField,
      addInitValues,
      addRef,
      getFields,
      getInitValues,
      getRefs,
      initValues,
      removeFields,
      removeByFields,
      removeInitValues,
      removeRefs,
      addConditionValues,
      removeConditionValues,
      getConditionValues,
    ],
  );

  return <PageContext.Provider value={providerState}>{children}</PageContext.Provider>;
};
