import { useCallback, useLayoutEffect } from 'react';

import { Divider, Grid, Typography } from '@mui/material';

import { navigate } from '@ecp/utils/routing';

import { Button, Page } from '@ecp/features/sales/shared/components';
import { startOverFlow } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import { GraphicError404PageNotFoundImageUrl } from '@ecp/themes/base';

import { useStyles } from './ErrorNotFoundPage.styles';

const PAGE_NOT_FOUND_ERROR = 'PAGE_NOT_FOUND';

export const ErrorNotFoundPage: React.FC = () => {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();

  const navigateToLandingPage = useCallback(() => {
    dispatch(startOverFlow(true));
  }, [dispatch]);

  useLayoutEffect(() => {
    navigate({ hash: PAGE_NOT_FOUND_ERROR }, { replace: true });
  }, []);

  return (
    <div className={classes.root}>
      <Page>
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          className={classes.containerWrapper}
        >
          <Grid item lg={6} className={cx(classes.container, classes.headerContainer)}>
            <h1 data-testid='pageNotFoundHeader' className={classes.pageTitle}>
              Well, this is unexpected...
            </h1>
            <Typography data-testid='pageNotFoundSubHeader' variant='subtitle'>
              We can’t seem to find the page you’re looking for.
            </Typography>
            <Divider aria-hidden='true' className={classes.divider} />
            <Button
              className={classes.homepageButton}
              variant='primary'
              type='submit'
              data-testid='goToHomepageButton'
              aria-label='Go to Homepage'
              onClick={navigateToLandingPage}
            >
              Go to homepage
            </Button>
          </Grid>
          <Grid item lg={6} className={cx(classes.container, classes.imageContainer)}>
            <img
              src={GraphicError404PageNotFoundImageUrl}
              alt='Not found'
              className={classes.errorImage}
            />
          </Grid>
        </Grid>
      </Page>
    </div>
  );
};
