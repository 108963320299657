import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Divider, FormControlLabel, Grid } from '@mui/material';

import { trackClick, trackRender } from '@ecp/utils/analytics/tracking';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { GridItem, HelpBox } from '@ecp/components';
import { env } from '@ecp/env';
import { useAddConditionValues } from '@ecp/features/sales/form';
import { Checkbox, Popover, RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import {
  getLobForOfferProductsSelected,
  getOfferProductsSelected,
  updateAnswers,
  useField,
} from '@ecp/features/sales/shared/store';
import type { OfferInfo, RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product, ProductName } from '@ecp/features/shared/product';
import { LineOfBusiness } from '@ecp/features/shared/product';
import { GraphicUIAgentAvatarImageUrl, useIsMobile } from '@ecp/themes/base';

import { AutoPayProductAlertMetadata } from '../../../metadata/CheckoutPage';
import {
  getAvailablePaymentTypesForProduct,
  getPaymentTypeForProduct,
  setPaymentPlan,
  setPaymentType,
} from '../../../state/paymentoptions';
import { setPaymentMethod } from '../../../state/purchase';
import type { PaymentOption, PaymentOptions } from '../../../types';
import { useGetSelectedProductsCheckoutFields } from '../../../util';
import { BankPayment } from '../PaymentMethod/BankPayment';
import { CreditCardPayment } from '../PaymentMethod/CreditCardPayment';
import { MortgagePayment } from '../PaymentMethod/MortgagePayment';
import { PolicyDifference } from '../PolicyDifference';
import { useStyles } from './CheckoutPaymentMethod.styles';
import metadata from './metadata';
import { TipContent } from './TipContent';
import { createPaymentOptions } from './utils/createPaymentOptions';
import { getPaymentMethodOptions } from './utils/getPaymentMethodOptions';

export interface CheckoutPaymentMethodProps {
  label: string;
  selectedProduct: Product;
  offerDetails: OfferInfo;
  paymentOptions: PaymentOptions;
  policyStartDate: string;
  coverageType: ProductName;
  handleReuseAutoPaymentCheck: (
    event: React.ChangeEvent<HTMLInputElement>,
    newChecked: boolean,
  ) => void;
  reuseAutoPayment: boolean;
  syncPropertyToAuto?: (newType: string) => void;
  isConnectAutoCC?: boolean;
  hasTokens: boolean;
  allowPaymentAlert?: boolean;
  hasBankPayToken: boolean;
}

export const CheckoutPaymentMethod: React.FC<CheckoutPaymentMethodProps> = (props) => {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const {
    label,
    selectedProduct,
    offerDetails,
    paymentOptions,
    policyStartDate,
    coverageType,
    handleReuseAutoPaymentCheck,
    reuseAutoPayment,
    syncPropertyToAuto,
    isConnectAutoCC,
    hasTokens,
    allowPaymentAlert,
    hasBankPayToken,
  } = props;
  const isConnect = selectedProduct.includes('connect');

  const availablePaymentTypesForProduct = useSelector((state: RootStore) =>
    getAvailablePaymentTypesForProduct(state, selectedProduct),
  );

  const methodOptions = useMemo(
    () => getPaymentMethodOptions(availablePaymentTypesForProduct, coverageType),
    [availablePaymentTypesForProduct, coverageType],
  );
  const productPaymentType = useSelector((state: RootStore) =>
    getPaymentTypeForProduct(state, selectedProduct),
  );
  const paymentMethodType = useField(`static.checkout.${coverageType}.paymentMethod`);
  const autoPay = useField('discount.auto.autoPay');
  const lineOfBusiness = useSelector(getLobForOfferProductsSelected);
  const offerProductsSelected = useSelector(getOfferProductsSelected);
  const paymentFields =
    useGetSelectedProductsCheckoutFields(offerProductsSelected)[selectedProduct];

  const {
    accountNumber,
    routingNumber,
    creditCardCardNumber,
    creditCardExpirationDate,
    creditCardFullName,
  } = paymentFields || {};

  const { policyDuration } = offerDetails;
  const { carrierPaymentOptions } = paymentOptions;

  // ADVECP-1496 AMFAM ADV has requirement to create an extra paymentPlan option "Custom"
  // Note:- SAPI is not sending as part of paymentOptions
  const finalCarrierPaymentOptions: PaymentOption[] = carrierPaymentOptions.some(
    (option) =>
      (option.paymentPlan === 'Twelve Months' || option.paymentPlan === 'Six Months') &&
      option.eligibleForCustomDownPayment,
  )
    ? carrierPaymentOptions.reduce<PaymentOption[]>((acc, option) => {
        if (
          (option.paymentPlan === 'Twelve Months' || option.paymentPlan === 'Six Months') &&
          option.eligibleForCustomDownPayment
        ) {
          acc.push({ ...option, paymentPlan: 'Custom' });
        }
        acc.push(option);

        return acc;
      }, [])
    : carrierPaymentOptions;

  const sortedCarrierPaymentOptions: PaymentOption[] = [
    ...finalCarrierPaymentOptions.filter((option) => option.paymentPlan === 'Full'),
    ...finalCarrierPaymentOptions.filter(
      (option) => option.paymentPlan !== 'Full' && option.paymentPlan !== 'Custom',
    ),
    ...finalCarrierPaymentOptions.filter((option) => option.paymentPlan === 'Custom'),
  ];
  const creditCardPayment = sortedCarrierPaymentOptions.find(
    (option) => option.paymentType === 'CreditCard',
  );
  const creditCardUrl = creditCardPayment ? creditCardPayment.creditCardUrl : '';
  const checkoutPageRedesign = flagValues[FeatureFlags.CHECKOUT_PAGE_REDESIGN];
  // only sync property payment selections and values with auto if this payment method is auto
  const shouldSyncPropertyToAuto = reuseAutoPayment && coverageType === 'auto';

  // re-use auto payment checkbox only shows for bundle, only above property payment,
  // and do not show for Connect if using credit card because they validate on their end
  const bundlePropertyPayment =
    ((lineOfBusiness === LineOfBusiness.BUNDLE && coverageType === 'home') ||
      (lineOfBusiness === LineOfBusiness.BUNDLE_AUTO_RENTERS && coverageType === 'renters')) &&
    !isConnectAutoCC;

  const [showMessage, setShowMessage] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const radioGroupRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();

  const handleCloseMessage = useCallback(() => {
    setAnchorEl(null);
    setShowMessage(false);
    trackClick({ action: 'auto_pay_warning_message_x', label: 'x' });
  }, [setShowMessage, setAnchorEl]);

  const handlePaymentMethodSelect = useCallback(
    async (value: string) => {
      if (
        (allowPaymentAlert &&
          autoPay.value &&
          AutoPayProductAlertMetadata[selectedProduct] &&
          (value === 'CreditCard' || value === 'CostcoVisa')) ||
        (env.static.isAgent &&
          autoPay.value &&
          AutoPayProductAlertMetadata[selectedProduct] &&
          (value === 'CreditCard' || value === 'CostcoVisa' || value === 'EFT'))
      ) {
        setAnchorEl(radioGroupRef.current);
        setShowMessage(true);
        trackRender({ action: 'auto_pay_warning_message', label: 'auto_pay_warning' });
      }
      dispatch(setPaymentType({ product: selectedProduct, paymentType: value }));
      // update paymentMethod for purchase
      const selectedMethod = methodOptions?.find((element) => element.value === value);
      if (selectedMethod?.key) {
        dispatch(
          setPaymentMethod({
            product: selectedProduct,
            paymentMethod: selectedMethod.key,
          }),
        );
      }
      if (finalCarrierPaymentOptions) {
        let selectedOption = null;
        if (value === 'CreditCard' || value === 'CostcoVisa') {
          selectedOption = finalCarrierPaymentOptions.find(
            (e) => e.paymentType === 'CreditCard' && e.paymentPlan === 'Full',
          );
        } else {
          selectedOption = finalCarrierPaymentOptions.find((e) => e.paymentType === value);
        }
        if (selectedOption) {
          paymentMethodType.props.actionOnComplete(value);
          dispatch(
            setPaymentPlan({ product: selectedProduct, paymentPlan: selectedOption.paymentPlan }),
          );
        }

        if (shouldSyncPropertyToAuto && syncPropertyToAuto) {
          syncPropertyToAuto(value);
        }
      }
    },
    [
      allowPaymentAlert,
      autoPay.value,
      selectedProduct,
      dispatch,
      methodOptions,
      finalCarrierPaymentOptions,
      shouldSyncPropertyToAuto,
      syncPropertyToAuto,
      paymentMethodType.props,
    ],
  );

  // TODO - Remove this connect check here and dont add these CC fields to form for connect as it uses iFrame instead
  useAddConditionValues({
    conditionalFields: [creditCardCardNumber, creditCardFullName, creditCardExpirationDate],
    isExcluded: () => isConnect || !['CreditCard', 'CostcoVisa'].includes(productPaymentType),
  });

  useAddConditionValues({
    conditionalFields: [routingNumber, accountNumber],
    isExcluded: () => productPaymentType !== 'EFT',
  });

  useEffect(() => {
    const updatePaymentMethod = async (): Promise<void> => {
      // update answer for validation
      // TODO - Move these defaulting from useEffect to actual handlers
      await dispatch(updateAnswers({ answers: { [paymentMethodType.key]: productPaymentType } }));
      // update paymentMethod for purchase
      const selectedMethod = methodOptions?.find((element) => element.value === productPaymentType);
      if (selectedMethod?.key) {
        dispatch(
          setPaymentMethod({
            product: selectedProduct,
            paymentMethod: selectedMethod.key,
          }),
        );
      }
    };
    if (productPaymentType) updatePaymentMethod();
  }, [dispatch, methodOptions, productPaymentType, paymentMethodType.key, selectedProduct]);

  const creditCardPaymentOptions = createPaymentOptions(
    'CreditCard',
    sortedCarrierPaymentOptions,
    productPaymentType,
    selectedProduct,
    policyDuration,
  );

  const bankAccountPaymentOptions = createPaymentOptions(
    'EFT',
    sortedCarrierPaymentOptions,
    productPaymentType,
    selectedProduct,
    policyDuration,
  );

  // TODO: We can remove div here once we move away from iframe and we added it only to accommodate iFrame for Connect
  const displayPayment = (): JSX.Element => {
    return (
      // For CostcoVisa and CC we use completely identical Transaction id.
      <div
        className={
          isMobile ? classes.paymentMethodMobileContainer : classes.paymentMethodDetailsContainer
        }
      >
        {(productPaymentType === 'CostcoVisa' || productPaymentType === 'CreditCard') && (
          <div>
            <CreditCardPayment
              displayPaymentImage={metadata.displayPaymentImage}
              reuseAutoPayment={reuseAutoPayment && bundlePropertyPayment}
              creditCardUrl={creditCardUrl}
              // !TODO We need to fix the casing of CreditCardType to be camelcased throughout the application to stay consistent with SAPI.
              type={productPaymentType === 'CostcoVisa' ? 'costcoVisa' : 'creditCard'}
              paymentOptions={creditCardPaymentOptions}
              product={selectedProduct}
              coverageType={coverageType}
              shouldSyncPropertyToAuto={shouldSyncPropertyToAuto}
              syncPropertyToAuto={syncPropertyToAuto}
              hasTokens={hasTokens}
            />
            {creditCardPaymentOptions?.length > 1 && !(bankAccountPaymentOptions?.length < 1) && (
              <PolicyDifference />
            )}
          </div>
        )}
        {productPaymentType === 'EFT' && (
          <>
            <BankPayment
              displayPaymentImage={metadata.displayPaymentImage}
              reuseAutoPayment={reuseAutoPayment && bundlePropertyPayment}
              paymentOptions={bankAccountPaymentOptions}
              product={selectedProduct}
              coverageType={coverageType}
              shouldSyncPropertyToAuto={shouldSyncPropertyToAuto}
              syncPropertyToAuto={syncPropertyToAuto}
              hasToken={hasBankPayToken}
            />
            {bankAccountPaymentOptions?.length > 1 && !(creditCardPaymentOptions?.length < 1) && (
              <PolicyDifference />
            )}
          </>
        )}
        {productPaymentType === 'Mortgage' && (
          <MortgagePayment
            paymentOptions={createPaymentOptions(
              'Mortgage',
              sortedCarrierPaymentOptions,
              productPaymentType,
              selectedProduct,
              policyDuration,
            )}
            product={selectedProduct}
            policyStartDate={policyStartDate}
            coverageType={coverageType}
          />
        )}
      </div>
    );
  };

  const showTipContent =
    !isConnect &&
    coverageType === 'auto' &&
    !(bundlePropertyPayment && reuseAutoPayment) &&
    productPaymentType !== 'Mortgage';

  return (
    <div className={classes.checkoutPaymentContainer}>
      <Grid item xs={12}>
        {checkoutPageRedesign ? (
          <>
            <h2 className={classes.title}>{label}</h2>
            <Divider aria-hidden='true' className={classes.divider} />
          </>
        ) : (
          <h3>{label}</h3>
        )}
      </Grid>
      <div className={classes.background}>
        <GridItem
          className={isMobile ? classes.reuseLabelMobileContainer : classes.reuseLabelContainer}
          xs={12}
        >
          {bundlePropertyPayment && (
            <FormControlLabel
              label='Re-use my auto policy payment method'
              className={classes.reuseLabel}
              control={
                <Checkbox
                  value={reuseAutoPayment}
                  checked={reuseAutoPayment}
                  onChange={handleReuseAutoPaymentCheck}
                />
              }
            />
          )}
        </GridItem>
        {methodOptions && methodOptions.length > 1 && (
          <GridItem
            className={
              isMobile ? classes.paymentMethodMobileContainer : classes.paymentMethodContainer
            }
            xs={12}
          >
            {(!bundlePropertyPayment || !reuseAutoPayment) && (
              <>
                <div ref={radioGroupRef}>
                  <RadioGroupWithOptions
                    data-testid='checkoutPaymentMethod'
                    {...paymentMethodType.props}
                    id={paymentMethodType.props.name}
                    options={methodOptions}
                    actionOnComplete={handlePaymentMethodSelect}
                    cardSize='medium'
                    trackingName='payment_method_selection'
                  />
                </div>
                <Popover
                  variant='warning'
                  onClose={handleCloseMessage}
                  open={showMessage}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <p className={classes.popOverText}>
                    {env.static.isAgent && paymentMethodType.props.value === 'EFT'
                      ? 'To keep your AutoPay discount, you must enroll in online servicing.'
                      : 'To keep your AutoPay discount, you must enroll in online servicing, add a bank account, and set up AutoPay within 10 days.'}
                  </p>
                </Popover>
              </>
            )}
          </GridItem>
        )}
        {metadata.showHelpboxTip && showTipContent && (
          <GridItem topSpacing='sm' xs={12} className={classes.tip}>
            <HelpBox
              image={GraphicUIAgentAvatarImageUrl}
              data-testid='helpBoxIcon'
              content={<TipContent />}
            />
          </GridItem>
        )}
        <GridItem
          topSpacing='sm'
          xs={12}
          className={cx(
            classes.box,
            methodOptions && methodOptions.length === 1
              ? isMobile
                ? classes.singlePaymentMethodMobileSpacing
                : classes.singlePaymentMethodSpacing
              : undefined,
          )}
        >
          {displayPayment()}
        </GridItem>
      </div>
    </div>
  );
};
