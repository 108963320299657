import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CheckoutRetryPage' })((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: 15,
      paddingRight: 15,
      paddingBottom: 10,
    },
    [theme.breakpoints.between('md', 'xl')]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
}));
