import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PercentageGroup' })((theme) => ({
  percentageInputPanel: {
    display: 'flex',
    marginBottom: 15,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  percentageLabel: {
    ...theme.typography.body4,
    marginRight: 5,
    paddingTop: 26,
    width: 219,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '&:after': {
      content: `"................................................."`,
    },
  },
  inputPanel: {
    width: '30%',
    minWidth: 71,
    maxWidth: 192,
  },
  removeButtonPanel: {
    paddingTop: 0,
    paddingLeft: 5,
  },
  removeIcon: {
    fontSize: 14,
    ...theme.mixins.setColorSvg(theme.palette.primary.main),
  },
  percentageIcon: {
    marginRight: 10,
    fontSize: 14,
  },
}));
