import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem, NumberFormat } from '@ecp/components';
import { Select, TextField } from '@ecp/features/sales/shared/components';
import type { OptionProps } from '@ecp/features/sales/shared/types';
import type { Field } from '@ecp/types';

interface Props {
  id?: string;
  otherCompanyName: Field;
  otherCompanyAddressLine1: Field;
  otherCompanyAddressLine2: Field;
  otherCompanyCity: Field;
  otherCompanyState: Field;
  otherCompanyZip: Field;
}

export const VehicleLienHolderOtherCompanyQuestion: React.FC<Props> = (props) => {
  const {
    id,
    otherCompanyName,
    otherCompanyAddressLine1,
    otherCompanyAddressLine2,
    otherCompanyCity,
    otherCompanyState,
    otherCompanyZip,
  } = props;

  return (
    <div id={id}>
      <GridItem xs={12} lg={6} topSpacing='sm'>
        <TextField
          {...otherCompanyName.props}
          label='Company name'
          trackingName='other_company_name'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
          actionOnChange={otherCompanyName.validateUpdateAndPatch}
        />
      </GridItem>
      <Grid item container spacing={1}>
        <GridItem xs={12} lg={6} topSpacing='sm'>
          <TextField
            {...otherCompanyAddressLine1.props}
            label='Address'
            ariaLabel='Other company address line 1'
            trackingName='other_company_address_line1'
            trackingLabel={GoogleAnalyticsLabels.REDACTED}
            actionOnChange={otherCompanyAddressLine1.validateUpdateAndPatch}
          />
        </GridItem>
        <GridItem xs={12} lg={6} topSpacing='sm'>
          <TextField
            {...otherCompanyAddressLine2.props}
            label='Apt./Unit # (optional)'
            ariaLabel='Other company address line 2'
            trackingName='other_company_address_line2'
            trackingLabel={GoogleAnalyticsLabels.REDACTED}
            actionOnChange={otherCompanyAddressLine2.validateUpdateAndPatch}
          />
        </GridItem>
      </Grid>
      <Grid item container spacing={1}>
        <GridItem xs={12} lg={4} topSpacing='sm'>
          <TextField
            {...otherCompanyCity.props}
            label='City'
            ariaLabel='Other company city'
            trackingName='other_company_city'
            trackingLabel={GoogleAnalyticsLabels.REDACTED}
            actionOnChange={otherCompanyCity.validateUpdateAndPatch}
          />
        </GridItem>
        <GridItem xs={12} lg={4} topSpacing='sm'>
          <Select
            {...(otherCompanyState.props as OptionProps)}
            id='otherCompanyState'
            label='State'
            inputAriaLabel='Other company state'
            inputButtonAriaLabel='Other company state'
            trackingName='other_company_state'
            trackingLabel={GoogleAnalyticsLabels.REDACTED}
          />
        </GridItem>
        <GridItem xs={12} lg={4} topSpacing='sm'>
          <NumberFormat
            {...otherCompanyZip.props}
            label='ZIP code'
            ariaLabel='Other company zip code'
            formatType='zipcode'
            trackingName='other_company_zip_code'
            trackingLabel={GoogleAnalyticsLabels.REDACTED}
            actionOnChange={otherCompanyZip.validateUpdateAndPatch}
          />
        </GridItem>
      </Grid>
    </div>
  );
};
