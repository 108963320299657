import { useCallback, useRef } from 'react';

import { Grid } from '@mui/material';

import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { GridItem } from '@ecp/components';
import { env } from '@ecp/env';
import { useGetFields } from '@ecp/features/sales/form';
import { Form } from '@ecp/features/sales/shared/components';
import { getSelectedPropertyProduct, useForm } from '@ecp/features/sales/shared/store';
import { useField } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { getProductNameFromProduct } from '@ecp/features/shared/product';

import { useStyles } from './DiscountsForm.styles';
import { DiscountsFormBody } from './DiscountsFormBody';
import { DiscountsFormButton } from './DiscountsFormButton';

export interface DiscountsFormProps {
  onNext: () => Promise<void>;
  onAlternateNext: () => Promise<void>;
}

export const DiscountsForm: React.FC<DiscountsFormProps> = (props) => {
  const { onNext, onAlternateNext } = props;
  const { classes } = useStyles();
  const initValues = useRef({});
  const getFields = useGetFields();
  const fields = getFields();
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({ initValues, fields });
  const wildfireMitigationDiscount = useField('discount.property.wildfireMitigation');
  const caWildfireMandateFlag = flagValues[FeatureFlags.CA_WILDFIRE_MANDATE];
  // When true continue button will be disabled as part of ECP-10820
  const showConsumerWildfireMandate = (wildfireMitigationDiscount.value &&
    !env.static.isAgent &&
    caWildfireMandateFlag) as boolean;
  const selectedPropertyProduct = useSelector(getSelectedPropertyProduct);
  const productName = selectedPropertyProduct && getProductNameFromProduct(selectedPropertyProduct);

  const handleIsFormValid = useCallback(async () => {
    const isValid = validateForm().isValid;
    if (isValid) await patchFormValues();

    return isValid;
  }, [patchFormValues, validateForm]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container>
          <DiscountsFormBody />
          {/* topSpacing set to sm since button has uses mixin with a top margin of 20px */}
          <GridItem topSpacing='sm' xs={12}>
            <DiscountsFormButton
              disabled={showConsumerWildfireMandate}
              isFormValid={handleIsFormValid}
              onClick={onNext}
              onSecondaryClick={onAlternateNext}
              isProcessing={isPatchFormInProgress}
              className={classes.next}
              data-testid='applyMyDiscounts'
              trackingLabel={`${productName}_discounts_continue`}
              analyticsElement='choice.discountPage.continueButton'
            >
              Continue
            </DiscountsFormButton>
          </GridItem>
        </Grid>
      </Form>
    </div>
  );
};
