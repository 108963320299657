/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type {
  AcknowledgementResponse,
  AcknowledgementsResponse,
  AcknowledgementState,
} from './types';

const initialState: AcknowledgementState = {
  acknowledgementList: {},
  agreementResponse: false,
  signatureMethodEmails: {},
};

export const acknowledgementsSlice = createSlice({
  name: 'acknowledgements',
  initialState,
  reducers: {
    // TODO: Remove the merge on the acknowledgementList once we have the IAgree modal up and running
    setAcknowledgementsApiGetSuccess(state, { payload }: PayloadAction<AcknowledgementsResponse>) {
      const { offerSetId, acknowledgementList } = payload.data;
      state.offerSetId = offerSetId;
      Object.entries(acknowledgementList).forEach(([product, { categories }]) => {
        state.acknowledgementList[product] = state.acknowledgementList[product] || {
          categories: {},
        };
        Object.entries(categories).forEach(([category, acknowledgement]) => {
          state.acknowledgementList[product].categories[category] = acknowledgement;
        });
      });
    },

    setAcknowledgementsApiPostSuccess(state, { payload }: PayloadAction<AcknowledgementResponse>) {
      // Do nothing
    },

    setSignatureText(state, { payload }: PayloadAction<string>) {
      state.signatureText = payload;
    },

    setPniSignatureText(state, { payload }: PayloadAction<string>) {
      state.pniSignatureText = payload;
    },

    setSniSignatureText(state, { payload }: PayloadAction<string>) {
      state.sniSignatureText = payload;
    },

    setAgreementResponse(state, { payload }: PayloadAction<boolean>) {
      state.agreementResponse = payload;
    },

    setSignatureMethod(state, { payload }: PayloadAction<string>) {
      state.signatureMethod = payload;
    },

    setSignatureMethodPersonEmail(
      state,
      { payload: { personRef, email } }: PayloadAction<{ personRef: string; email: string }>,
    ) {
      state.signatureMethodEmails[personRef] = email;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      (action) => action.type === '@global/teardownStore',
      () => initialState,
    );
  },
});

export const {
  setAcknowledgementsApiGetSuccess,
  setAcknowledgementsApiPostSuccess,
  setAgreementResponse,
  setPniSignatureText,
  setSignatureText,
  setSniSignatureText,
  setSignatureMethod,
  setSignatureMethodPersonEmail,
} = acknowledgementsSlice.actions;
