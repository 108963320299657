// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { UseTheme } from '../../../../base/src';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useTheme as useThemeBase } from '../../../../base/src';
import type { Theme } from '../theme';

/**
 * Adapter for MUI useTheme typed with Progressive theme,
 * so you don't need to pass Theme generic in every useTheme call.
 */
export const useTheme = useThemeBase as unknown as UseTheme<Theme>;
