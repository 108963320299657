import { useEffect, useState } from 'react';

import { Events, trackEvent } from '@ecp/utils/flags';
import { useEvent } from '@ecp/utils/react';

import {
  INITIAL_OFFERS_REQUESTED,
  PRODUCT_LOB_USER_SELECTION,
} from '@ecp/features/sales/shared/constants';
import {
  createOffers,
  getCreateOffersInProgress,
  getDalSessionId,
  getInquiryId,
  getLineOfBusiness,
  getLineOfBusinessUserSelection,
  getOfferSetId,
  getOffersForSelectedLob,
  getRecalcProcessingValue,
  getRecalled,
  getUpdateOffersInProgress,
  updateAnswers,
  updateOffers,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { ProductName } from '@ecp/features/shared/product';
import {
  getUnbundledLobFromProductName,
  isLineOfBusinessBundle,
} from '@ecp/features/shared/product';

export const useQuotesPage = (): {
  doOffersForSelectedLobExist: boolean;
  doOffersForSelectedLobIncludeBundle: boolean;
  inquiryId: string | undefined;
  dalSessionId: string | undefined;
  loading: boolean;
  offerSetId: string | undefined;
  xRequestId: string;
} => {
  const dispatch = useDispatch();

  useEffect(() => {
    // A/B test metric tracking function for quote summary page view
    trackEvent(Events.QUOTE_SUMMARY_PAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inquiryId = useSelector(getInquiryId);
  const dalSessionId = useSelector(getDalSessionId);
  const offerSetId = useSelector(getOfferSetId);
  const isCreateOffersInProgress = useSelector(getCreateOffersInProgress);
  const isUpdateOffersInProgress = useSelector(getUpdateOffersInProgress);
  const recalled = useSelector(getRecalled);
  const isRecalcProcessing = useSelector(getRecalcProcessingValue);
  // Show full page loader for offers loading and hide for offers recalc as we show inline spinner
  const offersLoading =
    !offerSetId || isCreateOffersInProgress || (isUpdateOffersInProgress && !isRecalcProcessing);
  // TODO We need this local loading state due to user selection reset on useEffect. This will be removed soon after that logic is removed part of SAPIV4 migration
  const [isLoading, setIsLoading] = useState(true);
  // TODO This needs to be uniformly handled in the Error-specific component
  const [xRequestId, setXRequestId] = useState('');
  // TODO This needs to be encapsulated in useOffers hook
  useEffect(() => {
    const handleOffers = async (): Promise<void> => {
      if (!recalled) {
        // already has updated offers
        if (offerSetId || isCreateOffersInProgress) {
          await dispatch(updateOffers({ clearUserSelection: true })).then((result) => {
            const requestId = result?.response;
            if (typeof requestId === 'string') setXRequestId(requestId);
          });
        } else if (dalSessionId) {
          // If the offers are being created for first time, toggle the policy start date modal automatically
          await Promise.all([
            dispatch(
              updateAnswers({
                answers: {
                  [INITIAL_OFFERS_REQUESTED]: true,
                },
              }),
            ),
            dispatch(createOffers()).then((result) => {
              const requestId = result?.response;
              if (typeof requestId === 'string') setXRequestId(requestId);
            }),
          ]);
        }
      }
    };
    handleOffers().finally(async () => {
      await checkLineOfBusinessUserSelection();
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const offersForSelectedLob = useSelector(getOffersForSelectedLob);
  const doOffersForSelectedLobExist = Boolean(offersForSelectedLob);
  const doOffersForSelectedLobIncludeBundle = Boolean(offersForSelectedLob?.bundle);

  // Currently this function handles 2 scenarios:
  // 1. When user selection is bundle and there is no bundle offer: use any valid offer
  // 2. When user selection is empty: use default
  // TODO ECP-15476 This check could potentially be unnecessary depending on how
  // useDetermineLineOfBusinessUserSelection is refactored. See comment there.
  const userSelection = useSelector(getLineOfBusinessUserSelection);
  const defaultSelection = useSelector(getLineOfBusiness);
  const checkLineOfBusinessUserSelection = useEvent(async () => {
    if (!offersForSelectedLob) return;

    let pitchedSelection = userSelection || defaultSelection;

    const isBundleSelectionInvalid =
      isLineOfBusinessBundle(pitchedSelection) && !offersForSelectedLob.bundle;

    if (isBundleSelectionInvalid) {
      const validProduct = Object.keys(offersForSelectedLob).find((key) => {
        return offersForSelectedLob[key];
      });

      const unbundledLob =
        validProduct && getUnbundledLobFromProductName(validProduct as ProductName);

      if (unbundledLob) pitchedSelection = unbundledLob;
    }

    if (pitchedSelection !== userSelection)
      await dispatch(
        updateAnswers({
          answers: {
            [PRODUCT_LOB_USER_SELECTION]: pitchedSelection,
          },
        }),
      );
  });

  return {
    doOffersForSelectedLobExist,
    doOffersForSelectedLobIncludeBundle,
    inquiryId,
    dalSessionId,
    loading: isLoading || offersLoading,
    offerSetId,
    xRequestId,
  };
};
