import type { AutoProduct } from '@ecp/features/shared/product';
import {
  GraphicAutoCoverageCollisionImageUrl,
  GraphicAutoCoverageEmergencyRoadsideImageUrl,
  GraphicAutoCoverageFullGlassImageUrl,
  GraphicAutoCoverageLeaseGapImageUrl,
  GraphicAutoCoverageNewCarReplacementImageUrl,
  GraphicAutoCoverageOriginalEquipmentManufacturerImageUrl,
  GraphicAutoCoverageRentalReimbursementImageUrl,
  GraphicAutoCoverageRoadTripAccommodationsImageUrl,
  GraphicAutoCoverageTheftPreventionImageUrl,
  GraphicAutoCoverageUnderinsuredUninsuredMotoristImageUrl,
  GraphicAutoCoverageUninsuredMotoristPropertyDamageDeductibleImageUrl,
} from '@ecp/themes/base';
import type { OptionsMetadata } from '@ecp/types';

// metadata for vehicle coverage card on coverage page
export const VehicleCoverageMetadata: Record<AutoProduct, OptionsMetadata> = {
  'amfam-adv.auto': {
    'fullSafetyGlass.selected': {
      primaryImage: GraphicAutoCoverageEmergencyRoadsideImageUrl,
      title: 'Full Glass Coverage',
      primaryText: 'Full Glass Coverage',
      stateOptions: {},
    },
    'travelAssistance.selected': {
      primaryImage: GraphicAutoCoverageEmergencyRoadsideImageUrl,
      title: 'Full Glass Coverage',
      primaryText: 'Full Glass Coverage',
      stateOptions: {},
    },
    'roadsideService.selected': {
      primaryImage: GraphicAutoCoverageEmergencyRoadsideImageUrl,
      title: 'Emergency Roadside Assistance',
      primaryText:
        'May provide help if you have a flat tire, dead battery, get locked out of your car, or run out of gas. Coverage also includes towing costs for the insured vehicle when it is disabled.',
      secondaryText:
        'Insureds will have access to multiple roadside services, which include lockout service, gas delivery, jump start service, and towing to the nearest facility to make repairs.',
      stateOptions: {},
    },
    'rental.dailyLimitMaximum': {
      primaryImage: GraphicAutoCoverageRentalReimbursementImageUrl,
      title: 'Rental Reimbursement',
      primaryText:
        'May cover the reimbursement of rental fees for renting an auto, for a specified limit, as a temporary substitute for the insured auto while it is being repaired due to a covered loss.',
      secondaryText:
        'Because auto repairs can be a lengthy process, this coverage helps pay for potential out of pocket expenses for a rental vehicle while your vehicle is being repaired from a covered loss.',
      stateOptions: {},
    },
    'roadTripAccident.selected': {
      primaryImage: GraphicAutoCoverageRoadTripAccommodationsImageUrl,
      title: 'Road Trip Accident Accommodations',
      primaryText:
        'May pay for alternate transportation, lodging, and meals incurred due to a covered Comprehensive or Collision loss that happens more than 100 miles from your home.',
      secondaryText: 'Up to total of $600, subject to the following per-day maximums:',
      stateOptions: {},
    },
    'loanOrLease.selected': {
      primaryImage: GraphicAutoCoverageLeaseGapImageUrl,
      title: 'Loan or Lease Assistance',
      primaryText:
        'In the event of a covered total loss, this coverage may pay the difference between the unpaid amount on your lease or installment loan and the actual cash value of your insured car. When you select this coverage, you will be asked to provide the lienholder information after you purchase your policy.',
      secondaryText:
        'An auto installment loan is a loan through a commercial lending institution. Comprehensive and Collision Coverages are required. Coverage is capped at 25% of actual cash value of car.',
      stateOptions: {},
    },
    'oem.selected': {
      primaryImage: GraphicAutoCoverageOriginalEquipmentManufacturerImageUrl,
      title: 'Original Equipment Manufacturer (OEM) Parts',
      primaryText:
        'OEM coverage requires claims to first look for parts made by the original manufacturer before they look for used, refurbished, or non-OEM parts.',
      secondaryText:
        'Vehicles 11 model years old and newer with Comprehensive or Collision Coverage are eligible. If new parts are unavailable, repair parts will be furnished by non-OEMs or OEMs of varying condition.',
    },
  },
  'amfam.auto': {
    fullGlass: {
      primaryImage: GraphicAutoCoverageFullGlassImageUrl,
      refFieldKey: 'comprehensive',
      title: 'Full Glass',
      primaryText: 'May cover, without a deductible, the glass portion of any covered loss.',
      secondaryText:
        'Glass means only the glass used: In the windshield, doors and windows, and the glass, plastic or other materials used in the lights of the covered car (mirrors are excluded). The coverage also applies to covered losses.',
    },
    rental: {
      primaryImage: GraphicAutoCoverageRentalReimbursementImageUrl,
      title: 'Rental Reimbursement',
      primaryText:
        'May cover the reimbursement of rental fees for renting an auto, for a specified limit, as a temporary substitute for the insured auto while it is being repaired due to a covered loss.',
      secondaryText:
        'Because auto repairs can be a lengthy process, this coverage helps pay for potential out of pocket expenses for a rental vehicle while your vehicle is being repaired from a covered loss.',
      stateOptions: {
        VA: {
          title: 'Transportation Expense Coverage',
        },
        MA: {
          title: 'Substitute Transportation',
        },
        NC: {
          title: 'Extended Transportation Expenses Coverage',
          primaryText:
            'May cover the reimbursement of rental fees for renting an auto, for a specified limit, as a temporary substitute for the insured auto while it is being repaired due to a covered loss.',
          secondaryText:
            'This coverage provides for the reimbursement of rental fees for renting an auto from a car rental agency or garage, up to the limits you select, as a temporary substitute for the insured auto while it is being repaired due to a covered loss.',
        },
      },
    },
    roadsideAssistance: {
      primaryImage: GraphicAutoCoverageEmergencyRoadsideImageUrl,
      title: 'Emergency Roadside Assistance',
      primaryText:
        'May provide help if you have a flat tire, dead battery, get locked out of your car, or run out of gas. Coverage also includes towing costs for the insured vehicle when it is disabled.',
      secondaryText:
        'Insureds will have access to multiple roadside services, which include lockout service, gas delivery, jump start service, and towing to the nearest facility to make repairs.',
      stateOptions: {
        VA: {
          title: 'Towing and Labor Costs Coverage',
        },
        NC: {
          title: 'Towing and Labor Costs Coverage',
        },
      },
    },
    uninsuredPropertyDamage: {
      primaryImage: GraphicAutoCoverageUninsuredMotoristPropertyDamageDeductibleImageUrl,
      title: 'Uninsured Motorist Property Damage',
      primaryText:
        'May cover damage to your vehicle in a covered loss caused by a driver who did not have insurance. This coverage is not available if you select Collision Coverage.',
      secondaryText:
        'A deductible applies before your insurance will pay for an Uninsured Motorist Property Damage claim.',
    },
    uninsuredPropertyDamageLimit: {
      primaryImage: GraphicAutoCoverageUninsuredMotoristPropertyDamageDeductibleImageUrl,
      title: 'Uninsured Motorist Property Damage',
      primaryText:
        'This coverage provides protection against loss or damage to the insured vehicle caused by a motorist who does not have insurance or by an unidentified motorist who has left the scene.',
      secondaryText: 'A deductible may apply.',
      subCoverages: {
        uninsuredPropertyDamageDeductible: {
          title: 'Deductible',
          primaryText:
            'A deductible applies to losses covered by Uninsured Motorist Property Damage coverage.',
        },
      },
      stateOptions: {
        IL: {
          secondaryText:
            'A deductible applies before your insurance will pay for an Uninsured Motorist Property Damage claim.',
        },
      },
    },
    uninsuredUnderinsuredPropertyDamageLimit: {
      primaryImage: GraphicAutoCoverageUninsuredMotoristPropertyDamageDeductibleImageUrl,
      title: 'Uninsured or Underinsured Motorist Property Damage',
      primaryText:
        'The limit reimburses the insured for damage to or destruction of the insured vehicle caused by a liable uninsured or underinsured motorist.',
      secondaryText:
        'This deductible applies to losses covered by Uninsured or Underinsured Motorist – Property Damage coverage.',
      subCoverages: {
        uninsuredUnderinsuredPropertyDamageDeductible: {
          title: 'Deductible',
          primaryText:
            'This deductible applies to losses covered by Uninsured or Underinsured Motorist – Property Damage coverage.',
        },
      },
      stateOptions: {
        VA: {
          title: 'Uninsured Motorist Property Damage',
          primaryText:
            'The limit reimburses the insured for damage to or destruction of the insured vehicle caused by a liable uninsured motorist.',
          secondaryText:
            'This deductible applies to losses covered by Uninsured Motorist – Property Damage coverage.',
          subCoverages: {
            uninsuredUnderinsuredPropertyDamageDeductible: {
              title: 'Deductible',
              primaryText:
                'This deductible applies to losses covered by Uninsured Motorist – Property Damage coverage.',
            },
          },
        },
      },
    },
    underinsuredPropertyDamageLimit: {
      primaryImage: GraphicAutoCoverageUnderinsuredUninsuredMotoristImageUrl,
      title: 'Underinsured Motorist Property Damage',
      primaryText:
        'Coverage provides protection against loss or damage to the insured vehicle caused by a motorist who does not have sufficient liability limits or by an unidentified motorist who has left the scene.',
      secondaryText:
        'Please refer to your policy details for specific coverage information for your state.',
      subCoverages: {
        underinsuredPropertyDamageDeductible: {
          title: 'Deductible',
          primaryText:
            'A deductible applies to losses covered by Underinsured Motorist – Property Damage coverage.',
        },
      },
    },
    loanAndLease: {
      primaryImage: GraphicAutoCoverageLeaseGapImageUrl,
      title: 'Loan or Lease Assistance',
      primaryText:
        'In the event of a covered total loss, this coverage may pay the difference between the unpaid amount on your lease or installment loan and the actual cash value of your insured car. When you select this coverage, you will be asked to provide the lienholder information after you purchase your policy.',
      secondaryText:
        'An auto installment loan is a loan through a commercial lending institution. Comprehensive and Collision Coverages are required. Coverage is capped at 25% of actual cash value of car.',
      stateOptions: {
        VA: {
          title: 'Auto Loan/Lease Coverage',
        },
      },
    },
    originalEquipmentManufacturer: {
      primaryImage: GraphicAutoCoverageOriginalEquipmentManufacturerImageUrl,
      title: 'Original Equipment Manufacturer (OEM) Parts',
      primaryText:
        'OEM coverage requires claims to first look for parts made by the original manufacturer before they look for used, refurbished, or non-OEM parts.',
      secondaryText:
        'Vehicles 11 model years old and newer with Comprehensive or Collision Coverage are eligible. If new parts are unavailable, repair parts will be furnished by non-OEMs or OEMs of varying condition.',
    },
    newCarReplacement: {
      primaryImage: GraphicAutoCoverageNewCarReplacementImageUrl,
      title: 'New Car Replacement',
      primaryText:
        'This coverage may change the policy so that, after a covered total loss, we may pay to replace your covered auto with a new vehicle of the same make, model, year, and equipment. Your deductible will apply.',
      secondaryText:
        'Comprehensive and Collision Coverage are required and applies only to newer vehicles',
      stateOptions: {
        NC: {
          title: 'Replacement Coverage For New Autos',
        },
      },
    },
    accidentAccommodations: {
      primaryImage: GraphicAutoCoverageRoadTripAccommodationsImageUrl,
      title: 'Road Trip Accident Accommodations',
      primaryText:
        'May pay for alternate transportation, lodging, and meals incurred due to a covered Comprehensive or Collision loss that happens more than 100 miles from your home.',
      secondaryText: 'Up to total of $600, subject to the following per-day maximums:',
      listItems: ['Alternate transportation: $50', 'Lodging: $100', 'Meals: $100'],
      stateOptions: {
        VA: {
          title: 'Trip Interruption Coverage',
        },
      },
    },
    autoTheftPreventionFee: {
      primaryImage: GraphicAutoCoverageTheftPreventionImageUrl,
      title: 'Auto Theft Prevention Fee',
      primaryText:
        'This fee is non-refundable and is charged per vehicle and per policy term. State law requires every auto insurer to collect a surcharge per auto, for every six months of coverage.',
      secondaryText:
        'State mandated surcharge, charged per vehicle and per policy term. This is not a premium and is, by law, submitted quarterly to the Auto Theft Prevention Board.',
    },
    rentalOption: {
      primaryImage: GraphicAutoCoverageRentalReimbursementImageUrl,
      title: 'Transportation Expense Option',
      primaryText:
        'Provides coverage for temporary transportation expenses incurred as a result of a covered loss up to the limit of liability stated on the declarations, but in no event less than $600 for your ‘covered auto’. Option 2 offers additional coverage for a ‘non-owned auto’ for up to 30 days with no limit.',
      secondaryText:
        'This is only a general description of terms and available coverages, not a statement of contract. All coverages are subject to all policy provisions and applicable endorsements.',
      subCoverages: {
        rentalMax: {
          title: 'Maximum',
          primaryText:
            'Provides Transportation Expense coverage up to the limit of liability that you select for a covered loss.',
        },
      },
      stateOptions: {
        VA: {
          title: 'Transportation Expense Coverage',
        },
      },
    },
    coverageForRentedVehicles: {
      primaryImage: GraphicAutoCoverageRentalReimbursementImageUrl,
      title: 'Coverage for Rented Vehicles',
      primaryText:
        'This endorsement provides coverage for you or a family member who rents a rental vehicle on a daily basis for less than 22 consecutive days subject to a $250 deductible.',
    },
  },
  'connect.auto': {
    FullSafetyGlass: {
      primaryImage: GraphicAutoCoverageFullGlassImageUrl,
      title: 'Full Glass',
      primaryText: 'May cover without a deductible, the glass portion of any covered loss.',
      secondaryText:
        'Glass means only the glass used: In the windshield, doors and windows, and the glass, plastic or other materials used in the lights of the covered car (mirrors are excluded). The coverage also applies to covered losses.',
      stateOptions: {
        MA: {
          title: 'Comprehensive Glass Deductible',
          primaryText:
            'Amount you pay out-of-pocket before your insurance pays a comprehensive claim for glass damage to your vehicle. The deducible applies per eligible expense or loss per person.',
        },
        NY: {
          title: 'Comprehensive Full Glass',
        },
      },
    },
    CollisionFullSafetyGlass: {
      primaryImage: GraphicAutoCoverageFullGlassImageUrl,
      title: 'Collision Full Glass',
      primaryText:
        'Eliminates the deductible you would have to pay for coverage claim for glass damage.',
    },
    CollisionDeductibleWaiver: {
      primaryImage: GraphicAutoCoverageCollisionImageUrl,
      title: 'Collision Deductible Waiver',
      primaryText:
        'Coverage waives the collision deductible if the other driver: 1. Strikes your while legally parked or, 2. Rear-ends your vehicle while moving in the same direction or, 3. Was convicted at the time of accident of: operating under the influence, driving the wrong way, or operating at excessive speeds',
      secondaryText:
        'The collision deductible is also waived if you can recover damages in court for reasons not listed previously. This coverage does not apply if the other driver cannot be identified for example, hit and run.',
    },
    RentalReimbursement: {
      primaryImage: GraphicAutoCoverageRentalReimbursementImageUrl,
      title: 'Rental Reimbursement',
      primaryText:
        'May cover the reimbursement of rental fees for renting an auto, for a specified limit, as a temporary substitute for the insured auto while it is being repaired due to a covered loss.',
      secondaryText:
        'Because auto repairs can be a lengthy process, this coverage helps pay for potential out of pocket expenses for a rental vehicle while your vehicle is being repaired from a covered loss.',
    },
    UninsuredMotoristPropertyDamage: {
      primaryImage: GraphicAutoCoverageUninsuredMotoristPropertyDamageDeductibleImageUrl,
      title: 'Uninsured Motorist Property Damage',
      primaryText:
        'May cover damages to your vehicle in a covered loss caused by a driver who did not have insurance. This coverage is not available if you select Collision Coverage.',
      secondaryText:
        'A deductible applies before your insurance will pay for an Uninsured Motorist Property Damage coverage claim.',
      stateOptions: {
        IL: {
          primaryText:
            'This coverage provides protection against loss or damage to the insured vehicle caused by a motorist who does not have insurance or by an unidentified motorist who has left the scene.',
          secondaryText:
            'A deductible applies before your insurance will pay for an Uninsured Motorist Property Damage claim.',
        },
      },
    },
    EmergencyRoadsideService: {
      primaryImage: GraphicAutoCoverageEmergencyRoadsideImageUrl,
      title: 'Emergency Roadside Assistance',
      primaryText:
        'Provides help if you have a flat tire, dead battery, get locked out of your car, or run out of gas. Coverage also includes towing costs for the insured vehicle when it is disabled.',
      secondaryText:
        'Insureds will have access to multiple roadside services, which include lockout service, gas delivery, jump start service, and towing to the nearest facility to make repairs.',
    },
    UninsuredMotoristPropertyDamageLimit: {
      primaryImage: GraphicAutoCoverageUninsuredMotoristPropertyDamageDeductibleImageUrl,
      title: 'Uninsured Motorist Property Damage',
      primaryText:
        'May cover damages to your vehicle in a covered loss caused by a driver who did not have insurance.',
    },
  },
  'connect-boost.auto': {},
};
