import { Grid } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';
import { formatDate } from '@ecp/utils/date';

import { StateFeesSection } from '@ecp/features/sales/shared/components';
import { PaymentPlan } from '@ecp/features/sales/shared/constants';
import {
  getOfferDetailsForProduct,
  getUpdateOffersInProgress,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';
import { getProductDisplayNameFromProduct } from '@ecp/features/shared/product';
import { useIsMobile, useIsTablet } from '@ecp/themes/base';

import { getMonthlyPaymentsText } from '../../util';
import { EstimatedTooltip } from '../EstimatedTooltip';
import { PremiumToggle } from '../PremiumToggle';
import { Recalc } from '../Recalc';
import metadata from './metadata';
import { useStyles } from './MonthlyPaymentsCheckoutCard.styles';

interface Props {
  offerProduct: Product;
  recalculate?: boolean;
  showEstimated?: boolean;
  showProcess: boolean;
  psd: string;
  showToggle?: boolean;
  premiumPlan: PaymentPlan;
  showStateFees?: boolean;
}

export const MonthlyPaymentsMonolineCoverageView: React.FC<Props> = (props) => {
  const {
    offerProduct,
    recalculate,
    showEstimated,
    showProcess,
    psd,
    showToggle,
    premiumPlan,
    showStateFees,
  } = props;
  const { classes, cx } = useStyles();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const updatesInProgress = useSelector((state: RootStore) => getUpdateOffersInProgress(state));
  const recalculatingOrUpdating = recalculate || updatesInProgress;
  const showLoading = updatesInProgress || showProcess;

  const offerDetails = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, offerProduct),
  );
  const productName = getProductDisplayNameFromProduct(offerProduct);

  if (!offerDetails) return null;

  const { monthlyPremium, fullPremium } = offerDetails;

  const renderMonthlyView = (
    <>
      <Grid item xs={8} display='flex' alignItems='center'>
        <p className={classes.policyCheckoutHeaders}>
          {getMonthlyPaymentsText(
            monthlyPremium?.paymentPlan,
            monthlyPremium?.downPayment,
            offerProduct,
          )}
        </p>
      </Grid>
      <Grid item xs={4} className={cx(classes.premiumFeesMonolineColumn, classes.monthlyPayments)}>
        <Recalc
          amount={parseDollar(monthlyPremium?.installmentPayment)}
          recalculate={recalculatingOrUpdating}
          showProcess={showProcess}
        />
      </Grid>
      {(metadata.showDueTodayAmt || !!monthlyPremium?.downPayment) && (
        <>
          <Grid item xs={6}>
            <p className={classes.policyCheckoutHeaders}>Due today</p>
          </Grid>
          <Grid item xs={6} className={cx(classes.premiumFeesColumn, classes.premiumFeesInfo)}>
            <Recalc
              amount={parseDollar(monthlyPremium?.downPayment)}
              recalculate={recalculatingOrUpdating}
              showProcess={showProcess}
            />
          </Grid>
        </>
      )}
      {isMobile ? null : (
        <>
          <Grid item xs={6}>
            <p className={classes.policyCheckoutHeaders}>Policy total</p>
          </Grid>
          <Grid item xs={6} className={cx(classes.premiumFeesColumn, classes.premiumFeesInfo)}>
            <Recalc
              amount={parseDollar(monthlyPremium?.totalPremium)}
              recalculate={recalculatingOrUpdating}
              showProcess={showProcess}
            />
          </Grid>
          <Grid item xs={6}>
            <p className={classes.policyCheckoutHeaders}>Start date</p>
          </Grid>
          <Grid item xs={6} className={cx(classes.premiumFeesColumn, classes.premiumFeesInfo)}>
            <span className={cx(classes.customizeButton, classes.premiumFeesInfo)}>
              {formatDate(psd)}
            </span>
          </Grid>
          {isTablet ? null : (
            <StateFeesSection
              premiumPlan={premiumPlan}
              offerDetails={offerDetails}
              showStateFees={showStateFees}
            />
          )}
        </>
      )}
    </>
  );

  const renderFullPaymentView = (
    <>
      <Grid item xs={8} display='flex' alignItems='center'>
        <p className={classes.policyCheckoutHeaders}>One-time payment of</p>
      </Grid>
      <Grid item xs={4} className={cx(classes.premiumFeesMonolineColumn, classes.monthlyPayments)}>
        <Recalc
          amount={parseDollar(fullPremium?.totalPremium)}
          recalculate={recalculate}
          showProcess={showLoading}
        />
      </Grid>
      {isMobile ? null : (
        <>
          <Grid item xs={6}>
            <p className={classes.policyCheckoutHeaders}>Start date</p>
          </Grid>
          <Grid item xs={6} className={cx(classes.premiumFeesColumn, classes.premiumFeesInfo)}>
            <span className={cx(classes.customizeButton, classes.premiumFeesInfo)}>
              {formatDate(psd)}
            </span>
          </Grid>
          {isTablet ? null : (
            <StateFeesSection
              premiumPlan={premiumPlan}
              offerDetails={offerDetails}
              showStateFees={showStateFees}
            />
          )}
        </>
      )}
    </>
  );

  return (
    <>
      {showToggle && (
        <Grid container justifyContent='center'>
          <Grid item xs={12} className={classes.toggleContainer}>
            <PremiumToggle size='large' />
          </Grid>
        </Grid>
      )}
      <Grid container>
        <Grid item xs={12}>
          <p className={classes.policyDurationHeaderColumn}>
            <span className={classes.policyDurationHeader}>{productName}</span>{' '}
            {`${offerDetails.policyDuration}-mo policy`}
            {showEstimated && <EstimatedTooltip inline />}
          </p>
        </Grid>
        {premiumPlan === PaymentPlan.MONTHLY_PREMIUM ? renderMonthlyView : renderFullPaymentView}
      </Grid>
    </>
  );
};
