import type { Product } from '@ecp/features/shared/product';
import { getProductNameFromProduct } from '@ecp/features/shared/product';
import { IconUIDownload } from '@ecp/themes/base';

import type { DocumentStatus, Status } from '../types';

export type Metadata = Record<Status, ((product: Product) => string) | string>;

const metadata: DocumentStatus = {
  ProofOfInsurance: {
    default: 'Download proof of insurance',
    processing: 'Generating proof of insurance',
    delayed: 'Still generating proof of insurance',
    error: (product: Product) =>
      `Your proof of insurance isn’t available yet. To download it, access your ${getProductNameFromProduct(
        product,
      )} policy online by creating an account.`,
  },
  icon: <IconUIDownload />,
} as const;

export default metadata;
