import { useEffect } from 'react';

import * as hotjar from '@ecp/utils/hotjar';

import { env } from '@ecp/env';
import {
  getDalSessionId,
  getInquiryId,
  getPartnerExperienceId,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

/**
 * Install Hotjar and reinstall whenever trackingId changes.
 * In consumer experience it will be installed once over the entire hook lifecycle.
 * In agent experience it will be reinstalled every time session exp id updates.
 */
export const useHotjar = (): void => {
  const expId = useSelector(getPartnerExperienceId);
  const trackingId =
    env.hotjarTrackingId &&
    (typeof env.hotjarTrackingId === 'string' ? env.hotjarTrackingId : env.hotjarTrackingId[expId]);

  hotjar.useHotjar({ trackingId, snippetVersion: env.hotjarSnippetVersion });

  const dalSessionId = useSelector(getDalSessionId);
  useEffect(() => {
    if (dalSessionId && trackingId) hotjar.setUserAttributes({ dalSessionId });
  }, [dalSessionId, trackingId]);

  const inquiryId = useSelector(getInquiryId);
  useEffect(() => {
    if (inquiryId && trackingId) hotjar.setUserAttributes({ inquiryId });
  }, [inquiryId, trackingId]);
};
