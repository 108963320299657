import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'BankPayment' })((theme) => ({
  ...theme.mixins.formAll,
  textField: {
    padding: 0,
  },
  bankPaymentContainer: {},
  bankPaymentFieldsContainer: {
    width: '100%',
  },
  invalidBankPaymentError: {
    [theme.breakpoints.down('lg')]: {
      padding: 0,
    },
    paddingLeft: 20,
    paddingRight: 20,
    color: theme.palette.error.light,
  },
  bankPaymentFields: {
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
      maxWidth: 'none',
    },
  },
  accountNumber: {
    [theme.breakpoints.down('md')]: {
      marginTop: 30,
    },
  },
}));
