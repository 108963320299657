import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<{ productLength: number }>({ name: 'AccessPoliciesOnline' })(
  (...[theme, { productLength }]) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    informationCard: {
      backgroundColor: theme.palette.grey[100],
      borderTopColor: theme.palette.primary.main,
      borderTopStyle: 'solid',
      borderTopWidth: 4,
      marginTop: 20,
      padding: '0px 20px 20px 20px',
    },
    columnContentHeader: {
      marginTop: 20,
      marginBottom: 6,
    },
    linkContainer: {
      color: theme.palette.primary.darkText,
      marginTop: 20,
      marginBottom: 0,
      paddingLeft: 15,
      ...(productLength === 1 && {
        paddingInlineStart: 0,
      }),
      '& li:only-child': {
        listStyleType: 'none',
      },
    },
    listItem: {
      marginBottom: 20,
    },
    link: {
      ...theme.typography.button2,
      marginTop: 0,
    },
  }),
);
