import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'Acknowledgement' })((theme) => ({
  errorColor: {
    color: theme.palette.error.main,
  },
  errorFont: {
    fontSize: 12,
    fontStyle: 'italic',
  },
  container: {
    paddingTop: '10px',
    maxWidth: 760,
  },
  divider: {
    borderBottomWidth: 1,
    marginBotttom: 0,
    marginTop: 20,
  },
}));
