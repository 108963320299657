import type { BaseStateMetadataCollection } from '@ecp/types';

export const signatureAcknowledgementMetadata: {
  [productKey: string]: BaseStateMetadataCollection;
} = {
  'connect.auto': {
    UMUIM_KS: {
      title:
        'I have read and agree to selection of coverage as indicated on the Uninsured Motorist Coverage Excess Limits Rejection Form:',
    },
    UMUIM_UT: {
      title:
        'I have read and agree to the coverage selected on the Uninsured Motorist and Underinsured Motorist Coverage Selection/Rejection Form:',
    },
    UMUIM_NM: {
      title:
        'I have read and agree to the coverage selected on the New Mexico Uninsured Motorists Coverage Selection/Rejection form:',
    },
    UMUIM_KY: {
      title:
        'I have read and agree to the coverage selected on the Uninsured Motorists Coverage/Underinsured Motorists Coverage Selection/Rejection Form:',
    },
    WorkLoss_UT: {
      title:
        'I have read and agree to reject coverage as indicated on the Optional Work Loss Coverage Rejection Form:',
    },
    UMUIM_GA: {
      title:
        'I have read and agree to the coverage selected on the Uninsured Motorists Coverage Selection/Rejection form:',
    },
    UMUIM_CO: {
      title:
        'I have read and agree to reject coverage as indicated on the Agreement to Reject Uninsured Motorist Coverage form:',
    },
    MedicalExpenseReject_CO: {
      title:
        'I have read and agree to reject coverage as indicated on the Agreement to Reject Medical Expense Coverage form:',
    },
    PolicyDisclosures: {
      title: 'I have read and agree to the statements outlined in the following documents:',
    },
    UMUIM_AZ: {
      title:
        'I have read and agree to the coverage selected on the Agreement to Purchase/Reject Uninsured Motorists and Underinsured Motorists Insurance form:',
    },
    UMUIM_IL: {
      title:
        'I have read and agree to the coverage selected on the Illinois Uninsured and Underinsured Motorist Selection/Rejection form:',
    },
    UMUIM_TX: {
      title:
        'I have read and agree to reject coverage as indicated on the Agreement to Reject Uninsured Motorist Bodily Injury and Uninsured Motorist Property Damage Coverage form:',
    },
    UMUIM_OR: {
      title:
        'I have read and agree to the coverage selected on the Agreement to purchase lower limits of Uninsured Motorist Bodily Injury Coverage form:',
    },
    UMUIM: {
      title:
        'I understand and agree that my selection of Uninsured and/or Underinsured Motorists Coverage as applicable, outlined in the following document applies to my liability insurance policy, including any future renewals:',
    },
    PIPReject_TX: {
      title:
        'I have read and agree to reject coverage as indicated on the Agreement to Reject Personal Injury Protection Coverage form:',
    },
    PIPReject_MN: {
      title:
        'I have read and agree to reject coverage as indicated on the Agreement to Reject Stacked Personal Injury Protection coverage form:',
    },
    WorkLoss_MN: {
      title:
        'I have read and agree to exclude coverage as indicated on the Agreement to Exclude Work Loss Benefits form:',
    },
    MedicalExpenseReject_NV: {
      title:
        'I have read and agree to the coverage selected on the Agreement to Purchase/Reject Medical Expense Coverage form:',
    },
    UMUIM_NV: {
      title:
        'I have read and agree to the coverage selected on the Nevada Uninsured Motorist/Underinsured Motorist Coverage Selection or Rejection form:',
    },
    UMUIM_IN: {
      title:
        'I have read and agree to the coverage selected on the Uninsured/Underinsured Motorists Coverage Selection/Rejection form:',
    },
    CoverageSelection_NJ: {
      title: 'I have read and agree to the coverage selected on the Coverage Selection form.',
    },
    PIPReject_MD: {
      title:
        'I have read and agree to reject coverage as indicated on the Notice and Waiver of Personal Injury Protection (PIP) Coverage/Waiver of Personal Injury Protection (PIP) Coverage form:',
    },
    UMUIM_MD: {
      title:
        'I have read and agree to the coverage selected on the Required Notice of Uninsured Motorist (“UM”) Coverage and Enhanced Underinsured Motorist (“EUIM”) Coverage and Option Selection Form:',
    },
    UMUIM_CT: {
      title:
        'I have read and agree to the coverage selected on the Informed Consent Form Uninsured Motorist Coverage/Election of Coverage form:',
    },
    Residency_NH: {
      title:
        'I have read and attest that I am a resident of New Hampshire as indicated on the New Hampshire Statement of Residency Including Applicable Exemptions:',
    },
    UMUIM_AL: {
      title:
        'I have read and agree to reject coverage as indicated on the Agreement to reject Uninsured Motorists Coverage form:',
    },
    UMUIM_NE: {
      title:
        'I have read and agree to the coverage selected on the Uninsured and Underinsured Motorist Insurance Selection Form:',
    },
    UMUIM_IA: {
      title:
        'I have read and agree to reject coverage as indicated on the Agreement to Reject Uninsured Motorists Insurance and/or Underinsured Motorists Insurance form:',
    },
    UIM_WA: {
      title:
        'I have read and agree to the coverage selected on the Agreement to Reject or Lower Limits of Underinsured Motorists Coverage form:',
    },
    PIPReject_WA: {
      title:
        'I have read and agree to the coverage selected on the Personal Injury Protection Coverage and Agreement to Reject PIP Coverage form:',
    },
    OBEL_NY: {
      title:
        'I have read and agree to the coverage selected on the Offer of Optional Basic Economic Loss Coverage form:',
    },
    UMUIM_NY: {
      title:
        'I have read and agree to the coverage selected on the Agreement to reject or purchase lower limits of Supplementary Uninsured/Underinsured Motorists Insurance form:',
    },
    PIP_DC: {
      title:
        'I have read and agree to the coverage selected on the Personal Injury Protection Coverage District of Columbia form:',
    },
    PIP_AR: {
      title:
        'I have read and agree to reject coverage as indicated on the Agreement to reject Personal Injury Protection Coverages form:',
    },
    UMUIM_MS: {
      title:
        'I have read and agree to reject coverage as indicated on the Agreement to Reject Uninsured Motorists Coverage form:',
    },
    UMUIM_VT: {
      title:
        'I have read and agree to the coverage selected on the Vermont Uninsured/Underinsured Motorists Coverage form:',
    },
    UMUIM_VA: {
      title:
        'I have read and agree to the coverage selected on the Uninsured Motorists Coverage Selection form:',
    },
    UMUIM_MT: {
      title:
        'I have read and agree to reject coverage as indicated on the Uninsured & Underinsured Motorist Coverage Rejection Form:',
    },
    UMUIM_DE: {
      title:
        'I have read and agree to the coverage selected on the Delaware Uninsured and Underinsured Motorist Coverage Selection/Rejection Form:',
    },
    UMUIM_AR: {
      title:
        'I have read and agree to the coverage selected and/or rejected on the Agreement to reject Uninsured Motorists, Underinsured Motorists or Uninsured Motorists Property Damage or select lower limits of liability form:',
    },
    PIP_DE: {
      title:
        'I have read, understand my options and agree to the deductible selected on the Personal Injury Protection Deductible Notification Form:',
    },
    MotoristsProtection_DE: {
      title:
        'I have read and agree to the coverage selected on the Delaware Motorists Protection Act Required Statement to Policyholders Form:',
    },
    UMUIM_ID: {
      title:
        'I have read the explanations of Uninsured Motorist and Underinsured Motorist Coverages:',
    },
    UMUIMReject_ID: {
      title:
        'I agree to reject any coverage listed on the Idaho Uninsured Motorist and Underinsured Motorist Coverage - Option to Reject form:',
    },
    UMUIM_SC: {
      title:
        'I have read and agree to the coverage selected on the Offer of Additional Uninsured Motorists (UM) & Uninsured Motorists Property Damage (UMPD) Coverages and Offer of Optional Underinsured Motorists (UIM) & Underinsured Motorists Property Damage (UIMPD) Coverages form.',
    },
    UIMReject_PA: {
      title:
        'I have read and agree to reject coverage as indicated on the Underinsured Motorist Coverage Rejection form:',
    },
    UIMSelect_PA: {
      title:
        'I have read and agree to the coverage selected on the Underinsured Motorist Coverage Selection form:',
    },
    UMReject_PA: {
      title:
        'I have read and agree to reject coverage as indicated on the Uninsured Motorist Coverage Rejection form:',
    },
    UMSelect_PA: {
      title:
        'I have read and agree to the coverage selected on the Uninsured Motorist Coverage Selection form:',
    },
    Tort_PA: {
      title: 'I have read and agree to the tort option selected on the Notice to Named Insureds:',
    },
    UMUIM_CA: {
      title:
        'I have read and agree to the coverage selected on the Agreement to Reject or Amend Uninsured Motorists Coverage form:',
    },
    UMPD_CA: {
      title:
        'I have read and agree to reject coverage as indicated on the Agreement to Reject Uninsured Motorists Property Damage Insurance form:',
    },
    UMUIM_FL: {
      title:
        'I have read and agree to the coverage I selected on the Florida Uninsured Motorist Coverage Selection/Rejection form.',
    },
    UMUIMNonStacked_FL: {
      title:
        'I have read and agree that I rejected coverage as indicated on the Florida Election of Non-Stacked Uninsured Motorist Coverage form.',
    },
    CreditInformation_FL: {
      title:
        'In connection with your application for insurance, we will obtain an insurance score based on information in your credit history. You may request a copy of your credit information from the credit bureau. If corrections are made, we will upon your request re-evaluate your application based on the corrected information. Your signature acknowledges that youve read and understand the above statement.',
    },
    UMUIM_OK: {
      title:
        'I have read and agree to the coverage selected on the Oklahoma Non-Stacked Uninsured Motorists Coverage Law form.',
    },
    UMUIM_HI: {
      title:
        '	I have read and agree to the coverage selected on the Hawaii Uninsured and Underinsured Motorists Coverage Offer And Selection/Rejection Form.',
    },
    UMUIM_TN: {
      title:
        'I have read and agree to the coverage selected on the Agreement to Purchase Lower Uninsured Motorist Bodily Injury Limits or to Reject Uninsured Motorist Coverage form:',
    },
  },
  'amfam.auto': {
    // CPUI-2795
    'UFM 00 05': {
      title: 'I have read and agree to the coverage selected on the ',
      stateOptions: {
        IN: {
          title:
            'I have read and agree to reject coverage as indicated on the Agreement to Reject Coverage Form:',
        },
        KY: {
          title: 'I have read and agree to the ',
        },
      },
    },
    'UFM 00 06': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 80 23': {
      title: 'I have read and agree to:',
    },
    'BTC 00 02': {
      title: 'I have read and agree to:',
    },
    'UFM 80 30': {
      title: 'I have read and agree to the information in the following documents:',
    },
    'UFM 00 11': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 00 27': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 00 28': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 00 29': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 00 30': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 00 31': {
      title: 'I have read and agree to the ',
      stateOptions: {
        LA: {
          title: 'I have read and agree to the coverage selected on the ',
        },
      },
    },
    'UFM 00 32': {
      title:
        'I have read and agree to the certification statements on the Acknowledgement of Physical Damage Only Coverage form:',
    },
    'UFM 00 35': {
      title: 'I have read and agree to the ',
    },
    'UFM 00 40': {
      title: 'I have read and agree to the ',
    },
    'UFM 00 45': {
      title: 'I have read and agree to the ',
    },
    'UFM 00 58': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 00 90': {
      title: 'I have read and agree to the ',
    },
    'UFM 00 91': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 00 92': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 00 95': {
      title:
        'I have read and agree to the certification statements on the Medical Expense Selection/Rejection Form:',
    },
    'UFM 00 97': {
      title: 'I have read and agree to the ',
    },
    'UFM 00 98': {
      title:
        'I have read and agree to the certification statements on the Uninsured Motorist Property Damage Coverage Selection/Rejection Form:',
    },
    'UFM 01 02': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 01 05': {
      title: 'I have read and agree to the ',
    },
    'UFM 01 22': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 01 23': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 01 31': {
      title: 'I have read and agree to the ',
    },
    'UFM 00 67': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 00 57': {
      title: 'I have read and agree to the ',
    },
    'UFM 00058': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 00040': {
      title: 'I have read and agree to the ',
    },
    'UFM 00 56': {
      title: 'I have read and agree to the coverage selected on the ',
    },
  },
  'amfam-adv.auto': {
    // CPUI-2795
    'UFM 00 05': {
      title: 'I have read and agree to the coverage selected on the ',
      stateOptions: {
        IN: {
          title:
            'I have read and agree to reject coverage as indicated on the Agreement to Reject Coverage Form:',
        },
        KY: {
          title: 'I have read and agree to the ',
        },
      },
    },
    'UFM 00 06': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 80 23': {
      title: 'I have read and agree to:',
    },
    'BTC 00 02': {
      title: 'I have read and agree to:',
    },
    'UFM 80 30': {
      title: 'I have read and agree to the information in the following documents:',
    },
    'UFM 00 11': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 00 27': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 00 28': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 00 29': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 00 30': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 00 31': {
      title: 'I have read and agree to the ',
      stateOptions: {
        LA: {
          title: 'I have read and agree to the coverage selected on the ',
        },
      },
    },
    'UFM 00 32': {
      title:
        'I have read and agree to the certification statements on the Acknowledgement of Physical Damage Only Coverage form:',
    },
    'UFM 00 35': {
      title: 'I have read and agree to the ',
    },
    'UFM 00 40': {
      title: 'I have read and agree to the ',
    },
    'UFM 00 45': {
      title: 'I have read and agree to the ',
    },
    'UFM 00 58': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 00 90': {
      title: 'I have read and agree to the ',
    },
    'UFM 00 91': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 00 92': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 00 95': {
      title:
        'I have read and agree to the certification statements on the Medical Expense Selection/Rejection Form:',
    },
    'UFM 00 97': {
      title: 'I have read and agree to the ',
    },
    'UFM 00 98': {
      title:
        'I have read and agree to the certification statements on the Uninsured Motorist Property Damage Coverage Selection/Rejection Form:',
    },
    'UFM 01 02': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 01 05': {
      title: 'I have read and agree to the ',
    },
    'UFM 01 22': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 01 23': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 01 31': {
      title: 'I have read and agree to the ',
    },
    'UFM 00 67': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 00 57': {
      title: 'I have read and agree to the ',
    },
    'UFM 00058': {
      title: 'I have read and agree to the coverage selected on the ',
    },
    'UFM 00040': {
      title: 'I have read and agree to the ',
    },
    'UFM 00 56': {
      title: 'I have read and agree to the coverage selected on the ',
    },
  },
  'amfam-adv.home': {
    'UFM 00 32': {
      title:
        'I have read and agree to the certification statements on the Acknowledgement of Physical Damage Only Coverage form:',
    },
    hasWildfireExclusion: {
      title:
        'I understand that, unless I am scheduled to move into or take ownership of the property or have current insurance, there is no coverage for any loss resulting directly or indirectly from an active wildfire for the first 72 hours after this policy becomes effective.',
    },
  },
  'amfam-adv.renters': {
    'UFM 00 32': {
      title:
        'I have read and agree to the certification statements on the Acknowledgement of Physical Damage Only Coverage form:',
    },
    hasWildfireExclusion: {
      title:
        'I understand that, unless I am scheduled to move into or take ownership of the property or have current insurance, there is no coverage for any loss resulting directly or indirectly from an active wildfire for the first 72 hours after this policy becomes effective.',
    },
  },
  'homesite.home': {
    'UFM 00 32': {
      title:
        'I have read and agree to the certification statements on the Acknowledgement of Physical Damage Only Coverage form:',
    },
    hasWildfireExclusion: {
      title:
        'I understand that, unless I am scheduled to move into or take ownership of the property or have current insurance, there is no coverage for any loss resulting directly or indirectly from an active wildfire for the first 72 hours after this policy becomes effective.',
    },
  },
  'homesite.renters': {
    hasWildfireExclusion: {
      title:
        'I understand that, unless I am scheduled to move into or take ownership of the property or have current insurance, there is no coverage for any loss resulting directly or indirectly from an active wildfire for the first 72 hours after this policy becomes effective.',
    },
  },
};

export const agreeAcknowledgementMetadata: {
  [productKey: string]: BaseStateMetadataCollection;
} = {
  'connect.auto': {
    VehiclesTitled: {
      title: 'All vehicles are personally titled within the household.',
    },
    VehiclesJointTitling: {
      title: 'All vehicles are solely or jointly titled to the policyholder(s).',
    },
    VehiclesTitled_KY: {
      title:
        'All vehicles are personally titled to the policyholder(s) or to adults within the household.',
    },
    VehiclesTitled_VT: {
      title: 'All vehicles are personally titled or registered within the household.',
    },
    VehiclesAtResidence: {
      title: 'All vehicles are kept at the address supplied on this application.',
    },
    NoVehicleBusiness: {
      title:
        'No vehicle is used for a business including transporting people, making deliveries or used by employees.',
    },
    ValidLicense: {
      title: 'All drivers have a valid U.S. license.',
    },
    NoOtherOperators: {
      title:
        'There are no other regular operators of the vehicles in addition to those listed on the quote.',
    },
    NoSR22: {
      title: 'No driver on the quote requires an SR-22 filing.',
    },
    NoSR22_VA: {
      title: 'No driver on the quote requires an SR-22 or FR-44 filing.',
    },
    NoClassicOrAntique: {
      title: 'No vehicle on the quote is registered as classic or antique.',
    },
    NoCustomizations: {
      title:
        'No vehicle on the quote has customizations, outside of those by the original manufacturer design, that exceed $1,000.',
    },
    NoCustomizations_MD: {
      title:
        'No vehicle on the quote has customizations, outside of those by the original manufacturer design, that exceed $1,500.',
    },
    NoCustomizations_TX: {
      title:
        'No truck or van on the quote has customizations, outside of those by the original manufacturer design.',
    },
    NoCustomizations_VA: {
      title:
        'No vehicle on the quote has customizations, outside of those by the original manufacturer design, that exceed $1,500.',
    },
    NoCustomizations_WA: {
      title: 'No vehicles have customization not installed by the manufacturer.',
    },
    NoPerformance: {
      title: 'No vehicle on the quote has modifications that alter its performance.',
    },
    NoSharingProgram: {
      title: 'No vehicle on the quote is in a vehicle sharing program.',
    },
    NoExistingDamage: {
      title:
        'No vehicle for which you are requesting comprehensive and/or collision coverage has existing damage other than minor wear and tear.',
    },
    NoCustomizations_WI: {
      title:
        'No vehicle on the quote has customizations, outside of those by the original manufacturer design, that exceed $1,500.',
    },
    VehiclesTitled_NV: {
      title:
        'All vehicles are personally titled to the policyholder(s), solely or jointly with someone else in the household.',
    },
    VehiclesTitled_NJ: {
      title:
        'All vehicles are personally solely or jointly titled to the policyholder(s) or solely to others within the household.',
    },
    VehiclesTitled_NY: {
      title:
        'All vehicles are personally solely or jointly titled to the policyholder(s) or solely to others within the household.',
    },
    NoClassicOrAntique_MD: {
      title: 'No vehicle on the quote is registered as historic, classic or antique.',
    },
    NoVehicleBusiness_MD: {
      title:
        'No vehicle is used for a business, including transporting people (except for transporting children for an in-home daycare), making deliveries, or is used by employees.',
    },
    VehiclesTitled_CT: {
      title:
        'All vehicles are personally titled solely to the policyholder(s) or to adults within the household.',
    },
    NoLiability: {
      title:
        'I understand that the policy does not provide liability coverage for punitive or exemplary damages in any claim or case filed against me, other than for wrongful death.',
    },
    NoVehicleBusiness_DE: {
      title:
        'No vehicle is used for a business including making deliveries, carrying items or people for a fee or used by employees.',
    },
    LicensedLength_CA: {
      title:
        'I and each driver listed on the policy have been licensed for the last 18 months in the U.S. or Canada. If a driver other than the primary named insured on the policy has not been licensed in the U.S. or Canada for the last 18 months, I agree that they live in my household and are a child, caregiver, spouse/domestic partner or temporary guest.',
    },
    RejectUM_FL: {
      title:
        'If I reject uninsured motorist coverage or select coverage lower than the limit I select for bodily injury coverage, then I agree to read, sign, and return the uninsured motorist form within 30 days of the policy effective date.',
    },
    NonStackedUM_FL: {
      title:
        'If I select non-stacked uninsured motorist coverage, then I agree to read, sign, and return the uninsured motorist form within 30 days of the policy effective date.',
    },
    VehiclesTitled_NC: {
      title:
        'All vehicles are personally titled to the policyholder(s), solely or jointly with someone else in the household.',
    },
    PermanentResidence_NC: {
      title:
        'I am, and each named insured is, a resident of the State of North Carolina and maintain permanent residence at the address provided on this quote.',
    },
    LicensedUS: {
      title: 'Policyholders have been licensed in the US for at least 3 years.',
    },
  },
  'amfam.auto': {
    noFinancialResponsibility: {
      title: 'No driver on the policy requires an SR-22 or FR-44 filing.',
      stateOptions: {
        NH: {
          title: 'No driver on the policy requires an SR-22 filing.',
        },
      },
    },
    noFelonyConvictionLastThreeYears: {
      title: 'I do not have a felony conviction within the last 3 years.',
    },
    noBusinessTitleHolder: {
      title: 'The vehicle is not titled to a self-owned business, a corporate estate, or a trust.',
    },
    noBrandedTitle: {
      title:
        'The vehicles requesting comprehensive and/or collision coverage do not have a salvaged/branded title.',
    },
    noBusinessUse: {
      title: 'The vehicles are not used for delivery of people or goods.',
    },
    noUnrepariedDamage: {
      title:
        'The vehicles requesting comprehensive and/or collision coverage do not have any unrepaired damage.',
    },
    noAntiqueVehicle: {
      title: 'The vehicles are not antique/classic or replica type.',
    },
    noCustomizedVehicle: {
      title:
        'The vehicles are not customized from the original manufacturer design above $1,000 in value.',
    },
    garagedAtPolicyAddress: {
      title: 'All vehicles are garaged at the location you have requested for this policy.',
    },
    noVehiclePerformanceAlteration: {
      title: 'The vehicles on the quote do not have modifications that alter its performance.',
    },
    noNonPolicyDrivers: {
      title:
        'No other drivers regularly operate the vehicles other than those listed on the policy.',
    },
    excludeDriver: {
      title:
        'I understand that I may exclude a driver from this policy by contacting the Care Center.',
    },
    insuranceCertification: {
      title:
        'I understand that I may request a Maryland Vehicle Insurance Certiﬁcation (FR-19) form to be submitted to the Maryland Motor Vehicle Administration (MVA) by contacting the Care Center.',
    },
    newHampshireResident: {
      title:
        'You acknowledge that you are a New Hampshire resident. (To see if you qualify for an exception, please contact us)',
    },
    consumerReport: {
      title:
        'Consumer reports will be ordered to determine eligibility for insurance and rate your policy. These reports include information about claims, credit history, and driving history, and may include information about current and former members of your household. Consumer reports may also be used for updates, renewals, or extensions of this insurance. We may also obtain and use a credit-based insurance score based on information contained in your credit report. By continuing with this quote you consent to this practice. We may use a third party in connection with the development of the insurance score. Personal information may be collected from sources other than yourself. This will include a loss history report showing your personal insurance claims history that can be furnished to you upon request.',
    },
    insuranceCertificationDE: {
      title:
        'I understand that I may request a Delaware Insurance Certiﬁcation (FR-19) forms to be submitted to the Delaware Department of Motor Vehicles (DMV) by contacting the Care Center.',
    },
    additionalFees: {
      title:
        'I understand that the following fees may apply to this policy: A $25.00 returned bank item fee; $10.00 handling fee; $5.00 installment fee (each installment after the initial payment). Premium amount shown does not include installment fees.',
    },
    vehicleGaragedOutOfState: {
      title: 'All vehicles on this quote are solely titled and registered to the named insured(s).',
    },
  },
  'amfam-adv.auto': {
    certificationStatement: {
      title: `<li>This application is part of your policy.  You represent and warrant that the statements in your application are true.  We provide this policy on the condition that your statements are true. This policy will be voided from its inception as if it never was issued if you made any false statements, material misrepresentations or concealment of facts in your application that we rely on.</li>
              <li>Any person who, with intent to defraud or knowing that he or she is facilitating a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement may be guilty of insurance fraud.</li>
              <li><strong>IMPORTANT:</strong>  All proposed named insureds listed on this application must be made aware of the following consumer report statement by the person signing this application.  Consumer reports may be ordered in connection with this application. These reports provide information that may assist in determining your eligibility for insurance and the price you may be charged. We may also obtain and use a credit-based insurance score based on information contained in your credit report. We may use a third party in connection with the development of the insurance score. Consumer reports may also be used for updates, renewals or extensions of this insurance. You understand and agree that information we obtain from these reports may be shared with all named insureds on your policy.</li>
              <li>You understand that any rate quoted by the agent is an estimate and subject to final determination by the Company. The agent has no authority to bind rates.</li>
              <li>You understand that you may be required to take additional action to qualify for discounts and coverages included in this application.</li>
              <li>You certify that you have identified all drivers of the vehicles insured on this policy and all members of your household.</li>
              <li>You understand that the Company may consider your claims history in determining whether to decline, cancel, nonrenew, or surcharge this policy. Claims you incur during the term of this policy may be reported to an insurance support organization.</li>
              <li>By submission of this application you have read this statement (or have had it read to you) and have selected the limits and coverage indicated.</li>
              <li>If only one named insured is signing this application:  By signing this application, you agree to all statements on this application on behalf of all insureds.</li>`,
    },
  },
  // TODO See homesite.renters mapping and split homesite.home statements same way as disjoint vs combined
  'homesite.home': {
    hasDangerousDogs: {
      title:
        'I don’t have dangerous exotic animals, non-domesticated animals, or dogs with a bite history.',
    },
    hasDogBiteHistory: {
      title:
        'I don’t have dangerous exotic animals, non-domesticated animals, or dogs with a bite history.',
    },
    hasExoticAnimals: {
      title:
        'I don’t have dangerous exotic animals, non-domesticated animals, or dogs with a bite history.',
    },
    hasDangerousDogBreed: {
      title:
        'I don’t have dogs of the following breeds: Akita, Alaskan Malamute, Chow Chow, Doberman, Pit Bull, Rottweiler, Staffordshire Terrier.',
    },
    isBusinessNotConductedOnPremises: {
      title:
        'I don’t conduct any business on the premises, including a child-care or day-care facility, or commercial/retail farming.',
    },
    isBusinessConductedOnPremises: {
      title:
        'The business I conduct on premises is not a child-care or day-care facility or commercial/retail farming, and I have fewer than 3 customer visits per week.',
    },
    hasDayCare: {
      title:
        'I don’t conduct any business on the premises, including a child-care or day-care facility, or commercial/retail farming.',
    },
    hasRetailFarming: {
      title:
        'I don’t conduct any business on the premises, including a child-care or day-care facility, or commercial/retail farming.',
    },
    hasBusinessVisitors: {
      title:
        'I don’t conduct any business on the premises, including a child-care or day-care facility, or commercial/retail farming.',
    },
    hasWoodStove: {
      title: 'This house is not primarily heated by wood.',
    },
    isSwimmingPoolOnPremises: {
      title:
        'My pool is enclosed by a fence at least 4 feet high or ladders are removed or locked when not in use.',
    },
    isMobileOrManufactured: {
      title: 'This is not a mobile or manufactured home.',
    },
    isPrimaryOccupancy: {
      title:
        'I own this home and it is or will be my primary residence within 60 days of the policy effective date.',
    },
    isOilTankOnPremises: {
      title: 'This property does not have an underground oil tank on the premises.',
    },
    hasMineSubsidenceDamagedStructures: {
      title:
        'I do not have any structures on my property that have ongoing or unrepaired mine subsidence damage.',
    },
    hasProfessionallyInstalledWindstormShutters: {
      title:
        'This property does not have professionally installed windstorm shutters, protective awnings, or professionally installed foundation tie downs.',
    },
    hasFullTimeResidentEmployee: {
      title: 'I don’t have any full time residence employees.',
    },
    hasDogsDeclaredAggressiveByState: {
      title:
        'My dog(s) has not been declared aggressive by the state or community, and there has been no claims paid to a third party as a result of a bite.',
    },
    hasSevereStormProtectFixture: {
      title:
        'This property does not have windstorm shutters or impact-resistant glass on all windows, sliding glass doors, and skylights.',
    },
    hasMoreThanTwoFullTimeResidentEmployees: {
      title:
        'I do not employ more than two full time employees, such as a nanny, au pair, gardener, caretaker, maid, or butler, at this residence.',
    },
    isAnimalLiabilityCoverageRequired: {
      title: 'I do require animal liability insurance coverage.',
    },
    isAnimalLiabilityCoverageNotRequired: {
      title: 'I do not require animal liability insurance coverage.',
    },
    hasNoDangerousDogBreed: {
      title: 'I don’t have a dangerous dog breed.',
    },
    isNotRented: {
      title:
        'This house will never be used as a short or long-term rental, unless the property is a multi-family house and I occupy one of the units.',
    },
  },
  'amfam-adv.home': {
    hasDangerousDogs: {
      title:
        'I don’t have dangerous exotic animals, non-domesticated animals, or dogs with a bite history.',
    },
    hasDogBiteHistory: {
      title:
        'I don’t have dangerous exotic animals, non-domesticated animals, or dogs with a bite history.',
    },
    hasExoticAnimals: {
      title:
        'I don’t have dangerous exotic animals, non-domesticated animals, or dogs with a bite history.',
    },
    hasDangerousDogBreed: {
      title:
        'I don’t have dogs of the following breeds: Akita, Alaskan Malamute, Chow Chow, Doberman, Pit Bull, Rottweiler, Staffordshire Terrier.',
    },
    isBusinessNotConductedOnPremises: {
      title:
        'I don’t conduct any business on the premises, including a child-care or day-care facility, or commercial/retail farming.',
    },
    isBusinessConductedOnPremises: {
      title:
        'The business I conduct on premises is not a child-care or day-care facility or commercial/retail farming, and I have fewer than 3 customer visits per week.',
    },
    hasDayCare: {
      title:
        'I don’t conduct any business on the premises, including a child-care or day-care facility, or commercial/retail farming.',
    },
    hasRetailFarming: {
      title:
        'I don’t conduct any business on the premises, including a child-care or day-care facility, or commercial/retail farming.',
    },
    hasBusinessVisitors: {
      title:
        'I don’t conduct any business on the premises, including a child-care or day-care facility, or commercial/retail farming.',
    },
    hasWoodStove: {
      title: 'This house is not primarily heated by wood.',
    },
    isSwimmingPoolOnPremises: {
      title:
        'My pool is enclosed by a fence at least 4 feet high or ladders are removed or locked when not in use.',
    },
    isMobileOrManufactured: {
      title: 'This is not a mobile or manufactured home.',
    },
    isPrimaryOccupancy: {
      title:
        'I own this home and it is or will be my primary residence within 60 days of the policy effective date.',
    },
    isOilTankOnPremises: {
      title: 'This property does not have an underground oil tank on the premises.',
    },
    hasMineSubsidenceDamagedStructures: {
      title:
        'I do not have any structures on my property that have ongoing or unrepaired mine subsidence damage.',
    },
    hasProfessionallyInstalledWindstormShutters: {
      title:
        'This property does not have professionally installed windstorm shutters, protective awnings, or professionally installed foundation tie downs.',
    },
    hasFullTimeResidentEmployee: {
      title: 'I don’t have any full time residence employees.',
    },
    hasDogsDeclaredAggressiveByState: {
      title:
        'My dog(s) has not been declared aggressive by the state or community, and there has been no claims paid to a third party as a result of a bite.',
    },
    hasSevereStormProtectFixture: {
      title:
        'This property does not have windstorm shutters or impact-resistant glass on all windows, sliding glass doors, and skylights.',
    },
    hasMoreThanTwoFullTimeResidentEmployees: {
      title:
        'I do not employ more than two full time employees, such as a nanny, au pair, gardener, caretaker, maid, or butler, at this residence.',
    },
    hasNoDangerousDogBreed: {
      title: 'I don’t have a dangerous dog breed.',
    },
    isNotRented: {
      title:
        'This house will never be used as a short or long-term rental, unless the property is a multi-family house and I occupy one of the units.',
    },
  },
  'amfam-adv.renters': {
    certificationStatement: {
      title: `<li><strong>THESE STATEMENTS</strong> are accurate to the best of my knowledge. The company may rely upon them in issuance of this policy. I understand that this policy may be subject to a minimum premium retention if I cancel this policy.</li>
              <li> <strong>AMOUNTS OF INSURANCE:</strong> You are responsible for sufficient amounts of insurance for all coverage in this policy. You must notify us if you wish to increase or change any amount of insurance. This includes but is not limited to any increase due to:
                 a. structure, building addition, alteration, fixture, improvement, or installation constructed at your expense on the residence premises; or
                 b. purchase of personal property.
              </li>
              <li><strong>CONCEALMENT OR FRAUD:</strong>
                 This application is part of your policy. You represent that the statements in your application are true. We provide this policy on the condition that your statements are true. Any misrepresentations, omissions, concealment of facts, or incorrect statements you make on this application may lead to the denial of insurance benefits if:
                 1. they are fraudulent;
                 2. they are material to the acceptance of the risk or hazard assumed by the Company; and
                 3. we would not have issued the policy, or would have issued the policy with additional limitations, had true facts been provided on this application.
              </li>
              <li><strong>CONSUMER REPORTS:</strong> IMPORTANT - All proposed named insureds listed on this application must be made aware of the following consumer report statement by the person signing this application. Consumer reports may be ordered in connection with this application. These reports provide information that may assist in determining your eligibility for insurance and the price you may be charged. We may also obtain and use a credit-based insurance score based on information contained in your credit report. We may use a third party in connection with the development of the insurance score. Consumer reports may also be used for updates, renewals or extensions of this insurance. You understand and agree that information we obtain from these reports may be shared with all named insureds on your policy.</li>
              <li><strong>DOCUMENTATION:</strong> You understand that you may be required to take additional action to qualify for discounts and coverages included in this application.</li>
              <li><strong>Flood Coverage through the National Flood Insurance Program:</strong> I understand this policy does not cover damage to property caused by flooding. Flood insurance is available for properties within communities that participate in the National Flood Insurance Program (NFIP). Not all communities participate in the NFIP. However, flood insurance may still be available even if I do not live in a participating community.</li>
              <li><strong>Information Practices:</strong> Personal information may be collected from others. The information collected by us or our agent may in certain circumstances be disclosed to third parties without authorization. You have the right to see your personal records and correct personal information collected. A detailed notice of information practices will be furnished by us or our agent if you request it.</li>
              <li><strong>Exterior Inspection:</strong> We may, but have no duty to inspect your property or operations. Our inspection report or any resulting advice does not warrant that your property or operations are safe, healthful or in compliance with any law or regulation. We have no duty to provide a copy of any report if we do an inspection.</li>
              <li><strong>Certification:</strong> You agree to all statements on this application on behalf of all insureds. All insureds must comply with all policy terms. Any failure to comply with policy terms by any insured will affect policy coverage for all insureds.</li>`,
    },
    'Sewer Back-up': {
      title: 'Sewer Back-up',
    },
    'Septic Back-up': {
      title: 'Septic Back-up',
    },
    'Sump Overflow': {
      title: 'Sump Overflow',
    },
    'Coverage A': {
      title: 'Coverage A',
    },
    'Coverage B': {
      title: 'Coverage B',
    },
    'Coverage C': {
      title: 'Coverage C',
    },
  },
  'homesite.renters': {
    // hasDangerousDogs is a "legacy" node and has to be skipped ECP-3306
    hasNoDangerousDogBreed: {
      title: 'I don’t have a dangerous dog breed.',
    },
    hasDogBiteHistory: {
      title: 'I don’t have a dog with a bite history.',
    },
    hasExoticAnimals: {
      title: 'I don’t have dangerous exotic animals, non-domesticated animals.',
    },
    isAnimalLiabilityCoverageRequired: {
      title: 'I do require animal liability insurance coverage.',
    },
    isAnimalLiabilityCoverageNotRequired: {
      title: 'I do not require animal liability insurance coverage.',
    },
    hasFullTimeResidentEmployee: {
      title: 'I don’t have any full time residence employees',
    },
    hasProfessionallyInstalledWindstormShutters: {
      title:
        'This property does not have professionally installed windstorm shutters, protective awnings, or professionally installed foundation tie downs.',
    },
    hasDogsDeclaredAggressiveByState: {
      title:
        'My dog(s) has not been declared aggressive by the state or community, and there has been no claims paid to a third party as a result of a bite.',
    },
    isNotRetired: {
      title: 'The insured(s) is not considered retired and works three or more full days a week.',
    },
    hasMoreThanTwoFullTimeResidentEmployees: {
      title:
        'I do not employ more than two full time employees, such as a nanny, au pair, gardener, caretaker, maid, or butler, at this residence.',
    },
    hasSevereStormProtectFixture: {
      title:
        'This property does not have windstorm shutters or impact-resistant glass on all windows, sliding glass doors, and skylights.',
    },
  },
};
