import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PostBindFormBody' })((theme) => ({
  informationCard: {
    backgroundColor: theme.palette.grey[100],
    borderTopColor: theme.palette.primary.main,
    borderTopStyle: 'solid',
    borderTopWidth: 4,
    padding: '0px 20px 20px 20px',
  },
  additionalInformation: {
    display: 'flex',
    flexDirection: 'column',
  },
  attentionedNeededColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  policyInformationHeader: {
    ...theme.typography.h2,
    marginTop: 40,
    marginBottom: 5,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 30,
    },
  },
  policiesInformation: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    [theme.breakpoints.up('lg')]: {
      marginLeft: 30,
    },
    [theme.breakpoints.only('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: 30,
    },
    marginTop: 0,
  },
  columnHeader: {
    marginTop: 40,
    marginBottom: 5,
  },
  columnContentHeader: { marginTop: 20 },
  allSetContainer: { marginTop: 60 },
  policyCardSpacing: {
    marginBottom: 20,
  },
  policyCardContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  policyCardsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  bulletedList: {
    display: 'list-item',
  },
  tryAgainButton: {},
  tryAgainButtonContainer: {
    marginRight: 20,
  },
  redColumnHeader: {
    color: theme.palette.error.main,
  },
  dialogRoot: { maxWidth: '100%' },
  errorInformationCard: { borderColor: theme.palette.error.main },
  attentionedNeededReason: {
    ...theme.typography.body1,
    marginTop: 10,
    marginBottom: 20,
  },
  callUsPhone: {
    ...theme.typography.body2Bold,
    marginBottom: -10,
    display: 'flex',
    height: '100%',
  },
}));
