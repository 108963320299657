import { Typography } from '@mui/material';

import { NumberFormat } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const WiringReplacedYearQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const wiringReplacedYear = useHomePropertyField('renovation.wiringReplacedYear');
  const {
    groupLabel = wiringReplacedYear.question?.title,
    trackingName = 'wiring_replaced_year_question',
  } = props;

  useAddFields({ wiringReplacedYear });

  if (!wiringReplacedYear.exists) return null;

  return (
    <NumberFormat
      {...wiringReplacedYear.props}
      groupLabel={<Typography variant='body4'>{groupLabel}</Typography>}
      format='####'
      id='WiringReplacedYear'
      placeholder='YYYY'
      trackingName={trackingName}
    />
  );
};
