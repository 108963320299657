import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'HelpCard' })((theme) => ({
  cardWrapper: {
    maxWidth: 345,
    border: `1px solid ${theme.palette.other.border}`,
    borderRadius: 4,
  },
  card: {
    padding: '10px 5px 0 5px',
    borderRadius: 'inherit',
    boxShadow: 'none',
  },
  content: {
    paddingTop: 10,
  },
  contentDiv: {
    paddingBottom: 5,
  },
  header: {
    ...theme.typography.h4,
    marginTop: 10,
    marginLeft: 10,
    [theme.breakpoints.down('xl')]: {
      marginLeft: 20,
      ...theme.typography.body3,
      fontWeight: 'bold',
    },
  },
  helpImage: {
    width: 60,
    height: 60,
  },
  askExpertGrid: {
    paddingBottom: 20,
  },
}));
