import { isKeyOf } from '@ecp/utils/common';

import { SAPI_NAMES_MAP } from '@ecp/features/sales/shared/constants';
import type { Answers } from '@ecp/features/sales/shared/types';

import type { QuestionsState } from '../types';
import { getLabelForAnswersValue } from './getLabelForAnswersValue';
import { getQuestionWithDefault } from './getQuestion';

/**
 * This method will return a subset of answers based on props.
 * Also, it will ensure type safety. Based on props type, the function
 * determines what property values to return and also the type of those
 * properties. It uses additional logic to map the property names with
 * SAPI names in case they are different.The returned type will be an
 * object with collection of properties from props type T.
 * For eg, if you want to get the address zip code and latitude
 * for a particular address reference, the function call will be:
 *
 * collectRefProperties(answers, 'address.abcdefgh', {
 *   zipcode: '',
 *   city: '',
 * });
 */
// TODO - Use re-reselect, modify it for lists as well
export const collectRefProperties = <
  T extends Record<string, string | number | symbol | boolean>,
  U extends string & keyof T,
>(
  answers: Answers,
  questions: QuestionsState,
  ref: string,
  props: T,
): T =>
  Object.keys(props).reduce((acc, inputProperty) => {
    const property = isKeyOf(inputProperty, SAPI_NAMES_MAP)
      ? SAPI_NAMES_MAP[inputProperty]
      : inputProperty;
    const answersKey = `${ref}.${property}`;
    const question = getQuestionWithDefault(answersKey, questions);
    const answersValue = answers[answersKey];
    if (question.options && answersValue)
      acc[inputProperty] = getLabelForAnswersValue(question, answersValue) as T[U];
    else acc[inputProperty] = (answers[answersKey] || props[inputProperty]) as T[U];

    return acc;
  }, {} as T);
