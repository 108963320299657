import type { Product } from '@ecp/features/shared/product';

import type { PostBindMessage } from '../types';

const defaultRetryMessages = [
  {
    messageHeader: 'Missing credit card token',
    messageKey: 'MissingCreditCardToken.',
    subtext: 'Credit card information provided is invalid.',
    subtextShort: 'invalid credit card information.',
  },
  {
    messageHeader: 'Invalid routing number',
    messageKey: 'We were unable to verify your routing number.',
    subtext: 'We were unable to verify your routing number.',
    subtextShort: 'an invalid routing number.',
  },
  {
    messageHeader: 'Invalid account number',
    messageKey: 'We were unable to verify your bank account number.',
    subtext: 'We were unable to verify your bank account number.',
    subtextShort: 'an invalid bank account number.',
  },
  {
    messageHeader: 'Invalid account number',
    messageKey: 'INVALID_ACH_FINANCIALS.',
    subtext: 'We were unable to verify your bank account number.',
    subtextShort: 'an invalid bank account number.',
  },
];

export const PostBindRetryMessaging: Record<Product, PostBindMessage[]> = {
  'amfam.auto': [
    ...defaultRetryMessages,
    {
      messageHeader: 'Invalid routing number',
      messageKey: 'INVALID_ROUTING_NUMBER.',
      subtext: 'Routing number provided does not match active bank',
    },
    {
      messageHeader: 'Invalid account number',
      messageKey: 'INVALID_ACCOUNT_NUMBER.',
      subtext: 'Account number provided does not match active bank.',
    },
    {
      messageHeader: 'Invalid financial token',
      messageKey: 'INVALID_FINANCIAL_TOKEN.',
      subtext: 'Financial account token provided for payment was not found or has expired',
    },
    {
      messageHeader: 'Credit card declined',
      messageKey: 'CreditCardDeclined',
      subtext: 'Credit card has been declined by credit card processor',
    },
    {
      messageHeader: 'Missing credit card data',
      messageKey: 'MISSING_CC_DATA',
      subtext: 'Credit card number or expiration date information was not provided.',
    },
    {
      messageHeader: 'Invalid credit card data',
      messageKey: 'INVALID_CC_DATA',
      subtext: 'Credit card information provided is invalid.',
    },
    {
      messageHeader: 'Invalid expiration date',
      messageKey: 'INVALID_EXPIRATION_DATE',
      subtext: 'Credit card expiration date contains non-calendar date.',
    },
  ],
  'homesite.home': [
    ...defaultRetryMessages,
    {
      messageHeader: 'Incorrect payment information.',
      messageKey:
        'Policy not generated, carrier returned status code: Declined, Eligibility Reason of: There is a problem with your answers below. We may have some missing or inaccurate information. Please check the Routing Number.',
      subtext:
        'We were unable to process your home policy as we may have some missing or inaccurate information. Please check the Routing Number and try again.',
    },
    // TODO this interface needs to be updated to follow the other carriers logic of having only one message key
    // The following two objects are a temporary fix because carrier was not able to provide us with the exact errorReason when purchase fails
    // Instead of doing an exact match we do a substring match for status code and the word credit card to see if we can allow a user to retry
    {
      messageHeader: 'Credit Card Authorization Failed. ',
      secondaryMessageKey: 'Credit Card Authorization Failed',
      messageKey: 'QAPI Validation Error',
      subtext:
        'We were unable to process your home policy due to your credit card authorization failure.',
    },
  ],
  'homesite.renters': [
    ...defaultRetryMessages,
    {
      messageHeader: 'Incorrect payment information.',
      messageKey:
        'Policy not generated, carrier returned status code: Declined, Eligibility Reason of: There is a problem with your answers below. We may have some missing or inaccurate information. Please check the Routing Number.',
      subtext:
        'We were unable to process your renters policy as we may have some missing or inaccurate information. Please check the Routing Number and try again.',
    },
    // TODO this interface needs to be updated to follow the other carriers logic of having only one message key
    // The following two objects are a temporary fix because carrier was not able to provide us with the exact errorReason when purchase fails
    // Instead of doing an exact match we do a substring match for status code and the word credit card to see if we can allow a user to retry
    {
      messageHeader: 'Credit Card Authorization Failed. ',
      secondaryMessageKey: 'Credit Card Authorization Failed',
      messageKey: 'QAPI Validation Error',
      subtext:
        'We were unable to process your renters policy due to your credit card authorization failure.',
    },
  ],
  'amfam-adv.auto': defaultRetryMessages,
  'amfam-adv.home': defaultRetryMessages,
  'amfam-adv.renters': defaultRetryMessages,
  'connect.auto': [
    ...defaultRetryMessages,
    {
      messageKey: 'Credit card has been declined by credit card processor.',
      subtext: 'The credit card information provided is not valid.',
    },
    {
      messageKey: 'CreditCardDeclined.',
      subtext: 'The credit card information provided is not valid.',
    },
  ],
  'connect-boost.auto': defaultRetryMessages,
};
