import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'SessionErrorStopPage' })((theme) => ({
  root: {
    padding: 50,
    margin: '15px 50px 0px 60px',
    alignContent: 'center',
    [theme.breakpoints.only('md')]: {
      paddingLeft: 30,
      marginLeft: 0,
      marginRight: 40,
    },
    [theme.breakpoints.down('md')]: {
      padding: 15,
      margin: 0,
    },
  },
  divider: {
    backgroundColor: theme.palette.other.divider,
    margin: '20px 0px',
  },
  reason: {
    ...theme.typography.body1,
    marginBottom: 23,
    [theme.breakpoints.down('md')]: {
      marginBottom: 10,
    },
  },
  guidance: {
    ...theme.typography.body1,
    marginBottom: 30,
    [theme.breakpoints.down('md')]: {
      marginBottom: 10,
    },
  },
  guidanceSubtitle1: {
    ...theme.typography.subtitle,
    marginBottom: 30,
    [theme.breakpoints.down('md')]: {
      marginBottom: 10,
    },
  },
  pageTitle: {
    ...theme.typography.h1,
    paddingTop: 150,
    [theme.breakpoints.down('md')]: {
      paddingTop: 10,
    },
  },
  unsupportedBrowserHelpText: theme.typography.body1Bold,
  imageContainer: {
    // these margins are designed to offset the left & right margins defined above for the container.
    // this is to enable our continued use of the <Grid> system, while also allowing the image to
    // expand to its full size.
    margin: '120px -50px 0px -60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: 'auto',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  devDiagnostic: {
    marginTop: 20,
    overflowWrap: 'normal',
    wordWrap: 'normal',
  },
  errorContainer: {
    flexDirection: 'column',
    backgroundColor: theme.palette.warning.light,
    padding: 15,
    border: `1px solid ${theme.palette.warning.main}`,
    width: 441,
    marginBottom: 25,
    [theme.breakpoints.down('md')]: {
      marginTop: 10,
    },
  },
  quotesError: {
    ...theme.mixins.form,
    maxWidth: 1144,
    margin: '60px auto 0 auto',
    [theme.breakpoints.down('xl')]: {
      maxWidth: theme.breakpoints.values.lg,
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: theme.breakpoints.values.md,
    },
    [theme.breakpoints.down('md')]: {
      margin: '10px 0px',
      padding: '0px 15px',
    },
  },
}));
