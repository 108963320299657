import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product, ProductName } from '@ecp/features/shared/product';

import { getSignatureMethodsByPerson } from '../../../state/acknowledgements';
import { RemoteClick2SignPerson } from './RemoteClick2SignPerson';

export interface Props extends QuestionProps {
  coverageType: ProductName;
  selectedProduct: Product;
}

export const RemoteClick2Sign: React.FC<Props> = (props) => {
  const { coverageType, selectedProduct } = props;

  const signaturePersons = useSelector((state) =>
    getSignatureMethodsByPerson(state, selectedProduct, 'REMOTE_CLICK_SIGN'),
  );

  return (
    <>
      {Object.entries(signaturePersons).map(([personRef]) => (
        <RemoteClick2SignPerson coverageType={coverageType} personRef={personRef} />
      ))}
    </>
  );
};
