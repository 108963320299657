import { memo, useCallback } from 'react';

import { trackEntryComplete, trackEntryStart } from '@ecp/utils/analytics/tracking';

import type { TextFieldProps } from '@ecp/components';
import { TextField as TextFieldBase } from '@ecp/components';
import { useFieldRef } from '@ecp/features/sales/form';

export const TextField: React.FC<TextFieldProps> = memo((props) => {
  const {
    fullWidth = true,
    helperText,
    name,
    trackingName,
    trackingLabel,
    value: valueProp,
    variant = 'outlined',
    onFocus,
    onBlur,
    pattern,
    ...rest
  } = props;
  const value = valueProp || '';

  const handleFocus = useCallback<NonNullable<TextFieldProps['onFocus']>>(
    (event) => {
      if (trackingName) {
        trackEntryStart?.({ action: trackingName, label: trackingLabel || (value as string) });
      }
      onFocus?.(event);
    },
    [onFocus, trackingLabel, trackingName, value],
  );

  const handleBlur = useCallback<NonNullable<TextFieldProps['onFocus']>>(
    (event) => {
      if (trackingName) {
        trackEntryComplete?.({
          action: trackingName,
          label: trackingLabel || event.target.value,
        });
      }
      onBlur?.(event);
    },
    [trackingName, trackingLabel, onBlur],
  );

  return (
    <TextFieldBase
      fullWidth={fullWidth}
      helperText={helperText}
      variant={variant}
      name={name}
      onFocus={handleFocus}
      onBlur={handleBlur}
      inputRef={useFieldRef(name)}
      value={value}
      pattern={pattern}
      {...rest}
    />
  );
});
