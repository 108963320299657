import { useCallback, useEffect, useMemo, useState } from 'react';

import { setDimension, TrackingDimensions } from '@ecp/utils/analytics/tracking';
import { isMasked, noop } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';

import { env } from '@ecp/env';
import { POLICY_VEHICLE_REF, PrefillFlow } from '@ecp/features/sales/shared/constants';
import {
  createRef,
  deleteAnswers,
  deleteInquiryRef,
  getAllValues,
  getAnswers,
  getBasicVehicles,
  getInferredValueForYesNoButton,
  getInquiryLoaded,
  getNavTracking,
  getOfferProductsSelectedByType,
  getReferencePagePaths,
  getShownFields,
  getVehicleAutoDeltaQuestions,
  getVehicleFields,
  getVehicleQuestions,
  getVehicleRefs,
  getVehicles,
  getVehiclesFields,
  getVehicleValues,
  setFormErrorsChangedByField,
  setPageStatusRemoved,
  updateAddedRef,
  updateAnswers,
  useField,
  usePrimaryAddressRef,
} from '@ecp/features/sales/shared/store';
import type {
  RootStore,
  ValidateFormParams,
  ValidateFormResult,
} from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type {
  Answers,
  AnswerValue,
  Condition,
  DriverVehicleListItem,
  FieldsDef,
  QuestionsMetadata,
  Vehicle,
  VehicleBasic,
  VehicleWithImage,
} from '@ecp/features/sales/shared/types';
import { GraphicGenericCarImageUrl, IconCardAutoImageUrl } from '@ecp/themes/base';
import type { Field, Fields } from '@ecp/types';

import { GARAGE_ADDRESS_REF_SUFFIX, VEHICLE_ADDRESS_REF } from '../../constants';
// import { getVehicleImageUrlForAllModels } from '../../util';
import { gettingVehicleImage } from '../vehicles';
import metadata from './metadata';

const getAddressRefForVehicle = (vehicleRef: string, allValues: Answers): string =>
  allValues[`${vehicleRef}.${GARAGE_ADDRESS_REF_SUFFIX}`] as string;

interface AddVehicleResult {
  // the api has received the update
  done: Promise<[string, string]>;
  // the ref used
  vehicleRef: string;
}

export const useVehicleKeptAddressRef = (): string =>
  useSelector(getAllValues)[VEHICLE_ADDRESS_REF] as string;

export const useDefaultVehicleAddressRef = (): string => {
  const primaryAddress = usePrimaryAddressRef();
  const secondaryAddress = useVehicleKeptAddressRef();
  if (secondaryAddress) return secondaryAddress;

  return primaryAddress;
};

export const useAddVehicle = (): (() => AddVehicleResult) => {
  const dispatch = useDispatch();
  const inquiryLoaded = useSelector(getInquiryLoaded);
  const addressRef = useDefaultVehicleAddressRef();

  return useCallback(() => {
    // FIXME: assume inquiry is loaded?
    if (!inquiryLoaded) {
      datadogLog({
        logType: 'error',
        message: 'inquiry not loaded',
        context: {
          logOrigin: 'libs/features/sales/quotes/auto/src/state/modelUtil/vehicleModelUtil.ts',
          functionOrigin: 'useAddVehicle/useCallback',
        },
      });
      throw new Error('inquiry not loaded');
    }
    const vehicleRef = dispatch(createRef('vehicle'));

    const updateAddressWork = dispatch(
      updateAnswers({
        answers: { [`${vehicleRef}.${GARAGE_ADDRESS_REF_SUFFIX}`]: addressRef },
      }),
    );
    const updateVehicleWork = dispatch(
      updateAddedRef({ type: POLICY_VEHICLE_REF, newRef: vehicleRef }),
    );

    const done = Promise.all([updateAddressWork, updateVehicleWork]).then((): [string, string] => [
      addressRef,
      vehicleRef,
    ]);

    return {
      done,
      addressRef,
      vehicleRef,
    };
  }, [addressRef, dispatch, inquiryLoaded]);
};

export const useAddressRefForVehicle = (vehicleRef: string): string =>
  getAddressRefForVehicle(vehicleRef, useSelector(getAllValues));

export const useUpdateVehicleAddressRef = (
  vehicleRef: string,
): ((isAtDefaultVehicleAddress: boolean) => Promise<void>) => {
  const dispatch = useDispatch();
  const currentVehicleAddressRef = useAddressRefForVehicle(vehicleRef);
  const defaultVehicleAddressRef = useDefaultVehicleAddressRef();
  const allAnswers = useSelector(getAnswers);
  const vehicleRefs: string[] = useSelector(getVehicleRefs);

  const updateVehicleAddressRef = async (isAtDefaultVehicleAddress: boolean): Promise<void> => {
    if (!vehicleRef) return;
    if (isAtDefaultVehicleAddress) {
      await dispatch(
        updateAnswers({
          answers: { [`${vehicleRef}.${GARAGE_ADDRESS_REF_SUFFIX}`]: defaultVehicleAddressRef },
        }),
      );

      /*
        when using default address, we need to delete unused address answer
        we should also account for potential race conditions, where the `currentVehicleAddressRef`
        is still pointing towards the defaultVehicleAddressRef
      */
      if (currentVehicleAddressRef && currentVehicleAddressRef !== defaultVehicleAddressRef) {
        // We have to check if address entries exists before deleting refs otherwise SAPI throws
        const addressEntriesExists = Object.keys(allAnswers).some((key) =>
          key.startsWith(currentVehicleAddressRef),
        );
        if (addressEntriesExists) {
          await dispatch(
            deleteInquiryRef({ refType: 'address', refId: currentVehicleAddressRef.split('.')[1] }),
          );
        }
      }
    } else if (currentVehicleAddressRef === defaultVehicleAddressRef) {
      const vehicleAddressRef = dispatch(createRef('address'));
      let answers: Answers = {
        [`${vehicleRef}.${GARAGE_ADDRESS_REF_SUFFIX}`]: vehicleAddressRef,
      };
      // if another garage address other than the PNI address is available, pre-fill it. CPCR-385
      // first check if exist the garage address which is NOT the pni address
      const vehicleNotAtDefaultAddress =
        vehicleRefs &&
        [...vehicleRefs]
          .reverse()
          .find(
            (ref, index) =>
              allAnswers[`${ref}.${GARAGE_ADDRESS_REF_SUFFIX}`] !== defaultVehicleAddressRef &&
              index > 0,
          );
      // if exists a different garage address, pre-fill the value to the new address.
      if (vehicleNotAtDefaultAddress) {
        const garageAddressRef = allAnswers[
          `${vehicleNotAtDefaultAddress}.${GARAGE_ADDRESS_REF_SUFFIX}`
        ] as string;
        answers = Object.keys(allAnswers).reduce((previous, key) => {
          if (key.startsWith(garageAddressRef)) {
            const newKey = key.replace(garageAddressRef, vehicleAddressRef);

            return { ...previous, [newKey]: allAnswers[key] };
          }

          return previous;
        }, answers);
      }
      await dispatch(
        updateAnswers({
          answers,
        }),
      );
    }
  };

  return updateVehicleAddressRef;
};

export const useUpdateVehicleKeptAddressRef = (): ((
  isPNIAddress: boolean,
  currentVehicleAddressRef?: string,
) => void) => {
  const dispatch = useDispatch();
  const primaryAddressRef = usePrimaryAddressRef();

  const updateVehicleKeptAddressRef = useCallback(
    async (isPNIAddress: boolean, currentVehicleAddressRef?: string): Promise<void> => {
      if (!isPNIAddress) {
        const newVehicleKeptAddressRef = dispatch(createRef('address'));
        await dispatch(
          updateAnswers({
            answers: { 'vehicle.address.ref': newVehicleKeptAddressRef },
          }),
        );
      } else {
        if (currentVehicleAddressRef && currentVehicleAddressRef !== primaryAddressRef) {
          await dispatch(deleteAnswers({ ref: currentVehicleAddressRef }));
        }
        await dispatch(
          updateAnswers({
            answers: { 'vehicle.address.ref': primaryAddressRef },
          }),
        );
      }
    },
    [dispatch, primaryAddressRef],
  );

  return updateVehicleKeptAddressRef;
};

export const useVehicleValues = (ref: string): Vehicle => {
  return useSelector((state: RootStore) => getVehicleValues(state, ref));
};

export const useVehicleImageUrls = (): { [key: string]: string } => {
  return useSelector((state: RootStore) => state.vehicles.images);
};

export const useVehiclesFields = (refs: string[]): Record<string, FieldsDef<Vehicle>> => {
  const dispatch = useDispatch();
  const { auto: autoOfferProduct } = useSelector(getOfferProductsSelectedByType);

  return useSelector((state: RootStore) =>
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    getVehiclesFields(state, refs, dispatch, autoOfferProduct!),
  );
};

export const useVehicleAutoDeltaMetadata = (
  vehicles: Vehicle[],
): { metadata: QuestionsMetadata; fields: Fields; vehicle: Vehicle }[] => {
  const dispatch = useDispatch();
  const { auto: autoOfferProduct } = useSelector(getOfferProductsSelectedByType);

  return useSelector((state: RootStore) =>
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    getVehicleAutoDeltaQuestions(state, vehicles, dispatch, autoOfferProduct!),
  );
};

export const useVehiclesForSidebar = (): VehicleBasic[] => {
  return useSelector((state: RootStore) => getBasicVehicles(state, getVehicleRefs(state)));
};

// returns current vehicle ref, and if it's not set,
// makes sure one is created via addVehicle
// also updates the NavVehicle reference
export const useAndEnsureCurrentVehicleRef = (vehicleRefParam?: string): string | undefined => {
  const vehicles = useSelector(getVehicleRefs);
  const addVehicle = useAddVehicle();
  const inquiryLoaded = useSelector(getInquiryLoaded);

  // FIXME: assume inquiry is loaded?
  if (!inquiryLoaded) {
    datadogLog({
      logType: 'error',
      message: 'inquiry not loaded',
      context: {
        logOrigin: 'libs/features/sales/quotes/auto/src/state/modelUtil/vehicleModelUtil.ts',
        functionOrigin: 'useAndEnsureCurrentVehicleRef',
      },
    });
    throw new Error('inquiry not loaded');
  }
  const vehiclesLastRef = (vehicles.length && vehicles[vehicles.length - 1]) || undefined;
  const vehicleRef = vehicleRefParam || vehiclesLastRef;

  useEffect(() => {
    if (!vehicleRef) {
      // NOTE: this is async but we will not wait for it
      addVehicle();
    }
  }, [addVehicle, vehicleRef]);

  return vehicleRef;
};

export const useGetVehicleItemsWithImage = (): VehicleWithImage[] => {
  const vehicles = useVehiclesForSidebar();
  const dispatch = useDispatch();
  // const vehicleImageUrls = useVehicleImageUrls();
  const [vehicleList, setVehicleList] = useState<VehicleWithImage[]>([]);

  const vehiclesWithImageUrls: VehicleWithImage[] = useMemo(() => {
    return vehicles.map((vehicle: VehicleBasic) => ({
      vehicle,
      // ECP-13138 We'll be reusing this image call with the appropriate image at a later time
      // imageUrl:
      //   getVehicleImageUrlForAllModels(vehicle, vehicleImageUrls) || GraphicGenericCarImageUrl,
      imageUrl: IconCardAutoImageUrl,
    }));
  }, [vehicles]);

  const getVehiclesWithImages = useMemo(
    () =>
      Promise.all(
        vehicles.map(async (vehicle: VehicleBasic) => {
          const { year, make, model } = vehicle;
          let rawImageUrl = '';
          if (year && make && model) {
            try {
              rawImageUrl = await dispatch(gettingVehicleImage({ year, make, model }));
            } catch (e) {
              datadogLog({
                logType: 'warn',
                message: `could not get image for vehicle - ${(e as Error).message}`,
                context: {
                  logOrigin:
                    'libs/features/sales/quotes/auto/src/state/modelUtil/vehicleModelUtil.ts',
                  contextType: 'Fuel API Warning',
                  severity: 'low',
                  functionOrigin: 'useGetVehicleItemsWithImage',
                  // TODO: we cannot spread anything into DD logs
                  // ...e,
                  vehicleYear: year,
                  vehicleMake: make,
                  vehicleModel: model,
                },
                error: e as Error,
              });
              // no image retrieved
            }
          }
          const imageUrl = rawImageUrl || GraphicGenericCarImageUrl;

          return imageUrl;
        }),
      ),
    [dispatch, vehicles],
  );

  useEffect(() => {
    getVehiclesWithImages.then(noop);
  }, [getVehiclesWithImages]);

  useEffect(() => {
    setVehicleList(vehiclesWithImageUrls);
  }, [vehiclesWithImageUrls]);

  return vehicleList;
};

export const useRestrictedGaragedState = (
  state: Field<AnswerValue>,
  primaryState: string,
): (() => boolean) => {
  return useCallback((): boolean => {
    const stateValue = state.props.value as string;
    if (stateValue !== primaryState && metadata.validateGaragedState) {
      return false;
    }

    return true;
  }, [primaryState, state]);
};

export const useKeptAtRiskAddressValue = (
  keptAtRiskAddress: Field,
  garageAddress: Field,
): boolean | null => {
  const defaultVehicleAddressRef = useDefaultVehicleAddressRef();

  return getInferredValueForYesNoButton({
    yesNoField: keptAtRiskAddress,
    getValue: () => {
      if (garageAddress.props.name.includes(defaultVehicleAddressRef)) return true;
      if (garageAddress.value) return false;

      return null;
    },
  });
};

export const useVehicleInfoOrVinValue = (vehicleInfoOrVin: Field, vin: Field): string => {
  if (vehicleInfoOrVin.value === undefined) {
    return env.static.isAgent ? 'vin' : vin.value ? 'vin' : 'vehicleInfo';
  }

  return String(vehicleInfoOrVin.value);
};

export interface VehicleFields extends Fields {
  vehicle: FieldsDef<Omit<Vehicle, 'ref'>>;
}

export interface VehicleProfileFields extends Fields {
  vehicle: FieldsDef<Pick<Vehicle, 'year' | 'make' | 'model' | 'series' | 'vin'>>;
}

export const getVehicleFormConditions = (
  fields: VehicleFields,
  vehicleInfoOrVinValue: string,
  keptAtRiskAddressValue: boolean | null,
): Condition[] => {
  if (!fields.vehicle) return [];
  const {
    vehicle: {
      businessUse,
      primaryUse,
      garage: {
        line1: garageAddress,
        line2: garageUnitOrApt,
        city: garageCity,
        state: garageState,
        zipcode: garageAtZip,
      },
      make,
      model,
      series,
      vin,
    },
  } = fields;

  return [
    {
      conditionalFields: [vin],
      isExcluded: () => vehicleInfoOrVinValue === 'vehicleInfo',
    },
    {
      conditionalFields: [make, model, series],
      isExcluded: () => vehicleInfoOrVinValue === 'vin',
    },
    {
      conditionalFields: [garageAddress, garageUnitOrApt, garageCity, garageState, garageAtZip],
      isExcluded: () => !!keptAtRiskAddressValue,
    },
    {
      conditionalFields: [businessUse],
      isExcluded: () => primaryUse.value !== 'VEHICLE_USE.PRIMARY.BUSINESS',
    },
    {
      conditionalFields: [primaryUse],
      isRequiredOverride: () => true,
    },
  ];
};

export const useVehicleRef = (vehicleId?: string): string | undefined => {
  const vehicleRefParam = vehicleId && `vehicle.${vehicleId}`;

  return useAndEnsureCurrentVehicleRef(vehicleRefParam);
};

type GarageFieldName = 'line1' | 'line2' | 'city' | 'state' | 'zipcode';
export const useGarageField = (ref: string, garageFieldName: GarageFieldName): Field => {
  const answers = useSelector((state: RootStore) => getAnswers(state));
  const garageRef = String(answers[`${ref}.garage.address.ref`]);

  return useField(`${garageRef}.${garageFieldName}`);
};

const vehicleFormKeys: { [key in PrefillFlow]: Array<{ key: string }> } = {
  [PrefillFlow.LONG]: [
    { key: 'year' },
    { key: 'make' },
    { key: 'model' },
    { key: 'series' },
    // TODO: I'm unsure about these
    // { key: 'vin' },
    // { key: 'line1' },
    // { key: 'line2' },
    // { key: 'city' },
    // { key: 'state' },
    // { key: 'zipcode' },
    { key: 'primaryUse' },
    { key: 'businessUse' },
    { key: 'features.safety.antiLockBrakes' },
    { key: 'features.safety.airbag' },
    { key: 'features.safety.dayTimeRunningLights' },
    { key: 'features.safety.electronicStabilityControl' },
    { key: 'features.antitheft' },
    { key: 'keptAtRiskAddress' },
  ],
  [PrefillFlow.SHORT]: [{ key: 'year' }, { key: 'vin' }],
};

export const useGetVehicleFieldsForValidation = (
  vehicles: VehicleBasic[],
  flow: PrefillFlow,
): { [key: string]: Fields } => {
  const dispatch = useDispatch();

  return useSelector((state: RootStore) =>
    getVehicleQuestions(state, vehicles, dispatch, vehicleFormKeys[flow]),
  );
};

export const useValidateVehicleInfo = (
  vehicleInfoOrVinValue: string,
  make: Field,
  model: Field,
  series: Field,
): (() => boolean) => {
  const dispatch = useDispatch();

  return useCallback((): boolean => {
    if (vehicleInfoOrVinValue === 'vehicleInfo') {
      const makeValue = make.props.value as string;
      const modelValue = model.props.value as string;
      const seriesValue = series.props.value as string;
      if (!makeValue) {
        dispatch(setFormErrorsChangedByField({ key: make.key, errors: ['Required field'] }));

        return false;
      }
      if (!modelValue) {
        dispatch(setFormErrorsChangedByField({ key: model.key, errors: ['Required field'] }));

        return false;
      }
      if (!seriesValue) {
        dispatch(setFormErrorsChangedByField({ key: series.key, errors: ['Required field'] }));

        return false;
      }
    }

    return true;
  }, [dispatch, vehicleInfoOrVinValue, make, model, series]);
};

export const useCheckDuplicateVin = (vin: Field, vehicleRef: string): (() => void) => {
  const dispatch = useDispatch();
  const vehicleRefs = useSelector(getVehicleRefs);
  const getVehicles = useVehiclesFields(vehicleRefs);

  return useCallback((): void => {
    const vehicleVin = vin.props.value as string;
    const vehicles = getVehicles;

    if (isMasked(vehicleVin)) return;

    if (Object.keys(vehicles).length > 1 && vehicleVin) {
      const hasDuplicateVin = Object.entries(vehicles).some(([key, value]) => {
        if (isMasked(value.vin.props.value)) return false;
        const checkVehicleRef = key !== vehicleRef;
        const checkVin = value.vin.props.value === vehicleVin;

        return checkVehicleRef && checkVin;
      });
      if (hasDuplicateVin) {
        dispatch(
          setFormErrorsChangedByField({
            key: vin.key,
            errors: ['This VIN has already been added to the quote. Enter a different VIN.'],
          }),
        );
      }
    }
  }, [dispatch, getVehicles, vin, vehicleRef]);
};

export const checkDuplicateVIN = (
  vehicles: Record<string, FieldsDef<Vehicle>>,
  vehicleVin: AnswerValue,
  vehicleRef: string,
): boolean => {
  if (isMasked(vehicleVin)) return false;
  if (Object.keys(vehicles).length > 1 && vehicleVin) {
    const hasDuplicateVin = Object.entries(vehicles).some(([key, value]) => {
      if (isMasked(value.vin.props.value)) return false;
      const checkVehicleRef = key !== vehicleRef;
      const checkVin = value.vin.props.value === vehicleVin;

      return checkVehicleRef && checkVin;
    });
    if (hasDuplicateVin) {
      return true;
    }

    return false;
  }

  return false;
};

export const useRemoveUnusedRefsForVehicle = (): ((vehicleRef: string) => Promise<void>) => {
  const dispatch = useDispatch();
  const vehicle = useSelector(getVehicles);
  const navTracking = useSelector(getNavTracking);

  return useCallback(
    async (vehicleRef: string) => {
      const paths = vehicleRef ? getReferencePagePaths(navTracking, vehicleRef.split('.')[1]) : [];
      paths.forEach((path) => dispatch(setPageStatusRemoved(path)));

      if (vehicle) {
        await dispatch(deleteInquiryRef({ refType: 'vehicle', refId: vehicleRef.split('.')[1] }));
      }
    },
    [dispatch, navTracking, vehicle],
  );
};

export const useVehicleItemList = (): DriverVehicleListItem[] => {
  const vehicleItems = useGetVehicleItemsWithImage();

  return useMemo(
    () =>
      vehicleItems?.map(({ vehicle, imageUrl }) => {
        const { description } = vehicle;
        const obj = {
          description,
          imageUrl,
        };

        return obj;
      }),
    [vehicleItems],
  );
};

export const useVehicleEditValidation = (
  validateForm: (params?: ValidateFormParams) => ValidateFormResult,
  vehicleInfoOrVinValue: string,
  fetchVehicleInfo: (year: string, vinNumber: string) => Promise<boolean>,
  year: AnswerValue,
  vin: AnswerValue,
  goToFirstError: () => boolean,
  isEdit: boolean,
  fieldsLength: number,
  vehicleRef: string,
): void => {
  useEffect(() => {
    async function validateVehicleInfoEdit(): Promise<void> {
      validateForm();
      if (vehicleInfoOrVinValue === 'vin') {
        const vehicleInfoError = await fetchVehicleInfo(year as string, vin as string);
        if (vehicleInfoError) goToFirstError();
      }
    }
    if (isEdit && fieldsLength) {
      validateVehicleInfoEdit();
    }
    setDimension(TrackingDimensions.VEHICLE_REF, vehicleRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleRef, fieldsLength]);
};

/**
 * Determines if the quote has a Gen10 vehicle by going
 * through all the vehicle refs and checks if ownership date should be shown.
 * Gen10 vehicles have GM's tailored telematics.
 *
 * useMemo to run it only when vehicleFields changes
 */
export const useCheckHasGen10Vehicle = (): boolean => {
  const dispatch = useDispatch();
  const vehicleRefs = useSelector(getVehicleRefs);
  const vehiclesFields = useVehiclesFields(vehicleRefs);

  return useMemo(() => {
    const filteredVehicleRefs = vehicleRefs.filter((ref) => {
      const vehicleField = vehiclesFields[ref];
      if (vehicleField?.ownershipDate.exists) {
        const { ownershipDate } = vehicleField;
        const filteredFields = dispatch(
          getShownFields({ fields: { [ownershipDate.key]: ownershipDate } }),
        ) as Fields;

        return !!filteredFields[ownershipDate.key];
      }

      return false;
    });

    return filteredVehicleRefs.length > 0;
  }, [dispatch, vehiclesFields, vehicleRefs]);
};

/** Determines if the vehicle ownership date should be hidden. Also helps in determining if a vehicle is a Gen10 vehicle */
export const useCheckShowOwnershipDate = (ownershipDate: Field): boolean => {
  const dispatch = useDispatch();

  return useMemo(() => {
    if (ownershipDate.exists) {
      const filteredFields = dispatch(
        getShownFields({ fields: { [ownershipDate.key]: ownershipDate } }),
      ) as Fields;

      return !!filteredFields[ownershipDate.key];
    }

    return false;
  }, [dispatch, ownershipDate]);
};

export const checkAndClearFields = (fields: Field[]): void => {
  fields.forEach((field) => {
    if (field.value) {
      field.validateUpdateAndPatch('');
    }
  });
};

/** @deprecated We added `useVehicleFields` function which it seems like is doing similar thing. */
export const useVehicleFieldsTmp = (ref: string): FieldsDef<Vehicle> => {
  const dispatch = useDispatch();
  const { auto: autoOfferProduct } = useSelector(getOfferProductsSelectedByType);

  return useSelector((state: RootStore) =>
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    getVehicleFields(state, ref, dispatch, autoOfferProduct!),
  );
};

export const useVehicleFields = (
  vehicleRef: string,
): {
  make: Field;
  model: Field;
  series: Field;
  vin: Field;
  year: Field;
  stubVin: Field;
  vehicleDetailId: Field;
  vehicleInfoOrVin: Field;
  msrpPrice: Field;
} => {
  return {
    make: useField(`${vehicleRef}.make`),
    model: useField(`${vehicleRef}.model`),
    series: useField(`${vehicleRef}.series`),
    vin: useField(`${vehicleRef}.vin`),
    year: useField(`${vehicleRef}.year`),
    stubVin: useField(`${vehicleRef}.stubVin`),
    vehicleDetailId: useField(`${vehicleRef}.vehicleDetailId`),
    vehicleInfoOrVin: useField(`static.${vehicleRef}.vehicleInfoOrVin`),
    msrpPrice: useField(`${vehicleRef}.msrpPrice`),
  };
};
