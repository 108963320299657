import { memo, useCallback, useState } from 'react';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem, NumberFormat } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { RadioGroupWithOptions, Select, TextField } from '@ecp/features/sales/shared/components';
import { PRIMARY_INSURED_MAILING_ADDRESS_REF } from '@ecp/features/sales/shared/constants';
import {
  createRef,
  fetchInquiry,
  removeAndDeleteRef,
  updateAnswers,
  useMailingAddressRef,
  usePrimaryAddressRef,
} from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { OptionProps } from '@ecp/features/sales/shared/types';

import { useMailingAddressFields } from '../../../../util/mailingAddressUtil';
import { useStyles } from './MailingAddressQuestions.styles';

export const MailingAddressQuestions: React.FC = memo(() => {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const primaryInsuredAddressRef = usePrimaryAddressRef();
  const primaryInsuredMailingAddressRef = useMailingAddressRef();
  const [mailingSameAsProperty, setMailingSameAsProperty] = useState(
    primaryInsuredAddressRef === primaryInsuredMailingAddressRef,
  );

  const mailingAddressFieldLabel = 'Is the mailing address the same as the property address?';
  const {
    address: {
      line1: mailingAddressLine1,
      line2: mailingAddressLine2,
      city: mailingAddressCity,
      state: mailingAddressState,
      zipcode: mailingAddressZipcode,
    },
  } = useMailingAddressFields();

  useAddFields({
    mailingAddressLine1,
    mailingAddressLine2,
    mailingAddressCity,
    mailingAddressState,
    mailingAddressZipcode,
  });

  const handleClick = useCallback(
    async (value: boolean) => {
      if (!value) {
        // Create New Mailing Address Ref when mailing and primary address are not the same
        const newMailingAddressRef: string = dispatch(createRef('address'));
        await dispatch(
          updateAnswers({
            answers: {
              [PRIMARY_INSURED_MAILING_ADDRESS_REF]: newMailingAddressRef,
            },
          }),
        );
        // Change the value post the creation of the ref
        setMailingSameAsProperty(value);
      } else {
        // Change the value prior to delete the answers
        setMailingSameAsProperty(value);
        // Delete the answer keys for the new ref that was created
        // and reset it to primaryInsuredAddressRef
        await Promise.all([
          dispatch(
            removeAndDeleteRef({
              refType: PRIMARY_INSURED_MAILING_ADDRESS_REF,
              ref: primaryInsuredMailingAddressRef,
            }),
          ),
          dispatch(
            updateAnswers({
              answers: {
                [PRIMARY_INSURED_MAILING_ADDRESS_REF]: primaryInsuredAddressRef,
              },
            }),
          ),
        ]);
      }
      // Fetch on inquiry to update the question set
      await dispatch(fetchInquiry({}));
    },
    [dispatch, primaryInsuredAddressRef, primaryInsuredMailingAddressRef],
  );

  return (
    <>
      <GridItem topSpacing='sm' xs={12}>
        <RadioGroupWithOptions
          value={mailingSameAsProperty}
          actionOnComplete={handleClick}
          label={mailingAddressFieldLabel}
          id='MailingAddressQuestion'
          variant='yesNoButton'
          trackingName='mailing_address_same_as_property_selection'
        />
      </GridItem>
      {!mailingSameAsProperty && (
        <>
          <GridItem topSpacing='lg' xs={12} md={6} lg={6} className={classes.columnLeft}>
            <TextField
              {...mailingAddressLine1.props}
              id='MailingAddressLine1'
              label='Mailing Address'
              ariaLabel='Mailing address line 1'
              trackingName='mailing_address'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
            />
          </GridItem>
          <GridItem topSpacing='lg' xs={12} md={6} lg={6} className={classes.columnRight}>
            <TextField
              {...mailingAddressLine2.props}
              id='MailingAddressLine2'
              label='Apt./Unit # (optional)'
              ariaLabel='Mailing address unit# (optional)'
              trackingName='mailing_address_line_2'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
            />
          </GridItem>
          <GridItem topSpacing='sm' xs={12} md={6} lg={6} className={classes.columnLeft}>
            <TextField
              {...mailingAddressCity.props}
              id='MailingAddressCity'
              label='City'
              ariaLabel='Mailing address city'
              trackingName='mailing_city'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
            />
          </GridItem>
          <GridItem
            topSpacing='sm'
            xs={6}
            md={3}
            lg={3}
            className={cx(classes.columnMiddle, classes.columnLeftPadding)}
          >
            <Select
              {...(mailingAddressState.props as OptionProps)}
              id='MailingAddressState'
              label='State'
              inputButtonAriaLabel='Mailing Address State'
              trackingName='mailing_state'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
            />
          </GridItem>
          <GridItem
            topSpacing='sm'
            xs={6}
            md={3}
            lg={3}
            className={cx(classes.columnRight, classes.columnRightPadding)}
          >
            <NumberFormat
              {...mailingAddressZipcode.props}
              id='MailingAddressZipcode'
              formatType='zipcode'
              label='ZIP code'
              ariaLabel='Mailing Address Zip code'
              data-testid='MailingAddressZipcode'
              trackingName='mailing_zip_code'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
            />
          </GridItem>
        </>
      )}
    </>
  );
});
