import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CreditCard' })((theme) => ({
  alertContainer: {
    display: 'flex',
    textAlign: 'left',
  },
  alertContainerError: {
    backgroundColor: theme.palette.error.light,
    border: `2px solid ${theme.palette.error.main}`,
    borderBottom: 0,
    borderRadius: 0,
  },
  alertText: {
    color: theme.palette.text.primary,
    fontFamily: theme.typography.body1,
    fontSize: 14,
  },
  creditCardWrapper: {
    width: '100%',
  },
  datePicker: {
    minWidth: 137.5,
  },
  iFrame: {
    margin: 'auto',
    border: 'none',
    display: 'block',
    paddingLeft: 14,
  },
  iFrameMobile: {
    margin: 'auto',
    border: 'none',
    display: 'block',
    paddingLeft: 0,
  },
  iFrameWrapper: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  iFrameWrapperError: {
    border: `2px solid ${theme.palette.error.main}`,
  },
  helperText: {
    ...theme.typography.body2,
    color: theme.palette.text.tertiary,
    marginLeft: 12,
    marginBottom: 10,
  },
  helperTextMobile: {
    ...theme.typography.body2,
    color: theme.palette.text.tertiary,
    marginLeft: 0,
    marginBottom: 10,
  },
  creditCardField: {
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
      maxWidth: 'none',
    },
  },
  errorIcon: {
    ...theme.mixins.setColorSvg(theme.palette.error.main),
  },
  warningIcon: {
    ...theme.mixins.setColorSvg(theme.palette.warning.main),
  },
}));
