import { Grid } from '@mui/material';

import { upperFirst } from '@ecp/utils/common';

import { GridItem } from '@ecp/components';
import { env } from '@ecp/env';
import { Button, SingleLineDetail } from '@ecp/features/sales/shared/components';
import { getPrimaryInsuredStateCode } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { ProductName } from '@ecp/features/shared/product';
import type { Product } from '@ecp/features/shared/product';
import {
  IconMaterialDesignActionOpenInNew,
  IconUIClose as IconUIExclaimCircleSolid,
  IconUICheck as SuccessIcon,
} from '@ecp/themes/base';

import { DownloadPostBindDocuments } from '../DownloadPostBindDocuments';
import metadata from './metadata';
import { useStyles } from './PostBindPolicyCard.styles';

interface Props {
  effectiveDate: string;
  policyEndDate: string;
  policyNumber: string;
  policyType: ProductName;
  purchased?: boolean;
  selectedProduct: Product;
  poiDocumentFormName: string;
}

export const PostBindPolicyCard: React.FC<Props> = (props) => {
  const {
    effectiveDate,
    poiDocumentFormName,
    policyEndDate,
    policyNumber,
    policyType,
    purchased,
    selectedProduct,
  } = props;
  const { classes, cx } = useStyles();

  const showAgentPostbindEnhancements = env.static.isAgent;
  // !TODO this should be driven by the response from offers (if we have a link - we show download option, otherwise - we do not show)
  /*
    CSUI-676, CSUI-736 The state of New York says that customers for Midvale Auto have to wait to have their card mailed to them.
    So for NY we can't show the POI card download button. This will be a quick fix to meet the deadline.
    Right now the carrier does not give us a download link so if this is showing for NY the user will get an error
    because the download link won't do anything.
    Connect Home & Auto as well as Midvale Home should display the POI
   */
  const stateCode = useSelector(getPrimaryInsuredStateCode);
  let shouldDisplayPoiCard = true;
  if (
    (!metadata.showPoiDocument &&
      stateCode.toLowerCase() === 'ny' &&
      metadata.product.includes(selectedProduct)) ||
    (metadata.showPoiDocument && metadata.product.includes(selectedProduct))
  ) {
    shouldDisplayPoiCard = false;
  }

  return (
    <Grid className={cx(classes.informationCard, !purchased && classes.errorInformationCard)}>
      <Grid className={classes.headerContainer}>
        <GridItem
          className={cx(
            classes.icon,
            purchased ? classes.successIconContainer : classes.errorIconContainer,
          )}
        >
          {purchased ? (
            <SuccessIcon className={classes.successIcon} />
          ) : (
            <IconUIExclaimCircleSolid className={classes.errorIcon} />
          )}
        </GridItem>
        <h3 className={classes.cardHeader}>
          {showAgentPostbindEnhancements
            ? `${upperFirst(policyType)} policy`
            : `Your ${policyType} policy`}
        </h3>
      </Grid>

      <Grid container>
        <GridItem
          xs={12}
          data-testid='policyNumberText'
          topSpacing='xs'
          className={classes.divider}
        >
          <SingleLineDetail
            text='Policy number'
            value={purchased ? policyNumber : 'N/A'}
            variant={metadata.variant}
            classes={{
              leftWrapper: classes.leftWrapper,
            }}
          />
        </GridItem>
        <GridItem
          xs={12}
          data-testid='effectiveDateText'
          topSpacing='xs'
          className={classes.divider}
        >
          <SingleLineDetail
            text='Policy start date'
            value={purchased ? effectiveDate : 'N/A'}
            variant={metadata.variant}
            classes={{
              leftWrapper: classes.leftWrapper,
            }}
          />
        </GridItem>
        <GridItem xs={12} data-testid='endDateText' topSpacing='xs'>
          <SingleLineDetail
            text='Policy end date'
            value={purchased ? policyEndDate : 'N/A'}
            variant={metadata.variant}
            classes={{
              leftWrapper: classes.leftWrapper,
            }}
          />
        </GridItem>
      </Grid>

      {shouldDisplayPoiCard && (
        <DownloadPostBindDocuments
          documentFormName={
            poiDocumentFormName === undefined && metadata.showPoiDocument
              ? 'ProofOfInsurance'
              : poiDocumentFormName
          } // TODO: Get the document name from purchaseStatus once SAPI sends it.
          policyNumber={policyNumber}
          purchased={purchased}
          selectedProduct={selectedProduct}
          trackingName='proof_of_insurance_link'
          analyticsElement='choice.postBindPage.proofOfInsuranceDownloadButton'
          documentType='ProofOfInsurance'
        />
      )}
      {metadata.shouldDisplayBillingReceipt && (
        <DownloadPostBindDocuments
          documentFormName='BillingReceipt'
          policyNumber={policyNumber}
          purchased={purchased}
          selectedProduct={selectedProduct}
          trackingName='billing_receipt_link'
          analyticsElement='choice.postBindPage.billingReceiptDownloadButton'
          documentType='BillingReceipt'
        />
      )}
      {metadata.shouldDisplayRouteToPLPC && (
        <Button
          classes={{
            root: classes.policyCenterButton,
          }}
          variant='iconTextMedium'
          target='_blank'
          data-testid='viewInPolicyCenterButton'
          trackingName='view_in_policy_center_button'
          analyticsElement='postBindPage.ViewInPolicyCenterButton'
          icon={<IconMaterialDesignActionOpenInNew />}
          iconLast
        >
          VIEW IN POLICY CENTER
        </Button>
      )}
    </Grid>
  );
};
