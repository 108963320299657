import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PercentageGroupSelection' })((theme) => ({
  ...theme.mixins.formAll,
  percentageGroupLabel: {
    ...theme.typography.body4,
    paddingBottom: 0,
    marginBottom: 5,
    paddingTop: 40,
  },
}));
