type InitializeFunction = (params: {
  onload?: boolean;
  timeout?: number;
  src?: string;
}) => Promise<Event | void>;

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace AwsWafIntegration {
  function getToken(): string;
  function hasToken(): boolean;
}

/** Keeps track of pending initialization (via setTimer), so that we can cancel it on tear down. */
let pendingInitialization: ReturnType<typeof setTimeout> | null = null;
export const TIMEOUT = 6000;

// Injects AWS WAF integration script
export const initialize: InitializeFunction = (params: {
  onload?: boolean;
  timeout?: number;
  src?: string;
}) => {
  const { onload = true, timeout = TIMEOUT, src } = params;
  if (src && window && !document.getElementById('botcontrol') && !pendingInitialization) {
    return new Promise<Event>((resolve, reject) => {
      const script = document.createElement('script');
      script.id = 'botcontrol';
      script.setAttribute('defer', 'defer');
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);

      const injectScript = (): void => {
        if (!src) return;
        // Add additional timeout
        if (timeout)
          pendingInitialization = setTimeout(() => {
            // Prevent memory leaks
            pendingInitialization = null;
            script.src = src;
          }, timeout);
        else script.src = src;
      };
      if (onload && document.readyState !== 'complete') {
        const handleLoad = (): void => {
          injectScript();
          window.removeEventListener('load', handleLoad);
        };
        // Inject script after the entire page loads (including scripts, CSS, images for the current page)
        window.addEventListener('load', handleLoad);
      } else {
        injectScript();
      }
    });
  }

  return Promise.resolve();
};

export const getToken = async (): Promise<string | undefined> => {
  if (typeof AwsWafIntegration === 'undefined') {
    return undefined;
  }
  const token = await AwsWafIntegration.getToken();

  return token;
};

export const hasToken = (): boolean => {
  if (typeof AwsWafIntegration === 'undefined') {
    return false;
  }

  return AwsWafIntegration.hasToken();
};
