// drop i,l,o
const mapAlpha = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'j',
  'k',
  'm',
  'n',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
] as const;
type MapAlpha = typeof mapAlpha;

const mapAlphaNumeric = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ...mapAlpha] as const;
type MapAlphaNumeric = typeof mapAlphaNumeric;

function rand(seed: number): number {
  const a = 1664525;
  const c = 1013904223;
  const m = 2 ** 32;

  return (seed * a + c) % m;
}

function randRange(seed: number, max: number): [number, number] {
  const m = 2 ** 32;
  const overlap = m % max;
  let result = rand(seed);
  while (result < overlap) {
    result = rand(result);
  }

  return [result, result % max];
}

function getNewSeedAndSymbol<T extends MapAlpha | MapAlphaNumeric>(
  seed: number,
  map: T,
): [number, T[number]] {
  const max = map.length;
  const [newSeed, number] = randRange(seed, max);

  // https://github.com/Microsoft/TypeScript/issues/15480
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return [newSeed, map[number]!];
}

function randId(seed: number, length: number): [number, string] {
  let currentSeed = seed;

  let result = '';
  const [tmpSeed, leading] = randRange(currentSeed, 10);
  currentSeed = tmpSeed;
  result += leading;

  const [tmp2Seed, second] = getNewSeedAndSymbol(currentSeed, mapAlpha);
  currentSeed = tmp2Seed;
  result += second;

  for (let i = 2; i < length; i += 1) {
    let character;
    [currentSeed, character] = getNewSeedAndSymbol(currentSeed, mapAlphaNumeric);
    result += character;
  }

  return [currentSeed, result];
}

export function idFor<T extends string>(seed: number, kind: T): [number, `${T}.${string}`] {
  const [newSeed, id] = randId(seed, 8);

  return [newSeed, `${kind}.${id}`];
}
