import type { CarrierName } from '@ecp/features/shared/product';
import { LogoConnectImageUrl, LogoMidvaleImageUrl } from '@ecp/themes/base';

export const getCarrierLogoImageUrlFromCarrierName = (carrierName: CarrierName): string => {
  switch (carrierName) {
    case 'connect':
      return LogoConnectImageUrl;
    case 'homesite':
    case 'amfam':
    case 'midvale':
      return LogoMidvaleImageUrl;
    default:
      return '';
  }
};
