import { env } from '@ecp/env';

import type { HelpCardProps } from './HelpCard';
import { HelpCard } from './HelpCard';
import metadata from './metadata';

export const HelpCardConditional: React.FC<HelpCardProps> = (props) => {
  const { phoneNumberTrackingValue = 'SidePhoneNumber', chatTrackingValue = 'SideChat' } = props;

  if (env.static.isAgent || metadata.hideHelpCard) return null;

  return (
    <HelpCard
      phoneNumberTrackingValue={phoneNumberTrackingValue}
      chatTrackingValue={chatTrackingValue}
    />
  );
};
