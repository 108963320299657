import { memo, useCallback, useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import dayjs from 'dayjs';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import {
  DatePicker,
  RadioGroupWithOptions,
  TextField,
} from '@ecp/features/sales/shared/components';
import { useField, usePostBindField } from '@ecp/features/sales/shared/store';
import { clearFields } from '@ecp/features/sales/shared/utils/web';

import { useStyles } from './SecondaryInsuredQuestion.styles';

export const SecondaryInsuredQuestion: React.FC = memo(() => {
  const { classes } = useStyles();

  const primaryInsuredRef = useField('primaryInsured.person.ref');
  const primaryInsuredMarried = useField(`${primaryInsuredRef.value as string}.married`);
  const [isSecondaryInsured, setIsSecondaryInsured] = useState(false);

  const secondaryInsuredFirstName = usePostBindField('secondaryInsured.firstname');
  const secondaryInsuredLastName = usePostBindField('secondaryInsured.lastname');
  const secondaryInsuredDob = usePostBindField('secondaryInsured.dob');

  useAddFields({
    secondaryInsuredFirstName,
    secondaryInsuredLastName,
    secondaryInsuredDob,
  });

  useAddConditionValues({
    conditionalFields: [secondaryInsuredFirstName, secondaryInsuredLastName, secondaryInsuredDob],
    isExcluded: () => !isSecondaryInsured,
    isRequiredOverride: () => true,
  });

  // After mounting set the switch to `yes` if primary insured is married
  useEffect(() => {
    if (primaryInsuredMarried.value === 'MARITAL_STATUS.MARRIED') setIsSecondaryInsured(true);
  }, [primaryInsuredMarried]);

  // On `yes/no` switch clear all fields if `no` has been chosen
  useEffect(() => {
    if (!isSecondaryInsured) {
      clearFields(secondaryInsuredFirstName, secondaryInsuredLastName, secondaryInsuredDob);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSecondaryInsured]);

  const handleIsSecondaryInsuredClick = useCallback((value: boolean) => {
    setIsSecondaryInsured(value);
  }, []);

  return (
    <Grid container item xs={12}>
      <p className={classes.text}>
        Do you want to add a secondary named insured? (If married, please add your spouse)
      </p>
      <Grid item xs={12}>
        <RadioGroupWithOptions
          value={isSecondaryInsured}
          actionOnComplete={handleIsSecondaryInsuredClick}
          id='IsSecondaryInsured'
          variant='yesNoButton'
          trackingName='SNIButton'
        />
      </Grid>
      {isSecondaryInsured && (
        <>
          <p className={classes.secondaryInsuredText}>
            What is the name and date of birth of secondary insured?
          </p>
          <Grid item container spacing={1}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                {...secondaryInsuredFirstName.props}
                id='SecondaryInsuredFirstName'
                label='First name'
                ariaLabel='Secondary insured first name'
                trackingName='SecondaryInsuredFirstName'
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                {...secondaryInsuredLastName.props}
                id='SecondaryInsuredLastName'
                label='Last name'
                ariaLabel='Secondary insured last name'
                trackingName='SecondaryInsuredLastName'
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
              />
            </Grid>
          </Grid>
          <Grid item xs={6} className={classes.dob}>
            <DatePicker
              {...secondaryInsuredDob.props}
              hidePicker
              id='SecondaryInsuredDob'
              label='Date of birth'
              trackingName='SecondaryInsuredDob'
              trackingLabel={
                secondaryInsuredDob.props.value
                  ? dayjs(secondaryInsuredDob.props.value).format('YYYY')
                  : ' '
              }
            />
          </Grid>
        </>
      )}
    </Grid>
  );
});
