import { Divider, Grid } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';
import { formatDate } from '@ecp/utils/date';

import { StateFeesSection } from '@ecp/features/sales/shared/components';
import type { PaymentPlan } from '@ecp/features/sales/shared/constants';
import { getOfferDetailsForProduct } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AutoProduct, Product, PropertyProduct } from '@ecp/features/shared/product';
import { getProductDisplayNameFromProduct } from '@ecp/features/shared/product';
import { useAboveMobile, useIsMobile, useIsTablet } from '@ecp/themes/base';

import type { PaymentOption } from '../../types';
import { getCombinedTotal, getNumOfInstallments } from '../../util';
import { determineInstallmentFeeTotal } from '../../util/installmentFeeUtil';
import { EstimatedTooltip } from '../EstimatedTooltip';
import { Recalc } from '../Recalc';
import { useStyles } from './MonthlyPaymentsCheckoutCard.styles';

interface Props {
  autoOfferProduct: AutoProduct;
  propertyOfferProduct: PropertyProduct;
  autoPaymentOption: PaymentOption;
  propertyPaymentOption: PaymentOption;
  autoPSD: string;
  homePSD: string;
  isMVRActive?: boolean;
  premiumPlan: PaymentPlan;
  recalculate?: boolean;
  showProcess: boolean;
  showStateFees?: boolean;
  showToggle?: boolean;
}

export const MonthlyPaymentsBundleCheckoutView: React.FC<Props> = (props) => {
  const {
    autoOfferProduct,
    propertyOfferProduct,
    autoPaymentOption,
    propertyPaymentOption,
    autoPSD,
    homePSD,
    isMVRActive,
    premiumPlan,
    recalculate,
    showProcess,
    showStateFees,
  } = props;
  const { classes, cx } = useStyles();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isAboveMobile = useAboveMobile();
  const autoOfferDetails = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, autoOfferProduct),
  );
  const propertyOfferDetails = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, propertyOfferProduct),
  );

  const propertyProductName = getProductDisplayNameFromProduct(propertyOfferProduct);

  if (!autoOfferDetails && !propertyOfferDetails) return null;

  const getMonthlyPaymentsText = (
    paymentPlan: string | undefined,
    downPayment: number | undefined,
    product?: Product,
  ): string => {
    const numberOfInstallments = getNumOfInstallments(paymentPlan, Number(downPayment), product);

    return `${numberOfInstallments} mo payment${numberOfInstallments !== 1 ? 's' : ''} of`;
  };

  const renderMonthlyAutoView = autoOfferDetails && (
    <>
      <Grid
        container
        item
        xs={6}
        md={5}
        lg={12}
        className={classes.leftColumn}
        alignContent={isMobile ? 'start' : 'center'}
      >
        <Grid item xs={12}>
          <p className={classes.policyDurationBundleHeaderColumn}>
            <span className={classes.policyDurationHeader}>Auto</span>{' '}
            {`${autoOfferDetails.policyDuration}-mo policy`}
            {!isMobile && !isMVRActive && <EstimatedTooltip inline />}
          </p>
        </Grid>
        {isAboveMobile ? null : (
          <Grid item xs={10} sm={10} md={12} className={classes.premiumsDividerContainer}>
            <Divider aria-hidden='true' className={classes.premiumsDivider} />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          display='flex'
          alignItems='center'
          justifyContent={isMobile ? 'center' : 'left'}
        >
          <p className={classes.policyBundleCheckoutHeaders}>
            {getMonthlyPaymentsText(
              autoPaymentOption.paymentPlan,
              Number(autoPaymentOption.downPayment),
              autoOfferProduct,
            )}
          </p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          className={cx(classes.premiumFeesBundleColumn, classes.bundleMonthlyPayments)}
        >
          <Recalc
            amount={parseDollar(autoPaymentOption.monthlyAmount)}
            recalculate={recalculate}
            showProcess={showProcess}
          />
          {isMobile && !isMVRActive && <EstimatedTooltip />}
        </Grid>
        {isMobile ? null : (
          <>
            <Grid container justifyContent='center'>
              {Number(autoPaymentOption.downPayment) > 0 && (
                <Grid container item xs={9} md={12}>
                  <Grid item xs={7} md={6}>
                    <p className={classes.policyBundleCheckoutHeaders}>Due today</p>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    md={6}
                    className={cx(
                      classes.premiumFeesColumn,
                      classes.premiumFeesInfo,
                      classes.dueTodayText,
                    )}
                  >
                    <Recalc
                      amount={parseDollar(autoPaymentOption.downPayment)}
                      recalculate={recalculate}
                      showProcess={showProcess}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item container xs={12}>
                <Grid item xs={6}>
                  <p className={classes.policyCheckoutHeaders}>Installment Fees</p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={cx(classes.premiumFeesColumn, classes.premiumFeesInfo)}
                >
                  <Recalc
                    amount={parseDollar(
                      determineInstallmentFeeTotal(autoOfferProduct, autoPaymentOption),
                    )}
                    recalculate={recalculate}
                    showProcess={showProcess}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={6}>
                  <p className={classes.policyCheckoutHeaders}>Policy total</p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={cx(classes.premiumFeesColumn, classes.premiumFeesInfo)}
                >
                  <Recalc
                    amount={parseDollar(getCombinedTotal(autoOfferProduct, autoPaymentOption))}
                    recalculate={recalculate}
                    showProcess={showProcess}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={6}>
                <p className={classes.policyBundleCheckoutHeaders}>Start date</p>
              </Grid>
              <Grid
                item
                xs={6}
                className={cx(
                  classes.premiumFeesColumn,
                  classes.premiumFeesInfo,
                  classes.policyStartDate,
                )}
              >
                <span className={cx(classes.customizeButton, classes.premiumFeesInfo)}>
                  {`${formatDate(autoPSD)}`}
                </span>
              </Grid>
            </Grid>
            {isTablet ? null : (
              <StateFeesSection
                premiumPlan={premiumPlan}
                offerDetails={autoOfferDetails}
                showStateFees={showStateFees}
              />
            )}
          </>
        )}
      </Grid>
      {isTablet ? null : (
        <Grid item xs={10} sm={10} md={12} className={classes.premiumsDividerContainer}>
          <Divider aria-hidden='true' className={classes.premiumsDivider} />
        </Grid>
      )}
    </>
  );

  const renderMonthlyPropertyView = propertyOfferDetails && (
    <Grid
      container
      item
      xs={6}
      md={5}
      lg={12}
      className={classes.rightColumn}
      alignContent={isMobile ? 'start' : 'center'}
    >
      <Grid item xs={12}>
        <p className={classes.policyDurationBundleHeaderColumn}>
          <span className={classes.policyDurationHeader}>{propertyProductName}</span>{' '}
          {`${propertyOfferDetails.policyDuration}-mo policy`}
        </p>
      </Grid>
      {isAboveMobile ? null : (
        <Grid item xs={10} sm={10} md={12} className={classes.premiumsDividerContainer}>
          <Divider aria-hidden='true' className={classes.premiumsDivider} />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        display='flex'
        alignItems='center'
        justifyContent={isMobile ? 'center' : 'left'}
      >
        <p className={classes.policyBundleCheckoutHeaders}>
          {getMonthlyPaymentsText(
            propertyPaymentOption.paymentPlan,
            Number(propertyPaymentOption.downPayment),
            propertyOfferProduct,
          )}
        </p>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        className={cx(classes.premiumFeesBundleColumn, classes.bundleMonthlyPayments)}
      >
        <Recalc
          amount={parseDollar(propertyPaymentOption.monthlyAmount)}
          recalculate={recalculate}
          showProcess={showProcess}
        />
      </Grid>
      {isMobile ? null : (
        <>
          <Grid container justifyContent='center'>
            {Number(propertyPaymentOption.downPayment) > 0 && (
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <p className={classes.policyBundleCheckoutHeaders}>Due today</p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={cx(
                    classes.premiumFeesColumn,
                    classes.premiumFeesInfo,
                    classes.dueTodayText,
                  )}
                >
                  <Recalc
                    amount={parseDollar(propertyPaymentOption.downPayment)}
                    recalculate={recalculate}
                    showProcess={showProcess}
                  />
                </Grid>
              </Grid>
            )}
            <Grid item container xs={12}>
              <Grid item xs={6}>
                <p className={classes.policyCheckoutHeaders}>Installment Fees</p>
              </Grid>
              <Grid item xs={6} className={cx(classes.premiumFeesColumn, classes.premiumFeesInfo)}>
                <Recalc
                  amount={parseDollar(
                    determineInstallmentFeeTotal(propertyOfferProduct, propertyPaymentOption),
                  )}
                  recalculate={recalculate}
                  showProcess={showProcess}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={6}>
                <p className={classes.policyCheckoutHeaders}>Policy total</p>
              </Grid>
              <Grid item xs={6} className={cx(classes.premiumFeesColumn, classes.premiumFeesInfo)}>
                <Recalc
                  amount={parseDollar(
                    getCombinedTotal(propertyOfferProduct, propertyPaymentOption),
                  )}
                  recalculate={recalculate}
                  showProcess={showProcess}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <p className={classes.policyBundleCheckoutHeaders}>Start date</p>
            </Grid>
            <Grid
              item
              xs={6}
              className={cx(
                classes.premiumFeesBundleColumn,
                classes.premiumFeesInfo,
                classes.policyStartDate,
              )}
            >
              <span className={cx(classes.customizeButton, classes.premiumFeesInfo)}>
                {formatDate(homePSD)}
              </span>
            </Grid>
          </Grid>
          {isTablet ? null : (
            <StateFeesSection
              premiumPlan={premiumPlan}
              offerDetails={propertyOfferDetails}
              showStateFees={showStateFees}
            />
          )}
        </>
      )}
    </Grid>
  );

  const renderFullAutoView = autoOfferDetails && (
    <>
      <Grid
        container
        item
        xs={6}
        md={5}
        lg={12}
        className={classes.leftColumn}
        alignContent={isMobile ? 'start' : 'center'}
      >
        <Grid item xs={12}>
          <p className={classes.policyDurationBundleHeaderColumn}>
            <span className={classes.policyDurationHeader}>Auto</span>{' '}
            {`${autoOfferDetails.policyDuration}-mo policy`}
            {!isMobile && !isMVRActive && <EstimatedTooltip inline />}
          </p>
        </Grid>
        {isAboveMobile ? null : (
          <Grid item xs={10} sm={10} md={12} className={classes.premiumsDividerContainer}>
            <Divider aria-hidden='true' className={classes.premiumsDivider} />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          display='flex'
          alignItems='center'
          justifyContent={isMobile ? 'center' : 'left'}
        >
          <p className={classes.policyBundleCheckoutHeaders}>One-time payment of</p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          className={cx(classes.premiumFeesBundleColumn, classes.bundleMonthlyPayments)}
        >
          <Recalc
            amount={parseDollar(autoPaymentOption.totalPremium)}
            recalculate={recalculate}
            showProcess={showProcess}
          />
          {isMobile && !isMVRActive && <EstimatedTooltip />}
        </Grid>
        {isMobile ? null : (
          <>
            <Grid item container xs={12}>
              <Grid item xs={4} sm={5}>
                <p className={classes.policyBundleCheckoutHeaders}>Start date</p>
              </Grid>
              <Grid
                item
                xs={8}
                sm={7}
                className={cx(
                  classes.premiumFeesBundleColumn,
                  classes.premiumFeesInfo,
                  classes.policyStartDate,
                )}
              >
                <span className={cx(classes.customizeButton, classes.premiumFeesInfo)}>
                  {formatDate(autoPSD)}
                </span>
              </Grid>
            </Grid>
            {isTablet ? null : (
              <StateFeesSection
                premiumPlan={premiumPlan}
                offerDetails={autoOfferDetails}
                showStateFees={showStateFees}
              />
            )}
          </>
        )}
      </Grid>
      {isTablet ? null : (
        <Grid item xs={10} sm={10} md={12} className={classes.premiumsDividerContainer}>
          <Divider aria-hidden='true' className={classes.premiumsDivider} />
        </Grid>
      )}
    </>
  );

  const renderFullView = propertyOfferDetails && (
    <Grid
      container
      item
      xs={6}
      md={5}
      lg={12}
      className={classes.rightColumn}
      alignContent={isMobile ? 'start' : 'center'}
    >
      <Grid item xs={12}>
        <p className={classes.policyDurationBundleHeaderColumn}>
          <span className={classes.policyDurationHeader}>{propertyProductName}</span>{' '}
          {`${propertyOfferDetails.policyDuration}-mo policy`}
        </p>
      </Grid>
      {isAboveMobile ? null : (
        <Grid item xs={10} sm={10} md={12} className={classes.premiumsDividerContainer}>
          <Divider aria-hidden='true' className={classes.premiumsDivider} />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        display='flex'
        alignItems='center'
        justifyContent={isMobile ? 'center' : 'left'}
      >
        <p className={classes.policyBundleCheckoutHeaders}>One-time payment of</p>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        className={cx(classes.premiumFeesBundleColumn, classes.bundleMonthlyPayments)}
      >
        <Recalc
          amount={parseDollar(propertyPaymentOption.totalPremium)}
          recalculate={recalculate}
          showProcess={showProcess}
        />
      </Grid>
      {isMobile ? null : (
        <>
          <Grid item container xs={12}>
            <Grid item xs={4} sm={5}>
              <p className={classes.policyBundleCheckoutHeaders}>Start date</p>
            </Grid>
            <Grid
              item
              xs={8}
              sm={7}
              className={cx(
                classes.premiumFeesBundleColumn,
                classes.premiumFeesInfo,
                classes.policyStartDate,
              )}
            >
              <span className={cx(classes.customizeButton, classes.premiumFeesInfo)}>
                {formatDate(homePSD)}
              </span>
            </Grid>
          </Grid>
          {isTablet ? null : (
            <StateFeesSection
              premiumPlan={premiumPlan}
              offerDetails={propertyOfferDetails}
              showStateFees={showStateFees}
            />
          )}
        </>
      )}
    </Grid>
  );

  return (
    <Grid container className={classes.bundlePremiumContainer} justifyContent='center'>
      {autoPaymentOption.paymentPlan === 'Full' ? renderFullAutoView : renderMonthlyAutoView}
      {propertyPaymentOption.paymentPlan === 'Full' ? renderFullView : renderMonthlyPropertyView}
    </Grid>
  );
};
