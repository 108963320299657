import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AppHeader' })((theme) => ({
  root: {
    ...theme.mixins.headerRoot,
    color: theme.palette.text.primary,
  },
  spacer: {
    height: 105,
    [theme.breakpoints.down('lg')]: {
      height: 70,
    },
  },
  contactInfoOrAgentMenu: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
  },
  phone: {
    marginRight: 20,
  },
  chat: {
    marginRight: 20,
    display: 'flex',
    flex: '1',
  },
  headerContactHours: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
  },
  outageBanner: {
    backgroundColor: theme.palette.warning.light,
    textAlign: 'center',
  },
}));
