import { useState } from 'react';

import { useLocation } from '@ecp/utils/routing';

import { updateInitializing } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';

/**
 * Session should be completed right away when new tab with a static page gets opened.
 * Which is the case in consumer scenario but not in agent, as agent static pages are dynamic per partner.
 */
export const useEnsureSessionCompleted = (
  staticPaths?: string[],
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const location = useLocation();
  const isStaticRoute = Boolean(staticPaths?.includes(location.pathname));
  const dispatch = useDispatch();
  if (isStaticRoute) {
    dispatch(updateInitializing(false));
  }

  return useState(isStaticRoute);
};
