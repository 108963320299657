import { useCallback, useState } from 'react';

import { FormControlLabel } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { isTruthy } from '@ecp/utils/common';

import { GridItem, Radio, RadioGroup } from '@ecp/components';
import { Button, Form } from '@ecp/features/sales/shared/components';
import { NavStatus } from '@ecp/features/sales/shared/constants';
import { usePrefillFlowDetermined } from '@ecp/features/sales/shared/routing';
import {
  getPniPersonInfo,
  makeSurePrefillFlowInStore,
  updateAnswers,
  updatePageStatus,
  useGetAddressFields,
} from '@ecp/features/sales/shared/store';
import type { ThunkAction } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue } from '@ecp/features/sales/shared/types';
import type { Field } from '@ecp/types';

import { useStyles } from './PersonAddressSuggestion.styles';

export interface PersonAddressSuggestionProps {
  onNext: () => Promise<void>;
  onEditClick: () => void;
  suggestedAddress: {
    suggestedStreet: string;
    suggestedSecondary: string;
    suggestedCity: string;
    suggestedState: string;
    suggestedZipcode: string;
  };
  enteredAddress: Record<string, unknown>;
}

interface SubmitParams {
  onNext: () => Promise<void>;
  prefillFlowDetermined: boolean;
  primaryInsuredAddressLockKey: string;
  setIsSubmitting: (f: boolean) => void;
  suggestedAddressValue: string;
  line1: Field;
  line2: Field;
  suggestedStreet: string;
  suggestedSecondary: string;
  pniInfoSubmitted: boolean;
}

const suggestedAddressValueOptions = {
  SUGGESTED_ADDRESS: 'SUGGESTED_ADDRESS',
  ORIGINAL_ADDRESS: 'ORIGINAL_ADDRESS',
};

const doSubmit =
  ({
    onNext,
    prefillFlowDetermined,
    primaryInsuredAddressLockKey,
    setIsSubmitting,
    suggestedAddressValue,
    line1,
    line2,
    suggestedStreet,
    suggestedSecondary,
    pniInfoSubmitted,
  }: SubmitParams): ThunkAction<Promise<void>> =>
  async (dispatch) => {
    setIsSubmitting(true);
    if (suggestedAddressValue === suggestedAddressValueOptions.SUGGESTED_ADDRESS) {
      // no need to patch answers if user selected original address
      line1.props.actionOnComplete(suggestedStreet as AnswerValue);
      line2.props.actionOnComplete(suggestedSecondary as AnswerValue);
    }
    // lock the address fields after confirmation
    await dispatch(
      updateAnswers({
        answers: {
          [primaryInsuredAddressLockKey]: true,
        },
      }),
    );
    if (!prefillFlowDetermined) {
      // show spinner on continue button
      setIsSubmitting(true);
    }
    // make sure the person info is sumbitted before making prefill call
    if (pniInfoSubmitted) await dispatch(makeSurePrefillFlowInStore());
    await onNext();
  };

export const PersonAddressSuggestion: React.FC<PersonAddressSuggestionProps> = (props) => {
  const { suggestedAddress, enteredAddress, onEditClick, onNext } = props;
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();

  const { firstName, lastName } = useSelector(getPniPersonInfo);
  const pniInfoSubmitted: boolean = !!(firstName && lastName);
  const prefillFlowDetermined = usePrefillFlowDetermined();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [suggestedAddressValue, setSuggestedAddressValue] = useState(
    suggestedAddressValueOptions.SUGGESTED_ADDRESS,
  );
  const selectAddressOption = useCallback(
    (newValue: AnswerValue) => setSuggestedAddressValue(newValue?.toString() ?? ''),
    [],
  );

  const fields = useGetAddressFields();
  const {
    address: { line1, line2 },
    primaryInsuredAddressLock,
  } = fields;

  const { suggestedStreet, suggestedSecondary, suggestedCity, suggestedState, suggestedZipcode } =
    suggestedAddress;
  const { enteredStreet, enteredSecondary, enteredCity, enteredState, enteredZipcode } =
    enteredAddress;

  const handleSubmit = useCallback(async () => {
    await dispatch(
      doSubmit({
        onNext,
        prefillFlowDetermined,
        primaryInsuredAddressLockKey: primaryInsuredAddressLock.key,
        setIsSubmitting,
        suggestedAddressValue,
        line1,
        line2,
        suggestedStreet,
        suggestedSecondary,
        pniInfoSubmitted,
      }),
    );
    dispatch(updatePageStatus(NavStatus.VALID));
  }, [
    dispatch,
    primaryInsuredAddressLock.key,
    onNext,
    prefillFlowDetermined,
    suggestedAddressValue,
    line1,
    line2,
    suggestedStreet,
    suggestedSecondary,
    pniInfoSubmitted,
  ]);

  return (
    <div className={classes.root} id='SuggestionForm'>
      <Form showBackdrop={isSubmitting}>
        <RadioGroup
          variant='classic'
          value={suggestedAddressValue}
          name='suggestedAddressGroup'
          actionOnComplete={selectAddressOption}
          trackingName='suggested_address'
        >
          <FormControlLabel
            classes={{
              root: cx(
                classes.card,
                suggestedAddressValue === suggestedAddressValueOptions.SUGGESTED_ADDRESS &&
                  classes.cardSelected,
              ),
              label: classes.label,
            }}
            control={
              <Radio
                classes={{
                  root: classes.radio,
                }}
                value={suggestedAddressValueOptions.SUGGESTED_ADDRESS}
              />
            }
            label={
              <>
                <p className={classes.useAddress}>Use suggested address</p>
                <p className={classes.labelAddress}>
                  <mark className={classes.labelAddressHighlight}>
                    {[suggestedStreet, suggestedSecondary].filter(isTruthy).join(', ')},
                  </mark>
                  <p>{` ${[suggestedCity, suggestedState].join(', ')} ${suggestedZipcode}`}</p>
                </p>
              </>
            }
          />
          <FormControlLabel
            classes={{
              root: cx(
                classes.card,
                suggestedAddressValue === suggestedAddressValueOptions.ORIGINAL_ADDRESS &&
                  classes.cardSelected,
              ),
              label: classes.label,
            }}
            control={
              <Radio
                classes={{
                  root: classes.radio,
                }}
                value={suggestedAddressValueOptions.ORIGINAL_ADDRESS}
              />
            }
            label={
              <>
                <strong className={classes.useAddress}>
                  Use original address{' '}
                  <Button
                    className={classes.editButton}
                    variant='iconTextSmall'
                    onClick={onEditClick}
                    disabled={
                      suggestedAddressValue !== suggestedAddressValueOptions.ORIGINAL_ADDRESS
                    }
                  >
                    Edit address
                  </Button>
                </strong>
                <p className={classes.labelAddress}>
                  {`${[enteredStreet, enteredSecondary, enteredCity, enteredState]
                    .filter((x) => x)
                    .join(', ')} ${enteredZipcode}`}
                </p>
              </>
            }
          />
        </RadioGroup>
        <GridItem topSpacing='sm' xs={12}>
          <Button
            id='submitAddressSuggestionButton'
            variant='primary'
            onClick={handleSubmit}
            isProcessing={isSubmitting}
            disabled={isSubmitting}
            className={classes.next}
            trackingName={GoogleAnalyticsLabels.CONTINUE}
            trackingLabel='person_address_continue'
            analyticsElement='choice.addressPage.suggestionContinueButton'
            data-testid='PersonAddressSuggestionContinueButton'
          >
            Continue
          </Button>
        </GridItem>
      </Form>
    </div>
  );
};
