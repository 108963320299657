import { memo } from 'react';

import { Box, Divider } from '@mui/material';

import { PhoneLink, ProductQuoteNumber } from '@ecp/features/sales/shared/components';
import { partner } from '@ecp/partners';

import { useStyles } from '../QuotesError.styles';
import type { DeclinationData } from '../types';
import { dnqProviderDisplayName } from './metadata';

export const DNQ: React.FC<Partial<DeclinationData>> = memo(() => {
  const { classes } = useStyles();

  return (
    <Box>
      <h1>Whoops! No quotes available</h1>
      <ProductQuoteNumber />
      <Divider aria-hidden='true' className={classes.divider} />
      <p className={classes.guidance}>
        Unfortunately, {dnqProviderDisplayName} was unable to provide you with an online quote at
        the moment.
      </p>
      <p className={classes.guidance}>
        We’ve arranged for you to speak with a licensed agent who can help. Please call
        <PhoneLink
          withUnderlinedLinkStyle
          withLinkStyle
          number={partner.shared.dnqPhoneNumber}
        />{' '}
        at your convenience.
      </p>
    </Box>
  );
});
