import { Dialog } from '@ecp/features/sales/shared/components';

import { useStyles } from './PaymentInfoDisclosureDialog.styles';

interface Props {
  open: boolean;
  onClose(): void;
  trackingName?: string;
  trackingLabel?: string;
}

export const PaymentInfoDisclosureDialog: React.FC<Props> = (props) => {
  const { open, onClose, trackingName, trackingLabel } = props;
  const { classes } = useStyles();

  return (
    <Dialog
      actionButtonLabel='Close'
      titleText='Why these policy totals are different'
      open={open}
      onClose={onClose}
      trackingNameButton={trackingName}
      trackingLabelButton={trackingLabel}
      trackingLabelCloseIcon='X'
      trackingNameCloseIcon='policy_total_difference_X'
      buttonPlacement='right'
    >
      <h3 className={classes.subText}>Paying in full vs. paying in installments</h3>
      <p>
        These policy totals are different because the selection of an installment plan may impact
        the premium charged for your policy. Customers have the option of paying their annual policy
        premium in full or in installment payments throughout the policy term. Paying in full allows
        you to avoid paying installment fees and may lower your total amount paid.
      </p>
    </Dialog>
  );
};
