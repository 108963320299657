import { useCallback, useEffect, useState } from 'react';

import { FormLabel, Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import {
  isMasked,
  maskPaymentAccountNumber,
  resetMaskedPaymentAccountNumber,
} from '@ecp/utils/common';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { restrictSpaceChars } from '@ecp/utils/web';

import { GridItem } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { Select, TextField } from '@ecp/features/sales/shared/components';
import { PurchaseErrorReason } from '@ecp/features/sales/shared/constants';
import { getPurchaseError, useField } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product, ProductName } from '@ecp/features/shared/product';
import { useIsMobile } from '@ecp/themes/base';

import {
  ensureEFTPayToken,
  setAccountNumber,
  setAccountType,
  setAccountUse,
  setRoutingNumber,
} from '../../../state/purchase';
import { useStyles } from './BankPayment.styles';
import type { PaymentFieldOption } from './PaymentTerm';
import { PaymentTerm } from './PaymentTerm';

interface Props {
  reuseAutoPayment: boolean;
  paymentOptions: PaymentFieldOption[];
  product: Product;
  coverageType: ProductName;
  shouldSyncPropertyToAuto: boolean;
  syncPropertyToAuto?: (newType: string) => void;
  displayPaymentImage?: boolean;
  hasToken?: boolean;
}

export const BankPayment: React.FC<Props> = (props) => {
  const { classes, cx } = useStyles();
  const isMobile = useIsMobile();
  const {
    reuseAutoPayment,
    product,
    paymentOptions,
    coverageType,
    shouldSyncPropertyToAuto,
    syncPropertyToAuto,
    hasToken,
    ...otherProps
  } = props;
  const shouldGenerateTokenForEFTPay = flagValues[FeatureFlags.EFT_PAY_THROUGH_PAYMENT_GATEWAY];
  const dispatch = useDispatch();

  const routingNumber = useField(`static.checkout.${coverageType}.routingNumber`);
  const accountNumber = useField(`static.checkout.${coverageType}.accountNumber`);
  const accountUse = useField(`static.checkout.${coverageType}.accountUse`);
  const accountType = useField(`static.checkout.${coverageType}.accountType`);

  const purchaseError = useSelector(getPurchaseError);

  useAddFields({
    [routingNumber.key]: routingNumber,
    [accountNumber.key]: accountNumber,
    [accountUse.key]: accountUse,
    [accountType.key]: accountType,
  });

  const accountNumberValue = accountNumber.props.value;
  const [accNumDisplayValue, setAccNumDisplayValue] = useState<string | undefined>(
    accountNumberValue ? maskPaymentAccountNumber(accountNumberValue) : undefined,
  );

  // This is only used for amfam adavnce and can be extended to other partners
  useEffect(() => {
    if (!shouldGenerateTokenForEFTPay) return;
    if (
      routingNumber.props.error ||
      accountNumber.props.error ||
      accountUse.props.error ||
      accountType.props.error
    )
      return;
    if (
      !(
        routingNumber.props.value &&
        accountNumber.props.value &&
        accountUse.props.value &&
        accountType.props.value
      )
    )
      return;
    dispatch(
      ensureEFTPayToken({
        product,
        accountType: accountType.props.value,
        accountUse: accountUse.props.value,
        accountNumber: accountNumber.props.value,
        routingNumber: routingNumber.props.value,
        isReccuringPayment: false,
      }),
    );
  }, [
    accountNumber.props.error,
    routingNumber.props.error,
    accountNumber.props.value,
    routingNumber.props.value,
    dispatch,
    product,
    accountUse.props.error,
    accountUse.props.value,
    accountType.props.error,
    accountType.props.value,
    shouldGenerateTokenForEFTPay,
  ]);

  const onAccountUseChange = useCallback(
    (value: string) => {
      dispatch(setAccountUse({ product, accountUse: value }));

      accountUse.validateUpdateAndPatch(value);
    },
    [dispatch, product, accountUse],
  );

  const onAccountTypeChange = useCallback(
    (value: string) => {
      dispatch(setAccountType({ product, accountType: value }));

      accountType.validateUpdateAndPatch(value);
    },
    [dispatch, product, accountType],
  );

  const onRoutingNumberChange = useCallback(
    (value: string) => {
      const valueTrimmed = value.trim();

      dispatch(setRoutingNumber({ product, routingNumber: valueTrimmed }));

      routingNumber.validateUpdateAndPatch(valueTrimmed);
    },
    [dispatch, routingNumber, product],
  );

  const onAccountNumberChange = useCallback(
    (value: string) => {
      let valueTrimmed = value.trim();

      if (isMasked(valueTrimmed)) {
        if (accNumDisplayValue && valueTrimmed.length === accNumDisplayValue?.length) return; // user clicks into field and leaves
        valueTrimmed = resetMaskedPaymentAccountNumber(valueTrimmed, accNumDisplayValue);
      }

      setAccNumDisplayValue(undefined);
      dispatch(setAccountNumber({ product, accountNumber: valueTrimmed }));

      accountNumber.validateUpdateAndPatch(valueTrimmed);
    },
    [accountNumber, dispatch, product, accNumDisplayValue],
  );

  const onRoutingNumberBlur = useCallback((): void => {
    if (shouldSyncPropertyToAuto && syncPropertyToAuto) {
      syncPropertyToAuto('EFT');
    }
  }, [shouldSyncPropertyToAuto, syncPropertyToAuto]);

  const onAccountNumberBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>): void => {
      if (shouldSyncPropertyToAuto && syncPropertyToAuto) {
        syncPropertyToAuto('EFT');
      }
      setAccNumDisplayValue(maskPaymentAccountNumber(event.target.value.trim()));
    },
    [shouldSyncPropertyToAuto, syncPropertyToAuto],
  );

  const onAccountNumberFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>): void => {
      if (isMasked(event.target.value.trim())) setAccNumDisplayValue(accountNumberValue);
    },
    [accountNumberValue],
  );

  return (
    <Grid
      container
      direction='column'
      alignItems='flex-start'
      className={classes.bankPaymentContainer}
    >
      {!reuseAutoPayment && (
        <Grid item xs={12} ignore='true' className={classes.bankPaymentFieldsContainer}>
          {(purchaseError === PurchaseErrorReason.INVALID_ROUTING_NUMBER_ERROR ||
            purchaseError === PurchaseErrorReason.INVALID_BANK_ACCOUNT_NUMBER_ERROR ||
            (shouldGenerateTokenForEFTPay && !hasToken)) && (
            <FormLabel
              component='legend'
              focused={false}
              className={classes.invalidBankPaymentError}
            >
              Please re-enter account information
            </FormLabel>
          )}
          <Grid container>
            {shouldGenerateTokenForEFTPay && (
              <>
                <Grid item xs={12} md={6} className={classes.columnLeft}>
                  <Select
                    {...accountUse.props}
                    label='Account Use'
                    data-testid='accountUse'
                    id={`accountUse-${product}`}
                    value={accountUse.value}
                    trackingName='eft_account_Use'
                    trackingLabel={GoogleAnalyticsLabels.REDACTED}
                    actionOnChange={onAccountUseChange}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={cx(classes.columnRight, classes.accountNumber)}
                >
                  <Select
                    {...accountType.props}
                    label='Account Type'
                    data-testid='accountType'
                    id={`accountType-${product}`}
                    value={accountType.value}
                    trackingName='eft_account_Type'
                    trackingLabel={GoogleAnalyticsLabels.REDACTED}
                    actionOnChange={onAccountTypeChange}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={6} className={classes.columnLeft}>
              <TextField
                {...routingNumber.props}
                inputMode='numeric'
                pattern='[0-9]*'
                label='Routing number'
                ariaLabel='Routing number'
                data-testid='routingNumber'
                id={`RoutingNumber-${product}`}
                value={routingNumber.value}
                trackingName='eft_routing_number'
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
                onBlur={onRoutingNumberBlur}
                actionOnChange={onRoutingNumberChange}
                maxLength={9}
                onKeyDown={restrictSpaceChars}
              />
            </Grid>
            <Grid item xs={12} md={6} className={cx(classes.columnRight, classes.accountNumber)}>
              <TextField
                {...accountNumber.props}
                inputMode='numeric'
                pattern='[0-9]*'
                label='Account number'
                ariaLabel='Account number'
                id={`AccountNumber-${product}`}
                data-testid='accNumber'
                value={accNumDisplayValue ?? accountNumber.value}
                trackingName='eft_account_number'
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
                onBlur={onAccountNumberBlur}
                actionOnChange={onAccountNumberChange}
                onFocus={onAccountNumberFocus}
                maxLength={17}
                onKeyDown={restrictSpaceChars}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid container direction='column' alignItems='stretch'>
        <GridItem topSpacing={isMobile ? 'lg' : 'sm'}>
          <PaymentTerm
            product={product}
            paymentOptions={paymentOptions}
            coverageType={coverageType}
            {...otherProps}
          />
        </GridItem>
      </Grid>
    </Grid>
  );
};
