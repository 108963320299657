import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DevDiagnostic' })((theme) => ({
  root: {
    padding: '15px 10px',
    backgroundColor: theme.palette.error.light,
    fontFamily: 'monospace',
    whiteSpace: 'pre',
    '& p': {
      color: theme.palette.error.main,
    },
  },
  wrapword: {
    wordWrap: 'break-word',
    wordBreak: 'break-all',
    whiteSpace: 'normal',
  },
}));
