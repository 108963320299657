import { useCallback, useState } from 'react';

import { Collapse } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { emptyObject } from '@ecp/utils/common';

import { IconUIExpandLess, IconUIExpandMore } from '@ecp/themes/base';

import { Button } from '../Button';
import { useStyles } from './Topbar.styles';

interface Props {
  classes?: Partial<Record<'root' | 'titlePanel' | 'collapseContent', string>>;
  title: string;
  drawer?: React.ReactElement;
  trackingName?: string;
  infoBanner?: React.ReactElement;
}

export const Topbar: React.FC<Props> = (props) => {
  const {
    classes = emptyObject as NonNullable<Props['classes']>,
    title,
    drawer,
    trackingName,
    infoBanner,
  } = props;
  const { classes: ownClasses, cx } = useStyles();
  const [expanded, setExpanded] = useState(false);

  const Icon = expanded ? IconUIExpandLess : IconUIExpandMore;

  const toggleExpand: React.MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    trackClick({ action: trackingName, label: expanded ? 'Close' : 'Open' });
    setExpanded(!expanded);
  }, [expanded, trackingName]);

  return (
    <div className={cx(ownClasses.root, classes.root)}>
      {infoBanner}
      <div className={cx(ownClasses.titlePanel, classes.titlePanel)}>
        <Button
          variant='iconText'
          className={ownClasses.expandButton}
          onClick={toggleExpand}
          aria-label={`${title} Toggle topbar`}
          aria-expanded={expanded}
        >
          {title}
          <Icon className={ownClasses.expandIcon} />
        </Button>
      </div>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <div className={cx(ownClasses.collapseContent, classes.collapseContent)}>{drawer}</div>
      </Collapse>
    </div>
  );
};
