import { TooltipWithIcon } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const NumberOfDaysRentedQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const numberOfDaysRented = useHomePropertyField('noOfDaysRented');
  const rentedToOthers = useHomePropertyField('rentedToOthersInd');
  const helpText = numberOfDaysRented.question?.helpText;
  const {
    trackingName = 'number_of_days_rented_drop_down',
    groupLabel = numberOfDaysRented?.question?.title,
  } = props;

  const displayNumberOfDaysRented =
    rentedToOthers.exists &&
    rentedToOthers.props.value &&
    rentedToOthers.props.value === true &&
    numberOfDaysRented.exists;

  useAddFields({ numberOfDaysRented });

  if (!displayNumberOfDaysRented) return null;

  return (
    <Select
      {...numberOfDaysRented.props}
      fullWidth={false}
      groupLabel={
        <>
          {groupLabel}
          <TooltipWithIcon title={helpText} />
        </>
      }
      id='numberOfDaysRented'
      trackingName={trackingName}
      inputButtonAriaLabel='numberOfDaysRented'
    />
  );
};
