import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

export const HundredFeetDefensibleSpaceQuestion: React.FC<QuestionProps> = (props) => {
  const {
    label = 'Do you maintain at least 100 feet of defensible space on your property?',
    trackingName = 'maintain_100feet_defensible_space',
  } = props;
  const hundredFeetDefensibleSpace = useField('property.defensibleSpaceInd');

  if (!hundredFeetDefensibleSpace.exists) return null;

  return (
    <RadioGroupWithOptions
      {...hundredFeetDefensibleSpace.props}
      id='hundredFeetDefensibleSpace'
      label={label}
      variant='yesNoButton'
      trackingName={trackingName}
    />
  );
};
