import { useMemo } from 'react';

import { Divider } from '@mui/material';

import { GridItem } from '@ecp/components';
import type { Product, ProductName } from '@ecp/features/shared/product';
import { isProductProperty } from '@ecp/features/shared/product';

import type { Acknowledgement as SignatureAcknowledgement } from '../../../types';
import { useStyles } from './Acknowledgement.styles';
import { AutoWithdrawAgree } from './AutoWithdrawAgree';
import { CheckboxAcknowledgements } from './CheckboxAcknowledgements';
import { FullNameAcknowledgements } from './FullNameAcknowledgements';

interface Props {
  selectedProduct: Product;
  coverageType: ProductName;
  signatureAcknowledgements?: SignatureAcknowledgement[];
}

export const Acknowledgement: React.FC<Props> = (props) => {
  const { signatureAcknowledgements, selectedProduct, coverageType } = props;
  const { classes } = useStyles();

  /**
   * Acknowledgements that require user confirmation will be divided into two sections:
   * - one that requires a checkbox confirmation,
   * - one that requires a user to enter their full name into a text field.
   * We will use the `acknowledgementKey` to determine what type of UI input is needed to confirm each acknowledgement.
   * It should be noted that while all of these acknowledgements fall under the `category` of `Signature`,
   * the acknowledgementType of `Signature` refers to a text input requirement.
   */
  const checkboxAcknowledgements = useMemo(
    () => signatureAcknowledgements?.filter((ack) => ack.acknowledgementType === 'Checkbox'),
    [signatureAcknowledgements],
  );
  const hasCheckboxAcknowledgements =
    checkboxAcknowledgements && checkboxAcknowledgements.length > 0;

  const fullNameAcknowledgements = useMemo(
    () => signatureAcknowledgements?.filter((ack) => ack.acknowledgementType === 'Signature'),
    [signatureAcknowledgements],
  );

  const hasFullNameAcknowledgements =
    fullNameAcknowledgements && fullNameAcknowledgements.length > 0;

  const hasAutoWithdrawAgree = isProductProperty(selectedProduct);

  return (
    <>
      {hasCheckboxAcknowledgements && (
        <>
          <GridItem data-testid='checkboxAcknowledgements' xs={12} className={classes.container}>
            <CheckboxAcknowledgements
              acknowledgements={checkboxAcknowledgements}
              coverageType={coverageType}
              selectedProduct={selectedProduct}
            />
          </GridItem>
          {hasFullNameAcknowledgements && (
            <Divider aria-hidden='true' className={classes.divider} />
          )}
        </>
      )}
      {hasFullNameAcknowledgements && (
        <GridItem data-testid='nameAcknowledgements' xs={12} className={classes.container}>
          <FullNameAcknowledgements
            acknowledgements={fullNameAcknowledgements}
            coverageType={coverageType}
            selectedProduct={selectedProduct}
          />
        </GridItem>
      )}
      {hasAutoWithdrawAgree && (
        <GridItem xs={12} className={classes.container}>
          <AutoWithdrawAgree product={selectedProduct} />
        </GridItem>
      )}
    </>
  );
};
