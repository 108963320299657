import { datadogLog } from '@ecp/utils/logger';
import { Headers } from '@ecp/utils/network';
import { sessionStorage } from '@ecp/utils/storage';

import type { Answers } from '@ecp/features/sales/shared/types';

import type { SapiTarget } from '../../api';
import { setSapiTarget } from '../../api';
import { setOffersResponseFromPostInquirySuccess } from '../../offers';
import { wrapThunkActionWithErrHandler } from '../../util/wrapThunkActionWithErrHandler';
import { setInquiryApiPostSuccess, setInquiryCreationFlag } from '../actions';
import { postInquiry } from '../api';

type CreateInquiry = { answers?: Answers };

export const createInquiry = wrapThunkActionWithErrHandler<CreateInquiry>(
  ({ answers }) =>
    async (dispatch) => {
      dispatch(setInquiryCreationFlag(true));
      const response = await postInquiry({ answers });
      dispatch(setInquiryApiPostSuccess(response.payload));
      // EDSP-11130 - Resume flow for experiences coming with url and quoteid
      if (response.payload?.data?.offers) {
        dispatch(
          setOffersResponseFromPostInquirySuccess({
            ...response.payload.data.offers,
            dalSessionId: response.payload.dalSessionId,
          }),
        );
      }

      const hasSapiTarget = Headers.SAPI_TARGET in response.headers;
      if (!hasSapiTarget) {
        datadogLog({
          logType: 'error',
          message: 'sapi target not found in response headers for createInquiry',
          context: {
            logOrigin: 'libs/features/sales/shared/store/lib/src/inquiry/thunks/createInquiry.ts',
            functionOrigin: 'createInquiry',
          },
        });

        throw new Error('sapi target not found in response headers for createInquiry');
      }
      const sapiTarget = response.headers[Headers.SAPI_TARGET] as SapiTarget;
      dispatch(setSapiTarget(sapiTarget));

      sessionStorage.setItem('dalSessionId', response.payload.dalSessionId, true);
      sessionStorage.setItem('inquiryId', response.payload.data.id, true);
    },
  'createInquiry',
);
