import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'Topbar' })((theme) => ({
  root: {
    width: '100%',
    padding: '0 15px',
  },
  titlePanel: {
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.background,
    display: 'flex',
    minHeight: 40,
    width: '100%',
    paddingRight: 5,
    borderTop: `1px solid ${theme.palette.text.tertiary}`,
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
    [theme.breakpoints.down('md')]: {
      minHeight: 50,
    },
  },
  expandButton: {
    ...theme.typography.h4,
    color: theme.palette.primary.main,
    width: '100%',
    paddingLeft: 30,
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: 16,
    [theme.breakpoints.down('md')]: {
      whiteSpace: 'inherit',
      lineHeight: '18px',
      fontSize: 14,
    },
  },
  expandIcon: {
    ...theme.mixins.setColorSvg(theme.palette.primary.main),
    width: 16,
    height: 16,
    marginLeft: 9,
  },
  collapseContent: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.background,
    borderBottom: `1px solid ${theme.palette.text.tertiary}`,
    justifyContent: 'center',
    padding: '20px 0px',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'left',
    },
  },
}));
