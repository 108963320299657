import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem } from '@ecp/components';
import { EmailQuestion, PhoneNumberQuestion } from '@ecp/features/sales/shared/questions';

import metadata from '../metadata';
import { useStyles } from './NamedInsuredContactInfoQuestions.styles';

export interface Props {
  namedInsuredRef: string;
  phoneNumberRequired: boolean;
}

export const NamedInsuredContactInfoQuestions: React.FC<Props> = (props) => {
  const { classes } = useStyles();
  const { phoneNumberRequired, namedInsuredRef } = props;

  return (
    <>
      <GridItem topSpacing='xs' xs={12} md={6} lg={6} className={classes.columnLeft}>
        <EmailQuestion
          id='EmailAddress'
          personRef={namedInsuredRef}
          label='Email'
          trackingName='email_adress'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
          helperText={metadata.emailHelpText}
        />
      </GridItem>
      <GridItem topSpacing='xs' xs={12} md={6} lg={6} className={classes.columnRight}>
        <PhoneNumberQuestion
          id='Phone'
          personRef={namedInsuredRef}
          label={`Phone${phoneNumberRequired ? '' : ' (optional)'}`}
          trackingName='phone_number'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
          helperText={metadata.phoneHelpText}
          required={phoneNumberRequired}
        />
      </GridItem>
    </>
  );
};
