import type { BaseMetadataCollection } from '@ecp/types';

export const HomeBasicRatingCriteriaMetadata: BaseMetadataCollection = {
  occupancyType: {
    title: 'Dwelling type',
  },
  finishedBasementSqFt: {
    title: 'Finished basement square feet',
  },
  foundationType: {
    title: 'Foundation type',
  },
  propertySlope: {
    title: 'Is home built on slope?',
  },
  propertyType: {
    title: 'Number of living units',
  },
  numberOfStories: {
    title: 'Number of stories',
  },
  dwellingStyle: {
    title: 'Style of home',
  },
  squareFootage: {
    title: 'Total square feet',
  },
  yearBuilt: {
    title: 'Year built',
  },
  wiringImprovementYear: {
    title: 'Year electrical system was installed',
  },
};

export const HomeExteriorRatingCriteriaMetadata: BaseMetadataCollection = {
  exteriorSiding: {
    title: 'Exterior siding',
  },
  roofShape: {
    title: 'Roof type',
  },
  roofMaterial: {
    title: 'Roof material',
  },
  roofYear: {
    title: 'Year of roof installation',
  },
  garageType: {
    title: 'Type of garage',
  },
};

export const HomeInteriorRatingCriteriaMetadata: BaseMetadataCollection = {
  ceilingHeight: {
    title: 'Ceiling height',
  },
  fireplaceTypes: {
    title: 'Fireplace type',
  },
  floorTypes: {
    title: 'Floor type',
  },
  fullBath: {
    title: 'Full bathrooms',
  },
  halfBath: {
    title: 'Half bathrooms',
  },
  primaryHeatingType: {
    title: 'Heating type',
  },
  interiorWalls: {
    title: 'Inside wall material',
  },
  kitchenCountertopMaterial: {
    title: 'Kitchen countertop material',
  },
  roomsWithCathedralCeilings: {
    title: 'Number of rooms with cathedral/vaulted ceilings',
  },
  roomsWithCrownMolding: {
    title: 'Number of rooms with crown molding',
  },
};

export const HomeAdditionalRatingCriteriaMetadata: BaseMetadataCollection = {
  partnerAutoPolicy: {
    title: 'CONNECT Auto Policy?',
  },
  newHome: {
    title: 'Is this quote for a new home purchase?',
  },
  businessOnPremises: {
    title: 'Is there a business on premises?',
  },
  currentlyInsured: {
    title: 'Do you currently have insurance on this property?',
  },
  numberOfPeopleInHousehold: {
    title: 'Number of people in your household',
  },
  fireAlarmType: {
    title: 'Fire alarm type',
  },
  burglarAlarmType: {
    title: 'Burglar alarm type',
  },
  indoorSprinkler: {
    title: 'Does your home have an indoor sprinkler system?',
  },
  dogs: {
    title: 'Do you have dogs?',
  },
  sixtyFeetFence: {
    title: 'Does your property have 60 feet or more of fence?',
  },
  swimmingPool: {
    title: 'Pool',
  },
  fullTimeResidents: {
    title: 'Number of full-time residence employees',
  },
  smokeDetectors: {
    title: 'Smoke detector type',
  },
  lengthOfResidence: {
    title: 'Length of residence',
  },
};
