import { useCallback } from 'react';

import { trackClick } from '@ecp/utils/analytics/tracking';

import { Toggle as ToggleBase } from '@ecp/components';
import type { ToggleProps as TogglePropsBase } from '@ecp/components';

interface ToggleProps extends TogglePropsBase {
  trackingName?: string;
}

export const Toggle: React.FC<ToggleProps> = (props) => {
  const { trackingName, onChange, ...rest } = props;

  const handleChange = useCallback<NonNullable<ToggleProps['onChange']>>(
    (event, value) => {
      if (value) {
        onChange?.(event, value);
        if (trackingName) {
          trackClick({ action: trackingName, label: value });
        }
      }
    },
    [onChange, trackingName],
  );

  return <ToggleBase onChange={handleChange} {...rest} />;
};
