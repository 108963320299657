import { useRef, useState } from 'react';

import { Divider, Link } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { useEvent } from '@ecp/utils/react';

import { Button } from '@ecp/features/sales/shared/components';
import {
  getCurrentPage,
  getShowThirdPartyBanner,
  updateShowThirdPartyBanner,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import {
  IconUIClose,
  IconUIExpandLess,
  IconUIExpandMore,
  LogoPrimaryImageUrl,
  useIsMobile,
} from '@ecp/themes/base';

import metadata from '../metadata';
import { getTodaysSalesHours } from '../util';
import { ContactUsPopover } from './ContactUsPopover';
import { useStyles } from './HeaderMainContent.styles';

const handleSkipLink = (): void => {
  const el = document.getElementById('content');
  if (el) {
    el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    el.focus({ preventScroll: true });
  }
};

export const Banner: React.FC = (): React.ReactNode => {
  const { classes } = useStyles();

  const currentPage = useSelector(getCurrentPage);
  const showBanner = useSelector(getShowThirdPartyBanner);
  const showBannerOnCurPage = currentPage && metadata.bannerPages?.includes(currentPage);
  const dispatch = useDispatch();

  const handleHideBanner = useEvent(() => {
    dispatch(updateShowThirdPartyBanner(!showBanner));
  });

  return (
    <>
      {metadata.bannerText && showBannerOnCurPage && showBanner && (
        <div data-testid='HeaderBanner' className={classes.banner}>
          <div className={classes.bannerWrapper}>
            <p className={classes.bannerText}>{metadata.bannerText}</p>
            <Button
              variant='iconText'
              className={classes.closeBanner}
              onClick={handleHideBanner}
              aria-label='Close banner'
            >
              <IconUIClose className={classes.closeIcon} />
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export const ContactInfo: React.FC = (): React.ReactNode => {
  const { classes, cx } = useStyles();

  const [displayContactUsHrs, setDisplayContactUsHrs] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const contactInfoRef = useRef(null);

  const handleDialogClose = useEvent(() => {
    setAnchorEl(null);
    setDisplayContactUsHrs(false);
    trackClick({ action: 'topbar_contact_us', label: 'Close' });
  });

  const expandContactUsHours = useEvent(() => {
    setAnchorEl(contactInfoRef.current);
    setDisplayContactUsHrs(true);
  });

  const ExpandIcon = displayContactUsHrs ? IconUIExpandLess : IconUIExpandMore;

  return (
    <>
      <div
        className={cx(classes.contactInfo, metadata.opcoLogo && classes.hideMobileContactInfo)}
        ref={contactInfoRef}
      >
        <Button
          variant='iconTextSmall'
          className={classes.expandContactHours}
          onClick={expandContactUsHours}
          trackingName='topbar_contact_us'
          trackingLabel='Open'
          icon={<ExpandIcon />}
          iconLast
        >
          Contact us
        </Button>
        <p>Today: {getTodaysSalesHours()}</p>
      </div>
      <ContactUsPopover
        onClose={handleDialogClose}
        anchorEl={anchorEl}
        open={displayContactUsHrs}
      />
    </>
  );
};

export const Logo: React.FC = (): React.ReactNode => {
  const { classes } = useStyles();

  return (
    <img
      src={metadata.opcoLogo || metadata.primaryLogo}
      alt='insurance agency logo'
      className={
        metadata.styledLogoImage ? classes.partnerLogoImageStyled : classes.partnerLogoImage
      }
    />
  );
};

export const OpcoLogo: React.FC = (): React.ReactNode => {
  const { classes, cx } = useStyles();

  return (
    <>
      {metadata.opcoLogo && (
        <img
          src={LogoPrimaryImageUrl}
          alt='Partner Logo'
          className={cx(classes.partnerLogoImage, classes.partnerSecondaryLogoImage)}
        />
      )}
    </>
  );
};

export const VerticalDivider: React.FC = (): React.ReactNode => {
  const { classes } = useStyles();

  return (
    <>
      {metadata.showVerticalDivider && (
        <Divider orientation='vertical' className={classes.headerDivider} flexItem />
      )}
    </>
  );
};

export const LogoOnlyHeaderBar: React.FC = (): React.ReactNode => {
  const { classes } = useStyles();

  return (
    <div className={classes.mobileLogoOnlyHeaderBar}>
      <Logo />
    </div>
  );
};

export const FullHeaderBar: React.FC = (): React.ReactNode => {
  const { classes } = useStyles();

  return (
    <div className={classes.headerBar}>
      <div className={classes.bannerContainer}>
        <Logo />
        <VerticalDivider />
        <ContactInfo />
        {metadata.title && <h5 className={classes.title}>{metadata.title}</h5>}
      </div>
      <OpcoLogo />
    </div>
  );
};

export const HeaderBar: React.FC = (): React.ReactNode => {
  const isMobile = useIsMobile();
  if (isMobile && metadata.showLogoOnlyOnMobile) return <LogoOnlyHeaderBar />;

  return <FullHeaderBar />;
};

export const HeaderMainContent: React.FC = (): React.ReactNode => {
  const { classes } = useStyles();

  return (
    <>
      <Link className={classes.skipLink} component='button' onClick={handleSkipLink}>
        Skip to main content
      </Link>
      <Banner />
      <HeaderBar />
    </>
  );
};
