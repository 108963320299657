import type { ThunkAction } from '@ecp/features/sales/shared/store/types';

import { jumpToPage, startOverFlow } from '../global';
import { getDalSessionId, getLastVisitedPage } from '../inquiry';
import { setRecalled } from './actions';
import { getIsAnyPolicyExpired, getOffersExist } from './selectors';

export const makeQuoteRecallNavigation =
  ({
    nextPage,
    noOfferPage,
    initialRecallRequest,
  }: {
    nextPage: string;
    noOfferPage?: string;
    initialRecallRequest: boolean;
  }): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const offersExist = getOffersExist(state);
    const inquiryExists = !!getDalSessionId(state);
    if (offersExist) {
      dispatch(setRecalled(initialRecallRequest));
      const isAnyPolicyExpired = getIsAnyPolicyExpired(state);
      // It is important to have this inversed as it will go to the default return statement if no LOB has been selected
      if (!isAnyPolicyExpired) return dispatch(jumpToPage({ page: nextPage }));

      return dispatch(startOverFlow());
    } else if (inquiryExists) {
      if (noOfferPage) {
        return dispatch(jumpToPage({ page: noOfferPage }));
      } else {
        const lastVisitedPage = getLastVisitedPage(state);

        return dispatch(jumpToPage({ page: lastVisitedPage }));
      }
    }
  };
