import { Typography } from '@mui/material';

import { NumberFormat } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const PlumbingReplacedYearQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const plumbingReplacedYear = useHomePropertyField('renovation.plumbingReplacedYear');
  const {
    groupLabel = plumbingReplacedYear.question?.title,
    trackingName = 'plumbing_replaced_year_question',
  } = props;

  useAddFields({ plumbingReplacedYear });

  if (!plumbingReplacedYear.exists) return null;

  return (
    <NumberFormat
      {...plumbingReplacedYear.props}
      groupLabel={<Typography variant='body4'>{groupLabel}</Typography>}
      format='####'
      id='PlumbingReplacedYear'
      placeholder='YYYY'
      trackingName={trackingName}
    />
  );
};
