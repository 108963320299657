import type { PaymentType } from '@ecp/features/sales/shared/store/types';
import type { ProductName } from '@ecp/features/shared/product';
import {
  IconCardCreditCard as CreditCardIcon,
  IconCardBank,
  IconCardPayThroughMortgage as MortgageIcon,
} from '@ecp/themes/base';
import type { CardOption } from '@ecp/types';

import type { PaymentMethod } from '../../state/purchase/types';

// TODO: Make the change to PaymentType in @ecp/features/sales/shared/store/types to remove CostcoVisa
export type AvailablePaymentTypes = Exclude<PaymentType, 'CostcoVisa'>;
export type PaymentCardOption = CardOption<PaymentMethod, AvailablePaymentTypes>;
export type PaymentTypeOrder = Partial<Record<ProductName, AvailablePaymentTypes[]>>;

export const paymentTypeToPaymentMethod: Record<AvailablePaymentTypes, PaymentCardOption> = {
  EFT: {
    key: 'bankAccount',
    value: 'EFT',
    label: 'Bank account',
    icon: <IconCardBank />,
  },
  CreditCard: {
    key: 'creditCard',
    value: 'CreditCard',
    label: 'Credit/debit card',
    icon: <CreditCardIcon />,
  },
  Mortgage: {
    key: 'mortgage',
    value: 'Mortgage',
    label: 'Pay through mortgage',
    icon: <MortgageIcon />,
  },
};

export const paymentTypeOrder: PaymentTypeOrder = {
  auto: ['EFT', 'CreditCard'],
  home: ['Mortgage', 'EFT', 'CreditCard'],
  renters: ['EFT', 'CreditCard'],
};

export const AutoPayProductAlertMetadata = {
  'amfam.auto': true,
  'homesite.home': false,
  'connect.auto': false,
  'connect-boost.auto': false,
  'homesite.renters': false,
  'amfam-adv.auto': false,
  'amfam-adv.home': false,
  'amfam-adv.renters': false,
};

// CSUI-1068
export const sniEmailRequiredStates = ['NE', 'IA', 'MN', 'AL', 'UT', 'NV', 'OH', 'WI'];
