import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import {
  getIsBundleForOfferProductsSelected,
  getOfferProductsSelectedByType,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { useIsMobile } from '@ecp/themes/base';

export const useCoveragePageRedesignTestsPolicyStartDateAtTopOfCoveragesForm = (): {
  showCostcoCoveragesPageRedesignTestTopPolicyStartDateBundleHeading: boolean;
  showCostcoCoveragesPageRedesignTestTopAuto: boolean;
  showCostcoCoveragesPageRedesignTestTopProperty: boolean;
  showCostcoCoveragesPageRedesignTestOnTop: boolean;
  showZillowCoveragesPageRedesignTestOnTop: boolean;
} => {
  const isMobile = useIsMobile();
  const isBundle = useSelector(getIsBundleForOfferProductsSelected);

  const isZillowMobileCoveragePageRedesignABTestApplyOnTop =
    flagValues[FeatureFlags.COVERAGEPAGE_REDESIGN] && isMobile;
  const isCostcoCoveragePageRedesignABCTestApplyOnTop =
    flagValues[FeatureFlags.COVERAGE_PAGE_REDESIGN_VARIANTS] && !isMobile;

  const showCostcoCoveragesPageRedesignTestOnTop = isCostcoCoveragePageRedesignABCTestApplyOnTop;
  const showZillowCoveragesPageRedesignTestOnTop =
    isZillowMobileCoveragePageRedesignABTestApplyOnTop;

  const showCostcoCoveragesPageRedesignTestTopPolicyStartDateBundleHeading =
    isCostcoCoveragePageRedesignABCTestApplyOnTop && isBundle;

  const { auto: autoOfferProduct, property: propertyOfferProduct } = useSelector(
    getOfferProductsSelectedByType,
  );

  const showCostcoCoveragesPageRedesignTestTopAuto = !!(
    isCostcoCoveragePageRedesignABCTestApplyOnTop && autoOfferProduct
  );
  const showCostcoCoveragesPageRedesignTestTopProperty = !!(
    isCostcoCoveragePageRedesignABCTestApplyOnTop && propertyOfferProduct
  );

  return {
    showCostcoCoveragesPageRedesignTestOnTop,
    showCostcoCoveragesPageRedesignTestTopAuto,
    showCostcoCoveragesPageRedesignTestTopProperty,
    showCostcoCoveragesPageRedesignTestTopPolicyStartDateBundleHeading,
    showZillowCoveragesPageRedesignTestOnTop,
  };
};

export const useShowCostcoCoveragesPageRedesignPolicyStartCalendarOnAutoAndPropertyForms =
  (): boolean => {
    const isMobile = useIsMobile();
    const isCostcoCoveragesPageDesignTestOn =
      flagValues[FeatureFlags.COVERAGE_PAGE_REDESIGN_VARIANTS];

    return isCostcoCoveragesPageDesignTestOn && isMobile;
  };

export const useShowOriginalPolicyStartDateForm = (): boolean => {
  const { showZillowCoveragesPageRedesignTestOnTop, showCostcoCoveragesPageRedesignTestOnTop } =
    useCoveragePageRedesignTestsPolicyStartDateAtTopOfCoveragesForm();
  const showPolicyStartCalendarOnAutoAndPropertyForms =
    useShowCostcoCoveragesPageRedesignPolicyStartCalendarOnAutoAndPropertyForms();

  return !(
    showZillowCoveragesPageRedesignTestOnTop ||
    showCostcoCoveragesPageRedesignTestOnTop ||
    showPolicyStartCalendarOnAutoAndPropertyForms
  );
};
