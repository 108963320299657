import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

export const FiveFootCombustibleClearanceQuestion: React.FC<QuestionProps> = (props) => {
  const {
    label = 'Have you cleared vegetation, debris, mulch, stored combustible materials, and any and all moveable combustible objects within 5 feet of your home?',
    trackingName = 'cleared_combustible_objects_within_5feet',
  } = props;
  const clearedVegetationAndCombustibles = useField('property.fiveFootCombustibleClearanceInd');

  if (!clearedVegetationAndCombustibles.exists) return null;

  return (
    <RadioGroupWithOptions
      {...clearedVegetationAndCombustibles.props}
      id='clearedVegetationAndCombustibles'
      label={label}
      variant='yesNoButton'
      trackingName={trackingName}
    />
  );
};
