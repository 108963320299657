import { wrapThunkActionWithErrHandler } from '../../util/wrapThunkActionWithErrHandler';
import { setAnswersApiGetSuccess } from '../actions';
import { getAnswers } from '../api';
import { getDalSessionId } from '../selectors';

type FetchAnswers = { dalSessionId?: string };

export const fetchAnswers = wrapThunkActionWithErrHandler<FetchAnswers, void>(
  ({ dalSessionId }) =>
    async (dispatch, getState) => {
      const dalSessionIdValue = dalSessionId ?? getDalSessionId(getState());

      if (!dalSessionIdValue) {
        return;
      }

      const response = await getAnswers({ dalSessionId: dalSessionIdValue });
      dispatch(setAnswersApiGetSuccess(response.payload));
    },
  'fetchAnswers',
);
