import {
  IconCardFrontAirBag as AirBagIcon,
  IconCardAntiLockBreaks,
  IconCardAntiTheft,
  IconCardDaytimeRunningLights,
  IconCardKeptInGarage,
} from '@ecp/themes/base';
import type { OptionsMetadata } from '@ecp/types';

/**
 * The key will be used to patch the answer if a user
 * makes a selection.
 *
 * The type will be used to differentiate driver and vehicle
 * disocunts since they're going to be merged into a single arraayt
 */
const vehicleDiscountMetadata: { [productKey: string]: OptionsMetadata } = {
  'connect.auto': {
    AntiTheftDevice: {
      title: 'Anti-theft',
      type: 'vehicle',
      primaryImage: <IconCardAntiTheft />,
      subHeaderText: 'Which anti-theft devices?',
      secondaryText: `You may qualify for a discount if your vehicle is equipped with at least one or more active or passive anti-theft devices. An active device will prevent your vehicle from being driven by a thief. A passive device will set off an alarm or aid in the recovery of a stolen vehicle.`,
      subDiscount: {
        Active: {
          title: 'Active',
          entityKey: 'features.antitheft.active',
        },
        Passive: {
          title: 'Passive',
          entityKey: 'features.antitheft.passive',
        },
        Alarm: {
          title: 'Alarm',
          entityKey: 'features.antitheft.alarm',
        },
        VehicleRecoverySystem: {
          title: 'Vehicle Recovery System',
          entityKey: 'features.antitheft.vehicleRecoverySystem',
        },
        WindowGlassEtching: {
          title: 'Window Etching',
          entityKey: 'features.antitheft.windowGlassEtching',
        },
        CategoryI: {
          title: 'Category I',
          entityKey: 'features.antitheft.category1',
        },
        CategoryII: {
          title: 'Category II',
          entityKey: 'features.antitheft.category2',
        },
        CategoryIII: {
          title: 'Category III',
          entityKey: 'features.antitheft.category3',
        },
        CategoryIV: {
          title: 'Category IV',
          entityKey: 'features.antitheft.category4',
        },
      },
      stateOptions: {
        KY: {
          secondaryText: `You may qualify for a discount if your vehicle(s) have one or more anti-theft devices. Discount applicability is subject to state rules and may vary by state.`,
          subDiscount: {
            CategoryI: {
              title: 'Category I',
              entityKey: 'features.antitheft.category1',
            },
            CategoryII: {
              title: 'Category II',
              entityKey: 'features.antitheft.category2',
            },
            CategoryIII: {
              title: 'Category III',
              entityKey: 'features.antitheft.category3',
            },
          },
        },
        NJ: {
          secondaryText: `You may qualify for a discount if your vehicle(s) have one or more anti-theft devices. Discount applicability is subject to state rules and may vary by state.`,
          subDiscount: {
            CategoryI: {
              title: 'Category I',
              entityKey: 'features.antitheft.category1',
            },
            CategoryII: {
              title: 'Category II',
              entityKey: 'features.antitheft.category2',
            },
            CategoryIII: {
              title: 'Category III',
              entityKey: 'features.antitheft.category3',
            },
            CategoryIV: {
              title: 'Category IV',
              entityKey: 'features.antitheft.category4',
            },
          },
        },
      },
      entityKey: 'features.antitheft',
    },
    GarageParked: {
      title: 'Vehicle kept in garage',
      type: 'vehicle',
      primaryImage: <IconCardKeptInGarage />,
      entityKey: 'discount.keptInGarage',
    },
    Airbags: {
      title: 'Airbags',
      type: 'vehicle',
      primaryImage: <AirBagIcon />,
      subHeaderText: 'Which airbags?',
      fieldType: 'radiogroup',
      entityKey: 'features.safety.airbag',
    },
    AntiLockBrakes: {
      title: 'Anti-lock brakes',
      type: 'vehicle',
      primaryImage: <IconCardAntiLockBreaks />,
      entityKey: 'features.safety.antiLockBrakes',
    },
    DaytimeRunningLights: {
      title: 'Daytime running lights',
      type: 'vehicle',
      primaryImage: <IconCardDaytimeRunningLights />,
      entityKey: 'features.safety.dayTimeRunningLights',
    },
  },
  'amfam.auto': {
    antiLockBrakes: {
      title: 'Anti-lock brakes',
      type: 'vehicle',
      primaryImage: <IconCardAntiLockBreaks />,
      stateOptions: {
        NY: {
          title: 'New York Anti-Lock Brakes',
        },
      },
      entityKey: 'features.safety.antiLockBrakes',
    },
    dayTimeRunningLights: {
      title: 'Daytime running lights',
      type: 'vehicle',
      primaryImage: <IconCardDaytimeRunningLights />,
      stateOptions: {
        NY: {
          title: 'New York Daytime Running Lights',
        },
      },
      entityKey: 'features.safety.dayTimeRunningLights',
    },
    airbag: {
      title: 'Airbags',
      type: 'vehicle',
      primaryImage: <AirBagIcon />,
      subHeaderText: 'Which airbags?',
      fieldType: 'radiogroup',
      stateOptions: {
        NC: {
          title: 'North Carolina Airbag Discount',
          secondaryText: `You qualified for this discount because you drive a vehicle with a driver-side or passenger-side airbag.`,
        },
      },
      entityKey: 'features.safety.airbag',
    },
    antiTheft: {
      title: 'Anti-theft',
      type: 'vehicle',
      primaryImage: <IconCardAntiTheft />,
      secondaryText: `You may qualify for a discount if your vehicle is equipped with at least one or more active or passive anti-theft devices. An active device will prevent your vehicle from being driven by a thief. A passive device will set off an alarm or aid in the recovery of a stolen vehicle.`,
      subHeaderText: 'Which anti-theft devices?',
      subDiscount: {
        active: {
          title: 'Active',
        },
        passive: {
          title: 'Passive',
        },
        alarm: {
          title: 'Alarm',
        },
        vehicleRecoverySystem: {
          title: 'Vehicle Recovery System',
        },
        windowEtching: {
          title: 'Window Etching ',
        },
        categoryI: {
          title: 'Category I',
        },
        categoryII: {
          title: 'Category II',
        },
        categoryIII: {
          title: 'Category III',
        },
        categoryIV: {
          title: 'Category IV',
        },
      },
      stateOptions: {
        AR: {
          secondaryText: `You may qualify for a discount if your vehicle is equipped with at least one or more active or passive anti-theft devices. An active device will prevent your vehicle from being driven by a thief. A Passive device will set off an alarm or aid in the recovery of a stolen vehicle.`,
        },

        SC: {
          secondaryText: `You may qualify for a discount if your vehicle is equipped with at least one or more active or passive anti-theft devices. An active device will prevent your vehicle from being driven by a thief. A Passive device will set off an alarm or aid in the recovery of a stolen vehicle.`,
        },

        AK: {
          secondaryText: `You may qualify for a discount if your vehicle is equipped with at least one or more active or passive anti-theft devices. An active device will prevent your vehicle from being driven by a thief. A Passive device will set off an alarm or aid in the recovery of a stolen vehicle.`,
        },
        RI: {
          secondaryText: `You qualified for a discount because your vehicle has additional anti-theft device(s).`,
          subDiscount: {
            categoryI: {
              title: 'Category I',
              secondaryText: `A device qualifies as a Category I anti-theft device or vehicle recovery device if it is an "Alarm Only Device" . "Alarm Only Device" means a device which sounds an audible alarm that can be heard at a distance of at least 300 feet for a minimum of three (3) minutes.`,
            },
            categoryII: {
              title: 'Category II',
              secondaryText: `A device qualifies as a Category II anti-theft device or vehicle recovery device if it  is an "Active Disabling Device" . "Active Disabling Device" means a device which disables the vehicle by making the fuel, ignition or starting system inoperative and a separate manual step is required to engage the device.`,
            },
            categoryIII: {
              title: 'Category III',
              secondaryText: `A device qualifies as a Category III anti-theft or vehicle recovery device if it is a "Passive Disabling Device". "Passive Disabling Device" means a device which disables the vehicle by making the fuel, ignition or starting system inoperative and a separate manual step is not required to engage the device.`,
            },
            categoryIV: {
              title: 'Category IV',
              secondaryText: `A device or system qualifies as a Category IV anti-theft or vehicle recovery device it is "Vehicle Recovery System Device". "Vehicle Recovery System Device" means an electronic unit installed in a vehicle that is activated after the vehicle is stolen.  When activated, the device provides information to law enforcement officials or another public or private entity regarding the vehicle's location.  The system provides for the routine delivery of the information to the appropriate law enforcement entity to assist in the recovery of the vehicle.`,
            },
          },
        },
        NJ: {
          secondaryText: `You qualified for a discount because your vehicle has additional anti-theft device(s).`,
          subDiscount: {
            categoryI: {
              title: 'Category I',
              secondaryText: '',
            },
            categoryII: {
              title: 'Category II',
              secondaryText: '',
            },
            categoryIII: {
              title: 'Category III',
              secondaryText: '',
            },
            categoryIV: {
              title: 'Category IV',
              secondaryText: '',
            },
          },
        },
        KY: {
          subDiscount: {
            categoryI: {
              title: 'Category I',
              secondaryText: '',
            },
            categoryII: {
              title: 'Category II',
              secondaryText: '',
            },
            categoryIII: {
              title: 'Category III',
              secondaryText: '',
            },
          },
        },
      },
      entityKey: 'features.antitheft',
    },
  },
  'amfam-adv.auto': {},
};

export default vehicleDiscountMetadata;
