import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'MailingAddressQuestions' })((theme) => ({
  ...theme.mixins.formAll,
  columnLeftPadding: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 5,
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
      paddingRight: 15,
    },
  },
  columnRightPadding: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: 15,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 5,
    },
  },
}));
