import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { useAddFields } from '@ecp/features/sales/form';
import { TextField } from '@ecp/features/sales/shared/components';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

import type { QuestionProps } from '../../questionProps.types';

interface Props extends QuestionProps {
  personRef: string;
  disabled: boolean;
  helperText?: string;
}

export const MiddleNameQuestion: React.FC<Props> = (props) => {
  const {
    personRef,
    id = 'MiddleName',
    label = 'Middle name (optional)',
    trackingName = 'middle_name',
    trackingLabel = GoogleAnalyticsLabels.REDACTED,
    disabled,
    helperText,
  } = props;
  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const middleName = usePersonField('middleName');
  useAddFields({ middleName });

  if (!middleName.exists) return null;

  return (
    <TextField
      {...middleName.props}
      ariaLabel='Middle Name'
      autoComplete='family-name'
      data-testid='middleName'
      disabled={disabled}
      helperText={helperText}
      id={id}
      label={label}
      trackingLabel={trackingLabel}
      trackingName={trackingName}
    />
  );
};
