import { useMemo } from 'react';

import { Card, CardContent, Grid, Step, StepLabel } from '@mui/material';

import {
  getAggregatePathTrackingStatus,
  getNavTracking,
  getPageFlowPaths,
} from '@ecp/features/sales/shared/store';
import type { PageFlow } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { useStyles } from '../NavbarDrawer';
import { getNavbarStatus } from '../navigationUtil';
import { StepIcon } from '../StepIcon';

interface Props {
  otherPageFlows: PageFlow[];
  activeRootStepNumber: number;
  stepIndex: number;
  jumpInNavbar: (pagePth: string) => void;
}

export const OtherNavbar: React.FC<Props> = (props) => {
  const { otherPageFlows, activeRootStepNumber, stepIndex, jumpInNavbar } = props;
  const { classes, cx } = useStyles();
  const navTracking = useSelector(getNavTracking);

  const navTrackingStatuses = useMemo(
    () =>
      otherPageFlows.map((pageFlow) =>
        getAggregatePathTrackingStatus(navTracking, getPageFlowPaths(pageFlow)),
      ),
    [navTracking, otherPageFlows],
  );

  return (
    <>
      {otherPageFlows.map((pageFlow, index) => {
        return (
          <Card key={index} className={classes.cardMargin}>
            <CardContent>
              <div>
                {pageFlow.value?.menuItems?.map((menuItem) => {
                  const navbarStatus = getNavbarStatus(navTrackingStatuses[index]);
                  const completed =
                    navbarStatus.completed && activeRootStepNumber > stepIndex + index;
                  const active = navbarStatus.active;

                  const step = (
                    <Step key={menuItem.text} completed={completed} expanded>
                      <StepLabel
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={completed ? () => jumpInNavbar(menuItem.path) : undefined}
                      >
                        <Grid container justifyContent='flex-start'>
                          <Grid item className={classes.iconPadding}>
                            <StepIcon
                              active={active}
                              completed={completed}
                              index={stepIndex + index}
                            />
                          </Grid>
                          <Grid
                            item
                            aria-disabled={!completed}
                            className={cx(
                              classes.columnLeft,
                              classes.labelText,
                              active && classes.activeStep,
                              completed && classes.labelLink,
                            )}
                          >
                            {menuItem.text}
                          </Grid>
                        </Grid>
                      </StepLabel>
                    </Step>
                  );

                  return step;
                })}
              </div>
            </CardContent>
          </Card>
        );
      })}
    </>
  );
};
