import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { isMasked } from '@ecp/utils/common';
import { formatDate } from '@ecp/utils/date';
import { useEvent } from '@ecp/utils/react';

import { GridItem, Select } from '@ecp/components';
import type { SelectProps } from '@ecp/components';
import { env } from '@ecp/env';
import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { Checkbox, DatePicker, TextField } from '@ecp/features/sales/shared/components';
import {
  deleteAnswers,
  getNonPniDrivers,
  getSapiTarget,
  updateAnswers,
  useField,
} from '@ecp/features/sales/shared/store';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue } from '@ecp/features/sales/shared/types';
import type { Field } from '@ecp/types';

import { useStyles } from './SecondaryNamedInsuredForm.styles';

export interface SniFormQuestionsProps {
  variant?: 'select' | 'textFields';
  title: string | React.ReactElement;
  textFieldUseAutoSni?: boolean;
  fieldPrefix: string;
  newPersonRef?: string | undefined;
  trackingPrefix?: string;
  isPropertySni?: Dispatch<SetStateAction<boolean>>;
}

export const SniFormQuestions: React.FC<SniFormQuestionsProps> = (props) => {
  const {
    variant,
    textFieldUseAutoSni,
    title,
    fieldPrefix,
    newPersonRef,
    trackingPrefix,
    isPropertySni,
  } = props;

  const { classes, cx } = useStyles();
  const dispatch = useDispatch();

  const drivers = useSelector(getNonPniDrivers);

  const secondaryInsuredPersonRef = useField(`${fieldPrefix}.person.ref`);
  const autoSniFieldValue = useField(`secondaryInsured.person.ref`).props.value?.trim();
  const propertyPersonRef = secondaryInsuredPersonRef.props.value
    ? secondaryInsuredPersonRef.props.value
    : newPersonRef;
  // for auto only
  const useAutoField = useFieldWithPrefix(autoSniFieldValue, 'person.<id>');

  const driverFirstName = useAutoField('firstName');
  const driverLastName = useAutoField('lastName');
  const driverDOB = useField(`${autoSniFieldValue}.dob`);
  const staticDriverDateOfBirth = useField(`static.${autoSniFieldValue}.dob`);
  const sniDriverDateOfBirth =
    staticDriverDateOfBirth && staticDriverDateOfBirth.props.value !== undefined
      ? staticDriverDateOfBirth
      : driverDOB;

  // for home and renters
  const sapiTarget = useSelector(getSapiTarget);
  const isSAPIV4 = sapiTarget === 'v4';
  const personQuestionPrefix = isSAPIV4 ? 'person.<id>' : 'personPropertySNI.<id>';
  const usePersonField = useFieldWithPrefix(propertyPersonRef, personQuestionPrefix);

  const firstName = usePersonField('firstName');
  const lastName = usePersonField('lastName');
  const dateOfBirth = usePersonField('dob');
  const staticDateOfBirth = useField(`static.personPropertySNI.dob`);

  const fieldObj: { [key: string]: Field<AnswerValue> } = {};

  const fn = fieldPrefix + '_firstName';
  const ln = fieldPrefix + '_lastName';
  const dob = fieldPrefix + '_dob';
  fieldObj[fn] = firstName;
  fieldObj[ln] = lastName;
  fieldObj[dob] = dateOfBirth;

  useAddFields(
    variant === 'textFields'
      ? { [fn]: fieldObj[fn], [ln]: fieldObj[ln], [dob]: fieldObj[dob] }
      : {},
  );
  useAddConditionValues({
    conditionalFields: [fieldObj[fn], fieldObj[ln], fieldObj[dob]],
    isExcluded: () =>
      !fieldObj[fn].props.value && !fieldObj[ln].props.value && !fieldObj[dob].props.value,
  });

  const [useAutoSNIData, setUseAutoSNIData] = useState(false);
  const [isNotApplicable, setIsNotApplicable] = useState(false);

  useEffect(() => {
    const getSniYear = (dob: string): string | undefined => formatDate(dob)?.slice(-4);
    if (
      secondaryInsuredPersonRef.props.value &&
      driverFirstName.props.value === firstName.props.value &&
      driverLastName.props.value === lastName.props.value &&
      getSniYear(sniDriverDateOfBirth.props.value) === getSniYear(dateOfBirth.props.value)
    ) {
      setUseAutoSNIData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDriverObjactionOnChange: NonNullable<SelectProps['actionOnChange']> = useCallback(
    (value) => {
      setIsNotApplicable(value === ' ');
      secondaryInsuredPersonRef.props.actionOnComplete(value);
    },
    [secondaryInsuredPersonRef.props],
  );

  const handleActionOnChange = useCallback(
    (
        func: (value: AnswerValue) => void,
        secondaryInsuredPersonRef: Field<AnswerValue>,
        newPersonRef: string | undefined,
      ) =>
      (value: AnswerValue) => {
        func(value);
        if (!secondaryInsuredPersonRef.props.value)
          secondaryInsuredPersonRef.props.actionOnComplete(newPersonRef);
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const handleActionOnComplete = useCallback(
    (
        field: Field<AnswerValue>,
        secondaryInsuredPersonRef: Field<AnswerValue>,
        firstName: Field<AnswerValue>,
        lastName: Field<AnswerValue>,
        dateOfBirth: Field<AnswerValue>,
        staticDob?: boolean,
      ) =>
      (value: AnswerValue) => {
        if (value) {
          field.props.actionOnComplete(value);
        } else {
          field.props.actionOnComplete('');
          field.update('');
        }
        if (isPropertySni) {
          isPropertySni(true);
        }
        if (staticDob) {
          staticDateOfBirth.props.actionOnComplete(value);
        }
        if (!firstName.props.value && !lastName.props.value && !dateOfBirth.props.value) {
          secondaryInsuredPersonRef.props.actionOnComplete('');
          if (isPropertySni) {
            isPropertySni(false);
          }
        }
      },
    [isPropertySni, staticDateOfBirth.props],
  );

  const deleteKeys = async (): Promise<void> => {
    const keysToDelete = [
      firstName.key,
      lastName.key,
      dateOfBirth.key,
      secondaryInsuredPersonRef.key,
      staticDateOfBirth.key,
    ];
    await dispatch(deleteAnswers({ keys: keysToDelete }));
    firstName.update('');
    lastName.update('');
    dateOfBirth.update('');
    staticDateOfBirth.update('');
    secondaryInsuredPersonRef.update('');
    setUseAutoSNIData(false);
  };

  const handleCheckbox = useEvent(async () => {
    if (!useAutoSNIData) {
      // going to be checked
      const keysToUpdate = isSAPIV4
        ? {
            // For v4, this will point to the same person, so just use autoSni reference
            [secondaryInsuredPersonRef.key]: autoSniFieldValue,
            [staticDateOfBirth.key]: sniDriverDateOfBirth.props.value,
          }
        : {
            [firstName.key]: driverFirstName.props.value,
            [lastName.key]: driverLastName.props.value,
            [dateOfBirth.key]: sniDriverDateOfBirth.props.value,
            [secondaryInsuredPersonRef.key]: propertyPersonRef,
            [staticDateOfBirth.key]: sniDriverDateOfBirth.props.value,
          };
      if (isPropertySni) {
        isPropertySni(true);
      }

      await dispatch(updateAnswers({ answers: keysToUpdate }));
      firstName.update(driverFirstName.props.value);
      lastName.update(driverLastName.props.value);
      dateOfBirth.update(sniDriverDateOfBirth.props.value);
      secondaryInsuredPersonRef.update(propertyPersonRef);
      staticDateOfBirth.update(sniDriverDateOfBirth.props.value);
      setUseAutoSNIData(true);
    } else {
      // going to be unchecked
      if (isPropertySni) {
        isPropertySni(false);
      }
      deleteKeys();
    }
  });

  useEffect(() => {
    // changes checkbox to false and others when select is changed
    if (variant === 'textFields' && useAutoSNIData) {
      deleteKeys();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSniFieldValue]);

  if (variant === 'select') {
    if (!drivers) return null;

    const driverObj = drivers.map((driver) => ({
      label: `${driver.firstName} ${driver.lastName} ${
        isMasked(driver.dateOfBirth) ? driver.dateOfBirth : formatDate(driver.dateOfBirth)
      }`,
      value: driver.personRef,
    }));
    driverObj.push({
      label: 'Not applicable',
      value: ' ',
    });

    return (
      <Grid container>
        {title}

        <Grid container>
          <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
            <Select
              {...secondaryInsuredPersonRef.props}
              id='FirstName'
              label='First name'
              actionOnChange={handleDriverObjactionOnChange}
              options={driverObj}
              displayEmpty
              // eslint-disable-next-line react/jsx-no-bind
              renderValue={(value) =>
                isNotApplicable ? 'Not applicable' : driverFirstName.props.value
              }
              className={classes.select}
              trackingName='auto_sni_first_name_selection'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
            />
          </GridItem>
          <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnRight}>
            <TextField
              id='SecondaryInsuredLastName'
              label='Last name'
              data-testid='lastName'
              value={driverLastName.props.value}
              disabled
              ariaLabel='Secondary insured last name'
              trackingName='auto_sni_last_name_selection'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
            />
          </GridItem>
          <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
            <TextField
              id='SecondaryInsuredDob'
              label='Date of birth'
              data-testid='dob'
              value={
                isMasked(sniDriverDateOfBirth.props.value)
                  ? sniDriverDateOfBirth.props.value
                  : formatDate(sniDriverDateOfBirth.props.value)
              }
              disabled
              ariaLabel='Secondary insured dob'
              trackingName='auto_sni_dob'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
            />
          </GridItem>
        </Grid>
      </Grid>
    );
  }

  // (variant === 'textFields')
  else {
    return (
      <Grid container>
        {title}
        {textFieldUseAutoSni &&
          (env.static.isAgent || (staticDriverDateOfBirth.props.value && !env.static.isAgent)) && (
            <GridItem topSpacing={10} xs={12}>
              <Checkbox
                className={classes.checkBox}
                onChange={handleCheckbox}
                checked={useAutoSNIData}
                disabled={!autoSniFieldValue}
                trackingName={`${trackingPrefix}_add_sni_checkbox`}
                trackingLabel={useAutoSNIData ? '0' : '1'}
              />
              <span
                className={cx(classes.questionSubLabel, !autoSniFieldValue && classes.disabled)}
              >
                Add the SNI from my auto policy
              </span>
            </GridItem>
          )}
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
          <TextField
            {...firstName.props}
            id='SecondaryInsuredFirstName'
            label='First name'
            ariaLabel='Secondary insured first name'
            trackingName={`${trackingPrefix}_sni_first_name`}
            trackingLabel={GoogleAnalyticsLabels.REDACTED}
            actionOnChange={handleActionOnChange(
              firstName.props.actionOnChange,
              secondaryInsuredPersonRef,
              newPersonRef,
            )}
            disabled={useAutoSNIData}
            actionOnComplete={handleActionOnComplete(
              firstName,
              secondaryInsuredPersonRef,
              firstName,
              lastName,
              dateOfBirth,
            )}
          />
        </GridItem>
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnRight}>
          <TextField
            {...lastName.props}
            id='SecondaryInsuredLastName'
            label='Last name'
            ariaLabel='Secondary insured last name'
            trackingName={`${trackingPrefix}_sni_last_name`}
            trackingLabel={GoogleAnalyticsLabels.REDACTED}
            actionOnChange={handleActionOnChange(
              lastName.props.actionOnChange,
              secondaryInsuredPersonRef,
              newPersonRef,
            )}
            disabled={useAutoSNIData}
            actionOnComplete={handleActionOnComplete(
              lastName,
              secondaryInsuredPersonRef,
              firstName,
              lastName,
              dateOfBirth,
            )}
          />
        </GridItem>
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
          <DatePicker
            {...dateOfBirth.props}
            value={
              isMasked(dateOfBirth.props.value)
                ? staticDateOfBirth.props.value
                : dateOfBirth.props.value
            }
            hidePicker
            id='SecondaryInsuredDob'
            label='Date of birth'
            trackingName={`${trackingPrefix}_sni_dob`}
            trackingLabel={GoogleAnalyticsLabels.REDACTED}
            disabled={useAutoSNIData}
            actionOnChange={handleActionOnChange(
              dateOfBirth.props.actionOnChange,
              secondaryInsuredPersonRef,
              newPersonRef,
            )}
            actionOnComplete={handleActionOnComplete(
              dateOfBirth,
              secondaryInsuredPersonRef,
              firstName,
              lastName,
              dateOfBirth,
              true,
            )}
          />
        </GridItem>
      </Grid>
    );
  }
};
