import { useCallback, useState } from 'react';

import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { IconUIExpandLess, IconUIExpandMore } from '@ecp/themes/base';

import { useStyles } from './CededPolicyBanner.styles';

export const CededPolicyBanner: React.FC = () => {
  const { classes } = useStyles();

  const [showDetails, setShowDetails] = useState(true);

  const collapse = useCallback(() => setShowDetails(false), []);
  const expand = useCallback(() => setShowDetails(true), []);

  return (
    <div className={classes.root}>
      {showDetails && (
        <IconButton className={classes.expandIcon} onClick={collapse}>
          <IconUIExpandLess />
        </IconButton>
      )}
      {!showDetails && (
        <IconButton className={classes.expandIcon} onClick={expand}>
          <IconUIExpandMore />
        </IconButton>
      )}
      <p>
        <strong>Some coverages have been adjusted due to your policy being ceded.</strong> Due to
        ceding requirements filed with the North Carolina Motor Vehicle Reinsurance Facility,
        certain coverages have limitations and some coverage(s) are not available.
      </p>
      {showDetails && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width='50%'>Affected coverages</TableCell>
              <TableCell width='50%'>Limitations</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Bodily Injury Liability</TableCell>
              <TableCell>Maximum limit you can select is $100,000/$300,000</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Property Damage Liability</TableCell>
              <TableCell>Maximum limit you can select is $50,000</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Medical Expense</TableCell>
              <TableCell>Maximum limit you can select is $2,000</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Uninsured Motorist - Property Damage</TableCell>
              <TableCell>Maximum limit you can select is $50,000</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Coverage for Rented Vehicles</TableCell>
              <TableCell>This coverage is not available</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </div>
  );
};
