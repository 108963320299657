import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'NextPageInstructions' })((theme) => ({
  root: {
    marginTop: 40,
    color: theme.palette.text.primary,
    ...theme.typography.h3,
  },
  compact: {
    marginTop: 20,
  },
  divider: {
    backgroundColor: theme.palette.other.divider,
    marginTop: 40,
    marginBottom: 40,
  },
}));
