import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'VehicleLienHolderFormQuestions' })((theme) => ({
  header: {
    ...theme.typography.h3,
    paddingBottom: 20,
  },
  informationCard: {
    backgroundColor: theme.palette.grey[100],
    borderTop: `4px solid ${theme.palette.primary.main}`,
    marginTop: 20,
    padding: '0px 20px 20px 20px',
  },
  footer: {
    marginLeft: 5,
  },
  headerContainer: {
    display: 'flex',
  },
  headerContainerTitle: {
    marginTop: 20,
    marginBottom: 5,
  },
}));
