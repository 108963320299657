import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CededPolicyBanner' })((theme) => ({
  root: {
    ...theme.typography.body1,
    width: '100%',
    backgroundColor: theme.palette.warning.light,
    border: `1px solid ${theme.palette.warning.main}`,
    padding: 10,
  },
  expandIcon: {
    color: theme.palette.grey[900],
    float: 'right',
    padding: 0,
  },
}));
