import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PropertyDetailsForm' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    ...theme.mixins.form,
    marginTop: 40,
    [theme.breakpoints.down('md')]: {
      padding: '0 15px',
    },
  },
  next: {
    ...theme.mixins.formAll.next,
    marginTop: 0,
  },
}));
