export const enum ConfigType {
  INTRO_PAGE_FLOW = 'introPageFlow',
  PRODUCT_PAGE_FLOW = 'productPageFlow',
  SECONDARY_NAMED_INSURED_PAGE_FLOW = 'secondaryNamedInsuredPageFlow',
  INITIAL_SUMMARY_PAGE_FLOW = 'initialSummaryPageFlow',
  HOME_PREFILL_SUMMARY_PAGE_FLOW = 'homePrefillSummaryPageFlow',
  DELTA_PAGE_FLOW = 'deltaPageFlow',
  THIRD_PARTY_INTEREST_FLOW = 'thirdPartyInterestPageFlow',
  THIRD_PARTY_REPORTS_FLOW = 'thirdPartyReportsPageFlow',
  FINAL_SUMMARY_PAGE_FLOW = 'finalSummaryPageFlow',
  PURCHASE_PAGE_FLOW = 'purchasePageFlow',
}
