import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'IAgreeCheckbox' })((theme) => ({
  verticalAlign: {
    ...theme.typography.body1,
    margin: 0,
    paddingLeft: 12,
    listStyle: 'none',
  },
  checkboxRoot: {
    marginTop: 15,
  },
  checkboxText: {
    width: '50%',
  },
}));
