import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<void, 'closeIcon'>({ name: 'Dialog' })(
  (...[theme, , classes]) => ({
    dialog: {
      borderRadius: 8,
      backgroundColor: theme.palette.other.body,
    },
    dialogTitleContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexShrink: 0,
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      paddingBottom: 5,
    },
    dialogTitle: theme.typography.header1,
    dialogDivider: {
      marginBottom: 10,
    },
    regularScreen: {
      position: 'fixed',
      top: '5%',
      padding: 15,
      maxHeight: '85%',
    },
    fullScreen: {},
    fullScreenForm: {
      height: '100%',
    },
    fullScreenRoot: {
      maxWidth: '100vw !important',
      width: '100%',
    },
    fullScreenAppBar: {
      position: 'relative',
      '& .MuiToolbar-root': { minHeight: 56 },
    },
    fullScreenActionBar: {
      alignItems: 'center',
    },
    flexEnd: {
      justifyContent: 'flex-end',
    },
    spaceBetween: {
      justifyContent: 'space-between',
    },
    fullScreenButton: {
      ...theme.typography.button,
      marginTop: 10,
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.grey[50],
        color: theme.palette.grey[50],
      },
      [`& .${classes.closeIcon}`]: theme.mixins.setColorSvg(theme.palette.primary.main),
    },
    closeIcon: {
      ...theme.mixins.setColorSvg(theme.palette.primary.main),
      '&:hover': theme.mixins.setColorSvg(`${theme.palette.primary.main} !important`),
      fontSize: 14,
    },
    appBarCloseIcon: {
      ...theme.mixins.setColorSvg(theme.palette.primary.contrastText),
      fontSize: 14,
    },
    contentRoot: {
      ...theme.typography.body1,
      maxWidth: 720,
      padding: 0,
      height: 'fit-content',
      overflowY: 'visible',
    },
    dialogActions: {
      ...theme.typography.body1,
      padding: 0,
      marginTop: 30,
    },
    closeButton: {
      minWidth: 'auto',
      height: 14,
      margin: '0 0 15px 15px',
      marginLeft: 'auto',
      transform: 'translate(0, 50%)',
    },
    disclosureText: {
      ...theme.typography.body1Italics,
      textAlign: 'right',
      margin: '15px 0 0 0',
    },
    buttonsPanel: {
      margin: 'auto',
      maxWidth: '85%',
      display: 'flex',
      rowGap: 15,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        maxWidth: '100%',
      },
    },
    buttonWidth: {
      maxWidth: '100%',
    },
    button: {
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    textButton: {
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      [theme.breakpoints.up('md')]: {
        margin: '0px 20px 0px 20px',
      },
    },
  }),
);
