import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'TextItem' })(() => ({
  root: {},
  description: {
    '& > *': {
      marginBottom: 10,
      marginTop: 10,
    },
  },
  heading: {
    paddingTop: 40,
  },
  paddingBetween: {
    marginTop: 10,
  },
}));
