import { uuid } from '@ecp/utils/common';
import { useEffectOnce } from '@ecp/utils/react';

import { getCurrentPage, getDalSessionId, getInquiryId } from '@ecp/features/sales/shared/store';
import type { ThunkAction } from '@ecp/features/sales/shared/store/types';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import { addConversationStartedListener, addCustomAttributes } from '@ecp/features/shared/chat';

const sendSapiAnalyticsEvent: (traceId: string) => ThunkAction<Promise<void>> =
  (traceId) => async (dispatch, getState) => {
    const state = getState();
    const dalSessionId = getDalSessionId(state);
    const inquiryId = getInquiryId(state);
    const currentPage = getCurrentPage(state);
    const eventDetail = `pageName:${currentPage}, dalSessionId: ${dalSessionId}, inquiryId:${inquiryId}`;

    trackSapiAnalyticsEvent({
      element: 'choice.anyPage.chatButton',
      event: 'click',
      eventDetail,
      traceId,
    });
  };

/** For analytics to see where user opened the chat in the user journey */
export const useAnalytics = (): void => {
  const dispatch = useDispatch();

  useEffectOnce(() => {
    addConversationStartedListener(async () => {
      const traceId = uuid();
      dispatch(sendSapiAnalyticsEvent(traceId));
      addCustomAttributes({ actionTraceId: traceId });
    });
  });
};
