import { Typography } from '@mui/material';

import { NumberFormat, TooltipWithIcon } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { PrefillFlow } from '@ecp/features/sales/shared/constants';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { getCurrentFlows, useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

export interface MarketValueQuestionProps extends QuestionProps {
  noGroupLabel?: boolean;
}

export const MarketValueQuestion: React.FC<MarketValueQuestionProps> = (props) => {
  const {
    label = 'Approximate market value',
    trackingName = 'MarketValue',
    groupLabel = 'What’s the market value of your home?',
    dataTestId = 'marketValue',
  } = props;
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const marketValue = useHomePropertyField('marketValue');
  const flow = useSelector(getCurrentFlows);
  const isFlowShortHome = flow.home === PrefillFlow.SHORT;
  const groupMarketLabelToolTip = !isFlowShortHome && (
    <>
      <Typography variant='body4'>{groupLabel}</Typography>
      <TooltipWithIcon title='Approximate market value is the amount you would sell the home for. This can often be determined by an appraisal, tax record or by asking a real estate agent in your area.' />
    </>
  );

  useAddFields({ marketValue });

  if (!marketValue.exists) return null;

  return (
    <NumberFormat
      {...marketValue.props}
      groupLabel={groupMarketLabelToolTip}
      id='MarketValue'
      fullWidth={false}
      label={label}
      prefix='$'
      thousandSeparator
      decimalScale={0}
      data-testid={dataTestId}
      trackingName={trackingName}
    />
  );
};
