import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { getPrimaryInsuredStateCode } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { usePniFirstName, usePropertyField } from '../../utils';

export const HighRiskDogQuestion: React.FC<QuestionProps> = (props) => {
  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const { label: propsLabel, trackingName = 'high_risk_dog_button' } = props;

  const highRiskDog = usePropertyField('highRiskDogOnPremisesInd');

  const title =
    highRiskDog?.question.stateOptions?.[stateCode]?.title ??
    highRiskDog?.question.title ??
    propsLabel ??
    '';
  const label = usePniFirstName(title);

  const subtitle =
    highRiskDog?.question.stateOptions?.[stateCode]?.subtitle ?? highRiskDog?.question.subtitle;

  if (!highRiskDog) return null;

  return (
    <RadioGroupWithOptions
      {...highRiskDog.props}
      variant='yesNoButton'
      trackingName={trackingName}
      label={label}
      helperText={subtitle}
    />
  );
};
