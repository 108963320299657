import {
  isLineOfBusinessBundle,
  isProductAuto,
  LineOfBusiness,
} from '@ecp/features/shared/product';
import type { Product } from '@ecp/features/shared/product';

// Returns label based when Auto product fails in bundle sceanrio
export const getDialogCloseBtnText = (lineOfBusiness: string, product: Product): string => {
  if (!(isLineOfBusinessBundle(lineOfBusiness) && isProductAuto(product))) {
    return 'Close';
  }
  switch (lineOfBusiness) {
    case LineOfBusiness.BUNDLE:
      return 'Continue with home';
    case LineOfBusiness.BUNDLE_AUTO_RENTERS:
      return 'Continue with renters';
    default:
      return 'Close';
  }
};
