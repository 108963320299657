import type { FC, ReactNode } from 'react';

import { HoursList } from '@ecp/components';
import { ChatHelp } from '@ecp/features/sales/shared/chat';
import { Button, PhoneLink, Popover } from '@ecp/features/sales/shared/components';
import { getCurrentPage } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { partner } from '@ecp/partners';

import metadata from '../metadata';
import { useStyles } from './ContactUsPopover.styles';

export const ContactUsPopover: FC<{
  anchorEl: HTMLElement | null;
  onClose: () => void;
  open: boolean;
}> = ({ anchorEl, onClose, open }): ReactNode => {
  const { classes } = useStyles();
  const currentPage = useSelector(getCurrentPage);

  return (
    <Popover
      onClose={onClose}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      title='Licensed, expert agents can help.'
      showPointer
    >
      <div className={classes.popoverBody}>
        <PhoneLink
          analyticsEventDetail={currentPage}
          number={partner.shared.salesPhoneNumber}
          trackingLabel='phone_number'
          trackingName='top_phone_number_link'
          withUnderlinedLinkStyle
          variant='medium'
        />
        {partner.shared.salesPhoneHours && (
          <HoursList
            weeklyHours={partner.shared.salesPhoneHours}
            className={classes.contactHours}
          />
        )}
        <ChatHelp
          displayText='Live chat'
          trackingLabel='chat'
          trackingName='top_chat_button'
          variant='text'
        />
        {partner.shared.chatHours && (
          <HoursList weeklyHours={partner.shared.chatHours} className={classes.contactHours} />
        )}
        {partner.shared.salesEmailQuotesAddress && metadata.showEmailText && (
          <div className={classes.lastPopoverItem}>
            <Button
              variant='text'
              href={`mailto:${partner.shared.salesEmailQuotesAddress}`}
              target='_top'
            >
              {partner.shared.salesEmailQuotesAddress}
            </Button>
          </div>
        )}
      </div>
    </Popover>
  );
};
