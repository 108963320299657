import { IconUIMinus, IconUIPlus, makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<{ inDrawer?: boolean }>({
  name: 'PolicyStartDateForm',
})((theme, { inDrawer }) => ({
  autoCoverageStartDate: {
    ...theme.typography.h2,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    paddingBottom: 10,
    marginBottom: 20,
    paddingTop: 10,
  },
  dateInputField: {
    width: 'auto',
  },
  policyToolTip: {
    position: 'relative',
    padding: 0,
    marginTop: -1,
  },
  psdDatePicker: {
    paddingBottom: 10,
  },
  psdDatePickerText: {
    marginTop: -14,
  },
  accordionSummaryHeader: {
    '&.MuiAccordionSummary-root': {
      padding: 0,
      borderBottom: `4px solid ${theme.palette.primary.main}`,
      minHeight: 30,
    },
    '& .MuiAccordionSummary-content ': {
      marginTop: 0,
      marginBottom: 10,
    },

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded ': {
      transform: 'rotate(0deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper .MuiIconButton-label div ': {
      ...theme.typography.body1Bold,
    },
    '& .MuiAccordionSummary-expandIconWrapper.MuiIconButton-root ': {
      paddingTop: 0,
      paddingBottom: 10,
    },
    '& .MuiAccordionSummary-expandIconWrapper .MuiIconButton-label div:before ': {
      content: '"Expand "',
    },
    '& .MuiAccordionSummary-expandIconWrapper .MuiIconButton-label div:after ': {
      content: `url("${IconUIPlus}")`,
      position: 'relative',
      bottom: '-2px',
      marginLeft: 15,
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded .MuiIconButton-label div:before ': {
      content: '"Collapse"',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded .MuiIconButton-label div:after ': {
      content: `url("${IconUIMinus}")`,
      position: 'relative',
      bottom: 3,
      left: 3,
    },
  },
  accordionDetailsBkg: {
    background: theme.palette.grey[100],
    paddingRight: 20,
    margin: 0,
    width: 'auto',
    [theme.breakpoints.down('md')]: {
      paddingBottom: 20,
    },
  },
  startDateTextContainer: {
    marginBottom: 5,
  },
  datePickerContainer: {
    marginBottom: 20,
  },
  adjustmentToStartDateTxt: {
    ...theme.typography.body1Italics,
    color: theme.palette.text.tertiary,
  },
  accordionDetailsBkgTest: {
    background: theme.palette.grey[50],
  },
  policyTextTest: {
    color: theme.palette.common.black,
  },
  policyHelpText: {
    ...theme.typography.body1,
    color: theme.palette.text.secondary,
  },
  divider: {
    margin: '10px 0',
  },
  datePickerDialog: {
    '&.MuiModal-root': {
      zIndex: inDrawer ? 100003 : undefined,
    },
  },
  datePickerPopper: {
    '&.MuiPopper-root': {
      zIndex: inDrawer ? 100003 : undefined,
    },
  },
}));
