import { useCallback } from 'react';

import { Backdrop } from '@mui/material';

import { useStyles } from './Form.styles';

interface Props
  extends React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
  showBackdrop?: boolean;
  component?: React.ElementType;
}

export const Form: React.FC<Props> = (props) => {
  const {
    children,
    onSubmit,
    showBackdrop = false,
    component: Component = 'form',
    ...rest
  } = props;
  const { classes } = useStyles();

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (onSubmit) {
        onSubmit(e);
      }
    },
    [onSubmit],
  );

  return (
    <Component className={classes.root} {...rest} onSubmit={handleSubmit}>
      <Backdrop
        data-testid='formBackdrop'
        open={showBackdrop}
        invisible
        className={classes.backdrop}
        title='backdrop'
      />
      {children}
    </Component>
  );
};
