import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CompleteIcon' })((theme) => ({
  circle: {
    borderRadius: '50%',
    width: 24,
    height: 24,
  },
  complete: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.success.main,
  },
}));
