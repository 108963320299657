import { Fragment, useCallback } from 'react';

import { Link } from '@ecp/features/sales/shared/components';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';

import { downloadAndSaveDocuments } from '../../../state';
import type { DocumentForm } from '../../../types';
import { useStyles } from './CheckboxAcknowledgements.styles';

interface Props {
  documentForm: DocumentForm;
  isLast: boolean;
  selectedProduct: Product;
  trackingName?: string;
  trackingLabel?: string;
}

export const DocumentLink: React.FC<Props> = (props) => {
  const { documentForm, isLast, selectedProduct, trackingName, trackingLabel } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const handleDocumentDownloadClick = useCallback(async (): Promise<void> => {
    await dispatch(
      downloadAndSaveDocuments({
        product: selectedProduct,
        documentTypeName: documentForm.documentFormName,
        documentDisplayName: documentForm.documentDisplayName,
      }),
    );
  }, [dispatch, documentForm.documentFormName, documentForm.documentDisplayName, selectedProduct]);

  if (!documentForm) return null;

  return (
    <Fragment key={documentForm.documentFormName}>
      <Link
        className={classes.documentFormLink}
        component='button'
        onClick={handleDocumentDownloadClick}
        trackingName={trackingName}
        trackingLabel={trackingLabel}
      >
        {`${documentForm.documentDisplayName}${isLast ? '' : ','}`}
      </Link>{' '}
    </Fragment>
  );
};
