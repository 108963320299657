import type { BaseMetadataCollection } from '@ecp/types';
export type ProductBaseMetadaCollection = {
  [productKey: string]: BaseMetadataCollection;
};
export const AutoPolicyRatingCriteriaMetadata: ProductBaseMetadaCollection = {
  'amfam.auto': {
    POLICY_EFFECTIVE_DATE: {
      title: 'Effective date',
    },
    POLICY_TERM: {
      title: 'Policy term',
    },
    PRIOR_CARRIER: {
      title: 'Prior carrier name',
    },
    PRIOR_CARRIER_BI: {
      title: 'Prior carrier BI Limit',
    },
    PRIOR_CARRIER_LENGTH: {
      title: 'Current carrier length',
    },
    VIOLATION_TYPE: {
      title: 'Violation type',
    },
    DATE_OCCURRED: {
      title: 'Date of occcurrence',
    },
    POLICY_DISCOUNTS: {
      title: 'Policy discounts',
    },
    FOREIGN_LICENSE_COUNTRY: {
      title: 'When international DL is selected THEN  Enter foreign DL Country',
    },
    LENGTH_OF_RESIDENCE: {
      title: 'Length of Residence',
    },
  },
  'connect.auto': {
    EffectiveDate: {
      title: 'Effective date',
    },
    PolicyTerm: {
      title: 'Policy term',
    },
    PriorCarrierName: {
      title: 'Prior carrier name',
    },
    PriorBILimit: {
      title: 'Prior carrier BI Limit',
    },
    Discounts: {
      title: 'Policy discounts',
    },
    LENGTH_OF_RESIDENCE: {
      title: 'Length of Residence',
    },
  },
};

export const DriverRatingCriteriaMetadata: ProductBaseMetadaCollection = {
  'amfam.auto': {
    FIRST_LAST_NAME: {
      title: 'First and last name',
    },
    AGE: {
      title: 'Age',
    },
    MARITAL_STATUS: {
      title: 'Marital status',
    },
    GENDER: {
      title: 'Gender',
    },
    YEARS_DRIVING_EXP: {
      title: 'Years of driving experience',
    },
    DRIVER_DISCOUNTS: {
      title: 'Driver discounts',
    },
  },
  'connect.auto': {
    FullName: {
      title: 'First and last name',
    },
    Age: {
      title: 'Age',
    },
    MartialStatus: {
      title: 'Marital status',
    },
    Gender: {
      title: 'Gender',
    },
    DrivingExperienceYears: {
      title: 'Years of driving experience',
    },
  },
};

export const VehicleRatingCriteriaMetadata: ProductBaseMetadaCollection = {
  'amfam.auto': {
    VEHICLE_MAKE: {
      title: 'Make',
    },
    VEHICLE_MODEL: {
      title: 'Model',
    },
    VEHICLE_SERIES: {
      title: 'Series',
    },
    VEHICLE_USAGE: {
      title: 'Vehicle usage',
    },
    VEHICLE_MILEAGE: {
      title: 'Vehicle mileage',
    },
    VEHICLE_YEAR: {
      title: 'Year',
    },
    VEHICLE_DISCOUNTS: {
      title: 'Vehicle discounts',
    },
    VEHICLE_OWNERSHIP_DATE: {
      title: 'Ownership timeframe',
    },
  },
  'connect.auto': {
    YearMakeModelSeries: {
      title: 'Year Make Model Series',
    },
    Usage: {
      title: 'Vehicle usage',
    },
    Mileage: {
      title: 'Vehicle mileage',
    },
  },
};

export const IncidentRatingCriteriaMetadata: ProductBaseMetadaCollection = {
  'amfam.auto': {
    DRIVER_NAME: {
      title: 'Driver name',
    },
    VIOLATION_TYPE: {
      title: 'Violation type',
    },
    DATE_OCCURRED: {
      title: 'Date of occurrence',
    },
  },
  'connect.auto': {
    DRIVER_NAME: {
      title: 'Driver name',
    },
    VIOLATION_TYPE: {
      title: 'Violation type',
    },
    DATE_OCCURRED: {
      title: 'Date of occurrence',
    },
  },
};
