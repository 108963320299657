import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const AirConditioningQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const airConditioning = useHomePropertyField('airConditioning');
  const { label = airConditioning.question?.title, trackingName = 'home_central_air_selection' } =
    props;

  useInitValues({ [airConditioning.key]: false });

  useAddFields({ airConditioning });

  if (!airConditioning.exists) return null;

  return (
    <RadioGroupWithOptions
      {...airConditioning.props}
      id='AirConditioning'
      label={label}
      variant='yesNoButton'
      trackingName={trackingName}
    />
  );
};
