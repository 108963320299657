import { useCallback } from 'react';

import { GridItem, TooltipWithIcon, useShowMoreOrLess } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import type { AnswerValue } from '@ecp/features/sales/shared/types';

export interface RoofShapeQuestionProps extends QuestionProps {
  avoidChildQuestion?: boolean;
}

export const RoofShapeQuestion: React.FC<RoofShapeQuestionProps> = (props) => {
  const useRoofPropertyField = useFieldWithPrefix('property.roof', 'property.roof');
  const roofShape = useRoofPropertyField('shape');
  const roofAnyPortionFlat = useRoofPropertyField('anyPortionFlat');
  const {
    label = roofShape.question?.title,
    trackingName = 'roof_shape_selection',
    dataTestId = 'RoofShapeButton',
    avoidChildQuestion = false,
  } = props;
  const FLAT_ROOF_HELP_TEXT =
    'A flat roof has a roof pitch that is less than 11 degrees. Most flat roofs are constructed of rubber, foam or tar and gravel.';
  useAddFields({ roofShape, roofAnyPortionFlat });

  const {
    showMore: showMoreRoofShape,
    displayOptions: displayRoofShape,
    handleClick: handleRoofShapeShowMoreOrLess,
  } = useShowMoreOrLess(roofShape.props.options, 4, 'roof_shape_more_choices');

  let flatShapeValue;
  if (roofShape.props.options && roofShape.props.options.length > 0) {
    const flat = roofShape.props.options.find((option) => option.label === 'Flat');
    flatShapeValue = flat ? flat.value : '-1';
  }

  // in case of GMI we don't need to show "Is any portion of roof flat" question.
  // currently SAPI doesn't have provision to ignore this sub question unless the roof shape is flat.
  // so avoiding this question for GMI by defaulting it to false, no matter what roof shape is.
  const handleAnyPortionFlatQuestion = useCallback(
    (value: AnswerValue) => {
      roofShape.validateUpdateAndPatch(value);
      if (avoidChildQuestion) {
        roofAnyPortionFlat.validateUpdateAndPatch(false);
      }
    },
    [roofShape, avoidChildQuestion, roofAnyPortionFlat],
  );

  return (
    <div>
      {roofShape.exists && (
        <RadioGroupWithOptions
          {...roofShape.props}
          options={displayRoofShape}
          label={label}
          trackingName={trackingName}
          showMoreOrLessProps={{
            showMore: showMoreRoofShape,
            onClick: handleRoofShapeShowMoreOrLess,
            groupTestId: 'RoofShapeMoreChoices',
          }}
          actionOnComplete={handleAnyPortionFlatQuestion}
          dataTestId={dataTestId}
          id='RoofShape'
          cardSize='small'
        />
      )}
      {!avoidChildQuestion &&
        roofAnyPortionFlat.exists &&
        roofShape.value &&
        roofShape.value !== flatShapeValue && (
          <GridItem topSpacing='lg' xs={12}>
            <RadioGroupWithOptions
              {...roofAnyPortionFlat.props}
              label={
                <>
                  Is any portion of the roof flat?
                  <TooltipWithIcon title={FLAT_ROOF_HELP_TEXT} />
                </>
              }
              id='RoofAnyPortionFlat'
              variant='yesNoButton'
              trackingName='RoofSectionFlatButton'
              dataTestId='RoofSectionFlatButton'
              cardSize='small'
            />
          </GridItem>
        )}
    </div>
  );
};
