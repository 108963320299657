import { uuid } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';

import { env } from '@ecp/env';

import type { ConfigRequest } from './types';

export const fetchConfig = async <T extends object, U>(
  configRequest: ConfigRequest<T>,
): Promise<U> => {
  const requestUrl = `${env.configApiRoot}/result`;
  const requestId = uuid();
  const response = await fetch(requestUrl, {
    headers: {
      'Content-Type': 'application/json',
      'X-Request-Id': requestId,
    },
    body: JSON.stringify(configRequest),
    mode: 'cors',
    method: 'POST',
  });

  const { status, statusText } = response;
  if (status !== 200) {
    datadogLog({
      logType: 'error',
      message: 'Error calling Config API',
      context: {
        logOrigin: 'libs/utils/config/src/config/api.ts',
        functionOrigin: 'fetchConfig',
        requestUrl,
        requestId,
        responseStatus: status,
        statusText: statusText,
      },
    });

    throw new Error(`Exception calling Config Api - code: [${status}] - reason - [${statusText}]`);
  }

  return response.json();
};
