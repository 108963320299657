export interface Metadata {
  shouldRenderTitle?: boolean;
  skipFetchAcknowledgement?: boolean;
  skipFetchPostBindQuestions?: boolean;
  showAcknowledgement?: boolean;
  showCarrierMessaging?: boolean;
  collectRecurringPay?: boolean;
  hideContactInfo?: boolean;
}

const metadata: Metadata = {
  shouldRenderTitle: true,
  showAcknowledgement: true,
  showCarrierMessaging: true,
};

export default metadata;
