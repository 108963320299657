import { useEffect } from 'react';

import { NavStatus } from '@ecp/features/sales/shared/constants';
import { useNavigateToNextPage } from '@ecp/features/sales/shared/routing';
import {
  getIsPrefillCompleted,
  getPrimaryInsuredPersonLock,
  updatePageStatus,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { IconCardProtection, IconCardProtectionImageUrl } from '@ecp/themes/base';

import { imageUrls, useStyles } from './TransitionPage.styles';
import { useTransitionPage } from './utils';

export const TransitionPage: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const navigateToNext = useNavigateToNextPage();
  const firstRowImageUrls = imageUrls.slice(0, 12);
  const secondRowImageUrls = imageUrls.slice(12);
  const primaryInsuredPersonLock = useSelector(getPrimaryInsuredPersonLock);
  const isPrefillCompleted = useSelector(getIsPrefillCompleted);
  useTransitionPage();
  useEffect(() => {
    if (primaryInsuredPersonLock && isPrefillCompleted) {
      dispatch(updatePageStatus(NavStatus.VALID));
      navigateToNext();
    }
  }, [dispatch, navigateToNext, isPrefillCompleted, primaryInsuredPersonLock]);

  function renderRow(imageUrls: string[]): JSX.Element {
    return (
      <>
        <span className={classes.marqueeSpan}>
          {imageUrls.map((url, index) => renderImages(url, index))}
        </span>
        <span className={classes.marqueeSpan}>
          {imageUrls.map((url, index) => renderImages(url, index))}
        </span>
      </>
    );
  }

  function renderImages(url: string, index: number): JSX.Element {
    if (url === IconCardProtectionImageUrl) {
      return <IconCardProtection className={classes.cardProtectionImage} key={index} />;
    } else {
      return <img className={classes.image} src={url} key={index} alt={`${url}`} />;
    }
  }

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>Great choice!</h1>
      <h2 className={classes.subTitle}>Gathering info about your home now...</h2>
      <div className={classes.marqueeWrapper}>
        <div className={classes.marqueeContainer}>
          <div className={classes.marqueeBlock}>
            <div className={classes.topRow}>{renderRow(firstRowImageUrls)}</div>
          </div>
          <div className={classes.marqueeBlock}>
            <div className={classes.bottomRow}>{renderRow(secondRowImageUrls)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
