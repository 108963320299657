export const MAX_NUM_VEHICLES = 6;
export const MAX_NUM_DRIVERS = 5;
export const MAX_NUM_HEATING_SOURCES = 9;

export const COVERAGES_LEGAL_TEXT =
  'All coverages are subject to the terms, conditions, special limits, and exclusions of your policy and all applicable endorsements. In the event of any conflict between the policy and this coverage summary, the provisions of the policy shall prevail.';

export const NATURAL_NUMBER_REGEX = /^-?\d+$/;

export const STATE_CODE_PREFIX = 'STATE_CODE.';

export const PARTNER_EXPERIENCE_ID = 'partner.experienceId';

export const BINDABLE_BRIDGEOVER = 'static.bindableBridgeOver';

export const INDICATIVE_BRIDGEOVER = 'static.indicativeBridgeOver';

/** CSUI-1068 */
// NOTE: this regex works for both v3 and v4 form of the key
// e.g. connect.auto.delta.person.123.isSecondaryNamedInsured
// and delta.person.123.isSecondaryNamedInsured
// (\b demands that delta is the start of the string, or that any character
// before it is not \w)
export const SNI_REGEX = /\bdelta\.person\.([^.]+)\.isSecondaryNamedInsured$/;

export const REF_REGEX = /\.\d[^.]*./;

export const ENTITY_REF_REGEX = /([a-zA-z]+\.)+\d[a-zA-Z]\w{6}/;

export const enum PaymentPlan {
  FULL_PREMIUM = 'fullPremium',
  MONTHLY_PREMIUM = 'monthlyPremium',
}
