import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

export const EnclosedEavesQuestion: React.FC<QuestionProps> = (props) => {
  const { label = 'Does your home have enclosed eaves?', trackingName = 'have_enclosed_eaves' } =
    props;
  const enclosedEaves = useField('property.enclosedEavesInd');

  if (!enclosedEaves.exists) return null;

  return (
    <RadioGroupWithOptions
      {...enclosedEaves.props}
      id='enclosedEaves'
      label={label}
      variant='yesNoButton'
      trackingName={trackingName}
    />
  );
};
