import * as constants from '@ecp/features/sales/shared/constants';
import { LineOfBusiness } from '@ecp/features/shared/product';
import {
  IconCardCentralDirectBurglarAlarm,
  IconCardCentralDirectFireAlarm,
  IconCardIndoorSprinklers,
  IconCardPartnerDiscount,
} from '@ecp/themes/base';
import type { CardOption } from '@ecp/types';

import { DISCOUNT_PROPERTY_HOME_BUYER } from './metadata.js';

const DISCOUNT_PROPERTY_CENTRAL_OR_DIRECT_ALARM_BURGLAR: CardOption = {
  value: constants.DISCOUNT_PROPERTY_CENTRAL_OR_DIRECT_ALARM_BURGLAR,
  label: 'Central/direct burglar alarm',
  icon: <IconCardCentralDirectBurglarAlarm />,
  helpText: 'Directly alerts a third party monitoring company of a possible intrusion.',
};

const DISCOUNT_PROPERTY_CENTRAL_OR_DIRECT_ALARM_FIRE: CardOption = {
  value: constants.DISCOUNT_PROPERTY_CENTRAL_OR_DIRECT_ALARM_FIRE,
  label: 'Central/direct fire alarm',
  icon: <IconCardCentralDirectFireAlarm />,
  helpText: 'Directly alerts a third party monitoring company of a possible fire.',
};

const DISCOUNT_PROPERTY_INDOOR_SPRINKLER: CardOption = {
  value: constants.DISCOUNT_PROPERTY_INDOOR_SPRINKLER,
  label: 'Indoor sprinkler system',
  icon: <IconCardIndoorSprinklers />,
  helpText:
    'May apply if a properly maintained interior sprinkler system (fire suppression system) is present in the home.',
};

export const DISCOUNT_PARTNER_AUTO_POLICY: CardOption = {
  value: constants.DISCOUNT_PARTNER_AUTO_POLICY,
  label: 'Progressive Auto policy',
  icon: <IconCardPartnerDiscount />,
  helpText:
    'If you have an auto policy with Progressive, or you plan to, you may qualify for a discount. Any available discounts will be calculated in your quote. Discounts may vary by state.',
};

export const discountOptions: Record<LineOfBusiness, CardOption[]> = {
  [LineOfBusiness.AUTO]: [],
  [LineOfBusiness.HOME]: [
    DISCOUNT_PROPERTY_HOME_BUYER,
    DISCOUNT_PROPERTY_CENTRAL_OR_DIRECT_ALARM_BURGLAR,
    DISCOUNT_PROPERTY_CENTRAL_OR_DIRECT_ALARM_FIRE,
    DISCOUNT_PROPERTY_INDOOR_SPRINKLER,
    DISCOUNT_PARTNER_AUTO_POLICY,
  ],
  [LineOfBusiness.RENTERS]: [],
  [LineOfBusiness.BUNDLE]: [],
  [LineOfBusiness.BUNDLE_AUTO_RENTERS]: [],
};
