import { createReducer } from '@reduxjs/toolkit';

import type { Answers } from '@ecp/features/sales/shared/types';

import {
  deleteKeyFromPostBindAnswers,
  deleteKeysFromPostBindAnswers,
  setPostBindApiGetSuccess,
  setPostBindApiPostError,
  setPostBindApiPostSuccess,
  updatePostBindAnswers,
  updatePostBindQuestions,
} from './actions';
import type { PostBindState } from './types';

const initPostBindState: PostBindState = {
  offerSetId: '',
  questions: {},
  answers: {},
  errors: [],
};

export const postbindReducer = createReducer(initPostBindState, (builder) => {
  builder
    .addCase(setPostBindApiGetSuccess, (state, { payload }) => {
      const { offerSetId, questions } = payload.data;
      state.offerSetId = offerSetId;
      state.questions = questions;
    })
    .addCase(setPostBindApiPostSuccess, (state) => {
      state.errors = [];
    })
    .addCase(setPostBindApiPostError, (state, { payload }) => {
      const { errors } = payload;
      state.errors = errors;
    })
    .addCase(deleteKeyFromPostBindAnswers, (state, { payload: key }) => {
      delete state.answers[key];
    })
    .addCase(updatePostBindAnswers, (state, { payload }) => {
      state.answers = { ...state.answers, ...payload };
    })
    .addCase(updatePostBindQuestions, (state, { payload }) => {
      state.questions = { ...state.questions, ...payload };
    })
    .addCase(deleteKeysFromPostBindAnswers, (state, { payload: ref }) => {
      const { answers } = state;
      state.answers = Object.keys(answers).reduce((obj, key) => {
        if (!key.startsWith(ref)) obj[key] = answers[key];

        return obj;
      }, {} as Answers);
    })
    .addMatcher(
      (action): action is { type: '@global/teardownStore' } =>
        action.type === '@global/teardownStore',
      () => initPostBindState,
    );
});
