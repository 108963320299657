import { GridItem } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { RadioGroupWithOptions, Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const RoofClassQuestion: React.FC<QuestionProps> = (props) => {
  const useRoofPropertyField = useFieldWithPrefix('property.roof', 'property.roof');
  const roofClass = useRoofPropertyField('roofClass');
  const roofClassDocument = useRoofPropertyField('roofClassDocumentProvidedInd');

  const {
    trackingName = 'roof_class_drop_down',
    groupLabel = roofClass?.question?.title,
    label = roofClassDocument.question?.title,
    trackingLabel = 'roof_class_document_button',
  } = props;

  useInitValues({ [roofClass.key]: roofClass.question.defaultValue });

  const displayRoofClassDocument =
    roofClass.exists &&
    roofClass.props.value &&
    roofClass.props.value !== 'NA' &&
    roofClassDocument.exists;

  useAddFields({ roofClass, roofClassDocument });

  if (!roofClass.exists) return null;

  return (
    <>
      <GridItem topSpacing='lg' xs={12}>
        <Select
          {...roofClass.props}
          fullWidth={false}
          id='roofClass'
          groupLabel={groupLabel}
          trackingName={trackingName}
          inputButtonAriaLabel='roofClass'
        />
      </GridItem>

      {displayRoofClassDocument && (
        <GridItem topSpacing='lg' xs={12}>
          <RadioGroupWithOptions
            {...roofClassDocument.props}
            id='roofClassDocument'
            label={label}
            variant='yesNoButton'
            trackingName={trackingLabel}
          />
        </GridItem>
      )}
    </>
  );
};
