import { useEffect } from 'react';

import { lowerFirst } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';

import type { PaymentPlan } from '@ecp/features/sales/shared/constants';
import { PurchaseErrorReason, PurchaseStatusCode } from '@ecp/features/sales/shared/constants';
import type { ThunkAction } from '@ecp/features/sales/shared/store/types';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';
import { getReducedProductNameFromProduct } from '@ecp/features/shared/product';

import {
  fetchAcknowledgementResponse,
  getPurchaseRequest,
  postPurchase,
  pushPaymentOption,
  setAccountNumber,
  setCardNumber,
  setExpirationDate,
  setName,
  setRoutingNumber,
} from '../../state';
import type { CheckoutOffers, PaymentFields, PaymentMethods } from '../../types';
import metadata from './metadata';

const hasError = (result: { error?: unknown } | null): result is { error?: unknown } =>
  Boolean(result && 'error' in result);

/** @returns whether or not all payment options succeeded */
export const postAllPaymentOptions =
  ({
    dalSessionId,
    offers,
    payments,
  }: {
    dalSessionId: string;
    offers: CheckoutOffers;
    payments: PaymentMethods;
  }): ThunkAction<Promise<boolean>> =>
  async (dispatch) => {
    const pushPaymentOptions = Object.entries(offers).map(([reducedProductName, offer]) => {
      const payment = payments[reducedProductName];
      const monthlyPayPaymentPlan = reducedProductName === 'auto' ? 'Six Months' : 'Twelve Months';
      if (offer && payment?.paymentType && payment?.paymentPlan)
        return dispatch(
          pushPaymentOption({
            dalSessionId,
            product: offer.selectedProduct,
            paymentType: payment.paymentType,
            paymentPlan:
              payment.paymentPlan === 'Custom' ? monthlyPayPaymentPlan : payment.paymentPlan,
          }),
        );

      return null;
    });

    let missingCreditCardToken = false;
    const results = await Promise.all(pushPaymentOptions);
    results.forEach((result) => {
      if (result?.response === PurchaseErrorReason.MISSING_CREDITCARD_TOKEN_ERROR)
        missingCreditCardToken = true;
      if (hasError(result)) {
        datadogLog({
          logType: 'error',
          message: `Error posting payment options - ${result.error?.message}`,
          context: {
            logOrigin: 'libs/features/sales/checkout/src/forms/CheckoutForm/util.ts',
            contextType: 'Purchase Error',
            functionOrigin: 'handleSubmit',
            message: result.error?.message,
          },
          error: result.error,
        });
      }
    });
    const didAllResultsSucceed = !results.some(hasError) && !missingCreditCardToken;

    return didAllResultsSucceed;
  };

// TODO Should we run these in parallel? Should we signify if requests failed?
export const purchase =
  ({
    dalSessionId,
    offerSetId,
    offers,
    products,
    skipFetchPostBindQuestions,
  }: {
    dalSessionId: string;
    offerSetId: string;
    offers: CheckoutOffers;
    products: Product[];
    skipFetchPostBindQuestions?: boolean;
  }): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();

    let timeout = 29000;
    let purchaseRequest = getPurchaseRequest(state, products);
    const checkoutResponse = await dispatch(
      postPurchase({
        dalSessionId,
        offerSetId,
        purchaseRequest,
        products,
        timeout,
        skipFetchPostBindQuestions,
      }),
    );

    const purchasedProducts: Product[] = [];

    if (checkoutResponse?.response) {
      for (const offer of Object.values(offers)) {
        if (
          checkoutResponse.response.policies?.[offer.selectedProduct].purchaseStatus ===
          PurchaseStatusCode.COMPLETE
        ) {
          purchasedProducts.push(offer.selectedProduct);
        } else if (
          checkoutResponse.response.policies?.[offer.selectedProduct].purchaseStatus ===
          PurchaseStatusCode.INCOMPLETE
        ) {
          timeout /= 2;
          purchaseRequest = getPurchaseRequest(state, [offer.selectedProduct]);
          const checkoutResponseForIndividualProduct = await dispatch(
            postPurchase({
              dalSessionId,
              offerSetId,
              purchaseRequest,
              products,
              timeout,
              skipFetchPostBindQuestions,
            }),
          );
          if (
            checkoutResponseForIndividualProduct?.response?.policies?.[offer.selectedProduct]
              .purchaseStatus === PurchaseStatusCode.COMPLETE
          ) {
            purchasedProducts.push(offer.selectedProduct);
          }
          // TODO I don't think this is doing anything; This is an artifact from CheckoutForm, see git history.
          const newPolicy =
            checkoutResponseForIndividualProduct?.response?.policies?.[offer.selectedProduct];
          if (newPolicy) checkoutResponse.response.policies[offer.selectedProduct] = newPolicy;
        }
      }
    }

    if (!metadata.skipFetchAcknowledgement && purchasedProducts.length) {
      await dispatch(
        fetchAcknowledgementResponse({
          dalSessionId,
          products: purchasedProducts,
          category: 'reminders',
        }),
      );
    }
  };

const FIELD_KEYS_TO_RESET = [
  'creditCardCardNumber',
  'creditCardExpirationDate',
  'creditCardFullName',
  'costcoCardNumber',
  'costcoCardExpirationDate',
  'costcoCardFullName',
  'routingNumber',
  'accountNumber',
] as const;

// TODO - Clean up Mortgage fields for only home products
const cleanUpPaymentFields =
  ({
    products,
    fields,
    payments,
  }: {
    products: Product[];
    fields: PaymentFields;
    payments: PaymentMethods;
  }): ThunkAction<Promise<(void[] | undefined)[]>> =>
  async (dispatch) =>
    Promise.all(
      products.map((product) => {
        const reducedProductName = getReducedProductNameFromProduct(product);
        const paymentFields = fields[reducedProductName];
        const paymentMethod = payments[reducedProductName];
        if (!paymentFields || !paymentMethod) return undefined;

        dispatch(
          setCardNumber({
            cardNumber: '',
            product,
            type: lowerFirst(paymentMethod.paymentType) as 'creditCard',
          }),
        );
        dispatch(
          setName({
            fullName: '',
            product,
            type: lowerFirst(paymentMethod.paymentType) as 'creditCard',
          }),
        );
        dispatch(
          setExpirationDate({
            expirationDate: '',
            product,
            type: lowerFirst(paymentMethod.paymentType) as 'creditCard',
          }),
        );
        dispatch(
          setAccountNumber({
            product,
            accountNumber: '',
          }),
        );
        dispatch(
          setRoutingNumber({
            product,
            routingNumber: '',
          }),
        );

        return Promise.all(
          FIELD_KEYS_TO_RESET.map((key) => paymentFields[key]?.props.actionOnComplete('')),
        );
      }),
    );

/**
 * This hook uses a cleanup function to clear the payment fields when the CheckoutForm component is unmounted.
 * That is on Checkout form submit, on back navigation, on page refresh.
 * This keeps it consistent with the store since the info is reset when the user navigates to the payment method page.
 */
export const useCleanUpPaymentFieldsOnUnmount = (
  ...args: Parameters<typeof cleanUpPaymentFields>
): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(cleanUpPaymentFields(...args));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const createTrackingLabel = ({
  offers,
  premiumPlan,
}: {
  offers: CheckoutOffers;
  premiumPlan: PaymentPlan;
}): string => {
  const data = Object.values(offers).map(
    (offer) => `product:${offer.selectedProduct}, quoteNumber:${offer.offer.details.quoteNumber}`,
  );

  return `${data}`;
};
