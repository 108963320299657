import { emptyObject } from '@ecp/utils/common';

import { parseSearchParams } from './searchParams';
import type { Location, To } from './types';

const getPathnameFromTo = (to: Partial<To> | string): string => {
  if (typeof to === 'object') return to.pathname || '';

  return to.split(/\?|#/)[0] || '';
};

const getSearchParamsFromTo = (to: Partial<To> | string): Location['search'] => {
  if (typeof to === 'object') {
    if (typeof to.search === 'object') return to.search;
    if (to.search) return parseSearchParams(to.search);
  }

  if (typeof to === 'string') {
    if (to.indexOf('?') < 0) return emptyObject;

    return parseSearchParams(to.split(/\?|#/)[1]);
  }

  return emptyObject;
};

const getHashFromTo = (to: Partial<To> | string): string => {
  if (typeof to === 'object') return to.hash || '';
  if (to.indexOf('#') < 0) return '';

  return to.split('#')[1] || '';
};

/** Converts `to` into an object for custom route matching. */
export const buildLocationFromTo = (
  to: Partial<To> | string = '',
): Omit<Location, 'parameters'> => ({
  pathname: getPathnameFromTo(to),
  search: getSearchParamsFromTo(to),
  hash: getHashFromTo(to),
});
