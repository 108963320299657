import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

export const FireResistantVentsQuestion: React.FC<QuestionProps> = (props) => {
  const {
    label = 'Does your home have fire-resistant vents?',
    trackingName = 'have_fire_resistant_vents',
  } = props;
  const fireResistantVents = useField('property.fireResistantVentsInd');

  if (!fireResistantVents.exists) return null;

  return (
    <RadioGroupWithOptions
      {...fireResistantVents.props}
      id='fireResistantVents'
      label={label}
      variant='yesNoButton'
      trackingName={trackingName}
    />
  );
};
