import { Dialog } from '@ecp/features/sales/shared/components';
import { getPrimaryInsuredStateCode } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';

import { RecurringPaymentCompanyName, RecurringPaymentMetadata } from '../../../metadata';
import type { PaymentOption } from '../../../types';
import { useStyles } from './RecurringPaymentInfoDialog.styles';

interface Props {
  product: Product;
  paymentType: PaymentOption['paymentType'];
  open: boolean;
  onClose(): void;
  trackingNameClose?: string;
  trackingLabelClose?: string;
  trackingNameX?: string;
  trackingLabelX?: string;
}

export const RecurringPaymentInfoDialog: React.FC<Props> = (props) => {
  const {
    product,
    paymentType,
    open,
    onClose,
    trackingNameClose,
    trackingLabelClose,
    trackingNameX,
    trackingLabelX,
  } = props;
  const { classes } = useStyles();

  const stateCode = useSelector(getPrimaryInsuredStateCode);

  let companyName = '';

  if (RecurringPaymentCompanyName[product]) {
    // check if we should update company name based on state
    companyName =
      RecurringPaymentCompanyName[product]?.stateOptions?.[stateCode]?.name ||
      RecurringPaymentCompanyName[product].name;
  }
  const recurringPaymentMetadata = RecurringPaymentMetadata[paymentType][product](
    companyName,
    stateCode,
  );

  return (
    <Dialog
      className={classes.root}
      actionButtonLabel='Close'
      titleText='Payment information disclosure'
      open={open}
      onClose={onClose}
      trackingNameCloseIcon={trackingNameX}
      trackingLabelCloseIcon={trackingLabelX}
      trackingNameButton={trackingNameClose}
      trackingLabelButton={trackingLabelClose}
      buttonPlacement='right'
    >
      <div className={classes.dialogBody}>{recurringPaymentMetadata}</div>
    </Dialog>
  );
};
