import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'SubtitleTextCard' })((theme) => ({
  text: {
    ...theme.typography.subtitle,
    marginTop: 40,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: theme.typography.body4,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
  },
}));
