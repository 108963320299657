import type { SalesResponse } from '@ecp/features/sales/shared/utils/network';
import { salesRequest } from '@ecp/features/sales/shared/utils/network';
import type { Product } from '@ecp/features/shared/product';

import type { Acknowledgement } from '../../types';
import type {
  AcknowledgementResponse,
  AcknowledgementsPostRequestBody,
  AcknowledgementsRequest,
  AcknowledgementsResponse,
} from './types';

export interface AcknowledgementMap {
  [category: string]: {
    key: string;
    pniSignatureText?: string;
    sniSignatureText?: string;
  };
}

// !TODO Revisit Request body shape and use type for it in postAcknowledgement
export interface AcknowledgementRequest {
  acknowledgementMap: AcknowledgementMap;
}

export const postAcknowledgement = ({
  dalSessionId,
  product,
  categoryAcknowledgementsMap,
  signatureText,
  pniSignatureText,
  sniSignatureText,
  signatureMethod,
  signatureMethodEmails,
}: {
  dalSessionId: string;
  product: Product;
  categoryAcknowledgementsMap: Record<string, Acknowledgement[]>;
  signatureText?: string;
  pniSignatureText?: string;
  sniSignatureText?: string;
  signatureMethod?: string;
  signatureMethodEmails: Record<string, string>;
}): Promise<SalesResponse<AcknowledgementResponse>> => {
  // Filter acknowledgements based on the signature method
  const filterAcknowledgements = (acknowledgement: Acknowledgement): boolean => {
    if (['REMOTE_CLICK_SIGN', 'IN_PERSON_CLICK_SIGN'].includes(acknowledgement.key)) {
      return acknowledgement.key === signatureMethod;
    }

    return !(
      ['REMOTE_CLICK_SIGN', 'IN_PERSON_CLICK_SIGN'].includes(acknowledgement.key) &&
      acknowledgement.key !== signatureMethod
    );
  };

  // Map acknowledgements to the request body format
  const mapAcknowledgements = (
    acknowledgement: Acknowledgement,
  ): AcknowledgementsPostRequestBody => {
    if (['REMOTE_CLICK_SIGN', 'IN_PERSON_CLICK_SIGN'].includes(acknowledgement.key)) {
      return {
        key: acknowledgement.key,
        value: acknowledgement.value,
        personId: acknowledgement.personId || undefined,
        email: acknowledgement.personId
          ? signatureMethodEmails[acknowledgement.personId]
          : undefined,
      };
    }

    return {
      key: acknowledgement.key,
      value: acknowledgement.value,
      signatureText: ['Signature', 'SignatureMethod'].includes(acknowledgement.acknowledgementType)
        ? signatureText
        : undefined,
      pniSignatureText: ['Signature', 'SignatureMethod'].includes(
        acknowledgement.acknowledgementType,
      )
        ? pniSignatureText
        : undefined,
      sniSignatureText: ['Signature', 'SignatureMethod'].includes(
        acknowledgement.acknowledgementType,
      )
        ? sniSignatureText
        : undefined,
    };
  };

  const acknowledgementMap: Record<
    string,
    { acknowledgements: AcknowledgementsPostRequestBody[] }
  > = Object.keys(categoryAcknowledgementsMap).reduce((acc, category) => {
    const acknowledgementsForCategory = categoryAcknowledgementsMap[category]
      .filter(filterAcknowledgements)
      .map(mapAcknowledgements);

    acc[category] = { acknowledgements: acknowledgementsForCategory };

    return acc;
  }, {} as Record<string, { acknowledgements: AcknowledgementsPostRequestBody[] }>);

  const body = JSON.stringify({ acknowledgementMap });

  return salesRequest({
    endpoint: `session/${dalSessionId}/offers/acknowledgements?product=${product}`,
    options: {
      body,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
};

export const getAcknowledgements = ({
  dalSessionId,
  category,
  products,
}: AcknowledgementsRequest): Promise<SalesResponse<AcknowledgementsResponse>> => {
  const params: string = products.map((x) => `product=${x}`).join('&');

  return salesRequest({
    endpoint: `session/${dalSessionId}/offers/acknowledgements${
      category ? `/${category}` : ''
    }?${params}`,
    options: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
};
