import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

export const MultiPaneWindowsQuestion: React.FC<QuestionProps> = (props) => {
  const {
    label = 'Does your home have multi-pane windows or functional shutters?',
    trackingName = 'have_multipane_windows_or_functional_shutters',
  } = props;
  const multiPaneWindows = useField('property.multiPaneWindowsInd');

  if (!multiPaneWindows.exists) return null;

  return (
    <RadioGroupWithOptions
      {...multiPaneWindows.props}
      id='multiPaneWindows'
      label={label}
      variant='yesNoButton'
      trackingName={trackingName}
    />
  );
};
