import { createSelector } from '@reduxjs/toolkit';
import createCachedSelector from 're-reselect';

import { castAnswerType, ensureStringArray, merge } from '@ecp/utils/common';
import { scrollToElement } from '@ecp/utils/web';

import {
  deleteKeyFromPostBindAnswers,
  getPostBindQuestion,
  getQuestionPostBindNoDefault,
  updatePostBindAnswers,
} from '@ecp/features/sales/checkout';
import {
  INCIDENT_REF_SUFFIX,
  MILITARY_DEPLOYMENTS_REF_SUFFIX,
  PRIOR_INSURANCE_REF_SUFFIX,
} from '@ecp/features/sales/quotes/auto';
import {
  DRIVERS_REF,
  NavStatus,
  PRIMARY_INSURED_PERSON_REF,
  SECONDARY_INSURED_PERSON_REF,
} from '@ecp/features/sales/shared/constants';
import type { AppDispatch, RootStore } from '@ecp/features/sales/shared/store/types';
import { createDeepSelector } from '@ecp/features/sales/shared/store/utils';
import type {
  Answers,
  AnswerValue,
  Condition,
  FieldRefEntry,
} from '@ecp/features/sales/shared/types';
import { isField } from '@ecp/features/sales/shared/utils/web';
import type { Callbacks, Field, Fields, MuiRefTarget, Question, Questions } from '@ecp/types';

import { fetchApi, isAnyApiInProgress } from '../api';
import {
  deleteAnswers,
  getAnswers,
  getDalSessionId,
  getQuestion,
  questionExists,
  updateAnswers,
} from '../inquiry';
import { updatePageStatus } from '../nav';
import {
  setFormErrorsResetByField,
  setFormFieldReset,
  setFormInitialize,
  setFormReset,
  setFormValueChanged,
  setInitValues,
} from './actions';
import { validateField, validateFields, validatePostBindField } from './thunks';
import type { ErrorEntry, Errors, ValidateFormParams, ValidateFormResult } from './types';

export const PATCH_API_PREFIX = 'patchFormValues';

export const getErrors = (state: RootStore): Errors => state.form.errors;
export const getInitValues = (state: RootStore): Answers => state.form.initValues;
export const getUserValues = (state: RootStore): Answers => state.form.userValues;

export const getAllValues = createSelector(
  getInitValues,
  getUserValues,
  getAnswers,
  (initValues, userValues, answers) => {
    const { answers: nestedAnswers, ...others } = answers;
    const allValues: Answers = merge({}, initValues, nestedAnswers, others, userValues);

    return allValues;
  },
);

export const getFieldErrors = createCachedSelector<RootStore, string, string[], string[]>(
  (state, key) => getErrors(state)[key] || [],
  (errors) => errors,
)({
  keySelector: (...[, key]) => key,
  selectorCreator: createDeepSelector,
});

export const getFieldValue = (state: RootStore, key: string): AnswerValue =>
  getAllValues(state)[key];

export const getAllDriverRefs = (state: RootStore): string[] => {
  const refs: string[] = ensureStringArray(getFieldValue(state, DRIVERS_REF));

  return refs;
};

const getAllDriverPersonRefsSelector = (state: RootStore): AnswerValue[][] => {
  const allValues = getAllValues(state);
  const allDriverRefs = getAllDriverRefs(state);

  return allDriverRefs.map((driverRef) => [driverRef, allValues[`${driverRef}.person.ref`]]);
};

export const getAllDriverPersonRefs = createDeepSelector(
  getAllDriverPersonRefsSelector,
  (driverToPersonRefs) => driverToPersonRefs,
);

const getAllDriverPersonCurrentInsuranceRefsSelector = (state: RootStore): AnswerValue[][] => {
  const allValues = getAllValues(state);
  const allDriverRefs = getAllDriverRefs(state);

  return allDriverRefs.map((driverRef) => [
    driverRef,
    allValues[`${driverRef}.person.ref`],
    getPriorInsuranceRefsForDriver(state, { driverRef }),
  ]);
};

export const getAllDriverPersonPriorInsuranceRefs = createDeepSelector(
  getAllDriverPersonCurrentInsuranceRefsSelector,
  (driverToPersonPriorInsuranceRefs) => driverToPersonPriorInsuranceRefs,
);

export const getAllDeckRefs = (state: RootStore): string[] =>
  ensureStringArray(getFieldValue(state, 'property.decks.ref'));

export const getAllPorchRefs = (state: RootStore): string[] =>
  ensureStringArray(getFieldValue(state, 'property.porches.ref'));

export const getPrimary = (state: RootStore): string =>
  getFieldValue(state, PRIMARY_INSURED_PERSON_REF) as string;
export const getSecondary = (state: RootStore): string =>
  getFieldValue(state, SECONDARY_INSURED_PERSON_REF) as string;
export interface GetPersonSelector {
  driverRef: AnswerValue;
}
export const getPersonRefForDriver = createCachedSelector(
  getAllValues,
  (...[, { driverRef }]: [RootStore, Answers]) => driverRef,
  (allValues, driverRef) => allValues[`${driverRef}.person.ref`],
)((...[, { driverRef }]) => driverRef || '');

export const getDriverIsPNI = createCachedSelector(
  getPrimary,
  getPersonRefForDriver,
  (primaryRef, personRef) => primaryRef === personRef,
)((...[, { driverRef }]) => driverRef || '');

export const getDriverIsSNI = createSelector(
  getSecondary,
  getPersonRefForDriver,
  (secondaryRef, personRef) => secondaryRef === personRef,
);

export const getIncidentRefsForDriver = createCachedSelector(
  getAllValues,
  (...[, { driverRef }]: [RootStore, Answers]) => driverRef,
  (allValues, driverRef) => {
    return ensureStringArray(allValues[`${driverRef}.${INCIDENT_REF_SUFFIX}`]);
  },
)((...[, { driverRef }]) => driverRef || '');

export const getPriorInsuranceRefsForDriver = createCachedSelector(
  getAllValues,
  (...[, { driverRef }]: [RootStore, Answers]) => driverRef,
  (allValues, driverRef) => {
    const refs = allValues[`${driverRef}.${PRIOR_INSURANCE_REF_SUFFIX}`];
    if (typeof refs === 'string') {
      if (refs.trim().length > 0) {
        return refs.split(',');
      }

      return [];
    }

    return ensureStringArray(refs);
  },
)((...[, { driverRef }]) => driverRef || '');

export const getMiltiaryDeploymentsForDriver = createCachedSelector(
  getAllValues,
  (...[, { driverRef }]: [RootStore, Answers]) => driverRef,
  (allValues, driverRef) => {
    return ensureStringArray(allValues[`${driverRef}.${MILITARY_DEPLOYMENTS_REF_SUFFIX}`]);
  },
)((...[, { driverRef }]) => driverRef || '');

const getPostBindQuestions = (state: RootStore): Questions => state.postbind.questions;

const postBindQuestionExists =
  (key: string, questionKey?: string): ((state: RootStore) => boolean) =>
  (state: RootStore): boolean =>
    !!getQuestionPostBindNoDefault(key, getPostBindQuestions(state), questionKey);

export const getFieldInitValue = (state: RootStore, key: string): AnswerValue =>
  getInitValues(state)[key];

export const makeFieldKey = (field: Field): string => field.key;
const doMakeFieldsKey = (field?: Field | Fields): string => {
  if (!field) return '';
  if (isField(field)) return makeFieldKey(field);

  return Object.keys(field)
    .sort()
    .reduce((a, key) => [...a, doMakeFieldsKey(field[key])], [] as string[])
    .join(':');
};
export const makeFieldsKey = (fields: Fields): string => doMakeFieldsKey(fields);

interface GetFormSelector {
  fields: Fields;
  dispatch: AppDispatch;
  conditions?: Condition[];
  getFieldRefs: () => FieldRefEntry[];
}

export const getResetForm = createSelector(
  (...[, dispatch]: [RootStore, AppDispatch]): AppDispatch => dispatch,
  (dispatch: AppDispatch): (() => void) =>
    () =>
      dispatch(setFormReset()),
);

export const getInitializeForm = createSelector(
  (...[, dispatch]: [RootStore, AppDispatch]): AppDispatch => dispatch,
  (dispatch: AppDispatch): ((initValues?: Answers) => void) => {
    return (initValues = {}) => {
      dispatch(setFormInitialize({ initValues: { ...initValues } }));
    };
  },
);

export const getResetInitValues = createSelector(
  (...[, dispatch]: [RootStore, AppDispatch]): AppDispatch => dispatch,
  (dispatch: AppDispatch): ((initValues?: Answers) => void) => {
    return (initValues = {}) => {
      dispatch(setInitValues({ initValues: { ...initValues } }));
    };
  },
);

export const getExcludedFields = (conditions: Condition[] | undefined): Field[] => {
  if (conditions && conditions.length > 0) {
    return conditions.reduce((pre, condition) => {
      if (condition.isExcluded && condition.isExcluded()) pre.push(...condition.conditionalFields);

      return pre;
    }, [] as Field[]);
  }

  return [];
};

export const getRequiredOverrideFields = (conditions: Condition[] | undefined): Field[] => {
  if (conditions && conditions.length > 0) {
    return conditions.reduce((pre, condition) => {
      if (condition.isRequiredOverride && condition.isRequiredOverride())
        pre.push(...condition.conditionalFields);

      return pre;
    }, [] as Field[]);
  }

  return [];
};

export const getValidateForm = createCachedSelector<
  RootStore,
  GetFormSelector,
  RootStore,
  Fields,
  Condition[] | undefined,
  AppDispatch,
  () => FieldRefEntry[],
  (params?: ValidateFormParams) => ValidateFormResult
>(
  (state: RootStore) => state,
  (...[, { fields }]) => fields,
  (...[, { conditions }]) => conditions,
  (...[, { dispatch }]) => dispatch,
  (...[, { getFieldRefs }]) => getFieldRefs,
  (...[, fields, conditions, dispatch, getFieldRefs]) => {
    return (params: { goToError?: boolean } = {}) => {
      const { goToError = true } = params;
      const excludedFields: Field[] = getExcludedFields(conditions);
      const requiredOverrideFields: Field[] = getRequiredOverrideFields(conditions);
      const errors = dispatch(validateFields({ fields, excludedFields, requiredOverrideFields }));
      const fieldRefs = getFieldRefs();
      // https://theexperimentationlab.atlassian.net/browse/CPUI-5988
      const noScrollErrorList: Array<string | undefined> = [];
      Object.values(fields).forEach((field) => {
        // eslint-disable-next-line eqeqeq
        if (field != undefined) {
          if (field.key) {
            if (field.noScrollError) {
              noScrollErrorList.push(field.key as string);
            }
          } else {
            Object.values(field).forEach((nestedField) => {
              if (nestedField?.key) {
                if (nestedField.noScrollError) {
                  noScrollErrorList.push(nestedField.key);
                }
              }
            });
          }
        }
      });
      let firstError: ErrorEntry | null = null;
      let firstPosEl: HTMLElement | null;
      let firstFocusEl: HTMLElement | MuiRefTarget | null | undefined;
      let firstErrorElPos = -1;

      const getElTop = (selector: string): [HTMLElement, number] => {
        const posEl = document.querySelector(selector) as HTMLElement;
        const posElTop = posEl?.getBoundingClientRect().top || 0;

        return [posEl, posElTop];
      };

      // keep track of both focus/input element and position el (label) as label is better for using to scroll to position
      // while we need the input to set focus
      errors.forEach((error) => {
        if (!noScrollErrorList.includes(error.key)) {
          // try to get top of element's label
          let [posEl, posElTop] = getElTop(`[id*="${error.key}"][id$="-label"]`);
          // as some elements' label is visually hidden for accessibility purposes (top will be negative), get element's top
          if (posEl?.offsetTop < 0) [posEl, posElTop] = getElTop(`[id*="${error.key}"]`);
          const refEntry = fieldRefs.find((ref) => ref.name === error.key);
          const focusEl = refEntry?.ref?.current;
          if (!firstPosEl || firstErrorElPos > posElTop) {
            firstPosEl = posEl;
            firstErrorElPos = posElTop;
            firstFocusEl = focusEl;
            firstError = error;
          }
        }
      });

      const goToFirstError = (): void => {
        if (firstError && firstPosEl) {
          scrollToElement({ element: firstPosEl, focusElement: firstFocusEl });
        }
      };

      if (goToError && !!errors.length) {
        goToFirstError();
      }

      dispatch(updatePageStatus(errors.length ? NavStatus.INVALID : NavStatus.VALID));

      return {
        isValid: !errors.length,
        firstError,
        goToFirstError,
      };
    };
  },
)((...[, { fields }]) => makeFieldsKey(fields));

export const getPatchFormValues = createCachedSelector<
  RootStore,
  GetFormSelector,
  Fields,
  Condition[] | undefined,
  AppDispatch,
  () => Promise<string>
>(
  (...[, { fields }]) => fields,
  (...[, { conditions }]) => conditions,
  (...[, { dispatch }]) => dispatch,
  (formFields, conditions, dispatch) => {
    const createAnswersFromFields = (
      formFieldsToBeUsed: Fields,
      excludedFields: Field[],
    ): Answers => {
      const answerToBePatched: Answers = {};
      if (formFieldsToBeUsed)
        Object.values(formFieldsToBeUsed).forEach((field) => {
          if (isField(field)) {
            // Skip excluded fields
            if (!excludedFields.includes(field)) {
              answerToBePatched[field.key] = field.value;
            }
          } else {
            Object.assign(
              answerToBePatched,
              createAnswersFromFields(field as Fields, excludedFields),
            );
          }
        });

      return answerToBePatched;
    };

    // This functions returns a promise so that forms should
    // wait until the task complete
    return () => {
      const excludedFields: Field[] = getExcludedFields(conditions);

      // ! Form should have a local state variable `isSubmitting`
      // ! instead of using this and checking (state) => isAnyApiInProgress(state, PATCH_API_PREFIX)
      // ! simply await on submit operation on the caller side
      // ! and here before firing promise, set new var to true, chaining onto the promise `finally` where set new var to false
      return dispatch(
        fetchApi({
          fn: () =>
            dispatch(
              updateAnswers({
                answers: {
                  ...createAnswersFromFields(formFields, excludedFields),
                },
              }),
            ),
          idPrefix: PATCH_API_PREFIX,
        }),
      );
    };
  },
)((...[, { fields }]) => makeFieldsKey(fields));

export interface FormCallbacks {
  initializeForm: (initValues?: Answers) => void;
  resetInitValues: (initValues?: Answers) => void;
  resetForm: () => void;
  validateForm: (params?: ValidateFormParams) => ValidateFormResult;
  patchFormValues: () => Promise<string>;
  isPatchFormInProgress: boolean;
}

export const getForm = createCachedSelector<
  RootStore,
  GetFormSelector,
  () => void,
  (params?: ValidateFormParams) => ValidateFormResult,
  () => Promise<string>,
  (initValues?: Answers) => void,
  (initValues?: Answers) => void,
  boolean,
  FormCallbacks
>(
  (state, { dispatch }) => getResetForm(state, dispatch),
  (state, { fields, dispatch, conditions, getFieldRefs }) =>
    getValidateForm(state, { fields, dispatch, conditions, getFieldRefs }),
  (state, { fields, dispatch, conditions, getFieldRefs }) =>
    getPatchFormValues(state, { fields, dispatch, conditions, getFieldRefs }),
  (state, { dispatch }) => getInitializeForm(state, dispatch),
  (state, { dispatch }) => getResetInitValues(state, dispatch),
  (state) => isAnyApiInProgress(state, PATCH_API_PREFIX),
  (
    resetForm,
    validateForm,
    patchFormValues,
    initializeForm,
    resetInitValues,
    isPatchFormInProgress,
  ): FormCallbacks => ({
    resetForm,
    validateForm,
    patchFormValues,
    initializeForm,
    resetInitValues,
    isPatchFormInProgress,
  }),
)((...[, { fields }]) => makeFieldsKey(fields));

interface GetFieldSelector {
  key: string;
  noScrollError?: boolean;
  questionKey?: string;
  dispatch: AppDispatch;
  isPostBindQuestion?: boolean;
  dalSessionId?: string;
}

export const getFieldCallbacks = createCachedSelector<
  RootStore,
  GetFieldSelector,
  string,
  AppDispatch,
  Question,
  boolean,
  string | undefined,
  Callbacks
>(
  (...[, { key }]) => key,
  (...[, { dispatch }]) => dispatch,
  (state, { key, questionKey, isPostBindQuestion }): Question =>
    isPostBindQuestion
      ? getPostBindQuestion(key, questionKey)(state)
      : getQuestion(key, questionKey)(state),
  (...[, { isPostBindQuestion }]) => isPostBindQuestion || false,
  (state): string | undefined => getDalSessionId(state),
  (key, dispatch, question, isPostBindQuestion, dalSessionId): Callbacks => {
    const reset = (): void => {
      dispatch(setFormFieldReset(key));
    };

    const update = (valueProp: AnswerValue): void => {
      const value = castAnswerType(valueProp, question.answerType);
      dispatch(setFormValueChanged({ key, value }));
      // Since, we are not validating on actionOnChange, removing any previous error
      dispatch(setFormErrorsResetByField({ key }));
    };

    const validate = (valueProp: AnswerValue): string[] => {
      const value = castAnswerType(valueProp, question.answerType);
      if (isPostBindQuestion) {
        return dispatch(validatePostBindField({ key, question, value }));
      }

      return dispatch(
        validateField({
          key,
          question,
          value,
          dependentQuestionKeys: question.dependentQuestionKeys,
        }),
      );
    };

    const validateAndUpdate = (value: AnswerValue): string[] => {
      update(value);

      return validate(value);
    };

    const patch = async (value: AnswerValue): Promise<void> => {
      if (isPostBindQuestion) {
        // delete the answer when empty string
        if (value === '') {
          dispatch(deleteKeyFromPostBindAnswers(key));
        } else {
          dispatch(updatePostBindAnswers({ [key]: value }));
        }
      } else if (dalSessionId) {
        // delete the answer when empty string
        if (value === '') {
          await dispatch(deleteAnswers({ ref: key }));
        } else {
          await dispatch(updateAnswers({ answers: { [key]: value } }));
        }
      }
    };

    const validateUpdateAndPatch = async (value: AnswerValue): Promise<void> => {
      validateAndUpdate(value);
      await patch(value);
    };

    const validateAndPatch = async (value: AnswerValue): Promise<void> => {
      validate(value);
      await patch(value);
    };

    return {
      patch,
      reset,
      update,
      validate,
      validateAndUpdate,
      validateAndPatch,
      validateUpdateAndPatch,
    };
  },
)((...[, { key }]): string => key);

export const getField = createCachedSelector<
  RootStore,
  GetFieldSelector,
  string,
  AnswerValue,
  Question,
  boolean,
  string[],
  AnswerValue,
  Callbacks,
  boolean,
  Field
>(
  (...[, { key }]) => key,
  (state, { key }) => getFieldValue(state, key),
  (state, { key, questionKey, isPostBindQuestion }): Question =>
    isPostBindQuestion
      ? getPostBindQuestion(key, questionKey)(state)
      : getQuestion(key, questionKey)(state),
  (state, { key, questionKey, isPostBindQuestion }): boolean =>
    isPostBindQuestion
      ? postBindQuestionExists(key, questionKey)(state)
      : questionExists(key, questionKey)(state),
  (state, { key }) => getFieldErrors(state, key),
  (state, { key }) => getFieldInitValue(state, key),
  getFieldCallbacks,
  (state, { noScrollError }): boolean => !!noScrollError,
  (key, value, question, exists, errors, defaultValue, callbacks, noScrollError) => {
    const {
      patch,
      reset,
      update,
      validate,
      validateAndUpdate,
      validateAndPatch,
      validateUpdateAndPatch,
    } = callbacks;
    const { options = undefined } = question;

    return {
      props: {
        defaultValue,
        error: errors[0],
        name: key,
        actionOnChange: update,
        actionOnComplete: validateUpdateAndPatch,
        options,
        value,
      },
      errors,
      exists,
      noScrollError,
      key,
      question,
      // probably won't need all of these functions once we get requirements for how/when to update
      patch,
      reset,
      update,
      validate,
      validateAndUpdate,
      validateAndPatch,
      validateUpdateAndPatch,
      value,
    };
  },
)((...[, { key }]) => key);

export const getFieldsByKeys = <T extends string, U extends string>(
  state: RootStore,
  prefix: T,
  keys: U[],
  dispatch: AppDispatch,
): Record<U, Field> => {
  const fields = keys.reduce((acc, key) => {
    acc[key] = getField(state, {
      key: `${prefix ? `${prefix}.` : ''}${key}`,
      dispatch,
    });

    return acc;
  }, {} as Record<U, Field>);

  return fields;
};
