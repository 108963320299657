import type { HeatingSourceMetadata } from '@ecp/features/sales/shared/types';

// Heating source mapping
export const heatingSourceMetaData: HeatingSourceMetadata = {
  heatingSource: [
    {
      value: 'PROPERTY.HEATING_SOURCE.BUILT_IN_FIREPLACE',
      label: 'Built-in fireplace',
    },
    {
      value: 'PROPERTY.HEATING_SOURCE.FIREPLACE_INSERT',
      label: 'Fireplace insert',
    },
    {
      value: 'PROPERTY.HEATING_SOURCE.FREE_STANDING_STOVE',
      label: 'Freestanding stove',
    },
  ],

  // Built-in fireplace
  fireplaceType: [
    {
      value: 'BUILT_IN_FIREPLACE.FIREPLACE_TYPE.BRICK_OR_STONE_MASONRY',
      label: 'Brick or stone (masonry)',
    },
    {
      value: 'BUILT_IN_FIREPLACE.FIREPLACE_TYPE.MASONRY_CUSTOM_DESIGN',
      label: 'Masonry - custom design',
    },
    {
      value: 'BUILT_IN_FIREPLACE.FIREPLACE_TYPE.PREFABRICATED_ZERO_CLEARANCE',
      label: 'Prefabricated/zero clearance',
    },
    {
      value: 'BUILT_IN_FIREPLACE.FIREPLACE_TYPE.BEEHIVE_KIVA',
      label: 'Beehive (kiva)',
    },
  ],

  // Fireplace insert | Freestanding stove
  fuelType: [
    {
      value: 'FUEL_TYPE.WOOD',
      label: 'Wood',
    },
    {
      value: 'FUEL_TYPE.PELLET',
      label: 'Pellet',
    },
    {
      value: 'FUEL_TYPE.GAS',
      label: 'Gas',
    },
    {
      value: 'FUEL_TYPE.CORN',
      label: 'Corn',
    },
    {
      value: 'FUEL_TYPE.COAL',
      label: 'Coal',
    },
  ],
};
