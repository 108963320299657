import { createReducer } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { emptyObject } from '@ecp/utils/common';

import {
  setAccountNumber,
  setAccountType,
  setAccountUse,
  setCardNumber,
  setCreditCardFinancialAccountToken,
  setCreditCardToken,
  setCreditCardType,
  setEFTPayToken,
  setEFTPayTokenForReccuringPayment,
  setExpirationDate,
  setFilled,
  setGettingTokenFor,
  setHomeInspectionStatus,
  setMortgage,
  setName,
  setPaymentMethod,
  setPaymentOptionsAndAcksRefetchRequired,
  setPciToken,
  setPurchaseApiPostSuccess,
  setPurchaseRequestProduct,
  setReccuringPayAccountNumber,
  setReccuringPayAccountType,
  setReccuringPayAccountUse,
  setReccuringPayRoutingNumber,
  setRoutingNumber,
  setTokenForEFTPay,
  setTokenForReccuringPayment,
} from './actions';
import type { PurchaseState } from './types';

const purchaseInitial: PurchaseState = {
  offerSetId: '',
  purchaseRequest: {},
  membershipCardNumber: '',
  purchaseResponse: {},
  gettingTokenFor: {},
  gettingTokenForEFTPay: {},
  gettingEFTTokenForReccuringPayment: {},
  homeInspectionStatus: '',
  isPaymentOptionsAndAcksRefetchRequired: false,
  pciToken: '',
};

export const purchaseReducer = createReducer(purchaseInitial, (builder) => {
  builder
    .addCase(setAccountNumber, (state, { payload }) => {
      const { accountNumber, product } = payload;
      const { bankAccount } = state.purchaseRequest[product].paymentOption;
      bankAccount.accountNumber = accountNumber;
      bankAccount.customerAcknowledgement = true;
    })
    .addCase(setRoutingNumber, (state, { payload }) => {
      const { routingNumber, product } = payload;
      state.purchaseRequest[product].paymentOption.bankAccount.routingNumber = routingNumber;
    })
    .addCase(setAccountUse, (state, { payload }) => {
      const { accountUse, product } = payload;
      state.purchaseRequest[product].paymentOption.bankAccount.accountUse = accountUse;
    })
    .addCase(setAccountType, (state, { payload }) => {
      const { accountType, product } = payload;
      state.purchaseRequest[product].paymentOption.bankAccount.accountType = accountType;
    })
    .addCase(setReccuringPayAccountNumber, (state, { payload }) => {
      const { accountNumber, product } = payload;
      state.purchaseRequest[product].paymentOption.banckAccountForRecurringPay.accountNumber = '';
      state.purchaseRequest[product].paymentOption.banckAccountForRecurringPay.accountNumber =
        accountNumber;
    })
    .addCase(setReccuringPayRoutingNumber, (state, { payload }) => {
      const { routingNumber, product } = payload;
      state.purchaseRequest[product].paymentOption.banckAccountForRecurringPay.routingNumber = '';
      state.purchaseRequest[product].paymentOption.banckAccountForRecurringPay.routingNumber =
        routingNumber;
    })
    .addCase(setReccuringPayAccountUse, (state, { payload }) => {
      const { accountUse, product } = payload;
      state.purchaseRequest[product].paymentOption.banckAccountForRecurringPay.accountUse = '';
      state.purchaseRequest[product].paymentOption.banckAccountForRecurringPay.accountUse =
        accountUse;
    })
    .addCase(setReccuringPayAccountType, (state, { payload }) => {
      const { accountType, product } = payload;
      state.purchaseRequest[product].paymentOption.banckAccountForRecurringPay.accountType = '';
      state.purchaseRequest[product].paymentOption.banckAccountForRecurringPay.accountType =
        accountType;
    })
    .addCase(setCardNumber, (state, { payload }) => {
      const { cardNumber, product, type } = payload;
      state.purchaseRequest[product].cardNumber = cardNumber;
      state.purchaseRequest[product].paymentMethod = type;
    })
    .addCase(setCreditCardToken, (state, { payload }) => {
      const { token, type, product } = payload;
      state.purchaseRequest[product].paymentOption[type].token = token;
    })
    .addCase(setCreditCardFinancialAccountToken, (state, { payload }) => {
      const { token, type, product, paymentId, paymentPurpose } = payload;
      state.purchaseRequest[product].paymentOption[type].token = token;
      state.purchaseRequest[product].paymentOption[type].financialAccountToken = token;
      state.purchaseRequest[product].paymentOption[type].paymentId = paymentId;
      state.purchaseRequest[product].paymentOption[type].paymentPurpose = paymentPurpose;
    })
    .addCase(setEFTPayToken, (state, { payload }) => {
      const { token, product, paymentId, paymentPurpose } = payload;
      state.purchaseRequest[product].paymentOption.bankAccount.financialAccountToken = token;
      state.purchaseRequest[product].paymentOption.bankAccount.paymentId = paymentId;
      state.purchaseRequest[product].paymentOption.bankAccount.paymentPurpose = paymentPurpose;
    })
    .addCase(setEFTPayTokenForReccuringPayment, (state, { payload }) => {
      const { token, product, paymentId, paymentPurpose } = payload;
      state.purchaseRequest[
        product
      ].paymentOption.banckAccountForRecurringPay.financialAccountToken = token;
      state.purchaseRequest[product].paymentOption.banckAccountForRecurringPay.paymentId =
        paymentId;
      state.purchaseRequest[product].paymentOption.banckAccountForRecurringPay.paymentPurpose =
        paymentPurpose;
    })
    .addCase(setCreditCardType, (state, { payload }) => {
      const { creditCardType, type, product } = payload;
      state.purchaseRequest[product].paymentOption[type].creditCardType = creditCardType;
    })
    .addCase(setExpirationDate, (state, { payload }) => {
      const { expirationDate, type, product } = payload;
      const parsedDate = dayjs(expirationDate);
      const creditCard = state.purchaseRequest[product].paymentOption[type];

      if (creditCard) {
        if (parsedDate.isValid()) {
          creditCard.expirationMonth = parsedDate.format('MM');
          creditCard.expirationYear = parsedDate.format('YYYY');
        } else {
          creditCard.expirationMonth = '';
          creditCard.expirationYear = '';
        }
        state.purchaseRequest[product].expirationDate = parsedDate.format('MM/YY');
      }
    })
    .addCase(setFilled, (state, { payload }) => {
      const { filled, type, product } = payload;
      state.purchaseRequest[product].paymentOption[type].filled = filled;
    })
    .addCase(setGettingTokenFor, (state, { payload }) => {
      const { product, type } = payload;
      state.gettingTokenFor[product] = {};
      state.gettingTokenFor[product][type] = payload;
      state.purchaseRequest[product].paymentOption[type].token = null;
    })
    .addCase(setTokenForEFTPay, (state, { payload }) => {
      const { product } = payload;
      state.gettingTokenForEFTPay[product] = payload;
      state.purchaseRequest[product].paymentOption.bankAccount.financialAccountToken = null;
    })
    .addCase(setTokenForReccuringPayment, (state, { payload }) => {
      const { product } = payload;
      state.gettingEFTTokenForReccuringPayment[product] = payload;
      state.purchaseRequest[
        product
      ].paymentOption.banckAccountForRecurringPay.financialAccountToken = null;
    })
    .addCase(setHomeInspectionStatus, (state, { payload }) => {
      state.homeInspectionStatus = payload;
    })
    .addCase(setMortgage, (state, { payload }) => {
      const {
        product,
        company,
        loanNumber,
        addressLine1,
        addressLine2,
        city,
        zip,
        state: mortgageState,
        filled,
      } = payload;
      const { mortgage } = state.purchaseRequest[product].paymentOption;
      if (company) mortgage.company = company || '';
      if (loanNumber) mortgage.loanNumber = loanNumber;
      if (addressLine1) mortgage.addressLine1 = addressLine1;
      if (addressLine2) mortgage.addressLine2 = addressLine2;
      if (city) mortgage.city = city;
      if (zip) mortgage.zip = zip;
      if (mortgageState) mortgage.state = mortgageState;
      if (filled) mortgage.filled = filled;
    })
    .addCase(setName, (state, { payload }) => {
      const { fullName, type, product } = payload;
      const creditCard = state.purchaseRequest[product].paymentOption[type];
      if (creditCard) {
        const trimmedFullName = fullName.trim();
        const whitespaceIndex = trimmedFullName.indexOf(' ');
        if (whitespaceIndex > 0) {
          creditCard.lastName = fullName.substring(whitespaceIndex + 1).trim();
        }
        creditCard.firstName = fullName.substring(0, whitespaceIndex);
        // Setting middleName to blank as there are some validation issues
        // Will use either firstName, lastName or just the entire name as firstName
        creditCard.middleName = '';
      }
    })
    .addCase(setPaymentMethod, (state, { payload }) => {
      const { product, paymentMethod } = payload;
      state.purchaseRequest[product].paymentMethod = paymentMethod;
    })
    .addCase(setPaymentOptionsAndAcksRefetchRequired, (state, { payload }) => {
      state.isPaymentOptionsAndAcksRefetchRequired = payload;
    })
    .addCase(setPurchaseApiPostSuccess, (state, { payload }) => {
      const { policies } = payload.data;
      const storedPolicies = state.purchaseResponse.policies;
      // we should merge purchase response when retry happens for bundle
      state.purchaseResponse.policies = { ...storedPolicies, ...policies };
    })
    .addCase(setPurchaseRequestProduct, (state, { payload: products }) => {
      products.forEach((product) => {
        state.purchaseRequest[product] = {
          paymentMethod: 'creditCard',
          cardNumber: '',
          expirationDate: '',
          paymentOption: {
            bankAccount: {
              routingNumber: '',
              accountNumber: '',
              customerAcknowledgement: false,
            },
            banckAccountForRecurringPay: {
              accountUse: '',
              accountType: '',
              routingNumber: '',
              accountNumber: '',
              customerAcknowledgement: false,
              financialAccountToken: null,
            },
            costcoVisa: {
              creditCardType: '',
              expirationMonth: '',
              expirationYear: '',
              filled: false,
              firstName: '',
              middleName: '',
              lastName: '',
              token: '',
            },
            creditCard: {
              creditCardType: '',
              expirationMonth: '',
              expirationYear: '',
              filled: false,
              firstName: '',
              middleName: '',
              lastName: '',
              token: '',
            },
            mortgage: emptyObject,
          },
        };
      });
    })
    .addCase(setPciToken, (state, { payload }) => {
      state.pciToken = payload;
    })
    .addMatcher(
      (action): action is { type: '@global/teardownStore' } =>
        action.type === '@global/teardownStore',
      () => purchaseInitial,
    );
});
