import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ContactUsDialog' })((theme) => ({
  subHeader: {
    textTransform: 'none',
  },
  body: {
    paddingTop: 10,
  },
  titleHeading: {},
  dialogRoot: { maxWidth: '100%' },
  dialogContainer: {
    maxWidth: '85%',
    marginTop: 40,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 15,
      marginRight: 15,
    },
  },
  dialogDivider: {
    margin: '20px 0px 10px 0px',
  },
}));
