import { Divider, Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import { useFieldWithPrefix, usePniRef } from '@ecp/features/sales/shared/store';

import { MailingAddressQuestions } from '../CheckoutMailingAddressQuestions/MailingAddressQuestions';
import { NamedInsuredContactInfoQuestions } from '../NamedInsuredContactInfoQuestions';
import { useStyles } from './CheckoutContactInformationSection.styles';

export interface Props {
  phoneNumberRequired: boolean;
}

export const CheckoutContactInformation: React.FC<Props> = (props) => {
  const pniRef = usePniRef();
  const { classes } = useStyles();
  const { phoneNumberRequired } = props;
  const usePersonField = useFieldWithPrefix(pniRef, 'person.<id>');
  const {
    props: { value: name = '' },
  } = usePersonField('firstName');
  const possessiveNameOrYour = name ? `${name}'s` : 'your';

  return (
    <>
      <h2 className={classes.title}>Confirm contact information</h2>
      <Divider aria-hidden='true' className={classes.divider} />
      <Grid container className={classes.mainGrid}>
        <MailingAddressQuestions />
        <GridItem topSpacing='lg' xs={12}>
          <p className={classes.subHeader}>{`What's ${possessiveNameOrYour} contact info?`}</p>
        </GridItem>
        <NamedInsuredContactInfoQuestions
          phoneNumberRequired={phoneNumberRequired}
          namedInsuredRef={pniRef}
        />
      </Grid>
    </>
  );
};
