import type { RootStore } from '@ecp/features/sales/shared/store/types';

import type { SapiTarget } from './types';

export const isAnyApiInProgress = (state: RootStore, prefix?: string): boolean => {
  const calls = state.api.inProgressCalls;
  if (!prefix) return !!calls.length;

  return !!calls.find((id) => !!(id.indexOf(prefix) !== -1));
};

export const isApiInProgress = (state: RootStore, id: string | string[]): boolean => {
  const ids = Array.isArray(id) ? id : [id];

  return !!state.api.inProgressCalls.find((apiId) => ids.includes(apiId));
};

export const getSapiTarget = (state: RootStore): SapiTarget => state.api.sapiTarget;
