import { Divider, Grid } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';

import { StateFeesSection } from '@ecp/features/sales/shared/components';
import type { PaymentPlan } from '@ecp/features/sales/shared/constants';
import { getOfferDetailsForProduct } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AutoProduct, PropertyProduct } from '@ecp/features/shared/product';
import { getProductDisplayNameFromProduct } from '@ecp/features/shared/product';
import { useIsMobile } from '@ecp/themes/base';

import type { PaymentOption } from '../../types';
import { getCombinedTotal, getMonthlyPaymentsText } from '../../util';
import { determineInstallmentFeeTotal } from '../../util/installmentFeeUtil';
import { EstimatedTooltip } from '../EstimatedTooltip';
import { Recalc } from '../Recalc';
import { useStyles } from './MobileMonthlyPaymentsCheckoutCard.styles';

interface Props {
  autoOfferProduct: AutoProduct;
  propertyOfferProduct: PropertyProduct;
  autoPaymentOption: PaymentOption;
  propertyPaymentOption: PaymentOption;
  recalculate?: boolean;
  isMVRActive?: boolean;
  showProcess: boolean;
  premiumPlan: PaymentPlan;
  showError?: boolean;
  showStateFees?: boolean;
  isPricingDetailsHidden?: boolean;
}

export const MobileMonthlyPaymentsBundleCheckoutView: React.FC<Props> = (props) => {
  const {
    autoOfferProduct,
    propertyOfferProduct,
    autoPaymentOption,
    propertyPaymentOption,
    isMVRActive,
    isPricingDetailsHidden,
    recalculate,
    showProcess,
    premiumPlan,
    showError,
    showStateFees,
  } = props;
  const { classes, cx } = useStyles();
  const isMobile = useIsMobile();

  const autoOfferDetails = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, autoOfferProduct),
  );
  const propertyOfferDetails = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, propertyOfferProduct),
  );

  if (showError || !autoOfferDetails || !propertyOfferDetails) return null;

  const propertyProductName = getProductDisplayNameFromProduct(propertyOfferProduct);

  const getPremiumFromPaymentOption = (paymentOption: PaymentOption): string => {
    if (paymentOption.paymentPlan === 'Full') {
      return parseDollar(paymentOption.totalPremium);
    }

    return parseDollar(paymentOption.monthlyAmount);
  };

  return (
    <>
      {/* Tablet View */}
      {isMobile ? (
        <Grid
          xs={12}
          container
          className={classes.bottomDetailsWrapper}
          justifyContent='center'
          data-testid='mobileView'
        >
          <Grid item xs={6}>
            <Grid item xs={12} className={classes.policyDurationHeader}>
              <p className={cx(classes.mobileHeading, classes.textCenter)}>
                <span className={classes.policyDurationHeaderProduct}>Auto</span>{' '}
                {`${autoOfferDetails.policyDuration}-mo policy`}
              </p>
            </Grid>
            <Grid item xs={10} className={classes.premiumsDividerContainer}>
              <Divider aria-hidden='true' />
            </Grid>
            <Grid item xs={12}>
              <p className={cx(classes.policyCheckoutHeaders, classes.textCenter)}>
                {' '}
                {autoPaymentOption.paymentPlan !== 'Full'
                  ? getMonthlyPaymentsText(
                      autoPaymentOption.paymentPlan,
                      Number(autoPaymentOption.downPayment),
                      autoOfferProduct,
                    )
                  : 'One-time payment of'}
              </p>
            </Grid>

            <Grid item xs={12} className={cx(classes.premiumBold, classes.textCenter)}>
              <Recalc
                amount={getPremiumFromPaymentOption(autoPaymentOption)}
                recalculate={recalculate}
                showProcess={showProcess}
              />
              {!isMVRActive && <EstimatedTooltip />}
            </Grid>
            {autoPaymentOption.paymentPlan !== 'Full' && (
              <Grid container>
                {Number(autoPaymentOption.downPayment) > 0 && (
                  <Grid
                    item
                    xs={12}
                    className={cx(classes.policyCheckoutHeaders, classes.textCenter)}
                  >
                    Due today{' '}
                    <Recalc
                      amount={parseDollar(autoPaymentOption.downPayment)}
                      amountClassname={classes.premiums}
                      recalculate={recalculate}
                      showProcess={showProcess}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <p className={cx(classes.policyCheckoutHeaders, classes.textCenter)}>
                    Installment Fees{' '}
                    <Recalc
                      amount={parseDollar(
                        determineInstallmentFeeTotal(autoOfferProduct, autoPaymentOption),
                      )}
                      recalculate={recalculate}
                      showProcess={showProcess}
                    />
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <p className={cx(classes.policyCheckoutHeaders, classes.textCenter)}>
                    Policy total{' '}
                    <Recalc
                      amount={parseDollar(autoPaymentOption.totalPremium)}
                      amountClassname={classes.premiums}
                      recalculate={recalculate}
                      showProcess={showProcess}
                    />
                  </p>
                </Grid>
              </Grid>
            )}
            {!isPricingDetailsHidden && (
              <StateFeesSection
                premiumPlan={premiumPlan}
                offerDetails={autoOfferDetails}
                showStateFees={showStateFees}
              />
            )}
          </Grid>

          <Grid item xs={6}>
            <Grid item xs={12} className={classes.policyDurationHeader}>
              <p className={cx(classes.mobileHeading, classes.textCenter)}>
                <span className={classes.policyDurationHeaderProduct}>{propertyProductName}</span>{' '}
                {`${propertyOfferDetails.policyDuration}-mo policy`}
              </p>
            </Grid>
            <Grid item xs={10} className={classes.premiumsDividerContainer}>
              <Divider aria-hidden='true' />
            </Grid>
            <Grid item xs={12}>
              <p className={cx(classes.policyCheckoutHeaders, classes.textCenter)}>
                {propertyPaymentOption.paymentPlan !== 'Full'
                  ? getMonthlyPaymentsText(
                      propertyPaymentOption.paymentPlan,
                      Number(propertyPaymentOption.downPayment),
                      propertyOfferProduct,
                    )
                  : 'One-time payment of'}
              </p>
            </Grid>

            <Grid item xs={12} className={cx(classes.premiumBold, classes.textCenter)}>
              <Recalc
                amount={getPremiumFromPaymentOption(propertyPaymentOption)}
                recalculate={recalculate}
                showProcess={showProcess}
              />
            </Grid>
            {propertyPaymentOption.paymentPlan !== 'Full' && (
              <Grid container>
                {Number(propertyPaymentOption.downPayment) > 0 && (
                  <Grid
                    item
                    xs={12}
                    className={cx(classes.policyCheckoutHeaders, classes.textCenter)}
                  >
                    Due today{' '}
                    <Recalc
                      amount={parseDollar(propertyPaymentOption.downPayment)}
                      amountClassname={classes.premiums}
                      recalculate={recalculate}
                      showProcess={showProcess}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <p className={cx(classes.policyCheckoutHeaders, classes.textCenter)}>
                    Installment Fees{' '}
                    <Recalc
                      amount={parseDollar(
                        determineInstallmentFeeTotal(propertyOfferProduct, propertyPaymentOption),
                      )}
                      recalculate={recalculate}
                      showProcess={showProcess}
                    />
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <p className={cx(classes.policyCheckoutHeaders, classes.textCenter)}>
                    Policy total{' '}
                    <Recalc
                      amount={parseDollar(
                        getCombinedTotal(propertyOfferProduct, propertyPaymentOption),
                      )}
                      amountClassname={classes.premiums}
                      recalculate={recalculate}
                      showProcess={showProcess}
                    />
                  </p>
                </Grid>
              </Grid>
            )}
            {!isPricingDetailsHidden && (
              <StateFeesSection
                premiumPlan={premiumPlan}
                offerDetails={propertyOfferDetails}
                showStateFees={showStateFees}
              />
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid
          md={9}
          sm={4}
          xs={4}
          container
          className={classes.bottomDetailsWrapper}
          data-testid='tabletView'
        >
          <Grid item xs={5} className={classes.leftColumnContainer}>
            <Grid item xs={12} className={classes.policyDurationHeader}>
              <p className={classes.mobileHeading}>
                <span className={classes.policyDurationHeaderProduct}>Auto</span>{' '}
                {`${autoOfferDetails.policyDuration}-mo policy`}
                {!isMVRActive && <EstimatedTooltip inline />}
              </p>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={7} className={classes.policyCheckoutHeaders}>
                {autoPaymentOption.paymentPlan !== 'Full'
                  ? getMonthlyPaymentsText(
                      autoPaymentOption.paymentPlan,
                      Number(autoPaymentOption.downPayment),
                      autoOfferProduct,
                    )
                  : 'One-time payment of'}
              </Grid>
              <Grid item xs={5} className={cx(classes.premiumBold)}>
                <Recalc
                  amount={getPremiumFromPaymentOption(autoPaymentOption)}
                  recalculate={recalculate}
                  showProcess={showProcess}
                />
              </Grid>
            </Grid>
            {autoPaymentOption.paymentPlan !== 'Full' && (
              <>
                {Number(autoPaymentOption.downPayment) > 0 && (
                  <Grid item container>
                    <Grid item xs={7} className={classes.policyCheckoutHeaders}>
                      Due today
                    </Grid>
                    <Grid item xs={5} className={classes.premiums}>
                      <Recalc
                        amount={parseDollar(autoPaymentOption.downPayment)}
                        recalculate={recalculate}
                        showProcess={showProcess}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid item container>
                  <Grid item xs={7} className={classes.policyCheckoutHeaders}>
                    Installment Fees
                  </Grid>
                  <Grid xs={5} className={classes.premiums}>
                    <Recalc
                      amount={parseDollar(
                        determineInstallmentFeeTotal(autoOfferProduct, autoPaymentOption),
                      )}
                      recalculate={recalculate}
                      showProcess={showProcess}
                    />
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item xs={7} className={classes.policyCheckoutHeaders}>
                    Policy total
                  </Grid>
                  <Grid item xs={5} className={classes.premiums}>
                    <Recalc
                      amount={parseDollar(autoPaymentOption.totalPremium)}
                      recalculate={recalculate}
                      showProcess={showProcess}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {!isPricingDetailsHidden && (
              <StateFeesSection
                premiumPlan={premiumPlan}
                offerDetails={autoOfferDetails}
                showStateFees={showStateFees}
              />
            )}
          </Grid>

          <Grid item xs={5} className={classes.rightColumnContainer}>
            <Grid item xs={12} className={classes.policyDurationHeader}>
              <p className={classes.mobileHeading}>
                <span className={classes.policyDurationHeaderProduct}>{propertyProductName}</span>{' '}
                {`${propertyOfferDetails.policyDuration}-mo policy`}
              </p>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={7} className={classes.policyCheckoutHeaders}>
                {propertyPaymentOption.paymentPlan !== 'Full'
                  ? getMonthlyPaymentsText(
                      propertyPaymentOption.paymentPlan,
                      Number(propertyPaymentOption.downPayment),
                      propertyOfferProduct,
                    )
                  : 'One-time payment of'}
              </Grid>
              <Grid item xs={5} className={cx(classes.premiumBold)}>
                <Recalc
                  amount={getPremiumFromPaymentOption(propertyPaymentOption)}
                  recalculate={recalculate}
                  showProcess={showProcess}
                />
              </Grid>
            </Grid>

            {propertyPaymentOption.paymentPlan !== 'Full' && (
              <>
                {Number(propertyPaymentOption.downPayment) > 0 && (
                  <Grid item container>
                    <Grid item xs={7} className={classes.policyCheckoutHeaders}>
                      Due today
                    </Grid>
                    <Grid item xs={5} className={classes.premiums}>
                      <Recalc
                        amount={parseDollar(propertyPaymentOption.downPayment)}
                        recalculate={recalculate}
                        showProcess={showProcess}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid item container>
                  <Grid item xs={7} className={classes.policyCheckoutHeaders}>
                    Installment Fees
                  </Grid>
                  <Grid xs={5} className={classes.premiums}>
                    <Recalc
                      amount={parseDollar(
                        determineInstallmentFeeTotal(autoOfferProduct, propertyPaymentOption),
                      )}
                      recalculate={recalculate}
                      showProcess={showProcess}
                    />
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item xs={7} className={classes.policyCheckoutHeaders}>
                    Policy total
                  </Grid>
                  <Grid item xs={5} className={classes.premiums}>
                    <Recalc
                      amount={parseDollar(propertyPaymentOption.totalPremium)}
                      removeWrapper
                      recalculate={recalculate}
                      showProcess={showProcess}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {!isPricingDetailsHidden && (
              <StateFeesSection
                premiumPlan={premiumPlan}
                offerDetails={propertyOfferDetails}
                showStateFees={showStateFees}
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
