import { useAddFields } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import type { OptionProps } from '@ecp/features/sales/shared/types';

import type { QuestionProps } from '../../questionProps.types';

interface SuffixQuestionProps extends QuestionProps {
  personRef: string;
  disabled: boolean;
}

export const SuffixQuestion: React.FC<SuffixQuestionProps> = (props) => {
  const {
    personRef,
    id = 'suffix',
    label = 'Suffix (optional)',
    trackingName = 'suffix',
    helperText,
    disabled,
  } = props;

  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const suffix = usePersonField('suffix');
  useAddFields({ suffix });

  if (!suffix.exists) return null;

  return (
    <Select
      {...(suffix.props as OptionProps)}
      data-testid='suffix'
      disabled={disabled}
      helperText={helperText}
      id={id}
      inputButtonAriaLabel='Suffix'
      label={label}
      trackingName={trackingName}
    />
  );
};
