import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { env } from '@ecp/env';
import { PhoneLink } from '@ecp/features/sales/shared/components';
import { partner } from '@ecp/partners';

import metadata from '../AgreementUnderwriters';
import type { DataType } from '../AgreementUnderwriters/metadata';
import type { GeneralPaymentDisclaimerProps, NachaAuthorizationAgreementProps } from './types';

export const GeneralAuthorizationAgreement: React.FC = () => (
  <>
    <p>
      I authorize Homesite Group, Incorporated to initiate the payment listed on this page. I
      understand that payment will be submitted once I select "Purchase Policy". If you have
      questions regarding this payment, please contact {partner.shared.partnerDisplayName} at{' '}
      <PhoneLink withUnderlinedLinkStyle number={partner.shared.servicePhoneNumber} />. Agents are
      available {partner.shared.servicePhoneHours}.
    </p>
    <br />
  </>
);

export const NachaAuthorizationAgreement: React.FC<NachaAuthorizationAgreementProps> = ({
  product,
  phoneHours = partner.shared.servicePhoneHours,
  defaultComponent = <GeneralAuthorizationAgreement />,
}): JSX.Element => {
  const getMetadataContent = (dataType: DataType): string => metadata()[product][dataType];

  return !env.static.isAgent ? (
    <>
      <p>
        I authorize {getMetadataContent('underwritingGroupFull')} to automatically debit this one
        time payment from my bank account indicated above for the purpose of paying insurance
        premiums and related charges for this policy. I understand that payment amounts, due dates
        and any applicable fees will be indicated on my billing statement. The charge will appear on
        my bank account or credit card statement as {getMetadataContent('insuranceType')} Refunds or
        credits, if any, may be made directly to my account or card. Changes to my policy or premium
        may change the amount debited. I can enroll in Auto Pay deductions by calling{' '}
        {getMetadataContent('underwritingGroupShort')} at{' '}
        <PhoneLink withUnderlinedLinkStyle number={partner.shared.servicePhoneNumber} />{' '}
        {phoneHours} or by going online. I understand it is my responsibility to notify{' '}
        {getMetadataContent('underwritingGroupShort')} if I change financial institutions. I also
        understand that {getMetadataContent('underwritingGroupShort')} has the right to discontinue
        my enrollment at any time.
      </p>
      <br />
    </>
  ) : (
    defaultComponent
  );
};

export const GeneralPaymentDisclaimer: React.FC<GeneralPaymentDisclaimerProps> = ({
  fraudText,
}): JSX.Element => (
  <>
    <p>
      Applicable fees (after initial down payment): If you choose to be billed for each payment, you
      will be charged $5.00 ($4.00 in AZ, $2.50 in KY, $3.00 in WV and NC, $2.60 AL, $1.00 in FL)
      for each installment. This fee will be shown as a separate charge on your billing statements.
      If you enroll in automatic payments, you will be charged only $3.00 ($2.60 in AL, $2.50 in KY,
      $1.00 in FL, $0.00 in NC and TN) for each installment payment.
    </p>
    {fraudText && <p>{fraudText}</p>}
  </>
);

export const GeneralCreditAuthDisclaimer: React.FC<GeneralPaymentDisclaimerProps> = ({
  name,
  product,
}): JSX.Element => {
  const realTimeCreditCardPayment = flagValues[FeatureFlags.REAL_TIME_CREDIT_CARD_PAYMENT];

  return (
    <>
      <p>
        By selecting credit or debit card billing, you authorize {name} to bill your credit or debit
        card account for the {product} insurance premiums at the frequency selected for your payment
        option. Payments will be automatically billed to your credit or debit card account and this
        arrangement will remain in effect until you notify {name}. In the event of an unauthorized
        transfer to {name}, or if you desire to cancel this agreement at any time, please contact
        our office.
      </p>
      <p>
        {!realTimeCreditCardPayment
          ? 'The payment of your policy will be taken on the effective date of your policy.'
          : null}{' '}
        Late and Return Payment Fees may be assessed.
      </p>
    </>
  );
};

export const GeneralPaymentConfirmationDisclaimer: React.FC = () => (
  <>
    <p>
      Once you authorize the payment and receive confirmation, your payment will be applied to your
      billing account within one to three business days. It will be charged to your debit/credit
      card or checking/savings account on or after today’s date.
    </p>
    <p>
      A returned bank item fee may be charged if your payment is returned by your financial
      institution. Refer to your billing statement for specific fee information.
    </p>
  </>
);

export const GeneralAutoCheckingWithdrawlDisclaimer: React.FC<GeneralPaymentDisclaimerProps> = ({
  name,
}) => (
  <>
    <p>
      By selecting pre authorized checking withdrawal, you authorize {name} to charge your account
      at the financial institution selected to pay your auto premium installments. You authorize the
      financial institution selected to honor these charges for premium as if they were signed by
      you. You certify this agreement will remain in effect until you notify {name}, allowing a
      reasonable time to act on the cancellation. You may stop payment by notifying {name} at least
      three (3) banking days before the charge is made. {name} will notify you if any payment
      differs from the previous payment. In the event of an unauthorized transfer to {name}, or if
      you cancel this agreement at any time, please contact our office.
    </p>
    <p>
      The payment of your policy will be taken on the effective date of your policy. Late and Return
      Payment Fees may be assessed.
    </p>
  </>
);

export const GeneralAutoBillingDisclaimer: React.FC = () => (
  <p>
    If you choose to be billed for each payment, you will be charged $5.00 ($4.00 in AK, $3.00 in DC
    and WY, and $0.00 in NC and FL) for each installment. This fee will be shown as a separate
    charge on your billing statements.
  </p>
);
