import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'RatingCriteriaPage' })((theme) => ({
  root: {
    '#content': {
      maxWidth: 'initial',
    },
    '& [role=main]': {
      [theme.breakpoints.only('md')]: {
        maxWidth: 'initial',
      },
    },
  },
  backButton: {
    marginTop: 40,
    marginLeft: 30,
    [theme.breakpoints.down('lg')]: {
      marginTop: 24,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 24,
      marginLeft: 15,
    },
  },
}));
