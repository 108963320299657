import type { CarrierMessagingType } from '@ecp/features/sales/shared/types';
import type { Product } from '@ecp/features/shared/product';

const metadata: Partial<Record<Product, Record<string, CarrierMessagingType>>> = {
  'connect.auto': {
    NoFeeFirstPayment: {
      name: 'Installment fees',
      message: () => {
        return 'Installment fee not applied to first payment.';
      },
    },
    PaymentLateFeeSC: {
      name: 'Late and Returned Payments',
      message: () => {
        return 'The policy is subject to late and returned payment fees. Payments made after the due date may incur a late fee of $10. Returned payments may incur a fee of $25.';
      },
    },
  },
  'homesite.renters': {
    minimumEarnedPremium: {
      name: 'Minimum Earned Premium',
      message: () => {
        return 'The first $25 of the premium on this policy is non-refundable if the policy is cancelled at the insured’s request for any reason. This only applies at new business, when you first purchase the policy.';
      },
    },
  },
};

export default metadata;
