import { memo, useEffect } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem, NumberFormat } from '@ecp/components';
import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { Select, TextField } from '@ecp/features/sales/shared/components';
import { usePostBindField } from '@ecp/features/sales/shared/store';
import type { OptionProps } from '@ecp/features/sales/shared/types';
import { clearFields } from '@ecp/features/sales/shared/utils/web';

import { useStyles } from './MortgageAddressQuestion.styles';

export const MortgageAddressQuestion: React.FC<unknown> = memo(() => {
  const { classes } = useStyles();

  const mortgageLoanCompanyNameOrigin = usePostBindField('mortgage.loanCompanyNameOrigin');
  const mortgageAddressCompanyName = usePostBindField('mortgage.address.companyName');
  const mortgageAddressLine1 = usePostBindField('mortgage.address.line1');
  const mortgageAddressLine2 = usePostBindField('mortgage.address.line2');
  const mortgageAddressCity = usePostBindField('mortgage.address.city');
  const mortgageAddressState = usePostBindField('mortgage.address.state');
  const mortgageAddressZipcode = usePostBindField('mortgage.address.zipcode');

  useAddFields({
    mortgageAddressCompanyName,
    mortgageAddressLine1,
    mortgageAddressLine2,
    mortgageAddressCity,
    mortgageAddressState,
    mortgageAddressZipcode,
  });

  useAddConditionValues({
    conditionalFields: [
      mortgageAddressCompanyName,
      mortgageAddressLine1,
      mortgageAddressCity,
      mortgageAddressState,
      mortgageAddressZipcode,
    ],
    isExcluded: () => mortgageLoanCompanyNameOrigin.value !== 'OTHER',
    isRequiredOverride: () => true,
  });

  // On selecting any mortgage company except `OTHER` clear all fields
  useEffect(() => {
    if (mortgageLoanCompanyNameOrigin.value && mortgageLoanCompanyNameOrigin.value !== 'OTHER') {
      clearFields(
        mortgageAddressCompanyName,
        mortgageAddressLine1,
        mortgageAddressLine2,
        mortgageAddressCity,
        mortgageAddressState,
        mortgageAddressZipcode,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mortgageLoanCompanyNameOrigin.value]);

  if (!mortgageLoanCompanyNameOrigin.exists) return null;

  return (
    <Grid container item xs={12}>
      <Grid item xs={6} className={classes.mortageCompany}>
        <Select
          {...(mortgageLoanCompanyNameOrigin.props as OptionProps)}
          id='mortgageLoanCompanyNameOrigin'
          label='Mortgage company'
          inputButtonAriaLabel='Mortgage company'
          trackingName='mortgage_company_selection'
        />
      </Grid>
      {mortgageLoanCompanyNameOrigin.value === 'OTHER' && (
        <>
          <GridItem xs={12} topSpacing='sm'>
            <TextField
              {...mortgageAddressCompanyName.props}
              label='Mortgage company name'
              trackingName='mortgage_company_name'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
              actionOnChange={mortgageAddressCompanyName.validateUpdateAndPatch}
            />
          </GridItem>
          <GridItem xs={12} topSpacing='sm'>
            <TextField
              {...mortgageAddressLine1.props}
              label='Street address'
              ariaLabel='Other company address line 1'
              trackingName='mortgage_company_address_line1'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
              actionOnChange={mortgageAddressLine1.validateUpdateAndPatch}
            />
          </GridItem>
          <GridItem xs={12} topSpacing='sm'>
            <TextField
              {...mortgageAddressLine2.props}
              label='Apt./Unit # (optional)'
              ariaLabel='Other company address line 2'
              trackingName='mortage_company_address_line2'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
              actionOnChange={mortgageAddressLine2.validateUpdateAndPatch}
            />
          </GridItem>
          <GridItem xs={12} topSpacing='sm'>
            <TextField
              {...mortgageAddressCity.props}
              label='City'
              ariaLabel='Other company city'
              trackingName='mortgage_company_city'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
              actionOnChange={mortgageAddressCity.validateUpdateAndPatch}
            />
          </GridItem>
          <Grid item container>
            <GridItem xs={6} topSpacing='sm' className={classes.columnLeft}>
              <Select
                {...(mortgageAddressState.props as OptionProps)}
                id='mortgageAddressState'
                label='State'
                inputAriaLabel='Other company state'
                inputButtonAriaLabel='Other company state'
                trackingName='mortgage_company_state'
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
              />
            </GridItem>
            <GridItem xs={6} topSpacing='sm' className={classes.columnRight}>
              <NumberFormat
                {...mortgageAddressZipcode.props}
                label='ZIP code'
                ariaLabel='Other company zip code'
                formatType='zipcode'
                trackingName='mortgage_company_zip_code'
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
                actionOnChange={mortgageAddressZipcode.validateUpdateAndPatch}
              />
            </GridItem>
          </Grid>
        </>
      )}
    </Grid>
  );
});
