import { useCallback } from 'react';

import { StepConnector, Stepper } from '@mui/material';

import { PagePath } from '@ecp/features/sales/shared/routing';
import {
  getActiveRootStepNumber,
  getDeltaAndPurchaseFlows,
  getPageFlowIntro,
  getPageFlowProducts,
  getSummaryDeltaAndPurchaseFlows,
  jumpToPage,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

import { OtherNavbar } from '../OtherNavbar';
import { PersonalInfoNavbar } from '../PersonalInfoNavbar';
import { ProductNavbar } from '../ProductNavbar';
import metadata from './metadata';
import { useStyles } from './NavbarDrawer.styles';

interface Props {
  pagePath?: string;
}

export const NavbarDrawer: React.FC<Props> = (props) => {
  const { pagePath = '' } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { removeOrDisableStep, navbarDrawerImage } = metadata;

  const introPageFlow = useSelector(getPageFlowIntro);
  const productPageFlows = useSelector(getPageFlowProducts);
  const otherPageFlows = useSelector(getSummaryDeltaAndPurchaseFlows);
  const hideQuotePageFlows = useSelector(getDeltaAndPurchaseFlows);

  const activeRootStepNumber = useSelector(getActiveRootStepNumber);

  const jumpInNavbar = useCallback(
    async (pagePath: string): Promise<void> => {
      await dispatch(
        jumpToPage({
          page: pagePath,
          replace: false,
          addErrorHash: pagePath === PagePath.ERROR,
        }),
      );
    },
    [dispatch],
  );

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeRootStepNumber}
        className={classes.rootStepper}
        orientation='vertical'
        connector={<StepConnector classes={{ line: classes.noConnector }} />}
      >
        <PersonalInfoNavbar
          introPageFlow={introPageFlow}
          activeRootStepNumber={removeOrDisableStep ? 0 : activeRootStepNumber}
          jumpInNavbar={jumpInNavbar}
        />
        <ProductNavbar
          productPageFlows={productPageFlows}
          activeRootStepNumber={activeRootStepNumber}
          jumpInNavbar={jumpInNavbar}
          pagePath={pagePath}
        />
        <OtherNavbar
          otherPageFlows={removeOrDisableStep ? hideQuotePageFlows : otherPageFlows}
          activeRootStepNumber={activeRootStepNumber}
          stepIndex={productPageFlows?.length + 1}
          jumpInNavbar={jumpInNavbar}
        />
        {navbarDrawerImage && (
          <img src={navbarDrawerImage} alt='navbar' className={classes.navbarDrawerImage} />
        )}
      </Stepper>
    </div>
  );
};
