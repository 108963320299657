import { useCallback } from 'react';

import { Dialog } from '@ecp/features/sales/shared/components';
import { getBridgeOver, getRecalled, setRecalled } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { CoveragesPage as CoveragesPageBase } from '@ecp/features/sales/shell';

export const CoveragesPage: React.FC = () => {
  const dispatch = useDispatch();
  const offerRecalled = useSelector(getRecalled);

  const isBridgeOver = useSelector(getBridgeOver);
  const handleModalClose = useCallback(() => {
    dispatch(setRecalled(false));
  }, [dispatch]);

  return (
    <>
      {offerRecalled && !isBridgeOver && (
        <Dialog
          actionButtonLabel='Close'
          titleText='Welcome back!'
          buttonPlacement='right'
          open={offerRecalled}
          onClose={handleModalClose}
        >
          <p>
            We're glad you decided to take a closer look. We've pulled in your saved information.
            Adjust your coverages, confirm your policy start date, and calculate your new price.
          </p>
          <br />
          <p>
            If your quote is expired (past the original policy start date), then your quote is now
            updated to reflect tomorrow’s date.
          </p>
        </Dialog>
      )}
      <CoveragesPageBase />
    </>
  );
};
