import { useAddFields } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

export const PrimarySidingTypeQuestion: React.FC<QuestionProps> = (props) => {
  const {
    label = 'What is the primary siding type of the rental property?',
    trackingName = 'primary_siding_type_selection',
  } = props;

  const primarySidingType = useField('primarySidingType');
  const primarySidingTypeOptions = primarySidingType.props.options;

  useAddFields({ primarySidingType });

  if (!primarySidingType.exists) return null;

  return (
    <Select
      {...primarySidingType.props}
      label={label}
      options={primarySidingTypeOptions}
      id='primary_siding_type_selection'
      trackingName={trackingName}
    />
  );
};
