/* eslint-disable @typescript-eslint/no-use-before-define */
import type { ManipulateType } from 'dayjs';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { emptyRegExpMatchArray, Evaluators, Operators } from './constants';
import {
  isAnswer,
  isArray,
  isEmpty,
  isEvaluator,
  isObject,
  isOperator,
  isString,
  isUndefined,
} from './predicates';
import type { Answer, Answers, AnswerSet, Operand, Operation } from './types';

dayjs.extend(utc);
dayjs.extend(tz);

// This regex accepts strings with numbers and decimals only
const isNum = /^\d+\.\d+$|^\d+$/;

interface Context {
  value: Answer;
  answers: Answers;
}

export const operations: { [key in Operators]: (operand: Operand, context: Context) => boolean } = {
  [Operators.REGEX]: (regex, { value }) => new RegExp(regex as string).test(value as string),
  [Operators.EXISTS]: (key, { answers }) => !isUndefined(answers[(key as string).substring(1)]),
  [Operators.EQ]: (operand, context) => {
    const [a, b] = resolveOperand(operand, context);

    return a === b;
  },
  [Operators.EQDEL]: (operand, context) => {
    const [a, b] = resolveOperand(operand, context);
    const [delA] = getDelimiter(a);
    const [delB] = getDelimiter(b);
    if (isString(a) && isString(b) && !isUndefined(delA) && !isUndefined(delB)) {
      const [priorA, laterA] = a.split(delA);
      const [priorB, laterB] = b.split(delB);

      return priorA === priorB && laterA === laterB;
    }

    return a === b;
  },
  [Operators.NE]: (operand, context) => {
    const [a, b] = resolveOperand(operand, context);

    return a !== b;
  },
  [Operators.NEDEL]: (operand, context) => {
    const [a, b] = resolveOperand(operand, context);
    const [delA] = getDelimiter(a);
    const [delB] = getDelimiter(b);
    if (isString(a) && isString(b) && !isUndefined(delA) && !isUndefined(delB)) {
      const [priorA, laterA] = a.split(delA);
      const [priorB, laterB] = b.split(delB);

      return priorA !== priorB || laterA !== laterB;
    }

    return a !== b;
  },
  [Operators.GT]: (operand, context) => {
    const [a, b] = resolveOperand(operand, context);
    if (isString(a) && isString(b) && (a as string).match(isNum) && (b as string).match(isNum)) {
      return parseInt(a) > parseInt(b);
    } else {
      return a > b;
    }
  },
  [Operators.GTDEL]: (operand, context) => {
    const [a, b] = resolveOperand(operand, context);
    const [delA] = getDelimiter(a);
    const [delB] = getDelimiter(b);
    if (isString(a) && isString(b) && !isUndefined(delA) && !isUndefined(delB)) {
      const [priorA, laterA] = a.split(delA);
      const [priorB, laterB] = b.split(delB);
      if (
        (priorA as string).match(isNum) &&
        (laterA as string).match(isNum) &&
        (priorB as string).match(isNum) &&
        (laterB as string).match(isNum)
      ) {
        return parseInt(priorA) > parseInt(priorB) && parseInt(laterA) > parseInt(laterB);
      } else {
        return priorA > priorB && laterA > laterB;
      }
    }
    if (isString(a) && isString(b) && (a as string).match(isNum) && (b as string).match(isNum)) {
      return parseInt(a) > parseInt(b);
    } else {
      return a > b;
    }
  },
  [Operators.GTE]: (operand, context) => {
    const [a, b] = resolveOperand(operand, context);
    if (isString(a) && isString(b) && (a as string).match(isNum) && (b as string).match(isNum)) {
      return parseInt(a) >= parseInt(b);
    } else {
      return a >= b;
    }
  },
  [Operators.GTEDEL]: (operand, context) => {
    const [a, b] = resolveOperand(operand, context);
    const [delA] = getDelimiter(a);
    const [delB] = getDelimiter(b);
    if (isString(a) && isString(b) && !isUndefined(delA) && !isUndefined(delB)) {
      const [priorA, laterA] = a.split(delA);
      const [priorB, laterB] = b.split(delB);
      if (
        (priorA as string).match(isNum) &&
        (laterA as string).match(isNum) &&
        (priorB as string).match(isNum) &&
        (laterB as string).match(isNum)
      ) {
        return parseInt(priorA) >= parseInt(priorB) && parseInt(laterA) >= parseInt(laterB);
      } else {
        return priorA >= priorB && laterA >= laterB;
      }
    }
    if (isString(a) && isString(b) && (a as string).match(isNum) && (b as string).match(isNum)) {
      return parseInt(a) >= parseInt(b);
    } else {
      return a >= b;
    }
  },
  [Operators.LT]: (operand, context) => {
    const [a, b] = resolveOperand(operand, context);
    if (isString(a) && isString(b) && (a as string).match(isNum) && (b as string).match(isNum)) {
      return parseInt(a) < parseInt(b);
    } else {
      return a < b;
    }
  },
  [Operators.LTDEL]: (operand, context) => {
    const [a, b] = resolveOperand(operand, context);
    const [delA] = getDelimiter(a);
    const [delB] = getDelimiter(b);
    if (isString(a) && isString(b) && !isUndefined(delA) && !isUndefined(delB)) {
      const [priorA, laterA] = a.split(delA);
      const [priorB, laterB] = b.split(delB);
      if (
        (priorA as string).match(isNum) &&
        (laterA as string).match(isNum) &&
        (priorB as string).match(isNum) &&
        (laterB as string).match(isNum)
      ) {
        return parseInt(priorA) < parseInt(priorB) && parseInt(laterA) < parseInt(laterB);
      } else {
        return priorA < priorB && laterA < laterB;
      }
    }
    if (isString(a) && isString(b) && (a as string).match(isNum) && (b as string).match(isNum)) {
      return parseInt(a) < parseInt(b);
    } else {
      return a < b;
    }
  },
  [Operators.LTE]: (operand, context) => {
    const [a, b] = resolveOperand(operand, context);
    if (isString(a) && isString(b) && (a as string).match(isNum) && (b as string).match(isNum)) {
      return parseInt(a) <= parseInt(b);
    } else {
      return a <= b;
    }
  },
  [Operators.LTEDEL]: (operand, context) => {
    const [a, b] = resolveOperand(operand, context);
    const [delA] = getDelimiter(a);
    const [delB] = getDelimiter(b);
    if (isString(a) && isString(b) && !isUndefined(delA) && !isUndefined(delB)) {
      const [priorA, laterA] = a.split(delA);
      const [priorB, laterB] = b.split(delB);
      if (
        (priorA as string).match(isNum) &&
        (laterA as string).match(isNum) &&
        (priorB as string).match(isNum) &&
        (laterB as string).match(isNum)
      ) {
        return parseInt(priorA) <= parseInt(priorB) && parseInt(laterA) <= parseInt(laterB);
      } else {
        return priorA <= priorB && laterA <= laterB;
      }
    }
    if (isString(a) && isString(b) && (a as string).match(isNum) && (b as string).match(isNum)) {
      return parseInt(a) <= parseInt(b);
    } else {
      return a <= b;
    }
  },
  [Operators.BETWEEN]: (operand, context) => {
    const [low, high] = resolveOperand(operand, context);

    return context.value >= low && context.value <= high;
  },
  [Operators.IN]: (operand, context) => resolveOperand(operand, context).includes(context.value),
  [Operators.NIN]: (operand, context) => !resolveOperand(operand, context).includes(context.value),
  [Operators.IS_EVEN]: (operand, context) =>
    (resolveOperand(operand, context)[1] as number) % 2 === 0,
  [Operators.IS_ODD]: (operand, context) =>
    (resolveOperand(operand, context)[1] as number) % 2 !== 0,
  [Operators.AND]: (operand, context) => {
    for (let i = 0, n = (operand as Operand[]).length; i < n; i++) {
      if (!resolveOperation((operand as Operand[])[i] as Operation, context)) return false;
    }

    return true;
  },
  [Operators.OR]: (operand, context) => {
    for (let i = 0, n = (operand as Operand[]).length; i < n; i++) {
      if (resolveOperation((operand as Operand[])[i] as Operation, context)) return true;
    }

    return false;
  },
  [Operators.NOR]: (operand, context) => {
    for (let i = 0, n = (operand as Operand[]).length; i < n; i++) {
      if (resolveOperation((operand as Operand[])[i] as Operation, context)) return false;
    }

    return true;
  },
  [Operators.NOT]: (operand, context) => !resolveOperation(operand as Operation, context),
  [Operators.ANY]: (operand, context) => {
    const [[reference, operation]] = Object.entries(operand as Operation);
    const values = Object.values<Answer>(resolveReferences(reference, context) as Answers);
    for (let i = 0, n = values.length; i < n; i++) {
      if (resolveOperation(operation as Operation, { ...context, value: values[i] })) return true;
    }

    return false;
  },
  [Operators.EVERY]: (operand, context) => {
    const [reference, operation] = Object.entries(operand as Operation)[0];
    const values = Object.values<Answer>(resolveReferences(reference, context) as Answers);
    for (let i = 0, n = values.length; i < n; i++) {
      if (!resolveOperation(operation as Operation, { ...context, value: values[i] })) return false;
    }

    return true;
  },
};

export const evaluations: { [key in Evaluators]: (operand: Operand, context: Context) => Answer } =
  {
    [Evaluators.ADD]: (operand, context) => {
      const [a, b] = resolveOperand(operand, context);

      return (a as number) + (b as number);
    },
    [Evaluators.SUBTRACT]: (operand, context) => {
      const [a, b] = resolveOperand(operand, context);

      return (a as number) - (b as number);
    },
    [Evaluators.MULTIPLY]: (operand, context) => {
      const [a, b] = resolveOperand(operand, context);

      return (a as number) * (b as number);
    },
    [Evaluators.DIVIDE]: (operand, context) => {
      const [a, b] = resolveOperand(operand, context);

      return (a as number) / (b as number);
    },
    [Evaluators.SUM]: (operand, context) => {
      const [a, b] = resolveOperand(operand, context);

      return (a as number) + (b as number);
    },
    [Evaluators.PRODUCT]: (operand, context) => {
      const [a, b] = resolveOperand(operand, context);

      return (a as number) * (b as number);
    },
    [Evaluators.MODULO]: (operand, context) => {
      const [a, b] = resolveOperand(operand, context);

      return (a as number) % (b as number);
    },
    [Evaluators.COUNT]: (operand, context) => {
      if (isString(operand)) return Object.keys(resolveReferences(operand, context)).length;
      const [reference, operation] = Object.entries(operand as Operation)[0];
      const answers = resolveReferences(reference, context);

      return Object.values(answers).filter((value) => {
        return resolveOperation(operation as Operation, { ...context, value });
      }).length;
    },
    [Evaluators.DATE]: (operand) => {
      let date = dayjs();
      const mods = (operand as string).match(/[+-]\d+\s*[A-Za-z]+/g) || emptyRegExpMatchArray;
      const format = (operand as string).match(/fmt:(\S+)/) || emptyRegExpMatchArray;

      mods.forEach((mod) => {
        const [a, unit] = mod.split(' ');
        const cmd = a.substring(0, 1) === '+' ? 'add' : 'subtract';
        const amount = a.substring(1);
        date = date[cmd](parseInt(amount), unit as ManipulateType);
      });

      return date.tz('America/New_York').format(format[1]);
    },
  };

export function getDelimiter(operand: Answer): RegExpMatchArray {
  // This if clause will make sure that number type answer will also be treated as string type.
  if (isAnswer(operand)) operand = operand.toString();

  return operand.match(/([/|:;,&])/g) || emptyRegExpMatchArray;
}

export function resolveOperand(operand: Operand, context: Context): Answer[] {
  const values = isArray(operand) ? operand : [context.value, operand];

  return values.map((value) => {
    if (isString(value)) return resolveReference(value, context);
    if (isAnswer(value)) return value;

    return resolveEvaluation(value as Operation, context);
  });
}

export function resolveEvaluation(operation: Operation, context: Context): Answer {
  const [[key, operand]] = Object.entries(operation);
  if (isEvaluator(key)) return evaluations[key](operand, context);
  const value = resolveReference(key, context);

  return resolveEvaluation(operand as Operation, { ...context, value });
}

export function resolveOperation(operation: Operation, context: Context): boolean {
  if (!isObject(operation)) throw new Error('Unexpected Operation');

  return Object.entries(operation).every(([key, operand]) => {
    if (isOperator(key)) return operations[key](operand, context);
    const value = resolveReference(key, context);

    return resolveOperation(operand as Operation, { ...context, value });
  });
}

export function resolveReference(input: string, context: Context): Answer {
  const value = resolveReferences(input, context);

  return value as Answer;
}

export function resolveReferences(input: string, { answers }: Context): Answer | AnswerSet {
  if (!input.startsWith('@')) return input;
  const ref = input.substring(1);
  if (ref.includes('<>')) {
    const pattern = ref.replace(/<>/g, '\\w+');
    const exp = new RegExp(pattern);

    return Object.keys(answers).reduce((aggregate, key) => {
      if (exp.test(key) && isAnswer(answers[key])) aggregate[key] = answers[key];

      return aggregate;
    }, {} as AnswerSet);
  }
  if (ref.endsWith('.length') && isUndefined(answers[ref])) {
    const r = ref.slice(0, ref.length - 7);
    if (isString(answers[r])) return (answers[r] as string).length;
  }

  return isEmpty(answers[ref]) ? '' : answers[ref];
}
