import { createReducer } from '@reduxjs/toolkit';

import { setEnrollmentsApiPostSuccess } from './actions';
import type { EnrollmentsState } from './types';

const enrollmentsInitial: EnrollmentsState = {
  enrollments: {},
};

export const enrollmentsReducer = createReducer(enrollmentsInitial, (builder) => {
  builder
    .addCase(setEnrollmentsApiPostSuccess, (state, { payload }) => {
      state.enrollments = payload.data;
    })
    .addMatcher(
      (action): action is { type: '@global/teardownStore' } =>
        action.type === '@global/teardownStore',
      () => enrollmentsInitial,
    );
});
