import { merge } from '@ecp/utils/common';

import type { PaletteOverrides } from '@ecp/themes/base';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { palette as basePalette } from '../../../../base/src';

const paletteOverrides: PaletteOverrides = {
  actions: {
    disabledBg: '#eaeaea',
    disabledText: '#78777b',
  },
  error: {
    contrastText: '#ffffff',
    dark: '#a11b58',
    darkText: '#2d2d2d',
    light: '#fdf4f6',
    main: '#d41659',
  },
  grey: {
    '50': '#ffffff',
    '100': '#fafafa',
    '200': '#f7f7f7',
    '300': '#eaeaea',
    '400': '#d4d4d4',
    '500': '#cccccc',
    '600': '#afaeb0',
    '700': '#78777b',
    '800': '#4c4c4c',
    '900': '#2d2d2d',
  },
  icon: {
    default: '#ffffff',
    hover: '#0077b3',
    stroke: '#78777b',
  },
  info: {
    contrastText: '#ffffff',
    dark: '#2862a4',
    darkText: '#2d2d2d',
    light: '#f3f9fc',
    main: '#0077b3',
  },
  other: {
    backdrop: '#2d2d2d66',
    background: '#2862a4',
    backgroundContrastText: '#ffffff',
    body: '#ffffff',
    border: '#d4d4d4',
    caution: '#a86800',
    cookieBanner: '#ffffff',
    divider: '#eaeaea',
    snackbar: '#2d2d2d',
    tooltip: '#2d2d2d',
  },
  partner: {
    footerBackground: '#fafafa',
    footerTextLink: '#0077b3',
    footerTextPrimary: '#2d2d2d',
    footerTextSecondary: '#4c4c4c',
    headerBackground: '#ffffff',
  },
  primary: {
    background: '#f3f9fc',
    border: '#0077b3',
    contrastText: '#ffffff',
    dark: '#2862a4',
    darkText: '#2862a4',
    light: '#f3f9fc',
    main: '#0077b3',
  },
  secondary: {
    background: '#f3f9fc',
    border: '#0077b3',
    contrastText: '#ffffff',
    dark: '#2862a4',
    darkText: '#2862a4',
    light: '#f3f9fc',
    main: '#0077b3',
  },
  shadow: {
    primary: '#00000033',
  },
  success: {
    complete: '#ffffff',
    contrastText: '#ffffff',
    dark: '#067462',
    darkText: '#2d2d2d',
    light: '#effbf7',
    main: '#0ba88d',
  },
  text: {
    disabled: '#78777b',
    hover: '#2862a4',
    link: '#0077b3',
    primary: '#2d2d2d',
    secondary: '#4c4c4c',
    tertiary: '#6f6e72',
  },
  warning: {
    contrastText: '#ffffff',
    dark: '#a86800',
    darkText: '#2d2d2d',
    light: '#fff8e5',
    main: '#ffd039',
  },
};

export const palette = merge({}, basePalette, paletteOverrides);
