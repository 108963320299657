import { Alert } from '@ecp/components';
import { getAreSomeSelectedProductsIndicative } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { IconUIExclaimTriangle } from '@ecp/themes/base';

import { useStyles } from './IndicativeQuoteWarning.styles';

export const IndicativeQuoteWarning: React.FC = () => {
  const { classes } = useStyles();
  const areSomeSelectedProductsIndicative = useSelector(getAreSomeSelectedProductsIndicative);
  if (!areSomeSelectedProductsIndicative) return null;

  return (
    <div className={classes.indicativeQuoteWarning}>
      <Alert
        withIcon
        icon={<IconUIExclaimTriangle className={classes.indicativeQuoteWarningIcon} />}
        type='warning'
      >
        This is an <strong>estimated premium</strong>. We need additional information from the
        customer before returning a bindable quote.
      </Alert>
      <div className={classes.carret} />
    </div>
  );
};
