/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { VehiclesState } from './types';

const initialState: VehiclesState = {
  images: {},
};

export const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {
    imagesUpdated(state, { payload }: PayloadAction<{ [key: string]: string }>) {
      state.images = { ...state.images, ...payload };
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      (action) => action.type === '@global/teardownStore',
      () => initialState,
    );
  },
});

export const { imagesUpdated } = vehiclesSlice.actions;
