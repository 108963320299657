import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'SecondaryNamedInsuredForm' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    ...theme.mixins.form,
    marginTop: 0,
  },
  next: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  questionLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    margin: '40px 0px 40px 0px',
    width: '100%',
  },
  labelIcon: {
    '& svg': {
      width: 30,
      height: 30,
      ...theme.mixins.setColorSvg(theme.palette.icon.hover),
      marginRight: 10,
    },
  },
  optional: {
    color: theme.palette.text.tertiary,
    marginLeft: 5,
  },
  questionSubLabel: {
    ...theme.typography.body1,
    marginRight: 5,
  },
  checkBox: {
    marginRight: 5,
  },
  select: {
    '& .MuiSelect-select': {
      paddingLeft: '10px',
    },
  },
  dobPlaceHolder: {
    '& .MuiSelect-select': {
      WebkitTextFillColor: theme.palette.text.tertiary,
    },
  },
  disabled: {
    color: theme.palette.text.tertiary,
  },
  secondaryText: {
    ...theme.typography.body1,
    fontSize: '14px',
    fontWeight: 400,
    color: '#4C4C4C',
  },
  primaryTextLabel: {
    ...theme.typography.body4,
  },
  sniText: {
    paddingBottom: '8px',
  },
}));
