import { createAction } from '@reduxjs/toolkit';

import type { PurchaseErrorReason } from '@ecp/features/sales/shared/constants';

import type { GlobalError } from './types';

export const updateGlobalError = createAction<GlobalError>('@global/errorUpdated');
export const updatePurchaseError = createAction<PurchaseErrorReason | null>(
  '@global/purchaseErrorUpdated',
);
export const updateGlobalSeed = createAction<number>('@global/seedUpdated');
export const updateInitializing = createAction<boolean>('@global/initializingUpdated');
export const updateStartedInitializing = createAction<boolean>(
  '@global/startedInitializingUpdated',
);
export const updateShowThirdPartyBanner = createAction<boolean>(
  '@global/showThirdPartyBannerUpdated',
);
export const updateShouldTeardownApp = createAction<boolean>('@global/shouldTeardownAppUpdated');
export const teardownStore = createAction('@global/teardownStore');
export const updateBridgeOver = createAction<boolean>('@global/bridgeOver');

export type GlobalActions = ReturnType<
  | typeof updateGlobalError
  | typeof updatePurchaseError
  | typeof updateGlobalSeed
  | typeof updateInitializing
  | typeof updateStartedInitializing
  | typeof updateShowThirdPartyBanner
  | typeof updateShouldTeardownApp
  | typeof teardownStore
  | typeof updateBridgeOver
>;
