export const INVALID_LICENSE_NUMBER_MSG = 'Please enter a valid license number';

export const LOB_HOME_NOT_ELIGIBLE_MSG = 'We don`t offer home insurance in your state yet.';

export const LOB_AUTO_NOT_ELIGIBLE_MSG = 'We don`t offer auto insurance in your state yet.';

export const LOB_RENTERS_NOT_ELIGIBLE_MSG = 'We don`t offer renters insurance in your state yet.';

export const LOB_BUNDLE_NOT_ELIGIBLE_MSG =
  'We don’t offer the selected insurance products in your state yet.';

export const PARTNER_NOT_ELIGIBLE_MSG = 'Sorry, your state is not supported yet.';

export const AGENT_NOT_LICENSED_FOR_STATE_MSG = 'Agent is not appointed in this state.';
