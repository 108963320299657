import type { PolicySummary as PolicySummaryProp } from '@ecp/features/sales/shared/types';

import { PolicySummary } from '../PolicySummary';
import metadata from './metadata';

interface Props {
  policies: PolicySummaryProp[];
}

/** @deprecated To be removed, this seems like redundancy. */
export const CheckoutReview: React.FC<Props> = (props) => {
  const { policies } = props;

  return <PolicySummary policySummaries={policies} policyTitle={metadata.policyTitle} />;
};
