import type { PopoverOrigin, PopoverProps } from '@mui/material';
import { Divider, Popover as MuiPopover } from '@mui/material';

import { IconUIClose, IconUIExclaimTriangleImageUrl } from '@ecp/themes/base';

import { Button } from '../Button';
import { useStyles } from './Popover.styles';

interface Props {
  open?: boolean;
  body?: React.ReactElement;
  onClose?: () => void;
  anchorEl: PopoverProps['anchorEl'];
  anchorOrigin: PopoverOrigin;
  transformOrigin: PopoverOrigin;
  title?: string;
  showPointer?: boolean;
  variant?: string;
}

export const Popover: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    showPointer,
    title,
    anchorOrigin,
    transformOrigin,
    children,
    open = false,
    onClose,
    anchorEl,
    variant,
  } = props;
  const { classes } = useStyles();

  if (variant === 'warning') {
    return (
      <MuiPopover
        onClose={onClose}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <div className={classes.warningContainer}>
          <img className={classes.warningImage} src={IconUIExclaimTriangleImageUrl} alt='Warning' />
          {children}
          <Button
            variant='iconText'
            onClick={onClose}
            className={classes.warningCloseButton}
            data-testid='closeIcon'
            aria-label='Close warning'
          >
            <IconUIClose className={classes.warningCloseIcon} />
          </Button>
        </div>
      </MuiPopover>
    );
  }

  return (
    <MuiPopover
      onClose={onClose}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      classes={showPointer ? { paper: classes.pointer } : undefined}
    >
      <div className={classes.popoverContainer}>
        <div className={classes.titleContainer}>
          <h4 className={classes.title}>{title}</h4>
          <Button
            variant='iconText'
            onClick={onClose}
            className={classes.closeButton}
            data-testid='closeIcon'
            aria-label='Close dialog'
          >
            <IconUIClose />
          </Button>
        </div>
        <Divider aria-hidden='true' className={classes.titleDivider} />
        {children}
      </div>
    </MuiPopover>
  );
};
