export interface Metadata {
  mvrText?: string;
}

const metadata: Metadata = {
  mvrText:
    'We are unable to order driving records at this time. You may purchase this policy, but undisclosed or inaccurate driving or claim activity may result in a premium adjustment or the cancelation of your policy once the reports are obtained.',
};

export default metadata;
