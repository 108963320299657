import { useEffect, useState } from 'react';

import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { useEvent } from '@ecp/utils/react';
import { location, useSearchParams } from '@ecp/utils/routing';

import { env } from '@ecp/env';
import { LINE_OF_BUSINESS, LOB_ORDER } from '@ecp/features/sales/shared/constants';
import { PagePath } from '@ecp/features/sales/shared/routing';
import {
  getBlobRetrieveFailed,
  getDalSessionId,
  getEligibleProductsFromSapi,
  getLineOfBusiness,
  getLobOrder,
  getRecallErrorMessage,
  getUserSelection,
  jumpToPage,
  recallOffers,
  updateAnswers,
  useGetRetrieveFields,
} from '@ecp/features/sales/shared/store';
import type { ThunkAction } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Answers } from '@ecp/features/sales/shared/types';
import { productMatchesLOB } from '@ecp/features/shared/product';

import { GetQuote } from './GetQuote';
import metadata from './metadata';
import { RetrieveForm } from './RetrieveForm';

export const useLandingPage = (): {
  formType: string;
  isLoading: boolean;
  isRetrieve: boolean;
  quoteFormComponent: React.ReactElement;
  retrieveFormComponent: React.ReactElement;
} => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const blobRetrieveFailed = useSelector(getBlobRetrieveFailed);
  const retrieveFields = useGetRetrieveFields();
  const recallError = useSelector(getRecallErrorMessage);
  const [isRecallInProgress, setIsRecallInProgress] = useState(false);
  const { showRetrieve, retrieve: encryptedData } = useSearchParams();
  const dalSessionId = useSelector(getDalSessionId);

  const handleRetrieveFormNext = useEvent(async () => {
    const { person, zipCode } = retrieveFields;
    setIsRecallInProgress(true);
    await dispatch(
      recallOffers({
        person,
        zipcode: zipCode.props.value,
        initialRecallRequest: true,
      }),
    );
    setIsRecallInProgress(false);
  });
  const initialForm = encryptedData || metadata.disableLandingPage ? 'retrieveForm' : undefined;
  const formType = 'landingPage';
  const displayRecallError = blobRetrieveFailed ? '' : recallError;
  const isRetrieve = showRetrieve === 'true' || initialForm === 'retrieveForm';

  const quoteFormComponent = <GetQuote />;
  const retrieveFormComponent = (
    <RetrieveForm
      chaseLinkError={blobRetrieveFailed}
      recallError={displayRecallError || ''}
      isRecallInProgress={isRecallInProgress}
      onNext={handleRetrieveFormNext}
      formType={formType}
    />
  );

  useEffect(() => {
    const handleEffect = async (): Promise<void> => {
      // Do not remove this check otherwise the app is going to crash
      // due to improperly implemented Save and Continue logic on the coverages and checkout pages
      if (!dalSessionId) {
        return;
      }

      try {
        const didRedirect = await dispatch(handleRedirectToSelectProductPage);
        if (!didRedirect) await dispatch(setDefaultLineOfBusinessIfAbsent);
      } finally {
        setIsLoading(false);
      }
    };
    handleEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dalSessionId]);

  return {
    isLoading,
    isRetrieve,
    quoteFormComponent,
    retrieveFormComponent,
    formType,
  };
};

/**
 * Redirects to select product page if line of business was initially absent and other conditions are met.
 * If conditions are met, redirects and returns `true`, otherwise does not redirect and returns `false`.
 */
const handleRedirectToSelectProductPage: ThunkAction<Promise<boolean>> = async (
  dispatch,
  getState,
) => {
  if (!flagValues[FeatureFlags.SELECT_PRODUCT_PAGE]) return false;
  if (location.search.showRetrieve) return false;
  if (location.search.retrieve) return false;

  const state = getState();
  const lineOfBusiness = getLineOfBusiness(state);
  const eligibleProductsFromSapi = getEligibleProductsFromSapi(state);
  const isLineOfBusinessSupported = eligibleProductsFromSapi.some((product) =>
    productMatchesLOB(lineOfBusiness, product),
  );

  if (isLineOfBusinessSupported) return false;

  await dispatch(jumpToPage({ page: PagePath.SELECT_PRODUCT, skipError: true }));

  return true;
};

/**
 * Sets default line of business and unbundled selection if they have been never set before.
 * This should NOT run before handleRedirectToSelectProductPage because handleRedirectToSelectProductPage
 * needs to know if line of business was initially absent.
 */
const setDefaultLineOfBusinessIfAbsent: ThunkAction<Promise<void>> = async (dispatch, getState) => {
  const state = getState();
  const lineOfBusiness = getLineOfBusiness(state);
  const userSelection = getUserSelection(state);
  const eligibleProductsFromSapi = getEligibleProductsFromSapi(state);
  const isLineOfBusinessSupported = eligibleProductsFromSapi.some((product) =>
    productMatchesLOB(lineOfBusiness, product),
  );

  if (isLineOfBusinessSupported && userSelection) return;
  // Avoid defaulting LOB on retrieve when SELECT_PRODUCT_PAGE is enabled. This is currently applicable for Zillow, but should be considered for any future partners who enable this flag. Users have options like home and renters to choose from.
  const shouldDefaultLob = !(
    location.search.showRetrieve && flagValues[FeatureFlags.SELECT_PRODUCT_PAGE]
  );

  if (!isLineOfBusinessSupported && shouldDefaultLob && env.static.defaultLineOfBusiness) {
    const answers: Answers = {};
    const lobOrder = getLobOrder(state, env.static.defaultLineOfBusiness);
    answers[LINE_OF_BUSINESS] = env.static.defaultLineOfBusiness;
    answers[LOB_ORDER] = lobOrder;
    await dispatch(updateAnswers({ answers }));
  }
};
