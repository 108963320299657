import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { useSearchParams } from '@ecp/utils/routing';

import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import { getLineOfBusiness } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

const useDetermineAlternateStartingPage = (): PagePath => {
  const { retrieve: encryptedData, showRetrieve } = useSearchParams();
  const lineOfBusiness = useSelector(getLineOfBusiness);

  const isChaseLink = !!encryptedData;
  const initialForm = isChaseLink ? 'retrieveForm' : undefined;
  const isCurrentPageRetrieve = showRetrieve === 'true' || initialForm === 'retrieveForm';

  if (!isCurrentPageRetrieve) return PagePath.LANDING_RETRIEVE;

  if (flagValues[FeatureFlags.SELECT_PRODUCT_PAGE] && !lineOfBusiness)
    return PagePath.SELECT_PRODUCT;

  return PagePath.LANDING;
};

export const useToggleLandingAndRetrievePages = (): ReturnType<typeof useNavigateToPage> => {
  const page = useDetermineAlternateStartingPage();

  return useNavigateToPage(page, { removeQuery: true });
};
