import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'SummaryHeader' })((theme) => ({
  summaryHeader: {
    ...theme.typography.body1,
    maxWidth: 300,
  },
  typeDiv: {
    alignSelf: 'center',
  },
  type: {
    position: 'relative',
    float: 'left',
    textTransform: 'capitalize',
    margin: 0,
  },
  verticalDivider: {
    border: `0.5px solid ${theme.palette.other.divider}`,
    width: 0,
    margin: '0 15px',
  },
  premiumTextLine2: theme.typography.body2,
  premiumColumn: {
    alignItems: 'flex-end',
  },
  term: theme.typography.body1,
  recalculatingText: {
    color: theme.palette.grey[800],
  },

  expandIconPanel: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  expandIcon: theme.mixins.setColorSvg(theme.palette.primary.light),
  expandIconButton: {
    padding: 0,
  },
}));
