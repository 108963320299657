import { StrictMode } from 'react';

import { CssBaseline } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { BrowserRouter } from '@ecp/utils/routing';

import { theme, ThemeProvider } from '@ecp/themes/base';

import { App } from './app/App';
import { initializeAndGetStore, initializeGlobal } from './initialize';

initializeGlobal().then(() => {
  const store = initializeAndGetStore();

  const container = document.getElementById('root');
  const root = createRoot(container as HTMLElement);
  root.render(
    <StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </Provider>
      </BrowserRouter>
    </StrictMode>,
  );
});
