import { wrapThunkActionWithErrHandler } from '@ecp/features/sales/shared/store';

import { setEnrollmentsApiPostSuccess } from './actions';
import { getEnrollments } from './api';

type FetchEnrollmentsResponse = {
  dalSessionId: string | undefined;
  offerSetId: string | undefined;
};

export const fetchEnrollmentsResponse = wrapThunkActionWithErrHandler<FetchEnrollmentsResponse>(
  ({ dalSessionId, offerSetId }) =>
    async (dispatch) => {
      if (!dalSessionId || !offerSetId) {
        return;
      }

      const response = await getEnrollments({
        dalSessionId,
      });
      dispatch(setEnrollmentsApiPostSuccess(response.payload));
    },
  'fetchEnrollmentsResponse',
);
