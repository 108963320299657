import { useCallback } from 'react';

import { ensureStringArray } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';

import {
  createRef,
  deleteAnswers,
  getAllValues,
  getFieldsByKeys,
  getInquiryLoaded,
  getValueForPrefix,
  updateAddedRef,
  updateAnswers,
  updateRemovedRef,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Answers } from '@ecp/features/sales/shared/types';
import type { Fields } from '@ecp/types';

import {
  FLOOR_REF,
  FLOOR_TYPE_PERCENTAGE_SUFFIX,
  FLOOR_TYPE_REF_NAME,
  FLOOR_TYPE_SUFFIX,
  INTERIOR_WALL_MATERIAL_PERCENTAGE_SUFFIX,
  INTERIOR_WALL_MATERIAL_SUFFIX,
  INTERIOR_WALL_REF,
  INTERIOR_WALL_REF_NAME,
} from '../../constants';

interface BaseInteriorWall {
  material: string;
  percentage: string;
}

interface InteriorWall extends BaseInteriorWall {
  ref: string;
}

interface BaseFloorType {
  type: string;
  percentage: string;
}

interface FloorType extends BaseFloorType {
  ref: string;
}

const getInteriorWall = (ref: string, allValues: Answers): InteriorWall => {
  const getString = getValueForPrefix<string>(ref, allValues);

  return {
    material: getString(INTERIOR_WALL_MATERIAL_SUFFIX),
    percentage: getString(INTERIOR_WALL_MATERIAL_PERCENTAGE_SUFFIX),
    ref,
  };
};

const useInteriorWalls = (): InteriorWall[] => {
  const allValues = useSelector(getAllValues);
  const refs: string[] = ensureStringArray(allValues[INTERIOR_WALL_REF]);

  return refs.map((ref) => getInteriorWall(ref, allValues));
};

export const useInteriorWallPercentages = (): { [key: string]: string } => {
  const interiorWalls = useInteriorWalls();

  return interiorWalls.reduce<{ [key: string]: string }>((percentages, interiorWall) => {
    return { ...percentages, [interiorWall.material]: interiorWall.percentage };
  }, {});
};

export const useGetInteriorPercentageFields = (keys: string[]): Fields => {
  const dispatch = useDispatch();

  return useSelector((state: RootStore) => getFieldsByKeys(state, '', keys, dispatch));
};

const getFloorType = (ref: string, allValues: Answers): FloorType => {
  const getString = getValueForPrefix<string>(ref, allValues);

  return {
    type: getString(FLOOR_TYPE_SUFFIX),
    percentage: getString(FLOOR_TYPE_PERCENTAGE_SUFFIX),
    ref,
  };
};

export const useFloorTypes = (): FloorType[] => {
  const allValues = useSelector(getAllValues);
  const refs: string[] = ensureStringArray(allValues[FLOOR_REF]);

  return refs.map((ref) => getFloorType(ref, allValues));
};

export const useFloorTypePercentages = (): { [key: string]: string } => {
  const floorTypes = useFloorTypes();

  return floorTypes.reduce<{ [key: string]: string }>((percentages, floorType) => {
    return { ...percentages, [floorType.type]: floorType.percentage };
  }, {});
};

const useAddRef = (refType: string, refName: string): (() => Promise<string>) => {
  const dispatch = useDispatch();
  const allValues = useSelector(getAllValues);
  const inquiryLoaded = useSelector(getInquiryLoaded);

  return useCallback(async (): Promise<string> => {
    if (!inquiryLoaded) {
      datadogLog({
        logType: 'error',
        message: 'inquiry not loaded',
        context: {
          logOrigin: 'libs/features/sales/quotes/property/home/src/state/util/homeInteriorUtil.ts',
          functionOrigin: 'useAddRef/useCallback',
        },
      });
      throw new Error('inquiry not loaded');
    }
    const newRef = dispatch(createRef(refName));
    await dispatch(updateAddedRef({ allValues, type: refType, newRef }));

    return newRef;
  }, [allValues, dispatch, inquiryLoaded, refType, refName]);
};

export const useInteriorWallGroup = (): {
  addAndUpdateInteriorWall: (interiorWall: BaseInteriorWall) => Promise<void>;
  updateInteriorWalls: (params: BaseInteriorWall[]) => Promise<void>;
  removeInteriorWall: (material: string) => Promise<void>;
  addAndUpdateAllInteriorWalls: (
    newInteriorWall: BaseInteriorWall,
    updateInteriorWalls: BaseInteriorWall[],
  ) => Promise<void>;
} => {
  const dispatch = useDispatch();
  const addInteriorWallRef = useAddRef(INTERIOR_WALL_REF, INTERIOR_WALL_REF_NAME);
  const interiorWalls = useInteriorWalls();
  const allValues = useSelector(getAllValues);

  const addAndUpdateInteriorWall = useCallback(
    async (interiorWall: BaseInteriorWall) => {
      const newRef = await addInteriorWallRef();
      await dispatch(
        updateAnswers({
          answers: {
            [`${newRef}.${INTERIOR_WALL_MATERIAL_SUFFIX}`]: interiorWall.material,
            [`${newRef}.${INTERIOR_WALL_MATERIAL_PERCENTAGE_SUFFIX}`]: interiorWall.percentage,
          },
        }),
      );
    },
    [dispatch, addInteriorWallRef],
  );

  const updateInteriorWalls = useCallback(
    async (params: BaseInteriorWall[]) => {
      const answers = params.reduce<Answers>((preAnswers, p) => {
        const interiorWall = interiorWalls.find((iw) => iw.material === p.material);
        if (!interiorWall) {
          datadogLog({
            logType: 'error',
            message: `Not found interior wall for value "${p.material}"`,
            context: {
              logOrigin:
                'libs/features/sales/quotes/property/home/src/state/util/homeInteriorUtil.ts',
              functionOrigin: 'updateInteriorWalls',
            },
          });
          throw new Error(`Not found interior wall for value "${p.material}"`);
        }

        return {
          ...preAnswers,
          [`${interiorWall.ref}.${INTERIOR_WALL_MATERIAL_PERCENTAGE_SUFFIX}`]: p.percentage,
        };
      }, {});
      await dispatch(updateAnswers({ answers }));
    },
    [dispatch, interiorWalls],
  );

  const removeInteriorWall = useCallback(
    async (material: string) => {
      const interiorWall = interiorWalls.find((iw) => iw.material === material);
      if (!interiorWall) {
        datadogLog({
          logType: 'error',
          message: `Not found interior wall for value "${material}"`,
          context: {
            logOrigin:
              'libs/features/sales/quotes/property/home/src/state/util/homeInteriorUtil.ts',
            functionOrigin: 'removeInteriorWall',
          },
        });
        throw new Error(`Not found interior wall for value "${material}"`);
      }
      await dispatch(
        updateRemovedRef({ refType: INTERIOR_WALL_REF, allValues, removedRef: interiorWall.ref }),
      );
      await dispatch(deleteAnswers({ ref: interiorWall.ref }));
    },
    [dispatch, interiorWalls, allValues],
  );

  const addAndUpdateAllInteriorWalls = useCallback(
    async (newInteriorWall: BaseInteriorWall, updateInteriorWalls: BaseInteriorWall[]) => {
      const newRef = await addInteriorWallRef();

      const answers = updateInteriorWalls.reduce<Answers>((preAnswers, p) => {
        const interiorWall = interiorWalls.find((iw) => iw.material === p.material);
        if (!interiorWall) {
          datadogLog({
            logType: 'error',
            message: `Not found interior wall for value "${p.material}"`,
            context: {
              logOrigin:
                'libs/features/sales/quotes/property/home/src/state/util/homeInteriorUtil.ts',
              functionOrigin: 'updateInteriorWalls',
            },
          });
          throw new Error(`Not found interior wall for value "${p.material}"`);
        }

        return {
          ...preAnswers,
          [`${interiorWall.ref}.${INTERIOR_WALL_MATERIAL_PERCENTAGE_SUFFIX}`]: p.percentage,
        };
      }, {});

      await dispatch(
        updateAnswers({
          answers: {
            ...answers,
            [`${newRef}.${INTERIOR_WALL_MATERIAL_SUFFIX}`]: newInteriorWall.material,
            [`${newRef}.${INTERIOR_WALL_MATERIAL_PERCENTAGE_SUFFIX}`]: newInteriorWall.percentage,
          },
        }),
      );
    },
    [dispatch, addInteriorWallRef, interiorWalls],
  );

  return {
    addAndUpdateInteriorWall,
    updateInteriorWalls,
    removeInteriorWall,
    addAndUpdateAllInteriorWalls,
  };
};

export const useFloorTypeGroup = (): {
  addAndUpdateFloorType: (floorType: BaseFloorType) => Promise<void>;
  updateFloorTypes: (params: BaseFloorType[]) => Promise<void>;
  removeFloorType: (type: string) => Promise<void>;
} => {
  const dispatch = useDispatch();
  const addFloorRef = useAddRef(FLOOR_REF, FLOOR_TYPE_REF_NAME);
  const floorTypes = useFloorTypes();
  const allValues = useSelector(getAllValues);

  const addAndUpdateFloorType = useCallback(
    async (floorType: BaseFloorType) => {
      const newRef = await addFloorRef();
      await dispatch(
        updateAnswers({
          answers: {
            [`${newRef}.${FLOOR_TYPE_SUFFIX}`]: floorType.type,
            [`${newRef}.${FLOOR_TYPE_PERCENTAGE_SUFFIX}`]: floorType.percentage,
          },
        }),
      );
    },
    [dispatch, addFloorRef],
  );

  const updateFloorTypes = useCallback(
    async (params: BaseFloorType[]) => {
      const answers = params.reduce<Answers>((preAnswers, p) => {
        const floorType = floorTypes.find((ft) => ft.type === p.type);
        if (!floorType) {
          datadogLog({
            logType: 'error',
            message: `Not found floor type for value "${p.type}"`,
            context: {
              logOrigin:
                'libs/features/sales/quotes/property/home/src/state/util/homeInteriorUtil.ts',
              functionOrigin: 'updateFloorTypes',
            },
          });
          throw new Error(`Not found floor type for value "${p.type}"`);
        }

        return {
          ...preAnswers,
          [`${floorType.ref}.${FLOOR_TYPE_PERCENTAGE_SUFFIX}`]: p.percentage,
        };
      }, {});
      await dispatch(updateAnswers({ answers }));
    },
    [dispatch, floorTypes],
  );

  const removeFloorType = useCallback(
    async (type: string) => {
      const floorType = floorTypes.find((ft) => ft.type === type);
      if (!floorType) {
        datadogLog({
          logType: 'error',
          message: `Not found floor type for value "${type}"`,
          context: {
            logOrigin:
              'libs/features/sales/quotes/property/home/src/state/util/homeInteriorUtil.ts',
            functionOrigin: 'removeFloorType',
          },
        });
        throw new Error(`Not found floor type for value "${type}"`);
      }
      await dispatch(
        updateRemovedRef({ refType: FLOOR_REF, allValues, removedRef: floorType.ref }),
      );
      await dispatch(deleteAnswers({ ref: floorType.ref }));
    },
    [dispatch, floorTypes, allValues],
  );

  return { addAndUpdateFloorType, updateFloorTypes, removeFloorType };
};
