import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'LivingAreaQuestion' })((theme) => ({
  numberInput: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));
