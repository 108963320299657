import { useCallback } from 'react';

import dayjs from 'dayjs';

import { useAddFields } from '@ecp/features/sales/form';
import { DatePicker } from '@ecp/features/sales/shared/components';
import { useField, useFieldWithPrefix } from '@ecp/features/sales/shared/store';

import type { QuestionProps } from '../../questionProps.types';

interface Props extends QuestionProps {
  disabled: boolean;
  personRef: string;
}
export const DateOfBirthRecallQuestion: React.FC<Props> = (props) => {
  const { id = 'DOB', label = 'Date of birth', trackingName = 'DOB', disabled, personRef } = props;
  const dateOfBirthRecall = useField('static.person.dob');
  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const dateOfBirth = usePersonField('dob');

  useAddFields({ dateOfBirthRecall });
  const handleDOBComplete = useCallback(
    async (value: string) => {
      await dateOfBirthRecall.props.actionOnComplete(value);
      await dateOfBirth.props.actionOnComplete(value);
    },
    [dateOfBirth.props, dateOfBirthRecall.props],
  );
  if (!dateOfBirthRecall.exists) return null;

  return (
    <DatePicker
      {...dateOfBirthRecall.props}
      actionOnComplete={handleDOBComplete}
      data-testid='dob'
      disabled={disabled}
      hidePicker
      id={id}
      label={label}
      margin='none'
      trackingName={trackingName}
      trackingLabel={
        dateOfBirthRecall.props.value ? dayjs(dateOfBirthRecall.props.value).format('YYYY') : ' '
      }
    />
  );
};
