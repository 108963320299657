import { createAction } from '@reduxjs/toolkit';

import type { NavState, NavTracking } from './types';

export const setAntiTheftDevicesDialogToggled = createAction<boolean>(
  '@nav/antiTheftDevicesDialogToggled',
);
export const setNavCurrentPageChanged = createAction<string>('@nav/currentPageChanged');
export const setNavIncidentRefChanged = createAction<string>('@nav/incidentRefChanged');
export const setNavMilitaryDeploymentRefChanged = createAction<string>(
  '@nav/militaryDeploymentRefChanged',
);
export const setNavAll = createAction<NavState | undefined | null>('@nav/navAll');
export const setNavPreviousPageChanged = createAction<string>('@nav/previousPageChanged');
export const setZipLookupBypassed = createAction<boolean>('@nav/zipLookupBypassed');
export const setPageStatusChanged = createAction<{ path: string; tracking: NavTracking }>(
  '@nav/pageStatusChanged',
);
export const setPageStatusRemoved = createAction<string>('@nav/pageStatusRemoved');

export type NavActions = ReturnType<
  | typeof setAntiTheftDevicesDialogToggled
  | typeof setNavAll
  | typeof setNavCurrentPageChanged
  | typeof setNavIncidentRefChanged
  | typeof setNavPreviousPageChanged
  | typeof setZipLookupBypassed
  | typeof setPageStatusChanged
  | typeof setPageStatusRemoved
  | typeof setNavMilitaryDeploymentRefChanged
>;
