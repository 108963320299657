export interface Metadata {
  skipFetchPostBindQuestions?: boolean;
  showAcknowledgement?: boolean;
  showCarrierMessaging?: boolean;
  collectRecurringPay?: boolean;
  hideContactInfo?: boolean;
}

const metadata: Metadata = {
  showAcknowledgement: true,
  showCarrierMessaging: true,
};

export default metadata;
