import type { StateOption } from '@ecp/types';

export interface BaseMetadata {
  title?: string;
  subTitle?: string;
}

export interface StateMetadata extends StateOption<BaseMetadata>, BaseMetadata {}

export const sharedHeaderMetadata = {
  title: 'Let’s see what savings you might qualify for!',
  subTitle: 'While we prep your policy, let’s see about some extra savings...',
};

export const headerMetadata: {
  [productKey: string]: StateMetadata;
} = {
  'homesite.home': {
    title: 'Let’s see what discounts you might qualify for',
    subTitle: 'While we prep your policy, let’s see about some extra savings...',
    stateOptions: {
      MT: sharedHeaderMetadata,
      WA: sharedHeaderMetadata,
    },
  },
  'homesite.renters': {
    title: 'Let’s see what discounts you might qualify for',
  },
};
