import { useEffect } from 'react';

import { Drawer } from '@mui/material';

import type { AnalyticsRequest } from '@ecp/utils/analytics/tracking';
import { useEvent } from '@ecp/utils/react';

import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import type { TrackSapiAnalyticsEventParams } from '@ecp/features/sales/shared/utils/analytics';
import { IconUIClose } from '@ecp/themes/base';

import { Button } from '../Button';
import { useStyles } from './PageDrawer.styles';

export interface PageDrawerProps {
  children: React.ReactElement;
  open: boolean;
  onClose: (event: React.MouseEvent<HTMLButtonElement>, reason?: string) => void;
  anchor?: 'left' | 'right' | 'top' | 'bottom';
  saveHandler: () => void;
  isProcessing: boolean;
  title?: string;
  saveButtonTrackEventParams?: TrackSapiAnalyticsEventParams;
  cancelButtonTrackEventParams?: TrackSapiAnalyticsEventParams;
  saveButtonTrackClickParams?: AnalyticsRequest;
  cancelButtonTrackClickParams?: AnalyticsRequest;
  closeButtonTrackClickParams?: AnalyticsRequest;
  renderTrackEventParams?: TrackSapiAnalyticsEventParams;
  saveButtonLabel?: string;
  saveButtonClassName?: string;
  showCancelButton?: boolean;
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  showFooter?: boolean;
  modalClassName?: string;
}

export const PageDrawer: React.FC<PageDrawerProps> = (props) => {
  const {
    children,
    open,
    onClose,
    anchor = 'bottom',
    saveHandler,
    isProcessing,
    title,
    saveButtonTrackEventParams,
    cancelButtonTrackEventParams,
    saveButtonTrackClickParams,
    cancelButtonTrackClickParams,
    closeButtonTrackClickParams,
    renderTrackEventParams,
    saveButtonLabel = 'Save',
    saveButtonClassName,
    showCancelButton = true,
    showFooter = true,
    modalClassName,
  } = props;
  const { classes, cx } = useStyles(props, { props });

  useEffect(() => {
    if (renderTrackEventParams && open) {
      trackSapiAnalyticsEvent(renderTrackEventParams);
    }
  }, [renderTrackEventParams, open]);

  const saveButtonHandler = useEvent(() => {
    if (saveButtonTrackEventParams) {
      trackSapiAnalyticsEvent(saveButtonTrackEventParams);
    }
    saveHandler();
  });

  const cancelButtonHandler = useEvent((event, reason?: string | undefined) => {
    if (cancelButtonTrackEventParams) {
      trackSapiAnalyticsEvent(cancelButtonTrackEventParams);
    }
    onClose(event, reason);
  });

  const closeButtonHandler = useEvent((event, reason?: string | undefined) => {
    onClose(event, reason);
  });

  return (
    <Drawer
      ModalProps={{
        container: document.getElementById('module-drawer-container'),
        keepMounted: true,
        className: modalClassName,
      }}
      /**
       * `disableScrollLock` resolves an issue specific to Windows where,
       * when the drawer is open, a "padding-right" is added to the container
       * (`module-drawer-container`).
       * For more details, refer to:
       * @see https://github.com/mui/material-ui/issues/12879
       */
      disableScrollLock
      PaperProps={{ classes: { root: classes.drawerPaper } }}
      BackdropProps={{ style: { position: 'absolute' } }}
      onClose={closeButtonHandler}
      anchor={anchor}
      open={open}
      variant='temporary'
    >
      <div className={classes.drawerHeader}>
        <Button
          variant='iconText'
          className={classes.drawerCloseIcon}
          onClick={closeButtonHandler}
          aria-label='Close page drawer'
          id='module-drawer-close-button'
          trackingName={closeButtonTrackClickParams ? closeButtonTrackClickParams.action : 'X'}
          trackingLabel={closeButtonTrackClickParams ? closeButtonTrackClickParams.label : 'X'}
        >
          <IconUIClose className={classes.closeIcon} />
        </Button>
      </div>
      <div className={classes.drawerBody}>
        {title && <h1 className={classes.drawerTitle}>{title}</h1>}
        {children}
      </div>
      {showFooter && (
        <div className={cx(classes.drawerFooter)}>
          {showCancelButton && (
            <Button
              onClick={cancelButtonHandler}
              className={classes.drawerCloseButton}
              variant='text'
              trackingName={
                cancelButtonTrackClickParams ? cancelButtonTrackClickParams.action : 'cancel'
              }
              trackingLabel={
                cancelButtonTrackClickParams ? cancelButtonTrackClickParams.label : 'cancel'
              }
              id='module-drawer-cancel-button'
            >
              Cancel
            </Button>
          )}
          <Button
            isProcessing={isProcessing}
            onClick={saveButtonHandler}
            className={saveButtonClassName}
            variant='primary'
            trackingName={saveButtonTrackClickParams ? saveButtonTrackClickParams.action : 'save'}
            trackingLabel={saveButtonTrackClickParams ? saveButtonTrackClickParams.label : 'save'}
            id='module-drawer-save-button'
          >
            {saveButtonLabel}
          </Button>
        </div>
      )}
    </Drawer>
  );
};
