import { ensureStringArray } from '@ecp/utils/common';

import type { Answers } from '@ecp/features/sales/shared/types';

import { getAllValues } from '../../form/selectors';
import { wrapThunkActionWithErrHandler } from '../../util/wrapThunkActionWithErrHandler';
import { fetchInquiry } from './fetchInquiry';
import { updateAnswers } from './updateAnswers';

type UpdateAddedRef = {
  allValues?: Answers;
  type: string;
  newRef: string;
};

export const updateAddedRef = wrapThunkActionWithErrHandler<UpdateAddedRef>(
  ({ type, newRef }) =>
    async (dispatch, getState) => {
      const allValues = getAllValues(getState());
      const existingRefs: string[] = ensureStringArray(allValues[type]);
      const newRefs = [...existingRefs];
      if (!newRefs.includes(newRef)) newRefs.push(newRef);
      await dispatch(
        updateAnswers({
          answers: { [type]: newRefs },
        }),
      );

      // special case where when refs are created, we need to re-fetch inquiry to get proper questions for new fields
      await dispatch(fetchInquiry({}));
    },
  'updateAddedRef',
);
