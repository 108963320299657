import { useCallback, useEffect, useState } from 'react';

import { emptyObject } from '@ecp/utils/common';

import { setPaymentOptionsAndAcksRefetchRequired } from '@ecp/features/sales/checkout';
import {
  fetchApi,
  getAnswers,
  PATCH_API_PREFIX,
  setShouldRecalc,
  useForm,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type {
  Answers,
  AnswerValue,
  AutoCoveragesFields,
  PropertyCoveragesFields,
} from '@ecp/features/sales/shared/types';
import type { Field } from '@ecp/types';

export const useCoveragesFormValidationFactory = (
  initValues: React.RefObject<Answers>,
  fields: Partial<
    AutoCoveragesFields | PropertyCoveragesFields
  > = emptyObject as unknown as Partial<AutoCoveragesFields | PropertyCoveragesFields>,
  onFormValidChange: ((isValid: boolean) => void) | undefined,
): {
  handleCoverageItemChange: (field: Field) => (value: AnswerValue) => Promise<void>;
} => {
  const dispatch = useDispatch();
  const { validateForm, patchFormValues, resetForm } = useForm({ initValues, fields });

  // This handles showError flag on CheckoutCard
  const [isFormValid, setIsFormValid] = useState(true);
  useEffect(() => {
    if (onFormValidChange) onFormValidChange(isFormValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormValid]);

  const answers = useSelector(getAnswers);

  // !TODO Consider fixing SAPI questions validation criteria and removing handleCoverageItemChange everywhere, since we have this logic to
  // to validate whole form, because some fields' mutual dependencies are not included in SAPI questions validation criteria
  // and hook into store.form.errors to fire onFormValidChange event
  const handleCoverageItemChange = useCallback(
    (field: Field) =>
      async (value: AnswerValue): Promise<void> => {
        const fieldValue = (field.value as string) || '';
        if (value && value.toString() !== fieldValue.toString()) {
          field.update(value);
          // change the Field.value key directly so that getPatchFormValues() will always get the updated field value
          field.value = value;
        }
        const { isValid } = validateForm();
        if (
          value &&
          answers[field.key] &&
          answers[field.key]?.toString() === value.toString() &&
          answers[field.key]?.toString() === fieldValue.toString()
        )
          return;
        if (isValid) {
          dispatch(setShouldRecalc(true));
          // !TODO Why patchFormValues callback doesn't have new value set by field.update
          // !TODO How to patch only user values???
          await patchFormValues();
          // This insures isPatchFormInProgress is true while field.validateAndPatch is not yet complete
          await dispatch(
            fetchApi({
              fn: () => field.validateAndPatch(value),
              idPrefix: PATCH_API_PREFIX,
            }),
          );
          resetForm();
          // After patch to answers, ensure we fetch correct payment options
          // incase of backnav edge cases
          dispatch(setPaymentOptionsAndAcksRefetchRequired(true));
        }
        setIsFormValid(isValid);
      },
    [validateForm, patchFormValues, resetForm, dispatch, answers],
  );

  return { handleCoverageItemChange };
};
