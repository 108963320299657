import { useAddFields } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const WashingMachineLocationQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const washingMachineLocation = useHomePropertyField('washingMachineLocation');
  const {
    groupLabel = washingMachineLocation.question?.title,
    trackingName = 'washing_machine_location_dropdown',
  } = props;

  useAddFields({ washingMachineLocation });

  if (!washingMachineLocation.exists) return null;

  return (
    <Select
      {...washingMachineLocation.props}
      id='washingMachineLocation'
      groupLabel={groupLabel}
      trackingName={trackingName}
      inputButtonAriaLabel='washingMachineLocation'
    />
  );
};
