import { useLayoutEffect } from 'react';

import {
  stringifyLocation,
  stringifySearchParams,
  useBackAndForwardNav,
  useLocation,
} from '@ecp/utils/routing';

import { setNavPreviousPageChanged, updateNavCurrentPage } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';

import { PagePath } from './constants';

/**
 * This hook updates the nav store with currentPage and previousPage when the application loads
 * and during browser back navigation.
 *
 * It also keeps the user on the post bind page after purchase.
 *
 * NOTE: history.location.pathname returns the current pathname of the app, and we store it in store nav - nav.currentPage
 * location.pathname returns the path of previous page(through closure) that we store it in store nav - nav.previousPage
 */
export const useNavRules = (): void => {
  const dispatch = useDispatch();
  const location = useLocation();

  useBackAndForwardNav(({ currentLocation, nextLocation, history }) => {
    // Restrict user to navigate back from Post Bind using browser back.
    if (
      // includes instead of equality check covers postbind error scenarios
      currentLocation.pathname.includes(PagePath.POST_BIND) &&
      nextLocation.pathname !== PagePath.POST_BIND
    ) {
      history.go(1);

      return;
    }
    // Restrict user from navigating back after they're on retrieve page,
    // ECP-12049 and block to navigate to coverages page if they are in retrieve page
    if (
      currentLocation.pathname.includes(PagePath.LANDING) &&
      (nextLocation.pathname !== PagePath.QUOTES_LOADING ||
        nextLocation.pathname.includes(PagePath.COVERAGES))
    ) {
      history.go(1);

      return;
    }

    dispatch(setNavPreviousPageChanged(stringifyLocation(currentLocation)));
    dispatch(updateNavCurrentPage({ page: stringifyLocation(nextLocation) }));
  });

  useLayoutEffect(() => {
    dispatch(setNavPreviousPageChanged(PagePath.DEFAULT));

    // Two scenarios:
    // 1. When starts the app with landing page with product and address in the query, we want to skip `updateNavCurrentPage` for performance reasons
    //   because it prolongs the initial page load
    //   and analytics.landing_url already contains the initial landing page URL.
    // 2. During blob retrieval though, we cannot rely on "analytics.landing_url" key to store the initial landing page URL because it already stored landing page url from previous session.
    //   So in this case, we want to patch the initial page URL to avoid losing the new initial page URL.

    dispatch(
      updateNavCurrentPage({
        page: `${location.pathname}${stringifySearchParams(location.search)}`,
        trackCurrentPageAnalytics: !!location.search.retrieve,
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
