import { useMemo } from 'react';

import { emptyObject, merge, unique } from '@ecp/utils/common';
import { FeatureFlags, flagValues, flagVariables } from '@ecp/utils/flags';

import { env } from '@ecp/env';
import {
  CarrierAutoPolicyMetadata,
  CarrierVehicleDeductibleMetadata,
  featureFlaggedPropertyCoveragesMetadata,
  propertyCoveragesMetadata,
  VehicleCoverageMetadata,
} from '@ecp/features/sales/shared/metadata';
import { PagePath } from '@ecp/features/sales/shared/routing';
import { getCurrentPage, getFieldsByKeys, getShownFields } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type {
  CoverageItem,
  CoveragesFields,
  Driver,
  PropertyCoveragesFields,
  Vehicle,
} from '@ecp/features/sales/shared/types';
import type { AutoProduct, PropertyProduct } from '@ecp/features/shared/product';
import type { OptionsMetadata } from '@ecp/types';

// TODO: We need to check corresponding keys from answers not from metadata
export const useVehicleCoverageFields = (
  vehicles: Vehicle[],
  selectedAutoProduct?: AutoProduct,
): { [key: string]: CoveragesFields } => {
  const dispatch = useDispatch();
  const coverageMetadata = selectedAutoProduct ? VehicleCoverageMetadata[selectedAutoProduct] : {};
  const carrierVehicleCoverageMetadataKeys = Object.keys(coverageMetadata).reduce((acc, curr) => {
    acc.push(curr);

    const subCoverages = coverageMetadata[curr]?.subCoverages;
    if (subCoverages) {
      acc.push(...Object.keys(subCoverages));
    }

    return acc;
  }, [] as string[]);

  const deductibleMetadata = selectedAutoProduct
    ? CarrierVehicleDeductibleMetadata[selectedAutoProduct]
    : {};
  const carrierVehicleDeductibleMetadataKeys = Object.keys(deductibleMetadata).reduce(
    (acc, curr) => {
      acc.push(curr);

      const subCoverages = deductibleMetadata[curr]?.subCoverages;
      if (subCoverages) {
        acc.push(...Object.keys(subCoverages));
      }

      return acc;
    },
    [] as string[],
  );

  const vehicleCoverageFields: { [key: string]: CoveragesFields } = useSelector(
    (state: RootStore) =>
      Object.assign(
        {},
        ...vehicles.map(({ ref }) => {
          const fields = getFieldsByKeys(
            state,
            `${selectedAutoProduct}.coverages.${ref}`,
            [...carrierVehicleDeductibleMetadataKeys, ...carrierVehicleCoverageMetadataKeys],
            dispatch,
          );

          return { [ref]: fields };
        }),
      ),
  );

  const vehicleCoverageFieldsFiltered = Object.keys(vehicleCoverageFields).reduce(
    (acc, vehicle) => {
      const fields = dispatch(getShownFields({ fields: vehicleCoverageFields[vehicle] }));
      acc[vehicle] = fields as CoveragesFields; // FIXME ASAP

      return acc;
    },
    {} as { [key: string]: CoveragesFields },
  );

  return vehicleCoverageFieldsFiltered;
};

// TODO: We need to check corresponding keys from answers not from metadata
export const useGetPolicyCoverageFields = (
  selectedAutoProduct?: string,
  drivers?: Driver[],
): CoveragesFields => {
  const dispatch = useDispatch();
  const metadata = selectedAutoProduct ? CarrierAutoPolicyMetadata[selectedAutoProduct] : {};
  const carrierAutoPolicyMetadataKeys = Object.keys(metadata).reduce((acc, curr) => {
    acc.push(curr);

    const subCoverages = metadata[curr]?.subCoverages;
    if (subCoverages) {
      acc.push(...Object.keys(subCoverages));
    }

    const stateOptions = metadata[curr]?.stateOptions;
    if (stateOptions) {
      Object.keys(stateOptions).forEach((state) => {
        const stateSubCoverages = stateOptions[state].subCoverages;
        if (stateSubCoverages) {
          acc.push(...Object.keys(stateSubCoverages));
        }
      });
    }

    return unique(acc);
  }, [] as string[]);

  const policyCoverageFields = useSelector((state: RootStore) => {
    // goes through metaDatakeys if matches <> adds new keys with driver id
    // example: pipExcludeDriver-<driver.id>  => pipExcludeDriver-0ntkc2ya
    carrierAutoPolicyMetadataKeys.forEach((key) => {
      if (/<.+>/.test(key)) {
        drivers?.forEach((driver) => {
          const driverId = driver.ref.split('.')[1];
          carrierAutoPolicyMetadataKeys.push(key.replace(/<.+>/, driverId));
        });
      }
    });
    const fields = getFieldsByKeys(
      state,
      `${selectedAutoProduct}.coverages.policy`,
      carrierAutoPolicyMetadataKeys,
      dispatch,
    );

    return fields;
  }) as CoveragesFields;

  const policyCoverageFieldsFiltered = dispatch(getShownFields({ fields: policyCoverageFields }));

  return policyCoverageFieldsFiltered as CoveragesFields;
};

export const useGetPropertyCoverageFields = (
  selectedPropertyProduct: PropertyProduct | undefined,
): PropertyCoveragesFields => {
  const dispatch = useDispatch();
  const getPropertyCoverageFields =
    (metadataItem: { [productKey: string]: OptionsMetadata }) =>
    (state: RootStore): CoveragesFields => {
      if (!selectedPropertyProduct || !metadataItem[selectedPropertyProduct])
        return emptyObject as unknown as CoveragesFields;

      return getFieldsByKeys(
        state,
        selectedPropertyProduct,
        Object.keys(metadataItem[selectedPropertyProduct]),
        dispatch,
      );
    };

  const enableIquoteHo4Coverages = flagValues[FeatureFlags.IQUOTE_HO4_COVERAGES];
  let coveragesMetadata;
  let otherCoveragesMetadata;
  if (enableIquoteHo4Coverages) {
    coveragesMetadata = merge(
      {},
      propertyCoveragesMetadata.coverages,
      featureFlaggedPropertyCoveragesMetadata.coverages,
    );
    otherCoveragesMetadata = merge(
      {},
      propertyCoveragesMetadata.otherCoverages,
      featureFlaggedPropertyCoveragesMetadata.otherCoverages,
    );
  } else {
    coveragesMetadata = propertyCoveragesMetadata.coverages;
    otherCoveragesMetadata = propertyCoveragesMetadata.otherCoverages;
  }

  return {
    deductibles: useSelector(getPropertyCoverageFields(propertyCoveragesMetadata.deductibles)),
    coverages: useSelector(getPropertyCoverageFields(coveragesMetadata)),
    otherCoverages: useSelector(getPropertyCoverageFields(otherCoveragesMetadata)),
    optionalCoverages: useSelector(
      getPropertyCoverageFields(propertyCoveragesMetadata.optionalCoverages),
    ),
    includedCoverages: useSelector(
      getPropertyCoverageFields(propertyCoveragesMetadata.includedCoverages),
    ),
  };
};

export const hasCoverageItemError = (item: CoverageItem): boolean =>
  Boolean(item.field?.errors.length);

export const useIsCustomizeCoveragesEnabled = env.static.isAgent
  ? () => {
      const currentPage = useSelector(getCurrentPage);

      // ECP-3758: write-once (as soon as it's determined, it does NOT change)
      const isEnabled = useMemo(() => {
        // also check against CHECKOUT to make sure it support browser back button (back to coverages page) properly
        return currentPage === PagePath.COVERAGES || currentPage === PagePath.CHECKOUT;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      return isEnabled;
    }
  : undefined;

// EDSP-13235 need to check if the date is after the connect auto effective date
const getTodayMidnight = (): Date => new Date(new Date().setHours(0, 0, 0, 0));

export const isAfterDayOfConnectAutoMinimumLimitsWarning = (): boolean => {
  const { CONNECT_AUTO_LIMIT_DATE } =
    flagVariables[FeatureFlags.CONNECT_AUTO_MINIMUM_LIMITS_WARNING];

  if (CONNECT_AUTO_LIMIT_DATE) {
    const todayLocal = getTodayMidnight();
    const connectAutoMinimumLimitDate = new Date(CONNECT_AUTO_LIMIT_DATE);

    return todayLocal > connectAutoMinimumLimitDate;
  }

  return false;
};
