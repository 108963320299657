import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'RatingCriteriaForm' })((theme) => ({
  ...theme.mixins.withRightSidebar,
  root: {
    ...theme.mixins.form,
    [theme.breakpoints.down('md')]: {
      padding: '0 !important',
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: 40,
    },
  },
  container: {
    maxWidth: theme.breakpoints.values.md,
  },
  formContainer: theme.mixins.withRightSidebar.formContainer,
  singleProductContainer: {
    marginTop: -40,
  },
  tabs: {
    '& .MuiTabs-flexContainer': {
      background: theme.palette.grey[200],
      borderBottom: `4px solid ${theme.palette.grey[600]}`,
    },
    '& .Mui-selected': {
      background: theme.palette.grey[50],
      border: `1px solid ${theme.palette.grey[200]}`,
    },
    '& .MuiTabs-indicator': {
      background: theme.palette.primary.main,
      height: 4,
    },
  },
  tabLabel: theme.typography.h3,
}));
