import { memo } from 'react';

import { FeatureFlags, flagValues, flagVariables } from '@ecp/utils/flags';
import { useHtmlDecode } from '@ecp/utils/web';

import { getCurrentPage } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { IconUIInfoCircle } from '@ecp/themes/base';

import metadata from './metadata';
import { useStyles } from './OutageBanner.styles';

export const OutageBanner: React.FC = memo(() => {
  const { classes, cx } = useStyles();
  const currentPage = useSelector(getCurrentPage);

  const outageBannerText = flagValues[FeatureFlags.OUTAGE_NOTICE]
    ? flagVariables[FeatureFlags.OUTAGE_NOTICE].OUTAGE_TEXT
    : '';
  const html = useHtmlDecode(outageBannerText);

  if (!(outageBannerText && currentPage && metadata.outageBannerPages.includes(currentPage)))
    return null;

  return (
    <div className={cx(classes.root, classes.warning)}>
      <div className={classes.wrapper}>
        <IconUIInfoCircle className={classes.icon} />
        <div className={classes.message} dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  );
});
