import { GridItem } from '@ecp/components';

import {
  AirConditioningQuestion,
  CathedralOrVaultedCeilingQuestion,
  CeilingHeightQuestion,
  FloorTypeQuestion,
  FullBathQuestion,
  HalfBathQuestion,
  HeatingSourceQuestion,
  InteriorWallQuestion,
  KitchenCountertopMaterialQuestion,
  NewDwellingUnderConstructionQuestion,
  PlumbingReplacedYearQuestion,
  PrimaryHeatingTypeQuestion,
  PrimaryHeatSourceFuelTypeQuestion,
  RenovationInLast15YearsQuestion,
  RoomsWithCrownMoldingQuestion,
  WashingMachineLocationQuestion,
  WiringReplacedYearQuestion,
} from '../../components';

interface Props {
  hasHeatingSourceError: boolean;
  setHasHeatingSourceError: (hasHeatSourceExist: boolean) => void;
}

export const HomeInteriorFormQuestions: React.FC<Props> = (props) => {
  const { hasHeatingSourceError, setHasHeatingSourceError } = props;

  return (
    <>
      <GridItem xs={12} data-testid='interiorWallSection'>
        <InteriorWallQuestion />
      </GridItem>
      <GridItem topSpacing='lg' xs={12} data-testid='floorTypeSection'>
        <FloorTypeQuestion />
      </GridItem>
      <GridItem topSpacing='lg' xs={12} data-testid='ceilingHeightSection'>
        <CeilingHeightQuestion />
      </GridItem>
      <GridItem topSpacing='lg' xs={12} data-testid='cathedralOrVaultedCeilingSection'>
        <CathedralOrVaultedCeilingQuestion />
      </GridItem>
      <GridItem topSpacing='lg' xs={12} data-testid='roomsWithCrownMoldingSection'>
        <RoomsWithCrownMoldingQuestion />
      </GridItem>
      <GridItem topSpacing='lg' xs={12} data-testid='heatingSourceSection'>
        <HeatingSourceQuestion
          hasHeatingSourceError={hasHeatingSourceError}
          setHasHeatingSourceError={setHasHeatingSourceError}
        />
      </GridItem>
      <GridItem topSpacing='lg' xs={12} data-testid='fullBathSection'>
        <FullBathQuestion />
      </GridItem>
      <GridItem topSpacing='lg' xs={12} data-testid='halfBathSection'>
        <HalfBathQuestion />
      </GridItem>
      <GridItem topSpacing='lg' xs={12} data-testid='kitchenCountertopMaterialSection'>
        <KitchenCountertopMaterialQuestion />
      </GridItem>
      <GridItem topSpacing='lg' xs={12} data-testid='primaryHeatingTypeSection'>
        <PrimaryHeatingTypeQuestion />
      </GridItem>
      <GridItem topSpacing='lg' xs={12} data-testid='primaryHeatingFuelTypeSection'>
        <PrimaryHeatSourceFuelTypeQuestion />
      </GridItem>
      <GridItem topSpacing='lg' xs={12} data-testid='airConditioningSection'>
        <AirConditioningQuestion />
      </GridItem>
      <GridItem topSpacing='lg' xs={12} data-testid='washingMachineLocationSection'>
        <WashingMachineLocationQuestion />
      </GridItem>
      <GridItem topSpacing='lg' xs={12} data-testid='plumbingReplacedYearSection'>
        <PlumbingReplacedYearQuestion />
      </GridItem>
      <GridItem topSpacing='lg' xs={12} data-testid='wiringReplacedYearQuestionSection'>
        <WiringReplacedYearQuestion />
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <RenovationInLast15YearsQuestion />
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <NewDwellingUnderConstructionQuestion />
      </GridItem>
    </>
  );
};
