import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'Toggle' })((theme) => ({
  root: {
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    '& .MuiToggleButtonGroup-grouped:not(.first-of-type)': {
      marginLeft: 0,
      '&.Mui-selected': {
        marginLeft: -1,
        borderLeftColor: theme.palette.primary.main,
      },
    },
  },
  toggleButton: {
    ...theme.typography.body2,
    width: '100%',
    textTransform: 'none',
    borderColor: theme.palette.grey[500],
    color: theme.palette.grey[800],
    background: theme.palette.grey[100],
    '&:hover': {
      background: theme.palette.grey[100],
    },
    '&.Mui-selected': {
      ...theme.typography.body2Bold,
      background: theme.palette.primary.light,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.primary.light,
      },
    },
  },
  fullWidthButton: {
    width: '100%',
  },
}));
