import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'MobilePurchaseCard' })((theme) => ({
  // NOTE - these styles match those in MobileCheckoutCard.styles.ts
  // TODO: create a reusable component for Checkout and Purchase views that contain these styles
  bottomCheckoutBarContainer: {
    ...theme.mixins.shadow2,
    display: 'none',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: theme.palette.other.body,
    zIndex: theme.zIndex.drawer + 11,
    padding: '10px 30px',
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
    },
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bottomCheckoutBarButton: {
    textAlign: 'end',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  premium: {
    display: 'inline',
  },
  carrierButtons: {
    display: 'flex',
    justifyContent: 'center',
    gap: 15,
    flexDirection: 'column',
  },
}));
