import type { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';

import { isEqual } from '@ecp/utils/common';

import { env } from '@ecp/env';
import { getHomeInspectionStatus } from '@ecp/features/sales/checkout';
import type { Session } from '@ecp/features/sales/shared/misc';
import { getSession, setSession } from '@ecp/features/sales/shared/misc';
import type { RootStore } from '@ecp/features/sales/shared/store/types';

import { getGlobalError, getInitializing } from '../global/selectors';
import { getDalSessionId, getInquiryId } from '../inquiry/selectors';
import { getNav } from '../nav/selectors';
import { getOfferSetId } from '../offers/selectors';

// !TODO this middleware slows things down by a lot, consider replacing with saving Session to sessionStorage on pagehide and alike
/** Save Session to sessionStorage on every action whenever Session changes. */
export const sessionSync: Middleware = ({
  getState,
}: MiddlewareAPI<Dispatch<AnyAction>, RootStore>) => {
  function applyChange(
    newSession: Session,
    { initializing, error }: { initializing: boolean; error: boolean },
  ): void {
    if (initializing || error) return;

    setSession(newSession);
  }

  return (next: Dispatch<AnyAction>) => (action: AnyAction) => {
    const result = next(action);

    const state = getState();
    const existingSession = getSession({ expId: env.static.expId });
    const newSession: Session = {
      dalSessionId: getDalSessionId(state) || null,
      inquiryId: getInquiryId(state) || null,
      offerSetId: getOfferSetId(state) || null,
      expId: env.static.expId,
      nav: getNav(state) || null,
      homeInspectionStatus: getHomeInspectionStatus(state) || null,
    };

    const change = !isEqual(newSession, existingSession);

    if (change)
      applyChange(newSession, {
        initializing: getInitializing(state),
        error: getGlobalError(state).hasError,
      });

    return result;
  };
};
