import { useCallback, useEffect } from 'react';

import { Events, trackEvent } from '@ecp/utils/flags';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { PagePath, useNavigateToNextPage } from '@ecp/features/sales/shared/routing';

import { SecondaryNamedInsuredForm } from '../../forms/SecondaryNamedInsuredForm/SecondaryNamedInsuredForm';

export const SecondaryNamedInsuredPage: React.FC = () => {
  const navigateToNextPage = useNavigateToNextPage();
  useEffect(() => {
    trackEvent(Events.SECONDARY_NAMED_INSURED_PAGE);
  }, []);

  const handleNext = useCallback(async () => {
    await navigateToNextPage();

    return;
  }, [navigateToNextPage]);

  return (
    <div>
      <Page
        title='Secondary named insured'
        subTitle='A secondary named insured (SNI) is named on the policy and will receive the same coverage as the primary policyholder.'
        analyticsElement='sniDetailsPage.page'
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.SECONDARY_NAMED_INSURED} />,
        }}
      >
        <SecondaryNamedInsuredForm onNext={handleNext} />
      </Page>
    </div>
  );
};
