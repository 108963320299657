import type { Product } from '@ecp/features/shared/product';

export type Metadata = {
  [productKey in Product]: {
    removeCheckboxValidation?: boolean;
    isConnectAutoInstallmentPlan?: boolean;
  };
};

const metadata: Metadata = {
  'connect.auto': {
    isConnectAutoInstallmentPlan: true,
  },
  'amfam.auto': {},
  'amfam-adv.auto': {},
  'amfam-adv.home': {
    removeCheckboxValidation: true,
  },
  'homesite.home': {
    removeCheckboxValidation: true,
  },
  'homesite.renters': {},
  'amfam-adv.renters': {},
  'connect-boost.auto': {},
};

export default metadata;
