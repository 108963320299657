import { useCallback, useEffect, useRef, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { getPaymentPlanForProduct } from 'libs/features/sales/checkout/src/state/paymentoptions/selectors';

import { trackRender } from '@ecp/utils/analytics/tracking';
import { Events, FeatureFlags, flagValues, trackEvent } from '@ecp/utils/flags';
import { datadogLog } from '@ecp/utils/logger';
import { sessionStorage } from '@ecp/utils/storage';
import { scrollToElement } from '@ecp/utils/web';

import { Page } from '@ecp/features/sales/shared/components';
import { OfferStatusCode, PurchaseErrorReason } from '@ecp/features/sales/shared/constants';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import {
  getCustomerId,
  getDalSessionId,
  getInquiryId,
  getOfferProductsSelected,
  getOfferSetId,
  getPurchaseError,
  updateGlobalError,
  useForm,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Condition } from '@ecp/features/sales/shared/types';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import { isProductHome } from '@ecp/features/shared/product';
import type { Field, Fields } from '@ecp/types';

import { fetchAcknowledgementResponse, pushPaymentOption } from '../../state';
import { getPostBindProductsSummary } from '../../state/postbind';
import { getPurchaseRequest, getPurchaseResponse, postPurchase } from '../../state/purchase';
import {
  allowPurchaseRetry,
  checkEligiblePurchaseRequestProducts,
  cleanUpFields,
  getPurchaseResults,
  isRetrySuccessful,
  shouldDisplayErrorPage,
} from '../../state/purchaseUtil';
import {
  createSapiAnalyticsEvent,
  getPaymentFields,
  getProductsAndPolicyNumbers,
  useGetSelectedProductsCheckoutFields,
} from '../../util';
import { PostBindLoadingPage } from '../PostBindPage/PostBindLoadingPage';
import { CheckoutRetryForm } from './CheckoutRetryForm';
import { useStyles } from './CheckoutRetryPage.styles';
import metadata from './metadata';

export interface PaymentFields extends Fields {
  [fieldKey: string]: Field;
}

export const CheckoutRetryPage: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [submittingPayment, setSubmittingPayment] = useState(false);
  const navigateToPostBind = useNavigateToPage(PagePath.POST_BIND, {
    replace: true,
    removeQuery: true,
  });
  const navigateToErrorPage = useNavigateToPage(PagePath.CHECKOUT_ERROR, {
    replace: true,
    removeQuery: true,
  });

  // TODO This always must be derived state
  const [hasTokens, setHasTokens] = useState(true);
  const [hasBankPayToken, setHasBankPayToken] = useState(true);
  const [hasRecurringPayToken, setHasRecurringPayToken] = useState(true);
  const [showLoadingPage, setShowLoadingPage] = useState(false);
  const [showRetryModal, setShowRetryModal] = useState(false);
  const customerId = useSelector(getCustomerId);
  const inquiryId = useSelector(getInquiryId);
  const dalSessionId = useSelector(getDalSessionId);
  const offerSetId = useSelector(getOfferSetId);
  const purchaseResponse = useSelector(getPurchaseResponse);
  const purchaseError = useSelector(getPurchaseError);
  const shouldGenerateTokenForEFTPay = flagValues[FeatureFlags.EFT_PAY_THROUGH_PAYMENT_GATEWAY];

  let currentPostBindRetryCount = sessionStorage.getItem('PostBindRetryCount') as number;
  const offerProductsSelected = useSelector(getOfferProductsSelected);
  const productsSummary = useSelector((state: RootStore) =>
    getPostBindProductsSummary(state, offerProductsSelected),
  );

  const paymentPlans = useSelector((state: RootStore) =>
    offerProductsSelected.map((product) => getPaymentPlanForProduct(state, product)),
  ) as string[];

  const fieldsByProduct = useGetSelectedProductsCheckoutFields(offerProductsSelected);

  const purchaseResults = getPurchaseResults(productsSummary);

  const purchaseRequest = useSelector((state: RootStore) =>
    getPurchaseRequest(state, offerProductsSelected),
  );

  const { failed, retry } = purchaseResults;
  const failedString = JSON.stringify(failed);
  const retryString = JSON.stringify(retry);
  const purchaseErrorString = JSON.stringify(purchaseError);
  const offerProductsSelectedString = JSON.stringify(offerProductsSelected);

  useEffect(() => {
    if (shouldDisplayErrorPage(failed, retry, purchaseError, offerProductsSelected)) {
      dispatch(
        updateGlobalError({
          hasError: true,
          requestId: '',
          errorReason: PurchaseErrorReason.UNKNOWN_PURCHASE_FAILURE,
          name: '',
          text: '',
        }),
      );
    }

    const { policyNumberPostBind, productsSelected } = getProductsAndPolicyNumbers(
      purchaseResponse,
      productsSummary,
    );

    trackRender({
      action: 'PostbindRender',
      label: `product: ${productsSelected}, policyNumber: ${policyNumberPostBind}`,
    });
    // A/B test metric tracking function for postbind page view
    trackEvent(Events.POSTBIND_PAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    dalSessionId,
    failedString,
    retryString,
    purchaseErrorString,
    offerProductsSelectedString,
  ]);

  const initValues = useRef({});

  const conditions = purchaseResults.retry.reduce((acc, product) => {
    if (isProductHome(product)) {
      const productFields = fieldsByProduct[product];
      acc.push({
        conditionalFields: [productFields.phoneNumber],
        isRequiredOverride: () =>
          productFields.paymentMethod.value === 'Mortgage' &&
          productFields.mortgageKnowMortgageCompany.value === false,
      });
    }

    return acc;
  }, [] as Condition[]);

  const { validateForm } = useForm({
    initValues,
    fields: getPaymentFields(purchaseResults.retry, fieldsByProduct, paymentPlans),
    conditions: conditions.length ? conditions : undefined,
  });

  const onResubmitPayment = useCallback(async () => {
    if (!dalSessionId || !offerSetId) {
      return;
    }
    // ensure tokens are in place for carriers that require them
    setHasTokens(true);
    setHasBankPayToken(true);
    setHasRecurringPayToken(true);
    let purchaseTokens = true;
    Object.values(purchaseRequest).forEach((purchase) => {
      // If token is not available, set purchaseTokens = false and purchase won't be called.
      if (purchase.paymentOption.creditCard && !purchase.paymentOption.creditCard.token) {
        setHasTokens(false);
        purchaseTokens = false;
        const firstPaymentMethod = document.querySelector('[class*="CheckoutForm-box"]');
        if (firstPaymentMethod) {
          scrollToElement({ element: firstPaymentMethod });
        }
      }
      // If bank pay token is not available, set purchaseTokens = false and purchase won't be called
      if (
        shouldGenerateTokenForEFTPay &&
        purchase.paymentOption.bankAccount &&
        !purchase.paymentOption.bankAccount.financialAccountToken
      ) {
        setHasBankPayToken(false);
        purchaseTokens = false;
      }
      // If recurring pay bank pay token is not available, set purchaseTokens = false and purchase won't be called
      if (
        shouldGenerateTokenForEFTPay &&
        purchase.paymentOption.banckAccountForRecurringPay &&
        !purchase.paymentOption.banckAccountForRecurringPay.financialAccountToken
      ) {
        setHasRecurringPayToken(false);
        purchaseTokens = false;
      }
    });

    if (validateForm().isValid && purchaseTokens) {
      setSubmittingPayment(true);
      setShowLoadingPage(true);
      await checkEligiblePurchaseRequestProducts(productsSummary, purchaseRequest);
      const newCount = currentPostBindRetryCount + 1;
      // only let user try to resubmit payment a maxium of 3 times before stopping them from continuing.
      if (purchaseTokens) {
        productsSummary.forEach(
          async ({ offerCheckoutDetails, offerEligibility, payment, product }) => {
            if (
              offerCheckoutDetails &&
              payment.paymentType &&
              payment.paymentPlan &&
              offerEligibility !== OfferStatusCode.PURCHASED
            ) {
              const analyticsDetails = {
                products: product,
                offerId: offerSetId,
                quoteNumber: offerCheckoutDetails.offer.details.quoteNumber,
                policyNumber: offerCheckoutDetails.offer.details.policyNumber,
                dalSessionId,
                inquiryId,
                customerId,
                paymentMethod: payment.paymentType,
                paymentPlan: payment.paymentPlan,
                paymentAmount: payment.paymentPremium,
              };
              trackSapiAnalyticsEvent({
                element: 'choice.checkoutRetryPage.retryPage.purchaseMethodDetails',
                event: 'captured',
                eventDetail: JSON.stringify(analyticsDetails),
              });
              const paymentOptionResponse = await dispatch(
                pushPaymentOption({
                  dalSessionId,
                  product,
                  // TODO: huh?
                  paymentType:
                    payment.paymentType !== 'CostcoVisa' ? payment.paymentType : 'CreditCard',
                  paymentPlan: payment.paymentPlan,
                }),
              );
              if (paymentOptionResponse?.error) {
                datadogLog({
                  logType: 'error',
                  message: `Error posting payment options - ${paymentOptionResponse?.error?.message}`,
                  context: {
                    logOrigin:
                      'libs/features/sales/checkout/src/views/CheckoutRetryPage/CheckoutRetryPage.tsx',
                    contextType: 'Purchase Error',
                    functionOrigin: 'onResubmitPayment',
                    message: paymentOptionResponse?.error?.message,
                  },
                  error: paymentOptionResponse.error,
                });

                return;
              }
            }
          },
        );

        const checkoutResponse = await dispatch(
          postPurchase({
            dalSessionId,
            offerSetId,
            purchaseRequest,
            products: purchaseResults.retry,
            timeout: 29000,
            skipFetchPostBindQuestions: metadata.skipFetchPostBindQuestions,
          }),
        );
        await dispatch(
          fetchAcknowledgementResponse({
            dalSessionId,
            products: purchaseResults.retry,
            category: 'reminders',
          }),
        );
        if (checkoutResponse?.response !== undefined) {
          cleanUpFields(productsSummary, fieldsByProduct, dispatch);
        }
        sessionStorage.setItem('PostBindRetryCount', newCount);
        setShowLoadingPage(false);
        setSubmittingPayment(false);

        const isRetrySuccessfull = isRetrySuccessful(checkoutResponse?.response?.policies);
        const allowRetry = allowPurchaseRetry(checkoutResponse?.response?.policies);
        // navigate away from retry page to post bind if 1 product was successfully purchased.
        if (isRetrySuccessfull) {
          navigateToPostBind();
          // navigate away if none of the products are retryable or retry count is greater than 2 times
        } else if ((!allowRetry || newCount > 2) && !isRetrySuccessfull) {
          navigateToErrorPage();
        }
        // If both if statements aren't hit we keep them on retry page for 3 attempts.
        setShowRetryModal(false);
      }
    }
  }, [
    dalSessionId,
    offerSetId,
    purchaseRequest,
    validateForm,
    currentPostBindRetryCount,
    dispatch,
    purchaseResults.retry,
    inquiryId,
    customerId,
    productsSummary,
    navigateToPostBind,
    navigateToErrorPage,
    fieldsByProduct,
    shouldGenerateTokenForEFTPay,
  ]);

  const displayCheckoutRetryPage = (): JSX.Element | null => {
    if (showLoadingPage) {
      return <PostBindLoadingPage />;
    }
    // Check to see how many times they've tried to purchase a policy on post bind retry payment page
    // If we dont have a value stored for the count set the count to 0.
    if (!currentPostBindRetryCount) {
      sessionStorage.setItem('PostBindRetryCount', 0);
      currentPostBindRetryCount = 0;
    }
    if (currentPostBindRetryCount > 3) {
      const sapiAnalyticsEvent = createSapiAnalyticsEvent(
        offerProductsSelected,
        purchaseResults,
        false,
        'choice.postBindPage.maximumFailuresPage.page',
        'render',
      );
      trackSapiAnalyticsEvent(sapiAnalyticsEvent);
    }
    // At least one product fails to purchase and can be retried we display a retry form instead to allow user to purchase again.
    if (failed.length && retry.length) {
      const sapiAnalyticsEvent = createSapiAnalyticsEvent(
        offerProductsSelected,
        purchaseResults,
        false,
        'choice.postBindPage.retryPage.page',
        'render',
      );
      trackSapiAnalyticsEvent(sapiAnalyticsEvent);

      return (
        <CheckoutRetryForm
          fieldsByProduct={fieldsByProduct}
          productsSummary={productsSummary}
          purchaseResults={purchaseResults}
          setShowRetryModal={setShowRetryModal}
          onResubmitPayment={onResubmitPayment}
          hasTokens={hasTokens}
          showRetryModal={showRetryModal}
          submittingPayment={submittingPayment}
          currentPostBindRetryCount={currentPostBindRetryCount}
          hasBankPayToken={hasBankPayToken}
          hasRecurringPayToken={hasRecurringPayToken}
        />
      );
    }

    return null;
  };

  return (
    <div className={classes.root}>
      <Page analyticsElement='choice.postBindPage.page'>{displayCheckoutRetryPage()}</Page>
    </div>
  );
};
