import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { PagePath } from '@ecp/features/sales/shared/routing';
import { useNavigateToNextPage } from '@ecp/features/sales/shared/routing';

import { HomeMarketValueForm } from '../../forms';

export const HomeMarketValuePage: React.FC = () => {
  const handleNext = useNavigateToNextPage();

  return (
    <div>
      <Page
        title='What’s the market value of your home?'
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.HOME_MARKET_VALUE} />,
        }}
        subTitle='Approximate market value is the amount you would sell the home for. This can often be
        determined by an appraisal, tax record or by asking a real estate agent in your area.'
      >
        <HomeMarketValueForm onNext={handleNext} />
      </Page>
    </div>
  );
};
