import { isKeyOf, keyBy } from '@ecp/utils/common';

import { autoQuestionsUIMetadata } from '@ecp/features/sales/quotes/auto';
import { propertyQuestionsUIMetadata } from '@ecp/features/sales/quotes/property/home';
import type { Answers } from '@ecp/features/sales/shared/types';

import * as structure from '../structure';
import type { Error } from '../types';

interface QuestionsOverrides {
  staticQuestions: typeof structure.STATIC_QUESTIONS;
  overrides: typeof structure.questionsOverrides;
  uiMetaData: ReturnType<typeof productsUIMetadata>;
}

type WithOverrides<OriginalPayload extends Record<string, unknown> = Record<string, unknown>> = (
  payload: OriginalPayload,
) => {
  payload: OriginalPayload & QuestionsOverrides;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const productsUIMetadata = () => ({
  ...autoQuestionsUIMetadata,
  ...propertyQuestionsUIMetadata,
});

const withOverrides: WithOverrides = (payload) => ({
  payload: {
    ...payload,
    staticQuestions: structure.STATIC_QUESTIONS,
    overrides: structure.questionsOverrides,
    uiMetaData: productsUIMetadata(),
  },
});

interface ErrorsDictionary {
  [key: keyof Answers]: {
    reason: string;
    message: string;
    locationType: string;
    domain: string;
  };
}

const convertErrorsArrayToDictionary = (errors: Error[]): ErrorsDictionary =>
  keyBy(errors, 'location');

type OriginalPayload = object & { errors?: Error[] };

export type PrepareAction<T extends OriginalPayload = OriginalPayload> = (payload: T) => {
  payload: Omit<T, 'errors'> & { errors?: ErrorsDictionary };
} & ReturnType<WithOverrides<Omit<T, 'errors'>>>;

export const prepareAction: PrepareAction = (payload) => {
  const newPayload: ReturnType<PrepareAction> = withOverrides(payload);
  if (isKeyOf('errors', payload) && payload.errors)
    newPayload.payload.errors = convertErrorsArrayToDictionary(payload.errors);

  return newPayload;
};
