import { Divider, Grid } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';
import { formatDate } from '@ecp/utils/date';

import { StateFeesSection } from '@ecp/features/sales/shared/components';
import { PaymentPlan } from '@ecp/features/sales/shared/constants';
import { getOfferDetailsForProduct } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AutoProduct, Product, PropertyProduct } from '@ecp/features/shared/product';
import { getProductDisplayNameFromProduct } from '@ecp/features/shared/product';
import { useAboveMobile, useIsMobile, useIsTablet } from '@ecp/themes/base';

import { getNumOfInstallments } from '../../util';
import { EstimatedTooltip } from '../EstimatedTooltip';
import { PremiumToggle } from '../PremiumToggle';
import { Recalc } from '../Recalc';
import metadata from './metadata';
import { useStyles } from './MonthlyPaymentsCheckoutCard.styles';

interface Props {
  autoOfferProduct: AutoProduct;
  propertyOfferProduct: PropertyProduct;
  autoPSD: string;
  homePSD: string;
  isMVRActive?: boolean;
  premiumPlan: PaymentPlan;
  recalculate?: boolean;
  showProcess: boolean;
  showStateFees?: boolean;
  showToggle?: boolean;
}

export const MonthlyPaymentsBundleCoverageView: React.FC<Props> = (props) => {
  const {
    autoOfferProduct,
    propertyOfferProduct,
    autoPSD,
    homePSD,
    isMVRActive,
    premiumPlan,
    recalculate,
    showProcess,
    showStateFees,
    showToggle,
  } = props;
  const { classes, cx } = useStyles();
  const isMobile = useIsMobile();
  const isAboveMobile = useAboveMobile();
  const isTablet = useIsTablet();
  const autoOfferDetails = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, autoOfferProduct),
  );
  const propertyOfferDetails = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, propertyOfferProduct),
  );

  const propertyProductName = getProductDisplayNameFromProduct(propertyOfferProduct);

  if (!autoOfferDetails && !propertyOfferDetails) return null;

  const getMonthlyPaymentsText = (
    paymentPlan: string | undefined,
    downPayment: number | undefined,
    product?: Product,
  ): string => {
    const numberOfInstallments = getNumOfInstallments(paymentPlan, Number(downPayment), product);

    return `${numberOfInstallments} mo payment${numberOfInstallments !== 1 ? 's' : ''} of`;
  };

  const renderMonthlyView = (
    <Grid container className={classes.bundlePremiumContainer} justifyContent='center'>
      {autoOfferDetails && (
        <>
          <Grid
            container
            item
            xs={6}
            md={5}
            lg={12}
            className={classes.leftColumn}
            alignContent={isMobile ? 'start' : 'center'}
          >
            <Grid item xs={12}>
              <p className={classes.policyDurationBundleHeaderColumn}>
                <span className={classes.policyDurationHeader}>Auto</span>{' '}
                {`${autoOfferDetails.policyDuration}-mo policy`}
                {!isMobile && !isMVRActive && <EstimatedTooltip inline />}
              </p>
            </Grid>
            {isAboveMobile ? null : (
              <Grid item xs={10} sm={10} md={12} className={classes.premiumsDividerContainer}>
                <Divider aria-hidden='true' className={classes.premiumsDivider} />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              display='flex'
              alignItems='center'
              justifyContent={isMobile ? 'center' : 'left'}
            >
              <p className={classes.policyBundleCheckoutHeaders}>
                {getMonthlyPaymentsText(
                  autoOfferDetails.monthlyPremium?.paymentPlan,
                  autoOfferDetails.monthlyPremium?.downPayment,
                  autoOfferProduct,
                )}
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              className={cx(classes.premiumFeesBundleColumn, classes.bundleMonthlyPayments)}
            >
              <Recalc
                amount={parseDollar(autoOfferDetails.monthlyPremium?.installmentPayment)}
                recalculate={recalculate}
                showProcess={showProcess}
              />
              {isMobile && !isMVRActive && <EstimatedTooltip />}
            </Grid>
            {/* ECP-3809 hide 'Due today' section for mobile view */}
            {isMobile ? null : (
              <>
                <Grid container justifyContent='center'>
                  {(metadata.showDueTodayAmt || !!autoOfferDetails.monthlyPremium?.downPayment) && (
                    <Grid container item xs={9} md={12}>
                      <Grid item xs={7} md={6}>
                        <p className={classes.policyBundleCheckoutHeaders}>Due today</p>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={6}
                        className={cx(
                          classes.premiumFeesColumn,
                          classes.premiumFeesInfo,
                          classes.dueTodayText,
                        )}
                      >
                        <Recalc
                          amount={parseDollar(autoOfferDetails.monthlyPremium?.downPayment)}
                          recalculate={recalculate}
                          showProcess={showProcess}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid item container xs={12}>
                    <Grid item xs={6}>
                      <p className={classes.policyCheckoutHeaders}>Policy total</p>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className={cx(classes.premiumFeesColumn, classes.premiumFeesInfo)}
                    >
                      <Recalc
                        amount={parseDollar(autoOfferDetails.monthlyPremium?.totalPremium)}
                        recalculate={recalculate}
                        showProcess={showProcess}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={6}>
                    <p className={classes.policyBundleCheckoutHeaders}>Start date</p>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className={cx(
                      classes.premiumFeesColumn,
                      classes.premiumFeesInfo,
                      classes.policyStartDate,
                    )}
                  >
                    <span className={cx(classes.customizeButton, classes.premiumFeesInfo)}>
                      {`${formatDate(autoPSD)}`}
                    </span>
                  </Grid>
                </Grid>
                {isTablet ? null : (
                  <StateFeesSection
                    premiumPlan={premiumPlan}
                    offerDetails={autoOfferDetails}
                    showStateFees={showStateFees}
                  />
                )}
              </>
            )}
          </Grid>
          {isTablet ? null : (
            <Grid item xs={10} sm={10} md={12} className={classes.premiumsDividerContainer}>
              <Divider aria-hidden='true' className={classes.premiumsDivider} />
            </Grid>
          )}
        </>
      )}

      {propertyOfferDetails && (
        <Grid
          container
          item
          xs={6}
          md={5}
          lg={12}
          className={classes.rightColumn}
          alignContent={isMobile ? 'start' : 'center'}
        >
          <Grid item xs={12}>
            <p className={classes.policyDurationBundleHeaderColumn}>
              <span className={classes.policyDurationHeader}>{propertyProductName}</span>{' '}
              {`${propertyOfferDetails.policyDuration}-mo policy`}
            </p>
          </Grid>
          {isAboveMobile ? null : (
            <Grid item xs={10} sm={10} md={12} className={classes.premiumsDividerContainer}>
              <Divider aria-hidden='true' className={classes.premiumsDivider} />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            display='flex'
            alignItems='center'
            justifyContent={isMobile ? 'center' : 'left'}
          >
            <p className={classes.policyBundleCheckoutHeaders}>
              {getMonthlyPaymentsText(
                propertyOfferDetails.monthlyPremium?.paymentPlan,
                propertyOfferDetails.monthlyPremium?.downPayment,
                propertyOfferProduct,
              )}
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            className={cx(classes.premiumFeesBundleColumn, classes.bundleMonthlyPayments)}
          >
            <Recalc
              amount={parseDollar(propertyOfferDetails.monthlyPremium?.installmentPayment)}
              recalculate={recalculate}
              showProcess={showProcess}
            />
          </Grid>
          {/* ECP-3809 hide 'Due today' section for mobile view */}
          {isMobile ? null : (
            <>
              <Grid container justifyContent='center'>
                {(metadata.showDueTodayAmt ||
                  !!propertyOfferDetails.monthlyPremium?.downPayment) && (
                  <Grid container item xs={12}>
                    <Grid item xs={6}>
                      <p className={classes.policyBundleCheckoutHeaders}>Due today</p>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className={cx(
                        classes.premiumFeesColumn,
                        classes.premiumFeesInfo,
                        classes.dueTodayText,
                      )}
                    >
                      <Recalc
                        amount={parseDollar(propertyOfferDetails.monthlyPremium?.downPayment)}
                        recalculate={recalculate}
                        showProcess={showProcess}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid item container xs={12}>
                  <Grid item xs={6}>
                    <p className={classes.policyCheckoutHeaders}>Policy total</p>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className={cx(classes.premiumFeesColumn, classes.premiumFeesInfo)}
                  >
                    <Recalc
                      amount={parseDollar(propertyOfferDetails.monthlyPremium?.totalPremium)}
                      recalculate={recalculate}
                      showProcess={showProcess}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={6}>
                  <p className={classes.policyBundleCheckoutHeaders}>Start date</p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={cx(
                    classes.premiumFeesBundleColumn,
                    classes.premiumFeesInfo,
                    classes.policyStartDate,
                  )}
                >
                  <span className={cx(classes.customizeButton, classes.premiumFeesInfo)}>
                    {formatDate(homePSD)}
                  </span>
                </Grid>
              </Grid>
              {isTablet ? null : (
                <StateFeesSection
                  premiumPlan={premiumPlan}
                  offerDetails={propertyOfferDetails}
                  showStateFees={showStateFees}
                />
              )}
            </>
          )}
        </Grid>
      )}
    </Grid>
  );

  const renderFullPaymentView = (
    <Grid container className={classes.bundlePremiumContainer} justifyContent='center'>
      {autoOfferDetails && (
        <>
          <Grid
            container
            item
            xs={6}
            md={5}
            lg={12}
            className={classes.leftColumn}
            alignContent={isMobile ? 'start' : 'center'}
          >
            <Grid item xs={12}>
              <p className={classes.policyDurationBundleHeaderColumn}>
                <span className={classes.policyDurationHeader}>Auto</span>{' '}
                {`${autoOfferDetails.policyDuration}-mo policy`}
                {!isMobile && !isMVRActive && <EstimatedTooltip inline />}
              </p>
            </Grid>
            {isAboveMobile ? null : (
              <Grid item xs={10} sm={10} md={12} className={classes.premiumsDividerContainer}>
                <Divider aria-hidden='true' className={classes.premiumsDivider} />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              display='flex'
              alignItems='center'
              justifyContent={isMobile ? 'center' : 'left'}
            >
              <p className={classes.policyBundleCheckoutHeaders}>One-time payment of</p>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              className={cx(classes.premiumFeesBundleColumn, classes.bundleMonthlyPayments)}
            >
              <Recalc
                amount={parseDollar(autoOfferDetails.fullPremium?.totalPremium)}
                recalculate={recalculate}
                showProcess={showProcess}
              />
              {isMobile && !isMVRActive && <EstimatedTooltip />}
            </Grid>
            {isMobile ? null : (
              <>
                <Grid item container xs={12}>
                  <Grid item xs={4} sm={5}>
                    <p className={classes.policyBundleCheckoutHeaders}>Start date</p>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sm={7}
                    className={cx(
                      classes.premiumFeesBundleColumn,
                      classes.premiumFeesInfo,
                      classes.policyStartDate,
                    )}
                  >
                    <span className={cx(classes.customizeButton, classes.premiumFeesInfo)}>
                      {formatDate(autoPSD)}
                    </span>
                  </Grid>
                </Grid>
                {isTablet ? null : (
                  <StateFeesSection
                    premiumPlan={premiumPlan}
                    offerDetails={autoOfferDetails}
                    showStateFees={showStateFees}
                  />
                )}
              </>
            )}
          </Grid>
          {isTablet ? null : (
            <Grid item xs={10} sm={10} md={12} className={classes.premiumsDividerContainer}>
              <Divider aria-hidden='true' className={classes.premiumsDivider} />
            </Grid>
          )}
        </>
      )}

      {propertyOfferDetails && (
        <Grid
          container
          item
          xs={6}
          md={5}
          lg={12}
          className={classes.rightColumn}
          alignContent={isMobile ? 'start' : 'center'}
        >
          <Grid item xs={12}>
            <p className={classes.policyDurationBundleHeaderColumn}>
              <span className={classes.policyDurationHeader}>{propertyProductName}</span>{' '}
              {`${propertyOfferDetails.policyDuration}-mo policy`}
            </p>
          </Grid>
          {isAboveMobile ? null : (
            <Grid item xs={10} sm={10} md={12} className={classes.premiumsDividerContainer}>
              <Divider aria-hidden='true' className={classes.premiumsDivider} />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            display='flex'
            alignItems='center'
            justifyContent={isMobile ? 'center' : 'left'}
          >
            <p className={classes.policyBundleCheckoutHeaders}>One-time payment of</p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            className={cx(classes.premiumFeesBundleColumn, classes.bundleMonthlyPayments)}
          >
            <Recalc
              amount={parseDollar(propertyOfferDetails.fullPremium?.totalPremium)}
              recalculate={recalculate}
              showProcess={showProcess}
            />
          </Grid>
          {isMobile ? null : (
            <>
              <Grid item container xs={12}>
                <Grid item xs={4} sm={5}>
                  <p className={classes.policyBundleCheckoutHeaders}>Start date</p>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={7}
                  className={cx(
                    classes.premiumFeesBundleColumn,
                    classes.premiumFeesInfo,
                    classes.policyStartDate,
                  )}
                >
                  <span className={cx(classes.customizeButton, classes.premiumFeesInfo)}>
                    {formatDate(homePSD)}
                  </span>
                </Grid>
              </Grid>
              {isTablet ? null : (
                <StateFeesSection
                  premiumPlan={premiumPlan}
                  offerDetails={propertyOfferDetails}
                  showStateFees={showStateFees}
                />
              )}
            </>
          )}
        </Grid>
      )}
    </Grid>
  );

  return (
    <>
      {showToggle && (
        <Grid container justifyContent='center'>
          <Grid item xs={12} className={classes.toggleContainer}>
            <PremiumToggle size='large' />
          </Grid>
        </Grid>
      )}
      {premiumPlan === PaymentPlan.MONTHLY_PREMIUM ? renderMonthlyView : renderFullPaymentView}
    </>
  );
};
