import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'RecurringPaymentSetUp' })((theme) => ({
  ...theme.mixins.formAll,
  sameAccountLabel: {
    '& .MuiFormHelperText-root': {
      fontStyle: 'normal',
      fontSize: 12,
      fontWeight: 400,
      marginTop: 3,
      lineHeight: '20px',
    },
  },
  bankPaymentSection: {
    ...theme.typography.body4,
  },
  recurringPaymentAmount: {
    maxWidth: theme.breakpoints.values.md,
    marginTop: 40,
    paddingRight: 124,
  },
  bankPaymentFieldsContainer: {
    width: '100%',
  },
  invalidBankPaymentError: {
    [theme.breakpoints.down('lg')]: {
      padding: 0,
    },
    paddingLeft: 20,
    paddingRight: 20,
    color: theme.palette.error.light,
  },
  accountNumber: {
    [theme.breakpoints.down('md')]: {
      marginTop: 30,
    },
  },
}));
