import { memo } from 'react';

import { Box, Divider } from '@mui/material';

import { PhoneLink, ProductQuoteNumber } from '@ecp/features/sales/shared/components';
import { partner } from '@ecp/partners';

import { useStyles } from '../QuotesError.styles';

export const CNQ: React.FC = memo(() => {
  const { classes } = useStyles();

  return (
    <Box className={classes.cnqQnbError}>
      <h1>We need more information to complete your quote</h1>
      <ProductQuoteNumber />
      <Divider aria-hidden='true' className={classes.divider} />
      <p className={classes.guidance}>
        Thank you for your interest in purchasing a quote from Homesite. In order to complete your
        quote, we need some additional information.
      </p>
      <p className={classes.guidance}>
        Please contact us at{' '}
        <PhoneLink withUnderlinedLinkStyle number={partner.shared.qnbPhoneNumber} />. One of our
        associates would be happy to explain the information we need. We're sorry for any
        inconvenience this may cause.
      </p>
      <p>Note: We have not yet provided you with coverage.</p>
    </Box>
  );
});
