import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import type { GeoAddress } from '@ecp/utils/geo';

import { GridItem, NumberFormat } from '@ecp/components';
import type { OptionProps } from '@ecp/features/sales/shared/types';
import type { Field } from '@ecp/types';

import { AutoComplete } from '../AutoComplete';
import { Select } from '../Select';
import { TextField } from '../TextField';
import { useStyles } from './Address.styles';

interface Props {
  addressLabel?: string;
  line1: Field;
  line2: Field;
  city: Field;
  state: Field;
  zip: Field;
  autoCompleteSuggestions: string[];
  geoAddressFormattedSuggestions: GeoAddress[];
  handleSuggestionsClearRequested(): void;
  handleSuggestionsFetchRequested(val: string): void;
  gaTrackSuggestionClick?(): void;
  handleSuggestionSelected(value: string): void;
  disabled?: boolean;
  disableCity?: boolean;
  disableState?: boolean;
  disableZip?: boolean;
  selectedApt?: boolean;
  selectContainer?: HTMLElement | null;
}

export const Address: React.FC<Props> = (props) => {
  const {
    line1,
    city,
    line2,
    state,
    zip,
    autoCompleteSuggestions,
    geoAddressFormattedSuggestions,
    handleSuggestionsClearRequested,
    handleSuggestionsFetchRequested,
    gaTrackSuggestionClick,
    handleSuggestionSelected,
    disabled,
    disableCity,
    disableState,
    disableZip,
    selectedApt,
    selectContainer,
  } = props;
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Grid container columnSpacing={4}>
        {line1.exists && (
          <GridItem topSpacing='sm' xs={12} md={6}>
            <AutoComplete
              {...line1.props}
              label='Address'
              geoAddressFormattedSuggestions={geoAddressFormattedSuggestions}
              suggestions={autoCompleteSuggestions}
              selectedApt={selectedApt}
              onSuggestionsClearRequested={handleSuggestionsClearRequested}
              onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
              onSuggestionSelected={handleSuggestionSelected}
              data-testid='address'
              id='autoCompleteAddress'
              trackingName='address'
              ariaLabel='Address'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
              gaTrackSuggestionClick={gaTrackSuggestionClick}
              disabled={disabled}
              autoComplete='address-line1'
            />
          </GridItem>
        )}
        {line2.exists && (
          <GridItem topSpacing='sm' xs={12} md={6}>
            <TextField
              id='line2'
              label='Apt./Unit # (optional)'
              data-testid='aptUnit'
              {...line2.props}
              disabled={disabled}
              trackingName='address_line_2'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
              autoComplete='address-line2'
            />
          </GridItem>
        )}
        {city.exists && (
          <GridItem topSpacing='sm' xs={12} md={state.exists ? 4 : 6}>
            <TextField
              id='City'
              label='City'
              data-testid='city'
              {...city.props}
              disabled={disabled || disableCity}
              trackingName='city'
              trackingLabel={city.props.value}
              autoComplete='address-level2'
            />
          </GridItem>
        )}
        {state.exists && (
          <GridItem topSpacing='sm' xs={12} md={4}>
            <Select
              id='State'
              label='State'
              data-testid='state'
              {...(state.props as OptionProps)}
              disabled={disabled || disableState}
              trackingName='state'
              trackingLabel={state.props.value}
              autoComplete='address-level1'
              className={classes.stateQuestion}
              container={selectContainer}
            />
          </GridItem>
        )}
        {zip.exists && (
          <GridItem topSpacing='sm' xs={12} md={state.exists ? 4 : 6}>
            <NumberFormat
              id='ZipCode'
              formatType='zipcode'
              label='ZIP code'
              data-testid='zip'
              {...zip.props}
              trackingName='zip_code'
              disabled={disabled || disableZip}
              autoComplete='postal-code'
            />
          </GridItem>
        )}
      </Grid>
    </div>
  );
};
