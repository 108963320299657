import { GridItem } from '@ecp/components';
import { getLineOfBusiness } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { LineOfBusiness } from '@ecp/features/shared/product';

import {
  DogBiteHistoryQuestion,
  FireHydrantQuestion,
  FireStationQuestion,
  FullTimeEmployeesQuestion,
  PeopleLivingQuestion,
  PrimarySidingTypeQuestion,
  RetiredStatusQuestion,
  SecondaryNamedInsuredQuestion,
  UnitsInBuildingQuestion,
} from '../../components';
import { useStyles } from './PropertyDetailsFormQuestions.styles';

export const PropertyDetailsFormQuestions: React.FC = () => {
  const { classes, cx } = useStyles();
  const lineOfBusiness = useSelector(getLineOfBusiness);

  return (
    <div className={classes.root}>
      <div>
        <h2>About the policyholder</h2>
        <GridItem
          topSpacing='sm'
          xs={12}
          className={cx(classes.topSectionContainer, classes.sectionContainer)}
        >
          <DogBiteHistoryQuestion />
        </GridItem>
        {lineOfBusiness === LineOfBusiness.RENTERS && (
          <GridItem topSpacing='sm' xs={12} className={classes.sectionContainer}>
            <SecondaryNamedInsuredQuestion />
          </GridItem>
        )}
        <GridItem topSpacing='sm' xs={12}>
          <RetiredStatusQuestion />
        </GridItem>
      </div>
      <div>
        <h2 className={classes.sectionHeader}>About the unit</h2>
        <GridItem
          topSpacing='sm'
          xs={12}
          className={cx(
            classes.smSelectionField,
            classes.topSectionContainer,
            classes.selectContainer,
            classes.sectionContainer,
          )}
        >
          <PeopleLivingQuestion />
        </GridItem>
        <GridItem
          topSpacing='sm'
          xs={12}
          className={cx(classes.smSelectionField, classes.selectContainer)}
        >
          <FullTimeEmployeesQuestion />
        </GridItem>
      </div>
      <div>
        <h2 className={classes.sectionHeader}>About the rental property</h2>
        <GridItem
          topSpacing='sm'
          xs={12}
          className={cx(
            classes.smSelectionField,
            classes.topSectionContainer,
            classes.sectionContainer,
            classes.selectContainer,
          )}
        >
          <UnitsInBuildingQuestion />
        </GridItem>
        <GridItem
          topSpacing='sm'
          xs={12}
          className={cx(classes.smSelectionField, classes.sectionContainer)}
        >
          <FireStationQuestion />
        </GridItem>
        <GridItem
          topSpacing='sm'
          xs={12}
          className={cx(classes.smSelectionField, classes.sectionContainer)}
        >
          <FireHydrantQuestion />
        </GridItem>
        <GridItem
          topSpacing='sm'
          xs={12}
          className={cx(
            classes.mdSelectionField,
            classes.sectionContainer,
            classes.selectContainer,
          )}
        >
          <PrimarySidingTypeQuestion />
        </GridItem>
      </div>
    </div>
  );
};
