import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ErrorDetails' })((theme) => ({
  root: {
    flexDirection: 'column',
  },
  leftAlign: {
    alignContent: 'left',
  },
  centerAlign: {
    alignContent: 'center',
  },
  errorContainer: {
    flexDirection: 'column',
    backgroundColor: theme.palette.warning.light,
    padding: 15,
    border: `2px solid ${theme.palette.warning.main}`,
    width: '100%',
    marginBottom: 25,
  },
  subject: theme.typography.body1Bold,
  details: {
    display: 'flex',
    justifyContent: 'left',
  },
}));
