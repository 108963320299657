import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PolicySummary' })((theme) => ({
  columnLeft: {
    [theme.breakpoints.up('md')]: {
      paddingRight: 15,
      maxWidth: 'calc(100% - 15px)',
    },
  },
  columnRight: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 15,
      maxWidth: 'calc(100% - 15px)',
    },
  },
  policyTitle: {
    margin: 0,
    marginBottom: 10,
  },
  divider: {
    margin: '4px 0px 0px',
    height: 4,
    backgroundColor: theme.palette.primary.main,
    border: 'none',
  },
  mainGrid: {
    backgroundColor: theme.palette.grey['100'],
    padding: '0 20px 20px',
  },
  detail: {
    [theme.breakpoints.down('lg')]: {
      maxWidth: 'none',
      flexBasis: '100%',
    },
  },
  leftWrapper: {
    backgroundColor: theme.palette.grey['100'],
  },
  table: {
    borderRadius: 8,
    minWidth: 320,
    borderBottom: 'none',
  },
  rowStyle: {
    borderBottom: 'none',
  },
}));
