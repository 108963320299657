import type { Answers } from '@ecp/features/sales/shared/types';

import {
  doesAnswerExist,
  isAnswerProtected,
  isAnswerReadOnly,
  isAnswerToBeRemoved,
  isValueMasked,
} from '../predicates';

/**
 * Pre-processor creates the answers object ready to be passed to SAPI by following the rules:
 * - skip protected answers;
 * - skip read-only answers;
 * - skip masked values;
 * - `undefined`, empty strings and strings consisted only of whitespaces are replaced with `null` value,
 * meaning such answers will be removed on the SAPI side;
 * however, if such answer has never been answered before, it will be skipped;
 * - `null` answers are retained (if such answers already exist in the inquiry) and will be removed on the SAPI side;
 * - skip unchanged answers so answer source metadata field doesn't change unexpectedly;
 * - convert all other values into strings and trim whitespaces;
 */
export const preprocessAnswers = (
  answersToUpdate: Answers,
  allAnswers: Answers,
): Record<keyof Answers, string | undefined | null> => {
  return Object.keys(answersToUpdate).reduce((answersDraft, key) => {
    if (isAnswerProtected(key)) return answersDraft;
    if (isAnswerReadOnly(key, allAnswers)) return answersDraft;
    if (isValueMasked(key, answersToUpdate)) return answersDraft;
    if (isAnswerToBeRemoved(key, answersToUpdate)) {
      if (doesAnswerExist(key, allAnswers)) answersDraft[key] = null;

      return answersDraft;
    }

    // Skip adding answer to the draft object if the answer value hasn't changed compared with back-end state.
    // This ensures we don't pass unchanged answers to SAPI
    // so that answer source metadata field doesn't change unexpectedly, otherwise it will skew the inquiry object state.
    // Source field is hidden from the client can have the following values: customer, agent, prefill.
    // Which is important for analysts to e.g. measure prefill success rate metrics.
    if (String(answersToUpdate[key]) === String(allAnswers[key])) return answersDraft;

    answersDraft[key] = String(answersToUpdate[key]).trim();

    return answersDraft;
  }, {} as ReturnType<typeof preprocessAnswers>);
};
