import {
  IconCardAwayAtSchool,
  IconCardDefensiveDriver,
  IconCardEducationLevel,
  IconCardGoodStudent,
} from '@ecp/themes/base';
import type { OptionsMetadata } from '@ecp/types';

/**
 * The key will be used to patch the answer if a user
 * makes a selection.
 *
 * The type will be used to differentiate driver and vehicle
 * discounts since they're going to be merged into a single array
 */
const driverDiscountMetadata: { [productKey: string]: OptionsMetadata } = {
  'connect.auto': {
    DefensiveDriver: {
      title: 'Defensive driver',
      type: 'driver',
      primaryImage: <IconCardDefensiveDriver />,
      stateOptions: {
        AR: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older.',
        },
        AZ: {
          secondaryText:
            'To qualify for this discount the driver must have more than 10 years driving experience.',
        },
        UT: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older and hasn’t had any at-fault accidents within the 3 years.',
        },
        KY: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older and completed the driving course within the last 5 years. This will not apply if a suspension or revocation took place within 3 years.',
        },
        NM: {
          secondaryText:
            'To qualify for this discount, the driver must be 55 years of age or older and hasn’t had any at-fault accidents or minor violations within the past 3 years.',
        },
        MO: {
          secondaryText:
            'To qualify for this discount the driver must have more than 10 years driving experience and completed the driving course within the last 3 years.',
        },
        PA: {
          secondaryText:
            'To qualify for this discount all named insureds must be 55 years of age or older and have maintained a clean driving record.',
        },
        NJ: {
          secondaryText:
            'To qualify for this discount the driver must not have had any at-fault accidents or moving violations within the 3 years.',
        },
        CT: {
          secondaryText:
            'To qualify for this discount the driver must be 60 years of age or older.',
        },
        MD: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older, have completed the Defensive Driving course and have had a clean driving record with no at-fault accidents or more than 1 moving violation in the past 2 years.',
        },
        NH: {
          secondaryText:
            'To qualify for this discount the driver must not have had a suspended or revoked licenses within the last 3 years.',
        },
        NE: {
          secondaryText:
            'To qualify for this discount the driver must have more than 10 years driving experience.',
        },
        AL: {
          secondaryText:
            'To qualify for this discount the driver must have more than 10 years driving experience.',
        },
        IA: {
          secondaryText:
            'To qualify for this discount the driver must have more than 10 years driving experience.',
        },
        DC: {
          secondaryText:
            'To qualify for this discount the driver must have completed the Defensive Driving Course within the last 2 years without being required by a court order as a condition of probation or suspension for a moving violation.',
        },
        WA: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older and has completed the defensive driving course within the last 2 years.',
        },
        CO: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older and has not had an at-fault accident in the last 3 years.',
        },
        GA: {
          secondaryText:
            'To qualify for this discount the driver must have a clean driving record for 3 yrs. If the driver is 25+, the course must be at least 6 hrs.; if under 25, the course must have 30 hrs. of classroom training & 6 hrs. of practical training approved by the Dept. of Driver Services or accredited school.',
        },
        IL: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older and hasn’t had any at-fault accidents or minor violations within the last year.',
        },
        IN: {
          secondaryText:
            'To qualify for this discount the driver must have more than 10 years driving experience and was not required to complete the defensive driving course by the court system.',
        },
        MN: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older and completed the Motor Vehicle Accident Prevention Course.',
        },
        NV: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older and hasn’t had any at-fault accidents or minor violations within the 3 years.',
        },
        OH: {
          secondaryText:
            'To qualify for this discount the driver must be 60 years of age or older and hasn’t had any at-fault accidents or more than 1 minor violation within the 3 years.',
        },
        OR: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older and hasn’t had any at-fault accidents or minor violations within the 3 years.',
        },
        TX: {
          secondaryText:
            'To qualify for this discount the driver must complete the Driver Improvement Training course approved by the state of TX. If the driver is under 25 (male) or 21 (female) years of age, then the Youthful Driver Training course must also be completed.',
        },
        WI: {
          secondaryText:
            'To qualify for this discount the driver must have more than 10 years driving experience.',
        },
        SD: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older and hasn’t had any at-fault accidents or minor violations within the 3 years.',
        },
        MS: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older and have a clean driving record.',
        },
        VT: {
          secondaryText:
            'To qualify for this discount the driver has completed the Defensive Driving course and hasn’t had a suspended or revoked license within the last 3 years.',
        },
        VA: {
          secondaryText:
            'To qualify for this discount the driver has completed the Defensive Driving course and hasn’t had any at-fault accidents or minor violations within the last 3 years.',
        },
        MT: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older and has a clean driving record with no at-fault accidents or moving violations within the 3 years.',
        },
        DE: {
          secondaryText:
            'To qualify for this discount the driver must complete a basic or advanced driving course. The completed basic course must be 6 hours. Three years after completing the first an advanced course may be completed and must be 3-4 hours.',
        },
        ID: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older and have a clean driving record with no at-fault accidents or moving violations since completion of the Defensive Driving course.',
        },
        SC: {
          secondaryText:
            'This discount represents both the Accident Prevention Course and Driver Training Discount for all qualified drivers on the policy.',
        },
        FL: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older and have a clean driving record with no at-fault accidents or moving violations since completion of the Mature Driving course.',
        },
        TN: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older and hasn’t had any at-fault accidents or minor violations within the 3 years.',
        },
        HI: {
          secondaryText:
            'To qualify for this discount the driver must have had a clean driving record with no at-fault accidents or 2 or more moving violations in the past 3 years.',
        },
        OK: {
          secondaryText:
            'To qualify for this discount the driver must have taken then Motor Vehicle Accident Prevention course within the last 3 years without being required to do so by the court resulting from a motor vehicle violation or alcohol/drug offense.',
        },
        MA: {
          secondaryText:
            'To receive this discount, you must have been licensed less than six years and completed the course Advanced Driver Training within the last three years.',
        },
        NY: {
          secondaryText:
            'To qualify for this discount the driver must have completed a NY DMV approved defensive driver/student accident prevention course within the last 3 years.',
        },
        KS: {
          secondaryText:
            'To qualify for this discount the driver must have completed the 4-hour Motor Vehicle Accident Prevention course that was approved by the state within the last 2 years. The driver must not have been directed to take the course as punishment from a moving violation.',
        },
        CA: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older and have a clean driving record with no at-fault accidents or moving violations since completion of the Defensive Driving course.',
        },
      },
      entityKey: 'discount.defensiveDriver',
    },
    DefensiveDriverLevel: {
      title: 'Defensive driver',
      type: 'driver',
      primaryImage: <IconCardDefensiveDriver />,
      subHeaderText: 'What type of defensive driver course?',
      fieldType: 'radiogroup',
      stateOptions: {
        DE: {
          secondaryText:
            'To qualify for this discount the driver must complete a basic or advanced driving course. The completed basic course must be 6 hours. Three years after completing the first an advanced course may be completed and must be 3-4 hours.',
        },
      },
      entityKey: 'discount.defensiveDriverLabel',
    },
    GoodStudent: {
      title: 'Good student',
      type: 'driver',
      primaryImage: <IconCardGoodStudent />,
      entityKey: 'discount.goodStudent',
    },
    Student100MilesAway: {
      title: 'Away at school',
      type: 'driver',
      primaryImage: <IconCardAwayAtSchool />,
      entityKey: 'discount.awayAtSchool',
    },
    CollegeDegree: {
      title: 'College degree',
      type: 'driver',
      primaryImage: <IconCardEducationLevel />,
      entityKey: 'discount.collegeDegree',
    },
    EducationLevel: {
      title: 'College degree',
      type: 'driver',
      primaryImage: <IconCardEducationLevel />,
      subHeaderText: 'What is your highest level of education?',
      fieldType: 'radiogroup',
      entityKey: 'discount.educationLevel',
    },
  },
  'amfam.auto': {
    defensiveDriverCourse: {
      title: 'Defensive driver',
      type: 'driver',
      primaryImage: <IconCardDefensiveDriver />,
      subHeaderText: 'What type of defensive driver course?',
      fieldType: 'radiogroup',
      stateOptions: {
        DE: {
          secondaryText:
            'Applies to licensed drivers who meet the age requirement and have completed a basic or advanced driving course in the last 3 years. Course must have been taken voluntarily and not as a result of traffic violation(s), State mandated criteria may apply.',
        },
      },
      entityKey: 'discount.defensiveDriverCourse',
    },
    awayAtSchool: {
      title: 'Away at school',
      type: 'driver',
      primaryImage: <IconCardAwayAtSchool />,
      stateOptions: {
        NC: {
          secondaryText: 'Use driver experience instead of age (less than 9 years)',
        },
      },
      entityKey: 'discount.awayAtSchool',
    },
    goodStudent: {
      title: 'Good student',
      type: 'driver',
      primaryImage: <IconCardGoodStudent />,
      stateOptions: {
        NC: {
          secondaryText: 'Use driver experience instead of age (less than 9 years)',
        },
      },
      entityKey: 'discount.goodStudent',
    },
    defensiveDriver: {
      title: 'Defensive driver',
      type: 'driver',
      primaryImage: <IconCardDefensiveDriver />,
      stateOptions: {
        AR: {
          secondaryText:
            'Applies to licensed drivers who meet the age requirement and have taken a defensive driver course in the last 3 years. State mandated criteria may apply.',
        },
        CO: {
          secondaryText:
            'Driver must have completed a defensive driver course in the last 3 years. State criteria may apply. Course must have been taken voluntarily and not as a result of traffic violation(s).',
        },
        ID: {
          secondaryText:
            'Applies to licensed drivers who have taken a defensive driver course in the last 3 years. Course must have been taken voluntarily and not as a result of traffic violation(s), State mandated criteria may apply.',
        },
        UT: {
          secondaryText:
            'Applies to licensed drivers who meet the age requirement and have taken a defensive driver course in the last 3 years. Course must have been taken voluntarily and not as a result of traffic violation(s), State mandated criteria may apply.',
        },
        TN: {
          secondaryText:
            'Applies to licensed drivers who meet the age requirement and have taken a defensive driver course in the last 3 years. Course must have been taken voluntarily and not as a result of traffic violation(s), State mandated criteria may apply.',
        },
        SC: {
          secondaryText:
            'Applies to licensed drivers who meet the age requirement and have taken a defensive driver course in the last 3 years. Course must have been taken voluntarily and not as a result of traffic violation(s), State mandated criteria may apply.',
        },
        CA: {
          secondaryText:
            'Driver must have completed a defensive driver course in the last 3 years. State criteria may apply. Course must have been taken voluntarily and not as a result of traffic violation(s).',
        },
        KS: {
          secondaryText:
            'Applies to licensed drivers who have taken a defensive driver course in the last 3 years. Course must have been taken voluntarily and not as a result of traffic violation(s), State mandated criteria may apply.',
        },
        DE: {
          secondaryText:
            'Applies to licensed drivers who meet the age requirement and have completed a basic or advanced driving course in the last 3 years. Course must have been taken voluntarily and not as a result of traffic violation(s), State mandated criteria may apply.',
        },
        FL: {
          secondaryText:
            'Applies to licensed drivers who meet the age requirement and have taken a defensive driver course in the last 3 years. Course must have been taken voluntarily and not as a result of traffic violation(s), State mandated criteria may apply.',
        },
        OH: {
          secondaryText:
            'Applies to licensed drivers who meet the age requirement and have taken a defensive driver course in the last 3 years. Course must have been taken voluntarily and not as a result of traffic violation(s), State mandated criteria may apply.',
        },
        KY: {
          secondaryText:
            'To qualify for this discount the driver must have voluntarily completed a defensive driving course within the last 5 years. Military personnel, regardless of age, may be eligible if they have completed a U.S. Armed Forces Defensive Driver course, please contact us for more information.',
        },
        NM: {
          secondaryText:
            'To qualify for this discount the driver must be 55+ years of age and completed a defensive driving course within the last 3 years.',
        },
        WY: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older and completed the driving course within the last 2 years.',
        },
        RI: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older and completed the driving course within the last 2 years.',
        },
        ME: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older and completed the driving course within the last 3 years.',
        },
        PA: {
          secondaryText:
            'Applies to licensed drivers who meet the age requirement and have taken a defensive driver course in the last 3 years. The course must have been taken voluntarily and not as a result of traffic violation(s). State mandated criteria may apply.',
        },
        NJ: {
          secondaryText:
            'Applies to licensed drivers who meet the age requirement and have taken a defensive driver course in the last 3 years. State mandated criteria may apply.',
        },
        CT: {
          secondaryText:
            'Applies to licensed drivers who meet the age requirement and have taken a defensive driver course in the last 12 months. State mandated criteria may apply.',
        },
        AL: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older and completed the driving course within the last 3 years.',
        },
        DC: {
          secondaryText:
            'Applies to licensed drivers who have taken a defensive driver course in the last 2 years. State mandated criteria may apply.',
        },
        WA: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older and completed the driving course within the last 2 years.',
        },
        GA: {
          secondaryText:
            'Applies when all licensed drivers have taken a qualified defensive driver course in the last 3 years. State mandated criteria may apply.',
        },
        MS: {
          secondaryText:
            'To qualify for this discount the driver must be 55 or older and have completed a defensive driving course within the last 3 years.',
        },
        VA: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older and completed the driving course within the last 3 years.',
        },
        WV: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older and completed the driving course within the last 3 years.',
        },
        MI: {
          secondaryText:
            'To qualify for this discount the driver must have completed a defensive driving course within the last 3 years.',
        },
        MT: {
          secondaryText:
            'Applies to licensed drivers who meet the age requirement and have taken a defensive driver course in the last 3 years. State mandated criteria may apply.',
        },
        AK: {
          secondaryText:
            'Applies to licensed drivers who meet the age requirement and have taken a defensive driver course in the last 3 years. Course must have been taken voluntarily and not as a result of traffic violation(s), State mandated criteria may apply.',
        },
        OK: {
          secondaryText:
            'To qualify for this discount the driver must have a clean driving record with no at-fault accidents or moving violations within the last 3 years.',
        },
        IL: {
          secondaryText:
            'Applies to licensed drivers who meet the age requirement and have taken a defensive driver course in the last 3 years. State mandated criteria may apply.',
        },
        NY: {
          secondaryText:
            'To qualify for this discount the driver must have completed a defensive driving course within the last 3 years.',
        },
        ND: {
          secondaryText:
            'To qualify for this discount the driver must have completed the driving course within the last 3 years.',
        },
        NV: {
          secondaryText:
            'To qualify for this discount the driver must be 55 years of age or older and completed the driving course within the last 3 years.',
        },
      },
      entityKey: 'discount.defensiveDriver',
    },
    collegeDegree: {
      title: 'College degree',
      type: 'driver',
      primaryImage: <IconCardEducationLevel />,
      stateOptions: {
        AR: {
          title: 'Arkansas College Graduate',
          secondaryText:
            'You may qualify for a discount if you have 2 or 4 year college degree and graduated with a cumulative grade average of B or above (or equivalent).',
        },
      },
      entityKey: 'discount.collegeDegree',
    },
  },
  'amfam-adv.auto': {},
};

export default driverDiscountMetadata;
