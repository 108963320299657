import { useAddFields } from '@ecp/features/sales/form';
import { Slider } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const CathedralOrVaultedCeilingQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const cathedralOrVaulted = useHomePropertyField('ceiling.cathedralOrVaulted');
  const {
    groupLabel = cathedralOrVaulted.question?.title,
    trackingName = 'cathedral_vaulted_ceiling_slider',
  } = props;
  useAddFields({ cathedralOrVaulted });

  if (!cathedralOrVaulted.exists) return null;

  return (
    <Slider {...cathedralOrVaulted.props} groupLabel={groupLabel} trackingName={trackingName} />
  );
};
