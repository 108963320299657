/** Status code 0 or null or undefined */
export const statusNetworkOrCorsError = (status: number | null | undefined): boolean => !status;
/** Status code 200-299 */
export const statusOk = (status: number | null | undefined): boolean =>
  !!status && status >= 200 && status <= 299;
/** Status code 400 */
export const statusBadRequest = (status: number | null | undefined): boolean => status === 400;
/** Status code 401 or 403 */
export const statusUnauthorized = (status: number | null | undefined): boolean =>
  status === 401 || status === 403;
/** Status code 404 */
export const statusExpiredSession = (status: number | null | undefined): boolean => status === 404;
/** Status code 429 */
export const statusTooManyRequests = (status: number | null | undefined): boolean => status === 429;
/** Status code 402 or 405-428 or 430-499 */
export const statusClientError = (status: number | null | undefined): boolean =>
  !!status &&
  (status === 402 || (status >= 405 && status <= 428) || (status >= 430 && status <= 499));
/** Status code 500-599 */
export const statusServerError = (status: number | null | undefined): boolean =>
  !!status && status >= 500 && status <= 599;
/** Status code 501 */
export const statusNotImplemented = (status: number | null | undefined): boolean =>
  !!status && status === 501;
