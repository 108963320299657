import { useStyles } from './Recalc.styles';

type RecalcProps = {
  amount?: string;
  recalculate?: boolean;
  removeWrapper?: boolean;
  showProcess: boolean;
  amountClassname?: string;
};

export const Recalc = ({
  amount = '',
  recalculate,
  removeWrapper = false,
  showProcess,
  amountClassname,
}: RecalcProps): React.ReactElement => {
  const { classes, cx } = useStyles();

  return !recalculate ? (
    <span className={amountClassname}>{amount}</span>
  ) : (
    <div
      className={cx(
        amountClassname,
        classes.inlineDots,
        !removeWrapper && classes.loadingDotsContainer,
        showProcess && classes.movingLoadingContainer,
      )}
    >
      <span>. </span>
      <span>. </span>
      <span>.</span>
    </div>
  );
};
