import { useCallback } from 'react';

import {
  getField,
  getOfferProductsSelected,
  getPrimaryInsuredStateCode,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';
import {
  getProductNameFromProduct,
  getReducedProductNameFromProduct,
} from '@ecp/features/shared/product';
import { partner } from '@ecp/partners';
import type { AnswerValue, Field } from '@ecp/types';

import { agreeAcknowledgementMetadata } from '../../../metadata';
import { getIAgreeAcknowledgements } from '../../../state/acknowledgements';
import type { Acknowledgement } from '../../../types';

export interface AcknowledgementData {
  acknowledgementType: string;
  acknowledgementText: string;
  acknowledgementGroup?: string;
}

export type AcknowledgementMap = Record<string, AcknowledgementData>;

interface Acknowledgements {
  fields: Record<string, Field>;
  acknowledgements: Record<string, AcknowledgementData>;
}

interface AcknowledgementsInGroup {
  fields: Record<string, Field>;
  acknowledgements: Record<string, AcknowledgementData | AcknowledgementMap>;
}

// type AcknowledgementKey = keyof (typeof agreeAcknowledgementMetadata)[Product];
// TODO Extract these across the codebase into a function or create a Proxy and wrap each metadata object
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useGetMetadata = () => {
  const stateCode = useSelector(getPrimaryInsuredStateCode);

  return useCallback(
    (product: Product, acknowledgement: Acknowledgement) => {
      const result =
        (product &&
          (agreeAcknowledgementMetadata[product]?.[acknowledgement.key]?.stateOptions?.[
            stateCode
          ] ||
            agreeAcknowledgementMetadata[product]?.[acknowledgement.key])) ||
        {};
      // TODO Log warning if it's unable to locate metadata object vs returning an empty object

      return result;
    },
    [stateCode],
  );
};

export const useGetCheckboxIAgreeFields = (): Record<Product, Acknowledgements> => {
  const dispatch = useDispatch();

  const offerProductsSelected = useSelector(getOfferProductsSelected);
  const acknowledgements = useSelector(getIAgreeAcknowledgements);
  const getMetadata = useGetMetadata();

  return useSelector((state: RootStore) => {
    return offerProductsSelected.reduce((acc, product) => {
      const reducedProductName = getReducedProductNameFromProduct(product);
      const productName = getProductNameFromProduct(product);

      let pitchedAcknowledgements: Record<string, AcknowledgementData> = {};
      pitchedAcknowledgements =
        acknowledgements[reducedProductName]?.reduce((acc, acknowledgement) => {
          const metadataItem = getMetadata(product, acknowledgement);

          if (
            acknowledgement.key &&
            metadataItem.title &&
            !Object.values(acc).find(
              (acknowledgementData) =>
                acknowledgementData.acknowledgementText === metadataItem.title,
            )
          )
            acc[`static.checkout.checkbox.${productName}.${acknowledgement.key}`] = {
              acknowledgementType: acknowledgement.acknowledgementType,
              acknowledgementText: metadataItem.title,
              acknowledgementGroup: acknowledgement.group,
            };

          return acc;
        }, {} as Record<string, AcknowledgementData>) || {};
      const selectors = Object.keys(pitchedAcknowledgements)?.map((key: string) => {
        if (pitchedAcknowledgements[key].acknowledgementType !== 'Checkbox') return null;
        const field = getField(state, {
          key: key,
          dispatch,
        });

        return field;
      });

      let productFields: Record<string, Field> = {};
      if (!selectors)
        acc[product] = { fields: productFields, acknowledgements: pitchedAcknowledgements };
      else
        productFields = selectors?.reduce((acc, field) => {
          if (field) acc[field.key] = field;

          return acc;
        }, {} as Record<string, Field>);
      acc[product] = {
        fields: productFields,
        acknowledgements: pitchedAcknowledgements,
      };

      return acc;
    }, {} as Record<Product, Acknowledgements>);
  });
};

export const useGetCheckboxIAgreeFieldsInGroups = (): Record<Product, AcknowledgementsInGroup> => {
  const dispatch = useDispatch();

  const offerProductsSelected = useSelector(getOfferProductsSelected);
  const acknowledgements = useSelector(getIAgreeAcknowledgements);
  const getMetadata = useGetMetadata();

  return useSelector((state: RootStore) => {
    return offerProductsSelected.reduce((acc, product) => {
      const reducedProductName = getReducedProductNameFromProduct(product);
      const productName = getProductNameFromProduct(product);

      // sort - should display the acknowledgement's certificationStatement first in the dialog and below should display the coverages
      const sortedAcknowledgements = [...(acknowledgements[reducedProductName] ?? [])];
      sortedAcknowledgements?.forEach((acknowledgement, index) => {
        if (acknowledgement.acknowledgementType === 'None') {
          sortedAcknowledgements?.splice(index, 1);
          sortedAcknowledgements?.unshift(acknowledgement);
        }
      });
      let pitchedAcknowledgements: Record<string, AcknowledgementData | AcknowledgementMap> = {};
      pitchedAcknowledgements =
        sortedAcknowledgements?.reduce((acc, acknowledgement) => {
          const metadataItem = getMetadata(product, acknowledgement);
          if (acknowledgement.group && !acc[acknowledgement.group]) {
            acc[acknowledgement.group] = {};
          }
          if (
            acknowledgement.key &&
            metadataItem.title &&
            !Object.values(acc).some(
              (acknowledgementData) =>
                acknowledgementData.acknowledgementText === metadataItem.title,
            )
          ) {
            if (acknowledgement.group) {
              (acc[acknowledgement.group] as AcknowledgementMap)[
                `static.checkout.checkbox.${productName}.${acknowledgement.key}`
              ] = {
                acknowledgementType: acknowledgement.acknowledgementType,
                acknowledgementText: metadataItem.title ? metadataItem.title : '',

                acknowledgementGroup: acknowledgement.group,
              };
            } else {
              acc[`static.checkout.checkbox.${productName}.${acknowledgement.key}`] = {
                acknowledgementType: acknowledgement.acknowledgementType,
                acknowledgementText: metadataItem.title ? metadataItem.title : '',
                acknowledgementGroup: acknowledgement.group,
              };
            }
          }

          return acc;
        }, {} as Record<string, AcknowledgementMap | AcknowledgementData>) || {};

      const selectors = Object.entries(pitchedAcknowledgements).reduce((acc, [_, value]) => {
        if (!acc.length) acc = [];
        if (!value.acknowledgementText) {
          Object.entries(value as AcknowledgementMap).forEach(([subKey, subValue]) => {
            if (subValue && subValue.acknowledgementType !== 'Checkbox') return;
            const field = getField(state, {
              key: subKey,
              dispatch,
            });
            acc.push(field);
          });
        }

        return acc;
      }, {} as Field<AnswerValue>[]);

      let productFields: Record<string, Field> = {};
      if (!selectors)
        acc[product] = { fields: productFields, acknowledgements: pitchedAcknowledgements };
      else
        productFields = selectors?.reduce((acc, field) => {
          if (field) acc[field.key] = field;

          return acc;
        }, {} as Record<string, Field>);
      acc[product] = {
        fields: productFields,
        acknowledgements: pitchedAcknowledgements,
      };

      return acc;
    }, {} as Record<Product, AcknowledgementsInGroup>);
  });
};

interface IAgreeDialogParams {
  actionButtonLabel: string;
  textButtonLabel: string;
  handleActionButtonClick?(): void;
  handleTextButtonClick?(): void;
  onClose(): void;
  trackingDimension: string;
  trackingNameButton: string;
  trackingLabelButton: string;
  trackingNameText: string;
  trackingLabelText: string;
  analyticsElementRender: string;
  analyticsElementButton: string;
  analyticsElementText: string;
}

export const getIAgreeDialogParams = (
  contactUsOpen: boolean,
  showCarrierNumber: boolean,
  handleCallUsClick: () => void,
  onIAgreeClose: () => void,
  onContactUsClose: () => void,
  handleBackToAgreeClick?: () => void,
  handleIAgreeButtonClick?: () => void,
  handleHelpButtonClick?: () => void,
): IAgreeDialogParams => {
  if (contactUsOpen)
    return {
      actionButtonLabel: "Okay, I'm ready to agree",
      textButtonLabel: showCarrierNumber ? partner.shared.salesPhoneNumber : 'Call Us',
      handleActionButtonClick: handleBackToAgreeClick,
      handleTextButtonClick: handleCallUsClick,
      onClose: onContactUsClose,
      trackingDimension: 'IAgreeDialog',
      trackingNameButton: 'IAgreeCallUsButton',
      trackingLabelButton: 'IAgreeCallUs',
      trackingNameText: 'ImReadyToAgreeLink',
      trackingLabelText: 'ImReadyToAgree',
      analyticsElementRender: 'choice.iAgreeModal.modal',
      analyticsElementButton: 'choice.iAgreeModal.readyToAgreeButton',
      analyticsElementText: showCarrierNumber
        ? 'choice.iAgreeModal.talkWithAnAgentButton'
        : 'choice.iAgreeModal.callUsButton',
    };
  else
    return {
      actionButtonLabel: 'I agree, continue',
      textButtonLabel: 'Talk with an agent',
      handleActionButtonClick: handleIAgreeButtonClick,
      handleTextButtonClick: handleHelpButtonClick,
      onClose: onIAgreeClose,
      trackingDimension: 'IAgreeDialog',
      trackingNameButton: 'IAgreeContinueButton',
      trackingLabelButton: 'IAgreeContinue',
      trackingNameText: 'TalkWithAnAgentLink',
      trackingLabelText: 'TalkWithAnAgent',
      analyticsElementRender: 'choice.iAgreeModal.modal',
      analyticsElementButton: 'choice.iAgreeModal.iAgreeButton',
      analyticsElementText: 'choice.iAgreeModal.talkWithAnAgentButton',
    };
};
