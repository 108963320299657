import type { DriverVehicleListItem, Vehicle } from '@ecp/features/sales/shared/types';

interface Props {
  items:
    | Pick<Vehicle, 'year' | 'make' | 'model' | 'ref' | 'description'>[]
    | Pick<DriverVehicleListItem, 'description'>[];
  className: string;
  heading: string;
}

export const ListItems: React.FC<Props> = (props) => {
  const { items = [], className, heading } = props;
  if (!items.length) return null;

  return (
    <>
      <div>{heading}</div>
      <ul className={className}>
        {items.map(({ description }, index) => (
          <li key={index}>{description}</li>
        ))}
      </ul>
    </>
  );
};
