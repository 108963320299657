import type { FormEvent } from 'react';
import { memo, useCallback, useMemo } from 'react';

import { Grid, MenuItem, Paper } from '@mui/material';
import Autosuggest from 'react-autosuggest';

import { noop } from '@ecp/utils/common';
import type { GeoAddress } from '@ecp/utils/geo';

import type { TextFieldProps } from '@ecp/components';
import type { Props } from '@ecp/types';

import { TextField } from '../TextField';
import { useStyles } from './AutoComplete.styles';
import { useAutoCompleteInputAriaAutoCompletePatch } from './util';

interface AutoCompleteProps extends Props {
  disabled?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  id?: string;
  label?: string;
  ariaLabel?: string;
  geoAddressFormattedSuggestions?: GeoAddress[];
  trackingName?: string;
  trackingLabel?: string;
  placeholder?: string;
  required?: boolean;
  suggestions: string[];
  selectedApt?: boolean;
  onSuggestionsFetchRequested(val: string): void;
  onSuggestionsClearRequested(): void;
  onSuggestionSelected(val: string): void;
  gaTrackSuggestionClick?: () => void;
  autoComplete?: string;
}

const RenderInputComponent: Autosuggest.RenderInputComponent &
  React.FC<Autosuggest.RenderInputComponentProps> = (inputProps) => {
  const {
    inputRef = noop,
    ref,
    size: _,
    actionOnComplete, // don't patch value on onblur event as value change will be handled by onSuggestionSelected event handler
    ...rest
  } = inputProps as Autosuggest.RenderInputComponentProps & TextFieldProps;

  const referenceFunction = (node: HTMLInputElement): void => {
    if (typeof ref === 'function') ref(node);
    if (typeof inputRef === 'function') inputRef(node);
  };

  // eslint-disable-next-line react/jsx-no-bind
  return <TextField inputRef={referenceFunction} {...rest} />;
};

function getSuggestionValue(suggestion: string): string {
  return suggestion;
}

export const AutoComplete: React.FC<AutoCompleteProps> = memo((props) => {
  const { classes } = useStyles();

  const {
    value,
    ariaLabel,
    actionOnChange,
    geoAddressFormattedSuggestions,
    suggestions,
    selectedApt,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    onSuggestionSelected,
    gaTrackSuggestionClick,
    ...rest
  } = props;

  const autoCompleteContainerRef = useAutoCompleteInputAriaAutoCompletePatch();

  const handleChange = useCallback(
    (event: FormEvent, { newValue }: Autosuggest.ChangeEvent): void => {
      actionOnChange(newValue);
    },
    [actionOnChange],
  );

  const handleSuggestionsFetchRequested = useCallback(
    (input: { value: string; reason: string }) => {
      onSuggestionsFetchRequested(input.value);
    },
    [onSuggestionsFetchRequested],
  );

  const handleSuggestionSelected = useCallback(
    (
      event: React.ChangeEvent<EventTarget>,
      { suggestionValue }: { suggestionValue: string },
    ): void => {
      onSuggestionSelected(suggestionValue);
    },
    [onSuggestionSelected],
  );

  const handleComplete: NonNullable<
    Autosuggest.AutosuggestProps<string, string>['inputProps']['onBlur']
  > = useCallback(
    (event, params) => {
      if (params) {
        // If a user tabs out without highlighting over a suggestion highlightedSuggestion var will be empty.
        // We will need to grab what the user manually entered and pass it through to our handleSuggestionSelection method.
        if (!params.highlightedSuggestion) {
          const inputText = event.currentTarget as HTMLInputElement;
          handleSuggestionSelected(event, { suggestionValue: inputText.value });

          return;
        }
        // We don't need to patch selected suggestion here as it will be handled by 'onSuggestionSelected' event handler
      }
    },
    [handleSuggestionSelected],
  );

  const renderSuggestion = useCallback(
    (
      suggestion: string,
      { isHighlighted }: Autosuggest.RenderSuggestionParams,
    ): React.ReactElement => {
      if (geoAddressFormattedSuggestions) {
        // Styling last suggestion ("Use as typed")
        if (suggestion === suggestions[suggestions.length - 1] && geoAddressFormattedSuggestions) {
          const lastGeoAddress =
            geoAddressFormattedSuggestions[geoAddressFormattedSuggestions.length - 1];

          return (
            <div className={classes.useAddressAsTypedContainer}>
              <div className={classes.useAddressAsTyped}>Use address as typed</div>
              <div className={classes.defaultSuggestion}>{lastGeoAddress.street_line}</div>
            </div>
          );
        }
        // To style default suggestion differently
        // function takes a suggestion in string format so we must loop over geoAddress array in string format to search for matching address
        // then check if multiple entries AKA (apts) exist on address.
        const matchingAddress = geoAddressFormattedSuggestions.find((item) => {
          if (selectedApt) {
            return (
              item.street_line +
                (item.secondary !== ''
                  ? ` ${item.secondary} ${item.city}, ${item.state} ${item.zipcode}`
                  : ` ${item.city}, ${item.state} ${item.zipcode}`) ===
              suggestion
            );
          }

          return (
            item.street_line +
              (item.secondary !== ''
                ? ` ${item.secondary} (${item.entries} more entries) ${item.city}, ${item.state} ${item.zipcode}`
                : ` ${item.city}, ${item.state} ${item.zipcode}`) ===
            suggestion
          );
        });

        if (matchingAddress) {
          // Multiple addresses match this suggestion we want to render a suggestion line with # of entries under it.
          if (matchingAddress.entries > 1 && !selectedApt) {
            return (
              <MenuItem selected={isHighlighted} component='div' className={classes.listItem}>
                <Grid item xs={11} className={classes.addressSuggestion}>
                  {matchingAddress.street_line} {matchingAddress.secondary} (
                  {matchingAddress.entries} more entries) {matchingAddress.city},{' '}
                  {matchingAddress.state} {matchingAddress.zipcode}
                </Grid>
                <Grid item xs={1} className={classes.arrowKey}>
                  {'>'}
                </Grid>
              </MenuItem>
            );
          }

          return (
            <MenuItem selected={isHighlighted} component='div' className={classes.listItem}>
              {matchingAddress.street_line} {matchingAddress.secondary} {matchingAddress.city},{' '}
              {matchingAddress.state} {matchingAddress.zipcode}
            </MenuItem>
          );
        }
      }

      return (
        <MenuItem selected={isHighlighted} component='div' className={classes.listItem}>
          <div>{suggestion}</div>
        </MenuItem>
      );
    },
    [
      classes.arrowKey,
      classes.defaultSuggestion,
      classes.addressSuggestion,
      classes.listItem,
      classes.useAddressAsTyped,
      classes.useAddressAsTypedContainer,
      geoAddressFormattedSuggestions,
      selectedApt,
      suggestions,
    ],
  );

  const renderSuggestionsContainer: Autosuggest.RenderSuggestionsContainer = useCallback(
    (options) => (
      <Paper
        {...options.containerProps}
        onClick={gaTrackSuggestionClick}
        square
        aria-label='Suggested addresses'
      >
        {options.children}
      </Paper>
    ),
    [gaTrackSuggestionClick],
  );

  const theme = useMemo(
    () => ({
      container: classes.container,
      suggestionsContainerOpen: classes.suggestionsContainerOpen,
      suggestionsList: classes.suggestionsList,
      suggestion: classes.suggestion,
    }),
    [
      classes.container,
      classes.suggestion,
      classes.suggestionsContainerOpen,
      classes.suggestionsList,
    ],
  );

  return (
    <div className={classes.root}>
      <Autosuggest
        getSuggestionValue={getSuggestionValue}
        inputProps={{
          ...rest,
          'aria-label': ariaLabel,
          /* value will sometimes be assigned null or undefined
          such as after clicking on element and leaving without filling it out, and if so it will throw error */
          value: value || '',
          onChange: handleChange,
          onBlur: handleComplete,
          ref: autoCompleteContainerRef,
        }}
        multiSection={undefined}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionSelected={handleSuggestionSelected}
        renderInputComponent={RenderInputComponent}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        suggestions={suggestions}
        theme={theme}
      />
    </div>
  );
});
