import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { GridItem } from '@ecp/components';
import { getSelectedPaymentPlan } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { ProductName } from '@ecp/features/shared/product';
import { useIsMobile } from '@ecp/themes/base';

import {
  getPaymentOptions,
  getSelectedPaymentOptionPremium,
} from '../../../../state/paymentoptions';
import type {
  Acknowledgement as AcknowledgementProps,
  CheckoutOfferDetails,
} from '../../../../types';
import { Acknowledgement } from '../../Acknowledgement';
import { CheckoutPaymentMethod } from '../../CheckoutPaymentMethod';
import { PolicySummary } from '../../PolicySummary';
import { RecurringPaymentSetUp } from '../../RecurringPaymentSetUp';
import { Signature } from '../../Signature';
import { CarrierMessaging } from '../CarrierMessaging';
import { buildCheckoutPolicySummaryData } from '../util';
import { useStyles } from './CheckoutSection.styles';
import metadata from './metadata/metadata';

export interface Props {
  coverageType: ProductName;
  offerDetails: CheckoutOfferDetails;
  signatureAcknowledgements: AcknowledgementProps[] | undefined;
  paymentType?: string;
  handleReuseAutoPaymentCheck: (
    event: React.ChangeEvent<HTMLInputElement>,
    newChecked: boolean,
  ) => void;
  reuseAutoPayment: boolean;
  isConnectAutoCC?: boolean;
  syncPropertyToAuto?: (newType: string) => void;
  hasTokens: boolean;
  hasBankPayToken: boolean;
  hasRecurringPayToken: boolean;
}

export const CheckoutSection: React.FC<Props> = (props) => {
  const {
    coverageType,
    offerDetails,
    signatureAcknowledgements,
    paymentType,
    handleReuseAutoPaymentCheck,
    reuseAutoPayment,
    syncPropertyToAuto,
    isConnectAutoCC,
    hasTokens,
    hasBankPayToken,
    hasRecurringPayToken,
  } = props;
  const { classes } = useStyles();
  const premiumPlan = useSelector(getSelectedPaymentPlan);
  const { selectedProduct, policyStartDate, offer } = offerDetails;
  const isMobile = useIsMobile();

  const paymentOptions = useSelector(getPaymentOptions)[selectedProduct];
  const totalPremium =
    useSelector(
      (state: RootStore) => getSelectedPaymentOptionPremium(state, selectedProduct).totalPremium,
    ) || offer[premiumPlan]?.totalPremium.toString();
  const label = `Enter your ${coverageType} policy payment information`;
  const checkoutPageRedesign = flagValues[FeatureFlags.CHECKOUT_PAGE_REDESIGN];

  return (
    <>
      {!checkoutPageRedesign && metadata.showPolicySummarySection && (
        <GridItem xs={12} className={classes.policySummaryContainer}>
          <PolicySummary
            policySummaries={[
              {
                paymentType,
                ...buildCheckoutPolicySummaryData(
                  coverageType,
                  offerDetails,
                  totalPremium,
                  premiumPlan,
                ),
              },
            ]}
          />
        </GridItem>
      )}

      <GridItem topSpacing={checkoutPageRedesign ? 'sm' : 'lg'} xs={12}>
        <CheckoutPaymentMethod
          label={label}
          selectedProduct={selectedProduct}
          offerDetails={offer}
          policyStartDate={policyStartDate}
          paymentOptions={paymentOptions}
          coverageType={coverageType}
          handleReuseAutoPaymentCheck={handleReuseAutoPaymentCheck}
          reuseAutoPayment={reuseAutoPayment}
          syncPropertyToAuto={syncPropertyToAuto}
          isConnectAutoCC={isConnectAutoCC}
          hasTokens={hasTokens}
          allowPaymentAlert
          hasBankPayToken={hasBankPayToken}
        />
        {metadata.collectRecurringPay && (
          <RecurringPaymentSetUp
            selectedProduct={selectedProduct}
            coverageType={coverageType}
            hasToken={hasRecurringPayToken}
          />
        )}
        <div
          className={
            isMobile ? classes.acknowledgementMobileContainer : classes.acknowledgementContainer
          }
        >
          {metadata.showCarrierMessaging && <CarrierMessaging coverageType={coverageType} />}
          {metadata.showAcknowledgement && (
            <Acknowledgement
              signatureAcknowledgements={signatureAcknowledgements}
              selectedProduct={selectedProduct}
              coverageType={coverageType}
            />
          )}
          {metadata.showSignature && (
            <Signature selectedProduct={selectedProduct} coverageType={coverageType} />
          )}
        </div>
      </GridItem>
    </>
  );
};
