import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DiscountsBlock' })((theme) => ({
  root: {
    height: 'auto',
    borderRadius: 4,
    background: 'transparent',
    marginBottom: 0,
    boxShadow: 'none',

    '& li': {
      width: '33.33%',
      marginRight: 0,
      padding: 0,
      [theme.breakpoints.down('md')]: {
        width: '50%',
        padding: '6px 5px',
      },
    },

    '& .MuiCardContent-root:last-child': {
      padding: 0,
    },

    '& > .MuiDivider-fullWidth': {
      margin: '20px 0px',
    },

    '& > div': {
      padding: 0,
    },
  },
  content: {
    padding: 0,
    marginBottom: 'auto',
    '& ul': {
      padding: 0,
    },
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  discountsBlockContent: {},
  gmDiscountBanner: {},
  gmDiscountIcon: {},
  horizontalDivider: {},
}));
