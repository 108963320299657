import {
  GraphicLandingProductImageHomeDesktopImageUrl,
  GraphicLandingProductImageHomeMobileImageUrl,
  GraphicLandingProductImageRentersDesktopImageUrl,
  GraphicLandingProductImageRentersMobileImageUrl,
  makeStyles,
} from '@ecp/themes/base';

const heightGap = 150;

export const useStyles = makeStyles({ name: 'LandingPage' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    ...theme.mixins.pageRoot,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
    },
    width: '100%',
    margin: '0 auto',
    maxWidth: 'none',
  },
  HOME: {
    backgroundImage: `url(${GraphicLandingProductImageHomeMobileImageUrl})`,
    [theme.breakpoints.up('lg')]: {
      backgroundImage: `url(${GraphicLandingProductImageHomeDesktopImageUrl})`,
    },
  },
  RENTERS: {
    backgroundImage: `url(${GraphicLandingProductImageRentersMobileImageUrl})`,
    [theme.breakpoints.up('lg')]: {
      backgroundImage: `url(${GraphicLandingProductImageRentersDesktopImageUrl})`,
    },
  },
  landingWrapper: {
    [theme.breakpoints.down('lg')]: {
      padding: '0px 16px !important',
    },
  },
  dialogRoot: {
    maxWidth: 750,
  },
  spinner: {
    color: theme.palette.primary.main,
  },
  spinnerContainer: {
    width: 40,
    margin: 'auto',
    marginTop: 10,
  },
  maskContainer: {
    position: 'relative',
    height: 'auto',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
  },
  hiddenImage: {
    width: '100%',
    height: 'auto',
    visibility: 'hidden',
  },
  maskBase: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 'calc(100% - 3px)',
    width: '100%',
    backgroundColor: theme.palette.grey[200],
    zIndex: -1,
    [theme.breakpoints.up('lg')]: {
      height: 415,
    },
    [theme.breakpoints.up('xl')]: {
      height: 450,
    },
  },
  mask: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 0,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
  },
  mainContainer: {
    justifyContent: 'start',
    position: 'relative',
    top: 0,
    borderRadius: 0,
    [theme.breakpoints.up('lg')]: {
      top: heightGap,
      width: 750,
      marginBottom: `calc(${heightGap}px + 30px)`,
      justifyContent: 'center',
    },
    [theme.breakpoints.up('xl')]: {
      width: 860,
    },
  },
  mainContainerIsRetrieve: {
    [theme.breakpoints.up('lg')]: {
      top: heightGap,
      width: 750,
      justifyContent: 'center',
    },
  },
  mainContentSlider: {
    [theme.breakpoints.up('lg')]: {
      minHeight: 0,
    },
  },
}));
