import { useCallback, useMemo, useState } from 'react';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { Tooltip } from '@ecp/components';
import { env } from '@ecp/env';
import { Button, Dialog, PhoneLink } from '@ecp/features/sales/shared/components';
import {
  DERIVED_DISCOUNT_AUTO,
  DERIVED_DISCOUNT_HOME,
  EXISTING_AUTO_PRODUCT,
  EXISTING_HOME_PRODUCT,
  PRODUCT_LOB_USER_SELECTION,
} from '@ecp/features/sales/shared/constants';
import { productMetadata } from '@ecp/features/sales/shared/metadata';
import {
  getNextPageOnProductAddFromConfig,
  getUpdatedPageflowsFromConfig,
} from '@ecp/features/sales/shared/routing';
import {
  deleteAnswers,
  getAvailablePolicyTypes,
  getIsPrefillCompleted,
  getLineOfBusiness,
  getOffersExist,
  getProducts,
  makeSurePrefillFlowInStore,
  submitPolicyType,
  updateLobOrder,
  useGetLOBFields,
  useGetProductFields,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { LineOfBusiness } from '@ecp/features/shared/product';
import {
  getBundledLobFromUnbundledLobs,
  getLineOfBusinessUnbundledFromLineOfBusiness,
  getUnbundledLobFromProductName,
  isLineOfBusinessBundle,
  productMatchesLOB,
} from '@ecp/features/shared/product';
import { partner } from '@ecp/partners';
import { IconUIPlus } from '@ecp/themes/base';

import { useStyles } from '../NavbarDrawer';
import { ProductQuestion } from '../ProductQuestion';
import metadata from './metadata';

interface Props {
  pagePath: string;
}

export const AddProductDialog: React.FC<Props> = (props) => {
  const { pagePath } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { product: productField } = useGetProductFields();
  const availablePolicyTypes = useSelector(getAvailablePolicyTypes);
  const products = useSelector(getProducts);
  const lob = useSelector(getLineOfBusiness);
  const linesOfBusinessUnbundledSupported = availablePolicyTypes.map(
    getUnbundledLobFromProductName,
  );
  const shouldCallPrefillOnAddingProduct =
    flagValues[FeatureFlags.SHOULD_CALL_PREFILL_ON_ADDING_PRODUCT];

  const unbundledLobs = getLineOfBusinessUnbundledFromLineOfBusiness(lob);
  const optionsToAdd = useMemo(() => {
    // Find all LOBs that should be excluded from adding product
    const excludedLineOfBusiness = productMetadata.mutuallyExclusiveOptions.reduce(
      (acc, option) => {
        if (option.some((item) => unbundledLobs.includes(item))) {
          option.forEach((item) => {
            if (!unbundledLobs.includes(item)) acc.push(item);
          });
        }

        return acc;
      },
      [] as LineOfBusiness[],
    );

    const result = productMetadata.options.filter(
      (option) =>
        linesOfBusinessUnbundledSupported.includes(option.key) &&
        !unbundledLobs.includes(option.key) &&
        !excludedLineOfBusiness.includes(option.key),
    );

    return result;
  }, [linesOfBusinessUnbundledSupported, unbundledLobs]);

  const offerExist = useSelector(getOffersExist);

  const [addProductDialog, setAddProductDialog] = useState(false);
  const [productsAddedDialog, setProductsAddedDialog] = useState(false);
  const [addProcessing, setAddProcessing] = useState(false);
  const [productOptions, setProductOptions] = useState(optionsToAdd);
  const [error, setError] = useState('');
  const fields = useGetLOBFields(optionsToAdd);
  const isPrefillCompleted = useSelector(getIsPrefillCompleted);

  const toggleAddProductDialog = useCallback((): void => {
    trackClick({ action: 'SideNavAddProductButton', label: `AddProduct: ${productField}` });
    setProductOptions(optionsToAdd);
    setAddProductDialog(!addProductDialog);
  }, [addProductDialog, optionsToAdd, productField]);

  const toggleProductsAddedDialog = useCallback((): void => {
    trackClick({ action: 'SideNavAddProductButton', label: `AddProduct: ${productField}` });
    setProductsAddedDialog(!productsAddedDialog);
  }, [productsAddedDialog, productField]);

  const handleAddProduct = useCallback(async () => {
    const selectedDialogLOBs: LineOfBusiness[] = optionsToAdd
      .filter((option) => {
        const field = fields[option.value];

        return Boolean(field && field.value);
      })
      .map((option) => option.key);
    if (!selectedDialogLOBs.length) {
      setError('Required Field');

      return;
    }
    setError('');
    setAddProcessing(true);

    try {
      // TODO this makes an assumption there’s only two products ever selected (either monoline or two product bundle), WHICH IS INCORRECT
      const modifiedLOB = selectedDialogLOBs[0];
      selectedDialogLOBs.push(lob);
      const bundledLOB = getBundledLobFromUnbundledLobs(selectedDialogLOBs);
      await productField.props.actionOnComplete(bundledLOB);
      await dispatch(updateLobOrder(bundledLOB, true));

      // Everytime product.lob is modified, execute submitPolicyType
      // In V4, this creates/ removes coverage options and offers (only applicable after POST Offers)
      // No impact on V3 flow.
      await dispatch(submitPolicyType());

      // Here `isPrefillCompleted` will have the result for the prefill
      // based on prior product.lob value and not for the ones added as
      // part of this event handler. So, this is a indicator if prefill
      // has been called before.
      // DON'T use locks because they are not reliable indicators of prefill.
      // V4 -> If the product is added after prefill, call prefill as a workflow
      // step for the newly added product. If prefill hasn't been called yet, don't do anything.
      // V3 -> There is no impact on V3 flow.
      // shouldCallPrefillOnAddingProduct - EDSP-11441 Advance specific should call prefill every time a product is added
      if (isPrefillCompleted || shouldCallPrefillOnAddingProduct) {
        await dispatch(makeSurePrefillFlowInStore());
      }

      await dispatch(
        getUpdatedPageflowsFromConfig({
          modifiedLOB,
        }),
      );

      // TODO: This deleteAnswers call will be moved to DAL as part
      // of POST policy-type implementation. This is always applicable
      // anytime a product is added or removed. Keeping it here now for
      // backward compatibility until DAL changes are implemented.
      // Need to remove answers for existing products, otherwise sapi won't allow a bundle
      // See pr for more details https://gitlab.com/amfament/homesite/exclusive-carrier-platform/exclusive-carrier-platform-ui/-/merge_requests/910
      await dispatch(
        deleteAnswers({
          keys: [
            EXISTING_HOME_PRODUCT,
            EXISTING_AUTO_PRODUCT,
            DERIVED_DISCOUNT_HOME,
            DERIVED_DISCOUNT_AUTO,
            PRODUCT_LOB_USER_SELECTION, // PRODUCT_LOB_USER_SELECTION answer is  deleted so useQuotesSelection's useDetermineLineOfBusinessUserSelection, the pitchedSelection would be set to default for quote summary page
          ],
        }),
      );

      const selectedProduct = products.find((product) => productMatchesLOB(modifiedLOB, product));
      if (selectedProduct) {
        await dispatch(
          getNextPageOnProductAddFromConfig({
            path: pagePath,
            product: selectedProduct,
          }),
        );
      }

      toggleAddProductDialog();
    } finally {
      setAddProcessing(false);
    }
  }, [
    dispatch,
    fields,
    lob,
    optionsToAdd,
    pagePath,
    isPrefillCompleted,
    productField.props,
    products,
    toggleAddProductDialog,
    shouldCallPrefillOnAddingProduct,
  ]);

  return (
    <>
      <Tooltip
        data-testid='addProductTooltip'
        placement='top'
        title='Add a product'
        disableTouchListener
      >
        <Button
          variant='iconText'
          className={classes.actionIcon}
          onClick={optionsToAdd.length ? toggleAddProductDialog : toggleProductsAddedDialog}
        >
          <IconUIPlus />
        </Button>
      </Tooltip>
      {addProductDialog && (
        <Dialog
          actionButtonLabel='Save'
          textButtonLabel='Cancel'
          titleText='Add a product'
          buttonPlacement='right'
          actionButtonOnClick={handleAddProduct}
          textButtonOnClick={toggleAddProductDialog}
          open={addProductDialog}
          onClose={toggleAddProductDialog}
          isProcessing={addProcessing}
          trackingNameCloseIcon='SideNavAddProductModalXtoClose'
          trackingLabelCloseIcon='X'
          trackingNameButton='SideNavAddProductSaveButton'
          trackingLabelButton='Save'
          trackingNameText='SideNavAddProductCancelButton'
          trackingLabelText='Cancel'
        >
          <>
            {!offerExist && !env.static.isAgent && (
              <p className={classes.addProductSubHeader}>
                Select the product you’d like to add and click ‘SAVE’ to continue with your current
                quote.
              </p>
            )}
            <ProductQuestion
              options={productOptions}
              mutuallyExclusiveOptions={productMetadata.mutuallyExclusiveOptionsWithStaticPrefix}
              mutuallyDisabledOptions={productMetadata.mutuallyDisabledOptionsWithStaticPrefix}
              error={error}
            />
          </>
        </Dialog>
      )}
      {productsAddedDialog && (
        <Dialog
          actionButtonLabel='ok'
          titleText='All available products added'
          buttonPlacement='right'
          actionButtonOnClick={toggleProductsAddedDialog}
          open={productsAddedDialog}
          onClose={toggleProductsAddedDialog}
          trackingNameCloseIcon='SideNavAllAvailableProductsAddedModalXtoClose'
          trackingLabelCloseIcon='X'
          trackingNameButton='SideNavAllAvailableProductsAddedModalOK'
          trackingLabelButton='OK'
        >
          <p>
            You have added all of the products that are available online
            {isLineOfBusinessBundle(lob) ? '' : ' in your state'}!
            {!env.static.isAgent && (
              <>
                {' '}
                {metadata.phoneMessage}{' '}
                <PhoneLink withLinkStyle number={partner.shared.salesPhoneNumber} />
              </>
            )}
          </p>
        </Dialog>
      )}
    </>
  );
};
