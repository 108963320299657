import { useCallback } from 'react';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { useAddFields } from '@ecp/features/sales/form';
import { TextField } from '@ecp/features/sales/shared/components';
import {
  isDuplicatePerson,
  setFormErrorsResetByField,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue } from '@ecp/types';

import type { QuestionProps } from '../../questionProps.types';

interface Props extends QuestionProps {
  personRef: string;
  disabled: boolean;
  actionOnComplete?: (value: AnswerValue) => void | null;
}

export const LastNameQuestion: React.FC<Props> = (props) => {
  const {
    personRef,
    id = 'LastName',
    label = 'Last name',
    trackingName = 'last_name',
    trackingLabel = GoogleAnalyticsLabels.REDACTED,
    disabled,
    actionOnComplete = null,
  } = props;
  const dispatch = useDispatch();
  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const firstName = usePersonField('firstName');
  const lastName = usePersonField('lastName');
  const dateOfBirth = usePersonField('dob');
  useAddFields({ lastName });

  const handleLastNameComplete = useCallback(
    (value: AnswerValue): void => {
      lastName.props.actionOnComplete(value);
      if (isDuplicatePerson(firstName.props.error))
        dispatch(setFormErrorsResetByField({ key: `${personRef}.firstName` }));
      if (isDuplicatePerson(dateOfBirth.props.error)) {
        dispatch(setFormErrorsResetByField({ key: `${personRef}.dob` }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [firstName.props.error, dateOfBirth.props.error, dispatch, personRef],
  );

  if (!lastName.exists && !disabled) return null;

  return (
    <TextField
      {...lastName.props}
      actionOnComplete={actionOnComplete ? actionOnComplete : handleLastNameComplete}
      id={id}
      ariaLabel='Last Name'
      data-testid='lastName'
      label={label}
      trackingName={trackingName}
      trackingLabel={trackingLabel}
      disabled={disabled}
      autoComplete='family-name'
    />
  );
};
