import { emptyArray } from '@ecp/utils/common';

import { NavStatus } from '@ecp/features/sales/shared/constants';

import type { NavTracking } from './types';

export const getAggregatePathTrackingStatus = (
  state: Record<string, NavTracking>,
  paths: string[],
  referencePartialPaths?: string[],
): NavTracking => {
  // This is to capture paths with parameters (i.e. vehicle and driver edit page)
  const foundPartialPaths: string[] =
    referencePartialPaths?.flatMap((path) => {
      const matchingPartialPaths = Object.keys(state).filter((key) => key.includes(path));

      return matchingPartialPaths.length
        ? matchingPartialPaths
        : (emptyArray as unknown as string[]);
    }) ?? (emptyArray as unknown as string[]);

  const fullPaths: string[] = [...paths, ...foundPartialPaths].filter(
    (path) => !(path || '').toLowerCase().includes('error'),
  );

  const statuses: NavStatus[] = fullPaths.map(
    (path) => state[path]?.status ?? NavStatus.NOT_VISITED,
  );

  const inProgress = fullPaths.some((x) => state[x]?.inProgress);
  if (statuses.every((status) => status === NavStatus.VALID))
    return { inProgress: inProgress, status: NavStatus.VALID };

  // In the original use case (nav bar status) these all kinda mean the same thing (inactive state)
  if (statuses.some((status) => status === NavStatus.INVALID))
    return { inProgress: inProgress, status: NavStatus.INVALID };
  if (statuses.some((status) => status === NavStatus.VISITED))
    return { inProgress: inProgress, status: NavStatus.VISITED };

  return { inProgress: inProgress, status: NavStatus.NOT_VISITED };
};

export const getReferencePagePaths = (state: Record<string, NavTracking>, ref: string): string[] =>
  Object.keys(state).filter((key) => key.includes(ref));
