import { useState } from 'react';

import { useEvent } from '@ecp/utils/react';

/** Coordinate showError flag value across all CheckoutCard components on the Coverages page. */
export const useCoveragesFormCoordinatedValidation = (): {
  handleAutoCoveragesFormValidChange: (isFormValid: boolean) => void;
  handlePropertyCoveragesFormValidChange: (isFormValid: boolean) => void;
  showError: boolean;
} => {
  const [isAutoFormValid, setIsAutoFormValid] = useState(true);
  const handleAutoCoveragesFormValidChange = useEvent((isFormValid: boolean): void =>
    setIsAutoFormValid(isFormValid),
  );
  const [isPropertyFormValid, setIsPropertyFormValid] = useState(true);
  const handlePropertyCoveragesFormValidChange = useEvent((isFormValid: boolean): void =>
    setIsPropertyFormValid(isFormValid),
  );
  const showError = !(isAutoFormValid && isPropertyFormValid);

  return {
    handleAutoCoveragesFormValidChange,
    handlePropertyCoveragesFormValidChange,
    showError,
  };
};
