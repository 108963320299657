import { useShowMoreOrLess } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const KitchenCountertopMaterialQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const kitchenCountertopMaterial = useHomePropertyField('kitchenCountertopMaterial');
  const {
    label = kitchenCountertopMaterial.question?.title,
    trackingName = 'counter_materials_selection',
  } = props;

  useAddFields({ kitchenCountertopMaterial });

  const { showMore, displayOptions, handleClick } = useShowMoreOrLess(
    kitchenCountertopMaterial.props.options,
    4,
    'count_materials_more_choices',
  );

  if (!kitchenCountertopMaterial.exists) return null;

  return (
    <RadioGroupWithOptions
      {...kitchenCountertopMaterial.props}
      label={label}
      options={displayOptions}
      showMoreOrLessProps={{ showMore, onClick: handleClick }}
      trackingName={trackingName}
      cardSize='small'
    />
  );
};
