import { useCallback } from 'react';

import { trackClick } from '@ecp/utils/analytics/tracking';

import type { CheckboxProps as CheckboxBaseProps } from '@ecp/components';
import { Checkbox as CheckboxBase } from '@ecp/components';

export type CheckboxProps = CheckboxBaseProps & {
  trackingName?: string;
  trackingLabel?: string;
  'data-testid'?: string;
};

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { checked = false, onChange, trackingName, trackingLabel, ...rest } = props;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
      if (onChange) {
        onChange(event, newChecked);
      }

      if (trackingName && trackingLabel) {
        trackClick({ action: trackingName, label: trackingLabel });
      }
    },
    [onChange, trackingName, trackingLabel],
  );

  return <CheckboxBase checked={checked} onChange={handleChange} {...rest} />;
};
