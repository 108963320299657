import { memo, useEffect } from 'react';

import { Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import { getVehicleInfo, getVehicleRefs, usePostBindField } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

import { VehicleLienHolderQuestion } from '../../../components/Question/VehicleLienHolder';
import {
  getPostBindQuestionsKeysWithId,
  updatePostBindAnswers,
  updatePostBindQuestions,
} from '../../../state';
import { createDynamicQuestionsForPostBind } from './util';
import { useStyles } from './VehicleLienHolderFormQuestions.styles';

export const VehicleLienHolderFormQuestions: React.FC = memo(() => {
  const { classes } = useStyles();

  const dispatch = useDispatch();
  const vehicleRefs = useSelector(getVehicleRefs);
  const vehicles = useSelector(getVehicleInfo);
  const postBindQuestionsKeys = useSelector(getPostBindQuestionsKeysWithId);
  const dynamicQuestions = createDynamicQuestionsForPostBind(vehicleRefs, postBindQuestionsKeys);

  const vehicleLienHolderStatusField = usePostBindField(`vehicle.<id>.status`);

  useEffect(() => {
    // Create refs
    dispatch(updatePostBindAnswers({ [`vehicle.ref`]: vehicleRefs.toString() }));
    dispatch(updatePostBindQuestions(dynamicQuestions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!vehicleLienHolderStatusField.exists) return null;

  return (
    <Grid container className={classes.informationCard}>
      <GridItem className={classes.headerContainer}>
        <Grid item container>
          <Grid item xs={12}>
            <h3 className={classes.headerContainerTitle}>Vehicle lienholder information</h3>
          </Grid>
          <Grid item xs={12}>
            <p>
              Please enter lienholder information for your vehicle, if applicable. If you can’t
              provide it now, we will send you a reminder. This information is required if you have
              a loan on your vehicle.
            </p>
          </Grid>
          <Grid item xs={12}>
            {vehicles.map(({ year, make, model, ref }) => (
              <VehicleLienHolderQuestion
                key={ref}
                year={year}
                make={make}
                model={model}
                vehicleRef={ref}
                leaseHolderRef={ref.replace('vehicle', 'leaseHolder')}
                lienHolderRef={ref.replace('vehicle', 'lienHolder')}
              />
            ))}
          </Grid>
        </Grid>
      </GridItem>
    </Grid>
  );
});
