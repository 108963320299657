// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { Css, Cx } from 'tss-react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useStyles as useStylesTss } from 'tss-react/mui';

import type { Theme } from '../theme';

export const useStyles = useStylesTss as () => {
  theme: Theme;
  css: Css;
  cx: Cx;
};
