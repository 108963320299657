import { Grid } from '@mui/material';

import { AddProductDialog } from '../AddProductDialog';
import { useStyles } from '../NavbarDrawer';
import metadata from './metadata';

interface Props {
  pagePath: string;
}

export const ProductNavbarHeader: React.FC<Props> = (props) => {
  const { pagePath } = props;
  const { classes, cx } = useStyles();

  return (
    <Grid container justifyContent='space-between' alignItems='center'>
      <Grid item className={cx(classes.columnLeft, classes.stepHeadLabel)}>
        Product
      </Grid>
      <Grid item>
        {!metadata.hideAddOrRemoveProductButton && <AddProductDialog pagePath={pagePath} />}
      </Grid>
    </Grid>
  );
};
