import {
  getPrimaryInsuredStateCode,
  getStateSpecificSignatureAcknowledgementMetadata,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product, ProductName } from '@ecp/features/shared/product';

import { signatureAcknowledgementMetadata } from '../../../metadata/Acknowledgement.metadata';
import { getPNISignatureLabel, getSNISignatureLabel } from '../../../state';
import type { Acknowledgement } from '../../../types';
import { DocumentLink } from './DocumentLink';
import { useStyles } from './FullNameAcknowledgements.styles';
import { SignatureAcknowledgements } from './SignatureAcknowledgements';

interface Props {
  acknowledgements: Acknowledgement[];
  coverageType: ProductName;
  selectedProduct: Product;
}

export const FullNameAcknowledgements: React.FC<Props> = (props) => {
  const { acknowledgements, coverageType, selectedProduct } = props;
  const { classes } = useStyles();
  const pniSignature = useSelector((state: RootStore) =>
    getPNISignatureLabel(state, selectedProduct),
  );
  const sniSignature = useSelector((state: RootStore) =>
    getSNISignatureLabel(state, selectedProduct),
  );

  const renderDocumentList = (acknowledgement: Acknowledgement): React.ReactElement[] | null => {
    if (!acknowledgement.documentForms) return null;

    return acknowledgement.documentForms.map((documentForm, index) => {
      const isLast = acknowledgement.documentForms?.length === index + 1;

      return (
        <DocumentLink
          key={index}
          documentForm={documentForm}
          isLast={isLast}
          selectedProduct={selectedProduct}
          trackingName={`${documentForm.documentFormName.toLowerCase()}_link`}
          trackingLabel={documentForm.documentFormName.toLowerCase()}
        />
      );
    });
  };

  const stateCode = useSelector(getPrimaryInsuredStateCode);

  const acknowledgementList = acknowledgements.map((acknowledgement: Acknowledgement, index) => {
    const stateData = getStateSpecificSignatureAcknowledgementMetadata(
      signatureAcknowledgementMetadata[selectedProduct],
      stateCode,
    );
    const metaData = stateData[acknowledgement.key];

    return (
      metaData && (
        <li key={index} className={classes.acknowledgementItem}>
          <span className={classes.verticalAlignMiddle}>{metaData.title} </span>
          {renderDocumentList(acknowledgement)}
        </li>
      )
    );
  });

  return (
    <div>
      <ul className={classes.acknowledgementListContainer}>{acknowledgementList}</ul>
      {pniSignature && (
        <SignatureAcknowledgements
          coverageType={coverageType}
          pniSignature={pniSignature}
          sniSignature={sniSignature}
        />
      )}
    </div>
  );
};
