import { useEffect } from 'react';

import { isKeyOf } from '@ecp/utils/common';
import { Events, trackEvent } from '@ecp/utils/flags';
import { useLocation } from '@ecp/utils/routing';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { PagePath, useNavigateToNextPage } from '@ecp/features/sales/shared/routing';
import {
  getPrimaryInsuredStateCode,
  getSelectedPropertyProduct,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { DiscountsForm } from './DiscountsForm';
import * as metadata from './metadata';
import type { BaseMetadata } from './metadata';

// TODO Extract these across the codebase into a function or create a Proxy and wrap each metadata object
const useMetadata = (): BaseMetadata => {
  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const selectedPropertyProduct = useSelector(getSelectedPropertyProduct);

  const result =
    (selectedPropertyProduct &&
      (metadata.headerMetadata[selectedPropertyProduct]?.stateOptions?.[stateCode] ||
        metadata.headerMetadata[selectedPropertyProduct])) ||
    {};

  return result;
};

const discountsPageTrackingEvents = {
  [PagePath.AUTO_DISCOUNTS]: Events.AUTO_DISCOUNTS_PAGE,
  [PagePath.AUTO_DISCOUNTS_SHORT]: Events.AUTO_DISCOUNTS_PAGE,
  [PagePath.HOME_DISCOUNTS]: Events.HOME_DISCOUNTS_PAGE,
  [PagePath.RENTERS_DISCOUNTS]: Events.RENTERS_DISCOUNTS_PAGE,
};

// TODO: clean up this usage, should be covered in upcoming work
// on centralizing trackEvent instead of these individual uses
const useTrackPage = (): void => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isKeyOf(pathname, discountsPageTrackingEvents)) return;
    trackEvent(discountsPageTrackingEvents[pathname]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

// TODO This page only serves home and renters purpose atm
export const DiscountsPage: React.FC = () => {
  useTrackPage();

  const { subTitle, title } = useMetadata();
  const { pathname } = useLocation();
  const handleNext = useNavigateToNextPage();
  const handleAlternateNext = useNavigateToNextPage({ skipNextProductFlow: true });

  return (
    <div>
      <Page
        title={title}
        subTitle={subTitle}
        sidebarProps={{ drawer: <NavbarDrawer pagePath={pathname} /> }}
        analyticsElement='choice.discountPage.page'
      >
        <DiscountsForm onNext={handleNext} onAlternateNext={handleAlternateNext} />
      </Page>
    </div>
  );
};
