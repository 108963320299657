import { AUTO_POLICY_START_DATE } from '@ecp/features/sales/shared/constants';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { PolicyStartDateFields } from '@ecp/features/sales/shared/types';
import { getProductNameFromProduct } from '@ecp/features/shared/product';

import { useFieldWithPrefix } from '../form/formUtil';
import { getOfferProductsSelectedByType } from '../offers/selectors';

export function usePolicyStartDateFields(): PolicyStartDateFields {
  const useStartDateField = useFieldWithPrefix('', '');
  const products = useSelector(getOfferProductsSelectedByType);
  const propertyPoductName = products.property && getProductNameFromProduct(products.property);
  const propertyPolicyStartDateAnswerKey = `policy.${propertyPoductName}.effectiveDate`;

  return {
    auto: useStartDateField(AUTO_POLICY_START_DATE),
    property: useStartDateField(propertyPolicyStartDateAnswerKey),
  };
}
