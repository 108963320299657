import { useCallback, useMemo } from 'react';

import { Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid } from '@mui/material';

import { omit, parseDollar } from '@ecp/utils/common';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { TooltipWithIcon } from '@ecp/components';
import {
  Checkbox,
  RadioGroupWithOptions,
  Select,
  TextField,
} from '@ecp/features/sales/shared/components';
import { HealthInsurerQuestion } from '@ecp/features/sales/shared/questions';
import { getCoveragePremiumAmount, getRecalculateValue } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { CoverageItem } from '@ecp/features/sales/shared/types';
import type { Field } from '@ecp/types';

import { OtherStructuresForm } from '../OtherStructuresForm';
import { showCoverageSelectOption, showOtherStructuresCoverageSection } from '../util';
import { useStyles } from './CoverageCardItems.styles';

type Props = Pick<CoverageItem, 'field' | 'subCoverages' | 'title'>;

export const CoverageCardItems: React.FC<Props> = (props) => {
  const { field, subCoverages, title } = props;
  const { classes, cx } = useStyles();

  const pips: string[] = [];
  const excludeDriversKeys: string[] = [];

  const isCOWildfireLegislationOn = flagValues[FeatureFlags.CO_WILDFIRE_LEGISLATION];

  if (subCoverages) {
    Object.keys(subCoverages).forEach((key) => {
      if (subCoverages[key].isPipSubCoverage) pips.push(key);
      if (subCoverages[key].isExcludeDriver) excludeDriversKeys.push(key);
    });
  }

  /**
   * A generic function/template to display subcoverage field
   * @param key subcoverage key
   */
  const displaySubCoverage = useCallback(
    (
      key: string,
      subCoverageField: Field | undefined,
      subCoverageTitle: string,
      subCoverageFieldId: string,
      primaryText: string | undefined | null,
      trackingName = `change_${subCoverageTitle.toLowerCase()}_${title.toLowerCase()}_amount`,
      trackingLabel: string = subCoverageField?.props.value,
    ): React.ReactElement => {
      return (
        <>
          {subCoverageField?.props.options ? (
            <Select
              {...subCoverageField.props}
              className={classes.subCoveragesField}
              options={subCoverageField.props.options}
              label={subCoverageTitle}
              id={subCoverageFieldId}
              value={subCoverageField.props.value}
              actionOnChange={subCoverageField.props.actionOnComplete}
              trackingName={trackingName}
            />
          ) : (
            <TextField
              key={key}
              {...subCoverageField?.props}
              className={classes.subCoveragesField}
              label={subCoverageTitle}
              id={subCoverageFieldId}
              ariaLabel={subCoverageTitle}
              trackingName={trackingName}
              trackingLabel={trackingLabel}
            />
          )}
          {primaryText && (
            <TooltipWithIcon className={classes.subCoveragesTooltip} title={primaryText} />
          )}
        </>
      );
    },
    [classes.subCoveragesField, classes.subCoveragesTooltip, title],
  );

  const recalculate = useSelector(getRecalculateValue);
  const premium = useSelector((state: RootStore) => {
    const premiumAmountKey = field?.key + '.premiumAmount';
    const premiumValue = getCoveragePremiumAmount(state, premiumAmountKey);

    return premiumValue ? parseDollar(premiumValue, false) : undefined;
  });

  const getOptionsAndPremium = useMemo(() => {
    const options = field?.props?.options?.map((item) => {
      if (premium && field?.props.value !== 'No Coverage' && !recalculate) {
        return {
          ...item,
          label: (
            <span className={classes.coverageSelected}>
              {item.label}
              {field?.props.value === item.value && (
                <span className={classes.premiumValue}>{premium}/yr</span>
              )}
            </span>
          ),
        };
      }

      return item;
    });

    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [premium, recalculate]);

  if (!field?.props) return null;

  // For ADA compliance test id 244, aria-labelledby, aria-describedby cannnot have space(' '), period('.') delimiters
  const coverageFieldId = title.replace(/ /g, '');
  if (subCoverages) {
    const otherSubcoverages = omit(subCoverages, [...pips, ...excludeDriversKeys]); // omit pip subcoverages since we handle them separately

    return (
      <div className={classes.subCoveragesRoot}>
        <Grid container direction='column'>
          <div className={classes.subCoveragesRow}>
            {field.props.options ? (
              <Select
                {...field.props}
                className={classes.subCoveragesField}
                options={field.props.options}
                label={title}
                id={coverageFieldId}
                value={field.props.value}
                actionOnChange={field.props.actionOnComplete}
                actionOnComplete={undefined}
                trackingName={`change_${title.toLowerCase()}_amount`}
              />
            ) : (
              <TextField
                {...field.props}
                className={classes.subCoveragesField}
                label={title}
                id={coverageFieldId}
                ariaLabel={title}
                trackingName={`change_${title.toLowerCase()}_amount`}
                trackingLabel={field.props.value}
              />
            )}
          </div>
          {excludeDriversKeys.length > 0 && (
            <>
              <div className={classes.subCoveragesRow}>
                <TooltipWithIcon
                  className={cx(
                    classes.subCoveragesTooltip,
                    field.props.error && classes.subCoveragesTooltipError,
                  )}
                  title={subCoverages[excludeDriversKeys[0]].primaryText || ''}
                />
              </div>
              <div className={classes.subCoveragesRow}>
                {/* TODO: FormControl, FormGroup, FormLabel should be switched to use CheckboxGroup
                 instead https://bitbucket.org/p20/customersales-ui/commits/2e0ba757ec5e986c4167f40dc369aea8c85660f7 */}
                <FormControl className={classes.checkBoxGroup} component='fieldset'>
                  <FormLabel component='legend'>
                    <div className={classes.checkBoxGroupLabel}>
                      {subCoverages[excludeDriversKeys[0]].title}
                    </div>
                  </FormLabel>

                  <FormGroup>
                    {excludeDriversKeys.map((key) => {
                      // eslint-disable-next-line @typescript-eslint/no-shadow
                      const { field: subCoverageField } = subCoverages[key];
                      // For ADA compliance test id 244, aria-labelledby, aria-describedby cannnot have space(' '), period('.') delimiters
                      const subCoverageFieldId = subCoverageField?.question.description?.replace(
                        / /g,
                        '',
                      );

                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              className={classes.formControlCheckBox}
                              id={subCoverageFieldId}
                              checked={subCoverageField?.props.value === 'true'}
                              // eslint-disable-next-line react/jsx-no-bind
                              onChange={(_, checked) => {
                                subCoverageField?.props.actionOnComplete(
                                  checked ? 'true' : 'false',
                                );
                              }}
                            />
                          }
                          label={subCoverageField?.question.description}
                          className={classes.formControlLabel}
                        />
                      );
                    })}
                  </FormGroup>
                </FormControl>
              </div>
            </>
          )}
          <Divider aria-hidden='true' className={classes.divider} />

          {Object.keys(otherSubcoverages).map((key) => {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const {
              field: subCoverageField,
              title: subCoverageTitle,
              primaryText,
            } = subCoverages[key];
            // For ADA compliance test id 244, aria-labelledby, aria-describedby cannnot have space(' '), period('.') delimiters
            const subCoverageFieldId = subCoverageTitle.replace(/ /g, '');

            return (
              <div key={key} className={classes.subCoveragesRow}>
                {displaySubCoverage(
                  key,
                  subCoverageField,
                  subCoverageTitle,
                  subCoverageFieldId,
                  primaryText,
                )}
              </div>
            );
          })}

          <HealthInsurerQuestion
            subCoverages={subCoverages}
            pips={pips}
            displaySubCoverage={displaySubCoverage}
          />
        </Grid>
      </div>
    );
  }
  const dropDownCoveragesList = showCoverageSelectOption(field.props.name);

  return (
    <div className={classes.root}>
      <div className={classes.root}>
        {field.props.options ? (
          dropDownCoveragesList ? (
            <Select
              {...field.props}
              className={classes.coveragesDropdown}
              labelId={`${field.props.name}-label`}
              id={field.props.name}
              value={field.props.value}
              actionOnChange={field.props.actionOnComplete}
              displayEmpty
            />
          ) : (
            <>
              <RadioGroupWithOptions
                {...field.props}
                className={classes.hideError}
                options={isCOWildfireLegislationOn ? getOptionsAndPremium : field.props.options}
                id={field.props.name}
                dataTestId={title}
                variant='roundButton'
                trackingName={`change_${title.toLowerCase()}_amount`}
                adjustableGrid
              />
              {showOtherStructuresCoverageSection(field?.key) && (
                <OtherStructuresForm field={field} />
              )}
            </>
          )
        ) : (
          <TextField
            {...field.props}
            label='Coverage limit'
            id={coverageFieldId}
            ariaLabel='Coverage limit'
            trackingName={`change_${title.toLowerCase()}_amount`}
            trackingLabel={field.props.value}
          />
        )}
      </div>
    </div>
  );
};
