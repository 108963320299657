import type { Questions } from '@ecp/types';

export const createDynamicQuestionsForPostBind = (
  vehicleRefs: string[],
  postBindQuestionsKeys: Questions,
): Questions => {
  let dynamicKeys: Questions = {};

  vehicleRefs.forEach((ref) => {
    const leaseHolderRef = ref.replace('vehicle', 'leaseHolder');
    const lienHolderRef = ref.replace('vehicle', 'lienHolder');

    const questionsStaringWithVehicle = Object.keys(postBindQuestionsKeys).reduce((obj, key) => {
      if (key.startsWith('vehicle.<id>')) obj[key] = postBindQuestionsKeys[key];

      return obj;
    }, {} as Questions);

    const dynamicVehicleKeys = Object.keys(questionsStaringWithVehicle).reduce(
      (obj, questionKey) => {
        const dynamicKey = questionKey.replace('vehicle.<id>', ref);
        obj[dynamicKey] = questionsStaringWithVehicle[questionKey];

        return obj;
      },
      {} as Questions,
    );

    const questionsStaringWithLien = Object.keys(postBindQuestionsKeys).reduce((obj, key) => {
      if (key.startsWith('lienHolder.<id>')) obj[key] = postBindQuestionsKeys[key];

      return obj;
    }, {} as Questions);

    const dynamicLienKeys = Object.keys(questionsStaringWithLien).reduce((obj, questionKey) => {
      const dynamicKey = questionKey.replace('lienHolder.<id>', lienHolderRef);
      const originalValue = questionsStaringWithLien[questionKey];
      const requiredValue = originalValue.required
        ?.replace(/lienHolder.<id>/g, lienHolderRef)
        .replace(/vehicle.<id>/g, ref);
      const updatedValue = { ...originalValue, required: requiredValue };
      obj[dynamicKey] = updatedValue;

      return obj;
    }, {} as Questions);

    const questionsStaringWithLease = Object.keys(postBindQuestionsKeys).reduce((obj, key) => {
      if (key.startsWith('leaseHolder.<id>')) obj[key] = postBindQuestionsKeys[key];

      return obj;
    }, {} as Questions);

    const dynamicLeaseKeys = Object.keys(questionsStaringWithLease).reduce((obj, questionKey) => {
      const dynamicKey = questionKey.replace('leaseHolder.<id>', leaseHolderRef);
      const originalValue = questionsStaringWithLease[questionKey];
      const requiredValue = originalValue.required
        ?.replace(/leaseHolder.<id>'/g, leaseHolderRef)
        .replace(/vehicle.<id>/g, ref);
      const updatedValue = { ...originalValue, required: requiredValue };
      obj[dynamicKey] = updatedValue;

      return obj;
    }, {} as Questions);

    dynamicKeys = {
      ...dynamicKeys,
      ...dynamicVehicleKeys,
      ...dynamicLienKeys,
      ...dynamicLeaseKeys,
    };
  });

  return dynamicKeys;
};
