import { isProductAuto, type Product } from '@ecp/features/shared/product';

const PostBindContactInformationMetadata: { [productKey: string]: boolean } = {
  'connect.auto': false,
  'amfam.auto': true,
};
// This metadata controls hides the contact information based of the auto section
export const getAutoProductContactInfoConfig = (products: Product[]): boolean => {
  const autoProduct = products.find((product) => isProductAuto(product));

  return !!autoProduct && PostBindContactInformationMetadata[autoProduct];
};
