import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { TextField } from '@ecp/features/sales/shared/components';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import type { AnswerValue } from '@ecp/types';

import type { QuestionProps } from '../../questionProps.types';

interface Props extends QuestionProps {
  fullWidth?: boolean;
  personRef: string;
  disabled?: boolean;
  fieldKey?: string;
  actionOnComplete?: (value: AnswerValue) => void | null;
}

export const EmailQuestion: React.FC<Props> = (props) => {
  const {
    id = 'EmailAddress',
    trackingName = 'email',
    helperText,
    groupLabel,
    fullWidth,
    trackingLabel = GoogleAnalyticsLabels.REDACTED,
    personRef,
    disabled,
    fieldKey = 'email', // default
    actionOnComplete = null,
  } = props;

  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const email = usePersonField('email');
  useAddFields({ [fieldKey]: email });
  useAddConditionValues({
    conditionalFields: [email],
    isRequiredOverride: () => fieldKey.indexOf('required') >= 0,
  });

  if (!email.exists && !disabled) return null;

  return (
    <TextField
      {...email.props}
      {...(actionOnComplete && { actionOnComplete })}
      id={id}
      ariaLabel='Email'
      data-testid='email'
      helperText={helperText}
      trackingName={trackingName}
      trackingLabel={trackingLabel}
      fullWidth={fullWidth}
      groupLabel={groupLabel}
      disabled={disabled}
      autoComplete='email'
      label='Email'
      inputMode='email'
    />
  );
};
