import { useStyles } from './SingleLineDetail.styles';

interface Props {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  text: string;
  value: string | JSX.Element;
  variant?: string;
  recalculating?: boolean;
}

export const SingleLineDetail: React.FC<Props> = (props) => {
  const { text, value, variant = 'regular', recalculating = false } = props;
  const { classes, cx } = useStyles(undefined, { props });
  const isDiscount = variant === 'discount';
  const isPayment = variant === 'payment';

  return (
    // wrapper class is added to tackle long length texts.
    <div className={classes.root}>
      <div
        data-testid='singleLineDetail'
        className={cx(classes.line, { [classes.recalculatingText]: recalculating })}
      >
        <span className={classes.left}>
          <p
            className={cx(isPayment ? classes.leftWrapperNoBackground : classes.leftWrapper, {
              [classes.recalculatingText]: recalculating,
            })}
          >
            {text}
          </p>
        </span>
        <span
          className={cx(
            isPayment ? classes.rightForHideLineContent : classes.right,
            isDiscount ? classes.rightDiscount : classes.rightRegular,
            recalculating && classes.recalculatingText,
          )}
        >
          {value}
        </span>
      </div>
    </div>
  );
};
