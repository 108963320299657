import { useEffect } from 'react';

import { Container, Grid } from '@mui/material';

import { Events, trackEvent } from '@ecp/utils/flags';

import { PhoneLink } from '@ecp/components';
import { partner } from '@ecp/partners';
import { GraphicErrorCallUsImageUrl, LogoPrimaryImageUrl } from '@ecp/themes/base';

import { useStyles } from './SiteUnavailable.styles';

export const SiteUnavailable: React.FC = () => {
  const { classes } = useStyles();

  useEffect(() => {
    trackEvent(Events.PARTNER_SITE_UNAVAILABLE_PAGE);
  }, []);

  return (
    <Container className={classes.container}>
      <Grid container spacing={0}>
        <Grid item lg={2} md={2} />
        <Grid
          item
          lg={4}
          md={4}
          sm={12}
          xs={12}
          container
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
          className={classes.content}
        >
          <img src={LogoPrimaryImageUrl} alt='logo' />
          <p>
            This program is not currently open for new customers. Existing customers can call{' '}
            <PhoneLink withUnderlinedLinkStyle number={partner.shared.salesPhoneNumber} /> to
            service their policies.
          </p>
        </Grid>
        <Grid
          className={classes.imageGrid}
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          container
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
        >
          <div className={classes.imageContainer}>
            <img src={GraphicErrorCallUsImageUrl} alt='site unavailable' />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
