import { parseDollar } from '@ecp/utils/common';

export const getOptionLabelByType = (value: string, type?: string): string => {
  switch (type) {
    case 'CURRENCY':
      return parseDollar(value, false);
    default:
      return value;
  }
};
