import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AppHeader' })((theme) => ({
  headerBar: {
    ...theme.mixins.shadow1,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '0px 16px',
    height: 105,
    [theme.breakpoints.down('lg')]: {
      height: 70,
    },
    [theme.breakpoints.down('md')]: {
      padding: '0px 10px',
    },
  },
  mobileLogoOnlyHeaderBar: {
    ...theme.mixins.shadow1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
  },
  partnerLogoImage: {
    [theme.breakpoints.down('md')]: {
      width: 119,
      height: 40,
      margin: '15px 5px',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      width: 119,
      height: 40,
      margin: '15px 0px',
    },
    [theme.breakpoints.up('md')]: {
      width: 119,
      height: 40,
      margin: 15,
    },
    [theme.breakpoints.up('lg')]: {
      width: 214,
      height: 72,
      margin: 15,
    },
  },
  partnerLogoImageStyled: {
    [theme.breakpoints.between('xs', 'lg')]: {
      width: 170,
      height: 40,
      margin: 15,
    },
    [theme.breakpoints.up('md')]: {
      width: 214,
      height: 40,
      margin: 15,
    },
    [theme.breakpoints.up('lg')]: {
      width: 214,
      height: 72,
      margin: 15,
    },
  },
  partnerSecondaryLogoImage: {
    [theme.breakpoints.down('md')]: {
      margin: '15px 0px',
    },
  },
  title: {
    ...theme.typography.h5,
    marginLeft: 10,
    textAlign: 'left',
    width: 380, // To ensure the text occupies 2 lines only
    padding: '0px 10px',
    borderLeft: `1px solid ${theme.palette.text.primary}`,
    [theme.breakpoints.down('lg')]: {
      ...theme.typography.h6,
      fontWeight: 'normal',
      width: 205, // To ensure the text occupies 3 lines only
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  bannerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  contactInfo: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0px 8px 15px',
    width: 'max-content',
    height: 51,
    '& > p': {
      ...theme.typography.body2,
      color: theme.palette.text.secondary,
    },
  },
  headerDivider: {
    height: 51,
    backgroundColor: theme.palette.text.primary,
    margin: 'auto 0',
  },
  hideMobileContactInfo: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  banner: {
    backgroundColor: theme.palette.other.cookieBanner,
    height: 'auto',
    width: '100%',
  },
  bannerWrapper: {
    maxWidth: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 auto',
    [theme.breakpoints.down('xl')]: {
      alignItems: 'left',
    },
  },
  bannerText: {
    ...theme.typography.body1,
    color: theme.palette.info.contrastText,
    margin: 12,
    '& > span': {
      ...theme.typography.body1Underline,
      color: theme.palette.info.contrastText,
      '&:hover': {
        color: theme.palette.info.contrastText,
      },
    },
    [theme.breakpoints.down('md')]: {
      margin: 15,
    },
  },
  closeBanner: {
    margin: 12,
    textAlign: 'right',
    fontSize: 14,
    minWidth: 'auto',
    height: 20,
    '& svg > path': {
      fill: theme.palette.info.contrastText,
    },
    [theme.breakpoints.down('md')]: {
      margin: 15,
    },
  },
  closeIcon: {
    pointerEvents: 'all',
    cursor: 'pointer',
  },
  skipLink: {
    ...theme.typography.body3,
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    textDecoration: 'none',
    width: 1,
    '&:active, &:focus': {
      backgroundColor: theme.palette.grey[50],
      height: 'auto',
      padding: '5px 30px',
      width: 'auto',
    },
  },
  expandContactHours: {
    padding: 0,
    justifyContent: 'normal',
  },
}));
