import { merge } from '@ecp/utils/common';

import type { BaseMetadataCollection } from '@ecp/types';

import {
  HomeAdditionalRatingCriteriaMetadata as BaseHomeAdditionalRatingCriteriaMetadata,
  HomeBasicRatingCriteriaMetadata as BaseHomeBasicRatingCriteriaMetadata,
  HomeExteriorRatingCriteriaMetadata as BaseHomeExteriorRatingCriteriaMetadata,
  HomeInteriorRatingCriteriaMetadata as BaseHomeInteriorRatingCriteriaMetadata,
} from './HomeRatingCriteria.metadata.js';

export const HomeBasicRatingCriteriaMetadata: BaseMetadataCollection =
  BaseHomeBasicRatingCriteriaMetadata;
export const HomeExteriorRatingCriteriaMetadata: BaseMetadataCollection =
  BaseHomeExteriorRatingCriteriaMetadata;
export const HomeInteriorRatingCriteriaMetadata: BaseMetadataCollection =
  BaseHomeInteriorRatingCriteriaMetadata;
export const HomeAdditionalRatingCriteriaMetadata: BaseMetadataCollection = merge(
  {},
  BaseHomeAdditionalRatingCriteriaMetadata,
  {
    partnerAutoPolicy: {
      title: 'Progressive Auto Policy?',
    },
  },
);
