import { useCallback, useState } from 'react';

import { Divider, FormControlLabel, FormHelperText, Grid } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';

import { Tooltip } from '@ecp/components';
import type { CheckboxProps } from '@ecp/features/sales/shared/components';
import { Checkbox, Dialog, RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import { getFieldsByKeys, validateField } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue } from '@ecp/features/sales/shared/types';
import type { Product } from '@ecp/features/shared/product';
import { IconUITooltip } from '@ecp/themes/base';
import type { Field } from '@ecp/types';

import { useStyles } from '../DiscountCard.styles';
import type { DiscountMetadata } from '../DiscountCard.types';

type RadioObj = Record<string, string | string[]>;

export interface DiscountCardModalProps {
  open: boolean;
  onClose: (selected: AnswerValue[], radioObj: RadioObj) => void;
  product: Product;
  discounts: DiscountMetadata[];
  objRef: string;
  title: string;
  image: React.ReactElement;
}

const useDiscountFields = (keys: [string, string][], prefix: string): { [key: string]: Field } => {
  const dispatch = useDispatch();
  const fields = useSelector((state: RootStore) =>
    getFieldsByKeys(
      state,
      prefix,
      keys.map(([, key]) => key),
      dispatch,
    ),
  );

  return keys.reduce((output, [ref, key]) => ({ ...output, [ref]: fields[key] }), {});
};

export const DiscountCardModal: React.FC<DiscountCardModalProps> = (props) => {
  const { open, onClose, product, discounts, objRef, title, image } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const objName = objRef.split('.')[0];

  // !TODO effectively returns an array of keys where a key (per typedef) might be undefined,
  // but it can't be undefined, revisit `OptionMetadataWithKey` typedef
  const getSelected = useCallback(
    () =>
      discounts
        .filter((discount) => {
          return discount.availableObjects
            .filter((obj) => obj.ref === objRef)
            .find(
              (obj) =>
                !!obj.question.value &&
                obj.question.value !== 'false' &&
                obj.question.value !== 'None',
            );
        })
        .map(({ key }) => key),
    [discounts, objRef],
  );

  const getRadioObj = useCallback(() => {
    const initialObj: RadioObj = {};
    discounts.forEach((discount) => {
      if (discount.fieldType === 'radiogroup') {
        const value = discount.availableObjects.find((obj) => obj.ref === objRef)?.question?.value;
        if (discount.key && value && value !== 'false' && value !== 'None') {
          initialObj[discount.key] = value;
        }
      }
    });

    return initialObj;
  }, [discounts, objRef]);

  const getErrors = useCallback((): string[] => {
    const selectedDiscount = discounts.filter((discount) => {
      return discount.availableObjects
        .filter((obj) => obj.ref === objRef)
        .find((obj) => !!obj.question.value && obj.question.value !== 'false');
    });
    const updatedSelected = selectedDiscount.map(({ key }) => key);

    return selectedDiscount.reduce((errs, { subDiscount, title: discountTitle }) => {
      if (subDiscount && Object.keys(subDiscount).length) {
        const subKeys = Object.keys(subDiscount);
        if (!updatedSelected.some((item) => subKeys.includes(item))) {
          errs.push(`Select at least one ${discountTitle?.toLowerCase()} option`);
        }
      }

      return errs;
    }, [] as string[]);
  }, [discounts, objRef]);

  const [selected, setSelected] = useState<AnswerValue[]>(getSelected());

  const [radioObj, setRadioObj] = useState(getRadioObj());

  const discountFields = useDiscountFields(
    discounts.map(({ key = '' }) => [key, `discount.${objRef}.${key}`]),
    product,
  );

  const [error, setError] = useState(getErrors());

  const handleSaveAndClose = useCallback(() => {
    if (!error.length) onClose(selected, radioObj);
  }, [error, onClose, selected, radioObj]);

  const handleModalClose = useCallback(() => {
    trackClick({ action: `SelectDiscount_${objRef}_X`, label: 'X' });
    setSelected(getSelected());
    setRadioObj(getRadioObj());
    setError(getErrors());
    onClose(getSelected(), getRadioObj());
  }, [getSelected, objRef, onClose, getErrors, getRadioObj]);

  const handleSelect: NonNullable<CheckboxProps['onChange']> = useCallback(
    ({ currentTarget: { value: eventKey } }, checked) => {
      let updatedSelected = selected.filter((key) => key !== eventKey);

      if (!checked) {
        const { subDiscount } =
          discounts.find(
            (discount) =>
              discount.key === eventKey &&
              discount.subDiscount &&
              Object.keys(discount.subDiscount).length > 0,
          ) || {};

        if (subDiscount) {
          updatedSelected = updatedSelected.filter((key) => !subDiscount[`${key}`]);
        }
      }

      if (checked) updatedSelected.push(eventKey);
      // @ts-ignore FIXME ASAP
      if (!checked) setRadioObj({ ...radioObj, [eventKey]: null });
      const {
        update,
        question: { answerType },
      } = discountFields[eventKey] as Field;
      update(answerType === 'String' ? checked.toString() : checked);

      const newError = discounts.reduce(
        (errs, { key, availableObjects, subDiscount, title: discountTitle, fieldType }) => {
          const questionKey = `${product}.discount.${objRef}.${key}`;
          const refSelected = updatedSelected.includes(key);
          // @ts-ignore FIXME ASAP
          const { question } = availableObjects.find((obj) => obj.ref === objRef);
          const value = question.answerType === 'String' ? refSelected.toString() : refSelected;
          if (subDiscount && Object.keys(subDiscount).length && refSelected) {
            const subKeys = Object.keys(subDiscount);
            if (!updatedSelected.some((item) => subKeys.includes(item))) {
              errs.push(`Select at least one ${discountTitle?.toLowerCase()} option`);
            }
          }
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          if (fieldType === 'radiogroup' && refSelected && !radioObj[key!]) {
            errs.push(`Select at least one ${discountTitle?.toLowerCase()} option`);
          }
          if (fieldType !== 'radiogroup') {
            const validationErrors = dispatch(validateField({ key: questionKey, value, question }));
            errs = errs.concat(validationErrors); // Re-assign errs to the result of concat
          }

          return errs;
        },
        [] as string[],
      );

      setError(newError);
      setSelected(updatedSelected);
    },
    [discountFields, discounts, dispatch, objRef, product, selected, radioObj],
  );

  const handleRadioGroupChange = useCallback(
    (discount: DiscountMetadata, value: AnswerValue) => {
      const eventKey = discount.key ?? '';
      const answer = value ? value.toString() : '';
      const { update } = discountFields[eventKey] as Field;
      update(answer);

      setRadioObj({ ...radioObj, [eventKey]: answer });
      const existingErrors = error.filter((item) => !item.includes(discount.title?.toLowerCase()));
      setError(existingErrors);
    },
    [discountFields, radioObj, error],
  );

  const subDiscountList = (discount: DiscountMetadata, hasError: boolean): React.ReactElement => (
    <div className={classes.vehicleDriverList}>
      <Grid item xs={12} className={classes.subDiscountDivider}>
        <Divider aria-hidden='true' className={classes.divider} />
      </Grid>
      <p className={`${classes.subDiscountTitle} ${hasError ? `${classes.error}` : ''}`}>
        {discount.subHeaderText}
      </p>
      {discounts
        .filter((item) => item.parentKey === discount.key)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .sort((a, b) => a.key!.localeCompare(b.key!))
        .map((subDiscount) => (
          <div key={subDiscount.key} className={classes.subDiscountItem}>
            <FormControlLabel
              label={
                <>
                  <p className={classes.formLabel}>{subDiscount.title}</p>
                  {subDiscount.secondaryText && (
                    <Tooltip title={subDiscount.secondaryText} arrow key={subDiscount.title}>
                      <IconUITooltip className={classes.helpIcon} />
                    </Tooltip>
                  )}
                </>
              }
              control={
                <Checkbox
                  trackingName={`${subDiscount.key?.toLowerCase()}_selection_${objRef}`}
                  trackingLabel={selected.includes(subDiscount.key) ? '0' : '1'}
                  value={subDiscount.key}
                  checked={selected.includes(subDiscount.key)}
                  onChange={handleSelect}
                  data-testid={subDiscount.key}
                />
              }
              disabled={product !== 'connect.auto'}
            />
          </div>
        ))}
    </div>
  );

  const chooseAnswer = (discount: DiscountMetadata, hasError: boolean): React.ReactElement => {
    const availableObject = discount.availableObjects.find((obj) => obj.ref === objRef);
    const options = availableObject?.question?.options?.filter((opt) => opt.value !== 'None');

    return (
      <div>
        <Grid item xs={12} className={classes.subDiscountDivider}>
          <Divider aria-hidden='true' className={classes.divider} />
        </Grid>
        <p className={`${classes.subDiscountTitle} ${hasError ? `${classes.error}` : ''}`}>
          {discount.subHeaderText}
        </p>
        <div className={classes.discountRadioButton}>
          <RadioGroupWithOptions
            name={discount.key}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            value={radioObj[discount.key!]}
            id={discount.key}
            options={options}
            // eslint-disable-next-line react/jsx-no-bind
            actionOnComplete={(value: AnswerValue) => handleRadioGroupChange(discount, value)}
            trackingName={`${discount.key?.toUpperCase()}_type_selection_${objRef}`}
            variant='classic'
            className={classes.radioButtonMargin}
          />
        </div>
      </div>
    );
  };

  const discountList = (): React.ReactElement => (
    <div className={classes.vehicleDriverList}>
      {discounts
        .filter((item) => !item.parentKey)
        .map((discount, index) => {
          const hasError = error.some((item) => item.includes(discount.title?.toLowerCase()));
          const checked = selected.includes(discount.key);

          return (
            <div key={discount.key}>
              {index ? (
                <Grid item xs={12}>
                  <Divider aria-hidden='true' className={classes.divider} />
                </Grid>
              ) : (
                ''
              )}
              <FormControlLabel
                label={
                  <p className={`${classes.formLabel} ${hasError ? 'error' : ''}`}>
                    {discount.title}
                  </p>
                }
                control={
                  <Checkbox
                    trackingName={`${discount.key?.toLowerCase()}_selection_${objRef}`}
                    trackingLabel={checked ? '0' : '1'}
                    value={discount.key}
                    checked={checked}
                    onChange={handleSelect}
                    data-testid={discount.key}
                  />
                }
              />
              {discount.secondaryText && (
                <Tooltip title={discount.secondaryText} arrow key={discount.title}>
                  <span>
                    <IconUITooltip className={classes.helpIcon} />
                  </span>
                </Tooltip>
              )}
              {discount.fieldType === 'radiogroup' && checked && chooseAnswer(discount, hasError)}
              {checked &&
                discount.subDiscount &&
                Object.keys(discount.subDiscount).length > 0 &&
                subDiscountList(discount, hasError)}
              {error && hasError && (
                <FormHelperText role='alert' error className={classes.fieldError}>
                  {error.find((item) => item.includes(discount.title?.toLowerCase()))}
                </FormHelperText>
              )}
            </div>
          );
        })}
    </div>
  );

  return (
    <Dialog
      open={open}
      scroll='paper'
      onClose={handleModalClose}
      PaperProps={{ classes: { root: classes.dialogPaper } }}
      aria-labelledby='discount-modal'
      data-testid='discountCardModal'
      actionButtonOnClick={handleSaveAndClose}
      actionButtonLabel='Save &amp; close'
      trackingNameButton={`review_${objName}_discounts_save`}
      trackingLabelButton='Save'
      disableActionButton={error.length > 0}
      titleText={
        <Grid item xs={12} className={classes.modalTitleContainer}>
          {image}
          <p className={classes.modalTitle}>{title}</p>
        </Grid>
      }
    >
      {discountList()}
      <Grid item xs={12}>
        <Divider aria-hidden='true' className={classes.divider} />
      </Grid>
    </Dialog>
  );
};
