import type { RootStore } from '@ecp/features/sales/shared/store/types';
import type { GetVehicleBase } from '@ecp/features/sales/shared/types';

import { getVehicleImageUrlForAllModels } from '../../util';
import { composeVehicleKey } from '../../util/composeVehicleKey';

export const getVehicleImageUrls = (state: RootStore): Record<string, string> =>
  state.vehicles.images;

export const getVehicleImageUrl = (
  state: RootStore,
  year: string,
  make: string,
  model: string,
): string => {
  const key = composeVehicleKey({ make, model, year });
  const imageUrl = getVehicleImageUrls(state)[key];

  return imageUrl || '';
};

export const getResolvedVehicleImageUrl = (
  state: RootStore,
  vehicle: GetVehicleBase,
): string | undefined => getVehicleImageUrlForAllModels(vehicle, getVehicleImageUrls(state));
