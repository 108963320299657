import { location } from '@ecp/utils/routing';
import { deviceInformation } from '@ecp/utils/web';

import { env } from '@ecp/env';
import type { Answers } from '@ecp/features/sales/shared/types';

/** Parameters coming from the query string params in the URL. */
interface ParsedSearchParams {
  // TODO Figure out what each one of these params means
  campaignid?: string;
  emailuuid?: string; // chase email only has a retrieve link, need to figure out how retrieve links look like
  flowid?: string; // Passed from the Connect website
  /** Product selection from the referrer website landing page. */
  product?: string;
  redirectid?: string;
  refid?: string;
  /**
   * The source where the URL originates from.
   * Currently we use it only to signify that the session has started from the synthetic tests or e2e tests.
   * So that we can skip loading GTM/Google Analytics and Hotjar.
   */
  source?: 'test';
  vanity?: string;
  /** Zip code selection from the referrer website landing page. */
  zip?: string;
}

/** Parameters coming from multiple sources other than the query string in the URL. */
type MiscParams = {
  landing_url?: string;
  referrer_url?: string;
  browser_version?: string;
  device_type?: string;
  operating_system_name?: string;
  operating_system_version?: string;
};

type AnalyticsAnswers = {
  [K in `analytics.${keyof (ParsedSearchParams & MiscParams)}`]?: string;
};

/** App routing query string params should not be included in analytics answers. */
const SKIP_QUERY_STRING_KEYS = /^(showRetrieve|referencePage|loading)$|^env\./;

// TODO Skip using and transforming the app query strings

export const getAnalyticsAnswersFromQueryForInquiry = (): Answers => {
  const query = location.search;

  // Start with common answers (consumer + agent)
  const answers: AnalyticsAnswers = {
    'analytics.browser_version': deviceInformation.browser.version,
    'analytics.device_type': deviceInformation.device.type,
    'analytics.operating_system_name': deviceInformation.os.name,
    'analytics.operating_system_version': deviceInformation.os.version,
  };

  // Add consumer answers
  if (!env.static.isAgent) {
    answers['analytics.landing_url'] = window.location.href;
    answers['analytics.referrer_url'] = document.referrer;

    // Ensure that we are capturing only necessary analytics params from the query string
    // as it might include params related to our app navigation (like on driver and vehicle pages)
    {
      let key: string;
      let keyLowercased: string;
      for (key in query) {
        keyLowercased = key.toLowerCase();
        if (!SKIP_QUERY_STRING_KEYS.test(key))
          answers[`analytics.${keyLowercased as keyof ParsedSearchParams}`] = query[key];
      }
    }
  }

  return answers;
};
