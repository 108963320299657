import type { ReactNode } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { trackRender } from '@ecp/utils/analytics/tracking';
import { Events, FeatureFlags, flagValues, trackEvent } from '@ecp/utils/flags';
import { datadogLog } from '@ecp/utils/logger';
import { sessionStorage } from '@ecp/utils/storage';
import { scrollToElement, scrollToTop } from '@ecp/utils/web';

import { Page } from '@ecp/features/sales/shared/components';
import { OfferStatusCode, PurchaseErrorReason } from '@ecp/features/sales/shared/constants';
import {
  getCustomerId,
  getDalSessionId,
  getInquiryId,
  getLobForOfferProductsSelected,
  getOfferProductsSelected,
  getOfferSetId,
  getPurchaseError,
  updateGlobalError,
  useForm,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Condition } from '@ecp/features/sales/shared/types';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import { isProductHome } from '@ecp/features/shared/product';
import type { Field, Fields } from '@ecp/types';

import { PostBindForm } from '../../forms/PostBindForm';
import { fetchAcknowledgementResponse, pushPaymentOption } from '../../state';
import { getPostBindProductsSummary } from '../../state/postbind';
import { getPurchaseRequest, getPurchaseResponse, postPurchase } from '../../state/purchase';
import {
  checkEligiblePurchaseRequestProducts,
  cleanUpFields,
  getPurchaseResults,
  shouldDisplayErrorPage,
} from '../../state/purchaseUtil';
import {
  createSapiAnalyticsEvent,
  getPaymentFields,
  getProductsAndPolicyNumbers,
  useGetMarketingFields,
  useGetSelectedProductsCheckoutFields,
} from '../../util';
import metadata from './metadata';
import { PostBindLoadingPage } from './PostBindLoadingPage';
import { useStyles } from './PostBindPage.styles';

export interface PaymentFields extends Fields {
  [fieldKey: string]: Field;
}

export interface PostBindPageProps {
  disclosureScripts?: ReactNode;
  disclosureScriptsDialog?: ReactNode;
}

export const PostBindPage: React.FC<PostBindPageProps> = (props) => {
  const { disclosureScripts, disclosureScriptsDialog } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [showLoadingPage, setShowLoadingPage] = useState(false);
  const [hidePageInfo, setHidePageInfo] = useState(false);
  const [submittingPayment, setSubmittingPayment] = useState(false);
  // TODO This always must be derived state
  const [hasTokens, setHasTokens] = useState(true);
  const [hasBankPayToken, setHasBankPayToken] = useState(true);
  const [hasRecurringPayToken, setHasRecurringPayToken] = useState(true);
  const customerId = useSelector(getCustomerId);
  const dalSessionId = useSelector(getDalSessionId);
  const inquiryId = useSelector(getInquiryId);
  const offerSetId = useSelector(getOfferSetId);
  const purchaseResponse = useSelector(getPurchaseResponse);
  const marketingFields = useGetMarketingFields();
  const lineOfBusiness = useSelector(getLobForOfferProductsSelected);
  const purchaseError = useSelector(getPurchaseError);
  const currentPostBindRetryCount = sessionStorage.getItem('PostBindRetryCount') as number;
  const offerProductsSelected = useSelector(getOfferProductsSelected);
  const productsSummary = useSelector((state: RootStore) =>
    getPostBindProductsSummary(state, offerProductsSelected),
  );

  const fieldsByProduct = useGetSelectedProductsCheckoutFields(offerProductsSelected);

  const purchaseResults = getPurchaseResults(productsSummary);

  const purchaseRequest = useSelector((state: RootStore) =>
    getPurchaseRequest(state, offerProductsSelected),
  );

  const shouldGenerateTokenForEFTPay = flagValues[FeatureFlags.EFT_PAY_THROUGH_PAYMENT_GATEWAY];

  const { purchased, failed, retry } = purchaseResults;

  const handleNext = useCallback(() => {
    setHidePageInfo(true);
    scrollToTop();
  }, []);

  useEffect(() => {
    if (shouldDisplayErrorPage(failed, retry, purchaseError, offerProductsSelected)) {
      dispatch(
        updateGlobalError({
          hasError: true,
          requestId: '',
          errorReason: PurchaseErrorReason.UNKNOWN_PURCHASE_FAILURE,
          name: '',
          text: '',
        }),
      );
    }

    const { policyNumberPostBind, productsSelected } = getProductsAndPolicyNumbers(
      purchaseResponse,
      productsSummary,
    );

    trackRender({
      action: 'PostbindRender',
      label: `product: ${productsSelected}, policyNumber: ${policyNumberPostBind}`,
    });
    // A/B test metric tracking function for postbind page view
    trackEvent(Events.POSTBIND_PAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, purchaseResponse.policies, offerSetId]);

  const initValues = useRef({});

  const conditions = purchaseResults.retry.reduce((acc, product) => {
    if (isProductHome(product)) {
      const productFields = fieldsByProduct[product];
      acc.push({
        conditionalFields: [productFields.phoneNumber],
        isRequiredOverride: () =>
          productFields.paymentMethod.value === 'Mortgage' &&
          productFields.mortgageKnowMortgageCompany.value === false,
      });
    }

    return acc;
  }, [] as Condition[]);

  const { validateForm } = useForm({
    initValues,
    fields: getPaymentFields(purchaseResults.retry, fieldsByProduct),
    conditions: conditions.length ? conditions : undefined,
  });

  const onResubmitPayment = useCallback(async () => {
    if (!dalSessionId || !offerSetId) {
      return;
    }
    // ensure tokens are in place for carriers that require them
    setHasTokens(true);
    setHasBankPayToken(true);
    setHasRecurringPayToken(true);
    let purchaseTokens = true;
    Object.values(purchaseRequest).forEach((purchase) => {
      // If token is not available, set purchaseTokens = false and purchase won't be called.
      if (purchase.paymentOption.creditCard && !purchase.paymentOption.creditCard.token) {
        setHasTokens(false);
        purchaseTokens = false;
        const firstPaymentMethod = document.querySelector('[class*="CheckoutForm-box"]');
        if (firstPaymentMethod) {
          scrollToElement({ element: firstPaymentMethod });
        }
      }
      // If bank pay token is not available, set purchaseTokens = false and purchase won't be called
      if (
        shouldGenerateTokenForEFTPay &&
        purchase.paymentOption.bankAccount &&
        !purchase.paymentOption.bankAccount.financialAccountToken
      ) {
        setHasBankPayToken(false);
        purchaseTokens = false;
      }
      // If recurring pay bank pay token is not available, set purchaseTokens = false and purchase won't be called
      if (
        shouldGenerateTokenForEFTPay &&
        purchase.paymentOption.banckAccountForRecurringPay &&
        !purchase.paymentOption.banckAccountForRecurringPay.financialAccountToken
      ) {
        setHasRecurringPayToken(false);
        purchaseTokens = false;
      }
    });

    if (validateForm().isValid && purchaseTokens) {
      setSubmittingPayment(true);
      setShowLoadingPage(true);
      await checkEligiblePurchaseRequestProducts(productsSummary, purchaseRequest);
      const newCount = currentPostBindRetryCount + 1;
      // only let user try to resubmit payment a maxium of 3 times before stopping them from continuing.
      if (purchaseTokens) {
        productsSummary.forEach(
          async ({ offerCheckoutDetails, offerEligibility, payment, product }) => {
            if (
              offerCheckoutDetails &&
              payment.paymentType &&
              payment.paymentPlan &&
              offerEligibility !== OfferStatusCode.PURCHASED
            ) {
              const analyticsDetails = {
                products: product,
                offerId: offerSetId,
                quoteNumber: offerCheckoutDetails.offer.details.quoteNumber,
                policyNumber: offerCheckoutDetails.offer.details.policyNumber,
                dalSessionId,
                inquiryId,
                choiceId: customerId,
                paymentMethod: payment.paymentType,
                paymentPlan: payment.paymentPlan,
                paymentAmount: payment.paymentPremium,
              };
              trackSapiAnalyticsEvent({
                element: 'choice.postBindPage.retryPage.purchaseMethodDetails',
                event: 'captured',
                eventDetail: JSON.stringify(analyticsDetails),
              });
              const paymentOptionResponse = await dispatch(
                pushPaymentOption({
                  dalSessionId,
                  product,
                  // TODO: huh?
                  paymentType:
                    payment.paymentType !== 'CostcoVisa' ? payment.paymentType : 'CreditCard',
                  paymentPlan: payment.paymentPlan,
                }),
              );
              if (paymentOptionResponse?.error) {
                datadogLog({
                  logType: 'error',
                  message: `Error posting payment options - ${paymentOptionResponse?.error?.message}`,
                  context: {
                    logOrigin:
                      'libs/features/sales/checkout/src/views/PostBindPage/PostBindPage.tsx',
                    contextType: 'Purchase Error',
                    functionOrigin: 'onResubmitPayment',
                    message: paymentOptionResponse?.error?.message,
                  },
                  error: paymentOptionResponse.error,
                });

                return;
              }
            }
          },
        );

        const checkoutResponse = await dispatch(
          postPurchase({
            dalSessionId,
            offerSetId,
            purchaseRequest,
            products: purchaseResults.retry,
            timeout: 29000,
            skipFetchPostBindQuestions: metadata.skipFetchPostBindQuestions,
          }),
        );

        if (!metadata.skipGetAcknowledgement) {
          await dispatch(
            fetchAcknowledgementResponse({
              dalSessionId,
              products: purchaseResults.retry,
              category: 'reminders',
            }),
          );
        }

        if (checkoutResponse?.response !== undefined) {
          cleanUpFields(productsSummary, fieldsByProduct, dispatch);
        }
        sessionStorage.setItem('PostBindRetryCount', newCount);
        setShowLoadingPage(false);
        setSubmittingPayment(false);
      }
    }
  }, [
    dalSessionId,
    offerSetId,
    purchaseRequest,
    validateForm,
    productsSummary,
    currentPostBindRetryCount,
    dispatch,
    purchaseResults.retry,
    inquiryId,
    customerId,
    fieldsByProduct,
    shouldGenerateTokenForEFTPay,
  ]);

  const displayPostBindPage = (): JSX.Element | null => {
    if (showLoadingPage) {
      return <PostBindLoadingPage />;
    }

    // Successful purchase scenario for bundle/monoline
    if (purchased.length === offerProductsSelected.length) {
      return (
        <PostBindForm
          marketingFields={marketingFields}
          onNext={handleNext}
          fieldsByProduct={fieldsByProduct}
          currentPostBindRetryCount={currentPostBindRetryCount}
          lineOfBusiness={lineOfBusiness}
          productsSummary={productsSummary}
          purchaseResults={purchaseResults}
          hidePageInfo={hidePageInfo}
          disclosureScripts={disclosureScripts}
          disclosureScriptsDialog={disclosureScriptsDialog}
          hasBankPayToken={hasBankPayToken}
          hasRecurringPayToken={hasRecurringPayToken}
        />
      );
    }

    // If purchasing bundle and either product fails to purchase we display post bind page still.
    if (purchased.length && failed.length) {
      const sapiAnalyticsEvent = createSapiAnalyticsEvent(
        offerProductsSelected,
        purchaseResults,
        false,
        'choice.postBindPage.retryPage.page',
        'render',
      );
      trackSapiAnalyticsEvent(sapiAnalyticsEvent);

      return (
        <PostBindForm
          marketingFields={marketingFields}
          fieldsByProduct={fieldsByProduct}
          onNext={handleNext}
          lineOfBusiness={lineOfBusiness}
          productsSummary={productsSummary}
          purchaseResults={purchaseResults}
          hidePageInfo={hidePageInfo}
          onResubmitPayment={onResubmitPayment}
          hasTokens={hasTokens}
          submittingPayment={submittingPayment}
          currentPostBindRetryCount={currentPostBindRetryCount}
          hasBankPayToken={hasBankPayToken}
          hasRecurringPayToken={hasRecurringPayToken}
        />
      );
    }

    return null;
  };

  return (
    <div className={classes.root}>
      <Page analyticsElement='choice.postBindPage.page'>{displayPostBindPage()}</Page>
    </div>
  );
};
