import { createSelector } from '@reduxjs/toolkit';

import type { RootStore } from '@ecp/features/sales/shared/store/types';

import type { NavState } from './types';

export const getNav = (state: RootStore): NavState => state.nav;

export const getIncidentRef = createSelector(getNav, (nav) => nav.incidentRef);
export const getMilitaryDeploymentsRef = createSelector(
  getNav,
  (nav) => nav.militaryDeploymentsRef,
);

export const getCurrentPage = createSelector(getNav, (nav) => nav.currentPage);

export const getPreviousPage = createSelector(getNav, (nav) => nav.previousPage);
export const getNavTracking = createSelector(getNav, (nav) => nav.navTracking ?? {});

export const getZipLookupBypassed = createSelector(getNav, (nav) => nav.zipLookupBypassed);

export const getAntiTheftDevicesDialogToggled = createSelector(
  getNav,
  (nav) => nav.antiTheftDevicesDialogToggled,
);
