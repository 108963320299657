import { createAction } from '@reduxjs/toolkit';

import type { Answers } from '@ecp/features/sales/shared/types';

import type { ErrorEntry, Errors, ValueEntry } from './types';

export const setFormErrorsChanged = createAction<Errors>('@form/errorsChanged');
export const setFormErrorsChangedByField = createAction<ErrorEntry>('@form/errorsChangedByField');
export const setFormErrorsReset = createAction('@form/errorsReset');
export const setFormErrorsResetByField = createAction<Pick<ErrorEntry, 'key'>>(
  '@form/errorsResetByField',
);
export const setFormFieldReset = createAction<string>('@form/fieldReset');
export const setFormInitialize = createAction<{ initValues: Answers }>('@form/init');
export const setInitValues = createAction<{ initValues: Answers }>('@form/initValues');
export const setFormReset = createAction('@form/reset');
export const setFormValueChanged = createAction<ValueEntry>('@form/valueChanged');

export type FormActions = ReturnType<
  | typeof setFormErrorsChanged
  | typeof setFormErrorsChangedByField
  | typeof setFormErrorsReset
  | typeof setFormErrorsResetByField
  | typeof setFormFieldReset
  | typeof setFormInitialize
  | typeof setInitValues
  | typeof setFormReset
  | typeof setFormValueChanged
>;
