import type { ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { Grid } from '@mui/material';
import dayjs from 'dayjs';

import { castToNumberOrNull, reduceSentenceList } from '@ecp/utils/common';
import { DateConstants } from '@ecp/utils/date';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { sessionStorage } from '@ecp/utils/storage';

import { GridItem } from '@ecp/components';
import { env } from '@ecp/env';
import { Button, Dialog, PhoneLink } from '@ecp/features/sales/shared/components';
import {
  DEFAULT_AUTO_POLICY_DURATION,
  DEFAULT_HOME_POLICY_DURATION,
} from '@ecp/features/sales/shared/constants';
import {
  getIsBundleForOfferProductsSelected,
  useField,
  usePostBindField,
} from '@ecp/features/sales/shared/store';
import { getDalSessionId, getPrimaryInsuredStateCode } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { RemindersMetadata } from '@ecp/features/sales/shared/types';
import type { Product } from '@ecp/features/shared/product';
import {
  getProductNameFromProduct,
  hasAutoProduct,
  hasHomeProduct,
  hasRentersProduct,
} from '@ecp/features/shared/product';
import { partner } from '@ecp/partners';
import { useIsDesktop } from '@ecp/themes/base';

import { PostBindReminders } from '../../../components';
import {
  ContactInformationFormQuestions,
  MortgageFormQuestions,
  PreviousPolicyInformationQuestions,
  usePreviousPremiumFields,
  VehicleLienHolderFormQuestions,
} from '../../../formBody/PostBindFormBody';
import { AutoPOIHelpText } from '../../../formBody/PostBindFormBody/AutoPOIHelpText';
import { postBindRemindersMetadata } from '../../../metadata';
import {
  getHasPostBindSubmitError,
  getHomeInspectionStatus,
  getPaymentOptionsForRetry,
  getPurchaseResponse,
} from '../../../state';
import { getAllProductReminderKeys } from '../../../state/acknowledgements';
import { cleanUpFields } from '../../../state/purchaseUtil/purchaseUtil';
import type {
  PostBindMessage,
  PostBindSummary,
  ProductPurchaseStatus,
  PurchaseResults,
} from '../../../types';
import type { PaymentFields } from '../../../util';
import { getPolicyNumber, getProductsToUpdate, useResetPaymentTypes } from '../../../util';
import { useAdditionalInterestFields } from '../../../util';
import { PostBindRetryPaymentForm } from '../../PostBindRetryForm';
import { AccessPoliciesOnline } from './AccessPoliciesOnline';
import { AdditionalInterest } from './AdditionalInterest';
import type { DocumentType } from './DownloadPostBindDocuments/types';
import metadata from './metadata';
import { useStyles } from './PostBindFormBody.styles';
import { PostBindPolicyCard } from './PostBindPolicyCard';

interface Props {
  currentPostBindRetryCount?: number;
  errorReason?: string;
  fieldsByProduct: Record<Product, PaymentFields>;
  formSubmitted: boolean;
  formSubmitting: boolean;
  hasTokens: boolean;
  onResubmitPayment: (() => Promise<void>) | undefined;
  productsSummary: PostBindSummary[];
  purchaseResults: PurchaseResults;
  submittingPayment: boolean;
  disclosureScripts?: ReactNode;
  firstName?: string;
  hasBankPayToken: boolean;
  hasRecurringPayToken: boolean;
}

export const PostBindFormBody: React.FC<Props> = (props) => {
  const { classes, cx } = useStyles();
  const {
    fieldsByProduct,
    formSubmitted = false,
    formSubmitting = false,
    productsSummary,
    onResubmitPayment,
    hasTokens,
    purchaseResults: { purchased, failed, retry, errorMessages },
    purchaseResults,
    submittingPayment,
    currentPostBindRetryCount,
    disclosureScripts,
    firstName,
    hasBankPayToken,
    hasRecurringPayToken,
  } = props;
  const dispatch = useDispatch();
  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const dalSessionId = useSelector(getDalSessionId);
  const [showRetryModal, setShowRetryModal] = useState(false);
  const [loadingPaymentOptions, setLoadingPaymentOptions] = useState(false);
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const purchaseResponse = useSelector(getPurchaseResponse);
  const homeInspectionStatus = useSelector(getHomeInspectionStatus);
  const handleCallUs = useCallback(() => setShowPhoneNumber(true), [setShowPhoneNumber]);
  const isAutoProductSwapStateRollout = flagValues[FeatureFlags.AUTO_PRODUCT_SWAP_STATE_ROLLOUT];
  const { resetPaymentTypes } = useResetPaymentTypes();
  const isDesktop = useIsDesktop();
  const vehicleLienHolderStatusField = usePostBindField(`vehicle.<id>.status`);
  const purchasePolicyStatus = sessionStorage.getItem('purchasePolicyStatus') as Record<
    string,
    ProductPurchaseStatus
  >;
  const additionalInterestFeatureFlag = flagValues[FeatureFlags.ADDITIONAL_INTEREST];
  const isAdditionalInterestSubmitting = additionalInterestFeatureFlag && formSubmitting;
  const showAgentPostBindEnhancements = env.static.isAgent;
  const hasPostBindSubmitError = useSelector(getHasPostBindSubmitError);
  // This is purely speculation
  const hasAdditionalInterestSubmitError = additionalInterestFeatureFlag && hasPostBindSubmitError;
  const isBundle = useSelector(getIsBundleForOfferProductsSelected);
  const { allFields } = useAdditionalInterestFields();
  const mailingAddressLine1 = usePostBindField('mailing.address.line1');
  const isPaymentThroughMortgage =
    useField('static.checkout.home.paymentMethod').value === 'Mortgage';

  const mortgageLoanCompanyNameOrigin = usePostBindField('mortgage.loanCompanyNameOrigin');
  const fields = usePreviousPremiumFields();
  const fieldsEntries = Object.entries(fields);
  const { exists } = allFields;

  const products: Product[] = useMemo(
    () =>
      productsSummary.reduce((acc, { offerCheckoutDetails }) => {
        if (offerCheckoutDetails?.selectedProduct) {
          acc.push(offerCheckoutDetails.selectedProduct);
        }

        return acc;
      }, [] as Product[]),
    [productsSummary],
  );

  const productReminderKeys = useSelector((state: RootStore) =>
    getAllProductReminderKeys(state, purchased, 'Important'),
  );
  const productOptionalReminderKeys = useSelector((state: RootStore) =>
    getAllProductReminderKeys(state, purchased, 'Optional'),
  );

  const getAdditionalReminders = useCallback(
    (reminderKeys: string[] | null, important?: boolean): RemindersMetadata[] => {
      const additionalRemindersMetadata: RemindersMetadata[] = [];

      products.forEach((product) => {
        if (reminderKeys) {
          reminderKeys.forEach((key) => {
            const productMetaData = postBindRemindersMetadata[product];
            let reminderMetaData = productMetaData[key];
            if (reminderMetaData) {
              if (reminderMetaData.stateOptions) {
                const { stateOptions } = reminderMetaData;
                reminderMetaData = { ...reminderMetaData, ...stateOptions[stateCode] };
                delete reminderMetaData.stateOptions;
              }
              additionalRemindersMetadata.push(reminderMetaData);
            }
          });
        }

        if (important) {
          const homeInspectionAction = homeInspectionStatus
            ? postBindRemindersMetadata[product][homeInspectionStatus]
            : undefined;

          if (homeInspectionAction) {
            additionalRemindersMetadata.push(homeInspectionAction);
          }

          const addressConfidentiality =
            postBindRemindersMetadata[product]['addressConfidentiality'];

          if (addressConfidentiality) {
            additionalRemindersMetadata.push(addressConfidentiality);
          }

          const occupancyChanges = postBindRemindersMetadata[product]['occupancyChanges'];

          if (occupancyChanges) {
            additionalRemindersMetadata.push(occupancyChanges);
          }
        }
      });

      return additionalRemindersMetadata.filter(
        (reminder, i, reminders) =>
          reminders.findIndex((checkReminder) => checkReminder.title === reminder.title) === i,
      );
    },
    [homeInspectionStatus, products, stateCode],
  );

  const additionalReminders = getAdditionalReminders(productReminderKeys, true);
  const optionalReminders = getAdditionalReminders(productOptionalReminderKeys);

  // additional reminders should render for Liftoff and Agent Mivale
  const showImportantReminders =
    (additionalReminders?.length > 0 && !showAgentPostBindEnhancements) ||
    (additionalReminders?.length > 0 &&
      showAgentPostBindEnhancements &&
      isAutoProductSwapStateRollout);

  const handleSaveAndClose = useCallback(() => {
    cleanUpFields(productsSummary, fieldsByProduct, dispatch);
    setShowRetryModal(false);
  }, [setShowRetryModal, dispatch, fieldsByProduct, productsSummary]);

  const handleClose = useCallback(() => {
    cleanUpFields(productsSummary, fieldsByProduct, dispatch);
    setShowRetryModal(false);
  }, [setShowRetryModal, dispatch, fieldsByProduct, productsSummary]);

  const handleResubmitPayment = useCallback(async () => {
    if (onResubmitPayment) {
      await onResubmitPayment();
    }
  }, [onResubmitPayment]);

  const openRetryModal = useCallback(async () => {
    const productsToUpdate = getProductsToUpdate(productsSummary, purchased, fieldsByProduct);

    setLoadingPaymentOptions(true);
    await dispatch(getPaymentOptionsForRetry({ dalSessionId, products: productsToUpdate }));
    setLoadingPaymentOptions(false);
    resetPaymentTypes(productsSummary, purchased);

    setShowRetryModal(true);
  }, [
    productsSummary,
    resetPaymentTypes,
    fieldsByProduct,
    purchased,
    setShowRetryModal,
    dispatch,
    dalSessionId,
  ]);

  const retryProducts = useMemo(
    () =>
      retry
        .map((product) => getProductNameFromProduct(product))
        .sort()
        .reduce(reduceSentenceList(retry.length), ''),
    [retry],
  );

  const attentionedNeededInformation = (): React.ReactElement | null => {
    if (productsSummary.length > 1 && failed.length) {
      const numberCurrentPostBindRetryCount = castToNumberOrNull(currentPostBindRetryCount);
      // We dont allow a user to retry purchsing if our metadata doesnt match the object returned

      if (
        (failed.length === 1 && !retry.length) ||
        (numberCurrentPostBindRetryCount && numberCurrentPostBindRetryCount >= 3)
      ) {
        const productName = getProductNameFromProduct(failed[0]);

        return (
          <div className={classes.attentionedNeededColumn}>
            <h2 className={cx(classes.columnHeader, classes.redColumnHeader)}>Attention needed</h2>
            <div className={cx(classes.informationCard, classes.errorInformationCard)}>
              <h3 className={classes.columnContentHeader}>
                Unable to complete {productName} purchase
              </h3>
              <p className={classes.attentionedNeededReason}>
                We were unable to process your {productName} policy. Please call us to complete the
                purchase.
              </p>
              <Grid container>
                <Grid item xs={4}>
                  <div data-testid='TopPhoneNumberLink' className={classes.callUsPhone}>
                    {!showPhoneNumber ? (
                      <PhoneLink
                        key='a'
                        withIcon
                        withLinkStyle
                        number='Call us'
                        onClick={handleCallUs}
                        trackingName='CallUsLink'
                        trackingLabel='PhoneNumber'
                      />
                    ) : (
                      <PhoneLink
                        key='b'
                        withIcon
                        withLinkStyle
                        number={partner.shared.servicePhoneNumber}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        );
      }

      return (
        <div className={classes.attentionedNeededColumn}>
          <h2 className={cx(classes.columnHeader, classes.redColumnHeader)}>Attention needed</h2>
          <div className={cx(classes.informationCard, classes.errorInformationCard)}>
            <h3 className={classes.columnContentHeader}>
              {Object.values(errorMessages).map(({ messageHeader }) => messageHeader)}
            </h3>
            {Object.entries(errorMessages).map((entry, idx) => {
              // Object.entries doesn't honor object key types, have to coerce it here
              const [product, { subtext, subtextShort }] = entry as [Product, PostBindMessage];

              return (
                <p
                  key={`postbindform-attn-error-${product}-${idx}`}
                  className={classes.attentionedNeededReason}
                >
                  We were unable to process your {getProductNameFromProduct(product)} policy due to{' '}
                  {subtextShort || `- ${subtext}`}
                </p>
              );
            })}
            <Grid container>
              <span className={classes.tryAgainButtonContainer}>
                <Button
                  variant='primary'
                  onClick={openRetryModal}
                  isProcessing={loadingPaymentOptions}
                  data-testid='postBindTryAgainButton'
                >
                  Try again
                </Button>
              </span>
              <span>
                <div data-testid='TopPhoneNumberLink' className={classes.callUsPhone}>
                  {!showPhoneNumber ? (
                    <PhoneLink
                      key='a'
                      withIcon
                      withLinkStyle
                      number='Call us'
                      onClick={handleCallUs}
                      trackingName='CallUsLink'
                      trackingLabel='PhoneNumber'
                    />
                  ) : (
                    <PhoneLink
                      key='b'
                      withIcon
                      withLinkStyle
                      number={partner.shared.servicePhoneNumber}
                    />
                  )}
                </div>
              </span>
            </Grid>
          </div>
        </div>
      );
    }

    return null;
  };

  const agentScripts = showAgentPostBindEnhancements && disclosureScripts && (
    <Grid container className={classes.additionalInformation}>
      <GridItem>{disclosureScripts}</GridItem>
    </Grid>
  );

  const nothingFurtherNeeded = (
    <div className={cx(classes.informationCard, classes.columnContentHeader)} id='allSet'>
      <p className={classes.columnContentHeader}>You are all set. Nothing further is needed!</p>
    </div>
  );

  const additionalInformation = (
    <>
      <Grid container className={classes.additionalInformation}>
        <GridItem>
          <h2 className={classes.columnHeader}>Additional information</h2>
          {showImportantReminders && (
            <div className={classes.informationCard} id='reminders'>
              <h3 className={classes.columnContentHeader}>Important reminders</h3>
              <PostBindReminders reminders={additionalReminders} />
            </div>
          )}
        </GridItem>
      </Grid>
      {optionalReminders?.length > 0 && (
        <Grid container className={cx(classes.additionalInformation, classes.columnContentHeader)}>
          <GridItem className={classes.informationCard} id='optionalReminders'>
            <h3 className={classes.columnContentHeader}>Optional next steps</h3>
            <br />
            <strong>If any of the following statements apply to you, please call us.</strong>
            <PostBindReminders reminders={optionalReminders} />
          </GridItem>
        </Grid>
      )}
    </>
  );

  const policyLabel = isBundle ? 'policies' : 'policy';
  const yourPolicies = (
    <>
      {!isDesktop && agentScripts}
      {metadata.hidePolicyHeader ? (
        <h2 className={classes.policyInformationHeader}>
          {showAgentPostBindEnhancements ? `${firstName}'s ${policyLabel}` : `Your ${policyLabel}`}
        </h2>
      ) : (
        <h2 className={classes.policyInformationHeader}>
          {showAgentPostBindEnhancements ? `Customer ${policyLabel}` : `Your ${policyLabel}`}
        </h2>
      )}

      <div className={cx(classes.columnContentHeader, classes.policiesInformation)}>
        {productsSummary.map(
          ({ product, productName, offerCheckoutDetails, getProductIsPurchased }, idx) => {
            const purchaseStatus = purchasePolicyStatus[product];
            const policyNumber = offerCheckoutDetails
              ? getPolicyNumber(purchaseResponse, offerCheckoutDetails, product)
              : '';
            const purchased = getProductIsPurchased(purchaseStatus);
            // TODO: SAPI is sending policyDuration as a string for advance and expected type is number for dayjs
            const policyDuration =
              !metadata.translatePolicyDuration && offerCheckoutDetails
                ? offerCheckoutDetails?.offer.policyDuration
                : offerCheckoutDetails?.offer.policyDuration === 'Annual'
                ? DEFAULT_HOME_POLICY_DURATION
                : offerCheckoutDetails?.offer.policyDuration === 'HalfYear'
                ? DEFAULT_AUTO_POLICY_DURATION
                : '';

            return (
              offerCheckoutDetails && (
                <div
                  key={`postbindform-policies-${product}-${idx}`}
                  className={cx(classes.policyCardsContainer)}
                >
                  <PostBindPolicyCard
                    purchased={purchased}
                    policyType={productName}
                    policyNumber={policyNumber}
                    poiDocumentFormName={purchaseStatus.poiDocumentFormName as DocumentType}
                    selectedProduct={product}
                    effectiveDate={
                      offerCheckoutDetails.policyStartDate
                        ? dayjs(offerCheckoutDetails.policyStartDate).format(
                            DateConstants.DISPLAY_FORMAT,
                          )
                        : ''
                    }
                    policyEndDate={
                      offerCheckoutDetails.policyStartDate
                        ? dayjs(offerCheckoutDetails.policyStartDate)
                            .add(+policyDuration, 'M')
                            .format(DateConstants.DISPLAY_FORMAT)
                        : ''
                    }
                  />
                  {purchased ? <AutoPOIHelpText /> : null}
                </div>
              )
            );
          },
        )}
      </div>
    </>
  );

  // this condition will get triggered when user submit postbind forms, for ex. lienholder, it will display nothingFurtherNeeded message.
  const formSubmittedNothingFurtherNeeded = useMemo(() => {
    return formSubmitted && !isAdditionalInterestSubmitting && !hasAdditionalInterestSubmitError;
  }, [formSubmitted, isAdditionalInterestSubmitting, hasAdditionalInterestSubmitError]);

  // this condition will get triggered when there are no forms to submit, it will display nothingFurtherNeeded message.
  const noFormSubmittedNothingFurtherNeeded = useMemo(() => {
    return (
      showAgentPostBindEnhancements &&
      !formSubmitted &&
      !hasAdditionalInterestSubmitError &&
      !vehicleLienHolderStatusField.exists &&
      !fieldsEntries.length &&
      !mailingAddressLine1.exists &&
      !isAdditionalInterestSubmitting &&
      !exists.exists &&
      (!isPaymentThroughMortgage || !mortgageLoanCompanyNameOrigin.exists)
    );
  }, [
    showAgentPostBindEnhancements,
    formSubmitted,
    hasAdditionalInterestSubmitError,
    vehicleLienHolderStatusField.exists,
    fieldsEntries.length,
    mailingAddressLine1.exists,
    isAdditionalInterestSubmitting,
    exists.exists,
    isPaymentThroughMortgage,
    mortgageLoanCompanyNameOrigin.exists,
  ]);

  return (
    <>
      {/* Order of cards need to be reversed when displaying on smaller screens */}
      {!metadata.hideAdditionalDetails ? (
        <Grid item container>
          <Grid order={{ lg: 1, xs: 2 }} item xs={12} sm={12} md={12} lg={7}>
            {attentionedNeededInformation()}
            {isDesktop && agentScripts}
            {additionalInformation}
            {!showAgentPostBindEnhancements && <AccessPoliciesOnline />}

            {(formSubmittedNothingFurtherNeeded || noFormSubmittedNothingFurtherNeeded) && (
              <>{nothingFurtherNeeded}</>
            )}

            {(!formSubmitted ||
              isAdditionalInterestSubmitting ||
              hasAdditionalInterestSubmitError) && (
              <>
                {hasRentersProduct(purchased) && additionalInterestFeatureFlag && (
                  <AdditionalInterest />
                )}
                {hasAutoProduct(purchased) && <VehicleLienHolderFormQuestions />}
                {hasHomeProduct(purchased) && <MortgageFormQuestions />}
                <ContactInformationFormQuestions products={products} />
                <PreviousPolicyInformationQuestions />
              </>
            )}
          </Grid>
          <Grid order={{ lg: 2, xs: 1 }} item xs={12} sm={12} md={12} lg={5}>
            {yourPolicies}
          </Grid>
        </Grid>
      ) : (
        <Grid item container className={classes.allSetContainer}>
          {!metadata.hideAdditionalDetails && (
            <Grid order={{ lg: 1, xs: 2 }} item xs={12} sm={12} md={12} lg={7}>
              {attentionedNeededInformation()}
              <>{nothingFurtherNeeded}</>
            </Grid>
          )}
          <Grid order={{ lg: 2, xs: 1 }} item xs={12} sm={12} md={12} lg={5}>
            {yourPolicies}
          </Grid>
        </Grid>
      )}
      <Dialog
        fullScreen
        open={showRetryModal}
        titleText={`Re-submit your ${retryProducts} payment information`}
        actionButtonOnClick={handleSaveAndClose}
        onClose={handleClose}
        className={classes.dialogRoot}
        trackingNameButton='CustomizeQuotesCloseButton'
        trackingLabelButton='Close'
        trackingNameCloseIcon='CustomizeQuotes_X'
        trackingLabelCloseIcon='X'
      >
        <PostBindRetryPaymentForm
          productsSummary={productsSummary}
          purchaseResults={purchaseResults}
          onResubmitPayment={handleResubmitPayment}
          hasTokens={hasTokens}
          submittingPayment={submittingPayment}
          hasBankPayToken={hasBankPayToken}
          hasRecurringPayToken={hasRecurringPayToken}
        />
      </Dialog>
    </>
  );
};
