import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PercentageGroupConnector' })((theme) => ({
  title: {
    ...theme.typography.body4,
    display: 'inline-block',
  },
  subtitle: {
    color: theme.palette.text.tertiary,
    fontSize: 12,
    lineHeight: '17px',
    fontStyle: 'italic',
    marginBottom: 15,
  },
}));
