export const partner = {
  shared: {
    partnerDisplayName: 'Progressive Home, by Homesite',
    salesEmailQuotesAddress: 'servicingrevolution@homesite.com',
    salesPhoneHours: 'Mon - Fri: 8am - 11pm ET, Sat - Sun: 9am - 8pm ET',
    servicePhoneHours: 'Mon - Fri: 8am - 9pm ET, Sat: 9am - 6pm ET',
    qnbPhoneNumber: '1-855-428-1856',
    dnqPhoneNumber: '1-855-428-1856',
    salesPhoneNumber: '1-844-208-0666',
    servicePhoneNumber: '1-866-960-8609',
    agentFromEmailAddress: 'donotreply@email.homesite.com',
    agentFromEmailName: 'Progressive Home, by Homesite',
  },
  lobDetail: { AUTO: { dnqNumbers: {}, discount: {} } },
} as const;
