import { useCallback, useState } from 'react';

import { Grid } from '@mui/material';

import { GridItem, HelpBox } from '@ecp/components';
import { env } from '@ecp/env';
import { CarrierMessagingDialog, Link } from '@ecp/features/sales/shared/components';
import {
  getCarrierMessagingMetadata,
  getPrimaryInsuredStateCode,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { ProductName } from '@ecp/features/shared/product';
import { GraphicUIAgentAvatarImageUrl } from '@ecp/themes/base';

import metadata from './metadata';

interface Props {
  coverageType: ProductName;
}

export const CarrierMessaging: React.FC<Props> = (props) => {
  const { coverageType } = props;
  const stateCode = useSelector(getPrimaryInsuredStateCode);

  const carrierMessages = useSelector((state: RootStore) =>
    getCarrierMessagingMetadata(state, stateCode, metadata),
  );

  const [isCarrierMessagingOpen, setIsCarrierMessagingOpen] = useState(false);
  const openCarrierMessaging = useCallback(
    () => setIsCarrierMessagingOpen(true),
    [setIsCarrierMessagingOpen],
  );
  const handleCarrierMessagingClose = useCallback((): void => {
    setIsCarrierMessagingOpen(false);
  }, [setIsCarrierMessagingOpen]);

  const messageNames = carrierMessages[coverageType]?.map((element) => element.name || '');

  const showCarrierMessage = messageNames?.length > 0 && !env.static.isAgent;

  return (
    <>
      {showCarrierMessage && (
        <GridItem xs={12} topSpacing='sm'>
          <HelpBox
            image={GraphicUIAgentAvatarImageUrl}
            data-testid='helpBoxIcon'
            content={
              <Grid>
                <p>
                  <strong>Important Information</strong>
                </p>
                <p>
                  Please review the following important information for {stateCode}.&nbsp;
                  <Link component='button' onClick={openCarrierMessaging} display='inline'>
                    {messageNames.join(', ')}
                  </Link>
                </p>
              </Grid>
            }
          />
        </GridItem>
      )}
      {isCarrierMessagingOpen && (
        <CarrierMessagingDialog
          open
          carrierMessages={carrierMessages}
          onClose={handleCarrierMessagingClose}
        />
      )}
    </>
  );
};
