import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PostBindReminders' })((theme) => ({
  root: {},
  listItems: {
    color: theme.palette.text.primary,
    padding: 0,
    // This is to account for dots. Set to same padding as informationCard in PostBindFormBody.styles.ts
    paddingLeft: 20,
    margin: 0,
  },
  dividerListItem: {
    listStyle: 'none',
  },
}));
