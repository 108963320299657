import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PostBindForm' })((theme) => ({
  root: {
    color: theme.palette.text.primary,
  },
  title: {
    marginTop: 40,
    color: theme.palette.success.main,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      margin: '20px 0px 0px 0px',
    },
  },
  titleAgent: {
    marginTop: 40,
    color: theme.palette.success.main,
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'left',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      margin: '20px 0px 0px 0px',
    },
  },
  titleHeader: {
    color: theme.palette.success.main,
    textAlign: 'center',
    alignSelf: 'center',
  },
  titleHeaderAgent: {
    color: theme.palette.text.primary,
    textAlign: 'left',
  },
  pageGreetingHeader: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  successIcon: {
    ...theme.mixins.setColorSvg(theme.palette.success.main),
    width: 30,
    height: 30,
  },
  successIconContainer: {
    width: 42,
    height: 42,
    borderRadius: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: `${theme.palette.success.main}40`, // Add opacity
    marginRight: 5,
    [theme.breakpoints.down('md')]: {
      marginTop: 1,
      width: 22,
      height: 22,
      marginRight: 4,
    },
  },
  errorText: {
    color: theme.palette.error.main,
  },
  titleText: {
    alignSelf: 'center',
  },
  subtitleText: {
    ...theme.typography.subtitle,
    marginTop: 40,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: theme.typography.body4,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
  },
  policyRow: {
    display: 'flex',
    width: '100%',
  },
  policyRowGutter: {
    width: 30,
  },
  submitButton: {
    marginTop: 40,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  crossSellContainer: {
    width: '100%',
    marginTop: 30,
    borderTop: `1px solid ${theme.palette.other.divider}`,
    backgroundColor: theme.palette.grey[100],
    [theme.breakpoints.up('md')]: {
      padding: '50px 10px 0px 10px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '50px 50px 0px 50px',
    },
  },
  helpImageMobile: {
    width: '100%',
    margin: '20px 0px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  helpImageDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      maxWidth: '300px',
      marginRight: 25,
      display: 'block',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 'none',
      height: 282,
      marginRight: 50,
    },
    [theme.breakpoints.up('xl')]: {
      height: 'auto',
      marginRight: 60,
    },
  },
  TextContainer: {
    padding: '35px 0px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: '0px 0px 55px 0px',
      maxWidth: '360px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '430px',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '520px',
      padding: '0px 0px 75px 0px',
    },
  },
  headerText: {
    maxWidth: 450,
    [theme.breakpoints.up('md')]: {
      paddingBottom: 20,
    },
  },
  contentText: {
    maxWidth: 500,
    paddingBottom: 22,
    ...theme.typography.body1,
  },
  crossSellButton: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  disclaimerText: {
    ...theme.typography.body1Italics,
    padding: '26px 0px',
    borderTop: `1px solid ${theme.palette.other.divider}`,
    [theme.breakpoints.up('md')]: {
      padding: '26px 0px',
    },
  },
}));
