import { useEffect, useMemo } from 'react';

import { Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import {
  MobileMonthlyPaymentsCheckoutCard,
  MonthlyPaymentsCheckoutCard,
} from '@ecp/features/sales/checkout';
import { HelpCardConditional } from '@ecp/features/sales/checkout';
import { Tabs } from '@ecp/features/sales/shared/components';
import { fetchRatingCriteria, getOfferProductsSelected } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { TabOptions } from '@ecp/features/sales/shared/types';
import { getProductDisplayNameFromProduct, isProductRenters } from '@ecp/features/shared/product';
import { useIsDesktop, useIsTablet } from '@ecp/themes/base';

import { useCheckoutCardProps } from '../../../util/useCheckoutCardProps';
import { AutoHomeForm } from '../AutoHomeForm';
import { useStyles } from './RatingCriteriaForm.styles';

type RatingCriteriaFormProps = Parameters<typeof useCheckoutCardProps>[0];

export const RatingCriteriaForm: React.FC<RatingCriteriaFormProps> = (props) => {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();

  const checkoutCardProps = useCheckoutCardProps(props);

  const isDesktop = useIsDesktop();
  const isTablet = useIsTablet();
  const offerProductsSelected = useSelector(getOfferProductsSelected);

  // Run each time offerProductsSelected array updates
  useEffect(() => {
    offerProductsSelected.forEach((product) => dispatch(fetchRatingCriteria({ product })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerProductsSelected]);

  const content = useMemo(() => {
    if (!offerProductsSelected.length) return null;
    if (offerProductsSelected.length === 1 && isProductRenters(offerProductsSelected[0]))
      return null;

    if (offerProductsSelected.length === 1)
      return (
        <div className={classes.singleProductContainer}>
          <AutoHomeForm product={offerProductsSelected[0]} />
        </div>
      );

    const tabOptions: TabOptions[] = offerProductsSelected
      .filter((product) => (isProductRenters(product) ? false : product))
      .map((product) => {
        const productDisplayName = getProductDisplayNameFromProduct(product);
        const label = <div className={classes.tabLabel}> {productDisplayName}</div>;
        const body = <AutoHomeForm product={product} />;

        return { label, body };
      });

    return <Tabs className={classes.tabs} tabOptions={tabOptions} currentIndex={0} />;
  }, [offerProductsSelected, classes.singleProductContainer, classes.tabs, classes.tabLabel]);

  return (
    <div className={cx(classes.root, classes.contentContainer)}>
      <Grid container className={cx(classes.container, classes.formContainer)}>
        <Grid item xs={12}>
          {content}
        </Grid>
      </Grid>
      {isTablet ? null : (
        <Grid container className={classes.sidebarContainer} spacing={2} direction='column'>
          <Grid item xs={12}>
            <MonthlyPaymentsCheckoutCard {...checkoutCardProps} showCTAButton />
            <GridItem topSpacing='sm'>
              <HelpCardConditional />
            </GridItem>
          </Grid>
        </Grid>
      )}
      {isDesktop ? null : <MobileMonthlyPaymentsCheckoutCard {...checkoutCardProps} />}
    </div>
  );
};
