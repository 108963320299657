import { makeStyles } from '@ecp/themes/base';

import metadata from './metadata';

const { mainDuration, mainPanelDelay, secondaryDuration, fadeDuration, showDelay } = metadata;

// this is how long to wait before enabling visibility hidden,
// this is important so that the form controls become disabled
export const visibilityDelay = Math.max(showDelay + fadeDuration, mainPanelDelay + mainDuration);

export const useStyles = makeStyles<void, 'positionA' | 'positionB' | 'cardContent'>({
  name: 'RetrieveSlider',
})((...[theme, , classes]) => ({
  root: {
    width: '100%',
    minHeight: 430,
    position: 'relative',
    flex: '1 0 auto',
    [theme.breakpoints.down('xl')]: {
      marginBottom: 0,
    },
  },

  // these are applied to $root, and trigger the animation to the child elements
  positionA: {},
  positionB: {},
  cardPositionA: {},
  cardPositionB: {},
  mainContent: {
    outline: 'none',
    [theme.breakpoints.up('lg')]: {
      marginRight: 'auto',
      transition: `margin-left ${mainDuration}ms ${mainPanelDelay}ms`,
      zIndex: 10,
      overflow: 'hidden',
      [`.${classes.positionB} &`]: {
        marginLeft: 0,
      },
    },
    [theme.breakpoints.down('lg')]: {
      marginBottom: 55,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 20,
    },
  },
  mainContentSlider: {
    [theme.breakpoints.up('lg')]: {
      minHeight: 836,
      width: '200%',
      display: 'flex',
      transition: `margin-left ${secondaryDuration}ms ease-in`,
      marginLeft: '-100%',

      [`.${classes.positionB} &`]: {
        marginLeft: 0,
      },
    },
  },
  mainContentSection: {
    width: '100%',
  },
  mainContentSectionA: {
    [theme.breakpoints.up('lg')]: {
      transition: `opacity ${fadeDuration}ms ease-in ${showDelay}ms, visibility 0ms 0ms`,
      opacity: 1,
      zIndex: 40,
      [`.${classes.positionB} &`]: {
        transition: `opacity ${fadeDuration}ms ease-out 0ms, visibility 0ms ${visibilityDelay}ms`,
        opacity: 0,
        visibility: 'hidden',
      },
    },
    [theme.breakpoints.down('lg')]: {
      [`.${classes.positionB} &`]: {
        display: 'none',
      },
    },
  },
  mainContentSectionB: {
    [theme.breakpoints.up('lg')]: {
      transition: `opacity ${fadeDuration}ms ease-in ${showDelay}ms, visibility 0ms 0ms`,
      opacity: 1,
      [`.${classes.positionA} &`]: {
        transition: `opacity ${fadeDuration}ms ease-out 0ms, visibility 0ms ${visibilityDelay}ms`,
        opacity: 0,
        visibility: 'hidden',
        maxHeight: 520,
      },
    },
    [theme.breakpoints.down('lg')]: {
      [`.${classes.positionA} &`]: {
        display: 'none',
      },
    },
  },
  backQuotesButton: {
    marginTop: 20,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      padding: '0 15px',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
    },
  },
  retrieveButton: {
    marginTop: 20,
    paddingTop: 0,
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 30,
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 60,
    },
  },
  hasSubHeader: {
    [theme.breakpoints.down('xl')]: {
      marginBottom: 0,
    },
  },
  mainCard: {
    height: 'auto',
    [theme.breakpoints.up('lg')]: {
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 20,
      paddingBottom: 45,
      [`& .${classes.cardContent} &&`]: {
        padding: 0,
      },
    },
  },
  cardContent: {
    padding: '0 0 20px 0',
  },
  protectionIcon: {
    width: 60,
    height: 60,
    backgroundColor: theme.palette.grey[50],
    borderRadius: '50%',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: -30,
    left: 15,
    zIndex: 50,
    ...theme.mixins.setColorSvg(theme.palette.success.main),
    [theme.breakpoints.up('md')]: {
      left: 30,
    },
    [theme.breakpoints.up('lg')]: {
      left: 60,
      top: -40,
      width: 80,
      height: 80,
    },
  },
}));
