import { emptyArray } from '@ecp/utils/common';

import { isObject, isString } from '../predicates';

export function flatten<T extends Record<string, unknown> = Record<string, unknown>>(
  input: Record<string, unknown>,
  assertQuestions = false,
): T {
  if (!isObject(input)) return input;

  const output = {} as Record<string, unknown>;

  function traverseKeys(obj: Record<string, unknown>, parentPath: string[]): void {
    Object.entries(obj).forEach(([key, value]) => {
      const path = [...parentPath, key];
      if (isObject(value)) {
        if (assertQuestions && isString(value.type)) {
          output[path.join('.')] = value;

          return;
        }

        traverseKeys(value, path);

        return;
      }
      output[path.join('.')] = value;
    });
  }

  traverseKeys(input, emptyArray as unknown as string[]);

  return output as T;
}
