import { createReducer } from '@reduxjs/toolkit';

import { merge } from '@ecp/utils/common';

import {
  setAnswersApiGetSuccess,
  setAnswersApiPatchSuccess,
  setDalSessionId,
  setInquiryApiGetSuccess,
  setInquiryApiPostSuccess,
  setInquiryCreationFlag,
  setInquiryId,
  setInquiryOnOffersApiSuccess,
} from './actions';
import * as structure from './structure';
import type { InquiryState } from './types';
import { getUpdatedQuestionsFromAnswers } from './util/getUpdatedQuestionsFromAnswers';
import { makeAnswersRich } from './util/makeAnswersRich';
import { mergeWithOverrides } from './util/mergeWithOverrides';

const inquiryInitial: InquiryState = {
  // initial fields to collect before we get questions
  answers: {},
  questions: {
    static: structure.STATIC_QUESTIONS,
  },
  creatingInquiry: false,
};

// ! TODO If errors array which is included in response body of all SAPI inquiry endpoints
// ! represents errors per each inquiry field, we must add to every API reducer here:
// ! `if ('errors' in payload) state.errors = payload.errors;`
// ! or better add it in prepareAction function (see actions module)
export const inquiryReducer = createReducer(inquiryInitial, (builder) => {
  builder
    .addCase(setAnswersApiGetSuccess, (state, { payload }) => {
      state.answers = makeAnswersRich(payload.data.answers, state.questions);
      state.questions.sapi = mergeWithOverrides(
        state.questions.sapi,
        payload.overrides,
        payload.uiMetaData,
      );
      state.questions.sapi = getUpdatedQuestionsFromAnswers(
        payload.data.answers,
        state.questions.sapi,
      );
      // This must be done everytime we assign to state.questions.sapi - ECP-806
      state.questions.static = payload.staticQuestions;
    })
    .addCase(setAnswersApiPatchSuccess, (state, { payload }) => {
      state.answers = makeAnswersRich(payload.answers, state.questions);
      state.questions.sapi = mergeWithOverrides(
        state.questions.sapi,
        payload.overrides,
        payload.uiMetaData,
      );
      state.questions.sapi = getUpdatedQuestionsFromAnswers(payload.answers, state.questions.sapi);
      // This must be done everytime we assign to state.questions.sapi - ECP-806
      state.questions.static = payload.staticQuestions;
      if ('errors' in payload) state.errors = payload.errors;
    })
    .addCase(setInquiryApiGetSuccess, (state, { payload }) => {
      state.dalSessionId = payload.dalSessionId;
      state.inquiryId = payload.data.id;
      state.questions.sapi = mergeWithOverrides(
        payload.data.questions,
        payload.overrides,
        payload.uiMetaData,
      );
      // This must be done everytime we assign to state.questions.sapi - ECP-806
      state.questions.static = payload.staticQuestions;
      state.answers = merge(
        {},
        state.answers,
        makeAnswersRich(payload.data.answers, state.questions),
      );
    })
    .addCase(setInquiryCreationFlag, (state, { payload }) => {
      state.creatingInquiry = payload;
    })
    .addCase(setInquiryApiPostSuccess, (state, { payload }) => {
      state.dalSessionId = payload.dalSessionId;
      state.inquiryId = payload.data.id;
      // flowStep - EDSP-11130 - Resume flow for experiences coming with url and quoteid
      state.flowStep = payload.data.flowStep;
      state.questions.sapi = mergeWithOverrides(
        payload.data.questions,
        payload.overrides,
        payload.uiMetaData,
      );
      // This must be done everytime we assign to state.questions.sapi - ECP-806
      state.questions.static = payload.staticQuestions;
      state.answers = makeAnswersRich(payload.data.answers, state.questions);
      state.creatingInquiry = false;
    })
    .addCase(setDalSessionId, (state, { payload }) => {
      state.dalSessionId = payload;
    })
    .addCase(setInquiryId, (state, { payload }) => {
      state.inquiryId = payload;
    })
    .addCase(setInquiryOnOffersApiSuccess, (state, { payload }) => {
      state.dalSessionId = payload.dalSessionId;
      state.inquiryId = payload.inquiryId;
      state.questions.sapi = mergeWithOverrides(
        payload.questions,
        payload.overrides,
        payload.uiMetaData,
      );
      // This must be done everytime we assign to state.questions.sapi - ECP-806
      state.questions.static = payload.staticQuestions;
      state.answers = makeAnswersRich(payload.answers, state.questions);
    })
    .addMatcher(
      (action): action is { type: '@global/teardownStore' } =>
        action.type === '@global/teardownStore',
      () => inquiryInitial,
    );
});
