import { useMemo } from 'react';

import { Divider, Step, StepConnector, StepContent, StepLabel, Stepper } from '@mui/material';

import { emptyArray } from '@ecp/utils/common';

import { CompleteIcon } from '@ecp/components';
import {
  getAggregatePathTrackingStatus,
  getDriverInfo,
  getLastConfigVisitedPage,
  getNavTracking,
  getVehicleInfo,
} from '@ecp/features/sales/shared/store';
import type { PageFlow } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { DriverBasicInfo } from '@ecp/features/sales/shared/types';

import { getNavbarStatus } from '../navigationUtil';
import { VehiclesAndDriversInfo } from '../VehiclesAndDriversInfo/VehiclesAndDriversInfo';
import { useStyles } from './ProductStepper.styles';

interface Props {
  productSteps: ProductSteps[];
  pagePath?: string;
  jumpInNavbar: (pagePth: string) => void;
  productPageFlow: PageFlow;
}

interface ProductSteps {
  text: string;
  pageId: string;
  path: string;
}

export const ProductStepper: React.FC<Props> = (props) => {
  const { productSteps = emptyArray, jumpInNavbar, productPageFlow } = props;
  const { classes, cx } = useStyles();

  const lastConfigVisitedPage = useSelector(getLastConfigVisitedPage);
  const driverInfo: DriverBasicInfo[] = useSelector(getDriverInfo);
  const vehicleInfo = useSelector(getVehicleInfo);
  const navTracking = useSelector(getNavTracking);

  const stepIndex = useMemo(() => {
    let stepIndex = -1;

    const foundKey = Object.keys(productPageFlow.value.byPageId).find(
      (key) => productPageFlow.value.byPageId[key].path === lastConfigVisitedPage,
    );
    if (foundKey) {
      stepIndex = productPageFlow.value.navigationOrder.indexOf(foundKey);
    }

    return stepIndex;
  }, [productPageFlow, lastConfigVisitedPage]);

  const getStepContent = (pageId: string): React.ReactElement | null => {
    switch (pageId) {
      case 'Vehicles & Drivers':
        return <VehiclesAndDriversInfo vehicleItems={vehicleInfo} drivers={driverInfo} />;
      case 'Vehicles':
        return <VehiclesAndDriversInfo vehicleItems={vehicleInfo} disableHeading />;
      case 'Drivers':
        return <VehiclesAndDriversInfo drivers={driverInfo} disableHeading />;
      default:
        return null;
    }
  };

  const getIcon = (completed: boolean, active: boolean): React.ReactElement => {
    if (completed) return <CompleteIcon className={classes.circle} />;

    return (
      <div
        className={cx(classes.circle, active ? classes.incompleteCircle : classes.notVisitedCircle)}
      />
    );
  };

  const navTrackingStatuses = useMemo(
    () =>
      productSteps.map(({ path }) => {
        const referencePartialPaths =
          productPageFlow.value.pageFlows.find((pageFlow) => pageFlow.path === path)
            ?.referencePartialPaths ?? [];

        return getAggregatePathTrackingStatus(navTracking, [path], referencePartialPaths);
      }),
    [navTracking, productPageFlow.value.pageFlows, productSteps],
  );

  return (
    <Stepper
      activeStep={stepIndex}
      orientation='vertical'
      connector={<StepConnector classes={{ vertical: classes.noConnector }} />}
    >
      {productSteps.map(({ text: label, path }, index) => {
        const { active, completed } = getNavbarStatus(navTrackingStatuses[index]);

        const icon = getIcon(completed, active);

        const isLink =
          index === 0 ||
          completed ||
          (index > 0 && getNavbarStatus(navTrackingStatuses[index - 1]).completed);

        const step = (
          <Step key={label} active={active} completed={completed} disabled expanded>
            <StepLabel
              StepIconProps={{ icon }}
              aria-disabled={!completed}
              classes={{
                label: cx(
                  active ? classes.active : classes.inActive,
                  isLink && classes.subMenuLink,
                ),
                iconContainer: classes.iconContainer,
                completed: classes.completed,
              }}
              // eslint-disable-next-line react/jsx-no-bind
              onClick={isLink ? () => jumpInNavbar(path) : undefined}
            >
              {label}
            </StepLabel>
            <StepContent
              className={cx(
                classes.content,
                classes.stepContent,
                !(active || completed) && classes.inactiveStepContent,
              )}
            >
              {getStepContent(label)}
              {index !== productSteps.length - 1 && (
                <Divider aria-hidden='true' className={classes.divider} />
              )}
            </StepContent>
          </Step>
        );

        return step;
      })}
    </Stepper>
  );
};
