import { Container } from '@mui/material';

import { Page, PhoneLink, TextItem } from '@ecp/features/sales/shared/components';
import { partner } from '@ecp/partners';

import { useStyles } from './ExternalInspection.styles';

export const ExternalInspection: React.FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Page>
        <Container classes={{ root: classes.pageContainer }}>
          <TextItem
            variant='h1'
            title='Your home inspection'
            paddingBetween
            description='We may conduct a home inspection of the outside of your home in order to verify
                certain&nbsp;information.'
          />
          <TextItem
            variant='h4'
            title='Why is this necessary?'
            description='Inspections are a routine part of our process when issuing new policies and a
                standard practice for many home insurance companies. We look for any potential risk
                hazards or unrepaired damage, which could affect your personal safety or the
                condition of the home you’re insuring with&nbsp;us.'
          />
          <TextItem
            variant='h4'
            title='When will my property be inspected?'
            description='Typically, we’ll inspect your property within 14 days of your policy’s
                effective&nbsp;date.'
          />
          <TextItem
            variant='h4'
            title='Do I need to be present for the inspection?'
            description='No, you don’t need to be present for the inspection. If you’re home, the
                inspector will let you know who they are and why they’re&nbsp;there.'
          />
          <TextItem
            variant='h4'
            title='What will be inspected?'
            description='The inspector will take photos of the outside of your property (front, rear, sides,
                roof and any hazards) and may have to access the property (i.e. your back yard) in
                order to do&nbsp;so.'
          />
          <TextItem
            variant='h4'
            title='Will I be notified after the inspection?'
            description='If we find any significant risks that affect the safety or condition of your home,
                we’ll send you a letter with specific details and any impact this may have on
                your&nbsp;policy.'
          />
          <TextItem
            variant='h4'
            title='Can my policy be cancelled?'
            description={
              <>
                Yes, your policy could be cancelled if:
                <ul>
                  <li>You refuse the inspection</li>
                  <li>Your property does not meet our underwriting criteria</li>
                  <li>Our recommended corrective actions are not&nbsp;addressed</li>
                </ul>
              </>
            }
          />
          <TextItem
            variant='h4'
            title='What if I have questions?'
            description={
              <>
                Please contact us at{' '}
                <PhoneLink withUnderlinedLinkStyle number={partner.shared.servicePhoneNumber} /> if
                you have any questions about this process.
              </>
            }
          />
        </Container>
      </Page>
    </div>
  );
};
