import { memo } from 'react';

import { useStyles } from '../CoveragesForm.styles';
import type { CoverageGroup } from './CoverageSummaryCardItem';
import { CoverageSummaryCardItem } from './CoverageSummaryCardItem';

export interface CoverageSummaryCardProps {
  coverageGroups: CoverageGroup[];
}

export const CoveragesSummaryCard: React.FC<CoverageSummaryCardProps> = memo(
  ({ coverageGroups }) => {
    const { classes } = useStyles();

    return (
      <div className={classes.coverageSumContent}>
        {coverageGroups.map(({ title, items }) => (
          <CoverageSummaryCardItem key={title} title={title} items={items} />
        ))}
      </div>
    );
  },
);
