import { memo } from 'react';

import { AppBar } from '@mui/material';

import { getCurrentPage, getShowThirdPartyBanner } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { useStyles } from './AppHeader.styles';
import { HeaderMainContent } from './HeaderMainContent/HeaderMainContent';
import metadata from './metadata';

export const AppHeader: React.FC = memo(() => {
  const { classes } = useStyles();

  const currentPage = useSelector(getCurrentPage);
  const showBanner = useSelector(getShowThirdPartyBanner);
  const showBannerOnCurPage = currentPage && metadata.bannerPages?.includes(currentPage);

  return metadata.fixedHeader ? (
    <>
      <AppBar className={classes.root} position='sticky'>
        <HeaderMainContent />
      </AppBar>
      {!showBannerOnCurPage && !showBanner && <div className={classes.spacer} />}
    </>
  ) : (
    <div className={classes.root}>
      <HeaderMainContent />
    </div>
  );
});
