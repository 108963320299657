import { useCallback, useEffect, useMemo, useState } from 'react';

import type { Selector } from 'reselect';

import type { TrackingDimensionsNames } from '@ecp/utils/analytics/tracking';
import { setDimension } from '@ecp/utils/analytics/tracking';
import { castToBoolean, hasValue } from '@ecp/utils/common';

import { useGetFieldRefs, useInitValues, useInitValuesState } from '@ecp/features/sales/form';
import {
  getCheckoutDataFromOffers,
  getOfferDetailsForProduct,
  getOfferProductsSelectedByType,
} from '@ecp/features/sales/shared/store';
import type { AppDispatch, OfferInfo, RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type {
  Answers,
  AnswerValue,
  Condition,
  FieldCardOption,
} from '@ecp/features/sales/shared/types';
import type { Field, Fields } from '@ecp/types';

import { getDeltaField, updateAnswers } from '../inquiry';
import type { FormCallbacks } from './selectors';
import { getField, getForm } from './selectors';

interface FormParams<Value extends AnswerValue = AnswerValue> {
  initValues?: React.RefObject<Answers>;
  fields: Fields<Value>;
  conditions?: Condition[];
  formKey?: string;
}

interface CheckboxNoPatchGroupCleanUpProps {
  theQuestionValueNeedsToBeCleanedUp: AnswerValue;
  theQuestionValueGroup: Field<AnswerValue>[];
}

export function useForm<Value extends AnswerValue = AnswerValue>({
  initValues: initValuesProp,
  fields,
  conditions,
  formKey,
}: FormParams<Value>): FormCallbacks {
  const dispatch = useDispatch();
  const getFieldRefs = useGetFieldRefs();
  const initValues = useInitValuesState();
  const formCallbacks = useSelector((state: RootStore) => {
    return getForm(state, {
      dispatch,
      fields: fields as unknown as Fields,
      getFieldRefs,
      conditions,
    });
  });

  useInitValues(initValuesProp?.current);

  const { initializeForm, resetInitValues } = formCallbacks;

  // reinitialize form should only happen once on page/form change, this will reset userValues
  useEffect(() => {
    if (initValues) {
      initializeForm(initValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // reset initValue if ref and initValue changed, this will not reset userValues
  useEffect(() => {
    if (initValues) {
      resetInitValues(initValues);
    }
  }, [initValues, formKey, resetInitValues]);

  return formCallbacks;
}

export const useField = (key: string, questionKey?: string): Field => {
  const dispatch = useDispatch();

  return useSelector((state: RootStore) => getField(state, { key, questionKey, dispatch }));
};

// while we support sapiTarget=v3 delta fields have different names between sapiTarget=v3 and sapiTarget=v4
// this means the caller must pass in the intended product in addition to the key, this product is needed
// to create the sapiTarget=v3 name
// When using sapiTarget=v3 this will always be the autoOfferProduct.
// When upgrading a component to work on v4, note that only some fields actually need a product set,
// but that is specified as part of the "ref", i.e. coverageOption fields (such as the paperless email flag).
// All other fields do not use the product namespace (except when sapiTarget=v3).
// So, code should generally look like:
// const { auto: autoProduct } = useSelector(getOfferProductsSelectedByType);
// const field = useDeltaField(autoProduct, 'driver.123', 'license.state');
// Once sapiTarget=v3 is dropped, this helper can be removed entirely (or at least this first parameter dropped).
// NOTE: pass 'root' for ref if you want to access fields attached directly to the delta namespace.
export const useDeltaField = (ref: string, key: string, questionKey?: string): Field => {
  const dispatch = useDispatch();

  return useSelector((state: RootStore) => {
    // delta keys have mapping based on sapiTarget, let's fix that first
    const fixedKey = getDeltaField(state, ref, key);

    return getField(state, { key: fixedKey, questionKey, dispatch });
  });
};

export const usePostBindField = (key: string, questionKey?: string): Field => {
  const dispatch = useDispatch();

  return useSelector((state: RootStore) =>
    getField(state, { key, questionKey, dispatch, isPostBindQuestion: true }),
  );
};

export const selectFieldWithPrefix =
  (
    dispatch: AppDispatch,
    answerPrefixProp: string,
    questionPrefixProp?: string,
  ): ((
    answerKeyWithoutPrefix: string,
    questionKeyWithoutPrefixProp?: string,
  ) => Selector<RootStore, Field>) =>
  (
    answerKeyWithoutPrefix: string,
    questionKeyWithoutPrefixProp?: string,
  ): Selector<RootStore, Field> => {
    const answerPrefix = answerPrefixProp && `${answerPrefixProp}.`;
    const questionPrefixTemp = questionPrefixProp || answerPrefixProp;
    const questionPrefix = questionPrefixTemp && `${questionPrefixTemp}.`;
    const questionKeyWithoutPrefix = questionKeyWithoutPrefixProp || answerKeyWithoutPrefix;

    return (state: RootStore): Field => {
      return getField(state, {
        key: `${answerPrefix}${answerKeyWithoutPrefix}`,
        questionKey: `${questionPrefix}${questionKeyWithoutPrefix}`,
        dispatch,
      });
    };
  };

// convenience function to get a bound '#useField()' to not have to put full path of answer/question key
export const useFieldWithPrefix = (
  answerPrefixProp: string,
  questionPrefixProp?: string,
): (<Value extends AnswerValue = AnswerValue>(
  answerKeyWithoutPrefix: string,
  questionKeyWithoutPrefixProp?: string,
) => Field<Value>) => {
  const dispatch = useDispatch();
  function useFieldPrefixed<Value extends AnswerValue = AnswerValue>(
    answerKeyWithoutPrefix: string,
    questionKeyWithoutPrefixProp?: string,
  ): Field<Value> {
    const answerPrefix = answerPrefixProp && `${answerPrefixProp}.`;
    const questionPrefixTemp = questionPrefixProp || answerPrefixProp;
    const questionPrefix = questionPrefixTemp && `${questionPrefixTemp}.`;
    const questionKeyWithoutPrefix = questionKeyWithoutPrefixProp || answerKeyWithoutPrefix;

    return useSelector<Field<Value>>(
      (state: RootStore) =>
        getField(state, {
          key: `${answerPrefix}${answerKeyWithoutPrefix}`,
          questionKey: `${questionPrefix}${questionKeyWithoutPrefix}`,
          dispatch,
        }) as unknown as Field<Value>,
    );
  }

  return useFieldPrefixed;
};

export const getInferredValueForYesNoButton = (param: {
  yesNoField: Field;
  refField?: Field;
  getValue?: () => boolean | null;
}): boolean | null => {
  const { yesNoField, refField, getValue } = param;
  if (yesNoField.value === undefined) {
    if (refField) return hasValue(refField.value) && refField.value !== 'CARRIER.PREFILL';
    if (getValue) return getValue();
  }

  return castToBoolean(yesNoField.value);
};

// This is a special use case for CheckboxGroup where boolean value fields and
// fields with multiple options are combined to create the options for CheckboxGroup
export const useMultiFieldsForCheckBoxGroup = (
  fields: Field[],
  fieldOptionMetaData: FieldCardOption[],
  trackingDimension?: TrackingDimensionsNames,
  trackingValue?: unknown,
): {
  values: string[];
  error: string;
  name: string;
  actionOnComplete: (value: AnswerValue, valueClicked?: string, newChecked?: boolean) => void;
} => {
  const [fieldToValueMap, valueToFieldMap] = useMemo(() => {
    const valueMap: { [key: string]: string } = {};
    const fieldMap: { [key: string]: string } = {};
    fieldOptionMetaData.forEach((option: FieldCardOption) => {
      valueMap[option.fieldName] = option.value;
      fieldMap[option.value] = option.fieldName;
    });

    return [valueMap, fieldMap];
  }, [fieldOptionMetaData]);

  const checkboxGroupValues = useMemo(() => {
    return fields.reduce((vals: string[], field: Field) => {
      const fieldName = field.props.name.substring(field.props.name.lastIndexOf('.') + 1);
      const val = field.props.value;
      if (field.exists && val) {
        if (field.props.options) {
          const fieldValues = Array.isArray(val) ? val : [val];

          return [...vals, ...fieldValues];
        }
        if (val === true) vals.push(fieldToValueMap[fieldName]);
      }

      return vals;
    }, []);
  }, [fields, fieldToValueMap]);
  const checkboxGroupError = useMemo(() => {
    const errs = fields.reduce((errors: string[], field: Field) => {
      const fieldName = field.props.name.substring(field.props.name.lastIndexOf('.') + 1);
      const { error } = field.props;
      if (field.exists && error) {
        errors.push(`${fieldName}: ${error}`);
      }

      return errors;
    }, []);

    return errs.join(';');
  }, [fields]);

  const checkboxGroupName = useMemo(() => {
    const nameList = fields.reduce((names: string[], field: Field) => {
      if (field.exists) {
        names.push(field.props.name);
      }

      return names;
    }, []);

    return nameList.join('_');
  }, [fields]);

  const onActionCompleteHandler = useCallback(
    (value: AnswerValue, valueClicked?: string, newChecked?: boolean): void => {
      const fieldClicked = valueToFieldMap[valueClicked as string];
      const clickedField = fields.find(
        (field) =>
          field.props.name.substring(field.props.name.lastIndexOf('.') + 1) === fieldClicked,
      );
      if (clickedField) {
        let fieldValue: string | undefined = '';
        if (newChecked) fieldValue = clickedField.props.options ? valueClicked : 'true';
        clickedField.props.actionOnComplete(fieldValue);
      }
      if (trackingDimension && trackingValue) {
        /**
         * This is currently use for Auto Short Form Discounts
         * which sets the corresponding vehicleRef to GA Dimension
         * as the user checks the vehicle discount options associated to a vehicle
         * */
        setDimension(trackingDimension, trackingValue);
      }
    },
    [fields, valueToFieldMap, trackingDimension, trackingValue],
  );

  return {
    values: checkboxGroupValues,
    error: checkboxGroupError,
    name: checkboxGroupName,
    actionOnComplete: onActionCompleteHandler,
  };
};

export const useMultiFieldsForNoPatchCheckBoxGroup = (
  fields: Field[],
  fieldOptionMetaData: FieldCardOption[],
  actualDisplayedArray: string[],
  trackingDimension?: TrackingDimensionsNames,
  trackingValue?: unknown,
): {
  values: string[];
  error: string;
  name: string;
  actionOnComplete: (value: AnswerValue, valueClicked?: string, newChecked?: boolean) => void;
} => {
  const [valueTobeDisplayed, setValueTobeDisplayed] = useState(actualDisplayedArray);
  const [fieldToValueMap, valueToFieldMap] = useMemo(() => {
    const valueMap: { [key: string]: string } = {};
    const fieldMap: { [key: string]: string } = {};
    fieldOptionMetaData.forEach((option: FieldCardOption) => {
      valueMap[option.fieldName] = option.value;
      fieldMap[option.value] = option.fieldName;
    });

    return [valueMap, fieldMap];
  }, [fieldOptionMetaData]);

  const checkboxGroupError = useMemo(() => {
    const errs = fields.reduce((errors: string[], field: Field) => {
      const fieldName = field.props.name.substring(field.props.name.lastIndexOf('.') + 1);
      const { error } = field.props;
      if (field.exists && error) {
        errors.push(`${fieldName}: ${error}`);
      }

      return errors;
    }, []);

    return errs.join(';');
  }, [fields]);

  const checkboxGroupName = useMemo(() => {
    const nameList = fields.reduce((names: string[], field: Field) => {
      if (field.exists) {
        names.push(field.props.name);
      }

      return names;
    }, []);

    return nameList.join('_');
  }, [fields]);

  const onActionCompleteHandler = useCallback(
    (value: AnswerValue, valueClicked?: string, newChecked?: boolean): void => {
      const fieldClicked = valueToFieldMap[valueClicked as string];
      const clickedField = fields.find(
        (field) =>
          field.props.name.substring(field.props.name.lastIndexOf('.') + 1) === fieldClicked,
      );

      if (newChecked) {
        if (clickedField) {
          let fieldValue: string | undefined = '';
          if (newChecked) fieldValue = clickedField.props.options ? valueClicked : 'true';

          const newFieldName = clickedField.props.name.substring(
            clickedField.props.name.lastIndexOf('.') + 1,
          );

          const valueTobeProcessed = fieldToValueMap[newFieldName] as string;
          if (fieldValue) {
            valueTobeDisplayed.push(valueTobeProcessed);
          } else {
            const index = valueTobeDisplayed.indexOf(valueTobeProcessed);
            if (index !== -1) {
              valueTobeDisplayed.splice(index, 1);
            }
          }

          const newArray = fields;

          const objIndex = fields.findIndex((item) => item.props.name === clickedField.props.name);

          newArray[objIndex].value = fieldValue ? true : undefined;

          setValueTobeDisplayed(valueTobeDisplayed);
        }
      } else {
        if (clickedField) {
          const fieldValue: string | undefined = '';
          const newFieldName = clickedField.props.name.substring(
            clickedField.props.name.lastIndexOf('.') + 1,
          );
          const valueTobeProcessed = fieldToValueMap[newFieldName] as string;

          const index = valueTobeDisplayed.indexOf(valueTobeProcessed);
          if (index !== -1) {
            valueTobeDisplayed.splice(index, 1);
          }

          const newArray = fields;
          const objIndex = fields.findIndex((item) => item.props.name === clickedField.props.name);
          newArray[objIndex].value = fieldValue ? true : undefined;
          setValueTobeDisplayed(valueTobeDisplayed);
        }
      }

      if (trackingDimension && trackingValue) {
        setDimension(trackingDimension, trackingValue);
      }
    },
    [
      valueToFieldMap,
      fields,
      trackingDimension,
      trackingValue,
      fieldToValueMap,
      valueTobeDisplayed,
    ],
  );

  return {
    values: valueTobeDisplayed,
    error: checkboxGroupError,
    name: checkboxGroupName,
    actionOnComplete: onActionCompleteHandler,
  };
};

export const convertFieldsValueToPatch = (
  intialArray: {
    [k: string]: unknown;
  }[],
): Answers => {
  const patchedValue = {};

  if (intialArray && intialArray.length > 0) {
    intialArray.forEach((element) =>
      Object.assign(patchedValue, {
        [element.key as string]: element.value ? 'true' : element.value,
      }),
    );
  }

  return patchedValue as Answers;
};

export const keysTobecomparedFromFields = ['key', 'value'];

export const useCheckboxNoPatchGroupSelectionsCleanUp = ({
  theQuestionValueNeedsToBeCleanedUp,
  theQuestionValueGroup,
}: CheckboxNoPatchGroupCleanUpProps): void => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (theQuestionValueNeedsToBeCleanedUp !== false) return;
    const theQuestionFieldsValueToBeCleared: Answers = {};

    theQuestionValueGroup.forEach((item) => {
      if (item.value === true) theQuestionFieldsValueToBeCleared[item.key] = null;
    });

    dispatch(updateAnswers({ answers: theQuestionFieldsValueToBeCleared }));
  }, [theQuestionValueNeedsToBeCleanedUp, theQuestionValueGroup, dispatch]);
};

export const useMonthlyPremium = (): OfferInfo | null => {
  useSelector(getCheckoutDataFromOffers);

  const { property: propertyOfferProduct } = useSelector(getOfferProductsSelectedByType);

  const offerDetails = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, propertyOfferProduct),
  );

  return offerDetails;
};
