import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ContactUsPopover' })((theme) => ({
  contactHours: {
    ...theme.typography.body2,
    margin: '10px 0px',
    color: theme.palette.text.secondary,
  },
  popoverBody: {
    '& > *:not(p)': {
      marginTop: 10,
    },
    marginBottom: -10, // remove 10 from -20 padding-bottom of parent Popover element
  },
  lastPopoverItem: {
    paddingBottom: 10, // compensate the popoverBody negative margin-bottom
  },
}));
