import { trackError } from '@ecp/utils/analytics/tracking';
import { emptyArray, merge } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';
import type { RequestInitExtended } from '@ecp/utils/network';
import { fetchWithTimeout } from '@ecp/utils/network';

import { env } from '@ecp/env';

import { SmartyStreetsEnums } from './constants';
import type { Address, AddressValidateQuery, GeoAddress, GeoValidatedAddress } from './types';

const defaultAddressOptions = {
  params: {
    'auth-id': env.smartyStreetsApiAuthId,
    license: 'us-core-enterprise-cloud',
    match: 'enhanced',
  },
};
const defaultSuggestionOptions = {
  params: {
    key: env.smartyStreetsApiAuthId,
  },
};

const validateRecordType = (verified: GeoValidatedAddress): string => {
  let matchType;
  switch (verified.metadata.record_type) {
    case SmartyStreetsEnums.METADATA.RECORD_TYPE.FIRM:
    case SmartyStreetsEnums.METADATA.RECORD_TYPE.RURAL_ROUTE:
    case SmartyStreetsEnums.METADATA.RECORD_TYPE.STREET:
    case SmartyStreetsEnums.METADATA.RECORD_TYPE.HIGH_RISE:
      matchType = SmartyStreetsEnums.MATCH_TYPE.EXACT;
      break;
    case SmartyStreetsEnums.METADATA.RECORD_TYPE.GENERAL_DELIVERY:
    case SmartyStreetsEnums.METADATA.RECORD_TYPE.POST_OFFICE_BOX:
    default:
      matchType = SmartyStreetsEnums.MATCH_TYPE.NONE;
      break;
  }

  return matchType;
};

const validateZipType = (verified: GeoValidatedAddress): string => {
  let matchType;
  switch (verified.metadata.zip_type) {
    case SmartyStreetsEnums.METADATA.ZIP_TYPE.STANDARD:
      matchType = SmartyStreetsEnums.MATCH_TYPE.EXACT;
      break;
    case SmartyStreetsEnums.METADATA.ZIP_TYPE.UNIQUE:
    case SmartyStreetsEnums.METADATA.ZIP_TYPE.PO_BOX:
    case SmartyStreetsEnums.METADATA.ZIP_TYPE.MILITARY:
    default:
      matchType = SmartyStreetsEnums.MATCH_TYPE.NONE;
      break;
  }

  return matchType;
};

const validateCarrierRoute = (verified: GeoValidatedAddress): string => {
  let matchType;
  switch (verified.metadata.carrier_route.substr(0, 1)) {
    case SmartyStreetsEnums.METADATA.CARRIER_ROUTE.CITY:
    case SmartyStreetsEnums.METADATA.CARRIER_ROUTE.RURAL:
    case SmartyStreetsEnums.METADATA.CARRIER_ROUTE.HIGHWAY:
      matchType = SmartyStreetsEnums.MATCH_TYPE.EXACT;
      break;
    case SmartyStreetsEnums.METADATA.CARRIER_ROUTE.BOX:
    case SmartyStreetsEnums.METADATA.CARRIER_ROUTE.GENERAL:
    default:
      matchType = SmartyStreetsEnums.MATCH_TYPE.NONE;
      break;
  }

  return matchType;
};

const validateBuildingDefaultIndicator = (verified: GeoValidatedAddress): string => {
  let matchType;
  switch (verified.metadata.building_default_indicator) {
    case SmartyStreetsEnums.METADATA.BUILDING_DEFAULT_INDICATOR.YES:
      matchType = SmartyStreetsEnums.MATCH_TYPE.PARTIAL;
      break;
    case SmartyStreetsEnums.METADATA.BUILDING_DEFAULT_INDICATOR.NO:
    default:
      // not always returned
      matchType = SmartyStreetsEnums.MATCH_TYPE.EXACT;
      break;
  }

  return matchType;
};

const validateResidentialDeliveryIndicator = (verified: GeoValidatedAddress): string => {
  let matchType;
  switch (verified.metadata.rdi) {
    case SmartyStreetsEnums.METADATA.RESIDENTIAL_DELIVERY_INDICATOR.RESIDENTIAL:
      matchType = SmartyStreetsEnums.MATCH_TYPE.EXACT;
      break;
    case SmartyStreetsEnums.METADATA.RESIDENTIAL_DELIVERY_INDICATOR.COMMERCIAL:
    default:
      matchType = SmartyStreetsEnums.MATCH_TYPE.NONE;
      break;
  }

  return matchType;
};

const validatePrecision = (verified: GeoValidatedAddress): string => {
  let matchType;
  switch (verified.metadata.precision) {
    case SmartyStreetsEnums.METADATA.PRECISION.ZIP5:
    case SmartyStreetsEnums.METADATA.PRECISION.ZIP6:
    case SmartyStreetsEnums.METADATA.PRECISION.ZIP7:
    case SmartyStreetsEnums.METADATA.PRECISION.ZIP8:
    case SmartyStreetsEnums.METADATA.PRECISION.ZIP9:
      matchType = SmartyStreetsEnums.MATCH_TYPE.EXACT;
      break;
    case SmartyStreetsEnums.METADATA.PRECISION.UNKNOWN:
    default:
      matchType = SmartyStreetsEnums.MATCH_TYPE.NONE;
      break;
  }

  return matchType;
};

const validateDpvMatchCode = (verified: GeoValidatedAddress): string => {
  let matchType;
  switch (verified.analysis.dpv_match_code) {
    case SmartyStreetsEnums.ANALYSIS.DPV_MATCH_CODE.CONFIRMED:
      matchType = SmartyStreetsEnums.MATCH_TYPE.EXACT;
      break;
    case SmartyStreetsEnums.ANALYSIS.DPV_MATCH_CODE.CONFIRMED_BY_DROPPING_SECONDARY:
    case SmartyStreetsEnums.ANALYSIS.DPV_MATCH_CODE.CONFIRMED_MISSING_SECONDARY:
      matchType = SmartyStreetsEnums.MATCH_TYPE.PARTIAL;
      break;
    case SmartyStreetsEnums.ANALYSIS.DPV_MATCH_CODE.NOT_CONFIRMED:
    default:
      matchType = SmartyStreetsEnums.MATCH_TYPE.NONE;
      break;
  }

  return matchType;
};

const validateDpvFootnotes = (verified: GeoValidatedAddress): string => {
  let matchType = '';
  const exactFootnotes: string[] = [
    SmartyStreetsEnums.ANALYSIS.DPV_FOOTNOTES.AA,
    SmartyStreetsEnums.ANALYSIS.DPV_FOOTNOTES.BB,
    SmartyStreetsEnums.ANALYSIS.DPV_FOOTNOTES.CC,
  ];
  const partialFootnotes: string[] = [
    SmartyStreetsEnums.ANALYSIS.DPV_FOOTNOTES.A1,
    SmartyStreetsEnums.ANALYSIS.DPV_FOOTNOTES.M1,
    SmartyStreetsEnums.ANALYSIS.DPV_FOOTNOTES.N1,
  ];
  // for reference or in case requirements change
  // const noMatchFootnotes: string[] = [
  //   SmartyStreetsEnums.ANALYSIS.DPV_FOOTNOTES.F1,
  //   SmartyStreetsEnums.ANALYSIS.DPV_FOOTNOTES.G1,
  //   SmartyStreetsEnums.ANALYSIS.DPV_FOOTNOTES.M3,
  //   SmartyStreetsEnums.ANALYSIS.DPV_FOOTNOTES.PB,
  //   SmartyStreetsEnums.ANALYSIS.DPV_FOOTNOTES.P1,
  //   SmartyStreetsEnums.ANALYSIS.DPV_FOOTNOTES.P3,
  // ];
  // break footnotes into 2 character pieces
  // https://stackoverflow.com/questions/7033639/split-large-string-in-n-size-chunks-in-javascript
  const footnotePieces = verified.analysis.dpv_footnotes.match(/.{1,2}/g) || emptyArray;
  for (let i = 0; i < footnotePieces.length; i += 1) {
    const footNote = footnotePieces[i];
    if (exactFootnotes.includes(footNote)) {
      matchType = SmartyStreetsEnums.MATCH_TYPE.EXACT;
    } else if (partialFootnotes.includes(footNote)) {
      matchType = SmartyStreetsEnums.MATCH_TYPE.PARTIAL;
    } else {
      matchType = SmartyStreetsEnums.MATCH_TYPE.NONE;
    }
    if (matchType !== SmartyStreetsEnums.MATCH_TYPE.EXACT) {
      break;
    }
  }

  return matchType;
};

const validateDpvCmra = (verified: GeoValidatedAddress): string => {
  let matchType;
  switch (verified.analysis.dpv_cmra) {
    case SmartyStreetsEnums.ANALYSIS.DPV_CMRA.YES:
      matchType = SmartyStreetsEnums.MATCH_TYPE.NONE;
      break;
    case SmartyStreetsEnums.ANALYSIS.DPV_CMRA.NO:
    default:
      matchType = SmartyStreetsEnums.MATCH_TYPE.EXACT;
      break;
  }

  return matchType;
};

const validateLacslinkCode = (verified: GeoValidatedAddress): string => {
  let matchType;
  switch (verified.analysis.lacslink_code) {
    case SmartyStreetsEnums.ANALYSIS.LACSLINK_CODE.ZERO_ZERO:
      matchType = SmartyStreetsEnums.MATCH_TYPE.NONE;
      break;
    case SmartyStreetsEnums.ANALYSIS.LACSLINK_CODE.A:
    case SmartyStreetsEnums.ANALYSIS.LACSLINK_CODE.NINE_TWO:
      matchType = SmartyStreetsEnums.MATCH_TYPE.PARTIAL;
      break;
    case SmartyStreetsEnums.ANALYSIS.LACSLINK_CODE.ZERO_NINE:
    case SmartyStreetsEnums.ANALYSIS.LACSLINK_CODE.ONE_FOUR:
    default:
      matchType = SmartyStreetsEnums.MATCH_TYPE.EXACT;
      break;
  }

  return matchType;
};

const validateLacslinkIndicator = (verified: GeoValidatedAddress): string => {
  let matchType;
  switch (verified.analysis.lacslink_indicator) {
    case SmartyStreetsEnums.ANALYSIS.LACSLINK_INDICATOR.N:
    case SmartyStreetsEnums.ANALYSIS.LACSLINK_INDICATOR.F:
      matchType = SmartyStreetsEnums.MATCH_TYPE.NONE;
      break;
    case SmartyStreetsEnums.ANALYSIS.LACSLINK_INDICATOR.S:
      matchType = SmartyStreetsEnums.MATCH_TYPE.PARTIAL;
      break;
    case SmartyStreetsEnums.ANALYSIS.LACSLINK_INDICATOR.Y:
    default:
      matchType = SmartyStreetsEnums.MATCH_TYPE.EXACT;
      break;
  }

  return matchType;
};

const validateSuitelinkMatch = (verified: GeoValidatedAddress): string => {
  let matchType;
  switch (verified.analysis.suitelink_match) {
    case SmartyStreetsEnums.ANALYSIS.LACSLINK_INDICATOR.N:
    case SmartyStreetsEnums.ANALYSIS.LACSLINK_INDICATOR.F:
      matchType = SmartyStreetsEnums.MATCH_TYPE.NONE;
      break;
    case SmartyStreetsEnums.ANALYSIS.LACSLINK_INDICATOR.S:
      matchType = SmartyStreetsEnums.MATCH_TYPE.PARTIAL;
      break;
    case SmartyStreetsEnums.ANALYSIS.LACSLINK_INDICATOR.Y:
    default:
      matchType = SmartyStreetsEnums.MATCH_TYPE.EXACT;
      break;
  }

  return matchType;
};

// 75.1
// https://theexperimentationlab.atlassian.net/wiki/spaces/CCR/pages/1443102887/Feat73+74+114+-+SmartyStreets+Enhancements+-+Phase+1+1.5+Phase+2
export const getMatchType = (verified: GeoValidatedAddress): string => {
  let matchType;
  if (!verified) {
    matchType = SmartyStreetsEnums.MATCH_TYPE.NONE;
  } else {
    matchType = SmartyStreetsEnums.MATCH_TYPE.EXACT;
    const validationFunctions: ((verified: GeoValidatedAddress) => string)[] = [
      validateRecordType,
      validateZipType,
      validateCarrierRoute,
      validateBuildingDefaultIndicator,
      validateResidentialDeliveryIndicator,
      // elot_sort is always exact so no need to loop through
      validatePrecision,
      validateDpvMatchCode,
      validateDpvFootnotes,
      validateDpvCmra,
      // dpv_vacant is always exact
      // active is always exact
      validateLacslinkCode,
      validateLacslinkIndicator,
      validateSuitelinkMatch,
    ];
    for (let i = 0; i < validationFunctions.length; i += 1) {
      matchType = validationFunctions[i](verified);
      if (matchType !== SmartyStreetsEnums.MATCH_TYPE.EXACT) {
        // stop validating once we know it's not an exact match
        break;
      }
    }
  }

  return matchType;
};

const checkResponse = (response: Response): void => {
  const { status, statusText } = response;
  if (status !== 200) {
    trackError({ action: statusText, label: status });
    datadogLog({
      logType: 'error',
      message: `Exception calling Geo Api - code: [${status}] - reason - [${statusText}]`,
      context: {
        logOrigin: 'libs/utils/geo/src/geoApi.ts',
        contextType: 'Smarty Streets Error',
        functionOrigin: 'checkResponse',
        responseStatus: status,
        statusText,
      },
    });
    throw new Error(`Exception calling Geo Api - code: [${status}] - reason - [${statusText}]`);
  }
};

export const fetchAptSugestions = async ({
  searchValue,
  selectedValue,
}: {
  searchValue: string;
  selectedValue: string;
}): Promise<GeoAddress[] | undefined> => {
  const init: RequestInitExtended = merge({}, defaultSuggestionOptions, {
    params: { search: searchValue, selected: selectedValue },
  });
  const response = await fetchWithTimeout<{ suggestions: GeoAddress[] }>({
    url: `${env.smartyStreetsAutoCompleteApiRoot}/lookup`,
    init,
  })
    .then((resp) => {
      checkResponse(resp);

      return resp.json();
    })
    .catch((e) => {
      datadogLog({
        logType: 'error',
        message: `Error fetching Smarty Streets Apartment Suggestions - ${e?.message}`,
        context: {
          logOrigin: 'libs/utils/geo/src/geoApi.ts',
          functionOrigin: 'fetchAptSugestions',
          contextType: 'Smarty Streets Error',
          responseError: JSON.stringify(e),
          severity: 'fatal',
        },
        error: e,
      });

      return { suggestions: undefined };
    });

  return response.suggestions;
};

export const fetchSuggestions = async ({
  value,
  zipcode,
  state,
}: {
  value: string;
  zipcode: string;
  state: string;
}): Promise<GeoAddress[] | undefined> => {
  let params: RequestInitExtended['params'];
  // Garage address may not be located in the same zip as primary address hence why we're conditionally setting the params.
  if (!zipcode && !state) {
    params = { search: value };
  } else if (!state) {
    params = { search: value, include_only_zip_codes: zipcode };
  } else {
    params = { search: value, include_only_states: state };
  }

  const init: RequestInitExtended = merge({}, defaultSuggestionOptions, {
    params,
  });
  const response = await fetchWithTimeout<{ suggestions: GeoAddress[] }>({
    url: `${env.smartyStreetsAutoCompleteApiRoot}/lookup`,
    init,
  })
    .then((resp) => {
      checkResponse(resp);

      return resp.json();
    })
    .catch((e) => {
      datadogLog({
        logType: 'error',
        message: `Error fetching Smarty Streets Address Suggestions - ${e?.message}`,
        context: {
          logOrigin: 'libs/utils/geo/src/geoApi.ts',
          functionOrigin: 'fetchSuggestions',
          contextType: 'Smarty Streets Error',
          responseError: JSON.stringify(e),
          severity: 'fatal',
        },
        error: e,
      });

      return { suggestions: undefined };
    });

  return response.suggestions;
};

export const verifyAddress = async (
  addressToBeValidated: AddressValidateQuery,
): Promise<GeoValidatedAddress[]> => {
  const init: RequestInitExtended = merge({}, defaultAddressOptions, {
    // The API will return detailed output only if a valid match is found. Otherwise the API response will be an empty array.
    // To cover edge cases of when addresses are partially correct.
    params: { ...addressToBeValidated, match: 'strict' },
  });

  return fetchWithTimeout<GeoValidatedAddress[]>({
    url: `${env.smartyStreetsStreetApiRoot}/street-address`,
    init,
  })
    .then((resp) => {
      checkResponse(resp);

      return resp.json();
    })
    .catch((e) => {
      datadogLog({
        logType: 'error',
        message: `Error Verifying Smarty Streets Address - ${e?.message}`,
        context: {
          logOrigin: 'libs/utils/geo/src/geoApi.ts',
          functionOrigin: 'verifyAddress',
          contextType: 'Smarty Streets Error',
          responseError: JSON.stringify(e),
          severity: 'fatal',
        },
        error: e,
      });

      return [];
    });
};

export const validateAndCombineAddress = async (
  inputAddress: AddressValidateQuery,
  ref: string,
): Promise<Address | null> => {
  const newAddress = await verifyAddress(inputAddress);
  const verified = newAddress[0];

  if (verified) {
    if (verified.components.city_name !== inputAddress.city) {
      datadogLog({
        logType: 'warn',
        message: `'geocode mismatch city',
        selected city did not match geocode result: '${verified.components.city_name}'
            '${String(inputAddress.city)}'`,
        context: {
          logOrigin: 'libs/utils/geo/src/geoApi.ts',
          contextType: 'Smarty Streets Warning',
          functionOrigin: 'validateAndCombineAddress',
        },
      });
    }
    if (verified.components.state_abbreviation !== inputAddress.state) {
      datadogLog({
        logType: 'warn',
        message: `'geocode mismatch state',
        selected city did not match geocode result: '${
          verified.components.state_abbreviation
        }' '${String(inputAddress.state)}'`,
        context: {
          logOrigin: 'libs/utils/geo/src/geoApi.ts',
          contextType: 'Smarty Streets Warning',
          functionOrigin: 'validateAndCombineAddress',
          stateAbbreviation: verified.components.state_abbreviation,
          addrState: inputAddress.state,
        },
      });
    }
  } else {
    datadogLog({
      logType: 'warn',
      message: `'geocode failed', query failed for: '${String(inputAddress.city)}' '${String(
        inputAddress.state,
      )}'`,
      context: {
        logOrigin: 'libs/utils/geo/src/geoApi.ts',
        contextType: 'Smarty Streets Warning',
        functionOrigin: 'validateAndCombineAddress',
      },
    });
  }

  // get type of usps address match
  const matchType = getMatchType(verified);

  // Address is not validated. Need Manual Address Entry
  if (!verified) {
    return null;
  }

  const { metadata, components } = verified;
  const parsedAddress = {
    latitude: metadata.latitude,
    longitude: metadata.longitude,
    line1: '',
    line2: '',
    city: components.city_name,
    state: components.state_abbreviation,
    zipcode: components.zipcode,
    ref,
    matchType,
  };

  if (components.street_name) {
    let line1Components = [
      components.primary_number,
      components.street_predirection,
      verified.components.street_name,
      verified.components.street_suffix,
      components.street_postdirection,
    ];
    // remove any falsy components
    line1Components = line1Components.filter((e) => e);

    parsedAddress.line1 = line1Components.join(' ');
  } else {
    // existing behavior remains for homeowners
    parsedAddress.line1 = verified.delivery_line_1;
  }

  // secondary_designator will be present if line2 (apt provided by the user)
  // was sent for verification.
  if (components.secondary_designator) {
    let line2Components = [components.secondary_designator, components.secondary_number];
    line2Components = line2Components.filter((e) => e);
    const line2 = line2Components.join(' ');
    parsedAddress.line2 = line2;
  }

  // returns validated address.
  return parsedAddress;
};
