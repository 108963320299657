import { env } from '@ecp/env';
import { PaymentPlan, SELECTED_PAYMENT_PLAN } from '@ecp/features/sales/shared/constants';
import type { Address, Person, QuestionsDef } from '@ecp/features/sales/shared/types';
import type { Question, Questions } from '@ecp/types';

import { PaymentFieldsInitial } from '../../purchase';

const STATIC_QUESTIONS_CONSUMER: Questions = {
  // TODO: Remove it in the future if not needed.
  'static.product.lob.auto': {
    description: 'Auto Line of Business',
    value: '',
    answerType: 'Boolean',
    businessType: ['auto', 'home', 'renters'],
    required: 'true',
  },
  'static.product.lob.home': {
    description: 'Home Line of Business',
    value: '',
    answerType: 'Boolean',
    businessType: ['auto', 'home', 'renters'],
    required: 'true',
  },
  'static.product.lob.renters': {
    description: 'Renters Line of Business',
    value: '',
    answerType: 'Boolean',
    businessType: ['auto', 'home', 'renters'],
    required: 'true',
  },
  'static.person.dob': {
    description: 'DOB',
    answerType: 'String',
    businessType: ['auto', 'home', 'renters'],
    criteria: [
      {
        error: 'Invalid format',
        $regex: '^\\d{4}\\-(((0)[1-9])|((1)[0-2]))\\-((0[1-9])|(1[0-9])|(2[0-9])|(3[0-1]))$',
      },
      { $lt: { $date: '-1 days' }, error: 'Please enter a valid date of birth' },
      {
        error: 'Invalid date of birth',
        $or: [
          {
            $and: [
              {
                $or: [
                  { $not: { $exists: '@primaryInsured.person.ref' } },
                  { '@primaryInsured.person.ref': { $ne: 'person.<id>' } },
                ],
              },
              {
                $and: [
                  {
                    $between: [{ $date: '-120 years -1 days' }, { $date: '-14 years' }],
                  },
                ],
              },
            ],
          },
          {
            $and: [
              { $not: { $gt: { $date: '-18 years' } } },
              { $not: { $lt: { $date: '-115 years' } } },
            ],
          },
        ],
      },
    ],
    required: 'true',
  },
  'static.person.<id>.dob': {
    description: 'DOB',
    answerType: 'String',
    businessType: ['auto', 'home', 'renters'],
    criteria: [
      {
        error: 'Invalid format',
        $regex: '^\\d{4}\\-(((0)[1-9])|((1)[0-2]))\\-((0[1-9])|(1[0-9])|(2[0-9])|(3[0-1]))$',
      },
      { $lt: { $date: '-1 days' }, error: 'Please enter a valid date of birth' },
      {
        error: 'Invalid date of birth',
        $or: [
          {
            $and: [
              {
                $or: [
                  { $not: { $exists: '@primaryInsured.person.ref' } },
                  { '@primaryInsured.person.ref': { $ne: 'person.<id>' } },
                ],
              },
              {
                $and: [
                  {
                    $between: [{ $date: '-120 years -1 days' }, { $date: '-14 years' }],
                  },
                ],
              },
            ],
          },
          {
            $and: [
              { $not: { $gt: { $date: '-18 years' } } },
              { $not: { $lt: { $date: '-115 years' } } },
            ],
          },
        ],
      },
    ],
    required: 'true',
  },
  'static.personPropertySNI.dob': {
    description: 'DOB',
    answerType: 'String',
    businessType: ['auto', 'home', 'renters'],
    criteria: [
      {
        error: 'Invalid format',
        $regex: '^\\d{4}\\-(((0)[1-9])|((1)[0-2]))\\-((0[1-9])|(1[0-9])|(2[0-9])|(3[0-1]))$',
      },
      { $lt: { $date: '-1 days' }, error: 'Please enter a valid date of birth' },
      {
        error: 'Invalid date of birth',
        $or: [
          {
            $and: [
              {
                $or: [
                  { $not: { $exists: '@primaryInsured.person.ref' } },
                  { '@primaryInsured.person.ref': { $ne: 'person.<id>' } },
                ],
              },
              {
                $and: [
                  {
                    $between: [{ $date: '-120 years -1 days' }, { $date: '-14 years' }],
                  },
                ],
              },
            ],
          },
          {
            $and: [
              { $not: { $gt: { $date: '-18 years' } } },
              { $not: { $lt: { $date: '-115 years' } } },
            ],
          },
        ],
      },
    ],
    required: 'true',
  },
  'static.address': {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  'static.triviaQuestion': {
    answerType: 'String',
    questionType: 'optional',
    businessType: ['auto', 'home'],
    required: 'false',
  },
  'static.username': {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home'],
    required: 'true',
    // set up custom error messages for specific errors. The following
    // section sets up custom error message for 'required' validation.
    _errors: {
      required: 'Please enter your username',
    },
  },
  'static.password': {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home'],
    required: 'true',
    // set up custom error messages for specific errors. The following
    // section sets up custom error message for 'required' validation.
    _errors: {
      required: 'Please enter your password',
    },
  },
  'static.policyBundle': {
    answerType: 'Boolean',
    questionType: 'optional',
    businessType: ['auto', 'home'],
    required: 'false',
  },
  'static.policyAuto': {
    answerType: 'Boolean',
    questionType: 'optional',
    businessType: ['auto', 'home'],
    required: 'false',
  },
  'static.policyHome': {
    answerType: 'Boolean',
    questionType: 'optional',
    businessType: ['auto', 'home'],
    required: 'false',
  },
  'static.keptAtRiskAddress': {
    answerType: 'Boolean',
    questionType: 'optional',
    businessType: ['auto', 'home'],
    required: 'false',
  },
  'static.vehicleKeptAddressYesNo': {
    answerType: 'Boolean',
    questionType: 'optional',
    businessType: ['auto', 'home'],
    required: 'false',
  },
  'static.vehicleKeptAddress': {
    answerType: 'String',
    questionType: 'optional',
    businessType: ['auto', 'home'],
    required: 'false',
  },
  'static.insurancePriorCoverage': {
    answerType: 'String',
    questionType: 'optional',
    businessType: ['auto', 'home'],
    required: 'false',
    options: [
      {
        value: '1',
        label: 'Comprehensive',
      },
      {
        value: '2',
        label: 'Collision',
      },
    ],
  },
  'static.isSni': {
    answerType: 'Boolean',
    questionType: 'optional',
    businessType: ['auto', 'home'],
    required: 'false',
  },
  'static.incidentDate': {
    answerType: 'String',
    questionType: 'optional',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  'static.vehicle.<id>.vehicleInfoOrVin': {
    answerType: 'String',
    questionType: 'optional',
    businessType: ['auto', 'home'],
    required: 'false',
    options: [
      {
        value: 'vehicleInfo',
        label: 'Vehicle information',
      },
      {
        value: 'vin',
        label: 'VIN',
      },
    ],
  },
  'static.notLiveYet': {
    answerType: 'Boolean',
    questionType: 'optional',
    businessType: ['auto', 'home'],
    required: 'false',
  },
  'static.foundationType': {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home'],
    required: 'true',
    criteria: [
      {
        error: 'The foundation type is not valid with the home style selected.',
        $not: {
          $and: [
            {
              '@property.styleOfHome': {
                $in: [
                  'STYLE_OF_HOME.BI_LEVEL',
                  'STYLE_OF_HOME.RAISED_RANCH',
                  'STYLE_OF_HOME.SPLIT_FOYER',
                ],
              },
            },
            {
              $not: {
                $regex: 'FOUNDATION\\.FOUNDATION_TYPE\\.SLAB',
              },
            },
          ],
        },
      },
      {
        error: 'Foundation type is not valid with selected basement garage.',
        $not: {
          $and: [
            { '@property.garage.type': { $eq: 'GARAGE.TYPE.BASEMENT' } },
            {
              $not: {
                $regex:
                  'FOUNDATION\\.FOUNDATION_TYPE\\.BASEMENT_UNDERGROUND_BELOW_GRADE|FOUNDATION\\.FOUNDATION_TYPE\\.BASEMENT_DAYLIGHT_WALKOUT',
              },
            },
          ],
        },
      },
    ],
    dependentQuestionKeys: ['property.styleOfHome', 'property.garage.type'],
  },
  'static.exteriorWall': {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  'static.interiorWall': {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  'static.floor': {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  'static.vehicleRefs': {
    answerType: 'String',
    questionType: 'optional',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  'static.driverRefs': {
    answerType: 'String',
    questionType: 'optional',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  'static.hasHeatingSource': {
    answerType: 'Boolean',
    questionType: 'required',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  'static.initialPrimaryInsuredRiskAddressfromRelate.ref': {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home', 'renters'],
    required: 'true',
  },
  'static.previous.selected.primaryInsuredAddress.ref': {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home', 'renters'],
    required: 'true',
  },
  [SELECTED_PAYMENT_PLAN]: {
    answerType: 'String',
    defaultValue: PaymentPlan.MONTHLY_PREMIUM,
    options: [
      {
        value: PaymentPlan.MONTHLY_PREMIUM,
        label: 'Monthly',
      },
      {
        value: PaymentPlan.FULL_PREMIUM,
        label: 'Pay in full',
      },
    ],
  },
  ...PaymentFieldsInitial,
};

const STATIC_QUESTIONS_AGENT: Questions = {
  'static.partner': {
    description: 'Partner',
    value: '',
    answerType: 'String',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  'static.partner.segment': {
    description: 'Partner Segment',
    value: '',
    answerType: 'String',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  'static.partner.account': {
    description: 'Partner Account',
    value: '',
    answerType: 'String',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  'static.emailQuotes.recepientEmail': {
    description: 'Email Quotes Email Address',
    value: '',
    maxLength: 40,
    answerType: 'String',
    businessType: ['auto', 'home'],
    criteria: [
      {
        error: 'Please enter a valid email',
        $regex: '(?=^([\\w\\.\\-\\+]+)@([\\w\\-]+)(\\.[\\w]+){1,2}$)(?=^.{0,40}$)',
      },
    ],
    required: 'true',
  },
  'static.agent.agreeToProceed.PersonPage': {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home'],
    required: 'true',
    _errors: {
      required: 'Must check box to continue',
    },
  },
  'static.agent.agreeToProceed.CheckoutPage': {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home'],
    required: 'true',
    _errors: {
      required: 'Must check box to continue',
    },
  },
  'static.agent.agreeToProceed.PostBindPageDialog': {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home'],
    required: 'true',
    _errors: {
      required: 'Must check box to continue',
    },
  },
  ...STATIC_QUESTIONS_CONSUMER,
};

/** Includes either agent or consumer questions, depending on the experience. */
const STATIC_QUESTIONS = env.static.isAgent ? STATIC_QUESTIONS_AGENT : STATIC_QUESTIONS_CONSUMER;
export { STATIC_QUESTIONS };

export const questionsOverrides: Record<string, Partial<Question>> = {
  'property.yearBuilt': {
    dependentQuestionKeys: ['property.styleOfHome'],
  },
  'property.numberOfStories': {
    dependentQuestionKeys: ['property.styleOfHome'],
  },
  'property.styleOfHome': {
    criteria: [
      {
        error: 'The foundation type is not valid with the home style selected.',
        $not: {
          $and: [
            {
              $in: [
                'STYLE_OF_HOME.BI_LEVEL',
                'STYLE_OF_HOME.RAISED_RANCH',
                'STYLE_OF_HOME.SPLIT_FOYER',
              ],
            },
            {
              $not: {
                '@static.foundationType': { $regex: 'FOUNDATION\\.FOUNDATION_TYPE\\.SLAB' },
              },
            },
          ],
        },
      },
    ],
    dependentQuestionKeys: [
      'property.numberOfStories',
      'property.yearBuilt',
      'static.foundationType',
    ],
  },
  'property.garage.type': {
    criteria: [
      {
        error: 'Basement garage is not valid with selected foundation type.',
        $not: {
          $and: [
            { $eq: 'GARAGE.TYPE.BASEMENT' },

            {
              '@static.foundationType': {
                $not: {
                  $regex:
                    'FOUNDATION\\.FOUNDATION_TYPE\\.BASEMENT_UNDERGROUND_BELOW_GRADE|FOUNDATION\\.FOUNDATION_TYPE\\.BASEMENT_DAYLIGHT_WALKOUT',
                },
              },
            },
          ],
        },
      },
    ],
    dependentQuestionKeys: ['static.foundationType'],
  },
};

// these are useful for testing
export const questionsEmptyPerson: QuestionsDef<Person> = {
  firstName: {
    questionType: 'required',
    answerType: 'String',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  lastName: {
    questionType: 'required',
    answerType: 'String',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  dateOfBirth: {
    questionType: 'required',
    answerType: 'String',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  ref: {
    questionType: 'required',
    answerType: 'String',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  email: {
    questionType: 'required',
    answerType: 'String',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  phone: {
    questionType: 'required',
    answerType: 'String',
    businessType: ['auto', 'home'],
    required: 'false',
  },
  maritalStatus: {
    questionType: 'required',
    answerType: 'String',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  gender: {
    questionType: 'required',
    answerType: 'String',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  fromPrefill: {
    questionType: 'required',
    answerType: 'Boolean',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  relationshipToApplicant: {
    questionType: 'required',
    answerType: 'String',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  insuredType: {
    questionType: 'required',
    answerType: 'String',
    businessType: ['auto', 'home'],
    required: 'true',
  },
};

export const questionsEmptyAddress: QuestionsDef<Address> = {
  ref: {
    questionType: 'optional',
    answerType: 'Reference',
    businessType: ['auto', 'home'],
    required: 'false',
  },
  city: {
    questionType: 'required',
    answerType: 'String',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  line1: {
    questionType: 'required',
    answerType: 'String',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  line2: {
    questionType: 'required',
    answerType: 'String',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  state: {
    questionType: 'required',
    answerType: 'String',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  zipcode: {
    questionType: 'required',
    answerType: 'String',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  latitude: {
    questionType: 'required',
    answerType: 'Number',
    businessType: ['auto', 'home'],
    required: 'true',
  },
  longitude: {
    questionType: 'required',
    answerType: 'Number',
    businessType: ['auto', 'home'],
    required: 'true',
  },
};
