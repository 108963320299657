import type { GetVehicleBase, Vehicle } from '@ecp/features/sales/shared/types';

import { composeVehicleKey } from './composeVehicleKey';

export const parseMakeName = (make = ''): string => make.replace(' Truck', '');

// Due to model name difference between amfam and fuelAPI, need to parse model name before passing it to fuelAPI
export const parseModelName = (() => {
  const vehicleModelDriveDoorRegex = /^([\w-_]+(((?!\dw?d\b)(?!\dx\d\b)(?! \(New\)).)*))/;
  const vehicleModelStyleRegex =
    /^([\w-_]+(((?! \(New\))(?!Conv\b)(?!Coupe\b)(?!Van\b)(?!Pickup\b).)*))/;
  // these are replacements that exist inside the model, so just replacing instead of adding to above regex which will split on match
  const knownModelReplacements = {
    Slvrdo: 'Silverado',
  } as const;

  return (modelProp = ''): string[] => {
    const resArr: string[] = [];
    const model = Object.entries(knownModelReplacements).reduce(
      (current, [replacementKey, replacementValue]) => {
        return (current || '').replace(replacementKey, replacementValue);
      },
      modelProp,
    );
    let smartMatch1 = model;
    let smartMatch2 = model;
    if (model) {
      // regex match, remove extra door, wheel drive info to get a cleaner model name
      let result = model.match(vehicleModelDriveDoorRegex);
      smartMatch1 = result ? result[1].trim() : smartMatch1;
      resArr.push(smartMatch1);
      // regex match, remove styles from model (like coupe, conv, etc) info to get a cleaner model name
      // many times image api doesn't have that level of detail, but sometimes they do
      // since we don't know, we split the regex and make two passes
      result = smartMatch1.match(vehicleModelStyleRegex);
      smartMatch2 = result ? result[1].trim() : smartMatch1;
      if (smartMatch2 !== smartMatch1) {
        resArr.push(smartMatch2);
      }
    }
    // we split the smartMatch further to get more possible keywords
    // we trim the words consecutively from the full length to a single word
    // like ['230 Xi Conv', '230 Xi', '230'] to get the precision-decreasing search
    for (let i = smartMatch2.length - 1; i >= 0; i -= 1) {
      if (smartMatch2.charAt(i) === ' ') {
        resArr.push(smartMatch2.substring(0, i));
      }
    }

    return resArr;
  };
})();

export const getVehicleDescription = (
  vehicle: Pick<Vehicle, 'year' | 'make' | 'model'>,
): string => {
  const { year, make, model } = vehicle;
  const modelCommonName = model ? parseModelName(model)[0] : '';

  return `${year ? `${year} ` : ''}${make ? `${make} ` : ''}${modelCommonName || ''}`.trim();
};

export const getVehicleImageUrlForAllModels = (
  { year, make, model }: GetVehicleBase,
  vehicleImageUrls: { [key: string]: string },
): string | undefined => {
  const urls: string[] = [];
  if (year && make && model) {
    const key = composeVehicleKey({ make, model, year });
    const vehicleImageUrl = vehicleImageUrls[key];
    urls.push(vehicleImageUrl);
    // first non-empty url, return
    if (vehicleImageUrl) return vehicleImageUrl;
  }

  // maintain '' vs null/undefined to help downstream to determine whether to retry or not
  // '' means it was looked up, but not found - null/undefined indicates there is no cache entry yet, so do lookup
  return urls.find((url) => url === '');
};
