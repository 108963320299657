import { GridItem } from '@ecp/components';
import { makeDriverItemList } from '@ecp/features/sales/shared/components';
import type { Driver, DriverOnly, Vehicle } from '@ecp/features/sales/shared/types';

import { ListItems } from '../ListItems';
import { useStyles } from './VehiclesAndDriversInfo.styles';

interface Props {
  vehicleItems?: Pick<Vehicle, 'year' | 'make' | 'model' | 'ref' | 'description'>[];
  drivers?: (Pick<
    Driver,
    | 'firstName'
    | 'middleName'
    | 'lastName'
    | 'suffix'
    | 'dateOfBirth'
    | 'personRef'
    | 'relationshipToApplicant'
    | 'gender'
    | 'ref'
    | 'driverStatus'
  > & { driversLicense: Pick<DriverOnly['driversLicense'], 'number'> })[];
  disableHeading?: boolean;
}

export const VehiclesAndDriversInfo: React.FC<Props> = (props) => {
  const { vehicleItems, drivers, disableHeading = false } = props;
  const driverItemList = makeDriverItemList(drivers || []);
  const { classes } = useStyles();

  return (
    <>
      {vehicleItems && (
        <GridItem topSpacing={10}>
          <ListItems
            className={classes.listPadding}
            items={vehicleItems}
            heading={disableHeading ? '' : 'Vehicles'}
          />
        </GridItem>
      )}
      {driverItemList && (
        <GridItem topSpacing={10}>
          <ListItems
            className={classes.listPadding}
            items={driverItemList}
            heading={disableHeading ? '' : 'Drivers'}
          />
        </GridItem>
      )}
    </>
  );
};
