import { createReducer } from '@reduxjs/toolkit';

import { setRatingCriteriaApiGetSuccess } from './actions';
import type { RatingCriteriaState } from './types';

const ratingCriteriaInitial: RatingCriteriaState = {
  ratingCriteriaList: {},
};

export const ratingCriteriaReducer = createReducer(ratingCriteriaInitial, (builder) => {
  builder
    .addCase(setRatingCriteriaApiGetSuccess, (state, { payload: { productKey, ...rest } }) => {
      state.ratingCriteriaList[productKey] = rest;
    })
    .addMatcher(
      (action): action is { type: '@global/teardownStore' } =>
        action.type === '@global/teardownStore',
      () => ratingCriteriaInitial,
    );
});
