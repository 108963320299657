import { TooltipWithIcon } from '@ecp/components';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const HistoricHomeQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const yearBuilt = useHomePropertyField('yearBuilt');
  const historicHome = useHomePropertyField('isHistoricHome');
  const { label = historicHome.question?.title, trackingName = 'HistoricHomeButton' } = props;
  const helpText = historicHome.question?.helpText;
  // TODO: Utilize hide expression from SAPI
  const displayHistoricHomeQuestion =
    yearBuilt.exists &&
    yearBuilt.props.value &&
    yearBuilt.props.value < 1941 &&
    historicHome.exists;

  if (!displayHistoricHomeQuestion) return null;

  return (
    <RadioGroupWithOptions
      {...historicHome.props}
      variant='yesNoButton'
      id='HistoricHome'
      trackingName={trackingName}
      trackingLabel={historicHome.props.value}
      label={
        <>
          {label}
          <TooltipWithIcon title={helpText} />
        </>
      }
    />
  );
};
