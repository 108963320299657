import { useCallback, useEffect, useMemo, useRef } from 'react';

import { setDimension, trackClick, TrackingDimensions } from '@ecp/utils/analytics/tracking';
import type { GeoAddress } from '@ecp/utils/geo';
import { useGeoAddressOptions, validateAndCombineAddress } from '@ecp/utils/geo';

import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { Address } from '@ecp/features/sales/shared/components';
import { PRIMARY_INSURED_ADDRESS_LOCK } from '@ecp/features/sales/shared/constants';
import {
  createAddressForUpdate,
  getZipLookupBypassed,
  updateAnswers,
  useField,
  useFieldWithPrefix,
  usePrimaryAddress,
  usePrimaryAddressRef,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

interface Props {
  setGeoAddressSuggestions(value: GeoAddress[]): void;
  geoAddressSuggestions: GeoAddress[];
}

const gaTrackSuggestionClick = (): void => {
  trackClick({ action: 'suggested_address', label: 'suggested_address' });
  trackSapiAnalyticsEvent({
    element: 'choice.personForm.smartyStreetSuggestion',
    event: 'click',
    eventDetail: 'true',
  });
};

export const AddressAutoCompleteQuestion: React.FC<Props> = (props) => {
  const { setGeoAddressSuggestions, geoAddressSuggestions } = props;
  const initAddress = usePrimaryAddress();
  const primaryAddressRef = usePrimaryAddressRef();
  const useAddressField = useFieldWithPrefix(primaryAddressRef, 'address.<id>');
  const line1 = useAddressField('line1');
  const line2 = useAddressField('line2');
  const city = useAddressField('city');
  const zipcode = useAddressField('zipcode');
  const state = useAddressField('state');
  const zipcodeBypassed = useSelector(getZipLookupBypassed);
  // In certain conditions zipcodeBypassed might be returned as undefined so we need to check whether it exists or not prior to diabling fields.
  const shouldDisableCityStateZip = zipcodeBypassed && !zipcodeBypassed;
  const {
    handleSuggestionsFetchRequested,
    handleSuggestionsClearRequested,
    handleAptSuggestionFetchRequested,
    selectedApt,
  } = useGeoAddressOptions();

  const initAddressFetchedHolder = useRef(false);

  const dispatch = useDispatch();

  const primaryInsuredAddressLock = useField(PRIMARY_INSURED_ADDRESS_LOCK);

  const autoCompleteSuggestions = useMemo(
    () =>
      geoAddressSuggestions.map((s: GeoAddress, index, arr) => {
        if (arr.length - 1 === index) {
          return s.street_line;
        }
        if (selectedApt) {
          return (
            s.street_line +
            (s.secondary !== ''
              ? ` ${s.secondary} ${s.city}, ${s.state} ${s.zipcode}`
              : ` ${s.city}, ${s.state} ${s.zipcode}`)
          );
        }

        return (
          s.street_line +
          (s.secondary !== ''
            ? ` ${s.secondary} (${s.entries} more entries) ${s.city}, ${s.state} ${s.zipcode}`
            : ` ${s.city}, ${s.state} ${s.zipcode}`)
        );
      }),
    [geoAddressSuggestions, selectedApt],
  );

  const handleSuggestionsFetch = useCallback(
    async (value: string): Promise<void> => {
      // We don't need to refetch if Apts are being displayed currently.
      if (!value.includes('Apt') || !value.includes('Ste')) {
        const output = await handleSuggestionsFetchRequested(value, zipcode.value as string, '');
        // Only update geoAddressSuggestions while fetch, not for clear.
        // This information is needed for validating address
        setGeoAddressSuggestions(output);
      }
    },
    [handleSuggestionsFetchRequested, setGeoAddressSuggestions, zipcode.value],
  );

  if (!initAddressFetchedHolder.current) {
    if (initAddress) {
      handleSuggestionsFetch(initAddress);
    }
    initAddressFetchedHolder.current = true;
  }

  const handlePrimaryAddressSelection = useCallback(
    async (value: string) => {
      // search array to find the value selected
      const addressToBeValidated = geoAddressSuggestions.find((addressVal: GeoAddress) => {
        let fullAddress;
        if (selectedApt) {
          fullAddress = `${addressVal.street_line} ${addressVal.secondary} ${addressVal.city}, ${addressVal.state} ${addressVal.zipcode}`;

          return fullAddress === value;
        }

        fullAddress =
          addressVal.street_line +
          (addressVal.secondary !== ''
            ? ` ${addressVal.secondary} (${addressVal.entries} more entries) ${addressVal.city}, ${addressVal.state} ${addressVal.zipcode}`
            : ` ${addressVal.city}, ${addressVal.state} ${addressVal.zipcode}`);

        return fullAddress === value;
      });
      // If selected address is just a house
      if (addressToBeValidated) {
        if (addressToBeValidated.entries <= 1) {
          const inputAddress = {
            street: addressToBeValidated.street_line,
            city: addressToBeValidated.city,
            state: addressToBeValidated.state,
            street2: addressToBeValidated.secondary !== '' ? addressToBeValidated.secondary : '',
            zipcode: addressToBeValidated.zipcode,
          };
          const newAddress = await validateAndCombineAddress(inputAddress, primaryAddressRef);
          // TODO - Confirm whether the address needs to be validated with SAPI validator
          if (newAddress) {
            line1.props.actionOnChange(newAddress.line1);
            line2.props.actionOnChange(newAddress.line2);
            const addressToBeSaved = createAddressForUpdate(newAddress);
            // This is cumbersome as the form state value always trump over answer value
            // It seems no clear way to reset form state after answers patch is done
            city.props.actionOnChange(newAddress.city);
            state.props.actionOnChange(addressToBeSaved[state.key]);
            zipcode.props.actionOnChange(newAddress.zipcode);
            await dispatch(
              updateAnswers({
                answers: { ...addressToBeSaved },
              }),
            );
          }
        } else {
          line1.props.actionOnComplete(`${addressToBeValidated.street_line}`);
          if (selectedApt) {
            const inputAddress = {
              street: addressToBeValidated.street_line,
              city: addressToBeValidated.city,
              state: addressToBeValidated.state,
              street2: addressToBeValidated.secondary !== '' ? addressToBeValidated.secondary : '',
              zipcode: addressToBeValidated.zipcode,
            };
            const newAddress = await validateAndCombineAddress(inputAddress, primaryAddressRef);
            if (newAddress) {
              const addressToBeSaved = createAddressForUpdate(newAddress);
              await dispatch(
                updateAnswers({
                  answers: { ...addressToBeSaved },
                }),
              );
            }
          }
          // need to pass selected address as well as the letter A to limit the results to number of valid entries recieved for address.
          // Will return # of entries whose secondary value starts with A.
          const selectedValue = `${addressToBeValidated.street_line} ${addressToBeValidated.secondary} A (${addressToBeValidated.entries}) ${addressToBeValidated.city} ${addressToBeValidated.state} ${addressToBeValidated.zipcode}`;
          // If selected address is a series of apartments
          const fetchNewAptSuggestions = await handleAptSuggestionFetchRequested(
            addressToBeValidated.street_line,
            selectedValue,
          );
          setGeoAddressSuggestions(fetchNewAptSuggestions);
        }
      } else {
        line1.props.actionOnComplete(value);
      }
    },
    [
      dispatch,
      primaryAddressRef,
      line1.props,
      line2.props,
      city.props,
      state.props,
      state.key,
      zipcode,
      geoAddressSuggestions,
      handleAptSuggestionFetchRequested,
      setGeoAddressSuggestions,
      selectedApt,
    ],
  );

  useEffect(() => {
    return () => {
      setDimension(TrackingDimensions.ZIP, zipcode.props.value);
      setDimension(TrackingDimensions.STATE, state.props.value);
    };
  }, [state.props.value, zipcode.props.value]);

  if (!initAddressFetchedHolder.current) {
    if (initAddress) {
      handleSuggestionsFetch(initAddress);
    }
    initAddressFetchedHolder.current = true;
  }
  useAddFields({ line1, line2, city, state, zipcode });
  useAddConditionValues({
    conditionalFields: [line1, city, state, zipcode],
    isRequiredOverride: () => true,
  });

  return (
    <Address
      line1={line1}
      line2={line2}
      city={city}
      state={state}
      zip={zipcode}
      disableCity={shouldDisableCityStateZip}
      disableState={shouldDisableCityStateZip}
      disableZip={shouldDisableCityStateZip}
      geoAddressFormattedSuggestions={geoAddressSuggestions}
      selectedApt={selectedApt}
      autoCompleteSuggestions={autoCompleteSuggestions}
      handleSuggestionsClearRequested={handleSuggestionsClearRequested}
      handleSuggestionsFetchRequested={handleSuggestionsFetch}
      handleSuggestionSelected={handlePrimaryAddressSelection}
      gaTrackSuggestionClick={gaTrackSuggestionClick}
      disabled={primaryInsuredAddressLock.props.value}
    />
  );
};
