import { isTruthy } from '@ecp/utils/common';

import type { AmeripriseAdvisor } from '@ecp/features/sales/shared/ameriprise-advisor/types';

const composeAdvisorName = (
  agrs: Pick<AmeripriseAdvisor, 'firstName' | 'middleName' | 'lastName'>,
): string => [agrs.firstName, agrs.middleName, agrs.lastName].filter(isTruthy).join(' ');

export const composeAdvisorValue = (
  agrs: Pick<AmeripriseAdvisor, 'firstName' | 'middleName' | 'lastName' | 'stateCode'>,
): string => {
  const advisorName = composeAdvisorName(agrs);
  const stateCode = agrs.stateCode?.slice(-2);
  const result = [advisorName, stateCode].filter(isTruthy).join(', ');

  return result;
};
