import { useEvent } from '@ecp/utils/react';

import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useGetProductFields } from '@ecp/features/sales/shared/store';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import type { LineOfBusiness } from '@ecp/features/shared/product';

import metadata from './metadata';
import { useStyles } from './ProductSelectQuestion.styles';

interface Props extends QuestionProps {
  disabled?: boolean;
}

export const ProductSelectQuestion: React.FC<Props> = (props) => {
  const {
    label = metadata.label,
    trackingName = 'GetProductSelectQuestion',
    formType,
    disabled,
  } = props;

  const { classes } = useStyles();

  const { product } = useGetProductFields();

  const actionOnComplete = useEvent(async (value: LineOfBusiness) => {
    product.props.actionOnComplete(value).then(() => {
      trackSapiAnalyticsEvent({
        element: `choice.${formType}GetProductSelectQuestion.productType`,
        event: 'select',
        eventDetail: value,
      });
    });
  });

  return (
    <RadioGroupWithOptions
      {...product.props}
      name='productQuote'
      label={label}
      options={metadata.options}
      value={product.value}
      actionOnChange={actionOnComplete}
      trackingName={trackingName}
      variant='radioCard'
      id='productSelectQuote'
      disabled={disabled}
      className={classes.radioButtonContainer}
      row
    />
  );
};
