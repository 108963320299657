import type { PropertyProduct } from '@ecp/features/shared/product';

export interface Metadata {
  hideDisclaimers?: boolean;
  getSocialProofDisclaimerText?: (
    propertyOfferProduct?: PropertyProduct,
    stateCode?: string,
  ) => string | undefined;
}

const metadata: Metadata = {};

export default metadata;
