import { ensureStringArray, isStringArray } from '@ecp/utils/common';

import { HEATING_SOURCE_REF } from '@ecp/features/sales/quotes/property/home';
import { POLICY_VEHICLE_REF } from '@ecp/features/sales/shared/constants';
import type { Answers } from '@ecp/features/sales/shared/types';

import { getAllValues } from '../../form/selectors';
import { wrapThunkActionWithErrHandler } from '../../util/wrapThunkActionWithErrHandler';
import { deleteAnswers } from './deleteAnswers';
import { updateAnswers } from './updateAnswers';

type UpdateRemovedRef = {
  allValues?: Answers;
  refType: string;
  removedRef: string;
};

export const updateRemovedRef = wrapThunkActionWithErrHandler<UpdateRemovedRef>(
  ({ refType, removedRef }) =>
    async (dispatch, getState) => {
      const allValues = getAllValues(getState());
      if (isStringArray(allValues[refType])) {
        const existingRefs: string[] = ensureStringArray(allValues[refType]);
        const newRefs = existingRefs.filter((ref) => ref !== removedRef);
        // TODO: remove this key-specific check once we have determined the impact of this change on the other functions that call `updateRemoveRef`
        // for the `vehicle.ref` array, if the refs are now empty (equal to '[]'), we want to remove the reference entirely from the answers.
        // see https://theexperimentationlab.atlassian.net/browse/CPUI-1539 for more information
        if ((refType === POLICY_VEHICLE_REF || refType === HEATING_SOURCE_REF) && !newRefs.length) {
          await dispatch(deleteAnswers({ ref: refType }));
        } else {
          await dispatch(
            updateAnswers({
              answers: { [refType]: newRefs },
            }),
          );
        }
      } else {
        // If this is not a ref that is a string array. We delete the answer for the ref type.
        await dispatch(deleteAnswers({ ref: refType }));
      }
    },
  'updateRemovedRef',
);
