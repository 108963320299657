import { datadogLog } from '@ecp/utils/logger';

import { getPaymentOptionsAndAcksForCheckout } from '@ecp/features/sales/checkout';
import { NavStatus } from '@ecp/features/sales/shared/constants';
import type { ThunkAction } from '@ecp/features/sales/shared/store/types';

import { getDalSessionId, getUserSelection } from '../inquiry/selectors';
import { updatePageStatus } from '../nav/thunks';
import { setRecalled } from './actions';
import {
  getAreSomeSelectedProductsIndicative,
  getIsAnyPolicyExpired,
  getOfferProductsSelected,
  getOfferSetId,
  getOffersExist,
  getOffersForSelectedLob,
} from './selectors';

// express path means that when doing bridging over, go to checkout page directly
export const prepareCheckoutPageState: () => ThunkAction<Promise<void>> =
  () => async (dispatch, getState) => {
    const offersForSelectedLob = getOffersForSelectedLob(getState());
    const doOffersForSelectedLobExist = Boolean(offersForSelectedLob);
    const dalSessionId = getDalSessionId(getState());
    const offerSetId = getOfferSetId(getState());
    const offerProductsSelected = getOfferProductsSelected(getState());
    const userSelection = getUserSelection(getState());
    const areSomeSelectedProductsIndicative = getAreSomeSelectedProductsIndicative(getState());

    if (
      !doOffersForSelectedLobExist ||
      !offerSetId ||
      !userSelection ||
      !offerProductsSelected.length
    ) {
      datadogLog({
        logType: 'error',
        message: 'Missing required data for checkout page',
        context: {
          logOrigin: 'libs/features/sales/shared/store/lib/src/offers/expressPath.ts',
          functionOrigin: 'prepareCheckoutPageState',
        },
      });

      throw new Error('Missing required data for checkout page');
    }

    dispatch(updatePageStatus(NavStatus.VALID));

    if (!areSomeSelectedProductsIndicative) {
      await dispatch(
        getPaymentOptionsAndAcksForCheckout({
          dalSessionId,
          products: offerProductsSelected,
        }),
      );
    }

    const offersExist = getOffersExist(getState());
    if (offersExist) {
      dispatch(setRecalled(true));
      const isAnyPolicyExpired = getIsAnyPolicyExpired(getState());
      if (isAnyPolicyExpired) {
        datadogLog({
          logType: 'error',
          message: 'policy expired',
          context: {
            logOrigin: 'libs/features/sales/shared/store/lib/src/offers/expressPath.ts',
            functionOrigin: 'prepareCheckoutPageState',
          },
        });

        throw new Error('policy expired');
      }
    }
  };
