import { Grid } from '@mui/material';

import { getPolicyEfectiveDateParam } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';

import { useStyles } from './PolicyStartDateForm.styles';
import { getStartDateFieldText } from './util';

export const PolicyText = ({
  product,
  inDrawer,
}: {
  product: Product;
  inDrawer: boolean;
}): React.ReactNode => {
  const { classes, cx } = useStyles({
    inDrawer,
  });
  const policyEffectiveDateParam = useSelector(getPolicyEfectiveDateParam);

  return (
    <Grid item xs={12}>
      <p className={cx(inDrawer && classes.policyTextTest)}>
        {getStartDateFieldText(product, policyEffectiveDateParam)}
      </p>
    </Grid>
  );
};
