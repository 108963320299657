import { useCallback } from 'react';

import { env } from '@ecp/env';
import { useNavigateToNextPage, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import { getLineOfBusiness, makeSurePrefillFlowInStore } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { PersonAddressPage as PersonAddressPageBase } from '@ecp/features/sales/shell';
import { LineOfBusiness } from '@ecp/features/shared/product';

import metadata from './metadata';

export const PersonAddressPage: React.FC = () => {
  const dispatch = useDispatch();
  const lineOfBusiness = useSelector(getLineOfBusiness);
  const navigateToNextConfigPage = useNavigateToNextPage();
  const navigateToNextPage = useNavigateToPage(metadata.nextPage);
  const handleNext = useCallback(async (): Promise<void> => {
    if (metadata.shouldCallPrefill) await dispatch(makeSurePrefillFlowInStore());
    if (env.static.personAddressPageIsLastIntroPage && lineOfBusiness === LineOfBusiness.HOME)
      await navigateToNextConfigPage();
    else await navigateToNextPage();
  }, [dispatch, lineOfBusiness, navigateToNextConfigPage, navigateToNextPage]);

  return <PersonAddressPageBase onNext={handleNext} />;
};
