import type { SyntheticEvent } from 'react';
import { Children, cloneElement, useState } from 'react';

import type { AccordionProps } from '@mui/material';
import { Grid } from '@mui/material';

import { useStyles } from './Accordion.styles';

export interface Props {
  spacing?: number;
  onChange?: (event: SyntheticEvent<Element, Event>, isExpanded: boolean) => void;
}

export const Accordion: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { children, onChange } = props;
  const { classes } = useStyles(props);
  const [expanded, setExpanded] = useState<number | false>(false);
  const handleChange =
    (index: number) => (event: SyntheticEvent<Element, Event>, isExpanded: boolean) => {
      if (onChange) onChange(event, isExpanded);
      setExpanded(isExpanded ? index : false);
    };

  return (
    <Grid container className={classes.root}>
      {Children.map(
        children as React.ReactElement<AccordionProps>[],
        (child: React.ReactElement<AccordionProps>, index) =>
          cloneElement(child, {
            expanded: child.props.expanded ?? expanded === index,
            onChange: handleChange(index),
          }),
      )}
    </Grid>
  );
};
