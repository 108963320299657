import { wrapThunkActionWithErrHandler } from '../../util/wrapThunkActionWithErrHandler';
import { deleteAnswers } from './deleteAnswers';
import { updateAnswers } from './updateAnswers';
import { updateRemovedRef } from './updateRemovedRef';

type RemoveAndDeleteRef = {
  refType: string;
  ref: string;
};

type RemoveAndDeleteAllRefs = {
  refType: string;
  refPrefix: string;
};

export const removeAndDeleteRef = wrapThunkActionWithErrHandler<RemoveAndDeleteRef>(
  ({ refType, ref }) =>
    async (dispatch) => {
      if (!ref) return;
      await Promise.all([
        dispatch(updateRemovedRef({ refType: refType, removedRef: ref })),
        dispatch(deleteAnswers({ ref })),
      ]);
    },
  'removeAndDeleteRef',
);

export const removeAndDeleteAllRefs = wrapThunkActionWithErrHandler<RemoveAndDeleteAllRefs>(
  ({ refType, refPrefix }) =>
    async (dispatch) => {
      if (!refPrefix) return;
      await Promise.all([
        dispatch(updateAnswers({ answers: { [refType]: [] } })),
        dispatch(deleteAnswers({ ref: refPrefix })),
      ]);
    },
  'removeAndDeleteAllRefs',
);
