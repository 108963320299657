import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CheckoutFormBody' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    ...theme.mixins.form,
    [theme.breakpoints.down('md')]: {
      padding: '0 15px',
    },
  },
  checkoutSectionContainer: {
    width: '100%',
  },
  agentCheckoutSectionContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      minWidth: 768,
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: 740,
    },
  },
  next: theme.mixins.nextButton,
  confirmLabel: theme.typography.body4,
  membershipDetailsContainer: {
    [theme.breakpoints.down('md')]: {
      margin: '30px 0',
    },
  },
  policySummaryContainer: {
    [theme.breakpoints.down('md')]: {
      margin: '0 0 30px 0',
    },
  },
  checkoutPageTitle: {
    borderBottom: `1px solid ${theme.palette.other.divider}`,
    paddingBottom: 10,
    paddingTop: 30,
    marginBottom: 34,
  },
  provideLabel: {
    ...theme.typography.body4,
    padding: '40px 0 15px 0',
  },
}));
