import { useState } from 'react';

import { Collapse, Divider, Grid } from '@mui/material';

import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { useEffectOnce, useEvent } from '@ecp/utils/react';

import { useGetVehicleItemsWithImage } from '@ecp/features/sales/quotes/auto';
import { Button, DriverIcon } from '@ecp/features/sales/shared/components';
import { getDrivers } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AutoProduct } from '@ecp/features/shared/product';
import { IconUIExpandLess, IconUIExpandMore } from '@ecp/themes/base';

import type { DiscountMetadata } from './DiscountCard';
import { DiscountCard } from './DiscountCard';
import { useStyles } from './VehicleAndDriverDiscounts.styles';

interface Props {
  autoProductKey: AutoProduct;
  isMobile: boolean;
  applicableVehicleDiscounts: DiscountMetadata[];
  applicableDriverDiscounts: DiscountMetadata[];
}

export const VehicleAndDriverDiscounts: React.FC<Props> = (props) => {
  const { classes } = useStyles();

  const { autoProductKey, isMobile, applicableVehicleDiscounts, applicableDriverDiscounts } = props;

  const drivers = useSelector(getDrivers);

  const vehiclesWithImage = useGetVehicleItemsWithImage();

  const [open, setOpen] = useState(true);

  const coveragePageRedesignVariant = flagValues[FeatureFlags.COVERAGE_PAGE_REDESIGN_VARIANTS];

  useEffectOnce(() => {
    if (coveragePageRedesignVariant && open) {
      setOpen(false);
    } else if (isMobile && !open) {
      setOpen(true);
    }
  }, []);

  const ExpandIcon = open ? IconUIExpandLess : IconUIExpandMore;

  const handleExpandDiscounts = useEvent(() => {
    setOpen(!open);
  });

  const title = coveragePageRedesignVariant ? (
    <Button
      variant='iconTextLarge'
      className={classes.expandDiscounts}
      onClick={handleExpandDiscounts}
      icon={<ExpandIcon />}
      iconLast
      fullWidth
    >
      {open ? 'Hide' : 'Show'} vehicle and driver discounts
    </Button>
  ) : (
    <h4 className={classes.title}>Driver & vehicle specific discounts</h4>
  );

  return (
    <div className={classes.root}>
      {title}
      <Collapse
        in={open}
        className={classes.discountContainer}
        data-testid='coveragePageDiscountContainer'
      >
        <Grid container direction='row'>
          {(applicableVehicleDiscounts.length > 0 || applicableDriverDiscounts.length > 0) &&
            vehiclesWithImage.map((vehicle, index) => {
              return (
                <div key={vehicle.vehicle.ref} className={classes.discountCardContainer}>
                  {index !== 0 && (index || !isMobile) && (
                    <Divider aria-hidden='true' className={classes.divider} />
                  )}
                  <Grid container item className={classes.discountGrid}>
                    <DiscountCard
                      discounts={applicableVehicleDiscounts}
                      autoProductKey={autoProductKey}
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      objRef={vehicle.vehicle.ref!}
                      title={vehicle.vehicle.description}
                      image={
                        <img
                          src={vehicle.imageUrl}
                          className={classes.vehicleIcon}
                          alt={vehicle.vehicle.description}
                        />
                      }
                    />
                  </Grid>
                </div>
              );
            })}
          {(applicableDriverDiscounts.length > 0 || applicableVehicleDiscounts.length > 0) &&
            drivers.map((driver, index) => {
              return (
                <div key={driver.ref} className={classes.discountCardContainer}>
                  {!(applicableVehicleDiscounts.length === 0 && index === 0) && (
                    <Divider aria-hidden='true' className={classes.divider} />
                  )}
                  <Grid container item className={classes.discountGrid}>
                    <DiscountCard
                      discounts={applicableDriverDiscounts}
                      autoProductKey={autoProductKey}
                      objRef={driver.ref}
                      title={`${driver.firstName} ${driver.lastName}`}
                      image={<DriverIcon index={index} />}
                    />
                  </Grid>
                </div>
              );
            })}
        </Grid>
      </Collapse>
    </div>
  );
};
