import { memo } from 'react';

import { Grid } from '@mui/material';

import { Card } from '@ecp/components';
import { ChatHelp } from '@ecp/features/sales/shared/chat';
import { PhoneLink } from '@ecp/features/sales/shared/components';
import { partner } from '@ecp/partners';
import { GraphicUIAgentAvatarImageUrl } from '@ecp/themes/base';

import { Header } from './Header';
import { useStyles } from './HelpCard.styles';
import metadata from './metadata';

export interface HelpCardProps {
  phoneNumberTrackingValue?: string;
  chatTrackingValue?: string;
}

export const HelpCard: React.FC<HelpCardProps> = memo((props) => {
  const { classes } = useStyles();
  const { phoneNumberTrackingValue, chatTrackingValue } = props;

  const content = (
    <div className={classes.content}>
      {metadata.showAgentImage && (
        <Grid container className={classes.askExpertGrid}>
          <Grid item xs={3}>
            <img
              src={GraphicUIAgentAvatarImageUrl}
              alt='Expert agent'
              data-testid='expertAgentIcon'
              className={classes.helpImage}
            />
          </Grid>
          <Grid item xs={9}>
            <h4 className={classes.header}>Licensed, expert agents can help.</h4>
          </Grid>
        </Grid>
      )}
      <div data-testid='sidePhoneLink'>
        <PhoneLink
          withIcon={metadata.showPhoneIcon}
          number={partner.shared.salesPhoneNumber}
          trackingName={phoneNumberTrackingValue}
          trackingLabel={phoneNumberTrackingValue}
          withLinkStyle={!metadata.withUnderlineLinkStyle}
          withUnderlinedLinkStyle={metadata.withUnderlineLinkStyle}
          variant='small'
        />
      </div>
      <div ata-testid='sideChatHelp'>
        <ChatHelp
          displayText='Live chat'
          trackingLabel={chatTrackingValue}
          trackingName={chatTrackingValue}
          variant='iconTextSmall'
        />
      </div>
    </div>
  );

  return (
    <div className={classes.cardWrapper}>
      <Card
        classes={{ root: classes.card }}
        header={<Header />}
        body={content}
        headerDivider
        divider={false}
      />
    </div>
  );
});
