import { useEffect, useState } from 'react';

import { useEffectOnce } from '@ecp/utils/react';
import { location } from '@ecp/utils/routing';

import metadata from './metadata';

export const useShowGlobalLoadingWhenRetrieve = (
  sessionCompleted: boolean,
): { showGlobalLoading: boolean } => {
  const [showGlobalLoading, setShowGlobalLoading] = useState(false);

  useEffectOnce(() => {
    if (metadata.showGlobalLoadingDuringBlobRetrieve && location.search.retrieve) {
      setShowGlobalLoading(true);
    }
  });

  useEffect(() => {
    if (sessionCompleted && showGlobalLoading) {
      setShowGlobalLoading(false);
    }
  }, [sessionCompleted, showGlobalLoading]);

  return {
    showGlobalLoading,
  };
};
