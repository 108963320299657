import { makeStyles } from '@ecp/themes/base';

const circleDiameter = 17;
const iconMarginRight = 15;

export const useStyles = makeStyles<void, 'completeCircle'>({ name: 'ProductStepper' })(
  (...[theme, , classes]) => ({
    circle: {
      borderRadius: '50%',
      borderStyle: 'solid',
      borderWidth: 0,
      width: circleDiameter,
      height: circleDiameter,
      color: theme.palette.primary.main,
      [`&.${classes.completeCircle}`]: {
        color: theme.palette.primary.main,
      },
    },
    notVisitedCircle: {
      borderColor: theme.palette.text.disabled,
      borderWidth: 2,
    },
    incompleteCircle: {
      borderColor: theme.palette.primary.main,
      borderWidth: 5,
    },
    completeCircle: {},
    noConnector: {
      display: 'none',
      padding: 0,
    },
    active: {
      ...theme.typography.body1Bold,
      textDecoration: 'none',
      '&.MuiStepLabel-label': {
        color: theme.palette.primary.main,
      },
    },
    inActive: {
      color: theme.palette.text.disabled,
    },
    completed: {
      color: theme.palette.text.primary,
      cursor: 'pointer',
    },
    stepContent: {
      ...theme.typography.body1,
      margin: 0,
      padding: 0,
      borderLeft: `1px solid ${theme.palette.primary.main}`,
      // Subtract 1 for border width
      marginLeft: (circleDiameter - 1) / 2,
      paddingLeft: (circleDiameter - 1) / 2 + iconMarginRight,
    },
    inactiveStepContent: {
      borderColor: theme.palette.text.disabled,
    },
    content: {
      color: theme.palette.text.secondary,
      fontSize: 12,
      lineHeight: '18px',
      fontWeight: 'normal',
    },
    subMenuLink: {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    iconContainer: {
      padding: 0,
      marginRight: iconMarginRight,
    },
    divider: {
      margin: '15px 0px',
    },
  }),
);
