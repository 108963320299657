import { GridItem } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { Select, TextField } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const PrimaryHeatSourceFuelTypeQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const fuelType = useHomePropertyField('heating.primaryHeatSourceFuelType');
  const primaryHeatingType = useHomePropertyField('heating.primaryType');
  const primaryHeatingFuelTypeDescription = useHomePropertyField(
    'heating.otherPrimaryHeatSourceFuelTypeDescription',
  );

  const {
    groupLabel = fuelType.question?.title,
    trackingName = 'primary_heating_type_dropdown',
    trackingLabel = 'primary_heating_type_other_description',
    label = primaryHeatingFuelTypeDescription.question?.title,
  } = props;

  useAddFields({ fuelType, primaryHeatingFuelTypeDescription });

  if (!fuelType.exists || !['CENTRAL', 'AREA'].includes(primaryHeatingType.value)) return null;

  return (
    <>
      <Select
        {...fuelType.props}
        id='primaryHeatingFuelType'
        groupLabel={groupLabel}
        trackingName={trackingName}
        inputButtonAriaLabel='primaryHeatingFuelType'
      />
      {primaryHeatingFuelTypeDescription.exists && fuelType.value === 'OTHER_EXT' && (
        <GridItem topSpacing='lg' xs={12}>
          <TextField
            {...primaryHeatingFuelTypeDescription.props}
            label={label}
            trackingName={trackingLabel}
          />
        </GridItem>
      )}
    </>
  );
};
