import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ContactInformationFormQuestions' })((theme) => ({
  informationCard: {
    backgroundColor: theme.palette.grey[100],
    borderTop: `4px solid ${theme.palette.primary.main}`,
    marginTop: 20,
    padding: '0px 20px 20px 20px',
  },
  headerContainer: {
    display: 'flex',
  },
  headerContainerTitle: {
    ...theme.typography.h3,
    marginTop: 20,
    marginBottom: 5,
  },
}));
