import type { Product } from '@ecp/features/shared/product';
import type { OptionsMetadata } from '@ecp/types';

export const CoveragePolicyLevelDiscountsMetadata: Record<Product, OptionsMetadata> = {
  'amfam-adv.auto': {},
  'amfam-adv.home': {},
  'amfam-adv.renters': {},
  'connect.auto': {
    MultiPolicy: {
      title: 'Multi product discount',
    },
    MultiVehicle: {
      title: 'Multi vehicle',
    },
    Internet: {
      title: 'Internet discount',
    },
    FullCoverage: {
      title: 'Full coverage',
    },
    PremierSafety: {
      title: 'Premier safety',
    },
    FullPay: {
      title: 'Full pay',
    },
    Affinity: {
      title: 'Ameriprise Advisor Affinity',
    },
    GoodDriver: {
      title: 'Good driver',
    },
    DaytimeRunningLights: {
      title: 'Daytime Running Lights',
      stateOptions: {
        NY: {
          secondaryText:
            'Factory installed lights that are continuously on while the vehicle is running. There is not an option to turn them off.',
        },
      },
    },
    VehicleRecoverySystem: {
      title: 'Vehicle Recovery System',
      stateOptions: {
        NY: {
          secondaryText:
            "An electronic device installed in a vehicle to enable the police or a monitoring company to track the vehicle's location in the event it is stolen or involved in an accident.",
        },
      },
    },
    WindowGlassEtching: {
      title: 'Window Glass Etching',
      stateOptions: {
        NY: {
          secondaryText:
            'Requires the completed manufacturers VIN or similar identification be etched by a tool which will not destroy the integrity of the glass into at least the windshield, door glass and/or rear glass.',
        },
      },
    },
    DISCOUNT_HOMEOWNER: {
      title: 'Home Ownership',
      stateOptions: {
        NY: {
          secondaryText:
            'If you own your home, you may qualify for a discount. Any available discounts will be calculated into your quote.',
        },
      },
    },
  },
  'amfam.auto': {
    multiVehicle: {
      title: 'Multi vehicle',
    },
    midvaleHomePolicy: {
      title: 'Multi Product',
    },
    partnerDiscount: {
      title: 'Partner discount',
    },
    fullPayment: {
      title: 'Full pay',
    },
    homeOwnership: {
      title: 'Home ownership',
    },
    autoPay: {
      title: 'Automatic payments',
    },
    paperless: {
      title: 'Paperless',
    },
    accidentFree: {
      title: 'Accident free',
    },
    earlyBird: {
      title: 'Early bird',
    },
    lowMileage: {
      title: 'Low mileage',
    },
    steerIntoSavings: {
      title: 'Steer into savings',
    },
    violationFree: {
      title: 'Violation free',
    },
    autoSafety: {
      title: 'Auto Safety Equipment',
    },
    seniorDriver: {
      title: 'Senior Driver',
    },
    goodDriving: {
      title: 'Good Driver',
    },
    antiTheft: {
      title: 'Anti-theft',
    },
    incomeLossExclusion: {
      title: 'Income loss exclusion',
      stateOptions: {
        MN: {
          secondaryText: `State mandated discount given to Named Insureds who opt to exclude Income Loss Coverage for themselves on their policy. Must be over age 65, or are between age 60 and 64, retired and receiving a pension.`,
        },
        MI: {
          title: 'Work Loss Benefit',
          secondaryText: `Available to named insured(s) age 60 or older who have rejected work loss benefits under Personal Injury Protection coverage`,
        },
        NY: {
          title: 'No Fault Work Loss Reduction',
          secondaryText: `You qualify for a discount if you or a member of your household opts to select a Wage Continuation Program for your primary PIP coverage`,
        },
      },
    },
    workLossReduction: {
      title: 'Work Loss Reduction',
      stateOptions: {
        NY: {
          title: 'No Fault Work Loss Reduction',
        },
      },
    },
    airbag: {
      title: 'Airbags',
      subHeaderText: 'Which airbags?',
      fieldType: 'radiogroup',
      stateOptions: {
        NC: {
          title: 'North Carolina Airbag Discount',
          secondaryText: `You qualified for this discount because you drive a vehicle with a driver-side or passenger-side airbag.`,
        },
      },
    },
    antiLockBrakes: {
      title: 'Anti-lock brakes',
      stateOptions: {
        NY: {
          title: 'Anti-Lock Brakes',
          secondaryText: 'You qualify for a discount for driving a vehicle with anti-lock brakes',
        },
      },
    },
    dayTimeRunningLights: {
      title: 'Daytime Running Lights',
      stateOptions: {
        NY: {
          secondaryText:
            'You qualify for a discount for driving a vehicle with daytime running lights',
        },
      },
    },
    passiveSeatBelt: {
      title: 'NY Passive Restraint Device',
      secondaryText: 'You qualify for a discount for driving a vehicle with passive restraints',
    },
  },
  'homesite.home': {
    responsibleMotoristDiscount: {
      title: 'Responsible motorist discount',
    },
    safetyFirstDiscount: {
      title: 'Safety first discount',
    },
    welcomeHomeDiscount: {
      title: 'Welcome home discount',
    },
    newConstructionDiscount: {
      title: 'New construction discount',
    },
    premiumTaxCreditPrivateFireCompany: {
      title: 'Premium tax credit - private fire company',
    },
    roofitOverDiscount: {
      title: 'Roof it over discount',
    },
    multiProductDiscount: {
      title: 'Multi product discount',
    },
    responsibleHomeownerDiscount: {
      title: 'Responsible homeowner discount',
    },
    newElectricalSystemDiscount: {
      title: 'New electrical system discount',
    },
    packageDiscount: {
      title: 'Package discount',
    },
    secureHomeDiscount: {
      title: 'Secure home discount',
    },
    driveHomeDiscount: {
      title: 'Drive home discount',
    },
  },
  'homesite.renters': {
    multiProductDiscount: {
      title: 'Multi product discount',
    },
    driveHomeDiscount: {
      title: 'Drive home discount',
    },
  },
  'connect-boost.auto': {},
};
