import { getAnswersFormattedDateAfterDays, isDate } from '@ecp/utils/date';
import type { Answers } from '@ecp/utils/validator';

import {
  AUTO_POLICY_START_DATE,
  HOME_POLICY_START_DATE,
  POLICY_EFFECTIVE_DATE_PARAM,
  RENTERS_POLICY_START_DATE,
} from '@ecp/features/sales/shared/constants';
import { getLineOfBusinessFromQuery, LineOfBusiness } from '@ecp/features/shared/product';

import type { PolicyEffectiveDateAdjustor } from '../metadata/metadata';

const getAutoPolicyEffectiveStartDateAnswers = (effectivePolicyStartDate: string): Answers => {
  const autoPolicyStartDateAnswers = { [AUTO_POLICY_START_DATE]: effectivePolicyStartDate };

  // TODO unable to add dispatch here, its not a react component. if we want dispatch we need to pass from where we calling initializeUtil.

  // if patching the variant days, then we dont need to initialize the start date for autoPolicyEffectiveDefaultDate,
  // otherwise use effectivePolicyStartDate, this is part of the A/B testing
  const answers = autoPolicyStartDateAnswers;

  return answers;
};

const getHomePolicyEffectiveStartDateAnswers = (effectivePolicyStartDate: string): Answers => {
  return { [HOME_POLICY_START_DATE]: effectivePolicyStartDate };
};

const shouldIgnorePolicyEffectiveDateQueryParam = (
  policyEffectiveDateQueryParam: string | undefined,
  productQueryParam: string | undefined,
  shouldGetPolicyEffectiveDateFromQueryParam: boolean,
): boolean => {
  if (!policyEffectiveDateQueryParam) return true;
  if (!shouldGetPolicyEffectiveDateFromQueryParam) return true;
  if (!productQueryParam) return true;
  if (!isDate(policyEffectiveDateQueryParam, 'YYYY-MM-DD')) return true;

  const lineOfBusiness = getLineOfBusinessFromQuery(productQueryParam);
  if (lineOfBusiness !== LineOfBusiness.RENTERS) return true;

  return false;
};

export const getRentersPolicyEffectiveStartDateAnswers = (
  policyEffectiveDateQueryParam: string | undefined,
  productQueryParam: string | undefined,
  shouldGetPolicyEffectiveDateFromQueryParam: boolean,
  adjustor: PolicyEffectiveDateAdjustor | undefined,
): Answers => {
  if (
    shouldIgnorePolicyEffectiveDateQueryParam(
      policyEffectiveDateQueryParam,
      productQueryParam,
      shouldGetPolicyEffectiveDateFromQueryParam,
    )
  )
    return {};
  if (!adjustor) return {};

  return {
    [RENTERS_POLICY_START_DATE]: adjustor(policyEffectiveDateQueryParam as string),
    [POLICY_EFFECTIVE_DATE_PARAM]: policyEffectiveDateQueryParam as string,
  };
};

export const createDefaultAutoAndHomePolicyEffectiveStartDate = (): string => {
  // Policy start date defaulted to +29 days from current date
  return getAnswersFormattedDateAfterDays(29);
};

export const createPolicyEffectiveStartDateAnswers = (
  policyEffectiveDateQueryParam: string | undefined,
  productQueryParam: string | undefined,
  shouldGetPolicyEffectiveDateFromQueryParam: boolean,
  adjustors?: {
    renters?: PolicyEffectiveDateAdjustor;
  },
): Answers => {
  const autoAndHomeEffectivePolicyStartDate = createDefaultAutoAndHomePolicyEffectiveStartDate();

  return {
    ...getAutoPolicyEffectiveStartDateAnswers(autoAndHomeEffectivePolicyStartDate),
    ...getHomePolicyEffectiveStartDateAnswers(autoAndHomeEffectivePolicyStartDate),
    ...getRentersPolicyEffectiveStartDateAnswers(
      policyEffectiveDateQueryParam,
      productQueryParam,
      shouldGetPolicyEffectiveDateFromQueryParam,
      adjustors?.renters,
    ),
  };
};
