import { useCallback } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem, NumberFormat } from '@ecp/components';
import {
  useAddFields,
  useAddFieldsCallback,
  useRemoveByFieldsCallback,
} from '@ecp/features/sales/form';
import { RadioGroupWithOptions, Select, TextField } from '@ecp/features/sales/shared/components';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { OptionProps } from '@ecp/features/sales/shared/types';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import { clearFields } from '@ecp/features/sales/shared/utils/web';
import { useIsMobile } from '@ecp/themes/base';

import { deleteKeysFromPostBindAnswers, getHasPostBindSubmitError } from '../../../../state';
import {
  useAdditionalInterest,
  useAdditionalInterestAddressRef,
  useAdditionalInterestFields,
} from '../../../../util';
import { useStyles } from './AdditionalInterest.styles';
import { AdditionalInterestError } from './AdditionalInterestError';
import metadata from './metadata';

export const AdditionalInterest: React.FC = () => {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const addFields = useAddFieldsCallback();
  const removeByFields = useRemoveByFieldsCallback();

  const additionalInterestAddressRef = useAdditionalInterestAddressRef();
  const { allFields } = useAdditionalInterestFields();
  const addAddtionalInterest = useAdditionalInterest();

  const { line1, line2, city, state, zipcode, email, name, exists } = allFields;

  const existsAdditionalInterest = !!exists.value;

  useAddFields(existsAdditionalInterest ? allFields : {});

  const hasPostBindSubmitError = useSelector(getHasPostBindSubmitError);

  const handleAdditionalInterestStatusChange = useCallback(
    (value: boolean): void => {
      exists.props.actionOnComplete(value);
      if (value) {
        if (value && !existsAdditionalInterest) {
          addAddtionalInterest();
        }
        addFields(allFields);
      } else {
        const prefix = 'additionalInterest';
        removeByFields(allFields);
        clearFields(...Object.values(allFields));
        dispatch(deleteKeysFromPostBindAnswers(additionalInterestAddressRef));
        dispatch(deleteKeysFromPostBindAnswers(prefix));
      }
      trackSapiAnalyticsEvent({
        element: 'postbindPage.additionalInterest',
        event: 'click',
        eventDetail: String(value),
      });
    },
    [
      exists.props,
      dispatch,
      allFields,
      addAddtionalInterest,
      additionalInterestAddressRef,
      addFields,
      removeByFields,
      existsAdditionalInterest,
    ],
  );

  const additionalInterestForm = (
    <div className={classes.formRoot} id='AdditionalInterestForm'>
      <Grid container columnSpacing={isMobile ? 2 : 4}>
        <GridItem topSpacing='sm' xs={12}>
          <p className={cx(classes.textTeriary, classes.textItalic)}>
            Notification will be sent as entered, please ensure the information is correct.
          </p>
        </GridItem>
        {email.exists && (
          <GridItem topSpacing='sm' xs={12}>
            <TextField
              {...email.props}
              id='email'
              label='Email (optional)'
              trackingName='add_landlord_email'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
              inputProps={{ 'data-testid': 'AdditionalInterestFormStreetTextField' }}
              autoComplete='email'
              actionOnChange={email.validateUpdateAndPatch}
            />
          </GridItem>
        )}
        {name.exists && (
          <GridItem topSpacing='sm' xs={12}>
            <TextField
              {...name.props}
              id='name'
              label='Landlord’s name or company '
              trackingName='add_landlord_name'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
              inputProps={{ 'data-testid': 'AdditionalInterestFormStreetTextField' }}
              autoComplete='name'
              actionOnChange={name.validateUpdateAndPatch}
            />
          </GridItem>
        )}
        {line1.exists && (
          <GridItem topSpacing='sm' xs={12} md={6}>
            <TextField
              {...line1.props}
              id='streetAddress'
              label='Address'
              trackingName='add_landlord_address'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
              inputProps={{ 'data-testid': 'AdditionalInterestFormStreetTextField' }}
              autoComplete='address-line1'
              actionOnChange={line1.validateUpdateAndPatch}
            />
          </GridItem>
        )}
        {line2.exists && (
          <GridItem topSpacing='sm' xs={12} md={6}>
            <TextField
              {...line2.props}
              id='line2'
              label='Apt./Unit # (optional)'
              trackingName='add_landlord_address_line_2'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
              inputProps={{ 'data-testid': 'AdditionalInterestFormLine2TextField' }}
              autoComplete='address-line2'
              actionOnChange={line2.validateUpdateAndPatch}
            />
          </GridItem>
        )}
        {city.exists && (
          <GridItem topSpacing='sm' xs={12} md={state.exists ? 4 : 6}>
            <TextField
              {...city.props}
              id='city'
              label='City'
              trackingName='add_landlord_city'
              inputProps={{ 'data-testid': 'AdditionalInterestFormCityTextField' }}
              autoComplete='address-level2'
              actionOnChange={city.validateUpdateAndPatch}
            />
          </GridItem>
        )}
        {state.exists && (
          <GridItem topSpacing='sm' xs={12} md={4}>
            <Select
              {...(state.props as OptionProps)}
              label='State'
              id='state'
              trackingName='add_landlord_state'
              inputButtonAriaLabel='Person Address State Field'
              autoComplete='address-level1'
              actionOnChange={state.validateUpdateAndPatch}
            />
          </GridItem>
        )}
        {zipcode.exists && (
          <GridItem topSpacing='sm' xs={12} md={state.exists ? 4 : 6}>
            <NumberFormat
              {...zipcode.props}
              id='zipCode'
              formatType='zipcode'
              label='ZIP code'
              trackingName='add_landlord_zipcode'
              data-testid='AdditionalInterestFormZipcodeField'
              autoComplete='postal-code'
              actionOnChange={zipcode.validateUpdateAndPatch}
            />
          </GridItem>
        )}
      </Grid>
    </div>
  );

  if (!exists.exists) {
    return null;
  }

  return (
    <Grid className={classes.root} container>
      <GridItem className={classes.informationCard}>
        <h3 className={classes.columnContentHeader}>{metadata.columnContentHeader}</h3>
        <p>{metadata.columnContentText}</p>

        {hasPostBindSubmitError ? (
          <AdditionalInterestError />
        ) : (
          <>
            <div className={classes.radioBtnGroupContainer}>
              <RadioGroupWithOptions
                value={existsAdditionalInterest}
                id='notifylandlord'
                variant='yesNoButton'
                actionOnComplete={handleAdditionalInterestStatusChange}
                trackingName='add_landlord_question'
              />
            </div>
            {existsAdditionalInterest && (
              <>
                {additionalInterestForm}
                <p className={classes.columnContentFooter}>{metadata.columnContentFooter}</p>
              </>
            )}
          </>
        )}
      </GridItem>
    </Grid>
  );
};
