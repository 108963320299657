import { env } from '@ecp/env';

export interface Metadata {
  title: string;
}

const metadata: Metadata = {
  title: env.static.isAgent
    ? 'Coverages for everyone on the policy'
    : 'Coverages for everyone on your policy',
};

export default metadata;
