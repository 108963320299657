import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CoverageCardItems' })((theme) => ({
  root: {
    padding: '10px 3px 0 3px',
  },
  hideError: {
    '& p': {
      display: 'none !important',
    },
  },
  subCoveragesRoot: {
    paddingTop: 15,
  },
  subCoveragesRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 30px',
    alignItems: 'flex-start',
    marginBottom: 7,
    position: 'relative',
  },
  subCoveragesField: {
    '& > *:first-child': {
      marginBottom: 0,
    },
    overflowX: 'hidden',
    paddingTop: 8,
    paddingBottom: 8,
    overflowY: 'hidden',
  },
  subCoveragesTooltip: {
    position: 'absolute',
    right: 0,
    marginTop: 45,
    '&:first-child': {
      bottom: 28,
    },
  },
  subCoveragesTooltipError: {
    '&:first-child': {
      bottom: 50,
    },
  },
  divider: {
    marginBottom: 20,
  },
  coveragesDropdown: {
    '& > *:first-child': {
      marginBottom: 0,
    },
    paddingTop: 8,
    paddingBottom: 8,
    overflow: 'hidden',
    maxWidth: '340',
  },
  checkBoxGroup: {
    '& legend': {
      paddingTop: 0,
    },
  },
  checkBoxGroupLabel: theme.typography.body1Bold,
  formControlLabel: {
    marginTop: 28,
    marginBottom: 28,
  },
  formControlCheckBox: {
    marginRight: 10,
  },
  coverageSelected: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    height: 40,
  },
  premiumValue: {
    color: theme.palette.grey[700],
  },
}));
