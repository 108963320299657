import { Grid } from '@mui/material';

import { env } from '@ecp/env';
import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Button, Page } from '@ecp/features/sales/shared/components';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import { getUserSelection } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { IconUIArrowLeft } from '@ecp/themes/base';

import { RatingCriteriaForm } from './RatingCriteriaForm';
import { useStyles } from './RatingCriteriaPage.styles';

export const RatingCriteriaPage: React.FC = () => {
  const { classes } = useStyles();
  const userSelection = useSelector(getUserSelection);
  const previousURL = !userSelection ? PagePath.QUOTES : PagePath.COVERAGES;
  const handleBack = useNavigateToPage(previousURL);
  const nextURL = !userSelection ? PagePath.COVERAGES : PagePath.CHECKOUT;
  const handleNext = useNavigateToPage(nextURL);

  return (
    <div className={classes.root}>
      <Page
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.COVERAGES} />,
          forceSwipeable: !env.static.isAgent,
        }}
      >
        <Grid container justifyContent='center' className={classes.root}>
          <Grid item>
            <Button
              variant='iconTextMedium'
              className={classes.backButton}
              onClick={handleBack}
              icon={<IconUIArrowLeft />}
            >
              Back to your coverages
            </Button>
            <RatingCriteriaForm onNext={handleNext} checkoutButtonText='View payment options' />
          </Grid>
        </Grid>
      </Page>
    </div>
  );
};
