import { memo, useCallback, useEffect, useState } from 'react';

import { Drawer, SwipeableDrawer } from '@mui/material';

import { Button } from '@ecp/features/sales/shared/components';
import { IconUIClose, IconUIDrawer, useIsDesktop, useIsTablet } from '@ecp/themes/base';

import { useStyles } from './Navbar.styles';

export interface NavbarProps {
  initialMobileOpen?: boolean;
  drawer?: React.ReactElement;
  forceSwipeable?: boolean;
}

export const Navbar: React.FC<NavbarProps> = memo((props) => {
  const { initialMobileOpen = false, drawer, forceSwipeable } = props;
  const { classes } = useStyles();
  const isDesktop = useIsDesktop();
  const isTablet = useIsTablet();
  const [mobileOpen, setMobileOpen] = useState(initialMobileOpen);
  const [scrollPosition, setScrollPosition] = useState<number>();

  useEffect(() => {
    if (scrollPosition !== undefined && !mobileOpen) {
      document.documentElement.scrollTop = scrollPosition;
    }
  }, [mobileOpen, scrollPosition]);
  const handleDrawerToggle = useCallback(() => {
    if (!mobileOpen) {
      setScrollPosition(document.documentElement.scrollTop);
    }
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  return (
    <div className={classes.root}>
      <nav aria-label='Quote Info' className={classes.nav}>
        {/* tablet/mobile version */}
        {drawer &&
          (isDesktop && !forceSwipeable ? null : (
            <div className={classes.mobileWrapper}>
              <div className={classes.drawerButtonWrapper}>
                <Button
                  onClick={handleDrawerToggle}
                  className={classes.drawerButton}
                  variant='iconText'
                >
                  <IconUIDrawer className={classes.drawerButtonIcon} />
                  &nbsp;
                  <p className={classes.drawerText}>Your progress</p>
                </Button>
              </div>
              <SwipeableDrawer
                variant='temporary'
                open={mobileOpen}
                onOpen={handleDrawerToggle}
                onClose={handleDrawerToggle}
                classes={{ root: classes.swipeableDrawerRoot, paper: classes.drawerPaper }}
                ModalProps={{ keepMounted: true }}
                transitionDuration={500}
                style={{ zIndex: 1199 }}
              >
                <div data-testid='AppHeaderSpacer' className={classes.headerSpacer} />
                <Button
                  onClick={handleDrawerToggle}
                  className={classes.drawerEditButton}
                  variant='iconText'
                >
                  <p className={classes.drawerText}>Edit details</p>
                  <IconUIClose className={classes.drawerButtonIcon} />
                </Button>
                {drawer}
              </SwipeableDrawer>
            </div>
          ))}
        {/* desktop version */}
        {isTablet || (isDesktop && forceSwipeable) ? null : (
          <Drawer classes={{ paper: classes.drawerPaper }} variant='permanent' open>
            {drawer}
          </Drawer>
        )}
      </nav>
    </div>
  );
});
