import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'RVPErrorCustomPage' })((theme) => ({
  root: {},
  guidanceHead: {
    fontWeight: 'bold',
  },
  guidanceList: {
    padding: '0 15px',
  },
  guidanceInnerList: {
    listStyle: 'none',
    padding: '0 0 0 30px',
  },
  guidanceInnerListItem: {
    position: 'relative',
    '&:before': {
      content: '"-"',
      position: 'absolute',
      display: 'block',
      left: -20,
    },
  },
}));
