import { useEffect } from 'react';

import { Container, Grid } from '@mui/material';

import { trackRender } from '@ecp/utils/analytics/tracking';
import { datadogLog } from '@ecp/utils/logger';

import { env } from '@ecp/env';
import { ErrorDetails } from '@ecp/features/sales/shared/components';
import { ErrorReason } from '@ecp/features/sales/shared/constants';
import {
  getErrorDetailsForOffers,
  getOffers,
  getOfferSetId,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import { partner } from '@ecp/partners';
import { GraphicErrorCallUsImageUrl } from '@ecp/themes/base';

import { CNQ } from './CNQ';
import { DNQ } from './DNQ';
import { HO2Guidance } from './HO2Guidance';
import { useStyles } from './QuotesError.styles';
import { createDeclinationData, getOfferErrors } from './util';

export type QuotesErrorProps = {
  dalSessionId?: string;
  inquiryId?: string;
  xRequestId?: string;
};

export const QuotesError: React.FC<QuotesErrorProps> = (props) => {
  const { dalSessionId = '', inquiryId = '', xRequestId = '' } = props;
  const { classes } = useStyles();
  const offerSetId = useSelector(getOfferSetId);

  // Get DNQ offer details only if product option is available
  // Eg. Auto quote requested on landing page
  // 1. Auto only quotes on Quote Summary -> Show DNQ Reasons (if any) for auto quotes only
  // 2. FDS HIT -> Bundle option available -> Show DNQ Reasons (if any) for auto and home quotes
  const erroredOfferDetails = useSelector(getErrorDetailsForOffers);

  const offers = useSelector(getOffers);
  const declinationData = erroredOfferDetails.reduce(
    (acc, offer) => (acc.extendedMessage ? acc : createDeclinationData(offer.product, offers)),
    {} as ReturnType<typeof createDeclinationData>,
  );

  const { shouldShowHO2Guidance, isDNQError } = useSelector(getOfferErrors);
  const ErrorDetailsElement: typeof DNQ = shouldShowHO2Guidance
    ? HO2Guidance
    : isDNQError
    ? DNQ
    : CNQ;

  const errorDetails = env.static.isAgent && erroredOfferDetails.length > 0 && (
    <ErrorDetails
      dalSessionId={dalSessionId}
      inquiryId={inquiryId}
      offerSetId={offerSetId}
      xRequestId={xRequestId}
      errorDetails={erroredOfferDetails}
    />
  );

  useEffect(() => {
    const erroredOfferDetailsString = erroredOfferDetails
      .map((error) => `${error.product}: ${error.reasons}.`)
      .join(' ');
    const analyticsEventDetail = {
      PageCategory: ErrorReason.DNQ,
      InquiryId: inquiryId,
      DalSessionId: dalSessionId,
      ErrorText: 'No Offers',
      UserShownText: 'Whoops! No quotes available',
    };

    datadogLog({
      logType: 'warn',
      message: `Whoops! No quotes available - no offers. ${erroredOfferDetailsString}`,
      context: {
        logOrigin: 'Whoops Page',
        functionOrigin: '',
        contextType: 'Whoops Page',
      },
    });
    trackRender({
      action: 'QuoteSummaryWhoopsMessage',
      label: `Unfortunately, ${partner.shared.partnerDisplayName} was unable to provide you with an online quote at the moment. We’ve arranged for you to speak with a licensed agent who can help.`,
    });
    trackSapiAnalyticsEvent({
      element: 'choice.quotesDNQError',
      event: 'render',
      eventDetail: JSON.stringify(analyticsEventDetail),
    });
    // TODO: Fix this dep issue that is throwing an warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className={classes.quoteContainer}>
      <Grid container spacing={0}>
        <Grid
          item
          md={6}
          xs={12}
          container
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
        >
          <ErrorDetailsElement {...declinationData} />
          {errorDetails}
        </Grid>
        <Grid item xs={false} md={1} lg={2} />
        <Grid
          className={classes.imageGrid}
          item
          lg={4}
          md={5}
          xs={false}
          container
          direction='row'
          justifyContent='center'
          alignItems='flex-start'
        >
          <div className={classes.imageContainer}>
            <img src={GraphicErrorCallUsImageUrl} alt='Error' />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
