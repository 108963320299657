import {
  GraphicHomeDetailAdobeImageUrl,
  GraphicHomeDetailBiLevelImageUrl,
  GraphicHomeDetailCapeCodImageUrl,
  GraphicHomeDetailColonialImageUrl,
  GraphicHomeDetailContemporaryImageUrl,
  GraphicHomeDetailFederalColonialImageUrl,
  GraphicHomeDetailLogImageUrl,
  GraphicHomeDetailMediterraneanImageUrl,
  GraphicHomeDetailMobileImageUrl,
  GraphicHomeDetailQueenAnneImageUrl,
  GraphicHomeDetailRaisedRanchImageUrl,
  GraphicHomeDetailRanchImageUrl,
  GraphicHomeDetailRowhouseCenterImageUrl,
  GraphicHomeDetailRowhouseEndImageUrl,
  GraphicHomeDetailSplitFoyerImageUrl,
  GraphicHomeDetailSplitLevelImageUrl,
  GraphicHomeDetailSWAdobeImageUrl,
  GraphicHomeDetailTownhouseCenterImageUrl,
  GraphicHomeDetailTownhouseEndImageUrl,
  GraphicHomeDetailTriLevelImageUrl,
  GraphicHomeDetailVictorianImageUrl,
} from '@ecp/themes/base';
import type { CardOption } from '@ecp/types';

export interface Metadata {
  options: CardOption<string, string>[];
}

const metadata: Metadata = {
  options: [
    {
      label: 'Adobe',
      value: 'STYLE_OF_HOME.ADOBE',
      image: GraphicHomeDetailAdobeImageUrl,
      alt: 'Adobe',
      helpText:
        '1 or 2 stories, with adobe block construction and simple floor plans. Popular in the southwestern U.S., include flat roofs.',
    },
    {
      label: 'Bi-level',
      value: 'STYLE_OF_HOME.BI_LEVEL',
      image: GraphicHomeDetailBiLevelImageUrl,
      alt: 'Bi-level',
      helpText:
        'A home with a split entry way allowing one to go up or down. A bi-level home has two levels of living area, with the lower level being both partially unfinished and below grade/underground.',
    },
    {
      label: 'Cape Cod',
      value: 'STYLE_OF_HOME.CAPE_COD',
      image: GraphicHomeDetailCapeCodImageUrl,
      alt: 'Cape Cod',
      helpText:
        '1.5 stories with dormers on the front and rear roof. Originally built with roofs with triangular angles on each end.',
    },
    {
      label: 'Colonial',
      value: 'STYLE_OF_HOME.COLONIAL',
      image: GraphicHomeDetailColonialImageUrl,
      alt: 'Colonial',
      helpText:
        '2 stories and often includes a central front door, box-shaped and roofs with triangular angles on each end.',
    },
    {
      label: 'Contemporary',
      value: 'STYLE_OF_HOME.CONTEMPORARY',
      image: GraphicHomeDetailContemporaryImageUrl,
      alt: 'Contemporary',
      helpText:
        '1-2 ½ stories with an open floor plan. Often custom built with asymmetrical designs and several large windows in the front.',
    },
    {
      label: 'Mediterranean',
      value: 'STYLE_OF_HOME.MEDITERRANEAN',
      image: GraphicHomeDetailMediterraneanImageUrl,
      alt: 'Mediterranean',
      helpText:
        'Larger homes with elaborate exteriors. Often have a square shape with stone or stucco exterior, decorative windows and symmetrical designs.',
    },
    {
      label: 'Federal Colonial',
      value: 'STYLE_OF_HOME.FEDERAL_COLONIAL',
      image: GraphicHomeDetailFederalColonialImageUrl,
      alt: 'Federal Colonial',
      helpText:
        'This is generally 2-story home that is characterized by its flat roof and ornate wood trim along the exterior roof line. It is usually square in design and is typically an early 20th century or older structure. Exterior walls usually have painted wood siding.',
    },
    {
      label: 'Log',
      value: 'STYLE_OF_HOME.LOG',
      image: GraphicHomeDetailLogImageUrl,
      alt: 'Log',
      helpText:
        'Solid logs that are 8" to 12" in diameter, with the log visible on the exterior and interior of the home. The space between each log is caulked to form a weather-tight seal.',
    },
    {
      label: 'Mobile/Manufactured',
      value: 'STYLE_OF_HOME.MOBILE_MANUFACTURED',
      image: GraphicHomeDetailMobileImageUrl,
      alt: 'Mobile/manufactured',
      helpText: (
        <>
          <p>
            {`Mobile/manufactured is a home built on a permanent chassis to ensure transportability and will have a title
          instead of a deed. It is typically transported to the site in 1 to 3 pieces and referred to as a single,
          double or triple wide.`}
          </p>
          <p>
            {`Mobile/manufactured also describes any home factory-built to the HUD Title 6 construction standards,
          commonly known as the HUD-Code. These homes will display documentation called the Certification Label and
          the Data Plate. The Certification Label (sometimes called the HUD Label) can be located on the tail end of
          each transportable section of the home. Data Plates are affixed inside the home on or near the main
          electrical breaker box, or other readily visible/accessible location.`}
          </p>
        </>
      ),
    },
    {
      label: 'Queen Anne',
      value: 'STYLE_OF_HOME.QUEEN_ANNE',
      image: GraphicHomeDetailQueenAnneImageUrl,
      alt: 'Queen Anne',
      helpText:
        'Notable for ornamental design. Typically has fish scale siding, spindle work, and decorative treatments.',
    },
    {
      label: 'Raised ranch',
      value: 'STYLE_OF_HOME.RAISED_RANCH',
      image: GraphicHomeDetailRaisedRanchImageUrl,
      alt: 'Raised ranch',
      helpText:
        'A ranch home with an elevated basement or portion of the basement above ground level.',
    },
    {
      label: 'Ranch',
      value: 'STYLE_OF_HOME.RANCH',
      image: GraphicHomeDetailRanchImageUrl,
      alt: 'Ranch',
      helpText: '1-story homes that are typically rectangular-shaped with simple floor plans.',
    },
    {
      label: 'Row house center',
      value: 'STYLE_OF_HOME.ROW_HOUSE_CENTER',
      image: GraphicHomeDetailRowhouseCenterImageUrl,
      alt: 'Row house center',
      helpText:
        'Series of identical houses, joined by common walls. Owner is responsible for interior and exterior. Typically in the middle of more than 4 connected units.',
    },
    {
      value: 'STYLE_OF_HOME.ROW_HOUSE_END',
      label: 'Row house end',
      image: GraphicHomeDetailRowhouseEndImageUrl,
      alt: 'Row house end',
      helpText:
        'Series of identical houses, joined by common walls. Owner is responsible for interior and exterior. Typically on the end of more than 4 connected units.',
    },
    {
      label: 'Southwest adobe',
      value: 'STYLE_OF_HOME.SOUTHWEST_ADOBE',
      image: GraphicHomeDetailSWAdobeImageUrl,
      alt: 'Southwest adobe',
      helpText:
        'A home constructed of adobe brick with wood beams used for both support and ornamentation. Generally these homes have a flat or low pitched roof.',
    },

    {
      value: 'STYLE_OF_HOME.SPLIT_FOYER',
      label: 'Split foyer',
      image: GraphicHomeDetailSplitFoyerImageUrl,
      alt: 'Split foyer',
      helpText:
        'A residence has two levels of living area, with the lower level being completely finished and normally partially below grade (with no basement below it). The entrance has a split-foyer – after entering the home, one would need to go either up or down the stairs to the home’s main living areas.',
    },
    {
      label: 'Split level',
      value: 'STYLE_OF_HOME.SPLIT_LEVEL',
      image: GraphicHomeDetailSplitLevelImageUrl,
      alt: 'Split level',
      helpText:
        'Similar to a raised ranch. Upon entering, there is a foyer, with one set of stairs that lead upstairs and one set of stairs that lead downstairs.',
    },
    {
      value: 'STYLE_OF_HOME.TOWNHOUSE_CENTER',
      label: 'Town house center',
      image: GraphicHomeDetailTownhouseCenterImageUrl,
      alt: 'Town house center',
      helpText:
        'Exterior style can vary. Owner is responsible for interior and exterior. Typically in the middle of 2-4 other connected units.',
    },
    {
      value: 'STYLE_OF_HOME.TOWNHOUSE_END',
      label: 'Town house end',
      image: GraphicHomeDetailTownhouseEndImageUrl,
      alt: 'Town house end',
      helpText:
        'Exterior style can vary. Owner is responsible for the interior and exterior. Typically on the end of 2-4 connected units.',
    },
    {
      value: 'STYLE_OF_HOME.TRI_LEVEL',
      label: 'Tri-level',
      image: GraphicHomeDetailTriLevelImageUrl,
      alt: 'Tri-level',
      helpText:
        'Floor levels are staggered, so that "main" level of house is between the upper and lower floors. Basements typically finished for additional living space.',
    },
    {
      label: 'Victorian',
      value: 'STYLE_OF_HOME.VICTORIAN',
      image: GraphicHomeDetailVictorianImageUrl,
      alt: 'Victorian',
      helpText:
        'Typically has steeply pitched roof of irregular shape, textured shingles to avoid smooth-walled appearance, and asymmetrical porch and façade.',
    },
  ],
};

export default metadata;
