import { useAddConditionValues, useAddFields, useInitValues } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

export const BusinessOnPremisesQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const businessOnPremises = useHomePropertyField('businessOnPremises');
  const {
    label = businessOnPremises.question?.title,
    trackingName = 'conduct_business_at_home_selection',
  } = props;

  useInitValues({ [businessOnPremises.key]: false });

  useAddFields({ businessOnPremises });

  useAddConditionValues({
    conditionalFields: [businessOnPremises],
    isRequiredOverride: () => true,
  });

  if (!businessOnPremises.exists) return null;

  return (
    <RadioGroupWithOptions
      {...businessOnPremises.props}
      id='BusinessOnPremises'
      label={label}
      variant='yesNoButton'
      trackingName={trackingName}
    />
  );
};
