import { useMemo } from 'react';

import { GridItem } from '@ecp/components';
import { useAddConditionValues, useAddFields, useInitValues } from '@ecp/features/sales/form';
import { PercentageGroupSelection, Slider } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField, useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import type { OptionProps } from '@ecp/features/sales/shared/types';
import type { CardOption } from '@ecp/types';

import { STATIC_FOUNDATION_TYPE } from '../../../../constants/questions';
import {
  useAddAndUpdateFoundation,
  useFoundationPercentages,
  useRemoveFoundation,
  useUpdateFoundation,
} from '../../../../state';
import { doesFoundationTypeIncludeBasement } from '../../../../state/util/foundationTypeUtil';

export const FoundationTypeQuestion: React.FC<QuestionProps> = (props) => {
  const useHomePropertyField = useFieldWithPrefix('property', 'property');
  const foundationType = useField(STATIC_FOUNDATION_TYPE);
  const percentageFinished = useHomePropertyField('foundation.percentageFinished');
  const type = useFieldWithPrefix('foundation.<id>', 'foundation.<id>')('foundationType');
  const percentage = useFieldWithPrefix('foundation.<id>', 'foundation.<id>')('percentage');

  const percentages = useFoundationPercentages();
  const foundationTypes = Object.keys(percentages).join(',');
  const addAndUpdateFoundation = useAddAndUpdateFoundation();
  const updateFoundation = useUpdateFoundation();
  const removeFoundation = useRemoveFoundation();

  useInitValues({ [foundationType.key]: foundationTypes });

  const foundationTypeIncludesBasement = useMemo(
    (): boolean => doesFoundationTypeIncludeBasement(foundationType.value as string),
    [foundationType.value],
  );

  useAddFields({ foundationType, percentageFinished });

  useAddConditionValues({
    conditionalFields: [percentageFinished],
    isExcluded: () => !foundationTypeIncludesBasement,
    isRequiredOverride: () => true,
  });

  if (!type.exists) return null;

  const {
    trackingName = 'home_basement_finished_percentage_slider',
    groupLabel = 'What percentage of your basement is finished?',
    label = type.question.title || '',
  } = props;

  return (
    <>
      <GridItem>
        <PercentageGroupSelection
          hidePercentageSelected
          title={label}
          subtitle={type.question.subtitle || ''}
          options={type.question.options as CardOption[]}
          percentageOptions={percentage?.question?.options ?? []}
          percentages={percentages}
          uiField={foundationType}
          optionTrackingName='home_foundation_selection'
          percentTrackingName='home_foundation_percentages'
          moreLessTrackingName='home_foundation_more_choices'
          onAdd={addAndUpdateFoundation}
          onUpdate={updateFoundation}
          onRemove={removeFoundation}
          moreLessDataTestId='FoundationType-SeeMoreOrLessChoices'
          checkBoxGroupTestId='FoundationType'
          sortByKey
        />
      </GridItem>
      {foundationTypeIncludesBasement && (
        <GridItem topSpacing='lg' xs={12}>
          <Slider
            {...(percentageFinished.props as OptionProps)}
            groupLabel={groupLabel}
            trackingName={trackingName}
          />
        </GridItem>
      )}
    </>
  );
};
