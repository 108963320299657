import { useEffect } from 'react';

import { Grid } from '@mui/material';

import { scrollToTop } from '@ecp/utils/web';

import { LogoSpinner } from '@ecp/components';

import { useStyles } from './PostBindLoadingPage.styles';

export const PostBindLoadingPage: React.FC<unknown> = () => {
  const { classes } = useStyles();

  useEffect(() => scrollToTop());

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <h1 className={classes.header}>
          Thank you for your patience while we process your payment
        </h1>
        <div>
          <LogoSpinner />
        </div>
      </Grid>
    </Grid>
  );
};
