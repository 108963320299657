import { GridItem } from '@ecp/components';
import { HighRiskDogQuestion } from '@ecp/sales/lob/property';

import {
  BusinessOnPremisesQuestion,
  DaycareOnPremisesQuestion,
  FoundationTypeQuestion,
  GarageTypeQuestion,
  HasPoolQuestion,
  HistoricHomeQuestion,
  LivingAreaQuestion,
  MarketValueQuestion,
  MineSubsidenceDamageQuestion,
  NoOfMortgagesQuestion,
  NumberOfDaysRentedQuestion,
  NumberOfStoriesQuestion,
  OccupancyTypeQuestion,
  OccupyMoreThanOneResidenceQuestion,
  RentedToOthersQuestion,
  SingleOrMultiFamilyQuestion,
  SolidFuelQuestion,
  StyleOfHomeQuestion,
  WildfireDefenseQuestion,
  YearBuiltQuestion,
} from '../../components';

export const HomeBasicFormQuestions: React.FC = () => {
  return (
    <>
      <GridItem topSpacing='lg' xs={12}>
        <MarketValueQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12} md={6}>
        <YearBuiltQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <HistoricHomeQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <OccupancyTypeQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <OccupyMoreThanOneResidenceQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <SingleOrMultiFamilyQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12} md={6}>
        <NoOfMortgagesQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <LivingAreaQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <StyleOfHomeQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <NumberOfStoriesQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <FoundationTypeQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <GarageTypeQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <BusinessOnPremisesQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <SolidFuelQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <DaycareOnPremisesQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <RentedToOthersQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <NumberOfDaysRentedQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <HighRiskDogQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <HasPoolQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <WildfireDefenseQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <MineSubsidenceDamageQuestion />
      </GridItem>
    </>
  );
};
