export interface Metadata {
  requireAccountTrackingAuto: string;
  requireAccountTrackingHome: string;
  requireAccountTrackingRenters: string;
}

const metadata: Metadata = {
  requireAccountTrackingAuto: 'false',
  requireAccountTrackingHome: 'false',
  requireAccountTrackingRenters: 'false',
};

export default metadata;
