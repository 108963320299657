import { NumberFormat } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { useField } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product, ProductName } from '@ecp/features/shared/product';

import { getPaymentPlanForProduct } from '../../../state/paymentoptions';

export interface Props {
  product: Product;
  coverageType?: ProductName;
}

export const CustomDownPaymentAmount: React.FC<Props> = (props) => {
  const { coverageType, product } = props;
  const paymentOptionValue = useSelector((state: RootStore) =>
    getPaymentPlanForProduct(state, product),
  );

  const customAmount = useField(`static.checkout.${coverageType}.customPaymentAmount`);
  const downPaymentAmount = useField(`static.checkout.${coverageType}.minCustomPaymentAmount`);

  useInitValues({ [customAmount.key]: downPaymentAmount.value });
  useAddFields({
    [`${customAmount.key}`]: customAmount,
  });

  if (!customAmount.exists && paymentOptionValue !== 'Custom') return null;

  return <NumberFormat {...customAmount.props} label='Payment Amount' />;
};
