import { emptyArray } from '@ecp/utils/common';

import type { Error } from '../types';

// TODO This is a quick fix for handling UI injected errors in our form.
// We currently do not validate errors that do not come from the sapi errors or the inquiry validation error object.
// We will need to create a separate object to handle UI injected errors when validating our form fields as tech debt.

const fieldErrorLocations = [
  /driver.primary.license.number.confirm/,
  /driver.[a-z0-9]{8}.license.number/,
  /person.[a-z0-9]{8}.firstName/,
  /person.[a-z0-9]{8}.lastName/,
  /person.[a-z0-9]{8}.dob/,
  /driver.[a-z0-9]{8}.license.ageFirstLicensed/,
];

interface FormattedErrors {
  [key: Error['location']]: Error['reason'];
}
export const formatErrors = (errors: Error[] = emptyArray as unknown as Error[]): FormattedErrors =>
  errors.reduce((acc, error) => {
    // If we get more than 1 error with the same location, we will only capture the 1st one
    acc[error.location] ??= error.reason;

    return acc;
  }, {} as FormattedErrors);

/** Matches key against common field error locations. */
export const errorExists = (key: string): boolean =>
  fieldErrorLocations.some((field) => field.test(key));
