import { datadogLog } from '@ecp/utils/logger';

import type { ThunkAction } from '@ecp/features/sales/shared/store/types';
import type { GetVehicleBase } from '@ecp/features/sales/shared/types';

import { composeVehicleKey } from '../../util/composeVehicleKey';
// import { fetchCarImages } from './api';
import { getResolvedVehicleImageUrl, getVehicleImageUrls } from './selectors';
// import { imagesUpdated } from './slice';

const fetchingVehicleImages: string[] = [];

export const gettingVehicleImage =
  (vehicleType: GetVehicleBase): ThunkAction<Promise<string>> =>
  async (dispatch, getState) => {
    const { year, make, model } = vehicleType;
    if (year && make && model) {
      // check if it is cached
      const cachedVehicleImageUrl = getResolvedVehicleImageUrl(getState(), vehicleType);
      const vehicleImageUrls = getVehicleImageUrls(getState());
      // if '', we return since that means lookup was attempted, but not found
      if (cachedVehicleImageUrl != null) return cachedVehicleImageUrl;

      const fetchVehicleImages = async (): Promise<string> => {
        try {
          const key = composeVehicleKey({ make, model, year });
          fetchingVehicleImages.push(key);
          if (!vehicleImageUrls[key] && vehicleImageUrls[key] !== '') {
            // Not calling this api as a part of discarding fuelapi usage.
            // TODO: Keeping the reference to update it later.
            // const vehicleImageUrl = await fetchCarImages({ year, make, model });
            const vehicleImageUrl = '';
            // fetchingVehicleImages = fetchingVehicleImages.filter((image) => image !== key);
            // dispatch(imagesUpdated({ [key]: vehicleImageUrl || '' }));

            return vehicleImageUrl;
          }
        } catch (e) {
          const error = e as Error;

          datadogLog({
            logType: 'warn',
            message: `could not get vehicle image - ${error?.message}`,
            context: {
              logOrigin: 'libs/features/sales/quotes/auto/src/state/vehicles/thunks.ts',
              contextType: 'Fuel API Warning',
              severity: 'low',
              functionOrigin: 'gettingVehicleImage',
              //    TODO: we cannot spread anything into DD logs
              //    TODO: We need to revisit this because it is logging PII/PCI Data
              //   ...e,
              vehicleYear: year,
              vehicleMake: make,
              vehicleModel: model,
            },
            error,
          });
          // if it is last loop, rethrow the error
          // otherwise continue loop
        }

        return '';
      };
      const imageUrl = await fetchVehicleImages();

      return imageUrl;
    }

    return '';
  };
