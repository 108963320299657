import { isTruthy, unique } from '@ecp/utils/common';

import {
  isProductAuto,
  isProductHome,
  isProductRenters,
  LineOfBusiness,
} from '@ecp/features/shared/product';

export const productsToLobs = (products: string[]): LineOfBusiness[] => {
  const lobs: (LineOfBusiness | undefined)[] = products.map((product) => {
    if (isProductAuto(product)) return LineOfBusiness.AUTO;
    if (isProductHome(product)) return LineOfBusiness.HOME;
    if (isProductRenters(product)) return LineOfBusiness.RENTERS;

    return undefined;
  });

  return unique(lobs).filter(isTruthy);
};
