import { useLayoutEffect } from 'react';

import { history } from './history';
import { location, useLocation } from './location';
import type { Location } from './types';

/**
 * Triggers callback on back/forward nav. Cleans up itself if back nav occurred or the caller component unmounted.
 *
 * ! NOTE: This also triggers on forward nav as there is no way to detect specifically back nav.
 */
export const useBackAndForwardNav = (
  onBackNav: (args: {
    currentLocation: Location;
    nextLocation: typeof location;
    history: typeof history;
  }) => Promise<void> | void,
): void => {
  // const [currentHistoryLength, setCurrentHistoryLength] = useState(history.length);
  const currentLocation = useLocation();

  useLayoutEffect(() => {
    const handleBackNav = async (): Promise<void> => {
      // The only way to identify back navigation event is via comparing prior and current history.length
      // if (history.length < currentHistoryLength)
      await onBackNav({ currentLocation, nextLocation: location, history });
    };
    window.addEventListener('popstate', handleBackNav);

    // unlisten in the cleanup callback should be called asynchronously, or history listener above never fires
    return () => {
      setTimeout(() => window.removeEventListener('popstate', handleBackNav), 0);
    };
  }, [/** currentHistoryLength, */ currentLocation, onBackNav]);

  // useEffect(() => {
  //   setCurrentHistoryLength(history.length);
  // }, [currentLocation]);
};
