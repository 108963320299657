import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CheckoutTitle' })((theme) => ({
  checkoutTitleSection: {
    display: 'flex',
  },
  alertDiv: {
    marginTop: 0,
    marginBottom: 20,
    textAlign: 'center',
  },
  checkoutTitleImages: {
    display: 'flex',
  },
  headerImage: {
    ...theme.mixins.setColorSvg(theme.palette.primary.main),
    height: 50,
    width: 'auto',
    marginLeft: -14,
  },
  carrierLogo: {
    width: 150,
    height: 45,
    paddingRight: 15,
  },
  title: {
    ...theme.typography.h1,
    marginLeft: 5,
    alignSelf: 'center',
  },
  titleContainer: {
    alignSelf: 'center',
  },
  quoteNumber: {
    marginLeft: 40,
  },
}));
