import { useCallback, useState } from 'react';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { useEvent } from '@ecp/utils/react';
import { navigate } from '@ecp/utils/routing';

import { Button, Dialog, Link } from '@ecp/features/sales/shared/components';
import type { RemindersMetadata } from '@ecp/features/sales/shared/types';

import { useStyles } from './PostBindReminder.styles';

type Props = {
  reminder: RemindersMetadata;
};

export const PostBindReminder: React.FC<Props> = (props) => {
  const {
    reminder: {
      title = '',
      link = '',
      linkText = '',
      DialogComponent = null,
      trackingName = '',
      trackingLabel = '',
    },
  } = props;
  const { classes } = useStyles();
  const navigateToReminderLink = useEvent(() => navigate(link, { external: true }));

  const [displayDialog, setDisplayDialog] = useState(false);
  const handleDialogShow = useCallback(() => {
    setDisplayDialog(true);
    trackClick({ action: trackingName, label: trackingLabel as string });
  }, [setDisplayDialog, trackingName, trackingLabel]);
  const handleDialogClose = useCallback(() => {
    setDisplayDialog(false);
  }, [setDisplayDialog]);

  const shouldRenderReminderAsLink = Boolean(link && linkText);
  const shouldRenderReminderAsDialog = Boolean(DialogComponent && linkText);

  return (
    <li className={classes.reminder}>
      {title}
      {shouldRenderReminderAsLink && (
        <>
          <br />
          <Link
            component='button'
            onClick={navigateToReminderLink}
            trackingName={trackingName}
            trackingLabel={trackingLabel as string}
          >
            {linkText}
          </Link>
        </>
      )}
      {shouldRenderReminderAsDialog && (
        <>
          <br />
          <Button variant='textMedium' onClick={handleDialogShow}>
            {linkText}
          </Button>
          <Dialog fullScreen open={displayDialog} titleText={linkText} onClose={handleDialogClose}>
            {DialogComponent && <DialogComponent />}
          </Dialog>
        </>
      )}
    </li>
  );
};
