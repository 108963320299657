import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'StepIcon' })((theme) => ({
  circle: {
    ...theme.typography.body2,
    alignItems: 'center',
    borderRadius: '50%',
    color: theme.palette.grey[50],
    display: 'flex',
    height: 24,
    justifyContent: 'center',
    width: 24,
  },
  incompleteCircle: { backgroundColor: theme.palette.primary.main },
  notVisitedCircle: { backgroundColor: theme.palette.text.disabled },
  completedCircle: {
    ...theme.mixins.setColorSvg(theme.palette.primary.contrastText),
    backgroundColor: theme.palette.success.main,
  },
}));
