import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'MortgagePayment' })((theme) => ({
  ...theme.mixins.formAll,
  fieldset: {
    border: 'none',
  },
  mortgagePayment: {
    [theme.breakpoints.down('lg')]: {
      paddingTop: 20,
    },
  },
  mortgageMobilePayment: {
    [theme.breakpoints.down('lg')]: {
      paddingTop: 0,
    },
  },
  title: {
    ...theme.typography.h3,
    display: 'block',
    marginBottom: 15,
  },
  subTitle: {
    ...theme.typography.body4,
    marginBottom: 15,
  },
  content: theme.typography.body4,
  mortgageOption: {
    marginBottom: 0,
  },
  label: theme.typography.body4,
}));
