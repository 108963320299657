import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

export const ClassARatedRoofQuestion: React.FC<QuestionProps> = (props) => {
  const {
    label = 'Does your home have a Class-A Fire Rated Roof?',
    trackingName = 'have_classA_fire_rated_roof',
  } = props;
  const classARatedRoof = useField('property.classAFireRatedRoofInd');

  if (!classARatedRoof.exists) return null;

  return (
    <RadioGroupWithOptions
      {...classARatedRoof.props}
      id='classARatedRoof'
      label={label}
      variant='yesNoButton'
      trackingName={trackingName}
    />
  );
};
