import { createSelector } from '@reduxjs/toolkit';

import { parseDollar } from '@ecp/utils/common';
import { formatDate } from '@ecp/utils/date';

import type { PaymentPlan } from '@ecp/features/sales/shared/constants';
import { SNI_REGEX } from '@ecp/features/sales/shared/constants';
import { getAnswers } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import type { PolicySummary } from '@ecp/features/sales/shared/types';
import type { ProductName } from '@ecp/features/shared/product';

import type { CheckoutOfferDetails, PaymentMethod } from '../../../types';

export const buildCheckoutPolicySummaryData = (
  coverageType: ProductName,
  offerDetail: CheckoutOfferDetails,
  premiumAmount: string | undefined | null,
  premiumPlan: PaymentPlan,
): PolicySummary => {
  const policyData = {
    coverageType,
    premiumAmount: parseDollar(premiumAmount),
    dueAmount: parseDollar(offerDetail.offer[premiumPlan]?.totalPremium),
    policyDuration: offerDetail.offer.policyDuration || 'undefined',
    items: [
      {
        text: 'Policy starts on',
        value: formatDate(offerDetail.policyStartDate),
        variant: 'regular',
      },
    ],
  };
  if (offerDetail.offer.checkoutPolicyKeys) {
    Object.keys(offerDetail.offer.checkoutPolicyKeys).forEach((item) => {
      policyData.items.push({
        text: item,
        value: offerDetail.offer.checkoutPolicyKeys
          ? offerDetail.offer.checkoutPolicyKeys[item]
          : '',
        variant: 'regular',
      });
    });
  }

  return policyData;
};

const billedTo = (type: string | undefined, paymentNumber: string | undefined): string => {
  const displayLastFourOfPayment = paymentNumber ? `ending in ${paymentNumber.substr(-4, 4)}` : '';
  switch (type) {
    case 'EFT':
      return `Bank ${displayLastFourOfPayment}`;
    case 'CreditCard':
      return `Credit card ${displayLastFourOfPayment}`;
    case 'CostcoVisa':
      return `Costco Visa ${displayLastFourOfPayment}`;
    case 'Mortgage':
      return 'Mortgage';
    default:
      return ' ';
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const numOfPayments = (plan: string | undefined) => {
  switch (plan) {
    case 'Full':
    case 'One Month':
      return '1';
    case 'Two Months':
      return '2';
    case 'Four Months':
      return '4';
    case 'Five Months':
      return '5';
    case 'Six Months':
      return '6';
    case 'Ten Months':
      return '10';
    case 'Eleven Months':
      return '11';
    case 'Twelve Months':
      return '12';
    default:
      return ' ';
  }
};

export const buildCheckoutReviewData = (
  coverageType: ProductName,
  offer: CheckoutOfferDetails,
  paymentMethod: PaymentMethod,
  paymentNumber: string | undefined,
  premiumPlan: PaymentPlan,
): PolicySummary => {
  return {
    coverageType,
    premiumAmount: parseDollar(
      paymentMethod.paymentPremium || offer.offer[premiumPlan]?.totalPremium,
    ),
    dueAmount: parseDollar(offer.offer[premiumPlan]?.totalPremium),
    policyDuration: offer.offer.policyDuration || 'undefined',
    items: [
      {
        text: 'Billed to',
        value: billedTo(paymentMethod.paymentType, paymentNumber),
        variant: 'regular',
      },
      {
        text: 'Policy starts on',
        value: formatDate(offer.policyStartDate),
        variant: 'regular',
      },
      {
        text: 'Number of payments',
        value: numOfPayments(paymentMethod.paymentPlan),
        variant: 'regular',
      },
    ],
  };
};

// CSUI-1068
export const getSniPersonRef = createSelector(
  (state: RootStore) => getAnswers(state),
  (answers): string | undefined => {
    if (answers) {
      // FIXME?: this code looks problematic,
      // what happens if answers includes 2 SNI_REGEX matches
      // (i.e. you have 3 people on the quote, and both the non-PNI people have this question answered)
      // one of these may be value === 'true' but the other may not be
      // using find() here only selects the "first" person, but that may be the one that will not pass
      // the value === 'true' check below.
      // this code should instead be:
      // const matchKey = Object.entries(answers).find(([key,value]) =>
      //   SNI_REGEX.test(key) && value === 'true');
      // if (matchKey) {
      //   const result = SNI_REGEX.exec(matchKey);
      //   return `person.${result!.[1]}`;
      // }
      const matchKey = Object.keys(answers).find((key) => SNI_REGEX.test(key));
      if (matchKey) {
        const value = answers[matchKey];
        if (value === 'true') {
          const result = SNI_REGEX.exec(matchKey);

          return `person.${result?.[1]}`;
        }
      }
    }

    return undefined;
  },
);
