import { upperFirst } from '@ecp/utils/common';

import { env } from '@ecp/env';
import { getOfferQuoteNumber, getOffers } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';

import { useStyles } from './ProductQuoteNumber.styles';

interface ProductQuoteNumberItemProps {
  product: Product;
  index: number;
}

export const ProductQuoteNumberItem: React.FC<ProductQuoteNumberItemProps> = ({
  product,
  index,
}) => {
  const { cx, classes } = useStyles();

  const productQuoteNumber = useSelector((state: RootStore) => getOfferQuoteNumber(state, product));
  const productTitle = upperFirst(product.substr(product.indexOf('.') + 1));

  return (
    <h3 className={cx(classes.quoteNumberInformation, index !== 0 && classes.quoteNumberDivider)}>
      {productQuoteNumber
        ? `${productTitle} quote #${productQuoteNumber}`
        : 'Unable to display quote number'}
    </h3>
  );
};

export const ProductQuoteNumber = (): React.ReactElement | null => {
  const { classes } = useStyles();
  const currentOffers = useSelector((state: RootStore) => getOffers(state));

  // TODO: This filter of indicative amfam.auto quote numbers is only temporarily
  const allProducts: Product[] = Object.entries(currentOffers)
    .filter(([key, data]) => !(!data?.eligibility.bindable && key === 'amfam.auto'))
    .map(([key, _]) => key);

  if (!env.static.isAgent || !allProducts.length) return null;

  return (
    <div className={classes.productQuoteNumberContainer}>
      {allProducts.map((product, i) => (
        <ProductQuoteNumberItem product={product} index={i} key={`${product}-${i}`} />
      ))}
    </div>
  );
};
