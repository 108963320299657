import { useCallback, useMemo } from 'react';

import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { PercentageGroupConnector } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField, useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import type { CardOption } from '@ecp/types';

import {
  useGetInteriorPercentageFields,
  useInteriorWallGroup,
  useInteriorWallPercentages,
} from '../../../../state';

export const InteriorWallQuestion: React.FC<QuestionProps> = (props) => {
  const interiorWall = useField('static.interiorWall');

  const interiorWallMaterialField = useFieldWithPrefix(
    'interiorWall.<id>',
    'interiorWall.<id>',
  )('material');
  const {
    addAndUpdateInteriorWall,
    updateInteriorWalls,
    removeInteriorWall,
    addAndUpdateAllInteriorWalls,
  } = useInteriorWallGroup();
  const interiorWallPercentages = useInteriorWallPercentages();
  const materials = Object.keys(interiorWallPercentages).join(',');
  useInitValues({ [interiorWall.key]: materials });

  const interiorWallMaterialFieldQuestionOptions = interiorWallMaterialField?.question?.options;
  const getinteriorWallMaterialAllLabels = useMemo(() => {
    return interiorWallMaterialFieldQuestionOptions?.map((option) => option.value) || [];
  }, [interiorWallMaterialFieldQuestionOptions]);
  const percentageFields = useGetInteriorPercentageFields(getinteriorWallMaterialAllLabels);

  useAddFields({
    interiorWall,
    ...percentageFields,
  });

  const handleInteriorWallAdd = useCallback(
    async (key: string, value: string) => {
      await addAndUpdateInteriorWall({ material: key, percentage: value });
    },
    [addAndUpdateInteriorWall],
  );

  const handleInteriorWallsUpdate = useCallback(
    async (keyValues: { [key: string]: string }) => {
      if (Object.keys(keyValues).length > 0) {
        const interiorWalls = Object.entries(keyValues).map(([key, value]) => {
          return { material: key, percentage: value };
        });
        await updateInteriorWalls(interiorWalls);
      }
    },
    [updateInteriorWalls],
  );

  const handleInteriorWallRemove = useCallback(
    async (key: string) => {
      await removeInteriorWall(key);
    },
    [removeInteriorWall],
  );

  const handleInteriorWallAddAndUpdateAll = useCallback(
    async (key: string, value: string, keyValues: { [key: string]: string }) => {
      const interiorWalls = Object.entries(keyValues).map(
        ([interiorWallKey, interiorWallValue]) => ({
          material: interiorWallKey,
          percentage: interiorWallValue,
        }),
      );

      await addAndUpdateAllInteriorWalls({ material: key, percentage: value }, interiorWalls);
    },
    [addAndUpdateAllInteriorWalls],
  );

  if (!interiorWallMaterialField.exists) return null;

  const {
    label = interiorWallMaterialField.question.title || '',
    helperText = interiorWallMaterialField.question.helpText,
    trackingName = 'interior_wall_more_choices',
  } = props;

  return (
    <PercentageGroupConnector
      hidePercentageSelected
      title={label}
      subtitle={interiorWallMaterialField.question.subtitle || ''}
      helpText={helperText}
      label='interior wall'
      options={interiorWallMaterialField.question.options as CardOption[]}
      percentages={interiorWallPercentages}
      uiField={interiorWall}
      onAdd={handleInteriorWallAdd}
      onUpdate={handleInteriorWallsUpdate}
      onRemove={handleInteriorWallRemove}
      moreLessTrackingName={trackingName}
      optionTrackingName='interior_wall_multiselect'
      percentTrackingName='interior_wall_materials_percent'
      onAddAndUpdateAll={handleInteriorWallAddAndUpdateAll}
    />
  );
};
