import { Grid } from '@mui/material';

import { getDrivers, getVehicles } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { useStyles } from './VehicleDriverSummary.styles';

const pluralize = (arr: unknown[]): string => (arr.length > 1 ? 's' : '');

export const VehicleDriverSummary: React.FC = () => {
  const { classes } = useStyles();
  const vehicles = useSelector(getVehicles);
  const drivers = useSelector(getDrivers);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={6}>
        <p className={classes.vehicleDriverTitle}>Vehicle{pluralize(vehicles)}</p>
        <ul className={classes.vehicleDriverList}>
          {vehicles.map(({ year, make, model }, i) => (
            <li key={`${year}${model}${i}`} className={classes.vehicleDriverContent}>
              {year} {make} {model}
            </li>
          ))}
        </ul>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <p className={classes.vehicleDriverTitle}>Driver{pluralize(drivers)}</p>
        <ul className={classes.vehicleDriverList}>
          {drivers.map(({ firstName, lastName }, i) => (
            <li key={`${lastName}${i}`} className={classes.vehicleDriverContent}>
              {firstName} {lastName}
            </li>
          ))}
        </ul>
      </Grid>
    </Grid>
  );
};
