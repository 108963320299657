export interface Metadata {
  shouldDisplayBillingReceipt: boolean;
  shouldDisplayRouteToPLPC: boolean;
  product: string[];
  variant: string;
  showPoiDocument: boolean;
}

const metadata: Metadata = {
  shouldDisplayBillingReceipt: false,
  shouldDisplayRouteToPLPC: false,
  product: ['amfam.auto'],
  variant: '',
  showPoiDocument: false,
};

export default metadata;
