import { useCallback, useState } from 'react';

import { getPaymentOptionsAndAcksForCheckout } from '@ecp/features/sales/checkout';
import { NavStatus } from '@ecp/features/sales/shared/constants';
import {
  getAreSomeSelectedProductsIndicative,
  getDalSessionId,
  getOfferProductsSelected,
  getOfferSetId,
  patchUserSelectionAndUpdateOffer,
  updatePageStatus,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

export const usePreToCheckoutSubmit = (
  onNext?: () => Promise<void>,
): { isProcessing: boolean; handleSubmit: () => Promise<void> } => {
  const dispatch = useDispatch();
  const dalSessionId = useSelector(getDalSessionId);
  const offerSetId = useSelector(getOfferSetId);
  const offerProductsSelected = useSelector(getOfferProductsSelected);
  const userSelection = useSelector(getOfferSetId);
  const [isProcessing, setIsProcessing] = useState(false);
  const areSomeSelectedProductsIndicative = useSelector(getAreSomeSelectedProductsIndicative);

  const handleSubmit = useCallback(async () => {
    setIsProcessing(true);
    if (offerSetId && userSelection && offerProductsSelected.length > 0) {
      await dispatch(patchUserSelectionAndUpdateOffer());
      dispatch(updatePageStatus(NavStatus.VALID));
      if (!areSomeSelectedProductsIndicative) {
        await dispatch(
          getPaymentOptionsAndAcksForCheckout({ dalSessionId, products: offerProductsSelected }),
        );
      }
    }
    if (onNext) {
      await onNext();
      // No need to setIsProcessing back to false because user is transitioning off the current page
    } else {
      setIsProcessing(false);
    }
  }, [
    offerSetId,
    userSelection,
    offerProductsSelected,
    onNext,
    dispatch,
    areSomeSelectedProductsIndicative,
    dalSessionId,
  ]);

  return { isProcessing, handleSubmit };
};
