import { useAddFields } from '@ecp/features/sales/form';
import { TextField } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

export const FullTimeEmployeesQuestion: React.FC<QuestionProps> = (props) => {
  const {
    label = 'How many people are employed by the policyholder and on the premises 40+ hours/week, such as a nurse or childcare provider?',
    trackingName = 'policyholder_employed_people',
  } = props;

  const fullTimeEmployees = useField('fullTimeEmployees');

  useAddFields({ fullTimeEmployees });

  if (!fullTimeEmployees.exists) return null;

  return (
    <TextField
      {...fullTimeEmployees.props}
      label={label}
      id={fullTimeEmployees.props.name}
      trackingName={trackingName}
    />
  );
};
