import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

export const NonCombustibleUseWithinFiveFeetQuestion: React.FC<QuestionProps> = (props) => {
  const {
    label = 'Do you only use non-combustible materials within 5 feet of your home?',
    trackingName = 'cleared_non_combustible_objects_within_5feet',
  } = props;
  const nonCombustibleUseWithinFiveFeet = useField('property.fiveFootNonCombustibleInd');

  if (!nonCombustibleUseWithinFiveFeet.exists) return null;

  return (
    <RadioGroupWithOptions
      {...nonCombustibleUseWithinFiveFeet.props}
      id='nonCombustibleUseWithinFiveFeet'
      label={label}
      variant='yesNoButton'
      trackingName={trackingName}
    />
  );
};
