import { useCallback, useEffect, useState } from 'react';

import { Divider, Grid } from '@mui/material';

import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { scrollToTop } from '@ecp/utils/web';

import type { Item } from '@ecp/components';
import { GridItem } from '@ecp/components';
import { TooltipWithIcon } from '@ecp/components';
import { env } from '@ecp/env';
import type { CheckoutCardProps } from '@ecp/features/sales/checkout';
import {
  HelpCardConditional,
  MobileMonthlyPaymentsCheckoutCard,
  MonthlyPaymentsCheckoutCard,
} from '@ecp/features/sales/checkout';
import { CarrierMessagingDialog, Form } from '@ecp/features/sales/shared/components';
import { COVERAGES_LEGAL_TEXT } from '@ecp/features/sales/shared/constants';
import { EmailQuotes, EmailQuotesToggle } from '@ecp/features/sales/shared/email-quotes';
import { PagePath } from '@ecp/features/sales/shared/routing';
import {
  getCarrierMessagingMetadata,
  getDeclinationNoticeDisplayedPagePath,
  getDrivers,
  getOfferProductsSelectedByType,
  getPolicyLevelDiscounts,
  getPrimaryInsuredStateCode,
  getVehicles,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AllCoveragesFields } from '@ecp/features/sales/shared/types';
import type { AutoProduct, PropertyProduct } from '@ecp/features/shared/product';
import { getProductNameFromProduct } from '@ecp/features/shared/product';
import { useIsDesktop, useIsTablet } from '@ecp/themes/base';

import { DeclinationNoticesWrapper } from '../../../components';
import {
  useCoveragePageRedesignTestsPolicyStartDateAtTopOfCoveragesForm,
  useShowOriginalPolicyStartDateForm,
} from '../../../util/coveragesPageRedesignABTestsLayout';
import { carrierMessagingMetadata } from '../metadata/carrierMessaging.metadata';
import {
  isAfterDayOfConnectAutoMinimumLimitsWarning,
  useGetPolicyCoverageFields,
  useGetPropertyCoverageFields,
  useVehicleCoverageFields,
} from '../util';
import { AutoCoveragesForm } from './AutoCoveragesForm';
import { CoverageLimitIncreaseNotice } from './CoverageLimitIncreaseNotice/CoverageLimitIncreaseNotice';
import { useStyles } from './CoveragesForm.styles';
import type { Metadata } from './metadata';
import metadata from './metadata';
import { PolicyStartDateCalendar } from './PolicyStartDateCalendarForm';
import { PolicyStartDateForm } from './PolicyStartDateForm';
import { PropertyCoveragesForm } from './PropertyCoveragesForm';
import { useCoveragesFormCoordinatedValidation } from './util/useCoveragesFormCoordinatedValidation';

const CostcoCoveragesTestPolicyStartDateCalendarGridItemAuto = (): React.ReactNode => {
  const { auto: autoOfferProduct } = useSelector(getOfferProductsSelectedByType);

  if (!autoOfferProduct) return null;

  return (
    <Grid item>
      <PolicyStartDateCalendar product={autoOfferProduct} />
    </Grid>
  );
};

const CostcoCoveragesTestPolicyStartDateCalendarGridItemProperty = (): React.ReactNode => {
  const { property: propertyOfferProduct } = useSelector(getOfferProductsSelectedByType);

  if (!propertyOfferProduct) return null;

  return (
    <Grid item>
      <PolicyStartDateCalendar product={propertyOfferProduct} />
    </Grid>
  );
};

const CostcoCoveragesTestBundlePolicyStartDateHeading = (): React.ReactNode => {
  const { classes } = useStyles();

  return (
    <h3 className={classes.costcoTestBundlePolicyStartDateHeading}>
      When do you want your coverage to start?
      <TooltipWithIcon
        className={classes.costcoBundlePolicyStartDateHeadingToolTip}
        title='Adjustments to start date could result in adjustments to price.'
      />
    </h3>
  );
};

const ZillowRentersMobileCoveragePageRedesignTest = (): React.ReactNode => {
  const { property: propertyOfferProduct } = useSelector(getOfferProductsSelectedByType);

  return (
    <Grid container item xs={12} justifyContent='center'>
      {propertyOfferProduct && (
        <Grid item>
          <PolicyStartDateCalendar product={propertyOfferProduct} />
        </Grid>
      )}
    </Grid>
  );
};

const CostcoCoveragesRedesignTest = (): React.ReactNode => {
  const { classes } = useStyles();
  const {
    showCostcoCoveragesPageRedesignTestTopPolicyStartDateBundleHeading,
    showCostcoCoveragesPageRedesignTestTopAuto,
    showCostcoCoveragesPageRedesignTestTopProperty,
  } = useCoveragePageRedesignTestsPolicyStartDateAtTopOfCoveragesForm();

  return (
    <div className={classes.costcoTestTopPolicyStartDateCalendarContainer}>
      <Grid container item xs={12} justifyContent='space-between'>
        {showCostcoCoveragesPageRedesignTestTopPolicyStartDateBundleHeading && (
          <CostcoCoveragesTestBundlePolicyStartDateHeading />
        )}
        {showCostcoCoveragesPageRedesignTestTopAuto && (
          <CostcoCoveragesTestPolicyStartDateCalendarGridItemAuto />
        )}
        {showCostcoCoveragesPageRedesignTestTopProperty && (
          <CostcoCoveragesTestPolicyStartDateCalendarGridItemProperty />
        )}
      </Grid>
    </div>
  );
};

export type CoveragesFormProps = { checkoutCardProps: CheckoutCardProps } & Metadata & {
    banner?: React.ReactNode;
  };

export const CoveragesForm: React.FC<CoveragesFormProps> = (props) => {
  const { banner, checkoutCardProps, hideDisclaimers = metadata.hideDisclaimers } = props;
  const { classes, cx } = useStyles();

  const drivers = useSelector(getDrivers);
  const vehicles = useSelector(getVehicles);

  const { auto: autoOfferProduct, property: propertyOfferProduct } = useSelector(
    getOfferProductsSelectedByType,
  );

  const fields: AllCoveragesFields = {};
  const policyLevelDiscounts = useSelector(getPolicyLevelDiscounts);

  const autoDiscountItems: Partial<Record<AutoProduct, Item[]>> = {};
  const propertyDiscountItems: Partial<Record<PropertyProduct, Item[]>> = {};

  const autoCoverageFields = {
    policyCoverageFields: useGetPolicyCoverageFields(autoOfferProduct, drivers),
    vehicleCoverageFields: useVehicleCoverageFields(vehicles, autoOfferProduct),
  };
  const propertyCoverageFields = useGetPropertyCoverageFields(propertyOfferProduct);

  if (autoOfferProduct) {
    fields[autoOfferProduct] = autoCoverageFields;
    autoDiscountItems[autoOfferProduct] = policyLevelDiscounts.auto;
  }
  if (propertyOfferProduct) {
    fields[propertyOfferProduct] = propertyCoverageFields;
    const propertyProductName = getProductNameFromProduct(propertyOfferProduct);
    propertyDiscountItems[propertyOfferProduct] = policyLevelDiscounts[propertyProductName];
  }

  const { handleAutoCoveragesFormValidChange, handlePropertyCoveragesFormValidChange, showError } =
    useCoveragesFormCoordinatedValidation();

  const updatedCheckoutCardProps = {
    ...checkoutCardProps,
    showError,
  };

  const [carrierMessagingOpen, setCarrierMessagingOpen] = useState(false);
  const handleCarrierMessagingClose = useCallback((): void => {
    setCarrierMessagingOpen(false);
  }, [setCarrierMessagingOpen]);

  const isDesktop = useIsDesktop();
  const isTablet = useIsTablet();

  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const carrierMessages = useSelector((state: RootStore) =>
    getCarrierMessagingMetadata(state, stateCode, carrierMessagingMetadata),
  );

  const socialProofDisclaimerText = metadata.getSocialProofDisclaimerText?.(
    propertyOfferProduct,
    stateCode,
  );

  const commonCoveragesProps = {
    fields,
    setCarrierMessagingOpen,
  };

  const disableEmailQuotes = flagValues[FeatureFlags.DISABLE_EMAIL_QUOTES];
  // EDSP-12641 - State Specific Minimum Limits Warning
  const showCoverageLimitIncreaseNotice =
    flagValues[FeatureFlags.CONNECT_AUTO_MINIMUM_LIMITS_WARNING];
  const isCoverageLimitIncreaseNotice = isAfterDayOfConnectAutoMinimumLimitsWarning();

  useEffect(() => {
    if (!updatedCheckoutCardProps.recalculate) scrollToTop();
  }, [updatedCheckoutCardProps.recalculate]);

  const declinationNoticeDisplayedPagePath = useSelector(getDeclinationNoticeDisplayedPagePath);
  const showDeclinationNotice =
    env.static.isAgent || declinationNoticeDisplayedPagePath !== PagePath.QUOTES;

  const { showCostcoCoveragesPageRedesignTestOnTop, showZillowCoveragesPageRedesignTestOnTop } =
    useCoveragePageRedesignTestsPolicyStartDateAtTopOfCoveragesForm();
  const showOriginalPolicyStartDateForm = useShowOriginalPolicyStartDateForm();

  return (
    <div className={classes.root}>
      <Form showBackdrop={updatedCheckoutCardProps.isProcessing} className={classes.formContainer}>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.addSpacing}>
            {banner}
          </Grid>

          {showCoverageLimitIncreaseNotice && !isCoverageLimitIncreaseNotice && (
            <CoverageLimitIncreaseNotice className={classes.addSpacingBelow} />
          )}
          {showDeclinationNotice && (
            <DeclinationNoticesWrapper className={classes.addSpacingBelow} />
          )}
          <Grid item xs={12} className={classes.addSpacing}>
            {showCostcoCoveragesPageRedesignTestOnTop && <CostcoCoveragesRedesignTest />}
            {showZillowCoveragesPageRedesignTestOnTop && (
              <ZillowRentersMobileCoveragePageRedesignTest />
            )}
            {showOriginalPolicyStartDateForm && <PolicyStartDateForm variant='onPage' />}
          </Grid>
          {autoOfferProduct && autoCoverageFields && (
            <AutoCoveragesForm
              {...commonCoveragesProps}
              fields={autoCoverageFields}
              autoDiscountItems={autoDiscountItems}
              messages={carrierMessages?.auto}
              onFormValidChange={handleAutoCoveragesFormValidChange}
            />
          )}
          {propertyOfferProduct && propertyCoverageFields && (
            <PropertyCoveragesForm
              {...commonCoveragesProps}
              fields={propertyCoverageFields}
              propertyDiscountItems={propertyDiscountItems}
              messages={
                carrierMessages?.home?.length > 0 ? carrierMessages.home : carrierMessages.renters
              }
              onFormValidChange={handlePropertyCoveragesFormValidChange}
            />
          )}
        </Grid>
        {!hideDisclaimers && (
          <>
            <GridItem topSpacing='lg' xs={12}>
              <Divider aria-hidden='true' />
            </GridItem>
            <GridItem topSpacing='lg' xs={12}>
              <p className={classes.disclaimerContent}>{COVERAGES_LEGAL_TEXT}</p>
              {!env.static.isAgent && propertyOfferProduct && socialProofDisclaimerText && (
                <p className={classes.disclaimerContent}>{socialProofDisclaimerText}</p>
              )}
            </GridItem>
          </>
        )}
        <CarrierMessagingDialog
          open={
            carrierMessagingOpen &&
            Object.values(carrierMessages)?.some((product) => product.length)
          }
          carrierMessages={carrierMessages}
          onClose={handleCarrierMessagingClose}
        />
      </Form>
      {/* Desktop View */}
      {isTablet ? null : (
        <Grid
          container
          className={cx(
            classes.sidebarContainerRoot,
            env.static.isAgent && classes.agentSidebarContainerRoot,
          )}
          spacing={2}
          direction='column'
        >
          <Grid item xs={12}>
            <MonthlyPaymentsCheckoutCard {...updatedCheckoutCardProps} showCTAButton />
            <GridItem topSpacing='sm'>
              <HelpCardConditional />
            </GridItem>
            {env.static.isAgent && !disableEmailQuotes && (
              <GridItem topSpacing='sm'>
                <EmailQuotes
                  analyticsElement='choice.policyCoveragePage.emailQuotesButton'
                  disabled={updatedCheckoutCardProps.recalculate}
                  fields={fields}
                />
              </GridItem>
            )}
          </Grid>
        </Grid>
      )}
      {/* Tablet and Mobile View */}
      {isDesktop ? null : (
        <MobileMonthlyPaymentsCheckoutCard {...updatedCheckoutCardProps}>
          {env.static.isAgent && !disableEmailQuotes && (
            <EmailQuotesToggle
              analyticsElement='choice.policyCoveragePage.emailQuotesButton'
              disabled={updatedCheckoutCardProps.recalculate}
              fields={fields}
            />
          )}
        </MobileMonthlyPaymentsCheckoutCard>
      )}
    </div>
  );
};
