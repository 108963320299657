import { useCallback, useState } from 'react';

import { Grid } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';

import type { PaymentPlan } from '@ecp/features/sales/shared/constants';
import type { OfferInfo } from '@ecp/features/sales/shared/store/types';

import { Button } from '../Button';
import { StateFeesDialog } from './StateFeesDialog';
import { useStyles } from './StateFeesSection.styles';

interface Props {
  premiumPlan: PaymentPlan;
  offerDetails?: OfferInfo | null;
  showStateFees?: boolean;
}

export const StateFeesSection: React.FC<Props> = (props) => {
  const { offerDetails, showStateFees, premiumPlan } = props;
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);

  const onOpen = useCallback(() => {
    setOpen(true);
    trackClick({ action: 'StateSpecificFeesLink', label: 'StateSpecificFees' });
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  if (!premiumPlan || !offerDetails) return null;

  const stateFeesDisclosure = offerDetails?.[premiumPlan]?.stateFeesDisclosure?.toLowerCase();

  const stateFeeItems = offerDetails?.[premiumPlan]?.stateFeeItems;
  const surcharges = offerDetails?.[premiumPlan]?.surcharges;

  if (!showStateFees) return null;

  return (
    <Grid item container xs={12}>
      <Grid item xs={12} className={classes.stateFeesContainer}>
        {stateFeesDisclosure === 'explicitfees' && (
          <Button variant='iconText' className={classes.linkTag} onClick={onOpen}>
            Premium includes state fees
          </Button>
        )}
        {stateFeesDisclosure === 'implicitfees' && (
          <p className={classes.stateFeeText}>Premium may include state-required fees</p>
        )}
        <StateFeesDialog
          stateFeeItems={stateFeeItems}
          surcharges={surcharges}
          open={open}
          onClose={handleClose}
        />
      </Grid>
    </Grid>
  );
};
