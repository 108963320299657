import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PreviousPolicyInformationQuestions' })((theme) => ({
  ...theme.mixins.formAll,
  informationCard: {
    backgroundColor: theme.palette.grey[100],
    borderTop: `4px solid ${theme.palette.primary.main}`,
    padding: '0px 20px 20px 20px',
    marginTop: 20,
  },
}));
