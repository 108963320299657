import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ExternalInspection' })((theme) => ({
  root: {},
  pageContainer: {
    maxWidth: 639,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      padding: 15,
    },
  },
  pageHeader: {
    maxWidth: 639,
    marginTop: 20,
  },
}));
